import { Dispatch } from 'react';

import { Box, Text, TextField } from '@nike/eds';
import { KeyenceConfiguration, KeyenceConfigurationForm } from './configuration-types';
import { ConfigurationAction } from './configuration-reducer';
import { getNonNumericFields } from './utils';

export function Position({
  keyenceConfig,
  dispatch,
}: {
  keyenceConfig: KeyenceConfigurationForm;
  dispatch: Dispatch<ConfigurationAction>;
}) {
  const nonNumericFields = getNonNumericFields(keyenceConfig);
  const numberErrMsg = 'Only numbers are allowed';

  return (
    <Box className="eds-spacing--mb-24">
      <Text font="title-4" as="h5" className="eds-spacing--mb-16">
        Position
      </Text>

      <Box className="eds-flex eds-gap--24">
        <Box className="eds-flex eds-flex--direction-column eds-gap--24">
          <TextField
            id="scan-length"
            type="number"
            value={keyenceConfig.position.length}
            label="Length of scan (in mmm)"
            hasErrors={nonNumericFields.includes('position.length')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_POSITION',
                name: 'length',
                value: e.target.value,
              })
            }
          />

          <TextField
            id="scan-rate"
            type="number"
            value={keyenceConfig.position.rate}
            label="Scanning frame speed (in hz)"
            hasErrors={nonNumericFields.includes('position.rate')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_POSITION',
                name: 'rate',
                value: e.target.value,
              })
            }
          />

          <TextField
            id="scan-speed"
            type="number"
            value={keyenceConfig.position.speed}
            label="Robot speed (in mm/sec)"
            hasErrors={nonNumericFields.includes('position.speed')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_POSITION',
                name: 'speed',
                value: e.target.value,
              })
            }
          />
        </Box>
      </Box>
    </Box>
  );
}
