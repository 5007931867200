/* eslint-disable no-restricted-syntax */
import {
  CloudConfiguration,
  KeyenceConfiguration,
  KeyenceConfigurationForm,
  Position,
} from './configuration-types';

export function convertNumbers(obj: Record<string, unknown>): Record<string, unknown> {
  return Object.entries(obj).reduce((accumulator, [key, value]) => {
    if (typeof value === 'string' && !Number.isNaN(Number(value))) {
      accumulator[key] = Number(value);
    } else if (typeof value === 'object' && value !== null && !(value instanceof Array)) {
      accumulator[key] = convertNumbers(value as Record<string, unknown>);
    } else {
      accumulator[key] = value;
    }
    return accumulator;
  }, {} as Record<string, unknown>);
}

export function isValidRecord(obj: Record<string, unknown>): boolean {
  return Object.values(obj).every(value => {
    if (typeof value === 'boolean' || typeof value === 'number') {
      return true;
    }
    if (typeof value === 'object' && value !== null && !(value instanceof Array)) {
      return isValidRecord(value as Record<string, unknown>);
    }
    return false;
  });
}

function isNumeric(str: string) {
  if (typeof str !== 'string') return false; // we only process strings!
  return (
    !Number.isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !Number.isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function getNonNumericFields(obj: any, parentKey = ''): string[] {
  let nonNumericFields: string[] = [];

  for (const key in obj) {
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      const nestedFields = getNonNumericFields(obj[key], parentKey ? `${parentKey}.${key}` : key);
      console.log('nestedFields', nestedFields);
      nonNumericFields = nonNumericFields.concat(nestedFields);
    } else if (typeof obj[key] === 'string' && !isNumeric(obj[key])) {
      const fullPath = parentKey ? `${parentKey}.${key}` : key;
      nonNumericFields.push(fullPath);
    }
  }

  return nonNumericFields;
}

export function parseCloudConfiguration(obj: any): CloudConfiguration | undefined {
  const result: any = {};

  // CropBox
  result.cropBox = { ...obj.cropBox };
  for (const key of ['sizeX', 'sizeY', 'sizeZ', 'x', 'y', 'z']) {
    if (typeof result.cropBox[key] === 'string') {
      const num = parseFloat(result.cropBox[key]);
      if (Number.isNaN(num)) return undefined;
      result.cropBox[key] = num;
    }
  }

  // Filtering
  result.filtering = { ...obj.filtering };
  for (const key of ['voxelSize', 'nbNeighbors', 'stdRatio', 'eps', 'minPoints']) {
    if (typeof result.filtering[key] === 'string') {
      const num = parseFloat(result.filtering[key]);
      if (Number.isNaN(num)) return undefined;
      result.filtering[key] = num;
    }
  }

  // Translation
  result.translation = { ...obj.translation };
  for (const key of ['x', 'y', 'z']) {
    if (typeof result.translation[key] === 'string') {
      const num = parseFloat(result.translation[key]);
      if (Number.isNaN(num)) return undefined;
      result.translation[key] = num;
    }
  }

  // Other
  result.other = { ...obj.other };
  for (const key of ['delayComp', 'minCloudSize']) {
    if (typeof result.other[key] === 'string') {
      const num = parseFloat(result.other[key]);
      if (Number.isNaN(num)) return undefined;
      result.other[key] = num;
    }
  }

  return result;
}

export function parsePosition(obj: any): Position | undefined {
  if (typeof obj !== 'object' || obj === null) {
    return undefined;
  }

  const config: Position = { length: NaN, rate: NaN, speed: NaN };

  type PositionKey = keyof Position;
  const keys: PositionKey[] = ['length', 'rate', 'speed'];

  for (const key of keys) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && typeof obj[key] === 'string') {
      const numValue = parseFloat(obj[key]); // Try to parse the string as a float
      if (!Number.isNaN(numValue)) {
        config[key] = numValue;
      } else {
        return undefined; // Return undefined if parsing fails for any property
      }
    } else {
      return undefined; // Return undefined if any required property is not a string
    }
  }

  return config;
}

export function parseConfigurationForm(form: KeyenceConfigurationForm): KeyenceConfiguration {
  const cloudConfig = parseCloudConfiguration(form.cloudConfig);
  const position = parsePosition(form.position);
  return { cloudConfig, position } as KeyenceConfiguration;
}

export function isKeyenceConfigurationForm(obj: any): obj is KeyenceConfigurationForm {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.cloudConfig === 'object' &&
    typeof obj.position === 'object' &&
    typeof obj.cloudConfig.filtering.downsample === 'string' &&
    typeof obj.cloudConfig.filtering.clean === 'string' &&
    obj.position.length === 'string'
  );
}

export function parseKeyenceConfigurationForm(
  input: unknown
): KeyenceConfigurationForm | undefined {
  if (isKeyenceConfigurationForm(input)) {
    return input;
  }

  return undefined;
}
