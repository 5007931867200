import useSWR from 'swr';

import { WearTest } from '@nike.innovation/talos-core';

import { environment } from '../../environments/environment';

export function useWearTest(
  id: string,
  token: string
): {
  wearTest?: WearTest;
  isLoading: boolean;
  isError: any;
} {
  const key = `api/v1/wear-tests/${id}`;

  const fetcher = async () => {
    const response = await fetch(`${environment.dataApiBaseUrl}/${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch wear tests');
    }

    const wearTest = await response.json();

    return wearTest;
  };

  const { data, error } = useSWR(key, fetcher);

  return {
    wearTest: data,
    isLoading: !error && !data,
    isError: error,
  };
}
