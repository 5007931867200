import { useState } from 'react';

import { ResponseSchema, Sample } from '@nike.innovation/talos-core';
import { TableCell, Button, Spinner } from '@nike/eds';

import { downloadMetrics, getFileNameFromMetricKey } from '../../api-client/get-row-metrics';
import { environment } from '../../../environments/environment';

export function ResponseTableRow({
  sample,
  responseSchema,
  openSipingModal,
}: {
  sample: Sample;
  responseSchema: ResponseSchema;
  openSipingModal: (sample: Sample) => void;
}) {
  const fileNamesString = sample.metricFiles
    .map(metricFile => getFileNameFromMetricKey(metricFile.file))
    .join(', ');

  const [isDownloading, setIsDownloading] = useState(false);

  const isPywebviewAvailable = environment.useMockServer || (window as any).pywebview !== undefined;

  return (
    <tr>
      <TableCell>{sample.sampleId}</TableCell>
      <TableCell>{sample.treatmentId}</TableCell>
      {responseSchema.map(response => (
        <TableCell>{sample.response[response]}</TableCell>
      ))}
      <TableCell>{sample.state}</TableCell>
      <TableCell>{fileNamesString}</TableCell>
      <TableCell>
        <Button
          variant="secondary"
          disabled={!isPywebviewAvailable}
          onClick={() => {
            openSipingModal(sample);
          }}
        >
          Run
        </Button>
      </TableCell>
      <TableCell>
        <Button
          size="small"
          variant="secondary"
          disabled={sample.metricFiles.length === 0 || isDownloading}
          onClick={async () => {
            setIsDownloading(true);
            await downloadMetrics(sample.metricFiles);
            setIsDownloading(false);
          }}
        >
          {isDownloading ? <Spinner /> : 'Download metrics'}
        </Button>
      </TableCell>
    </tr>
  );
}
