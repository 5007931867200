import { Card, Text, Box, Link } from '@nike/eds';
import { ResultFile } from '@nike.innovation/talos-core';

export function TestResults({ testResults }: { testResults: ResultFile[] }) {
  return (
    <Card className="eds-elevation--2">
      <Text font="title-4" className="eds-spacing--mb-16">
        Test Results
      </Text>
      <Box>
        {testResults.length > 0 ? (
          <Box>
            {testResults.map(link => (
              <Box className="eds-spacing--mb-8">
                <Link href={link.link} target="_blank" className="eds-spacing--mb-8">
                  {link.name}
                </Link>
              </Box>
            ))}
          </Box>
        ) : (
          <Text>No test results</Text>
        )}
      </Box>
    </Card>
  );
}
