import { useCallback, useEffect, useState } from 'react';
import { ScanPose } from '@nike.innovation/talos-core';
import { useScanPosesClient } from '../api-client/scan-poses-api-client';

const useSavedScanPose = () => {
  const [saved, setSaved] = useState<{ value: number; label: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [apiError, setError] = useState<unknown>(null);
  const [creating, setCreating] = useState(false);
  const [stateVersion, setStateVersion] = useState(0);

  const scanPosesClient = useScanPosesClient();

  const create = useCallback(async (key: string, scanPose: ScanPose) => {
    setCreating(true);
    const response = await scanPosesClient.storeScanPose({ key, scanPose });
    setStateVersion(v => v + 1);

    if (response.status) {
      setError(response.message);
    }

    setCreating(false);
    return response;
  }, []);

  useEffect(() => {
    setError(null);
    (async () => {
      try {
        setLoading(true);
        const retrievedPoses = await scanPosesClient.listScanPoses();
        const mappedPoses = retrievedPoses.map((pose, index) => ({
          value: index,
          label: pose,
        }));
        setSaved(mappedPoses);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [stateVersion]);

  return { saved, loading, apiError, create, creating };
};
export default useSavedScanPose;
