import { SetStateAction } from 'react';
import { Modal, Text, Box } from '@nike/eds';

import { PCDVisualizer, PointCloudMetadata } from '@nike.innovation/visualizer';

export function PCDModal({
  metadata,
  pcdModalVis,
  setPcdModalVis,
}: {
  metadata: PointCloudMetadata[];
  pcdModalVis: boolean;
  setPcdModalVis: (value: SetStateAction<boolean>) => void;
}) {
  return (
    <Modal
      isOpen={pcdModalVis}
      onDismiss={() => setPcdModalVis(false)}
      headerSlot={
        <Box>
          <Text font="title-3">Point cloud preview</Text>
          <Text font="body-2">Pan/zoom to view point cloud</Text>
          <Text font="body-3">(You may need to zoom out initially to see point cloud)</Text>
        </Box>
      }
    >
      <PCDVisualizer metadata={metadata} />
    </Modal>
  );
}
