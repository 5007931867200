import { Dispatch, useEffect, useState } from 'react';

import { Box, Button, Select, Spinner, Text } from '@nike/eds';
import { Measure } from '@nike/nike-design-system-icons';
import { PointCloudMetadata } from '@nike.innovation/visualizer';

import { MorphAlgorithm } from '@nike.innovation/talos-core';
import { talosPyWebViewClient } from '../../api-client/talos-py-web-view-client';
import { CommandPageAction, CommandPageState } from '../../command/command-page-reducer';
import { MorphParameterField } from './morph-parameter-field';

export function MorphSection({
  commandPageState,
  dispatch,
  token,
  isPywebviewAvailable,
  updateShowPcd,
}: {
  commandPageState: CommandPageState;
  dispatch: Dispatch<CommandPageAction>;
  token: string;
  isPywebviewAvailable: boolean;
  updateShowPcd: (metadata: PointCloudMetadata[]) => void;
}) {
  const [algorithmName, setAlgorithmName] = useState<string>('warpflow');
  const [morphAlgorithm, setMorphAlgorithm] = useState<MorphAlgorithm | undefined>(undefined);

  useEffect(() => {
    async function fetchMorphParameters() {
      if (token) {
        setMorphAlgorithm(await talosPyWebViewClient.morphParameters(algorithmName));
      }
    }

    fetchMorphParameters();
  }, [algorithmName, token, setMorphAlgorithm]);

  const parametersFilled = morphAlgorithm?.parameters.every(parameter => parameter.value);

  return (
    <Box className="eds-spacing--mb-32">
      {isPywebviewAvailable && (
        <Box>
          <Text font="title-2" className="eds-spacing--mb-16">
            Morph
          </Text>

          <Box className="eds-flex eds-flex--align-items-flex-end eds-gap--32 eds-spacing--mb-16">
            <Select
              id="morph-algo"
              required
              label="Choose algorithm"
              defaultValue={{ label: 'WarpFlow', value: 'warpflow' }}
              options={[
                { label: 'WarpFlow', value: 'warpflow' },
                { label: 'ICP', value: 'icp' },
                { label: 'SIMP', value: 'simp' },
              ]}
              onChange={e => {
                setAlgorithmName(e?.value || '');
              }}
            />
          </Box>

          {morphAlgorithm && (
            <>
              <Box className="eds-flex eds-gap--32 eds-spacing--mb-16 eds-flex--wrap">
                {morphAlgorithm.parameters.map(parameter => (
                  <MorphParameterField
                    key={parameter.name}
                    parameter={parameter}
                    setMorphAlgorithm={setMorphAlgorithm}
                  />
                ))}
              </Box>

              <Box>
                <Button
                  beforeSlot={<Measure />}
                  disabled={!!commandPageState.runningCommandId || !parametersFilled}
                  onClick={async () => {
                    dispatch({ kind: 'START_COMMAND', commandId: 'Morph' });
                    await talosPyWebViewClient.morph(morphAlgorithm, token).then(
                      async response => {
                        dispatch({ kind: 'END_COMMAND', response });

                        const toolPath = await talosPyWebViewClient.readPcd('warpflowPath');
                        const scan = await talosPyWebViewClient.readPcd('warpflowScan');
                        updateShowPcd([
                          {
                            data: toolPath,
                            color: 0x00ff00,
                            size: 3,
                          },
                          {
                            data: scan,
                            color: 0x000000,
                          },
                        ]);
                      },
                      () => {
                        dispatch({ kind: 'END_COMMAND', response: false });
                      }
                    );
                  }}
                >
                  {!commandPageState.runningCommandId ? 'Morph' : <Spinner />}
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
