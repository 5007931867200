/* eslint-disable jsx-a11y/control-has-associated-label */
import { Dispatch, SetStateAction, useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { AnchorPoint } from './anchor-point';
import { Point } from './calibrate-types';

export function CalibrateImage({
  imageData,
  anchor,
  setAnchor,
}: {
  imageData: string;
  anchor: Point | undefined;
  setAnchor: Dispatch<SetStateAction<Point | undefined>>;
}) {
  const imageRef = useRef<HTMLImageElement>(null);

  const handleClick = (event: React.MouseEvent) => {
    if (imageRef.current && imageRef.current !== null) {
      // Determine the clicked position on the image
      const image = imageRef.current;
      const imageRect = image.getBoundingClientRect();
      const imageXPercentage = ((event.clientX - imageRect.x) / imageRect.width) * 100;
      const imageYPercentage = ((event.clientY - imageRect.y) / imageRect.height) * 100;

      setAnchor({ x: imageXPercentage, y: imageYPercentage });
    }
  };

  return (
    <TransformWrapper
      centerOnInit
      maxScale={30}
      // limitToBounds={false}
      panning={{ disabled: true }}
      wheel={{
        activationKeys: [],
        excluded: ['wheelDisabled'],
        smoothStep: 0.01,
        step: 0.1,
      }}
    >
      <TransformComponent wrapperStyle={{ minHeight: 600 }}>
        <img
          ref={imageRef}
          src={`data:image/png;base64,${imageData}`}
          alt="basler camera capture"
        />

        {anchor && <AnchorPoint key={`${anchor.x},${anchor.y}`} pos={anchor} />}

        <button
          type="button"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            height: '100%',
            width: '100%',
            outline: 'none',
            position: 'absolute',
            cursor: 'crosshair',
            zIndex: 5,
          }}
          onClick={handleClick}
        />
      </TransformComponent>
    </TransformWrapper>
  );
}
