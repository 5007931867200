import { ScanPose } from '@nike.innovation/talos-core';
import { match } from 'ts-pattern';

interface ScanPoseType {
  inputName: string;
  inputX: number;
  inputY: number;
  inputZ: number;
  inputRX: number;
  inputRY: number;
  inputRZ: number;
  selectedPoses: ScanPose[];
}

type ScanPoseInput<T> = {
  [P in keyof T]: T[P] extends number ? string : T[P];
};

export type ScanPoseForm = ScanPoseInput<ScanPoseType>;

export type ScanPoseAction =
  | { kind: 'EDIT_POSE_NAME'; value: string }
  | { kind: 'EDIT_POSE_COORDINATE'; name: string; value: string }
  | { kind: 'EDIT_POSE_IN_FORM'; selected: ScanPose }
  | { kind: 'ADD_POSE'; pose: ScanPose }
  | { kind: 'EDIT_POSE_IN_LIST'; index: number; pose: ScanPose }
  | { kind: 'DELETE_POSE'; index: number };

export const scanPoseReducer = (state: ScanPoseForm, action: ScanPoseAction) =>
  match(action)
    .with({ kind: 'EDIT_POSE_NAME' }, refined => ({
      ...state,
      inputName: refined.value,
    }))
    .with({ kind: 'EDIT_POSE_COORDINATE' }, refined => ({
      ...state,
      [refined.name]: refined.value,
    }))
    .with({ kind: 'EDIT_POSE_IN_FORM' }, refined => ({
      ...state,
      inputName: refined.selected.name,
      inputX: refined.selected.x.toString(),
      inputY: refined.selected.y.toString(),
      inputZ: refined.selected.z.toString(),
      inputRX: refined.selected.rx.toString(),
      inputRY: refined.selected.ry.toString(),
      inputRZ: refined.selected.rz.toString(),
    }))
    .with({ kind: 'ADD_POSE' }, refined => ({
      ...state,
      selectedPoses: [...state.selectedPoses, refined.pose],
    }))
    .with({ kind: 'EDIT_POSE_IN_LIST' }, refined => ({
      ...state,
      selectedPoses: state.selectedPoses.map((pose, i) =>
        i === refined.index ? refined.pose : pose
      ),
    }))
    .with({ kind: 'DELETE_POSE' }, refined => ({
      ...state,
      selectedPoses: state.selectedPoses.filter((_, i) => i !== refined.index),
    }))
    .exhaustive();
