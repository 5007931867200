import { useEffect } from 'react';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import HiveOutlinedIcon from '@mui/icons-material/HiveOutlined';
import ForestIcon from '@mui/icons-material/Forest';
import { useOktaAuth } from '@okta/okta-react';

import { Box, Card, Link, Text } from '@nike/eds';

import { talosPyWebViewClient } from '../api-client/talos-py-web-view-client';

const supportedApplications = [
  {
    id: 'nectar',
    icon: <HiveOutlinedIcon fontSize="large" />,
    name: 'Nectar',
    description: 'Print with Nectar',
  },
  {
    id: 'siping',
    icon: <ContentCutOutlinedIcon fontSize="large" />,
    name: 'Siping',
    description: 'Sipe midsole',
  },
  {
    id: 'calibrate',
    icon: <CameraAltOutlinedIcon fontSize="large" />,
    name: 'Calibrate',
    description: 'Calibrate ...',
  },
  {
    id: 'calibrateCamera',
    icon: <CameraAltOutlinedIcon fontSize="large" />,
    name: 'Calibrate Camera',
    description: 'Calibrate Camera',
  },
  {
    id: 'forrest',
    icon: <ForestIcon fontSize="large" />,
    name: 'Forrest',
    description: 'Forrest',
  },
];

export function ApplicationIndexPage() {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();

  useEffect(() => {
    if (token) {
      talosPyWebViewClient.setUserToken(token);
    }
  }, [token]);

  return (
    <Box>
      <Text font="title-3" as="h1" className="eds-spacing--mb-24">
        Applications
      </Text>

      <Box className="eds-grid eds-grid--l-cols-3 eds-gap--16">
        {supportedApplications.map(app => (
          <Card key={app.id}>
            <Box className="eds-flex eds-flex--align-items-center eds-gap--8 eds-spacing--mb-12">
              {app.icon}

              <Text font="title-3" as="h2">
                <Link href={`/applications/${app.id}`}>{app.name}</Link>
              </Text>
            </Box>

            <Text font="body-2" className="eds-spacing--mb-12">
              {app.description}
            </Text>
          </Card>
        ))}
      </Box>
    </Box>
  );
}
