import { Tag } from '@nike/eds';
import { ShoeSide } from '@nike.innovation/talos-core';

const shoeSideToColor: Record<
  ShoeSide,
  'teal' | 'blue' | 'green' | 'yellow' | 'pink' | 'orange' | 'purple'
> = {
  Left: 'pink',
  Right: 'purple',
};

export function ShoeSideTag({ shoeSide }: { shoeSide: ShoeSide }) {
  return (
    <Tag color={shoeSideToColor[shoeSide]} size="small">
      {shoeSide}
    </Tag>
  );
}
