import { Experiment } from '@nike.innovation/talos-core';
import { Card, Text, Table, TableHeading, TableCell } from '@nike/eds';

export function TreatmentTable({ experiment }: { experiment: Experiment }) {
  const sortedTreatments = [...experiment.treatmentTable].sort(
    (a, b) => parseInt(a.treatmentId, 10) - parseInt(b.treatmentId, 10)
  );
  const headers = ['Treatment #', ...Object.keys(experiment.treatmentTable[0].levels), '# Samples'];

  return (
    <Card className="eds-elevation--2 eds-spacing--mb-24">
      <Text font="title-4" className="eds-spacing--mb-16">
        Treatment Table
      </Text>
      <Table width="100%">
        <thead>
          <tr>
            {headers.map(column => (
              <TableHeading key={column}>{column}</TableHeading>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedTreatments.map(treatment => (
            <tr key={treatment.treatmentId}>
              <TableCell>{treatment.treatmentId}</TableCell>
              {Object.entries(treatment.levels).map(([levelKey, level]) => (
                <TableCell key={`${treatment.treatmentId}-level-${levelKey}`}>{level}</TableCell>
              ))}
              <TableCell>{treatment.numSamples}</TableCell>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}
