import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { Box, VerticalNavbar, Icon, setActiveValueInNavItems, NavItem, NavEvent } from '@nike/eds';

const applicationDefault = {
  id: 'applications',
  icon: 'Shoe',
  label: 'Applications',
  active: true,
};

const dashboardDefault = {
  id: 'dashboard',
  icon: 'Analytics',
  label: 'Dashboard',
  active: false,
};

const experimentDefault = {
  id: 'experiments',
  icon: 'Experiments',
  label: 'Experiments',
  active: false,
};

export function VerticalNav() {
  const navigate = useNavigate();
  const location = useLocation();

  const [topItems, setTopItems] = useState<NavItem[]>([
    applicationDefault,
    dashboardDefault,
    experimentDefault,
  ]);

  useEffect(() => {
    const path = location.pathname;

    // Avoid edge case for root path
    if (path !== '/') {
      const newItems = topItems.map(item => ({
        ...item,
        active: path.startsWith(`/${item.id}`),
      }));
      setTopItems(newItems);
    }
  }, [location.pathname]);

  const onNavigate = (item: NavEvent) => {
    const newItems = setActiveValueInNavItems(item.id, topItems);
    if (newItems !== undefined) {
      setTopItems(newItems);
    }

    switch (item.id) {
      case 'applications':
        navigate('/');
        break;
      case 'commands':
        navigate('/commands');
        break;
      case 'dashboard':
        navigate('/dashboard');
        break;
      case 'experiments':
        navigate('/experiments');
        break;
      default:
        navigate('/');
    }
  };

  return (
    <Box>
      <VerticalNavbar
        appName="Talos"
        items={topItems}
        onNavigate={onNavigate}
        navLogoSlot={
          <Link to="/" onClick={() => onNavigate(applicationDefault)}>
            <Icon name="NikeSwoosh" size="l" />
          </Link>
        }
      />
    </Box>
  );
}
