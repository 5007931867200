import useSWRInfinite from 'swr/infinite';
import { environment } from '../../environments/environment';

export type MetricQueryResponse = {
  result: {
    cellId: string;
    appId: string;
    timestamp: number;
    phaseId: string;
    metrics: Record<string, unknown>;
  }[];
  last?: number;
  count: number;
};

/**
 * React hook for
 * @param assetId
 * @param versionId
 * @param token
 * @returns
 */
export function useCellMetrics(
  cellId: string,
  applicationId: string,
  limit: number,
  token: string
) {
  const fetcher = async (key: string) => {
    const response = await fetch(`${environment.dataApiBaseUrl}/${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
      throw await response.json();
    }

    const solves = (await response.json()) as MetricQueryResponse;
    return solves;
  };

  // passing in a pageIndex purely as a trigger for the new request of solves
  const getKey = (pageIndex: number, previousPageData: MetricQueryResponse) => {
    if (previousPageData && !previousPageData) return null;

    // Check for first page, otherwise use the last to get the new solves
    const fromFlag =
      pageIndex > 0 && previousPageData.last ? `&fromTime=${previousPageData.last}` : '';

    return `api/v1/cells/${cellId}/applications/${applicationId}?limit=${limit}${fromFlag}`;
  };

  const { data, mutate, size, setSize, error } = useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: false,
  });

  console.log('data', data);

  // Data returned from SWRInfinite is an array typically in page format,
  // we need to flatten that data in the results for make one page
  const accumulatedMetrics = data?.reduce(
    (accum, curr) => ({
      result: [...accum.result, ...curr.result],
      last: curr.last,
      count: curr.count,
    }),
    { result: [], count: 10, last: 0 }
  ) || { result: [], count: 10, last: 0 };

  console.log('accumulatedMetrics', accumulatedMetrics);

  return {
    metrics: accumulatedMetrics,
    metricsLoading: !error && !accumulatedMetrics,
    metricsError: error,
    resultMutate: mutate,
    setMetricsPage: setSize,
    metricsPage: size,
  };
}

export default useCellMetrics;
