import { Device } from '../command/command-types';

export const deepEqual = (x: any, y: any): boolean => {
  if (x === y) {
    return true;
  }
  if (typeof x === 'object' && x != null && typeof y === 'object' && y != null) {
    if (Object.keys(x).length !== Object.keys(y).length) return false;

    return Object.keys(x).every(prop => {
      if (Object.prototype.hasOwnProperty.call(y, prop)) {
        return deepEqual(x[prop], y[prop]);
      }
      return false;
    });
  }
  return false;
};

export function distinctByKind(...lists: Device[][]): Device[] {
  const allItems = lists.reduce((all, list) => [...all, ...list], []);
  return Array.from(new Set(allItems.map(item => item.kind))).map(
    kind => allItems.find(item => item.kind === kind)!
  );
}
