import { useReducer, useState } from 'react';
import ReactGA from 'react-ga4';
import useSWR from 'swr';

import StopRounded from '@mui/icons-material/StopRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { useOktaAuth } from '@okta/okta-react';

import { Box, Button, ButtonGroup, Snack, Snackbar, Spinner, Text } from '@nike/eds';
import { Loading } from '@nike.innovation/ui-components';

import { talosPyWebViewClient } from '../../api-client/talos-py-web-view-client';
import { environment } from '../../../environments/environment';
import { commandPageReducer, getInitialCommandPageState } from '../../command/command-page-reducer';
import { DeviceCard } from '../../device/device-card';
import { Device, Pose, Tool } from '../../command/command-types';
import { CalibrateImage } from './calibrate-image';
import { Point } from './calibrate-types';

const poses = {
  siping: [
    [
      [0, 0, 0],
      [1, 0, 0, 0],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [0, 0, 0],
      [1, 0, 0, 0],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
  ] as Pose[],
};

export function CalibratePage() {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();

  if (!token) {
    throw new Error('Error retrieving access token');
  }

  const [leftAnchor, setLeftAnchor] = useState<Point | undefined>();
  const [rightAnchor, setRightAnchor] = useState<Point | undefined>();

  const initialState = getInitialCommandPageState();
  const [commandPageState, dispatch] = useReducer(commandPageReducer, initialState);

  const isPywebviewAvailable = environment.useMockServer || (window as any).pywebview !== undefined;
  const [imageData, setImageData] = useState<[string, string] | undefined>();

  const { data: devices } = useSWR(
    `applications/calibrate/devices`,
    talosPyWebViewClient.calibrateDevices,
    {
      refreshInterval: 2000,
    }
  );
  const areDevicesReady = (deviceArray: Device[]): boolean =>
    deviceArray.length > 0 && deviceArray.filter(device => device.status !== 'ready').length === 0;

  return (
    <Box>
      <Box className="eds-flex eds-flex--justify-content-space-between eds-spacing--mb-24">
        <Text font="title-1" as="h1">
          Calibrate
        </Text>
      </Box>

      <Snackbar>
        {commandPageState.showSnack && (
          <Snack
            id="1"
            status={commandPageState.status}
            onDismiss={() => dispatch({ kind: 'TOGGLE_SNACK', newShowSnack: false })}
          >
            <Text>{commandPageState.statusText}</Text>
          </Snack>
        )}
      </Snackbar>

      {isPywebviewAvailable ? (
        <Box>
          {!imageData ? (
            <Box>
              {devices && (
                <>
                  <Box className="eds-flex eds-gap--16 eds-spacing--mb-32">
                    {devices.map(device => (
                      <DeviceCard device={device} key={device.kind} />
                    ))}
                  </Box>

                  <ButtonGroup>
                    <Button
                      beforeSlot={<PlayArrowRoundedIcon />}
                      disabled={!areDevicesReady(devices) || !!commandPageState.runningCommandId}
                      onClick={async () => {
                        dispatch({ kind: 'START_COMMAND', commandId: 'Calibrate' });

                        try {
                          const image = await talosPyWebViewClient.captureImage(
                            token,
                            poses.siping,
                            Tool.blade
                          );

                          dispatch({ kind: 'END_COMMAND', response: true });
                          setImageData(image);
                          ReactGA.event({ category: 'calibration', action: "'Start' clicked" });
                        } catch {
                          dispatch({ kind: 'END_COMMAND', response: false });
                        }
                      }}
                    >
                      {!commandPageState.runningCommandId ? 'Start' : <Spinner />}
                    </Button>

                    <Button
                      variant="secondary"
                      beforeSlot={<StopRounded />}
                      disabled={commandPageState.runningCommandId !== 'Calibrate'}
                      onClick={() => {
                        talosPyWebViewClient.stopCommand();
                      }}
                    >
                      Stop
                    </Button>
                  </ButtonGroup>
                </>
              )}
            </Box>
          ) : (
            <Box>
              <Box className="eds-flex eds-gap--16 eds-spacing--mb-12">
                <CalibrateImage
                  imageData={imageData[0]}
                  anchor={leftAnchor}
                  setAnchor={setLeftAnchor}
                />

                <CalibrateImage
                  imageData={imageData[1]}
                  anchor={rightAnchor}
                  setAnchor={setRightAnchor}
                />
              </Box>

              <ButtonGroup>
                <Button variant="secondary">Cancel</Button>

                <Button
                  disabled={!leftAnchor || !rightAnchor}
                  onClick={() => {
                    ReactGA.event({ category: 'calibration', action: "'Calibrate' clicked" });
                  }}
                >
                  Calibrate
                </Button>
              </ButtonGroup>
            </Box>
          )}
        </Box>
      ) : (
        <Loading />
      )}
    </Box>
  );
}
