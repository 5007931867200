import { Box } from '@nike/eds';

import { Device } from '../../command/command-types';
import { DeviceCard } from '../../device/device-card';

export function SipingDevices({ devices }: { devices: Device[] }) {
  return (
    <Box className="eds-flex eds-gap--16 eds-spacing--mb-24">
      {devices.map(device => (
        <DeviceCard device={device} key={device.kind} />
      ))}
    </Box>
  );
}
