import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';

import { Box, Text, TextField, Button, Spinner } from '@nike/eds';
import { useOktaAuth } from '@okta/okta-react';

import { environment } from '../../../environments/environment';

export function NewBiomechanicsForm() {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();

  const oktaToken = oktaAuth.getAccessToken();
  if (!oktaToken) {
    throw new Error('No okta token');
  }

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${oktaToken}`,
      'Content-Type': 'multipart/form-data',
    },
  };

  const [formData, setFormData] = useState({
    name: '',
    kineticsFile: null,
    kinematicsFile: null,
    metadataFile: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (field: string, value: any) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleFileChange = (field: string, file: File) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: file,
    }));
  };

  const submit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    setIsLoading(true);

    const data = new FormData();
    data.append('name', formData.name);
    if (formData.kineticsFile) data.append('kineticsFile', formData.kineticsFile);
    if (formData.kinematicsFile) data.append('kinematicsFile', formData.kinematicsFile);
    if (formData.metadataFile) data.append('metadataFile', formData.metadataFile);

    const response = await axios.post(
      `${environment.dataApiBaseUrl}/api/v1/biomechanics/new`,
      data,
      axiosConfig
    );

    if (response.status === 200) {
      navigate('/applications/biomechanics');
    }
    setIsLoading(false);
  };

  const isFormComplete: boolean =
    formData.name.trim() !== '' &&
    formData.kineticsFile !== null &&
    formData.kinematicsFile !== null &&
    formData.metadataFile !== null;

  return (
    <Box>
      <Text font="title-1" as="h1" className="eds-spacing--mb-32">
        Upload Biomechanics Data
      </Text>

      <Box className="eds-flex eds-flex--direction-column eds-gap--32">
        <TextField
          id="name"
          label="Name"
          value={formData.name}
          onChange={e => handleChange('name', e.target.value)}
          required
        />
        <Box>
          <TextField
            id="kineticsFile"
            label="Kinetics File"
            type="file"
            onChange={e => e.target.files && handleFileChange('kineticsFile', e.target.files[0])}
            value={formData.name}
            required
          />
        </Box>
        <Box>
          <TextField
            id="kinematicsFile"
            label="Kinematics File"
            type="file"
            onChange={e => e.target.files && handleFileChange('kinematicsFile', e.target.files[0])}
            value={formData.name}
            required
          />
        </Box>
        <Box>
          <TextField
            id="metadataFile"
            label="Metadata File"
            type="file"
            onChange={e => e.target.files && handleFileChange('metadataFile', e.target.files[0])}
            value={formData.name}
            required
          />
        </Box>

        <Button type="submit" disabled={!isFormComplete || isLoading} onClick={submit}>
          {isLoading ? <Spinner /> : 'Submit'}
        </Button>
      </Box>
    </Box>
  );
}
