import { useState, useReducer, useEffect } from 'react';
import useSWR from 'swr';

import StopRounded from '@mui/icons-material/StopRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import {
  Box,
  Button,
  ButtonGroup,
  Snack,
  Snackbar,
  Text,
  Spinner,
  Icon,
  Label,
  Toggle,
} from '@nike/eds';
import { useOktaAuth } from '@okta/okta-react';

import { Loading } from '@nike.innovation/ui-components';
import { SampleConfiguration } from '@nike.innovation/talos-core';
import { PointCloudMetadata } from '@nike.innovation/visualizer';

import { environment } from '../../../environments/environment';
import { DeviceCard } from '../../device/device-card';
import { Device } from '../../command/command-types';
import { talosPyWebViewClient } from '../../api-client/talos-py-web-view-client';
import {
  CommandPageState,
  commandPageReducer,
  getInitialCommandPageState,
} from '../../command/command-page-reducer';
import { PCDModal } from '../point-cloud/pcd-modal';

import { getFilenameFromPath } from '../../core/file-path';
import { ScanSection } from '../scan/scan-section';
import { KeyenceConfigurationForm } from '../../configuration/configuration-types';
import { ScanPoseForm } from '../../scan-pose/scan-poses-reducer';

const keyenceConfigurationForm: KeyenceConfigurationForm = {
  cloudConfig: {
    cropBox: {
      sizeX: '2000',
      sizeY: '2000',
      sizeZ: '2000',
      x: '-1000',
      y: '-1000',
      z: '-1000',
    },
    filtering: {
      downsample: true,
      clean: true,
      segment: true,
      voxelSize: '0.5',
      nbNeighbors: '20',
      stdRatio: '5',
      eps: '1.5',
      minPoints: '10',
    },
    translation: {
      invert: true,
      x: '50',
      y: '-100',
      z: '70',
    },
    other: {
      delayComp: '-0.6',
      minCloudSize: '1000',
    },
  },
  position: {
    length: '500',
    rate: '100',
    speed: '50',
  },
};

const defaultKeyenceConfiguration = {
  cloudConfig: {
    cropBox: {
      sizeX: 250,
      sizeY: 385,
      sizeZ: 200,
      x: -70,
      y: -10,
      z: -10,
    },
    filtering: {
      downsample: true,
      clean: true,
      segment: true,
      voxelSize: 0.5,
      nbNeighbors: 20,
      stdRatio: 5,
      eps: 1.5,
      minPoints: 10,
    },
    translation: {
      invert: true,
      x: 50,
      y: -12.5,
      z: 100,
    },
    other: {
      delayComp: -0.6,
      minCloudSize: 1000,
    },
  },
  position: {
    length: 500,
    rate: 100,
    speed: 50,
  },
};

const scanPoseForm: ScanPoseForm = {
  inputName: '',
  inputX: '0',
  inputY: '0',
  inputZ: '0',
  inputRX: '0',
  inputRY: '0',
  inputRZ: '0',
  selectedPoses: [],
};

function distinctByKind(...lists: Device[][]): Device[] {
  const allItems = lists.reduce((all, list) => [...all, ...list], []);
  return Array.from(new Set(allItems.map(item => item.kind))).map(
    kind => allItems.find(item => item.kind === kind)!
  );
}

export function NectarPage() {
  const { data: nectarDevices } = useSWR(
    `applications/nectar/devices`,
    talosPyWebViewClient.nectarDevices,
    {
      refreshInterval: 1200,
    }
  );
  const { data: scanDevices } = useSWR(
    `applications/scan/devices`,
    talosPyWebViewClient.scanDevices,
    {
      refreshInterval: 1200,
    }
  );

  const { data: logSignal } = useSWR(
    `applications/nectar/logSignal`,
    talosPyWebViewClient.getLoggingSignal,
    {
      refreshInterval: 2000,
    }
  );

  const [selectedMachineCode, setSelectedMachineCode] = useState<string>('');
  const initialState: CommandPageState = getInitialCommandPageState();
  const [commandPageState, dispatch] = useReducer(commandPageReducer, initialState);
  const [isGoHomeInUse, setIsGoHomeInUse] = useState(false);
  const [pcdModalVis, setPcdModalVis] = useState(false);
  const [pcdMetadata, setPcdMetadata] = useState<PointCloudMetadata[]>([]);

  const isPywebviewAvailable = environment.useMockServer || (window as any).pywebview !== undefined;

  const updateShowPcd = (metadata: PointCloudMetadata[]) => {
    setPcdMetadata(metadata);
    setPcdModalVis(true);
  };

  const areDevicesReady = (deviceArray: Device[]): boolean =>
    deviceArray.filter(device => device.status !== 'ready').length === 0;

  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();

  if (!token) {
    throw new Error('Error retrieving access token');
  }

  const emptySampleConfig: SampleConfiguration = {
    program: null,
    experimentId: null,
    sampleId: null,
  };

  useEffect(() => {
    if (token) {
      talosPyWebViewClient.setUserToken(token);
    }
  }, [token]);

  const allDevices = distinctByKind(scanDevices || [], nectarDevices || []);

  return (
    <Box>
      <PCDModal metadata={pcdMetadata} pcdModalVis={pcdModalVis} setPcdModalVis={setPcdModalVis} />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text font="title-1" className="eds-spacing--mb-24">
          Nectar
        </Text>

        <Button
          variant="secondary"
          className="eds-spacing--mb-16"
          beforeSlot={<Icon name="HomeFilled" size="s" />}
          disabled={
            commandPageState.runningCommandId === 'Scan' ||
            commandPageState.runningCommandId === 'Print'
          }
          onClick={async () => {
            setIsGoHomeInUse(true);
            await talosPyWebViewClient.goHome();
            setIsGoHomeInUse(false);
          }}
        >
          Go Home
        </Button>
      </div>

      <Box className="eds-flex eds-gap--16 eds-spacing--mb-24">
        {allDevices.map(device => (
          <DeviceCard device={device} key={device.kind} />
        ))}
      </Box>

      <Snackbar>
        {commandPageState.showSnack && (
          <Snack
            id="1"
            status={commandPageState.status}
            onDismiss={() => dispatch({ kind: 'TOGGLE_SNACK', newShowSnack: false })}
          >
            <Text>{commandPageState.statusText}</Text>
          </Snack>
        )}
      </Snackbar>

      <Box className="eds-spacing--mb-32">
        {isPywebviewAvailable ? (
          <Box>
            <ScanSection
              commandPageState={commandPageState}
              dispatch={dispatch}
              token={token}
              isPywebviewAvailable={isPywebviewAvailable}
              scanDevices={scanDevices}
              isGoHomeInUse={isGoHomeInUse}
              updateShowPcd={updateShowPcd}
              sampleConfig={emptySampleConfig}
            />
          </Box>
        ) : (
          <Loading />
        )}
      </Box>

      <Box className="eds-grid eds-grid--m-cols-2">
        <Box className="eds-grid--m-col-2">
          <Text font="title-2" className="eds-spacing--mb-16">
            Print
          </Text>

          {isPywebviewAvailable ? (
            <Box>
              <Box>
                <Label font="body-2" htmlFor="machineCodeButton" className="eds-spacing--mb-8">
                  Select machine code
                </Label>

                <Box className="eds-flex eds-flex--align-items-center eds-gap--16 eds-spacing--mb-16">
                  <Button
                    id="machineCodeButton"
                    variant="secondary"
                    beforeSlot={<Icon name="Upload" size="s" />}
                    size="small"
                    onClick={async () => {
                      const result = await talosPyWebViewClient.selectSourcePath();
                      setSelectedMachineCode(result);
                    }}
                  >
                    Choose file
                  </Button>

                  <Text font="body-3">
                    {selectedMachineCode === ''
                      ? 'no file selected'
                      : getFilenameFromPath(selectedMachineCode)}
                  </Text>
                </Box>
              </Box>

              <ButtonGroup>
                <Button
                  beforeSlot={<PlayArrowRoundedIcon />}
                  disabled={
                    !areDevicesReady(nectarDevices || []) ||
                    !!commandPageState.runningCommandId ||
                    selectedMachineCode === '' ||
                    isGoHomeInUse
                  }
                  onClick={() => {
                    dispatch({ kind: 'START_COMMAND', commandId: 'Nectar' });
                    talosPyWebViewClient.nectar(selectedMachineCode, token).then(
                      response => {
                        dispatch({ kind: 'END_COMMAND', response });
                      },
                      () => {
                        dispatch({ kind: 'END_COMMAND', response: false });
                      }
                    );
                  }}
                >
                  {!commandPageState.runningCommandId ? 'Print' : <Spinner />}
                </Button>

                <Button
                  variant="secondary"
                  beforeSlot={<StopRounded />}
                  disabled={commandPageState.runningCommandId !== 'Nectar'}
                  onClick={() => {
                    talosPyWebViewClient.stopCommand();
                  }}
                >
                  Stop
                </Button>
              </ButtonGroup>
            </Box>
          ) : (
            <Loading />
          )}
        </Box>

        <Box className="eds-grid--m-col-2">
          <Text font="title-2" className="eds-spacing--mb-16">
            Log
          </Text>

          {isPywebviewAvailable ? (
            <Box>
              <div style={{ width: 'fit-content' }}>
                <Toggle
                  id="setLoggingSignalToggle"
                  label="Log"
                  size="large"
                  checked={logSignal}
                  onChange={e => {
                    talosPyWebViewClient.logWithoutRobot(oktaAuth.getAccessToken() || '');
                  }}
                />
              </div>
            </Box>
          ) : (
            <Loading />
          )}
        </Box>
      </Box>
    </Box>
  );
}
