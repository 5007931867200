import { useState, Dispatch } from 'react';
import { Box, Text, Label, Button, ButtonGroup, Icon, Spinner } from '@nike/eds';
import { Loading } from '@nike.innovation/ui-components';
import { SampleConfiguration } from '@nike.innovation/talos-core';
import StopRounded from '@mui/icons-material/StopRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

import { talosPyWebViewClient } from '../../api-client/talos-py-web-view-client';
import { getFilenameFromPath } from '../../core/file-path';
import { CommandPageAction, CommandPageState } from '../../command/command-page-reducer';
import { Device } from '../../command/command-types';

export function SipingSipeSection({
  commandPageState,
  dispatch,
  token,
  isPywebviewAvailable,
  sipingDevices,
  isGoHomeInUse,
  sampleConfig,
}: {
  commandPageState: CommandPageState;
  dispatch: Dispatch<CommandPageAction>;
  token: string;
  isPywebviewAvailable: boolean;
  sipingDevices: Device[] | undefined;
  isGoHomeInUse: boolean;
  sampleConfig: SampleConfiguration;
}) {
  const [selectedMachineCode, setSelectedMachineCode] = useState<string>('');

  const areDevicesReady = (deviceArray: Device[]): boolean =>
    deviceArray.filter(device => device.status !== 'ready').length === 0;
  const areSipingDevicesReady = areDevicesReady(sipingDevices || []);

  return (
    <Box className="eds-grid eds-grid--m-cols-2">
      <Box className="eds-grid--m-col-2">
        <Text font="title-2" className="eds-spacing--mb-16">
          Sipe
        </Text>

        {isPywebviewAvailable ? (
          <Box>
            <Box>
              <Label font="body-2" htmlFor="machineCodeButton" className="eds-spacing--mb-8">
                Select machine code
              </Label>

              <Box className="eds-flex eds-flex--align-items-center eds-gap--16 eds-spacing--mb-16">
                <Button
                  id="machineCodeButton"
                  variant="secondary"
                  beforeSlot={<Icon name="Upload" size="s" />}
                  size="small"
                  onClick={async () => {
                    const result = await talosPyWebViewClient.selectSourcePath();
                    setSelectedMachineCode(result);
                  }}
                >
                  Choose file
                </Button>

                <Text font="body-3">
                  {selectedMachineCode === ''
                    ? 'no file selected'
                    : getFilenameFromPath(selectedMachineCode)}
                </Text>
              </Box>
            </Box>

            <ButtonGroup>
              <Button
                beforeSlot={<PlayArrowRoundedIcon />}
                disabled={
                  !areSipingDevicesReady ||
                  !!commandPageState.runningCommandId ||
                  selectedMachineCode === '' ||
                  isGoHomeInUse
                }
                onClick={() => {
                  dispatch({ kind: 'START_COMMAND', commandId: 'Siping' });
                  talosPyWebViewClient.sipe(selectedMachineCode, token, sampleConfig).then(
                    response => {
                      dispatch({ kind: 'END_COMMAND', response });
                    },
                    () => {
                      dispatch({ kind: 'END_COMMAND', response: false });
                    }
                  );
                }}
              >
                {!commandPageState.runningCommandId ? 'Sipe' : <Spinner />}
              </Button>

              <Button
                variant="secondary"
                beforeSlot={<StopRounded />}
                disabled={commandPageState.runningCommandId !== 'Siping'}
                onClick={() => {
                  talosPyWebViewClient.stopCommand();
                }}
              >
                Stop
              </Button>
              <Button
                variant="secondary"
                beforeSlot={<Icon name="ActivityStroke" size="m" />}
                disabled={
                  !areSipingDevicesReady || !!commandPageState.runningCommandId || isGoHomeInUse
                }
                onClick={() => {
                  dispatch({ kind: 'START_COMMAND', commandId: 'TestKnife' });
                  talosPyWebViewClient.testKnife().then(
                    response => {
                      dispatch({ kind: 'END_COMMAND', response });
                    },
                    () => {
                      dispatch({ kind: 'END_COMMAND', response: false });
                    }
                  );
                }}
              >
                Test Knife
              </Button>
            </ButtonGroup>
          </Box>
        ) : (
          <Loading />
        )}
      </Box>
    </Box>
  );
}
