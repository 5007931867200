import { match } from 'ts-pattern';
import { StatusIconProtoProps } from '@nike/eds';

export type CommandPageState = {
  showSnack: boolean;
  status: StatusIconProtoProps['status'];
  statusText: string;
  runningCommandId: string | null;
};

export type CommandPageAction =
  | { kind: 'START_COMMAND'; commandId: string }
  | { kind: 'END_COMMAND'; response: any }
  | { kind: 'TOGGLE_SNACK'; newShowSnack: boolean };

export const getInitialCommandPageState = (): CommandPageState => ({
  showSnack: false,
  status: 'info',
  statusText: '',
  runningCommandId: null,
});

export const commandPageReducer = (
  state: CommandPageState,
  action: CommandPageAction
): CommandPageState =>
  match(action)
    .with({ kind: 'START_COMMAND' }, refined => ({
      showSnack: true,
      status: 'info' as const,
      statusText: 'Running...',
      runningCommandId: refined.commandId,
    }))
    .with({ kind: 'END_COMMAND' }, refined => ({
      ...state,
      status: refined.response ? ('success' as const) : ('error' as const),
      statusText: refined.response ? 'Success' : 'Failure',
      runningCommandId: null,
    }))
    .with({ kind: 'TOGGLE_SNACK' }, refined => ({
      ...state,
      showSnack: refined.newShowSnack,
    }))
    .exhaustive();
