import { useReducer, useState } from 'react';
import useSWR from 'swr';

import StopRounded from '@mui/icons-material/StopRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { useOktaAuth } from '@okta/okta-react';

import { Box, Button, ButtonGroup, Checkbox, Snack, Snackbar, Spinner, Text } from '@nike/eds';
import { Loading } from '@nike.innovation/ui-components';

import { talosPyWebViewClient } from '../../api-client/talos-py-web-view-client';
import { environment } from '../../../environments/environment';
import { commandPageReducer, getInitialCommandPageState } from '../../command/command-page-reducer';
import { DeviceCard } from '../../device/device-card';
import { Device } from '../../command/command-types';
import { calibrateCameraPoses } from './poses';

export function CalibrateCameraPage() {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();

  if (!token) {
    throw new Error('Error retrieving access token');
  }

  const initialState = getInitialCommandPageState();
  const [commandPageState, dispatch] = useReducer(commandPageReducer, initialState);

  const [calibrateFull, setCalibrateFull] = useState(false);

  const isPywebviewAvailable = environment.useMockServer || (window as any).pywebview !== undefined;

  const { data: devices } = useSWR(
    `applications/calibrate/devices`,
    talosPyWebViewClient.calibrateCameraDevices,
    {
      refreshInterval: 2000,
    }
  );
  const areDevicesReady = (deviceArray: Device[]): boolean =>
    deviceArray.length > 0 && deviceArray.filter(device => device.status !== 'ready').length === 0;

  return (
    <Box>
      <Box className="eds-flex eds-flex--justify-content-space-between eds-spacing--mb-24">
        <Text font="title-1" as="h1">
          Calibrate Camera
        </Text>
      </Box>

      <Snackbar>
        {commandPageState.showSnack && (
          <Snack
            id="1"
            status={commandPageState.status}
            onDismiss={() => dispatch({ kind: 'TOGGLE_SNACK', newShowSnack: false })}
          >
            <Text>{commandPageState.statusText}</Text>
          </Snack>
        )}
      </Snackbar>

      {isPywebviewAvailable ? (
        <Box>
          {devices && (
            <>
              <Box className="eds-flex eds-gap--16 eds-spacing--mb-32">
                {devices.map(device => (
                  <DeviceCard device={device} key={device.kind} />
                ))}
              </Box>

              <Box className="eds-spacing--mb-32">
                <Text font="title-2" className="eds-spacing--mb-16">
                  Extrinsics
                </Text>
                <ButtonGroup>
                  <Button
                    beforeSlot={<PlayArrowRoundedIcon />}
                    disabled={!areDevicesReady(devices) || !!commandPageState.runningCommandId}
                    onClick={async () => {
                      dispatch({ kind: 'START_COMMAND', commandId: 'CalibrateCamera' });

                      try {
                        await talosPyWebViewClient.calibrateCamera(
                          token,
                          calibrateCameraPoses.poses,
                          calibrateFull,
                          true
                        );

                        dispatch({ kind: 'END_COMMAND', response: true });
                      } catch {
                        dispatch({ kind: 'END_COMMAND', response: false });
                      }
                    }}
                  >
                    {!commandPageState.runningCommandId ? 'Calibrate Extrinsics' : <Spinner />}
                  </Button>

                  <Button
                    variant="secondary"
                    beforeSlot={<StopRounded />}
                    disabled={commandPageState.runningCommandId !== 'Calibrate'}
                    onClick={() => {
                      talosPyWebViewClient.stopCommand();
                    }}
                  >
                    Stop
                  </Button>

                  <Checkbox
                    id="intrinsics"
                    label="Calculate intrinsics at the same time"
                    disabled={!areDevicesReady(devices) || !!commandPageState.runningCommandId}
                    checked={calibrateFull}
                    onChange={e => setCalibrateFull(e.target.checked)}
                  />
                </ButtonGroup>
              </Box>

              <Box>
                <Text font="title-2" className="eds-spacing--mb-16">
                  Intrinsics
                </Text>
                <ButtonGroup>
                  <Button
                    beforeSlot={<PlayArrowRoundedIcon />}
                    disabled={!areDevicesReady(devices) || !!commandPageState.runningCommandId}
                    onClick={async () => {
                      dispatch({ kind: 'START_COMMAND', commandId: 'CalibrateCamera' });

                      try {
                        await talosPyWebViewClient.calibrateCamera(
                          token,
                          calibrateCameraPoses.poses,
                          true,
                          false
                        );

                        dispatch({ kind: 'END_COMMAND', response: true });
                      } catch {
                        dispatch({ kind: 'END_COMMAND', response: false });
                      }
                    }}
                  >
                    {!commandPageState.runningCommandId ? 'Calibrate Intrinsics' : <Spinner />}
                  </Button>

                  <Button
                    variant="secondary"
                    beforeSlot={<StopRounded />}
                    disabled={commandPageState.runningCommandId !== 'Calibrate'}
                    onClick={() => {
                      talosPyWebViewClient.stopCommand();
                    }}
                  >
                    Stop
                  </Button>
                </ButtonGroup>
              </Box>
            </>
          )}
        </Box>
      ) : (
        <Loading />
      )}
    </Box>
  );
}
