import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';
import { MetricQueryResponse } from '@nike.innovation/talos-core';
import { environment } from '../../environments/environment';
import { KeyenceConfiguration } from '../configuration/configuration-types';

export function useSettingsClient() {
  const { oktaAuth } = useOktaAuth();

  const oktaToken = oktaAuth.getAccessToken();
  if (!oktaToken) {
    throw new Error('No okta token');
  }

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${oktaToken}`,
    },
  };

  return {
    fetchSetting:
      ({ namespace, key }: { namespace: string; key: string }) =>
      async (): Promise<KeyenceConfiguration> => {
        const { data } = await axios.get(
          `${environment.dataApiBaseUrl}/api/v1/settings/${namespace}/${key}`,
          axiosConfig
        );

        return data;
      },

    listSettings:
      ({ namespace }: { namespace: string }) =>
      async (): Promise<string[]> => {
        const { data } = await axios.get(
          `${environment.dataApiBaseUrl}/api/v1/settings/${namespace}`,
          axiosConfig
        );

        return data;
      },

    storeSetting: async ({
      namespace,
      key,
      settings,
    }: {
      namespace: string;
      key: string;
      settings: Record<string, unknown>;
    }): Promise<MetricQueryResponse> => {
      const { data } = await axios.post(
        `${environment.dataApiBaseUrl}/api/v1/settings/${namespace}/${key}`,
        settings,
        axiosConfig
      );

      return data;
    },
  };
}
