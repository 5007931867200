import { MetricFile } from './talos-core';

export type Program = 'Siping';

export interface Treatment {
  treatmentId: string;
  numSamples: number;
  levels: Record<string, string>;
}

export interface ResultFile {
  name: string;
  link: string;
}

export type ResponseSchema = string[];

export interface Experiment {
  program: Program;
  id: string;
  name: string;
  confluenceLink: string;
  treatmentTable: Treatment[];
  testResults: ResultFile[];
  responseSchema: ResponseSchema;
}

export type SampleResponse = Record<string, string>;

export enum SampleState {
  'Success' = 'Success',
  'Fail' = 'Fail',
  'TBD' = 'TBD',
}

export const sampleStates = Object.values(SampleState);

export interface MetricKeyPayload {
  cellId: string;
  timestamp: number;
  appId: string;
  phaseId: string;
}

export interface Sample {
  experimentId: string;
  sampleId: string;
  treatmentId: string;
  state: SampleState;
  response: SampleResponse;
  metricKeyPayload: MetricKeyPayload;
  metricFiles: MetricFile[];
}

export interface SampleConfiguration {
  program: string | null;
  experimentId: string | null;
  sampleId: string | null;
}
