import { Dispatch, useState, useEffect } from 'react';

import {
  Box,
  Text,
  TextField,
  Select,
  Button,
  IconButton,
  Table,
  TableHeading,
  TableCell,
  Skeleton,
} from '@nike/eds';
import { ScanPose } from '@nike.innovation/talos-core';
import { ScanPoseForm, ScanPoseAction } from './scan-poses-reducer';
import { SaveScanPose } from './save-scan-pose';
import useSavedScanPose from '../hooks/use-saved-scan-pose';
import { useScanPosesClient } from '../api-client/scan-poses-api-client';
import { getNonNumericFields } from '../configuration/utils';

export function ScanPoses({
  scanPoseForm,
  dispatch,
}: {
  scanPoseForm: ScanPoseForm;
  dispatch: Dispatch<ScanPoseAction>;
}) {
  const {
    saved: savedScanPoses,
    loading: scanPosesLoading,
    apiError: scanPosesError,
    create: createScanPose,
    creating: creatingScanPose,
  } = useSavedScanPose();

  const scanPosesClient = useScanPosesClient();
  const nonNumericFields = getNonNumericFields(scanPoseForm);
  const numberErrMsg = 'Only numbers are allowed';
  const [poseOptions, setPoseOptions] = useState(savedScanPoses);
  // index of the selected pose in the dropdown - different than selected because users can create and view poses without selecting them
  const [currentPoseOptionIndex, setCurrentPoseOptionIndex] = useState(-1);
  // index of the pose being currently edited if it is already in the selected poses table
  const [selectedPoseIndex, setSelectedPoseIndex] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const [poseToSaveIndex, setPoseToSaveIndex] = useState(-1);
  // manage poses that are created via dropdown but are not saved in S3
  const [localPoses, setLocalPoses] = useState<string[]>([]);

  // initial load of stored poses in S3 to populate dropdown
  useEffect(() => {
    if (!scanPosesLoading) {
      (async () => {
        const retrieved = await scanPosesClient.listScanPoses();
        const mappedPoses = retrieved.map((pose, index) => ({
          value: index,
          label: pose,
        }));
        setPoseOptions(mappedPoses);
      })();
    }
  }, []);

  const selectedPoseTableHeaders = [
    { Header: 'Pose Name' },
    { Header: 'X' },
    { Header: 'Y' },
    { Header: 'Z' },
    { Header: 'Rx' },
    { Header: 'Ry' },
    { Header: 'Rz' },
    { Header: 'Actions' },
  ];

  const isFormDisabled =
    scanPoseForm.selectedPoses.map(pose => pose.name).includes(scanPoseForm.inputName) ||
    scanPoseForm.inputName === '' ||
    Object.values(scanPoseForm).some(value => Number.isNaN(value));

  const handleSelectPose = async (poseName: string) => {
    if (poseName === '') {
      return;
    }

    // if pose is selected but not stored in S3, populate with empty values. only the created name will persist for the session
    if (localPoses.includes(poseName)) {
      const emptyPose: ScanPose = {
        name: poseName,
        x: 0,
        y: 0,
        z: 0,
        rx: 0,
        ry: 0,
        rz: 0,
      };
      dispatch({
        kind: 'EDIT_POSE_IN_FORM',
        selected: emptyPose,
      });
      return;
    }

    const pose = await scanPosesClient.fetchScanPose(poseName);
    dispatch({
      kind: 'EDIT_POSE_IN_FORM',
      selected: pose,
    });
  };

  const handleOpenSaveModal = () => {
    setIsSaveModalVisible(true);
  };

  const handleCloseSaveModal = () => {
    setIsSaveModalVisible(false);
  };

  // remove pose from localPoses if it is saved in S3
  const handleSuccessfulSave = (savedPoseName: string) => {
    const localIndex = localPoses.indexOf(savedPoseName);
    if (localIndex !== -1) {
      setLocalPoses(localPoses.filter((_, i) => i !== localIndex));
    }
  };
  return (
    <Box>
      <Text font="title-4" as="h5" className="eds-spacing--mb-16">
        Scan Poses
      </Text>
      <Box className="eds-grid eds-grid--s-cols-6">
        <Box className="eds-grid--col-2">
          {scanPosesLoading ? (
            <div style={{ width: '100%' }}>
              <Skeleton height={40} width="30%" className="eds-spacing--mb-8" />
            </div>
          ) : (
            <Select
              id="pose-name"
              required
              isCreatable
              label="Pose Name"
              subtitle="Select existing pose or create a new one"
              options={poseOptions}
              value={currentPoseOptionIndex === -1 ? null : poseOptions[currentPoseOptionIndex]}
              onCreateOption={newPoseName => {
                const nextIndex = poseOptions.length;
                setPoseOptions([...poseOptions, { value: nextIndex, label: newPoseName }]);
                setCurrentPoseOptionIndex(nextIndex);
                const newPose: ScanPose = {
                  name: newPoseName,
                  x: 0,
                  y: 0,
                  z: 0,
                  rx: 0,
                  ry: 0,
                  rz: 0,
                };
                dispatch({
                  kind: 'EDIT_POSE_IN_FORM',
                  selected: newPose,
                });
                setLocalPoses([...localPoses, newPoseName]);
              }}
              onChange={selected => {
                if (selected) {
                  handleSelectPose(selected.label);
                  setCurrentPoseOptionIndex(selected.value);
                }
              }}
            />
          )}
        </Box>
        <Box className="eds-grid--row-pos-2">
          <TextField
            id="pose-x"
            type="number"
            value={scanPoseForm.inputX}
            label="X"
            hasErrors={nonNumericFields.includes('inputX')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_POSE_COORDINATE',
                name: 'inputX',
                value: e.target.value,
              })
            }
          />
        </Box>
        <Box className="eds-grid--row-pos-2">
          <TextField
            id="pose-y"
            type="number"
            value={scanPoseForm.inputY}
            label="Y"
            hasErrors={nonNumericFields.includes('inputY')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_POSE_COORDINATE',
                name: 'inputY',
                value: e.target.value,
              })
            }
          />
        </Box>
        <Box className="eds-grid--row-pos-2">
          <TextField
            id="pose-z"
            type="number"
            value={scanPoseForm.inputZ}
            label="Z"
            hasErrors={nonNumericFields.includes('inputZ')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_POSE_COORDINATE',
                name: 'inputZ',
                value: e.target.value,
              })
            }
          />
        </Box>
        <Box className="eds-grid--row-pos-2">
          <TextField
            id="pose-rx"
            type="number"
            value={scanPoseForm.inputRX}
            label="Rx"
            hasErrors={nonNumericFields.includes('inputRX')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_POSE_COORDINATE',
                name: 'inputRX',
                value: e.target.value,
              })
            }
          />
        </Box>
        <Box className="eds-grid--row-pos-2">
          <TextField
            id="pose-ry"
            type="number"
            value={scanPoseForm.inputRY}
            label="Ry"
            hasErrors={nonNumericFields.includes('inputRY')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_POSE_COORDINATE',
                name: 'inputRY',
                value: e.target.value,
              })
            }
          />
        </Box>
        <Box className="eds-grid--row-pos-2">
          <TextField
            id="pose-rz"
            type="number"
            value={scanPoseForm.inputRZ}
            label="Rz"
            hasErrors={nonNumericFields.includes('inputRZ')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_POSE_COORDINATE',
                name: 'inputRZ',
                value: e.target.value,
              })
            }
          />
        </Box>
        <Box className="eds-grid--row-pos-3">
          <Button
            disabled={isEditing ? false : isFormDisabled}
            onClick={() => {
              const pose: ScanPose = {
                name: scanPoseForm.inputName,
                x: parseFloat(scanPoseForm.inputX),
                y: parseFloat(scanPoseForm.inputY),
                z: parseFloat(scanPoseForm.inputZ),
                rx: parseFloat(scanPoseForm.inputRX),
                ry: parseFloat(scanPoseForm.inputRY),
                rz: parseFloat(scanPoseForm.inputRZ),
              };
              if (isEditing) {
                dispatch({
                  kind: 'EDIT_POSE_IN_LIST',
                  index: selectedPoseIndex,
                  pose,
                });
                setIsEditing(false);
              } else {
                dispatch({
                  kind: 'ADD_POSE',
                  pose,
                });
                const nextIndex = scanPoseForm.selectedPoses.length;
                setSelectedPoseIndex(nextIndex);
              }
            }}
          >
            {isEditing ? 'Save' : 'Select'}
          </Button>
        </Box>
        <Box className="eds-grid--row-pos-4 eds-grid--col-full">
          <Text font="title-5" as="h5">
            Selected Poses
          </Text>
          <Table className="eds-spacing--mt-16">
            <thead>
              <tr>
                {selectedPoseTableHeaders.map(column => (
                  <TableHeading key={column.Header}>{column.Header}</TableHeading>
                ))}
              </tr>
            </thead>
            <tbody>
              {scanPoseForm.selectedPoses.map((pose, index) => (
                <tr key={pose.name}>
                  <TableCell>{pose.name}</TableCell>
                  <TableCell>{pose.x}</TableCell>
                  <TableCell>{pose.y}</TableCell>
                  <TableCell>{pose.z}</TableCell>
                  <TableCell>{pose.rx}</TableCell>
                  <TableCell>{pose.ry}</TableCell>
                  <TableCell>{pose.rz}</TableCell>
                  <TableCell>
                    <Box className="eds-flex eds-gap--16">
                      <IconButton
                        onClick={() => {
                          if (poseOptions[currentPoseOptionIndex].label !== pose.name) {
                            setCurrentPoseOptionIndex(
                              poseOptions.map(option => option.label).indexOf(pose.name)
                            );
                          }
                          setSelectedPoseIndex(index);
                          dispatch({
                            kind: 'EDIT_POSE_IN_FORM',
                            selected: pose,
                          });
                          setIsEditing(true);
                        }}
                        label="Edit Pose"
                        icon="Edit"
                      />
                      <IconButton
                        onClick={() => {
                          setPoseToSaveIndex(index);
                          handleOpenSaveModal();
                        }}
                        label="Save Pose"
                        icon="Upload"
                      />
                      <IconButton
                        onClick={() => {
                          dispatch({
                            kind: 'DELETE_POSE',
                            index,
                          });
                        }}
                        label="Delete Pose"
                        icon="Delete"
                      />
                    </Box>
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
        <SaveScanPose
          poseToSave={scanPoseForm.selectedPoses[poseToSaveIndex]}
          visible={isSaveModalVisible}
          createPose={createScanPose}
          onClose={handleCloseSaveModal}
          createStatus={{ loading: creatingScanPose, error: scanPosesError }}
          emitSuccessEvent={handleSuccessfulSave}
        />
      </Box>
    </Box>
  );
}
