import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';

import { Box, Link, Spinner, Text } from '@nike/eds';
import { Experiment, Sample, Treatment } from '@nike.innovation/talos-core';
import { environment } from '../../environments/environment';
import { ExperimentForm } from './experiment-form';
import { useExperiment } from '../hooks/use-experiment';
import { useSamples } from '../hooks/use-samples';

export function UpdateExperimentPage() {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();

  const oktaToken = oktaAuth.getAccessToken();
  if (!oktaToken) {
    throw new Error('No okta token');
  }

  const program = 'Siping';
  const { experimentId } = useParams();

  if (experimentId === undefined) {
    throw new Error('No experiment id');
  }

  const { experiment, isLoading: isExperimentLoading } = useExperiment(
    program,
    experimentId,
    oktaToken,
    0
  );

  const { samples, isLoading: isSamplesLoading } = useSamples(program, experimentId, oktaToken, 0);

  const experimentHeaders = Object.keys(experiment?.treatmentTable[0]?.levels || {});
  const initialLevels = (treatments: Treatment[]) => {
    const levels = treatments.map(treatment => [
      `${treatment.numSamples}`,
      ...Object.values(treatment.levels),
      ...Array.from({ length: 11 - experimentHeaders.length }, () => ''),
    ]);

    return [
      ...levels,
      ...Array.from({ length: 10 - levels.length }, () => Array.from({ length: 11 }, () => '')),
    ];
  };

  const onSubmit = async (updatedExperiment: Experiment, updatedSamples: Sample[]) => {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${oktaToken}`,
      },
    };

    const experimentResponse = await axios.put(
      `${environment.dataApiBaseUrl}/api/v1/experiments/${updatedExperiment.program}/${updatedExperiment.id}`,
      {
        ...updatedExperiment,
        samples: updatedSamples,
      },
      axiosConfig
    );

    if (experimentResponse.status === 200) {
      navigate(`/experiments/${experimentId}`);
    }
  };

  return (
    <Box>
      {(isExperimentLoading || isSamplesLoading) && <Spinner />}
      {experiment && samples && (
        <>
          <Box className="eds-spacing--mb-24">
            <Text font="title-5" as="h2">
              Edit experiment
            </Text>

            <Link href={`/experiments/${experiment.id}`} font="title-4">
              {program} / {experiment.name}
            </Link>
          </Box>

          <ExperimentForm
            initialTreatmentHeaders={experimentHeaders.concat(
              Array.from({ length: 10 - experimentHeaders.length }, () => '')
            )}
            initialTreatmentLevels={initialLevels(experiment.treatmentTable)}
            initialExperiment={experiment}
            initialSamples={samples}
            onSubmit={onSubmit}
            update
          />
        </>
      )}
    </Box>
  );
}
