import { Pose } from '../../command/command-types';

export const calibrateCameraPoses = {
  poses: [
    [
      [553.54, 245.39, 887.76],
      [0.61039, -0.01826, 0.055, -0.78998],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [593.64, 125.06, 837.57],
      [0.57366, 0.00756, 0.07931, -0.81251],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [538.38, 420.58, 750.76],
      [0.68023, -0.09027, 0.07678, -0.72336],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [236.16, 50.74, 966.71],
      [0.5386, 0.02607, 0.0451, -0.84095],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [279.8, 34.57, 863.54],
      [0.52991, 0.00407, 0.09318, -0.84291],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [325.74, -41.22, 827.44],
      [0.50082, 0.00383, 0.10472, -0.85918],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [323.32, 132.06, 811.31],
      [0.63426, -0.05984, 0.07047, -0.76757],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [307.45, 205.2, 887.09],
      [0.60982, -0.05058, 0.03554, -0.79013],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [361.27, 410.42, 988.47],
      [0.68346, -0.01014, 0.00028, -0.72992],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [553.54, 245.39, 887.76],
      [0.61039, -0.01826, 0.055, -0.78998],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [506.8, 503.01, 810.46],
      [0.73737, -0.06488, 0.03291, -0.67156],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [409.03, 523.83, 844.36],
      [0.73992, -0.05012, 0.04923, -0.66901],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [326.32, 620.65, 856.53],
      [0.75225, -0.04071, 0.06259, -0.65429],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [277.46, 422.2, 882.43],
      [0.70277, -0.04208, 0.04576, -0.7088],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
    [
      [229.68, 378.41, 938.58],
      [0.68313, 0.00623, 0.05595, -0.72813],
      [0, -1, 0, 0],
      [0, 9e9, 9e9, 9e9, 9e9, 9e9],
    ],
  ] as Pose[],
};
