import { Box, Text, Button, Card } from '@nike/eds';
import { WearTestsTable } from './wear-tests-table';

export function ForrestIndexPage() {
  return (
    <Box>
      <Box className="eds-flex eds-flex--justify-content-space-between eds-flex--align-items-center">
        <Text font="title-1" as="h1">
          Forrest
        </Text>

        <Button as="a" href="applications/forrest/create">
          Create test
        </Button>
      </Box>

      <Card className="eds-spacing--mt-32">
        <WearTestsTable />
      </Card>
    </Box>
  );
}
