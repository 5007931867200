import { ExperimentMetadata } from '@nike.innovation/talos-core';
import { Box, Card, Text, Link } from '@nike/eds';

export function ExperimentCardGrid({
  experiments,
  program,
}: {
  experiments: ExperimentMetadata[];
  program: string;
}) {
  return (
    <Box className="eds-grid eds-grid--l-cols-3 eds-gap--16">
      {experiments.map(experiment => (
        <Card key={experiment.id} id={experiment.id}>
          <Text font="title-3" className="eds-spacing--mb-8">
            <Link href={`/experiments/${experiment.id}`}>{experiment.name}</Link>
          </Text>

          <Text font="body-2" className="eds-spacing--mb-8">
            {program}
          </Text>
        </Card>
      ))}
    </Box>
  );
}
