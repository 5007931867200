import { Tag } from '@nike/eds';
import { Status } from '@nike.innovation/talos-core';

const statusToColor: Record<
  Status,
  'teal' | 'blue' | 'green' | 'yellow' | 'pink' | 'orange' | 'purple'
> = {
  'In Queue': 'teal',
  'In Test': 'yellow',
  'On Hold': 'pink',
  'Need shoe': 'orange',
  Complete: 'blue',
};

export function StatusTag({ status }: { status: Status }) {
  return (
    <Tag color={statusToColor[status]} size="small">
      {status}
    </Tag>
  );
}
