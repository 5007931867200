import { ResponseSchema } from '@nike.innovation/talos-core';
import { Card, Text, Box } from '@nike/eds';

export function ResponseSchemaCard({ responseSchema }: { responseSchema: ResponseSchema }) {
  const responseSchemaString = responseSchema.join(', ');

  return (
    <Card className="eds-elevation--2 eds-spacing--mb-24">
      <Text font="title-4" className="eds-spacing--mb-16">
        Response Schema
      </Text>
      <Box>
        {responseSchema.length > 0 ? (
          <Text>{responseSchemaString}</Text>
        ) : (
          <Text>No response schema</Text>
        )}
      </Box>
    </Card>
  );
}
