import { match, P } from 'ts-pattern';
import { Metric } from '@nike.innovation/talos-core';
import { Box, Text, Table, TableHeading, TableCell } from '@nike/eds';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export function DashboardMetric({ metric }: { metric: Metric }) {
  return (
    <Accordion
      key={metric.timestamp}
      className="eds-flex--grow-1 eds-spacing--ml-4 eds-spacing--mr-4 eds-spacing--mb-4"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${metric.timestamp}-content`}
        id={`panel${metric.timestamp}-header`}
      >
        <Box className="eds-flex eds-flex--grow-1 eds-flex--justify-content-space-between eds-flex--align-items-center">
          <Text>
            {new Date(metric.timestamp).toLocaleString()} / phase: {metric.phaseId} / User:
            {(metric.metrics.user as string) || 'No User'}
          </Text>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Table>
          <thead>
            <tr>
              {Object.keys(metric.metrics).map(
                metricObj => metricObj !== 'phaseId' && <TableHeading>{metricObj}</TableHeading>
              )}
            </tr>
          </thead>

          <tbody>
            <tr>
              {Object.entries(metric.metrics).map(
                metricObj =>
                  metricObj[0] !== 'phaseId' && (
                    <TableCell>
                      {match(metricObj)
                        .with(
                          [P.string, P.union(P.number, P.boolean, P.string)],
                          refined => refined[1]
                        )
                        .with(['files', P.array(P.string)], refined =>
                          refined[1].map(fileName => fileName.split('/').pop()).join(', ')
                        )
                        .with([P.string, P.array(P.string)], refined => refined[1].join(', '))
                        .otherwise(() => undefined)}
                    </TableCell>
                  )
              )}
            </tr>
          </tbody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
}
