import { Route, Routes } from 'react-router';
import { ForrestTestForm } from './forrest-test-form';
import { ForrestIndexPage } from './forrest-index-page';
import { ForrestDetailsPage } from './forrest-details-page';
import { ForrestRunPage } from './forrest-run-page';
import { BiomechanicsPage } from './biomechanics-index';
import { NewBiomechanicsForm } from './new-biomechanics-form';

export function ForrestRoutes() {
  return (
    <Routes>
      <Route path="/create" element={<ForrestTestForm />} />
      <Route path="/biomechanics" element={<BiomechanicsPage />} />
      <Route path="/biomechanics/new" element={<NewBiomechanicsForm />} />
      <Route path=":wearTestId/samples/:sampleId" element={<ForrestRunPage />} />
      <Route path=":id" element={<ForrestDetailsPage />} />
      <Route path="" element={<ForrestIndexPage />} />
    </Routes>
  );
}
