/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Spinner,
  Table,
  TableCell,
  TableHeading,
  Text,
  TextField,
} from '@nike/eds';
import { Experiment, Sample, Treatment } from '@nike.innovation/talos-core';
import { ResponseTableForm } from './response-table-form';

export function ExperimentForm({
  initialTreatmentHeaders,
  initialTreatmentLevels,
  initialExperiment,
  onSubmit,
  update,
  initialSamples,
}: {
  initialTreatmentHeaders: string[];
  initialTreatmentLevels: string[][];
  initialExperiment: Experiment;
  onSubmit: (experiment: Experiment, samples?: Sample[]) => Promise<void>;
  update?: boolean;
  initialSamples?: Sample[];
}) {
  const [experiment, setExperiment] = useState<Experiment>(initialExperiment);
  const [treatmentHeaders, setTreatmentHeaders] = useState<string[]>(initialTreatmentHeaders);
  const [treatmentLevels, setTreatmentLevels] = useState<string[][]>(initialTreatmentLevels);
  const [samples, setSamples] = useState(initialSamples);
  const [isLoading, setIsLoading] = useState(false);

  const updateHeaders = (index: number, value: string) => {
    setTreatmentHeaders(previousHeaders =>
      previousHeaders.map((header, headerIndex) => {
        if (headerIndex === index) {
          return value;
        }
        return header;
      })
    );
  };

  const updateTreatmentLevels = (
    previousLevels: string[][],
    row: number,
    column: number,
    newValue: string
  ): string[][] =>
    previousLevels.map((level, rowIndex) => {
      if (rowIndex === row) {
        return level.map((value, columnIndex) => {
          if (columnIndex === column) {
            return newValue;
          }
          return value;
        });
      }
      return level;
    });

  const buildTreatmentTable = () => {
    const nonEmptyHeaderIndexes: number[] = [];
    treatmentHeaders.forEach((header, index) => {
      if (header !== '') {
        nonEmptyHeaderIndexes.push(index);
      }
    });

    const treatmentTable: Treatment[] = [];

    treatmentLevels.forEach((row, index) => {
      if (row.some(v => v !== '')) {
        const levels: Record<string, string> = {};

        nonEmptyHeaderIndexes.forEach(headerIndex => {
          levels[treatmentHeaders[headerIndex]] = row[headerIndex + 1];
        });

        treatmentTable.push({
          treatmentId: `${index + 1}`,
          numSamples: parseInt(row[0], 10),
          levels,
        });
      }
    });

    return treatmentTable;
  };

  const internalSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    setIsLoading(true);
    onSubmit(
      {
        ...experiment,
        treatmentTable: buildTreatmentTable(),
      },
      samples
    );
    setIsLoading(false);
  };

  return (
    <form onSubmit={internalSubmit}>
      <Box className="eds-flex eds-flex--direction-column eds-gap--32">
        {!update && (
          <TextField
            id="id"
            label="Experiment Id"
            type="text"
            required
            value={experiment.id}
            onChange={e => {
              setExperiment(previousExperiment => ({
                ...previousExperiment,
                id: e.target.value,
              }));
            }}
          />
        )}

        <TextField
          id="name"
          label="Experiment Name"
          type="text"
          required
          value={experiment.name}
          onChange={e => {
            setExperiment(previousExperiment => ({
              ...previousExperiment,
              name: e.target.value,
            }));
          }}
        />

        <TextField
          id="confluenceLink"
          label="Confluence Link"
          type="text"
          value={experiment.confluenceLink}
          onChange={e => {
            setExperiment(previousExperiment => ({
              ...previousExperiment,
              confluenceLink: e.target.value,
            }));
          }}
        />

        <TextField
          id="responseSchema"
          label="Response schema columns (separated by commas)"
          type="text"
          value={experiment.responseSchema.join(', ')}
          onChange={e => {
            setExperiment(previousExperiment => ({
              ...previousExperiment,
              responseSchema: e.target.value.split(', '),
            }));
          }}
        />

        <Text font="title-3" as="h3" className="eds-spacing--mt-12">
          Treatment table
        </Text>
        <Table>
          <thead>
            <tr>
              <TableHeading>Treatment</TableHeading>
              <TableHeading>Number of samples</TableHeading>

              {treatmentHeaders.map((header, index) => (
                <TableHeading key={index}>
                  <TextField
                    id={`level-${index}`}
                    label="Level"
                    hideLabel
                    type="text"
                    value={header}
                    onChange={e => updateHeaders(index, e.target.value)}
                  />
                </TableHeading>
              ))}
            </tr>
          </thead>

          <tbody>
            {treatmentLevels.map((treatment, rowIndex) => (
              <tr key={rowIndex}>
                <TableCell>{rowIndex + 1}</TableCell>
                <TableCell>
                  <TextField
                    id={`treatmentSamples-${rowIndex}`}
                    label="Treatment samples"
                    hideLabel
                    type="number"
                    required
                    value={treatment[0]}
                    onChange={e =>
                      setTreatmentLevels(previousTreatments =>
                        updateTreatmentLevels(previousTreatments, rowIndex, 0, e.target.value)
                      )
                    }
                  />
                </TableCell>

                {treatmentHeaders.map((_header, columnIndex) => (
                  <TableCell key={columnIndex}>
                    <TextField
                      id={`treatment-${rowIndex}-${columnIndex}`}
                      label="Treatment"
                      hideLabel
                      type="text"
                      required
                      value={treatment[columnIndex + 1]}
                      onChange={e =>
                        setTreatmentLevels(previousTreatments =>
                          updateTreatmentLevels(
                            previousTreatments,
                            rowIndex,
                            columnIndex + 1,
                            e.target.value
                          )
                        )
                      }
                    />
                  </TableCell>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>

        {update && samples && (
          <>
            <Text font="title-3" as="h3" className="eds-spacing--mt-12">
              Response table
            </Text>

            <ResponseTableForm
              responseSchema={experiment.responseSchema}
              samples={samples}
              setSamples={setSamples}
            />
          </>
        )}

        <ButtonGroup>
          <Button type="submit" disabled={isLoading} onClick={internalSubmit}>
            {isLoading ? <Spinner /> : 'Save'}
          </Button>
        </ButtonGroup>
      </Box>
    </form>
  );
}
