import useSWR from 'swr';
import { useParams } from 'react-router';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import {
  Box,
  Button,
  ButtonGroup,
  Link,
  Select,
  Table,
  TableCell,
  TableHeading,
  Text,
} from '@nike/eds';
import { useOktaAuth } from '@okta/okta-react';

import { Loading } from '@nike.innovation/ui-components';

import { environment } from '../../../environments/environment';
import { ShoeSideTag } from './shoe-side-tag';
import { useWearTest } from '../../hooks/use-wear-test';
import { talosPyWebViewClient } from '../../api-client/talos-py-web-view-client';
import { DeviceCard } from '../../device/device-card';

export function ForrestRunPage() {
  const { oktaAuth } = useOktaAuth();

  const oktaToken = oktaAuth.getAccessToken();
  if (!oktaToken) {
    throw new Error('No okta token');
  }

  const { wearTestId, sampleId } = useParams();

  if (!wearTestId) {
    throw new Error('No id provided');
  }

  const { wearTest } = useWearTest(wearTestId, oktaToken);
  const sample = wearTest?.samples.find(s => s.id === sampleId);

  const { data: forrestDevices } = useSWR(
    `applications/forrest/devices`,
    talosPyWebViewClient.forrestDevices,
    { refreshInterval: 2000 }
  );

  const isPywebviewAvailable = environment.useMockServer || (window as any).pywebview !== undefined;

  return (
    <Box>
      {wearTest && sample ? (
        <Box>
          <Box className="eds-spacing--mb-32">
            <Box className="eds-spacing--mb-8">
              <Link font="title-2" href={`/applications/forrest/${wearTestId}`}>
                {wearTest.name}
              </Link>
            </Box>

            <Box className="eds-flex eds-gap--16">
              <Text as="h1" font="title-4">
                Sample: {sample.id}
              </Text>
              <ShoeSideTag shoeSide={sample.shoeSide} />
            </Box>

            <Box className="eds-flex eds-flex--direction-column eds-gap--8 eds-spacing--mt-16">
              <Text>Substrate: {wearTest.substrate}</Text>

              <Text>
                Shoe: {wearTest.shoe.name}, {wearTest.shoe.size}, {wearTest.shoe.buildVersion}
              </Text>
            </Box>
          </Box>

          <Box className="eds-spacing--mb-32">
            <Text font="title-4" className="eds-spacing--mb-16">
              Devices
            </Text>

            <Box className="eds-flex eds-gap--16 eds-spacing--mb-24">
              {isPywebviewAvailable &&
                forrestDevices &&
                forrestDevices.map(device => <DeviceCard device={device} key={device.kind} />)}
            </Box>
          </Box>

          <Box className="eds-spacing--mb-32">
            <Text font="title-4" className="eds-spacing--mb-16">
              Motion Profiles
            </Text>

            <Table>
              <thead>
                <tr>
                  <TableHeading>Motion Type</TableHeading>
                  <TableHeading>Progress</TableHeading>
                  <TableHeading>Time Remaining</TableHeading>
                  <TableHeading>State</TableHeading>
                </tr>
              </thead>

              <tbody>
                {sample.motionProfiles.map(motionProfile => (
                  <tr key={motionProfile.id}>
                    <TableCell>{motionProfile.motionType}</TableCell>
                    <TableCell>
                      {`${Math.floor(motionProfile.currentSteps / motionProfile.targetSteps)}%`}
                    </TableCell>

                    <TableCell>xxx</TableCell>
                    <TableCell>xxx</TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>

          <Box className="eds-spacing--mb-32">
            <Text font="title-4" className="eds-spacing--mb-16">
              Run
            </Text>

            <Box className="eds-flex eds-gap--32 eds-spacing--mb-32">
              <Select
                id="biomechanics"
                label="Biomechanics"
                options={[{ value: 'test', label: 'Test' }]}
                required
              />

              <Select
                id="fixture"
                label="Fixture"
                options={[{ value: 'test', label: 'Test' }]}
                required
              />
            </Box>

            <ButtonGroup>
              <Button beforeSlot={<PlayArrowRoundedIcon />}>Start</Button>
              <Button beforeSlot={<StopRoundedIcon />} variant="secondary">
                Stop
              </Button>
            </ButtonGroup>
          </Box>

          <Box className="eds-spacing--mb-32" style={{ maxWidth: 400 }}>
            <Select
              id="moveTo"
              label="Move To"
              options={[
                { value: 'home', label: 'Home' },
                { value: 'loadShoe', label: 'Load Shoe' },
                { value: 'loadPlate', label: 'Load Plate' },
              ]}
              required
              className="eds-spacing--mb-16"
            />

            <Button>Move</Button>
          </Box>
        </Box>
      ) : (
        <Loading />
      )}
    </Box>
  );
}
