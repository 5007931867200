import { Experiment } from '@nike.innovation/talos-core';
import { Card, Text, Table, TableHeading, TableCell } from '@nike/eds';

export function TreatmentTable({ experiment }: { experiment: Experiment }) {
  const headers = ['Treatment #', ...Object.keys(experiment.treatmentTable[0].levels), '# Samples'];

  return (
    <Card className="eds-elevation--2 eds-spacing--mb-24">
      <Text font="title-4" className="eds-spacing--mb-16">
        Treatment Table
      </Text>
      <Table width="100%">
        <thead>
          <tr>
            {headers.map(column => (
              <TableHeading>{column}</TableHeading>
            ))}
          </tr>
        </thead>
        <tbody>
          {experiment.treatmentTable.map(treatment => (
            <tr>
              <TableCell>{treatment.treatmentId}</TableCell>
              {Object.values(treatment.levels).map(level => (
                <TableCell>{level}</TableCell>
              ))}
              <TableCell>{treatment.numSamples}</TableCell>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}
