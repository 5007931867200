import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';
import { ScanPose, CreateScanPoseResponse } from '@nike.innovation/talos-core';
import { environment } from '../../environments/environment';

export function useScanPosesClient() {
  const { oktaAuth } = useOktaAuth();

  const oktaToken = oktaAuth.getAccessToken();
  if (!oktaToken) {
    throw new Error('No okta token');
  }

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${oktaToken}`,
    },
  };

  return {
    fetchScanPose: async (key: string): Promise<ScanPose> => {
      const { data } = await axios.get(
        `${environment.dataApiBaseUrl}/api/v1/scan-poses/${key}`,
        axiosConfig
      );

      return data;
    },

    listScanPoses: async (): Promise<string[]> => {
      const { data } = await axios.get(
        `${environment.dataApiBaseUrl}/api/v1/scan-poses/`,
        axiosConfig
      );

      return data;
    },

    storeScanPose: async ({
      key,
      scanPose,
    }: {
      key: string;
      scanPose: ScanPose;
    }): Promise<CreateScanPoseResponse> => {
      const { data } = await axios.post(
        `${environment.dataApiBaseUrl}/api/v1/scan-poses/${key}`,
        scanPose,
        axiosConfig
      );
      return data;
    },
  };
}
