import useSWR from 'swr';

import { WearTestMetadata } from '@nike.innovation/talos-core';

import { environment } from '../../environments/environment';

export function useWearTestMetadata(token: string): {
  wearTestsMetadata: WearTestMetadata[];
  isLoading: boolean;
  isError: any;
} {
  const key = `api/v1/wear-tests/metadata`;

  const fetcher = async () => {
    const response = await fetch(`${environment.dataApiBaseUrl}/${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch wear tests');
    }

    const wearTestsMetadata = await response.json();
    const descendingCreationDateMetadata = wearTestsMetadata.sort(
      (a: WearTestMetadata, b: WearTestMetadata) => (a.creationDate < b.creationDate ? 1 : -1)
    );

    return descendingCreationDateMetadata;
  };

  const { data, error } = useSWR(key, fetcher);

  return {
    wearTestsMetadata: data,
    isLoading: !error && !data,
    isError: error,
  };
}
