import { Box, Card, Text, Link } from '@nike/eds';

export function ExperimentCardGrid({
  experiments,
  program,
}: {
  experiments: string[];
  program: string;
}) {
  return (
    <Box className="eds-grid eds-grid--l-cols-3 eds-gap--16">
      {experiments.map(experiment => (
        <Card id={experiment}>
          <Text font="title-3" className="eds-spacing--mb-8">
            <Link href={`/experiments/${experiment}`}>{experiment}</Link>
          </Text>

          <Text font="body-2" className="eds-spacing--mb-8">
            {program}
          </Text>
        </Card>
      ))}
    </Box>
  );
}
