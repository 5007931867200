import { Box, Button, Text } from '@nike/eds';
import { useOktaAuth } from '@okta/okta-react';
import { match, P } from 'ts-pattern';
import { Loading } from '@nike.innovation/ui-components';

import { useExperiments } from '../../hooks/use-experiments';
import { ExperimentCardGrid } from './experiment-card-grid';

export function ExperimentIndexPage() {
  const { oktaAuth } = useOktaAuth();

  const program = 'Siping';
  const { experiments, isLoading, isError } = useExperiments(
    program,
    `${oktaAuth.getAccessToken()}`
  );

  return (
    <Box>
      <Box className="eds-flex eds-flex--justify-content-space-between eds-spacing--mb-24">
        <Text font="title-3" as="h1" className="eds-spacing--mb-24">
          Experiments
        </Text>

        <Button as="a" variant="primary" href="/experiments/new">
          New Experiment
        </Button>
      </Box>

      {match({
        experiments,
        isLoading,
        isError,
      })
        .with(
          {
            experiments: P.not(P.nullish),
            isLoading: false,
            isError: P.nullish,
          },
          () => <ExperimentCardGrid experiments={experiments} program={program} />
        )
        .with(
          {
            isError: P.not(P.nullish),
          },
          () => <Text>Error loading experiments</Text>
        )
        .otherwise(() => (
          <Loading />
        ))}
    </Box>
  );
}
