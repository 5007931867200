import { Box, Text, Card, Button } from '@nike/eds';
import { BiomechanicsTable } from './biomechanics-table';

export function BiomechanicsPage() {
  return (
    <Box>
      <Box className="eds-flex eds-flex--justify-content-space-between eds-flex--align-items-center">
        <Text font="title-1" as="h1">
          Biomechanics
        </Text>

        <Button as="a" href="applications/forrest/biomechanics/new">
          Upload New Biomechanics
        </Button>
      </Box>

      <Card className="eds-spacing--mt-32">
        <BiomechanicsTable />
      </Card>
    </Box>
  );
}
