import { Dispatch } from 'react';

import { Box, Text, TextField, Toggle } from '@nike/eds';
import { KeyenceConfiguration, KeyenceConfigurationForm } from './configuration-types';
import { ConfigurationAction } from './configuration-reducer';
import { getNonNumericFields } from './utils';

const keyenceConfigurationForm: KeyenceConfigurationForm = {
  cloudConfig: {
    cropBox: {
      sizeX: '250',
      sizeY: '385',
      sizeZ: '200',
      x: '-70',
      y: '-10',
      z: '-10',
    },
    filtering: {
      downsample: true,
      clean: true,
      segment: true,
      voxelSize: '0.5',
      nbNeighbors: '20',
      stdRatio: '5',
      eps: '1.5',
      minPoints: '10',
    },
    translation: {
      invert: true,
      x: '50',
      y: '-100',
      z: '70',
    },
    other: {
      delayComp: '-0.6',
      minCloudSize: '1000',
    },
  },
  position: {
    length: '500',
    rate: '100',
    speed: '50',
  },
};

export function CloudConfiguration({
  keyenceConfig,
  dispatch,
}: {
  keyenceConfig: KeyenceConfigurationForm;
  dispatch: Dispatch<ConfigurationAction>;
}) {
  const { cloudConfig } = keyenceConfig;
  const nonNumericFields = getNonNumericFields(keyenceConfig);
  const numberErrMsg = 'Only numbers are allowed';

  return (
    <Box>
      <Text font="title-4" as="h5" className="eds-spacing--mb-16">
        Cloud Configuration
      </Text>

      <Box className="eds-grid eds-grid--cols-4 eds-gap--24">
        <Box className="eds-flex eds-flex--direction-column eds-gap--24">
          <Text font="title-4" as="h6">
            Filtering
          </Text>

          <Toggle
            id="filtering-downsample"
            label="Downsample"
            onChange={e =>
              dispatch({
                kind: 'EDIT_CLOUD_FILTERING',
                name: 'downsample',
                value: !keyenceConfig.cloudConfig.filtering.downsample,
              })
            }
            checked={keyenceConfig.cloudConfig.filtering.downsample}
          />

          <Toggle
            id="filtering-clean"
            label="Clean"
            onChange={e =>
              dispatch({
                kind: 'EDIT_CLOUD_FILTERING',
                name: 'clean',
                value: !keyenceConfig.cloudConfig.filtering.clean,
              })
            }
            checked={keyenceConfig.cloudConfig.filtering.clean}
          />

          <Toggle
            id="filtering-segment"
            label="Segment"
            onChange={e =>
              dispatch({
                kind: 'EDIT_CLOUD_FILTERING',
                name: 'segment',
                value: !keyenceConfig.cloudConfig.filtering.segment,
              })
            }
            checked={keyenceConfig.cloudConfig.filtering.segment}
          />

          <TextField
            id="filtering-voxel-size"
            type="number"
            value={keyenceConfig.cloudConfig.filtering.voxelSize}
            label="Voxel size"
            onChange={e =>
              dispatch({
                kind: 'EDIT_CLOUD_FILTERING',
                name: 'voxelSize',
                value: e.target.value,
              })
            }
          />

          <TextField
            id="filtering-nb-neighbors"
            type="number"
            value={keyenceConfig.cloudConfig.filtering.nbNeighbors}
            label="Nearest neighbors"
            onChange={e =>
              dispatch({
                kind: 'EDIT_CLOUD_FILTERING',
                name: 'nbNeighbors',
                value: e.target.value,
              })
            }
          />

          <TextField
            id="filtering-std-ratio"
            type="number"
            value={keyenceConfig.cloudConfig.filtering.stdRatio}
            label="Standard deviation for outlier removal"
            onChange={e =>
              dispatch({
                kind: 'EDIT_CLOUD_FILTERING',
                name: 'stdRatio',
                value: e.target.value,
              })
            }
          />

          <TextField
            id="filtering-eps"
            type="number"
            value={keyenceConfig.cloudConfig.filtering.eps}
            label="Eps"
            onChange={e =>
              dispatch({
                kind: 'EDIT_CLOUD_FILTERING',
                name: 'eps',
                value: e.target.value,
              })
            }
          />

          <TextField
            id="filtering-min-points"
            type="number"
            value={keyenceConfig.cloudConfig.filtering.minPoints}
            label="Minimum density points for segmentation"
            onChange={e =>
              dispatch({
                kind: 'EDIT_CLOUD_FILTERING',
                name: 'minPoints',
                value: e.target.value,
              })
            }
          />
        </Box>

        <Box className="eds-flex eds-flex--direction-column eds-gap--24">
          <Text font="title-4" as="h6">
            Crop box
          </Text>

          <TextField
            id="cropbox-x"
            type="number"
            min={-1000}
            value={keyenceConfig.cloudConfig.cropBox.x}
            label="X"
            hasErrors={nonNumericFields.includes('cloudConfig.cropBox.x')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_CROP_BOX',
                name: 'x',
                value: e.target.value,
              })
            }
          />

          <TextField
            id="cropbox-y"
            type="number"
            value={keyenceConfig.cloudConfig.cropBox.y}
            label="Y"
            hasErrors={nonNumericFields.includes('cloudConfig.cropBox.y')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_CROP_BOX',
                name: 'y',
                value: e.target.value,
              })
            }
          />

          <TextField
            id="cropbox-z"
            type="number"
            value={keyenceConfig.cloudConfig.cropBox.z}
            label="Z"
            hasErrors={nonNumericFields.includes('cloudConfig.cropBox.z')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_CROP_BOX',
                name: 'z',
                value: e.target.value,
              })
            }
          />

          <TextField
            id="cropbox-size-x"
            type="number"
            value={keyenceConfig.cloudConfig.cropBox.sizeX}
            label="Size X"
            hasErrors={nonNumericFields.includes('cloudConfig.cropBox.sizeX')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_CROP_BOX',
                name: 'sizeX',
                value: e.target.value,
              })
            }
          />

          <TextField
            id="cropbox-size-y"
            type="number"
            value={keyenceConfig.cloudConfig.cropBox.sizeY}
            label="Size Y"
            hasErrors={nonNumericFields.includes('cloudConfig.cropBox.sizeY')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_CROP_BOX',
                name: 'sizeY',
                value: e.target.value,
              })
            }
          />

          <TextField
            id="cropbox-size-z"
            type="number"
            value={keyenceConfig.cloudConfig.cropBox.sizeZ}
            label="Size Z"
            hasErrors={nonNumericFields.includes('cloudConfig.cropBox.sizeZ')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_CROP_BOX',
                name: 'sizeZ',
                value: e.target.value,
              })
            }
          />
        </Box>

        <Box className="eds-flex eds-flex--direction-column eds-gap--24">
          <Text font="title-4" as="h6">
            Translation
          </Text>

          <TextField
            id="translation-x"
            type="number"
            value={keyenceConfig.cloudConfig.translation.x}
            label="X"
            hasErrors={nonNumericFields.includes('cloudConfig.translation.x')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_TRANSLATION',
                name: 'x',
                value: e.target.value,
              })
            }
          />

          <TextField
            id="translation-y"
            type="number"
            value={keyenceConfig.cloudConfig.translation.y}
            label="Y"
            hasErrors={nonNumericFields.includes('cloudConfig.translation.y')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_TRANSLATION',
                name: 'y',
                value: e.target.value,
              })
            }
          />

          <TextField
            id="translation-z"
            type="number"
            value={keyenceConfig.cloudConfig.translation.z}
            label="Z"
            hasErrors={nonNumericFields.includes('cloudConfig.translation.z')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_TRANSLATION',
                name: 'z',
                value: e.target.value,
              })
            }
          />

          <Toggle
            id="translation-invert"
            label="Invert"
            onChange={e =>
              dispatch({
                kind: 'EDIT_TRANSLATION',
                name: 'invert',
                value: !keyenceConfig.cloudConfig.translation.invert,
              })
            }
            checked={keyenceConfig.cloudConfig.translation.invert}
          />
        </Box>

        <Box className="eds-flex eds-flex--direction-column eds-gap--24">
          <Text font="title-4" as="h6">
            Other
          </Text>

          <TextField
            id="other-min-cloud-size"
            type="number"
            value={keyenceConfig.cloudConfig.other.minCloudSize}
            label="Minimum number of points for successful scan"
            hasErrors={nonNumericFields.includes('cloudConfig.other.minCloudSize')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_OTHER',
                name: 'minCloudSize',
                value: e.target.value,
              })
            }
          />

          <TextField
            id="other-delay-comp"
            type="number"
            value={keyenceConfig.cloudConfig.other.delayComp}
            label="Delay compensation for signal lag in Y axis (im mm)"
            hasErrors={nonNumericFields.includes('cloudConfig.other.delayComp')}
            errorMessage={numberErrMsg}
            onChange={e =>
              dispatch({
                kind: 'EDIT_OTHER',
                name: 'delayComp',
                value: e.target.value,
              })
            }
          />
        </Box>
      </Box>
    </Box>
  );
}
