import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { match, P } from 'ts-pattern';

import { useOktaAuth } from '@okta/okta-react';
import { Box, Text } from '@nike/eds';
import { Loading } from '@nike.innovation/ui-components';
import { SampleConfiguration } from '@nike.innovation/talos-core';

import { talosPyWebViewClient } from '../../api-client/talos-py-web-view-client';
import { useExperiment } from '../../hooks/use-experiment';
import { useSamples } from '../../hooks/use-samples';
import { TreatmentTable } from './treatment-table';
import { ResponseSchemaCard } from './response-schema';
import { TestResults } from './test-results';
import { ResponseTable } from './response-table';
import { ExperimentHeader } from './experiment-header';
import { SipingModal } from './siping-modal';

export function ExperimentDetailsPage() {
  const { oktaAuth } = useOktaAuth();
  const token = `${oktaAuth.getAccessToken()}`;

  const { experimentId } = useParams();

  const [stateVersion, setStateVersion] = useState(0);
  const refreshPage = () => setStateVersion(stateVersion + 1);

  const program = 'Siping';
  const {
    experiment,
    isLoading: isExperimentLoading,
    isError: experimentError,
  } = useExperiment(program, experimentId || '', token);
  const {
    samples,
    isLoading: isSamplesLoading,
    isError: samplesError,
  } = useSamples(program, experimentId || '', token, stateVersion);

  const [sipingModalOpen, setSipingModalOpen] = useState(false);
  const [selectedSample, setSelectedSample] = useState('');

  const sampleConfig: SampleConfiguration = {
    program,
    experimentId: experimentId || '',
    sampleId: selectedSample,
  };

  useEffect(() => {
    if (token) {
      talosPyWebViewClient.setUserToken(token);
    }
  }, [token]);

  return (
    <Box>
      {match({
        experiment,
        isExperimentLoading,
        experimentError,
        samples,
        isSamplesLoading,
        samplesError,
      })
        .with(
          {
            experiment: P.not(P.nullish),
            isExperimentLoading: false,
            experimentError: P.nullish,
            samples: P.not(P.nullish),
            isSamplesLoading: false,
            samplesError: P.nullish,
          },
          refined => (
            <Box>
              <SipingModal
                sipingModalOpen={sipingModalOpen}
                setSipingModalOpen={setSipingModalOpen}
                refreshPage={refreshPage}
                sampleConfig={sampleConfig}
              />
              <ExperimentHeader experiment={refined.experiment} />
              <TreatmentTable experiment={refined.experiment} />
              <ResponseSchemaCard responseSchema={refined.experiment.responseSchema} />
              <ResponseTable
                samples={refined.samples}
                responseSchema={refined.experiment.responseSchema}
                setSipingModalOpen={setSipingModalOpen}
                setSelectedSample={setSelectedSample}
              />
              <TestResults testResults={refined.experiment.testResults} />
            </Box>
          )
        )
        .with({ experimentError: P.not(P.nullish) }, () => <Text>Error loading experiment</Text>)
        .with({ samplesError: P.not(P.nullish) }, () => <Text>Error loading samples</Text>)
        .otherwise(() => (
          <Loading />
        ))}
    </Box>
  );
}
