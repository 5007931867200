import useSWR from 'swr';

import { Biomechanics } from '@nike.innovation/talos-core';

import { environment } from '../../environments/environment';

export function useBiomechanics(token: string): {
  biomechanics: Biomechanics[];
  isLoading: boolean;
  isError: any;
} {
  const key = `api/v1/biomechanics`;

  const fetcher = async () => {
    const response = await fetch(`${environment.dataApiBaseUrl}/${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch biomechanics');
    }

    const biomechanics = await response.json();
    return biomechanics;
  };

  const { data, error } = useSWR(key, fetcher);

  return {
    biomechanics: data,
    isLoading: !error && !data,
    isError: error,
  };
}
