import { Box, Button, Table, TableCell, TableHeading, Text } from '@nike/eds';
import { useOktaAuth } from '@okta/okta-react';
import { useParams } from 'react-router';
import { Loading } from '@nike.innovation/ui-components';

import { StatusTag } from './status-tag';
import { ShoeSideTag } from './shoe-side-tag';
import { useWearTest } from '../../hooks/use-wear-test';

export function ForrestDetailsPage() {
  const { oktaAuth } = useOktaAuth();

  const oktaToken = oktaAuth.getAccessToken();
  if (!oktaToken) {
    throw new Error('No okta token');
  }

  const { id } = useParams();

  if (!id) {
    throw new Error('No id provided');
  }

  const { wearTest } = useWearTest(id, oktaToken);

  return (
    <Box>
      {wearTest ? (
        <>
          <Text font="title-1" as="h1" className="eds-spacing--mb-32">
            {wearTest.name}
          </Text>

          <Box className="eds-spacing--mb-32">
            <Text font="title-3" className="eds-spacing--mb-16">
              Details
            </Text>

            <Box className="eds-flex eds-flex--direction-column eds-gap--8 eds-spacing--mt-16">
              <Text>Project: {wearTest.project}</Text>
              <Text>Test Type: {wearTest.testType}</Text>
              <Text>Gender: {wearTest.shoe.gender}</Text>
              <Text>
                Status: <StatusTag status={wearTest.status} />
              </Text>
              <Text>Cell: {wearTest.cell}</Text>
              <Text>Substrate: {wearTest.substrate}</Text>

              <Text>
                Shoe: {wearTest.shoe.name}, {wearTest.shoe.size}, {wearTest.shoe.side},{' '}
                {wearTest.shoe.buildVersion}
              </Text>
              <Text>Number of Samples: {wearTest.numberOfSamples}</Text>

              <Text>Requester: {wearTest.requester}</Text>
              <Text>Request Date: {new Date(wearTest.creationDate).toLocaleDateString()}</Text>
              <Text>
                Desired Finish Date: {new Date(wearTest.desiredFinishDate).toLocaleDateString()}
              </Text>
              <Text>Test goal: {wearTest.testGoal}</Text>
              <Text>Testing notes: {wearTest.testingNotes}</Text>
            </Box>
          </Box>

          <Box>
            <Text font="title-3" className="eds-spacing--mb-16">
              Samples
            </Text>

            <Table>
              <thead>
                <tr>
                  <TableHeading>Id</TableHeading>
                  <TableHeading>Shoe Side</TableHeading>
                  <TableHeading>Progress</TableHeading>
                </tr>
              </thead>

              <tbody>
                {wearTest.samples.map(sample => (
                  <tr key={sample.id}>
                    <TableCell>{sample.id}</TableCell>

                    <TableCell>
                      <ShoeSideTag shoeSide={sample.shoeSide} />
                    </TableCell>

                    <TableCell>
                      {sample.motionProfiles
                        .map(
                          profile =>
                            `${profile.motionType}: ${Math.floor(
                              profile.currentSteps / profile.targetSteps
                            )}%`
                        )
                        .join(', ')}
                    </TableCell>

                    <TableCell>
                      <Button as="a" href={`/applications/forrest/${id}/samples/${sample.id}`}>
                        Run
                      </Button>
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
}
