import { match } from 'ts-pattern';
import BoltIcon from '@mui/icons-material/OfflineBoltOutlined';
import HandIcon from '@mui/icons-material/BackHandOutlined';

import { Box, Card, StatusIndicator, Text, StatusIndicatorProps } from '@nike/eds';

import { Device, DeviceStatus } from '../command/command-types';
import { DeviceIcon } from './device-icon';

import styles from './device-card.module.css';

const indicatorStatus: Record<DeviceStatus, StatusIndicatorProps['status']> = {
  ready: 'success',
  'not connected': 'danger',
  waiting: 'warning',
  error: 'danger',
  stopped: 'warning',
  busy: 'danger',
};

export function DeviceCard({ device }: { device: Device }) {
  const textColor = device.status === 'not connected' ? 'grey-1' : 'black';

  return (
    <Card style={{ minWidth: 300 }} className="eds-flex eds-gap--16">
      <DeviceIcon device={device} />

      <Box className="eds-flex eds-flex--direction-column eds-gap--8">
        <Text as="h4">{device.name}</Text>

        <StatusIndicator
          className={styles.deviceStatus}
          status={indicatorStatus[device.status]}
          size="large"
          label={device.status}
        />

        {match(device)
          .with({ kind: 'ABBRobot' }, abbRobot => (
            <Box className={`eds-color--${textColor}`}>
              <Box className="eds-flex eds-flex--align-items-center eds-gap--8">
                <Text font="body-3" color={textColor}>
                  Mode:
                </Text>

                <Box className="eds-flex">
                  <HandIcon />
                  <Text font="body-3" color={textColor}>
                    {abbRobot.modeState}
                  </Text>
                </Box>
              </Box>

              <Box className="eds-flex eds-flex--align-items-center eds-gap--8">
                <Text font="body-3" color={textColor}>
                  Motor:
                </Text>

                <Box className="eds-flex">
                  <BoltIcon />
                  <Text font="body-3" color={textColor}>
                    {abbRobot.motorState}
                  </Text>
                </Box>
              </Box>
            </Box>
          ))
          .otherwise(() => null)}
      </Box>
    </Card>
  );
}
