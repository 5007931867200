/* eslint-disable prefer-promise-reject-errors */
import { SampleConfiguration, ScanPose } from '@nike.innovation/talos-core';
import { environment } from '../../environments/environment';
import { mockTalosClient } from './mock-talos-client';
import { Command, Device, Pose, RunParams, Tool } from '../command/command-types';
import { TalosPyWebViewClient, CommandStartParams } from './api-client-types';
import { KeyenceConfiguration } from '../configuration/configuration-types';

// eslint-disable-next-line import/no-mutable-exports
let talosPyWebViewClient: TalosPyWebViewClient;

if (environment.useMockServer) {
  talosPyWebViewClient = mockTalosClient;
} else {
  talosPyWebViewClient = {
    setUserToken: (token: string): void => {
      if ((window as any)?.pywebview?.api) {
        (window as any).pywebview.api.setUserToken(token);
      }
    },

    nectar: (machineCodePath: string, token: string): Promise<any> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.nectar(machineCodePath, token);
      }

      return Promise.reject(false);
    },

    nectarDevices: (): Promise<Device[]> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.nectarDevices();
      }

      return Promise.reject([]);
    },

    scan: (
      param: KeyenceConfiguration,
      sampleParams: SampleConfiguration,
      token: string,
      phase: string,
      appId: string
    ): Promise<any> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.scan(param, sampleParams, token, phase, appId);
      }

      return Promise.reject(false);
    },

    multiscan: (
      param: KeyenceConfiguration,
      sampleParams: SampleConfiguration,
      poses: ScanPose[],
      token: string,
      phase: string,
      appId: string
    ): Promise<any> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.multiscan(
          param,
          sampleParams,
          poses,
          token,
          phase,
          appId
        );
      }

      return Promise.reject(false);
    },

    scanDevices: (): Promise<Device[]> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.scanDevices();
      }

      return Promise.reject([]);
    },

    sipe: (machineCodePath: string, token: string, params: SampleConfiguration): Promise<any> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.sipe(machineCodePath, token, params);
      }

      return Promise.reject(false);
    },

    testKnife: (): Promise<boolean> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.testKnife();
      }

      return Promise.reject(false);
    },

    sipeDevices: (): Promise<Device[]> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.sipeDevices();
      }

      return Promise.reject([]);
    },

    calibrateDevices: (): Promise<Device[]> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.calibrateDevices();
      }

      return Promise.reject([]);
    },

    calibrateCameraDevices: (): Promise<Device[]> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.calibrateCameraDevices();
      }

      return Promise.reject([]);
    },

    captureImage: (token: string, poses: Pose[], tool: Tool): Promise<[string, string]> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.captureImage(token, poses, tool);
      }

      return Promise.reject('Error');
    },

    selectSourcePath: async (): Promise<string> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.selectSourcePath();
      }

      return Promise.reject('No pywebview.api');
    },

    getCommands: async (): Promise<Command[]> => {
      if ((window as any)?.pywebview?.api !== undefined) {
        return (window as any).pywebview.api.getCommands();
      }

      return Promise.resolve([]);
    },

    getCommand: (id: string) => (): Promise<Command | undefined> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.getCommand(id);
      }

      return Promise.resolve(undefined);
    },

    getRunParams: (id: string): Promise<RunParams | undefined> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.getRunParams(id);
      }

      return Promise.resolve(undefined);
    },

    startCommand: (id: string, params: CommandStartParams): Promise<any> => {
      if ((window as any)?.pywebview?.api) {
        console.log('starting command', id, params);
        const response = (window as any).pywebview.api.startCommand(id, params);
        console.log(response);
        return response;
      }

      return Promise.resolve(false);
    },

    stopCommand: (): Promise<boolean> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.stopCommand();
      }

      return Promise.resolve(false);
    },

    logWithoutRobot: (token: string): Promise<boolean> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.logWithoutRobot(token);
      }

      return Promise.resolve(false);
    },

    getLoggingSignal: (): Promise<boolean> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.getLoggingSignal();
      }

      return Promise.resolve(false);
    },

    goHome: (): Promise<boolean> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.goHome();
      }

      return Promise.resolve(false);
    },

    calibrateCamera: (
      token: string,
      poses: Pose[],
      intrinsics: boolean,
      extrinsics: boolean
    ): Promise<boolean> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.calibrateCamera(token, poses, intrinsics, extrinsics);
      }

      return Promise.resolve(false);
    },

    readPcd: (): Promise<string> => {
      if ((window as any)?.pywebview?.api) {
        return (window as any).pywebview.api.readPcd();
      }

      return Promise.resolve('');
    },
  };
}

export { talosPyWebViewClient };
