/* eslint-disable react/no-array-index-key */
import { Box, IconButton, Select, TableCell, TableHeading, TextField } from '@nike/eds';
import {
  Experiment,
  ResponseSchema,
  Sample,
  SampleState,
  sampleStates,
} from '@nike.innovation/talos-core';
import { Dispatch, SetStateAction } from 'react';

export function ResponseTableForm({
  responseSchema,
  experiment,
  samples,
  setSamples,
}: {
  responseSchema: ResponseSchema;
  experiment: Experiment;
  samples: Sample[];
  setSamples: Dispatch<SetStateAction<Sample[]>>;
}) {
  const headers = ['Sample #', 'Treatment #', ...responseSchema, 'State'];
  const treatmentOptions = experiment.treatmentTable.map(treatment => ({
    value: treatment.treatmentId.replace('treatmentId#', ''),
    label: treatment.treatmentId.replace('treatmentId#', ''),
  }));
  const sampleOptions = sampleStates.map(state => ({ value: state, label: state }));

  return (
    <Box>
      <table>
        <thead>
          <tr>
            {headers.map(column => (
              <TableHeading key={column}>{column}</TableHeading>
            ))}
          </tr>
        </thead>

        <tbody>
          {samples.map(sample => (
            <tr key={sample.sampleId}>
              <TableCell>{`${experiment.name}-${sample.treatmentId}-${sample.sampleId}`}</TableCell>
              <TableCell>
                <Select
                  id={`treatment-${sample.sampleId}`}
                  required
                  label={`Treatment for sample ${sample.sampleId}`}
                  hideLabel
                  defaultValue={{
                    value: sample.treatmentId.replace('treatmentId#', ''),
                    label: sample.treatmentId.replace('treatmentId#', ''),
                  }}
                  options={treatmentOptions}
                  onChange={e =>
                    setSamples(previousSamples =>
                      previousSamples.map(previousSample => {
                        if (sample.sampleId === previousSample.sampleId && e) {
                          return {
                            ...previousSample,
                            treatmentId: e.value,
                          };
                        }

                        return previousSample;
                      })
                    )
                  }
                />
              </TableCell>

              {responseSchema.map((response, columnIndex) => (
                <TableCell key={response}>
                  <TextField
                    id={`sample-${sample.sampleId}-${columnIndex}`}
                    label={`Sample ${sample.sampleId} ${response}`}
                    hideLabel
                    type="text"
                    value={sample.response[response]}
                    onChange={e =>
                      setSamples(previousSamples =>
                        previousSamples.map(previousSample => {
                          if (sample.sampleId === previousSample.sampleId) {
                            return {
                              ...previousSample,
                              response: {
                                ...previousSample.response,
                                [response]: e.target.value,
                              },
                            };
                          }

                          return previousSample;
                        })
                      )
                    }
                  />
                </TableCell>
              ))}

              <TableCell>
                <Select
                  id={`state-${sample.sampleId}`}
                  options={sampleOptions}
                  label={`State for sample ${sample.sampleId}`}
                  hideLabel
                  defaultValue={{ value: sample.state, label: sample.state }}
                  onChange={selected => {
                    setSamples(previousSamples =>
                      previousSamples.map(previousSample => {
                        if (sample.sampleId === previousSample.sampleId && selected) {
                          return {
                            ...previousSample,
                            state: selected.value,
                          };
                        }
                        return previousSample;
                      })
                    );
                  }}
                  required={false}
                />
              </TableCell>

              <TableCell>
                <IconButton
                  icon="Delete"
                  size="small"
                  label={`Delete sample ${sample.sampleId}`}
                  variant="ghost"
                  style={{ color: 'var(--eds-color-border-danger)' }}
                  onClick={() =>
                    setSamples(previousSamples =>
                      previousSamples.filter(
                        previousSample => previousSample.sampleId !== sample.sampleId
                      )
                    )
                  }
                />
              </TableCell>
            </tr>
          ))}
        </tbody>
      </table>

      <IconButton
        icon="Plus"
        onClick={() =>
          setSamples(previousSamples => {
            const maxSampleId = +previousSamples.reduce(
              (max, sample) => (sample.sampleId > max ? sample.sampleId : max),
              '0'
            );

            return [
              ...previousSamples,
              {
                experimentId: experiment.id,
                sampleId: `${maxSampleId + 1}`,
                treatmentId: treatmentOptions[0].value,
                state: 'TBD' as SampleState,
                response: {},
                metricKeyPayloads: [],
                metricFiles: [],
                files: [],
              },
            ];
          })
        }
        label="Add sample"
        variant="secondary"
        size="small"
      />
    </Box>
  );
}
