import { match } from 'ts-pattern';
import RobotIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import ScannerIcon from '@mui/icons-material/ScannerOutlined';
import StorageIcon from '@mui/icons-material/Storage';
import CameraIcon from '@mui/icons-material/PhotoCameraOutlined';
import { Device } from '../command/command-types';

export function DeviceIcon({ device }: { device: Device }) {
  const style = { fontSize: 48 };

  return match(device)
    .with({ kind: 'ABBRobot' }, () => <RobotIcon style={style} />)
    .with({ kind: 'KeyenceScanner' }, () => <ScannerIcon style={style} />)
    .with({ kind: 'PLCDevice' }, () => <StorageIcon style={style} />)
    .with({ kind: 'BaslerGigE' }, () => <CameraIcon style={style} />)
    .exhaustive();
}
