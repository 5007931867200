import { MorphAlgorithm } from '@nike.innovation/talos-core';
import { Command } from '../command/command-types';
import { TalosPyWebViewClient } from './api-client-types';
import { mockPhoto } from './mock-photo';
import { mockPointCloud } from './mock-point-cloud';

function sleep(ms: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

const mockCommands: Command[] = [
  {
    name: 'Scan Midsole',
    id: 'Scan',
    description: 'Scan midsole for Nectar process',
    devices: [
      {
        kind: 'KeyenceScanner',
        name: 'Keyence Scanner',
        status: 'ready',
      },
      {
        kind: 'ABBRobot',
        name: 'ABB Robot',
        status: 'ready',
        motorState: 'off',
        modeState: 'manual',
      },
    ],
  },
  {
    name: 'Nectar',
    id: 'Nectar',
    description: 'Print with Nectar',
    devices: [
      {
        kind: 'ABBRobot',
        name: 'ABB Robot',
        status: 'ready',
        motorState: 'off',
        modeState: 'manual',
      },
      {
        kind: 'PLCDevice',
        name: 'PLC Device',
        status: 'ready',
      },
    ],
  },
  {
    name: 'Siping',
    id: 'Siping',
    description: 'Sipe midsole',
    devices: [
      {
        kind: 'ABBRobot',
        name: 'ABB Robot',
        status: 'ready',
        motorState: 'off',
        modeState: 'manual',
      },
    ],
  },
  {
    name: 'Calibrate',
    id: 'Calibrate',
    description: 'Calibrate',
    devices: [
      {
        kind: 'ABBRobot',
        name: 'ABB Robot',
        status: 'ready',
        motorState: 'off',
        modeState: 'manual',
      },
      {
        kind: 'BaslerGigE',
        name: 'BaslerGigE',
        status: 'ready',
      },
    ],
  },
  {
    name: 'CalibrateCamera',
    id: 'CalibrateCamera',
    description: 'CalibrateCamera',
    devices: [
      {
        kind: 'ABBRobot',
        name: 'ABB Robot',
        status: 'ready',
        motorState: 'off',
        modeState: 'manual',
      },
      {
        kind: 'BaslerGigE',
        name: 'BaslerGigE',
        status: 'ready',
      },
    ],
  },
  {
    name: 'Forrest',
    id: 'Forrest',
    description: 'Forrest',
    devices: [
      {
        kind: 'ABBRobot',
        name: 'ABB Robot',
        status: 'ready',
        motorState: 'off',
        modeState: 'manual',
      },
      {
        kind: 'ForcePlate',
        name: 'ForcePlate',
        status: 'ready',
      },
      {
        kind: 'BaslerGigE',
        name: 'BaslerGigE',
        status: 'ready',
      },
    ],
  },
];

const mockMorphAlgorithms: MorphAlgorithm[] = [
  {
    name: 'cpd',
    parameters: [
      {
        kind: 'file',
        name: 'Point cloud',
        settings: {
          accepts: '.pcd',
        },
      },
      {
        kind: 'file',
        name: 'CAD',
        settings: {
          accepts: '.cad',
        },
      },
      { kind: 'number', name: 'Alpha', settings: { default: 0 } },
      { kind: 'number', name: 'Beta', settings: { default: 0 } },
    ],
  },
];

export const mockTalosClient: TalosPyWebViewClient = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserToken: () => {},
  nectar: () => Promise.resolve({}),
  nectarDevices: () => Promise.resolve(mockCommands[1].devices),
  scan: () => Promise.resolve({}),
  multiscan: () => Promise.resolve({}),
  scanDevices: () => Promise.resolve(mockCommands[0].devices),
  sipe: () => Promise.resolve({}),
  sipeDevices: () => Promise.resolve(mockCommands[2].devices),
  calibrateDevices: () => Promise.resolve(mockCommands[3].devices),
  calibrateCameraDevices: () => Promise.resolve(mockCommands[4].devices),
  captureImage: () => Promise.resolve([mockPhoto, mockPhoto]),
  forrestDevices: () => Promise.resolve(mockCommands[5].devices),

  getCommands: () => Promise.resolve(mockCommands),
  getCommand: (id: string) => () =>
    Promise.resolve(mockCommands.find(command => command.id === id)),
  getRunParams: (id: string) => Promise.resolve(undefined),
  startCommand: (id: string): Promise<boolean> =>
    // sleep for 3 seconds
    new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, 3000);
    }),
  stopCommand: (): Promise<boolean> => Promise.resolve(true),
  selectSourcePath: () => Promise.resolve('/Users/username/Documents/nectar_mid.txt'),
  logWithoutRobot: (token: string) => Promise.resolve(true),
  getLoggingSignal: () => Promise.resolve(true),
  goHome: () => Promise.resolve(true),
  async testKnife() {
    await sleep(3000);
    return Promise.resolve(true);
  },
  calibrateCamera: (): Promise<boolean> => Promise.resolve(true),
  readPcd: (): Promise<string> => Promise.resolve(mockPointCloud),
  morphParameters: (algorithmName: string) => Promise.resolve(mockMorphAlgorithms[0]),
  morph: (algorithm: MorphAlgorithm, token: string) => Promise.resolve(true),
};
