import useSWR from 'swr';

import { Experiment } from '@nike.innovation/talos-core';

import { environment } from '../../environments/environment';

export function useExperiment(
  program: string,
  experimentId: string,
  token: string
): {
  experiment: Experiment | undefined;
  isLoading: boolean;
  isError: any;
} {
  const key = `api/v1/experiments/${program}/${experimentId}`;

  const fetcher = async () => {
    const response = await fetch(`${environment.dataApiBaseUrl}/${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch experiment');
    }

    const experiment = await response.json();
    return experiment;
  };

  const { data, error } = useSWR(key, fetcher);
  return {
    experiment: data,
    isLoading: !error && !data,
    isError: error,
  };
}
