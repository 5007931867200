import { useState } from 'react';

import { Card, Text, Box, Button, Spinner, Tooltip } from '@nike/eds';
import { Experiment } from '@nike.innovation/talos-core';

import { downloadResultFile } from '../../api-client/get-row-metrics';
import { environment } from '../../../environments/environment';

export function TestResults({ experiment, token }: { experiment: Experiment; token: string }) {
  const isPywebviewAvailable = environment.useMockServer || (window as any).pywebview !== undefined;

  const [isDownloading, setIsDownloading] = useState(false);

  const testResultsExist = experiment.testResults.length > 0;

  return (
    <Card className="eds-elevation--2">
      <Box className="eds-flex eds-flex--justify-content-space-between eds-flex--align-items-center">
        <Text font="title-4" className="eds-spacing--mb-16">
          Test Results
        </Text>
        {isPywebviewAvailable ? (
          <Tooltip bodySlot="Only Available in Webview" isDark>
            <Button disabled>Download results</Button>
          </Tooltip>
        ) : (
          <Button
            disabled={!testResultsExist}
            onClick={async () => {
              setIsDownloading(true);
              await downloadResultFile(experiment, token);
              setIsDownloading(false);
            }}
          >
            {isDownloading ? <Spinner /> : 'Download results'}
          </Button>
        )}
      </Box>
      <Box>
        {testResultsExist ? (
          <Box>
            <Text>{experiment.testResults}</Text>
          </Box>
        ) : (
          <Text>No test results</Text>
        )}
      </Box>
    </Card>
  );
}
