export function AnchorPoint({ pos }: { pos: { x: number; y: number } }) {
  const anchorPointStyle: React.CSSProperties = {
    cursor: 'pointer',
    top: `${pos.y}%`,
    left: `${pos.x}%`,
    alignItems: 'center',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    position: 'absolute',
    backgroundColor: 'var(--eds-color-border-focus)',
    borderRadius: '50%',
    height: 12,
    width: 12,
    border: 'none',
  };

  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  return <button style={anchorPointStyle} type="button" />;
}
