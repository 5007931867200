import { Route, Routes } from 'react-router';
import { ExperimentIndexPage } from './index-page/experiment-index-page';
import { ExperimentDetailsPage } from './details-page/experiment-details-page';

export function ExperimentRoutes() {
  return (
    <Routes>
      <Route path=":experimentId" element={<ExperimentDetailsPage />} />
      <Route path="" element={<ExperimentIndexPage />} />
    </Routes>
  );
}
