import { Dispatch } from 'react';
import { Snackbar, Snack, Text } from '@nike/eds';

import { CommandPageState, CommandPageAction } from '../../command/command-page-reducer';

export function SipingSnackBar({
  commandPageState,
  dispatch,
}: {
  commandPageState: CommandPageState;
  dispatch: Dispatch<CommandPageAction>;
}) {
  return (
    <Snackbar>
      {commandPageState.showSnack && (
        <Snack
          id="1"
          status={commandPageState.status}
          onDismiss={() => dispatch({ kind: 'TOGGLE_SNACK', newShowSnack: false })}
        >
          <Text>{commandPageState.statusText}</Text>
        </Snack>
      )}
    </Snackbar>
  );
}
