import axios from 'axios';
import JSZip from 'jszip';

import { Experiment, Sample } from '@nike.innovation/talos-core';

import { environment } from '../../environments/environment';

export function getSampleFileNameFromMetricKey(key: string, sample: Sample): string {
  const lastSlashIndex = key.lastIndexOf('/');
  const fileName = key.slice(lastSlashIndex + 1);
  const experimentId = sample.experimentId.split('#').pop();
  return `${experimentId}-${sample.treatmentId}-${sample.sampleId}-${fileName}`;
}

export function getFileNameFromMetricKey(key: string): string {
  const lastSlashIndex = key.lastIndexOf('/');
  return key.slice(lastSlashIndex + 1);
}

// Keep for experiments
export const downloadBinaryFile = (data: Blob, filename: string): void => {
  const href = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${filename}`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(href);
};

export const getRowMetrics = async (
  authToken: string,
  cellId: string,
  appId: string,
  phaseId: string,
  timestamp: number,
  sample?: Sample
) => {
  if (!authToken) {
    throw new Error('No okta token');
  }

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const key = `api/v1/cells/${cellId}/applications/${appId}/phases/${phaseId}/timestamp/${timestamp}/files`;
  const response = await axios.get(`${environment.dataApiBaseUrl}/${key}`, axiosConfig);
  const fileNames: string[] = [];
  const getMetricsPromises = Object.entries(response.data).map(
    ([_, metricFileObject]: [string, any]) => {
      if (sample) fileNames.push(getSampleFileNameFromMetricKey(metricFileObject.file, sample));
      else {
        fileNames.push(getFileNameFromMetricKey(metricFileObject.file));
      }
      return axios.get(metricFileObject.url, { responseType: 'blob' }).catch(() => undefined);
    }
  );
  const metricFiles = await Promise.all(getMetricsPromises);

  return { fileNames, metricFiles: metricFiles.map(file => file?.data).filter(Boolean) };
};

export const attachFilesToSample = async (
  program: string,
  experimentId: string,
  sampleId: string,
  files: FileList,
  authToken: string
): Promise<void> => {
  if (!authToken) {
    throw new Error('No okta token');
  }

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const fileNames = Array.from(files).map(file => file.name);
  try {
    const response = await axios.post(
      `${environment.dataApiBaseUrl}/api/v1/experiments/${program}/${experimentId}/${sampleId}/files`,
      { files: fileNames },
      axiosConfig
    );
    const presignedUrls = response.data;

    Array.from(files).forEach(async file => {
      const { url } = presignedUrls.find(
        (urlObj: { fileName: string }) => urlObj.fileName === file.name
      );
      await axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
    });
  } catch (error) {
    console.error('Error uploading files:', error);
  }
};

export const downloadResultFile = async (experiment: Experiment, authToken: string) => {
  if (!authToken) {
    throw new Error('No okta token');
  }

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const key = `api/v1/experiments/${experiment.program}/${experiment.id}/test-results`;
  const response = await axios.get(`${environment.dataApiBaseUrl}/${key}`, axiosConfig);
  const presignedUrl = response.data;

  const fileResponse = await axios
    .get(presignedUrl, { responseType: 'blob' })
    .catch(() => undefined);

  if (fileResponse) {
    const zip = new JSZip();
    zip.file(experiment.testResults, fileResponse.data);
    const zipContent = await zip.generateAsync({ type: 'blob' });
    downloadBinaryFile(zipContent, `${experiment.testResults}.zip`);
  }
};

export const downloadSampleMetrics = async (
  token: string,
  samples: Sample[],
  experimentName: string
) => {
  const zip = new JSZip();

  await Promise.all(
    samples.map(async sample => {
      await Promise.all(
        sample.metricKeyPayloads.map(async metricKeyPayload => {
          const { fileNames, metricFiles } = await getRowMetrics(
            token,
            metricKeyPayload.cellId,
            metricKeyPayload.appId,
            metricKeyPayload.phaseId,
            metricKeyPayload.timestamp,
            sample
          );

          metricFiles.forEach((file, index) => {
            zip.file(fileNames[index], file);
          });
        })
      );
    })
  );
  const zipContent = await zip.generateAsync({ type: 'blob' });
  downloadBinaryFile(zipContent, `metrics-${experimentName}.zip`);
};

export const downloadDashboardMetrics = async (token: string, selectedMetrics: any[]) => {
  const zip = new JSZip();

  await Promise.all(
    selectedMetrics.map(async metric => {
      const { fileNames, metricFiles } = await getRowMetrics(
        token,
        metric.cellId,
        metric.appId,
        metric.phaseId,
        metric.timestamp
      );

      metricFiles.forEach((file, index) => {
        zip.file(fileNames[index], file);
      });
    })
  );

  const zipContent = await zip.generateAsync({ type: 'blob' });
  downloadBinaryFile(zipContent, `metrics-dashboard.zip`);
};
