import useSWR from 'swr';

import { environment } from '../../environments/environment';

export function useExperiments(
  program: string,
  token: string
): {
  experiments: string[];
  isLoading: boolean;
  isError: any;
} {
  const key = `api/v1/experiments/${program}`;

  const fetcher = async () => {
    const response = await fetch(`${environment.dataApiBaseUrl}/${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch experiments');
    }

    const experiments = await response.json();
    return experiments;
  };

  const { data, error } = useSWR(key, fetcher);
  return {
    experiments: data,
    isLoading: !error && !data,
    isError: error,
  };
}
