import { SetStateAction, Dispatch } from 'react';
import { Box, Button, Icon, Text } from '@nike/eds';
import { CommandPageState } from '../../command/command-page-reducer';
import { talosPyWebViewClient } from '../../api-client/talos-py-web-view-client';

export function SipingHeader({
  commandPageState,
  setIsGoHomeInUse,
}: {
  commandPageState: CommandPageState;
  setIsGoHomeInUse: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <Box className="eds-flex eds-flex--justify-content-space-between eds-spacing--mb-24">
      <Text font="title-1">Siping</Text>

      <Button
        variant="secondary"
        className="eds-spacing--mb-16"
        beforeSlot={<Icon name="HomeFilled" size="s" />}
        disabled={commandPageState.runningCommandId === 'Scan'}
        onClick={async () => {
          setIsGoHomeInUse(true);
          await talosPyWebViewClient.goHome();
          setIsGoHomeInUse(false);
        }}
      >
        Go Home
      </Button>
    </Box>
  );
}
