import { useState } from 'react';

import { Box, Text, Select, Spinner, Button, Checkbox, Card } from '@nike/eds';

import { useOktaAuth } from '@okta/okta-react';
import { Metric } from '@nike.innovation/talos-core';
import { match } from 'ts-pattern';
import { DashboardMetric } from './dashboard-metric';
import { PaginationShowMore } from '../core/paginate-show-more';
import usePaginatedMetrics from '../hooks/use-paginated-metrics';
import { downloadDashboardMetrics } from '../api-client/get-row-metrics';

export function DashboardIndexPage() {
  const [cellId, setCellId] = useState('bladerunner');
  const [appId, setAppId] = useState('siping');
  const [metricsFilter, setMetricsFilter] = useState('7');
  const { oktaAuth } = useOktaAuth();
  const token = `${oktaAuth.getAccessToken()}`;
  const refreshToken = `${oktaAuth.getRefreshToken()}`;

  const [selectedMetrics, setSelectedMetrics] = useState<Metric[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleCheckboxChange = (selectedMetric: Metric, isChecked: boolean) => {
    if (isChecked) {
      setSelectedMetrics(prevMetrics => [...prevMetrics, selectedMetric]);
    } else {
      setSelectedMetrics(prevMetrics => prevMetrics.filter(m => m !== selectedMetric));
    }
  };
  const { metrics, metricsLoading, metricsError, setMetricsPage, metricsPage } =
    usePaginatedMetrics(cellId, appId, 10, oktaAuth.getAccessToken() || '');

  const {
    metrics: accumulatedMetrics,
    metricsLoading: accumulatedMetricsLoading,
    metricsError: accumulatedMetricsErr,
  } = usePaginatedMetrics(cellId, appId, 300, oktaAuth.getAccessToken() || '');

  const filteredMetrics = accumulatedMetrics.result.filter((metric: Metric) => {
    // filter out metrics depending on the metricsFilter
    const metricDate = new Date(metric.timestamp);
    const daysAgo = new Date();
    const getFilterDuration = (): number =>
      match(metricsFilter)
        .with('7', () => 7)
        .with('30', () => 30)
        .with('90', () => 90)
        .otherwise(() => 1000000);
    daysAgo.setDate(daysAgo.getDate() - getFilterDuration());
    return metricDate > daysAgo;
  });

  const getDurationString = (): string =>
    match(metricsFilter)
      .with('7', () => 'last 7 days')
      .with('30', () => 'last 30 days')
      .with('90', () => 'last 90 days')
      .otherwise(() => 'all time');

  const mbUploaded = filteredMetrics.reduce(
    (acc: number, metric: Metric) =>
      // estimate size of file uploaded in MB
      // assume each file is around 1MB
      acc + 1.1,
    0
  );

  const processesRun = filteredMetrics.reduce(
    (acc: number, metric: Metric) => {
      // typically a process has a file attached to it
      if (
        metric.metrics.files !== null &&
        Array.isArray(metric.metrics.files) &&
        metric.metrics.files.length > 0 &&
        metric.metrics.files.some(file => file.endsWith('.txt'))
      ) {
        return acc + metric.metrics.files.length;
      }
      return acc;
    },

    0
  );

  const samplesRegex = /\d{4}-\d{2}-\d{2}_\d{2}-\d{2}-\d{2}.txt/;
  const samplesCreated = filteredMetrics.filter(
    // typically a sample has a file following a particular pattern for its name
    (metric: Metric) =>
      metric.metrics.files !== null &&
      Array.isArray(metric.metrics.files) &&
      metric.metrics.files.length > 0 &&
      metric.metrics.files.some(file => file.match(samplesRegex))
  ).length;

  const scansRun = filteredMetrics.filter(
    (metric: Metric) =>
      metric.metrics.files !== null &&
      Array.isArray(metric.metrics.files) &&
      metric.metrics.files.length > 0 &&
      metric.metrics.files.some(file => file === 'np_output.txt')
  ).length;

  console.log('mbUploaded', mbUploaded);
  console.log('processesRun', processesRun);

  if (metricsError) {
    return <Text>Error loading metrics</Text>;
  }

  if (metricsLoading) {
    return <Spinner />;
  }

  return (
    <Card>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Text font="title-3" as="h1" className="eds-spacing--mb-24">
          Data Dashboard
        </Text>

        <Button
          className="eds-flex--align-self-flex-end eds-spacing--mb-16"
          size="small"
          disabled={isDownloading || selectedMetrics.length === 0}
          onClick={async (e: any) => {
            e.stopPropagation();
            setIsDownloading(true);

            await downloadDashboardMetrics(token, selectedMetrics);

            setIsDownloading(false);
          }}
        >
          {isDownloading ? <Spinner /> : 'Download Metrics'}
        </Button>
      </div>

      <Box className="eds-grid eds-grid--m-cols-4">
        <Select
          id="currentCell"
          label="Cell"
          required={false}
          options={[
            { label: 'Greenhouse', value: 'greenhouse' },
            { label: 'Nectar Dev', value: 'nectar-dev' },
            { label: 'Bladerunner', value: 'bladerunner' },
          ]}
          defaultValue={{ label: 'Bladerunner', value: 'bladerunner' }}
          onChange={e => {
            if (e !== null) setCellId(e.value);
          }}
          className="eds-grid--m-col-2"
        />

        <Select
          id="currentCell"
          label="Application"
          required={false}
          options={[
            { label: 'Nectar', value: 'nectar' },
            { label: 'Siping', value: 'siping' },
            { label: 'Calibration', value: 'calibration' },
          ]}
          defaultValue={{ label: 'Siping', value: 'siping' }}
          onChange={e => {
            if (e !== null) setAppId(e.value);
          }}
          className="eds-grid--m-col-2"
        />

        <Select
          id="currentCell"
          label="Metrics Filter"
          required={false}
          options={[
            { label: 'Last 7 days', value: '7' },
            { label: 'Last 30 days', value: '30' },
            { label: 'Last 90 days', value: '90' },
            { label: 'All Time', value: 'all-time' },
          ]}
          defaultValue={{ label: 'Last 7 days', value: '7' }}
          onChange={e => {
            if (e !== null) setMetricsFilter(e.value);
          }}
          className="eds-grid--m-col-2"
        />
      </Box>

      <Box height="1em" />

      <Box className="eds-grid eds-grid--m-cols-3" width="100%" minWidth="100%">
        <Card
          className="eds-elevation--2 eds-grid--m-col-1"
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          {accumulatedMetricsLoading ? (
            <Spinner />
          ) : (
            <Text font="title-4">{filteredMetrics.length} files</Text>
          )}
          <Text
            font="legal-1"
            style={{ color: 'gray', alignItems: 'center', justifyContent: 'center' }}
          >
            uploaded {getDurationString()}
          </Text>
        </Card>

        <Card
          className="eds-elevation--2 eds-grid--m-col-1"
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          {accumulatedMetricsLoading ? (
            <Spinner />
          ) : (
            <Text font="title-4">{mbUploaded.toFixed(2)} MB</Text>
          )}
          <Text
            font="legal-1"
            style={{ color: 'gray', alignItems: 'center', justifyContent: 'center' }}
          >
            stored over {getDurationString()}
          </Text>
        </Card>

        <Card
          className="eds-elevation--2 eds-grid--m-col-1"
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          {accumulatedMetricsLoading ? (
            <Spinner />
          ) : (
            <>
              <Text font="title-4">
                {processesRun} process{processesRun > 1 ? 'es' : ''} run
              </Text>
              <Text
                font="legal-1"
                style={{ color: 'gray', alignItems: 'center', justifyContent: 'center' }}
              >
                {getDurationString()}
              </Text>
            </>
          )}
        </Card>

        <Card
          className="eds-elevation--2 eds-grid--m-col-1"
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          {accumulatedMetricsLoading ? (
            <Spinner />
          ) : (
            <>
              <Text font="title-4">
                {scansRun} scans{scansRun > 1 ? 's' : ''} created
              </Text>
              <Text
                font="legal-1"
                style={{ color: 'gray', alignItems: 'center', justifyContent: 'center' }}
              >
                {getDurationString()}
              </Text>
            </>
          )}
        </Card>
        <Card
          className="eds-elevation--2 eds-grid--m-col-1"
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          {accumulatedMetricsLoading ? (
            <Spinner />
          ) : (
            <>
              <Text font="title-4">
                {samplesCreated} sample{samplesCreated > 1 ? 's' : ''} created
              </Text>
              <Text
                font="legal-1"
                style={{ color: 'gray', alignItems: 'center', justifyContent: 'center' }}
              >
                {getDurationString()}
              </Text>
            </>
          )}
        </Card>
      </Box>

      <Box height="1em" />

      <Box>
        {metrics?.result.map(result => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              label=""
              checked={selectedMetrics.includes(result)}
              onChange={e => handleCheckboxChange(result, e.target.checked)}
              id="select-metric-checkbox"
            />
            <DashboardMetric metric={result} />
          </div>
        ))}
      </Box>

      {metrics?.last && (
        <Box mt="1em" style={{ justifyContent: 'center', margin: 'auto' }}>
          <PaginationShowMore
            page={metricsPage}
            setPage={setMetricsPage}
            metricsLoading={metricsLoading}
          />
        </Box>
      )}
    </Card>
  );
}
