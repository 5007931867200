import { WearTestMetadata } from '@nike.innovation/talos-core';
import { TableCell, Button } from '@nike/eds';
import { StatusTag } from './status-tag';

export function WearTestsTableRow({ wearTestMetadata }: { wearTestMetadata: WearTestMetadata }) {
  const requestDate = new Date(wearTestMetadata.creationDate);
  const desiredFinishDate = new Date(wearTestMetadata.desiredFinishDate);

  return (
    <tr>
      <TableCell>{wearTestMetadata.name}</TableCell>
      <TableCell>
        <StatusTag status={wearTestMetadata.status} />
      </TableCell>
      <TableCell>{wearTestMetadata.requester}</TableCell>
      <TableCell>{requestDate.toLocaleDateString()}</TableCell>
      <TableCell>{desiredFinishDate.toLocaleDateString()}</TableCell>
      <TableCell>
        <Button as="a" href={`/applications/forrest/${wearTestMetadata.id}`} variant="secondary">
          Open test
        </Button>
      </TableCell>
    </tr>
  );
}
