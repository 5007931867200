import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';

import { Box, Text, TextField, Select, TextArea, Button, Spinner } from '@nike/eds';
import {
  Gender,
  ShoeSide,
  Substrate,
  WearTestForm,
  WearTestType,
} from '@nike.innovation/talos-core';
import { useOktaAuth } from '@okta/okta-react';

import { environment } from '../../../environments/environment';

export function ForrestTestForm() {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();

  const oktaToken = oktaAuth.getAccessToken();
  if (!oktaToken) {
    throw new Error('No okta token');
  }

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${oktaToken}`,
    },
  };

  const commonProjectOptions = [
    'SIPING',
    'SPEED',
    'DIGITAL AIR',
    'ADHESIVES',
    'SOLE UTIONS EXPLORE',
    'ADDITIVE OUTSOLES: NECTAR',
  ];
  const commonSizeOptions = ['M10', 'M12', 'W8', 'W8.5', 'W9'];

  const initialWearTest: WearTestForm = {
    name: '',
    substrate: '' as Substrate,
    testType: '' as WearTestType,
    desiredFinishDate: 0,
    numberOfSamples: 0,
    project: '',
    shoe: {
      name: '',
      size: '',
      gender: '' as Gender,
      side: '' as ShoeSide & 'Both',
      buildVersion: undefined,
    },
    testGoal: undefined,
    testingNotes: undefined,
  };

  const [wearTestForm, setWearTestForm] = useState<Record<string, any>>(initialWearTest);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (field: string, value: any) => {
    setWearTestForm(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleShoeChange = (field: string, value: any) => {
    setWearTestForm(prevState => ({
      ...prevState,
      shoe: {
        ...prevState.shoe,
        [field]: value,
      },
    }));
  };

  const optionsMap = (optionStrings: string[]) =>
    optionStrings.map(option => ({
      value: option,
      label: option,
    }));

  const submit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    setIsLoading(true);

    const formattedForm = {
      ...wearTestForm,
      desiredFinishDate: new Date(wearTestForm.desiredFinishDate).getTime(),
    };
    const response = await axios.post(
      `${environment.dataApiBaseUrl}/api/v1/wear-tests`,
      formattedForm,
      axiosConfig
    );

    if (response.status === 200) {
      navigate('/applications/forrest');
    }
    setIsLoading(false);
  };

  // TODO: Should there be a visible indicator for form complete/required fields? Other examples: The date should be today or later, etc
  const isFormComplete: boolean =
    wearTestForm.name.trim() !== '' &&
    wearTestForm.substrate.trim() !== '' &&
    wearTestForm.testType.trim() !== '' &&
    wearTestForm.desiredFinishDate > 0 &&
    wearTestForm.numberOfSamples > 0 &&
    wearTestForm.project.trim() !== '' &&
    wearTestForm.shoe.name.trim() !== '' &&
    wearTestForm.shoe.size.trim() !== '' &&
    wearTestForm.shoe.gender.trim() !== '' &&
    wearTestForm.shoe.side.trim() !== '';

  return (
    <Box>
      <Text font="title-1" as="h1" className="eds-spacing--mb-32">
        Create wear test
      </Text>

      <Box className="eds-flex eds-flex--direction-column eds-gap--32">
        <Box className="eds-flex eds-flex--align-items-flex-end eds-gap--32">
          <Box className="eds-flex--grow-1">
            <TextField
              id="testName"
              label="Test Name"
              value={wearTestForm.name}
              onChange={e => handleChange('name', e.target.value)}
              required
            />
          </Box>
          <Select
            id="project"
            label="Project"
            subtitle="Select applicable program this is related to. If you can't find the right selection, please type in your own"
            options={optionsMap(commonProjectOptions)}
            onChange={(e: any) => handleChange('project', e.value)}
            isCreatable
            required
          />
        </Box>
        <Box className="eds-flex eds-flex--direction-column eds-gap--32 eds-spacing--my-32">
          <Box className="eds-flex eds-gap--32">
            <Box className="eds-flex--grow-3">
              <TextField
                id="shoeName"
                label="Shoe Name"
                value={wearTestForm.shoe.name}
                onChange={e => handleShoeChange('name', e.target.value)}
                required
              />
            </Box>
            <Box className="eds-flex--grow-1">
              <Select
                id="shoeSide"
                label="Shoe Side"
                options={optionsMap(['Left', 'Right', 'Both'])}
                onChange={(e: any) => handleShoeChange('side', e.value)}
                required
              />
            </Box>
          </Box>
          <Box className="eds-flex eds-flex--align-items-flex-end eds-gap--32">
            <Box className="eds-flex--grow-1">
              <Select
                id="shoeGender"
                label="Men/Women/Kids"
                options={optionsMap(['Men', 'Women', 'Kids'])}
                onChange={(e: any) => handleShoeChange('gender', e.value)}
                required
              />
            </Box>
            <Box className="eds-flex--grow-1">
              <Select
                id="shoeSize"
                label="Last Size"
                subtitle="Select from options. Type in your own if desired option not available"
                options={optionsMap(commonSizeOptions)}
                onChange={(e: any) => handleShoeChange('size', e.value)}
                required
                isCreatable
              />
            </Box>
          </Box>
          <TextArea
            id="buildVersion"
            label="Build Description"
            subtitle="Special plate, foam type or maybe outsole material"
            onChange={e => handleShoeChange('buildVersion', e.target.value)}
          />
        </Box>
        <Box className="eds-flex eds-gap--32">
          <Select
            id="substrate"
            label="Substrate"
            subtitle="What Ground would you like to use? (Floor Material that the outsole will be impacting against)"
            options={optionsMap(['Asphalt', 'Wood', 'Concrete'])}
            onChange={(e: any) => handleChange('substrate', e.value)}
            required
          />
          <Select
            id="testType"
            label="Test Type"
            subtitle="Pre-configured types of tests to choose from. If you would like to request a custom test, please note it in the 'Footwear Details + Test Goals' Section"
            options={optionsMap(['Lifestyle', 'Basketball', 'Running'])}
            onChange={(e: any) => handleChange('testType', e.value)}
            required
          />
        </Box>
        <Box className="eds-flex eds-flex--align-items-flex-end eds-gap--32">
          <TextField
            id="numSamples"
            type="number"
            label="Number of Samples"
            subtitle="How many samples will be tested? (# of individual shoes, a pair = 2 samples)"
            onChange={e => handleChange('numberOfSamples', parseInt(e.target.value, 10))}
          />
          <TextField
            id="desiredFinishDate"
            type="date"
            label="Desired Finish Date"
            onChange={e => handleChange('desiredFinishDate', new Date(e.target.value).getTime())}
          />
        </Box>
        <TextArea
          id="testGoal"
          label="Footwear Details + Test Goals"
          subtitle="Tell us about the construction of the footwear and your test goal. For Example - Construction:  Full-length Nike React foam, Zoom Air pods in the forefoot, Test Goal: Test the durability of the zoom airbag. Does the zoom airbag experience any failures?"
          onChange={e => handleChange('testGoal', e.target.value)}
        />
        <TextField
          id="testingNotes"
          label="Notes"
          onChange={e => handleChange('testingNotes', e.target.value)}
        />

        <Button type="submit" disabled={!isFormComplete || isLoading} onClick={submit}>
          {isLoading ? <Spinner /> : 'Submit'}
        </Button>
      </Box>
    </Box>
  );
}
