export const mockPointCloud = `
# .PCD v0.7 - Point Cloud Data file format
VERSION 0.7
FIELDS x y z
SIZE 4 4 4
TYPE F F F
COUNT 1 1 1
WIDTH 53004
HEIGHT 1
VIEWPOINT 0 0 0 1 0 0 0
POINTS 53004
DATA ascii
-9.5 49.4 29.102
63.7 32.4 60.302
69.6 49.9 62.11
-47 51.9 37.4626
-50.5 40.4 43.2798
-38 63.9 30.3666
70.9 24.4 89.888
58.5 51.9 56.4072
60.8 8.9 55.805
-68 39.9 43.4314
-68 25.9 39.4616
-69 18.9 33.7936
-55.45 68.9 38.68675
33.6 42.4 49.321
-44.35 54.4 36.839
-37.4 67.9 23.226
16.5 30.4 36.7398
-40.5 50.9 33.7838
21.5 28.4 34.2458
-37.2 68.9 22.098
10 47.4 60.9566
70.5 41.9 64.0636
13.8 43.9 57.841
-34.05 57.9 29.784
63.2 64.4 51.501
50.5 52.9 53.8072
65.35 59.4 54.2845
66.5 36.9 65.3722
-52 19.4 31.3532
57.5 69.9 52.1676
-45.5 51.9 37.4636
-25.5 49.4 31.6088
-49.5 54.9 42.969
-17.55 59.4 25.2835
6.5 68.9 58.2546
-59 54.4 45.8578
-25 49.4 31.6298
-40.95 60.4 45.661
34 19.9 35.8236
-66 9.4 19.2076
-66.45 55.9 45.24725
71.5 55.4 76.952
-52 38.4 42.7702
-4.4 47.9 28.67266667
50.65 8.4 44.158
61.85 7.9 57.3425
-49.65 14.9 24.6445
64.3 29.4 64.791
-17.5 61.4 24.7896
-57.5 9.9 20.5476
52.85 39.4 58.758
-50.5 85.9 18.8878
-17.3 57.9 24.989
-53.5 18.9 30.4452
-13.7 48.9 25.486
-39.3 33.4 29.455
6.5 43.4 28.3654
42.5 43.9 53.8828
-45.5 46.9 37.5428
32.15 44.9 53.7155
-63.5 33.9 43.5342
63.35 6.9 63.854
-16.7 58.4 21.612
62.85 4.9 62.4345
61.85 7.4 59.8025
-27 55.9 30.6588
-68 84.4 26.2918
-6 43.9 28.5644
-54.5 52.9 43.9126
-46 36.9 35.4554
-13.5 45.9 28.1176
-7.5 42.9 28.3772
-43.5 27.4 29.5822
64.8 8.4 66.851
-1.9 48.9 29.10366667
-53.5 45.9 44.031
45.7 40.9 54.374
-65.5 61.4 44.327
-58 87.4 20.7604
-20.2 59.9 27.616
-66.5 32.4 42.8966
57 44.4 61.9322
9 48.4 61.2556
-34.7 83.4 39.474
33 44.4 56.6168
0.6 50.4 34.367
-61 14.4 28.464
6.2 68.9 58.085
-55.45 14.4 26.2095
-56.5 85.4 24.678
47.5 41.9 56.7736
6.3 60.9 60.293
29.55 22.9 34.7185
-66.5 15.4 29.842
66.95 13.4 68.6875
66.5 6.4 68.268
69.5 12.9 71.3666
70 76.9 49.6648
-46.5 44.9 37.3816
-38.4 43.4 32.431
-70 19.9 34.755
-37.05 79.9 42.652
69.9 49.4 69.944
-37.6 67.4 25.641
-62.5 48.9 46.245
-41.35 25.4 27.4195
65.2 62.9 52.948
-68.5 80.4 31.6688
-38.3 65.4 29.77
40.5 69.9 48.7112
-56.1 11.4 23.64933333
-56.5 30.4 42.0416
12.6 44.4 58.792
-1.5 44.4 28.706
3.1 63.9 58.618
-55.7 11.9 23.304
-66.5 9.9 20.4022
6.45 57.4 60.777
52 53.9 56.119
-65 43.9 45.9874
16.95 33.4 36.59775
-14 62.4 24.447
-45 57.9 36.3394
72 44.9 63.0516
61 55.4 58.3632
-50 22.4 34.187
-65.5 28.4 40.9878
-37.7 42.4 31.265
-68 55.4 43.7884
-59.5 53.4 45.9778
35.5 42.9 51.8566
41 45.9 50.9074
-56.1 10.9 22.67833333
-16.5 60.9 24.7862
-37.2 73.4 43.961
-16 44.9 28.12
61.5 71.9 50.7108
-42.5 28.4 30.7114
51.5 52.9 55.7434
-49.35 27.9 30.3725
54.85 72.9 48.545
-67 15.9 30.45
-43.6 74.9 24.25266667
5.9 58.9 61.16066667
44 69.9 48.5902
-53.5 15.4 26.1978
2.5 49.9 43.612
-52.5 13.9 23.6628
-35.5 56.4 27.6778
31.4 43.9 54.98566667
-47.5 25.9 28.6052
63.8 64.4 50.875
-38.85 32.4 28.328
-47.5 34.9 34.599
69.5 70.9 53.1856
19.95 31.4 36.2605
69.8 25.4 78.381
-42.1 47.9 39.88433333
-56.05 76.4 35.71525
-61.5 18.9 33.8222
-53 47.4 44.0876
-58.5 45.4 46.133
-24.5 44.4 31.31
-66.5 29.9 41.7374
-62.5 75.4 36.6214
7 35.9 36.4212
33.5 44.4 56.503
37.85 43.9 50.3995
-63 83.9 27.0926
-62 43.4 45.9052
-64 30.9 42.241
-46 34.9 34.5876
-60.5 46.4 46.1902
51 50.9 66.5666
59.5 76.4 56.93866667
-61 15.4 29.8772
-20.5 47.9 26.6058
51.8 49.9 63.316
-40 46.4 42.4184
-60.5 32.9 43.1136
-46.5 39.9 36.3912
-65 29.4 41.4644
-49 76.4 32.6598
59.5 51.4 55.7778
-63.5 17.9 32.7722
-19.7 55.4 27.464
-51.05 11.9 18.625
64.05 29.9 64.1645
-29.5 57.9 30.091
-67.5 62.9 43.774
-67 72.9 38.5042
-0.5 48.9 28.8974
-48 78.4 19.5824
-50 57.9 43.0826
69 11.4 71.6642
6.7 32.9 36.14
53.6 65.9 50.046
-62.5 25.9 39.48
-51.5 14.9 25.2336
33.5 23.4 35.6848
61.5 67.9 53.6196
48 67.9 48.702
-34.45 45.4 29.17475
28.475 27.4 33.98908333
-65.5 57.4 45.337
-45.8 23.9 29.003
-30 44.4 30.8296
-62 75.9 36.2142
66.65 15.4 70.188
-28 50.9 31.555
64.7 15.9 69.461
-58.5 36.4 44.4164
-55.45 51.9 44.0905
-64.5 9.4 19.2096
65.9 9.4 67.797
-43.7 76.9 22.151
-18.5 43.4 29.2032
-48.5 40.4 36.5614
61.35 5.9 58.22
-66 21.9 36.5234
-58 36.9 44.5638
-67.5 63.4 43.607
66.5 24.9 70.2422
-2 46.4 29.042
-19.85 60.4 26.887
-53 62.4 41.7524
-54.5 62.9 41.578
16 32.9 36.968
64.1 61.9 46.348
-30 46.9 31.5416
-10.4 58.9 26.76433333
-41.3 78.4 33.124
53.65 53.4 56.476
-18 54.4 28.5042
-20.2 56.4 25.84
-8 46.4 29.0408
-14.3 58.4 20.715
-66.5 13.4 26.953
-41.2 29.4 30.686
-59.5 76.9 35.3336
-65.5 9.9 20.4266
49.55 41.4 57.716
-65.5 21.9 36.526
39.95 42.4 51.30425
8 34.4 36.5438
-52.5 21.4 33.296
57.5 61.9 43.0982
-55.8 80.4 31.394
39.55 15.9 37.8345
-15.5 45.9 28.1108
-55 30.4 39.693
-69.5 58.9 43.7946
69.5 11.4 71.8862
15.5 31.4 37.0356
-44.5 26.4 28.9936
-34.45 57.9 28.857
65 35.4 64.8276
65.55 3.9 64.72625
-65.5 36.4 44.3944
-8.5 48.4 29.0812
-2 59.9 26.2578
-59 39.9 45.3502
-24.5 48.9 31.6382
-58 31.4 42.496
-64.5 71.4 39.5348
-49 58.4 36.2166
-16.8 52.4 28.268
-67 83.4 27.8214
34.7 44.4 55.172
-52.5 82.4 25.7526
-55.7 12.9 24.818
-58.5 39.4 45.198
-29.5 48.9 31.6388
-55.45 33.4 41.09575
19.5 30.9 36.4398
53.35 57.9 54.9455
68 42.4 63.5834
-1 43.9 28.6962
44.8 70.4 48.249
12.5 73.4 55.3046
53.3 40.9 59.227
-36 62.9 24.765
-36 65.9 22.34
49 11.4 43.159
64.55 33.4 61.81175
-35.35 45.4 28.508
-31 46.9 31.5684
37.5 70.9 48.977
-23.5 60.4 29.049
-37.7 56.4 31.677
-63 15.9 30.514
-11.5 46.4 28.131
-7 48.4 29.1266
-5.5 52.4 28.9132
-45 60.4 35.4886
-21.1 44.4 31.155
65.8 6.4 67.959
50 10.9 43.7808
-54.5 42.9 43.6956
35 43.4 53.4496
-44 39.9 36.3932
-18 58.4 26.1138
61.4 6.9 57.86133333
-46.5 53.9 37.2214
-46.5 29.9 31.7756
-60 40.4 45.4344
64.5 30.4 64.884
-66 83.4 27.8542
63.4 9.4 60.72766667
-39.65 84.4 22.3075
43 13.9 39.693
-51 66.9 39.8146
-29 55.4 30.8152
-57.5 63.4 43.611
-50 66.9 39.7408
-57 9.9 20.575
50.5 9.9 44.359
-45 71.9 28.2876
16.6 29.4 36.327
70.9 50.9 65.088
-70 9.9 20.3692
-45 38.4 35.9608
-45 35.4 34.8258
-65 73.9 37.792
35.5 21.4 36.5572
68.7 12.4 71.102
16 73.9 54.7208
70.1 25.9 81.722
-39.6 45.9 39.781
53.2 70.4 49.88
-38.55 65.4 44.8105
-49.5 35.4 41.013
5.5 38.4 33.4458
-60.5 43.4 45.9072
-65.5 32.4 42.8948
-49 47.4 43.8262
-37.9 66.9 28.514
-1 47.4 29.1046
-69.5 62.9 43.7432
-23 56.9 30.3888
65.65 34.9 64.8985
-54 15.4 26.4014
-65.5 18.9 33.7782
58 65.4 52.1624
-66.45 47.9 45.778
62.35 8.9 59.247
-55.7 53.9 45.69
-27 46.4 31.5284
-62 30.4 42.0274
-47 41.4 36.7904
-59 57.9 45.2398
-69 87.4 20.5808
-48.5 71.4 28.7714
4.35 37.9 33.33
-45.5 67.9 31.5864
-65 43.4 45.9134
70.8 23.9 90.346
-47 58.4 36.2136
-49.65 49.9 43.736
10.95 69.9 57.61775
-68 11.9 24.5478
49.9 50.4 64.7
-8 58.4 27.0758
61.15 3.9 58.181
-37.3 64.4 23.7
54.15 8.4 47.112
32.65 43.9 54.9905
-59.5 49.4 46.2596
0.5 38.4 29.856
-12 43.4 28.36
-45.5 38.4 35.9718
-61.5 83.4 27.8938
-37 49.9 29.0354
-37.65 83.9 29.24
64.7 49.9 56.65
-65.5 77.9 34.348
24 26.9 34.329
30 23.4 35.2584
-13.2 47.9 23.267
3.6 61.4 61.15133333
67.05 10.9 69.8425
-43.4 50.4 36.82566667
-19.7 58.9 27.137
-55 60.9 42.2366
51.425 43.9 58.890375
45.5 12.9 41.148
-55.65 65.9 41.797
64.15 47.9 55.7665
-57 83.9 27.1134
9.2 51.4 60.46
-54 16.9 28.722
64.7 73.9 46.745
-40.95 32.4 32.2425
-57.5 10.9 22.7804
10 68.9 58.1706
68.2 23.9 76.19
-9 57.9 27.4286
-10.5 42.4 28.1742
-55 45.9 44.0414
-66 56.9 45.376
52.85 66.4 51.1185
70 43.4 63.6602
-3.5 65.9 27.292
-30 48.9 31.6318
65.1 46.9 58.79666667
60 33.9 60.6954
54.55 7.4 47.6885
59.5 64.9 51.9454
50 51.9 52.8894
64.5 52.4 56.8562
-68 68.9 41.0318
-56.05 12.9 26.2085
49.7 40.4 59.508
35.6 41.9 47.30433333
15.65 70.9 54.3295
55.6 6.9 48.66566667
44.45 50.4 53.11425
-57.5 10.4 21.7558
-52.5 23.4 35.1386
-46.4 21.9 31.047
-55 75.4 33.9306
-53.5 59.9 42.5474
8.5 44.4 28.2546
-54 35.9 42.0404
40.4 42.4 51.87033333
36.5 47.4 53.748
-54 35.4 41.8674
30.5 48.4 54.2896
60.8 5.9 57.803
5.5 57.4 61.8004
-36.1 83.9 33.772
72.5 49.9 87.055
-47.5 50.9 37.5382
46.5 41.9 56.2514
67.5 41.9 63.8026
-64 72.9 38.5398
65.025 66.9 53.51358333
70.9 53.9 67.107
-50 52.4 43.9754
-70 41.9 43.458
-41.15 45.4 41.3595
35 70.9 49.165
-61 11.4 23.7088
11 71.4 57.2506
-47.5 29.9 31.7764
-42.7 45.9 38.925
52 65.9 50.768
24 29.9 33.6856
-60 40.9 45.4982
-49.7 30.9 39.756
-49.7 43.9 43.656
6.35 55.4 61.615
-68 86.9 21.7272
65.85 14.9 68.8955
-49.55 61.9 41.3115
7.1 65.4 59.932
-9 47.9 29.0772
-57.5 58.9 45.0654
71.4 49.4 80.529
-42.05 83.9 16.317
-49.4 15.4 23.222
40.5 71.4 48.4088
-14.15 59.4 22.8635
10 34.4 36.9336
-32.5 46.9 31.4806
-49.35 41.4 36.9065
38 47.4 52.7078
-62 25.4 39.1924
-4 65.9 25.204
-64 67.9 41.5996
-61.5 46.4 46.176
70.45 16.4 71.83175
-53.5 70.4 37.765
66 24.4 70.3484
11 47.9 60.846
37.05 44.4 49.7555
-45.35 22.9 26.9195
-48 30.9 32.4544
-38.05 42.4 32.18725
-57 67.4 41.862
66.5 31.9 67.6168
20 29.9 34.3988
8.6 51.9 60.83233333
-49.6 72.9 35.044
-40.7 84.4 19.273
-59.5 11.9 24.6152
7.4 37.9 33.21166667
63.4 31.4 60.855
-63.5 50.9 46.1716
-63.5 31.4 42.445
9.5 47.9 61.1364
40.1 42.9 52.12633333
58.1 6.4 51.17033333
70 53.9 59.6844
69 17.9 73.2748
50.65 45.9 57.529
-64 33.4 43.3078
-51.5 21.9 33.2926
-67 58.4 44.2134
47 51.4 53.2544
-47 69.9 30.0702
-63.5 40.4 45.4218
-50 17.4 28.68
61.5 32.9 61.6016
36.5 42.9 51.7352
-36.65 79.4 43.466
-11 45.9 28.9074
-61.5 51.9 46.1168
-15.5 60.4 24.2978
-35.15 57.4 27.28
-53 44.9 43.9482
-48.5 59.4 35.8956
45.5 13.4 41.1336
-22 47.9 31.6008
-40.35 83.4 22.74
63.5 53.4 57.723
-47 61.4 35.1386
-29.65 86.4 38.9
-69 84.4 26.266
5.5 43.4 28.2616
52.8 48.9 63.053
30.6 22.4 35.11033333
47.9 50.9 55.166
62.35 6.4 61.823
-66 15.4 29.82
-50.5 79.9 29.2166
-12 61.4 24.8002
-44.5 36.9 35.4394
56 58.9 55.4242
-50 24.9 36.2012
56.5 70.9 51.1264
65.65 46.9 59.6705
67.35 72.4 51.465
71.6 53.9 75.95
-49.5 40.4 42.428
61 38.9 60.8862
-22.5 49.4 31.6038
11.5 71.9 56.881
22.5 71.9 51.3954
-15.2 48.4 19.365
64.2 30.9 60.479
-68.5 49.9 43.6924
-56.5 20.4 35.2666
-57.5 22.9 37.4084
-58 13.4 27.0574
3 47.4 28.064
-40.4 33.9 31.84
40.55 43.9 52.68425
-53 26.4 37.3536
-40.65 31.9 31.2735
-6 47.9 29.0912
-50 39.9 43.0884
59.625 32.4 59.016
-7.5 48.9 29.0872
66.3 62.4 54.401
-56.5 29.9 41.7946
-20.6 59.9 28.277
-41.3 23.4 24.988
56.5 8.4 48.2258
-49.65 63.9 40.826
52.85 39.9 59.2105
33.5 70.4 48.6986
-37 53.4 28.7322
70.8 54.9 66.97
-37.2 61.9 29.933
39 71.4 48.7394
32.4 44.4 55.11466667
49.45 43.9 56.8625
-13.65 60.9 24.3625
23.5 74.9 51.7666
66.55 9.4 69.3385
16.1 43.4 57.83233333
-13.05 61.4 24.516
-48 55.9 36.8184
-63 67.4 41.8186
57 41.4 62.353
32.6 45.4 56.617
-10 56.9 27.811
-68.5 77.4 34.7848
-47.5 48.4 43.9366
-52 52.9 43.9064
9.35 44.4 28.751
67.55 5.9 68.6955
3.7 50.4 51.04
53.3 40.4 58.757
-66.45 41.9 45.2615
63.8 24.4 65.159
-36.85 69.9 16.715
49.6 9.4 43.617
42 69.4 48.6106
55.6 72.9 49.27266667
-65 19.4 34.2746
-35.2 49.9 28.906
-31 49.4 31.6408
-64.5 83.9 27.14
46.3 10.9 40.924
21 27.9 33.706
-47.525 26.4 29.03008333
32.6 44.9 57.193
-33 53.9 31.195
-42.7 56.9 37.865
-67.5 69.9 40.436
-58.5 21.9 36.5784
-68 29.4 41.4548
-58 33.9 43.5278
-69 15.9 30.4902
-56.1 10.4 21.63166667
-52 83.4 24.092
-46.7 18.9 30.197
-37.05 74.9 44.6565
6.5 35.9 36.5564
-33.85 84.4 39.208
69.3 5.9 70.779
57.5 7.4 49.8816
11.95 47.4 60.608
61.15 7.4 56.903
62.1 8.4 56.872
-51 81.9 26.5144
48.6 54.9 53.1
33.7 42.9 50.377
52.3 54.9 56.181
-41.15 26.9 28.2865
40.5 44.9 52.239
-63.5 67.4 41.8348
30 47.9 54.7244
-48 25.9 28.5974
-21.5 54.9 30.9056
-15.5 51.9 28.1072
5.2 50.4 59.067
12 31.9 37.3156
40.5 16.4 38.6732
-15.5 42.9 28.5706
-60 41.4 45.5674
-55.7 30.4 41.078
-53 56.9 43.3202
-26 53.4 31.2064
7 33.9 36.5942
36 71.4 49.1664
-2.65 38.4 27.461
63.5 54.4 58.1606
36.45 42.4 48.7945
-19.7 47.4 28.467
65.5 30.4 67.8408
3.5 45.9 27.9076
68.55 4.9 68.64975
39.5 44.9 51.6814
-70 30.9 42.2176
67.2 12.4 69.644
-42.2 37.9 35.677
63.6 35.9 57.602
-58 57.9 45.246
37 42.9 51.7376
-46 63.4 34.2278
-57.5 28.4 41.0226
-58.5 60.4 44.6136
60 57.4 57.47
-52.5 22.9 34.258
70.6 25.4 87.318
51 10.9 44.1222
-55 29.9 39.4016
-64.5 51.4 46.1544
-61 47.9 46.2358
-62 76.4 35.7714
63.7 46.9 57.434
19.5 29.9 35.697
-59 51.4 46.1614
64.5 26.9 67.07
-65 44.4 46.0398
67.2 9.9 70.63
62.8 8.4 61.296
-50.05 85.4 20.12925
-61 48.4 46.2438
66.2 8.9 68.64
63.7 27.9 64.316
-33 54.9 30.997
-52 76.4 33.0522
4.4 52.4 60.79566667
53.35 6.9 46.3795
-52 76.9 32.5396
13.5 71.4 56.3408
28 47.9 54.5798
3.2 50.4 48.369
2.8 50.4 46.219
-53 41.9 43.5298
68.3 63.9 55.368
54.5 56.4 56.671
42 45.9 42.6526
-21.5 55.9 30.7108
37 44.9 49.7596
-57 71.9 39.269
11 34.9 37.0958
-43.5 41.4 36.7834
6 43.4 28.249
18.8 31.4 35.687
4.3 36.4 36.012
66 30.9 67.8868
68.7 49.4 61.46
-69 55.4 43.7672
31.65 43.9 51.834
-39.3 61.9 33.236
66 42.9 63.5568
54.85 5.9 47.409
24 29.4 34.118
50.45 55.4 53.2715
68.9 24.9 70.595
-44.5 37.4 35.6314
-61 12.4 25.4462
-19.5 45.4 28.273
-10 49.9 29.108
52.6 45.9 54.69666667
66.5 7.4 68.82733333
15.5 30.9 36.969
-9.5 47.9 29.1026
-13 55.9 27.276
64.4 27.4 66.188
-51 23.4 35.135
-39.4 45.4 36.267
-66 63.4 43.597
-49.5 38.9 42.079
58 35.9 52.882
57.5 67.4 52.7654
-34.15 45.4 29.4115
-69 86.4 22.7496
-69 54.9 43.753
33 70.9 49.2112
-8 47.4 29.0788
-50.5 32.4 40.6622
13 45.9 60.2816
57.85 6.4 50.9235
-37.15 78.9 43.209
71.1 54.4 70.239
70.8 54.4 66.582
-20.8 42.4 30.485
-70 31.9 42.6632
-52 25.9 36.9672
-67.05 38.4 43.71575
-67.5 42.4 43.6228
64.3 64.4 51.686
64.8 23.4 69.875
67.3 2.4 66.748
62.95 8.9 60.67
-37.85 53.4 28.9315
-55.4 66.9 39.8
-56.05 80.4 31.69875
-21.65 41.9 30.6025
-53 61.4 42.0858
-51 29.9 39.3844
20.5 28.4 33.8658
54.5 51.9 55.7928
28.925 72.4 50.508875
-67 25.4 39.1558
10 71.4 57.2352
-51.5 78.9 30.3078
-52.5 79.9 29.2272
-63.5 9.9 20.4344
-68 45.4 43.6058
-49.6 27.4 37.432
39 69.4 48.4902
-68 27.9 40.6696
-47.85 44.9 38.871
-57 27.9 40.7416
-58.5 28.9 41.2476
-61.5 27.4 40.4058
-47.3 44.9 37.419
12 36.4 35.9964
7.5 32.9 36.2166
49.9 71.4 41.208
-15.5 61.9 24.4704
-55 55.4 43.5904
-48.5 71.9 28.7714
-67.2 51.4 43.864
-46 39.9 36.3926
-61.5 55.9 45.6454
51.2 42.9 59.376
-63 84.9 25.5894
-35.1 54.9 27.84866667
-54.5 66.4 40.0516
-57 31.9 42.7416
11.5 44.9 59.018
-14.35 58.9 20.261
-62.5 11.4 23.6578
65.3 16.9 69.571
-47.575 24.9 27.355125
53.7 69.4 51.464
-46 27.4 29.951
-51 83.9 23.166
-50 18.9 30.7024
-23 43.9 31.2148
27 72.4 50.9128
-69.5 14.9 29.1518
-62 37.4 44.706
-23 49.9 31.596
-62 61.9 44.1548
-43.45 84.4 11.249
-60.5 29.4 41.4934
-38.65 31.4 27.285
-63.5 39.4 45.1734
48.5 41.9 57.2582
-64.5 33.4 43.329
65.15 5.4 66.2075
43.475 44.4 54.03125
65.15 72.9 48.2885
50.95 52.4 53.44375
-59.5 73.9 37.813
-56.5 22.4 36.9998
-23 57.9 30.106
-44.5 28.4 30.6874
-51.5 81.9 26.5584
40 49.4 53.585
-60.5 71.4 39.5908
-55.7 84.9 21.344
39.5 50.4 53.6132
-33 49.9 31.6548
-64.5 64.4 43.1904
-45.5 73.4 26.7248
67.5 24.9 70.4716
38.65 44.9 51.1425
-38.45 51.9 29.3755
63.8 29.9 63.729
-27 47.9 31.637
-47 32.9 33.5834
-47.5 65.4 33.1894
-34.65 46.9 29.4055
31.5 21.9 35.3646
51.55 8.9 45.13825
50.1 71.9 41.476
-70 41.4 43.4436
-11.5 44.4 28.7758
40.5 49.9 53.7264
-44.5 27.9 30.3058
16.5 45.4 59.193
-69.5 13.9 27.6712
-51 55.4 43.5608
-35.6 65.9 22.22033333
68.9 49.4 62.88
8.9 50.9 61.116
-68.5 22.9 37.3298
-12.5 42.4 28.2268
66.1 17.4 72.61266667
-48 54.9 37.0424
4.5 53.9 61.6496
70.6 25.9 87.379
-58 42.4 45.8026
-40.85 56.9 40.7365
-1.5 46.9 29.0798
-64 32.4 42.9102
-64.5 64.9 42.9852
65 31.9 57.7494
-36 62.4 25.1432
-68 46.4 43.656
0 44.4 28.6598
64.9 19.9 71.341
-39 50.9 31.391
-44.5 32.9 33.5878
-68 26.4 39.774
-27 52.9 31.2932
65.5 55.4 58.684
42 49.4 53.3414
66 3.4 64.2412
-24.5 49.4 31.6404
-37 44.4 28.788
67.4 16.4 70.89833333
-68 52.9 43.7544
6 64.4 60.6466
-16.3 57.4 18.734
-60 41.9 45.6866
50.5 54.4 53.9234
63.7 35.9 58.403
54.975 55.9 57.01583333
-3 48.4 29.0936
-51.5 47.4 44.0978
6.5 33.9 36.643
54 40.9 61.621
-65 76.9 35.2746
-63 11.4 23.6706
-3.65 43.4 28.411
2 65.9 50.153
-36.15 80.9 42.916
63.3 8.9 61.778
65.55 30.9 67.6815
-57.5 12.9 26.2832
-63 34.9 43.9036
-70 33.4 43.1842
60.5 46.4 60.6416
-37.85 47.9 32.2025
-50.5 31.9 40.4192
67.85 42.9 63.6095
38.85 20.4 36.601
-54 42.9 43.7174
-65 15.4 29.8336
46.7 44.9 48.646
-40.7 32.4 31.669
-11 55.9 28.1254
-40.5 66.4 46.053
-51 40.4 43.2726
-53 23.4 35.1184
67.5 43.9 63.7024
66.95 23.9 70.47825
-70 68.4 41.289
-52.5 77.4 32.0318
20.95 31.4 33.6375
-56.5 61.4 44.3376
-58.5 38.9 45.1108
34.65 42.4 50.1975
26.5 44.4 55.7284
64.3 32.4 58.24
23 45.9 56.8922
39.5 45.9 50.814
70.5 25.4 86.206
64.05 32.4 58.342
69.95 55.4 59.30375
52.45 8.4 45.8685
-43.45 48.4 38.2805
-39.1 62.4 31.906
-55.8 41.9 45.493
-42.65 57.4 37.884
-63.5 52.4 46.0892
15 29.9 36.5378
67.3 14.4 69.436
-45.5 23.4 27.875
-20.15 47.4 27.172
-56.5 86.4 22.7512
60.15 8.4 52.83
-15 45.4 28.1122
-22.5 57.4 30.2528
-3 60.4 26.2688
-49.65 57.9 42.7305
-58.5 73.9 37.806
-61 26.9 40.1166
67.8 9.9 71.461
-19.8 45.4 27.632
-41.8 58.4 38.605
-34.7 49.9 30.33
51 9.4 44.7384
-66.5 18.9 33.7812
-18.55 46.4 28.87725
-36.85 79.4 43.26
4.55 44.9 28.10475
-35.2 48.9 28.867
67.8 8.9 71.472
-52.5 24.9 36.2804
66.35 7.9 68.8345
-61 68.4 41.351
66.85 12.9 68.6845
-6.5 46.9 29.085
-42.2 54.9 32.805
45 14.9 40.4302
-41.35 64.9 45.938
-24 48.4 31.6306
68.5 18.4 73.1434
17.85 30.4 36.463
-43.5 26.9 29.4392
52.1 44.9 56.674
3.35 48.9 28.103
-55 14.9 26.7514
65.2 73.4 48.22
-62.5 21.4 36.123
-59.5 25.4 39.1842
4.1 35.4 36.36233333
-55.45 27.9 38.32025
-3.5 46.9 29.0586
-38.5 55.9 32.589
70.5 54.9 62.908
-15.2 56.9 18.873
-48 22.4 31.9066
-44 32.9 33.571
-24 44.9 31.3492
65 6.4 66.823
-66.5 70.9 39.8528
55 54.4 57.1404
-45.5 24.4 26.956
2.85 57.4 60.8515
57.1 61.9 42.404
-59.5 15.9 30.5458
-46 38.4 35.9676
51.8 44.9 57.649
55.9 7.9 48.43933333
-37 56.9 27.8696
57.5 46.4 59.7034
-59.5 38.9 45.1074
-30.5 51.9 31.4722
-34.5 47.9 30.25566667
-15.4 46.9 18.93633333
35.8 22.9 35.228
66 19.4 72.3256
-56.5 34.9 43.9346
-10.5 42.9 28.293
-64 36.4 44.4022
-33 56.4 30.6008
-65.5 47.4 46.177
-46 45.9 37.4416
70.5 49.4 74.196
-67.2 49.4 43.838
-69.5 15.9 30.473
-41.6 23.4 25.759
-57.5 50.4 46.2074
-61 71.9 39.252
63.5 52.9 57.3512
-40.5 67.4 45.9946
-58 70.9 39.9134
-41.7 57.4 39.477
-70 83.4 27.7468
-52.9 11.9 19.92366667
45.65 44.9 45.2065
-57.5 19.4 34.3804
71.5 50.4 74.509
35 41.4 47.3744
55.5 75.4 55.8592
51.5 49.9 63.3184
-57.5 63.9 43.4058
25 46.9 55.8202
-54.85 12.9 23.456
-39.8 70.9 45.686
-69 62.4 43.823
-37.05 76.4 44.62725
-49.3 44.4 42.496
-56.5 21.4 36.1672
-69 63.9 43.4126
59.15 6.4 52.146
-2.6 66.4 30.73
3.35 56.9 61.41
-66 22.4 36.9328
-19.7 45.9 27.897
-40.95 66.4 46.00175
-38.9 44.9 32.76666667
67.85 61.9 55.924
-70 55.9 43.7516
42.3 15.4 39.504
70 25.9 80.591
-60.5 75.9 36.2138
62.65 4.9 62.106
-35.1 54.4 28.10233333
14.5 73.9 54.8716
47 43.4 56.3658
-55 43.4 43.7604
25 46.4 56.217
3.8 66.4 56.413
-44.5 28.9 31.039
-61 83.9 27.1066
-14.15 58.9 20.696
-35.5 57.4 27.3082
-38.2 32.4 27.156
37.3 42.9 51.739
71.2 53.9 70.876
-40.5 65.4 46.0948
-25 44.9 31.3826
-63 29.4 41.4488
-43.975 57.9 36.51791667
-62.5 35.9 44.2332
-63 60.9 44.447
-28.5 50.4 31.5826
-49.4 22.4 31.81233333
-49 38.9 36.0954
-64 48.9 46.2332
-42.45 56.9 38.2535
-66.5 74.9 36.9744
-18 52.9 28.8046
26.425 25.4 34.547125
-56.5 39.4 45.2232
68.1 3.4 66.886
56 65.9 52.1182
61.5 69.9 53.119
2.5 45.4 28.2954
-67.2 48.9 43.838
-68.5 23.9 38.0858
-62.5 68.4 41.3352
-50.5 81.4 27.2574
-54.5 15.4 26.6052
72.2 51.9 82.105
70.7 24.9 89.039
-46.45 78.9 19.8285
-37.8 53.9 32.346
-58 70.4 40.182
-64 47.9 46.2104
-33.5 60.4 26.7966
-61.5 52.4 46.0784
68 17.4 73.115
-35.55 57.9 27.219
-42.5 36.4 35.236
-65 9.4 19.2196
65.45 48.4 58.79
-39.7 78.9 36.796
68.05 9.4 71.67075
57 67.9 52.6974
-45 30.9 32.4568
-45.5 66.9 32.2558
-43.55 54.4 36.937
-70 24.9 38.812
-50 35.9 41.9748
-49.3 16.4 25.882
66.05 33.9 63.152
-39.6 62.9 45.81633333
65.9 19.9 72.266
-69 48.9 43.6678
51.7 73.4 50.166
-4.4 53.4 28.25833333
-42 35.4 34.7808
53.7 48.9 58.458
-45 24.4 26.906
16 44.4 59.1668
16.5 73.4 54.8848
-55.7 37.9 44.553
-0.5 66.4 39.188
51.85 45.9 57.3265
-23.5 57.9 30.0974
4.3 38.9 32.938
-57.5 26.9 40.1758
55 41.9 62.0998
65.15 74.4 47.874
62.4 4.4 61.327
-25 60.4 29.0422
58.35 6.4 51.361
-38.35 83.4 28.682
-53.5 42.9 43.7094
-48 76.9 32.3368
67 60.9 55.7032
56.5 55.9 57.3926
-53 24.9 36.2474
38 71.9 48.7316
67.6 8.9 71.247
62.65 32.9 60.852
-22.5 44.4 31.2602
-68.5 32.9 43.0812
-44.7 79.4 23.989
69.5 24.4 78.061
-57 51.9 46.1386
46.3 15.4 39.561
-47.5 21.9 32.0292
-41.35 32.4 32.834
-61.5 82.4 29.3024
6.5 45.4 28.2002
21.5 71.9 51.7704
18.1 30.4 35.13033333
-9.5 48.4 29.0868
63.5 35.4 56.63
-17.65 51.9 28.6925
-37.4 43.9 29.757
-45.55 78.4 22.3535
45.85 11.4 40.935
72 51.9 79.616
50.425 71.4 44.44325
-50 40.9 43.2846
-53 77.9 31.5148
25 27.4 34.632
-43.8 21.4 25.008
49 11.9 42.9212
64.35 6.9 65.914
-50.5 14.4 24.378
-63 25.4 39.142
-60.5 39.9 45.3268
-47.5 39.9 36.4012
-64.5 49.9 46.223
54.925 56.9 56.547375
-56.5 68.9 41.0638
-63 28.9 41.2566
-69.5 44.9 43.5684
42.85 49.4 53.225
66.85 11.4 69.2375
-60 39.9 45.3264
-53.5 50.9 44.0642
39 70.9 48.8276
-57 86.9 21.8246
32.9 45.9 54.433
42.5 17.4 38.577
68.1 42.9 63.42966667
42.85 48.9 52.603
-69 21.9 36.5012
-19.45 48.4 29.00375
68.1 11.4 71.14033333
-56.5 78.9 33.4144
63.1 58.4 56.49533333
-54 38.9 42.9208
-44.95 20.9 25.828
-51 26.9 37.6652
-56.5 34.4 43.7562
-57 63.4 43.6316
36.5 48.4 54.0914
-67 85.9 23.8212
15.025 46.4 59.51858333
-33.5 81.9 42.1704
-57 86.4 22.8196
-40.7 44.9 42.011
-42.1 46.4 39.77766667
-49.7 63.4 41.157
63.8 35.4 58.546
72.8 49.9 89.63
-23.5 56.4 30.552
56.9 5.4 49.808
-41.6 30.9 32.26033333
36 42.9 51.7788
-20.85 60.4 28.4345
4.7 37.4 30.569
-49 27.4 29.9422
50.925 11.9 42.98525
66.3 32.9 62.678
-56.5 82.9 28.5946
49 41.4 57.2928
-42.8 63.9 44.52
-36.85 60.9 26.0485
49.15 44.4 57.706
-49 25.4 28.1706
40.4 42.9 52.41733333
55.7 5.9 48.609
69.5 54.9 59.3002
-65 41.4 45.614
-56.5 76.9 35.3184
69.5 43.4 63.584
-49.35 27.4 29.9395
-64 59.4 44.8944
27 74.9 50.4124
-57.5 70.9 39.9116
-62 47.9 46.2224
67 33.9 63.7518
-52.5 47.9 44.1056
-36.95 46.9 29.023
-57.5 65.4 42.7846
-53.575 11.4 19.50025
62.3 4.9 61.429
23.15 26.4 33.133
43.3 12.9 39.009
-61 41.9 45.7006
-42.9 46.4 38.736
-60 45.4 46.1238
52.5 72.9 48.586
62.2 7.4 60.827
-57 32.9 43.1364
-57.5 64.4 43.2408
10.7 49.4 60.418
60 40.9 62.6702
51.2 72.9 48.23
-63.5 37.4 44.6922
-14 50.9 27.171
-69.5 78.4 33.792
-59 43.9 45.9862
-65.5 19.9 34.7584
71 25.9 91.911
39.55 42.4 50.80275
56.6 5.9 49.656
-42.3 51.9 32.282
56.35 5.9 49.3795
-66.5 80.9 31.144
60 36.4 52.91
5 38.9 33.422
7.55 36.9 33.162
52.95 70.4 50.16275
-45 27.9 30.2984
-57.5 68.4 41.3406
-62.5 39.9 45.3168
-39.95 43.4 33.8645
-2 56.4 27.9816
66 31.4 67.6444
69 75.9 50.218
-65.5 55.9 45.6252
-7.5 48.4 29.1016
53.5 55.9 56.42566667
-61 9.4 19.2734
41 15.4 38.7928
53.3 55.9 54.447
57 8.4 48.3442
-67 87.4 20.6258
-65 42.9 45.8288
-49.925 51.4 44.01975
59.05 4.9 52.29475
58 69.9 52.2936
-29.5 58.4 29.927
65 51.4 56.8
-0.5 65.9 39.779
-53.5 43.9 43.8164
-58.5 61.4 44.3132
-65.5 16.9 31.65
22.5 43.9 56.93
64.1 46.9 55.915
66 13.9 66.881
3.3 65.9 55.501
-51 80.9 27.9552
-15.1 51.4 27.86433333
-61 54.9 45.7932
68.075 7.4 70.965875
-69.5 79.9 32.2694
-57 52.4 46.122
-60.5 73.4 38.1662
6.45 55.9 61.33175
70.9 25.9 90.774
-59.5 70.4 40.172
-32.5 45.4 30.7118
-67.5 84.4 26.3038
63.5 41.4 62.837
-62 24.9 38.8308
-39.5 45.9 38.668
65.1 3.4 63.60533333
-16.9 58.4 22.67
50.5 9.4 44.359
23 72.9 52.2814
-69.5 33.4 43.1542
3 49.9 46.137
-34.65 56.4 29.189
51.5 49.4 63.1796
-11.05 60.9 25.3345
40.55 14.9 37.807
6.4 58.9 58.664
48.5 42.4 57.2582
-17 43.9 28.5998
-40.1 60.4 41.445
-67 14.9 29.1192
-65 54.9 45.805
0.1 65.9 42.274
-20.8 53.9 26.035
63.5 39.9 61.6932
57 39.9 61.6868
16.925 73.9 54.5015
43.55 43.4 54.643
7.2 63.9 59.494
56.5 50.9 54.62
52 56.9 55.143
65.4 64.9 53.26266667
-50 25.9 36.9148
-44 54.9 36.7796
-1 46.4 29.079
12 32.4 37.4144
62.5 2.4 58.1644
-31.5 44.4 30.1354
-51.5 19.9 31.8018
-33.7 83.9 40.842
-65.5 33.9 43.493
-66 12.4 25.3834
67.3 5.9 68.463
38.8 16.4 37.467
-58 46.4 46.1998
-29.4 59.9 29.21433333
55.5 69.9 51.7286
-64.5 32.4 42.9134
59.3 34.4 58.443
63.8 3.9 62.931
-70 80.4 31.6836
-6.5 48.4 29.1172
-49.7 80.9 27.554
-51.5 20.9 32.3992
56.5 38.4 59.6128
-49.5 47.4 43.8246
-64.5 43.9 45.9814
-5.5 53.9 28.6338
-43.9 49.9 35.78166667
-48 79.9 16.2132
44.5 43.4 55.1448
11.5 35.9 36.5848
-55.9 10.4 21.29
-38.85 53.4 29.373
-2.4 38.4 27.651
45 43.4 55.4012
7.65 31.9 34.624
16 45.9 59.2992
7.45 31.9 34.2265
52 57.9 53.7814
27 25.9 34.8514
52.1 48.9 62.96733333
5.5 53.9 61.9934
-43.8 74.9 24.251
-38.95 74.9 44.6815
69.3 24.9 73.846
-50.5 12.9 20.829
3 50.4 47.296
57 56.4 57.2804
-64.5 47.9 46.2212
32.65 44.4 56.3835
-48.5 18.9 30.4676
46.45 49.4 60.1615
73 51.4 91.666
-54.55 10.9 19.1425
31 24.4 35.4268
46.65 49.4 60.6465
56.1 6.4 49.14966667
-14.6 47.9 18.49166667
-37.7 58.9 31.696
69 72.9 52.2638
-64.5 73.4 38.1772
8.475 68.4 58.98533333
-40.95 57.9 40.797
17 33.9 36.3286
-4.65 45.9 28.868
-60.5 24.9 38.8284
-40.8 57.9 41.223
-53.5 11.9 20.345
-4.5 51.4 28.7072
-45 25.4 28.122
-67 59.4 44.1646
52 66.9 51.1196
-48.5 30.9 32.4594
-5.5 45.4 28.9244
64.05 71.9 48.2565
41.8 14.4 38.91
-32.5 57.4 30.2636
-52.5 17.4 28.5468
-46 26.4 29.0116
-49.2 45.4 42.004
-14.5 42.9 28.3528
63.3 8.4 62.779
-40.65 56.4 41.8915
62.9 3.4 61.25133333
-9 45.4 28.9298
-66.5 22.4 36.922
64.55 4.4 64.33
49 10.9 43.2844
-60 28.9 41.2542
33 42.9 52.0976
-48 49.9 43.3254
-19.45 47.4 28.96625
-62.5 82.4 29.3228
-13.5 47.9 22.152
64.7 22.9 69.749
-61 10.9 22.7002
37.9 41.9 48.1375
51.95 44.9 57.1985
-61 46.4 46.1832
-27.5 58.4 29.9212
-43.05 41.4 36.72725
24.5 75.4 50.7228
-41.5 61.9 45.9476
-62 28.9 41.2636
-70 44.4 43.52
53 7.4 46.265
-24 58.4 29.9118
-65.5 26.4 39.8102
15 33.4 37.0336
-52 35.4 41.8778
53.5 53.9 56.517
-45.5 34.4 34.376
-10.95 46.4 28.84575
52 43.4 58.9434
-16.8 47.4 27.482
68.6 5.9 69.645
31.5 46.9 54.9404
72.3 49.4 86.875
-66 14.4 28.4144
61.5 42.9 63.2878
-50.5 83.4 24.0896
-50 49.9 44.0884
-48.5 73.9 32.972
59.85 5.4 53.3775
72 50.9 79.117
-53 27.9 38.2496
-65.5 82.4 29.2606
-20.3 58.4 28.451
20.5 43.9 57.586
-61.5 15.4 29.8646
-61.5 13.9 27.7432
-38.5 83.4 28.238
-12 47.9 27.836
13.5 31.4 37.1206
64.8 61.4 52.449
-68.5 54.4 43.7546
8 48.9 61.3086
64.05 27.9 65.23375
33.7 43.9 54.995
-14.5 51.4 27.303
3.95 53.4 60.846
-42.95 84.4 12.716
53 8.9 46.177
5.4 58.9 61.63166667
45.85 42.9 55.9835
52 46.9 55.981
-69.5 21.4 36.1068
-52 21.4 33.3112
59 40.4 62.248
38.5 18.9 37.7172
-60 47.9 46.2416
-52.5 60.9 42.2782
-49 23.4 31.2522
-63.5 53.4 45.9822
48 40.9 56.2838
-66.6 44.9 45.501
55.5 42.4 62.2096
3.5 66.4 55.191
-69.5 8.9 17.2436
-31.5 57.4 30.2354
-36.5 76.9 44.3226
-56.05 9.9 20.32375
-37.05 79.4 42.772
-50.5 57.9 43.086
-59.5 77.4 34.8672
6.6 42.4 28.47266667
-50.5 13.9 22.6274
-62 67.9 41.563
51.55 40.9 58.903
-67.5 18.4 33.2688
42.6 67.9 47.60433333
66.65 9.9 69.3355
-38.55 65.9 44.7715
64 42.9 63.1694
-17.5 52.9 28.774
9 35.9 35.8546
-63 21.4 36.106
-53.85 21.4 33.2015
-48 36.4 35.26
40.7 14.9 38.112
69.125 5.9 70.36025
-55 50.4 44.1298
54.15 49.4 60.1955
-20.65 41.9 29.192
5 57.9 61.9622
71.1 49.9 75.053
-38.5 58.4 31.9942
-64 82.4 29.286
7.5 43.4 28.4758
37.7 16.9 36.569
-66.5 15.9 30.4546
22 72.4 52.3138
51.5 10.9 44.2352
30.5 72.4 50.183
-64.5 29.4 41.467
-61 46.9 46.2094
72.1 52.4 80.839
-55.1 25.4 36.64966667
71.9 52.4 78.32
-19.65 55.9 27.323
18.425 44.9 58.49325
-53.4 10.9 18.45833333
-49.35 25.9 28.7385
53.55 70.9 47.74
54.6 7.9 47.647
-38.95 75.4 44.56825
-66 34.4 43.6934
-49.5 30.4 38.682
-64.5 58.4 45.147
-53 56.4 43.3866
-34.7 58.9 27.87
-55.8 12.4 24.91
-57 45.4 46.1572
-61.5 9.4 19.2496
-27 59.9 29.2876
-52 68.4 38.956
-39.35 83.9 24.224
-69.5 9.9 20.3992
4.5 43.9 27.9834
-19.65 56.4 27.198
-50 58.9 42.8284
-48.5 36.9 35.4576
-69.5 62.4 43.8192
-13.5 44.9 28.158
-70 11.4 23.6034
43 14.4 39.8366
-62 67.4 41.83
5 39.4 32.9374
-47 50.9 37.5414
33.5 72.9 49.454
69 14.9 70.8122
-61.5 63.9 43.4222
64.3 35.9 63.227
17.5 74.9 53.3388
18 44.4 58.592
-44.5 46.9 37.5596
-39.5 65.4 45.8318
-1 46.9 29.102
57.9 8.4 48.361
-67.5 53.4 43.8154
-46.65 73.9 32.732
-37.95 66.4 29.3725
67.3 34.4 63.928
-55.45 22.9 34.78925
-59.5 79.9 32.344
-35.05 48.9 29.2575
-45.5 25.4 28.1324
67.7 23.9 73.458
-53 13.4 23.2932
-1.075 59.9 26.504
5.5 43.9 28.1726
60.05 7.9 52.278
65.7 4.4 65.606
8.3 43.9 30.531
-56 54.4 45.8662
37.5 48.4 53.9086
70 15.9 71.795
63 33.9 61.2408
-3.5 50.9 29.0734
67.5 61.9 55.6976
63.1 46.9 59.771
52.35 72.4 47.1015
65.55 48.9 58.75975
-21.2 45.4 31.394
1.5 48.4 28.4492
-15.3 56.9 19.388
0.35 65.9 43.3095
44.1 12.9 40.17466667
-69 19.4 34.2784
-21.5 60.9 28.5798
43.85 12.9 39.9235
-51 70.9 37.4324
72.4 52.4 84.65
50.9 7.9 43.857
52.5 11.4 43.2156
0.5 65.9 43.93
-53.5 12.9 22.6512
62.5 59.4 55.1516
-57 53.4 45.9888
-11 60.4 25.3942
-52 16.9 28.4002
-62.5 13.4 26.9862
-34.45 83.4 40.214
-61.5 34.4 43.7138
-61 49.4 46.2396
-20.9 47.9 27.488
61.5 59.4 55.311
65.5 55.9 58.684
3.3 66.4 54.401
-66.5 66.4 42.3254
-17 54.9 28.4012
-9.5 61.4 25.3152
-35.7 83.9 34.935
61.05 5.9 58.16825
25.5 26.9 34.66
-8.5 44.4 28.7672
52.7 49.4 63.918
-27 44.4 31.2524
-57 69.9 40.493
51.4 72.9 48.67933333
-58 10.9 22.7714
-49 26.4 29.1068
-49.65 55.4 43.2915
-1.1 60.4 26.31833333
-47 33.4 33.8762
44.5 14.9 40.3242
-46 38.9 36.141
-42.35 56.4 38.668
35 46.4 53.9378
62.45 53.4 58.14225
43 68.9 48.5282
-10.5 45.9 28.975
63.8 2.4 60.925
-33.95 48.9 31.61025
-66.9 57.4 44.38183333
-57.5 45.4 46.1544
-18 43.4 28.88
42.3 44.4 53.508
46.5 52.9 53.715
-47 38.4 35.9436
58.05 5.9 51.21925
51.6 8.4 45.17266667
-37.15 60.9 30.187
63.4 2.9 61.317
68.575 74.4 51.498875
-13.2 59.9 23.402
-19 46.4 28.9868
-49.65 67.9 38.7995
-42.5 37.9 35.7216
70.8 24.9 90.126
-70 34.9 43.2568
63.9 24.4 65.662
-39.15 83.4 26.3095
-65.5 80.4 31.7184
-53.5 39.9 43.1332
-25 58.4 29.909
57.5 7.9 49.2824
-70 54.9 43.7356
-1.45 65.9 35.806
-67 27.4 40.384
67.45 46.4 61.43825
-9 47.4 29.0732
-68.5 63.4 43.5742
68.3 36.9 66.229
-20.8 52.9 26.178
37.6 45.9 50.15933333
72.5 42.9 63.6798
-56.5 36.9 44.5616
-38.65 83.9 26.2955
-60.5 26.4 39.81
-25.5 43.9 31.1372
53.6 73.9 52.888
-38.15 79.9 39.837
-68.5 29.4 41.4544
66.1 50.9 58.65466667
-64 83.4 27.8568
40 19.4 37.2414
-10.8 60.9 25.659
-65.5 81.4 30.5538
66.5 50.4 59.2202
-51 36.9 42.3486
-35.7 32.9 22.855
6 46.4 28.203
-54.5 74.9 34.3924
9.15 50.9 60.906
43.6 41.4 53.644
70.5 74.4 52.0642
-67 63.4 43.6046
63.3 31.4 61.379
55 7.9 47.8734
-50.975 10.9 17.01875
66 24.9 70.1306
-12.5 44.9 28.7114
-58.5 55.9 45.6572
49.5 69.4 48.468
-68.5 10.4 21.5412
24.5 73.4 51.8942
-48 21.4 32.049
-64.5 72.9 38.54
-61.5 62.4 43.9648
-54.6 25.4 36.354
-37 45.4 28.8708
-1.5 43.9 28.652
-22.5 54.4 31.0528
26 73.4 51.3994
17 32.9 36.7454
65.15 5.9 66.676
59.9 32.4 61.471
17 45.9 58.871
-49 34.9 34.6142
-54.5 63.9 41.1684
67.5 33.4 64.228
-46.5 28.9 31.0754
-50.575 15.4 25.004875
-67 10.4 21.5846
-38.5 60.9 31.4492
-64.5 65.4 42.7736
-62 81.9 29.9536
-20.05 54.9 26.7255
65.5 25.4 69.7954
-41.95 51.9 30.767
64.9 5.9 66.31433333
38.5 71.9 48.5764
5.45 46.9 27.778
-42.65 39.9 36.299
-63 38.9 45.0836
40.9 48.4 52.337
60.5 56.4 58.1106
-40.95 47.4 41.29825
-30 47.4 31.5752
-42.5 27.9 30.2862
-53 41.4 43.4182
-47.5 32.4 33.321
-57.5 42.4 45.7972
-51 45.4 44.0014
-62 11.4 23.6824
-14.3 48.4 21.744
-44.5 51.9 37.437
66.5 44.9 63.0878
8.5 48.4 61.212
48.6 9.4 42.283
-66.5 78.4 33.84
3.5 62.4 60.7724
-36.5 43.9 28.675
-52.5 32.4 40.6324
-43.2 60.4 36.273
-39 72.9 45.4096
63 70.9 52.1514
59.6 31.4 58.96466667
52.35 51.4 55.2545
3.35 61.4 60.9265
70.4 23.9 88.168
-44 26.4 28.9878
-59 20.9 35.7096
63.7 51.4 54.191
60.4 7.4 51.311
-37.3 78.4 43.249
65.4 15.4 67.903
-55.4 60.4 42.42333333
-6.5 55.9 28.106
-43.5 31.4 32.7534
-53.025 15.4 26.004
42.425 17.9 37.990625
-1.5 45.4 28.8968
-0.6 38.9 28.94466667
65.95 60.4 54.8065
-35.6 37.4 26.19166667
-65.5 37.4 44.715
54 8.9 46.7486
58 67.4 52.8692
5.3 50.4 59.606
-46.3 76.9 22.125
-59.5 30.4 42.035
-53 30.4 39.653
-67.15 34.9 43.4845
52.55 47.4 58.74075
5.45 62.9 61.1085
45.95 44.9 46.201
-39 60.9 31.4508
-36 43.9 28.634
-67 30.9 42.2116
35.5 21.9 36.3094
-46 55.9 36.8496
64.3 24.4 67.733
-39 60.4 31.6818
-70 85.4 24.6644
14.5 32.4 37.1912
-9.5 56.9 27.8178
-67.5 15.4 29.831
-65.5 25.4 39.121
-37.3 44.9 29.549
63.35 3.4 61.8935
-64.5 59.4 44.911
56 71.9 47.0664
-63.5 40.9 45.4844
-64 11.9 24.5308
-58.5 42.4 45.7952
73 48.4 91.616
40.55 68.4 47.6745
53.5 73.4 50.0724
58.05 5.4 51.21925
-37.5 76.4 44.7168
-60.5 37.9 44.826
-11.5 42.4 28.2972
49.35 44.9 57.2945
-47 46.9 43.655
69.6 23.9 83.815
20.05 30.4 34.3935
-45 72.9 27.2798
-31.2 84.9 40.212
-66.5 82.4 29.2558
-63.5 41.4 45.5762
-65 78.9 33.3512
6.3 46.4 28.226
-28.5 46.4 31.5102
53.1 45.4 57.145
-24 55.9 30.6968
-5 45.4 28.8664
5 37.4 30.5702
-33.95 44.9 29.1115
43.5 16.4 39.329
68.85 5.9 69.9245
-63.5 55.4 45.7362
-53.5 69.9 38.1058
69.5 8.4 72.3428
53.1 36.9 51.95233333
36 46.9 53.892
-44.5 46.4 37.5138
-48.5 72.9 32.2676
-49.65 27.9 37.8315
-11.95 49.9 28.10075
69.5 71.9 52.8328
-55.7 39.9 44.929
-25 45.4 31.4076
-59 51.9 46.134
53 10.4 44.952
-63 13.9 27.7288
-58 60.9 44.4674
-16 46.4 27.8704
39.4 42.9 51.38866667
-56.5 25.4 39.2332
-20 59.9 27.286
-17.5 56.9 26.8014
3.6 56.9 61.63266667
11.8 68.9 56.17
-58 73.4 38.2252
-57.5 41.9 45.7116
56.475 44.9 61.51591667
66.85 73.9 50.4465
-8.5 47.9 29.0838
-43 34.4 34.358
60.05 32.9 61.40925
-66 14.9 29.1244
-65 81.4 30.5518
65.8 33.9 62.995
-59.5 46.9 46.2122
-69 80.4 31.672
57 71.9 48.9582
-64 35.4 44.0752
-65.925 38.9 45.002
-9 42.4 28.1472
-68.5 38.4 43.3842
-54 74.4 34.8128
-58.5 23.4 37.7624
67 48.9 60.1842
-48 27.9 30.3412
71.5 52.4 73.255
-40.85 84.4 18.839
-19 53.9 28.5826
-45 49.4 37.6054
62.65 4.4 61.767
-59 81.4 30.6326
20.8 47.9 56.395
-68.5 37.4 43.3602
53.3 45.4 59.344
48.6 44.9 54.823
-17.475 44.9 28.6105
-41.35 49.4 34.8745
-61.5 78.9 33.3858
-68 56.9 43.7962
40.35 48.4 52.6725
34.45 44.4 54.87
-52.5 45.4 44.0128
-60.5 68.9 41.0724
-60 38.4 44.9628
-50 45.9 43.9852
-12 44.4 28.76
-69.5 15.4 29.818
5 58.4 61.8996
-56.5 41.4 45.6238
-63 29.9 41.7264
-57.5 77.9 34.402
-67 75.9 36.1922
67 66.4 53.8854
64.65 72.9 46.7745
-9.5 44.4 28.7508
65.15 19.9 71.708
64.7 20.4 70.143
-54 19.9 31.8898
-52.5 68.9 38.671
22.4 73.4 52.60266667
-6 44.9 28.8328
55.5 44.9 61.2824
-22.5 47.9 31.6108
38.4 44.9 50.94133333
-12.3 48.9 27.114
-39.6 47.4 41.906
-21 45.9 29.116
42.55 14.9 39.62025
59.9 8.4 52.304
-69 70.4 40.161
-55.7 14.4 27.194
-52.5 47.4 44.0964
-54.5 40.9 43.3694
35 72.9 48.9602
46.5 42.4 56.3138
53.65 36.4 51.6045
18 33.4 36.2716
-57 52.9 46.0782
-46 77.9 19.7414
-36 45.9 28.9472
22.4 73.9 52.60266667
66.475 3.4 64.50133333
5.35 67.4 58.9185
70.5 51.9 60.868
-59.5 31.4 42.4702
-26 48.4 31.6066
47.9 49.9 63.21866667
-36.4 37.4 26.45333333
-65 67.9 41.5782
-59 54.9 45.8132
-70 33.9 43.2042
66.5 5.4 67.774
-27.5 50.4 31.5842
-42.3 84.4 14.618
63.6 51.4 54.865
-65.5 28.9 41.2486
7.5 33.9 36.5704
-52 51.4 44.0538
-46.8 45.4 39.595
-46.35 72.9 27.2465
-60.5 41.9 45.693
48.1 10.4 42.62566667
72.4 51.9 84.609
-60.5 19.4 34.3122
63.3 38.9 60.125
-58 54.9 45.8132
-14.5 54.9 28.4194
-67.5 87.9 19.3266
-53 12.9 22.3552
-66.5 87.4 20.663
57.5 65.9 52.341
-25.5 48.9 31.6048
-53.5 46.4 44.0642
-20.65 60.4 28.1245
67 43.4 63.7956
-37 69.9 17.548
15.5 35.4 35.6696
53.8 7.9 47.004
0 44.9 28.739
31.65 42.9 50.5205
-49.65 24.4 35.3625
-65.5 40.9 45.4736
62 39.9 62.3288
-39.9 56.9 42.207
-70 30.4 41.9834
-11.55 56.9 26.852
-10.6 58.9 26.235
-45 36.9 35.45
-54 71.9 36.7284
-19.375 55.9 27.951
-43.7 47.4 37.978
-57 54.4 45.874
-43.85 78.9 26.3685
22 73.4 52.7438
-49.6 24.9 35.429
-59.5 17.4 32.3038
67.5 48.9 60.38
42.5 69.4 48.6196
-65.5 16.4 31.0894
-39.7 79.9 35.846
32.5 72.9 49.7204
36.5 70.4 48.8968
-58 19.9 34.8344
-48.5 19.9 31.5912
-66.025 41.9 45.52191667
-49.8 13.9 21.762
-54 33.9 41.2596
-18 59.9 25.4138
3.65 56.4 61.5975
-37.35 78.9 42.708
71.1 49.4 78.419
60.7 7.4 52.682
62.05 7.4 60.315
65.95 9.9 67.6745
65.85 1.9 60.9085
12 32.9 37.4856
-17.6 44.9 28.368
-51 65.4 40.4862
-42.6 51.4 36.211
64.43333333 72.4 46.76966667
7.5 44.9 28.1888
-27.5 52.9 31.3062
60.5 39.4 61.6258
41 49.9 53.6262
51.65 48.4 60.1965
62.625 5.9 62.3935
-27 45.4 31.4408
63.2 2.4 60.261
66 44.4 63.2508
-51 26.4 37.3542
-37.6 55.9 31.151
-41.4 46.4 40.688
-12.7 47.9 25.175
-56.5 69.9 40.4978
3.5 47.9 27.8566
-65 55.9 45.675
67.3 10.9 70.361
66.65 65.9 53.817
64.65 48.4 57.166
-57 30.4 42.062
-67.2 46.9 43.795
60.85 4.4 57.9215
-13.9 48.9 25.25133333
63.9 2.9 61.89633333
63.525 34.4 61.92891667
66 34.4 63.1402
-62.5 73.4 38.1784
43 49.4 52.889
43.55 42.9 54.643
-57.5 84.9 25.5994
-58.5 29.9 41.7774
35.65 42.4 48.792
18 27.9 35.1632
-61.5 13.4 27.0302
62.9 71.4 51.71866667
-48 31.4 32.7856
-55.6 70.9 38.214
66.95 13.9 68.6875
-38.5 83.9 26.738
49.2 50.9 62.42
-49.6 15.4 24.383
-43 58.4 36.7312
45.3 12.4 40.986
-10.5 51.4 29.0298
61.1 3.4 57.748
65.6 59.4 54.685
62.3 3.4 60.414
-68 72.9 38.5032
58.5 40.9 62.4176
64.35 3.4 62.9625
69.7 49.4 68.533
69 16.9 71.7514
-67.5 9.4 19.1754
-51 73.9 35.2352
49 69.4 48.4936
-32.2 60.4 27.491
-45.5 28.4 30.7054
-33 46.4 29.8724
47 52.4 53.7072
-51.5 47.9 44.1118
-47 42.9 37.0846
-58 79.9 32.3816
73 48.9 91.664
62.35 5.9 61.823
42.55 15.4 39.634
17.5 31.4 36.7932
-40.15 62.4 45.9155
65.5 34.4 62.8844
62.05 3.9 60.4365
-58 38.4 44.9552
-4.35 56.4 27.3115
63.6 3.9 62.699
65.3 3.9 64.503
64.4 60.9 51.324
-64 60.9 44.4494
69 9.4 72.1964
51.9 45.4 57.27
-67 62.4 43.9114
-62.5 10.4 21.6194
69.9 25.9 79.461
48.975 55.4 52.48158333
-58.5 32.4 42.9592
65.15 4.9 65.6635
-48 18.9 30.4842
68.5 12.9 70.8252
60.7 4.4 57.695
61.1 4.4 58.31166667
17.55 29.4 35.826
-57.5 25.4 39.2056
-68 45.9 43.6308
-41.05 83.4 20.689
-58 59.4 44.899
-40.85 83.4 21.276
-47.5 57.9 36.3402
64.3 4.4 64.015
66.5 4.4 66.323
67.525 4.4 66.99508333
68 36.9 66.0976
-48 43.4 37.1814
68.4 4.4 66.274
68 35.9 66.2354
68.6 4.4 67.91
-63.5 54.9 45.8174
49.35 9.4 43.353
-45.5 64.4 33.7454
66.05 6.4 68.157
53.6 54.9 56.689
-24 60.9 28.7706
-4.5 42.9 28.3136
3.575 45.4 28.035375
41.5 50.4 53.7082
-68 13.4 26.9518
-7.5 45.9 28.978
57.2 4.9 49.647
60 35.9 52.91
43 14.9 39.8366
62.45 32.9 61.335
-60.5 62.9 43.7848
-37.4 32.4 25.716
-16.45 44.9 28.158
-66.2 48.9 45.99
-58 46.9 46.2242
-56.05 12.4 25.3545
-7.025 51.9 29.01666667
51.05 57.4 54.2755
-36 38.9 27.0618
56.6 5.4 49.40383333
60.1 5.4 53.706
3 66.4 53.212
-5.5 44.9 28.8256
61.4 5.4 58.33966667
5.8 38.4 33.467
62.35 5.4 61.7425
6 66.9 59.7264
-53.5 21.4 33.3034
-58.5 10.4 21.727
63.85 5.4 64.2865
64.1 5.4 64.673
64.05 36.9 64.9245
65.85 50.9 58.367
-67 83.9 27.0398
64.9 5.4 65.86966667
68.4 5.4 68.814
53 41.4 59.4052
29.5 43.4 53.3492
-64.5 9.9 20.4306
-28 46.9 31.5816
64.1 6.4 65.27666667
-38.45 84.4 25.8135
46.05 49.9 57.3185
-58 48.9 46.2472
54.55 5.9 46.813
52.45 7.9 45.866
-68.5 83.9 26.9972
4.55 34.9 36.607
55.9 5.9 48.83933333
57.8 5.9 50.924
61.85 5.9 60.685
62.1 5.9 61.24133333
-65 71.9 39.2324
64.15 5.9 65.114
-33.5 83.4 41.8666
-65.5 41.4 45.5698
65.65 60.9 54.2
65.3 5.9 66.892
4.05 62.4 61.133
67.85 5.9 68.9615
-63.5 20.9 35.6792
-58.5 47.9 46.2466
52 11.4 43.4646
-49.5 58.4 36.885
-49.5 59.9 41.895
64 69.9 53.0778
-42.15 51.4 35.653
3.7 36.4 34.728
-66.075 50.4 46.014875
64.075 44.9 62.5115
38.975 19.4 37.50883333
-54.5 12.9 23.245
-66 29.9 41.729
43 50.4 53.48
66 1.9 61.792
53.5 6.4 45.844
53.8 6.4 46.433
-37 46.4 28.9862
-57 19.9 34.8514
55.35 6.4 48.4125
57.35 6.4 50.4395
58.9 6.4 51.909
-42.6 84.4 13.741
-62.5 85.9 23.8532
61.5 6.4 59.738
37.5 71.4 48.977
61.65 6.4 60.1385
63.85 6.4 64.811
0.5 43.4 28.3944
-32.8 82.9 41.504
68.075 6.4 69.897375
69.3 6.4 70.946
67.8 13.9 69.944
-15.5 52.9 28.1564
44.55 13.4 40.6455
14 35.9 35.749
-62 39.4 45.179
-32.5 45.9 30.7196
-53 20.9 32.4178
-43 29.4 31.442
50 11.9 43.095
-51.5 45.9 44.026
-65.5 52.4 46.076
53.15 6.9 46.1105
54.1 6.4 46.90916667
52.9 71.9 46.83566667
54.3 6.9 47.445
-58.5 37.9 44.8484
-56.5 62.4 43.9976
57.075 6.9 49.952375
59.525 6.9 51.97508333
58.425 70.4 52.04225
17.7 28.4 35.019
-8.5 50.9 29.0696
-34.65 48.4 30.159
-58 17.4 32.3038
62.05 6.9 60.84
62.2 6.9 61.274
51.8 50.4 66.717
62.3 6.9 61.518
-69.5 40.4 43.4164
68.05 14.4 70.21725
65.575 6.9 67.934875
-66 70.9 39.8646
-55.5 20.9 32.9374
-3 59.9 26.4676
-51.5 46.9 44.0708
34 44.9 56.4446
-53.5 73.9 35.2616
51.7 7.4 44.331
52.3 7.4 45.438
53.5 7.4 46.7616
58.35 4.9 51.3615
53.55 38.9 59.3485
-17.55 42.4 28.6365
-45.5 60.9 35.302
56 7.4 48.8278
-10.5 43.9 28.7148
-55.075 23.9 35.51525
-61 67.9 41.6026
-59 82.4 29.329
67.575 7.4 70.43225
53.6 67.4 51.207
69 7.4 71.6878
36.8 44.4 49.49
17.5 33.9 36.194
69.85 8.9 72.505
-41.55 79.4 31.738
60.1 31.4 59.839
-49 72.9 32.2246
59 69.4 52.7706
64.45 8.9 65.699
71.5 43.9 63.4192
65.05 9.4 66.8785
61.5 51.4 55.8712
-55.8 10.4 20.936
62 42.9 63.3614
-13.5 53.9 28.6416
52.2 7.9 45.619
53 7.9 46.3374
53.5 7.9 46.7616
-3.7 66.4 26.306
58.075 7.9 49.5005
58.5 71.4 50.8936
59.55 7.9 50.798
59 70.9 51.7134
60.7 7.9 54.206
23.5 45.9 56.7698
-58 34.9 43.9104
62.125 7.9 59.85525
63.15 7.9 62.9065
-49.7 72.9 35.67
64.8 6.4 66.486
64.7 7.9 66.7
-49 24.9 27.6492
3.575 55.9 61.440875
67.15 7.9 70.18
37.05 43.9 49.74325
5.95 56.4 61.66775
14 72.9 55.8098
-53 49.4 44.1178
58.55 6.9 51.1765
59.3 8.9 52.432
-50.05 23.4 35.113
-17.5 53.9 28.6178
7.5 33.4 36.4556
50 8.4 42.984
50.15 8.4 43.318
53 8.4 46.3364
68 18.9 72.9232
-43 32.4 33.3358
8.95 51.4 60.78625
57.5 8.4 48.3776
15.5 29.9 36.566
58.65 8.4 49.678
58.05 73.4 49.92875
58.8 8.4 49.993
61.125 8.4 54.883
62.65 8.4 60.7565
63.8 8.4 64.268
66.3 8.4 68.86
57.5 6.9 50.3084
65 8.4 67.26966667
-49.7 54.4 43.604
66.7 8.4 69.636
67.125 8.4 70.39325
67.975 8.4 71.48625
55.1 8.4 47.61233333
-55 34.4 41.4744
-57.5 68.9 41.0706
26.35 43.9 55.099
-55 19.4 31.3474
-12.8 44.9 28.655
-43.5 56.9 36.8358
47.15 50.9 52.4645
-4.9 41.9 28.07466667
-10.65 47.9 28.189
-20.9 48.4 27.546
-4.9 60.4 25.96433333
46.85 42.9 56.483
-53.5 36.9 42.3568
-46.5 67.4 31.9312
54.575 8.9 46.973375
-39.05 78.4 38.827
56.95 8.9 47.32275
57.45 8.9 48.285
57.65 8.9 48.7325
-49.8 23.4 34.906
71.35 72.9 52.6895
60.15 8.9 54.342
62.4 46.4 61.10033333
61 8.9 56.237
-46 66.9 32.3184
-66 13.4 26.9458
62.625 8.9 59.863
-40.9 45.4 41.752
63.15 8.9 61.236
63 44.9 62.3056
63.45 8.9 62.333
63 41.4 62.7746
63.6 8.9 62.885
64.65 8.9 66.2545
65.1 8.9 67.31766667
66.5 8.9 69.26066667
66.8 8.9 69.872
-65 83.4 27.865
-51.5 41.4 43.4462
-49.65 34.9 41.3215
-67.5 54.4 43.8474
56.45 75.4 56.2105
-69 65.4 42.7436
-50.5 84.9 21.235
45.5 41.4 55.2988
70.3 25.9 83.993
-69.7 9.4 18.328
-68.5 9.4 19.1366
-65 17.9 32.765
-66.5 9.4 19.1668
25.5 74.4 51.3756
-62.5 9.4 19.2112
-62 9.4 19.1934
-57.5 9.4 19.3206
-56.5 9.4 19.3188
46.3 45.4 47.781
51.3 9.4 44.974
71.8 53.4 77.884
52.5 9.4 45.6756
54 9.4 46.3098
54.5 9.4 46.4286
67.35 8.9 70.932
55.85 9.4 46.304
63.5 40.9 62.5728
63.8 9.4 62.202
-53 61.9 41.9326
19 74.4 53.5934
-34.95 57.4 27.8385
65.525 9.4 67.50408333
67.6 9.4 71.247
49.05 50.4 61.2035
-47 34.9 34.5872
46.1 50.4 52.011
70.1 41.4 64.252
-56 47.4 46.1816
-65.5 14.9 29.1096
67.55 13.9 69.67575
-40.4 71.4 45.21266667
-35.15 47.9 28.9005
-67 80.9 31.1346
-68.5 9.9 20.4024
-68 9.9 20.3916
71 55.9 70.718
-60 9.9 20.485
-62 19.4 34.3218
56.15 5.4 48.7335
-58.075 9.9 20.524875
48.5 9.9 42.753
-50.5 46.9 44.0806
49 9.9 43.2406
50.15 9.9 44.1095
16.5 44.9 59.193
-42 34.9 34.607
22.65 73.4 52.496
-60.5 56.9 45.4824
-56 65.4 42.7532
64.2 9.9 61.998
3.9 50.4 52.103
29.5 45.4 55.1682
64.5 9.9 63.805
6.5 45.9 28.2068
66.85 9.9 69.804
-63.5 41.9 45.708
-25 48.4 31.6022
61.2 7.9 55.692
-60.5 15.4 29.8796
-20.85 49.4 26.584
10.5 33.4 37.3614
-69 10.4 21.5562
-62.5 16.4 31.1146
-68 10.4 21.5506
41.05 41.4 50.3275
-64.5 10.4 21.5984
-69.5 87.9 19.354
-64 10.4 21.6124
-50 65.4 40.4564
-63.5 10.4 21.6112
-61 10.4 21.664
64.2 72.4 47.276
-61 38.9 45.096
-61 27.4 40.4148
-56.025 11.9 24.42125
-69.5 67.9 41.576
-56 24.4 38.3966
36 47.4 53.9
59.2 8.9 52.207
-59 76.4 35.7894
-58.5 11.4 23.7156
-52 79.4 29.8132
47.65 10.4 42.1655
-1 44.4 28.7286
50.4 71.9 44.962
51.5 10.4 44.6464
-36 37.9 26.6166
64.9 10.4 63.981
-21.9 41.9 30.61633333
65 10.4 64.432
-24.5 47.9 31.5994
65.3 10.4 65.833
65.4 10.4 66.271
66.05 10.4 67.7575
-49.35 77.9 20.5845
-54.5 36.4 42.203
51.5 42.9 59.1652
-25.5 57.4 30.2554
-61 30.9 42.2472
9.5 48.4 61.2252
16 44.9 59.3472
-41 56.4 41.229
-20.975 43.9 31.00466667
33.5 21.9 36.1634
-69 10.9 22.602
-68.5 10.9 22.6116
-41.3 26.9 28.441
-47.575 24.4 27.35375
-53 47.9 44.1082
-46.5 36.4 35.2402
-63.5 58.4 45.1506
-64 10.9 22.6818
-63 10.9 22.6542
-62.5 10.9 22.6646
-62 10.9 22.6976
-59.5 10.9 22.731
-53.5 79.9 29.2036
-5.5 59.4 26.6974
-52.5 52.9 43.8954
-50.5 44.4 43.8958
-57 10.9 22.7926
-55.85 10.9 22.3195
-55.075 10.9 19.45475
-54 10.9 18.8152
60.1 30.9 59.463
67.5 24.4 70.4802
-48.5 53.9 37.2242
4.55 36.9 35.694
65.3 37.9 62.359
65.6 10.9 65.517
-24.5 48.4 31.6136
65.7 10.9 65.874
-69.5 27.4 40.3818
-47 47.4 43.851
66.15 10.9 67.7705
-45 28.9 31.0452
66.35 10.9 68.273
66.625 10.9 68.879
-26.5 52.9 31.3368
67.55 10.9 70.74325
43.65 12.9 39.745
47.4 10.4 41.85066667
69 10.9 71.8354
69.9 10.9 72.18666667
21.1 47.9 57.57066667
39.5 51.9 52.6916
-41.55 78.9 32.153
70.3 49.9 68.146
-41.15 48.9 32.2465
60.5 40.4 62.4708
-63.5 50.4 46.186
42 68.4 48.1552
72.5 55.9 90.803
-49.5 68.4 36.931
-61 51.9 46.1226
-54.55 11.4 20.1535
-70 23.9 38.0972
-54 11.4 19.785
-58.5 24.9 38.8478
53.9 50.9 52.806
-20.4 41.9 28.82766667
45.45 11.4 40.2655
44.4 12.9 40.438
-53 75.4 33.9436
46.1 11.4 41.23733333
46.85 11.4 41.9065
51 11.4 43.6316
-63.5 11.9 24.5394
66.5 11.4 67.711
-64 38.9 45.0906
40.5 49.4 53.6096
-38.4 42.4 32.38066667
2 38.9 30.8548
19.2 75.4 52.206
-16.7 48.4 20.309
63.95 5.9 64.756
-57 60.9 44.4778
48 51.9 53.4774
-47.5 73.9 32.9592
-51 13.4 22.3668
39.15 44.4 51.6215
-63 18.9 33.8114
45.65 49.4 58.2215
-69.5 11.9 24.4874
-66 80.4 31.716
-70 49.9 43.6894
-65.5 11.9 24.514
65.95 37.9 64.2385
-60 11.9 24.5974
70.3 13.9 71.217
-61.5 44.4 46.0326
-57.5 11.9 24.5662
-54.35 11.9 20.943
-56.5 28.9 41.2602
-50.1 11.9 17.9715
-47 64.4 33.779
47.975 11.9 42.54516667
-5 47.9 29.1254
46.55 10.9 41.3035
-48.425 63.9 34.01575
-25 50.9 31.547
66.7 11.9 68.718
-49.5 33.9 40.38
-70 12.4 25.3776
-64.5 12.4 25.416
51.95 7.9 45.3425
66.65 10.4 69.1435
-63.5 12.4 25.4362
21.5 30.9 33.8546
-61.5 12.4 25.4412
36.5 69.9 48.5764
-57 12.4 25.523
-24.5 51.4 31.502
-56.5 12.4 25.5242
-47 44.4 37.3394
44.55 12.4 40.283
65.5 7.9 67.945
7.8 31.9 34.923
66.85 12.4 68.869
-3.45 66.4 27.3135
53.2 42.9 59.879
-41.2 83.9 18.811
71.2 54.4 71.467
-68 12.9 26.1776
-65.5 12.9 26.2038
-57 43.9 45.9946
-54 30.4 39.6674
-63 12.9 26.223
-61 52.4 46.0756
-58.5 12.9 26.2672
-57.5 84.4 26.402
-55.8 22.9 37.001
-55.5 12.9 23.91466667
68.7 4.4 70.547
-51.05 12.9 21.1645
59 44.4 62.3536
67.025 59.9 55.95891667
-50.8 12.9 21.012
-50 12.9 20.5926
49.425 12.9 41.9875
68.8 12.9 70.991
33 46.4 54.408
17.9 30.9 36.607
-36.95 47.4 29.11225
62.55 43.9 62.88275
-40.35 49.4 33.911
64.35 23.4 68.3525
63.6 33.9 61.61666667
-59.5 11.4 23.734
-0.2 66.4 40.397
-66.5 17.9 32.7418
-14 52.4 28.1212
67.925 43.9 63.389125
-6.5 48.9 29.1172
-69 14.9 29.142
71.2 49.4 79.122
25.8 24.9 33.434
-69 13.4 26.9388
-65 13.4 26.9598
-50.05 82.4 25.73525
-63.5 13.4 26.9924
49 51.9 53.248
-61.975 13.4 27.01733333
-20.8 52.4 26.305
-59.5 13.4 27.0414
-57 13.4 27.0614
4.35 37.4 33.33
-54 13.4 23.7644
-11.65 45.9 28.344
-18 45.4 28.8586
-51.55 13.4 22.6245
-22.5 46.4 31.5332
44 13.4 40.2988
67.15 13.4 69.1325
7.425 35.4 36.495875
-32 58.9 29.6576
67.3 13.4 69.448
67.55 13.4 69.81375
-59.5 41.9 45.7064
68.075 13.4 70.414875
-35.15 47.4 28.8805
-49.7 14.4 24.189
-68.5 30.4 41.9932
-63 19.4 34.2926
-60.5 39.4 45.1698
48.35 54.9 52.988
9 34.4 36.5948
-55.8 19.4 34.01
-18.975 46.9 29.01933333
-62.5 23.9 38.0828
-66.5 13.9 27.6876
-66 13.9 27.6874
-64 13.9 27.7316
-61 13.9 27.7372
-27 57.4 30.2718
-62 77.4 34.8558
65.3 5.4 66.416
-59 13.9 27.783
57.5 70.4 51.8208
0.5 44.9 28.6344
-48.7 44.4 40.729
-58.5 34.4 43.738
65 41.9 63.249
-55.45 13.9 25.20925
-55.025 13.9 24.9865
-56.15 85.4 24.601
-51 13.9 22.876
-20.5 43.9 30.7016
72.9 50.4 89.991
57.1 5.9 50.19166667
-61.5 87.9 19.5422
56 46.4 59.3386
49.7 50.9 65.21
42.1 13.9 38.72266667
43.35 13.9 39.9735
-60 30.9 42.2606
45.525 13.9 41.004
62.95 7.9 62.381
68.9 24.4 72.984
66.3 13.9 67.371
66.8 13.9 68.36
69 13.9 70.7952
-19.35 53.9 28.5175
-23 44.9 31.3342
-43 27.4 29.6248
-62.925 11.9 24.523625
6.6 55.4 61.36366667
-55.85 14.9 28.827
-55.45 42.9 43.77375
4.55 36.4 36.15575
64.15 9.4 63.835
-36.5 77.4 44.198
-61 40.4 45.4436
-14 62.9 24.2182
-64 17.4 32.233
21 30.4 34.3014
-67.5 80.9 31.1178
-68.5 34.4 43.2164
-45.95 79.9 19.8375
-67.5 63.9 43.4168
-67.5 14.4 28.3976
-67 14.4 28.4074
-66.5 14.4 28.4154
-65 14.4 28.4378
-52.5 33.4 41.0502
-59.975 14.4 28.51858333
70.2 24.9 83.618
-36.475 78.4 44.02383333
-55 58.9 42.8804
-57.5 14.4 28.531
67.5 65.9 55.3686
-65 85.9 23.8122
13 73.4 55.4232
43.55 14.4 40.12175
44 14.4 40.3332
45 14.4 40.6674
57.5 66.4 52.552
45.5 14.4 40.7842
-57.5 13.9 27.7948
-30.5 48.9 31.6508
67.05 14.4 69.2
49.5 54.4 53.7286
69.575 14.4 71.01425
-10 45.9 28.9652
-45.5 77.9 19.908
67.1 73.9 50.67766667
-45 45.9 37.494
-49.5 55.9 42.822
33 44.9 57.904
-18 57.9 26.399
4.6 37.4 31.683
-55.7 51.9 45.878
11.5 31.9 37.3314
-63 9.9 20.4284
-59.5 12.9 26.2732
-70 14.9 29.142
-61.5 24.9 38.85
-64 14.9 29.1654
-50.5 28.9 38.8772
-59 14.9 29.2242
-58 14.9 29.239
-53.5 37.9 42.6252
-55.7 14.9 27.642
-54.475 14.9 26.52375
-66 65.9 42.5466
-53.55 14.9 26.12275
56 74.9 54.708
-49 30.9 32.4748
-49.4 56.4 37.75
-51.025 14.9 25.02741667
-61.5 14.9 29.1812
46 14.9 40.3966
66.1 14.9 69.21033333
66.6 14.9 69.63466667
68.075 14.9 70.47525
-49.4 46.4 43.36633333
30 25.4 35.0916
55.35 6.9 48.4125
12.95 47.9 59.67175
10.65 68.4 57.3645
-13.5 51.9 28.1364
48.4 13.9 40.89066667
-12 44.9 28.805
47.8 44.9 52.225
69.7 24.9 78.187
-68.5 15.4 29.8418
-68 15.4 29.8174
-65.5 15.4 29.8316
64.5 69.9 53.1918
-42.85 48.9 35.326
53.5 48.9 59.478
-6.5 44.9 28.86
22 74.9 52.279
-62.5 15.4 29.8396
36.2 45.4 49.278
58.5 40.4 62.1598
-59.5 15.4 29.9032
-59 15.4 29.9062
-58 15.4 29.9234
14.5 72.9 55.67
-57.5 15.4 29.9096
-55.8 15.4 29.44
-52.05 15.4 25.60625
-51 15.4 25.1782
-49.3 15.4 22.647
-19.7 54.9 27.753
-66.975 34.4 43.50375
-52.5 25.9 36.9752
9 31.9 36.1994
-39.05 75.9 43.9105
41.3 15.4 39.015
-48 46.9 43.7448
45.075 15.4 40.020625
-66 15.9 30.4628
64.3 9.4 64.478
65.3 15.4 67.304
-2.3 61.9 25.116
66.1 15.4 69.39033333
67.5 15.4 70.7992
64.65 47.9 57.3885
63.6 29.4 63.105
8.475 49.9 61.51366667
-52.6 13.4 23.105
-26.5 51.4 31.4944
38 42.4 49.1342
-5.5 57.4 27.6548
-46.35 49.4 37.6165
-59.5 41.4 45.577
-44.3 78.9 25.229
8.1 70.4 57.60366667
-41.35 29.4 30.8435
-67 24.4 38.4442
-65.5 49.9 46.1804
-53.5 27.4 37.9694
-64 75.4 36.595
-67 13.9 27.689
-48.5 37.9 35.8392
2 43.9 28.3158
-40.65 31.4 31.2445
-42.6 39.4 36.25466667
-0.5 48.4 29.0794
-48.5 75.4 33.1196
-13 43.4 28.4064
-49.4 22.9 31.60266667
-61 15.9 30.5386
-62 81.4 30.5818
-60 15.9 30.5254
-53 46.9 44.0672
-57.5 15.9 30.5634
67.45 65.4 55.456
-55.7 15.9 28.61
58.2 75.9 56.104
42 15.9 39.3482
-54.5 61.9 41.9768
42.55 15.9 39.60275
-42.4 49.4 35.89766667
-63.5 34.9 43.9152
44.5 15.9 39.6182
2.9 49.4 28.134
60.5 71.9 50.2878
64.95 15.9 69.31525
-22 56.4 30.5632
-69 51.9 43.7078
-2.5 59.4 26.4436
-41.1 32.9 32.86433333
-49.3 34.4 34.404
-6.5 47.4 29.09
68 15.9 71.1808
68.5 15.9 71.4152
-55 29.4 39.1706
69.025 15.9 71.54416667
-68.5 77.9 34.2672
37 41.4 47.2746
-57 61.4 44.3396
-55 16.9 28.8828
-55.8 82.4 28.936
-41 26.9 27.999
-56.1 16.4 31.11733333
36 70.4 48.8946
-64.5 19.4 34.301
-32.5 58.9 29.613
8 34.9 36.539
55 66.9 52.1784
51.95 47.4 57.9185
-52.55 11.9 19.67775
37.35 43.9 49.9705
-66 19.4 34.3006
-32.5 47.4 31.5542
-13.5 52.9 28.6366
64.4 60.4 51.404
64.5 60.4 51.721
36.5 19.4 37.0846
-2.5 43.4 28.5364
-29.5 46.4 31.5358
-70 84.4 26.2566
-50.05 81.4 27.207
-60.5 38.4 44.9724
-70 16.4 31.0978
70.5 17.4 73.50533333
-68.5 16.4 31.0708
-33 54.4 31.1084
-64 34.4 43.7166
-66.5 16.4 31.073
-63.5 16.4 31.0862
-49.65 73.9 34.8475
-62 16.4 31.1088
-46 46.4 37.5114
-59.5 16.4 31.1586
-58 16.4 31.176
-55.85 16.4 30.8315
-57 81.4 30.629
-55.7 16.4 30.277
-19.7 46.9 28.457
-54 16.4 27.6588
-52.425 16.4 27.524625
-52 16.4 27.4884
-50 16.4 27.3228
-49.5 16.4 26.65
-49.4 16.4 26.266
-48 32.9 33.6032
-48.5 64.4 33.7588
-48.8 16.4 23.972
-57.5 46.4 46.2044
38.4 16.4 36.881
-45.6 22.9 27.10133333
-64.5 76.9 35.2794
67.85 53.9 59.002
39.55 16.4 38.1415
-39.3 56.9 32.449
64.65 24.9 68.7215
43 16.4 39.3376
-69.5 41.4 43.4454
44.85 16.4 38.827
-13.025 46.4 28.01525
-62.5 36.4 44.3888
8.15 47.4 60.155
-63.5 56.4 45.5398
-46.5 54.9 37.0556
-58.5 85.9 23.895
65.3 16.4 69.064
-44 33.9 34.1284
69 16.4 71.5988
-52 26.9 37.6524
-50 31.9 40.3652
72.8 50.4 88.881
3.55 57.9 61.63225
7.025 37.9 33.44566667
-60.5 52.4 46.0878
-46 72.4 27.7876
-27.5 44.9 31.3794
-54 79.9 29.1974
-3.925 47.9 28.879375
-58 48.4 46.2592
-50 20.9 32.7094
-58 47.4 46.2354
-55.7 40.9 45.141
-54 63.9 41.1616
48.5 42.9 57.221
-70 16.9 31.6836
-68.5 21.4 36.0928
12.5 30.4 36.6506
-68 16.9 31.6396
16.5 34.9 35.8326
65.55 15.4 68.273
-60.5 16.9 31.706
-59.5 16.9 31.7212
-55.4 66.4 40.13066667
-58 16.9 31.76
-55 34.9 41.7016
-55.45 16.9 28.96425
-62 35.4 44.0904
-55.6 71.4 38.299
-53.5 16.9 28.6418
-53 16.9 28.561
57.5 36.4 52.8046
56.925 68.9 52.536875
-51.5 16.9 28.32
13 32.9 37.345
-51 16.9 28.2396
-50.5 16.9 28.159
-50 16.9 28.0922
47 67.9 48.5872
38.35 16.9 37.46
63.6 29.9 62.897
38.6 16.9 37.681
39.075 16.9 37.9955
-6 56.9 27.8218
40.5 16.9 38.673
-46 28.4 30.7088
61.5 36.4 53.8224
65.55 16.9 70.8605
-59 35.4 44.081
65.85 16.9 70.861
69.5 16.9 71.805
70 16.9 71.8106
-40.35 48.9 30.7855
-49.7 13.4 21.98
-11.5 49.4 28.0996
14.5 45.4 59.837
-44.3 23.9 27.994
-51.5 64.9 40.709
-55 65.4 40.5318
-51 33.4 41.0854
58.5 61.4 43.2632
-40.85 49.4 34.3875
22.5 27.9 34.3036
-63.5 43.9 45.9492
-43 43.4 36.779
59.1 6.9 51.61933333
64.05 28.4 64.804
52.8 49.9 62.727
-34.3 48.9 31.208
-34.45 48.9 30.8185
-54.5 20.9 32.4366
-58.5 72.9 38.555
-42 27.4 29.9138
42.5 45.9 42.6026
-0.5 52.4 29.005
-50 78.9 30.3466
-70 17.4 32.2194
-69.5 17.4 32.2114
-67.5 17.4 32.2212
-49.7 58.9 42.713
-65 17.4 32.2202
57.1 7.4 49.63533333
-55.55 18.9 30.8725
-57.5 17.4 32.2946
-12.55 48.4 26.38
63 61.4 43.6512
-57 17.4 32.2924
50 53.4 53.9186
43 16.9 38.9586
60 34.4 60.2138
-56.5 17.4 32.3024
-55.7 17.4 30.816
-65 34.9 43.8756
-55.4 17.4 28.388
-45.5 26.4 29.0056
-49.55 32.9 40.302
-38 51.4 29.5014
34 20.4 36.152
69 12.4 71.2972
-68.5 85.4 24.7116
-58 59.9 44.7648
26.5 24.9 34.2488
68.3 7.9 71.466
58.5 70.9 51.584
47.9 13.9 41.10766667
41 17.4 38.6642
-51.5 48.9 44.1098
41.5 17.4 38.7032
59.5 70.9 51.8626
42 17.4 38.6884
66 23.9 70.376
43 17.4 38.377
-41.3 65.4 45.937
71.4 52.9 72.547
69 17.4 73.2706
-70 29.4 41.456
-51.5 69.9 38.0416
-63 22.4 36.9274
53.1 45.9 57.111
-49 22.9 31.6296
-66 20.4 35.2156
-52 74.4 34.8364
-35.5 47.4 28.7964
14.5 33.4 37.0932
-53 74.4 34.8266
-64.5 66.9 42.0852
-39.4 45.9 37.566
-43 24.4 26.705
-50.5 54.9 43.6412
-69.5 63.9 43.3874
53.5 8.4 46.6688
-43.5 41.9 36.8384
-54 33.4 41.0576
-49.5 52.9 43.178
64.9 29.9 67.343
-36 40.4 27.7208
-67 20.4 35.1674
-53 26.9 37.6584
-59.5 37.4 44.717
-47.5 42.4 37.006
-14.55 60.4 24.139
29 47.9 54.6886
2.4 37.9 31.365
-42.5 37.4 35.642
20.5 46.9 56.353
-68 85.4 24.6984
-64.5 36.9 44.535
-50 68.4 38.916
-47.5 51.4 37.4976
50 52.9 53.8004
-53 25.9 36.9894
-50.5 61.9 41.931
17.7 29.4 35.269
-68.5 17.9 32.7396
45.05 44.9 43.252
47.125 10.4 41.43
-68 17.9 32.7298
-64 17.9 32.7628
10.8 45.4 59.49
-59.5 17.9 32.8256
69 9.9 72.1612
-58 17.9 32.8304
-57 17.9 32.8602
14.5 30.4 36.7646
-55.6 17.9 29.717
-55.375 17.9 29.004
60.6 8.4 53.777
-19 61.9 25.1352
-55 17.9 28.9604
-54 17.9 28.9248
-53.5 17.9 28.9068
3 64.4 56.963
-49.7 84.4 21.744
-51.5 17.9 28.835
-47 25.9 28.5802
36.5 17.9 36.363
-33 58.4 29.8034
37.8 17.9 37.507
38.05 17.9 37.66
63 59.4 55.1624
47.7 49.9 63.184
-59 10.4 21.7078
41.5 17.9 38.3384
-54.5 53.4 43.8596
67.575 17.9 72.994875
49 12.9 42.1042
69.5 17.9 73.316
-12.5 61.9 24.6894
70.5 17.9 73.599
-12.65 50.4 27.3905
-58.5 68.9 41.072
66.1 62.4 54.17633333
-64.5 13.9 27.6976
-26.5 55.4 30.8344
-45.3 21.4 26.443
29.5 47.4 55.1018
-56.05 34.4 43.7055
-67 78.4 33.8328
-57 15.4 29.9074
-39.1 45.9 34.324
62.6 44.9 62.36966667
69.2 49.4 65.016
-57 70.4 40.204
35.6 45.4 57.771
-57 13.9 27.8154
-62.5 28.4 40.9986
-2 57.4 27.6018
-69.5 18.4 33.2896
-64.5 18.4 33.2802
-35.4 60.4 25.88133333
-63 18.4 33.3052
-59 18.4 33.3466
-47 22.4 31.84
41.925 48.9 53.029375
-55 18.4 29.7428
44.5 67.9 48.2158
-39.15 79.9 37.266
14.5 30.9 36.9662
54.55 6.9 47.6885
-56.5 54.4 45.885
-19.8 45.9 27.679
67.6 72.4 51.66066667
-10.5 45.4 28.8968
36.2 18.4 36.626
-64.5 40.9 45.4844
39.5 18.4 38.0484
40.5 18.4 38.076
38 20.4 36.9278
-4.65 53.4 28.7975
10.05 32.9 37.15825
-66 24.4 38.4556
-65.5 51.9 46.1226
-37.4 44.9 30.14
-69 27.9 40.661
60.075 6.9 52.425625
-55.8 9.4 17.862
-47 38.9 36.1084
-49.35 62.4 34.7905
-53.5 16.4 27.6162
-60.5 87.4 20.7576
-67.5 73.4 38.1214
-13.95 45.9 28.11075
6.5 44.4 28.2132
-51.5 32.9 40.841
-52 64.4 40.9446
-40.65 33.9 32.261
-44.5 75.9 23.6526
-1.1 60.9 25.71
-43.2 44.9 37.961
64.6 36.4 65.351
-15.5 55.9 27.2678
-18.5 59.4 25.6778
64.55 47.4 57.3255
61.95 8.9 58.351
63.5 24.4 64.622
-44 40.9 36.6506
70.5 43.9 63.4572
-62 64.9 42.9994
-60 15.4 29.8852
-39.7 59.9 34.567
-6.5 44.4 28.7246
-47.5 37.4 35.6374
-11 55.4 28.2488
-66.35 40.4 45.0985
-69.5 18.9 33.7686
-63.5 18.9 33.8108
-3 58.4 27.2792
-60 18.9 33.8298
-59.5 18.9 33.8382
53.9 71.9 46.082
52.8 49.4 63.74
-59 18.9 33.8578
-58.5 18.9 33.8772
-58 18.9 33.8902
-55.8 18.9 33.476
56.85 7.4 49.4445
-55.35 18.9 30.3755
-54.5 18.9 30.3608
-32.5 44.9 29.792
-51.5 18.9 30.614
70.4 49.9 69.004
-40.9 48.9 31.79
-41.7 78.9 31.777
-51 18.9 30.656
-23 54.4 31.0342
-49 18.9 30.3856
58.8 4.4 51.466
36 18.9 36.737
-47.45 44.9 37.815
-47.1 23.9 30.63166667
36.35 18.9 36.955
-68.5 82.4 29.2134
-68 43.4 43.5712
64.7 7.4 66.7
65.2 7.9 67.655
37.575 18.9 37.4845
62 57.4 57.964
38 18.9 37.6218
39.5 18.9 37.8066
64.35 2.4 61.459
64 32.9 61.3218
-44.5 25.4 28.1116
-68 57.9 43.8086
-37 66.4 21.8742
64.8 18.9 71.477
-69.5 82.9 28.481
-64.5 63.9 43.4092
30.5 48.9 52.47
-69 79.9 32.2258
53.7 58.9 53.966
-69 28.4 40.972
-46.35 23.4 28.355
24 28.4 34.554
65.925 45.4 62.528125
-57.525 25.9 39.51708333
63.7 36.9 61.562
-47.5 77.4 31.761
-53 63.9 41.1578
62.5 40.9 62.8572
0 49.4 28.7866
-65 40.4 45.4354
-44.5 77.4 21.1972
-42.075 43.9 35.9005
-58.5 84.9 25.6332
-49.3 29.9 31.845
-57 44.4 46.0522
1.8 65.9 49.327
-7 44.4 28.743
-63 55.9 45.6512
-68.5 36.9 43.3442
50.5 50.9 66.6142
5 49.9 56.181
-15.2 57.4 18.152
-6.5 52.4 28.9198
-53 17.9 28.8886
-54 42.4 43.6252
37.55 45.4 50.1735
-19.65 41.9 27.7355
-67.5 19.4 34.28
-67 19.4 34.2752
63.7 30.9 58.892
-63.5 19.4 34.2652
-12.95 47.9 24.222
-59 19.4 34.355
-58.5 19.4 34.357
-56.05 19.4 34.26775
-55.45 19.4 31.375
-54 19.4 31.3492
-53.5 19.4 31.3502
-20.9 46.9 28.174
63.8 63.9 52.509
-51 19.4 31.355
53.15 71.9 45.429
-43.3 55.4 36.45
-49 19.4 31.1276
-46.8 19.4 31.014
57.5 55.4 57.632
34.45 72.9 49.12775
50.15 39.4 59.0015
-62.5 82.9 28.5714
46.85 50.4 54.277
34.575 19.4 35.908125
35 19.4 36.3006
36 19.4 36.8908
38 19.4 37.4736
38.5 19.4 37.5284
-16.7 58.9 22.635
65.575 3.4 63.957625
-29.5 46.9 31.5502
39.5 19.4 37.4106
65.05 19.4 71.7115
-30 59.9 28.7812
65.3 19.4 71.994
65.55 19.4 72.14275
63.7 31.4 59.282
-33.5 56.9 30.448
66 7.4 68.1954
70 19.4 73.321
3.9 50.9 53.194
-42.5 27.4 29.8664
54.05 38.9 60.12625
-55.7 85.9 20.291
57 40.9 62.1928
72.1 50.9 80.386
-53 63.4 41.3972
24.5 43.9 56.1478
-0.6 66.4 38.784
-4.575 47.4 28.9075
-59 79.4 32.8528
-62.5 47.9 46.2442
60.5 69.4 53.1086
-67.5 36.9 43.4596
38.5 17.9 37.8314
-63.5 22.9 37.3546
62.45 40.4 62.68025
-4.5 65.9 23.105
64.3 9.9 62.512
-61.5 18.4 33.3298
-61 18.4 33.3406
-59.5 39.9 45.3412
4.6 65.9 59.61133333
-52 23.4 35.1358
50.85 8.4 44.403
-55.4 61.9 41.97333333
41.1 42.4 52.69566667
43.4 17.4 38.206
44 70.4 48.3116
-44.5 40.9 36.6664
26.5 46.9 55.6718
51.6 57.9 53.63266667
-69.5 19.9 34.754
-47 18.9 30.3402
-67 19.9 34.7352
64.1 22.4 66.555
-64 19.9 34.77
-63 19.9 34.776
-51 50.9 44.0632
-59 19.9 34.8326
-58.5 19.9 34.822
-37.5 42.4 30.31
-54.5 19.9 31.9072
65.35 39.9 62.2165
-53 19.9 31.8544
-57.5 80.9 31.2178
-48 19.9 31.5994
-47.5 19.9 31.5662
-32.5 56.4 30.5692
-70 52.4 43.7256
-46.975 19.9 31.51875
-61.5 32.9 43.1316
-46.5 19.9 30.549
-37.65 31.4 25.3125
-68 78.4 33.796
37 19.9 37.1856
-22.5 45.9 31.4964
38 19.9 37.2586
-64 85.9 23.824
37.5 19.9 37.2546
-62 15.9 30.4914
-63 81.4 30.5632
-64 27.4 40.3986
6.1 38.4 31.10666667
-70 32.4 42.8738
30 42.9 51.8886
-54.5 80.9 27.9318
-67 16.4 31.0666
66 19.9 72.674
64.2 7.9 65.63
-34.8 56.9 28.687
-49.5 36.4 36.83
10.15 35.9 36.156
8.05 47.9 60.71675
70 51.9 60.471
-68.5 15.9 30.477
-42.5 29.4 31.4208
-47 66.9 32.2916
-57.5 73.9 37.8598
-41 64.9 46.1182
63.6 32.4 61.175
-70 15.4 29.8188
8.5 70.9 57.2052
49.9 9.4 43.896
-68.5 38.9 43.3906
-58.5 22.4 36.9778
-20.15 49.4 26.5925
-36.35 31.9 23.2675
-41.05 29.4 30.295
-63 60.4 44.5986
-65 39.9 45.336
-55.7 35.4 43.655
-70 20.4 35.22
-49.55 28.9 38.375
-67.5 20.4 35.1778
56 44.9 61.3982
-63.5 42.4 45.7652
67.55 14.4 69.67575
-14.5 43.9 28.663
-59 88.4 18.2666
-61 20.4 35.2702
-37.6 84.4 28.297
-65 59.9 44.7584
-50.5 33.4 41.0786
-56.05 20.4 35.191
-16.6 57.9 20.808
-49 20.4 31.7688
-48.5 20.4 31.8366
-46.6 20.4 31.643
-67 12.4 25.4038
33.075 20.4 35.39475
-46 71.4 28.8014
36 20.4 36.8874
37.5 20.4 37.0104
38.5 20.4 36.7724
39.3 20.4 36.395
-4.5 43.9 28.5832
66.15 9.4 68.3635
-36.5 48.9 29.1448
-54 21.9 33.3916
65 20.4 71.149
-46.5 24.4 27.0568
69.9 24.4 81.436
-59 32.9 43.1276
-49.5 34.9 40.649
-25.5 47.4 31.5664
-70 64.9 42.9802
-66 18.9 33.7776
64.1 25.9 65.662
-68.5 18.4 33.2746
-62 45.4 46.1024
-57.5 14.9 29.2516
-69.5 20.9 35.668
28 46.4 55.722
-21.1 57.9 29.362
-68 20.9 35.661
-67 20.9 35.6472
63 40.4 62.2062
-53.5 44.9 43.954
-65 20.9 35.6566
63.8 23.4 63.879
-19.45 60.4 26.269
9.5 69.4 58.3844
-63 20.9 35.6798
-61.5 20.9 35.6906
-58.5 20.9 35.7004
-6 49.4 29.0906
-58 20.9 35.7246
-52.5 20.9 32.4116
-46.6 20.9 31.79733333
53.4 48.9 59.989
-46.35 20.9 27.445
-46.1 20.9 27.15433333
-45.85 20.9 26.866
-45.2 20.9 26.116
-44.575 20.9 25.395875
-57 18.4 33.3828
-43.55 20.9 24.215
-18 47.9 28.1232
71 45.4 63.1066
32.125 20.9 34.90925
-0.7 38.4 28.944
33 20.9 35.7576
-26.5 45.4 31.437
19.425 71.9 52.9705
-58.5 41.4 45.5924
33.35 20.9 35.992
-65 40.9 45.4792
65.2 62.4 53.132
34.5 20.9 36.447
-26.5 47.4 31.6014
-67 23.4 37.7454
51.5 68.9 50.477
40 15.9 38.2606
62.8 58.9 56.108
-62 80.4 31.7974
59.5 50.9 53.8172
64.6 20.9 67.398
64.7 20.9 69.702
-46 31.9 33.0382
46.5 68.4 48.6434
69.5 72.4 52.6654
66.7 71.9 51.108
54 38.4 57.49
-37.5 58.9 30.612
-44.5 43.9 37.2194
55.5 53.9 57.1718
-55.6 67.9 40.63
59 42.9 62.807
-38.8 47.9 32.986
65.35 4.9 65.8915
-63 31.4 42.4764
-67 21.4 36.0882
-63.5 21.4 36.097
-55 21.4 33.367
61.6 38.4 58.47033333
-54.3 21.4 32.967
-53 21.4 33.2828
-59 73.9 37.8208
-50.5 51.4 44.058
-49.7 21.4 32.792
71.7 53.9 77.193
-49.45 21.4 31.928
-49 21.4 31.9806
56 65.4 51.8388
-61 62.4 43.9836
-48.475 21.4 32.02741667
-47.5 21.4 32.0388
-46.6 21.4 31.842
-26.5 54.9 30.9568
-45.55 21.4 26.6825
24.075 74.9 51.52825
-44.05 21.4 25.24675
-57 18.9 33.8906
-43.5 21.4 24.7208
-43 21.4 24.2428
-42.5 21.4 23.765
-49 37.4 35.628
13.15 47.4 59.9545
-43.3 20.9 23.927
34.5 21.4 36.444
34.975 21.4 36.51675
37.85 21.4 35.9135
-35.1 53.9 28.13966667
2.7 59.9 60.638
65.5 15.9 68.8908
37.8 44.4 50.5
59.5 52.4 57.0448
-59 11.9 24.6016
-59.5 42.4 45.7836
55.5 38.9 60.4692
-31.5 56.9 30.4262
59 52.4 56.9872
-62.5 78.4 33.852
45.8 44.9 45.712
37.6 43.9 50.13866667
-45.35 79.9 21.3685
-43.5 39.4 36.2816
-63.5 30.9 42.2364
-16 48.4 18.7794
-48.2 44.4 39.263
-56.05 84.4 26.218
36.1 44.4 48.93366667
-39.5 66.9 45.9394
50.4 8.4 43.77933333
69.5 25.9 74.958
-35.6 83.9 35.232
-68 21.9 36.5064
-67.5 21.9 36.5018
-65 21.9 36.5248
-64 21.9 36.5114
-69 62.9 43.7302
-63.025 21.9 36.52166667
58 66.9 52.7894
-61 21.9 36.535
-60.5 21.9 36.5602
55 70.9 50.5964
64.4 22.9 68.781
-50.5 19.4 31.3562
64.1 24.4 66.692
30.5 73.9 49.8986
-59 87.4 20.7646
-46.5 63.4 34.214
-53 21.9 33.2824
-52.5 21.9 33.2856
-51 21.9 33.2964
-49 21.9 31.9694
-54.5 68.4 38.9764
-3.5 57.4 27.6268
66 37.4 65.6446
-48 21.9 32.049
-69 77.9 34.2572
-65 25.9 39.4762
-45 21.9 26.3156
-44.075 21.9 25.459125
27.5 26.9 34.7026
-41.6 21.9 23.83033333
24.5 44.9 56.6284
-60 10.9 22.6984
24.5 45.4 56.6284
52.5 42.9 59.3028
12.075 46.4 60.540625
8.65 31.9 36.1115
30.55 21.9 34.43225
63.9 36.4 60.982
-65 54.4 45.8466
63.8 36.4 60.196
-49.025 42.4 37.01958333
63.5 42.9 63.0964
34 21.9 36.3028
-26.5 48.4 31.617
-67.5 35.4 43.4044
64.3 21.9 66.567
64.4 21.9 68.42
-52 20.9 32.4054
-69 24.4 38.4434
72.8 51.9 89.584
11.5 46.9 60.712
70.7 49.4 75.619
54.5 67.4 52.0974
-19.85 56.4 26.7045
-45 47.9 37.588
-53.5 43.4 43.757
50.5 43.4 60.1514
-41.4 22.9 24.83366667
-3.5 38.4 26.841
-47.5 37.9 35.7652
-16.8 46.9 27.926
64.8 47.4 57.967
-1 51.4 29.0598
33.5 20.4 35.8068
19.5 75.4 52.1988
-46.5 24.9 27.2082
-39.15 31.4 28.274
70.3 53.4 59.905
-51 39.9 43.1316
-69.5 22.4 36.9262
-69 50.4 43.6786
-49.65 37.9 42.3605
-64 25.9 39.4526
-65.5 22.4 36.9276
19 46.4 57.6536
-64 22.4 36.9424
-61.5 22.4 36.9668
-60 22.4 36.9668
69 73.9 51.8818
-55.5 22.4 34.336
57.6 4.9 50.29533333
10 69.4 58.1706
-51.15 22.4 33.8555
-41.3 57.4 40.147
-49.6 22.4 32.251
67 44.9 63.2346
59.65 8.4 51.778
-48.5 22.4 31.8792
23 75.4 51.3202
-45.075 22.4 26.40525
8.2 31.9 35.713
5.5 45.9 28.1956
-42.9 22.4 24.424
-41.65 22.4 24.676
-41.1 22.4 22.772
-50.5 33.9 41.2796
18.7 27.9 34.388
31.3 22.4 35.507
32.5 22.4 35.908
35 22.4 36.061
36.4 22.4 35.469
57.3 8.9 47.949
-39.65 32.4 29.7735
64.6 35.4 63.678
36.5 71.4 49.0804
-54 61.9 41.9368
-3.85 56.4 27.8505
64.2 22.4 68.136
-63 16.4 31.0954
64.4 22.4 68.824
-44 26.9 29.4662
-48.5 32.4 33.337
12.5 32.4 37.39
64.7 23.9 69.102
38 48.9 53.9178
-61 62.9 43.793
-66 32.9 43.0932
65.55 38.4 62.8165
-49 50.4 42.6432
5.7 49.9 59.748
20.2 31.4 34.619
-62.5 51.9 46.1272
70 15.4 71.4552
-24 57.4 30.2406
-66.5 31.9 42.682
-60.5 33.4 43.3634
56 69.4 52.1508
63.9 26.9 64.727
66.35 4.9 66.9665
-68 22.9 37.2964
-7.5 58.4 27.1314
-65 22.9 37.3346
-62.5 22.9 37.3546
-62 22.9 37.3518
-60 12.9 26.2454
-61 22.9 37.3852
-59.5 22.9 37.3908
-59.5 69.9 40.4622
-58.5 22.9 37.4066
-37.65 31.9 25.679
60.1 4.4 53.423
9 43.4 28.4742
-51.5 22.9 34.3058
-58 18.4 33.3554
-51.1 22.9 34.36866667
-48 40.9 36.687
15.9 46.9 58.64166667
56 40.9 62.0808
52.5 10.9 44.1896
-45.5 65.4 33.1472
-27.5 47.9 31.649
-50.85 22.9 34.6695
16.15 46.9 58.4875
-50.5 22.9 34.6914
-37.9 31.4 25.805
57.525 42.9 62.51683333
-48.5 22.9 31.738
-46.075 22.9 27.446375
-3.5 46.4 29.0522
6.5 34.4 36.7424
64.6 30.4 65.32
64.2 26.4 65.676
-44.075 22.9 25.9935
-1 49.4 29.1296
-43.35 22.9 25.4675
-61.5 11.4 23.6882
-27 54.4 31.068
-60.5 27.4 40.425
65.5 2.4 62.1388
29.3 22.9 34.494
29.975 22.9 35.02141667
-60 78.9 33.4196
34.95 22.9 35.651
35.2 22.9 35.516
53.7 51.4 53.84
-64.5 72.4 38.871
48.5 67.9 48.7716
65.4 32.4 60.194
-46.5 41.4 36.7844
64.1 22.9 67.546
64.2 24.4 67.212
64.55 22.9 69.286
-51 79.9 29.2158
-44 44.4 37.3542
-34 46.4 29.327
-49.65 30.4 39.235
55.5 64.9 51.3328
-55 38.4 42.8252
49.5 67.4 48.7962
71.9 53.4 79.073
-68.5 63.9 43.4112
-69.5 42.9 43.5082
-39.9 60.4 38.85
9.65 46.4 60.1665
-1.5 53.4 28.7358
-14.5 49.9 27.1644
-56 37.9 44.7908
-49.5 39.4 42.298
59.35 4.4 52.331
58.475 65.9 52.52708333
-42.45 84.4 14.1795
-56 38.4 44.9246
-35.7 84.4 33.805
-37.7 44.9 31.922
-63.5 42.9 45.8282
51.3 40.4 59.559
-68 23.4 37.7296
-66.5 23.4 37.7348
-66 23.4 37.73
-62.5 23.4 37.7474
-28 49.4 31.6332
-61 23.4 37.7724
-60 23.4 37.7856
-1.5 53.9 28.6236
-57.5 23.4 37.8086
-57 23.4 37.809
-59 70.9 39.8848
-47.5 60.4 35.53
-56.05 23.4 37.6845
41 17.9 38.3914
-55.8 23.4 37.436
65.55 5.9 67.22075
-51 38.9 42.8892
-20.2 46.4 26.867
60.35 8.4 53.2505
-55.7 23.4 36.644
-54.5 23.4 35.1432
-48.5 23.4 31.34
-46.6 23.4 30.946
5 35.9 36.567
-69 40.4 43.4442
-41.4 23.4 25.441
-62.5 43.4 45.9056
-55.85 26.9 39.9225
-50.5 34.9 41.6714
-55.85 21.4 35.8715
30.5 23.4 35.4264
-32.5 58.4 29.8126
-64.425 21.9 36.518125
32.5 23.4 35.7728
-21.5 51.4 31.545
-28 44.4 31.2366
-63 33.9 43.5302
3.15 38.9 31.6055
-65 38.9 45.1062
64.15 23.4 67.7195
64.55 23.4 68.928
-37.2 47.4 30.981
8.5 68.9 58.8136
-46.5 61.9 34.956
-66.45 59.4 44.6425
-69 9.9 20.3996
39.5 17.9 38.2058
-1.35 66.4 35.749
47.575 40.9 55.996375
-52.5 27.4 37.9734
-12.5 45.4 28.1442
-59.975 13.4 27.02025
-69 49.9 43.6822
67.8 43.4 63.615
-44 24.9 27.3972
66.5 55.4 58.942
64.2 7.4 65.642
-36.8 40.4 27.619
4 44.9 27.9264
3.4 64.4 59.207
52.95 42.9 59.4595
-65.5 80.9 31.14
-20.6 60.9 27.66033333
64.8 7.4 66.886
52.7 49.9 65.485
-32.8 84.9 40.941
-53 73.4 35.6084
-14.2 45.9 28.003
-68 23.9 38.0938
-67.5 23.9 38.08
-67 23.9 38.0888
-66 23.9 38.1076
-64.5 23.9 38.117
-62 23.9 38.125
-61.5 23.9 38.1158
20 43.9 57.7484
-60 23.9 38.1748
-59.5 23.9 38.1646
-25.5 45.9 31.4662
-53 39.4 43.037
-54.5 23.9 35.4346
-36.5 42.4 28.286
72 43.4 63.5724
46.05 53.9 53.11
-53 23.9 35.3152
65.4 16.4 69.969
-46.65 23.9 30.138
-46.05 23.9 29.17125
-45.5 23.9 28.801
71 41.4 64.2188
-29 45.9 31.455
-41.1 23.9 25.27066667
-26.5 54.4 31.051
-48.5 27.4 29.9456
-56.5 39.9 45.3314
47.475 41.4 56.52791667
-41.1 79.9 32.272
27.3 23.9 33.468
28.5 23.9 34.7594
29.025 23.9 35.02058333
-65.5 23.4 37.7744
-50.5 18.9 30.6982
64 22.9 65.632
29.5 23.9 35.204
30 23.9 35.3388
32 23.9 35.5872
33 23.9 35.4346
62.9 50.9 52.234
33.975 23.9 35.008
34.35 23.9 34.834
72 52.4 79.578
-52.5 31.9 40.3904
-3.925 47.4 28.833
63.5 23.9 64.593
63.65 23.9 65.159
64.5 39.9 61.7656
64.35 23.9 68.1635
67.4 23.9 71.814
67.8 23.9 74.006
-31 50.9 31.5766
64 39.9 61.706
69 23.9 80.541
69.9 23.9 85.451
70.1 23.9 86.537
64.7 23.4 69.561
70.3 23.9 87.624
-42.6 47.4 39.30733333
53.7 38.4 53.718
-34.8 47.9 29.631
8 35.4 36.3954
-40.7 57.9 41.507
69.5 74.4 51.9304
-57.5 33.4 43.381
-66 78.4 33.8324
26.975 44.4 55.51958333
-45.5 24.9 27.2838
43 51.4 53.6224
65 44.9 62.7056
-64 70.4 40.1786
-63.5 48.9 46.2264
-55 41.4 43.4364
3.85 65.9 57.8045
-24.5 44.9 31.3674
-45 43.9 37.2464
-16 54.9 28.3784
-68.5 24.4 38.4618
-65 24.4 38.4774
-62.5 24.4 38.4642
-29.5 49.9 31.5826
55 42.4 62.0998
67.85 11.4 70.9245
62.7 70.9 52.499
-57.5 24.4 38.5402
-48 60.4 35.539
-57 24.4 38.5336
-54 24.4 35.8524
-52 24.4 35.8558
-51 24.4 35.908
-49 24.4 27.6446
-9 52.4 28.9368
-48.5 24.4 27.6154
-47 24.4 27.107
43.5 50.9 53.5794
-44 24.4 26.805
-43.5 24.4 26.755
-41 24.4 25.694
51.65 38.9 58.295
-56.5 40.4 45.4418
5 57.4 61.9904
29.5 24.4 35.2514
30 24.4 35.3388
32.975 24.4 34.97633333
68 74.4 51.1676
-39.9 32.4 30.22566667
33.45 24.4 34.70425
50.5 11.4 43.5986
70 17.4 73.2934
-59 25.4 39.1734
37.425 49.4 54.021375
-28 45.9 31.4642
-53.5 27.9 38.258
65.7 38.4 63.279
-62 73.4 38.1824
63.65 24.4 65.159
64.1 23.9 67.236
22.925 72.4 52.0045
64.45 24.4 68.2635
27.5 46.9 55.5642
65.05 24.4 69.72
-49.65 22.9 34.228
34.45 46.4 54.12975
55.975 8.4 48.02991667
-21.425 43.4 31.048875
3.3 58.4 61.456
68.7 24.4 71.284
-35.65 66.4 21.656
70.3 24.4 84.828
70.4 24.4 85.67
70.5 24.4 86.512
70.6 24.4 87.354
39.5 48.9 53.6098
70.8 24.4 89.041
-44.5 39.4 36.2708
24 46.9 55.8962
13.5 44.9 59.7746
44.475 16.4 38.97741667
-41.2 78.4 33.375
-50.5 85.4 20.2278
57 52.4 56.7208
-66 81.4 30.5488
-65.525 44.4 46.01033333
-64 15.4 29.8212
67.7 16.9 71.512
-49 55.4 36.9854
-51.5 78.4 30.964
-38.3 79.4 39.711
57 40.4 61.9836
51.5 39.4 58.803
-54.85 24.9 35.8825
-53.5 24.9 36.2552
-55 17.4 28.4098
49.5 66.4 47.7722
-52 24.9 36.2764
-67.5 70.4 40.125
-49.35 24.9 27.705
-42 24.9 27.5406
-41.15 24.9 26.697
-6.5 51.4 29.0402
-38.2 31.9 26.702
-41 24.9 26.501
1.5 44.4 28.4318
35.5 20.4 36.7382
-70 10.9 22.595
26.05 24.9 33.8075
-45.5 30.4 32.1138
27.5 24.9 34.7628
29 24.9 35.168
30.5 24.9 35.2716
65.8 74.4 48.953
-48 66.4 32.6316
17 30.9 36.8402
65.8 37.9 63.804
-68.5 46.9 43.6432
68 24.9 70.6042
71.5 53.9 74.686
-58 47.9 46.2466
69.4 24.9 74.945
69.5 24.9 76.031
-41.6 33.9 33.96316667
64.5 46.4 60.6566
-58 58.4 45.154
-69.5 10.4 21.5492
-50.65 21.9 33.359
70.5 24.9 86.871
-40.9 59.9 45.597
61 69.4 53.2432
70.9 24.9 91.216
71 24.9 92.31
-63.5 16.9 31.704
-54 45.4 43.9866
30 42.4 50.3536
-52.5 37.4 42.513
-32.5 44.4 29.6724
7 52.4 61.8116
-54.5 77.4 32.0106
-49.5 63.9 40.195
7.3 54.9 60.681
-62.5 20.4 35.2298
-40.6 45.9 41.87566667
-41.7 49.9 32.598
-64.5 20.9 35.6442
57 45.4 61.172
-57 20.4 35.2944
-57.5 48.9 46.2458
-30 48.4 31.6404
62 32.4 61.9546
-14.85 51.4 27.2945
-37 60.4 26.658
-66.5 66.9 42.0786
-34.2 47.9 30.88
53.1 69.4 50.453
-44 47.4 37.6762
-56.05 29.9 41.75475
52.65 39.9 58.7205
-22.5 43.4 31.0628
50.5 8.9 44.2788
53.3 50.9 55.237
-39.8 61.4 38.017
-3.5 47.9 29.064
-45.5 35.4 34.8386
-68.5 25.4 39.1318
-8.5 54.9 28.3908
56.35 71.9 47.221
70.5 76.9 49.78
47.65 44.9 51.7375
-62.5 25.4 39.1686
-61.5 25.4 39.2054
70.6 51.9 62.123
-54.075 12.9 22.98825
-60.5 25.4 39.1854
13 45.4 60.0838
-49 52.4 37.4138
-5.075 58.9 27.009875
-58 25.4 39.184
64.5 7.9 66.28733333
-65 12.4 25.4232
-56.05 25.4 39.1575
-54.85 25.4 36.288
50.6 71.9 45.585
-51 49.4 44.1252
-49.65 42.9 43.344
-53.5 25.4 36.6266
-53 25.4 36.6324
55.5 55.4 57.2622
-50.5 25.4 36.6258
-49 37.9 35.847
-34.875 57.9 27.86975
-51.5 19.4 31.3542
-49.6 25.4 36.007
-47.5 25.4 28.161
65.5 36.9 65.2456
25.4 25.4 33.90066667
9.5 48.9 61.2506
58.8 72.9 48.317
-46 41.9 36.9014
-67 66.9 42.0856
64 25.4 65.232
64.15 25.4 65.742
65 25.4 69.3046
-55.5 24.9 36.326
66 25.4 69.958
32 43.4 52.1154
66.425 25.4 70.042625
62.5 44.4 62.7422
68.95 25.4 70.57025
-2 58.4 27.163
59.35 7.9 50.206
70 25.4 80.61
70.1 25.4 81.729
68 73.9 51.3504
23 29.4 34.3714
64.55 49.9 56.309
71 25.4 91.796
-59.5 9.4 19.3032
-41.5 44.4 35.302
56.5 74.4 52.8334
54.5 38.9 60.2344
66.1 46.9 60.24
54 41.4 61.7558
18.8 27.9 33.519
64.35 38.4 60.2985
52.8 47.4 58.755
26 46.9 55.7076
-70 25.9 39.455
-49.4 45.4 42.861
67.1 7.4 69.77133333
11 45.9 60.277
-52.5 55.9 43.5074
-69.5 25.9 39.4622
68.025 6.9 70.44933333
11 46.4 60.6092
-60.5 25.9 39.4808
-59.5 25.9 39.5094
-58.5 25.9 39.4988
-58 25.9 39.5014
-56 25.9 39.3782
-6.5 42.4 28.101
-55.45 25.9 37.037
-20.15 55.4 26.2545
59.5 67.9 53.1722
51.85 49.4 63.2585
-55.075 25.9 37.015625
11 34.4 37.3134
-53.5 25.9 36.9886
-70 51.4 43.7108
-51.5 25.9 36.9528
-49.7 25.9 36.624
-46.5 25.9 28.1316
2.9 64.4 56.4
-45.5 25.9 28.121
-44.5 25.9 28.111
-44.5 52.4 37.3696
66.55 62.4 54.71375
-55.7 45.9 45.873
-23.5 44.4 31.2574
70 23.9 85.994
-57.5 67.4 41.8532
-30 58.9 29.753
41.5 68.9 48.4334
-14 49.4 26.7038
30.5 25.9 34.6062
16.925 46.9 57.988125
28.5 74.4 50.2912
-42 25.4 28.0304
65.9 32.4 62.215
-49.35 44.9 42.8585
65.55 25.9 69.6325
66.5 25.9 69.864
69.2 25.9 71.533
-43.8 84.4 10.221
69.3 25.9 72.672
69.4 25.9 73.813
31 48.9 53.703
65.05 72.4 48.237
18.55 29.9 36.286
-17.575 56.4 27.02025
-66.4 44.4 45.65733333
-32.9 60.4 27.11666667
-36.7 33.9 25.625
2.5 50.9 45.156
56.5 52.4 56.6734
-52.3 11.9 19.502
64.2 25.9 66.147
-57.5 11.4 23.7444
39 69.9 48.7208
-41.85 45.9 40.1205
-20.525 43.4 30.51425
38.5 46.4 49.8896
-68 11.4 23.6442
-68.5 43.9 43.543
-54 28.4 38.5838
-58 11.4 23.7352
-67.5 26.4 39.783
-64.5 26.4 39.7916
-54 38.4 42.7684
-63.5 26.4 39.8068
-63 26.4 39.8162
-70 24.4 38.4698
-58.5 26.4 39.8446
-58 26.4 39.8494
-40.9 44.9 41.68833333
-67.5 24.9 38.8118
-40.65 69.4 45.067
-8.5 54.4 28.5288
-57 26.4 39.8348
-55.45 26.4 37.3615
-62.5 76.9 35.3008
-52.5 26.4 37.301
-50 26.4 37.2898
-48 26.4 29.0072
-26.5 55.9 30.697
45 12.4 40.7226
-59 48.9 46.261
-55 20.9 32.4444
-39.1 66.9 30.402
64.6 3.4 63.18766667
-55 44.4 43.893
-55.4 61.4 42.119
21 29.4 34.2906
67.3 74.9 50.465
-68.5 40.4 43.4644
41.525 14.9 38.98
-29 49.9 31.5922
-67.5 33.9 43.3356
-68.5 43.4 43.5316
18.55 31.9 34.99375
23.35 26.4 33.4395
24.5 26.4 34.2562
-53 84.9 21.1152
25.075 26.4 34.484875
26 26.4 34.7052
-68 42.9 43.5424
26.5 26.4 34.7892
27 26.4 34.852
28.5 26.4 34.8074
29.525 26.4 34.5345
30 26.4 34.2886
63.85 7.9 64.76
64.05 7.9 65.256
66.5 19.9 74.715
-49 42.9 37.0848
-63.5 13.9 27.7342
66.4 19.9 74.306
-62 80.9 31.172
19.85 30.9 36.3745
63.3 36.4 56.283
56 68.9 52.3652
69.1 24.4 74.689
64.4 26.4 66.675
-68.5 18.9 33.7996
64.5 26.4 67.181
-59 55.4 45.7402
64.6 26.4 67.686
-3.5 56.9 27.7978
64.7 26.4 68.193
3.6 62.9 60.654
67.35 26.4 69.9585
-41.1 30.4 31.27066667
42.15 41.9 53.1115
-1.2 66.4 36.357
54.4 6.4 47.33
-49.625 38.4 42.3935
8 36.9 33.1006
-27.5 57.4 30.2714
-59 13.4 27.0282
39.85 42.9 51.871
-2 54.9 28.387
34.5 43.9 54.8474
-44.8 79.4 23.741
57.5 70.9 51.3458
58.3 5.9 51.514
-48 41.9 36.8912
67.6 11.9 70.41966667
-68 15.9 30.4796
-67 26.9 40.0552
-41.85 57.9 38.2345
-69.5 45.4 43.5788
23.5 72.4 51.7996
20.3 32.4 35.667
-41.15 25.4 27.321
-64.5 26.9 40.0852
-60.5 26.9 40.107
-60 26.9 40.1042
-59.5 26.9 40.1122
42.6 41.4 52.264
-58.5 26.9 40.1544
-6.5 45.9 28.9658
-58 26.9 40.1644
-55.5 26.9 37.7266
-40 44.9 40.283
-51.5 26.9 37.662
-48 26.9 29.5156
41.9 41.9 52.867
37 18.4 37.181
-47.5 26.9 29.494
53.125 39.9 59.88525
63.3 7.9 63.319
53.5 36.9 52.0774
-66.5 27.4 40.3912
52 44.4 57.8884
-44.5 26.9 29.4938
65.2 15.9 68.887
-42 26.9 29.3598
18.2 30.9 34.989
45.85 49.4 58.706
-50.5 52.4 43.9922
-39.4 62.9 45.464
-46.5 32.9 33.587
-38.3 31.9 26.888
-42.2 56.4 38.953
-65 11.4 23.6512
22.2 26.9 33.028
60 41.9 62.9484
-56.5 49.9 46.2516
-12.2 49.9 27.882
22.525 26.9 33.43516667
48 11.4 42.657
23.5 26.9 34.1264
25 26.9 34.5838
27 26.9 34.7788
-63 23.4 37.711
28 26.9 34.6198
29 26.9 34.2766
29.475 26.9 33.99008333
43.5 68.9 48.5624
-46 24.9 27.2458
43.5 51.4 53.6542
-45.5 39.9 36.4032
-65 15.9 30.4588
16.45 45.9 59.09975
-68 35.4 43.3436
-63 25.9 39.5006
-67.5 83.4 27.8068
-59.5 54.4 45.8576
66.025 18.4 72.4825
-68 21.4 36.0872
67.46666667 32.9 63.68833333
-40.5 70.4 45.149
46.05 41.4 55.67
38.95 19.9 37.099
63.6 26.9 64.384
-53 48.9 44.113
-60 25.4 39.1796
-68.5 20.9 35.6576
64.8 26.9 68.492
70.425 18.4 73.524625
-48 34.4 34.3706
-56.5 70.9 39.9096
68.575 19.4 72.99675
68 3.9 66.1118
61.95 4.9 60.745
4.925 63.4 61.035125
65.65 71.9 49.741
-15 43.4 28.586
-60 12.4 25.4728
-45.5 49.4 37.6056
-40.8 33.4 32.41
-11.5 47.9 28.1168
61.85 6.9 60.253
-48 39.9 36.3818
-61 30.4 42.0246
-65 44.9 46.065
-70 27.4 40.3842
-45 22.9 26.6656
27.5 25.4 34.9
59.025 71.4 51.02141667
-65 27.4 40.3898
-41.15 25.9 27.701
-64.5 27.4 40.3944
-63.5 27.4 40.3914
-66.5 12.4 25.3746
-63.5 52.9 46.0456
-6.5 42.9 28.3422
67.05 46.9 61.0975
-55.025 27.4 38.00125
-46 48.9 37.614
-65.5 81.9 29.9234
-69 37.9 43.3716
-53 27.4 37.9556
-51.5 27.4 37.9626
-59 63.9 43.432
2.3 65.9 51.39
-44 27.4 29.8608
-41.35 27.4 29.396
-51 71.4 37.1168
-38.5 72.4 45.4984
-60.5 33.9 43.5296
67.35 2.9 63.542
-16.55 46.9 27.715
-55 73.9 35.2078
21.2 27.4 32.939
-59 31.9 42.7386
22 27.4 33.78
22.475 27.4 34.02983333
66.85 16.4 70.449
-69.5 24.9 38.8074
23 27.4 34.2182
24.5 27.4 34.5712
25.5 27.4 34.665
-57.5 16.9 31.7738
26.5 27.4 34.6382
27.5 27.4 34.436
28.9 27.4 33.747
3.7 49.9 49.672
59 7.4 50.8238
-54.5 20.4 31.9606
62.925 46.4 60.4405
63.65 27.4 64.3005
64.3 27.4 65.759
-42 34.4 34.3428
65.525 24.9 70.00641667
-44 48.4 37.6784
65.5 18.9 72.2414
-54 40.9 43.3794
-45 45.4 37.4426
63.7 36.4 59.411
20 30.9 35.58
-10 44.4 28.7672
-34.975 60.4 26.00791667
64.6 5.4 65.42666667
-51.5 45.4 44.0058
-70 27.9 40.6732
-69.5 27.9 40.675
-55.7 20.4 34.012
33 71.9 49.6598
-54 22.4 33.8778
-63 27.9 40.6778
63.8 32.4 58.729
-62.5 27.9 40.6968
23.5 44.4 56.8432
-24.5 45.9 31.4674
-61 27.9 40.6874
-60.5 27.9 40.6868
-36.05 84.4 32.7945
-56.05 27.9 40.67275
6 44.4 28.1942
70.3 15.4 71.486
33.7 45.4 53.469
-59 16.9 31.7176
-44.05 74.9 24.9325
-46.5 27.9 30.3322
69.1 23.9 81.086
-41.05 27.9 29.3395
-66.5 67.4 41.8064
-63.5 32.4 42.915
-66 44.4 45.8682
50.15 9.4 44.1095
17.7 27.9 34.273
-70 22.4 36.9204
18.4 27.9 35.23566667
18.6 27.9 34.685
65.8 32.9 61.917
23 27.9 34.416
25.5 27.9 34.6056
-49.55 29.9 38.83
-63.5 30.4 41.9922
-8 56.4 27.9692
-29 48.9 31.6538
-44.5 33.9 34.1282
26.5 27.9 34.4182
-59.5 50.4 46.2218
27.475 27.9 34.01233333
70.4 53.9 60.772
58.5 7.9 49.608
40 43.9 52.2582
27.95 27.9 33.70075
66.8 10.4 69.468
-39.2 45.9 35.395
-46.45 77.9 19.59125
20.5 31.9 33.1296
-57 19.4 34.3762
58.8 72.4 48.317
-68.5 28.4 40.9626
60 39.9 62.046
-68 28.4 40.9634
63.5 71.9 50.778
-67 28.4 40.9784
-55.5 28.4 38.64
-51.5 28.4 38.5502
26.6 43.9 54.89033333
-50.05 31.4 40.14675
-43.5 28.4 30.7212
64.35 25.4 66.352
55.65 71.9 47.224
67.2 23.9 70.721
-56.05 18.9 33.7625
17.6 28.4 34.274
71.8 49.4 83.352
17.85 28.4 35.2965
32.075 21.4 35.451875
18.1 28.4 35.63666667
58.5 42.4 62.7124
19 28.4 34.956
19.8 28.4 33.514
21.075 28.4 33.923375
-37.4 44.4 29.974
22.5 28.4 34.426
23 28.4 34.481
23.425 28.4 34.5235
12 35.4 36.8834
53.4 40.4 59.4
26.475 28.4 34.00066667
27 28.4 33.69
12.95 70.9 56.59925
27.3 28.4 33.529
67.9 76.4 48.8
63 41.9 62.9178
-15 62.9 24.2978
-36 33.9 24.7204
59.6 4.4 52.69966667
58.5 67.9 52.9722
21 45.9 57.4094
-48.5 38.9 36.1268
-16.525 56.4 27.02225
47.7 49.4 63.197
-62 34.4 43.7168
-56 78.9 33.2946
-34.2 83.4 40.953
-67 28.9 41.2156
-66.5 28.9 41.2128
-66 28.9 41.244
44.65 12.9 40.6295
-64 28.9 41.2016
-58 28.9 41.2532
-57.5 28.9 41.27
-55.7 28.9 40.379
-52 28.9 38.824
-51.5 28.9 38.821
-50 28.9 38.7858
-35.425 78.9 43.508
-35.6 44.9 28.65633333
-36 44.9 28.843
-3 44.9 28.849
-43.5 28.9 31.075
-62 35.9 44.26
-43.3 79.9 26.634
18 28.9 35.6832
18.425 28.9 36.007125
21 28.9 34.0618
21.5 28.9 34.2458
22.5 28.9 34.452
26 28.9 33.6964
-41.55 26.9 29.23375
26.4 28.9 33.44666667
-42.8 44.9 38.611
-53 75.9 33.509
-32 47.9 31.6196
67.5 71.4 52.597
-58 31.9 42.7342
52.55 7.4 45.75375
-36.425 38.9 27.007375
63.6 28.9 62.452
-40.1 49.4 33.66433333
63.7 28.9 63.848
44 15.9 39.6912
-42.475 35.9 35.03408333
64.1 28.9 64.72166667
-27 48.9 31.658
6.5 50.9 61.6332
-28.5 49.4 31.6304
-66 41.4 45.4528
-61.5 43.9 45.9612
-67.5 29.4 41.4582
-55.7 13.9 26.663
7.5 51.4 61.7138
61 52.9 57.6892
-66.5 29.4 41.4432
-67 29.4 41.4372
-64 29.4 41.4658
-60 29.4 41.4788
-13.6 47.9 21.778
-58 29.4 41.4982
56 53.9 57.2978
-57.525 29.4 41.51691667
-41.3 25.9 27.899
-51 54.4 43.7826
12.475 34.9 37.00891667
-56 29.4 41.4218
-54.5 29.4 39.126
-53 29.4 39.1096
13.5 44.4 59.2818
17.45 46.9 57.661
-49.35 29.4 31.5695
48.3 50.9 57.411
-48.5 29.4 31.4212
33.5 41.9 48.887
64.6 2.4 61.67066667
-41.6 29.4 31.254
24 43.9 56.351
-68 10.9 22.6328
64.8 37.9 65.051
43.5 15.9 39.6892
-19.4 56.4 27.76766667
-59 10.9 22.7466
18.5 29.4 36.2172
-42.05 56.4 39.238
-43.525 23.9 27.47225
19 73.9 53.8318
-55.5 20.4 32.43833333
61 6.9 57.3
43.5 15.4 39.92
22.5 29.4 34.4476
40.3 15.9 38.472
-44.5 32.4 33.2782
55.5 51.4 55.4122
-52.5 57.4 43.1934
64.3 62.4 47.849
-5.5 45.9 29.0022
67.9 3.4 64.947
67.7 34.9 64.79
36.7 43.9 49.507
4.5 56.4 61.9792
51.6 69.4 50.435
-27.5 45.9 31.4652
-65.5 30.4 41.98
63.9 29.4 64.34
64.1 29.4 64.72166667
-47.5 33.9 34.1296
-51 30.4 39.6852
49.3 50.4 61.99
-51.3 11.4 17.979
-14.575 49.4 26.540375
-8.5 45.4 28.9106
45.65 11.4 40.6355
28 25.9 34.9754
-30.5 56.9 30.443
68.4 60.4 56.67333333
-70 29.9 41.6958
67.5 71.9 51.7944
-55.45 49.4 44.19575
-69 29.9 41.71
-68.5 29.9 41.7166
-43.1 50.4 36.652
-62.5 29.4 41.4538
-64.5 29.9 41.727
-59.5 29.9 41.7552
63.1 5.9 63.28366667
-57.5 29.9 41.7832
-60.5 10.4 21.6958
-55.7 29.9 41.145
-51.5 29.9 39.3758
-47 24.9 27.1702
-15.4 59.4 21.404
-49.7 29.9 39.203
17.7 46.9 57.509
-48.5 29.9 31.7834
-53 40.4 43.2182
-45 29.9 31.803
-70 63.9 43.399
-44.5 29.9 31.779
64.4 36.4 64.926
-7 45.4 28.9178
-44 29.9 31.7912
52.3 47.4 58.053
62.5 31.9 61.996
-57 14.9 29.247
-25 49.9 31.6084
-47.075 63.9 34.014125
-13.35 59.9 23.1645
-13.6 59.9 22.77933333
-47.5 20.4 31.8092
-56 32.4 42.8662
-34.8 56.4 28.784
-53 58.9 42.85
70 53.4 59.896
43.575 13.4 39.959125
-65 30.4 42.0036
-43.4 45.9 37.939
-56.5 47.4 46.2492
-12 50.4 28.105
14.6 29.9 36.49966667
41.5 16.9 38.9412
18.3 29.9 35.743
-49 23.9 30.5792
-69 43.9 43.53
19.35 29.9 36.387
-41.15 58.9 41.337
-44.8 23.4 27.48
70.9 23.9 90.891
-46 51.4 37.5258
31 25.4 34.8744
-58 62.9 43.7964
-61 73.4 38.164
-12.5 54.4 28.4988
-48.5 48.9 43.836
64.8 29.9 66.886
-37.4 58.9 30.458
65.45 32.9 60.993
10.5 47.4 60.9466
-67.5 50.4 43.7588
69.4 24.4 77.219
-49.35 23.9 30.5535
63.9 30.4 63.218
37.5 71.9 48.8512
-46.5 54.4 37.1704
-32.5 49.4 31.6216
-50.5 47.9 44.1018
64.7 51.4 56.122
63.45 7.9 63.7335
-67.5 30.4 41.9988
-67 30.4 41.9856
-53.475 13.4 23.51725
-64.5 31.4 42.4226
-58.5 30.4 42.0404
-57.5 30.4 42.0422
-54.5 30.4 39.6474
2.5 65.9 52.213
-51.5 30.4 39.7006
-64 67.4 41.829
58.35 7.4 50.456
43.4 49.4 52.76066667
-50.5 35.4 41.8486
-56 27.4 40.3464
-47.5 30.4 32.1416
49.4 43.4 57.33433333
-46.5 30.4 32.1586
-45 30.4 32.1284
-44 30.4 32.097
-59 29.4 41.5106
-54.5 63.4 41.3718
-42.5 30.4 32.096
-55 84.4 22.305
-69 85.4 24.7006
-67.15 40.9 43.639
11.05 45.4 59.73575
-46.475 26.4 29.03308333
-55 41.9 43.543
38.5 41.4 47.3172
13 30.4 36.6988
15 30.4 36.7986
15.5 30.4 36.7992
17.5 30.4 36.5978
-55 35.4 41.8424
-60.5 36.9 44.5484
18.4 30.4 35.737
18.8 34.4 35.048
67.85 44.9 63.104
19.5 30.4 36.4398
21.5 30.4 34.2106
61.05 7.9 55.2455
-66.5 87.9 19.4226
23.5 30.4 33.3298
-59.5 73.4 38.1932
-46.35 21.4 27.453
23.8 30.4 33.205
-36 60.4 26.255
63.65 30.4 59.741
64.2 36.9 65.143
-48.5 23.9 30.6758
-51.5 76.9 32.5462
64.075 30.4 64.0085
63.95 26.4 65.11225
64.7 30.4 65.756
-66 31.4 42.4332
65.2 30.4 67.619
-41.5 49.9 32.286
44.15 12.4 39.7315
42.35 41.4 51.9515
67 48.4 60.422
59.4 5.4 52.76866667
58.5 66.9 52.8778
5.4 50.9 60.89966667
61.5 33.9 60.7752
-25.5 47.9 31.5974
-69.5 30.9 42.2316
-69 30.9 42.2416
-18 49.4 28.143
-68.5 30.9 42.2624
-66 30.9 42.243
-42.05 78.4 31.2355
28.5 47.4 55.1568
-48 56.4 36.7734
69.95 11.9 71.83025
-30 49.4 31.6306
69 24.4 73.836
-64.5 30.9 42.2518
-26 51.9 31.4656
-33.5 50.9 31.5566
61.5 5.9 59.774
55 64.4 50.7206
65.05 30.4 67.258
-59 30.9 42.3014
-57.5 30.9 42.3048
-38.1 69.4 43.969
-19.35 52.4 28.81
-55.45 30.9 40.005
-61.5 29.9 41.7628
-53 30.9 39.9134
65.05 23.4 70.12125
-52 30.9 39.9432
-51.5 30.9 39.9534
-50 30.9 39.8654
-36.45 83.9 32.752
-49.6 30.9 39.514
-1.6 66.4 34.733
-45.5 59.4 35.9042
-44.5 30.9 32.4604
65 16.4 69.2736
-43.5 30.9 32.435
-42.5 30.9 32.4458
58 68.4 52.8488
53 9.4 45.9262
34.5 48.4 54.418
-66.5 25.4 39.1482
-19 55.9 28.0974
43 15.9 39.6474
-70 79.4 32.746
-49.35 26.4 29.1055
-33.5 50.4 31.5766
11.5 30.9 36.9168
-57.5 44.9 46.1186
-56.5 69.4 40.7732
-47.1 45.4 42.19766667
15 30.9 36.9784
16.5 30.9 36.899
-23.5 45.9 31.4602
70.6 49.4 74.907
18.425 30.9 35.0315
-60 28.4 40.9926
40.5 71.9 47.9368
18.7 30.9 36.205
58.5 66.4 52.732
59.4 4.9 52.76866667
-45.5 44.4 37.315
20.15 30.9 34.3295
-19.05 57.9 27.3385
38 50.4 53.6912
1.525 47.4 28.53741667
22.2 30.9 33.488
-69 27.4 40.4076
-1.2 65.9 36.854
-22 50.9 31.5636
63.85 38.9 59.0035
39.7 41.4 48.639
62.5 30.9 60.812
-70 21.9 36.5056
63.4 30.9 59.372
64.4 30.9 61.413
-60 21.4 36.1192
64.7 30.9 63.505
64.8 30.9 64.513
65.15 30.9 67.29
65.3 30.9 67.462
-33 50.4 31.6042
23.5 29.4 34.261
-27.5 49.9 31.6322
-54.5 48.4 44.1052
-44 75.4 24.3036
-50.05 22.9 34.657
-14.5 60.9 24.3904
-61.5 75.4 36.6188
-47.5 69.4 30.435
-37.7 67.4 26.531
-45.5 46.4 37.4932
-38.05 60.9 31.28325
4.3 34.4 36.495
-20 46.4 27.29333333
-19.8 46.4 27.72
-42.3 22.4 24.734
62 68.9 53.6306
-69.5 31.4 42.4338
-68.5 31.4 42.451
-49 28.4 30.7408
-61.5 66.4 42.3548
-44 28.4 30.7078
-61 31.4 42.4678
-6 46.4 29.0366
-56 31.4 42.435
-23.5 51.4 31.5224
-49.35 33.9 34.1675
14 34.9 36.6044
-54 31.4 40.176
25 73.4 51.7208
-65 21.4 36.0894
-53.5 31.4 40.1864
66.35 14.9 69.4435
-18 58.9 25.8906
-55 36.4 42.222
-49.35 31.4 32.8235
-17.5 45.9 28.1498
-49 31.4 32.79
-34.65 57.4 28.6755
-34.55 55.4 29.795
-48.5 31.4 32.7852
-59 17.4 32.3078
-46.5 31.4 32.7484
67 24.4 70.4778
-45.5 66.4 32.6292
-45 31.4 32.7586
-44.5 31.4 32.7682
-42 31.4 32.736
-53.5 28.9 38.8336
-69 60.9 43.7966
-58 19.4 34.3804
-11.5 47.4 28.1232
-40.4 31.4 30.75
-39.65 31.4 29.2645
-64.5 40.4 45.4366
-38.9 31.4 27.77933333
-38.15 31.4 26.2985
-67.5 19.9 34.7354
-49.7 27.4 37.602
-41.125 29.9 30.98625
11.65 44.9 59.2155
-48 30.4 32.0988
-55.7 85.4 20.917
46.1 42.9 56.11333333
-41.65 31.4 32.598
-47.5 20.9 32.014
-70 53.4 43.7326
12 31.4 37.1746
-28 44.9 31.3698
13 31.4 37.1628
14 31.4 37.1088
15 31.4 37.0428
-20.35 61.4 26.8435
-50 44.4 43.8454
-51.5 18.4 29.8968
19.05 31.4 36.29825
20.5 31.4 33.9002
-12 43.9 28.6534
41.5 42.9 53.323
26 74.9 50.8334
37.8 40.9 46.038
64.85 48.4 57.6855
-52.5 20.4 32.0922
64.85 31.4 57.831
-62 79.4 32.8342
-61 17.9 32.7928
-41.1 28.4 29.814
-2.7 65.9 30.617
12.5 47.4 60.3308
-9.5 48.9 29.1014
-69.5 12.4 25.3694
64.5 22.4 69.239
59.95 31.4 59.126
63 31.4 61.63
-6 44.4 28.7764
64.35 31.4 58.718
-61 25.9 39.4812
-58 28.4 41.0222
42.5 48.9 52.8478
-7.5 42.4 28.12
21.425 44.9 57.503125
-32.5 56.9 30.4486
65.525 31.4 67.48
66.5 31.4 67.7852
52.5 53.4 55.98
57 69.4 52.3634
34.5 19.9 36.2298
-46 69.9 30.0592
-46.05 78.9 20.8305
-67.2 36.4 43.507
4.5 55.9 61.9642
-31.5 51.4 31.5088
-46 44.9 37.3644
-62 40.4 45.4264
-40.95 47.9 41.23675
-50.5 66.9 39.8074
65 37.4 65.352
-67 33.9 43.3984
-69.5 31.9 42.666
46 15.4 39.6942
-68 31.9 42.6878
42.05 14.4 39.1565
-66 31.9 42.6938
-63 31.9 42.6836
-60 31.9 42.7176
64.5 43.9 63.1318
-47 27.9 30.308
-58.5 31.9 42.7326
-57.5 31.9 42.7366
-56 31.9 42.684
-55 31.9 40.3828
-54 31.9 40.4056
-60.5 14.4 28.4914
-53.5 31.9 40.379
67.525 3.9 65.99283333
45.2 11.9 40.628
-42.575 31.9 33.021625
-56.5 19.4 34.3894
-41.55 31.9 32.79725
-17.4 57.9 25.582
-39.35 31.9 28.8455
-43.65 22.4 25.1075
-42.5 28.9 31.0702
70.5 44.9 63.118
-38 31.9 26.33
-37.8 31.9 25.958
-39.5 83.4 25.268
-37.15 31.9 24.7505
-36.6 31.9 23.73033333
-36 65.4 22.7798
11.15 49.4 59.893
-35.7 31.9 22.618
-24 54.9 30.975
17.5 32.4 36.7562
-43.5 35.4 34.8554
15 45.4 59.6714
-65.5 35.4 44.0794
-69 78.9 33.2764
-66.4 48.4 45.82333333
63.6 31.4 59.806
-4.35 46.9 28.5055
-24.5 43.9 31.2014
-58.5 60.9 44.4608
18.5 34.9 34.3656
61.8 8.9 58.015
53.4 53.4 54.632
63.5 44.9 62.3914
9.35 31.9 36.426
9.6 31.9 36.624
-38.2 79.4 39.956
9.85 31.9 36.9055
10.1 31.9 37.09766667
13.5 31.9 37.2252
5.45 37.9 30.8145
-61 36.9 44.5424
16.025 31.9 37.015
16.5 31.9 36.9368
17 31.9 36.8936
38 51.4 52.724
18.85 31.9 35.193
19 31.9 35.775
19.15 31.9 36.1155
69.3 24.4 76.379
-49.5 23.9 33.117
64.3 22.4 68.424
54.5 9.9 45.646
-45.5 21.9 26.779
0.5 66.4 43.207
54.85 7.4 47.9575
-36 56.9 27.8102
-21.15 58.4 29.7375
-28 55.4 30.8096
-55 33.9 41.291
-55.6 66.4 41.346
-46 42.4 36.987
59.6 31.9 58.97966667
-64 85.4 24.7548
63.5 31.9 60.781
-50.5 26.9 37.6608
63.7 31.9 58.354
-32.8 83.4 41.504
-3.1 66.4 28.723
-40.45 78.4 35.2625
64 31.9 58.3398
65.3 31.9 57.622
65.5 31.9 61.497
10 43.4 28.4728
66.85 31.9 67.761
67.1 34.9 63.95866667
-61 29.4 41.4892
52.45 55.9 54.6315
-60 13.9 27.7574
-61 23.9 38.1194
-37 66.9 21.3388
51.9 49.9 64.232
15.5 43.9 58.7838
69 43.9 63.256
40 41.9 49.78
11.5 72.9 55.7442
54 55.9 56.7036
-38.2 59.9 31.62
-37.95 59.9 31.3695
65 63.9 53.3336
-63 32.4 42.9156
-62.5 32.4 42.8996
-57.5 64.9 43.0058
-57 32.4 42.9476
-55.7 32.4 42.431
-55.45 32.4 40.681
-7 60.9 25.9716
52.5 8.9 45.856
-57.5 36.4 44.445
-54.5 32.4 40.6226
-55 64.9 40.749
72.85 42.9 63.578
-69.5 48.4 43.658
55 46.4 58.81
66.3 46.9 60.447
-46 28.9 31.0638
-49.3 32.4 33.362
-48 32.4 33.3176
-47 32.4 33.3246
-66.025 68.9 41.01941667
-52.5 76.4 33.0506
-14 44.4 28.6582
-47 64.9 33.468
-37.5 59.4 30.842
-53 76.4 33.054
-46.5 64.9 33.464
51.5 11.4 43.5954
-46 32.4 33.3182
-30.975 44.9 30.5075
-44 32.4 33.306
-66.5 23.9 38.1078
-43.5 32.4 33.3496
-62.5 80.4 31.7722
-42.5 32.4 33.3054
-40.5 32.4 31.30833333
27.5 25.9 34.9206
-40.3 32.4 30.947
-39.4 32.4 29.32133333
72.85 43.4 63.393
-69.5 48.9 43.6806
-54 25.9 36.9632
-69 19.9 34.7566
-38 32.4 26.79566667
-24.5 56.9 30.3756
-56.5 73.4 38.2168
-51 41.9 43.5226
-50.5 40.9 43.34
41 69.9 48.6956
53.5 44.9 60.6116
-37 63.4 24.4838
-36.6 32.4 24.27933333
-55.65 66.9 41.346
-36.35 32.4 23.8315
-48 29.4 31.4316
-66 66.4 42.323
-28 46.4 31.5102
46.15 40.9 55.158
-19 54.4 28.4638
65.2 16.9 68.094
11 32.4 37.456
57 68.4 52.676
17 30.4 36.7288
11.5 32.4 37.451
18.2 32.4 36.459
18.4 32.4 36.357
18.6 32.4 35.64
-38.9 33.9 29.31633333
22 27.9 34.171
18.9 32.4 34.62366667
-62 12.4 25.4294
19.15 32.4 35.7445
19.5 32.4 35.9988
52.05 45.4 56.7875
-58 44.4 46.0284
69.4 6.9 71.53583333
-63.5 70.4 40.1918
-61.5 81.4 30.5924
-59 47.4 46.246
-37 58.4 27.2522
-25 59.4 29.5038
64.95 9.9 65.8205
60.5 32.4 61.974
61.5 32.4 61.9138
54.05 74.4 54.45675
-68.5 74.4 37.371
36.65 43.4 51.736
-39.2 58.4 44.678
63 32.4 61.9656
-41.3 31.9 32.511
63.4 32.4 61.829
70 12.4 71.6498
64.9 32.4 57.85733333
73 49.4 91.661
-11 48.9 28.1098
28.5 71.9 50.3254
-40.05 64.4 46.02875
65.3 32.4 59.628
65.55 32.4 60.613
65.7 32.4 61.78
62.7 2.9 60.413
65.8 32.4 61.917
47.5 11.4 42.3348
66.35 32.4 63.1545
35.5 43.9 54.8164
-36.5 63.9 24.0802
39.2 19.9 37.012
28 72.9 50.8518
66.1 64.9 54.293
62 70.4 52.9486
-50.05 26.9 37.603
-55.45 44.4 43.97375
5 33.9 36.607
-56.5 21.9 36.5638
63.6 3.4 62.19333333
-46.5 40.4 36.5238
54.7 5.9 47.157
-69 32.9 43.0718
-67 64.4 43.1986
-29.5 44.9 31.199
-66.5 32.9 43.0914
62.65 58.4 56.9305
31.5 47.9 54.7116
-63.5 32.9 43.1156
-63 64.4 43.1842
-58.5 32.9 43.1358
-58 64.4 43.2264
-52 50.9 44.0706
61.85 4.4 60.4015
-56.5 64.4 43.2132
-28.5 58.9 29.7154
-56.05 32.9 43.13225
40.55 43.4 52.68425
-12 55.9 28.0816
-56.5 65.4 42.7964
-55.8 32.9 43.013
-55.5 32.9 41.004
-54.5 32.9 40.8764
67.15 11.9 69.694
-54 64.4 40.9384
-38.45 73.9 45.2555
-50 64.4 40.9454
-48.5 32.9 33.5896
-46 32.9 33.5814
-46 64.4 33.7446
-46 27.9 30.3136
-49.35 33.4 33.957
59 39.4 61.4174
70.8 51.4 63.812
8 32.9 36.2946
-61.5 31.4 42.4636
-52.5 43.9 43.816
58.15 8.4 48.6295
30.5 44.4 54.8516
-54.5 44.9 43.947
65.9 63.9 53.87633333
11.5 32.9 37.513
16.5 32.9 36.8394
17.5 32.9 36.6034
17.975 32.9 36.48366667
19 32.9 33.9196
-66.65 40.9 44.849
19.3 32.9 34.576
19.6 32.9 35.66433333
-13.3 58.4 22.708
-44.2 22.4 25.608
20.3 32.9 35.587
-22.5 55.9 30.6834
-63.5 36.9 44.5238
-21 46.4 29.323
64.7 72.4 47.15
-64 45.4 46.1144
31.9 74.4 48.743
14 30.9 36.9872
32.5 41.9 48.9958
-60.5 29.9 41.7566
69 55.4 59.171
-44.5 76.9 22.1244
-10 48.9 29.1242
-5.5 42.4 28.0822
61 32.9 61.581
52.5 43.4 59.088
62 32.9 61.6566
31.5 45.9 54.6794
62.3 32.9 61.672
52 49.9 64.806
64.85 32.9 61.696
65.1 32.9 61.376
-64 43.4 45.9052
65.7 32.9 61.78
-29 52.4 31.4288
67.55 32.9 63.4615
-42 41.9 35.754
71 44.4 63.2494
66.3 19.9 73.898
-37.6 65.9 26.125
-2.5 51.4 29.0922
-67.5 48.4 43.7602
-42.2 37.4 35.644
-21.05 42.9 30.64125
-43.1 54.9 34.668
-13.35 46.9 27.2265
-62 20.9 35.6834
-13.05 44.9 28.32325
-42 21.4 23.4182
-63 33.4 43.3504
-62.5 33.4 43.354
-61 33.4 43.3396
-61 32.9 43.1078
-61.5 64.4 43.2032
-53 44.4 43.8696
-62.5 66.4 42.336
-59 33.4 43.3552
-55.7 33.4 42.956
-69.5 59.9 43.803
-49.5 33.4 40.204
-2 45.4 28.9074
-43 33.4 33.8404
-41.35 33.4 33.332
-2.5 44.9 28.8522
-40.7 33.4 32.213
-6 47.4 29.0688
47.5 12.4 42.2082
-39.95 33.4 30.7355
69 6.4 70.7118
-40.65 58.4 41.653
-38.95 33.4 28.7645
-38.3 33.4 27.487
-37.95 33.4 26.799
-68.5 20.4 35.2008
-37.65 33.4 26.211
-37.4 33.4 25.721
-36.65 33.4 24.2535
-69 75.9 36.1174
59.5 38.4 59.1098
-32.5 48.9 31.6266
66.6 15.9 70.17266667
43 42.9 54.3346
-37.15 59.9 30.3205
6 33.4 36.3804
9 33.4 36.6912
-24.5 56.4 30.5512
10.05 33.4 37.15625
11 33.4 37.4784
11.425 33.4 37.520625
11.925 33.4 37.509375
5.5 36.4 36.4164
-49.55 44.4 43.322
52.7 53.9 54.985
13.5 33.4 37.285
14 33.4 37.2078
47.1 11.9 42.10133333
71.1 25.9 93.053
16.5 33.4 36.6974
11.85 69.9 57.101
-50 35.4 41.819
46.3 44.9 47.343
-55 64.4 40.9586
20 33.4 35.252
-47.5 22.4 31.9108
-65.5 67.4 41.8202
45 68.9 47.6652
-50 45.4 43.9198
61 33.4 61.1874
62.5 33.4 61.2136
-69 82.4 29.223
62.9 2.4 58.04433333
-68 17.4 32.2216
30 43.4 53.3774
-49.05 25.9 28.6595
-41.35 46.9 40.6935
67.85 33.4 64.4275
69.475 44.9 63.02391667
54.05 74.9 54.45675
-21 43.4 30.8224
-54 60.4 42.4076
-41.5 43.9 35.3148
10.5 48.9 60.851
67.4 10.4 70.75966667
30.5 70.9 48.9402
53.1 65.9 49.827
69.5 17.4 73.2426
-64.5 70.4 40.1714
-66.5 33.9 43.4508
-0.5 47.9 29.0998
-66 33.9 43.4836
-66.5 64.4 43.1764
71 72.4 52.8622
38 45.9 50.2436
61 32.4 61.9362
-62 33.9 43.5056
-61.5 33.4 43.3566
44.5 70.9 47.78
60.625 8.9 55.41125
-61 33.9 43.521
-59.9625 33.9 43.523
15 44.4 59.3368
8.5 41.4 27.0676
-54.5 33.9 41.2424
-51.5 33.9 41.269
-37 62.9 24.7962
-50 33.9 41.2414
-49.65 33.9 40.8555
-49 33.9 34.1492
-35.7 77.9 43.76
-45.5 33.9 34.1264
66.525 53.9 58.5315
-45 33.9 34.1282
52.3 55.4 55.901
-42.5 33.9 34.1452
-41.2 33.9 33.186
-38.85 67.9 45.4115
-56.5 45.4 46.1462
-66.5 30.4 41.9844
-37.1 33.9 26.29433333
66 43.9 63.424
49.5 11.4 43.3564
6 33.9 36.672
60.8 7.4 53.719
23.5 27.4 34.3682
9.5 33.9 36.852
63.5 43.9 62.9108
-6.5 58.9 27.0344
-33 55.9 30.7074
10.5 33.9 37.2854
-67.5 9.9 20.3916
46.85 45.4 49.335
11 33.9 37.4172
11.5 33.9 37.491
57.7 75.9 57.394
12.5 33.9 37.386
13 33.9 37.2708
13.5 33.9 37.1714
-38.05 44.4 32.65175
-38 74.9 45.1646
14 33.9 37.086
-45.5 42.9 37.0786
16.5 33.9 36.4622
17.975 33.9 36.02441667
-19.55 45.9 28.224
-13.5 62.9 24.1624
-65.5 30.9 42.2416
59.65 33.9 60.0535
72 49.4 84.766
-70 48.4 43.6562
61 71.4 51.6398
-59.5 24.4 38.4844
61 33.9 60.7446
62 33.9 60.7308
64.05 2.4 61.18525
63.35 33.9 61.46
-54 75.4 33.9516
-2 66.4 33.13
-48 64.4 33.7408
64.5 33.9 62.1806
-56.5 14.4 28.5526
65.025 33.9 62.50966667
65.5 33.9 62.8074
-49.7 83.9 21.744
-42 21.9 24.0994
-58.5 83.9 27.1526
0 53.9 28.679
67.5 33.9 64.2324
-23 52.4 31.4226
72.6 50.4 86.668
-51.55 11.4 18.146
72.9 49.4 91.106
28.5 47.9 54.6474
-58 44.9 46.1148
-50 72.9 35.9072
66.7 7.4 69.16
-56.5 43.4 45.9216
50.7 70.4 43.379
-51.5 20.4 32.158
-65.5 34.4 43.7022
-64.5 34.4 43.708
-60 34.4 43.7264
-59.5 34.4 43.7124
-59 34.4 43.7364
-34.5 81.9 42.6386
-57.5 18.9 33.876
-55 62.4 41.7848
19.65 33.4 35.1665
-47 37.9 35.8112
-36.2 75.9 42.921
-58 34.4 43.7384
-19.85 57.4 26.347
-57 34.4 43.7564
-16 52.4 28.174
16.5 29.9 36.5878
-53 34.4 41.4678
-66.5 28.4 40.9598
-51.5 34.4 41.462
-51 34.4 41.4606
58 7.4 50.2366
-49.55 34.4 40.7135
-49.4 34.4 35.113
52.4 54.9 55.798
-45.5 63.4 34.2156
-45 34.4 34.3796
-52 17.4 28.5742
3.6 50.4 50.507
-44 34.4 34.3488
12.5 32.9 37.4504
-35.6 34.4 24.453
-43.5 36.9 35.4364
-34.5 79.9 42.955
63.65 26.4 64.782
6 34.4 36.7836
62 35.9 52.95
-67.5 70.9 39.8684
7 34.4 36.6654
8.5 34.4 36.5326
-54 24.9 36.2496
10.3 34.4 37.009
-20.8 42.9 30.507
-69.5 32.4 42.8632
14 73.4 55.4474
-43.425 31.9 33.024375
67 53.4 58.6606
13 34.4 37.0766
-41 25.4 26.501
13.5 34.4 36.9728
14 34.4 36.9096
-2 55.9 28.1092
-50.5 65.4 40.4926
-10 49.4 29.1388
64.025 46.4 60.50291667
-44.05 79.9 24.6985
-68 18.9 33.7828
-33.5 47.9 31.6452
-38.8 65.4 44.957
7 36.4 36.1096
-61.5 14.4 28.4578
-63.5 71.4 39.5744
64 40.4 62.2928
36.5 18.4 36.8532
58 54.4 57.6992
12 34.9 37.147
58.4 34.4 52.045
-54 44.4 43.8678
59.575 34.4 59.96225
57 42.4 62.4598
63.075 2.9 60.896875
62.55 34.4 60.7805
71.6 53.4 75.511
4.5 46.4 28.1842
69.5 25.4 75.046
-53.5 44.4 43.8692
-62 69.4 40.772
-17.4 58.4 25.213
-41.15 58.4 40.3305
57.9 8.9 49.292
-65.5 29.9 41.7336
8 36.4 35.721
-37.4 45.4 29.74
-46 40.4 36.5388
4.55 38.9 33.2785
18.075 29.9 34.90175
-68.5 40.9 43.4776
-69.5 34.9 43.247
-69 34.9 43.2264
-67.5 45.9 43.6918
-66.9 34.9 43.61533333
-65.5 34.9 43.8994
-64.5 34.9 43.8772
-64 34.9 43.9076
-61.5 34.9 43.9024
-70 62.9 43.7546
-61 34.9 43.9168
-59.5 34.9 43.901
20.5 43.4 57.116
-69 38.9 43.3912
-57.5 34.9 43.8992
-53 34.9 41.6892
0 43.9 28.5876
-52.5 34.9 41.659
29.9 43.9 53.39
-14.95 56.9 18.14975
72 42.4 63.9532
-49.35 34.9 34.8005
-45.5 34.9 34.5952
64.1 3.4 62.719
-35.95 34.9 25.20875
-33.9 45.4 29.60833333
56.15 5.9 49.14
4.3 34.9 36.507
5 34.9 36.7494
6.5 36.4 36.2632
-48 31.9 33.0612
52.05 50.9 66.30275
7.5 34.9 36.5902
10.5 34.9 36.9246
-19.8 57.9 26.152
10 71.9 56.6516
67.15 4.9 67.6
11.5 34.9 37.2092
13 34.9 36.886
-37.8 45.4 32.504
-40.8 59.9 44.69
17.5 34.9 35.3806
17.95 34.9 35.123
18.2 34.9 34.806
18.8 34.9 34.331
-10.5 52.4 28.919
-39.5 60.9 31.2844
-54 41.4 43.4374
-58.5 17.4 32.3022
-42.15 36.9 35.4625
67.2 2.4 65.242
66.35 34.9 65.2695
-27.5 55.4 30.8188
67.95 34.9 64.5115
-68 33.4 43.235
-45 24.9 27.3218
-48.5 27.9 30.3508
-16.9 49.9 27.45366667
61 44.4 62.823
-41.5 32.9 33.299
49.3 10.4 43.496
-45 28.4 30.6964
-55.8 13.9 27.357
54.95 74.9 56.10875
-21.5 48.4 31.6026
67.95 44.4 63.19725
-62 23.4 37.7698
67.65 12.9 70.137
-25.5 46.4 31.5422
52.55 51.4 67.4565
60.5 68.9 53.3094
-43.45 58.4 36.611
37 48.9 54.0662
-43.05 83.4 14.7835
-66.95 35.4 43.63825
57 70.9 51.2454
-67.5 45.4 43.6786
-58.5 25.4 39.1728
-4.1 66.4 24.69
-64.5 35.4 44.0714
-63.5 35.4 44.0782
-68 26.9 40.0836
43.85 49.4 53.8465
-59.5 29.4 41.4946
6.35 58.4 60.6445
-60.5 35.4 44.084
67.5 2.9 64.976
-46.625 49.9 43.00075
-59.5 35.4 44.0994
-51 36.4 42.2074
-58 35.4 44.1094
-57.5 35.4 44.1144
51.5 40.4 58.77466667
-57 35.4 44.121
46.5 42.9 56.3138
-54.5 35.4 41.8496
-51.5 35.4 41.865
55.5 70.4 51.3466
66.4 47.4 60.38333333
53.6 40.9 61.47866667
-49.65 16.4 27.1755
62 65.9 53.1574
-46 35.4 34.855
-44.5 35.4 34.8408
60.1 33.4 61.09833333
63.85 46.9 56.8275
-44 35.4 34.852
-57.5 16.4 31.1914
56 44.4 61.7396
-37.6 56.4 31.421
-12.5 46.4 28.0994
-53 22.4 33.859
67 32.9 63.8384
-66.5 72.4 38.8808
-36.3 35.4 25.326
-56.5 42.4 45.7948
-14.3 47.9 19.149
4.425 35.4 36.534875
63 43.9 62.8302
6 35.4 36.7816
6.5 35.4 36.7178
10.5 35.4 36.6356
6 53.9 61.9892
-38.35 83.9 27.1805
11.5 35.4 36.9178
12.5 35.4 36.7638
13 35.4 36.595
-49.8 82.4 25.472
13.5 35.4 36.424
33 49.4 53.614
15 35.4 35.88
70 17.9 73.4122
-37 48.4 29.1336
-64 57.9 45.2752
41.5 48.9 53.268
21.05 47.4 55.9715
-69.5 29.4 41.4372
-10 51.4 29.0428
38 18.4 37.6464
-50.1 10.9 16.52316667
-45.5 70.9 29.2384
-38.5 49.4 33.1034
58.5 35.4 51.8794
62 35.4 52.8464
62.5 35.4 52.9628
63.2 35.4 54.731
-41.15 26.4 27.943
-57.5 37.4 44.7286
65.1 10.4 64.944
63.9 35.4 59.185
-54.5 78.4 30.9968
-25 59.9 29.2728
64.1 35.4 60.467
62.3 8.4 57.035
-35.55 43.4 28.29575
33 72.9 49.583
64.4 35.4 62.392
65.8 3.9 64.934
65.525 35.4 65.00325
67 3.9 65.7666
15.5 33.4 36.9186
66.5 35.4 65.599
-53.5 41.4 43.4502
70.5 55.9 64.016
36.975 71.4 49.01875
67.45 6.9 69.82425
72.6 49.9 87.912
-21.5 48.9 31.6164
-49.65 54.9 43.354
-60 31.4 42.462
72.4 49.4 87.579
-56.5 72.4 38.9066
-69.5 35.9 43.3094
-69 35.9 43.3162
-68 35.9 43.3546
-67.2 35.9 43.502
-66.5 33.4 43.326
-21.99166667 46.4 31.52175
-67.5 44.9 43.669
54 9.9 45.6736
-66.95 35.9 43.69225
-65.5 35.9 44.2516
-65 35.9 44.229
64.2 36.4 63.345
-38.2 72.9 45.456
-67.525 83.9 27.01716667
-60 35.9 44.2476
52.15 72.4 47.188
-9.5 42.4 28.156
-59.5 35.9 44.2612
-59 35.9 44.2448
-47 36.9 35.4166
-57 35.9 44.2612
-56.5 35.9 44.2518
59 46.4 60.1544
-58 21.9 36.581
-55.7 35.9 44.026
-0.6 65.9 39.362
-45 41.4 36.7442
-54.5 35.9 42.0234
-64 73.9 37.794
-53.5 35.9 42.048
-52 35.9 42.056
-56.025 13.4 26.91416667
41.15 14.9 38.6605
-54.5 21.9 33.3832
62 66.4 53.3794
-45.975 35.9 35.01875
-60 55.9 45.633
-44 35.9 35.0255
-39.7 33.9 30.664
-41.975 35.9 35.02358333
32.4 44.9 54.643
-35.575 35.9 25.42725
67.7 6.9 70.118
-68.5 26.4 39.785
-55.975 23.9 37.97266667
52.5 50.9 67.44766667
6 35.9 36.6628
8.425 35.9 36.008
-38.15 75.4 44.951
10.5 35.9 36.2196
54.5 70.9 50.388
68.5 8.4 71.8454
35.5 46.9 53.8856
-57 46.4 46.2058
-34.15 47.4 30.807
-41.5 35.4 34.454
71.6 51.4 74.115
-33.2 82.4 41.838
-44.5 47.9 37.5968
-5.5 47.9 29.0996
-60.5 67.4 41.8682
55.7 35.9 52.428
-55 24.4 35.893
55.6 35.9 51.688
40.5 48.9 53.282
-8.5 48.9 29.0866
61 35.9 52.9324
62.5 35.9 52.9784
-43.5 33.4 33.8634
63.2 35.9 54.417
68.5 11.4 71.3176
-36.075 32.4 23.457125
63.5 35.9 56.802
64.4 35.9 64.034
60.5 57.4 57.5998
-9.5 54.9 28.4074
-42.4 20.9 22.89333333
66 35.9 65.2918
-62.5 66.9 42.0918
67 5.9 68.2674
33.975 71.9 49.50275
-24.5 46.9 31.555
-66 39.9 45.1798
19.5 45.4 58.115
-25 55.9 30.6938
-63 9.4 19.205
-70 36.4 43.312
-63.5 36.4 44.4104
66 2.9 63.331
13.925 46.4 59.997
-61 36.4 44.4062
-37.15 83.4 32.221
2.2 49.9 42.102
-53 36.4 42.1746
64 66.9 53.3486
-49 36.4 35.2416
63.15 58.9 55.4735
-4 60.4 26.17
66.9 32.4 63.988
-48.5 36.4 35.2528
-69.5 33.9 43.2158
-70 64.4 43.179
71.35 71.9 53.072
-46 36.4 35.2418
-45.5 36.4 35.251
-44 36.4 35.25
-41.85 36.4 35.0045
55 8.9 47.1298
-53.5 24.4 35.8666
-33.5 55.4 30.8548
-34.5 44.9 28.853
62.4 41.9 63.189
-33.5 53.4 31.2488
67 25.4 70.177
53.35 39.4 59.868
72.4 53.9 85.98
10.5 69.4 57.871
-41 42.4 34.8306
31 73.9 49.718
-54.5 31.9 40.3882
55.55 73.4 50.083
2.2 36.4 32.222
-49.65 64.9 40.3575
3.125 36.4 33.3515
-33.475 45.9 29.9605
65.15 47.4 58.7115
4.15 36.4 35.805
11.025 35.9 36.47033333
8.8 36.4 35.385
-38.5 62.4 30.9848
-18.575 43.9 29.47775
64.5 7.4 66.28733333
-47.5 62.9 34.4778
-55.45 46.4 44.1255
11.5 70.9 57.301
-41.4 48.4 40.75566667
-64 24.4 38.4588
-55.45 13.4 24.44675
65 42.4 63.3558
-41.5 57.4 39.81166667
54 68.9 51.7536
65.4 31.9 59.607
-52 63.9 41.172
67.15 13.9 69.1325
58 68.9 52.7236
63.1 36.4 54.732
-58 45.4 46.1284
-53.5 23.4 35.135
42 44.4 53.3602
63.4 36.4 57.062
59.4 6.4 52.404
63.5 36.4 57.844
66.525 36.4 65.50975
-31.5 46.9 31.5346
64.5 36.9 65.3538
67.9 23.9 74.555
-61.5 22.9 37.367
53.7 74.4 53.926
-50.5 61.4 42.1296
59 57.4 57.2324
44.5 50.9 53.442
-3.5 48.9 29.051
-50 75.9 33.4568
35.3 50.4 53.002
-44.5 59.9 35.659
58.075 57.4 56.99625
-45 32.4 33.2942
-45.5 64.9 33.455
-3.5 57.9 27.4528
4.4 39.4 32.464
47.5 51.4 53.1162
-55.7 44.9 45.671
-39.6 57.4 42.89333333
63.6 36.4 58.627
-62 74.4 37.4068
-62 15.4 29.8524
-36.5 47.9 29.1708
35 21.9 36.353
66 8.4 68.20033333
65.8 8.4 67.934
42.55 44.4 53.6345
-25 56.4 30.5366
-11.5 48.4 28.1266
-67.5 43.9 43.6596
-49 35.4 34.8278
-65.575 36.9 44.54425
-55.4 15.4 26.972
-65 36.9 44.5574
-60.5 11.9 24.5908
64.05 37.4 64.7765
-48.5 41.9 36.8896
64.575 4.9 64.909625
-64 36.9 44.5318
-59.5 36.9 44.561
-58.5 36.9 44.5422
2.9 66.4 52.815
-37.2 79.4 42.406
-57.5 36.9 44.5872
57.15 5.4 50.1305
-43.5 56.4 36.9196
-55.7 36.9 44.241
56.5 7.9 48.788
-54.5 36.9 42.3156
-59.5 84.4 26.3828
-54 36.9 42.349
-51.5 36.9 42.3618
-50.5 36.9 42.345
-45 69.4 30.4384
-50 36.9 42.3168
-49 36.9 35.4442
-41.55 24.4 26.7605
-9.5 45.4 28.9352
-47.5 36.9 35.4444
-45.5 36.9 35.4588
-35.6 36.9 25.91466667
-38.4 69.4 44.697
2.3 36.9 32.481
2.9 36.9 32.773
-54 69.4 38.3794
3.3 36.9 33.488
4.15 36.9 35.1425
5 36.9 35.9034
52.15 47.4 57.327
5.925 36.9 35.99875
6.4 36.9 35.4685
18.3 31.9 36.442
-60.5 37.4 44.7036
-22 58.4 29.9254
-64.5 14.9 29.1698
-56.5 66.9 42.1212
7.1 36.9 34.37
-57.5 70.4 40.1884
64 52.9 57.4058
-62.5 76.4 35.7582
-43.2 83.4 14.338
-48.2 16.4 21.666
-12.95 58.9 23.295
-51.5 84.9 21.3392
-14.85 57.4 17.162
-67.2 35.4 43.483
-36.5 64.4 23.6422
65 42.9 63.3558
-42.075 25.9 28.457125
8 33.9 36.5258
65.3 65.4 52.99
29 43.9 53.3694
61 36.9 53.7652
65 31.4 60.411
61.025 68.4 53.49816667
61.5 36.9 53.8224
62 68.4 53.7038
-46 43.9 37.2446
-0.6 56.4 28.09133333
59.85 33.4 60.915
-61 14.9 29.1906
62.5 68.4 53.6694
-57 33.9 43.5494
62.9 36.9 53.94133333
-58.075 24.4 38.5235
52 41.4 59.1268
63.4 36.9 58.287
-51.5 83.4 24.1104
65.65 50.9 58.1095
63.8 36.9 62.659
67.575 36.9 65.968125
68 10.9 71.2364
-61 16.4 31.1182
-21.1 46.9 30.665
-46.5 25.4 28.1538
-46 73.4 26.7172
48.65 49.9 63.3665
-25.5 58.4 29.939
-12.5 53.4 28.7246
55.1 72.9 49.91666667
-57.5 20.4 35.3012
-49.35 45.9 42.858
30 46.4 55.2442
62.5 54.4 58.375
-54 26.4 37.334
50.5 41.4 60.2748
-49.65 31.4 39.7735
49.85 45.4 57.8315
-45 36.4 35.2332
-57 14.4 28.5462
-53.5 81.9 26.4964
-10.45 60.4 25.88675
-25 46.4 31.548
-50 43.4 43.7264
-69 37.4 43.3484
-67.425 37.4 43.4915
-48.5 33.4 33.89
65.1 14.9 66.817
-13.875 48.4 22.85625
66 26.4 69.572
-48.5 42.4 37.0088
63.9 36.9 63.757
-64 37.4 44.702
-62.5 37.4 44.7042
-61 37.4 44.7018
53.15 38.9 58.2155
-33.5 49.4 31.642
-60 37.4 44.7092
-55.45 27.4 38.04425
52.9 65.9 50.78233333
-57 37.4 44.7458
48.1 9.9 42.23466667
-56.5 37.4 44.755
53.55 42.9 61.6255
-69.5 26.4 39.7716
-58 21.4 36.1708
55.7 74.4 53.357
-54.5 37.4 42.4968
-54 37.4 42.4744
-65 87.4 20.6736
-53 37.4 42.4936
-2.55 65.9 31.238
15 43.9 58.7604
-48.5 37.4 35.6276
2.6 37.4 32.761
-47 37.4 35.6428
-61.5 57.9 45.2192
-44 37.4 35.6006
12.5 35.9 36.3778
-50.5 53.9 43.8124
52.075 66.4 50.9885
32.2 43.9 52.136
-39.5 49.4 33.1142
15.5 32.4 37.0982
-45.5 41.4 36.7576
64.8 39.4 61.453
56 72.9 48.628
-42.85 45.9 38.7135
-59.5 75.9 36.2288
39.9 46.4 49.877
-36.8 40.9 27.875
64.2 6.9 65.632
-63.5 31.9 42.6948
-11.5 51.9 28.9082
44.3 16.9 38.267
-69 31.4 42.4402
-53 37.9 42.6216
-65 9.9 20.446
63.85 28.9 64.284
-1 57.4 27.6794
61.5 35.9 52.9358
3.85 54.9 61.445
65.125 47.9 58.44425
-55 79.4 29.8266
53 37.4 52.293
19 30.9 36.4266
21 48.4 57.6286
66.5 37.4 65.773
68 37.4 66.1074
-68 29.9 41.7286
63.15 5.4 63.169
50.05 55.4 53.0995
-66 12.9 26.1988
38.35 44.4 50.9225
-56.5 83.9 27.089
60.1 6.4 53.14166667
-62 64.4 43.1878
-61.575 33.9 43.515125
-45 56.4 36.7734
-50.5 59.4 42.7384
-31.5 46.4 30.6902
-66 76.9 35.2678
-60 38.9 45.1046
50.2 71.4 44.025
-41.8 46.9 40.112
68.1 44.9 62.837
65.3 2.9 63
-68 37.9 43.4062
-11 49.4 28.1144
-67.425 37.9 43.5025
-41.7 79.4 31.372
-63.5 37.9 44.812
71.7 52.4 75.815
45 13.4 40.877
-59.5 37.9 44.8404
-58 37.9 44.8496
-54 37.9 42.6484
-52 37.9 42.5928
-51 37.9 42.6298
8.7 46.9 59.938
-49.4 37.9 36.762
-46 37.9 35.794
-45 37.9 35.7744
48.5 13.4 41.6008
-55.7 44.4 45.787
-44 37.9 35.8082
-52.5 29.4 39.0912
68 64.4 55.203
49.7 43.9 57.468
-36.8 37.9 26.612
-36.5 37.9 26.6264
67 3.4 64.7238
4.5 37.9 32.81
-51 31.9 40.4148
5 37.9 30.5702
6.575 37.9 33.50425
-56.5 8.9 17.94
39.9 41.4 48.88933333
-58.5 14.9 29.2234
-70 44.9 43.5668
-51 61.4 42.1176
65.5 18.4 72.2414
52.1 38.9 56.88
-68.5 8.9 17.7212
-64.5 24.9 38.809
-10 45.4 28.903
64.55 32.9 61.80425
19.5 43.4 57.4198
66.8 72.4 50.994
64 35.9 60.811
-41.2 22.4 23.506
-55.3 20.4 31.999
-65.5 71.4 39.5528
-61 37.9 44.8226
64.3 37.9 60.378
65.1 37.9 62.215
65.1 37.9 61.782
65.65 37.9 63.3705
21 44.4 57.6262
66.3 37.9 65.251
-58.5 71.9 39.2486
61.5 41.9 63.2332
28 73.9 50.7582
3.55 58.9 61.60975
-44.55 21.4 25.72525
-59 48.4 46.2584
47.95 44.9 52.7125
-47 34.4 34.3978
-3 43.9 28.6966
-50.5 29.9 39.3982
43 44.9 53.2572
66.15 66.4 53.6455
62 40.9 62.9742
-18.5 49.4 28.14
-54.7 24.9 35.883
11.5 70.4 57.3252
63.5 33.4 61.1994
48.5 12.9 42.1326
67.5 15.9 70.8578
-39.5 69.4 45.8862
49.7 43.4 59.857
-12.95 46.9 27.619
-7 56.4 27.9724
-51 64.9 40.7386
-70 38.4 43.3914
-69.5 38.4 43.3712
-66.8 38.4 44.116
-66.65 38.4 44.3765
-62.5 38.4 44.9772
-62 38.4 44.9726
-59.5 38.4 44.982
-58.5 38.4 44.9668
54.85 6.9 47.9575
-57.5 38.4 44.975
-55.7 38.4 44.683
-55.45 38.4 42.84675
-53.5 38.4 42.7652
61 42.4 63.2018
-53 38.4 42.7826
-51.5 33.4 41.0806
33 41.9 48.9982
-50 38.4 42.7338
-43 32.9 33.603
-64 22.9 37.348
10.5 31.9 37.2662
53.4 68.9 49.932
-49.3 38.4 36.001
-44 38.4 35.9788
-43 38.4 35.9446
-36.8 38.4 26.784
-55.45 45.9 44.1115
-36 38.4 26.9512
12.2 70.4 56.958
-12.2 48.4 27.312
8 31.9 35.318
-3 38.4 27.195
38.05 44.4 50.692
-67.5 28.9 41.22
-58 22.4 36.9874
1.55 38.4 30.654
-67.5 12.9 26.1758
47.5 11.9 42.3292
3 38.4 31.5315
3.6 38.4 33.14966667
6.7 38.4 33.187
54 70.4 50.7788
10.65 45.4 59.299
-41.65 48.4 40.454
-51.5 49.9 44.1088
-42.5 35.4 34.7986
51.9 73.4 50.23233333
-53 31.9 40.4088
67.575 6.4 69.398875
-64.5 22.9 37.348
60.8 6.4 57.443
-69.85 75.4 36.535
-64 27.9 40.6844
-60 56.9 45.4776
-56 46.4 46.1534
23 29.9 34.1106
26.475 44.9 56.01658333
51.95 38.4 56.937
52.3 38.4 56.132
64.3 49.9 55.503
-45.5 40.4 36.5712
-56 48.4 46.229
57 38.4 59.612
58.075 38.4 59.533625
-54.5 26.9 37.6306
-51.5 65.4 40.4834
-67.5 16.9 31.6614
-68 40.4 43.4826
69.9 24.9 80.353
63.7 38.4 58.631
63.85 38.4 58.9105
-48.1 16.4 21.272
64.15 38.4 59.77
-41.5 41.9 35.2676
64.8 38.4 61.493
-59 12.9 26.281
65.05 38.4 61.6015
-45.5 67.4 31.9496
65.35 38.4 62.2
66.3 38.4 65.131
-36.5 58.4 27.2862
-17.5 53.4 28.728
34 42.9 50.3132
61.55 7.4 57.62
-55.8 20.9 35.424
-59.5 65.4 42.776
52.925 37.9 52.544875
49 42.9 57.4258
-38.45 33.4 27.7815
50 41.9 60.1806
-51.5 61.4 42.0944
43.5 71.4 47.4302
-59 37.4 44.7216
4.5 58.9 61.9428
68.5 45.4 62.7472
41.35 14.4 38.317
68.1 53.9 59.10733333
65.35 39.4 62.2165
-60 44.4 46.0462
-25 54.4 31.0428
-62.5 74.4 37.4182
69 44.9 62.9102
-66.5 14.9 29.1184
-67.05 38.9 43.72475
-29.5 48.4 31.6508
-67.5 41.9 43.6064
5 59.9 61.7402
-65.5 38.9 45.0978
44.95 11.9 40.25775
-63 13.4 26.9766
-64.5 38.9 45.1004
-50 70.4 37.6988
-62.5 38.9 45.0794
64.8 50.9 56.498
52.7 7.9 46.099
-60.5 38.9 45.0956
48.575 11.4 42.964
-52 48.9 44.1166
-55.45 38.9 42.98625
-58.5 14.4 28.497
-52 11.9 19.2918
-54.5 38.9 42.9116
-52.5 15.4 25.7896
-58.575 9.9 20.515875
66.5 16.4 70.3852
54 46.4 58.2562
-53 38.9 42.8836
66.575 2.9 63.504
-58.5 71.4 39.582
61 45.9 61.6226
-49.5 46.9 43.6616
-28.5 57.4 30.2444
-48 38.9 36.145
-47.5 38.9 36.1104
-61.5 66.9 42.0982
-56.05 15.4 29.7795
-46.5 38.9 36.1118
-58.5 12.4 25.468
-45.5 38.9 36.1296
-41.65 45.9 40.4015
21.575 46.9 56.011375
-43 38.9 36.1004
-3.8 38.9 26.766
52 67.9 51.1782
-2.4 38.9 27.62166667
-1.5 38.9 28.2832
-1.2 38.9 28.504
-51 81.4 27.2882
0.075 38.9 29.441
52.65 39.4 58.315
1.05 38.9 30.157
-66 25.4 39.1286
2.9 38.9 31.47633333
65.925 43.4 63.542125
15 32.9 37.1534
3.5 38.9 31.7486
-60.5 17.9 32.8092
66.15 63.9 54.1455
-23.5 43.9 31.179
-61.5 79.4 32.8596
67.55 34.4 64.3125
3.8 38.9 31.945
22 46.9 56.0548
4.15 38.9 32.6395
-34.85 49.9 29.903
5.5 38.9 33.4458
62 34.4 60.3186
-70 37.9 43.385
-43.5 24.9 27.4348
64.85 73.4 47.234
-50.5 73.9 35.2186
47.35 14.4 40.6115
-11.5 58.9 25.8418
-66 40.4 45.3012
-34.6 58.4 28.237
32.9 45.4 58.04
68.05 9.9 71.66225
62.5 67.4 53.6774
63.1 35.9 53.614
-46 61.9 34.9242
-49.4 67.9 31.71766667
62.425 31.4 61.979
-42.85 20.9 23.4095
51.6 66.4 50.78033333
-61 85.4 24.8024
5.9 60.9 60.911
40 17.9 38.298
-3 47.4 29.0884
66.5 42.4 63.6506
67.3 11.9 69.972
-17 53.9 28.6234
-45 52.4 37.4028
70 14.4 71.0888
-12.5 49.4 27.1832
45.05 49.4 56.768
-42.4 57.4 38.30333333
64.8 69.9 53.265
43.95 49.9 52.7015
44.2 49.9 52.491
71.2 52.9 70.072
-49.35 55.4 36.9755
-55 32.4 40.6622
50.6 38.9 58.4955
41 52.4 52.6886
52.2 38.9 56.517
-62.5 32.9 43.1128
-63 63.9 43.4206
-63 55.4 45.7388
57.8 5.4 50.924
52.4 38.9 56.19833333
64.3 33.4 61.478
-25 52.9 31.3106
52.65 38.9 56.8005
52.85 38.9 57.3665
53 38.9 57.791
62.5 38.9 60.9104
62.95 38.9 60.71875
63.65 38.9 59.0395
64 38.9 59.462
65.05 38.9 61.763
-65 70.4 40.1538
6 67.4 59.3804
33.5 70.9 49.2232
-68.5 85.9 23.7646
-36.4 33.4 23.765
55.1 7.4 48.19033333
-47 28.4 30.6694
64.9 4.9 65.33966667
64.5 37.4 65.258
36 48.9 54.1834
-37.6 54.4 32.23466667
-66 37.9 44.7204
-40.7 59.9 40.037
-69 40.9 43.4362
34 46.9 54.3246
-56.5 38.4 44.9916
39.15 43.9 51.6215
-52 46.9 44.0652
20.5 28.9 34.1692
-51.5 60.9 42.2518
71.1 24.4 91.583
43.5 70.9 48.0684
41.35 18.9 37.1965
-46.5 37.9 35.8136
-41.55 26.4 28.7765
7.5 36.4 35.9178
48 13.4 41.6774
62.65 6.9 62.332
-46.5 36.9 35.4318
70.3 12.9 71.52
-70 39.4 43.3888
-63.5 76.9 35.2774
-41.575 28.4 30.411
-69.5 39.4 43.392
-67.5 85.9 23.7856
-64.5 39.4 45.185
-64 39.4 45.1756
-53.5 48.9 44.1192
-53.5 77.4 32.0342
55 65.9 51.865
-60 39.4 45.1944
-54.5 39.4 43.0438
-53.975 39.4 43.0245
-36.5 49.4 29.1204
64 34.4 62.1272
-15 61.9 24.468
-68 25.4 39.145
-49.65 39.4 42.655
-49.35 39.4 36.325
33 47.9 54.6744
-48.5 39.4 36.2926
-47 39.4 36.282
-46.5 39.4 36.2864
-44 39.4 36.291
-35.65 39.4 27.1335
-48.35 44.4 39.7025
-56 42.4 45.732
-67.5 22.9 37.333
-60.5 12.9 26.26
8.55 65.9 58.2985
-34.95 56.9 28.282
-51.5 16.4 27.446
-52.5 81.4 27.2258
35.6 44.9 55.266
36.2 73.4 47.545
63 68.4 53.4014
-62 38.9 45.0896
-60 19.4 34.3214
-31.5 53.9 31.1558
-66 54.9 45.6976
-46.85 23.9 30.4225
-4.5 45.4 28.791
-51 60.4 42.4242
49.9 39.4 57.301
51.65 39.4 58.343
0.8 38.9 29.974
52.4 39.4 57.76033333
53.125 39.4 59.3685
54 39.4 60.709
-49.65 32.4 40.217
54.5 39.4 60.8266
-49 59.4 35.93
55.5 39.4 61.0648
58.5 39.4 61.3738
63.5 39.4 60.8048
64.5 39.4 61.116
65.05 39.4 61.8595
38.525 49.9 54.01566667
-70 40.9 43.4252
-27 45.9 31.4718
-57.5 27.4 40.4404
-22.5 42.9 30.9988
-69.5 69.9 40.4452
-62.5 48.4 46.2416
-51.5 17.4 28.6016
-52.5 80.4 28.6066
-48.5 19.4 31.2302
-21.5 60.4 29.0306
-47.5 60.9 35.3424
-64.5 45.9 46.1364
11.5 45.9 60.3546
-66 36.9 44.4628
-36.3 83.9 33.189
-53 35.4 41.8242
-35.95 81.4 42.647
65 36.9 65.2884
65.45 4.4 65.344
-39.6 44.9 37.26
-2.5 45.9 28.9886
-8 44.4 28.7756
6.7 37.4 33.707
-46.5 70.9 29.2396
-66.4 39.9 44.95533333
-65.5 39.9 45.2932
-68.5 22.4 36.9014
-60.5 59.9 44.7536
-64.5 39.9 45.3494
66 35.4 65.3294
-58 39.9 45.3124
-64 49.9 46.2292
53.55 74.4 52.908
-57.5 39.9 45.3378
-41 43.9 34.8064
-55.45 39.9 43.19075
-54.5 39.9 43.1346
-53 39.9 43.1142
-52 39.9 43.1302
-49.65 39.9 42.7905
-49 39.9 36.4336
-35.2 55.9 27.807
-44.5 39.9 36.4086
35.5 19.9 36.7288
-52.475 39.4 43.02308333
-43.5 39.9 36.4012
17.5 28.9 35.7522
-65.5 20.4 35.2294
-43 39.9 36.4034
-55 26.4 37.3284
68.525 14.4 70.51608333
-36.5 39.9 27.6718
-3.3 66.4 27.918
66.8 13.4 68.36
67.2 8.9 70.697
-63.5 38.4 44.936
-41.45 79.9 31.379
-46.5 29.4 31.4112
-47.5 32.9 33.5886
-8 59.4 26.622
64.3 47.9 56.298
41 71.4 48.2584
-47.7 44.4 37.804
-47.45 44.4 37.32125
-55.8 84.4 25.871
70 12.9 71.5066
52.1 39.9 57.373
-13.45 44.4 28.16975
52.3 39.9 57.862
54 39.9 61.1206
9.05 32.4 36.606
55.5 39.9 61.477
56.5 39.9 61.6282
66 26.9 69.572
57.5 39.9 61.7258
-42 44.4 35.797
58 39.9 61.7698
58.5 39.9 61.8234
65 39.9 61.9136
39.15 48.4 53.369
-55.8 71.9 38.906
55.5 41.9 62.2096
2.5 38.4 31.4042
-10.5 56.9 27.7794
-60 37.9 44.8254
-66 86.9 21.7988
-55.925 45.4 46.028625
20.425 72.4 53.008125
-45 73.4 26.7386
-36.5 55.9 28.1624
-31.15 85.4 40.1795
-50 14.4 24.213
-10 43.9 28.6858
-70 13.9 27.6826
-63.5 24.9 38.804
-11.5 54.4 28.5306
-68.5 21.9 36.506
-70 50.9 43.6906
-70 23.4 37.7302
-51.5 49.4 44.0986
-20.2 61.4 26.621
57 66.4 52.485
-40.2 54.4 32.505
-19.95 61.4 26.249
55 9.4 46.463
-55 28.4 38.608
-9 51.9 29.0458
53.2 45.4 58.244
-56.075 87.4 20.438875
43.55 42.4 54.62975
-61 16.9 31.6852
-55.7 25.9 38.675
5.55 51.9 61.62025
-1 55.9 28.1136
64.55 3.9 63.7475
-32.5 54.4 31.0924
65.575 7.4 68.00375
-67.575 40.4 43.5315
-66.6 40.4 44.824
-7 55.4 28.244
-63 40.4 45.4008
-59 40.4 45.4236
35 71.4 49.3254
-52.5 62.9 41.5692
-52 21.9 33.2892
-58 40.4 45.4322
-30 57.9 30.0586
-54 40.4 43.2396
-16.2 56.9 23.976
56 38.4 59.6
-44 40.4 36.5366
-52.5 38.9 42.9226
-43.5 40.4 36.5524
39.2 42.4 50.19
-13.35 47.9 22.705
-36 64.4 23.6294
-36.5 40.4 27.7098
-35.6 40.4 27.62466667
5 62.9 61.2102
-28.5 55.9 30.7234
-30 54.4 31.086
-55.8 27.9 40.512
-51 48.9 44.127
-55.45 75.9 33.48525
-47.5 30.9 32.4684
54.1 73.4 51.37466667
-58.5 68.4 41.339
-36 49.4 29.139
-68.5 75.4 36.5826
-55 40.9 43.378
-50 63.9 41.1264
68.45 49.4 60.615
50.5 40.4 59.912
-38.2 33.9 28.14
51 40.4 59.9648
28.425 25.9 35.021125
-42.7 51.9 34.02
12.5 44.9 59.6738
-13 45.9 28.1132
-8.5 58.4 27.0826
53 40.4 58.6692
-50 64.9 40.6986
-5 50.4 29.0976
54.475 40.4 61.52558333
59.5 40.4 62.3354
61 40.4 62.5582
13.5 30.4 36.6966
32.7 73.9 48.981
-2.15 48.9 29.011
61.5 40.4 62.6414
-58 51.4 46.1714
62.5 66.9 53.5994
-5.15 60.4 26.185
-19.45 58.9 26.795
0.3 50.4 32.761
50.5 49.4 63.428
49.6 50.9 64.652
65.35 40.4 62.4685
-11.35 61.4 25.109
31.55 22.4 35.614
56 41.4 62.224
-62 62.4 43.963
66.1 7.9 68.349
59 42.4 62.807
18.5 45.9 58.2408
51.65 44.9 58.1635
-14.7 46.9 18.703
56 38.9 60.5564
-51.5 59.9 42.6002
65.5 41.9 63.3422
33.5 72.4 49.587
46.075 11.9 41.4415
6 59.9 60.84866667
8 44.9 28.2334
39.05 45.4 51.125
-52.5 83.4 24.0812
52.8 46.9 57.17
44.5 52.4 53.6144
-61 20.9 35.7002
-64 26.9 40.0876
-2.5 44.4 28.739
-39.3 45.9 36.476
25.5 25.9 34.4178
59.075 50.9 54.032125
-58 41.4 45.614
23 43.9 56.7336
-65.5 49.4 46.1908
70.4 50.4 62.267
-68.5 33.9 43.2136
-34.025 81.9 42.49775
-6 53.9 28.6302
-10.5 54.9 28.4068
-44.95 79.9 22.387
-65 27.9 40.6866
-60 45.9 46.1546
-52 73.4 35.6336
-47 55.9 36.8614
4 43.4 28.3014
-52.5 35.9 42.0214
31.45 21.4 34.78225
66.5 18.9 72.4836
-68 40.9 43.4902
62.05 62.4 44.29775
-55 72.9 36.0162
38 42.9 50.169
-61.5 40.9 45.4782
-58.5 40.9 45.524
-58 40.9 45.5356
69.5 11.9 71.7206
63 44.4 62.621
-56.925 40.9 45.515375
60.8 4.9 57.975
-20.9 48.9 28.289
-49 31.9 33.0844
-3 56.9 27.8086
-59 24.9 38.844
-12.925 48.9 26.396375
-52 40.9 43.365
-35 59.9 26.2346
66.05 38.4 64.3585
-51.5 40.9 43.3366
-49.5 40.9 42.428
-47 36.4 35.2546
-39.5 64.9 45.8032
72 51.4 79.226
-64.5 54.9 45.8112
-62 36.9 44.5708
-52.5 41.4 43.4238
-40.9 49.9 31.351
-64 76.4 35.7458
25.4 71.9 50.78233333
-48 52.4 37.42
63 54.9 58.1948
66.5 54.4 58.7476
-57.5 21.4 36.173
-67.5 54.9 43.851
-66.425 35.4 43.97675
65.85 38.4 63.7415
59.5 65.4 52.3884
45.8 40.9 54.479
-50.5 29.4 39.1416
45.95 40.9 54.664
29.5 42.9 51.814
-43.1 59.4 36.46733333
-55.075 55.9 43.516375
49.375 40.9 57.04925
51.2 40.9 59.772
32.5 44.9 56.047
40.65 42.9 52.63
-55.7 80.9 29.728
-47.5 29.4 31.4506
-12 57.4 26.625
64.8 49.4 56.958
64.9 63.4 50.618
-12.95 44.4 28.658
58.5 43.4 62.606
-7 55.9 28.1152
53 40.9 59.1624
-40.15 32.4 30.6765
31.4 43.4 53.54333333
-36.5 44.4 28.7602
-44 43.4 37.1864
26.5 73.4 51.2436
-33 57.4 30.286
27.5 45.9 55.8308
68.55 7.9 71.69525
42 45.4 52.1476
-31.475 59.9 28.03075
70.6 53.9 63.31
68.525 13.9 70.51608333
49 49.4 63.7064
52.65 56.4 53.9295
-47.5 28.4 30.6872
-1.5 38.4 28.3354
53.7 66.9 51.757
-49.7 22.4 33.864
69.3 23.9 82.177
-57.5 60.4 44.6214
63.3 5.4 63.404
62.4 42.4 63.19633333
-69 41.4 43.4388
54.8 71.9 47.112
-45.5 70.4 29.651
-38.5 48.9 33.0674
-52.5 46.9 44.0722
-54.5 41.4 43.425
-62.5 47.4 46.2378
-52 41.4 43.4206
-62 9.9 20.451
-58.5 66.9 42.1142
-51 41.4 43.4538
-48.5 41.4 36.8028
51.7 70.9 43.604
-35 59.4 26.2598
-12.5 41.4 27.3768
6.95 41.4 23.8395
-66 25.9 39.4606
-49.6 60.9 41.824
-63 42.4 45.769
7.5 41.4 24.963
-13.5 50.4 27.2154
8 41.4 26.257
64.975 54.9 58.54041667
-12.05 58.9 25.2725
-60 42.4 45.7908
63.625 6.9 64.4035
-59 42.9 45.8304
9.55 71.4 57.11775
38.5 69.9 48.7074
69.8 49.9 63.843
-70 37.4 43.3634
-31 60.4 28.1314
-35.05 48.4 29.1975
61.85 5.4 60.685
-60.5 70.9 39.872
50.5 51.9 52.6364
39.45 41.4 48.32575
40.15 41.4 49.2025
-61 66.9 42.1214
40.35 41.4 49.4525
19 30.4 36.4266
-70 22.9 37.3496
-60.5 40.9 45.4802
-20.9 58.4 29.359
-12.5 58.4 24.30566667
-42.025 24.4 26.96075
41.9 41.4 51.39
42.15 41.4 51.7025
-64.5 73.9 37.8012
44.1 41.4 54.23733333
46.525 41.4 55.98058333
4 58.9 61.8154
49.35 41.4 57.4355
-55 70.4 37.7304
-1 55.4 28.2796
49.7 41.4 59.95
50 41.4 60.1216
51.95 72.9 50.13825
58.5 41.4 62.583
60 41.4 62.8422
68.1 5.9 69.18233333
3.8 62.4 61.004
72.1 51.4 80.503
-44.075 42.4 37.019625
61.5 41.4 63.1148
62 41.4 63.1706
62.65 41.4 62.86
-62.5 9.9 20.4424
-56.5 67.9 41.6322
64.575 41.4 63.010375
65 41.4 63.0824
-63.5 21.9 36.5262
-41.2 46.4 40.948
-5 49.9 29.1214
-68.5 12.4 25.396
-22.5 53.9 31.1638
-63.5 15.4 29.8334
7 52.9 61.7438
-55.7 12.4 24.09
7.05 68.9 58.61575
17 43.4 57.8796
-3.85 46.9 28.9035
-69.5 41.9 43.4784
-63.5 25.9 39.466
12 47.9 60.3608
-67.975 41.9 43.50641667
69.6 49.4 67.829
36.4 41.9 47.24833333
70.4 51.4 60.648
-67.525 39.9 43.52266667
41.3 44.4 52.99
-49.35 59.4 36.007
-63 41.9 45.707
26 73.9 51.3994
-37.4 31.9 25.21433333
-54 41.9 43.5364
-65.5 55.4 45.6932
-53.49166667 41.9 43.51908333
48.525 10.4 42.97058333
-52 41.9 43.5432
48.4 49.9 62.948
2.5 48.9 28.1076
-49.35 41.9 36.9795
-47 40.4 36.5678
64.15 8.9 64.7915
15.5 44.4 59.243
-65 48.9 46.225
-13 51.4 28.1082
-57 49.4 46.2526
7.6 41.4 25.471
55.55 46.4 59.14025
-44 41.9 36.899
-40 41.9 33.8184
67.5 60.4 56.216
-37.2 41.9 28.653
49.6 70.4 43.68366667
69.5 10.4 72.2868
-36.95 41.9 28.17175
0.5 50.4 33.827
-21.4 41.9 30.287
-20.1 41.9 28.3905
20 46.4 57.2954
-14.5 45.4 28.0932
68.3 23.9 76.733
-15.5 54.9 28.3822
68.95 4.9 68.68125
-39.6 47.9 42.59233333
-66.2 42.4 45.509
18.15 29.4 35.75
-59.5 47.9 46.244
6.15 41.9 26.234
11.95 70.9 57.10325
-35.55 62.9 24.4315
52.5 69.4 50.8536
7.55 41.9 25.441
7.8 41.9 25.442
-55.7 22.9 36.647
-48.5 63.4 34.2574
-36.2 83.4 35.007
-51 74.4 34.8078
-34.3 57.4 29.65
34.5 41.9 48.9104
-52 47.4 44.1096
64.85 46.9 58.264
36 41.9 47.2424
49.9 9.9 43.90766667
-51 70.4 37.7192
38.55 41.9 47.4685
-68 12.4 25.3978
39.8 41.9 49.436
-1.5 52.4 28.9606
-44 36.9 35.433
41.575 41.9 52.393875
39.05 48.9 53.6175
-62.5 85.4 24.7776
-69 64.4 43.1814
44.475 41.9 55.0015
-56.5 13.4 27.0476
39.1 20.4 36.45733333
46.075 41.9 56.0065
49.35 44.4 58.239
46.975 41.9 56.50583333
49.5 41.9 57.87466667
-49.65 31.9 39.99
-54.5 84.4 22.257
50.5 41.9 60.3454
51.2 41.9 59.376
-23.5 52.9 31.3268
52.925 41.9 59.5235
-47.5 43.9 37.2038
-54.975 14.4 26.03375
68.5 24.9 70.662
59 41.9 62.7822
62 41.9 63.2968
52.65 51.9 55.6415
64.5 41.9 63.1756
35 71.9 49.3254
32 42.9 50.513
53.4 70.4 49.52533333
-58 60.4 44.611
67 41.9 63.6932
71 41.9 64.0938
19 29.9 36.3666
-22 53.9 31.1744
-6 51.9 29.0594
44.45 49.4 55.2925
72.575 41.9 64.027875
68 45.9 62.6116
-62.5 45.4 46.1172
-58.5 65.4 42.7996
-63 24.4 38.4648
-42.55 78.9 29.6455
-26 43.9 31.1528
66 2.4 62.2802
-42.35 83.4 16.8575
66 25.9 69.7418
13 30.9 36.9698
-39.95 54.4 32.602
-65 29.9 41.7374
-69 42.4 43.503
34.7 42.9 51.943
-67.15 42.4 43.704
30 47.4 55.0688
-59.5 43.9 45.9864
-65 42.4 45.7536
-64.5 42.4 45.7656
36.5 41.4 47.1624
-20.9 54.4 27.744
33.5 49.4 53.741
-43.05 78.4 28.7065
-62.5 42.4 45.7688
-38.5 57.9 32.1758
-62 42.4 45.7662
3.3 57.9 61.433
14.5 33.9 36.9812
-44.5 43.4 37.178
5 52.9 61.6776
-59 42.4 45.7816
28.5 74.9 49.7064
-55.7 42.4 45.488
-1.6 65.9 35.176
-62.5 44.4 46.04
68 50.9 60.159
-53 42.4 43.6242
-70 32.9 43.0598
-51.5 42.4 43.612
-55.7 37.4 44.387
-21 47.4 30.249
-43.575 42.4 37.01875
61.8 4.9 60.508
-56.5 63.9 43.4284
65.5 26.9 69.3766
-56 76.9 35.2478
24.5 74.9 51.392
42.55 13.9 39.29325
23.5 46.9 55.9516
-39.025 42.4 32.97608333
-19.4 51.9 28.942
66.925 5.4 68.04975
11.5 34.4 37.348
1 47.9 28.6578
-67.5 57.4 43.8816
61.5 56.9 58.1182
-19.5 42.4 29.745
-15.5 42.4 28.3104
-14.4 42.4 27.856
64.7 19.9 70.441
-5 66.4 21.8552
-13.5 42.4 28.2806
-64.5 41.9 45.6994
-37.4 64.9 24.538
-51 84.9 21.269
-37.65 65.4 29.3435
16 71.4 54.7742
-41.15 57.4 40.3975
-7 42.4 28.1106
-6 42.4 28.0918
68.925 10.4 72.017375
57.1 7.9 49.10966667
41.5 16.4 39.0794
6.1 42.4 26.187
7.4 42.4 28.15033333
50.075 44.4 59.483
-68 36.4 43.3598
69.05 12.9 71.19375
-64 55.4 45.7434
31 42.4 50.4636
32.075 42.4 50.506
62 33.4 61.2068
-62.5 41.4 45.5964
70 50.4 60.759
66.1 32.4 62.86133333
-70 28.9 41.224
38.025 17.4 37.48108333
32.5 42.4 50.5242
63 69.9 52.9216
3.6 50.9 51.487
35.5 47.9 54.2358
-1.5 47.4 29.104
66.65 47.4 60.6315
-33.5 52.9 31.3294
33.45 73.9 48.60125
31.7 21.4 35.111
28.575 24.4 34.98725
23 74.4 52.2606
-70 40.4 43.4068
41.9 42.4 53.39533333
6.05 50.4 61.17225
45.025 42.4 55.52283333
-49.15 16.4 25.308
49.65 42.4 59.9525
58 42.4 62.6018
-12.5 53.9 28.6158
60.5 42.4 63.1096
-9 44.9 28.8632
-58.5 31.4 42.4848
65.05 60.4 53.296
64.5 42.4 63.256
-55.7 43.4 45.584
36.7 41.9 48.685
72.1 54.4 82.472
-29 49.4 31.6362
24.975 44.9 56.50016667
65.925 42.4 63.532125
58.5 54.4 57.7988
-16.7 44.9 28.612
69 42.4 63.8298
53.8 6.9 46.91
-65.5 10.4 21.5854
53 72.4 46.9488
69.975 42.4 64.02408333
-66 54.4 45.746
-55.1 12.9 23.607
-40.3 68.9 45.906
2.5 46.4 28.265
54 56.9 56.2432
-7.5 55.4 28.2464
51.65 68.9 50.8525
-70 42.9 43.4928
8.3 44.9 28.483
-43.1 45.9 38.362
35.55 19.4 36.642
-48 71.4 28.7864
-47 60.4 35.525
-61.5 42.9 45.8278
-61 42.9 45.8448
7.6 47.9 60.224
-60.5 42.9 45.8644
-4.5 57.4 27.6504
70 52.9 60.0736
-60 42.9 45.8514
3 56.4 60.887
59.5 56.4 57.8572
-59.5 42.9 45.8488
-59 26.4 39.8284
60.5 32.9 61.5588
-21.2 46.4 31.518
-55.7 18.9 33.139
-41.2 83.4 20.248
66.075 51.4 58.435
-57.5 42.9 45.854
7.2 41.4 24.104
-63 45.9 46.141
62 31.9 62.1698
66.4 32.9 63.241
47.1 42.4 56.61266667
-30.5 44.9 30.73
3.5 43.4 28.3146
47.025 12.4 42.01333333
-52.5 42.9 43.6992
-64 23.9 38.1138
-15 44.9 28.1448
-62.5 75.9 36.215
-54.5 43.9 43.8496
-45 42.9 37.028
17.5 44.9 58.834
58 54.9 57.7424
-56 37.4 44.701
-35.45 83.4 37.2465
21.5 74.9 52.4386
-41.075 42.9 34.96825
-46.8 72.4 27.808
-44 41.4 36.7814
-45.5 27.9 30.3088
-40.45 42.9 34.37675
-68.5 86.4 22.7806
-40.15 42.9 34.104
-49.6 77.9 30.987
63.5 29.4 62.818
-38.6 42.9 32.61566667
-60.5 55.4 45.7308
-23.575 42.9 31.017875
-59 84.9 25.6318
-49.35 32.9 33.6205
68.5 42.4 63.6492
-20.05 42.9 30.105
-34.4 46.4 29.10866667
-49.3 53.9 37.254
-41.5 59.4 42.2608
53.55 41.4 61.60875
-34.65 79.9 43.1125
66.5 47.9 60.286
-18.5 42.9 29.2752
-46.65 75.9 32.7575
-18.025 42.9 29.02116667
-38.85 31.9 27.913
-55 20.4 31.9462
-17.025 42.9 28.50508333
-38.85 46.4 32.8785
-12 42.9 28.328
-11 42.9 28.3318
-50 79.4 29.7774
-9 42.9 28.4272
-7 42.9 28.3598
10.5 68.9 57.8182
65.9 66.4 53.45233333
35 20.4 36.6078
-37.65 58.4 31.723
-36.5 53.4 28.7192
33.35 42.9 52.099
-59.5 40.9 45.5126
33.55 42.9 51.958
2.7 57.9 60.703
4.85 66.4 59.4265
-43 31.9 33.0006
8.8 51.9 60.551
32.5 71.9 49.7442
69 8.4 72.1142
-55.4 73.4 35.59766667
37.4 42.9 50.912
61 57.9 57.3588
39.15 42.9 51.13
-12 45.9 28.0952
-36 44.4 28.7332
-62 34.9 43.8738
-65 51.4 46.1502
-46 34.4 34.3726
44.075 42.9 54.9635
45.025 42.9 55.52283333
38 46.4 49.9276
-43.7 47.9 38
-3.8 38.4 26.766
49.4 42.9 57.64466667
-9 53.4 28.708
49.65 42.9 59.935
-49.6 80.9 27.014
-56 59.4 44.8584
50 42.9 60.1488
-0.35 66.4 39.7925
-2 54.4 28.504
-54.925 37.4 42.51775
-70 13.4 26.9596
50.5 42.9 60.309
-2.5 47.9 29.0508
52 42.9 59.18
64.2 23.9 67.737
62.35 7.4 61.3065
55 38.9 60.3508
-19.7 56.9 26.866
-45.5 48.4 37.6198
68 51.9 59.783
-45.95 77.4 20.599
-57 50.4 46.221
-49.7 61.9 41.693
56.5 42.9 62.367
59.5 42.9 62.8892
-12 52.9 28.7964
60.075 42.9 63.009
-57.5 27.9 40.7414
-28 56.4 30.5712
56.5 41.9 62.3836
71 42.9 63.8288
-64.5 22.4 36.943
-40.65 58.9 42.3645
71.1 23.9 91.717
-60.5 19.9 34.8008
-56 45.9 46.139
-55.8 12.9 25.832
-54.55 21.4 33.13125
72.9 52.9 91.016
-40.5 51.9 31.80066667
43.075 41.9 53.96775
-40 67.4 46.013
-66.5 70.4 40.154
67 61.9 55.3312
-66.5 10.9 22.6176
-46.5 72.4 27.7946
-67.5 27.4 40.3814
55.5 54.4 57.2624
61 34.4 60.2904
-68 76.9 35.2548
-58.5 16.4 31.169
-70 43.4 43.5112
-33 55.4 30.804
-66.45 43.4 45.465
-37.7 44.4 31.669
65.9 70.4 53.17833333
-63.5 43.4 45.8754
-41.2 56.4 40.85
-68.5 42.4 43.5066
-56 43.4 45.8542
-54 30.9 39.9104
-52 43.4 43.7666
-11.2 46.4 28.398
50.4 50.4 63.671
52.7 45.4 54.814
-41.575 43.4 35.412
-39.85 31.9 29.7805
-41.075 43.4 34.932375
-61 45.9 46.1508
-27 53.4 31.2436
-37.6 42.4 30.787
-15 44.4 28.6438
-37.7 43.4 31.456
-66.5 79.9 32.2596
-58 29.9 41.7864
-55.975 44.9 46.02275
-24.5 43.4 31.122
-16.525 43.4 28.52375
11.7 68.9 56.42
-11.5 43.4 28.3818
-48.9 16.4 24.353
4 57.9 61.856
39.4 41.9 48.746
-1.5 43.4 28.4634
-59 68.9 41.0648
40 50.9 53.9254
-56.05 41.9 45.65375
-40.4 60.4 45.467
-0.475 43.4 28.50491667
-43.05 40.4 36.56075
0 43.4 28.411
7 43.4 28.4726
-63.5 10.9 22.6732
7.025 42.9 28.52075
34.2 69.9 47.343
-67.15 42.9 43.7175
64.6 61.4 51.125
-45 55.4 36.9248
8.5 43.4 28.475
-34.35 55.4 30.3825
17.5 43.4 57.8352
21 43.4 56.9314
64.5 61.4 49.583
-5.5 55.9 28.096
-10.5 44.4 28.768
36 71.9 49.1664
66.35 48.4 59.8895
55.5 42.9 62.1826
29 43.4 53.32
-60.5 22.9 37.3584
30.5 43.4 53.3794
-64.5 25.9 39.4588
31.075 43.4 53.488
-55.45 74.9 34.38825
70.5 42.9 63.856
-50.5 46.4 44.0304
-37.65 53.9 32.3465
38.15 43.4 50.681
51 70.9 42.3802
-64 68.4 41.3162
40 43.4 52.2568
60.5 55.4 58.2428
-62 82.9 28.6412
-9.5 45.9 28.9726
48.525 43.4 57.005
51.2 43.4 59.832
61.5 43.9 63.1334
52.95 43.4 59.211
4 56.9 61.8594
53.2 43.4 59.636
55 43.4 61.9338
64.925 55.4 58.54625
-56.5 65.9 42.6052
-11.975 59.4 25.431
-51 42.4 43.6224
-54.5 17.4 28.4372
56 43.4 62.177
-69.85 8.9 17.05
57 43.4 62.3324
37 20.4 37.0204
14.2 71.4 55.969
66.15 13.9 67.1075
59 43.4 62.7192
7.4 47.9 59.809
56 63.9 50.2084
60.425 43.4 63.028875
62.35 43.4 63.1835
64.5 43.4 63.2354
65.5 43.4 63.4236
-46.7 79.9 17.918
66.5 43.4 63.6626
-32.2 45.4 30.948
69 43.4 63.4456
-36.95 83.4 32.8085
-38.5 47.4 32.9472
-49.925 29.4 39.054125
-58 27.4 40.4442
-37.9 46.9 32.35266667
-60.5 54.9 45.8042
-23.5 43.4 31.0352
38 49.4 53.977
-15.5 62.4 24.4704
-54.5 55.9 43.4794
50.5 42.4 60.346
1.5 50.4 39.244
-57 21.9 36.5766
52.15 64.9 50.044
55.45 5.9 48.2965
-65 26.4 39.8048
-63 30.4 41.9934
58 53.4 57.4206
-61 43.9 45.9572
21.5 46.4 56.8756
-26.5 43.9 31.1812
34 43.4 53.3774
6.5 33.4 36.4732
-63 76.4 35.7704
-60.5 43.9 45.9438
-56.5 54.9 45.8422
-55.45 43.9 43.902
-51.5 43.9 43.8228
-20.1 60.4 27.27366667
41.45 48.4 52.72275
4 45.4 27.989
-49.35 43.9 40.4135
-48.95 43.9 39.38
-69.5 63.4 43.5748
-43.5 43.9 37.2456
-50 18.4 29.959
-66.6 56.4 44.994
-42.575 43.9 36.4105
-39.4 31.4 28.769
66.5 63.9 54.4632
-64 41.9 45.7018
-42.5 25.4 28.0694
-40.5 43.9 34.3038
68.575 9.9 71.935875
9.35 66.9 57.7795
43 43.4 54.3346
-39 43.9 32.7932
-37.975 43.9 32.41641667
61.5 53.9 58.2482
-22 43.9 31.1992
31.65 43.4 52.2025
48.025 42.4 57.02983333
-15.5 43.9 28.6438
-57 42.9 45.8666
52.85 66.9 51.2195
46.1 69.9 48.35433333
-14 43.9 28.6582
-11 52.4 28.9246
-67.5 40.9 43.5812
62.65 41.9 62.9495
-68 34.4 43.2568
-8.5 43.9 28.6718
6 63.4 60.7804
-4.4 48.4 28.93166667
-37.8 61.9 30.329
-63.5 49.4 46.2272
-3.5 43.9 28.6224
-31 56.4 30.5848
-61 25.4 39.1814
1 43.9 28.4514
-70 61.9 43.8336
-44.65 78.9 24.342
-28.5 57.9 30.0848
-49.5 42.4 42.791
4.075 43.9 28.035
-27.5 46.4 31.524
6 43.9 28.1822
6.5 43.9 28.219
8 43.9 29.047
8.1 43.9 29.542
-51 71.9 36.7404
8.55 44.9 28.72825
-16.9 57.4 23.958
68.3 63.4 55.397
-70 67.9 41.5502
29 25.4 35.151
45.05 49.9 53.7545
-51.5 44.9 43.9402
-49.35 38.9 36.2055
-58 62.4 43.997
53.4 68.4 50.12133333
67 43.9 63.6852
-49.35 37.4 35.6785
-53 33.9 41.2414
-9.5 52.4 28.9584
-61 12.9 26.2532
33 43.9 55.1192
-22.5 46.9 31.548
34 43.9 54.9554
-65 10.9 22.6236
-17.35 45.4 28.182
35.85 43.9 54.741
61.5 44.4 62.912
-55.45 84.9 20.354
36.8 43.9 49.508
38.1 43.9 50.67466667
39.575 43.9 51.927375
3.491666667 46.4 28.0265
59.075 40.9 62.5045
21.35 48.4 57.558
64.05 3.9 63.20175
-69.5 87.4 20.5442
-41.85 44.9 40.151
63.8 29.4 63.997
-13.5 62.4 24.4606
47.075 43.9 55.984375
50 43.9 59.7626
55 43.9 61.7434
56.425 43.9 62.04525
58.575 43.9 62.492375
3.5 65.9 56.342
-4.7 66.4 22.255
63.975 43.9 63.01566667
-67 33.4 43.3104
53.8 7.4 47.004
-47.85 44.4 38.2415
-45.5 74.4 25.5942
-19.35 57.9 27.3185
66.425 43.9 63.5215
55 45.4 60.6472
72.85 43.9 63.242
52.65 55.9 54.4235
-25.5 55.9 30.6812
67 64.9 55.43
-69.5 23.4 37.7366
-53.5 45.4 43.9802
-53.5 78.4 30.9678
-39.075 44.4 32.9135
19 45.4 58.2996
-45.05 61.9 34.89
-38.35 33.9 28.392
-2 56.9 27.8026
-62.475 30.4 42.01775
23.5 44.9 56.9128
33.45 45.4 53.661
-61.5 47.9 46.2224
-60 82.4 29.3246
-66.45 45.9 45.704
-56.025 40.9 45.48433333
43.35 53.4 52.46
-2.5 59.9 26.4224
70.6 53.4 63.469
-41.15 59.4 42.4275
-56 57.4 45.3284
-61.5 48.4 46.2412
-35.5 61.4 25.28633333
-56.025 44.4 45.98525
-64 23.4 37.7486
7 33.4 36.4798
-46 58.9 35.976
-54.5 44.4 43.8856
-45 44.4 37.3324
-65.5 83.9 27.094
-37.8 44.4 32.237
-65.975 45.9 46.01116667
-35.65 44.4 28.623
-26 44.4 31.2526
3.15 62.9 60.1555
-25.5 44.4 31.2892
50.6 49.9 63.29
-11 44.9 28.8406
-24 44.4 31.2856
-6 49.9 29.0848
-19.1 44.4 29.659
-16.4 57.9 20.25633333
-17 44.4 28.681
-5 57.4 27.6676
15.5 33.9 36.735
37.65 42.4 49.1835
69.075 7.9 71.98625
65 63.4 51.399
-13.2 44.4 28.498
-51.5 73.9 35.2484
-55.8 25.4 38.885
64.3 7.9 65.86
-9 44.4 28.7422
51.5 71.9 45.5854
-5.5 44.4 28.7838
-5 44.4 28.7288
-2 44.4 28.702
-56.5 32.9 43.146
-44.65 69.9 30.0085
3.5 44.4 28.1278
4 44.4 28.0518
-12 46.4 28.096
24.5 71.9 50.8862
5 44.4 28.118
57.95 74.9 53.13375
5.5 44.4 28.1786
9.1 44.4 28.75233333
-20.5 53.4 26.1522
40 68.9 48.2272
69.7 24.4 79.746
19 44.4 58.292
61 51.9 56.7028
52.025 53.4 56.02433333
60.1 32.4 61.919
64.525 1.9 60.50358333
24.475 44.4 56.50233333
24.5 73.9 51.8942
-39.15 42.9 33.145
67.05 6.4 68.766
-69 74.4 37.368
33.35 44.4 56.665
35 44.4 56.392
35.7 44.4 54.783
-57 78.4 33.9046
-17.1 57.4 25.656
-35.65 79.4 43.5985
49.65 44.9 58.2745
35.85 44.4 54.754
39 44.9 51.373
40 44.4 52.2094
63.35 60.9 48.789
45.5 44.4 54.7902
-45.5 29.9 31.7508
-67 9.9 20.3914
-69.5 52.9 43.7148
47.975 44.4 55.50125
38.05 46.9 49.9255
-39 55.4 32.6734
48.65 44.4 56.375
48.95 44.4 57.173
58.5 53.9 57.6844
2 49.4 28.3332
0 48.4 28.9254
48.9 9.4 42.787
-20.1 57.4 25.783
-57.5 66.9 42.1288
-7 47.4 29.1152
64 68.9 53.4666
49.55 44.4 58.772
49.7 44.4 59.172
-67 64.9 42.9974
50.5 44.4 59.6286
-55.45 34.9 41.86875
8.35 47.4 60.3845
58.925 45.9 61.0495
51.45 44.4 58.77
68.3 35.9 66.583
56 58.4 55.4242
-34.7 55.4 29.353
66.6 7.9 69.29866667
-56.5 42.9 45.872
-38.9 52.4 32.993
-39.5 67.4 45.934
-44.5 44.9 37.3844
62 44.4 62.9768
66.5 44.4 63.368
67.025 44.4 63.52591667
1.15 66.4 45.8145
68.5 44.4 62.955
-38.15 47.4 32.7155
-64.5 45.4 46.122
70.5 44.4 63.2914
-48 42.4 37.0254
67.6 12.4 70.23866667
63 55.9 58.1834
-7.5 43.9 28.6828
-18.8 59.9 25.518
33.5 48.4 54.4474
-30.1 86.4 39.11233333
-64.5 46.9 46.1986
-62.5 60.4 44.6116
-43.5 60.4 36.1742
56.5 68.4 52.6
57 35.9 52.7106
-63.5 28.4 40.9764
-68 44.9 43.58
-67.2 44.9 43.74
-66.4 44.9 45.68733333
-56 64.9 42.9314
51 69.4 48.4296
-45 42.4 36.9828
-62.5 44.9 46.0834
44.925 15.9 39.472625
54.5 43.9 61.5954
-58.5 44.9 46.112
62.9 3.9 61.775
-38.475 70.4 45.52433333
70.8 49.4 76.314
-52 44.9 43.9494
-55.45 19.9 31.9635
-38.05 43.4 32.4075
-41.6 49.4 35.11766667
-58 61.4 44.3258
-50 44.9 43.8844
67.05 9.9 70.2955
-57.5 46.9 46.237
-48.15 44.9 39.6645
-4.7 65.9 22.268
-39.7 44.9 38.01
-56.05 20.9 35.60725
-47 29.4 31.4394
-55 54.4 43.821
-10.65 48.9 28.229
68.1 61.9 56.10533333
72.1 53.9 82.2
-36.5 44.9 28.8378
64 40.9 62.663
-66.45 46.4 45.72925
-35 44.9 28.618
-53 82.4 25.7756
7.85 65.4 59.2235
-31.5 44.9 30.261
-28.5 44.9 31.3764
-46 46.9 37.5712
-23.5 44.9 31.3312
6.025 64.9 60.5125
64.1 63.4 44.376
-22.5 44.9 31.3834
-5.5 46.9 29.0752
9.5 49.4 61.2374
13.5 72.4 56.1888
-22 44.9 31.3374
32.3 44.9 53.848
53.8 38.9 59.985
-62 48.9 46.2412
-51 32.4 40.6568
56 42.9 62.283
32.5 24.9 34.6598
50.575 67.9 49.010625
-38.5 56.9 32.4108
-19.3 44.9 28.943
-43 31.4 32.755
-42.925 47.9 38.912875
-17 44.9 28.6974
67.875 33.9 64.516
-18 50.4 28.115
68.5 51.4 60.2236
58 65.9 52.4364
-7 44.9 28.85
-14 42.4 27.7116
4.3 44.9 28.008
-58.5 33.4 43.3562
5.5 44.9 28.1822
11 32.9 37.4866
-40.425 59.4 43.39
67.35 9.4 70.932
7 44.9 28.178
12.025 44.9 59.4885
0.8 66.4 44.406
-68.5 78.9 33.266
15 44.9 59.6048
-49.65 51.9 43.6925
27 44.9 55.8386
37 72.9 48.1746
27.5 44.9 55.6524
29.5 44.9 54.7562
-34 49.9 31.5934
-67.5 78.4 33.8126
-60.5 34.4 43.7324
51.5 55.4 55.9104
31.35 44.9 54.4855
-54 57.4 43.2042
31.5 44.9 53.576
66.075 65.4 53.939875
-20.5 52.9 26.2248
31.65 44.9 51.8755
10 48.9 61.1038
-37.6 44.9 31.326
-14.5 44.4 28.663
-43 34.9 34.5878
65.5 44.9 62.8094
60.9 6.9 56.637
-57.5 87.4 20.7418
-50.5 30.9 39.9098
-65.5 66.9 42.085
-43 22.9 25.2132
-45 74.4 25.598
34.575 44.9 56.414125
70 41.9 64.0988
-48.55 50.9 41.7025
35.05 44.9 57.8905
47.5 42.4 56.7834
-63 36.9 44.51975
-50.5 28.4 38.6018
49.6 43.4 57.723
40.425 19.4 37.00975
36.1 44.9 48.93366667
-51 40.9 43.3372
65 40.9 62.8002
-42 29.4 31.4032
44.9 70.4 49.573
44.5 44.9 53.576
-57.5 17.9 32.8382
46.45 44.9 47.831
-45 67.9 31.5796
46.9 44.9 49.297
-70 79.9 32.2592
-59.5 20.4 35.2538
-39.525 43.4 33.45325
48.15 44.9 53.362
55.5 67.4 52.3888
48.3 44.9 53.849
-48 59.4 35.8924
49.05 44.9 56.313
63 67.4 53.3878
49.975 44.9 58.97158333
-57.5 54.9 45.799
51.3 44.9 59.296
52.2 44.9 56.296
-17.5 55.9 28.0948
-9 49.4 29.0762
67.575 44.4 63.523
-12.525 46.9 27.99383333
-3.8 45.9 28.748
48.5 69.9 48.0794
-69.5 40.9 43.4326
70.2 25.4 82.849
58.925 44.9 62.03875
21.2 46.9 55.979
-69 74.9 36.9682
-53.5 67.4 39.5474
62.35 44.9 62.6415
66 44.9 62.9584
-14 44.9 28.1456
67.5 44.9 63.3022
-37.65 79.4 41.3045
68.95 25.9 70.40725
-40.875 79.4 33.38425
71 44.9 63.1066
62.425 55.4 58.448375
-22 56.9 30.4036
-0.4 65.9 40.196
-40.6 57.9 41.79
-35.95 83.4 35.755
3 45.4 28.1744
-12.5 60.4 24.8032
-70 45.4 43.5874
51.35 57.9 53.4675
-67.2 45.4 43.751
-64.5 21.4 36.0964
37.4 43.4 50.422
-7.5 61.4 25.4034
-61.5 45.4 46.1136
51.625 66.9 50.966
-32.425 57.9 30.00875
-55.45 45.4 44.057
-55.075 45.4 44.008125
-40.55 79.9 33.672
-54.5 45.4 43.9928
28.425 24.9 35.047125
-42.35 48.9 34.426
-61.5 44.9 46.0748
23 73.9 52.3878
-52 45.4 43.998
62 46.4 61.0802
71.9 53.9 79.69
-50.5 45.4 44.0016
63 53.4 57.7548
-59.025 74.9 37.01983333
4.15 53.4 61.1525
-50.5 51.9 44.0138
-41.35 24.9 26.917
24 74.4 51.9162
-48.5 45.4 42.2898
-44.2 49.9 37.582
55.5 43.9 61.8718
-48 45.4 42.3118
-69.55 9.4 17.82
-65.475 58.9 45.0175
-60 49.4 46.2584
42.5 52.9 52.7506
-69.5 85.9 23.7284
-42.15 45.4 39.787
-51.5 38.9 42.899
53.15 71.4 45.421
-40.2 45.4 42.337
-39.7 45.4 38.676
54 72.9 48.7388
-15.5 53.4 28.616
-43.55 83.4 13.298
-69.025 8.9 17.506
50.65 71.4 43.971
55.6 6.4 48.66566667
-37.7 45.4 31.809
-37.6 45.4 31.117
-61 56.9 45.4648
-37.5 45.4 30.427
-36.5 45.4 28.8768
-55 78.9 30.3704
-36 45.4 28.9034
-45.05 65.4 33.1395
-58.5 48.9 46.2594
-66.075 46.9 46.0325
-35.6 45.4 28.69933333
-58.5 33.9 43.5154
-35 45.4 28.741
62.5 51.9 56.8774
29.025 44.9 55.03008333
-37.45 41.9 29.841
-6 59.4 26.6428
-11 43.9 28.7084
63.3 2.4 60.42
-31.95 45.4 31.14525
-30.5 45.4 31.3872
-37.5 49.9 29.796
-41 51.9 30.79433333
21 45.4 57.6148
-21 57.9 25.73
16.5 31.4 36.9368
23 28.9 34.4848
12.5 45.4 60.104
38.5 70.9 48.8516
-45 60.9 35.2756
-25.5 45.4 31.4234
-23 45.4 31.439
-22.5 45.4 31.431
-12.5 51.4 28.1202
-21 45.4 28.959
43.475 67.9 47.994
-19.95 45.4 27.311
-18.5 45.4 28.934
31 47.9 54.7318
-49.35 30.9 32.5015
53.6 56.9 56.10733333
56.7 75.9 56.009
34.3 69.9 47.953
-49.5 74.4 33.541
50.925 50.4 63.48
1 45.4 28.6582
67.6 60.9 56.17033333
2 45.4 28.416
13.575 45.4 60.026375
14 45.4 59.9562
15.475 45.4 59.50958333
-24 43.9 31.2142
17.025 45.4 59.01666667
-60.5 45.4 46.121
-69.5 22.9 37.3128
-66.5 24.4 38.4658
-43.65 45.4 37.459
18 45.4 58.6504
35.975 69.9 48.51866667
59.3 7.4 50.983
-65.5 19.4 34.284
22 45.4 57.3346
22.5 45.4 57.1922
-53.975 51.9 44.01741667
-55.7 71.4 39.026
-55.025 11.4 20.47175
19.4 29.4 35.201
18.7 32.9 34.143
52.6 73.4 50.06766667
-67 74.4 37.3976
27.5 45.4 55.8306
33.1 45.4 55.113
12 35.9 36.5602
-2.5 51.9 29.052
-46.5 41.9 36.9036
-54.5 79.4 29.8218
-59.475 64.9 43.00891667
-51.025 37.4 42.5175
-70 35.4 43.2696
-50.5 48.4 44.1186
41.5 45.4 52.062
64.1 26.9 65.181
72.7 54.9 92.522
43.575 45.4 43.016
-55.7 41.9 45.381
44 45.4 42.84
60.8 7.9 54.503
27.925 74.9 49.9985
3.025 47.9 28.0315
45.2 45.4 44.651
71.7 51.4 75.418
-70 53.9 43.7276
47.15 45.4 50.1825
-43 42.4 36.7196
19.2 28.4 33.684
-51.5 80.9 27.9128
47.55 45.4 51.311
53.6 45.9 59.43933333
48.45 45.4 53.8485
48.8 45.4 54.834
63.5 70.9 52.0808
-34.85 46.9 29.1585
49.65 45.4 57.2645
7.425 49.9 61.53075
9.5 33.4 36.881
40.6 41.4 49.765
-59.5 23.4 37.7864
-2.5 47.4 29.0826
-57 36.4 44.4458
51.7 45.4 57.894
-67 32.4 42.8708
-36.5 49.9 29.092
51.8 45.4 57.599
65.15 71.9 48.707
52.2 45.4 56.296
-60 69.9 40.4634
8 33.4 36.4616
9.5 43.4 28.4732
52.9 45.4 54.946
-37.5 65.4 25.754
-36.85 33.9 25.876
56 45.4 60.94
-38 56.4 32.2802
25.5 28.4 34.3316
58 45.4 61.3972
-34.85 58.4 27.7235
-14.5 50.9 27.1952
40.5 17.9 38.3776
-34.85 55.9 28.8255
-46.4 22.4 31.049
-22 59.9 29.327
-49.7 82.9 24.439
-37.7 49.4 32.714
61.5 45.4 62.2702
64.5 21.4 67.028
63.5 45.4 61.9374
64.5 45.4 62.181
-66.5 83.4 27.8282
30.1 43.9 54.064
39.05 41.4 47.825
21.5 72.9 52.8522
-31 45.9 31.3732
65 45.4 62.277
67.5 45.4 63.054
-50.05 34.9 41.65675
1.4 50.4 38.704
67.925 45.4 62.918375
69.475 45.4 63.02391667
72 45.4 63.0516
-35.65 33.9 24.4255
-45 66.4 32.6082
4.55 34.4 36.60525
49.15 9.4 43.1335
-49.925 42.4 43.54125
-41.4 48.9 32.704
53.9 38.4 56.232
60.5 6.9 52.7874
59.5 69.9 52.6482
7.65 52.4 61.481
-44.5 31.9 33.0456
55.5 51.9 56.0476
-15.55 59.9 23.4425
-63 41.4 45.5728
-49.4 59.9 36.362
-58 10.4 21.7376
64.925 55.9 58.54625
-47.5 35.9 35.076
68.1 73.4 51.60566667
1.4 49.9 38.047
1.575 46.4 28.5425
-64.5 11.9 24.5248
6.2 37.4 34.875
-7.5 47.4 29.1118
55 68.4 52.1924
-48.5 51.4 37.444
-4.5 43.4 28.4046
-70 45.9 43.5906
-69 45.9 43.6018
-38.35 64.9 30.0165
43.1 41.4 52.9125
-68.5 45.9 43.6116
66.05 14.4 68.252
-58 68.4 41.3512
57 38.9 60.6194
-64 45.9 46.1478
65.4 51.9 57.33066667
-59.5 45.9 46.1752
-7.5 44.4 28.7798
-56.425 84.9 25.538875
-68.5 28.9 41.2312
-59 45.9 46.1648
-67.5 10.9 22.6358
-58.5 45.9 46.1722
-58 45.9 46.171
64.65 24.4 68.7215
-64 77.4 34.8248
63.95 23.9 65.8205
-49.5 45.4 43.35
-51.075 45.9 44.016625
-55.925 38.9 45.019
-48.55 43.9 38.3485
-58 20.4 35.2886
-34.85 48.9 29.7785
-49.65 45.9 43.7015
-48.075 45.9 43.018875
-64.5 17.9 32.7724
-53.5 64.4 40.936
-53 32.9 40.8372
-46.65 45.9 42.6475
-35.65 34.9 24.946
-44.5 45.9 37.4816
-44 45.9 37.4934
62.95 8.4 61.7205
-40.925 45.9 41.419625
32 73.4 49.677
-40.35 45.9 42.226
37 21.9 35.743
63.8 5.9 64.49
-33.9 45.9 29.62966667
-31.95 45.9 31.14775
68.475 16.4 71.51691667
5 44.9 28.1498
-39.025 43.4 32.96733333
-19.35 46.4 28.6785
-49 38.4 35.9618
-28.5 45.9 31.463
-50.525 76.9 32.5235
71 49.9 74.183
-23 45.9 31.4862
-60.5 21.4 36.1224
67.2 10.9 70.17
-56.5 81.4 30.6178
-20.9 45.9 27.786
-55.6 18.4 30.57
-12.4 47.9 26.317
-20.8 45.9 26.467
-57 27.4 40.4524
15.5 31.9 37.0926
-44 43.9 37.2574
68.9 23.9 79.997
54 41.9 61.8306
-18.1 45.9 28.67166667
71.4 54.4 73.935
-45 56.9 36.5986
4.5 45.4 28.1454
-8 42.4 28.1292
15.1 47.4 58.461
-17 45.9 28.151
-20.2 56.9 25.45
-3.7 65.9 26.457
-16 45.9 28.0966
14.5 31.4 37.0896
-14.5 45.9 27.9776
-55.7 72.9 38.031
-37.2 69.9 44.04
69 8.9 72.1964
-50.925 14.4 24.53525
-12.6 59.9 24.355
3.025 45.9 28.01891667
-60.5 9.4 19.2482
4 45.9 28.1252
4.5 45.9 28.1922
70.1 49.4 71.358
6 45.9 28.1924
-42.2 79.9 29.461
6.9 45.9 28.274
61 54.9 58.3632
12.5 45.9 60.3492
60.125 31.9 62.0035
-35.4 44.4 28.414
-66.425 21.9 36.516625
14.5 45.9 59.837
-55.7 67.9 41.229
-35.6 63.4 24.143
24.575 45.9 56.53325
-57 22.9 37.41
-16.8 57.9 22.032
55.575 40.9 62.00425
26.925 45.9 55.98525
-63.5 33.4 43.328
-46.3 73.9 26.187
28 45.9 55.728
-18.8 47.9 28.136
-27 44.9 31.3734
30.525 45.9 55.03083333
-62 25.9 39.487
31.925 45.9 54.504625
-63.5 25.4 39.1382
-58.5 49.4 46.2434
-45 65.9 32.8254
-54 46.9 44.0948
-61 39.9 45.323
-65.5 42.4 45.7446
39.3 15.9 37.505
40.5 45.9 50.976
-54.975 58.4 43.00741667
-69.5 36.4 43.3208
-32.95 84.4 41.194
-46.5 33.9 34.1652
65 70.4 52.9716
52.2 45.9 56.296
-42.35 83.9 15.4345
23.925 43.4 55.474
53 45.9 56.485
62 45.4 62.346
-38.35 32.4 27.426
-44.55 71.9 28.26275
53.4 45.9 58.987
71 43.4 63.5934
53.975 45.9 59.54291667
54.5 45.9 59.76
65.8 49.4 58.923
-65 56.9 45.466
56.925 45.9 60.544875
5.85 49.9 60.379
57.5 51.9 56.3244
30.2 43.9 54.759
57.5 45.9 60.673
67 45.9 62.338
-64 55.9 45.6892
68.5 45.9 62.736
-46.5 27.4 29.9602
69 45.9 62.8958
-39.1 32.4 28.77933333
-45.15 79.9 21.878
69.575 45.9 63.00225
71.5 45.9 63.0588
-64.5 53.4 45.9996
-40 68.4 45.9498
56.5 43.4 62.2626
60.5 35.9 52.9176
60.025 69.4 53.0205
-4.15 55.4 27.8825
-43.4 45.4 37.817
-66.925 37.9 43.87875
-53.5 71.9 36.7262
-37.35 54.4 28.6795
-8 55.9 28.0946
-56.1 86.9 21.625
-57 20.9 35.7396
-55 42.9 43.6872
-21.05 53.4 31.16075
-46.35 76.4 22.968
-6 54.9 28.4054
-23.5 59.4 29.562
-27 57.9 30.0682
-37.15 33.4 25.232
13.925 45.9 60.002
64.65 26.9 67.781
-65.5 32.9 43.0898
-69 46.4 43.614
34 70.9 49.2096
42 16.4 39.2086
64.5 73.4 45.143
-66.025 46.4 46.02941667
-65 18.9 33.7818
17 31.4 36.8918
34 44.4 54.9554
61.5 31.4 62.2128
-33.5 52.4 31.4214
-63.5 46.4 46.1594
67 33.4 63.7266
-61.5 41.4 45.5828
-40.475 62.9 46.04916667
51.475 54.4 56.04066667
-52 49.4 44.1246
-56 39.4 45.169
52 10.9 44.2518
-59 29.9 41.7618
41 44.4 52.814
-40.45 54.9 32.29225
-58.5 46.4 46.1846
-57 76.4 35.7886
-55 46.4 44.0666
3.8 36.4 34.969
-54.5 46.4 44.0422
-45 46.4 37.513
51.1 7.9 44.228
62.6 43.4 62.963
-43.9625 46.4 37.52325
-35.5 59.9 26.1962
-43.6 46.4 37.82333333
65.6 72.9 49.21566667
-43.15 46.4 38.41
-42.45 46.4 39.322
6.5 34.9 36.7424
-41.85 46.4 40.103
-66.5 60.9 44.3326
-41.65 46.4 40.363
-40.95 46.4 41.273
13 72.4 56.3424
-39.7 46.4 42.469
7 44.4 28.179
-57.5 37.9 44.8562
-60.5 46.9 46.2174
66.1 72.4 50.2
-23.475 46.4 31.524
-29 55.9 30.7066
55.375 49.4 57.36475
-21.475 46.4 31.52191667
-65.5 86.9 21.7688
-38.1 47.9 32.759
54.425 68.4 52.0215
65.6 73.9 48.82333333
-42.925 47.4 38.915
-19.55 46.4 28.2525
-15 46.4 27.4282
25.5 45.4 56.3422
35 42.9 51.9448
64.15 2.9 62.1335
-55.7 43.9 45.638
55 57.9 55.7224
-10.575 46.4 29.01475
-30 53.9 31.155
-44 27.9 30.2954
-47.5 46.4 43.4464
46.9 50.9 52.66766667
-21.5 59.4 29.5106
-7 46.9 29.0782
-9 46.4 29.0498
64 45.9 61.3506
-7 46.4 29.0476
71.6 49.4 81.939
70.5 50.4 63.385
42.5 71.4 47.8276
-50.5 49.4 44.122
-3.85 46.4 28.903
48.95 45.4 55.256
18.45 72.9 54.1225
-0.025 46.4 29.02275
58.3 6.9 50.958
-69 58.9 43.8078
0.5 46.4 28.865
-39.2 74.9 44.509
-37.3 56.4 28.007
45.5 67.4 47.9246
9.2 46.4 59.547
-39.85 83.9 22.75
57.5 43.4 62.4194
-38.475 63.9 30.524
50.95 41.9 60.3305
6.5 51.9 61.875
13.5 46.4 60.1416
-56.05 82.4 29.17275
-49.1 72.4 32.427
-57 62.9 43.8038
11.2 72.9 55.674
61 43.4 63.1442
15.9 46.4 59.11133333
16.15 46.4 58.968
16.5 46.4 58.8114
30.9 21.9 34.884
51 45.4 58.7016
17.95 46.4 58.11225
20.5 46.4 57.1516
-19.425 47.9 29.03275
22.5 46.4 56.6728
26.075 46.4 56.034375
66.8 6.4 68.424
6.4 54.9 61.67066667
8.5 36.4 35.4158
68 41.9 63.6828
-29.5 53.9 31.1722
70.7 51.4 62.515
-40.7 42.9 34.613
52 46.4 55.877
67 4.4 66.6826
52.575 46.4 55.960375
52.85 46.4 56.3625
57 74.9 54.24
53.125 46.4 56.879
51.85 38.9 57.679
53.35 46.4 57.302
26 74.4 51.2228
-16.5 45.4 28.1294
-41 31.4 31.8
-68 39.4 43.4266
-37.1 61.9 29.087
54.525 46.4 58.51475
-2.5 50.4 29.1122
-22 60.9 28.5674
36.5 72.4 48.8266
-42.65 44.9 38.854
61.5 46.4 60.9472
22 29.4 34.429
69.5 49.9 61.245
62.65 46.4 60.834
-49.65 33.4 40.6225
-60 65.9 42.5646
-54.5 48.9 44.1264
-64 20.9 35.6446
-36.025 51.4 29.01025
-46 30.4 32.113
-37.3 41.9 29.127
-38.6 33.9 28.812
-38.9 66.9 28.98733333
-40.65 78.4 34.7515
47.5 13.9 41.2158
66.5 73.4 50.2894
-70 46.9 43.629
-69.5 46.9 43.6378
-68 46.9 43.6654
-12.6 57.4 25.398
-42 48.9 33.80233333
-68 47.4 43.6708
-64 46.9 46.1878
-60.5 23.4 37.7914
70.5 53.4 62.258
-60 46.9 46.2122
-70 19.4 34.2878
-48.5 58.4 36.1962
-56 46.9 46.1752
-40 66.4 46.0338
-54 23.4 35.1206
-67 12.9 26.1772
61.1 63.9 50.642
-40.5 41.9 34.3012
-64 30.4 41.9946
56.525 74.9 54.42808333
-64.5 74.9 37.0028
1 46.4 28.715
-30 52.4 31.4092
-51 46.9 44.0894
59.5 45.9 61.2016
-42.45 46.9 39.272
-42.9 46.9 38.69033333
65.3 72.4 48.843
55.5 45.4 60.8134
-58.5 47.4 46.231
-40.9 46.9 41.274
-39.975 46.9 42.4335
-57.5 21.9 36.5818
-39.4 46.9 39.431
-57 37.9 44.8618
52.925 65.4 50.49675
-10 42.4 28.1652
62.45 4.9 61.727
-42.4 50.9 35.86866667
-36.5 46.9 29.0578
-35.5 46.9 28.7888
-69 17.9 32.754
-33 46.9 31.3768
50 39.4 58.32
34.7 72.9 49.008
-32 46.9 31.5066
2 43.4 28.3546
-26 46.9 31.5624
-37.4 63.9 24.753
-23.5 46.9 31.5546
-37.5 47.4 31.8992
-67.5 67.4 41.8082
17.5 33.4 36.4404
-59.5 83.4 27.9226
64.2 47.4 56.182
58 61.9 43.1918
-21.5 46.9 31.5456
-36.7 74.4 43.943
-45.5 74.9 24.9822
-56.5 31.4 42.4958
-57 66.4 42.3776
64.9 22.4 70.19366667
14.65 35.4 35.992
62.7 45.9 61.365
-17.05 46.9 28.12925
-36.5 52.4 28.8858
-16.3 46.9 27.464
8.5 33.9 36.5362
-15.6 46.9 26.511
-10 46.9 29.1176
-28.5 55.4 30.8326
8.525 67.4 59.00808333
24.5 28.9 34.2818
64.2 35.9 62.42
-9 46.9 29.0752
-40.8 70.9 44.961
-55 28.9 38.9214
-2 46.9 29.0802
-38 44.9 32.664
-65 52.4 46.0948
54.025 58.9 54.52033333
-58.5 51.9 46.1352
-0.5 46.9 29.1248
0.025 46.9 29.03125
38.3 41.9 48.746
44.7 11.9 39.825
1 46.9 28.7298
-65.5 86.4 22.8344
62 66.9 53.5694
27.05 43.9 54.4995
8.4 66.4 58.9345
2.5 46.9 28.266
-3.5 38.9 26.8386
34 48.9 54.1912
-55 56.9 43.3228
-56.5 45.9 46.1792
-50.5 11.9 18.24
-52.5 18.4 29.8528
2.9 65.9 53.854
9 46.9 60.264
64.5 21.9 69.239
-69.5 12.9 26.1894
-38.45 78.4 40.3475
12.5 46.9 60.455
-52.5 38.4 42.7598
48.45 44.9 54.3365
-6.5 49.4 29.1202
13.525 46.9 60.03741667
14.475 46.9 59.50725
-40.4 31.9 30.80666667
-37.85 62.9 30.4595
22.525 46.9 56.02216667
-33.45 46.4 29.62725
24.5 46.9 55.8608
60.9 7.4 54.758
-59.5 51.9 46.1448
25.5 46.9 55.7622
17.5 30.9 36.7566
20 72.4 53.2086
-64 64.9 42.99
27.975 46.9 55.51966667
32.975 22.4 36.01333333
-69.5 74.4 37.3614
-49.65 50.4 43.795
-34.7 45.9 29.006
-41.875 78.9 31.339
-49.35 63.4 34.3165
28.5 46.9 55.4442
-61.5 72.4 38.8952
-49.65 65.4 40.1435
-59 83.4 27.911
37.6 46.9 53.373
-6.5 54.4 28.495
37.7 46.9 50.259
-54.5 78.9 30.3808
-40.55 60.9 45.824
65.9 52.4 57.85966667
9.8 33.4 37.003
51.075 43.9 59.928375
-68.5 72.4 38.8544
55.975 57.4 56.53008333
-40.3 60.9 42.154
63.2 46.9 59.433
-66.5 83.9 27.0646
7.5 65.9 59.661
63.35 46.9 58.85
60 44.4 62.5816
63.5 46.9 58.243
19.5 31.9 36.2306
65.925 31.9 67.534375
-55.45 35.9 42.25675
18.425 45.4 58.49325
-61.5 45.9 46.1482
63.6 46.9 57.839
18.4 32.9 36.23666667
53.65 6.4 46.175
45 52.9 53.4684
-41.9 45.4 40.18033333
72.2 53.4 82.661
-53 42.9 43.6954
-59 27.9 40.7304
13.5 30.9 36.9854
-70 47.4 43.6472
-15.5 47.4 18.761
-63.5 24.4 38.4618
-67.925 41.4 43.51
-54 43.4 43.7708
-15.5 45.4 28.1302
-30.45 44.4 30.62125
-52.5 43.4 43.7448
45 12.9 40.8736
-60.5 54.4 45.8768
-69 47.4 43.6378
-68 87.4 20.5718
-20.5 42.9 30.3464
66.85 65.4 54.888
-47.5 27.4 29.9298
-49.65 46.4 43.734
31.5 24.4 35.4006
-65 47.4 46.2288
-64 47.4 46.1922
-47 67.9 31.6016
-39.05 64.9 45.2875
-53.075 13.9 23.964375
-63.5 47.4 46.1982
-40.15 56.9 41.8205
-40.3 33.4 31.425
-54.5 47.4 44.1196
-28.5 59.4 29.4264
-51 47.4 44.085
-46 47.4 37.596
25 28.4 34.4584
-49.45 75.9 32.9065
-45.5 47.4 37.5804
51.2 57.9 53.356
70.45 16.9 71.842
-43.1 50.9 36.64133333
64.55 32.4 57.881
16.5 43.4 57.8994
-36 42.4 28.2752
-69 73.9 37.7522
-8 54.4 28.5082
8 68.4 59.0716
-10.575 46.9 28.990125
63.55 32.9 58.6775
15.7 71.4 54.99
-69 78.4 33.7978
7.05 41.9 27.6565
-1 54.9 28.3976
-28.5 47.4 31.5914
-27 47.4 31.6178
-24.5 47.4 31.5788
-23.5 47.4 31.5964
62 38.9 60.9192
-59.5 28.9 41.2442
60.5 55.9 58.1942
59.5 70.4 52.3112
-63 63.4 43.6052
-64.5 69.9 40.4484
-22 47.4 31.5964
52.15 48.4 61.582
-20.9 47.4 28.656
-29.5 50.4 31.5708
53.3 48.9 60.499
-41.075 65.4 46.032375
-20.5 47.4 26.6026
59.7 7.9 51.242
67 45.4 62.911
-11.95 47.4 28.1165
-14.65 48.4 20.82
-17.35 47.9 27.928
-10.5 47.4 28.9135
28.5 44.4 54.7056
-0.5 47.4 29.1248
0.025 47.4 29.02625
2.5 47.4 28.2198
-67.5 34.4 43.3614
-45.5 61.9 34.906
-56.05 68.4 41.28575
8.6 47.4 60.601
-64 87.9 19.4804
-26.5 57.9 30.0826
9.5 47.4 60.942
-6.5375 43.9 28.5355
53.65 53.9 56.6185
14.475 47.4 58.96875
24.7 47.4 54.999
-62 84.9 25.5868
-38.65 78.4 39.841
-58 41.9 45.7214
69.5 7.9 72.1658
3.45 36.9 33.7775
32 47.4 54.8414
-39 68.9 45.7792
34.5 47.4 54.3566
-59.5 74.4 37.4528
35 47.4 54.2248
-21.1 44.9 31.19033333
37 47.4 53.7912
37.7 47.4 52.979
1.5 49.9 38.556
67.85 73.4 51.439
-15.075 45.9 28.015875
-50.9 22.4 34.15966667
-63 68.9 41.0446
41 48.9 53.1688
-20.1 50.9 26.54633333
60.6 7.4 51.646
-28 56.9 30.4234
64.1 47.4 55.849
-53 81.4 27.258
64.7 47.4 57.732
56.525 35.4 51.51216667
66.025 47.4 59.94908333
-35.575 49.4 28.93125
-34.3 50.4 31.52
-5 56.9 27.7356
69.025 11.9 71.4835
-49.55 56.4 42.865
64.4 49.9 55.852
-61.5 39.9 45.308
32.5 72.4 49.7442
66.5 26.4 69.6926
-18.975 42.9 29.52941667
2.2 36.9 32.443
-48 71.9 28.7864
62.4 45.4 62.257
67.5 70.9 53.2498
-36.96666667 43.4 28.66833333
49.15 45.4 55.8185
-12.925 49.4 27.00875
-57.925 14.4 28.511625
-55.6 58.9 43.858
-20.2 45.9 26.806
-55.3 65.4 40.509
21.5 43.9 57.2622
-46 43.4 37.1802
-4.65 47.9 29.102
6 62.9 60.8038
54 67.4 51.9516
31.025 46.9 55.02391667
-65 47.9 46.2338
65.85 8.9 67.8795
71.5 44.4 63.2726
-18.5 47.4 28.1274
-57.5 19.9 34.8372
51.475 56.9 55.03308333
-67.2 44.4 43.738
49 13.4 41.3932
-39.6 31.9 29.313
-64 44.4 46.0358
-69.5 60.9 43.8086
-20.5 49.4 26.5894
-55 47.9 44.1168
64.8 60.9 52.481
24 45.4 56.7756
-62.5 56.9 45.4584
-49 47.9 43.8784
-48 47.9 43.921
-36 46.9 29.0556
-48 48.4 43.921
-57 84.4 26.3678
67.6 23.9 72.909
-47.5 47.9 43.9366
-61.5 36.4 44.4074
25 28.9 34.1678
-48 67.9 31.6004
-45.5 47.9 37.6142
29.5 74.4 49.8794
33 73.9 48.827
-66.075 47.9 46.042625
70.5 72.4 52.8252
63.125 6.4 63.385
-66 11.9 24.5112
-41.65 47.9 40.4135
9.8 32.4 36.998
-48.5 34.9 34.6184
-38.5 47.9 32.972
68.5 43.9 63.1286
-51 32.9 40.8642
-55 65.9 40.3134
-58.5 80.4 31.8044
-44 46.9 37.547
-37.3 47.9 31.493
-54.5 77.9 31.5042
-49 20.9 31.9234
54.05 65.4 51.29875
-34.7 47.9 29.839
-35.5 55.9 27.8116
65.8 19.9 71.907
-55 74.4 34.804
-31.5 47.9 31.6148
-31 47.9 31.5992
-25 47.9 31.5948
51 67.9 49.0668
67.35 12.4 69.8825
48.025 42.9 56.99758333
-44.5 58.9 36.0708
36 42.4 48.78
30.5 46.9 55.1382
-45.5 42.4 36.9668
-50 13.4 22.0552
-18.5 47.9 28.1248
52.5 42.4 59.383
68.5 6.4 70.314
-17.15 47.9 27.7715
-16 47.9 18.8736
57 75.9 55.862
-61 55.4 45.7446
-19.7 46.4 27.933
54.475 66.9 52.03708333
-15 47.9 18.4868
71 43.9 63.39
-53.5 68.4 38.966
56.625 72.4 48.509
-65.5 12.4 25.4166
-39 50.4 33.0518
31.4 42.9 52.03666667
-13.1 47.9 23.65
48 43.9 56.3502
-11.85 47.9 28.1125
-33 56.9 30.4626
-22.5 57.9 30.1016
-11 47.9 28.116
-54.5 65.9 40.3074
-10.375 47.9 28.896
64 69.4 53.3128
-35.4 79.4 43.45966667
49.5 44.9 57.785
-7 47.9 29.1264
-62.5 81.4 30.5666
-6.5 47.9 29.1094
46.15 44.9 46.853
25.15 25.4 33.6545
-8 47.9 29.0738
-1.5 47.9 29.1312
49.6 40.9 57.549
-0.075 47.9 28.99925
-50.5 68.4 38.9782
-55.7 67.4 41.446
-54 55.9 43.4786
-68.5 76.9 35.2308
69 51.4 60.415
-68.5 57.4 43.794
7 49.4 61.182
8 49.4 61.4752
-41 22.9 23.637
-21.1 46.4 30.593
-43 37.9 35.7876
-59.5 43.4 45.9302
46.5 51.9 53.6054
57.35 5.4 50.3625
38.8 48.9 53.503
10.425 47.9 60.99975
50.35 70.9 44.359
70.3 25.4 83.973
12.525 47.9 60.00425
64.35 47.4 56.694
-62 72.9 38.5318
20.65 47.9 56.272
-69 17.4 32.2176
-50 52.9 43.8874
-70 36.9 43.332
-60.5 18.4 33.3232
-3.5 49.4 29.0518
63.95 27.4 64.8465
69.5 42.4 63.9486
-49.65 37.4 42.162
32.5 47.9 54.7326
35 47.9 54.3308
65.4 50.9 57.71766667
60.5 39.9 62.1078
5.425 59.9 61.4465
37 47.9 53.8378
37.5 47.9 53.6838
3.8 64.4 59.934
37.925 47.9 53.502125
38.4 47.9 53.31433333
52.8 47.9 58.755
63 61.9 44.4478
-41.7 45.4 40.495
34.8 18.9 35.417
31.5 48.9 53.8532
64.2 51.4 54.673
62 44.9 62.7272
43.1 53.4 52.374
-53.5 34.9 41.683
43 44.4 53.8244
-60 73.9 37.7946
65.35 47.9 58.8365
-63 38.4 44.938
67.3 47.9 60.664
-68.5 17.4 32.225
-60 20.9 35.7128
-59.5 84.9 25.6446
40.85 42.4 52.4455
-51 59.9 42.5876
63.4 51.4 55.28466667
-27.5 44.4 31.2596
-54 76.4 33.0506
-64.5 37.9 44.8162
-22.5 59.9 29.3066
-50.5 24.4 35.9114
24 44.4 56.6582
39.5 17.4 38.2408
12.9 47.4 60.106
19.425 72.4 53.48025
69.925 11.4 72.0155
-39.5 71.4 45.5976
51 66.4 48.337
55.5 45.9 60.156
-43.35 46.9 38.1075
59.5 35.4 52.1554
-61 57.9 45.235
-52 54.9 43.6538
-55.8 34.4 43.472
-66.4 53.4 45.62366667
2.8 65.9 53.444
-49.7 56.9 43.128
-0.5 51.9 29.0428
70.8 52.4 64.366
-66 64.9 43.0058
-69 46.9 43.641
-68.5 48.4 43.6606
-19 56.4 27.9224
-63.5 48.4 46.224
65.45 40.9 62.8795
-50.8 11.9 18.45
65 43.9 63.2184
-42.925 48.4 38.915
70 50.9 60.759
-60.5 48.4 46.2544
-63.5 22.4 36.9388
-58.5 48.4 46.2556
-2.7 66.4 30.329
-54 48.4 44.0946
65.8 4.9 66.412
1 38.4 30.236
-44.5 49.4 37.6058
-47 48.4 43.8942
-13.7 47.9 21.403
-42.6 48.4 39.30733333
-39.6 48.4 42.71633333
-38 48.4 32.8406
51.7 40.4 58.457
-30 45.4 31.4054
13.2 71.4 56.466
-20.4 44.9 30.307
-36 48.4 29.2024
3.55 58.4 61.6335
-34.85 48.4 29.678
52.8 50.4 66.221
-34.4 48.4 30.759
-34.025 48.4 31.4705
-33.5 48.4 31.6306
-62.5 14.9 29.1494
-63.5 82.4 29.3046
62.5 62.4 44.3692
-44.5 55.9 36.7584
-31 48.4 31.6326
52 9.9 45.1592
-28.5 48.4 31.6712
-63 37.9 44.8014
-28 48.4 31.6632
-39 73.9 44.7926
-50.5 63.4 41.3892
-54 20.4 31.9934
-23 48.4 31.6334
-17.5 59.9 25.2132
-59 24.4 38.501
-21 48.4 29.996
1.2 50.4 37.622
-20.5 48.4 26.62
-65.5 79.4 32.799
-11.5 42.9 28.315
-53 28.9 38.8554
-55.6 76.9 33.08
-17.65 48.4 28.101
-53 18.4 29.8306
18.45 73.4 54.1225
-14.2 48.4 22.007
-13.55 48.4 23.7125
5.5 64.9 60.5772
-13.15 48.4 24.7805
-12.95 48.4 25.3145
-55 76.9 32.5626
-11 48.4 28.1124
-45.15 79.4 22.881
-47 28.9 31.098
-10.65 48.4 28.187
-5 48.4 29.135
-68 77.9 34.2936
-22 58.9 29.702
-2.525 48.4 29.03033333
72.5 51.9 85.851
-67.2 56.9 43.994
0.5 48.4 28.7664
4.9 50.9 58.954
-36.6 58.9 27.108
-46.35 48.4 37.629
-39.5 53.9 32.714
2 48.4 28.2904
65.4 14.4 67.422
-62 49.4 46.2332
3.8 48.4 27.807
-21.1 54.9 30.85633333
52.5 41.9 59.383
10 48.4 61.1202
-67.5 11.4 23.6372
-2 47.4 29.1022
11.85 48.4 60.133
-50.5 83.9 23.19
47.6 14.4 40.481
70.8 49.9 72.451
-8.5 45.9 28.9702
29.1 48.4 53.94633333
7 50.4 61.6052
31 25.9 34.321
-54.5 24.4 35.8308
29.425 48.4 54.023375
44.35 41.4 54.4315
70.6 49.9 70.724
67 9.4 70.30466667
-39.5 59.9 33.459
69.45 6.4 71.138
-31 48.9 31.6438
31 48.4 54.3476
62 2.9 58.3328
-54.35 25.4 36.6035
65.2 61.9 53.183
-63.5 64.9 42.9998
-43 35.4 34.8424
39.5 48.4 53.255
-58 85.9 23.8454
-39.65 83.9 23.334
-42.1 36.4 35.14966667
-63.5 46.9 46.1896
61.5 44.9 62.6388
-63 36.4 44.4022
67.525 45.9 62.52208333
63.95 37.9 59.1745
-41.7 56.9 39.418
65.85 48.4 59.347
-52.5 36.4 42.1874
-57.5 35.9 44.2552
-41.4 56.9 39.88383333
66.1 48.4 59.63766667
40 18.4 38.095
-36 81.9 42.2508
-69.5 46.4 43.6128
-55 81.4 27.2756
-69.5 23.9 38.115
-57 39.9 45.3188
40.925 51.4 53.523
66.4 49.9 59.312
63 43.4 62.9606
-39.65 42.9 33.625
-1 38.4 28.7158
-53 45.4 43.9894
24.5 72.9 51.776
-48 20.9 31.9492
56 55.4 57.4014
-55 40.4 43.2818
71.1 50.4 70.045
22 29.9 34.3036
-32 49.4 31.6178
-49.7 79.9 28.74
33 46.9 54.611
-70 48.9 43.6712
64.6 9.9 64.323
-56 39.9 45.2492
-66.4 48.9 45.84233333
-57 83.4 27.9082
-1.5 66.4 35.14
29 46.4 55.4534
-53.5 32.4 40.6394
-65.5 48.9 46.201
-36.5 53.9 28.6418
-40 59.9 36.193
-62.5 16.9 31.678
-63.5 80.4 31.7164
-15 43.9 28.6384
-54 67.9 39.2626
-61.5 48.9 46.2436
-46 49.4 37.623
-47.5 54.4 37.166
-50 48.9 44.0922
32.5 45.9 54.4282
7 45.4 28.1932
59.2 4.4 52.115
57.975 69.4 52.53908333
-49.5 48.9 43.7606
68.45 24.4 70.67075
-49 48.9 43.7856
40.3 43.4 52.507
-54.5 17.9 28.9426
29.5 45.9 55.3298
-52 54.4 43.7794
-16.7 47.9 26.898
-46.5 48.9 43.47
-52.5 34.4 41.456
-43.65 48.9 36.769
-43.4 48.9 36.31733333
-51 11.4 17.7786
-36 77.9 43.9404
-43.1 48.9 35.77633333
-58 32.4 42.9434
-51 35.4 41.8328
-58.575 64.9 43.01475
-66.5 82.9 28.556
-42.625 48.9 34.921
-37.5 48.9 32.009
30.5 22.9 35.3054
-34.2 48.9 31.468
70.7 23.9 89.801
-60.5 49.4 46.2478
-65.5 25.9 39.4828
-27.5 48.9 31.6528
17.2 33.4 36.508
-26 48.9 31.6076
-24 48.9 31.6194
-23.5 48.9 31.6246
27.5 47.4 55.184
-20.5 48.9 26.618
68.05 13.9 70.21725
-19.05 48.9 29.0645
-17.4 48.9 27.88333333
49.4 42.4 57.72766667
-65.5 43.9 45.9422
52.8 42.4 59.489
-69 36.9 43.3454
67 36.9 65.5988
42.4 41.9 53.36666667
-13.45 48.9 25.77975
4.025 54.4 61.44625
-48.5 40.9 36.6428
54.5 43.4 61.7934
-41.15 32.4 32.607
-11.5 48.9 28.1056
-70 18.9 33.7772
3 44.9 28.112
-3 48.9 29.1046
-28 54.9 30.9608
-53.05 11.4 19.14875
54.05 7.9 47.21525
-1.5 48.9 29.1258
4.5 57.4 61.9844
0 48.9 28.7648
-48 34.9 34.6132
48 12.9 42.095
-42.7 46.9 38.949
0.975 48.9 28.50833333
16 45.4 59.3472
-49.5 37.4 41.716
-64.5 13.4 26.9678
2 48.9 28.239
-39.5 66.4 45.9252
8.5 48.9 61.3624
-58 69.4 40.7626
13.2 70.9 56.456
52.15 71.4 46.8915
-35 58.9 27.26366667
67 52.9 58.744
60.5 70.9 52.1412
-27 49.4 31.6464
-68.5 39.9 43.425
9.5 34.4 36.762
-58.5 63.9 43.4276
-55.6 63.4 42.011
71.1 53.4 69.473
-0.1 50.4 30.622
66.7 8.9 69.655
-58.5 75.9 36.2568
34.5 48.9 54.2016
57 65.9 52.2808
-40.2 53.9 32.505
-62.425 74.9 37.016
64.6 73.9 45.653
7.5 50.9 61.7026
-66 35.4 44.0624
53.2 48.9 61.01
7.5 35.9 36.2396
-59 23.4 37.781
64.5 48.9 56.478
64.6 48.9 56.969
-57.5 55.4 45.75
-49 34.4 34.3982
-61 41.4 45.6008
-35.6 61.9 25.22566667
24.5 46.4 56.2964
-55.8 75.9 35.879
71.8 53.9 78.439
-44.5 74.4 25.5954
-32 52.9 31.3308
38.575 45.9 50.47025
-64 40.9 45.4876
32.3 43.4 53.226
71.3 73.4 52.502
-69.5 55.4 43.7522
44.55 13.9 40.6455
45.525 11.9 40.94733333
46 13.9 41.1426
-53.5 37.4 42.4782
-65 68.9 41.038
-51.5 53.4 43.8822
-4.4 49.9 28.72066667
7.85 43.9 28.342
-1 44.9 28.8042
-62 27.4 40.3924
-69 52.9 43.7114
-39.65 78.4 37.3025
-55.7 36.4 44.069
-69.5 49.4 43.6854
-44.5 33.4 33.8674
41.5 49.9 53.5576
-65 49.4 46.2284
4.1 49.9 51.678
-62.5 49.4 46.2412
-59 49.4 46.2536
-56.5 49.4 46.2558
-50 49.4 44.1022
-48.5 49.4 43.6682
19.925 44.4 57.987375
-60.5 82.4 29.3234
-19.55 57.9 26.8205
-57.5 74.4 37.4044
57.5 56.9 57.173
-42.9 49.4 36.38566667
54.35 7.9 47.463
41 51.9 53.247
-51.5 32.4 40.6302
-52 64.9 40.7102
-57 39.4 45.2072
68.5 14.9 70.637
-56.025 21.9 36.46958333
-47.5 45.9 43.0104
42.3 15.9 39.459
-44.8 21.4 25.965
-69 77.4 34.7632
-38.5 46.4 32.9122
-27 58.9 29.7294
61 70.4 52.7082
-40.7 79.9 33.29
21 44.9 57.6292
-37.35 49.4 29.0735
-68.5 54.9 43.7666
-56.05 14.4 28.36475
5.075 64.9 60.5055
61.8 6.4 60.438
-34.85 49.4 29.893
-34.55 49.4 30.739
-33 49.4 31.6596
-64 41.4 45.588
44.5 15.4 40.0528
-68 19.4 34.2814
71.3 73.9 52.311
32.35 43.9 53.3855
-60.5 48.9 46.2492
-27.5 49.4 31.648
-22 49.4 31.6136
-58 42.9 45.8466
64.35 26.9 66.362
51.4 11.9 42.81066667
-19.4 49.4 29.01683333
-19.05 49.4 29.06925
38.6 43.9 51.152
-59.5 61.9 44.152
-10.65 49.4 28.261
27.975 44.4 54.99191667
-42.075 42.9 35.916375
-39.6 61.9 37.45
-8 49.4 29.0934
57 42.9 62.434
-38.7 73.9 44.949
-2.475 49.4 29.05016667
-65.5 27.9 40.6862
-50.5 56.9 43.2946
60.5 40.9 62.767
8.1 65.4 58.80966667
-1.5 49.4 29.0936
-66 11.4 23.6366
1.075 49.4 28.543
-46.925 75.4 33.04325
1.5 49.4 28.4466
59.5 66.9 53.0666
6.2 49.4 60.336
-57 8.9 17.9104
19 29.4 36.2558
-54.3 11.4 19.986
-51.5 79.9 29.2188
65.2 48.9 58.203
46.5 12.9 41.6604
-55.45 37.4 42.54875
-43 36.9 35.4396
32.5 49.4 53.424
-43.8 55.4 36.671
59.65 6.4 52.661
36 72.9 48.6078
35.475 49.4 54.01791667
-42.05 46.9 39.789
-69.5 35.4 43.2838
39 49.4 53.794
39.5 49.4 53.615
43.65 49.4 53.3635
45.3 49.4 57.373
46.125 49.4 59.373
-42.35 47.4 39.6095
46.95 49.4 61.375
-36 31.4 22.268
47.55 49.4 62.8325
57.1 6.4 50.19166667
65 35.9 64.8276
62.85 58.4 56.604
47.9 49.4 63.21866667
-53.5 47.4 44.0964
-5.5 50.4 29.105
38.025 41.4 47.46308333
-36.5 54.9 28.3984
40.6 41.9 50.81333333
48.15 49.4 62.9165
-18 53.4 28.7078
-44.05 75.9 23.64075
-34.075 56.4 30.48925
51 49.4 63.3028
65.3 57.9 57.667
-19.8 55.4 27.196
52.2 49.4 64.174
52.975 49.4 62.9145
59.9 8.9 53.78
-64 9.9 20.4456
-42.1 44.9 39.746
54.7 49.4 58.924
58.5 64.4 51.2256
-58 43.4 45.8954
-14.1 57.9 19.197
54.95 49.4 58.3465
53.2 69.4 49.759
-33.9 47.4 31.28833333
63.6 36.9 60.468
-55.7 49.4 45.966
20 75.4 52.1376
64.4 49.4 55.852
-51 48.4 44.1096
65.15 49.4 57.777
65.35 49.4 58.2875
64.1 50.9 52.72
-61 18.9 33.8234
38.9 43.9 51.41933333
-55.4 52.4 44.03066667
66.975 49.4 60.01808333
52.5 71.9 46.9378
66.1 1.9 62.869
61.5 34.4 60.313
-61.5 12.9 26.24
18.6 32.9 35.64
38 70.4 48.846
-53.5 53.9 43.8278
68.8 49.4 62.169
-52 56.4 43.4214
69.4 49.4 66.423
67.5 19.4 72.7292
69.5 49.4 67.126
45 68.4 47.6516
69.8 49.4 69.238
-15 47.4 18.697
65.5 65.9 52.9752
70 49.4 70.65
70.2 49.4 72.066
-38.9 42.9 32.904
8.5 34.9 36.4656
-52 31.4 40.1514
-36.5 66.9 21.3538
71.5 49.4 81.234
-40 71.9 45.3028
72.8 49.4 90.399
62.85 52.9 57.629
49.3 9.9 43.496
-43.4 49.9 35.221
-39.1 65.9 45.45233333
-37.35 54.9 28.488
-50 54.4 43.7496
-66.95 36.4 43.72225
-59.5 68.9 41.0558
-56 66.4 42.3018
-40.95 48.4 41.29825
-69 53.4 43.7216
-55 80.9 27.9538
-69.5 49.9 43.6848
-34.1 83.4 41.248
8.2 43.9 30.037
-62.5 49.9 46.2188
-49.35 71.9 28.7975
-62 49.9 46.2194
-46 51.9 37.4598
-59 49.9 46.2426
-42.1 57.4 38.80666667
-61.5 23.4 37.7446
-57 49.9 46.2472
-34 58.9 29.28333333
-61.5 38.9 45.1026
-55.7 49.9 45.96
31 24.9 35.2218
52.075 70.4 49.989625
-64.5 54.4 45.8808
56.5 53.9 57.3814
-54.5 49.9 44.1232
47.1 11.4 42.10133333
-46.5 34.9 34.5806
-30.5 45.9 31.3994
-52.5 41.9 43.5348
4.5 60.4 61.7588
30.35 22.4 34.9015
32.075 48.9 54.000625
-55.7 72.4 38.19
66.1 59.9 55.142
-55.7 75.4 35.951
-51 49.9 44.1148
-44.5 47.4 37.5792
-48.5 49.9 43.3188
-48.5 35.9 35.0916
-38.5 46.9 32.9254
-46.35 49.9 37.589
-69 68.9 41.0296
-45.5 49.9 37.5866
63.7 29.4 63.625
-45 49.9 37.5952
-44.1 49.9 36.605
-42.85 49.9 34.376
33.5 47.4 54.5772
-63 68.4 41.3312
-43.05 51.4 36.76975
-42.45 49.9 33.76725
33.5 22.9 35.9536
37.1 45.9 49.85433333
64.4 6.4 65.82933333
-41.85 49.9 32.831
69.85 10.4 72.4
-41.3 49.9 31.974
-40.6 49.9 30.88433333
-46.65 48.9 43.6545
17.5 43.9 58.4612
-52.49166667 72.4 36.515
-38.5 49.9 33.08
-38.075 49.9 32.94825
-37.6 49.9 32.174
-34.35 49.9 31.324
72 53.9 80.943
-32.5 49.9 31.6226
-31.5 49.9 31.6238
-61 52.9 46.045
-27 49.9 31.636
-26 49.9 31.6046
-20.8 58.9 28.915
-24.5 49.9 31.6114
-45.7 79.9 20.476
11.1 30.9 36.91466667
67.9 65.9 55.627
58.6 6.4 51.61433333
-24 49.9 31.5866
51.6 45.4 58.272
-21.5 49.9 31.609
-15.9 56.4 17.387
-20.5 49.9 26.577
-20.15 49.9 26.5885
46 67.9 48.466
67 24.9 70.3834
-2.05 65.9 33.305
41.975 16.9 39.0135
-18.925 49.9 29.017125
31.425 72.4 50.001375
-1.8 65.9 34.339
-18.45 49.9 28.16225
-16.5 49.9 27.2592
-13 49.9 27.1022
-35.55 41.4 27.726
70.9 54.9 68.307
-11.5 49.9 28.0924
-57 74.4 37.4286
-10.7 49.9 28.332
-44.5 45.4 37.4266
63.075 31.9 62.008375
-8 49.9 29.0934
65.5 8.9 67.6838
-7.5 49.9 29.0664
-70 80.9 31.1184
-46 57.9 36.37
-6.5 49.9 29.0804
63.5 56.4 58.1554
-58 37.4 44.7256
-13.5 55.9 27.2696
69.5 76.4 50.3726
-46.5 68.4 31.2206
-29.5 56.9 30.4302
-2.5 49.9 29.1082
-63 74.4 37.437
7.9 64.9 58.80366667
-1.5 49.9 29.0894
-1 49.9 29.1214
37 70.4 48.8878
-49.4 40.9 37.468
-67.5 67.9 41.5684
-0.1 49.9 30.445
69.475 13.9 71.02733333
0.1 49.9 31.453
-43.15 49.4 36.63
-58.5 63.4 43.6042
-63.5 54.4 45.8596
0.4 49.9 32.962
44 44.9 53.4842
-66.5 43.9 45.508
0.9 49.9 35.495
-66.35 43.9 45.6435
-46.5 42.4 37.0062
1 49.9 36.007
-46.4 73.4 28.868
66.075 61.9 54.40575
1.1 49.9 36.518
-55 72.4 36.3668
1.9 49.9 40.586
2.1 49.9 41.597
65 46.4 60.6498
2.3 49.9 42.605
67.95 35.4 65.9135
2.9 49.9 45.627
3.1 49.9 46.643
3.5 49.9 48.665
-12.05 61.9 24.69025
36.075 49.4 54.0165
4 49.9 51.178
4.2 49.9 52.179
4.4 49.9 53.177
4.6 49.9 54.172
-66.5 30.9 42.2376
4.7 49.9 54.67
30.5 72.9 50.183
66.2 1.9 63.949
5.5 49.9 58.733
-68.5 56.9 43.7808
7 49.9 61.4282
31 71.4 49.691
-45.45 78.9 22.33
-68 61.9 43.8488
32 22.9 35.7834
-45.5 75.4 24.3074
-3.6 66.4 26.709
9.45 49.9 61.149
33.425 46.9 54.50225
9.7 49.9 60.979
-64 9.4 19.2028
59 60.9 42.3584
60 51.9 56.5962
-41.3 22.4 23.572
-38.1 68.4 44.66633333
-33.5 47.4 31.5908
-49 68.4 31.2404
-67 31.9 42.6896
-43.1 20.9 23.697
14.5 44.4 59.3696
71.6 50.4 75.627
48.475 41.4 57.0235
-53 66.9 39.7854
67.575 72.9 51.445875
-62 71.9 39.2296
35.5 49.9 53.6888
52 10.4 44.7804
-52.575 18.9 30.52275
-56 61.4 44.2724
36 49.9 53.7478
67.1 65.4 55.16866667
36.5 49.9 53.8092
-62 55.9 45.645
-50.5 48.9 44.1252
70.2 25.9 82.856
-66.5 61.9 44.0782
37 49.9 53.8468
40 49.9 53.7536
-52.8 11.4 18.982
52 43.9 58.5656
-51.5 66.4 40.0654
53 71.4 47.63
19 44.9 58.3016
-47 31.9 33.0522
-7 49.9 29.0662
-11.925 48.9 27.91575
43.475 49.9 52.99283333
56 72.4 47.0664
-60 75.4 36.6216
44.5 49.9 52.346
-60 11.4 23.7146
-28 50.4 31.5834
47.8 50.9 54.614
-61 68.9 41.0374
44.8 49.9 52.872
45.5 49.9 55.343
-43.5 34.4 34.3554
-40.35 84.4 20.287
-2 50.4 29.1056
70 42.9 63.8576
45.8 49.9 56.431
33.2 45.4 53.847
-45.5 72.9 27.233
46.2 49.9 57.851
61.975 70.9 52.53041667
46.3 49.9 58.206
-21.5 59.9 29.2986
63.8 35.9 59.205
46.7 49.9 59.627
-48 60.9 35.3494
46.8 49.9 59.982
-69.5 77.4 34.7536
-15.45 41.9 27.51
47.3 49.9 61.76
64.7 19.4 70.446
28.5 23.4 34.2806
-43.6 83.9 11.742
70.3 14.9 71.102
-47.5 48.9 43.8822
-52.5 59.4 42.7116
47.45 49.9 62.294
47.6 49.9 62.828
48.15 49.9 62.9165
49 49.9 63.7064
49.5 49.9 63.7656
-60.5 64.4 43.19
-59.5 85.4 24.8096
64.2 61.4 47.171
46.5 54.4 52.7488
-53.475 23.9 35.519
49.85 49.9 63.8425
50.4 49.9 63.653
-61 55.9 45.6246
-38.45 52.4 29.308
65.3 49.9 57.888
-58.5 44.4 46.0358
-45.65 78.9 21.831
68.975 49.9 60.52983333
-51 35.9 42.0376
70 49.9 65.583
45 67.9 47.8246
33.65 44.4 55.02
70.1 49.9 66.434
70.9 49.9 73.317
63.6 4.4 63.14366667
71.4 49.9 77.616
-69.425 21.9 36.5175
71.7 49.9 80.182
6 61.9 60.8068
-61 84.4 26.3972
-69.5 84.4 26.259
0.7 49.9 34.473
71.9 49.9 81.899
-51 82.9 24.9722
-46.525 35.9 35.02566667
-16.5 61.4 24.728
0.5 49.9 33.464
-16 51.9 28.1506
-67.5 75.4 36.5798
-2 60.4 26.2446
72 49.9 82.76
-19.4 59.4 26.425
0.3 49.9 32.46
72.1 49.9 83.622
72.2 49.9 84.485
-63 17.9 32.7802
72.4 49.9 86.198
-60 35.4 44.089
72.7 49.9 88.77
67 73.4 50.7734
-43.45 47.9 38.2945
-68.5 78.4 33.8044
68.5 44.9 62.7662
-55.9 85.4 24.35633333
-65.5 18.4 33.2808
-58 33.4 43.367
64.6 20.4 68.026
6 44.9 28.1884
-2 58.9 26.9074
-58 77.4 34.8732
-54.5 60.4 42.429
42.35 14.4 39.4035
-41.1 49.4 34.631
-52 32.4 40.6256
-51.5 24.9 36.225
14.5 34.9 36.4272
-44.5 42.4 37.0062
66.5 19.4 72.455
8.35 52.4 60.77
-14.45 48.4 21.348
64.075 67.4 53.505
35.5 69.9 48.4346
52.4 51.9 55.32233333
64.2 35.4 61.108
-63 49.9 46.2158
-69.5 50.4 43.6768
-36.5 68.4 19.5964
65.025 26.4 68.94041667
52.5 54.4 55.587
5.525 36.9 36.01833333
-51 43.4 43.754
-49.7 44.9 43.664
-63 50.4 46.2066
46 51.9 53.632
67.5 42.9 63.822
-62.5 50.4 46.2072
65.5 66.9 53.6046
-61.5 50.4 46.1898
-42.35 48.4 39.6095
-60.5 50.4 46.2112
22.5 30.9 33.2978
-60 50.4 46.225
-54 50.4 44.099
51.45 72.4 47.247
-53.5 50.4 44.1076
-39.4 46.4 38.931
-20.5 46.9 26.5916
-67.5 44.4 43.6662
-43.7 75.4 24.173
-53 50.4 44.1048
-34.45 45.9 29.201
-63.5 20.4 35.2284
-49.5 50.4 43.388
-44.8 79.9 22.768
-48.5 50.4 42.701
47.35 49.4 62.3465
-15.15 57.9 18.951
-47.5 50.4 42.7646
46.5 40.9 55.4176
-49 35.9 35.0596
-47 50.4 42.6976
55 52.9 56.6684
-52.5 58.4 42.961
-36.5 52.9 28.8004
65.45 37.9 62.7925
-46.65 50.4 42.398
-44.5 50.4 37.594
-42.85 50.4 36.392
-41.9 50.4 35.406
-41.65 50.4 35.1465
-41.4 50.4 34.88766667
45 53.9 52.6518
39 70.4 48.8274
-40.4 50.4 33.86
-38 50.4 32.7212
-36.5 50.4 29.0672
-15.05 57.4 17.6875
-49.3 35.4 34.896
-51.5 77.9 31.4748
-22 50.4 31.5742
52.1 71.9 46.89266667
-20.15 50.4 26.573
66 62.9 53.8196
10.075 35.4 36.47375
28 45.4 55.6716
-18.65 50.4 28.8925
-18.4 50.4 28.14566667
-17.075 50.4 27.858
-39.6 60.4 35.089
-56 81.9 29.8184
-16.575 50.4 27.509875
-15.5 50.4 27.4068
-15 50.4 27.3588
-40 84.4 21.298
-14.5 50.4 27.3112
8 49.9 61.574
-14 50.4 27.2632
-68 38.9 43.4144
-36.9 61.4 25.706
-11.5 50.4 28.0972
61 65.9 52.9602
-40.7 51.9 31.398
-46.5 71.4 28.7942
-55.2 82.9 24.122
-11.1 50.4 28.152
-12 55.4 28.2736
-8.5 44.9 28.8394
64.7 8.4 66.631
24.5 25.9 33.7798
-44.15 52.9 37.1245
-10.5 50.4 29.0666
-66.075 47.4 46.038125
-9 50.4 29.0778
-8.5 50.4 29.0818
-8 50.4 29.0786
-37.3 58.4 27.253
-41.65 27.9 30.1205
-12.7 41.9 28.172
-3 50.4 29.0998
-60.5 76.4 35.777
-67.5 32.9 43.1
-68 64.4 43.156
61 40.9 62.8216
-10 55.9 28.105
-67.5 68.9 41.0278
53 67.4 50.887
-14 43.4 28.4682
-0.2 50.4 30.086
63 40.9 62.526
0.2 50.4 32.227
-53.5 26.4 37.3588
-10.925 43.4 28.490625
0.7 50.4 34.906
-6.5 58.4 27.2352
0.9 50.4 35.994
1.1 50.4 37.08
64.5 44.9 62.603
1.9 50.4 41.397
-3.5 48.4 29.0594
-37.4 68.4 23.23
2 50.4 41.933
2.2 50.4 43.004
-67.5 47.9 43.752
65.85 72.4 49.8515
4.3 50.4 54.22
35.5 41.4 47.3112
4.6 50.4 55.823
-52 60.4 42.4104
66 18.9 72.391
-35.3 54.9 27.93
-42.8 23.9 26.985
7.5 50.4 61.6524
9.425 50.4 60.938375
10.3 50.4 59.989
47.3 43.4 56.5
52.05 45.9 56.727
-47 42.4 37.0056
-42.35 79.4 29.7805
-41.65 35.4 34.622
36.975 48.4 54.01166667
-65 33.9 43.4968
37.025 50.4 53.52075
40 50.4 53.9254
41 50.4 53.6946
57.5 68.4 52.742
59 53.9 57.77
7.8 47.9 60.509
59.5 53.4 57.6834
-35.15 57.9 27.2305
64.3 32.9 61.478
-22 51.4 31.5322
42.575 50.4 53.541875
-57.5 56.4 45.5586
44 50.4 53.2628
53 73.4 50.0698
67.5 43.4 63.8144
45.975 50.4 52.22275
57.6 5.9 50.69866667
46.35 50.4 52.716
28 25.4 34.975
-63 22.9 37.3582
-19.5 50.4 29.00866667
-36.5 59.9 26.6138
47.35 50.4 55.837
61 7.4 55.781
47.95 50.4 57.747
48.15 50.4 58.375
-60 61.9 44.1376
29.5 73.9 50.252
49 67.4 48.6762
-54 34.9 41.673
48.3 50.4 58.846
-55.7 77.9 33.903
41 16.4 38.902
23 74.9 51.938
-55.7 16.9 30.374
-12.425 50.4 27.93325
-52.5 79.4 29.8154
48.45 50.4 59.317
-44.1 83.9 10.26
49.55 50.4 62.7775
49.7 50.4 63.25
61.55 7.9 56.7025
52.55 45.4 55.188
-66 84.9 25.54
50.3 50.4 65.293
-55.45 43.4 43.8165
-44.575 57.4 36.52
51.15 50.4 66.602
-45.05 23.4 27.621
64.3 38.9 60.378
52.3 50.4 66.488
-60 34.9 43.9144
53 50.4 61.797
64.65 50.4 56.355
-65 67.4 41.8168
0.525 43.9 28.51558333
65.35 50.4 57.8545
67 35.4 65.8436
-16.5 42.4 28.3308
-46.5 50.4 41.951
65.6 50.4 58.22733333
66.05 50.4 58.792
30.975 46.4 55.01625
-69 45.4 43.5824
67.05 50.4 59.63275
-49.7 81.4 26.756
-13.2 46.9 27.418
27 46.4 55.8694
-42.05 83.4 17.744
68 50.4 60.191
68.5 50.4 60.4062
69 50.4 60.58
-36.6 31.4 23.24766667
-6 60.4 26.2832
70.7 50.4 65.632
54.1 38.4 58.751
67.4 11.4 70.33366667
71 50.4 68.937
-66.5 62.9 43.767
32.575 46.4 54.551375
54.2 38.4 59.229
56.575 69.9 52.005375
67.65 11.4 70.6855
71.2 50.4 71.157
53.5 51.9 53.8326
22 73.9 52.7438
63.2 36.4 55.519
72.2 50.4 82.237
-18.8 48.4 28.133
72.3 50.4 83.347
-10 56.4 27.9822
-45 33.4 33.8784
56 42.4 62.2964
-51 83.4 24.075
65.525 36.4 65.00725
72.7 50.4 87.773
-64 46.4 46.1676
-35.575 66.9 21.004
-40.15 56.4 42.8365
53.3 49.4 62.162
56 66.9 52.4436
-68 50.9 43.7194
-67.5 50.9 43.7714
-50 17.9 28.7862
-48.7 44.9 41.125
65.35 61.9 53.4465
-46.35 78.4 20.3395
61.65 8.9 57.679
27.925 44.9 55.499625
-61 50.9 46.1768
-13.5 45.4 28.1184
-60.5 50.9 46.1786
-64 68.9 41.0434
-60 50.9 46.1996
-49.35 60.4 35.5705
-57 50.9 46.1944
30 48.4 54.1954
52.15 49.9 65.272
-56 50.9 46.168
-51.5 50.9 44.0702
71.3 54.9 73.707
53 68.4 50.963
-49.925 50.9 44.032625
68.1 12.9 70.61433333
28.5 44.9 55.2494
34.5 22.9 35.8202
-48 50.9 37.5226
-62 41.9 45.693
-37.8 42.9 32.001
-46 50.9 37.5468
-36.8 75.9 44.6
69 53.4 59.6738
-44.425 50.9 37.539625
-9.5 49.9 29.0928
-42.65 49.4 36.1415
-42.85 50.9 36.3655
38.5 69.4 48.428
-42.65 50.9 36.1445
53 44.4 58.1496
-9 52.9 28.7982
-42.025 50.9 35.45533333
-61.5 17.4 32.251
-7 48.9 29.1256
52.8 45.4 54.442
18.5 34.4 35.1688
5.3 49.9 57.717
62.075 2.4 57.932875
-48.5 42.9 37.0922
37.9 72.9 47.72933333
-39.15 50.9 33.028
-20.50833333 46.4 26.54016667
-38.85 50.9 29.6955
-38.5 50.9 29.6664
31.35 25.9 34.169
-65 57.9 45.2582
-37 50.9 28.9976
-46 30.9 32.4486
66.05 47.9 59.781
-36 50.9 29.0474
-43.975 23.4 27.01466667
-37.5 47.9 31.94716667
-32.5 50.9 31.5716
12.35 69.4 56.16
-30 50.9 31.5654
54.5 41.4 61.8768
-29 50.9 31.5626
47.2 44.9 50.274
52.9 49.9 54.909
-27 50.9 31.5528
-61.425 58.9 45.0145
-26 50.9 31.549
2.9 61.4 60.36466667
-25.5 50.9 31.5638
-24.5 50.9 31.5464
-36.5 51.9 28.9688
-16.55 47.4 27.3785
-23 50.9 31.545
-22.5 50.9 31.5464
-21.5 50.9 31.5578
14.4 35.4 36.12033333
-49 45.9 42.9122
-46.2 77.4 20.496
-67 25.9 39.4644
-20.9 50.9 29.382
-20.8 50.9 26.535
-65.5 74.9 36.958
-52 73.9 35.2338
-52.5 26.9 37.6352
-20.5 50.9 26.548
-19.375 50.9 29.0105
-18.55 50.9 28.94025
-58 35.9 44.2512
-18 50.9 28.1138
-35.625 64.9 22.94475
-13.1 50.9 27.277
-49.65 29.4 38.7565
33.15 45.9 53.9515
-12.5 50.9 28.1138
64.2 27.4 65.316
-31 47.4 31.5884
-12 50.9 28.131
-20.8 51.9 27.879
-40.15 83.4 23.3285
-10.95 50.9 28.8825
-10.5 50.9 29.0646
-10 50.9 29.0658
-1 48.9 29.1534
-7.5 43.4 28.5504
58.5 44.9 61.935
-9 50.9 29.0706
72.6 53.4 87.452
-46 64.9 33.455
-59 58.4 45.1558
-6 50.9 29.0678
-5.5 50.9 29.0632
-5 50.9 29.0652
-55.7 74.4 36.709
66.6 32.4 63.81866667
-51.5 52.4 43.9726
10.05 32.4 37.147
-4.575 50.9 28.87225
-37.5 73.4 44.225
-45.5 68.9 30.858
-59.5 27.9 40.7068
65.5 46.4 60.51941667
-48.5 35.4 34.8266
-37.5 63.9 28.255
-36.35 58.9 26.996
-39.3 45.4 35.473
-40.05 57.9 43.345
2.7 50.9 46.316
-35.55 62.4 24.8035
3.1 50.9 48.625
-58.5 50.9 46.1866
-48 28.4 30.7052
3.2 50.9 49.199
-8 48.4 29.0506
47.5 50.4 56.335
3.4 50.9 50.345
3.5 50.9 50.917
72.9 53.9 91.853
42.9 53.4 51.954
-55.45 47.9 44.17775
-45.5 43.4 37.1788
3.7 50.9 52.057
-65.5 40.4 45.3954
4 50.9 53.761
4.1 50.9 54.328
66.5 33.4 63.2276
4.6 50.9 57.213
4.8 50.9 58.375
70.7 49.9 71.587
7 50.9 61.7188
-37.35 56.9 27.903
-50.5 20.9 32.3868
9.35 50.9 60.691
51 38.9 58.6182
-57 68.4 41.3364
-69 18.4 33.2688
-64.5 60.4 44.5934
65.6 14.4 67.68866667
36 50.9 51.926
-66 71.4 39.5676
36.15 50.9 52.7585
-63 79.4 32.859
-62 17.9 32.8024
36.5 50.9 52.7912
67.15 75.9 49.1235
38 50.9 53.3584
-62 29.9 41.7636
39.5 50.9 53.577
-45 40.4 36.5338
70.9 76.9 49.84466667
63.7 8.9 63.247
49.5 52.4 53.5702
41 50.9 53.6768
-38.5 62.9 30.7552
-33 50.9 31.5704
-3.5 45.9 28.9944
72.7 53.4 88.649
43.975 50.9 53.52616667
-65.5 14.4 28.4316
-36 76.9 42.358
45.5 50.9 53.2292
46.075 50.9 53.0515
69.5 41.9 64.13
46.5 50.9 52.855
-49.55 74.9 33.75
-39.5 55.4 43.9424
47.6 50.9 53.51
4.5 50.4 55.281
-14 54.4 28.496
-38.5 44.9 32.7816
48 50.9 55.729
48.1 50.9 56.3
48.5 50.9 58.523
-68.5 48.9 43.683
2.5 48.4 28.1318
65.3 20.4 71.457
40.9 14.9 38.38333333
-51.5 24.4 35.9028
-55 49.9 44.146
64 71.4 51.3006
-66 69.9 40.4454
64.3 60.9 50.952
49 50.9 61.306
58.5 52.4 56.9136
49.3 50.9 62.978
-19 45.9 28.9718
64.5 24.9 68.293
-63 75.4 36.6194
63.5 45.9 61.2126
49.4 50.9 63.536
52.7 51.4 67.648
46.8 49.4 61.011
41.9 13.9 38.342
41.55 45.9 42.766
53.55 57.4 55.67925
-69.5 60.4 43.7992
49.9 50.9 66.306
51.8 50.9 66.717
53.3 41.9 60.178
-54 76.9 32.562
-46.5 37.4 35.6328
-34.65 79.4 43.06
-59 70.4 40.1822
52.7 50.9 67.648
-49.975 39.4 42.99808333
52.95 50.9 67.825
-46.5 59.9 35.725
66.525 60.4 55.45333333
53.8 50.9 52.469
-63.025 58.9 45.02091667
54.2 50.9 53.821
55 50.9 54.3688
-33.5 54.9 30.9776
57 50.9 54.6584
-47 29.9 31.7884
65.7 61.4 54.099
-54.5 34.4 41.423
35 73.4 48.4778
58.5 50.9 54.3228
70.8 55.9 68.042
-34.3 47.9 30.672
-40.3 49.9 30.417
47 70.4 47.7526
-55 52.9 43.9252
58.1 75.9 56.951
-66.45 51.9 45.71325
-69 42.9 43.5072
59 39.9 61.8914
-23 52.9 31.3442
7.2 53.4 61.492
64.5 53.4 57.8966
58.5 63.9 50.3126
63.15 50.9 52.9115
63.45 50.9 52.88775
-41.925 28.9 31.0195
-9 51.4 29.0548
41 71.9 47.7366
64.55 50.9 55.841
64.95 50.9 56.8765
-55.7 80.4 31.157
65.15 50.9 57.2985
-7.5 60.4 26.3898
37.425 48.9 54.01475
7 63.9 59.87166667
-42.1 51.9 31.415
67.5 50.9 59.862
68.5 50.9 60.3534
-55 42.4 43.6478
-50 34.4 41.4014
68.975 50.9 60.51275
-54 62.4 41.7254
-17.5 44.4 28.555
22 28.4 34.364
69.5 50.9 60.6406
70.8 50.9 63.794
-70 26.4 39.7746
-59.5 46.4 46.2
32.7 44.9 58.039
71.1 50.9 67.634
68.525 75.9 50.02325
71.3 50.9 70.179
69.6 25.9 76.089
71.5 50.9 72.744
-66.5 19.4 34.2872
-70 56.4 43.7624
72.7 50.9 88.031
72.9 50.9 90.588
73 50.9 91.576
-47 68.4 31.2182
17.5 34.4 35.8452
-20.8 61.4 27.513
61.5 65.4 52.7302
-65.5 13.9 27.6898
42.5 49.4 53.341
-49.975 33.4 40.99016667
-52.5 78.4 30.9566
-51.5 13.9 23.1384
-60.5 10.9 22.7054
36.5 48.9 54.1024
-42.9 55.9 33.466
-44 23.9 27.7918
-69.5 51.9 43.7168
-37.8 41.9 31.513
-47 20.4 31.773
53.9 49.4 60.774
-65.5 77.4 34.8088
-68.5 51.4 43.7074
-68 51.4 43.724
57.5 38.9 60.635
-67.5 51.4 43.7912
7 66.9 59.765
67.5 49.9 60.2388
-66.45 51.4 45.72625
-40.9 50.4 34.37066667
-65.5 51.4 46.1232
-61.5 87.4 20.7496
4.05 34.9 36.3415
-41.5 30.4 31.82
-59.5 51.4 46.1662
-51 67.4 39.5014
64 44.4 62.8184
-43.475 35.9 35.01883333
-52 58.4 42.979
-56 51.4 46.14
-37.4 67.4 23.877
-52.5 51.4 44.0484
-25 45.9 31.4818
-45.525 60.4 35.51791667
-55.7 40.4 45.009
18.45 46.9 57.10775
-51.5 53.9 43.852
-47 51.4 37.4818
-45 51.4 37.5066
69 52.9 59.8494
-44.5 51.4 37.5104
60 40.4 62.4038
63.5 42.4 63.0964
-41.9 51.4 35.343
-64.5 63.4 43.6218
-40.35 51.4 33.4255
8.35 51.9 61.1045
-47.5 41.9 36.9142
57.5 35.4 51.6172
57.075 65.4 52.010125
-40.15 51.4 33.175
-39.9 51.4 32.92666667
-16.9 49.4 27.38766667
-38.425 51.4 29.532375
-36.525 51.4 29.01766667
-28 51.4 31.527
-27.5 51.4 31.5302
-27 51.4 31.502
41.6 14.4 38.66166667
-18.925 50.9 29.021125
-25 51.4 31.5252
-44.2 50.4 36.827
-24.025 51.4 31.51441667
3.55 60.9 61.20225
-22.5 51.4 31.5252
-5 59.4 26.7584
-20.575 51.4 26.51675
59.8 32.4 59.245
-20.1 51.4 26.52833333
-18.925 51.4 29.0205
-67.5 65.4 42.7616
-53 14.4 25.303
-1.5 45.9 29.0082
-67 32.9 43.0894
-18.5 51.4 28.9778
-7.5 50.9 29.056
-18.15 51.4 28.9425
-56 40.4 45.3716
-17.5 51.4 28.131
-2 57.9 27.4398
-50 15.4 24.7908
-15.925 51.4 28.02575
-15.5 51.4 27.9002
48 43.4 56.797
-48 59.9 35.7364
-12 51.4 28.1416
-57.5 32.9 43.1534
-58 63.4 43.5958
-69 88.4 18.012
-4.925 51.4 29.01725
55.5 8.4 47.826
-17.5 58.4 25.727
56.8 4.9 48.82
-56.5 23.9 38.182
-51 51.9 44.0048
-4 51.4 28.7376
-37.6 58.9 31.445
-47.35 79.9 16.2505
-0.15 51.4 29.0565
-38 52.9 29.1764
6 51.4 61.6216
70.2 55.4 59.945
7.5 68.4 59.0954
-55.45 49.9 44.17975
-49 41.4 36.8028
-56.5 27.9 40.7308
68.05 8.9 71.6655
4.125 65.4 59.51275
72.2 52.4 82.105
-32.5 50.4 31.6094
71.6 51.9 74.658
-52 66.4 40.0438
14.5 46.4 59.7606
-31 54.4 31.0544
58.5 34.4 52.759
58 67.9 52.8696
-11.9 57.9 26.393
-41.1 56.9 40.349
-59.5 24.9 38.8284
33 22.9 35.9612
-65.5 85.4 24.7482
-68.5 84.4 26.2774
67.8 71.9 51.993
-58 23.4 37.782
33.5 73.4 49.153
-33.5 48.9 31.6468
44 51.4 53.657
-41.45 22.4 24.3045
70.4 55.9 62.661
-62 37.9 44.8234
-60 46.4 46.2098
-32.475 59.9 27.52125
49.425 51.4 52.52975
0.5 47.4 28.8714
52.95 51.4 67.825
63.8 28.4 64.353
-37.9 59.4 31.50633333
-67 18.4 33.2708
-50 65.9 40.257
54 51.4 55.069
22 46.4 56.7628
-33.5 54.4 31.0926
57 51.4 55.627
48.5 11.9 42.763
3.95 38.9 32.2425
-56.5 48.9 46.2584
58 51.4 55.6762
58.5 51.4 55.7152
6 65.9 60.209
-58.5 35.4 44.0898
63.5 57.9 57.2162
45.65 45.4 45.9425
62 51.4 55.8794
62 42.4 63.3614
40 69.4 48.5522
-8.5 55.9 28.1128
17.45 29.9 36.35425
-26 55.4 30.8272
62 55.4 58.5314
64.55 51.4 55.8295
65.2 51.4 57.174
-47 26.9 29.496
8.6 66.9 58.89633333
-28.5 52.9 31.3274
64.575 2.9 62.49475
64.1 36.4 62.557
66.575 51.4 58.94425
-43.7 48.4 37.978
53.15 67.9 50.309
71.3 51.4 70.236
64.8 67.9 53.692
14.5 44.9 59.6908
-56 34.9 43.8742
-17.5 46.9 28.1462
67.5 51.4 59.6868
-55.7 31.4 42.002
68.025 51.4 59.99483333
69.425 51.4 60.53375
70.9 51.4 65.116
-15.5 52.4 28.1098
52.95 57.4 54.751
71.1 51.4 67.674
32.575 23.9 35.524875
-37.4 48.4 31.67
71.5 51.4 72.817
52.3 45.9 55.998
-10.8 46.9 28.668
-39.85 83.4 24.2245
72.8 51.4 89.478
1.4 66.4 46.821
-39.8 45.4 39.488
-44.5 53.4 37.3228
-70 51.9 43.7134
-67.2 51.9 43.865
50.05 8.9 43.81025
65.1 9.9 66.335
63.925 45.4 62.03075
-65.975 51.9 46.00658333
-65 51.9 46.1266
-67.5 34.9 43.3926
-64.5 51.9 46.1226
4 57.4 61.8596
-62 51.9 46.1126
-1.975 38.4 27.974
-41.85 56.4 39.618
8.45 36.9 33.09475
-49 77.4 31.622
-56.025 30.4 41.9675
-58 51.9 46.1524
-69 81.4 30.4952
-54.475 51.9 44.01741667
-36.5 69.9 17.2926
20 29.4 34.2782
-52.5 51.9 44.023
-43.2 79.9 26.892
-52 51.9 44.0264
62.85 39.4 61.6055
71.4 51.9 72.135
-49.5 51.9 43.23
-49.35 51.9 37.4995
-20.8 41.9 29.411
-42.8 51.9 34.435
52.925 38.4 52.544875
39 46.4 49.9392
6.8 63.9 60.12
-42.2 51.9 31.848
-41.8 51.9 30.119
-53.5 36.4 42.1754
-41.2 51.9 30.391
-40.2 51.9 32.404
-38.7 51.9 30.335
-66 59.4 44.8068
-38 51.9 29.3784
-37.65 51.9 29.263
-48.5 66.9 32.2946
-35.5 51.9 28.6844
-61 60.4 44.6042
-34.3 51.9 31.424
-37.6 67.9 25.458
-31.5 51.9 31.4596
57.5 53.4 57.337
70.3 17.9 73.518
59.85 4.9 53.3775
-67.2 47.9 43.804
-61 45.4 46.1212
-27 51.9 31.4638
-20.2 54.9 26.285
-26.5 51.9 31.4652
-53 58.4 42.9618
-51.475 23.9 35.51608333
-30 60.4 28.6652
-24.5 51.9 31.4862
-57 21.4 36.177
52.3 56.9 54.809
-55.45 28.9 38.92425
-43 44.9 38.286
-22.5 51.9 31.4946
-21.975 51.9 31.51875
-67 24.9 38.7966
-21.1 51.9 31.43766667
-20.5 51.9 26.4426
-55.4 63.9 41.187
50.3 38.9 57.699
-35.4 53.4 28.36366667
-58.925 9.9 20.515
-20.1 51.9 26.47133333
-44.5 72.9 27.2614
-67.15 41.9 43.704
41.55 15.4 39.15225
-19 51.9 28.9604
-18.5 51.9 28.9326
-43.5 42.9 37.09
-49.35 28.9 31.2725
-53.525 14.4 25.4975
-36 50.4 29.0708
-18 51.9 28.9044
34 49.4 53.857
-66.5 11.4 23.633
-46.65 79.4 19.2215
-57 65.9 42.5992
-56.5 31.9 42.7574
-16.5 51.9 28.1432
55.5 70.9 50.8114
-14 51.9 28.095
53.575 8.9 46.499375
-13 51.9 28.1246
-51.5 68.9 38.6772
-11.85 51.9 28.695
62.1 4.4 60.78466667
40.5 50.4 53.7618
-64 31.9 42.7002
-11 51.9 28.9728
-48 77.4 31.7662
-10.425 51.9 29.009625
-45.075 26.9 29.523875
-48.5 33.9 34.1526
-8 51.9 28.993
37.8 44.9 50.5
11 48.4 60.725
-7.5 51.9 29.036
42.8 41.4 52.513
-6.475 51.9 29.0155
-18.925 50.4 29.028
-4.5 51.9 28.6922
-4 51.9 28.7082
-27.5 58.9 29.7116
62 43.9 63.1954
-3.525 51.9 29.00925
-69.5 42.4 43.4986
-3 51.9 29.0474
53.05 74.4 51.51825
-2 51.9 29.0284
-58.5 52.9 46.0542
-67.5 11.9 24.5324
47.5 10.9 42.2832
66.575 33.9 63.4815
-1.425 51.9 29.020625
-1 51.9 29.0404
-55.8 9.9 19.552
-62.5 56.4 45.5452
50.5 10.4 44.2326
5.05 51.9 61.26675
6 51.9 61.7924
-64.5 50.4 46.211
-43.6 22.9 25.64866667
4.8 50.4 56.905
-42.5 42.9 36.32
-59 63.4 43.6396
9 48.9 61.3624
-35.5 46.4 28.7866
-27.5 51.9 31.4756
-51.5 65.9 40.2956
-18.3 50.9 28.139
65.8 47.9 59.478
68.025 10.4 71.49641667
-53 64.4 40.9388
65.05 26.9 68.80625
68.5 23.9 77.82
-36.5 68.9 18.8788
-42.4 51.9 32.716
-40.5 59.9 38.932
-35.05 49.9 29.3335
-66.6 44.4 45.472
-59.5 45.4 46.131
-65.5 24.9 38.803
-50.5 20.4 32.2238
42 51.9 53.4378
-12.15 47.9 27.267
-47 56.4 36.7334
-42.5 24.4 26.654
44 51.9 53.657
-59.5 14.4 28.5216
52.6 38.4 52.484
-64.5 48.9 46.2238
63.7 50.9 51.559
44.5 51.9 53.6276
-15.075 42.9 28.49
-10.925 57.9 26.47
70.8 25.9 89.639
-52 49.9 44.1254
-57.5 43.4 45.9076
-61 28.9 41.2588
-47 62.4 34.7198
58.5 55.4 57.8306
47.475 51.9 53.51733333
49.5 51.9 53.1142
65.5 70.4 53.0772
51.4 51.9 54.321
51.6 51.9 55.16566667
53 51.9 55.311
53.1 51.9 54.393
53.2 51.9 53.999
69.5 51.9 60.385
55 51.9 55.9226
56 51.9 56.1158
59.425 51.9 56.518625
60.5 51.9 56.649
66.6 30.9 68.304
-61.5 9.9 20.4702
-3 60.9 26.0548
39.7 15.4 37.227
-42.5 42.4 36.24
62.95 51.9 56.73
63.2 51.9 56.382
-65.5 27.4 40.387
63.6 51.9 55.87266667
17.35 29.4 36.16
64.5 51.9 56.093
65.8 51.9 57.911
-57.5 41.4 45.6316
-64.5 88.4 18.14
-37.3 83.9 30.267
66.05 51.9 58.21625
66.3 51.9 58.479
-57 69.4 40.7828
54.975 69.9 51.54625
66.8 51.9 58.967
37.5 50.4 53.6108
7.7 41.4 25.714
70.35 51.9 60.475
-49.5 55.4 42.872
70.8 51.9 64.647
52.925 56.9 52.996875
71.2 51.9 69.631
-36.025 41.4 27.99875
-49.5 23.4 33.912
71.5 51.9 73.394
-55.4 80.4 28.58966667
-46.9 45.4 42.018
12.5 31.9 37.2956
-4.4 56.9 27.331
72.7 51.9 88.336
-50.05 30.4 39.655
-5.075 59.9 26.413
-40.425 69.9 45.3595
-44 47.9 37.7092
-13.5 49.4 26.8456
58.075 41.4 62.508875
-18.5 53.9 28.6102
-51.5 74.4 34.8142
70.5 25.9 86.253
12.55 48.4 59.3945
5.8 50.4 60.947
-7.5 57.4 27.6366
-20.9 54.9 26.833
10 34.9 36.7568
71.5 41.9 64.1004
-69 33.4 43.1546
66.575 61.9 54.955875
-29.9 86.9 38.75833333
-67.5 52.4 43.7944
-67 65.4 42.7566
17.5 45.9 58.66
-51 67.9 39.233
-66.6 52.4 45.511
-66.4 52.4 45.70066667
-64.5 52.4 46.098
68.525 10.9 71.52283333
36.7 42.4 49.766
35.575 47.4 54.047625
-59 52.4 46.098
62.05 61.9 44.29775
-58 52.4 46.1116
-56.5 52.4 46.123
-53.5 52.4 43.965
-52 52.4 43.9456
-26 59.9 29.2924
64.975 24.9 69.472
55 67.4 52.2512
49 52.4 53.631
-55.7 51.4 45.875
-48.5 52.4 37.433
65.5 19.9 71.8926
-49.5 70.9 30.875
-67 87.9 19.3738
4 47.9 27.5942
-47 52.4 37.383
-55 15.4 26.8092
67.525 62.4 55.51591667
-46.5 52.4 37.375
69.5 55.4 59.3002
-44.15 52.4 37.113
30 72.9 50.3104
-67.475 38.4 43.51216667
70.5 52.9 61.419
-20.65 44.9 30.618
-57 16.9 31.7616
4.9 50.4 57.446
25 45.9 56.4298
-39.2 46.4 36.119
51.9 8.4 45.44
-43.9 52.4 36.83133333
-38.7 52.4 32.999
6 52.9 61.9384
-58.5 15.9 30.5678
-26 45.9 31.4818
-38 52.4 29.2348
68.075 49.4 60.496875
-37 52.4 28.9108
-47.5 52.4 37.4172
-49.7 41.4 43.175
-35.15 52.4 28.391
-51.5 37.9 42.6254
66.15 9.9 68.1555
-34 52.4 31.392
62 31.4 62.1698
-33 52.4 31.442
-32.5 52.4 31.4518
52.85 51.9 55.6425
-31.5 52.4 31.4312
-45.5 32.4 33.3118
-60 19.9 34.8032
-28 52.4 31.4476
-67.2 48.4 43.811
-27 52.4 31.3938
-49.65 74.4 34.401
-36.9 41.4 27.98533333
-26 52.4 31.4152
-23.5 52.4 31.4042
-23 47.4 31.603
-22.5 52.4 31.4286
-49.5 29.4 38.417
-20.5 52.4 26.3418
26 28.4 34.2008
-35.4 52.9 28.39
-20.05 52.4 26.407
-17.5 52.4 28.7858
-17.05 52.4 28.66325
-37.4 74.9 44.893
-16.5 52.4 28.1764
14.5 73.4 55.3922
30.575 71.9 50.028125
-15 52.4 28.1096
71.7 54.4 77.6
9.5 70.4 57.966
-10.5 60.9 25.6604
42.6 14.4 39.59866667
-14.5 52.4 28.1156
-13 52.4 28.1886
-43 33.9 34.1108
-12.65 52.4 28.407
15 34.9 36.3024
-10 52.4 28.913
-48 55.4 36.9966
-61.5 21.9 36.526
-35.5 66.4 21.392
-26.5 47.9 31.611
-4.1 52.4 28.43866667
-66.5 63.4 43.594
59.575 54.4 58.008375
-61 73.9 37.785
-3 57.9 27.4672
-3.85 52.4 28.8055
-63.5 38.9 45.082
-65.5 47.9 46.1812
52.95 43.9 58.8155
-61 35.4 44.0852
-63.5 45.9 46.141
-27.5 53.9 31.1452
-4.65 49.4 29.1305
4.65 52.4 61.143
-38.65 83.4 27.794
-9.5 59.9 26.586
6 52.4 61.885
-67.2 50.4 43.816
54.35 49.4 59.7335
-53.5 29.9 39.3906
8.2 52.4 60.963
-31.025 51.4 31.52183333
68.5 72.4 52.2178
-53.5 57.4 43.1694
51.2 52.4 54.331
32 47.9 54.7108
5.5 53.4 61.9458
-70 70.9 39.8634
47 66.9 47.6904
-54 59.4 42.7118
-52 28.4 38.5992
71 74.9 51.935
72.5 54.9 89.812
46.075 12.9 41.49
59.15 8.4 50.728
40.975 18.4 37.99775
-61 43.4 45.9036
42 52.4 53.0942
-61 79.9 32.3242
53.55 50.9 52.8015
-11.975 49.4 27.97133333
44.025 52.4 53.5245
-58.5 81.9 29.9808
65.5 43.9 63.3128
8 50.4 61.599
-59 52.9 46.052
45 52.4 53.6604
45.5 52.4 53.656
46 52.4 53.67
-50.5 25.9 36.9938
48 52.4 53.6686
-40.5 64.9 46.1392
51.475 52.4 55.49358333
-20.1 47.9 26.60966667
51.4 51.4 54.571
52.85 52.4 55.3595
38 48.4 53.792
53 52.4 54.721
-35.7 32.4 23.226
-36 64.9 23.1992
53.2 52.4 53.999
-65.5 63.9 43.4008
53.5 52.4 53.8326
19 73.4 53.8888
55 52.4 56.3452
55.5 52.4 56.4446
-20.5 42.4 30.314
-60 51.9 46.1386
60 52.4 57.108
63.15 52.4 56.894
6.7 41.9 27.197
-57.425 12.4 25.515
2.2 50.9 43.44
-38 65.4 29.7372
-64 82.9 28.6156
-15.5 53.9 28.616
-67.075 61.4 44.02925
66.15 52.4 58.1165
64.15 8.4 65.2565
68.05 52.4 59.62
-16 58.4 20.906
68.975 52.4 60.027
66.5 8.4 69.25933333
69.5 52.4 60.2048
-20.5 44.4 30.7054
50.5 70.9 43.75
70.425 52.4 60.397
71.3 53.9 72.141
-5.5 49.9 29.107
-55.7 65.4 42.182
71 52.4 66.91
64.1 59.4 52.378
-22.5 48.4 31.61
-11.5 60.4 25.1926
62.8 8.9 60.291
-49.4 55.9 38.559
71.1 52.4 68.171
21.5 31.4 33.2676
-56.5 26.4 39.844
-9 56.4 27.9806
-66 29.4 41.45
62.7 40.4 62.449
-54.5 76.4 33.0074
71.2 52.4 69.434
-12.55 48.9 26.83675
71.6 52.4 74.538
-44.05 20.9 24.791
71.8 52.4 77.065
-59 33.9 43.5124
42.3 14.9 39.504
-67.125 55.4 44.018
60 53.9 57.9678
54.5 53.9 56.8952
72.3 52.4 83.376
-39.9 70.4 45.75566667
-55.7 79.9 31.738
64.5 40.4 62.3594
-67.5 25.9 39.4684
-58 11.9 24.5558
-16 43.4 28.59
13.1 69.9 55.84666667
70.4 55.4 62.568
6.65 56.4 60.818
25.5 45.9 56.3174
-6 66.4 21.7368
69.475 71.4 53.03066667
-49.65 52.4 43.6535
31 43.9 54.978
-49.8 84.9 18.539
-0.55 55.9 28.16025
-27 59.4 29.533
-49 27.9 30.3752
-62 53.4 45.9552
-26.5 50.9 31.5258
12 45.4 60.0706
59.5 40.9 62.5766
-68.5 62.4 43.8298
13.5 32.4 37.2738
26.7 71.4 50.409
-2.5 58.9 26.6976
-39.4 42.9 33.385
-58 67.4 41.8572
52.5 10.4 44.8848
63.1 32.9 59.76433333
-70 52.9 43.7278
54 68.4 51.8876
31 45.9 54.8654
-49.7 40.9 43.118
-67.5 52.9 43.7992
-10.7 47.4 28.485
-17.6 47.9 28.10866667
62 51.9 56.825
-48.55 44.4 40.2885
-65 52.9 46.0498
68.05 43.4 63.362
-67 21.9 36.495
-63 52.9 46.0488
-50.05 21.4 33.31125
-12.5 55.4 28.276
-62 52.9 46.0312
-26 44.9 31.3934
-4.6 52.4 28.79333333
-61.5 52.9 46.01916667
-56 74.4 37.34
5.1 50.4 58.527
-60.5 52.9 46.036
-2.5 50.9 29.1056
66.7 74.4 50.11
-54 52.9 43.9248
55.2 74.9 55.946
-57 36.9 44.5618
-29.925 44.9 30.9995
-53 52.9 43.9052
-49.35 52.9 37.3515
28.975 25.9 35.0105
-49 52.9 37.3572
-14.5 53.4 28.6144
69.15 76.9 49.604
-48.5 52.9 37.3316
-47.5 52.9 37.361
-47 52.9 37.3396
50.5 66.4 48.2102
-45.5 52.9 37.3476
-39.9 44.9 39.521
-18.5 44.9 28.8528
-70 50.4 43.6876
-44.5 52.9 37.3326
65 64.9 52.918
-43.7 52.9 36.914
-43.2 52.9 37.207
-60.5 55.9 45.622
63.95 23.4 65.8205
-53.5 40.9 43.3482
-65 80.4 31.7154
-38.65 52.9 32.9925
-37.575 52.9 28.999
65 48.9 57.821
-3.925 55.9 27.9645
-35.65 52.9 28.6785
-35.1 52.9 28.28933333
-69 66.9 42.0692
-65.5 50.4 46.1738
-31 52.9 31.3082
-30.5 52.9 31.2992
-41.65 48.9 33.1615
70.8 50.4 66.73
-29.5 52.9 31.2912
23.025 45.4 57.03483333
7.3 36.9 34.158
-60.5 40.4 45.4232
56 64.4 50.9324
-36 34.4 25.0052
-56.025 81.4 30.42466667
-52.5 61.9 41.9128
-25.5 52.9 31.3136
44.3 13.4 40.502
-54.925 84.9 20.50875
58 6.9 50.7142
-24.5 52.9 31.3424
-22 52.9 31.305
-20.05 52.9 26.28775
38.5 68.9 47.7202
-43.1 51.9 35.695
-19 52.9 28.8062
32 44.4 53.511
-13.925 52.9 28.410625
70.6 51.4 61.222
-13 52.9 28.7072
-64 39.9 45.3406
-11 52.9 28.7958
-66.5 18.4 33.2824
53.6 46.4 57.77133333
4.2 65.9 59.239
-10.5 52.9 28.8086
56.025 51.4 55.51875
-38.9 62.4 30.96366667
15 34.4 36.5902
-10 52.9 28.8214
-6.5 52.9 28.8152
-56.5 23.4 37.7958
-3.85 52.9 28.6655
60.85 63.9 50.4685
-2.5 52.9 28.8286
-2 52.9 28.8116
-37.35 79.9 41.886
-41.2 79.9 32.017
-68.5 13.4 26.9408
11.8 48.9 59.601
-0.5 52.9 28.8584
7.425 52.9 61.459375
43.15 49.4 52.458
-60.5 23.9 38.1414
7.8 52.9 61.114
-41.3 56.4 40.661
-40.475 62.4 46.03016667
65.9 52.9 57.85966667
-15.5 54.4 28.5252
59.5 43.9 62.6922
5.15 67.4 58.711
-65 80.9 31.14
43.075 52.9 52.989875
-3.5 52.9 28.8092
42.5 44.9 53.1104
47 52.9 53.7142
51.1 52.9 55.263
52 52.9 55.822
-35.6 52.4 28.71533333
65.6 47.4 59.4105
-58 12.4 25.4756
51.6 51.4 55.16566667
-5 52.9 28.8522
53.15 52.9 54.4045
-50.1 11.4 17.26266667
72.7 51.4 88.193
54.475 52.9 56.51825
26 43.9 55.3302
41.5 18.4 37.7998
-61 74.9 37.0082
-55.5 25.4 36.678
-58.5 43.9 45.9848
45.45 15.9 39.2405
-4.6 52.9 28.76433333
1 66.4 45.209
56 52.9 56.9098
59.85 31.9 59.155
-61.5 59.9 44.7852
57.5 52.9 57.0972
-61.5 43.4 45.8938
-26 57.4 30.2574
58.5 52.9 57.2718
-64.5 36.4 44.3934
59 52.9 57.3458
-17.5 50.9 28.099
59.5 52.9 57.4114
46.475 67.9 48.52108333
-65 62.4 43.9678
10.475 46.4 60.52175
-58.5 10.9 22.73
62.5 52.9 57.8582
-41.3 26.4 28.105
12.5 36.4 35.9148
67.3 52.9 58.971
-50.5 54.4 43.7606
-59 22.9 37.4236
-3 50.9 29.0988
67.55 52.9 59.1165
-45.8 78.4 21.725
68 52.9 59.3914
68.5 52.9 59.659
-56.075 58.9 45.00875
-32 50.9 31.5722
54 42.9 61.7644
-65 61.4 44.3038
-48.425 25.9 28.4055
-43.65 78.9 26.874
66.3 8.9 68.86
69.525 52.9 60.01475
-39.9 31.4 29.76
-38.85 46.9 32.9415
-58.5 54.4 45.885
-20.5 54.4 25.8952
32.5 71.4 49.543
70.35 52.9 60.1295
-54.5 76.9 32.5418
52.9 57.9 54.34833333
71.1 52.9 68.84
71.3 52.9 71.307
-27.5 50.9 31.5682
7.5 49.4 61.381
44.9 44.9 42.986
-38 50.9 29.5538
-37 55.4 28.3822
-15.5 49.4 26.278
71.5 52.9 73.792
71.6 52.9 75.041
71.9 52.9 78.706
72.4 52.9 84.864
40.2 41.9 50.125
-58.5 69.4 40.7638
72.5 52.9 86.089
-68 30.9 42.2376
6.9 55.4 60.92516667
-15.5 43.4 28.6104
54.5 69.9 51.383
72.7 52.9 88.544
72.8 52.9 89.778
-66 43.9 45.8184
-37.2 67.4 22.089
-16 55.9 27.2708
54.5 56.9 56.4086
-55.45 50.4 44.15725
-49 41.9 36.9038
24.95 25.4 33.319
-62 17.4 32.249
-57.5 44.4 46.0298
-55.4 78.9 30.36933333
-47.5 31.4 32.7424
-45.85 21.4 26.9695
-38.1 66.9 29.05433333
-67.075 53.4 43.9875
-54 49.9 44.1102
0.9 66.4 44.805
-64 53.4 45.9972
-22 54.4 31.0628
-63 53.4 45.9836
63 45.4 61.8396
59 5.4 52.2918
-68.5 47.4 43.6538
-49.35 36.4 35.236
-6.5 53.4 28.7528
42.5 70.4 48.5574
-6.5 43.4 28.5022
65.35 72.9 48.74
-53 43.9 43.8154
-56.025 72.9 38.46716667
-62.5 53.4 45.9652
-47.5 76.4 32.7838
-14 49.9 27.144
-60.5 53.4 45.9862
-60 53.4 45.9844
63.8 27.9 64.716
-59 53.4 45.9828
-61.5 75.9 36.214
-57.075 22.4 37.01425
-46.7 23.4 31.117
-5 44.9 28.8044
-54 53.4 43.8608
-61.5 35.9 44.2664
50.2 38.9 56.352
-52.5 53.4 43.8416
67.525 51.9 59.50425
-50.5 53.4 43.8644
-41.5 60.9 45.8834
-49.7 53.4 43.65
-49.3 53.4 37.309
53.6 48.9 58.968
-48 53.4 37.2642
-47.5 53.4 37.2758
-17.5 43.9 28.7952
-56.5 44.9 46.1146
-47 53.4 37.3016
-60.5 22.4 36.9372
-45.5 53.4 37.2902
-48 75.9 32.9354
-57.5 49.9 46.2388
67.5 25.4 70.2914
52 9.4 45.4044
-43.9 53.4 36.882
-53.025 17.4 28.51783333
-10 50.4 29.1076
-54 80.4 28.594
-43.45 53.4 37.13975
62.55 7.9 61.3065
-43.2 53.4 36.61
-49.4 44.4 42.791
-61.5 17.9 32.7964
-47 25.4 28.163
-56 24.9 38.7308
26.8 71.4 50.162
-5 51.9 28.9708
-38.5 53.4 29.3406
-38.1 53.4 29.15633333
5 62.4 61.3458
-30 55.9 30.7014
64.85 50.4 56.878
39.5 43.4 51.817
56.5 41.4 62.292
-35.65 53.4 28.6155
-67 65.9 42.5504
-34.3 53.4 31.227
-62.5 37.9 44.8074
-34 53.4 31.2468
67.65 10.4 71.1185
37.5 44.9 50.2196
64.8 14.9 65.921
-63 45.4 46.1048
-32 53.4 31.2446
64.8 9.9 65.215
-66.45 47.4 45.77675
-31 53.4 31.2262
-53 83.9 23.1634
47.7 50.9 54.062
-50 69.4 38.3356
-53 33.4 41.0488
-29.5 53.4 31.2704
28.45 73.9 50.61275
49.15 66.4 47.6175
-25.5 53.4 31.2204
68.5 13.4 70.6412
3.35 57.4 61.464
-16.3 59.4 23.017
-45 52.9 37.355
70 14.9 71.0888
-55.7 31.9 42.362
-17 53.4 28.7162
-36.5 39.4 27.2514
1.475 47.9 28.50691667
43.075 13.4 39.439375
33.45 44.9 56.739
39 51.4 53.1256
4.6 37.9 31.683
-16 53.4 28.6666
-15 53.4 28.6158
-56.5 15.9 30.5912
-4 42.9 28.3664
-49.35 58.9 36.093
-13 53.4 28.7072
70.45 15.9 71.83175
23.5 45.4 56.9128
-10 53.4 28.733
64.55 14.9 65.4305
6.1 49.9 60.805
-9.5 53.4 28.7102
-41.925 31.9 33.021375
-7.5 53.4 28.7182
-46.35 47.9 37.5995
9.95 69.9 58.10875
-22.975 46.4 31.52416667
64.1 35.9 61.616
-7 53.4 28.7108
-3.925 43.9 28.52875
64.7 61.9 51.246
-5.5 53.4 28.756
64 55.9 58.3782
-3.5 53.4 28.696
-64.5 62.4 43.9636
-29.425 59.4 29.491125
-2.5 53.4 28.7298
49.925 54.9 53.53575
-36 53.4 28.725
-0.5 53.4 28.7724
-22.5 43.9 31.2236
-24.5 60.4 29.0404
-33.7 46.4 29.491
6 53.4 61.9842
-7 43.9 28.654
-33 45.4 30.318
6.5 53.4 61.8946
7.7 53.4 60.965
31.525 42.4 50.50641667
70.6 52.9 62.66
61 52.4 57.2868
63.5 31.4 60.33
47.55 43.4 56.60675
-49.3 72.4 33.918
65.7 14.9 68.629
-51 17.4 28.6288
-52 80.4 28.6084
-12.5 56.4 27.055
-47.5 36.4 35.2544
67.3 7.9 70.42
-53.5 65.9 40.2794
-64.5 14.4 28.4382
51 9.9 44.6526
-58.5 54.9 45.8198
52.5 47.9 59.233
-55.45 76.4 33.06325
-8.9625 43.4 28.519125
6.9 38.4 33.195
-47.5 45.4 42.2692
43.6 53.4 52.60433333
2.4 49.9 43.109
44 53.4 52.8
-68.5 14.9 29.1196
45 53.4 53.1652
49.8 50.9 65.764
46.425 53.4 53.532375
66 41.9 63.4514
-57 16.4 31.203
65.575 33.4 62.44
-28.5 60.4 29.0172
-49 51.9 37.4518
65.15 31.4 67.113
47.5 53.4 53.739
48 53.4 53.7658
48.5 53.4 53.7908
37.5 41.4 47.3906
36 20.9 36.7618
49 53.4 53.8304
64.5 9.4 65.218
-67.5 87.4 20.6026
45.5 43.4 55.6468
-51.5 15.4 25.3822
49 68.4 48.8374
-53 51.9 44.021
51 53.4 55.395
25.5 43.9 55.6216
11.5 36.4 35.945
8 43.4 28.4756
61.5 31.9 62.2128
6.575 41.4 23.42675
-44.5 38.4 35.9728
-59 80.9 31.1874
55.475 53.4 57.02316667
-45 37.4 35.6362
58.525 53.4 57.50591667
-18 52.4 28.8984
-61 66.4 42.358
40.35 41.9 50.383
22.425 75.4 51.513125
-42.1 45.9 39.769
58.6 4.9 51.70233333
59 53.4 57.6066
-7 52.4 28.9174
-55.4 76.9 32.58666667
-31.5 49.4 31.6312
5 43.4 28.2748
64.5 72.9 46.063
61 53.4 57.9564
-58.5 27.9 40.7376
-35.7 67.9 19.948
-66.6 52.9 45.462
-22 43.4 31.0902
61.425 53.4 58.026
-43 27.9 30.2946
-51.5 71.4 37.0844
65.425 53.4 58.0335
-49.65 26.9 37.197
54.575 41.9 61.9835
-55.4 60.9 42.28666667
66 53.4 58.1402
43.85 45.9 42.0645
24.575 26.9 34.499125
-12 52.4 28.8068
66.5 53.4 58.341
-56 43.9 45.9192
67.575 53.4 58.9705
68.575 53.4 59.502875
-44.575 21.9 25.92225
-31.975 51.4 31.52166667
57.5 64.9 51.6672
-48 51.9 37.4724
61.5 42.4 63.2878
70.9 53.4 67.084
-60 66.9 42.1066
-69 16.4 31.0874
64 63.4 43.593
-13 42.9 28.3752
-55 53.4 43.8682
-45.4 20.9 26.347
-69.5 77.9 34.238
47 41.4 56.275
-43 25.4 28.08
45.05 45.4 44.228
71.7 53.4 76.698
59.025 56.9 57.5275
-69.5 57.9 43.7858
55 39.9 61.3678
5 36.4 36.3434
47 12.9 41.8562
52.025 68.9 51.00383333
17.925 45.9 58.473875
69.3 49.4 65.729
-59.5 72.9 38.534
66.6 4.9 67.18633333
-68 53.9 43.7744
-67.075 53.9 43.99
-64 20.4 35.2184
-33 48.9 31.6628
6.5 53.9 61.816
-68.85 9.4 19.0885
-66.35 53.9 45.635
-13.5 53.4 28.657
45 70.9 47.6204
-66 53.9 45.8082
-65.5 53.9 45.8952
-65 53.9 45.9236
25.65 71.4 50.6885
-61.5 53.9 45.9434
1.5 66.4 47.222
-2.65 38.9 27.438
70.8 25.4 89.551
32.5 21.9 35.8758
-61 53.9 45.936
5.5 34.4 36.806
-57.5 56.9 45.4666
-49.5 66.4 34.029
-47 49.4 43.5644
-54.1 21.4 32.88766667
-69.5 47.9 43.6512
-67 10.9 22.6136
-38.5 60.4 31.6414
-60 53.9 45.9318
-63 43.4 45.8776
-15.5 50.9 27.2708
-55.7 64.9 42.564
-59.5 53.9 45.934
-59 53.9 45.9188
-31.5 50.4 31.6106
-43.5 29.4 31.4098
-52 26.4 37.2886
-57.5 53.9 45.9428
64.4 9.9 63.168
-51 68.4 38.9574
-55 53.9 43.8476
-62.5 19.9 34.7928
-63.5 77.4 34.8324
-54.5 53.9 43.8456
66.4 59.9 55.42833333
-50 53.9 43.7974
-49.7 53.9 43.626
-49 53.9 37.2462
-59 25.9 39.499
21.425 44.4 57.495125
-17.5 57.4 26.5824
-3 45.4 28.9066
-48 53.9 37.24
63.85 8.9 63.7855
-45 53.9 37.209
56.2 35.4 50.98
-14.15 47.9 19.7135
-68 37.4 43.4048
50 68.4 48.932
4.5 57.9 61.9542
56.3 5.4 48.97
-44.5 53.9 37.1748
-40.3 53.9 32.459
-56.05 17.4 32.22225
-39 53.9 32.604
-38.6 53.9 32.85166667
-34 53.9 31.1814
64.05 63.9 52.681
-33.5 53.9 31.1946
69.5 43.9 63.388
-32 53.9 31.1528
52.4 53.9 56.108
64 33.4 61.4026
-21.5 58.4 29.8944
-31 53.9 31.1584
50.05 39.9 59.429
-46.5 42.9 37.0598
59.5 56.9 57.6378
52 48.4 61.036
-52.5 58.9 42.8426
-53 85.4 19.878
-30.5 53.9 31.141
65.8 15.4 68.89
-35.8 83.9 34.638
-61.5 51.4 46.1422
-53 65.9 40.2964
55.525 59.4 53.96891667
-29 53.9 31.1524
0.5 49.4 28.6734
-27 53.9 31.1444
-26.5 53.9 31.1712
-64.5 12.9 26.1972
-49.6 40.9 42.919
65.4 14.9 67.845
-50 74.4 34.7424
61.65 59.4 54.987
9 49.4 61.3942
-23 53.9 31.1512
66.7 12.9 68.357
-18 53.9 28.596
-44.5 57.9 36.3702
58 46.4 59.8556
35.5 72.9 48.8076
-36 39.9 27.6484
-37.3 48.4 31.343
62.925 39.9 61.904625
71.4 51.4 71.524
-24 56.9 30.3812
-16.5 53.9 28.6336
-16 53.9 28.645
49.55 9.9 43.68675
63.1 52.9 57.38433333
69.3 26.4 79.88
-14 53.9 28.5946
62.85 30.9 60.252
-10.6 59.4 25.74533333
48.6 50.9 59.079
-11.5 53.9 28.615
-37.4 69.9 44.07066667
-9 53.9 28.6622
-57 11.9 24.638
-54.5 38.4 42.79
-8.5 53.9 28.6406
-54 18.9 30.4028
-6.5 53.9 28.651
53 53.9 54.8058
-5 53.9 28.685
7.85 48.4 60.9835
-4.7 53.9 28.707
68.55 75.4 51.1075
-60 43.4 45.9258
57.5 68.9 52.6444
58 36.4 52.882
63.625 60.9 49.41525
-4.45 53.9 28.26625
-6.5 50.9 29.0518
-2.5 53.9 28.6216
-40.15 70.4 45.405
-29.5 55.9 30.6852
44.075 42.4 54.96275
-1 53.9 28.621
-63.5 29.4 41.4502
-68 60.9 43.8488
-0.5 53.9 28.6346
3.7 53.9 60.794
-7 61.4 25.4252
47 69.9 48.255
-13 43.9 28.6564
67.8 12.4 70.496
3.8 53.9 60.988
5 53.9 61.898
54 66.4 51.762
-15.5 47.9 18.6916
-48.975 19.9 31.516
-65 58.9 45.0674
-37 47.9 29.1352
7.025 53.9 61.48175
62.5 8.4 60.159
51.9 72.4 48.42266667
7.35 53.9 61.146
4.7 33.9 36.53
0 45.4 28.9
62.4 8.4 59.775
44.5 69.4 48.679
7.55 53.9 60.8535
14.5 71.4 55.7604
68.925 44.4 63.0545
-14 47.9 20.277
-55.85 11.4 23.2595
-21.5 55.4 30.8218
-42.45 79.9 28.8195
-65.5 26.9 40.0964
44.5 53.9 52.428
49.9 12.9 41.77966667
45.5 53.9 52.8886
-35.1 50.9 28.60333333
-14.6 58.9 19.95933333
65 2.9 62.7948
64.5 35.4 63.035
64.8 51.4 56.311
48.5 53.9 53.7388
49 53.9 53.8284
-54 50.9 44.0618
-54.5 56.4 43.4176
50 53.9 53.9186
3.35 36.4 33.887
51.1 53.9 55.789
-40.1 33.9 31.336
-20.5 54.9 25.8408
54 53.9 56.7584
1.9 38.4 30.92
54.925 53.9 57.024
-57 34.9 43.917
62.5 65.4 52.8734
57.525 53.9 57.51033333
-62 59.9 44.763
-26.5 44.4 31.2306
59.5 53.9 57.8644
59 58.4 56.248
-61 19.4 34.321
60.425 53.9 58.041375
61 53.9 58.1518
62 53.9 58.3384
-54 27.9 38.2876
62.5 53.9 58.2774
63.575 53.9 58.0055
-60.5 45.9 46.1524
64 53.9 58.0902
45.8 45.4 46.367
66 53.9 58.4284
64.15 37.9 59.867
65.85 34.9 64.9025
-43.1 40.9 36.65433333
26.45 71.4 50.60825
68.5 53.9 59.303
57.525 41.9 62.52
70.3 53.9 59.689
70.7 53.9 64.586
68.15 76.4 49.369
-55.4 62.9 41.58033333
71.1 53.9 69.615
-67.2 47.4 43.804
-17.15 48.4 27.626
72.2 53.9 83.462
-67.2 45.9 43.751
72.7 53.9 89.747
-45 58.9 36.0414
4.4 50.9 56.042
-50.5 64.9 40.732
-50.05 32.4 40.6215
6.5 68.4 58.7978
-49.5 48.4 43.812
2.7 60.9 60.233
-18 49.9 28.1248
-65 64.4 43.1756
40.5 51.9 53.0966
62.425 2.9 58.4785
2 66.4 49.227
59 71.9 49.6354
13.6 70.4 55.85233333
-54.5 50.9 44.0742
-29.5 47.9 31.6196
-69.5 54.4 43.7442
-67.15 54.4 43.96
-66.45 54.4 45.454
-56.5 11.9 24.673
68.1 23.9 75.649
-66.3 54.4 45.598
-37.6 43.4 30.935
68.5 16.9 71.6488
-62 19.9 34.7966
-11.95 45.4 28.78675
-63 77.4 34.838
-44.85 78.9 23.834
-60 44.9 46.101
-68 9.4 19.1466
-65.5 54.4 45.8304
-36 52.9 28.817
-49.65 28.4 38.151
-64 54.4 45.8844
66.3 11.4 66.9
-62.5 54.4 45.8602
-24 51.9 31.496
-64 13.4 26.973
-61 54.4 45.8668
-69.5 38.9 43.388
63 71.9 51.1362
-55.45 54.4 43.8125
-54.5 54.4 43.8058
-28.5 50.9 31.5554
-53 54.4 43.7988
-33.8 58.9 29.687
-52.5 54.4 43.7958
-49.5 24.9 34.864
-52 68.9 38.6842
-33.95 46.9 30.2675
39 50.9 53.5378
-9 61.4 25.3374
49 43.9 56.7208
58.475 45.4 61.52616667
-49.55 54.4 43.19
-63.5 18.4 33.2868
-66.4 52.9 45.657
-49.35 54.4 37.227
67.5 35.4 66.0552
-39 51.4 29.6006
-47 54.4 37.1702
-44.6 54.4 37.132
-44 54.4 36.6505
-54.5 64.9 40.7314
-54 32.4 40.6356
-1 48.4 29.1542
-54.5 58.4 42.9636
-44 54.4 36.42933333
-40.45 54.4 32.32375
-63.5 23.9 38.1376
-37 54.4 28.6348
37.9 41.9 47.537
-36.5 54.4 28.5666
-7 59.4 26.6678
-62 48.4 46.237
54 52.9 56.3816
7 42.4 28.4888
-54.5 55.4 43.583
47.5 42.9 56.7786
-25.5 46.9 31.5584
-34 51.4 31.4934
47 44.4 55.233
37 69.4 48.0936
-65.5 78.9 33.331
-29.5 54.4 31.069
-29 54.4 31.0488
-59.5 81.9 29.9964
-27.5 54.4 31.0848
-26 54.4 31.0752
-21.5 54.4 31.0796
-19.35 54.4 28.431
-63.5 60.9 44.4538
-17.475 54.4 28.51608333
-15.975 54.4 28.51241667
57.5 60.9 42.197
-13.5 54.4 28.5276
24 72.4 51.6418
-35.7 69.4 17.882
-13.075 54.4 28.52025
-9.5 50.4 29.0998
-9.975 54.4 28.51641667
-7.5 54.4 28.489
-69.5 28.9 41.213
53.5 42.4 61.6694
-1.85 66.4 33.729
-5.5 43.4 28.4534
38.15 41.9 48.7725
-70 35.9 43.2992
60.5 51.4 55.8386
-6.075 54.4 28.518875
-5.075 54.4 28.50575
-46.5 53.4 37.316
50.85 53.4 54.034
32.2 42.9 51.272
-4.5 54.4 28.2506
-66 28.4 40.9492
-49.65 64.4 40.6325
-4 54.4 28.2318
-64 88.4 18.1496
9.55 46.9 60.62675
-64 78.9 33.3486
-3.5 54.4 28.4906
-2.5 54.4 28.5134
-67 81.9 29.9038
65.15 49.9 57.6505
-65 72.4 38.8834
-22.5 47.4 31.6012
-1.5 54.4 28.5106
-34.85 55.4 28.912
-49.65 68.9 38.3085
-1 54.4 28.5134
66.05 65.9 53.4315
-18.05 56.4 27.96375
-0.5 54.4 28.5336
20.3 33.4 34.723
4.5 54.4 61.7738
5.075 54.4 61.991375
62 38.4 58.3482
7.3 54.4 60.907
-4.1 46.4 28.477
57.5 64.4 51.174
-19.55 44.9 29.253
6.5 54.4 61.7096
45.075 41.4 54.98275
38.85 41.9 47.7975
-56.5 74.9 37.0374
-35 46.4 28.7812
45.7 54.4 51.036
-59 15.9 30.56
-21.5 57.9 30.1024
48 54.4 53.3494
48.575 54.4 53.500375
50.8 54.4 54.051
-46 72.9 27.2306
50.9 54.4 55.432
36.5 49.4 54.0518
-52 53.4 43.882
51.1 54.4 55.90533333
63.9 35.9 60.007
52.35 54.4 56.167
72.3 51.9 83.355
54.575 54.4 57.008125
53.9 10.4 44.898
-53 10.9 18.2208
-27.5 57.9 30.0744
-37.4 42.4 29.834
56.975 54.4 57.52875
63 54.4 58.1202
64 54.4 58.2634
-52 22.9 34.278
13.95 71.9 56.11775
64.5 54.4 58.3604
-60 18.4 33.3224
-42.95 79.4 28.307
65.475 54.4 58.526
-46.625 22.9 31.47325
67 54.4 58.8604
-41.6 32.4 33.18066667
49.475 10.9 43.53866667
-66 30.4 41.9812
-62.5 11.9 24.5112
67.5 54.4 58.9496
70.6 50.9 61.219
-62 24.4 38.4966
68 54.4 58.9666
68.5 54.4 59.1226
61.025 67.9 53.49816667
70.3 54.4 60.419
-25 58.9 29.717
-65.5 17.4 32.2328
-55.7 50.4 45.844
64.45 50.4 55.766
-5 43.4 28.429
54 42.4 61.8306
-44 45.4 37.419
62.15 8.9 58.799
-35.6 39.9 27.31033333
71 54.4 69.016
71.9 54.4 80.029
-32.3 84.4 40.966
-52.5 72.9 35.9728
-58 39.4 45.2166
-27.5 55.9 30.6822
-43 51.9 35.275
72.3 54.4 84.927
-65.5 52.9 46.0072
-42.9 51.9 34.855
72.4 54.4 86.143
-61.5 65.9 42.557
72.7 54.4 89.807
66.5 17.9 72.653
-46 53.4 37.2924
-53.5 67.9 39.2434
-52.5 30.4 39.6778
62.45 70.9 52.6015
-39.9 51.9 32.92183333
4.6 39.4 32.89933333
-58.5 56.9 45.4658
51 39.9 59.6712
-8.5 51.4 29.0612
-53.5 83.4 24.0706
-16.8 58.4 22.141
-54.5 51.4 44.0372
-60.5 84.4 26.397
-68 14.4 28.4152
-62.5 19.4 34.3076
-69.5 54.9 43.7482
-67 54.9 44.099
70 75.4 51.6354
-66 87.4 20.6582
52 67.4 51.1782
-64 51.4 46.1514
-59 64.9 43.032
-66.375 54.9 45.4635
-23 50.4 31.591
-60 54.9 45.8224
-20.6 57.9 24.42966667
54 44.9 60.7952
-61 82.9 28.6464
-12 53.9 28.6124
48.025 41.9 57.02983333
-17.3 59.4 24.977
-63.075 44.4 46.025375
71.8 54.4 78.812
-46 50.4 37.568
52.5 68.4 51.2314
-59.5 54.9 45.8098
61.5 68.4 53.6196
62 36.9 53.8626
-56 54.9 45.7982
-48.35 16.4 22.2585
-31.5 52.9 31.3068
-55 54.9 43.7104
70 24.4 82.283
-48.5 80.4 14.8884
41.025 43.9 53.00075
-36.2 84.4 32.361
60.075 42.4 63.009
-39.15 33.9 29.7375
-10.375 48.4 28.896
-48.5 54.9 37.0694
-33 47.4 31.5898
-47.5 54.9 37.0836
-42.15 47.4 39.8505
-6.5 61.4 25.4594
43.5 70.4 48.401
66.5 17.4 72.6772
-41.15 79.4 32.714
-37.45 79.4 41.7945
-46 54.9 37.0484
68.075 5.4 68.448625
-43.3 54.9 36.45
-70 31.4 42.4306
-43.2 54.9 35.557
67 42.4 63.7792
20 74.4 53.2604
-41.35 30.9 31.949
-42.5 54.9 32.7744
57.5 54.4 57.6046
-69.5 47.4 43.6436
-60 54.4 45.8668
-40.1 54.9 32.45866667
-39.85 54.9 32.618
-21.05 41.9 29.776
72.5 54.4 87.361
13.5 72.9 55.9176
27.1 24.4 34.20233333
60.65 60.9 42.633
-38.5 54.9 32.6854
-38.025 54.9 32.49833333
-36 54.9 28.3548
-22.5 58.4 29.9254
43 43.9 54.156
4.475 64.4 60.50491667
-32 54.9 30.9456
35.05 50.4 52.97775
-52 27.9 38.2806
59.025 35.4 52.02433333
-31.5 54.9 30.9746
-54.5 16.4 27.7014
-31 54.9 30.9412
23.9 26.4 33.938
45.5 52.9 53.5528
-30 54.9 30.9628
44 43.4 54.8898
-29.5 54.9 30.9646
-20.8 47.9 26.605
-56.5 83.4 27.853
-29 54.9 30.9526
-34.7 55.9 29.262
-27.5 54.9 30.934
56.5 51.4 55.5972
-14.35 59.4 22.627
-25.5 54.9 30.962
-61.5 39.4 45.1802
30.2 70.9 48.804
-22.5 54.9 30.9704
-22 54.9 30.9404
-51 46.4 44.0694
5 61.4 61.6234
-60 72.4 38.8998
-19.85 54.9 27.313
-53 71.9 36.7256
-56 63.9 43.396
-19.45 54.9 28.282
-4.35 52.9 28.3525
-19 54.9 28.3878
-54.5 27.9 38.324
-18.5 54.9 28.3916
-39.3 59.9 32.321
-49.2 72.4 33.172
-16.5 54.9 28.4168
-15 54.9 28.4254
48 10.9 42.657
-14 54.9 28.4016
-13.5 54.9 28.383
57.5 41.4 62.421
-65 84.4 26.3566
70.4 53.4 61.051
-13 54.9 28.3792
50.5 53.4 53.9672
69 71.9 52.6706
-11 54.9 28.4016
19.55 28.9 33.94875
63.9 71.9 48.879
-41.9 49.4 35.41033333
-70 67.4 41.7948
53.2 43.9 59.188
53.3 41.4 60.008
-50.5 82.4 25.7936
-10 54.9 28.4018
-69 36.4 43.3272
67 36.4 65.6998
-20.6 59.4 28.287
-38.05 42.9 32.3875
-42 22.4 24.7812
-9 54.9 28.3966
67.5 18.9 72.7556
-8 54.9 28.4008
-7.5 54.9 28.3958
17 74.4 54.0812
-64.5 86.4 22.8636
-68.5 67.9 41.5846
37.7 51.4 52.614
-9 54.4 28.505
-17.5 42.9 28.7402
-7 54.9 28.4062
-6.5 54.9 28.4148
-5 54.9 28.4062
-4.6 54.9 28.24633333
60.5 35.4 52.4318
53.5 10.4 44.9954
-4.15 54.9 27.9075
1.5 43.9 28.3836
-50 60.9 42.2062
60 65.9 52.7992
-3.9 54.9 28.25266667
-49.4 78.9 18.614
46.3 11.4 41.451
-3 54.9 28.3882
56.5 64.9 51.5568
-2.5 54.9 28.3936
48.5 69.4 48.5118
63.5 70.4 52.5992
29 23.4 34.7336
70.3 13.4 71.357
49.5 54.9 53.4174
-0.15 54.9 28.52
34.5 22.4 36.1316
4.1 54.9 61.633
-63 72.4 38.8566
-51 34.9 41.66
-30.5 47.9 31.6332
32 45.4 54.1986
-67 37.4 43.7484
-49.925 55.4 43.527625
4.5 54.9 61.8672
61.05 6.4 57.93475
5.575 54.9 62.02675
-61 78.9 33.3994
6 54.9 61.8932
16.55 74.9 53.365
55.525 7.4 48.48316667
-57 55.9 45.6564
-47 20.9 31.9048
-37.8 42.4 31.744
6.35 41.9 26.43
51.55 9.4 45.12475
-54.5 54.9 43.7196
38.975 17.4 38.03816667
-36.5 78.9 43.8626
-68 55.9 43.79
-36.525 41.4 28.01941667
-35.2 84.4 35.252
0.1 50.4 31.693
-60.5 59.4 44.8988
-65.5 21.4 36.0896
-58 63.9 43.4276
-46.65 46.9 43.4445
-62.5 43.9 45.9618
58.45 8.4 49.258
-21.975 42.9 31.01833333
71.7 49.4 82.645
-50.5 72.9 35.9622
-22 53.4 31.2204
47.475 54.9 52.5305
-49 65.4 33.186
50.8 54.9 55.149
-35 45.9 28.7726
51.475 54.9 56.03125
-21.45 61.4 28.29275
52.5 54.9 55.194
62.9 35.9 53.00633333
53 54.9 54.8162
-20.425 45.4 26.4695
53.35 54.9 54.889
55.5 8.9 47.2712
54 54.9 56.8386
68.5 41.9 63.837
-20 43.4 30.1742
-59 85.4 24.8246
14.9 70.9 55.15033333
56 54.9 57.4014
-39.9 42.9 33.86433333
56.5 54.9 57.4854
30.5 45.4 54.7524
57 54.9 57.54
-9.5 59.4 26.7044
57.5 54.9 57.632
-60.5 15.9 30.5226
59 54.9 57.93
62.05 46.9 59.9615
-41.55 83.9 17.7855
62.425 54.9 58.45075
-60 24.4 38.504
63.5 54.9 58.259
72.5 53.9 87.231
-60.5 44.9 46.0822
64 54.9 58.3716
-23 47.9 31.6298
-22.5 52.9 31.346
20.5 30.9 34.192
-26 58.4 29.9514
66.5 54.9 58.8602
-18.5 55.4 28.2676
35.5 22.9 35.342
53.575 43.4 61.49775
25 72.4 51.3832
-65 79.9 32.2858
69.075 72.4 52.483625
-21.05 52.4 31.3445
-40.45 44.9 42.23075
-64.5 15.9 30.4686
-50 71.9 36.704
-49.35 40.4 36.664
67.075 54.9 59.01175
-17 57.4 24.805
12.5 72.9 55.968
-25.5 57.9 30.0742
-61.5 38.4 44.9638
64.3 50.4 55.223
-66 68.4 41.313
52.45 49.9 65.61575
-56.5 51.4 46.1724
67.925 54.9 58.97725
-49.4 53.9 38.324
68.575 54.9 59.00475
53.4 74.4 52.464
71.2 54.9 72.348
-39.4 63.4 45.504
-48.5 76.4 32.7766
-46 57.4 36.505
13.5 45.9 60.1416
71.4 54.9 75.071
71.6 54.9 77.737
-53 67.4 39.5312
67 17.4 72.7704
56.35 6.4 49.3795
71.8 54.9 80.407
-17.15 49.4 27.6215
-49.7 20.9 32.263
72 54.9 83.096
67.5 42.4 63.8026
-37.2 42.4 28.883
4.4 66.4 58.836
-18.9 58.4 26.31333333
57.5 51.4 55.6494
-45.5 26.9 29.497
72.3 54.9 87.124
68.4 60.9 56.61566667
-21.5 50.4 31.577
-59 14.4 28.5052
-28 47.4 31.6092
-4.1 52.9 28.336
-13 60.9 24.6834
38.025 45.4 50.49316667
66.45 26.9 69.67975
-55.4 72.4 36.36866667
53.6 40.4 61.284
-38.925 63.9 30.5285
-70 55.4 43.7408
-8.5 60.9 25.8772
-68.5 55.4 43.7712
57.5 58.4 55.8426
-65.95 55.4 45.6305
66.55 46.9 60.73475
-64.5 55.4 45.7486
-61.5 55.4 45.7456
-60 55.4 45.7194
-35.55 65.4 22.436
-59.5 55.4 45.722
-56.5 55.4 45.7584
-44.5 56.9 36.5998
-52.5 55.4 43.5812
18.5 33.9 35.5642
-51 42.9 43.6926
-52 55.4 43.5916
-64.5 49.4 46.2324
-51.5 59.4 42.73
57.5 66.9 52.689
-51.5 55.4 43.5952
64.8 30.4 66.175
-48.5 55.4 36.9726
-18.5 58.4 26.1436
32.15 44.4 55.01
-61.5 28.4 40.9948
-21.5 43.9 31.1878
66.3 10.4 68.336
29.5 24.9 35.2492
-45.5 55.4 36.9438
-44.05 55.4 36.3065
52.8 44.9 54.652
28.85 22.9 33.737
23.5 71.9 51.0978
60.5 42.9 63.1096
67.9 4.9 67.871
-43.55 55.4 36.89225
-43.2 55.4 35.557
23 30.4 33.6436
-62.5 40.4 45.4074
-43.1 55.4 34.668
-65.5 20.9 35.6496
-60.5 47.9 46.2476
-9.5 51.4 29.052
-43 55.4 33.762
62.7 7.4 62.174
59.5 72.4 47.0034
-9.5 50.9 29.064
64 24.4 66.18
64.3 22.9 68.424
-42.5 55.4 32.7734
4.5 49.9 53.675
-58 50.4 46.2114
70 52.4 60.264
-38.025 55.4 32.49533333
-35.65 55.4 28.1585
-68.5 31.9 42.6874
54.475 57.4 56.02391667
44 16.4 39.2126
61.15 6.9 57.7385
-35.4 55.4 27.924
50.85 53.9 54.092
64.45 47.9 56.8
-35.05 55.4 28.3225
-7 54.4 28.4906
38.6 16.4 37.18433333
-40.35 72.4 44.9235
-51.5 27.9 38.2788
-32.5 55.4 30.8228
32.7 45.4 58.039
-64.5 16.9 31.6414
10.5 46.9 60.7968
3.6 64.4 59.712
48.55 40.9 56.60725
60.525 45.9 61.49875
-22.5 55.4 30.8192
-21.1 55.4 30.79566667
-65 84.9 25.5764
-19.35 55.4 28.2235
61.5 54.4 58.3784
15 46.9 59.2004
-18 55.4 28.2828
-9 56.9 27.778
-56.5 24.9 38.8552
-17.5 55.4 28.2788
-44.65 69.4 30.437
-16.5 55.4 28.3034
-16.05 55.4 27.718
69.55 73.9 52.15825
-66 43.4 45.7666
-15.85 55.4 27.469
69 19.4 73.0866
69.925 54.4 59.51575
-14.525 55.4 27.51483333
-15.5 57.9 19.0914
65.8 65.9 52.983
-54.5 82.9 24.9172
-63.5 28.9 41.2304
-39.45 33.4 29.7505
-69 26.9 40.0968
71.35 72.4 52.871
-11.5 55.4 28.2826
2.7 50.4 45.677
-17 55.4 28.2856
-10.5 55.4 28.2486
-62 60.4 44.6058
-39.7 47.4 42.563
-10 55.4 28.2768
56 66.4 52.3134
-9 55.4 28.2738
13.7 43.9 57.342
41.5 71.4 48.1484
-8 55.4 28.2806
-5.5 55.4 28.2932
-49.7 44.4 43.659
52.6 53.9 55.495
-70 47.9 43.6536
-5 55.4 28.2636
31 70.9 49.0466
-34.35 52.4 31.316
-49.6 63.4 41.01
-3.9 55.4 28.14066667
-51 24.9 36.2556
64.55 31.9 57.881
-3.5 55.4 28.255
-58.5 61.9 44.1602
-63 70.9 39.8962
-68.5 67.4 41.827
-49.55 53.9 43.2915
-54 45.9 44.0308
31.075 44.4 55.024125
-37.15 57.9 30.2465
-0.5 55.4 28.3118
65.55 14.9 68.2115
63.9 37.4 59.031
0.7 65.9 44.755
-61.5 86.4 22.8576
-59 41.9 45.7218
50.5 11.9 43.067
-55.45 55.9 43.57225
-51.5 25.4 36.632
5 53.4 61.8032
6 45.4 28.1936
61.5 67.4 53.5932
-41.6 25.4 27.844
67.5 53.9 58.904
-20.85 49.9 26.5665
52.475 69.9 50.524
5.575 55.4 62.00675
-24 46.4 31.5506
-6 57.4 27.6502
58.2 4.9 51.162
-39.575 44.4 33.401625
57 64.4 51.1288
-2.2 48.4 29.011
-53.5 55.4 43.589
71 53.9 68.359
-61 26.4 39.8304
-70 38.9 43.3906
-42 27.9 30.2672
36 47.9 54.1056
-42.425 24.9 27.5165
59.925 39.4 61.528125
62.9 5.4 62.75033333
54 36.4 51.6582
53.7 68.9 51.63
6.025 67.9 58.98375
49.8 55.4 52.97
31.475 73.9 49.51816667
50.8 55.4 55.149
-55.5 21.4 33.4104
-62.5 54.9 45.8072
-24 54.4 31.0568
9.3 71.4 57.007
59 45.4 61.6524
-34.4 46.9 29.713
45 50.4 52.8792
52 55.4 55.9968
-50.5 38.4 42.7684
-67 80.4 31.6886
52.6 55.4 54.74833333
62 67.4 53.6794
31.5 22.9 35.6608
16.5 32.4 36.9206
62.5 36.9 53.9122
53.35 55.4 54.8285
-56.5 88.4 18.1408
-6.575 60.9 26.006625
53.6 55.4 56.623
-38.65 79.4 38.851
54 55.4 56.8182
55 55.4 57.1266
56.5 55.4 57.4854
57 55.4 57.54
59 55.4 57.93
59.425 55.4 58.0315
61.5 4.4 58.3824
72.6 50.9 86.76
22.5 72.4 52.135
60 55.4 58.1448
61.65 5.9 60.31
-49.65 70.4 37.387
3.8 50.9 52.626
34.4 42.9 50.41366667
28 73.4 50.8518
63 55.4 58.1948
63.5 55.4 58.2712
-60 25.9 39.5064
64 55.4 58.3782
-55.45 44.9 44.0775
25 44.4 56.3272
21.025 30.9 34.034
66 55.4 58.804
67 55.4 59.0726
-41.55 28.9 30.754
67.5 55.4 59.1836
-46 76.9 22.118
67.975 55.4 59.00433333
-45.5 22.4 26.793
33 23.4 35.7484
68.575 55.4 59.00525
-68.5 66.4 42.3386
-46.5 75.9 32.472
-28.075 60.4 29.01875
-40.425 46.9 41.88675
70.6 55.4 65.209
70.7 55.4 66.511
-43.45 83.9 12.186
-65 59.4 44.8956
70.8 55.4 67.805
-15.3 48.4 19.1
-34.65 47.4 29.8445
71.2 55.4 73.03
71.3 55.4 74.348
-60.5 12.4 25.4492
42.925 45.4 43.039875
-37.5 44.4 30.538
71.6 55.4 78.241
-37.3 44.4 29.412
-67.5 74.4 37.3946
71.7 55.4 79.535
-46.5 28.4 30.698
-16.95 48.9 27.363
71.9 55.4 82.135
-49.575 19.4 31.020875
72 55.4 83.441
-50 19.9 31.7408
-41.7 56.4 39.902
-41.025 61.9 46.04358333
72.3 55.4 87.342
66.6 34.9 64.756
-67.5 77.9 34.3126
-64 86.4 22.8554
-22.5 58.9 29.694
-54 59.9 42.5424
72.5 55.4 89.949
72.6 55.4 91.259
52.45 49.4 64.32725
4.075 46.9 27.98
-56.5 59.9 44.7828
33 48.4 54.4312
-67.5 29.9 41.7234
63.65 8.4 63.85
-40.7 83.9 20.273
-65.5 11.4 23.6382
72.1 49.4 85.471
60.5 57.9 57.2298
-46.5 38.4 35.9668
-18.5 42.4 29.1764
-57 40.4 45.4656
-51.5 31.4 40.1468
-37.85 78.4 41.863
32.5 43.9 54.249
-69.5 55.9 43.7542
-68.5 55.9 43.7842
-29.65 44.4 30.9915
1 47.4 28.7084
-69.5 56.9 43.77
-38.45 68.9 44.7475
-66.5 34.4 43.6524
-67.5 55.9 43.836
-48.5 26.9 29.5122
-62.5 55.9 45.6556
-47.5 78.9 18.546
-0.1 52.4 28.95966667
52 41.9 59.2144
-37.7 64.4 29.835
-58 55.9 45.6382
-68.5 11.4 23.6452
50 67.9 48.932
30.99166667 44.9 54.52441667
-61.5 68.4 41.352
-56.5 55.9 45.67
-28 54.4 31.0784
-55.8 55.9 45.511
-55 26.9 37.6762
-53.5 55.9 43.4852
-35.2 55.4 27.88
-50 27.9 38.2534
-32.5 59.4 27.7384
-55.9 9.4 18.532
-52 55.9 43.5118
4.3 49.9 52.678
-42 32.4 33.3164
-51.5 55.9 43.477
68 75.9 49.7822
-55.4 64.4 40.98466667
-49.3 55.9 36.853
-40 45.9 42.331
-20.15 57.9 25.216
-9.925 43.4 28.519375
62.3 63.9 51.474
-18.5 44.4 29.211
-55.85 85.9 23.363
48.8 50.4 60.417
-24 55.4 30.8244
52.15 7.4 45.206
-45 55.9 36.8592
35.35 41.9 48.8995
-54.5 41.9 43.5338
-44 55.9 36.6814
-12.425 49.9 27.388
-36 55.4 28.3222
57 4.9 49.28633333
29.5 71.4 49.7494
-43.5 55.9 36.9326
-55.05 83.4 23.27625
-11.2 50.9 28.309
-43.2 55.9 36.582
-36.5 60.9 26.0694
-35.575 48.9 28.9235
41.15 48.4 52.6475
60.45 5.9 53.866
-43 55.9 34.534
-69.575 43.9 43.522125
54 45.4 60.2868
-41.7 55.9 32.912
38.8 41.4 47.512
-56.5 78.4 33.8674
-36.5 40.9 27.872
48.475 43.9 56.52733333
-39 55.9 32.6734
54.05 7.4 47.21725
-1.5 48.4 29.1294
18.7 72.9 54.015
-67.5 64.9 42.983
-36.5 50.9 29.047
6.5 52.4 61.9052
-37.7 55.9 31.902
-51.5 39.4 43.0448
-37.4 55.9 28.406
34.425 42.4 48.96025
-55.7 68.9 40.258
-35.8 55.9 28.01
-32.5 53.4 31.2414
-14 55.4 27.5044
-58.5 77.9 34.3834
3 63.9 58.039
-64 53.9 45.936
56 53.4 57.139
-44.5 48.9 37.6098
2.8 63.9 56.879
-35.05 55.9 28.244
67.95 70.9 53.2445
-40.4 56.9 41.43333333
-54 46.4 44.0616
-34.35 55.9 30.277
35.9 69.4 46.325
-30.5 55.9 30.7048
47.5 52.4 53.6718
62 55.9 58.5118
-26 55.9 30.7038
10.9 49.4 60.19066667
41.5 43.9 53.2898
35.1 18.9 35.91366667
-20.05 55.9 26.348
-62.5 12.4 25.4206
-39.525 42.4 33.45175
-17.2 55.9 27.832
45.5 42.9 55.7828
-51.575 12.9 21.48475
37.025 17.9 36.96408333
-16.95 55.9 27.25325
-38.15 33.4 27.192
-69 20.9 35.6552
-15 55.9 27.2744
56.5 57.4 56.6732
30.5 47.9 54.735
-14.5 55.9 27.286
-66 24.9 38.7884
58 70.4 51.9392
-70 66.9 42.0842
-57 24.9 38.8804
-10.5 55.9 28.0996
-15.5 44.4 28.6296
13.5 32.9 37.295
-68.5 61.9 43.8352
59.5 69.4 52.9046
-7.5 55.9 28.1194
54.5 51.4 55.1684
-4.65 55.9 28.117
12.5 30.9 36.94
-4.4 55.9 27.516
5.425 55.9 61.998875
6 55.9 61.7232
13.925 70.9 55.93275
-51 53.4 43.8708
-60.5 31.4 42.489
7.5 43.9 28.1764
42 43.4 53.6934
59.55 7.4 51.12375
-37.5 76.9 44.5532
-36.5 67.4 20.7684
-49.15 44.9 42.3245
54.65 6.4 47.644
15 73.4 55.3006
-46.95 79.9 17.2775
-46.65 47.9 43.743
-53.5 62.4 41.7552
-58 64.9 42.9998
-57.5 32.4 42.9354
-44.5 55.4 36.8054
2.4 65.9 51.802
-0.15 53.4 28.799
53 69.9 50.492
-66.5 22.9 37.3316
-60 78.4 33.8782
48 54.9 52.7878
-34.55 83.9 38.3415
-61.5 60.9 44.449
-67 88.4 18.0656
-8.5 53.4 28.7384
-42.5 23.4 26.1826
-50.5 39.9 43.1292
40.475 44.4 52.50725
-54.5 16.9 28.802
-56.5 62.9 43.7992
57.8 73.4 48.776
-12.2 58.4 24.881
-58 74.4 37.4294
70.8 53.4 65.899
-46 26.9 29.488
46.5 67.4 48.2242
-43.35 83.4 13.8925
61.5 51.9 56.7786
-64.5 67.9 41.5818
50.85 55.9 55.4295
51.5 55.9 55.715
55.5 55.9 57.1692
42 15.4 39.3586
-47 47.9 43.8942
-4 45.4 28.844
51 39.4 59.2782
57 55.9 57.4708
-48.5 69.4 30.4322
-50.575 11.4 17.49475
-17.5 46.4 28.1426
-37.8 33.9 27.468
-34 82.9 42.1484
59 55.9 57.871
-55.7 70.4 39.715
59.575 55.9 58.013125
60 55.9 58.1058
-61 80.4 31.7622
-63 64.9 42.973
-62.5 31.9 42.7012
17.025 44.9 59.01666667
-41.3 33.9 33.302
-68 52.4 43.7352
-65.5 33.4 43.3304
35.5 70.9 49.1422
62.5 55.9 58.3478
3.5 43.9 28.1128
-52 20.4 32.125
-56.5 33.4 43.3804
-57 64.4 43.2302
64.5 55.9 58.48
66 55.9 58.8048
-53.65 10.9 18.607
67 55.9 59.1704
-17.6 49.4 28.12166667
67.475 55.9 59.495
70.2 55.9 59.965
1.3 66.4 46.418
70.3 55.9 61.31
70.6 55.9 65.378
62.4 42.9 63.18666667
46.5 69.9 48.3182
70.9 55.9 69.377
69.2 23.9 81.632
7 51.9 61.8292
-65 60.9 44.4586
-69.5 76.4 35.6268
71.2 55.9 73.415
-52 62.4 41.7406
71.6 55.9 78.763
71.9 55.9 82.769
53.1 68.9 50.453
72.1 55.9 85.455
-54 61.4 42.0984
72.4 55.9 89.458
-65 74.9 36.993
-63.5 35.9 44.2228
69.9 49.9 64.712
-32.5 83.9 41.2074
68.475 25.4 70.51941667
-8 57.9 27.3906
6.65 48.9 60.3535
-22.5 60.9 28.7766
-67.2 56.4 43.972
0.5 46.9 28.8842
-69 56.4 43.7774
58.075 72.9 48.52075
-62 56.4 45.5278
-55.6 64.9 42.064
-49 80.4 14.9202
6.7 55.9 60.972
-60 56.4 45.548
-68 42.4 43.5278
-59.5 56.4 45.5668
-46.65 48.4 43.743
-56.1 8.9 17.73833333
64.5 57.4 57.8826
-59 36.4 44.4122
-38 55.9 32.4136
-59 56.4 45.5562
-51.425 37.4 42.511125
4.3 36.9 35.435
-58.5 56.4 45.5552
-58 56.4 45.5666
-55.6 70.4 38.15
-50.5 71.4 37.1242
-60 83.9 27.1434
-57 56.4 45.5606
71.5 42.9 63.76
3.3 58.9 61.456
-55.7 56.4 45.215
68.575 7.4 71.433
-37 48.9 29.1008
-59 41.4 45.5966
-55.45 56.4 43.4455
-49.7 34.4 41.192
-68.5 53.4 43.7254
-49.65 41.9 43.1745
-28.925 59.4 29.50825
-2 53.4 28.7328
-54 56.4 43.4198
-51.5 56.4 43.3992
-50.5 56.4 43.389
-0.5 51.4 29.056
-49.7 56.4 43.224
-60 63.9 43.4098
-39.85 58.4 43.7615
-59.5 33.4 43.3578
60 44.9 62.298
-54 66.9 39.8086
-64 19.4 34.2756
-45.5 53.9 37.2332
-54.5 60.9 42.2478
-48.5 56.4 36.7688
-49 29.9 31.8058
30 25.9 34.7828
-4.1 53.4 28.168
-46.5 56.4 36.7432
-36.5 76.4 44.3608
-45.5 56.4 36.7694
-44.5 56.4 36.7162
53.3 39.9 60.315
65.075 70.9 52.498375
-41.5 56.4 40.28166667
-15 42.4 28.3132
-68.5 39.4 43.4092
-39.15 78.9 38.1855
-40.8 56.4 41.608
-40.375 56.4 42.41125
67.95 64.9 55.84425
63.45 8.4 63.2345
-38.475 56.4 32.49875
-59.5 87.4 20.783
-42 26.4 28.9652
-37.5 56.4 30.067
-58.5 46.9 46.2122
-37 56.4 27.9858
-9.5 43.9 28.6882
-37 63.9 24.1766
-36 56.4 27.9654
-36.3 36.4 25.845
-45 46.9 37.5428
-34.3 56.4 30.133
-9.5 47.4 29.103
41.5 15.9 39.1302
70 45.4 63.1334
-27.5 56.4 30.5766
41 68.9 48.3642
48.65 45.4 54.412
-24.5 52.4 31.4408
-19.35 53.4 28.657
-27 56.4 30.5618
-37.8 32.4 26.435
54.5 72.4 47.0876
-29.5 58.9 29.7176
-64.5 75.9 36.1946
-21 56.4 29.045
-50 74.9 34.3146
-55 49.4 44.1314
-67.5 51.9 43.7914
-20.425 56.4 25.453375
-45 32.9 33.5962
-18.5 56.4 27.9342
15.2 71.9 55.513
-16.125 56.4 26.926
3.5 37.9 33.121
-13.85 56.4 26.7655
29.5 46.4 55.3298
-52 53.9 43.8224
-56 33.4 43.2944
-12 56.4 27.109
-42.15 58.4 37.674
-59.5 55.9 45.6382
-7.5 56.4 27.9682
-39.5 83.9 23.779
-53 54.9 43.6958
-6.5 56.4 27.973
-4.1 56.4 27.23033333
66.5 64.4 54.4638
-36.85 31.4 23.739
-1.5 56.4 27.9882
47.5 69.4 48.5416
-43.1 55.9 35.553
-1 56.4 27.9866
60.5 34.4 60.2302
-21 47.9 29.932
3.15 56.4 61.0995
29 46.9 55.378
52.4 56.9 53.955
-49 63.4 34.2548
40.5 45.4 51.7816
4 56.4 61.8122
5.5 56.4 61.9292
69.7 25.4 77.27
71.4 53.4 73.086
-64.5 77.4 34.822
50.05 70.4 44.325
-69.5 29.9 41.693
-0.2 49.9 29.941
52.85 68.4 51.229
0 49.9 30.95
-26 54.9 31.0036
-12.6 55.9 27.30633333
65.35 57.4 58.082
62.1 5.4 61.23366667
-49.65 45.4 43.664
-35.2 48.4 28.836
59 66.4 52.8314
-3.5 55.9 28.1008
-40.1 31.9 30.247
-2 49.9 29.0704
-55.7 68.4 40.697
51.4 73.4 48.67933333
-55.45 68.4 39.04075
-19 60.4 25.8822
-48 29.9 31.7868
-21.5 53.4 31.2464
59.5 61.4 43.3744
-18 47.4 28.1328
64.65 38.9 61.177
63.125 6.9 63.3845
-41.65 47.4 40.454
-39.4 55.9 44.00733333
-45.5 44.9 37.3668
51 56.4 55.2734
6.55 49.4 60.82475
-64 71.9 39.2432
-60 49.9 46.236
51.5 56.4 55.4292
55.575 56.4 57.013
33.6 20.9 36.10166667
-62 54.9 45.8106
58.025 56.4 57.51025
61.575 58.4 56.997
-69.5 61.9 43.827
-21.05 51.4 31.43025
-52 50.4 44.0822
-1 52.9 28.8214
62 56.4 58.4116
48.5 52.9 53.7894
62.5 56.4 58.23
63 56.4 58.066
64 56.4 58.251
-9 43.9 28.6838
64.5 56.4 58.3756
65.5 56.4 58.5872
-23 51.4 31.51716667
57 46.4 59.5964
66 49.9 58.8018
-37.35 50.9 29.0095
-16 56.9 22.985
-49.575 19.9 31.501625
12.5 31.4 37.1506
-64.5 44.4 46.042
63.5 67.9 53.44
-57 87.4 20.7224
-30.525 86.4 39.48275
-9 45.9 28.9952
-55.6 80.9 28.583
-37.8 60.9 30.91
-63.5 49.9 46.2196
45 50.9 53.3492
-60 21.9 36.563
-37 45.9 28.9622
4.425 61.4 61.544125
-54 66.4 40.0628
60.5 3.9 52.929
59.5 68.9 53.064
-42.5 22.9 24.8904
-8 57.4 27.6728
-42.55 58.4 36.875
-58.5 15.4 29.9196
-10 42.9 28.332
66.6 48.4 60.15533333
9.975 49.4 60.99541667
-49.8 26.9 37.44
-66.5 31.4 42.4304
-45.5 37.9 35.771
72.3 55.9 88.119
-22.5 59.4 29.5544
-69 56.9 43.7754
6.5 49.9 61.1682
63.85 4.9 63.884
37 20.9 36.7398
-4.2 66.4 24.285
71.5 41.4 64.1666
-67.5 56.9 43.872
-66.9 56.9 44.369
-65.5 56.9 45.4362
61.5 52.9 57.7534
-50.025 67.4 39.49183333
-63 56.9 45.4634
51.8 47.9 59.253
-63 77.9 34.3782
3.4 50.4 49.44
-39.6 59.9 34.029
-49 72.4 31.686
58.5 73.4 49.9304
43.5 44.9 53.3928
50.5 52.4 53.4666
-47 23.4 31.2586
-54.5 56.9 43.3286
51.15 53.4 55.8045
69.3 10.9 71.977
-53.5 56.9 43.3156
-65.5 38.4 44.9166
-8.5 46.9 29.0688
-52 56.9 43.319
58.55 5.4 51.80875
-51.5 56.9 43.3014
63 51.4 55.7326
-69.5 45.9 43.5944
-49.55 56.9 42.818
0.2 65.9 42.689
-38.05 56.9 32.22175
-66.5 25.9 39.4578
-5.5 48.4 29.1098
67 18.4 72.7632
-37.65 56.9 31.6645
-13.95 57.9 19.7235
-36.5 56.9 27.8594
-3 49.4 29.0926
-35.6 56.9 27.619
-12.5 61.4 24.6956
-35.35 56.9 27.3995
8.35 65.9 58.7405
-35.15 56.9 27.741
-26 50.4 31.584
-65 61.9 44.1144
66 45.9 61.932
-34.35 56.9 29.902
-34 56.9 30.3942
-41.3 51.9 30.19
7 66.4 59.8878
-31 56.9 30.4282
-53 57.4 43.183
-54.5 66.9 39.8138
-30 56.9 30.4386
-34.375 47.4 30.37425
-15.55 48.4 18.6635
-56.075 28.4 40.970125
-49.5 41.9 42.722
37 72.4 48.6732
-68 18.4 33.2818
9.9 67.9 57.93683333
-21.075 54.4 31.015
-28.5 56.9 30.4252
64.2 62.4 47.14
-26.5 56.9 30.3828
52.85 48.4 59.889
-62 63.4 43.5714
-26 56.9 30.3846
4.65 53.4 61.602
-66.2 55.4 45.508
-25 56.9 30.4028
-22.5 56.9 30.4006
45 44.4 54.628
65.5 45.9 61.766
-21.5 56.9 30.3866
-21.15 56.9 30.337
-20.85 56.9 25.127
61.4 7.9 56.258
38.1 16.9 37.19866667
-20.525 56.9 25.1375
37.85 16.9 36.871
26.525 74.4 51.02941667
-20.4 56.9 24.97
-19.85 56.9 26.4415
-37.35 48.9 29.0985
-63 46.9 46.1864
-19.55 56.9 27.2905
-19.35 56.9 27.7535
25.5 71.4 50.02
6.15 58.9 60.7515
-19 56.9 27.8032
-15.9 56.9 22.487
-15.8 56.9 21.968
-15.7 56.9 21.451
1.3 49.9 37.538
-34.9 83.9 37.307
-15.4 56.9 19.903
-68.5 46.4 43.6322
-14.6 56.9 18.047
-7.5 56.9 27.8122
-46.6 46.4 42.562
-3.95 56.9 27.66175
47.5 69.9 48.1964
-1 56.9 27.8128
-30.7 44.4 30.506
45.6 67.9 48.38633333
51.925 56.4 55.521625
-61 86.4 22.895
4.5 56.9 61.9854
-50.5 17.9 28.799
-66.5 85.9 23.8292
6.1 56.9 61.40233333
-37.7 48.9 32.743
-63 47.9 46.2282
6.3 56.9 61.156
-46.5 56.9 36.5996
-55.7 63.4 43.317
71.2 53.4 70.674
71.4 52.4 71.977
0 50.4 31.158
49.55 10.4 43.68675
-59.5 56.9 45.4718
-67.1 39.4 43.67233333
37.9 43.4 50.07933333
-36 67.9 20.1498
51.35 67.9 49.1385
70.6 54.9 64.27
59.5 41.4 62.7642
-5 46.9 29.0828
-59 79.9 32.344
-16 43.9 28.6388
-63 53.9 45.9304
-18.5 52.9 28.8034
-59.5 67.4 41.8734
62 3.4 58.3648
13 46.4 60.307
-49 51.4 37.4528
-48 37.9 35.8142
-54.5 19.4 31.3484
-40.5 44.4 34.3156
38.5 19.9 37.2046
52.6 56.9 53.26666667
-59.5 12.4 25.4852
-51 69.9 38.046
55.5 56.9 56.749
56.525 56.9 56.99991667
39.5 45.4 51.364
60.5 56.9 57.891
5.5 35.9 36.6446
40.5 50.9 53.7352
62.4 56.9 58.16333333
-66 10.4 21.561
-1 65.9 37.691
-65 64.9 43.013
-1.1 65.9 37.273
66 46.4 60.6292
-43.2 83.9 12.926
-62.5 87.4 20.7372
63 56.9 57.8568
65.45 56.9 58.398
-26 45.4 31.423
-55 38.9 42.942
-16 45.4 28.1104
15.15 70.9 54.9115
-41.95 79.4 30.7605
37.5 44.4 50.2194
67.6 9.9 71.23466667
71.2 50.9 68.904
-66.5 75.9 36.1788
25 25.9 34.1646
8 66.4 59.3206
-68 57.4 43.8198
-64 12.4 25.432
-67.2 57.4 43.994
66.975 15.9 70.49225
-39 63.4 31.38
-44.45 78.9 24.849
51.4 70.4 40.85533333
-52.5 49.9 44.1102
-27.5 60.4 29.0214
-63.5 57.4 45.3738
21.5 29.9 34.228
45.4 50.4 52.65933333
-62 57.4 45.3466
-67.2 46.4 43.766
-61 57.4 45.3496
34.8 44.9 57.266
-58 57.4 45.3792
-22 46.9 31.5304
-57.5 57.4 45.382
-21.075 50.4 31.51675
-33.15 60.4 26.9835
72.85 45.4 62.8775
-64 73.4 38.1814
-32.55 84.4 41.18925
-55.7 57.4 45.046
-52 57.4 43.217
-50.5 57.4 43.1888
-48.5 45.9 43.0094
-49.625 57.4 42.92475
-65 33.4 43.3324
62.95 7.4 62.781
-46.5 57.4 36.4816
-70 14.4 28.4454
-43.5 57.4 36.648
-54.5 72.4 36.3626
-43 57.4 37.296
-42.8 57.4 37.632
-64.5 52.9 46.0514
56.5 54.4 57.4746
-41.85 57.4 39.2255
5 34.4 36.7336
-40.5 78.9 34.768
-40.9 57.4 40.816
68.525 6.9 70.968
66.5 55.9 58.9444
-39.4 44.9 35.77
-40.2 78.4 35.901
-40 57.4 42.32
8.5 32.9 36.406
64.3 51.4 55.015
34.975 19.9 36.50508333
-16.5 45.9 28.1134
-39 57.4 32.3388
-22 48.9 31.6186
61 39.4 61.6802
-38.5 57.4 32.2546
58.625 8.9 50.917
-38.1 57.4 32.146
58.35 8.9 50.3
-37.85 57.4 31.865
4.5 58.4 61.9368
67.1 12.9 69.24233333
51.35 67.4 49.108
-37.6 57.4 31.75333333
4.7 37.9 30.569
33.975 72.4 49.50275
-45.5 73.9 26.1926
-34.45 57.4 29.233
9.6 71.9 56.48466667
-24 52.9 31.3244
51.3 44.4 59.296
-63.5 71.9 39.2188
-31 57.4 30.2756
-53 80.9 27.9414
14.9 47.4 58.624
-30.5 57.4 30.2714
-40 62.9 45.9054
-29.5 57.4 30.2808
-62.475 40.9 45.5165
-43 54.9 33.762
39.15 51.9 52.4315
-61.5 50.9 46.172
-63 65.4 42.7684
-25 57.4 30.2654
-21.15 57.4 30.225
57 57.9 56.3234
-20.35 57.4 25.219
54.575 65.4 51.496
-18.4 57.4 26.689
-67.5 53.9 43.842
-66.7 36.4 43.968
55 40.9 61.8826
72.9 51.4 90.767
-69 24.9 38.8038
57.5 44.9 61.7152
-35.6 35.4 25.22833333
41.5 49.4 53.3264
-40 43.9 33.798
-18 57.4 26.5696
72.3 52.9 83.627
69.5 44.4 63.2258
4.15 66.4 57.8275
-16.4 57.4 19.593
-16 57.4 18.614
-57 12.9 26.2976
4.2 50.9 54.892
62.65 56.9 57.928
-15.575 57.4 18.484375
14 44.4 59.353
70.6 23.9 89.256
-20.8 51.4 28.71
-14.4 57.4 17.737
-14.3 57.4 18.163
31.5 23.9 35.5944
-40.5 45.4 42.263
66.8 31.4 67.811
-62.5 20.9 35.6884
49.5 68.9 48.7668
-44 57.4 36.5338
35.4 42.4 50.27233333
-53.475 51.9 44.01916667
58.5 64.9 51.8228
-14.15 57.4 18.8015
72.7 49.4 89.694
-60.5 60.4 44.6002
-13.6 57.4 21.134
-55.6 79.4 30.653
-48 63.4 34.265
65.1 51.9 56.94
-13 57.4 23.674
-42.2 48.9 34.163
-42.5 32.9 33.6062
-12.7 57.4 24.967
-53.6 84.4 21.71566667
-10.5 57.4 27.6222
-8.5 57.4 27.6394
-55.8 69.4 40.507
-19 44.9 28.8552
-4 57.4 27.5926
58.5 72.4 48.448
-1.5 57.4 27.6362
-49.65 49.4 43.736
37.5 20.9 36.6502
33.025 21.4 35.9815
-11.3 56.9 27.238
50 42.4 60.1806
-48 68.9 30.8696
-32 51.9 31.4586
53 66.4 50.649
-66 73.9 37.7808
-42.7 79.4 28.922
-49.2 15.4 22.044
18.3 30.4 35.137
-56.05 70.4 40.1335
59.475 59.4 55.51841667
21 73.4 53.105
-45.5 37.4 35.6326
-68.5 27.4 40.3684
40.5 70.4 48.712
42.475 43.4 54.01125
-65.5 15.9 30.4578
59.5 67.4 53.1722
-19.7 57.9 26.419
-39.5 54.9 32.653
40.075 16.4 38.455875
-37.3 67.4 23.001
51 49.9 63.4002
-55.7 46.9 45.884
-14.5 62.9 24.3016
52.53333333 57.4 54.20966667
-64.5 57.4 45.3702
-21.5 44.4 31.2404
-55 32.9 40.8894
-55.45 65.4 40.609
52.8 57.4 54.466
41 70.4 48.6956
53.525 44.4 60.99783333
42.5 50.9 53.687
-45 50.4 37.5712
-17.15 49.9 27.694
-55.45 36.4 42.2835
63.7 32.9 58.315
-55.7 45.4 45.805
55 57.4 56.2112
53.15 66.4 49.978
72.2 54.4 83.699
-19.85 58.9 27.379
55.5 57.4 56.391
-42.9 21.9 24.37166667
71.6 50.9 74.034
-5.5 48.9 29.1114
59.5 57.4 57.3408
-19.4 48.9 29.01633333
63.5 57.4 57.6518
-3 53.9 28.637
-44.2 78.4 25.783
-62 52.4 46.0854
-55 30.9 39.9956
68.5 76.4 49.8046
14 30.4 36.745
32 72.9 49.8458
-69 67.9 41.5832
1 48.4 28.6078
-63 62.4 43.9894
-62.5 34.4 43.7168
-64.5 68.9 41.0394
61.5 56.4 58.3154
-55.6 17.4 29.717
7.95 65.9 59.27525
-34.15 81.4 42.66
-69 57.9 43.7856
-68.5 57.9 43.791
-65.5 57.9 45.217
-42.15 50.4 35.665
-70 72.4 38.8846
-62.5 57.9 45.2566
-57 42.4 45.7972
-9 48.9 29.0748
58.5 42.9 62.7124
-70 39.9 43.386
-58 27.9 40.7302
-60.5 57.9 45.2302
-67 79.4 32.7802
-56.575 64.9 43.019875
65.075 56.4 58.493875
-59.5 57.9 45.2414
66.6 11.9 68.21
54.95 73.9 53.11
42.575 42.4 53.935625
-57 57.9 45.2626
46.5 52.4 53.715
-38.475 71.9 45.5095
-56.5 57.9 45.261
64.5 63.4 47.503
-55.45 57.9 43.11725
-51 57.9 43.0592
-49.35 57.9 36.4135
-62 22.4 36.958
29 45.4 55.344
-49 57.9 36.3578
-48 57.9 36.355
-63.5 57.9 45.2532
63.45 38.9 59.7575
-47 57.9 36.3366
69.9 25.4 79.495
-62 33.4 43.354
49 54.4 53.6058
-39.2 44.9 34.293
-17 56.4 27.0102
-45.5 57.9 36.3698
-25.5 51.9 31.4574
-42.5 36.9 35.4738
-43 57.9 35.4542
-42.7 57.9 35.801
-42.35 57.9 36.8045
-68.5 62.9 43.7492
-58 72.9 38.5932
-41.45 57.9 39.3755
-4.1 46.9 28.49633333
-50.5 50.9 44.0602
68.3 36.4 66.372
17 72.9 54.7166
-41.3 57.9 39.802
-63 46.4 46.1646
15.9 70.9 53.894
-41.15 57.9 40.229
63.85 3.4 62.469
-55.2 22.9 34.773
-49.7 78.4 30.644
-39.5 57.9 44.47283333
-40.1 45.4 41.951
-39.2 57.9 43.635
-37.7 67.9 26.588
-48 50.4 42.7358
60.5 61.4 43.4264
54.5 64.9 51.116
-62.5 26.4 39.8152
-11 58.4 26.1292
53.35 53.9 54.807
-37.6 57.9 31.75333333
-37.35 57.9 30.274
-5.5 58.9 27.0438
-33.85 57.9 30.1015
-56.5 36.4 44.4328
67.05 51.9 59.18125
-31.5 57.9 30.0398
-16.5 51.4 28.1134
42.15 42.4 53.6035
-30.5 57.9 30.0564
63.8 72.4 48.694
-62 50.9 46.1534
64.5 61.9 49.06
-56.05 86.4 22.637
-24 57.9 30.0888
-65.5 31.9 42.6942
-22 57.9 30.103
-34.8 57.4 28.257
-66 70.4 40.1688
2.5 45.9 28.1804
-14.5 59.9 22.0762
-21.2 57.9 30.095
-46 55.4 36.9826
-20.35 57.9 24.681
72.2 51.4 81.784
-44.5 73.9 26.1496
64.65 9.4 65.7365
-19.95 57.9 25.7515
64.1 46.9 56.259
71.6 49.9 79.326
32.5 48.4 54.4346
-42.85 45.4 38.6845
-17.2 57.9 24.398
-43.1 45.4 38.29033333
-17.1 57.9 23.808
-53 52.4 43.9926
66.65 6.9 68.7205
2.3 50.4 43.538
-16.9 57.9 22.645
-55.7 10.9 20.939
12 71.9 56.8302
58 71.9 49.2654
9 47.4 60.7954
-58.5 39.9 45.3382
66.05 3.9 65.1505
-15.85 57.9 19.162
-39.5 46.9 40.948
-43.5 30.4 32.1142
-13.8 57.9 20.25
-48.95 44.9 41.791
35.6 44.4 55.657
72.85 42.4 63.7635
-13.65 57.9 20.7755
-69.5 61.4 43.817
-48 62.9 34.489
21.95 43.9 57.11025
65.025 52.4 56.99791667
-66 33.4 43.3344
-15.6 56.9 20.934
65.5 45.4 62.4114
45 43.9 55.1256
-12.8 57.9 23.75
64.5 44.4 62.9224
-66.5 73.4 38.157
10.5 69.9 57.8684
-12.35 57.9 25.2225
-30.5 56.4 30.5874
-9.5 57.9 27.4354
-41.575 51.4 34.94
-12.4 57.4 26.258
-64.5 18.9 33.781
-53.5 59.4 42.7422
-49.65 81.9 25.8305
-8.5 57.9 27.4328
-60 22.9 37.383
22.425 72.9 52.484875
-38 65.9 29.53116667
-6 57.9 27.4434
49.075 42.4 57.502875
13 46.9 60.2986
39.5 16.9 38.2344
-4 57.9 27.4084
-49 49.9 43.2592
-50.5 19.9 31.7664
42 50.4 53.6364
-65.5 24.4 38.4606
5.95 57.9 61.31325
-67.05 56.4 44.109
67.6 62.9 55.26766667
-45.8 79.9 20.221
-60 71.4 39.5778
-53 50.9 44.081
47.8 45.4 52.017
54.15 64.9 50.947
-55.6 64.4 41.855
-48 37.4 35.6438
-18.4 60.9 25.24733333
-34 54.9 30.9624
67.5 5.4 68.123
-53.5 72.4 36.3562
-1.925 38.9 27.971
-64.5 80.9 31.1656
65.5 71.4 51.822
-14.5 62.4 24.4484
52.575 57.9 53.94125
-37.2 78.4 43.501
65.2 64.4 53.129
-68 27.4 40.3594
57 41.9 62.4598
54 57.9 55.3174
-60 17.4 32.2814
65.55 65.4 53.2965
-43.5 37.4 35.59
55.5 57.9 55.9278
29.5 71.9 50.1752
60.5 38.9 60.8508
67.55 66.4 55.25125
-49.35 30.4 32.1315
56 57.9 56.0886
45.2 49.9 54.284
-64 65.9 42.5924
-33.5 57.4 30.297
49.525 66.9 48.51991667
-39.5 60.4 33.828
9.5 34.9 36.611
-43 26.9 29.4118
57.5 57.9 56.4382
-47.5 42.9 37.1044
57.925 57.9 56.53875
59 57.9 56.8194
62 57.9 57.5926
59 5.9 52.2914
23.5 75.4 51.126
-49.075 74.9 33.025125
59.5 44.9 62.1966
-69 63.4 43.584
54.475 57.9 55.52225
-25.5 60.4 29.0328
64.575 57.9 57.498625
33 47.4 54.6744
-19.8 42.9 29.971
-3 55.4 28.267
-66.5 63.9 43.4038
-70 85.9 23.7216
56.5 58.4 55.5872
-67.5 58.4 43.8804
-64 18.9 33.802
-48.925 57.4 36.5015
65.65 31.9 61.74
71.5 54.4 75.175
-65.5 58.4 45.1284
-65.025 11.9 24.51966667
-28.5 52.4 31.4262
-47.5 58.4 36.2128
-53 29.9 39.4024
-63 58.4 45.1266
3.575 47.4 27.99775
-52 30.4 39.6968
-39.975 63.4 45.99158333
-57.5 58.4 45.1678
65.55 2.9 63.1195
-44.975 77.4 21.00108333
66.9 4.9 67.42633333
-61.5 76.9 35.3184
-62.5 61.9 44.1422
-55.45 58.4 43.023
-41.35 79.4 32.226
-54 58.4 42.9804
-58.5 87.4 20.7628
-51.5 58.4 42.9852
-16 52.9 28.6644
25.925 44.4 55.964875
5.925 57.4 61.4475
-45 62.9 34.4636
-51 58.4 42.9822
-54 20.9 32.4302
-23 48.9 31.6222
57.5 43.9 62.2378
-41.2 54.4 32.776
4.025 63.9 60.48783333
45.475 51.4 53.514
-24.5 45.4 31.4122
-63 70.4 40.1726
7.35 63.9 59.1425
-55.7 13.4 25.36
-57 44.9 46.115
3.1 50.4 47.833
48.4 49.4 62.948
63.6 28.4 63.647
-43.7 58.4 36.451
-11.95 48.4 27.883
31.5 24.9 35.097
-41.6 59.9 42.707
-35.4 40.4 27.477
70.9 55.4 69.104
-41.55 58.4 39.2695
69.4 23.9 82.723
46 68.9 48.6082
30.5 43.9 54.8516
-40.95 58.4 40.8595
33.5 22.4 36.1038
-66.425 57.9 44.916875
-12.5 52.9 28.7278
-40.375 58.4 42.379
-39.5 43.9 33.2834
28 24.4 34.7506
-4.4 48.9 28.93166667
45.3 45.4 44.934
-69 21.4 36.1126
-40.2 58.4 42.84
-24.5 58.4 29.903
27.5 73.9 50.9254
-62.5 63.9 43.4116
-46.65 78.9 19.3275
-39.65 58.4 44.287
42.65 13.4 38.7655
-49.7 77.9 31.236
-39.4 58.4 44.67733333
65.025 33.4 61.98483333
-35.45 58.4 26.873
-47.5 40.9 36.6752
3.5 60.4 61.266
-65 16.9 31.6326
-33.5 58.4 29.9624
-66.975 82.9 28.52166667
36.5 69.4 47.8346
24.15 26.4 34.1005
64.95 49.4 57.305
-28.5 58.4 29.8964
-53.5 83.9 23.1596
58.075 58.4 56.003125
-17.2 58.4 24.187
60.025 52.9 57.50833333
-47.5 55.9 36.8646
-15.05 58.4 19.25575
-49.35 70.4 29.7905
16 33.4 36.8074
-54 18.4 29.7868
-14.8 58.4 19.715
-63.5 64.4 43.187
-14 58.4 21.31433333
-52.5 40.4 43.2284
-63 84.4 26.357
-62 12.9 26.2214
-50.5 39.4 43.0442
-11.8 58.4 26.125
54.95 75.4 56.10875
-52.35 12.9 21.958
-55.7 38.9 44.73
-10.65 58.4 26.3785
-9.5 58.4 27.1276
-34.2 57.4 29.929
-61.5 29.4 41.4958
-5.5 58.4 27.2822
18.2 31.4 36.493
-34.35 58.4 28.7495
-12.65 57.9 24.2835
58.5 38.4 59.4142
66.35 73.9 49.9335
46.7 50.4 53.809
3.05 58.4 61.20575
4 58.4 61.8392
62.65 42.9 63.0035
-62 87.4 20.731
62.3 7.9 60.455
-66.5 86.4 22.834
6.15 58.4 60.9255
-48 44.9 39.268
38.5 49.4 53.8496
67.075 74.4 50.465
66.2 16.4 70.518
52.5 70.9 49.4786
59.025 69.9 52.52508333
27.525 24.4 34.518
10 46.4 60.3684
60.5 61.9 43.4264
34.025 23.4 35.53741667
-42.55 57.9 36.2315
49.4 50.4 62.305
-43.95 83.9 10.704
-19.8 46.9 28.169
-46 29.4 31.417
46.6 66.9 47.43066667
26.925 45.4 55.98525
-49.7 75.4 33.685
35.95 18.4 36.30775
-62 70.4 40.1772
60 43.9 62.8112
-47.5 71.9 28.7634
-68.5 32.4 42.8916
-6 55.9 28.1074
-59.5 71.9 39.2356
-44 42.9 37.1126
4.5 52.9 61.2752
53.1 64.9 49.911
55.5 58.4 55.2238
-38.35 42.9 32.4075
-47.5 74.9 33.1234
51.5 50.9 66.7094
-5.5 52.9 28.8348
70 16.4 71.7992
41.425 70.9 48.4985
61.65 6.9 59.791
7.8 45.4 28.331
52.45 56.4 54.1305
46.1 42.4 56.11333333
60.5 52.9 57.6126
-56 69.9 40.4242
56 73.4 50.0856
59.925 58.4 56.526375
-64.5 10.9 22.6462
65.5 58.9 56.01616667
60.5 58.4 56.6968
62.4 58.4 57.13533333
18 74.9 53.2944
-45 50.9 37.54
-52.5 14.4 25.118
-39.1 44.9 33.515
-62.5 65.9 42.5616
64 58.4 56.6766
51 56.9 54.849
41.5 70.4 48.6506
64.5 58.4 56.833
65.075 58.4 56.999875
-56.5 16.4 31.2106
-62.5 86.9 21.8438
-4 43.4 28.3848
70.5 54.4 62.883
-13 53.9 28.6058
-30.925 46.4 31.003625
-57.5 57.9 45.2726
-41.65 22.9 25.188
59 61.9 43.3232
-62.5 33.9 43.5066
-58.5 43.4 45.8998
-68.5 87.9 19.3146
63.65 71.9 49.793
-64.5 58.9 45.0542
-70 76.9 35.1786
47.5 44.9 51.25
58 41.9 62.5942
-4.4 55.4 27.78933333
-58.5 74.4 37.4668
-62.5 39.4 45.1954
-61 58.9 45.003
-59.5 63.4 43.6206
-53.5 74.9 34.3914
67.3 13.9 69.436
-14.65 42.4 28.193
-48.5 77.4 31.753
-38.8 79.9 38.167
-60 58.9 45.0418
-39.35 78.9 37.6805
-58.5 58.9 45.0358
24.5 29.9 33.381
65.1 6.9 67.24733333
52 38.9 57.208
-39.05 68.4 45.75675
26 71.9 51.1356
-52.5 32.9 40.8196
-68 59.9 43.8518
-29 52.9 31.3104
-35.575 49.9 28.922625
-56.5 10.9 22.7798
-13.2 57.4 22.823
63.15 60.9 48.334
-57 58.9 45.0394
-55.7 53.4 45.727
69.8 24.4 80.591
65.6 61.9 53.78166667
-32 56.9 30.4192
-41.15 50.4 34.6285
-60.5 13.9 27.7472
-54 58.9 42.8646
-14.5 53.9 28.6144
-54.5 58.9 42.8822
-45.5 56.9 36.6216
-36.575 75.9 44.418125
-42.6 38.4 35.93933333
62.65 57.9 57.3445
-65 32.4 42.9058
-65.5 64.9 43.007
-46 62.9 34.4834
51 45.9 57.6156
-54.5 74.4 34.8044
-51.475 77.4 32.02175
4 47.4 27.6674
-47.5 58.9 36.0576
-63.5 58.9 45.0098
35 42.4 50.4326
-5.3 61.4 26.169
-47 58.9 36.0596
17.5 73.9 54.3246
-67.025 11.9 24.518
-45.5 31.9 33.065
-63 49.4 46.2288
-69.5 50.9 43.6794
2.5 36.4 32.314
-14.5 61.9 24.4484
-28.5 48.9 31.6498
-7 51.4 29.036
-58 81.9 29.9964
-58.5 84.4 26.4646
-32.5 52.9 31.3232
-7.5 57.9 27.4166
-43.525 58.9 36.5015
71 23.9 91.437
12.925 70.4 56.462
-62.5 69.4 40.7512
-40.875 58.9 41.902
60 61.4 43.3954
51.9 8.9 45.44
-37.1 31.4 24.23033333
-37.3 63.9 24.133
-40.375 58.9 42.92825
-44.5 22.9 26.3022
64.9 30.9 65.521
-10.425 49.4 28.86975
38.35 43.9 50.92
-47.5 66.9 32.2878
51.45 70.9 42.41475
-39.95 58.9 43.7995
71.9 49.4 84.059
-39.65 58.9 44.414
34 47.9 54.4762
60.5 33.4 61.1544
70.5 73.4 52.4412
52 54.9 56.154
-39.4 58.9 44.73666667
-39.1 58.9 44.2
-42.15 78.9 30.6495
-35.5 58.9 26.7484
-34.8 58.9 27.668
-49.5 71.4 31.597
-55 47.4 44.1068
4 50.4 52.633
-26.35 60.9 28.8215
42 17.9 38.1924
-34.5 58.9 28.274
72.3 51.4 83.069
-59 44.4 46.0422
-50.5 66.4 40.086
-48 28.9 31.061
-28 58.9 29.7294
-24.5 58.9 29.7146
-24.5 57.4 30.2602
-23.5 58.9 29.706
70 71.4 53.2002
-21.5 58.9 29.7046
-50 37.4 42.45
-40.55 84.4 19.708
9.5 35.4 36.2808
-21.1 58.9 29.4115
-20.4 58.9 28.268
-20.1 58.9 27.78333333
-17.55 58.9 25.3375
-16.8 58.9 22.953
9 69.9 58.269
-48.5 51.9 37.4632
-55.7 50.9 45.884
-57 72.4 38.9208
-16.45 58.9 22.39475
-58 54.4 45.887
-49.975 28.4 38.50441667
-39 74.4 44.7604
-37.7 64.9 29.687
-13.375 58.9 22.381
-12.7 58.9 23.845
-20.9 59.4 28.78566667
-7.5 53.9 28.6248
-12.3 58.9 24.724
-50 78.4 30.8984
-8.575 58.9 27.018
-4 58.9 26.9826
42.5 51.4 53.687
9.425 67.9 58.428125
-26 60.9 28.8212
58.3 75.4 54.33
-55.7 52.4 45.851
59.85 7.9 51.686
-69 70.9 39.8526
55 65.4 51.591
-53.5 77.9 31.5114
-3.575 58.9 27.02625
-41.85 47.9 40.1785
61.5 39.4 61.725
-1.15 58.9 26.8525
-60 80.4 31.7718
2.8 58.9 60.9
68.8 24.4 72.133
-0.15 51.9 29.039
-45 62.4 34.655
25.45 29.4 33.4185
58 44.4 62.1386
42.65 41.9 53.604
35 43.9 54.8416
72.525 43.4 63.50808333
63.45 59.4 54.727
-25 46.9 31.5588
-53 35.9 42.021875
-64.5 83.4 27.8406
-16.5 60.4 24.7482
68.45 12.4 70.95775
36 21.4 36.56
3.625 54.4 60.97225
-62.5 52.4 46.0878
29.5 25.9 34.9182
-58.5 40.4 45.427
60.975 41.4 63.02466667
56.5 58.9 55.5872
-54.5 13.9 24.7114
64.85 8.9 66.751
38 72.4 48.34
72.6 51.4 86.915
59.925 58.9 56.526375
-37.5 68.4 24.619
-30.425 59.4 28.964875
62 58.9 56.3546
4.5 37.4 32.81
46.8 44.9 48.972
-58.5 18.4 33.3494
-65 66.9 42.0734
61.975 59.4 55.02283333
63.4 58.9 55.44933333
-16.9 58.9 23.256
-8.475 51.9 29.02016667
63.65 58.9 55.6155
66.5 48.9 59.8174
71.7 54.9 79.07
-14.4 47.9 18.772
55.85 6.4 48.912
-45 75.4 24.3184
57.5 65.4 52.0752
41.95 18.4 37.57575
-47 33.9 34.1574
-48 43.9 37.2486
64.2 26.9 65.66
45 51.9 53.6604
66 5.9 67.7774
30.5 42.9 51.9256
-43.8 79.4 26.214
-58.5 42.9 45.839
13.2 47.9 59.46
-66.7 59.4 44.436
64 61.9 45.671
66.45 10.4 68.6845
-62.5 59.4 44.8814
-2.9 65.9 29.787
23.5 46.4 56.4728
7.5 66.4 59.659
-51.5 66.9 39.8024
52.975 9.9 45.51208333
-58.5 20.4 35.2764
57.5 52.4 56.7516
-61.5 59.4 44.874
-52.5 68.4 38.9408
-59.5 69.4 40.7612
-55.45 34.4 41.5065
-62.5 71.9 39.2346
-33 48.4 31.6478
-39.2 79.4 37.497
-60 59.4 44.8774
-9.5 46.9 29.0992
70.425 71.9 53.025125
63.1 36.9 55.044
55.5 58.9 55.2238
10 31.4 36.892
-6 58.4 27.2666
-53.5 33.9 41.2716
-46.55 79.9 18.303
62.95 65.4 52.74125
58.5 72.9 48.448
-47 59.4 35.8896
59.4 8.4 51.253
-12 46.9 28.1092
-56.5 52.9 46.0502
-43.5 59.4 36.3762
-54.5 73.9 35.2126
-43.1 56.9 37.24266667
62.5 57.4 57.8172
1.65 38.9 30.598
-15.3 59.9 22.147
-61 44.9 46.08
20 28.9 34.0922
-16.6 57.4 21.366
-39.85 59.4 44.152
72 50.4 80.024
34 72.9 49.302
-39.1 59.4 35.484
66.5 66.4 53.8124
15.425 46.9 58.964
-38.5 59.4 31.8098
-4.5 60.9 25.956
66.525 35.9 65.52133333
-8 45.4 28.9026
-62 72.4 38.8964
-37.35 59.4 30.478
-36.65 83.9 32.168
-68 54.9 43.8012
-37.15 59.4 30.3205
-64.5 53.9 45.9456
60.525 6.4 53.50258333
63.5 36.9 59.376
64.7 49.4 56.705
-34 59.4 26.8516
43.3 14.4 39.99
-61 59.9 44.7336
65.05 25.9 69.1825
-31.5 59.4 28.3294
61.5 38.9 60.9094
-35.05 49.4 29.3285
-55.8 14.4 27.97
61.95 6.4 60.83
-31.2 59.4 28.507
-48 39.4 36.2928
-30.95 59.4 28.6545
-49.35 31.9 33.1745
-55.025 39.4 43.02541667
-44.2 83.9 9.968
-58.5 79.9 32.3546
45.5 14.9 40.445
-24.5 59.4 29.5016
-43.65 77.9 20.3945
-58 80.4 31.7758
-23.925 59.4 29.524125
41 69.4 48.6012
-49.925 73.4 35.526375
64 70.4 52.7174
-20 59.4 27.291
11.05 36.4 35.915
-37 77.4 44.3732
-69.5 14.4 28.424
-66.5 74.4 37.3766
-39 61.4 31.324
-19.15 59.4 26.4075
67.3 48.4 60.486
-42 30.4 32.0814
-26.475 59.4 29.51858333
-18.9 59.4 25.81133333
65.3 14.9 67.425
36 50.4 53.3146
-16.85 59.4 24.405
36 22.4 35.6748
-59 30.4 42.0354
-16.4 59.4 23.768
-64 49.4 46.2354
71 55.4 70.408
-16.05 59.4 22.9275
67.1 2.4 63.706
72.4 55.4 88.643
-55.45 29.9 39.42525
-47.5 70.4 29.6464
-10.3 59.4 26.623
-61 39.4 45.188
-55 48.4 44.123
-49.35 43.4 37.1485
-8.5 59.4 26.6014
2.5 44.9 28.2166
-61.5 70.9 39.8856
-49.35 71.4 28.837
-66 65.4 42.7472
-6.5 59.4 26.6912
68.35 5.9 69.4045
32.7 20.4 34.841
3.5 46.9 28.066
-65.95 48.9 46.09875
61 51.4 55.85
52.8 52.9 55.12
-68 59.4 43.8356
30.5 23.9 35.439
64.9 30.4 66.606
-45.2 78.9 22.947
-3.491666667 59.4 26.52375
-66 36.4 44.3584
-41.15 44.9 41.284
-59 26.9 40.135
2.85 59.4 60.941
-69.5 71.9 39.2146
-35.6 38.9 26.875
-60 79.9 32.349
-61.5 49.4 46.2302
-5 52.4 28.9052
-33 59.9 27.254
53.3 57.4 55.417
-3 46.9 29.062
-59.5 59.4 44.875
3.1 59.4 61.22066667
61 56.4 58.1912
-52 31.9 40.413
3.475 59.4 61.51025
-24.5 55.4 30.8268
44.6 41.4 54.612
64.3 3.9 63.483
4 59.4 61.7642
33.7 43.4 53.429
70.5 74.9 51.875
60.6 30.9 60.163
3.1 57.9 61.24333333
-68 43.9 43.5706
-13 58.4 23.298
-57 58.4 45.158
-66 40.9 45.3622
-21 46.9 29.414
5.525 59.4 61.47125
6.1 59.4 60.73466667
6.3 59.4 60.306
52.6 73.9 50.06766667
-56 48.9 46.2246
-43.05 23.9 27.15275
47.15 49.4 61.8605
-49.4 72.4 34.63
65.7 15.4 68.659
2.65 59.4 60.6535
-59 45.4 46.132
59 66.9 52.9686
-2.5 56.4 27.9728
-68.5 64.9 42.99
64 41.4 62.9086
47 69.4 48.5696
-4.35 65.9 23.7395
-17.5 61.9 24.6938
-66.5 58.4 44.765
-12.4 52.4 28.67333333
20 43.4 57.246
-48.5 43.4 37.0922
-66 19.9 34.7606
-66.45 41.4 45.19425
70.6 50.4 64.507
54.5 59.4 53.3386
-65 46.9 46.1984
-1.5 59.9 26.3818
54.85 59.4 53.4815
52.1 39.4 57.25233333
10.5 70.4 57.7782
57.5 59.4 54.8336
-39.9 56.4 43.30866667
59 59.4 55.3554
35.5 44.9 56.302
-46.5 55.9 36.8604
-53.5 49.4 44.1222
-49.7 21.9 33.413
12.1 69.9 56.90333333
-68.5 66.9 42.0742
-49.6 58.9 42.494
71.5 42.4 63.9756
72.4 54.9 88.465
0.5 45.4 28.7788
-24 50.4 31.5666
60 59.4 55.73
-38.9 59.4 31.78933333
-24 60.4 29.0468
-39.2 45.4 34.683
5 59.4 61.8552
-53 49.9 44.1036
-27 48.4 31.6496
63.85 59.4 53.2805
-68.5 51.9 43.7138
-65.5 42.9 45.7916
45.5 51.9 53.6552
-31 58.9 29.6948
64.7 59.4 52.797
-67 36.9 43.704
-47 67.4 31.9506
-37.35 57.4 27.6605
-52.5 42.4 43.6244
46.45 45.4 48.205
15.5 72.4 55.371
-40.1 61.4 43.007
29 71.4 49.7446
10 49.9 60.7246
66.4 30.9 67.955
-41.8 79.4 31.127
67.35 9.9 70.879
-67 17.9 32.7212
-8.5 52.9 28.7998
64.8 3.9 64.006
-57 25.9 39.537
-46.5 69.4 30.4594
-29.65 59.9 28.9605
-49.7 36.9 42.136
66.8 14.4 68.997
-55 59.4 42.7358
-55 62.9 41.5792
-70 59.9 43.8054
-64.5 27.9 40.6876
-68.5 59.9 43.819
-52.5 29.9 39.3772
-66.5 76.4 35.7384
-28.5 54.9 30.9676
70 71.9 53.001
52.65 54.4 54.9775
-49.55 44.9 43.42
65 54.4 58.443
51.6 70.4 41.809
-66 59.9 44.6956
65.6 6.4 67.69566667
59.5 63.9 50.2892
61.5 70.9 52.4172
17.5 27.9 33.33166667
-5.5 60.4 26.2858
-47 59.9 35.7294
-65.5 45.9 46.109
-63.5 59.9 44.751
-64 42.4 45.7712
-63 59.9 44.76
-55.7 59.4 44.626
-57 59.9 44.7838
-17.975 61.9 24.97266667
-68 47.9 43.6658
-3.925 50.9 28.884875
-55.6 59.9 44
2.8 50.9 46.896
-61.575 72.9 38.519875
-25 44.4 31.32
48.5 51.9 53.3706
-20.15 48.9 26.6185
21 29.9 34.3382
-55.4 59.9 42.587
-67.5 59.4 43.9066
-50.5 59.9 42.5508
55.075 44.4 61.491125
-55.7 54.4 45.536
55.425 43.4 62.051625
-45.5 59.9 35.6876
-41.4 47.4 40.75566667
63.625 6.4 64.391
36.425 21.4 36.504625
-66.5 80.4 31.7132
-43.5 59.9 36.1206
24 44.9 56.7756
-68.5 68.4 41.2954
17 74.9 53.3576
12 34.4 37.331
-38.35 78.9 40.2005
-40.6 59.9 39.484
-66 20.9 35.6326
-53.5 70.9 37.4418
-40.4 59.9 38.381
14 32.4 37.2224
55 38.4 59.4274
-40.2 59.9 37.284
-64 12.9 26.2298
-10.95 57.4 27.4825
-40.1 59.9 36.738
-39.8 59.9 35.108
59.575 5.9 52.917875
42 44.9 52.9142
-30.5 58.4 29.924
-48 68.4 31.2214
-39.4 59.9 32.891
-8 44.9 28.819
-37.7 59.9 31.234
65 45.9 61.6306
-37 59.9 29.505
-57.5 66.4 42.3622
-20.2 55.9 25.982
63.8 30.4 62.688
65.15 59.9 53.67
-35.925 59.9 26.54825
-32 59.9 27.763
-46.6 22.4 31.68666667
-42.85 56.4 37.717
-49.65 62.9 41.2375
-10.7 57.9 26.848
51.65 39.9 58.3385
46.9 14.9 40.03966667
69 42.9 63.6354
57 44.9 61.6164
-66.7 38.9 44.36
42.5 69.9 48.6196
-23.5 59.9 29.2874
6.1 68.4 58.5085
60 7.4 51.2768
-63 28.4 40.9818
-19.65 59.9 26.7085
-18.5 59.9 25.4308
-28.5 47.9 31.6528
6.35 61.4 60.302
51.5 41.9 59.174
-16.9 59.9 24.93233333
-21.075 50.9 31.48275
-49.65 36.4 41.83
-15 59.9 22.0948
-11.95 59.9 25.29
65.2 72.4 48.614
6.5 65.9 60.1512
-11 59.9 25.4626
27 47.4 55.1714
64.55 25.4 68.2245
-10.7 59.9 25.474
6 61.4 60.7964
-4.525 50.4 28.90383333
67 26.4 69.7926
-10 59.9 26.5394
70.5 53.9 62.039
-9 59.9 26.5916
58 38.9 60.6526
-7 59.9 26.5924
-48.5 66.4 32.6324
65.2 9.4 67.255
-49.3 45.4 42.392
-1.05 57.9 27.42275
-15 61.4 24.5424
-66.5 37.4 44.3246
-6.5 59.9 26.6026
60 45.9 61.3492
-6 59.9 26.6066
-4.5 59.9 26.243
63.7 9.4 61.671
-33.975 44.4 28.98916667
49.5 53.9 53.8642
-62 13.9 27.7432
-36 42.9 28.4366
-63 83.4 27.8698
-3.5 59.9 26.3924
-20.9 46.4 28.064
2.9 59.9 60.89633333
-53 65.4 40.517875
-36.5 41.9 28.1748
4 59.9 61.6438
-41.65 78.4 32.244
67.5 25.9 70.1302
-59.5 61.4 44.3326
58.075 50.9 54.503125
51.575 7.9 44.914375
6.4 59.9 59.649
61 39.9 62.1782
39.5 71.4 48.6196
-62.5 31.4 42.4804
65.5 66.4 53.3424
61.15 60.9 43.771
6.5 59.9 58.304
51.05 55.4 55.77425
-44 56.4 36.6304
64.5 67.4 53.5836
-14 61.9 24.447
61.3 8.9 56.896
-65.5 50.9 46.1364
70.7 50.9 62.504
-41.55 84.4 16.8045
57 53.4 57.2922
-68 81.4 30.5108
35.65 18.4 35.9075
-49.6 78.9 28.998
64.45 59.9 51.7895
-65.5 23.9 38.1032
42 50.9 53.7028
-17.65 48.9 28.1135
-7 52.9 28.7958
64.95 59.9 53.2335
-52.5 50.9 44.0644
-46 76.4 22.9656
-60 36.4 44.4216
-67.5 85.4 24.6866
-19.4 61.4 25.433
-46 68.4 31.2096
-56.475 87.9 19.48883333
65.3 59.9 53.987
13.375 69.9 55.40675
62.6 3.9 61.28733333
65.7 59.9 54.668
-69.5 65.9 42.5306
-47.65 44.9 38.342
54.55 49.4 59.2705
7.85 41.4 25.7525
58 40.4 62.0934
42.35 67.9 47.48
60.5 43.9 62.9182
-2.2 66.4 32.331
-49.35 24.4 27.705
-63.5 87.9 19.5072
51 44.9 59.317
-51 52.9 43.9146
-3.925 50.4 28.924875
2.7 58.4 60.758
-46.5 70.4 29.6792
57.5 39.4 61.266
-38.5 67.4 28.8602
10.475 31.4 37.0245
-46.85 79.4 18.7315
-11.5 45.4 28.857
-16 61.9 24.487
-63.5 26.9 40.0944
1.5 43.4 28.3676
-54 47.4 44.0968
-46.575 60.4 35.515125
62.5 61.4 43.6186
48.4 44.4 55.72566667
-43.65 49.4 37.118
-49 59.9 35.7476
-57.5 48.4 46.2444
70.7 52.9 63.907
-38.5 61.9 31.3034
27.45 23.9 33.8275
30.2 73.9 50.012
-69 75.4 36.576
-55.7 60.9 44.164
-67.2 60.4 44.011
-19.6 43.9 30.12933333
-65.5 60.4 44.598
26 72.9 51.3792
56.5 35.9 52.6074
-64 60.4 44.6024
-40.35 57.4 41.7355
-41.525 63.4 46.00933333
-69 12.9 26.2042
-59 60.4 44.6018
-57 60.4 44.618
58.425 74.4 51.4965
3.35 54.9 60.804
-61 44.4 46.0282
-66 86.4 22.8472
-55.8 60.4 44.476
68.075 49.9 60.470375
-53 71.4 37.0964
-59 32.4 42.9376
57 69.9 52.0886
-45.5 50.9 37.5376
58.15 4.4 50.2985
57 63.9 50.3326
-63 54.4 45.8602
-41.025 65.9 46.02091667
-27.5 47.4 31.6158
-51.5 60.4 42.4184
-44 50.9 37.2276
-61 53.4 45.996
64 58.9 55.7456
32.55 70.4 48.363
-61.5 26.4 39.8264
-35.6 63.9 23.777
-49.7 60.4 42.194
-56.5 48.4 46.2622
35.5 73.4 48.2616
37.5 17.9 37.3226
41.525 68.4 48.02916667
51.475 43.4 58.99866667
-57.5 50.9 46.1908
-49 60.4 35.5428
-7 45.9 28.9528
64.6 35.9 64.672
65.075 4.4 64.940375
-14.7 58.4 19.916
-33 52.9 31.3254
-69.5 51.4 43.7096
-44.7 60.4 35.483
-54.5 50.4 44.102
-39.5 33.9 30.32666667
-44.45 60.4 35.6305
-55 52.4 43.9832
-4.6 66.4 22.652
3.4 65.9 55.923
6.5 44.9 28.195
-65 78.4 33.8218
72.8 53.9 91.012
-66.4 49.4 45.83
7.1 64.9 59.929
-69.5 34.4 43.2254
3.6 65.9 56.76
-58 71.9 39.2554
-40.3 60.4 44.122
-40.2 60.4 42.773
-30.5 55.4 30.8314
-39.7 60.4 36.327
-63 79.9 32.3214
-45.5 45.4 37.4182
-39.4 60.4 32.452
-61.5 30.9 42.2562
-18.5 56.9 27.757
-38 60.4 31.2744
-12.9 59.9 23.879
-12.8 61.4 24.626
2.9 50.9 47.473
-37.15 60.4 30.297
68.525 8.9 71.96225
-36.5 60.4 26.3112
-35.65 60.4 26.16
-34.5 60.4 26.262
-54.5 37.9 42.6288
51.7 72.9 50.166
55.35 73.9 53.0175
-62 26.4 39.8124
-56 70.9 39.7816
-53.5 82.9 24.9224
-46 62.4 34.7532
66 36.9 65.2194
55.05 72.4 48.4545
-63 85.4 24.777
22.5 74.4 52.43
-33.9 81.4 42.44433333
-61.5 81.9 29.9552
-45.65 20.9 26.6355
68.475 51.9 60.022
-63.5 69.9 40.4672
-12.5 57.4 25.828
-59 73.4 38.165
-34.35 84.4 37.7465
-53.8 84.4 21.929
62.15 50.9 52.4775
46.45 49.9 58.7385
61.475 68.9 53.51808333
-26.5 60.4 29.0318
-44.425 59.4 35.995
48.525 12.4 42.51225
56.025 67.4 52.52658333
-68 50.4 43.7128
-56.5 76.4 35.7882
-3.975 49.4 28.85491667
71 50.9 66.371
62.85 35.4 52.993
-68 60.4 43.8564
-21.15 55.9 30.703
-62 79.9 32.3058
-21.1 60.4 28.822
-56.5 57.4 45.382
-67 15.4 29.8274
53.6 69.9 50.535
-20.4 60.4 27.738
-68 48.4 43.6746
-68.5 71.9 39.2066
-34.3 54.4 31.022
-18 60.4 25.234
-29 46.4 31.5444
37 49.4 54.0616
-17.5 60.4 25.1692
64 60.9 50.269
-43.075 43.9 36.920125
-14.3 60.4 23.973
19 72.9 53.8888
-14 60.4 23.7328
-56.5 80.9 31.2408
67.9 34.4 64.52466667
-63 16.9 31.6842
-13.4 60.4 24.32233333
65 57.9 57.6034
-6.5 60.4 26.2774
-2.5 60.4 26.2592
3.975 60.4 61.5415
-56.5 22.9 37.3924
5.425 60.4 61.405625
-61.5 31.9 42.7144
45 13.9 40.8364
-64 84.4 26.3828
-60 36.9 44.5574
5.9 60.4 60.916
-34.3 50.9 31.446
-55.45 73.9 35.21775
61.35 58.9 56.8485
12 30.9 36.9292
6.3 60.4 60.284
-54.5 57.4 43.2212
6.5 60.4 58.722
51.1 8.4 44.68133333
-39.1 45.4 33.878
-59.5 74.9 37.0024
-62.5 24.9 38.8152
-11 58.9 25.8966
-65.5 10.9 22.6266
-45.5 72.4 27.7874
-51 53.9 43.8094
62.5 58.9 56.3732
51 44.4 59.7212
-39.85 78.4 36.793
-36.35 31.4 22.76
37.5 47.4 53.12933333
59 67.4 53.0568
-67.2 50.9 43.834
60.15 3.9 52.726
59.5 36.4 52.9134
-67.5 30.9 42.2296
46 51.4 53.4468
-59 76.9 35.3324
14 47.4 59.3416
56 43.9 61.9618
65.2 60.4 53.608
-39 48.4 33.01016667
-68.5 88.4 18.0406
-57 74.9 37.0304
5 35.4 36.7362
68 53.4 59.2208
63.6 35.4 57.268
-37.875 78.9 41.39275
71.5 44.9 63.0982
4.8 51.9 61.003
-32.65 83.4 41.3675
-55.7 58.4 44.843
-39.075 41.9 32.907625
-65.5 64.4 43.1808
57.8 73.9 50.208
-31.5 85.4 40.4198
-65 35.4 44.0758
-55.925 58.4 45.0335
-46.625 72.9 31.93
-62 41.4 45.6004
11 70.9 57.4664
62.85 32.9 60.3685
-39.05 65.4 45.30625
-51.5 64.4 40.9486
-54.5 28.9 38.9012
45.8 69.9 50.743
-70 60.9 43.822
0.8 50.4 35.45
43.45 12.9 39.387
-46.5 73.4 31.643
58.9 72.9 47.119
-16.5 46.4 28.0902
41.5 43.4 53.3246
-47.5 61.9 34.9384
-64.5 60.9 44.4344
-65 83.9 27.122
57.5 40.9 62.254
-62 28.4 40.9942
-37.7 49.9 32.674
-62.5 60.9 44.4492
-58.5 78.4 33.8978
-54 74.9 34.3884
-60.5 60.9 44.4708
6.425 63.4 60.44
-59.5 60.9 44.4658
54.575 74.4 54.49675
53.8 73.4 50.074
-55.6 60.9 43.633
-64.5 80.4 31.7016
-18 46.4 28.148
-69 86.9 21.6976
-58 65.9 42.5548
-62 44.4 46.0346
-52 60.9 42.2612
-47 26.4 29.0626
-51 60.9 42.2652
8.9 36.4 34.035
-54 47.9 44.0934
-64 56.9 45.4612
-46.5 60.9 35.3314
52.2 46.9 56.673
-41 60.9 45.9022
6.2 60.9 60.506
63.5 56.9 57.9426
-61 40.9 45.4768
-39 53.4 32.534
-49.35 66.9 32.2915
55.075 39.4 60.98775
-54 14.9 26.3178
-40.1 60.9 33.717
-21.05 60.9 28.2305
3.5 48.4 27.824
44 14.9 40.211
-61 51.4 46.1552
-3.5 47.4 29.0726
-60.5 16.4 31.1288
54.5 53.4 56.7488
-59 58.9 45.0408
-56.5 28.4 41.0368
-60.5 69.9 40.4558
-63 27.4 40.3896
-19.6 60.9 26.39566667
-62 78.9 33.3764
-59.5 39.4 45.2158
-46 44.4 37.3324
13.5 70.9 56.255
-68 80.9 31.103
-51.5 51.4 44.0444
40.025 71.4 48.52475
-41.575 34.9 34.49925
-35.7 58.4 27.114
-49.5 25.4 35.149
-54 53.9 43.83
-50 63.4 41.3172
-17.85 60.9 25.0165
-57.5 75.4 36.6526
-12.5 60.9 24.8156
-55.6 78.4 31.669
-12 60.9 24.9904
-11.45 60.9 25.0985
-9 60.9 25.669
68.5 26.4 70.3384
-46.5 50.9 37.5294
-8 60.9 25.7624
-66.5 62.4 43.9408
61.2 8.9 56.672
-28 49.9 31.6158
-55.7 11.4 21.952
-58 52.9 46.064
-5 60.9 25.9714
-69.5 56.4 43.7684
-49.7 70.9 37.204
-53 53.4 43.8306
-10.35 58.4 27.181
-2 60.9 25.7058
28 26.4 34.8798
-3.5 42.9 28.3806
63 35.4 53.45
4 60.9 61.4512
4.5 60.9 61.6744
-43.025 23.4 26.47866667
66.2 15.9 69.688
-46 21.9 27.2424
-45 39.4 36.254
-62 61.4 44.2916
-40.15 78.9 35.6565
57.5 75.4 55.8382
44.3 49.4 54.931
-38 64.9 29.9478
-46 22.4 27.249
-49 68.9 30.8462
-40.15 79.4 35.15
-17.5 54.9 28.3854
66.8 6.9 68.937
44.3 12.4 39.972
66.85 34.9 64.284
-68 22.4 36.9166
-14.2 47.4 18.686
-43.3 83.9 12.63
33.5 47.9 54.5772
-42 23.4 25.9966
-52.5 63.4 41.376
64.2 48.4 55.968
-54.5 59.9 42.5468
-58 77.9 34.401
-48.5 78.4 19.579
66.45 37.9 65.686
-69 73.4 38.152
64.7 18.4 69.353
-50.5 69.9 38.0578
65 65.9 52.9192
-43.5 51.9 36.773
-37.3 43.4 29.375
-61.5 85.4 24.7846
-23.5 54.4 31.0416
-62 46.9 46.2084
64.85 62.4 51.427
61.5 35.4 52.7082
-30 58.4 29.9546
52 51.9 55.226
-60 60.4 44.6136
-29 44.9 31.3496
-44.5 37.9 35.7808
30.65 48.9 53.584
57.075 71.4 50.4925
56.55 60.9 41.93675
49 54.9 53.252
3.15 65.9 54.877
56.925 60.9 42.030375
31.85 44.4 51.9265
34 20.9 36.2768
-61 82.4 29.3202
-51 33.9 41.245
-45 61.4 35.1268
52.5 68.9 51.0944
31.5 73.4 49.8314
51.05 54.9 55.8775
-63.5 81.9 29.9288
-19.35 45.9 28.66
-60.5 86.9 21.8496
-40 62.4 44.858
6.3 59.9 60.284
-44.55 78.4 24.8905
-50 48.4 44.0954
68.075 16.9 71.439
3.8 36.9 34.457
64.3 7.4 65.86
-47 31.4 32.7216
-12.15 62.4 24.4615
-56.5 26.9 40.1622
36.15 69.4 47.6975
58.5 60.9 42.3372
-43.2 57.4 36.96
-68 75.9 36.1778
-65.5 67.9 41.571
59.5 60.9 42.3462
-66.475 57.4 44.96066667
-55.45 40.4 43.33125
51.35 39.9 59.3635
-26.5 45.9 31.4778
-68.5 76.4 35.6904
-54 54.4 43.799
-55.4 67.9 39.30033333
-37.2 68.4 20.234
-2 55.4 28.3044
-64 24.9 38.8132
61.625 60.9 44.853
63.8 60.9 49.814
-39.5 45.4 37.065
-40 67.9 45.9572
-63.5 86.9 21.8046
-47 54.9 37.0778
64.2 60.9 50.725
56.025 70.4 51.50583333
-60.5 30.4 42.0222
-51 50.4 44.097
-18.1 60.9 25.10066667
-69 15.4 29.8304
61 61.9 43.4602
-53.5 20.9 32.424
-67.5 10.4 21.5654
43.975 16.9 38.47783333
-27 56.9 30.405
-60 17.9 32.8298
-65.5 13.4 26.9528
-3 38.9 27.1806
63.6 9.4 61.222
15.475 45.9 59.50891667
-37.2 67.9 20.87
-49.65 71.4 36.7385
67.025 15.4 70.494
35.5 20.9 36.717
-44.35 79.4 24.855
-62.5 35.4 44.1034
-61 22.4 36.9584
-63 61.4 44.3242
48.6 50.4 59.788
-61.5 61.4 44.3238
66.5 52.9 58.341
-55 61.4 42.1128
-54.5 61.4 42.1048
-66.6 59.9 44.43766667
-12.85 50.9 27.966
-49.4 61.4 35.17733333
-55.7 26.4 38.54
-21.5 52.4 31.4332
56.525 46.4 59.50516667
39.575 44.4 51.927375
40.975 44.9 52.49983333
7.5 48.4 60.7546
-40.5 61.4 45.8938
-3.5 50.4 29.0876
-69 20.4 35.208
-38 33.9 27.804
-14.925 54.4 28.521625
-40.2 61.4 44.757
-37.6 41.9 30.556
64.8 18.4 71.477
-39.5 61.4 33.194
7 34.9 36.6654
-46 56.4 36.7698
-39.3 44.9 35.03
-38.5 61.4 31.3372
56 8.9 47.3312
54.8 72.4 47.112
-37.2 61.4 30.106
-55.4 59.4 42.74833333
-35.7 61.4 25.639
-20.6 61.4 27.215
-19.525 61.4 25.705
3.2 61.9 60.626
25 27.9 34.6276
-18.65 61.4 25.725
0.5 38.9 29.753
-67.5 57.9 43.8824
3 61.9 60.389
-18.4 61.4 25.435
-36 47.9 29.2062
-18.1 61.4 25.10066667
-53 77.4 32.062
-65 13.9 27.6962
33.5 71.9 49.587
9.5 32.9 36.8818
60.85 8.4 54.3035
56 56.9 56.8796
-49.35 25.4 28.1745
-17.85 61.4 24.99
-41.15 78.9 33.152
-64.5 56.4 45.5392
-17 61.4 24.698
-51 17.9 28.817
-59.5 58.9 45.0454
-4.5 44.4 28.7214
-25 55.4 30.8182
33.5 46.4 54.21
62.5 69.9 53.2398
-15.5 61.4 24.5766
4.2 35.9 36.18
-14.5 61.4 24.4676
-2 53.9 28.6298
-68.5 75.9 36.169
14.95 71.9 55.6335
-19.55 55.4 27.867
-11 61.4 25.2502
-69 76.4 35.6604
6.5 52.9 61.9312
-40.7 79.4 33.81
-60.5 77.9 34.3748
67 46.4 61.2158
-10.5 61.4 25.271
-52.5 54.9 43.6674
59.45 8.9 52.7685
-55.7 55.4 45.389
64.9 20.9 70.73433333
-8 61.4 25.3816
-44 38.9 36.1232
63.35 32.9 59.1605
-49.5 76.4 32.62
-62 71.4 39.5656
62.85 65.9 53.1045
-5.55 61.4 25.757
-66 79.4 32.7954
-37.6 45.9 32.371
-61 59.4 44.8872
-70 73.4 38.0968
-11.7 60.9 25.012
-4.05 45.9 28.431
2.55 61.4 59.2595
54.5 58.4 54.7566
-3 43.4 28.5822
5.5 61.4 61.2728
64.95 60.9 52.8675
-60 70.9 39.8976
-4.5 44.9 28.7678
6 36.4 36.3994
-60.5 35.9 44.2468
60.65 5.4 57.708
-60 62.4 43.9634
49 10.4 43.2868
-50.5 47.4 44.1002
10.975 48.9 60.50675
-66.5 76.9 35.2812
-61.5 56.4 45.5306
7.6 64.9 59.36433333
62 63.9 51.2656
57.9 75.9 57.506
53.15 67.4 50.258
71.3 51.9 70.88
5.075 56.9 62.018625
-55.45 53.4 43.90025
57.5 61.4 43.0982
63.9 72.4 48.34
58 61.4 43.1918
-48 49.4 43.6566
53.3 74.4 51.54
-64.5 86.9 21.7736
61.5 61.4 43.5088
6.4 59.4 58.825
-68 75.4 36.581
61 61.4 43.4602
62 61.4 43.5542
5 63.9 60.8784
-66 73.4 38.1594
65.5 20.4 71.62
63.5 61.4 43.685
34.5 21.9 36.3574
68.075 11.9 70.92325
65.15 61.4 53.145
1 50.4 36.537
-33.025 57.9 29.99933333
-29.85 86.4 38.964
-60.5 68.4 41.3526
65.9 61.4 54.365
-70 12.9 26.158
-11.5 44.9 28.8458
-47 78.9 18.6528
66.6 61.4 55.16566667
-39 59.9 31.7284
66.9 61.4 55.42933333
67 35.9 65.817
-5.5 57.9 27.4246
68.35 61.4 56.408
-65 46.4 46.1714
52.3 50.9 66.535
-46.65 19.9 31.4345
59.8 4.4 52.996
59.5 35.9 52.9134
59 67.9 53.0568
37 71.9 48.9444
60.9 3.9 57.79633333
-12.5 51.9 28.1638
-7.5 50.4 29.0604
-3 47.9 29.0936
39.8 15.4 37.494
-58.5 69.9 40.4732
-2.2 65.9 32.686
-10 61.4 25.2934
-70 58.9 43.7892
-18.7 49.9 28.943
-50.5 23.4 35.1
-63 73.4 38.154
-67.5 61.9 43.9008
54 73.9 53.0066
-66.975 61.9 44.01141667
-34.025 60.4 26.51583333
-38.65 43.4 32.6025
-60.5 75.4 36.6264
-66 61.9 44.1184
60.375 5.4 54.0065
-41.5 42.4 35.3
-36 61.9 25.4856
-34.475 59.9 26.50275
-38.8 47.4 32.951
-20.5 53.9 26.0386
-64 61.9 44.141
-12.55 47.9 25.7465
-61 61.9 44.1516
-55.45 14.9 26.9465
-65 36.4 44.4054
64.45 68.4 53.65325
-51 20.4 32.1908
-60.5 61.9 44.161
-26.5 48.9 31.615
-67 66.4 42.3334
-61.5 32.4 42.9198
-59 64.4 43.2088
-56.5 61.9 44.1562
29.5 73.4 50.4396
70.5 71.4 53.2332
-66.45 58.9 44.71075
-59 60.9 44.4644
-35.7 65.4 22.662
-13 50.4 27.1884
-49.35 61.9 34.956
-48.5 61.9 34.9354
-70 61.4 43.8238
14.5 43.9 58.6616
-47 61.9 34.948
-38.95 56.4 32.53475
-52.5 36.9 42.3612
-19.9 58.4 27.847
66.8 75.4 49.165
-39.2 61.9 31.697
66.15 37.9 64.8175
-14.5 51.9 28.101
5.1 46.4 27.90233333
-40.2 57.9 42.922
19.45 33.4 34.9335
-44 28.9 31.027
-38.1 61.9 31.13233333
45.5 44.9 44.716
-37.9 61.9 30.868
-68 49.4 43.7002
-49 22.4 31.8384
-35.35 61.9 24.9775
64 25.9 65.166
64.55 29.9 65.7575
9.975 33.9 37.017
6.45 37.4 34.8645
40.025 44.9 51.98216667
-61 72.4 38.904
45.95 45.4 46.791
20.55 27.9 33.33975
-57.5 18.4 33.3768
-16.4 61.9 24.49166667
-37.8 84.4 27.713
-52.5 40.9 43.3848
48.3 45.4 53.426
43 42.4 54.244
-65 20.4 35.2434
-3.025 58.9 26.88375
2.8 49.9 45.118
-12.925 61.9 24.53875
64.8 49.9 56.832
-56.1 26.9 40.12066667
-35.1 50.4 28.64833333
-30 55.4 30.7938
-51 47.9 44.0978
5.025 61.9 61.4765
-57 54.9 45.8214
-16 60.9 24.6984
47.35 44.9 50.7615
-63.5 74.9 36.977
-41.4 27.9 29.81733333
-59 22.4 36.9844
51.4 69.4 48.42066667
-69 52.4 43.709
72.5 49.4 88.283
23.025 44.9 57.03483333
59 65.9 52.5994
-53.425 84.9 20.9815
46.5 44.4 55.0832
-54.5 34.9 41.6796
-44 58.9 36.1916
62.7 30.9 60.492
66.1 34.9 65.30366667
-9.508333333 51.9 29.02583333
-42.5 21.9 24.1524
-59 61.4 44.299
-49 30.4 32.1278
-49.8 21.4 32.995
-3 57.4 27.6506
-48 35.4 34.8542
-50 73.9 35.18
58.5 61.9 43.2632
-3.5 44.4 28.7514
-65 66.4 42.3532
59.5 61.9 43.3744
-67.575 82.9 28.5155
-46 59.9 35.7068
60 61.9 43.3954
-66 60.9 44.4232
52.9 6.9 45.73166667
-15 50.9 27.2266
-60.5 72.4 38.9108
64.2 61.9 47.025
39 68.9 47.9278
7.3 42.9 28.473
-36.95 68.9 18.83525
63.5 69.4 53.1996
-60.5 83.9 27.1358
-40.2 60.9 37.731
-50.5 45.9 44.017
-32.5 51.4 31.5458
-38.6 54.4 32.60166667
-65 88.4 18.1228
-55.025 81.9 26.51558333
-10.4 59.4 26.239
-64 18.4 33.2854
-65.5 60.9 44.458
58.5 71.9 49.4374
65.5 62.4 53.2804
-56 22.4 36.862
-53.5 46.9 44.0842
69.7 25.9 77.21
71.4 50.9 71.46
42.5 52.4 53.2184
66.075 71.4 52.0105
-54 72.9 35.9868
-56.05 52.4 46.10625
-21.15 59.4 29.2055
-56 47.9 46.2244
-68 61.4 43.8502
32.5 42.9 52.087
-30.5 51.4 31.543
56 40.4 61.8612
67.35 60.9 55.9705
-35 80.4 43.2056
53.45 38.4 52.6905
-19.8 59.9 26.956
-8.5 42.9 28.4118
-44.3 83.9 9.677
47.9 45.4 52.298
-62 66.9 42.0962
2.65 60.4 60.4005
-61.5 25.9 39.5076
40 51.4 53.375
-55.925 41.4 45.506625
8 50.9 61.5926
39.925 48.9 53.484
-37.5 61.9 30.1014
-62 85.4 24.771
-69 23.4 37.7598
-38 41.9 32.02766667
-47.5 28.9 31.0788
-56.5 60.4 44.6134
-59.5 85.9 23.875
66.05 8.9 68.279
-44.5 30.4 32.1314
-67.5 66.4 42.3406
-37.5 58.4 30.551
-70 62.4 43.8332
-68 62.4 43.8422
-50.5 49.9 44.104
-63.5 62.4 43.9972
-59.5 62.4 43.9702
-70 46.4 43.606
-57.5 62.4 44.0022
-51 62.4 41.7468
59.5 58.4 56.389
-24 46.9 31.5712
-68 71.4 39.5512
-19.5 43.4 29.8502
61.5 55.9 58.4226
-55 80.4 28.5906
-49 62.4 34.7276
-60 75.9 36.2188
-44.7 62.4 34.631
-67.15 43.9 43.7285
71.3 49.4 79.825
-19.65 59.4 26.71
-41 62.4 46.0884
-69 59.9 43.808
-70 26.9 40.0688
-21 55.9 30.129
-4.4 49.4 28.70933333
26 45.9 56.2346
16.975 34.4 36.01041667
40.6 48.4 52.37633333
-35.2 49.4 28.905
62.15 6.4 61.3395
63.425 62.4 44.540125
-38.95 61.9 31.29125
-4.2 56.9 27.438
61.05 4.9 58.3275
-12.5 54.9 28.3772
-17.5 62.4 24.9212
-16.9 62.4 24.42266667
-60 61.4 44.332
3.15 59.9 61.1475
-12.5 62.4 24.4788
-46 47.9 37.6162
-53 20.4 32.0592
-37.65 59.4 31.269
-51.475 51.9 44.0195
6 62.4 60.8136
-64 80.4 31.7136
70.7 25.9 88.508
-23.5 53.9 31.1518
59.5 42.4 62.8892
-40.1 48.9 30.32933333
1.4 65.9 47.67
-68.5 35.9 43.3238
-36.8 83.4 33.248
-62.5 59.9 44.742
63 33.4 61.2066
-45.7 78.4 21.976
-43.5 37.9 35.7844
-68.5 25.9 39.4368
61.6 62.4 42.054
-55.975 33.9 43.48441667
-67 78.9 33.298
-31 55.4 30.852
-12.2 58.9 24.943
-54.525 13.4 24.01175
-58 75.4 36.6372
-62 73.9 37.8108
68.05 71.9 52.16575
46.85 42.4 56.483
6 34.9 36.7902
-51 57.4 43.1868
40.925 49.4 53.496375
56.575 52.9 57.004875
-63.5 86.4 22.866
-42 55.9 32.7878
9 49.9 61.373
-59 62.4 43.9832
57.2 61.9 43.024
64.4 62.4 48.559
37.65 21.4 35.9905
-49.5 50.9 43.168
66.8 46.9 60.963
64.5 62.4 49.27
-52 69.9 38.0576
-55.6 83.9 23.306
65.1 62.4 52.794
-46.5 31.9 33.0316
4.5 59.9 61.7856
-52.5 56.9 43.3288
-66.5 73.9 37.7666
67.05 62.4 55.18975
68.35 62.4 56.0155
-54.5 40.4 43.249
53 53.4 54.708
55.5 52.9 56.7798
-16.5 43.9 28.6298
-52.5 61.4 42.104
53.5 9.9 45.6392
-66 27.4 40.3924
56.15 75.9 56.227
-49.8 22.9 34.517
-64.5 74.4 37.399
50.1 50.9 66.65933333
64 70.9 52.2078
-49 40.9 36.6856
63.5 68.9 53.3648
-48 33.4 33.8616
-61.5 71.9 39.2414
-56.05 22.9 37.2835
58.95 8.4 50.308
-52.5 84.9 21.2724
-54.5 81.4 27.2716
-69 65.9 42.5508
-65 76.4 35.7308
58.8 73.4 48.849
-61 35.9 44.259
26 45.4 56.2346
-4.4 45.9 28.46866667
-45 43.4 37.1946
-16.2 55.4 28.25
69.6 24.9 77.108
-45 59.4 35.9128
-38.575 41.9 32.414375
53.5 9.4 46.1508
-2 51.4 29.0684
-57 29.4 41.5224
53.05 73.9 51.51825
-68 62.9 43.7702
-10.7 58.9 25.983
-58 12.9 26.2734
-55.4 70.4 37.773
-54.55 83.9 22.2415
-68.5 86.9 21.7054
1.3 38.4 30.464
-66.5 68.4 41.322
-64 63.9 43.4126
-49.925 61.4 42.026
35.55 50.4 53.099
64.5 66.9 53.4266
-63.5 62.9 43.8016
68.5 11.9 71.1484
36 19.9 36.9048
41.5 51.9 53.352
-62 62.9 43.7982
-56 62.9 43.761
59 7.9 49.6596
20 45.9 57.708
-41.575 50.9 34.95925
46.3 49.4 59.798
-29.5 52.4 31.4128
-38.45 76.9 44.34275
-53.5 62.9 41.5752
-53 62.9 41.566
-19 52.4 28.8766
-58.5 70.4 40.18
-44.1 73.4 26.259
-38.85 63.4 30.6375
-52 62.9 41.5722
-61 47.4 46.2356
17.075 29.9 36.50825
33.9 45.9 54.09433333
11.5 31.4 37.1796
-64.5 37.4 44.7098
-24 47.4 31.5906
-55.7 34.9 43.41
-53 46.4 44.0554
-65 30.9 42.2512
53.4 56.9 56.018
34.5 71.9 49.3824
-45.5 40.9 36.6798
-8 52.9 28.8028
-13.9 59.4 23.142
-53 55.9 43.492
20 71.9 52.6264
-55.7 48.9 46.03
-34 59.9 26.7448
-36.5 62.9 24.7992
65 30.9 66.507
30.475 25.4 35.01233333
41.5 51.4 53.6194
63.95 8.4 64.6905
67.8 52.4 59.462
-52 25.4 36.627
63.2 36.9 56.118
-60.5 31.9 42.717
-54 68.9 38.681
-16.8 62.9 24.455
-3.975 49.9 28.86975
-17.7 58.9 25.619
-56.475 24.4 38.5205
-39 54.4 32.5294
-44.5 38.9 36.125
-15.5 62.9 24.244
-38.65 42.4 32.6185
-50.5 60.9 42.278
-5.5 49.4 29.1122
34.7 46.4 54.015
-19.8 59.4 26.959
-37.7 78.9 41.832
-66.8 38.9 44.144
71.9 51.4 77.953
6.4 62.4 60.38083333
-17 60.9 24.9542
52 55.9 55.8036
-28 45.4 31.431
16.5 74.4 54.1764
-13.2 62.9 24.176
-61 85.9 23.8834
-58.5 16.9 31.7484
3.35 62.9 60.3925
4.075 62.9 60.97575
58 71.4 50.7176
-57.5 62.9 43.8092
-39.8 57.4 42.653
-39.5 44.9 36.512
-40.05 78.4 36.2835
-12 54.9 28.373
10.5 32.9 37.3614
6.425 62.9 60.4175
-60.025 74.9 37.017
44.5 51.4 53.5952
-43.925 59.9 35.95225
70.5 76.4 50.5874
-40.65 50.4 34.1225
-42.7 84.4 13.448
-63.5 65.9 42.5754
-55.5 84.4 22.3214
6.8 62.9 59.868
70.9 25.4 90.672
-37 64.4 23.6716
-67.5 22.4 36.9114
-50.575 67.4 39.52325
72.8 50.9 89.307
11 35.4 36.8192
-20.1 53.9 26.05733333
66.5 62.9 54.2672
55.1 6.9 48.19033333
66.075 48.9 59.421375
23 71.9 51.233
-51 63.4 41.4012
-36.5 33.9 25.29033333
-55.4 67.4 39.53633333
-29 59.9 29.2894
68.075 62.9 55.51775
-35.6 37.9 26.56866667
-11.5 56.4 27.8834
-69.5 74.9 36.9518
23.5 73.4 52.239
-39.95 49.9 29.9775
56.075 68.4 52.5035
-46 29.9 31.747
-66 76.4 35.7326
65.5 26.4 69.3896
-35.575 47.9 28.889
-49.65 73.4 35.1515
-68 56.4 43.7932
53.1 52.4 54.203
-49.8 32.4 40.486
49.5 12.4 42.5958
54.3 7.4 47.445
-35.5 39.4 27
-49.5 51.4 43.31
39.3 16.4 37.972
23.5 74.4 52.0956
66.95 37.4 65.66025
11.7 69.4 56.974
-30.5 60.4 28.3984
27.5 74.4 50.687
-62.5 63.4 43.5904
-46.4 77.4 22.742
-67.5 78.9 33.3232
-50 27.4 37.8998
-54.5 28.4 38.5658
-43.45 52.9 37.1745
-49.35 62.9 34.5525
55.8 75.4 55.8
40.3 68.4 47.476
72.5 43.9 63.3478
-65.5 63.4 43.5566
-70 20.9 35.6834
-48.5 57.9 36.3526
3 46.9 28.111
-35.2 83.4 37.99
-60 59.9 44.7522
33 73.4 49.331
-65 63.4 43.584
39.8 72.4 47.558
63.7 28.4 64.106
-61 63.4 43.6208
-59.5 82.4 29.3442
-69.5 86.9 21.6524
58 42.9 62.6018
-40.2 61.9 45.827
-2.5 43.9 28.683
68.35 61.9 56.2335
-54 39.9 43.1398
48.5 51.4 52.6964
-49.5 67.4 37.864
-59.5 79.4 32.842
-13.5 58.4 22.311
-60.5 63.4 43.6266
70.5 55.4 63.886
61 41.9 63.1528
-10 48.4 29.1208
53.3 42.9 61.466
69 54.9 59.171
43.5 16.9 38.791
14 35.4 36.2568
-19.65 57.4 26.798
-56 63.4 43.582
-55.4 63.4 41.38233333
-47 40.9 36.6506
-70 43.9 43.5072
-4.65 55.4 28.1805
-55 63.4 41.3822
-41.15 49.9 31.7405
58.8 75.9 55.733
-6 60.9 26.0548
-52 63.4 41.378
-68.5 24.9 38.8154
57.45 75.9 55.8125
-37.9 63.4 30.42933333
-67.15 54.9 43.9675
-24 47.9 31.6112
-57 67.9 41.6206
-52 48.4 44.1116
-47.5 63.4 34.2338
-66 69.4 40.7358
-61.5 37.9 44.8212
58.5 58.4 56.1188
60.7 3.9 56.931
-47 63.4 34.2178
18.5 74.4 53.7234
-36.95 84.4 30.1875
-45.1 63.4 34.212
-41.6 46.9 40.37
-57.5 88.4 18.2346
-41.85 63.4 45.641
-41 63.4 46.1464
-25 51.9 31.4874
-16.2 59.9 24.225
-61 32.4 42.9148
69.5 75.4 51.6258
-61.5 64.9 43.0086
63.35 51.9 56.1545
-51 59.4 42.7004
4 38.4 33.2064
-38.5 63.4 30.6176
-38.15 63.4 30.5975
61.3 7.4 57.277
-26 51.4 31.496
-29 57.9 30.0982
67 60.4 55.8662
35 49.4 53.9744
-46.1 21.4 27.209
-39.3 46.9 37.944
-37.6 63.4 30.374
31.5 45.4 54.292
26.5 72.9 51.2292
53.55 56.4 56.34875
-37.1 69.9 19.02
-34.85 84.4 36.281
-40.5 64.4 46.1392
-59 11.4 23.72
60 60.9 42.3128
-36.9 31.9 24.28633333
-37.3 63.4 24.44
-15 51.9 28.0888
26.5 46.4 55.9716
72.3 53.9 84.727
70 44.4 63.3088
59.425 54.9 58.0315
3.2 63.4 59.95
5 50.4 57.987
3.5 63.4 60.2892
22.975 44.4 57.01058333
-13.5 61.4 24.4966
-38.5 43.9 32.6152
-47.5 75.9 32.934
65.8 10.9 66.27
3.15 61.4 60.694
-37.35 60.4 30.324
-49.3 56.4 36.779
-11.5 57.9 26.3746
-5 48.9 29.1406
56 54.4 57.3908
69 6.9 71.3136
-55.4 58.9 42.883
-24.5 46.4 31.5534
55.925 50.9 54.534625
52 40.4 58.3892
-55 60.4 42.4374
69 49.4 63.591
64 65.4 52.2784
-1 47.9 29.1472
36 48.4 54.1846
-21.5 53.9 31.1728
-12.425 59.4 24.902
66.5 60.9 55.2566
-43.55 53.9 36.95
53.4 40.9 59.92
-50.475 81.9 26.51841667
-42.075 23.9 26.541125
-65.5 17.9 32.7478
3.8 50.4 51.572
19.1 28.9 33.20466667
64 65.9 52.7918
-20.5 50.4 26.5562
-53 43.4 43.7396
-54 52.4 43.9624
-5 45.9 28.9484
46.5 11.9 41.7374
5.65 66.9 59.5995
-50 24.4 35.832
35.5 48.4 54.2576
46.15 45.4 47.3565
-40.5 33.4 31.81866667
-29.5 51.9 31.4642
64.2 63.4 45.16
17 44.4 58.8876
-35.525 59.4 26.45483333
-60.5 28.9 41.2538
-9 55.9 28.1052
-36.9 61.9 25.45266667
-62 58.4 45.1376
59.65 5.4 53.103
64.6 63.4 48.281
54.5 58.9 54.7566
14.5 34.4 36.7746
-32.7 82.9 41.457
64.7 63.4 49.059
65.1 63.4 52.18
4.1 52.9 60.796
57 75.4 55.862
-48.5 77.9 20.517625
65.5 63.4 53.2832
71.3 74.4 52.127
-62.5 22.4 36.9206
-38.65 67.9 45.147
66.5 63.4 54.2672
-34 47.9 31.296
53.2 68.9 49.925
72.2 55.9 86.784
-16.6 59.4 24.32733333
66.925 63.4 54.54975
50 54.4 53.824
-49.6 36.9 41.943
57.5 38.4 59.6036
16.2 70.9 52.569
-24 43.4 31.0892
67 2.9 63.5692
66.5 34.4 63.396
-65.5 46.4 46.1564
-67.5 79.4 32.7416
-68.475 42.9 43.514
-13.5 49.9 27.123
70 54.9 59.3238
-67.5 33.4 43.2562
-68 63.9 43.4066
-64 59.9 44.7508
-56.5 33.9 43.5692
-57 63.9 43.424
-64.5 84.9 25.5756
-61.5 53.4 45.9682
-51 30.9 39.9476
53.5 43.9 61.2764
-55 63.9 41.2074
-51.5 63.9 41.163
54.5 73.4 51.3854
22.5 44.4 57.1688
36.5 72.9 48.4062
59 58.9 56.248
52 42.4 59.2182
-50.5 63.9 41.1752
-31.5 60.4 27.8644
-3 56.4 27.9772
-49.35 63.9 34.0755
63.85 61.9 44.7795
-52.5 75.4 33.9556
-49.075 63.9 34.01825
-64 40.4 45.4294
-64.5 62.9 43.7816
-54.6 11.9 21.119
-47.5 63.9 34.0414
70.45 19.4 73.45125
-42.5 63.9 44.6698
65.5 42.4 63.449
16 34.9 35.9708
-49.4 36.9 35.581
44 43.9 54.6704
-41 63.9 46.146
-59 77.4 34.8678
72 44.4 63.2388
-61.5 83.9 27.134
-63.5 63.4 43.6108
-58.5 76.4 35.8114
41.925 71.4 48.0085
-37.85 68.4 44.503
-37.7 66.4 27.014
64.65 46.9 57.7885
-45.5 69.4 30.4344
-58 53.9 45.931
-40.5 63.4 46.1296
48.025 67.4 48.51625
68.575 42.9 63.493125
-37.65 63.9 30.22
-39 56.9 32.4406
-35.975 63.9 24.01625
-39.6 63.9 43.953
64.6 61.9 49.739
2.7 63.9 56.298
5.5 33.4 36.3294
36 41.4 47.2424
50.95 72.9 47.1115
5.5 63.9 60.8772
-22 57.4 30.2682
63.925 68.4 53.54175
-63 62.9 43.787
61.575 63.9 50.971375
-63.5 82.9 28.6224
-69 54.4 43.7468
57.35 74.4 52.813
48 51.4 52.916
14.1 43.9 58.476
9.4 46.4 59.859
47 13.9 41.2566
-58.5 81.4 30.628
-45.5 65.9 32.8706
10.5 67.9 56.668
-51 78.4 30.9592
-54 56.9 43.3294
-29 58.9 29.7182
-49.6 79.9 28.205
40 18.9 37.7356
67 8.9 70.30466667
-53.5 32.9 40.8358
-65.5 48.4 46.201
-64 52.9 46.0446
-40.5 67.9 45.9176
66.1 16.9 72.591
-69 11.9 24.5054
63 62.4 44.4478
-53.5 26.9 37.6714
65.9 73.9 49.31066667
0.3 53.9 28.774
56 71.4 50.1586
59.5 58.9 56.389
8.2 65.9 58.981
-64.5 38.4 44.9526
57 43.9 62.1662
-51.5 79.4 29.8138
-42.5 26.9 29.3846
65 1.9 60.7766
-64 62.4 43.9486
-55.45 74.4 34.786
-61 87.4 20.766
40.575 15.4 38.456875
-44.95 79.4 23.369
-49.5 24.4 34.864
-51.5 35.9 42.0518
51.575 67.4 50.953
55.5 63.9 50.0762
-34 49.4 31.5836
-63 69.4 40.7416
65.7 74.9 48.659
-48 48.9 43.8464
6.85 69.4 57.94
57.5 67.9 52.7654
-33.5 44.4 29.2096
-18.85 44.4 29.4725
-60.5 44.4 46.035
-69.5 81.9 29.889
7.7 70.4 57.355
67 62.9 54.6994
34.5 70.9 49.1848
-36.2 83.9 33.481
-55.8 52.4 45.98
60 63.9 50.2984
-49 46.4 43.36
-45 73.9 26.1784
-1.5 55.9 28.1064
-48 72.4 31.5748
53 48.9 62.031
-45.05 64.9 33.43375
-49.975 62.9 41.53308333
-39.5 69.9 45.824
-52.025 39.4 43.02125
-48.05 44.4 38.825
64.525 63.9 53.00758333
67.5 23.9 72.362
65.5 63.9 53.6762
72.425 44.9 63.00725
71 72.9 52.685
-66.475 37.9 44.45516667
-64 57.4 45.3678
71.8 52.9 77.486
67.475 63.9 54.99633333
52.3 55.9 55.41
-35.1 51.4 28.575
-57 85.9 23.8026
64.8 48.9 57.39
-51 75.9 33.5028
-41.05 83.9 19.2505
-64 50.4 46.1888
-68.5 12.9 26.1984
-59 61.9 44.1536
-68.5 64.4 43.1658
-53.5 85.4 19.6558
-52.075 11.4 18.496625
55.3 73.4 50.936
-44.05 51.9 37.15325
-56.05 71.9 39.16475
-60 32.4 42.9386
64.1 61.4 46.369
-42 28.4 30.6872
-61 77.4 34.8474
-67.5 64.4 43.1792
-69 43.4 43.51891667
56.15 7.9 48.6035
67.075 52.4 58.987875
58 56.9 57.284
48.05 45.4 52.721
-55.7 64.4 42.89
-34 55.9 30.6664
-64.5 85.4 24.7538
60.025 66.4 53.02108333
-49.625 62.4 41.4165
-64.5 61.9 44.1288
-68 87.9 19.2958
64.2 37.4 65.112
64.3 5.9 65.359
-47.5 64.4 33.7498
16.9 46.4 58.605
-61 60.9 44.4598
-41.6 34.4 34.16666667
-15.6 58.9 21.34266667
-50 62.4 41.712
-45.05 64.4 33.725
-34.65 84.4 36.8675
-37.65 60.9 30.881
68.525 18.9 73.03008333
-51.5 38.4 42.7762
-34 52.9 31.3264
-45.15 63.9 33.9955
-48 61.9 34.94
-50.5 32.9 40.8674
4.1 50.4 53.163
-66 74.4 37.387
-61.5 69.9 40.4688
-8.5 60.4 26.2748
-69 85.9 23.738
-39.8 64.4 44.683
26.5 25.9 34.7456
50 71.4 42.147
-54 77.9 31.4814
-70 59.4 43.7946
-5.5 43.9 28.6384
32 46.9 54.8414
5.7 58.4 61.471
58.2 75.4 55.499
-39.1 64.4 30.978
56.55 61.4 41.93675
-38.5 64.4 30.297
-58 9.4 19.3022
11.975 71.4 57.0155
-35.6 64.4 23.38966667
-33.425 45.4 29.98325
-49.4 53.4 39.014
71.1 54.9 70.995
-48.5 46.9 43.7394
3.2 64.4 58.086
66.15 73.9 49.6745
70.4 49.4 73.484
38.975 49.9 53.96358333
5 64.4 60.7108
-44.5 34.4 34.3648
-37.2 62.4 29.846
60.925 59.4 56.046125
-2.5 46.4 29.032
-46 60.9 35.32
-54.5 62.4 41.7356
6.425 64.4 60.434875
-62 56.9 45.4564
-56.5 71.4 39.6018
7.1 64.4 59.86333333
12.5 46.4 60.455
60.35 30.9 59.813
-35.5 45.9 28.6906
-12.3 58.4 24.708
-21.5 52.9 31.3334
5 60.4 61.736
58.025 59.4 55.00516667
-47.5 55.4 36.9756
-59.5 21.4 36.147
-64 26.4 39.8134
-43 24.9 27.4728
45.2 44.9 43.74
-30 47.9 31.615
47 14.4 40.7416
-53.9 83.9 23.19733333
56 39.9 61.5604
64 31.4 58.7294
4.7 50.4 56.364
-36.5 77.9 44.1268
-56.5 53.4 45.989
35.95 73.4 48.05575
-49.05 44.4 41.7585
-45.85 78.9 21.331
-16.5 62.9 24.3416
26.525 71.9 50.95825
-52.5 84.4 22.268
8.35 66.9 59.1045
59 35.9 52.9128
-31.5 55.4 30.8436
58.5 67.4 52.9722
-54 82.4 25.7684
66.4 65.9 53.45766667
54.5 71.4 49.3908
-40.1 61.9 44.747
36.425 47.9 54.001875
58 64.4 51.1906
27 25.4 34.7686
56.525 59.4 54.51133333
-53 55.4 43.5652
-8 53.4 28.7192
65.95 15.9 69.24125
60 64.4 51.3926
-69.5 73.4 38.1436
-67.5 46.4 43.7092
-41.85 83.4 18.3345
62 64.4 51.599
-51 56.4 43.3798
62.5 64.4 51.6256
-69.5 83.4 27.7558
34 41.9 48.8382
34 22.4 36.145
-66 58.9 44.936
-38.075 57.9 31.987875
-63 75.9 36.1918
-51.5 58.9 42.8402
65.8 41.4 63.221
-52.5 14.9 25.6672
-39.1 46.4 34.745
-65.5 62.9 43.7812
64 64.4 51.40183333
60.5 54.9 58.2428
59.5 38.9 60.7728
-42 42.4 35.77
65.65 64.4 53.632
12.95 71.4 56.5995
-45.5 62.4 34.7156
-48 47.4 43.8668
-45.5 57.4 36.5048
-17 56.9 26.8446
64.7 61.4 52.215
-64 16.4 31.0912
13.35 70.4 56.1235
-65.5 54.9 45.791
67.15 10.4 70.301
25.15 74.4 51.493
-65 77.9 34.338
-37.6 43.9 30.908
-38.4 67.9 44.81733333
-46.475 58.9 36.01933333
-53.425 39.4 43.012875
-37 54.9 28.4442
70.8 53.9 65.862
-51.5 61.9 41.9194
-12.5 45.9 28.1144
-3.925 48.9 29.0065
-41.85 56.9 39.1855
-41.7 58.4 38.871
-49.975 83.4 23.95983333
64.3 5.4 64.978
-56.5 60.9 44.4858
-14 57.4 19.439
-58 43.9 45.9812
58.3 5.4 51.514
-23.5 49.4 31.6028
-52.5 71.9 36.609
-33.5 49.9 31.6418
-69 32.4 42.8732
-69.5 64.9 42.9762
-55.7 57.9 44.955
64.3 63.4 45.95
55.65 5.4 47.784
12.85 69.9 56.1795
-69 64.9 42.9844
-36.35 73.9 43.35
50.9 69.9 44.053
-44.375 79.9 23.857
5 43.9 28.1038
64 59.4 52.739
-62 32.4 42.9162
-62.5 64.9 42.98
-53 60.4 42.415
-59.5 32.4 42.9164
3.15 56.9 61.204
52 52.4 55.5832
-60 64.9 42.9952
53.625 39.4 60.4425
31.5 70.9 49.1266
-36 35.4 25.34
-57.075 64.9 43.0245
0.6 49.9 33.965
44.5 42.4 55.2312
-65.5 79.9 32.2738
-46.65 47.4 43.6905
-53 32.4 40.6426
-53.5 64.9 40.7132
-3.95 66.4 25.296
57.5 71.4 50.5874
-31 49.9 31.618
48 12.4 42.38
-56.5 18.9 33.8778
7.4 37.4 33.18033333
-51 56.9 43.3062
-9 58.9 26.988
29.925 45.4 55.0015
8.5 70.4 57.7938
-20 44.4 30.3314
-59 86.4 22.8914
-45.5 28.9 31.0612
-33 53.4 31.252
60.975 71.9 50.50525
-42.2 78.4 30.857
7 67.9 59.3466
-17.4 62.9 24.769
-49 32.4 33.3694
-43.8 75.9 23.293
-49.35 64.9 33.558
-47.5 35.4 34.8298
4.35 65.9 59.3385
-46.2 78.9 20.455
-8 58.9 27.0206
-48 64.9 33.4558
-47.5 64.9 33.4694
-38.625 32.4 27.922
53.3 45.9 58.361
15.425 44.9 59.498375
-57.5 73.4 38.2326
-57.5 74.9 37.032
-67.5 66.9 42.0928
-38.8 64.9 45.015
22 72.9 52.6606
54.925 42.9 62.04
-54 14.4 25.6734
-38.65 64.9 45.0155
66.85 7.4 69.404
-19.35 61.9 25.1255
-46 67.9 31.5906
61.3 7.9 55.989
-51 72.4 36.3838
59.4 34.4 59.156
39.5 51.4 53.2606
70 43.9 63.4734
-37.6 64.9 28.841
-37.3 64.9 23.195
45.125 70.4 50.49275
61.5 64.4 51.5494
27.65 23.9 34.1095
10 47.9 61.098
-37.4 42.9 30.043
-66 82.4 29.256
-33.5 58.9 29.7468
42.05 43.9 53.63475
65 22.9 70.173
-36 33.4 23.584
-48.85 44.4 41.17
-36.5 64.9 23.2012
41.55 44.4 53.1165
-38.95 57.9 32.21575
4 64.9 59.7752
64.55 49.4 56.3365
17.5 31.9 36.7922
-44 58.4 36.3314
-53.5 52.9 43.9244
-45 38.9 36.1216
68.475 72.9 52.00908333
-58 68.9 41.0804
-67.5 59.9 43.9034
-49.925 46.9 44.01925
-11.5 58.4 26.1712
3.7 65.9 57.178
6.85 64.9 60.1095
46.025 54.4 52.51533333
5.8 59.9 61.129
-10 47.9 29.1252
-54.5 33.4 41.0468
-58.5 78.9 33.4216
60.5 66.4 53.1284
72.9 49.9 90.492
72.1 52.9 81.159
54.575 42.4 61.9835
72.9 44.9 62.85833333
26.5 73.9 51.2398
51.575 67.9 50.953
-57 38.4 45.0002
33 48.9 54.103
52.9 64.9 50.194
69.5 18.4 73.2716
53.2 64.9 49.463
-67.2 52.9 43.87
-55.8 76.4 35.45
-16.50833333 49.4 26.03866667
63.7 35.4 57.907
-48.8 44.9 41.391
42.5 49.9 53.341
58 64.9 51.756
-51.5 14.4 24.748
-44.025 49.4 37.46991667
-63.5 84.9 25.5842
26.525 72.4 51.03225
63.5 67.4 53.3894
29 26.4 34.7006
-56 30.9 42.1956
36.5 70.9 49.0712
39.5 68.9 48.1
59 64.9 51.8834
-61.5 19.4 34.317
68.575 49.9 60.532625
63 64.9 52.2768
63.5 64.9 51.686
-69 48.4 43.6496
67.475 64.9 55.53091667
3.05 58.9 61.20575
-44 30.9 32.4642
-60.5 64.9 43.0048
-60.5 63.9 43.408
-13.4 60.9 24.63866667
-63 17.4 32.2448
-42 33.4 33.8606
27 46.9 55.6248
71 51.4 66.405
-13.5 50.9 27.1462
-69.5 32.9 43.0566
-25.5 49.9 31.6018
-70 65.4 42.7532
-29 57.4 30.273
63.65 2.9 61.6205
33.2 44.9 56.89
-29 44.4 31.2614
-66.5 65.4 42.7518
-13.425 41.9 27.96575
-42.35 50.4 35.873
-9.5 52.9 28.8058
-60 29.9 41.7526
-69 50.9 43.6776
-58 49.4 46.2554
-60.5 65.4 42.7962
-58 65.4 42.7954
-0.5 45.9 29.0058
-65 86.9 21.7576
-38.575 50.4 32.96525
-62 43.9 45.9732
51.45 44.9 58.786
-45.5 61.4 35.147
-55.45 35.4 42.08625
-56.05 67.9 41.59875
-53.5 75.4 33.9418
-54.525 65.4 40.51825
-14.6 57.4 17.171
-47.5 31.9 33.0696
64.8 51.9 56.311
-55.7 79.4 32.119
61 31.4 62.2244
-59.5 21.9 36.5492
-6.5 57.9 27.437
28 24.9 34.9336
63.9 22.9 63.67
-4.35 46.4 28.4865
59.5 46.4 60.3178
-60 70.4 40.2
-70 56.9 43.7784
-47 65.4 33.1762
36.5 19.9 37.0748
71.3 54.4 72.698
69.8 24.9 79.269
-53.5 76.4 33.0584
-39.975 65.4 46.01958333
55 67.9 52.2512
67.075 49.9 59.990875
67.525 75.9 49.47541667
-57 71.4 39.6122
-42.5 55.9 32.6876
64.2 22.9 68.136
-51 28.9 38.87
58.5 56.4 57.6272
-8 43.4 28.5716
-37.35 65.4 22.7625
-43.5 27.9 30.2558
-35.85 32.9 22.8535
-36.5 65.4 22.8104
-52 37.4 42.4982
4.575 65.4 59.9285
70 24.9 81.439
-59.5 63.9 43.4178
-62.5 65.4 42.7662
5.1 49.9 56.699
67.075 14.9 69.94925
6.5 65.4 60.2584
63.65 64.4 50.875
-63.5 53.9 45.92
65.85 10.4 67.299
-37.3 62.9 24.794
-18.5 60.4 25.3472
69.35 49.9 60.5895
-23 58.9 29.7144
6.85 65.4 60.101
-59 83.9 27.1646
68.5 43.4 63.2942
-67.5 23.4 37.7538
-63.5 29.9 41.728
-15.35 58.9 20.0665
-54 68.4 38.9784
4.1 65.9 58.848
-48.5 47.4 43.8856
-57 88.4 18.2394
-42.8 63.4 44.52
-51.5 22.4 33.8262
-61.5 69.4 40.7536
64 39.4 60.8236
-55 37.9 42.6516
28.7 43.9 53.331
52.5 65.4 50.5668
-42.65 38.9 36.086
-49.65 66.9 39.407
-52.5 19.9 31.837
4.05 35.9 35.845
59 65.4 52.299
-60 30.4 42.0238
-64 81.4 30.5622
-36.5 61.9 25.4482
58.5 38.9 60.684
-56.5 20.9 35.698
19.65 29.9 34.408
-8.5 56.4 27.9856
-39.3 57.4 32.361
63.2 65.4 52.424
-53 69.9 38.0876
-26 52.9 31.3194
-43 37.4 35.6088
7.5 45.4 28.2604
64.45 71.9 46.741
29.2 74.4 50.005
53.45 49.4 61.815
8.8 44.9 28.785
-59.5 66.9 42.1176
12 72.9 55.8994
-35.55 60.9 25.73725
-52.575 10.9 17.968
64.4 61.9 48.381
5 50.9 59.533
59.8 31.4 58.972
-65 37.4 44.7196
64.4 65.4 52.357
50.95 43.4 60.24325
48.7 50.9 59.636
-20.8 48.9 26.618
-44 77.4 21.3582
-55.45 29.4 39.16825
67.05 64.4 55.45725
34.5 72.4 49.3662
8.475 33.4 36.51975
64.3 50.9 54.999
-68 86.4 22.8272
67.7 65.4 55.671
66.35 75.4 48.367
-68 28.9 41.2172
36.575 71.9 49.051625
-57 80.9 31.2006
70.9 51.9 65.911
69.4 25.4 73.914
-70 65.9 42.5342
-44.65 79.9 23.1475
-68 65.9 42.5618
-50 55.9 43.4718
-67.5 65.9 42.5468
-42.45 78.4 30.2255
-65 65.9 42.5534
-60.5 65.9 42.5892
-27.5 53.4 31.2232
-61.5 46.9 46.199
-55.45 11.9 21.716
61.5 71.4 51.7956
64.7 14.9 65.614
-19.1 59.9 26.34866667
-51.5 72.4 36.3896
-60 69.4 40.7668
63.4 4.9 63.259
-49.7 23.9 35.11
36.525 21.9 36.00516667
-59 50.4 46.2086
41 18.9 37.3754
7.45 64.4 59.323
-57.5 65.9 42.6024
-55.4 65.9 40.32766667
-60 43.9 45.9782
-17.5 47.4 28.1332
-53.50833333 75.9 33.5215
-59.5 60.4 44.5796
-37.2 46.9 30.872
-59.5 70.9 39.8942
32 44.9 53.099
-52.5 65.9 40.2882
-67.5 43.4 43.6578
-60.5 86.4 22.9064
-29.5 50.9 31.5648
36.5 44.9 49.3154
-39.5 74.4 44.3016
63.85 62.4 44.789
70.9 52.4 65.653
51.7 65.9 50.681
69.6 24.4 78.903
-52 65.9 40.3086
-13.5 43.4 28.3954
70.2 49.9 67.289
-32 52.4 31.4412
-42.35 51.4 35.901
61.575 66.9 53.490875
62 45.9 61.839
53.075 64.4 49.51775
51.3 8.9 44.974
54.7 74.9 55.351
22 74.4 52.6056
-25 43.9 31.13
-35.2 58.9 26.859
-17.15 59.9 25.1225
-61.5 73.4 38.1778
-17.15 60.4 25.1225
-49.65 65.9 39.9345
-52 33.9 41.267
-16.8 48.4 26.479
-47.5 33.4 33.862
-46 42.9 37.062
-67.5 86.4 22.8312
-66 77.4 34.7956
-13.65 60.4 23.8115
-52 84.4 22.2714
-52.5 48.9 44.111
-30.5 46.9 31.555
-49 65.9 32.9382
-13 62.4 24.4806
20.9 47.9 57.058
10.35 67.9 57.164
-40.5 65.9 46.0912
-54.025 23.9 35.52025
-39.975 65.9 46.014
-65.5 70.4 40.1578
-61 70.9 39.8876
-39.5 65.9 45.8758
-38.8 65.9 45.017
-37.8 65.9 28.589
-62 46.4 46.1678
-68.5 59.4 43.8198
-0.5 44.4 28.7354
-37.8 56.9 31.862
-59 57.4 45.376
-67.5 61.4 43.9158
-37.7 65.9 27.351
58.5 56.9 57.4208
-37.3 65.9 22.505
-13.1 57.9 22.697
-65.5 69.4 40.761
62.95 58.9 55.7565
-61 17.4 32.2612
5.5 62.4 61.1324
5.5 66.4 59.8708
-55.7 18.4 32.764
-37 65.9 22.3608
65.075 23.9 69.925875
35.925 72.4 48.9885
-36.5 65.9 22.3788
64.95 49.9 57.2165
-36.05 55.9 28.126
-50.5 65.9 40.29
18.5 28.4 35.7298
-4.95 65.9 21.858
-70 66.4 42.3234
-4.6 65.9 22.678
-37.575 74.4 44.958875
-30.5 54.9 30.931
34.5 41.4 47.427
71.8 50.4 77.821
-37.55 46.4 32.24
34 47.4 54.4586
-4.2 65.9 24.367
-20.8 54.9 25.791
-51.5 72.9 35.9728
-53 59.4 42.7166
7.35 41.4 24.3295
-49.35 60.9 35.383
-12.5 42.9 28.3516
-50.5 55.9 43.4968
64.7 48.9 57.192
30.5 24.4 35.419
-46 74.4 25.5852
4.5 62.4 61.257
42.475 51.9 53.5155
-3.85 65.9 25.831
-3 65.9 29.372
-2.4 65.9 31.859
-54.5 75.4 33.948
-49.35 54.9 37.1485
23.5 72.9 52.1094
-2.3 65.9 32.273
-70 42.4 43.4838
-9.475 58.9 27.01433333
-41.4 62.4 46.04
-1.3 65.9 36.435
72.85 45.9 62.8625
-59 12.4 25.4704
-9.5 43.4 28.4718
9.2 67.4 58.444
-0.3 65.9 40.612
16.5 43.9 58.6932
-3.5 56.4 27.983
-8 42.9 28.3946
-37.4 65.9 23.712
-34 55.4 30.861
-0.15 65.9 41.2355
17 72.4 54.712
2.65 65.9 52.829
-1 45.4 28.9116
-48.5 53.4 37.2746
-53.5 76.9 32.529
44.575 44.4 54.47175
57.65 74.4 51.5655
-46.5 65.9 32.9044
-63.5 12.9 26.218
-40.3 59.9 37.832
-64.5 48.4 46.2356
70.5 43.4 63.6456
68.075 60.9 56.45425
5.5 46.4 27.808
52.075 65.4 50.49225
-56.5 9.9 20.5754
71.4 55.9 76.114
22 28.9 34.3918
69.5 50.4 60.6718
6.925 65.9 59.985375
70.5 45.4 63.118
-12.8 47.9 24.794
-58.5 29.4 41.5044
-55.975 35.4 44.01641667
6.1 37.9 33.45666667
-42.925 35.9 35.02975
-54 70.9 37.4452
-60 26.4 39.8108
-61.5 67.4 41.8432
69.3 75.9 50.439
-52 32.9 40.8302
3.475 44.9 28.013
-68.5 81.9 29.9086
-36.65 83.4 33.688
-11.5 53.4 28.725
-63 66.4 42.3436
-38.4 31.4 26.79166667
-16.1 56.9 23.476
-59 66.4 42.3686
52.5 65.9 50.874
-50 41.4 43.395
53.7 65.9 51.114
-64 51.9 46.1302
-38.4 52.9 29.28066667
65.8 71.9 49.992
54.5 65.9 51.7216
71.5 55.9 77.435
-38.9 62.9 30.76166667
-40.65 78.9 34.3945
11.4 48.9 60.13866667
6.65 54.9 61.4745
-53.5 35.4 41.8612
-65 82.9 28.5718
-54.95 22.4 33.8595
55.575 65.9 51.99675
-46.35 46.4 37.521
71.2 49.9 75.912
-58.5 62.9 43.7854
-66 42.9 45.6886
-37.6 75.4 46.47333333
-60.975 13.4 27.01975
52.15 73.4 51.4785
-61 81.4 30.5902
59.5 65.9 52.6808
59.8 71.9 49.988
-61 75.4 36.6198
-55.45 11.4 20.7555
-48 27.4 29.9426
-62.5 13.9 27.7356
-63.5 83.4 27.864
60.5 65.9 52.887
58.8 4.9 51.985
38.6 42.4 48.93233333
-49.075 49.4 43.537125
-35.65 78.4 43.688
64.5 65.9 52.8568
-23 57.4 30.2548
66.5 24.4 70.4278
-18.5 48.4 28.114
-0.5 46.4 29.1206
-46.075 24.4 27.0135
-68.5 71.4 39.5554
71 52.9 67.613
-55.7 63.9 43.078
65.95 16.4 70.6105
3 50.9 48.05
64.3 61.4 47.974
-55.65 15.4 28.386
64.65 64.9 52.7715
-48.5 34.4 34.3816
-8 52.4 28.94
-43 26.4 28.9758
-55.925 56.4 45.502
-29 46.9 31.5624
-47.5 27.9 30.3228
66.85 65.9 54.2435
-51 58.9 42.8354
72.6 55.9 92.154
-57.5 20.9 35.743
-45 47.4 37.5766
14.5 72.4 55.841
-35.925 60.9 26.027625
31 45.4 54.595
-3.5 45.4 28.8992
46 13.4 41.3424
-42.7 78.9 29.268
-69.5 80.9 31.1292
8 47.4 59.929
-47 27.4 29.9486
-61.5 78.4 33.865
59 63.9 50.3016
-65.5 43.4 45.8902
19.5 28.4 33.2996
-50 75.4 33.8932
-45.5 35.9 35.0746
-6 51.4 29.0698
-17.8 43.9 28.986
-54 29.4 39.119
62.7 53.4 58.014
-65 26.9 40.0868
71 24.4 90.735
-42.1 56.9 38.79733333
-38 48.9 32.8552
12 46.9 60.5912
63.1 4.9 62.82133333
-69.5 66.4 42.3318
-69 66.4 42.3414
-65.5 66.4 42.3296
-38.2 71.4 45.363
4.6 66.4 59.186
14.2 71.9 55.973
-45.95 78.4 21.347
-64 66.4 42.3324
-30.5 50.9 31.574
-41.4 31.4 32.26466667
56 64.9 51.4726
-62 66.4 42.3346
-6 43.4 28.478
-58.5 66.4 42.3574
-34.3 52.9 31.29
69 18.9 73.1772
-30.5 49.9 31.6142
-55 66.4 40.0324
-45 75.9 23.6238
-31.5 58.4 29.9454
4.5 63.9 60.7466
-32 56.4 30.5298
-52.5 66.4 40.0558
-50 32.9 40.8076
-50.5 64.4 40.9446
-38.85 83.4 27.2005
-44.5 40.4 36.5306
67 51.4 59.3236
67.525 50.4 59.97108333
3.4 66.4 54.796
-54.925 51.9 44.025125
-51 66.4 40.0584
-53.15 11.9 20.099
53 70.9 49.5466
-63.5 9.4 19.194
-49.925 66.4 39.98125
-52 40.4 43.215
19 72.4 53.6936
55.35 72.9 49.7925
-49.65 66.4 39.682
59 43.9 62.5736
48.8 50.9 60.192
57 56.9 57.0854
39.075 43.4 51.4305
-69 22.4 36.9136
30.5 47.4 55.0448
-67 22.4 36.9126
-40.575 42.4 34.43375
-39.975 64.9 46.01008333
51.7 70.4 49.2
-47 66.4 32.5808
38.5 72.4 48.1534
14.375 70.4 54.896
-36 58.9 26.962
-9.5 60.4 26.2022
11 47.4 60.847
-39.2 66.4 45.626
61.5 43.4 63.2224
-47.3 79.4 17.628
-43.55 52.4 36.96775
-38.95 66.4 45.251
-55 56.4 43.4234
-49 16.4 24.735
-1.1 58.4 26.92733333
57.2 8.9 47.726
18.6 30.4 36.26933333
65 34.4 62.6288
45.85 42.4 55.9835
-37.6 66.4 25.85
-16 44.4 28.6318
-67.5 26.9 40.08
46.8 40.9 55.471
-55 66.9 39.7918
-25.5 44.9 31.4002
-37.5 66.4 24.698
-67.5 17.9 32.7316
-69 79.4 32.7364
-3.8 66.4 25.902
-40.3 78.4 35.646
-33.05 83.4 41.6695
-3.2 66.4 28.321
27 72.9 51.1176
-46.025 60.4 35.51541667
-2.85 66.4 29.7275
-56.5 16.9 31.7418
-57.5 80.4 31.7918
-46.525 63.9 34.02491667
-2.5 66.4 31.131
-2.35 66.4 31.731
-1.7 66.4 34.33
-36 47.4 29.0908
-51 44.4 43.8814
-17.6 45.4 28.686
-0.85 66.4 37.774
63.65 72.4 49.226
68.925 73.4 52.051
-36 59.4 26.768
-0.7 66.4 38.38
61 66.9 53.37
-35.45 42.4 27.9715
-64.5 87.9 19.4002
-63.5 61.4 44.3086
1.8 66.4 48.426
-16 50.9 27.356
-67.5 76.4 35.7202
2.15 66.4 49.827
66.65 49.9 59.652
2.65 66.4 51.821
57 53.9 57.4558
4 46.4 28.1594
-43.05 58.9 36.66025
-62.5 67.4 41.8172
70.5 45.9 63.0762
-66.45 50.9 45.733
6.9 63.4 59.84466667
3.65 66.4 55.804
-5 57.9 27.4432
-59.5 67.9 41.5952
-16.6 56.9 25.985
4 66.4 57.222
-55.45 33.9 41.328
-16.7 56.9 26.489
65.3 25.9 69.404
5.1 66.4 59.656
-53 51.4 44.04
49 67.9 48.8374
-39.85 57.9 43.909
-62 27.9 40.6844
-48 74.9 33.1086
-53.35 84.4 21.4485
6.5 66.4 60.0044
-56.025 15.9 30.41783333
-25.475 51.4 31.51858333
-43.1 56.4 37.24066667
-47 44.9 37.3534
-34.95 56.4 28.379
8.7 66.4 58.518
-60 57.4 45.3396
-67 11.4 23.6378
63.8 61.4 43.938
64.7 18.9 69.353
-51.5 68.4 38.9494
53.7 67.4 51.803
-55.45 51.4 44.10975
60.925 35.4 52.549375
-54 73.4 35.6302
-39.9 59.9 35.65
-45 69.9 30.0304
59.8 32.9 60.825
65.5 44.4 63.1186
8.85 66.4 58.204
-44.5 35.9 35.0372
-59 80.4 31.7992
35 20.9 36.6078
3.2 63.9 59.196
-69 14.4 28.4232
57.5 35.9 52.8046
-65 31.4 42.4442
43 15.4 39.7742
-50.5 17.4 28.656
-49.35 58.4 36.2385
52.5 66.4 51.0868
-0.35 38.9 29.1285
56.5 66.4 52.4168
69.025 53.9 59.502
61.5 66.4 53.2972
-53.5 40.4 43.2232
-61 64.9 43.001
-10 57.4 27.6258
50.7 55.4 53.629
43.5 43.9 54.4092
63.35 7.4 63.7525
-49.9 11.4 16.808
62.35 3.9 60.861
65.5 59.9 54.34766667
64.4 69.4 53.405
3.15 55.9 60.935
-59.5 30.9 42.2632
-3.4 65.9 27.708
-23.5 45.4 31.4198
-55 23.4 35.155
-44.8 78.4 24.252
62.5 66.4 53.4314
63.15 66.4 53.006
5 60.9 61.7264
-35.1 53.4 28.26966667
63.525 66.4 53.028
64 66.4 53.1162
64.5 66.4 53.1942
65 66.4 53.272
-61 24.9 38.8356
-28 52.9 31.3314
61.5 65.9 53.0656
-61.5 42.4 45.7712
29 45.9 55.4534
-38.15 75.9 44.8325
-69 69.4 40.7312
66.85 8.4 69.908
-54 65.9 40.2804
71.8 50.9 76.591
34 49.9 53.242
-37.7 66.9 26.287
69.5 8.9 72.4238
-53.5 54.9 43.7166
-26 58.9 29.7144
62.65 42.4 63.005
20 32.9 35.6576
-55.7 29.4 39.867
22 45.9 57.1524
-11.925 54.4 28.50575
-64.5 68.4 41.3224
-38.05 62.4 30.83275
-11 45.4 28.8838
-62.5 53.9 45.926
-43.45 47.4 38.2805
45.35 68.4 47.8695
5.65 58.9 61.4305
-12.5 43.9 28.6572
-17.4 51.9 28.17766667
55 36.4 52.1122
50.575 68.4 49.010625
-63 35.9 44.2238
-48 16.4 20.878
64 38.4 59.317
-42.85 83.4 15.377
66.2 60.4 55.119
-66 66.9 42.0862
-51 62.9 41.5784
45.5 70.4 50.6834
68 19.4 72.8634
65.1 61.9 52.794
-55.7 83.4 25.149
-58 66.9 42.121
-15 62.4 24.468
-58.5 13.9 27.7836
-33.425 46.9 30.913875
-51 19.9 31.7842
-60.5 62.4 43.999
-11.5 57.4 26.5884
-49.65 50.9 43.795
6.925 54.4 61.39525
-53.5 66.9 39.7786
38.45 48.9 53.69325
-52.5 66.9 39.8002
10.45 49.4 60.6415
52.5 52.4 55.6372
-55.6 77.9 32.041
-22.5 49.9 31.6
-61.5 58.4 45.1336
-44.5 24.4 26.8554
-43.7 53.4 36.893
-59.5 87.9 19.5298
-9.5 55.4 28.2788
7.45 66.9 59.62675
-52 66.9 39.7862
-55.4 18.4 29.731
-12.3 47.9 26.697
-29 45.4 31.4406
-50 61.9 41.9098
-69 25.4 39.1256
60.5 65.4 52.5584
5.5 61.9 61.185
-36 57.9 27.4352
-39.45 79.4 36.8805
26.85 43.9 54.6895
66.5 71.4 52.1838
-46.5 66.9 32.2966
-52.5 28.4 38.5852
-45.05 66.9 32.276
-39.525 59.4 44.50558333
-4.1 65.9 24.786
-57.5 79.9 32.3636
0.95 65.9 45.7985
-68.5 79.4 32.7448
-40.475 66.9 46.02833333
-68 33.9 43.248
-69.5 13.4 26.9636
64.7 21.4 70.013
-19 45.4 28.9366
69.025 5.4 69.94483333
-38.575 66.9 29.02725
-67.575 81.4 30.52175
-6 61.4 25.6542
67.5 3.4 64.8928
-53 78.4 30.9376
-36.5 57.9 27.447
-37.5 66.9 24.099
-39 83.9 25.261
-37.4 66.9 23.018
-59 66.9 42.125
28.075 23.9 34.477375
-66 72.4 38.8848
5.1 66.9 59.19466667
20.05 28.4 33.68525
63.025 63.9 51.97525
-52.5 60.4 42.407
8 66.9 59.3426
-60 62.9 43.7792
8.85 66.9 58.626
-58 23.9 38.1386
-53.5 21.9 33.3128
-59 77.9 34.3886
-68 51.9 43.728
-53 70.9 37.4222
-70 57.4 43.7912
-10 53.9 28.6208
66 33.4 62.7972
-42.85 55.4 32.7185
4.5 55.4 61.9196
66.5 2.4 62.3186
7.7 65.4 59.43
2.5 38.9 31.256
-56 72.4 38.7788
-66.5 53.9 45.493
-62.5 52.9 46.0448
47.5 66.9 47.9076
-49 46.9 43.619
-58 84.9 25.6326
35.4 46.4 53.85033333
47.8 66.9 48.011
-44.5 24.9 27.3592
-34.8 83.9 37.603
26.5 26.9 34.7872
55.5 50.9 54.4842
-58 53.4 45.9926
-61 86.9 21.8388
-4.6 56.4 27.81266667
48.05 66.9 48.11625
-24.5 60.9 28.762
-20.85 55.4 25.612
-61 81.9 29.965
62 65.4 52.8304
-25 57.9 30.0758
48.5 66.9 48.2462
-17.05 62.9 24.66225
-49 64.9 33.5052
-28.525 51.4 31.51691667
58.4 4.4 50.774
64.35 29.9 64.8345
-17.1 59.4 24.723
52.3 56.4 55.031
49 66.9 48.371
-15 55.4 27.5354
53.65 49.4 61.3525
-63.5 68.9 41.0376
50.5 66.9 48.6976
-48 20.4 31.8336
-35.925 43.4 28.519
62.55 7.4 61.8125
70 44.9 63.1334
55.5 66.9 52.3162
-5 53.4 28.7908
53 54.4 54.8508
47.05 40.9 55.6135
-1.5 54.9 28.3976
-45.5 71.4 28.789
65.85 64.4 53.855
60.5 66.9 53.288
-48.5 68.9 30.8564
63.5 66.9 53.2556
34 48.4 54.4634
-51.5 54.4 43.8006
-23 58.4 29.9336
-58 24.9 38.8612
66 66.9 53.6972
-13.7 51.4 27.497
8.15 41.4 26.972
-22.5 48.9 31.61
-63 10.4 21.5948
-54 69.9 38.0854
33.9 45.4 53.42533333
64 43.4 63.1408
-55.975 52.9 46.00466667
-66.075 39.4 45.042875
-38.7 79.9 38.424
-69.5 67.4 41.8184
31.15 21.9 35.1125
-65 23.4 37.7514
-69 67.4 41.827
-55.7 19.4 33.315
-21.2 46.9 31.538
-47 30.9 32.5026
65.1 7.4 67.4195
-14 60.9 24.3036
-61 67.4 41.8604
-63.5 80.9 31.173
-45.5 50.4 37.5598
-60 67.4 41.874
-38.8 33.4 28.47
-10.45 49.9 28.947
-54.5 18.4 29.7648
5.65 50.4 60.7525
-49.075 74.4 33.024
-48.8 50.9 41.704
-56.5 67.4 41.8882
7 51.4 61.801
54 66.9 51.8954
-65 60.4 44.5886
54.5 66.4 51.9258
-51.5 81.4 27.2458
47.15 49.9 61.2265
-18 59.4 25.6336
-55.6 67.4 40.97
46.1 43.9 55.60266667
-6 50.4 29.088
-55 67.4 39.5396
27 27.4 34.5692
-40 71.4 45.3364
-48.5 28.9 31.0582
-54.525 67.4 39.52158333
15 33.9 36.8702
-67.5 71.4 39.5678
-40.2 43.4 34.107
-51.5 67.4 39.5406
42.5 68.4 48.2284
15.925 35.4 35.445375
-49.65 42.4 43.234
-48.5 67.4 31.9466
-48 67.4 31.959
1 43.4 28.381
60.8 60.9 42.974
-21.5 45.4 31.4376
30.975 22.9 35.51775
-40.2 72.4 44.924
53.7 50.9 52.254
18 43.4 57.7736
-40.85 67.4 45.8955
7.1 48.4 60.29066667
-22 60.4 29.0454
37.5 50.9 53.1948
24 27.9 34.5518
-55.8 17.4 31.843
19.075 43.9 58.04575
-39.2 67.4 45.715
64 57.9 57.3402
45.35 44.9 44.2285
-38.1 67.4 28.89333333
64.975 44.4 63.01675
57.5 56.4 57.4002
48 69.9 48.1444
-37.8 67.4 27.426
58.9 72.4 47.119
-59 44.9 46.1126
-6 46.9 29.0802
-26 60.4 29.0622
-69 55.9 43.7686
9.4 67.4 58.20866667
-64 32.9 43.1134
-64.5 59.9 44.7594
-43.55 54.9 36.89225
-34.15 45.9 29.435
5.6 67.4 59.13833333
4.65 51.9 60.755
-42.65 20.9 23.1795
-43.85 78.4 26.6745
45.5 69.4 48.9188
-66.7 58.9 44.506
-20 43.9 30.3838
-59 85.9 23.904
-9 58.4 27.0434
-60 10.4 21.7016
-35.3 57.9 26.982
7 67.4 59.5818
-67 77.4 34.8012
-68 30.4 42.0148
-0.5 43.9 28.7048
-17.3 42.4 28.495
-37.2 42.9 29.069
8 67.4 59.3116
-28 51.9 31.4816
52.05 47.9 59.72075
-70 28.4 40.9656
8.95 67.4 58.69375
36 21.9 36.2244
-48.5 79.4 17.4814
-62.5 17.9 32.7764
-63.5 79.4 32.823
-55.45 30.4 39.731
29 47.4 55.1402
-56.05 19.9 34.736
64 33.9 61.8674
52.35 48.4 61.5825
-40.35 83.9 21.2945
-61.5 47.4 46.212
42.475 42.9 54.01125
69.7 23.9 84.362
46 67.4 48.0948
3.3 60.9 60.972
38.6 44.4 51.152
48.525 10.9 42.97725
-7 50.9 29.0448
6.4 61.9 60.283
-36.5 47.4 29.138
47 67.4 48.3242
51.025 67.4 49.01266667
-19.375 57.4 27.41325
52.85 67.4 51.289
8.475 50.4 61.49833333
-45.5 62.9 34.4844
39.425 69.4 48.522625
62 36.4 53.8626
-28 53.9 31.1596
60 67.4 53.2868
63.5 65.4 52.2602
5 45.4 28.2056
-66.5 27.9 40.6816
-57 28.9 41.2616
-44.5 29.4 31.3982
65 67.4 53.6716
-51.5 82.9 24.9484
61.7 62.4 42.807
-40.8 51.4 33.991
65.35 67.4 53.711
-49.4 65.4 33.22466667
-52.475 77.9 31.51858333
4.85 46.4 28.146
73 50.4 91.104
-68 67.9 41.5538
-46 52.9 37.336
-67 67.9 41.575
68 63.9 55.2034
-44.5 34.9 34.6142
-66.5 67.9 41.5738
-36.925 50.4 29.01475
53.3 73.9 51.54
-41.35 58.4 39.8005
60.5 44.4 62.7022
-63.5 67.9 41.6032
-62.5 28.9 41.2552
33.475 45.9 54.00425
-63 67.9 41.5806
-16.5 53.4 28.6944
-62.5 67.9 41.5688
-57.5 83.4 27.9018
-47.5 59.9 35.729
-61.5 67.9 41.5684
-60.5 67.9 41.606
-60 67.9 41.5816
64 42.4 63.1694
-34.975 44.4 28.52541667
-59 67.9 41.604
16.425 72.4 54.996375
-66 62.9 43.778
-6 42.9 28.3248
0 65.9 41.859
-58.5 13.4 27.0268
4.3 47.9 27.607
-58 67.9 41.6176
-53 59.9 42.551
-5 61.4 27.439
-51.3 13.4 22.51
-17.1 58.4 23.676
-70 25.4 39.1426
-27.975 59.4 29.50925
3.8 49.9 50.174
64.625 6.9 66.43025
-56.5 12.9 26.3096
45.8 41.4 55.502
49.5 69.9 47.947
-49 67.9 31.6462
-53 67.9 39.248
-10.5 53.4 28.7308
-61 56.4 45.5358
-63.5 14.4 28.4648
49.8 70.4 43.999
-39.1 67.9 45.723
-32 44.4 29.9038
-52.5 24.4 35.8404
-55.45 47.4 44.1595
-37.5 67.9 24.337
-4.475 58.4 27.01108333
-55.7 47.4 45.888
-37.3 67.9 22.068
-34.4 59.4 26.615
-51 76.4 33.0598
-45.5 75.9 23.6454
-36.95 67.9 20.1855
38.925 72.4 47.974375
-35.5 50.9 28.7586
61.6 58.9 56.30733333
-4.625 46.4 28.922
4.4 53.4 61.40166667
5.6 67.9 58.63533333
-17.05 47.4 27.83125
-52.5 69.4 38.378
8 67.9 59.2216
68.3 62.9 55.643
-68 78.9 33.3198
-53 31.4 40.1372
32.5 48.9 54.0636
4 37.9 33.2456
-44.7 62.9 34.426
32.5 24.4 35.1846
-45.5 48.9 37.6192
-69 72.4 38.8656
-24.5 53.9 31.1602
-38 74.4 45.075
-66.5 12.9 26.1868
64.9 61.9 52.408
-17 50.9 28.1226
-45 31.9 33.0692
-55.45 41.4 43.49725
4.85 47.4 27.6725
-55.6 63.9 42.405
-3.5 54.9 28.378
67.5 54.9 59.086
0 66.4 41.201
-20.85 57.9 24.4375
-37.55 48.4 32.2745
-27 55.4 30.859
43 67.9 47.8144
53.4 50.9 53.361
-39.9 61.4 39.642
-43.35 44.9 37.7175
60.5 36.4 52.9176
45.35 67.9 47.8815
62.5 32.4 61.9946
-50.5 77.4 32.0582
-64 48.4 46.2216
-56.5 59.4 44.8872
58.8 73.9 50.629
-39.6 61.4 34.869
49.5 67.9 48.8924
55.5 64.4 50.8276
36.6 43.9 50.558
53.7 74.9 52.421
-64.5 25.4 39.1548
-41.2 27.9 29.598
-69 44.9 43.5818
-17.3 58.4 24.7
37.025 50.9 53.0055
-55.7 30.9 41.938
-1.7 65.9 34.756
-53.5 42.4 43.6424
52.85 67.9 51.289
-39.9 50.4 33.336
53 67.9 50.964
3.6 54.9 61.16466667
-56.05 60.4 44.6145
-66.45 55.4 45.305
-41.65 58.9 40.307
54.5 67.9 52.0974
-24.5 50.4 31.5876
-56 57.9 45.2762
57 36.4 52.7106
56.5 67.9 52.6436
70.7 54.9 65.638
-62 21.9 36.5406
65.65 73.4 49.118
-38.35 75.9 44.664
-64.5 17.4 32.2104
37.3 47.4 53.76
-68.5 58.9 43.81
-46 65.9 32.8912
-36.7 75.4 44.484
-69.5 79.4 32.7312
-55.45 16.4 27.79575
64.5 67.9 53.66
-2.5 57.4 27.6352
-49.7 15.4 24.761
-42.2 83.9 15.876
-69.5 68.4 41.302
-63.5 68.4 41.3068
-62 68.4 41.3446
2.6 49.9 44.115
50.7 72.9 47.097
-63.5 83.9 27.0986
21.5 73.9 52.937
-45.5 71.9 28.3052
64.35 48.4 56.343
65.7 48.4 59.155
-53.5 28.4 38.6008
-3 52.4 28.9178
-49.4 49.4 43.50833333
-59 68.4 41.3234
61 65.4 52.641
-13.15 58.9 22.8645
41.5 50.9 53.71
-16.5 59.9 24.671
-45 76.9 22.121
54.925 66.4 52.0315
53.9 73.4 50.967
-66.95 60.4 44.171
-42.5 33.4 33.8498
-60 83.4 27.9384
-56.5 68.4 41.35
6.5 66.9 59.8122
-55.8 68.4 41.017
64.95 14.9 66.362
-55 68.4 38.9514
-53 68.4 38.9588
-18.5 55.9 28.0968
54.2 64.4 50.465
-52 22.4 33.8372
65.1 18.4 71.935
-25.5 52.4 31.4084
-56.1 80.9 31.16166667
56.5 44.4 61.8532
60 68.4 53.2898
-49.55 60.4 41.8625
-37.7 73.9 44.854
-45.5 68.4 31.2326
-45 68.4 31.2042
67.5 4.9 67.7436
-53.575 72.9 36.01475
-18.05 43.9 29.1445
-49.65 52.9 43.638
54 67.9 51.9516
54.5 36.4 51.8792
-36.025 70.4 16.55075
-57 10.4 21.791
-65 62.9 43.7686
-40.35 68.4 45.9355
41.475 71.9 47.53433333
-39.5 68.4 45.9538
66.925 16.9 71.999625
-37.4 53.9 28.80733333
50.2 70.9 43.549
-38.8 68.4 45.5
-49.5 42.9 42.963
-40.3 46.4 42.117
-46.5 59.4 35.8846
-38.55 68.4 45.202
-37.3 68.4 21.751
-40.1 48.4 42.322
-68 66.9 42.0714
-36.95 68.4 19.541
-6.5 55.4 28.25
-50.5 73.4 35.6162
-43.1 59.9 36.18166667
-17.6 43.4 28.621
-50.5 27.4 37.9888
-57.5 85.9 23.8388
6.975 68.4 59.02416667
9 68.4 58.7448
-61 65.4 42.7894
-66 85.4 24.7424
64.5 58.9 55.832
-66.5 11.9 24.52016667
9.475 68.4 58.47233333
-40.1 44.9 41.048
53.6 66.9 51.189
65.8 73.4 49.376
60.975 43.9 63.02491667
46.55 50.4 53.341
-46.5 45.4 37.4206
57.35 5.9 50.4395
66.6 73.9 50.20033333
9.9 68.4 58.166
-69 61.4 43.818
-27.5 59.9 29.3244
44 67.9 48.1404
-49.35 74.4 33.0155
34.55 69.9 48.1045
-58 50.9 46.1884
5.5 65.9 60.1416
-34.95 83.4 38.7325
-36.3 33.9 24.956
48 68.4 48.7588
-59.5 65.9 42.563
-55.45 10.9 19.6775
-43.35 46.4 38.1495
47.075 10.9 41.90025
-53.5 51.4 44.0372
49.5 68.4 48.8924
-40.55 79.4 34.174
-27 58.4 29.925
-46 75.9 23.6416
52 68.4 51.1416
53.6 68.4 51.135
-58.5 57.9 45.24
63.5 68.4 53.44
24.5 74.4 51.7332
5 45.9 28.207
60.5 67.4 53.3802
39.5 70.4 48.8
-14.5 47.4 18.6808
49 51.4 52.4008
-64.5 24.4 38.4676
-12.8 48.4 25.714
-68.5 68.9 41.0348
-60 85.9 23.8922
10 70.9 57.6442
-46 48.4 37.628
-50 47.9 44.091
53.7 66.4 51.378
20.5 44.4 57.8002
53.4 66.4 49.92433333
2.9 63.9 57.459
-55.7 42.9 45.53
67.5 74.4 50.8082
-55.7 77.4 34.205
5.5 37.4 30.8292
5.075 65.9 59.963375
-55.7 69.4 39.979
-49.8 34.9 41.517
31.4 44.4 55.14533333
-41.8 63.9 45.608
-41.3 45.4 41.124
-26.5 46.9 31.5676
40.1 15.4 37.92383333
-51.5 34.9 41.6726
-57.5 59.4 44.92
-23.5 55.4 30.8382
-38.05 45.4 32.8115
4.5 62.9 61.146
63 66.9 53.2914
-66 16.4 31.1052
63 45.9 61.1508
-48 40.4 36.5626
-47 68.9 30.8474
65.5 42.9 63.449
-10 57.9 27.44
-60.5 61.4 44.3216
19.5 31.4 36.3838
-61.5 71.4 39.5592
-42.45 45.9 39.27675
-20.1 54.4 25.945
64.55 37.9 64.997
-66.5 68.9 41.0392
-61.5 35.4 44.0816
28.5 46.4 55.5976
27 74.4 50.865
-47.025 74.4 32.99833333
72.4 53.4 85.068
-62 11.9 24.5398
-56.5 56.4 45.5874
-61.5 68.9 41.055
-50.5 43.4 43.7628
-35.6 36.4 25.70266667
18 34.4 35.6548
-0.5 49.9 28.96466667
-15.65 46.4 27.682
-54 28.9 38.8716
55 64.9 51.223
63.025 42.4 63.01691667
21.025 71.9 52.02208333
33.425 42.4 50.5265
52.5 67.9 51.2878
-57 68.9 41.0682
26 25.4 34.3392
-59 69.4 40.7592
-4.45 66.4 23.27
-51 68.9 38.6802
1.525 45.4 28.531
-29 56.9 30.4454
-70 18.4 33.2666
58.5 35.9 52.9
58 66.4 52.6484
69.5 73.4 52.2872
-66 44.9 45.9268
-50.5 68.9 38.6902
57.975 43.4 62.5125
-57 23.9 38.1842
-42.8 55.9 32.387
-54.5 67.9 39.2664
-46.3 22.4 27.522
-49.35 68.9 30.993
-66.4 49.9 45.82833333
67.5 49.4 60.3062
-42.15 49.4 35.6535
-5.5 47.4 29.0964
-53 22.9 34.2546
-36.5 61.4 25.7594
-57 75.4 36.6454
-29.9 87.4 38.75833333
-69 31.9 42.6784
37 18.9 37.28
63.85 7.4 64.9045
-46.5 68.9 30.8508
-68.5 26.9 40.0662
-24 49.4 31.6188
-46 68.9 30.8544
53.9 48.9 57.439
-40 68.9 45.9078
-49 39.4 36.31
-49.65 71.9 36.427
-51.5 80.4 28.5816
-39.3 33.9 29.99
-38.65 68.9 45.3745
-29.4 44.4 31.107
-33.5 82.4 42.1264
-21.15 56.4 30.5225
-38.3 68.9 44.281
-38.15 68.9 43.9525
60.95 60.9 43.316
61.5 61.9 43.5088
46.525 13.4 41.50475
-20.85 44.9 30.866
-50 40.4 43.2002
-35.55 42.9 28.16125
-36 68.9 18.8528
6.8 68.9 58.478
-17.5 49.9 28.0984
8 68.9 58.8326
-62.5 46.4 46.164
64.7 60.9 52.319
10.925 68.9 57.4415
-50.5 60.4 42.4094
-41.5 62.9 46.04566667
46.65 45.4 48.7705
42.575 68.9 48.51525
71.4 53.9 73.411
-66 26.4 39.795
-35.7 68.9 18.662
-70 77.4 34.721
53.15 57.4 55.131
-11.5 59.4 25.622
50.3 49.9 64.322
61 45.4 62.152
44 68.9 48.5888
14 73.9 54.824
-66 52.9 45.9136
-42.9 83.9 13.812
-46 31.4 32.7508
46.5 68.9 48.6608
-44.5 42.9 37.077
10.8 68.4 57.152
15.5 74.4 54.2256
47.5 68.9 48.7094
-45.5 41.9 36.8978
-30.5 58.9 29.6992
-52 84.9 21.3356
29.1 22.9 34.23066667
48.5 68.9 48.7786
-56.5 19.9 34.866
-51 55.9 43.4862
-36 52.4 28.8778
-59 78.9 33.4046
-20.075 44.9 29.904
-16 46.9 27.378
-48.5 62.9 34.4974
-68 32.9 43.0878
-68.5 65.4 42.771
63.475 43.4 63.02933333
-55.8 83.4 27.397
51 68.9 48.8518
-37.3 43.9 29.185
-32 48.4 31.6074
47.5 52.9 53.7416
69.025 13.4 70.97458333
-49.65 55.9 43.247
-65 41.9 45.6798
-69 30.4 41.9882
53.6 68.9 51.076
-59.5 44.9 46.1102
29.5 72.9 50.4396
-54.5 85.4 19.2112
-55.6 61.4 43.146
-62 30.9 42.276
-24.5 57.9 30.0742
70.95 75.4 51.74325
-57.5 52.9 46.0786
44.05 49.4 54.3285
-61.5 60.4 44.5996
-56.1 9.4 19.172
59 36.4 52.9128
-44.5 41.9 36.8884
58.5 68.9 52.8544
-69.5 44.4 43.5314
-4 44.4 28.7194
-56.5 85.9 23.7596
47.5 67.4 48.4232
-60 48.4 46.2526
59 68.9 52.9678
72.1 50.4 81.129
-67.5 73.9 37.7708
-36.5 43.4 28.6458
-53 64.9 40.7284
-42.7 49.9 34.153
-32.5 84.9 40.9436
-46.3 74.9 24.962
62.7 68.9 53.448
68 36.4 66.2092
64.5 52.9 57.4676
-55 43.9 43.844
63 68.9 53.3152
-55.45 48.4 44.1945
-45.5 43.9 37.2426
64.4 68.9 53.55833333
52.15 8.9 45.6165
41.45 41.4 50.8275
-54 65.4 40.5506
43 51.9 53.5772
66.1 74.9 49.23433333
-62 42.9 45.8304
-37.85 61.4 30.9435
-51 29.4 39.1132
-53 74.9 34.4008
62.4 41.4 63.103
50 68.9 48.789
6.5 64.9 60.3442
-23 43.4 31.0414
-22.5 56.4 30.5444
-57 43.4 45.934
-15.5 58.4 19.1744
1.5 48.9 28.3704
-66 34.9 43.874
-62.5 77.4 34.8462
52.8 45.9 55.231
4.05 34.4 36.335
-70 69.4 40.7254
-34.05 83.9 39.814
25.5 26.4 34.5946
-46 66.4 32.6
-57.5 38.9 45.104
-41.65 57.9 38.8055
-68.5 69.4 40.7298
57.15 61.4 42.8725
-68 69.4 40.7308
52.45 39.9 58.2305
-13.7 44.4 28.645
65.05 62.9 52.4795
-61 69.4 40.771
-56.05 69.4 40.70925
-13.5 43.9 28.6458
33.7 44.9 56.454
-47.5 51.9 37.487
68.975 70.9 53.01483333
-55.6 69.4 38.721
-55.4 69.4 38.40666667
66.5 5.9 68.169
-31 44.4 30.3666
-25 48.9 31.6208
-52.5 74.4 34.833
69.8 23.9 84.908
-54.5 69.4 38.3892
-53.5 69.4 38.389
27 27.9 34.2326
-40.05 70.9 45.3405
-56 62.4 43.9642
-67.5 77.4 34.7948
-53 69.4 38.3758
-12.85 57.4 24.32
-64.5 11.4 23.6562
65.3 58.4 57.074
37 21.4 36.3416
-57 59.4 44.9152
-62.5 71.4 39.5768
-63 76.9 35.2764
17.9 29.4 34.814
-50.5 69.4 38.3892
-18.45 46.9 28.17375
-49.7 69.4 38.136
-63.5 44.9 46.069
68 61.4 56.228
-49.075 69.4 30.50125
32.5 21.4 35.694
-68.5 33.4 43.1958
-48 69.4 30.4384
-58.5 82.4 29.3336
-47 69.4 30.4558
8.55 32.4 36.29675
-40.3 69.4 45.892
70 51.4 60.6342
27.15 47.9 54.365
-42.55 56.4 38.288
2.3 66.4 50.426
-39 69.4 45.778
-40 83.4 23.777
-64.5 61.4 44.2996
-38.7 69.4 45.614
-37.6 51.4 29.257
4.475 44.4 28.02058333
-50.5 58.9 42.8336
-68 14.9 29.1176
-29.5 45.9 31.447
-38.3 69.4 44.34
17 29.4 36.2794
34 46.4 54.1138
-37.85 69.4 44.2335
-46 23.4 28.1572
-37.55 69.4 44.32425
-37.2 69.4 21.074
-36.4 69.4 18.10266667
-21 56.9 26.949
6.7 69.4 57.863
-55 31.4 40.1818
70.6 52.4 61.804
7.1 69.4 58.12666667
72.075 45.9 63.023375
9.025 69.4 58.51725
-54 63.4 41.3486
-66 83.9 27.0896
10.9 69.4 57.64133333
-62 31.4 42.4552
-46.3 78.9 20.205
-61 50.4 46.1994
-46.7 76.9 32.113
11.15 69.4 57.458
-69 13.9 27.6912
11.9 69.4 56.74933333
-48 57.4 36.4922
-56.5 44.4 46.0656
2.55 36.9 32.665
12.55 69.4 55.8305
65.1 18.9 71.935
36 69.4 47.04
-9.925 58.9 27.005625
-37.4 62.9 28.671
-68.5 56.4 43.7804
19.5 74.4 53.4304
-70 69.9 40.432
34.5 23.4 35.3106
-66 17.4 32.2166
-66 18.4 33.2774
-12.5 44.4 28.7114
-55.8 29.9 41.481
-49 54.4 37.168
66.4 65.4 54.33766667
-1.5 58.9 26.8866
62.45 45.9 61.73575
66.55 72.4 50.73575
-50.5 76.4 33.0356
-67.5 48.9 43.7642
-21.5 57.4 30.2496
-36.9 59.4 26.83766667
-64 62.9 43.7982
-44 50.4 35.85433333
52.5 67.4 51.2878
-11.5 54.9 28.3932
-60 77.9 34.3958
-65.575 68.9 41.0205
61.85 3.9 58.4565
38 69.4 48.3372
41.5 69.4 48.5972
72 55.9 84.115
-55.7 52.9 45.841
5 56.4 62.0354
71.7 50.9 75.329
46 69.4 48.7446
46.5 69.4 48.6196
-67.5 68.4 41.3106
-68 32.4 42.8936
6.9 54.9 61.226
71 54.9 69.648
-63 14.9 29.142
-65.5 22.9 37.3282
69.5 74.9 51.7546
47.975 69.4 48.51875
68.5 17.9 73.2548
-59.5 64.4 43.1928
50.5 69.4 48.425
-65 39.4 45.1776
62.85 6.9 62.774
-69.5 64.4 43.1746
53.4 69.4 49.54766667
-67 16.9 31.6534
-20.35 60.9 27.344
53.6 69.4 50.794
-13 45.4 28.1282
68.7 23.9 78.908
55 69.4 51.857
55.525 69.4 52.01708333
-59 17.9 32.8144
-51 74.9 34.3736
-34.3 57.9 29.205
-48.5 74.9 33.1196
58.5 69.4 52.6796
-8 45.9 28.9492
-55.7 23.9 36.835
-65.5 75.9 36.1774
56.5 70.4 51.624
62 69.4 53.4428
-30 45.9 31.4254
-68.5 44.4 43.5636
46.5 53.9 53.2538
-35.1 83.4 38.288
53.1 48.9 61.52
-16 47.4 18.8746
-37.4 61.4 30.20033333
-47.5 72.9 32.2594
-62 86.9 21.8524
-62 18.4 33.294
-19.3 60.9 26.017
61.05 5.4 58.31225
-49.15 43.9 39.896
-61 77.9 34.3766
66.5 18.4 72.6426
54.575 45.4 60.49175
37.5 49.9 53.9208
-68 24.9 38.8254
27.05 71.4 49.66675
-15 60.9 24.5424
35.5 22.4 35.9328
-44.05 83.4 11.808
37.65 40.9 46.041
-36 54.4 28.5504
-56 49.4 46.218
-17.65 58.4 26.1035
-62.5 78.9 33.3986
60.5 68.4 53.3838
-55 27.9 38.3198
-37.6 44.4 31.103
11 68.4 56.78333333
71.8 51.4 76.685
-3 59.4 26.468
-69 69.9 40.443
-41.2 84.4 17.822
-59 65.9 42.5654
0.4 66.4 42.806
26.85 24.4 33.9275
61.5 55.4 58.4572
62 71.9 50.881
-68.5 69.9 40.4326
-3.85 53.4 28.605
-67 69.9 40.4276
52.7 47.9 58.808
34 70.4 48.7982
20.5 74.4 53.1184
-60 76.9 35.3378
-38.85 58.9 31.8605
-66.5 69.9 40.4466
-65 69.9 40.4344
-63 69.9 40.46
63.3 35.9 55.221
-56.075 74.9 36.952125
-62 69.9 40.4756
-59 59.9 44.76
54 69.9 51.2228
-58 69.9 40.4858
-23 60.9 28.7852
66.5 14.4 68.7174
-67.5 18.9 33.785
50 67.4 48.859
-52 17.9 28.8528
-52 27.4 37.9768
-16.5 52.9 28.6944
-55 69.9 38.063
-54.5 69.9 38.0714
70 18.4 73.325
-52.5 69.9 38.0698
-53.5 60.4 42.423
-49.975 69.9 38.01625
-39.8 44.9 38.764
-60 68.9 41.0846
64.3 36.4 64.135
65.05 3.9 64.2625
-17.2 58.9 24.216
-66 67.9 41.569
-28.5 44.4 31.2576
-58 87.9 19.553
-49.65 69.9 37.6965
58 43.9 62.332
-54.525 72.9 36.01766667
-49.4 69.9 30.23633333
-48.5 69.9 30.0532
-66 60.4 44.5526
-45.5 69.9 30.0566
-40.875 69.9 45.04
-54 51.4 44.0516
-19.8 41.9 27.954
65.85 59.4 54.9945
-67.5 62.4 43.8766
-36 69.9 17.3336
-68 53.4 43.7614
-46.5 61.4 35.1318
7.15 69.9 57.636
-13.8 58.4 21.713
7.8 69.9 58
65.5 72.4 49.252
61 68.9 53.4102
-67.5 15.9 30.4698
54 43.4 61.627
-49.8 85.4 19.252
-46.5 58.4 36.2244
-9 48.4 29.0748
8.05 69.9 58.11875
47.025 54.4 52.99825
-12.95 59.4 24.27575
11.2 69.9 57.513
64.3 8.9 65.256
-58.5 34.9 43.9072
-25 60.9 28.7342
70.4 50.9 60.697
11.5 69.9 57.3204
24 28.9 34.4092
-57 81.9 29.9944
-25.5 53.9 31.1412
-52.5 44.4 43.8946
58.5 59.4 55.1696
71.7 55.9 80.094
12.4 69.9 56.63966667
2.8 66.4 52.417
7 48.9 60.7856
-46.5 67.9 31.6254
-53.5 79.4 29.8272
-26.5 58.9 29.722
72.3 49.9 85.344
-37.8 79.9 40.734
13.6 69.9 54.835
-14 51.4 27.2888
19.5 45.9 57.882
-36.5 57.4 27.6734
-60.5 71.9 39.2676
-13.65 59.4 23.4395
31 47.4 55.0104
-55 39.9 43.1626
62.625 6.4 62.3935
-58 30.4 42.0304
3.15 55.4 60.6805
9.5 35.9 35.8802
-12.7 58.4 23.903
-7.5 52.9 28.7912
13.1 44.4 59.14066667
38 69.9 48.6954
-40.075 42.4 33.973625
48 68.9 48.7588
37.5 69.9 48.6784
-37.4 64.4 24.779
-38.15 78.9 40.7025
-48.55 44.9 40.727
58 70.9 51.4448
70.9 52.9 66.396
39.5 69.9 48.723
40 69.9 48.712
41.2 14.4 38.034
-57.5 78.4 33.9256
-59 65.4 42.7666
5.5 34.9 36.8112
-47 49.9 43.2408
-52 71.9 36.7072
44.5 69.9 48.6316
44.8 69.9 48.827
-47.5 68.9 30.8632
67.5 35.9 66.0784
71.5 43.4 63.5712
-66 80.9 31.154
-60.925 24.4 38.51325
-42 29.9 31.7544
44.9 69.9 49.766
-59 74.4 37.4398
28.475 45.4 55.51916667
45.125 69.9 50.49275
53 66.9 50.777
45.9 69.9 49.004
-10.85 50.4 28.6935
-3.5 49.9 29.0676
-50.95 85.9 18.96425
-10 60.9 25.6632
52.5 43.9 58.693
-55.7 33.9 43.188
50.8 69.9 47.759
-8 56.9 27.806
50.8 70.4 42.369
-48 42.9 37.1006
-43.45 76.9 21.29625
27 73.4 51.1176
51.4 69.9 40.85533333
70 13.9 71.1928
-48 41.4 36.7466
-32.5 46.4 30.145
68.15 4.9 68.186
51.7 69.9 50.272
-58.925 87.9 19.5235
56.025 67.9 52.52658333
-46.5 62.9 34.482
16 30.9 36.932
-5 43.9 28.631
-57.5 78.9 33.3948
-10.5 53.9 28.6096
50.075 43.4 59.998375
-64 86.9 21.7828
-54.5 31.4 40.1482
-52.5 44.9 43.9342
63 57.9 57.1424
56 69.9 51.8682
17.95 31.4 36.62425
63 38.4 58.066
-16 50.4 27.4546
-10 44.9 28.8344
18.1 30.9 36.479
-34.3 58.9 28.678
20.5 71.9 52.3346
-63 34.4 43.7162
4.05 53.9 61.30725
60.5 69.9 52.8942
49.5 52.9 53.7996
2.5 66.4 51.224
66.5 16.9 72.2716
34.6 42.9 51.17
41.6 42.4 53.136
31.5 47.4 54.9404
-19.975 42.4 30.01525
41.075 42.9 52.977
-50.5 31.4 40.1822
-48.5 60.4 35.5386
70 73.4 52.4048
-47 60.9 35.3438
-47.5 49.9 43.322
-59.5 86.9 21.8404
49.5 50.9 64.093
-37.4 58.4 29.486
63.8 71.9 49.293
9 35.4 36.2242
19.3 28.9 33.339
-65.5 44.9 46.055
-35.1 46.9 28.85066667
-55.7 74.9 36.412
-65.5 62.4 43.9478
-70 81.9 29.8854
-46 56.9 36.6156
63 53.9 57.9528
-58.5 72.4 38.9436
64.55 31.4 58.2805
-60.5 57.4 45.346
60.9 7.9 54.8
-17 42.4 28.359
-55 19.9 31.9324
-14.55 46.4 27.4675
22.025 30.4 34.04541667
-20.85 55.9 25.4395
51.85 71.9 45.6315
-19.15 58.4 27.0575
-56.5 77.9 34.3864
-50.5 14.9 24.7996
-42.7 83.4 15.821
-55.9 8.9 17.128
51.1 69.9 40.85433333
-57 53.9 45.945
-3 54.4 28.503
5.4 66.9 59.44033333
-34.875 47.4 29.4105
65.8 60.9 54.411
-64.5 16.4 31.07
58.5 57.4 57.1128
-51.5 62.4 41.736
62.15 4.9 61.138
-49.5 45.9 43.384
64 41.9 63.0902
-69.5 70.4 40.154
54.5 8.4 47.3088
-35.6 38.4 26.79533333
-15.55 48.9 23.312
-69 11.4 23.6382
-42.05 63.9 45.25725
-68.5 70.4 40.1676
59 54.4 57.901
-50.5 22.4 34.2632
-14 53.4 28.5946
-42 33.9 34.1426
16.7 45.9 59.014
-39.95 53.9 32.68
-59.5 66.4 42.377
22 75.4 51.6582
-11 49.9 28.1324
61.05 30.9 60.79375
-32.9 44.9 29.60433333
-68 70.4 40.145
34.5 49.4 53.9228
-65.5 59.4 44.8806
-54.95 22.9 34.2995
59.65 8.9 53.218
-62.5 57.4 45.3602
56.5 72.9 48.552
-65 28.9 41.2428
-54.5 70.4 37.7342
-7 57.4 27.6264
-41.9 47.4 40.15233333
-24.5 55.9 30.6954
61.45 8.9 57.2315
-23 59.9 29.2842
60.5 53.4 57.8542
21.025 72.9 53.02916667
60.65 4.9 57.708
2.85 57.9 60.9375
-21.9 42.4 30.85933333
-61.5 10.4 21.643
-24 59.9 29.3076
60.025 64.9 52.02366667
-41.575 42.9 35.44225
32 24.4 35.3274
-50.5 70.4 37.7494
70 72.9 52.6008
-36 61.4 25.7346
-43.5 32.9 33.6128
-57.5 51.4 46.171
-62 31.9 42.7128
-60.5 80.4 31.7698
-48 70.4 29.6334
-44.65 70.4 29.6165
-58.5 67.4 41.874
-60.5 32.4 42.9222
-40.8 70.4 45.114
-45 34.9 34.616
-55.075 11.9 21.452375
63.1 65.9 52.80566667
47.65 50.4 56.805
3.3 50.9 49.772
-53.15 84.4 21.328
52.55 46.9 57.1695
-64.5 46.4 46.1686
43 68.4 48.3088
68 26.4 70.2398
61.5 40.9 62.9142
7.85 70.4 57.4125
71.4 50.4 73.389
-45 44.9 37.3744
-52.5 30.9 39.9228
-36 67.4 20.7608
71.5 45.4 63.0982
10 70.4 57.8988
67.3 66.4 53.895
-27 60.4 29.0234
-58 83.9 27.1712
-46.5 51.9 37.4722
-45 54.4 37.1608
11.075 70.4 57.555125
11.95 70.4 57.10325
18.5 43.9 58.2076
-62 21.4 36.119
12.5 70.4 56.7694
-51 63.9 41.168
-62.5 34.9 43.8664
-59 46.4 46.1804
-26 56.4 30.5512
-62 63.9 43.4162
13.975 70.4 55.44858333
-69 47.9 43.6472
-60.5 53.9 45.935
-49.5 30.9 38.149
-11.6 51.4 28.30366667
-68.5 87.4 20.5766
-45 67.4 31.9162
-47.99166667 24.9 27.42383333
69.45 7.4 71.9365
-55 16.4 27.7442
-36.5 63.4 24.4858
40 17.4 38.4304
-54.5 79.9 29.1972
-29 47.4 31.609
-11.85 58.9 25.7055
29.5 46.9 55.3006
-17.35 63.4 24.385
-59 27.4 40.4166
32.975 70.4 48.53191667
34.5 70.4 48.846
57 64.9 51.6052
-68 69.9 40.4414
-35.5 80.4 43.3472
64.5 42.9 63.256
33.85 73.9 48.389
-37.1 67.4 21.158
4.5 38.4 33.3844
52.9 50.4 65.063
63 67.9 53.4102
35 70.4 48.8768
35.5 70.4 48.8958
2.1 50.4 42.469
37.5 70.4 48.8768
51.575 9.9 44.959375
-40.15 84.4 20.8655
-46.6 19.4 30.82233333
40 70.4 48.729
59.5 66.4 52.919
34.15 19.4 35.312
60 35.4 52.2936
42 70.4 48.5998
-19.375 51.4 28.99375
52.9 69.4 50.88633333
-36.625 73.9 43.8525
46.05 70.4 47.9725
46.5 70.4 47.8794
-52.5 75.9 33.499
47.5 70.4 47.6018
-64 38.4 44.9514
61.5 57.4 57.8532
-64 61.4 44.3058
-36.35 35.9 25.531
48 70.4 47.4932
48.35 70.4 47.4655
-42.05 84.4 15.348
-58.5 52.4 46.1082
50.45 70.4 44.403
-52 38.9 42.9094
50.9 70.4 41.609
30.5 44.9 54.7172
13.5 47.4 59.7278
-39.5 70.4 45.7894
-56.075 83.9 26.95475
51.1 70.4 40.85433333
63.15 3.9 62.1365
53.6 70.4 50.173
-42.5 31.4 32.7404
8.525 67.9 59.00433333
53.7 70.4 50.637
64.05 7.4 65.3335
2 46.4 28.415
-52.425 23.9 35.486625
9 68.9 58.6914
44.5 68.9 48.6012
-57.5 39.4 45.2196
-65 56.4 45.5538
54.575 70.4 50.9985
64.8 25.9 68.982
-56.1 88.4 17.954
55 70.4 51.1524
-49.6 25.9 36.378
72.1 53.4 81.462
7.5 69.4 58.3584
-60.5 73.9 37.765
-50 58.4 42.915
69 15.4 71.2846
-33.5 59.4 27.147
40 45.9 50.9278
56 56.4 57.1182
53.5 72.9 48.6912
6.95 53.4 61.6625
-65 58.4 45.1392
-48.5 46.4 43.447
3 43.4 28.3278
32.85 24.9 34.5125
-40.85 83.9 19.835
-58 78.9 33.4366
65.5 62.9 53.2804
3.5 50.4 49.974
63 70.4 52.592
-17.4 48.4 27.88733333
23.925 75.4 50.98
-35.5 79.9 43.4452
-69 34.4 43.1958
-65.5 83.4 27.8562
-65 65.4 42.7668
-52 34.9 41.6672
-55.85 87.9 18.7895
-49.5 15.4 23.802
-41.85 83.9 16.905
62 64.9 52.3082
-2.5 56.9 27.8044
-64 74.4 37.391
-61 65.9 42.5928
72.8 54.4 91.035
65.2 10.4 65.408
-28.5 53.9 31.1692
64 35.4 59.826
-58 61.9 44.1472
-67 17.4 32.2136
62.95 4.4 62.258
-43.15 79.4 27.815
-67.2 49.9 43.834
66.9 7.9 69.792
-46 67.4 31.9192
-32 55.4 30.8234
-52.5 67.4 39.4966
-21.15 47.4 31.5345
64.525 71.4 51.50666667
-18.5 57.9 26.3836
66.4 64.9 54.734
-26 57.9 30.0726
-6 45.9 29.0048
-64 81.9 29.9342
-69.5 70.9 39.844
9.925 50.4 60.425125
-62.5 70.9 39.8886
11.4 68.9 56.8775
67.55 74.9 50.65775
-49.4 75.4 32.979
68.5 25.9 70.3384
14.7 35.9 34.654
-55.4 55.4 43.652
-37 43.9 28.6846
-58 82.4 29.3636
5.2 49.9 57.208
56.1 6.9 49.14933333
24.975 45.4 56.50016667
68.4 23.9 77.276
-57 70.9 39.92
-55.7 70.9 39.226
-47 55.4 36.969
-21.5 49.4 31.6114
18 72.4 54.2152
59.65 4.9 53.103
-52.5 70.9 37.4552
-51.5 70.9 37.4212
31.5 71.9 49.8816
-32.5 54.9 30.9602
-57 77.4 34.8814
65.6 16.4 70.726
-46.4 23.9 29.407
-66.5 19.9 34.7656
-3.15 65.9 28.7485
-52.5 35.4 41.8302
-47 70.9 29.243
-44.65 70.9 29.2125
37.5 19.4 37.4106
-65 85.4 24.7552
72.925 44.4 63.02625
60.4 60.9 42.27633333
53.7 52.9 56.277
54.425 64.4 50.548125
-35.7 34.4 24.653
-40.5 70.9 45.1544
-42.85 54.9 32.7185
4 61.9 61.2586
-34.5 84.4 37.307
-51 27.9 38.26
-39 70.9 45.7482
-64 69.4 40.7542
-60 51.4 46.1746
8.2 70.9 57.056
65.025 57.4 58.00558333
9.075 70.9 57.506875
60.5 64.9 52.1152
-36.5 45.9 28.9456
-50.5 42.4 43.6248
47.5 68.4 48.7094
-48 79.4 17.4756
-68 72.4 38.8588
15.4 70.9 54.63533333
-70 58.4 43.781
-12.2 59.9 24.99
36.5 46.9 53.748
-51.575 11.9 18.9935
-1.5 55.4 28.288
-44.3 21.4 25.486
55.025 58.9 55.00216667
-46.35 50.4 37.571
-37.35 59.9 30.377
-33.475 51.4 31.5245
-59.5 77.9 34.4208
-5.5 46.4 29.041
-16.5 44.4 28.6818
5.15 50.9 60.343
55.975 74.4 52.94783333
-57.5 26.4 39.8546
-5.5 59.9 26.5648
-19.35 43.9 29.9705
-70 82.4 29.2154
32 70.9 49.167
58.85 6.9 51.4045
-56 53.9 45.9108
-57 45.9 46.183
51 8.9 44.738
32.5 70.9 49.1874
-66.025 50.9 46.02958333
-70 54.4 43.7304
39.3 42.4 50.427
23.5 29.9 33.916
-48.5 56.9 36.6356
44.85 45.4 43.663
7.5 48.9 61.1422
-58.5 50.4 46.2066
-25.5 56.9 30.395
28.5 73.4 50.7298
18.525 31.4 35.03541667
-41.6 33.4 33.67433333
40 70.9 48.7134
5 38.4 33.422
41 70.9 48.5694
-34.65 57.9 28.3925
42 70.9 48.3886
65.65 74.4 48.7185
-39.5 70.9 45.7224
-1 43.4 28.4268
-54.425 45.9 44.021875
-47.5 56.4 36.7496
42.5 70.9 48.2762
-51 20.9 32.3928
-58.5 23.9 38.1338
-13.7 58.4 21.913
43 70.9 48.1796
-68.5 49.4 43.6972
-33 47.9 31.6392
6.2 59.9 60.486
61.65 5.4 60.3135
-13.8 57.4 20.287
49.5 70.9 42.9336
49.95 70.9 42.92575
-54.5 75.9 33.483
-11.5 59.9 25.4538
-55.65 62.9 43.3795
50.65 70.9 42.832
-59.5 72.4 38.911
50 40.4 59.7248
-62 68.9 41.0606
-60.5 41.4 45.596
28.475 26.9 34.50791667
-70 15.9 30.4612
48.2 50.9 56.856
47.5 43.9 56.1812
-59.5 58.4 45.1354
-67.2 59.9 44.011
-56 53.4 45.9628
19.6 29.4 34.258
4 55.4 61.6714
-63.5 70.9 39.8888
10.3 45.9 59.952
54.05 70.9 50.221
-31.5 50.9 31.5736
-51.5 41.9 43.528
30 46.9 55.241
56.075 70.9 51.006125
-46 70.9 29.228
-42 43.4 35.8192
-53 78.9 30.4402
-8 46.9 29.069
-35.5 54.4 28.219
60 66.9 53.1864
-37.5 43.4 30.414
-66 85.9 23.8248
-68.5 36.4 43.3298
-68.5 61.4 43.8256
-49.5 39.9 42.417
-23 44.4 31.2546
-7 53.9 28.6452
-55.4 78.4 30.965
-65.5 31.4 42.4492
9.3 46.9 60.513
-39.2 78.4 38.446
-62 10.4 21.6346
-56 79.4 32.771
63.5 63.9 52.3026
-67.5 46.9 43.724
-47 73.9 32.9054
45.45 49.4 57.737
-43.5 57.9 36.6756
-66.45 46.9 45.77625
-61.5 24.4 38.5014
69.85 70.9 53.2805
-35.575 53.9 28.40525
-3 44.4 28.7572
47.05 44.9 49.7855
-11.5 50.9 28.1366
65 62.4 52.45
-53 19.4 31.3512
-37.3 66.4 22.396
64.45 46.9 57.24
-41.4 47.9 40.70766667
-57 87.9 19.54
-56.5 66.4 42.3936
66.9 2.4 62.229
-70 71.4 39.5338
55.975 73.9 51.50025
-55.8 70.4 39.989
-69 80.9 31.0888
32 71.4 49.5978
39.05 16.4 37.742
9.5 70.9 57.6146
34.5 49.9 53.4324
-68.5 52.9 43.7236
54 51.9 55.6808
-51 80.4 28.5634
-29 56.4 30.5786
1.425 45.9 28.510625
-66.5 71.4 39.5718
48.9 50.4 60.732
-16.5 54.4 28.4916
13.025 71.9 56.531
52.35 45.4 55.8155
-17.35 43.4 28.4595
-31.5 84.9 40.4214
-59 71.4 39.5914
-53.5 80.9 27.9392
53.6 54.4 56.689
-6.5 45.4 28.9112
-39.525 71.9 45.52175
-56.025 71.4 39.45858333
-54 80.9 27.9354
62 53.4 58.1222
69 45.4 62.9102
-54 71.4 37.1114
1.7 49.9 39.572
-17 46.4 28.1562
-49.7 79.4 29.524
-61.5 11.9 24.5658
12.5 33.4 37.4506
72.7 52.4 88.435
-37.4 63.4 29.408
-53 24.4 35.8684
-58.5 86.9 21.8526
55 54.9 57.1404
-55.85 16.9 31.3865
-68.575 11.9 24.52375
-40.6 57.4 41.31733333
-62.5 86.4 22.8594
17.5 44.4 58.752
-65 63.9 43.4236
-62 26.9 40.1238
-47.5 71.4 28.7752
48.3 40.9 56.477
71.3 49.9 76.762
-62 75.4 36.6276
8.5 40.9 27.0674
-44.45 71.4 28.3775
-38.9 64.4 30.25666667
53.55 41.9 61.69575
-50.5 82.9 24.9506
-17 61.9 24.686
-57.5 49.4 46.2482
-48 76.4 32.7938
-37.3 58.9 27.205
-50.5 75.4 33.9628
-47 72.9 32.2126
-38.475 71.4 45.52216667
-2 43.9 28.663
-11.5 52.4 28.9044
-66 81.9 29.9058
-41.7 84.4 16.368
-49.5 18.9 30.343
13.5 73.9 54.7356
10.5 71.4 57.2846
-39.925 47.9 42.4405
-68 67.4 41.8058
-60 14.9 29.21
58.3 8.4 48.944
-69 49.4 43.6862
57.5 73.4 48.6368
-61 29.9 41.7564
-44.15 48.9 37.598
12.5 71.4 56.8312
-31.5 45.9 31.3622
-30.525 59.9 28.51425
-61 9.9 20.4814
-38.8 84.4 24.788
-63 20.4 35.2192
-63.5 51.9 46.1316
-65 69.4 40.7462
-50 82.9 24.8546
-69 38.4 43.3776
-67 70.9 39.8594
-46.6 74.4 32.779
66.05 60.9 54.737
-3 51.4 29.0902
7.3 41.9 27.704
-56.5 15.4 29.895
-9 57.4 27.6146
0.8 65.9 45.172
-67.5 36.4 43.4254
30.5 46.4 55.1382
-64 84.9 25.5694
-63 12.4 25.416
-41.2 48.4 40.997
64.85 21.4 70.013
-56.5 32.4 42.9516
-57 65.4 42.804
-51.5 83.9 23.2356
-9 60.4 26.2528
-62.5 84.4 26.389
-68.575 84.9 25.514
-5 46.4 29.0588
-35.05 83.9 36.864
-5 56.4 27.987
-39.8 33.4 30.441
-47.5 66.4 32.6072
-43.5 25.4 28.0904
-46.5 55.4 36.969
-59.5 80.4 31.7876
27.5 71.4 49.624
63.425 58.4 56.526625
62.5 61.9 44.3692
-44.5 58.4 36.2134
69.5 16.4 71.7458
55.5 54.9 57.2654
-20.2 59.4 27.623
62.5 64.9 52.3636
65.5 70.9 52.6134
-64 31.4 42.448
-33.65 84.4 39.7905
-33.45 83.9 41.411
-69.5 57.4 43.7796
-24.8 43.4 31.11
-52.5 74.9 34.406
55 71.4 49.6466
-54 36.4 42.2366
-56 42.9 45.8202
28 71.4 49.6688
-26.5 59.9 29.2898
-64.5 30.4 42.008
30.5 71.4 49.721
31.5 71.4 49.6218
-46 74.9 24.9812
32 46.4 54.7368
-52 61.4 42.1058
-45.375 79.4 22.336
-54.075 13.9 24.488625
66.35 9.4 68.856
-59 43.4 45.9052
-48.5 28.4 30.7154
-43.55 79.4 26.8305
34.5 71.4 49.3758
-39.45 78.4 37.811
-14.5 52.9 28.2388
35.5 71.4 49.2436
-42.7 56.4 38.002
-37.95 83.4 29.8645
66.65 59.9 55.65
-67.5 12.4 25.4008
-37 55.9 28.2868
53.575 71.4 45.453375
56.85 5.9 49.9365
-7.5 44.9 28.8262
-43.9 48.9 37.22033333
52.45 57.4 53.801
1.9 66.4 48.827
60.5 33.9 60.692
70.5 73.9 52.2528
33.7 42.4 48.841
63.35 31.9 62.048
-69.575 43.4 43.52275
15.5 46.4 59.2922
-52 61.9 41.9116
-61 64.4 43.1986
62.5 71.4 52.0004
43.85 41.4 53.921
50.1 50.4 66.65933333
-36.5 38.4 26.9294
1.5 44.9 28.4254
1.7 50.4 40.322
-39.55 78.9 37.1755
-65.5 71.9 39.2278
-61 70.4 40.1846
-52 75.9 33.489
-36.9 67.4 20.73366667
-43.5 77.9 19.494
-54 27.4 37.9664
-39 45.9 33.152
18.5 46.4 57.859
67 71.4 52.3826
5.5 57.9 61.713
-27.5 46.9 31.5914
68 71.4 52.6414
-38.5 45.4 32.8624
-14 55.9 27.2778
68.5 71.4 52.6376
-69.5 65.4 42.7376
69 71.4 52.8654
-69.8 9.4 18.7
-47 22.9 31.632
-63.5 87.4 20.7012
-56.5 79.4 32.8608
71.35 71.4 53.262
-8 48.9 29.0586
-37.65 74.9 45.2245
62.6 5.4 62.22133333
5.9 38.4 32.617
53.575 71.9 45.4785
-60.425 58.9 45.024
-45 59.9 35.6692
-41.15 24.4 26.172
55.1 5.9 47.80066667
-67.5 71.9 39.2272
-50 60.4 42.3766
13 35.9 36.2188
66 36.4 65.1598
50.5 69.9 47.8008
-65 82.4 29.274
-55 36.9 42.3634
-66.5 71.9 39.2194
-63.5 76.4 35.7656
-57.5 34.4 43.7596
-38.525 74.4 45.03141667
-53.5 63.4 41.3842
-46 49.9 37.5902
7.6 64.4 59.009
-39.9 45.4 40.304
-60 77.4 34.852
-60 71.9 39.2418
-23 53.4 31.2332
64.65 6.4 66.238
-69.5 37.4 43.3534
-56.5 71.9 39.2428
-16 42.4 28.3234
67.15 72.9 51.1125
14.475 70.9 55.49816667
-55.7 71.9 38.528
-64.5 28.4 40.9732
-68.5 60.4 43.8142
49.45 45.4 56.698
-9.5 46.4 29.0524
-60 65.4 42.782
-59.5 31.9 42.7262
3.1 57.4 61.19366667
-46.5 71.9 28.2692
43 49.9 53.1744
66.95 11.9 69.2625
38.575 51.4 52.9805
-41.15 46.9 40.9515
-5 49.4 29.1296
58.55 5.9 51.80875
-42 32.9 33.589
8.1 48.4 61.11233333
-51.5 57.4 43.2216
65.5 41.4 63.162
-39 71.9 45.5568
12.5 71.9 56.7196
15.5 71.9 55.347
51.85 39.4 57.7895
53.2 69.9 49.903
69.5 13.4 71.1984
-57 41.4 45.6242
8.05 52.9 60.78725
-11 42.4 28.2358
18.8 33.9 35.194
19 71.9 53.2464
66.9 72.9 50.884
71.3 53.4 71.879
-66.5 26.9 40.0694
69.6 25.4 76.162
-61.5 85.9 23.8766
64.3 26.4 66.175
63.5 46.4 60.329
-55.925 66.9 42.024
30.5 73.4 50.1618
23.975 71.9 50.99866667
24 45.9 56.67
-55.7 83.9 23.698
52.9 47.4 57.502
-44.15 79.4 25.3495
-50.5 50.4 44.0966
-42.5 43.4 36.299
21.525 27.9 33.98558333
12.85 44.4 58.9465
56.5 68.9 52.4626
-49.65 38.9 42.6245
27.025 71.9 50.52
-60.5 79.9 32.3394
27.5 71.9 50.4816
28 71.9 50.4264
37.05 17.4 36.351
-61 84.9 25.6266
29 71.9 50.2458
64.8 63.4 49.839
-67 81.4 30.5398
-48.5 65.9 32.904
-42.85 79.9 27.7925
25.575 73.4 51.535125
16.575 44.4 59.03175
-69.5 78.9 33.2782
-39 71.4 45.6012
-52.5 33.9 41.2528
-43.4 49.4 36.874
-52 74.9 34.3884
-58.5 83.4 27.932
70.5 49.9 69.864
30 71.9 50.113
64.95 47.4 58.2945
26 47.4 55.1384
32 71.9 49.8194
31.5 74.4 48.8906
-15.7 46.9 26.687
-49.35 52.4 37.4605
-69 39.4 43.4048
-41.15 27.4 29.1635
-36.95 83.9 31.2915
-46.6 49.4 43.42433333
-55 71.4 37.096
9 47.9 61.0898
20 45.4 57.9346
-37.65 33.9 27.2165
40.425 17.4 38.54025
56.5 36.4 52.6074
0.15 66.4 41.8035
-32 55.9 30.6928
7.5 68.9 58.7822
6.575 50.4 61.474
-60.5 13.4 27.0074
25 74.9 51.1946
7.8 53.4 60.815
22.5 74.9 52.1118
-18.8 47.4 28.499
65.85 59.9 54.868
53.1 50.4 54.333
49.2 50.4 61.675
37 45.4 49.7706
-9 49.9 29.0694
50.2 71.9 42.638
-57 48.9 46.2544
58.5 36.4 52.9
5.65 50.9 61.1775
57 66.9 52.6218
51 71.9 45.5576
-44 76.4 22.8944
70.075 75.9 50.974625
53.8 71.9 45.542
55.05 71.9 48.4545
2.8 58.4 60.9
-39.85 48.4 42.623
-63 48.4 46.23
-16.7 57.4 22.258
29.475 23.4 35.01858333
3.3 55.4 60.986
72.6 52.9 87.315
59.5 71.9 49.8478
65.3 47.4 58.96
-52.5 28.9 38.8572
46 53.4 53.4288
46.5 12.4 41.738
-62 82.4 29.318
20.925 74.4 52.976125
64.53333333 71.9 47.213
14.5 71.9 55.841
62 52.9 57.8346
58.5 76.4 55.7848
-62.5 55.4 45.7374
-62 36.4 44.392
64.8 71.9 47.733
-66.45 39.4 44.7955
-11.5 43.9 28.6664
-47 77.9 20.4772
-66.5 24.9 38.7866
64.95 71.9 48.1765
-54 72.4 36.3696
66.1 71.9 50.41383333
-36.8 75.4 44.511
-37.65 78.4 42.368
-39.6 56.9 42.48333333
62.95 64.4 51.61525
3.4 56.4 61.378
-52.5 52.4 43.956
-41.55 51.9 29.9245
-50.5 74.9 34.4004
-21.5 47.9 31.615
-23 46.9 31.5678
-64.5 75.4 36.5854
-12.5 56.9 26.81
-56 51.9 46.1102
63.65 7.9 64.259
-40.65 83.4 21.8625
-56 55.4 45.6578
-21.15 59.9 29.1835
-43 25.9 28.096
50.1 49.9 64.286
-4.025 59.4 26.51008333
-67.5 31.4 42.435
-45.3 78.9 22.701
-43.5 36.4 35.244
54.5 69.4 51.688
-64 15.9 30.4658
-48.475 21.9 32.02575
52.4 45.9 55.434
61.35 8.4 55.3575
64.85 59.4 53.227
-59 72.4 38.9228
-49.4 69.4 30.62133333
-35.15 56.4 27.8385
-14.5 57.9 18.1724
-17.05 58.9 23.736
-64.5 47.4 46.2168
63.9 9.4 62.733
-45 53.4 37.3056
-59.5 68.4 41.3252
-49.5 59.4 41.998
12.2 70.9 56.96
-35.7 62.9 24.616
26.65 24.4 33.6305
-50.05 85.9 18.811
-46.5 57.9 36.3518
-49.5 58.9 41.998
-45.5 58.9 35.9982
-47.2 79.4 17.874
-22.075 59.4 29.521875
-54 54.9 43.7138
6.5 67.9 59.2324
-54.8 82.9 24.906
44 52.9 53.2318
-52.45 85.4 20.11825
25 72.9 51.655
32 25.4 34.3856
-43.5 40.9 36.6644
-66 21.4 36.099
45.6 68.4 48.38666667
-16.1 58.9 21.604
-53 72.4 36.3792
-4.65 48.9 29.1245
45.45 45.4 45.359
-23.5 60.9 28.779
-40.05 58.4 43.235
59.5 64.4 51.3236
-43.2 78.4 28.326
-4.9 61.4 27.862
-40.35 71.9 45.215
-48.5 59.9 35.7508
25.5 46.4 56.1314
15.425 34.4 36.4945
37.5 41.9 48.729
-62 32.9 43.132
-62.5 64.4 43.2104
58.35 74.9 52.932
-60 60.9 44.4692
-50.5 72.4 36.3802
61.45 4.9 58.333
12.5 34.4 37.2444
60 54.9 58.1448
-63 44.9 46.0784
56.5 67.4 52.6436
62.025 30.9 61.46716667
-38.5 58.9 31.8814
-69.5 28.4 40.9644
-15.9 58.9 21.413
60.075 56.4 57.99375
-56.5 50.9 46.1998
-49.65 72.4 35.9635
66.8 9.4 69.872
-48.525 72.4 31.54041667
-49.5 64.4 40.168
-63.5 73.4 38.1698
-70 76.4 35.6096
-44 77.9 20.3998
52.15 8.4 45.6165
-54 32.9 40.8724
-54.5 64.4 40.9476
-22 44.4 31.2358
-54 17.4 28.4642
-42.55 83.9 14.845
52.9 45.9 55.855
39.975 48.4 52.95866667
-26.5 52.4 31.3958
-68.5 19.4 34.2784
-34.15 84.4 38.332
-36 53.9 28.613
53.8 71.4 45.542
-47.5 40.4 36.5358
-17 43.4 28.4524
64.55 59.4 52.354
-1.5 60.4 26.2402
35.35 44.9 57.8745
-56 77.9 34.277
66.8 11.9 68.916
-38.15 72.4 45.3345
-55.7 46.4 45.881
-36 40.9 27.8288
55 56.4 56.8416
70.9 76.4 50.726
-46.5 77.4 30.474
-37.5 42.9 30.531
-47.5 70.9 29.2608
-49.4 80.9 16.147
38.8 45.4 50.97
-63.5 72.9 38.54
-50.5 13.4 22.1298
-41.9 48.4 40.15233333
-55.7 82.9 25.357
-38.35 79.9 39.324
51.5 50.4 66.7094
-64 14.4 28.452
-52.5 22.4 33.8484
35.5 72.4 49.1122
70.3 14.4 71.102
-43.3 84.4 11.689
-51.5 29.4 39.0878
-14 41.9 27.6456
-37.525 52.4 29.00683333
-18.5 53.4 28.6874
-57.5 72.4 38.9374
37.85 46.9 50.1505
-64 29.9 41.7246
61.525 57.9 57.502
14.95 72.4 55.6335
-36.5 62.4 25.163
-47 76.9 32.2834
67.35 62.9 54.948
-49 71.9 28.7922
-13.45 57.4 21.7685
66.1 64.4 54.134
-18 56.9 26.8004
-69.5 20.4 35.206
67.075 71.9 51.45225
15.2 72.4 55.513
-42.5 44.4 36.2926
70.2 24.4 83.979
11 71.9 56.888
58.5 73.9 51.4046
65.2 9.9 66.655
49.8 8.9 43.497
9 71.4 56.8714
-36.2 73.9 43.351
-46 53.9 37.2276
29 73.9 50.426
-57 28.4 41.0326
71.3 52.4 70.704
53.15 66.9 50.164
-64 28.4 40.9842
17.425 72.4 54.48825
0.5 48.9 28.6334
-19.4 59.9 26.42466667
-62 60.9 44.4418
27.5 72.4 50.8008
-56 26.4 39.7242
-55.45 77.4 32.06
-51.5 74.9 34.3778
-70 11.9 24.4812
41 15.9 38.902
-50 77.9 31.4574
-50.5 16.4 27.3608
30.1 22.4 34.655
-68.5 50.4 43.6984
3.9 49.9 50.676
-42.5 26.4 28.97
28 72.4 50.7142
68.5 71.9 52.4204
-35.5 51.4 28.711
48 52.9 53.7658
-52 80.9 27.95
-54 25.4 36.616
54 53.4 56.6246
-54.5 46.9 44.1094
63.2 4.4 62.619
-68 80.4 31.6748
-68.5 52.4 43.7206
70.4 54.4 61.648
39.5 72.4 47.6932
-42.5 41.9 36.241
-66.5 21.4 36.0884
-49.65 23.4 34.676
63.35 6.4 63.854
-62.5 51.4 46.1502
-59 38.4 44.9744
72 42.9 63.7358
11 31.9 37.3312
-35 79.4 43.2456
41.8 71.9 47.436
-28 59.9 29.2738
53.425 72.4 47.0385
-35.45 48.4 28.7635
-55.7 24.4 36.883
-65.5 75.4 36.5796
52.95 52.9 54.6455
63.15 72.4 50.116
59.5 57.9 56.9394
-20.8 46.4 26.817
21 74.9 52.5808
71.1 24.9 93.405
-62.5 70.4 40.177
64.8 72.4 47.491
-19 61.4 25.7542
-43.8 51.9 36.937
65.7 72.4 49.609
-38.15 46.9 32.689
70.3 49.4 72.775
66.05 73.4 49.7385
61.5 69.4 53.3522
65.65 52.4 57.686
-56.05 55.9 45.64875
69.5 42.9 63.7688
32.5 47.4 54.7444
66.3 72.4 50.448
-41.4 45.9 40.75266667
-68 79.4 32.7396
55.025 58.4 55.00216667
68.075 72.4 51.972
-46.5 40.9 36.654
-20.8 53.4 26.085
-47.5 56.9 36.6118
-53 70.4 37.7596
-46.5 32.4 33.3282
32 24.9 34.9358
70 72.4 52.8044
-5.5 54.4 28.5386
67.65 73.9 51.1245
40.55 15.9 38.636
-65 71.4 39.5326
-39.1 62.9 32.77
-14 61.4 24.449
-58.475 87.9 19.52975
64.85 21.9 70.013
50.5 12.4 42.3228
-52.5 59.9 42.5692
42 42.9 53.6934
-14.85 48.9 24.1345
-7.425 59.9 26.49625
-66.075 49.9 46.03575
71.1 55.9 72.063
0.2 49.9 31.957
-68.5 23.4 37.7364
-7 57.9 27.4238
-50.5 10.9 16.7368
-55 57.9 43.0984
-50.05 79.9 29.2
-65.5 41.9 45.6754
9 34.9 36.476
-35.5 80.9 43.1558
-46.8 79.9 17.662
-50.5 18.4 29.9406
-58.5 59.4 44.878
64.55 23.9 68.672
-64 79.9 32.301
-63.5 75.4 36.6148
-61.5 26.9 40.1228
60 67.9 53.2914
51.525 57.4 54.498
-64 50.9 46.1682
-12.5 43.4 28.3582
-59 75.4 36.6356
-50.5 55.4 43.5648
48.8 44.4 56.774
58 76.4 56.2982
-70 72.9 38.483
-67.5 72.9 38.5068
-53.5 56.4 43.4122
68.3 75.4 50.958
57.525 44.4 62.02458333
-40.3 51.9 32.203
15 73.9 54.8642
5.85 59.4 61.137
-69.575 84.9 25.52025
-67 54.4 44.099
-4.475 60.4 26.01425
-67 71.9 39.2282
-55 50.9 44.0858
19.475 74.9 53.02458333
-66.525 72.9 38.51291667
6.425 67.4 59.536875
-66 72.9 38.5242
52.05 49.4 63.775
60.375 4.9 54.0065
-19.95 55.4 26.7925
-61 19.9 34.7918
-65.525 72.9 38.51316667
69 14.4 70.7822
56.05 75.4 56.1665
-60.5 69.4 40.785
-34.55 56.9 29.363
-69.5 16.4 31.0898
66.9 75.9 48.79333333
-60.5 72.9 38.5312
-52.5 46.4 44.0614
25 43.9 55.8772
69.5 9.9 72.3992
50.9 52.9 53.84766667
-46.5 52.9 37.337
65 58.9 55.897
-59 72.9 38.5604
-40.6 60.4 45.604
50.5 39.4 59.1462
-50.5 78.4 30.9542
49.1 50.9 61.863
-56.5 72.9 38.5826
-11.05 46.9 28.2285
-53.5 33.4 41.0406
-54 64.9 40.7166
-41.075 50.9 34.410625
24.425 72.4 51.509625
-64.5 70.9 39.8598
-66.025 51.4 46.01975
-27.5 52.4 31.4038
-18.9 58.9 25.93966667
-61 80.9 31.2134
-43.4 22.4 24.87933333
-12 42.4 28.183
-58.975 28.4 41.017
54.85 8.4 47.483
-40.525 61.9 46.0225
53.9 71.4 47.266
6.7 62.4 59.887
44.5 42.9 55.2312
45.55 41.9 55.6725
50.075 10.4 43.99
-51 72.9 35.9664
50 69.4 48.4576
26.5 74.9 50.6186
-65.5 74.4 37.404
-39.5 68.9 45.9396
66 30.4 68.01516667
-46.35 79.9 18.815
-67.5 80.4 31.678
70.1 24.4 83.131
-45 27.4 29.9114
-24 50.9 31.5326
15.5 72.9 55.365
-45.925 63.9 34.008375
-50.5 41.4 43.4484
62.5 65.9 53.2104
23.925 72.9 51.97225
-53.5 71.4 37.1232
-38.5 66.4 44.6874
4 63.4 60.7194
-18.5 48.9 28.1242
-69 71.9 39.1946
-69 22.9 37.3224
-67 22.9 37.337
57.05 73.4 48.60875
46.55 11.4 41.65575
-61 72.9 38.5218
-63.5 66.9 42.1014
-59.5 81.4 30.6218
-57 26.9 40.1764
-61 31.9 42.7174
28.5 72.9 50.7298
69.7 49.9 62.975
64.5 56.9 58.1866
-55.1 24.9 36.21133333
51.35 39.4 59.2215
-64.025 58.9 45.01908333
-67.5 76.9 35.2546
53.6 6.9 46.68233333
62.9 46.9 60.154
52.6 72.4 46.95566667
-33.475 59.9 27.01175
56.5 39.4 61.2056
59.5 71.4 51.1732
64.95 72.9 47.745
52.45 44.9 55.75125
62.65 45.4 61.94
66.2 72.9 50.124
-21.05 53.9 31.00375
-49.975 76.4 32.99208333
57 58.9 55.7252
65.5 23.9 70.269
-59.5 50.9 46.1896
-51.5 52.9 43.9228
-33.5 51.9 31.4718
68 72.9 51.7346
66.7 13.9 68.111
50 66.9 48.6002
-67.5 69.4 40.7334
60.5 59.4 55.8872
70.5 72.9 52.641
-34.7 49.4 30.317
45.8 53.9 53.013
11 31.4 37.1466
26 71.4 50.672
65.6 49.4 58.65333333
-43 39.4 36.2726
46.5 14.4 40.8118
-21.1 45.9 30.462
-45.15 78.4 23.356
-8.5 49.9 29.0976
-39.65 33.4 30.1445
-54.975 85.4 19.00008333
-38.3 68.4 44.903
-62.575 21.9 36.525
-55.7 41.4 45.236
-53 53.9 43.82
-55.8 20.4 34.974
3.95 36.9 34.7525
-3.5 51.4 29.07
-10 60.4 26.0742
-30.5 52.4 31.3846
-14.2 58.4 20.915
44 12.4 39.41
-61 48.9 46.244
-48.475 57.4 36.51583333
-59.5 52.9 46.0586
-55.6 15.9 28.304
-57 57.4 45.3828
-58 86.9 21.8528
-43.7 76.4 22.231
-55 78.4 30.9744
-39.85 60.9 31.162
-10.925 51.4 29.00025
-54.5 43.4 43.7584
6.4 60.4 59.761
-70 82.9 28.4762
57 72.4 48.5924
58.6 7.4 50.60266667
-6 52.9 28.8444
-6 58.9 27.04
49.7 41.9 60.034
-5.15 61.4 26.8045
-56.05 73.4 38.155
52.7 8.4 46.099
-50.5 67.9 39.283
-55.7 73.4 37.674
-68 58.4 43.8122
-53 79.9 29.2242
56.85 7.9 48.971
-59.5 44.4 46.0392
21 72.4 52.7454
-66.45 50.4 45.7505
-69 35.4 43.2982
-53.5 73.4 35.6208
-39.975 47.4 42.442
-56 67.4 41.8074
69.05 76.4 50.13975
-56.5 10.4 21.7808
71.4 55.4 75.664
-55.7 55.9 45.365
-52.5 73.4 35.5854
20.5 45.4 57.7696
-50 42.9 43.6804
-54 11.9 20.6966
70.3 54.9 60.199
-40.575 43.4 34.455625
-40.85 78.4 34.2445
5 58.9 61.9026
65.35 73.9 48.3375
-48 73.4 32.6826
-53 79.4 29.8276
68 17.9 73.115
-55.5 82.9 24.90366667
-41.4 29.9 31.31033333
11.5 72.4 56.4166
69 18.4 73.2138
-53.575 20.4 32.021375
-68.5 83.4 27.78
39.1 41.9 48.22866667
52.525 40.9 59.01108333
-38.2 73.4 45.389
63.6 31.9 58.971
-30 52.9 31.2748
-25.5 54.4 31.0476
-49.5 35.9 41.013
-59 37.9 44.8414
-62.5 18.4 33.3062
63.4 5.9 63.804
-36.45 83.4 34.268
64.6 30.9 62.501
-68.5 16.9 31.6782
-19 43.9 29.7482
39.65 41.9 49.177
-66.075 49.4 46.0385
13.5 73.4 55.4528
-41.3 24.4 26.4
60.525 45.4 62.02308333
52.5 64.9 50.1472
67.4 46.9 61.18633333
-44.525 54.9 37.00391667
16.025 73.4 55.03358333
-15.3 48.9 23.606
29.8 26.9 33.859
-56.5 37.9 44.8472
-30.5 53.4 31.218
-6 56.4 27.9998
2.1 50.9 42.867
-11.5 55.9 28.127
-49.35 50.4 42.6575
-45.5 31.4 32.7474
31 22.4 35.3368
17.475 73.4 54.51733333
-37.6 64.4 29.266
-46.35 48.9 37.6015
46.5 13.9 41.236
-20.85 44.4 30.9675
65.55 49.9 58.2125
-26.5 53.4 31.2292
44.45 45.4 42.722
-51.85 85.4 18.9595
-66.4 42.9 45.43866667
-43.05 79.9 27.2775
39.15 15.9 37.2505
18 73.4 54.3028
23.5 73.9 52.239
-50 84.4 22.1996
54 40.4 61.4246
-14.1 48.4 22.271
18.7 73.4 54.015
-0.7 65.9 38.945
-37.4 51.9 28.96433333
28.5 25.4 35.0676
-13.5 42.9 28.2806
23 73.4 52.3878
-25 53.9 31.1416
-60 73.4 38.1982
27.425 73.4 51.006875
-57 46.9 46.2386
-70 70.4 40.1616
62.9 52.4 57.29133333
67.85 12.9 70.376
-52 59.9 42.5704
-20.15 48.4 26.6235
33.925 73.4 48.9885
-68 48.9 43.6774
-21.15 48.4 31.5805
-52 13.4 22.822
-43.5 29.9 31.7562
-13.9 57.4 19.863
61.85 60.9 45.366
-58.5 86.4 22.8896
61 64.9 52.1824
58.15 8.9 49.852
16 72.4 55.1706
-56.5 41.9 45.7172
-60 85.4 24.7944
50.5 40.9 60.133
-8.5 61.4 25.3594
71 49.4 77.717
-69 82.9 28.4846
-47 75.9 32.8664
58 55.9 57.6664
3 37.9 32.9548
-17 45.4 28.1728
-60.5 83.4 27.9068
-41.2 47.4 40.997
-0.5 45.4 28.9726
-37.4 31.4 24.82033333
6.6 42.9 28.55533333
19.8 30.4 35.783
47.5 54.4 53.1708
-32.025 44.9 30.01508333
-61.5 79.9 32.3318
30 44.9 54.6344
-52 79.9 29.224
2.5 44.4 28.2798
48.95 70.4 44.365
-16 42.9 28.4592
10.5 70.9 57.5922
37.35 45.9 49.9615
-60.5 30.9 42.2568
7.5 44.4 28.1866
50.425 45.4 58.529875
-36 49.9 29.0996
-44 74.4 25.609
-61.5 65.4 42.7532
60.35 8.9 54.792
20.5 74.9 52.727
9.85 45.9 59.4235
52.2 38.4 56.517
-46 40.9 36.678
22.5 29.9 34.2542
65.85 74.9 48.8615
58.5 65.4 52.2242
-55 69.4 38.411
11.5 47.9 60.635
-68 73.9 37.7536
-46.5 44.4 37.3318
-61.5 27.9 40.685
-12.3 57.4 26.616
-37.8 43.4 31.98
38.35 42.4 49.1985
50.075 11.4 43.509625
-48.5 70.4 29.6378
-34.5 81.4 42.767
-51 82.4 25.7902
-46.5 35.4 34.8578
65 53.9 58.2444
-51 43.9 43.8286
29.5 47.9 54.7134
-49.6 43.4 43.31666667
-40.15 58.9 43.3895
-24 45.4 31.43
-62.5 73.9 37.7962
-39.55 79.9 36.234
40.5 51.4 53.4506
-66.5 69.4 40.7354
-49.925 80.4 28.4715
-56.5 46.4 46.2088
-7 58.4 27.2516
11.425 48.4 60.45175
12.5 72.4 56.4234
-25.5 55.4 30.8322
66.4 11.4 67.27
-55.8 73.9 37.499
6 54.4 61.9376
-66 57.4 45.258
68.2 70.9 53.013
-44.5 36.4 35.2406
-35.35 52.4 28.4165
4.575 35.9 36.449125
-67.5 13.4 26.9586
-55.7 73.9 37.14
-25 52.4 31.4096
-44.35 78.4 25.4005
-16.075 60.4 24.479625
56.7 75.4 56.009
-40.1 72.9 45.25533333
-52.5 73.9 35.2268
64.65 21.9 69.811
-38.3 78.4 40.727
37.7 46.4 49.949
-49.5 73.9 33.373
59.1 72.4 46.928
-56.5 18.4 33.3784
-3 53.4 28.7134
-31.5 48.9 31.6246
-49.35 73.9 32.8615
1.65 66.4 47.8245
41.7 41.4 51.14
30 44.4 54.6346
65.4 46.9 59.30366667
13 33.4 37.345
-60 82.9 28.659
-39.3 73.9 44.57
48.9 50.9 60.749
-59 36.9 44.5364
64.65 64.4 52.7585
66.05 32.9 62.26
-49 43.4 37.0848
35.4 44.4 56.40333333
63.4 35.4 56.014
-56.025 75.4 36.48125
-37.8 73.9 44.928
-39.35 61.4 31.337
-37.5 77.4 44.2636
-52.5 53.9 43.8226
19.925 73.4 53.49025
-37 73.9 44.2404
-36 36.4 25.8282
-45 51.9 37.4848
-59.5 71.4 39.5848
-46.425 51.4 37.505375
-58 84.4 26.4352
-46.65 77.4 31.241
-37.65 42.9 31.265
15.5 73.9 54.8164
22.5 30.4 33.8826
-64 69.9 40.458
-46 45.4 37.406
39.9 19.9 36.67366667
16.5 73.9 54.6218
-46.5 66.4 32.5952
-64.5 23.4 37.7412
-44.05 73.9 26.1515
-21.1 49.4 31.57666667
19.5 73.9 53.6536
33.5 48.9 54.1424
-62.5 41.9 45.698
20.5 44.9 57.8002
53.4 66.9 50.07166667
-58.5 62.4 44.0032
64.95 64.4 52.9045
-36.95 78.4 43.976
-43.7 77.4 21.361
-68 71.9 39.2124
60.5 41.4 62.9354
22.65 73.9 52.496
-10.55 59.9 25.726
-60 52.9 46.0438
-49.025 62.9 34.51916667
69 75.4 51.3208
-2.525 60.9 25.93641667
24.075 73.9 52.0335
-60 72.9 38.51791667
7.4 52.4 61.62933333
25.575 73.9 51.535125
-62.5 68.9 41.0622
57.425 40.4 62.0305
-60.5 20.4 35.2576
48.9 66.4 47.4
24.075 27.4 34.4985
28.7 73.9 50.518
11.575 45.4 59.98525
52 69.4 50.7484
47 53.4 53.6608
-20.5 55.4 25.6662
-45 70.4 29.6386
-55.6 72.4 36.674
64.35 59.4 51.778
64.7 31.4 57.843
53.7 58.4 53.962
32 73.9 49.306
-34.35 49.4 31.3025
-21.2 45.9 31.493
-59.5 40.4 45.4352
64.2 50.9 54.446
7 32.9 36.1918
54.55 73.9 53.10775
-48.5 24.9 27.6244
55.2 73.9 53.017
-15.5 56.9 20.418
-52 78.4 30.9652
-21.5 56.4 30.5836
56.5 42.4 62.3836
-12.95 57.9 23.219
-59 34.9 43.8952
57 73.9 50.1534
63.5 65.9 52.7016
32 72.4 49.846
6.8 41.9 27.381
15.45 71.4 55.16825
57.525 73.9 50.01275
69.8 8.4 72.404
58.05 73.9 51.42275
58.8 8.9 51.309
61.8 61.9 43.526
-49.35 79.9 16.3285
-33.5 55.9 30.7144
-37.3 45.9 29.633
-13 60.4 24.4398
-51 27.4 37.9682
-8 43.9 28.6808
63.025 42.9 63.01691667
-19.575 58.4 27.37075
17.475 71.4 53.45225
-17.5 50.4 28.086
7.15 54.9 60.8465
47.5 50.9 52.957
25 29.4 33.6982
64.15 1.9 60.217
-49.7 74.9 34.108
-62 16.9 31.6878
-63 80.4 31.7486
67.4 73.9 50.92766667
29.5 44.4 54.6718
46.5 43.9 55.7666
68.3 73.9 51.51
2.925 48.4 28.015875
-20.8 54.4 25.882
-51.5 73.4 35.705
69.3 73.9 51.998
-20.9 57.4 24.90433333
71 73.9 52.301
-43 41.9 36.7008
-57 47.4 46.2464
-70 77.9 34.2178
1.1 65.9 46.423
56 59.4 54.2712
70.4 25.4 85.095
67 18.9 72.6232
6 63.9 60.7114
65.8 7.9 68.011
-34.6 83.4 39.77
-37.55 59.9 30.7945
-65 79.4 32.8086
50.5 68.9 48.8096
-48.5 47.9 43.9334
-37.35 70.4 44.018
-23.5 57.4 30.244
66.35 66.9 53.7835
-70 10.4 21.5458
-50 72.4 36.3526
65.1 59.4 53.816
-6 48.4 29.0782
64.5 25.9 67.612
-69.5 81.4 30.4986
-52 70.4 37.7436
54 44.4 61.158
9.6 50.9 60.40366667
-51 61.9 41.9258
-62.5 36.9 44.5404
42 51.4 53.6958
-51.5 67.9 39.2546
66.55 74.9 49.756
68.3 3.9 67.39
-59 38.9 45.1054
64.5 53.9 58.1656
-61.5 74.4 37.4248
-11.2 57.4 26.965
32.575 20.9 35.426875
-63.5 78.9 33.3676
-19.15 58.9 26.648
51.65 44.4 58.212
18.5 44.4 58.4312
60 45.4 61.8992
-6 55.4 28.257
2.15 65.9 50.772
64 52.4 56.7892
-5.5 42.9 28.3072
32 21.9 35.6444
58.525 46.4 60.0085
3.6 57.4 61.649
-45 76.4 22.859
-53.5 74.4 34.8264
16.975 71.4 53.9455
-38.2 78.4 40.98
-52 12.9 21.7442
-38.5 44.4 32.7296
37.425 72.9 47.9705
51.625 68.4 50.949
70 13.4 71.3406
-56.1 85.9 23.64
71.8 55.9 81.429
-39.8 58.9 44.107
-59.5 27.4 40.4178
-35.15 78.9 43.3605
-49.45 20.9 31.90475
72.1 54.9 84.448
-48 19.4 31.2526
-16.45 58.4 21.109
-67 13.4 26.9614
52.7 53.4 55.279
18 71.9 53.8626
-50.5 74.4 34.8292
20.2 31.9 36.005
28 47.4 55.1754
-46.3 74.4 25.582
-23.5 48.4 31.6304
-53 80.4 28.595
-1.5 51.4 29.078
59.5 41.9 62.8722
-51 69.4 38.3736
-49 54.9 37.0886
4.5 64.9 60.227
-46 41.4 36.7766
57.5 57.4 56.859
-33.5 57.9 30.097
-33.8 47.9 31.617
53.7 69.9 51.123
-49.7 78.9 30.117
4.5 63.4 60.9514
64.65 59.9 52.418
71.5 54.9 76.409
22.025 71.9 51.53358333
-52.5 81.9 26.5564
-28.5 46.9 31.5682
-48 58.4 36.1972
-56.5 46.9 46.238
21.525 72.4 52.5225
-19.8 47.4 28.18
14.7 74.4 54.114
1.6 49.9 39.064
-31 52.4 31.3962
-61.5 63.4 43.6006
-25.5 56.4 30.5438
-64.5 19.9 34.7594
15 74.4 54.142
40.5 69.4 48.5864
16 74.4 54.2214
2 47.9 28.3406
-46.2 79.4 20.323
17.5 74.4 53.9768
-53 66.4 40.0472
-50.5 44.9 43.9462
-20.025 60.9 26.933
-62 87.9 19.5464
25.525 74.9 51.01925
18.6 30.9 35.732
-60.5 36.4 44.4142
-20.8 47.4 27.088
64.8 7.9 66.887
47.15 50.4 55.213
-50 68.9 38.6508
54 52.4 56.0838
21.5 74.4 52.7706
-60 57.9 45.2364
25 71.9 50.7912
71.1 25.4 92.923
-67 70.4 40.132
-34.95 47.9 29.318
-49.975 46.4 44.0065
-69 87.9 19.3344
27.975 74.4 50.49866667
-5 42.9 28.2928
30.425 74.4 49.468
-56.1 21.4 36.11933333
-48.5 55.9 36.8592
-56.5 38.9 45.1142
-38.15 71.9 45.3625
-49.35 26.9 29.4785
53.8 74.4 53.974
-29 53.4 31.2506
-36.5 42.9 28.4648
63.1 35.4 54.09
62.5 67.9 53.6844
-47 48.9 43.7888
32 48.4 54.4198
55.6 74.4 54.371
-34.8 83.4 39.178
49.2 44.9 56.805
64.85 47.9 57.8845
-29.5 47.4 31.6028
57 74.4 52.9368
-63 51.4 46.1582
-39.95 74.4 43.678
57.5 74.4 52.086
34.5 43.4 53.3608
-45 41.9 36.8718
10.5 72.4 56.1138
-62.5 77.9 34.3468
58 74.4 51.5666
43 52.4 53.3428
-65 19.9 34.7686
-68.5 74.9 36.9718
7.45 53.4 61.2475
-63 56.4 45.5528
-11 53.4 28.7228
47 68.4 48.6924
6 66.4 60.0022
7.5 69.9 57.8122
-40.7 56.9 40.969
66.45 74.4 49.84925
69 74.4 51.696
-5.5 66.4 21.804
-38.7 78.9 39.32
-48.925 75.4 33.01575
5.4 49.9 58.225
-56.5 74.4 37.4638
66.1 74.4 49.39083333
-57.5 69.9 40.4858
64 53.4 57.811
70.025 74.4 52.022
-68 85.9 23.7868
43 69.9 48.6234
29.075 72.9 50.5525
-31 51.9 31.478
-35.3 83.4 37.693
-58.5 11.9 24.5972
71 74.4 52.1104
4.525 61.9 61.46283333
8.4 31.9 35.91833333
51.5 42.4 59.1864
-43.5 34.9 34.5948
-50 37.9 42.599
38.5 18.4 37.829
-3 45.9 29.0074
23.575 27.9 34.51325
58 51.9 56.3572
-63 37.4 44.6892
-28 47.9 31.6466
-70 74.9 36.9434
30.975 73.4 50.0045
-40.8 32.4 31.85
-50 59.9 42.5332
-37 58.9 27.1556
-54 55.4 43.6136
-29 58.4 29.9288
-20.85 50.4 26.549
72.9 51.9 90.837
-68 74.9 36.9638
33.7 73.9 48.484
-67.5 74.9 36.9614
-52 71.4 37.081
-43.05 83.9 13.369
38.975 50.4 53.95941667
-69 83.4 27.7734
-28.5 51.9 31.4708
-55.7 78.4 33.273
63.425 61.9 44.540125
-66 74.9 36.9552
39.5 19.9 36.8474
-60.5 51.4 46.1578
-9.5 53.9 28.6168
-43.5 38.4 35.9304
-49.5 72.4 35.342
-22 52.4 31.4152
-58 76.9 35.3386
-10.5 44.9 28.8456
36.6 41.9 47.63
-56.05 75.9 36.16475
-50.4 21.9 33.72966667
-63 74.9 36.999
-62 74.9 37.0252
-39.85 84.4 21.7315
57 72.9 48.5924
7 35.4 36.6084
-68 83.4 27.7772
-58.5 74.9 37.0528
43.425 14.9 40.03675
50.5 54.9 53.714
-61.5 54.9 45.792
-48.5 68.4 31.226
66.6 50.9 59.17266667
69.5 23.9 83.269
-10.025 51.9 29.0195
53 55.4 54.688
62.9 36.4 53.93966667
62.45 68.9 53.6255
65.4 64.4 53.352
-52 34.4 41.4546
-16.8 48.9 26.371
22.5 44.9 57.1922
-55 74.9 34.3802
-69 61.9 43.8354
-41.6 27.4 29.693
44.9 49.9 53.225
-48 66.9 32.2714
-70 49.4 43.6904
54.05 6.9 47.1895
63.625 7.4 64.39025
-46.65 73.4 32.456
42.5 16.4 39.3066
47.35 50.9 52.371
-49.35 74.9 33.0155
-49 61.9 34.9628
-43.525 21.9 24.95033333
4.8 49.9 55.166
66.45 71.9 50.83625
60.45 4.4 53.7405
-60 16.4 31.1494
-21.2 61.4 28.108
-46.6 74.9 32.863
43.3 42.4 54.459
-41 61.4 45.9486
-60 48.9 46.2576
-57.5 45.9 46.194
-39.4 74.9 44.35366667
-65.5 82.9 28.5548
-46.7 46.4 43.209
-38.425 74.9 44.951125
1.3 50.4 38.163
53.3 56.4 53.944
-44.55 72.4 27.765
-67.15 40.4 43.624
56.025 35.9 52.50583333
55.5 68.4 52.3386
-52.075 14.9 25.48275
-55.7 69.9 39.829
2 44.9 28.321
-49 70.9 29.26
-52 81.9 26.5638
59 64.4 51.2878
-60 32.9 43.125
18.5 74.9 53.2268
-64.5 55.9 45.6856
-63.5 85.9 23.8258
19 74.9 53.1154
44.5 52.9 53.3704
-37.8 33.4 26.505
28.925 74.9 49.46375
-49.4 32.4 33.977
53.8 74.9 53.974
68.5 70.9 52.8928
-64 25.4 39.143
57.5 74.9 54.3042
-16 62.9 24.2068
-70 57.9 43.7956
-18 46.9 28.14
43.5 50.4 53.3872
56.6 71.9 48.88733333
58 52.4 56.8154
48.8 44.9 55.472
-66 17.9 32.7436
60.5 44.9 62.4276
-50.5 84.4 22.2966
-39.1 58.4 44.2
-52 59.4 42.7304
-61 38.4 44.9884
52.3 48.9 62.986
-58.5 53.9 45.9414
63.45 72.4 49.819
-47.5 23.4 31.3542
-41.95 58.4 38.2065
-55.5 39.4 43.1254
51.3 40.9 59.189
33.5 23.9 35.2542
-26.5 46.4 31.549
-40.45 57.9 42.2145
-55 44.9 43.9768
-47 53.9 37.2002
67.05 74.9 50.2555
9.85 67.4 57.433
32.45 73.9 49.11875
52.3 47.9 59.7
65.2 6.4 67.143
70 74.9 51.8248
51.2 73.4 42.639
-54.5 49.4 44.1196
26.5 47.4 55.1732
-39.99166667 66.9 46.01966667
8.5 35.4 36.2694
-49.4 78.4 19.66566667
20.5 73.9 53.2884
-34.7 56.9 28.958
56.475 64.4 51.03466667
58.5 45.9 60.9288
-52.7 85.4 20.011
9 33.9 36.6728
56.8 73.4 49.225
-40.2 83.9 21.731
-37.6 68.9 44.43166667
8.85 44.4 28.4105
5.5 64.4 60.7524
-56.5 75.4 36.6238
-68 54.4 43.7806
72.9 52.4 90.977
-66.5 81.9 29.9154
-42.55 51.9 33.368
-38.05 64.4 30.1775
-39.9 62.4 43.352
-47.075 71.9 28.480375
-44.5 75.4 24.3322
-69 59.4 43.8112
-24 45.9 31.4694
-36.8 76.4 44.511
71.8 49.9 81.04
-54.5 71.9 36.7666
-55.45 57.4 43.26
-45 48.9 37.6096
67.65 16.4 71.1055
-55.7 27.9 39.722
-47.5 72.4 31.554
-37.35 75.4 44.836
-53 68.9 38.652
-38.85 58.4 32.0175
-49.6 75.4 33.491
65 15.4 66.7274
5.075 51.4 60.922375
-37.55 79.9 41.3745
69.2 25.4 71.661
-59 67.4 41.8812
-38.1 61.4 31.19066667
57 57.4 56.7682
-36.9 33.4 24.74266667
20.5 29.9 34.3762
-37.5 65.9 24.912
-54 84.9 20.8002
-52.5 78.9 30.4176
-61.5 61.9 44.1542
-49.3 40.9 36.746
-49.925 71.4 37.035625
-36 46.4 29.046
-47.5 47.4 43.8764
64.1 4.9 64.25633333
-57 84.9 25.5946
60.8 5.4 57.975
-50 20.4 32.199
-49 29.4 31.4436
-21.05 42.4 30.6275
-63.5 74.4 37.412
5.5 52.9 61.878
-49 26.9 29.507
-50 23.9 35.4594
-44.05 78.4 26.1655
-0.5 49.9 29.255
-67 69.4 40.7294
-52.6 12.9 22.111
18.5 43.4 57.6786
55.2 75.4 55.946
-36.7 74.9 43.945
-52.5 67.9 39.2718
-42.05 79.9 29.845
-13.35 48.4 24.2465
-66 63.9 43.4062
-61.5 76.4 35.7836
-69 68.4 41.3122
-19.35 52.9 28.788
40.2 42.4 51.619
-48.5 64.9 33.4932
-42.7 46.4 38.996
-37.5 49.4 31.723
-46.5 76.9 30.474
-37 44.9 28.8518
-64 72.4 38.8868
-65.5 45.4 46.0744
63.35 3.9 62.4015
-37.8 66.4 28.188
-2.5 58.4 27.266
-68 31.4 42.4466
54.5 38.4 59.2968
6.85 55.9 60.7445
64.3 61.9 47.703
-58 56.9 45.454
-34 82.4 42.3992
64.65 65.4 52.7675
18 74.4 53.878
-25.5 48.4 31.601
58.5 57.9 56.6858
66.6 75.4 48.662
-48 70.9 29.215
-49.65 51.4 43.7635
66.5 70.9 52.9012
18.425 72.4 53.9785
-25 54.9 30.9566
-66 53.4 45.8324
-51 65.9 40.2966
50.5 53.9 53.968
-45.5 39.4 36.2888
71 73.4 52.4942
10.4 68.4 57.685
67.1 75.4 49.92566667
-14.425 43.4 28.53
67.45 16.9 71.8055
-70 73.9 37.729
-1.5 50.9 29.102
67.575 75.4 50.441875
20 74.9 52.8794
-55.7 47.9 45.91
-49.2 44.4 42.201
-16.5 50.9 27.459
68 75.4 50.7928
-20.1 53.4 26.17133333
55.5 68.9 52.2456
65.05 50.4 57.304
64.05 72.4 47.8075
-67 31.4 42.4542
-50 53.4 43.8418
-64 79.4 32.8118
-38.95 49.9 32.99825
31 72.4 50.1
68 63.4 55.2876
-39 46.9 33.533
49.2 70.4 43.18
-67.5 75.9 36.1808
-20.35 59.9 27.8645
-50 43.9 43.8026
-59.075 40.9 45.524
-55.85 86.9 21.337
-53.5 30.9 39.9156
-60.5 17.4 32.2614
-64 75.9 36.1734
-52.5 31.4 40.1242
49 12.4 42.5762
41.35 42.4 52.9165
-36 66.9 21.3606
71.9 51.9 78.377
-37.5 53.4 28.7906
-44.3 72.4 27.225
50.95 42.4 60.333
64.55 8.4 66.282
-35.7 83.4 36.501
-58 75.9 36.245
66.55 51.9 58.7315
-64 74.9 36.9994
21.5 45.9 57.2694
-51.5 82.4 25.7864
-60 9.4 19.2658
-56.5 75.9 36.237
-48.5 65.4 33.1788
-55.7 75.9 35.57
-49.35 68.4 31.3175
2.5 47.9 28.1816
-49.65 68.4 38.6035
-55 75.9 33.4866
-57.5 71.4 39.6048
-43.8 20.9 24.503
41.5 44.9 52.7234
-49 40.4 36.571
46.9 49.9 60.338
-58 22.9 37.4004
-42.3 58.4 37.275
7.7 66.9 59.517
10.2 69.9 58.018
63.15 3.4 61.611
-50.5 75.9 33.511
52.05 50.4 66.30275
60.1 4.9 53.706
-22.5 60.4 29.0432
-50.05 83.9 23.1505
-67 73.9 37.7904
-15.5 44.9 28.137
-67.5 13.9 27.6966
-54 43.9 43.8246
70.7 52.4 63.083
-40.85 31.9 31.6465
-39.5 56.4 43.9166
-61.5 36.9 44.5598
-49.7 75.9 33.195
-41.1 31.9 32.23533333
-63 35.4 44.092
-42.15 48.4 39.8505
-49 53.4 37.2788
-56 28.9 41.1794
-49 75.9 32.8758
44 44.4 54.2314
-47.5 67.4 31.9552
-29.00833333 60.4 29.02008333
-57 73.9 37.8464
4.925 55.4 62.020125
-47.5 61.4 35.1456
-62 29.4 41.4742
17.1 28.9 35.75266667
61.5 45.9 61.7458
-37.425 46.9 31.938
59 51.9 56.462
-48 36.9 35.4462
-56.05 13.9 27.697
-55 57.4 43.2366
-60 81.9 29.9872
-16.35 47.9 19.0045
-69.5 85.4 24.6942
22.9 43.4 56.13666667
-49.925 25.4 36.51125
-38.95 73.4 45.217
66.5 42.9 63.6626
-65.5 73.4 38.1288
-48.5 75.9 32.9194
-49.35 70.9 29.2845
23 26.9 33.8386
-21.15 47.9 31.5855
72.5 41.4 64.0942
56.45 75.9 56.2105
69 26.4 70.3602
17.95 32.4 36.59825
66.35 15.9 69.901
-46.3 75.9 24.39
-9.525 54.4 28.5125
66.525 3.9 65.48291667
-42.55 22.4 24.24825
53.975 69.4 51.52891667
50.5 39.9 59.5798
-26.5 56.4 30.5484
70.5 75.9 51.079
72.4 51.4 84.358
-67.5 81.9 29.8832
-69 58.4 43.7918
-34.2 58.9 28.88
19.925 72.9 53.476875
64 57.4 57.7864
-47.5 43.4 37.185
57.5 72.9 48.6102
71 71.9 53.0604
-68 88.4 18.0714
12 33.9 37.4738
62.3 34.4 60.479
54 57.4 55.8504
44.2 49.4 54.69
66.15 52.9 58.1165
-34.3 83.4 40.658
-22 48.4 31.5996
-47 65.9 32.886
51.6 69.9 48.963
43.5 69.9 48.6312
13.5 34.9 36.7224
41 16.9 38.8258
-42.4 45.4 39.39333333
-53.5 78.9 30.394
63.3 35.4 55.372
-68 20.4 35.1972
-22 55.4 30.8136
50.075 12.4 42.518875
37 42.4 50.3004
45.5 53.4 53.3082
-55.7 76.4 35.064
49.8 50.4 63.565
-38.5 73.4 45.3334
56.5 65.9 52.2102
-51.5 21.4 33.327
-47.5 59.4 35.9062
36.1 45.4 50.305
-49 64.4 33.7836
-58 85.4 24.802
-43.65 78.4 27.183
-54.925 76.4 33.019375
-55.975 36.9 44.49491667
-55.6 80.4 29.209
-19.525 44.4 29.97625
-64 70.9 39.867
50.5 43.9 59.937
72 53.4 80.266
54.5 42.9 61.913
-48 69.9 30.039
43.075 45.9 42.527625
-41.35 83.9 18.3715
-46.625 76.4 32.4755
45.8 70.4 50.743
7 43.9 28.193
-38.575 76.4 44.513125
-65 22.4 36.9296
6.3 58.9 60.508
-55 61.9 41.9776
-49 78.4 19.5874
-13 41.9 28.1862
67.65 63.4 55.1415
-39.575 50.9 33.013125
-33.35 84.4 40.6645
-38.85 75.9 44.2905
-50 56.9 43.3128
57.95 75.4 55.933
66 5.4 67.242
-18.65 57.4 27.2485
-60 33.4 43.3526
64.4 9.4 64.848
-28.5 53.4 31.2462
31.075 42.9 51.992
-43.5 33.9 34.1382
38.5 17.4 37.785
72.2 49.4 86.173
-2 50.9 29.1004
-66 37.4 44.6308
54.05 50.9 53.3135
-53.5 61.9 41.9024
-56.5 56.9 45.4734
-48 35.9 35.076
-61.5 70.4 40.1932
-47.05 79.4 18.2415
-48.025 77.9 20.52225
4 55.9 61.7622
-62 57.9 45.2086
-49.5 47.9 43.8204
45.55 12.4 41.16525
-60.5 47.4 46.2428
3.075 60.4 60.888125
-57 48.4 46.2502
-49.3 59.9 35.764
-55.6 83.4 24.064
-19 60.9 25.9194
61.5 70.4 52.8254
-26.8 43.9 31.154
-46.3 21.9 27.52
-57 82.9 28.6356
-60.5 18.9 33.8312
57.7 76.4 56.414
16 34.4 36.3444
-39.65 51.9 33.0245
5.9 58.4 61.255
62.4 7.9 60.83
-19 57.4 27.5916
-16.975 54.4 28.51883333
13.95 71.4 56.11775
-31.95 60.4 27.624
-50.925 76.9 32.5205
50.075 49.4 63.53425
-63.5 73.9 37.7942
58.8 76.4 55.733
8.8 32.4 36.461
-49.6 70.9 36.955
67.8 14.4 69.944
6.2 56.4 61.452
-12.5 57.9 24.817
-55.45 24.4 35.9265
-13.9 58.9 21.24033333
-57.5 61.9 44.1404
52.8 73.9 50.569
-48.5 54.4 37.1624
68.8 76.4 49.973
70.075 76.4 50.499875
-45 29.4 31.412
-43.7 79.4 26.461
5.425 63.4 61.010375
64.925 36.4 65.03025
-62 44.9 46.0752
33 72.4 49.6598
21 73.9 53.105
51.85 48.4 60.327
-49.4 72.9 32.17266667
-69.5 76.9 35.1994
-69 76.9 35.2116
-61 83.4 27.8938
-62 76.9 35.319
26 26.9 34.7142
-41 33.9 32.84966667
-23 60.4 29.0502
-48.5 38.4 35.944
-58.5 76.9 35.3488
-14.2 57.9 18.846
54.8 49.4 58.693
-53 76.9 32.5506
47.85 10.4 42.371
60.025 70.9 52.00316667
-43.95 84.4 9.7885
-54 79.4 29.8452
-52 14.4 24.933
65.05 73.4 47.834
-62 70.9 39.8792
64.2 71.9 47.635
-49.925 76.9 32.485125
64.3 35.4 61.75
-44 31.4 32.7434
-57 25.4 39.2198
-25.5 58.9 29.7306
-6.5 57.4 27.643
-40.2 74.4 43.508
-46.5 62.4 34.7506
68 7.9 71.2692
62.5 69.4 53.4136
-46.3 73.4 26.729
-65 75.9 36.1922
-41.2 47.9 40.943
-49.5 76.9 32.173
-68.5 73.9 37.7736
-61.5 30.4 42.039
-55 21.9 33.3894
-44.5 23.4 27.3108
-39.15 33.4 29.1585
-36 48.9 29.1808
-48.5 76.9 32.2924
-39 58.4 42.258
-35.1 51.9 28.52666667
-42.5 25.9 28.0998
2.6 50.9 45.735
52.55 50.4 66.92075
-48 61.4 35.1428
-47.5 76.9 32.3354
58 45.9 60.7954
-52 43.9 43.8246
-54.5 70.9 37.408
-52 29.4 39.083
-30.9 86.4 39.64433333
55.15 49.4 57.8845
-62 78.4 33.8648
61 46.4 60.809
-46.6 76.9 31.992
-40.35 79.4 34.6565
-68.5 44.9 43.5774
70.3 24.9 84.71
57.5 72.4 48.6102
-46.4 76.9 24.953
-59.5 14.9 29.2256
15.95 71.9 55.1115
-53.5 82.4 25.7718
-8.5 52.4 28.9424
-61.5 62.9 43.8042
-69.5 66.9 42.0768
-64.5 76.4 35.7364
-40.8 69.4 45.005
-37.925 76.9 44.506125
-37.075 76.9 44.508
32.5 45.4 55.026
37.6 46.4 50.572
49.5 53.4 53.8642
51.5 39.9 58.801
12.7 69.4 55.307
-3.5 60.4 26.2644
-44.575 22.4 25.949875
4 65.9 58.431
-38.2 70.9 43.056
66 63.4 53.9062
-55.4 77.9 31.48333333
1.8 49.9 40.079
-58.5 88.4 18.2672
-66.5 75.4 36.584
59.5 76.9 56.93866667
65.5 24.4 70.1834
-55.45 75.4 33.97525
-65.5 78.4 33.827
7.5 51.9 61.683
25.5 72.4 51.2508
66.15 19.9 73.286
-50.5 30.4 39.6744
-38.875 78.9 38.879
-13.6 55.4 27.71933333
69.5 76.9 49.6254
-65.5 29.4 41.4714
-9.5 56.4 27.9872
-63 50.9 46.1814
14 46.9 59.7706
64.4 25.9 67.122
-11.35 51.4 28.811
-21.5 51.9 31.5292
48.7 50.4 60.103
58.075 58.9 56.003125
39.65 42.9 51.67
-65.5 76.9 35.2684
-57.425 40.9 45.520875
-37 53.9 28.6902
-27 54.9 30.9444
-37.6 48.9 32.476
25.5 44.4 56.1384
16.5 34.4 36.169
6.5 56.9 60.83366667
-39.7 45.9 40.906
-69.5 53.9 43.7308
13 72.9 55.9688
-59 78.4 33.8986
65.3 73.4 48.429
-43 30.4 32.1164
65.5 8.4 67.8662
-7.5 49.4 29.0816
59.5 45.4 61.7722
60.525 71.4 51.50233333
-46 33.9 34.1386
60.5 63.9 50.3102
-11 53.9 28.6232
-52.5 19.4 31.3522
-56 60.9 44.4284
31.5 25.4 34.638
70.9 54.4 67.796
-57 47.9 46.2464
44.525 67.4 47.51416667
-38 46.4 32.816
-50.5 23.9 35.489
-63 72.9 38.5342
-58.5 59.9 44.777
-48.5 20.9 31.9794
-42.1 55.4 33.07566667
-15.5 49.9 27.206
-63 42.9 45.8476
-66.4 56.9 45.15566667
2.6 50.4 45.137
-57.5 55.9 45.6522
-60.5 9.9 20.4816
-35.05 58.4 27.3125
-57.5 13.4 27.0562
-55 59.9 42.589
-43.6 44.9 37.409
60 68.9 53.1806
-70 75.9 36.0844
-69.5 10.9 22.5964
25.65 25.4 34.123
14 44.9 59.7506
-44.5 74.9 24.9516
52.5 9.9 45.3478
-34.575 80.9 42.995125
-26.5 58.4 29.9412
-50.5 26.4 37.3542
47.5 44.4 55.373
20.5 29.4 34.242
-14.15 48.9 24.9575
66.2 10.4 68.114
68.975 41.9 64.00991667
-34 54.4 31.0638
72 54.4 81.248
47 13.4 41.6244
-16.5 62.4 24.4494
62.5 38.4 58.1962
-66.45 42.4 45.31825
-69 83.9 26.9954
-68 70.9 39.8632
-58.5 77.4 34.8646
-22 45.4 31.4022
-57.5 77.4 34.8892
-44.05 78.9 25.8625
52.5 41.4 59.249
58.5 68.4 52.9608
-49 48.4 43.878
63.35 29.4 62.2105
-56 77.4 34.7444
-42.15 79.4 30.2705
-60 52.4 46.1018
-23.025 42.9 31.01875
-64 35.9 44.2348
-55 77.4 32.007
-65 77.4 34.8176
-51.925 77.4 32.022625
37 43.4 51.7376
-31 58.4 29.946
67 34.4 63.6808
-69.5 59.4 43.7954
-21.05 52.9 31.292
6.7 36.9 34.892
-0.85 65.9 38.3185
-49.975 77.4 31.97941667
-0.5 50.9 29.1074
-52.5 63.9 41.1696
-50.5 70.9 37.413
-37.95 72.4 44.972
-47 77.4 31.6878
-43.5 38.9 36.1066
-45.5 77.4 20.7848
64.85 3.4 63.411
-59.5 86.4 22.8986
-47.5 75.4 33.1184
-57.5 71.9 39.2876
-44.5 41.4 36.7698
-56.5 63.4 43.6024
58.3 34.4 51.332
-67 77.9 34.3376
-68.5 73.4 38.171
-64 54.9 45.8232
-30 51.9 31.4728
62.5 52.4 57.4446
4.5 43.4 28.2882
14.975 71.4 55.46391667
51.7 69.4 50.665
-51.5 63.4 41.3786
-67 39.9 43.86766667
-67.5 72.4 38.8604
-51.5 26.4 37.3106
59 72.9 46.911
-58.5 53.4 45.9832
25 47.4 55.007
-41.475 63.9 45.97016667
-10.5 56.4 27.9734
38.45 20.9 36.20875
1.2 65.9 46.839
-63 86.4 22.8726
-64 71.4 39.5602
56.525 73.9 51.50425
64.3 25.9 66.631
57 35.4 51.5698
-46 61.4 35.1452
53.35 54.4 54.889
6.425 63.9 60.45125
-42.5 63.4 44.6846
63.95 30.9 58.73325
-49 60.9 35.36
22 44.4 57.3262
10 46.9 60.7388
18 46.9 57.3296
-39.6 46.4 41.842
-1.5 60.9 25.7082
61.5 64.9 52.2428
-50 21.9 33.7474
-66.65 56.9 44.808
-49 28.9 31.0846
-43.1 49.9 34.76
62.55 3.4 60.73975
-43 29.9 31.7636
-59 50.9 46.179
50.1 45.4 58.378
18.8 28.4 35.705
-56.5 51.9 46.1332
-67.5 35.9 43.4208
-48.1 80.4 14.89133333
13.4 47.9 59.22633333
-36 51.9 28.9578
42 69.9 48.6234
9.9 35.9 35.95566667
-37.4 66.4 23.556
-22 55.9 30.6876
10.15 68.4 57.952
-49.65 59.9 42.226
61.5 3.4 58.2534
60 54.4 58.0976
-65 45.9 46.129
-61 42.4 45.7818
-1.15 59.4 26.632
70.9 49.4 77.015
57 70.4 51.7246
31 74.4 49.1692
64.4 63.4 46.726
-40.3 57.9 42.639
-67 63.9 43.4138
-39.5 46.4 40.373
52 69.9 50.4056
-69.5 75.9 36.0966
-49.35 56.9 36.7125
71.2 51.4 68.953
-55.6 65.4 41.586
63.8 4.4 63.393
21.35 47.9 57.558
72.5 45.9 62.9528
-66 77.9 34.3358
61.075 67.4 53.495375
-41.35 78.9 32.6525
-65.5 69.9 40.4248
-12.35 59.9 24.752
-55.4 72.9 36.00933333
-59 55.9 45.6544
-62 85.9 23.8684
-60.5 66.4 42.3556
-16.65 61.9 24.6255
17 73.4 54.6962
-4.3 66.4 23.88
68.025 18.4 73.03583333
-52.025 72.9 36.01875
-18 54.9 28.3918
51 72.4 47.098
-54 73.9 35.213
-63.5 77.9 34.3652
-61.5 77.9 34.3486
63.075 34.4 61.41175
-45.65 79.4 21.666
-54 26.9 37.6368
-39 46.4 33.261
-14.5 54.4 28.5026
-61.5 84.4 26.3886
-48 51.4 37.4692
-43.35 79.4 27.323
-64 83.9 27.1318
-59 46.9 46.2222
6.2 60.4 60.485
-46 71.9 28.291
61.5 3.9 58.4088
-33.85 83.9 40.402
72.5 52.4 85.911
0.5 47.9 28.8164
51.975 39.9 57.75725
-53.5 47.9 44.112
-58 49.9 46.2438
-34.2 46.9 29.959
-44.7 78.4 24.507
-39.1 63.9 32.691
-64 76.9 35.289
-55 73.4 35.5962
69.5 9.4 72.4238
-58.5 85.4 24.8178
-67.5 49.4 43.7622
66.65 72.9 50.641
-46.5 26.9 29.5136
-31 45.4 31.369
-57 77.9 34.378
-54 60.9 42.2644
-51.8 15.4 25.504
44.85 49.4 56.2815
45 41.9 55.3354
64.8 24.4 69.463
-36.5 66.4 21.8768
-46 58.4 36.2622
54.6 63.9 49.86666667
-35.7 48.4 29.115
39.5 46.4 49.914
-3.3 65.9 28.125
44.525 53.4 53.01433333
-48.5 26.4 28.6236
-45.5 63.9 33.9748
0.3 66.4 42.405
-67.5 27.9 40.6666
-51 77.9 31.4784
57.5 71.9 49.0966
-64 66.9 42.0998
-65 74.4 37.396
-49 77.9 20.5768
64 62.4 45.722
56.5 7.4 49.1998
4.5 46.9 27.7112
-54 81.9 26.4926
-42.65 78.4 29.7195
30 74.4 49.67
49 52.9 53.7952
-69 12.4 25.4018
49.075 41.9 57.502875
26 44.9 56.1746
-66.5 86.9 21.7776
55.5 66.4 52.19
-47.5 77.9 20.5142
-51.5 76.4 33.0682
68.5 15.4 71.1706
-57 72.9 38.5718
-31.925 46.4 30.45875
52.95 10.9 44.02625
-41.55 83.4 19.2185
-45.075 77.9 20.04975
-39.6 63.4 45.81866667
37.4 42.4 50.32866667
-55.45 71.9 36.75575
-44.5 77.9 20.2414
-48 25.4 28.133
52 57.4 54.6258
-35.925 69.4 18.042875
58 35.4 51.741
-4.35 54.9 27.9195
39 71.9 48.4698
32 23.4 35.751
-37 77.9 44.1928
63.9 61.4 44.747
54.2 59.4 53.254
66.4 9.9 68.75433333
39 18.9 37.7828
-39.3 60.4 31.534
-49 71.4 28.794
15.025 72.9 55.5215
-44 60.4 35.895
65.8 14.4 67.967
-31.5 45.4 31.3622
67 63.9 54.7
-45.5 33.4 33.847
-42.6 50.4 36.13266667
-53.5 68.9 38.674
-64.5 79.9 32.294
64.85 9.4 66.3195
-67 79.9 32.254
64.35 48.9 55.854
-29.5 56.4 30.5734
-38.5 72.9 45.4412
-39.975 63.9 45.99708333
-58 88.4 18.2342
-1 45.9 28.974
70.7 24.4 88.197
-23.5 58.4 29.9332
53.8 48.9 57.948
70.575 42.4 64.03225
58.6 34.4 53.473
-53.3 14.9 26.014
-55.7 58.9 44.699
-39 49.4 33.0816
-49.65 35.9 41.6895
56.5 63.9 50.2928
-8.5 49.4 29.104
-49.55 41.4 42.8285
-63.5 11.4 23.6696
-67 18.9 33.7816
-63 57.9 45.26
-65.5 9.4 19.2282
38.5 70.4 48.8336
-54.45 24.9 36.19625
70.7 25.4 88.433
66.05 49.4 59.2235
67.1 16.4 70.643
-16.2 58.4 21.107
61.075 64.4 51.5095
-56.5 47.9 46.2512
37.6 17.4 37.13566667
-58 76.4 35.8054
-63 57.4 45.3712
71 71.4 53.262
-33.95 57.4 30.24825
-8 50.9 29.0686
-36.5 59.4 26.7986
55.3 46.4 58.992
65.45 73.4 48.7345
-48.8 25.9 28.48
-62 55.4 45.7344
-43.7 45.9 37.517
-12.2 45.4 28.283
-45.05 78.9 23.3255
-52.5 45.9 44.0304
-69 51.4 43.7024
61.7 4.9 60.38
-64 78.4 33.8428
57.35 4.9 49.903
46.5 51.4 53.3708
2.5 43.9 28.2482
60.35 7.9 53.168
-61.5 54.4 45.858
-61 78.4 33.8706
12 72.4 56.4538
60.05 71.9 50.10625
-55.7 48.4 46.03
-59.5 78.4 33.8862
44 13.9 40.339
36.5 50.4 53.4446
-58 78.4 33.9224
-47 41.9 36.9116
52.5 70.4 50.0888
66 6.9 68.1758
-32.5 55.9 30.7078
-21 61.4 27.81
-36.2 76.4 43.167
-43.85 79.9 25.215
-45.35 78.4 22.8515
-46.3 75.4 26.171
15 45.9 59.6714
-49.4 38.4 37.47
25.4 75.4 50.37366667
-48.5 70.9 29.2178
58.075 72.4 48.52075
50.075 40.9 59.9895
-36 39.4 27.2904
-63 66.9 42.094
-58.5 65.9 42.5506
-62.5 29.9 41.7404
45 67.4 47.7418
3 43.9 28.1804
54.925 68.9 52.04575
-56.5 80.4 31.8114
3.3 50.4 48.905
-16.5 55.9 27.2688
63.5 40.4 62.2094
-48.85 72.4 31.4595
72.5 44.4 63.1628
66 10.9 67.143
61.9 50.9 52.60366667
-11.95 60.4 25.17675
41.5 69.9 48.6506
54.5 55.9 56.851
52.15 73.9 51.4785
-47 78.4 19.5286
69.55 75.9 50.762
68.2 44.4 62.957
-46.6 78.4 19.733
-62.5 83.9 27.1014
-42.1 49.9 33.221
-63.5 14.9 29.1618
61.7 2.9 58.145
67.1 11.4 69.76266667
-41 62.9 46.1238
-52 19.9 31.8192
-31.5 54.4 31.062
-5.5 54.9 28.4122
-46.15 78.4 20.844
-60.425 24.4 38.520375
-10.5 43.4 28.5422
-44.95 78.4 23.868
51.6 73.4 49.933
-14.5 58.4 20.31566667
-65.5 39.4 45.161
64.45 38.9 60.813
48.925 69.9 48.015625
-39.35 51.4 29.66
-14.925 59.4 21.945625
-1.95 48.4 29.0975
-39 58.9 42.258
-42.85 78.4 29.2135
-63 23.9 38.1022
-42.3 78.4 30.604
-17.15 48.9 27.621
-49.4 80.4 14.995
45.2 49.4 57.131
39.5 49.9 53.7764
67 53.9 58.6668
41 45.4 51.9564
62.45 6.9 61.8845
5.8 38.9 33.467
-51.5 46.4 44.0456
-41.7 44.9 40.394
9.65 67.4 57.8175
-43.3 78.9 27.757
51.5 45.9 57.7236
-41.45 78.4 32.747
-68.5 60.9 43.808
37.3 45.4 49.992
-46.5 69.9 30.0658
23.5 43.4 55.7592
-36 78.9 43.8184
-60 47.4 46.2272
-39.3 78.4 38.192
-35.9 80.9 43.10366667
65 43.4 63.3194
43.35 41.4 53.3275
-64.5 44.9 46.0744
9 70.4 57.94
-60.5 77.4 34.844
-67 27.9 40.6856
-53.5 48.4 44.1174
-38.85 78.4 39.334
-11 56.4 27.9822
-63.5 79.9 32.3134
-52 85.4 19.644
-27 50.4 31.5906
-69 53.9 43.7324
66.1 17.9 72.58366667
46.075 12.4 41.49
66.65 52.4 58.614
19.5 43.9 57.9092
55.5 71.4 49.9206
63.5 55.9 58.2694
-13.35 55.4 28.148
-65.975 55.9 45.52
-37.95 83.9 28.358
-38.55 69.4 45.235
33.55 19.9 35.284
-57 30.9 42.298
72 43.9 63.396
8.025 51.4 61.51933333
66.8 74.9 50.011
-52 29.9 39.3902
-63 32.9 43.1132
-8.5 56.9 27.7824
66 70.9 52.7418
-11.5 52.9 28.7912
64.55 5.9 65.76875
-50.5 34.4 41.4406
-47.5 74.4 33.054
41.15 41.9 51.76
-33.5 56.4 30.5576
18.85 28.9 36.0315
68.65 5.4 69.309
-48.95 45.4 42.1565
21 75.4 51.9272
64 56.9 58.0742
-26 47.4 31.5938
56.425 66.9 52.53725
18.2 46.4 57.997
-15 49.4 26.42
-70 78.4 33.7732
35.9 44.9 50.18
-49.6 81.4 25.679
-37.15 32.4 25.267
-58.5 82.9 28.663
66.075 4.4 65.97675
56.5 69.4 52.2766
-15.1 48.9 23.841
-70 78.9 33.266
-25.475 59.4 29.52116667
67.5 73.4 51.2006
-15 60.4 24.1922
-54.5 25.9 36.9608
52.65 54.9 54.884
-66.5 78.9 33.2756
-45.5 45.9 37.4794
-49.5 73.4 33.984
-46.5 43.9 37.241
19.5 73.4 53.6742
-63 78.9 33.378
52.5 71.4 48.2574
-36.5 67.9 20.201
-57.5 52.4 46.1134
-45 74.9 24.9686
-6.5 46.4 29.0358
64.3 49.4 55.503
-67 82.4 29.2508
-55.7 78.9 32.862
-3.4 43.4 28.60166667
-54 78.9 30.3904
59 8.9 51.75833333
-58.5 30.9 42.2858
65.95 72.9 49.7925
-3 52.9 28.821
-52 78.9 30.396
-50.5 24.9 36.2282
-18.8 49.4 28.233
16 72.9 55.1706
6.2 57.9 60.998
-62 77.9 34.316
38 49.9 53.9726
51.5 53.4 55.9268
-15.5 60.9 24.5766
-57.5 87.9 19.5538
-45.025 23.9 28.48125
-40.8 58.4 41.256
51.875 71.4 45.43225
64.95 61.4 52.7165
-49.0375 78.9 18.526375
25.5 44.9 56.3418
-65 23.9 38.1186
63.65 5.9 64.2415
-57.5 59.9 44.7674
-51.5 36.4 42.166
69.5 15.4 71.375
-56.5 13.9 27.7944
21.5 43.4 56.7508
-12.8 58.4 23.701
-44.2 78.9 25.483
-59 31.4 42.4792
-48.5 78.9 18.5226
61.025 56.9 58.01241667
52 54.4 56.154
-68.5 37.9 43.389
-37.5 43.9 30.332
-4 60.9 25.9752
46 43.4 55.897
-48.5 60.9 35.3312
-52.35 13.4 22.9875
55.9 5.4 48.29666667
-47.99166667 24.4 27.42383333
64.6 62.4 49.981
-43.45 78.9 27.379
67.575 17.4 72.994875
30.45 26.4 34.02225
51 66.9 48.832
-56 64.4 43.1636
-19.5 42.9 29.8106
0.65 66.4 43.807
-60.5 76.9 35.331
-10.45 57.9 27.34525
-48 75.4 33.1002
-43.05 78.9 28.3875
-51.5 43.4 43.7628
-37.15 70.4 43.9865
-16 49.4 26.1362
-42.85 78.9 28.891
53.6 67.9 51.252
-38 45.9 32.823
-23.5 54.9 30.9662
-57 85.4 24.7564
67.2 37.4 66.156
-47.075 21.4 32.00775
11.45 69.4 57.21375
53.4 67.9 50.12366667
-58.5 17.9 32.8186
-42.35 78.9 30.1475
-49.35 73.4 32.5525
-58 58.9 45.0362
-41.65 79.9 30.868
-44.45 84.4 8.3475
55.5 41.4 62.1286
14.5 32.9 37.1856
-23.5 51.9 31.4928
0.5 45.9 28.7948
-59 59.4 44.8772
63.35 4.4 62.817
71.5 49.9 78.47
-44.65 71.4 28.7725
-65 16.4 31.0498
27.425 72.9 51.006875
-37.2 84.4 29.461
-65 48.4 46.241
-48 33.9 34.1518
-55.7 76.9 34.832
-39.15 83.9 24.817
-38.55 78.9 39.697
58 44.9 61.8304
25.475 28.9 33.99091667
-37.3 84.4 29.17
17.5 71.9 54.1706
-14.5 44.9 28.1228
47.525 53.9 53.51525
29.075 73.4 50.5525
-5.475 51.9 29.01816667
-37.5 62.4 30.2358
37.475 72.4 48.52341667
-65 55.4 45.736
-18 42.4 28.8924
-46.6 75.4 32.863
-19.85 55.9 26.836
-50.475 41.9 43.5155
-45.5 76.9 22.1178
-3.925 48.4 29.0065
-67 58.9 44.1918
-66.5 61.4 44.1878
-48.65 16.4 23.4
-57.5 51.9 46.1536
61.7 7.9 57.169
-24.5 54.9 30.9694
-37.55 78.9 42.208
72.3 53.4 83.865
-61.5 80.4 31.7736
54.575 54.9 57.008125
-56.1 14.9 29.11433333
-57 82.4 29.3238
-12.2 60.4 25.008
67.05 6.9 69.2875
6.4 56.4 61.21066667
71.5 53.4 74.299
66.35 50.9 58.9265
10.55 45.9 60.112
-67.5 21.4 36.0812
-15.65 59.4 22.1675
-27.5 48.4 31.6558
-59.5 88.4 18.2818
-66 10.9 22.6194
-6.5 50.4 29.0692
-37.8 64.4 29.995
72.5 50.9 85.494
-18.8 48.9 28.154
47.8 50.4 57.276
-42.5 34.4 34.3576
70.5 23.9 88.712
-63 73.9 37.7762
-37.7 43.9 31.485
-52 42.4 43.6248
36.5 45.4 49.3662
66.3 49.4 59.491
-50 36.4 42.1438
68 62.4 55.822
46 52.9 53.6594
33.45 43.4 53.62175
9 67.9 58.7408
64.5 70.4 52.8366
-41.625 24.9 27.428
-64.5 77.9 34.3388
-4.625 46.9 28.9555
6 55.4 61.8318
54.075 71.9 47.0195
19.025 43.4 57.52991667
2.85 60.9 60.449
-25.5 50.4 31.5742
-50 56.4 43.3742
-45 39.9 36.3828
31.425 72.9 50.001375
-39.8 50.9 33.003
-12.35 55.9 27.7525
-67.5 31.9 42.6884
-68 65.4 42.7624
-56 56.9 45.4306
65.55 9.9 67.237
-7.5 46.9 29.0794
64.5 72.4 46.429
32.975 71.4 49.5135
40 51.9 52.9246
-69 29.4 41.4446
-38.6 69.9 45.59133333
-32 54.4 31.0828
-39.4 72.4 45.416
48.15 13.9 40.9785
68.3 4.9 68.457
64.05 4.4 63.7135
-37 49.4 29.0614
59 41.4 62.6686
-46 75.4 24.3432
11.65 48.9 59.8155
-68.5 45.4 43.5916
-64.5 79.4 32.8044
60.8 30.9 60.443
57.95 4.9 50.819
65.4 74.4 48.275
55.5 74.9 55.8516
-38.95 48.9 33.0795
-45 68.9 30.8788
31.65 44.4 51.808
-2 45.9 28.9964
-55.7 66.4 41.642
-43.95 22.4 25.3805
72.1 51.9 80.858
51.35 68.9 48.8845
-41.7 83.4 18.776
59 38.9 60.7162
-25.5 59.9 29.284
45.9 49.9 56.786
58.5 54.9 57.8306
-66.5 20.4 35.1928
69 54.4 59.325
-33.2 84.4 40.861
-32.925 44.4 29.475875
-60.5 79.4 32.8292
-2.5 55.9 28.1126
67.2 9.4 70.697
-26 59.4 29.5392
52.5 44.4 57.94
19.5 72.9 53.6738
-3.5 60.9 26.0018
33 42.4 50.5532
-59 23.9 38.143
-40.5 47.9 41.7654
-34.65 46.4 28.972
-57.5 79.4 32.871
-56.5 17.9 32.8356
-49 79.4 17.4534
14.5 31.9 37.1668
-32.2 45.9 30.953
-62.5 58.4 45.1194
-32 57.4 30.2496
-49.35 57.4 36.451
16.425 72.9 54.996375
12.2 73.4 55.229
-67.15 41.4 43.695
71.9 54.9 81.749
52.65 6.9 45.273
55.075 45.9 59.99825
30 71.4 49.7408
66.3 74.9 49.481
-46 52.4 37.3938
-0.5 54.9 28.398
-58 73.9 37.8428
-49.55 53.4 43.354
-5 58.4 27.1828
-35 79.9 43.269
53.2 50.9 62.422
2 47.4 28.3828
-46.15 79.9 19.326
-47 62.9 34.4834
-67.125 55.9 44.032
-46.05 79.4 20.689
-9.5 55.9 28.1152
-43.95 79.4 25.8435
-41 64.4 46.1374
-51 25.4 36.6502
-3.5 53.9 28.6122
64.7 25.4 68.741
-43 28.9 31.0792
-42.8 79.4 28.676
60.2 7.9 52.723
20.5 75.4 52.0416
49.7 40.9 59.765
2.65 58.9 60.684
-66.4 40.9 45.11966667
-66 9.9 20.4196
69.025 74.9 51.51691667
-69.5 69.4 40.7316
60.5 52.4 57.2034
0.4 50.4 33.294
-48 65.4 33.1874
49.6 8.9 43.19066667
-4 44.9 28.718
-42.2 57.9 37.234
-49.35 28.4 30.8425
60 53.4 57.768
61.35 6.4 58.0465
-63 24.9 38.7952
-46 69.4 30.4602
-64.975 72.9 38.51666667
-48 38.4 35.9508
-35.45 78.4 43.4785
-39.9 79.4 35.769
52.85 71.4 48.295
-48 72.9 32.2452
1.6 50.4 39.783
45.85 43.9 55.489
-48.5 62.4 34.7152
-55.45 37.9 42.77
-62 14.4 28.4554
-40.15 50.4 33.598
49.5 55.4 52.8132
66.5 45.4 62.6928
45.8 70.9 47.33
-57 73.4 38.2314
-60.5 34.9 43.9062
-39.05 79.4 37.8665
-1.5 56.9 27.8082
-57.5 22.4 37.0172
-38.95 84.4 24.3475
-38.45 79.4 39.3425
71.8 55.4 80.833
-38.05 79.4 40.3245
-67.5 24.4 38.4258
-36.9 62.4 25.19233333
1.7 65.9 48.913
65.2 50.4 57.601
-28 57.9 30.0734
-32.5 47.9 31.6022
63.6 5.4 63.8915
-52.025 67.4 39.52458333
-53.925 77.4 32.017375
-55.7 17.9 30.816
-20.9 45.4 27.7
64.5 55.4 58.48
-53 28.4 38.5966
-64.5 42.9 45.8242
-37.9 67.4 28.326
-42.4 44.9 39.26
-8.5 59.9 26.5858
53.3 51.4 55.237
32.475 73.4 49.50791667
-69 33.9 43.2026
66.35 61.4 54.902
53.5 37.4 52.4368
-35 81.4 42.8604
-50.5 71.9 36.7428
-34.525 80.4 42.99266667
-66.5 16.9 31.6416
66.55 49.4 59.7325
12.65 69.9 56.405
57.5 58.9 55.8426
67.6 2.9 66.768
-57 79.9 32.3714
-46.35 22.9 27.6465
-49.35 66.4 32.7685
-48 63.9 34.0486
-55.6 78.9 31.33
-68.5 47.9 43.65
-29.5 55.4 30.7988
-61.525 74.9 37.018
-29.5 45.4 31.4268
-20.8 45.4 26.453
-60 87.9 19.5446
67.45 34.9 65.14975
-62.5 14.4 28.4626
-16.8 57.4 23.124
-38.9 52.9 32.98366667
-20.05 56.9 25.875
59.5 68.4 53.162
69.525 72.9 52.50391667
-69 16.9 31.681
-59.5 33.9 43.5198
-64 21.4 36.0966
-64.5 35.9 44.2246
64.35 30.4 64.2485
66.475 41.9 63.53191667
60.925 44.9 62.526125
70.3 50.4 61.151
-49.3 37.9 35.862
-68 79.9 32.2528
56.525 6.9 49.48391667
19.5 44.4 58.122
-67.5 60.4 43.9104
13.5 71.9 56.3408
-68.5 79.9 32.2246
-66 79.9 32.2724
61.85 8.4 56.3805
-50.5 79.4 29.8458
-69.5 68.9 41.0316
-38.075 49.4 32.952625
40.5 18.9 37.6066
-59.5 49.9 46.2416
-62.5 79.9 32.2986
-39.35 84.4 23.172
-68 74.4 37.377
-8.5 55.4 28.291
42 14.9 39.3266
65.625 10.4 66.913
56.5 45.9 60.4562
64.8 19.4 71.385
-55.8 79.9 32.017
43.3 43.4 54.502
-63.5 59.4 44.856
-47 57.4 36.4838
-64 65.4 42.8038
11 72.4 56.308
-49 79.9 16.2676
-60.5 81.4 30.59
-33 45.9 30.3304
-56.5 25.9 39.53
46 44.4 54.9528
6 65.4 60.383
-59.5 9.9 20.4948
-37 65.4 22.7944
-34.55 55.9 29.697
69.2 4.9 67.686
55.1 59.4 53.65133333
-15.4 46.4 27.44933333
-43.1 84.4 12.276
56 52.4 56.5902
-45.55 79.9 20.8585
-51 18.4 29.9188
-13.5 61.9 24.4628
-10.35 59.9 26.354
28.5 71.4 49.7222
51.6 72.9 49.933
55.5 73.9 52.185
68.5 73.4 51.8352
2.7 61.4 59.894
-66.4 56.4 45.21533333
-65 81.9 29.9146
54.5 68.9 51.93
-39.35 83.4 25.715
-42.3 79.9 29.204
52.05 70.9 49.31975
7.925 69.4 58.509375
-26 53.9 31.1566
-40.15 79.9 34.684
-19.7 45.4 27.846
-15 46.9 18.7512
-58.5 73.4 38.168
-13.5 52.4 28.1604
69.2 24.4 75.539
68 24.4 70.6042
-17.35 49.4 27.868
-37.2 79.9 42.269
-37.9 47.4 32.27633333
12.2 47.4 60.513
12.15 69.4 56.45
-36 79.9 43.5512
-68.5 35.4 43.3082
-62.5 61.4 44.3088
-63.5 75.9 36.1646
49.475 11.9 43.0265
-37.65 61.4 30.768
64.1 62.4 46.431
55.4 72.4 48.43766667
70.4 25.9 85.129
-62 51.4 46.1376
34.5 20.4 36.407
-7 60.4 26.3132
-56 36.4 44.3892
-63.5 15.9 30.492
-19.4 49.9 29.01733333
-47.5 67.9 31.6054
35.575 18.9 36.421625
-61.5 37.4 44.7008
-11 56.9 27.716
-2 47.9 29.1082
-57 29.9 41.787
-68 63.4 43.5802
45.3 41.9 55.517
-66.45 36.4 44.173
-33.5 84.4 40.228
58 39.4 61.3268
-46 70.4 29.6738
-29 51.4 31.5124
-49.7 28.9 38.602
-39.85 33.9 30.916
33 43.4 53.656
62 54.4 58.4674
-51 31.4 40.1514
-37.35 51.4 29.0015
-18.5 54.4 28.4908
-69.5 26.9 40.0808
-39.5 84.4 22.736
-35.2 58.4 27.004
51.7 72.4 48.298
66 54.4 58.6188
-50.5 27.9 38.2894
5.5 54.4 62.036
-63.5 27.9 40.6818
8.5 69.4 58.5488
35.5 48.9 54.2272
3.6 49.9 49.169
-56.05 79.9 32.26975
-54.5 68.9 38.6726
47.35 45.4 50.747
18.9 28.4 35.513
-67.525 39.4 43.52216667
70.7 51.9 63.384
-63 40.9 45.5024
-3.5 58.4 27.2616
-61 76.9 35.3218
-68 58.9 43.8172
49.35 70.4 43.29
31.95 43.9 51.78425
-49.7 80.4 28.225
60 38.9 60.8144
65.6 4.9 66.18033333
60.075 65.4 52.501
18 43.9 58.3442
61 31.9 62.2244
55.5 7.9 48.1874
-36.4 80.4 43.23533333
-67.5 84.9 25.5502
15 31.9 37.1278
-44.5 48.4 37.5982
52.35 73.4 51.3105
-31 55.9 30.7062
65.35 35.9 64.936
66.5 45.9 62.1032
-61 58.4 45.1256
-22 49.9 31.6004
-60.5 87.9 19.5788
-62.5 17.4 32.2398
61 66.4 53.2162
-37.05 75.4 44.68575
-47.1 72.4 31.48683333
-66 78.9 33.3166
44 69.4 48.6144
44.075 15.4 40.01725
-37.9 68.9 44.32166667
65.85 62.4 53.6805
-49.45 21.9 31.92425
71.6 54.4 76.39
54.025 58.4 54.52033333
-58.5 51.4 46.1572
-64.5 20.4 35.2326
43.5 52.4 53.4348
-68 82.9 28.5564
54.5 39.9 61.248
-49.7 32.9 40.604
-54.45 83.4 24.06675
-58.5 58.4 45.1454
72.4 50.4 84.461
-22.5 50.4 31.5862
66.65 64.9 55.1005
57 39.4 61.2478
54.025 56.4 56.52283333
-60 20.4 35.242
-36.5 56.4 27.9826
-31.075 85.9 39.92725
-48.5 39.9 36.3932
68.05 12.4 70.728
65.15 60.9 53.328
-62 50.4 46.189
-68 77.4 34.787
-58 38.9 45.12
72.6 53.9 88.487
65.55 5.4 66.7245
-20.9 59.9 28.77333333
55.5 44.4 61.6152
-44.35 83.4 10.91
-51.5 44.4 43.8702
-59 9.4 19.3174
-51.5 50.4 44.0976
-65.5 73.9 37.7888
-70 21.4 36.097
60 70.4 52.4396
-39.875 78.9 36.353
21.5 75.4 51.7944
9 32.9 36.6698
-49.6 69.4 37.851
-65 49.9 46.2072
-33 58.9 29.6236
-58.5 28.4 41.025
45.35 68.9 47.8695
60.55 7.9 53.761
-26 49.4 31.6054
-66.5 85.4 24.7148
-52.925 72.9 36.019875
60.5 58.9 56.6968
-2 43.4 28.4998
-64 64.4 43.192
1.4 38.9 30.41433333
-54.5 84.9 20.6424
64.4 64.9 52.357
-12.8 58.9 23.625
-60.5 80.9 31.1808
-37.05 43.4 28.509
64.65 60.4 52.2665
-66 84.4 26.3222
-37.5 44.9 30.732
57 52.9 57.0466
-62.5 58.9 45.025
29.5 25.4 35.1412
-21.5 42.4 30.8386
-28 48.9 31.6424
-60 80.9 31.1896
-58.5 80.9 31.2348
-62.5 62.4 43.9746
13 31.9 37.2786
-52 82.9 24.9292
-16.4 56.9 24.979
-63 80.9 31.1594
-62.5 62.9 43.7982
-45 72.4 27.7756
21.025 46.4 57.00741667
-36.425 79.9 43.479625
-64.5 33.9 43.4896
57.5 63.9 50.3314
-46.35 45.9 37.439
-48.35 44.9 40.1955
28.5 45.9 55.5976
-50 80.9 27.851
64.5 70.9 52.3498
-34.15 80.9 42.694
71.1 51.9 68.386
58 40.9 62.329
64.4 51.4 55.351
17.7 29.9 35.935
48.7 44.9 55.147
-17.6 57.9 26.31633333
-52.5 25.4 36.6238
-42.5 23.9 26.7826
58 52.9 57.1758
61.35 60.9 44.2265
-42.8 51.4 36.459
-57.5 72.9 38.5728
69.475 12.4 71.53416667
-38.05 78.4 41.3585
-10 47.4 29.1178
-30.5 48.4 31.6494
-64.5 43.4 45.9038
53 45.4 56.046
-55.4 83.4 23.39833333
60.5 31.9 62.1408
-43.65 79.9 25.732
2.9 56.9 60.875
-70 87.9 19.308
-69.075 84.9 25.51575
-49 73.9 32.8728
-62 65.4 42.7594
-48 65.9 32.8736
-55 13.4 24.235
-1.5 57.9 27.431
-5.5 60.9 26.0492
-36 31.9 22.7318
43.6 13.9 40.14833333
-37.4 45.9 31.083
-46.7 77.9 20.384
27.5 74.9 50.1888
-65.5 85.9 23.8032
-42.2 83.4 17.301
-50 14.9 24.6334
-69.5 19.4 34.2782
-46.5 33.4 33.8754
67 19.4 72.5904
-26 46.4 31.5448
7.45 65.4 59.67125
-70 34.4 43.24
-66.5 81.4 30.537
-62.5 15.9 30.494
-63.5 81.4 30.5586
-57.5 81.4 30.6334
-32.5 48.4 31.6272
-45 71.4 28.7662
-42 20.9 22.6268
-68.5 41.4 43.4826
64 9.4 63.166
-55.7 27.4 39.808
-55 18.9 30.3188
-61 69.9 40.4522
-52 75.4 33.9926
21.5 29.4 34.3916
-62.075 58.9 45.01625
-66 16.9 31.6562
-68.5 81.4 30.505
67.4 12.9 69.78733333
-68 13.9 27.7088
-64 37.9 44.8018
-46.5 64.4 33.7424
-47.5 22.9 31.722
-42 58.9 39.8128
-38.1 62.9 30.66033333
-57.5 47.4 46.2386
48 53.9 53.6434
-60 81.4 30.6
-64.5 78.4 33.8224
-46.5 65.4 33.1748
-33.7 81.4 42.337
-55.45 42.4 43.70525
-54.5 52.4 43.9618
-64.5 71.9 39.2434
-69 81.9 29.8842
-3.6 65.9 26.875
67.65 8.4 71.2055
46.6 49.9 59.271
32.35 45.4 54.384
-68.5 58.4 43.8026
60 43.4 62.9242
-61 88.4 18.2302
-66.025 58.4 45.02283333
-47.15 79.9 16.7635
-1 66.4 37.167
-49.35 35.9 35.1405
-68 81.9 29.8982
-31.8 84.9 40.515
-38.65 84.4 25.2275
53 56.4 53.8878
-39.65 46.9 42.5295
-47.5 38.4 35.977
51 68.4 49.0668
67.5 14.9 70.208
-36.6 75.4 42.69
-48 22.9 31.7176
4.925 55.9 62.026625
-63 81.9 29.9276
-44 51.4 37.299
-16.9 60.4 24.96466667
37.2 17.4 36.607
-47.5 57.4 36.495
-28.5 45.4 31.429
-47 30.4 32.151
-62.5 81.9 29.9316
-63 39.4 45.2028
-59 81.9 29.9988
-59 21.9 36.5704
65.35 51.4 57.4275
-34.5 82.4 42.4
-7.5 52.4 28.9414
-60 87.4 20.7326
67.5 52.4 59.2818
-57.5 82.9 28.678
-59 21.4 36.1532
56.85 6.4 49.9365
-7.5 46.4 29.042
9.5 32.4 36.8588
-64.5 32.9 43.104
-58 55.4 45.745
-45.5 32.9 33.57
-46 65.4 33.1922
53.3 70.9 48.805
66.4 72.9 50.37066667
-56.5 86.9 21.7976
-60 76.4 35.7832
-41.85 79.9 30.357
-55 51.4 44.0464
56.5 40.9 62.1614
-5.5 56.9 27.8164
-34 50.4 31.5646
-52.925 81.9 26.523875
-61.475 15.9 30.51816667
-58.5 57.4 45.3726
53.5 37.9 52.6708
-35 81.9 42.6214
16 43.9 58.7622
-24 52.4 31.4052
58.65 4.4 51.2225
-63.5 55.9 45.6726
-67 29.9 41.717
-55 70.9 37.4304
-44.55 79.4 24.36
56.5 8.9 47.328
-37.6 62.9 30.387
-50.5 62.9 41.5992
-5.5 51.4 29.0544
23.15 43.4 55.9605
-15 52.9 28.1318
73 51.9 91.685
-41.3 28.9 30.488
-57.5 81.9 30.0002
12.35 48.4 59.619
66.8 62.4 54.995
-39 54.9 32.6218
50 51.4 52.7266
67.025 25.9 70.00933333
-53.5 34.4 41.4404
72.8 53.4 89.849
-16.5 56.9 25.482
67.5 18.4 72.9074
-4.65 49.9 29.1205
-63.5 51.4 46.1488
-31 85.4 39.925
-66 26.9 40.0714
-56.5 58.4 45.1628
-42.7 45.4 38.921
-62.5 27.4 40.4008
63 69.4 53.1424
-35.55 40.9 27.695
53.975 65.9 51.53333333
71 53.4 68.277
46.85 11.9 41.9355
72 41.9 64.1312
-68 82.4 29.2118
48 41.4 56.7918
-57.5 54.4 45.8862
-55.45 82.4 25.75775
72.6 52.4 87.171
60.425 41.9 63.041
-18.5 61.9 25.3608
-36.5 48.4 29.1674
63.7 59.4 53.823
-52 82.4 25.745
50.95 42.9 60.34
38 71.4 48.9126
-25 47.4 31.5808
66.7 9.4 69.655
38.7 48.9 53.502
-44.5 73.4 26.767
-68.5 41.9 43.4844
-65 32.9 43.0902
-69.5 53.4 43.7268
33.5 21.4 36.1634
-63 71.4 39.5724
23.525 43.9 56.54041667
60.5 54.4 58.1838
-23 55.4 30.8414
-54 82.9 24.909
-63.5 45.4 46.0956
-38 58.4 31.8674
52 40.9 58.8658
-59 47.9 46.2522
-31.5 48.4 31.6162
37.5 69.4 48.2168
-42.05 57.9 37.663
49.35 8.9 42.7445
-5.5 56.4 28.0058
3.05 62.4 60.2725
33.5 71.4 49.4766
19.1 28.4 34.326
47.7 45.4 51.735
34 71.4 49.434
58 63.9 50.3184
-42.65 79.9 28.306
-64.5 82.9 28.5724
38.85 42.4 49.3595
-2 52.4 28.9388
-38.15 83.9 27.77
-57.5 47.9 46.2406
-57 15.9 30.5828
-38.925 45.4 32.93775
5.5 56.9 61.8608
55.025 41.4 62.02133333
-40.85 66.9 45.9915
-9 59.4 26.6202
-26.5 50.4 31.5852
-62 53.9 45.929
-60 68.4 41.3426
59.5 43.4 62.8338
54.575 55.4 56.982625
-16.1 57.9 20.19033333
64.1 51.4 53.862
-68.5 34.9 43.256
-41.05 51.4 34.29425
-44 25.9 28.106
-43 44.4 36.7884
66.8 64.4 54.508
-2 59.4 26.42
42.8 17.9 37.847
-35.35 44.9 28.489
-59.5 82.9 28.6542
-34.2 57.9 29.437
34.5 73.4 48.6634
35 41.9 48.9518
-49.5 52.4 43.178
-40.425 48.4 41.9305
-13 55.4 28.2552
-41.65 29.9 31.6295
29.85 22.4 34.3485
-55.3 82.9 24.121
-20.425 45.9 26.49675
-59.5 75.4 36.6284
-38.85 45.9 32.873
55 51.4 55.3056
-52 13.9 23.4002
0.8 49.9 34.984
-2.525 48.9 29.04016667
-66 75.4 36.5852
-53.5 18.4 29.8086
-55.4 71.4 37.09333333
-56.5 81.9 29.9566
-53.5 58.9 42.8562
-40.1 59.4 43.821
-36.3 83.4 34.708
63.1 30.9 59.852
-43.375 78.4 27.882
2.15 38.4 31.11
-43.5 25.9 28.101
-53.5 57.9 43.0934
-13.35 57.9 21.825
3.65 36.9 34.1645
-16.2 58.9 22.294
1.9 65.9 49.74
-62 83.4 27.9046
66.6 11.4 68.435
-65 12.9 26.1742
36.7 17.9 36.633
52.575 48.4 60.382
-48.5 67.9 31.6058
-66.5 36.9 44.2446
-40.55 46.4 41.79225
-46 37.4 35.6248
-55 83.9 22.256
-4.35 52.4 28.4675
-41.35 84.4 17.3865
-63.425 44.4 46.020875
-27.5 45.4 31.4424
-66 22.9 37.3314
45.6 68.9 48.56466667
35 72.4 49.24
38.525 50.4 53.90483333
-55.6 55.4 44.156
14.45 35.9 35.51625
-58 83.4 27.9074
-55.7 54.9 45.416
-31.5 58.9 29.6608
-36.4 36.9 26.16933333
67.15 61.4 55.631
-37.8 62.4 30.391
-32 49.9 31.6096
-68 68.4 41.2922
-33.525 82.9 41.98133333
-13.575 56.4 26.960625
-49.925 50.4 44.049625
-41.375 58.9 40.8735
-6 53.4 28.7518
-49 70.4 29.673
3.1 64.4 57.524
-53 83.4 24.0774
-54.5 29.9 39.3958
-43.85 83.4 12.404
-37.3 46.4 31.785
-41.35 83.4 19.807
67.45 59.9 56.29675
-43.2 51.9 36.117
48.575 70.4 44.49375
-48.5 16.4 22.829
31.85 44.9 51.9485
35 48.9 54.222
-23.5 50.4 31.5594
-60.5 51.9 46.1336
-33.95 80.9 42.434
45.5 42.4 55.7828
55.5 9.4 46.3944
-47.5 53.9 37.205
-14.4 48.9 24.66366667
-51.5 10.9 17.33
-40.5 83.4 22.302
65.5 61.4 53.78933333
41.5 52.4 52.9124
-54 29.9 39.4096
55 63.9 49.9634
-58.5 70.9 39.8916
-49.5 20.4 31.7874
34.5 47.9 54.418
-19.4 46.9 28.96933333
-39.65 83.4 24.8215
-34.95 82.4 42.3295
-3 66.4 29.125
-40.2 44.9 41.818
5.5 35.4 36.7834
-66.5 77.4 34.786
-28.5 49.9 31.598
-21.5 45.9 31.4846
67 17.9 72.7762
-37.8 83.4 30.307
11.5 47.4 60.712
-37.65 83.4 30.7495
-60 24.9 38.8096
9.8 32.9 37.008
62 71.4 51.932
-37.3 83.4 31.78
68 4.4 67.1488
62.55 63.9 51.647
-45 54.9 37.0794
45 69.4 48.8568
-58 15.9 30.5692
-47.5 46.9 43.7574
38.5 50.9 53.456
-36.1 83.4 35.307
-53.5 54.4 43.7946
35 48.4 54.3308
-35.6 83.4 36.8
-55.45 48.9 44.185
-67.2 52.4 43.863
57 59.4 54.6806
-63.5 34.4 43.7192
-7.925 59.9 26.528375
56 41.9 62.2964
-17.2 57.4 26.511
-2.5 52.4 28.921
-49.65 35.4 41.4855
-65.5 68.4 41.3072
-20.6 57.4 24.916
64.075 64.9 51.871625
35.5 71.9 49.2436
38.575 43.4 50.957
-36.5 55.4 28.3548
56 55.9 57.2822
-56.5 82.4 29.3196
60 69.9 52.7666
60.8 6.9 52.973
-56 50.4 46.1938
-66 35.9 44.1984
-65 28.4 40.962
39.5 71.9 48.308
-37.3 42.9 29.556
3.55 55.4 61.287
-69.5 83.9 26.9962
65.85 46.9 59.933
17.075 34.9 35.534875
-65.925 52.4 45.989125
-43.6 84.4 10.808
-34.2 44.9 28.994
50.8 51.9 52.47
-61 34.4 43.7192
67.6 26.4 70.10466667
-0.425 50.4 29.329875
-67 68.9 41.0542
5.45 58.4 61.64625
-58.5 27.4 40.4308
-43.6 46.9 37.78433333
-58 72.4 38.9434
-6 45.4 28.9148
68.025 74.9 50.98925
-35.3 61.4 24.988
59 38.4 59.2618
-44.7 68.4 30.946
3.15 36.9 33.1985
-66.2 48.4 45.973
-62 83.9 27.1142
63.5 71.4 51.2008
49 70.9 42.9532
7.3 64.4 59.61
-63 71.9 39.2134
65.4 52.4 57.33066667
9.475 68.9 58.47233333
63.55 2.4 60.682
68.5 10.4 71.7366
-52 33.4 41.0878
-52.5 64.9 40.7328
-47 35.4 34.8276
-53.5 63.9 41.1762
17.5 46.4 58.3134
-52 83.9 23.2246
-50 29.9 39.3756
-51.5 57.9 43.0702
53 55.9 54.4092
-43.8 83.9 11.149
-26.5 44.9 31.3856
-62 59.4 44.8798
-41.7 35.9 35
65.65 51.9 57.712
-41.8 48.9 33.436
6.85 36.9 34.6955
-66.45 45.4 45.67625
-51 73.4 35.6762
-52 67.9 39.2642
-1.5 46.4 29.047
-28 55.9 30.722
-61.5 16.4 31.1168
47 51.9 53.5694
51.475 53.9 56.03691667
-65 68.4 41.3242
24.975 75.4 50.51125
36.3 17.9 36.014
-62.5 80.9 31.164
-55.45 81.9 26.54125
-37.8 66.9 27.396
-39.7 61.4 36.427
-55.85 80.9 30.876
-35.65 42.4 28.1295
-37 51.9 28.9654
64.85 6.9 66.8385
-52.5 83.9 23.1714
-39.65 79.4 36.3865
-39.2 46.9 36.482
62.6 2.9 59.873
38.5 71.4 48.8378
65 40.4 62.4116
-35.95 83.9 34.1995
-53.5 60.9 42.2614
-64.5 15.4 29.801
-40 69.9 45.8078
-70 71.9 39.2058
-42.8 83.9 14.108
49 43.4 57.189
-39 70.4 45.7678
66.95 10.4 69.8215
-60.5 20.9 35.6972
24.9 74.4 51.604
-55.4 62.4 41.782
-68.5 72.9 38.5126
-58.5 67.9 41.6196
-59.5 48.4 46.2604
63.3 36.9 57.198
-35.45 83.9 35.6775
-51 44.9 43.9562
18.9 27.9 32.648
64.5 38.4 60.951
58 60.9 42.2876
-54.3 10.9 18.994
-54.05 84.4 22.15825
-3.5 44.9 28.853
-46.3 77.4 20.463
-44 31.9 33.0388
68.525 9.4 71.96241667
66.15 61.4 54.684
0.5 44.4 28.5838
-59.5 78.9 33.41
-19 47.4 29.01983333
-34.7 83.9 37.898
-40.875 79.9 32.845
-37.7 79.9 40.991
-50.5 62.4 41.7482
-57.5 24.9 38.8696
-45 35.9 35.0436
-34.35 83.9 38.931
-23.5 47.9 31.6284
-37.6 66.9 25.189
-58.5 75.4 36.6416
-49.075 67.4 31.9965
6.55 41.9 26.823
60 56.9 57.7676
59.85 6.4 52.877
-69 60.4 43.7942
7.925 51.9 61.4445
61.45 2.9 57.7885
-37.3 45.4 29.055
-41.3 79.9 31.762
-53.5 29.4 39.1188
-67 9.4 19.1652
-64.5 56.9 45.4608
67.55 7.9 70.77725
69.85 9.9 72.479
-41.1 30.9 31.646
30.975 23.4 35.53133333
44 41.9 54.6684
-14 42.9 28.2116
13 44.9 59.764
-65 10.4 21.593
-35.5 81.4 42.8178
-61.5 40.4 45.4136
-37.15 83.9 30.7065
-9.5 44.9 28.8024
69.5 18.9 73.3134
-50 51.9 43.9928
26.5 45.4 56.1006
-40 69.4 45.8934
-13.85 47.9 20.8405
-43.15 22.4 24.6515
-39 69.9 45.7618
-19 55.4 28.2396
45.5 70.9 47.4404
-50.5 38.9 42.8892
70 73.9 52.2126
-20.15 46.9 27.163
-70 74.4 37.3298
65.3 48.9 58.375
60.2 30.9 59.603
-60 84.4 26.3844
-42 30.9 32.4288
65.65 64.9 53.6305
-16 61.4 24.6968
-56.5 77.4 34.8956
19.925 44.9 57.987375
61 42.9 63.2018
25.5 47.4 55.086
-59 84.4 26.4194
-61 79.4 32.8588
-37 51.4 28.9922
66.7 11.4 68.799
-25 50.4 31.5586
55 74.4 54.631
-36 78.4 43.8726
-56.5 84.4 26.3498
43 50.9 53.6168
-65 70.9 39.8464
-63.5 61.9 44.1506
15 32.4 37.158
-37.65 68.4 44.4895
-51 84.4 22.3098
64.7 73.4 46.852
-41.4 44.9 40.8795
-65.95 42.4 45.644
-19.05 47.9 29.0645
-64 80.9 31.1832
-55.45 40.9 43.39775
-38.15 59.4 31.653
-69.5 82.4 29.2134
55 40.4 61.6668
50.6 72.4 43.575
-66.475 35.9 44.00558333
-67.5 55.4 43.8494
2.5 49.4 28.2198
-56.1 16.9 31.69433333
-57 80.4 31.8192
-43 42.9 36.795
-44.3 84.4 8.78
-43.7 84.4 10.514
-17.85 45.9 28.273
-56.5 35.4 44.0992
66.9 50.9 59.42166667
-50 41.9 43.5002
-38.6 75.9 44.36033333
-59 82.9 28.6574
-58 66.4 42.3574
71.1 55.4 71.716
-19.3 45.4 28.701
50.5 67.4 48.9342
-18 48.9 28.143
-42.8 84.4 13.155
-57.5 69.4 40.7772
40.65 42.4 52.174
-37.45 78.4 42.872
-57 75.9 36.2494
53.4 73.9 52.464
44.65 49.4 55.7735
-41.1 28.9 30.21366667
-46 25.4 28.143
-41.05 84.4 18.258
61 55.9 58.3076
-48 74.4 33.0926
-51.5 39.9 43.1506
19.025 45.9 58.04008333
65 65.4 52.9062
2.85 36.4 32.697
-3.925 53.9 28.3995
-37.7 84.4 28.005
5.3 51.9 61.473
8.5 69.9 58.2392
-37 52.9 28.8324
-37.45 84.4 28.7335
64.05 6.9 65.3335
2 46.9 28.42
-28 53.4 31.2292
65.3 9.9 66.94
72.8 41.4 64.012
-36.35 84.4 31.927
66.2 38.4 64.822
-53 57.9 43.0836
-56 61.9 44.1224
-57.5 53.4 45.9876
-65.5 70.9 39.8772
63.35 71.9 51.168
-51 64.4 40.941
-31.5 85.9 40.1288
55 53.4 56.8896
-55.45 79.9 29.20325
-35.55 84.4 34.2305
19.4 32.9 35.396
-0.1 66.4 40.799
-47 43.9 37.2368
-37.65 32.4 26.166
-34 84.4 38.77
16.9 28.9 35.301
-36 41.9 28.1436
-56.5 40.9 45.5412
-56.5 58.9 45.0472
-35.35 84.4 34.811
72.2 54.9 85.789
-46.5 23.4 28.887
-55 79.9 29.1844
68.025 15.4 71.01475
-34.3 53.9 31.172
-68 49.9 43.7084
-49 32.9 33.6044
-49.35 64.4 33.7875
-48 52.9 37.3566
70.6 54.4 64.121
-62 18.9 33.8088
-1.5 50.4 29.106
3.3 49.9 47.655
-63 78.4 33.8514
-19.95 46.9 27.738
62 52.4 57.415
-53 14.9 25.8842
-54.5 27.4 37.963
20 32.4 35.9758
-68 44.4 43.58
-5 47.4 29.102
-51 78.9 30.3246
-66.5 64.9 43.0114
-66 32.4 42.8998
-11 47.4 28.1454
38.9 42.9 50.76433333
-42.475 47.9 39.443
-40.55 71.9 44.917
54.1 71.4 49.23366667
61.8 62.4 43.561
-69.5 11.4 23.6222
-69.5 52.4 43.7156
-64.5 28.9 41.201
-69.5 72.4 38.8788
64.35 8.4 65.794
-61.5 16.9 31.6938
-7 50.4 29.0568
-35.425 81.9 42.475125
63.9 25.9 64.694
57.5 69.4 52.433
64.7 29.9 66.43
-70 84.9 25.5036
-40.55 83.9 20.711
-39.2 59.4 44.552
70.9 75.9 51.09733333
-31.5 53.4 31.2516
62 39.4 61.7606
-37.8 83.9 28.8
-37.375 50.4 29.0375
-65.5 84.9 25.5408
-55 33.4 41.0468
-55.4 64.9 40.74933333
-10 46.4 29.0794
-65.5 76.4 35.7246
-55.45 23.4 35.163
-29 48.4 31.6636
-70 17.9 32.7642
-62.5 84.9 25.5854
62.8 7.9 61.985
62.5 39.4 61.7828
66.5 13.9 67.686
57 67.4 52.6974
-37.6 49.4 32.354
-45 48.4 37.608
5.025 52.4 61.48491667
-55.45 56.9 43.34075
-57.5 75.9 36.236
3 46.4 28.115
-51 39.4 43.0474
40.5 68.9 48.2876
-35.2 83.9 36.419
-48.5 31.9 33.0464
24.575 28.4 34.53125
-8.5 46.4 29.0448
-61.5 84.9 25.5944
-46.5 30.9 32.4736
42.575 16.9 39.01825
-51 21.4 33.3424
-69.5 80.4 31.6766
-63 82.9 28.5848
-7.5 58.9 26.9316
-59 16.4 31.1566
-61.5 49.9 46.2162
46.5 14.9 40.1962
-57 55.4 45.776
-60 84.9 25.6552
-50.05 84.9 21.1455
-10.525 54.4 28.51866667
-39.125 31.9 28.426
-43.9 52.9 36.882
-10.375 48.9 28.91575
-52.5 48.4 44.1078
3.525 37.4 33.00875
-32.05 84.9 40.837
-62 47.4 46.213
-69.5 73.9 37.7452
-46 33.4 33.8646
-19.075 42.4 29.50325
-37.425 55.4 28.49925
-62.5 42.9 45.845
-42.55 83.4 16.266
-65 57.4 45.3746
-36 37.4 26.4544
29.35 74.9 49.3035
60.7 5.9 57.613
9.1 66.9 58.269
-37.625 60.4 31.00925
65.9 10.9 66.673
-36.8 84.4 30.623
-31 59.9 28.272
-50.5 37.4 42.4906
45.65 49.9 55.8755
37 69.9 48.6352
-23.5 56.9 30.4342
-61.5 19.9 34.8034
47 50.4 54.745
-7 49.4 29.123
-52 47.9 44.115
-67 85.4 24.7056
72.425 45.4 63.00725
4.2 50.4 53.692
-63.5 72.4 38.8626
-66.5 17.4 32.211
72.2 55.4 86.047
-57 76.9 35.3246
-0.5 49.4 28.9018
-65.5 87.9 19.4128
70.5 75.4 51.7094
-66 62.4 43.95
-63.5 85.4 24.765
54.5 72.9 48.5722
56.875 61.4 42.01675
55.425 38.4 59.530625
-70 81.4 30.506
-40.2 57.4 41.986
-68 73.4 38.1418
53.45 52.9 54.3195
-60.5 85.4 24.7892
69.5 53.9 59.6586
-69.5 75.4 36.5558
-48 54.4 37.1578
-57.5 85.4 24.7878
-59.5 18.4 33.3436
9.5 69.9 58.2236
-49.35 39.9 36.5985
72 41.4 64.168
-4 58.4 27.1584
-67 71.4 39.56
-29.425 51.4 31.522
58.5 58.9 56.1188
-36.95 42.4 28.31025
4.3 66.4 58.433
65.4 71.9 49.263
-55.7 59.9 44.375
-55.6 60.4 43.936
-53.5 17.4 28.4918
-54.5 80.4 28.5862
64.7 62.4 50.693
-47 35.9 35.0896
-48.5 74.4 33.0838
-34.65 48.9 30.299
-58.5 55.4 45.738
60.2 31.4 62.081
-51.5 85.4 18.972
-61.5 21.4 36.1244
49 40.9 56.8782
56.425 45.4 61.048125
-37.4 56.4 28.411
-66 27.9 40.6672
26 27.4 34.6954
-13 56.4 27.0862
-54.5 82.4 25.7646
-53 48.4 44.1104
69.5 53.4 59.8386
66.65 65.4 54.643
62 58.4 57.1104
-63 14.4 28.456
-21.5 47.4 31.5938
-55 48.9 44.114
-51.5 75.4 33.9766
60.025 5.9 53.43825
-68.5 50.9 43.6872
65 71.4 51.651
36.5 20.9 36.7838
-49.7 24.9 35.893
-51 25.9 36.977
64.3 46.9 56.729
-57 78.9 33.3918
-45.5 29.4 31.4222
-63.925 33.9 43.513375
-58 13.9 27.7796
-55.6 59.4 43.425
-41.425 64.4 45.99825
-58 74.9 37.0348
5.5 52.4 61.7538
-62.5 45.9 46.1426
-53.0375 45.9 44.021
39.05 42.4 49.8335
-67.5 28.4 40.9642
-63.5 60.4 44.604
69.5 15.9 71.7132
72.6 51.9 87.091
-52.5 82.9 24.9652
22.025 43.4 56.54858333
-40.8 33.9 32.513
54.5 44.9 60.9626
51.4 45.4 58.70333333
-36.15 76.9 44.0575
-19.7 60.4 26.655
-57 62.4 43.9822
-52.5 62.4 41.738
-64.5 85.9 23.8228
-65 86.4 22.8342
30 72.4 50.3072
64.6 21.4 68.726
-61 76.4 35.7846
1.575 46.9 28.55175
-60 79.4 32.8242
-56.5 79.9 32.3786
-48 58.9 36.0648
0.975 44.4 28.51158333
-3 46.4 29.0576
64.9 37.9 64.01
-35.85 84.4 33.3725
-61.5 82.9 28.6636
68.5 17.4 73.2548
51.8 70.9 45.274
-51 75.4 33.959
-16.7 57.9 21.42
-63 85.9 23.8342
21.5 73.4 52.937
-38.5 31.9 27.26033333
53.55 45.4 60.14425
-64.5 67.4 41.8108
-2.5 45.4 28.9278
-12.1 51.9 28.243
70.2 23.9 87.081
5 46.9 27.7188
43.5 69.4 48.6312
64.7 35.4 64.322
-50.5 36.4 42.1936
-28.5 56.4 30.5806
61.5 33.4 61.1596
5.45 50.4 60.313
-61 63.9 43.4138
71.9 50.4 78.921
-18.9 59.9 25.769
43 71.4 47.636
-33.85 58.4 29.754
30 45.9 55.2026
-20.8 46.9 26.947
-35.8 83.4 36.203
-57.5 76.9 35.3434
-66.4 38.4 44.626
-53.5 58.4 42.972
-63 59.4 44.8784
-66.5 84.9 25.5358
72.8 52.4 89.704
-67 73.4 38.1522
56.5 71.4 50.3364
-66 56.4 45.419
-60 16.9 31.7166
67.4 8.4 70.842
30.975 23.9 35.52883333
-50 70.9 37.3794
59 51.4 55.7424
-27.5 59.4 29.5328
-22.5 53.4 31.2146
-40.05 83.9 22.1685
-25 53.4 31.2068
10.5 71.9 56.8146
72.5 53.4 86.259
-30 59.4 29.216
-56 18.4 33.2104
-37.65 50.4 29.712
-43.7 83.4 12.851
-51 38.4 42.778
65.5 52.9 57.6598
-49.8 81.4 26.976
-63 30.9 42.2352
72.3 50.9 82.936
-70 60.4 43.8262
69.3 25.4 72.786
-51.5 62.9 41.5794
-47.5 49.4 43.616
-39.5 54.4 32.6544
-61 28.4 41.001
-55.4 79.4 29.837
6.925 37.4 33.425
-49.7 25.4 36.28
72.2 52.9 82.391
67.5 64.4 55.122
-13 42.4 28.371
-62.5 72.9 38.51891667
34.925 46.9 53.994875
-1.975 49.4 29.03225
17.475 72.9 54.51733333
-2.5 46.9 29.0752
-49 56.9 36.6328
3 65.9 54.264
-4.65 56.9 27.6635
-69.5 86.4 22.7484
61 57.4 57.7316
40.6 52.4 52.488
65.075 2.4 61.964375
-38.5 75.4 44.7422
-67 74.9 36.9776
-50 47.4 44.0828
-64 52.4 46.0972
-11.6 61.4 24.978
-67 76.9 35.2552
53.2 65.9 49.498
-20.15 58.4 28.2245
-52 69.4 38.3616
-51 28.4 38.5696
69.1 49.4 64.303
-54 48.9 44.11
-49.65 26.4 36.8605
71.3 50.4 72.27
72.6 54.9 91.164
-61.5 88.4 18.2192
70.4 24.9 85.791
-62 86.4 22.8716
-7.5 51.4 29.045
-60 86.4 22.9138
63.575 69.9 52.989
-42.5 34.9 34.5908
64.85 60.4 52.815
-57.5 86.4 22.8788
65 7.9 67.26966667
-1 50.4 29.126
-64 43.9 45.9608
35 69.9 48.258
-47.5 62.4 34.7334
66.5 23.9 70.4278
-36.85 60.4 26.3355
-50 57.4 43.1962
-68.425 82.9 28.516875
61 54.4 58.2736
29.5 72.4 50.4072
-66.45 38.9 44.69275
27.5 44.4 55.255
-54.5 22.4 33.87
-6.925 43.4 28.52325
-55.8 86.4 21.82
-59.5 26.4 39.8204
-66 87.9 19.4318
7.4 42.9 27.756
-56.025 68.9 40.97666667
-52.5 11.4 18.781
-56.5 29.4 41.5082
8.575 47.9 60.973375
-47 70.4 29.639
-35.925 68.4 19.5165
-2.8 65.9 30.202
-52 57.9 43.1066
-60.5 78.9 33.4124
66.3 7.4 68.471
58 53.9 57.5964
-3 55.9 28.1144
-54.5 47.9 44.1196
61 70.9 52.2794
42.4 45.4 52.15566667
-46.35 47.4 37.5975
32.5 46.9 54.73
-68.5 53.9 43.744
56.5 65.4 51.914
-30.5 49.4 31.6374
-61.5 28.9 41.2716
-18.5 58.9 25.8792
70.7 54.4 65.362
-30 56.4 30.5736
-67 86.9 21.7682
52.475 40.4 58.513
-56 78.4 33.7628
56.5 40.4 61.9318
-41.4 54.4 32.91133333
10.5 32.4 37.3266
-52 72.4 36.4676
-42 22.9 25.281
17.9 71.4 52.92083333
59.5 39.9 61.977
-61.5 86.9 21.8514
-36.85 79.9 43.1535
-47 43.4 37.1356
55.65 72.4 47.224
71 42.4 64.0162
-65 73.4 38.1368
-54 67.4 39.4878
-44.2 83.4 11.36
-47.5 78.4 19.5718
-65.95 48.4 46.10225
69.85 9.4 72.505
70.9 50.4 67.833
-57.5 31.4 42.4958
-65 50.4 46.1998
-0.5 44.9 28.8164
2.5 50.4 44.605
64.7 9.9 64.776
-62.5 83.4 27.8836
65.6 60.4 54.30166667
-58 30.9 42.2934
25.65 71.9 51.159
-44.2 79.9 24.31
47.525 12.9 42.006
-55.7 61.4 43.999
-66.95 59.9 44.1785
50.95 40.9 60.17325
-23 49.4 31.6018
57.525 42.4 62.52216667
26 72.4 51.138
54.45 74.9 54.4545
-57.5 86.9 21.8238
-70 68.9 41.0316
68 23.9 75.104
-69 25.9 39.4468
-53.5 49.9 44.1118
34.5 46.9 54.1612
-66 57.9 45.141
34 21.4 36.304
57.5 50.9 54.59
52.9 44.9 53.574
62 40.4 62.7086
27.575 47.9 54.48125
19.475 46.4 57.49658333
-63.5 19.9 34.7746
37.4 21.4 36.15433333
32.975 21.9 36.01566667
-34.7 45.4 28.978
-60.5 66.9 42.1234
-56.5 53.9 45.9432
62.2 3.9 60.628
-14.65 48.9 24.37
48.2 45.4 53.144
-20.15 45.4 26.8825
-68.5 70.9 39.864
-52.5 65.4 40.516
-37.45 83.9 29.827
-60.5 42.4 45.7898
47.85 9.9 41.834
3 48.9 28.00933333
-68.5 80.9 31.0832
-65.5 87.4 20.654
-63.5 65.4 42.797
65.2 63.4 52.962
-52 18.9 30.5718
-57 61.9 44.1576
66 54.9 58.7512
-64.5 87.4 20.676
-37.5 56.9 30.531
51.3 66.9 48.9
-14.975 58.9 20.01441667
2.4 50.9 44.585
-52 18.4 29.8748
-49 69.9 30.0826
-19.05 48.4 29.063
-64 87.4 20.6876
8 35.9 36.1624
7.5 67.9 59.3442
-64 11.4 23.658
-20.05 56.4 26.2105
56.5 73.4 50.0882
43.925 70.9 47.974125
-69 23.9 38.0902
57.5 45.4 61.2762
-57 33.4 43.3938
64 8.9 64.309
-25.5 60.9 28.7134
-67.2 60.9 44.011
-15.425 55.4 27.49775
-37.95 77.4 44.015
-53.5 30.4 39.6768
-62.5 72.4 38.8802
2.7 49.9 44.616
64.5 48.4 56.745
63.7 26.9 64.657
-56 49.9 46.2102
-51 79.4 29.8516
-39.9 48.9 29.982
11.5 46.4 60.602
52.3 46.9 57.013
24.425 43.4 54.978
39 45.9 50.655
-57.5 61.4 44.3146
-59.5 83.9 27.1506
40.8 41.4 50.015
62 69.9 53.2612
-61 74.4 37.429
52.4 55.4 55.352
65 53.4 57.9676
67.15 50.9 59.625
62.5 51.4 55.8772
-57.5 40.4 45.4472
17 43.9 58.5962
-69.5 72.9 38.4806
-49.8 85.9 18.498
-58.5 24.4 38.5062
-13.65 58.9 21.7835
64.5 40.9 62.731
24.6 43.4 53.995
64.8 59.9 52.873
-59 71.9 39.2472
64.575 45.9 61.515625
58.5 41.9 62.6928
-43.45 79.9 26.2475
-68.5 13.9 27.7062
-51.5 40.4 43.235
-37.4 60.9 30.266
66.2 8.4 68.64
3.1 60.9 60.774
-54.5 22.9 34.2706
9 50.4 61.26
53.2 45.9 57.736
-69.5 36.9 43.337
50.5 44.9 59.1886
43.5 68.4 48.3832
-65 87.9 19.3934
-21.1 45.4 30.23
8.5 49.4 61.4844
-43.15 46.9 38.3665
-48 73.9 32.9792
-17 52.9 28.7388
-32.025 59.4 28.01925
-63 87.9 19.5326
-55.7 34.4 43.317
65.2 32.4 59.062
-67.5 49.9 43.7592
-20.85 56.4 25.3185
-18.7 46.9 28.931
-66.5 84.4 26.3036
53.9 8.4 46.94333333
26.5 45.9 56.1006
72.9 53.4 91.054
-66 82.9 28.5646
-24.5 54.4 31.0534
10.1 45.9 59.75833333
-50.425 35.9 42.0175
-52.5 64.4 40.942
-55.45 54.9 43.72675
63.7 29.9 63.34
35 49.9 53.5782
-1 53.4 28.7484
53.15 57.9 54.68
-67.5 41.4 43.5976
-40 60.9 30.98
-67.5 88.4 18.0798
52.2 72.9 49.646
-66.5 65.9 42.5514
-40.5 63.9 46.144
-5 42.4 28.2204
31.5 48.4 54.3854
-36 80.4 43.3418
-63 26.9 40.1012
-46 59.4 35.8954
-20.55 55.9 25.47325
-49.7 82.4 25.167
-47.5 18.9 30.4876
39 48.4 52.82
64.6 73.4 46.478
-67.5 47.4 43.7406
-38.475 70.9 45.52433333
-56 84.9 25.3392
-36.35 80.9 42.7965
16.975 71.9 54.49408333
36.6 18.9 37.099
-55.4 80.9 27.978
-58.5 21.4 36.155
-50.05 13.9 22.3995
-52 58.9 42.8454
3.95 36.4 35.3295
-67 86.4 22.8016
-52.5 50.4 44.0872
-1.5 52.9 28.8062
-23 56.4 30.5516
-67.5 86.9 21.7568
54.5 50.9 54.1966
-23.5 55.9 30.7228
16 30.4 36.8076
-47.05 19.4 31.133
-53.5 61.4 42.091
3.3 64.4 58.647
-56 35.9 44.2188
-49.8 79.9 29.008
-35 80.9 43.1202
-44.5 27.4 29.9372
0 45.9 28.948
-47.6 79.9 15.80766667
-7 56.9 27.82
-63 39.9 45.3126
-37.3 79.4 42.161
49.3 45.4 56.273
-66 75.9 36.1622
5 65.4 60.2122
-65 14.9 29.1476
12 45.9 60.3742
60.5 67.9 53.3842
-45 58.4 36.2128
-40.6 48.9 31.24166667
66.15 75.4 48.1035
70.5 18.9 73.6036
-62.5 30.9 42.2522
-63 65.9 42.5552
49.5 49.4 63.678
-17 51.9 28.1742
-53.5 38.9 42.88
-14.85 48.4 20.291
-38.15 83.4 29.274
-37.625 50.9 29.38575
-38 76.4 44.6804
62.85 5.9 62.819
53.85 36.9 51.7185
53.7 68.4 51.736
-64 42.9 45.8198
10.2 67.9 57.499
4 61.4 61.3786
-55.45 41.9 43.628
8.5 50.9 61.385
20.95 46.9 56.14025
70.3 55.4 61.254
53.5 51.4 53.487
-27.5 56.9 30.4116
-32.5 51.9 31.4746
71 45.9 63.0628
-53.5 66.4 40.0314
-37.1 61.4 29.936
31 72.9 50.1
68 25.9 70.2398
-47.5 19.4 31.2276
-61.5 20.4 35.2708
-49.65 58.4 42.664
-54 83.4 24.0664
-30.5 50.4 31.593
20.5 30.4 34.2416
-23 51.9 31.4944
-39 72.4 45.4544
-24.5 53.4 31.2184
1.2 49.9 37.028
65.2 8.4 67.655
-67 84.9 25.5386
37.575 18.4 37.48325
-55.7 82.4 28.08
34 22.9 35.9004
-9.5 60.9 25.6662
62 43.4 63.2884
-3.5 52.4 28.903
61.5 52.4 57.3562
42 49.9 53.4544
17.15 28.4 33.187
-55 71.9 36.7498
-13.2 57.9 22.349
66.6 32.9 63.74766667
-55.6 72.9 36.681
64.15 38.9 59.878
52.55 48.9 63.13925
-55.45 36.9 42.424
-43 36.4 35.2328
-53.5 13.9 24.1872
-29 50.4 31.5866
-47.5 41.4 36.7548
3.575 59.9 61.4515
69.8 25.9 78.335
-53.5 81.4 27.2778
-54.5 59.4 42.7062
-8.5 43.4 28.5628
-30 51.4 31.525
44.5 43.9 54.914
-15 53.9 28.6158
-42.85 56.9 37.6315
51.35 68.4 49.133
56.5 56.4 57.1968
-40.075 44.4 33.902
53.4 65.9 49.47366667
-65.5 84.4 26.3494
-47.5 69.9 30.0552
63.5 52.4 56.7442
65.4 60.9 53.79266667
-32 57.9 30.1096
-69 72.9 38.5078
1.55 65.9 48.292
-51 16.4 27.4032
-52 81.4 27.2296
63.9 51.4 53.369
18.65 71.9 53.442
-63 87.4 20.7196
14 31.9 37.211
-68 16.4 31.0702
51.35 66.4 48.427
-49.35 42.4 37.083
70.5 41.4 64.2404
-34.45 56.4 29.729
57.6 5.4 50.69533333
51.5 41.4 59.1158
-55 67.9 39.2752
-46 73.9 26.1888
42.3 13.9 39.015
-66.95 60.9 44.137
-55.7 62.4 43.723
-56.5 30.9 42.2768
-57 66.9 42.1362
-16.9 47.9 27.34766667
41.8 43.9 53.478
-23 55.9 30.7066
-69 57.4 43.7834
-34.2 83.9 39.373
-37.5 67.4 24.757
53.4 69.9 49.54766667
11.5 71.4 57.1554
62.85 6.4 62.819
67.4 63.4 54.85466667
51.2 52.9 55.605
-17 58.4 23.165
-70 86.4 22.7256
-47 73.4 32.6386
-66.5 79.4 32.7998
-37 42.9 28.48833333
-52 36.9 42.3754
-47.5 34.4 34.365
-18.65 60.9 25.725
-56.5 14.9 29.2392
-57.5 82.4 29.3512
-39.1 46.9 35.046
61.35 59.4 56.106
63.925 67.9 53.54175
-49.1 15.4 21.432
-42.55 79.4 29.2895
-53.5 65.4 40.5158
-16.5 48.4 18.99216667
-49.375 79.4 17.51825
-49 55.9 36.8498
2.4 61.4 58.624
-38.5 48.4 32.978
-33.975 50.9 31.5175
-45 40.9 36.6584
-36 63.4 24.4476
-38.15 84.4 26.691
-56.5 27.4 40.4464
-30.5 54.4 31.0984
3.9 66.4 56.818
4.5 50.9 56.63
-48.5 61.4 35.1654
1.025 44.9 28.52475
53.15 68.4 50.328
62.575 33.9 60.974625
47.5 13.4 41.673
38.9 44.4 51.41933333
-56.5 73.9 37.8054
-69.5 25.4 39.1436
-15.95 59.9 23.68
-52.5 80.9 27.9298
63.3 72.4 50.109
4.5 47.4 27.6354
-66 61.4 44.2748
-60.475 74.9 37.01741667
-13.5 57.9 21.301
66.8 50.4 59.449
4.975 54.9 62.01475
-61 21.4 36.123
59 56.4 57.7536
-64.5 50.9 46.176
-40.35 78.9 35.149
43.5 41.9 54.3036
-46.925 74.9 33.04325
65.3 61.4 53.441
63.45 38.4 58.202
-48.5 79.9 16.2094
4.85 66.9 58.9645
-54 34.4 41.4326
54.85 6.4 47.88
13.475 35.9 36.01741667
39 18.4 37.9716
-24 53.9 31.163
61 36.4 53.7652
-60.5 88.4 18.2188
-49.65 59.4 42.459
-64 77.9 34.3452
-49.8 30.4 39.498
72.5 50.4 85.567
27.5 26.4 34.9194
-48 56.9 36.6416
64.9 72.4 47.808
38.975 17.9 38.03816667
-48 78.9 18.538
36.975 70.9 49.01875
-65.5 65.4 42.7564
-32.925 59.4 27.48725
53.6 57.9 55.17866667
65.8 50.4 58.497
-50.5 77.9 31.4844
-47 39.9 36.4468
-62.5 46.9 46.187
-45.5 54.4 37.1282
-55.7 56.9 45.121
-49.7 16.9 27.7
60.425 70.4 52.546375
62.7 7.9 61.735
59 4.4 51.803
24.5 27.9 34.59
-12 53.4 28.7402
66.4 52.4 58.37266667
-13.5 51.4 27.961
27.5 46.4 55.7792
58.9 73.4 46.012
18.5 33.4 35.8428
38 70.9 48.9126
-12.5 58.9 24.28433333
-50 38.9 42.8858
-69.5 17.9 32.7786
-34.65 59.4 26.4665
-55.6 62.4 43.193
71.8 51.9 77.142
-39.5 67.9 45.9272
3 44.4 28.2038
21 43.9 57.4296
-17.9 51.4 28.205
-29 51.9 31.4716
-2 44.9 28.8546
-50 59.4 42.719
-55.45 69.9 38.06025
-39.5 50.4 33.0916
3.55 36.4 34.3645
-9.5 57.4 27.6476
-10 59.4 26.7696
-53.925 85.4 19.46725
56.5 57.9 56.2236
-49 66.4 32.6566
-66.3 43.4 45.599
72.6 54.4 88.581
30.5 42.4 50.431
29.95 73.9 50.09825
18 44.9 58.6504
-52.5 17.9 28.871
38.35 48.4 53.696
51.1 55.9 55.60266667
-30.5 46.4 31.2348
62.3 30.9 61.132
-52.5 49.4 44.1178
39.925 45.4 51.550125
-48.3 50.9 37.517
-52 23.9 35.551
69 51.9 60.2314
51.525 71.4 44.02433333
61.5 54.9 58.4572
0.6 65.9 44.343
50.3 71.9 43.8
-65.475 56.4 45.51391667
14.65 70.4 54.321
7.95 52.4 61.2175
-4.5 57.9 27.4396
59 70.4 52.1916
28.2 23.4 33.89
3.525 61.9 60.95625
13.6 47.9 59.001
-54 57.9 43.103
-37.925 67.9 44.525125
-36 36.9 26.1658
-31.5 55.9 30.6866
16.5 71.9 54.7852
-38.3 84.4 26.252
-48.5 25.4 28.14
55 73.4 51.3402
-15.8 58.4 20.247
-29.5 60.4 28.9266
-64 44.9 46.079
-62 65.9 42.5508
-48.5 48.4 43.9334
-35.65 67.4 20.5945
8 44.4 28.1896
-17.8 56.4 27.243
-67.5 20.9 35.6296
69.5 19.4 73.1972
70.8 52.9 65.157
-54 49.4 44.1126
-44.55 23.9 28.16175
11 46.9 60.7928
-52 44.4 43.894
-54.5 71.4 37.0994
67.05 72.4 51.21325
-7.5 47.9 29.1114
-45.5 27.4 29.928
-41.05 78.4 33.752
-24 58.9 29.7038
-66.35 59.9 44.609
17.4 28.4 33.30066667
22.475 45.9 57.01283333
-59.5 59.9 44.7678
-69.5 37.9 43.3618
-46.35 46.9 37.5505
-59.5 13.9 27.7696
61.025 69.9 53.0285
-32.5 53.9 31.196
52.5 66.9 51.2176
-64.5 82.4 29.2724
-21.1 48.9 31.566
-41.5 45.4 40.809
53.4 67.4 50.12133333
-47 21.9 31.9972
67 42.9 63.7956
56.35 72.4 47.167
-1.9 65.9 33.924
-21.1 49.9 31.57666667
15.5 34.9 36.1382
-49 66.9 32.299
38.5 45.4 50.8002
65.5 54.9 58.6508
-44 56.9 36.5982
-50.5 43.9 43.845
10.45 49.9 60.2485
51 71.4 43.9282
-59.5 22.4 36.9844
-63 51.9 46.1196
-49.35 67.4 32.0235
66.925 70.9 53.04
-51 77.4 32.0408
-44.15 84.4 9.213
20.525 45.9 57.54408333
-42.2 84.4 14.91
-51 52.4 43.9534
5.7 62.9 60.992
-49.55 43.9 43.2605
31.5 23.4 35.6608
62.5 36.4 53.9122
62 67.9 53.7038
-20.8 60.9 27.914
-69 41.9 43.4802
53.6 66.4 50.401
-68.5 14.4 28.419
64.85 73.9 47.1385
-51.8 10.9 17.508
46.5 43.4 56.1378
46 14.4 40.8308
24.5 29.4 33.9304
64 36.4 61.769
52.5 52.9 55.821
-50.5 37.9 42.6464
65 52.9 57.5608
-38.85 83.9 25.704
-54.5 73.4 35.595
-56.5 43.9 45.9816
-45.5 58.4 36.2486
69.55 10.9 72.112
68.5 74.9 51.2776
-39.6 51.4 32.96633333
-6.5 56.9 27.813
-51.5 69.4 38.36
44.3 13.9 40.502
32.55 43.4 53.60225
-60 66.4 42.3532
-65 31.9 42.6842
-33.15 44.9 29.4875
-27 46.9 31.5824
-63 54.9 45.819
-40.15 33.4 31.1295
-48.7 43.9 38.735
30 24.9 35.268
-37.15 74.4 44.637
-58.5 41.9 45.7238
-50.5 52.9 43.8956
-66.5 60.4 44.4336
15.975 32.4 37.00758333
17.5 45.4 58.834
-52.5 70.4 37.7642
-36 35.9 25.5524
50.575 10.9 43.987125
70 18.9 73.4258
58.5 69.9 52.4054
-30 49.9 31.5948
14.025 72.4 56.03166667
-20 45.9 27.242
60 57.9 57.1002
-61 87.9 19.5632
-13.7 57.4 20.711
-11 54.4 28.5382
-70 86.9 21.6394
-57.5 33.9 43.5362
-67 75.4 36.5924
37.3 43.4 51.739
-67 72.4 38.863
-60 27.4 40.4144
-35.6 41.9 27.957
-35.55 54.9 28.13325
-11 59.4 25.653
4 37.4 33.243
65.1 48.4 58.21266667
-64.5 84.4 26.3474
51.4 66.9 49.739
63.65 4.9 63.599
-49.6 23.9 34.777
-36.8 83.9 31.73
23 46.4 56.5698
-67.5 58.9 43.8962
-50.5 58.4 42.9634
-43.65 49.9 35.606
41.025 43.4 53.00075
54 43.9 61.4484
-18 55.9 28.099
40 71.9 48.1306
-35.55 43.9 28.36925
-38.5 59.9 31.712
-64.5 66.4 42.336
-33 51.9 31.4836
-61.5 73.9 37.8356
15.925 31.4 37.00825
-64 56.4 45.527
66.7 75.9 48.58
-63 86.9 21.836
-41.1 23.4 24.79433333
-55 35.9 42.048
2.3 50.9 44.014
-53 16.4 27.5738
-54 81.4 27.2818
-16.3 56.9 24.478
39.5 70.9 48.8
-60.5 70.4 40.2074
43 69.4 48.6234
-62 20.4 35.2582
-49 61.4 35.1772
-46.5 43.4 37.1884
-53 18.9 30.4872
18.475 73.9 54.01041667
65.45 32.9 61.1195
48.5 68.4 48.7942
-43.3 54.4 32.419
34.95 23.4 35.097
-47.55 79.4 17.41625
-50 54.9 43.6644
-57.5 60.9 44.4632
71 51.9 67.145
64.65 22.4 69.811
-62 84.4 26.3842
-0.45 38.4 29.134
53.8 70.9 49.187
-17.5 60.9 24.9962
68.6 23.9 78.364
55.5 36.4 52.3434
-7.5 59.4 26.6656
-12.7 48.4 25.981
-61 61.4 44.3354
51.4 38.9 58.62866667
-54.5 81.9 26.489
-64.5 78.9 33.3568
-24 56.4 30.5478
64.45 64.4 52.1625
-38.05 73.9 45.12225
-60.5 81.9 29.98
46.6 44.9 48.32
28 27.4 34.2394
63.6 59.4 54.184
55.4 71.9 48.43766667
46.2 10.9 40.719
-48 23.4 31.34
63 72.4 49.676
-50.075 81.9 26.470875
2 49.9 41.091
-34 51.9 31.4826
65.5 1.9 60.884
63.5 44.4 62.7034
-47.5 68.4 31.207
-31.5 56.4 30.5408
25 73.9 51.7208
50.7 72.4 47.097
-60.5 84.9 25.6576
-62.5 18.9 33.808
-63.5 78.4 33.8714
64.3 31.9 58.24
-59.5 10.4 21.7026
58.5 44.4 62.253
-43.5 23.4 26.7468
4.9 49.9 55.662
38.5 48.4 53.476
8.5 51.4 61.2204
-55.8 67.9 41.423
47.5 67.9 48.6526
-36.4 79.4 43.61166667
-28.5 54.4 31.0308
-51.5 75.9 33.4884
-55.7 60.4 44.276
-17.85 62.4 24.901
-64.5 69.4 40.7518
-67.5 56.4 43.8388
-66.5 34.9 43.7824
-60.5 56.4 45.5394
-37.45 83.4 31.339
-47.5 23.9 30.709
-40 45.4 41.125
-63.5 47.9 46.205
25.4 71.4 49.07
-14.9 57.9 18.101
-61.5 56.9 45.4572
66.7 12.4 68.518
62.8 7.4 62.417
62.5 39.9 62.3058
69.2 24.9 72.75
-63.5 17.4 32.2484
62.5 50.9 52.3008
-55.7 32.9 42.868
-56.05 83.4 27.706
-52 36.4 42.175
-48 23.9 30.717
-69 44.4 43.5518
-64.5 31.9 42.6958
29 44.4 54.7894
-49 58.9 36.0542
-60 86.9 21.8544
-56 59.9 44.7138
-36.7 84.4 30.913
-23.8 42.9 30.975
-65.5 46.9 46.166
-63.5 39.9 45.339
-20.6 58.4 28.905
-49 73.4 32.5808
60 71.4 51.3196
-65.5 65.9 42.541
-57 41.9 45.7174
-18.5 52.4 28.906
-60.5 58.4 45.1514
-13.4 59.4 23.73833333
40.9 41.9 51.32933333
-67.5 32.4 42.898
-68 64.9 42.973
-52.5 27.9 38.265
-50.5 42.9 43.7046
47 49.9 60.693
-8.5 47.4 29.0692
24.1 25.9 33.225
-14.6 59.4 22.33133333
16.5 46.9 58.2706
36.5 20.4 36.9774
-43.15 21.9 24.603
-66.5 26.4 39.7858
-51.5 54.9 43.688
-38.85 76.4 44.2295
-60.5 49.9 46.2272
-13.3 46.4 27.931
45.5 43.9 55.3462
45 51.4 53.5628
-47.5 73.4 32.6898
65.4 38.9 62.287
-57.5 23.9 38.1818
-37.3 66.9 21.876
-39.15 84.4 23.76
43.5 45.9 42.3222
-55.5 21.9 33.881
-43.5 50.9 36.8558
-55.45 53.9 43.86
50.1 71.4 43.086
3.65 63.9 60.133
-65 34.4 43.703
3.2 49.9 47.15
43.5 51.9 53.6538
-1.1 66.4 36.762
-49.8 31.4 40.012
-41 41.9 34.7812
-53.5 22.9 34.2512
-39.3 79.4 37.25
-1 50.9 29.1064
-55.45 81.4 27.2675
-47.5 39.4 36.2864
-32 48.9 31.617
16.2 71.9 54.969
15.5 32.9 37.0774
56 39.4 61.1504
-61.5 41.9 45.6986
-49.35 51.4 37.5555
-68.5 65.9 42.5606
-40.425 47.4 41.9305
-36.9 57.9 27.46266667
-41.5 61.4 45.9202
-51.5 70.4 37.7462
-51.5 23.4 35.1194
-49.35 42.9 37.1485
37 41.9 48.7918
-49 33.4 33.9334
-40.9 59.4 42.75966667
-62.5 69.9 40.464
-47 56.9 36.592
-34.1 58.4 29.263
-23 54.9 30.9704
61.575 30.9 61.437625
-38 58.9 31.7478
-14 59.9 22.2498
-55.2 22.4 34.254
-43.525 51.4 37.03533333
63.15 8.4 62.3185
66.7 13.4 68.111
65.2 14.9 67.121
-8 51.4 29.0484
-60 27.9 40.6932
62.475 46.9 60.02975
72.85 41.9 63.9385
-51 23.9 35.4912
-60.5 74.4 37.4144
67.95 65.4 55.84425
53 72.9 48.5516
-63 48.9 46.2334
-39.3 46.4 37.513
31.2 21.4 34.301
58.2 74.9 52.994
-7.5 60.9 25.9556
69.1 24.9 71.657
63.15 7.4 63.2605
-51.5 84.4 22.2856
-65.5 59.9 44.7544
62 54.9 58.5314
-61 75.9 36.2034
-44 25.4 28.101
67.1 65.9 54.776
-44.5 49.9 37.6048
-36.9 78.9 43.74033333
-36.85 59.9 26.6115
-64 63.4 43.6022
-17.35 58.9 24.6965
64.55 60.9 51.7525
51.9 7.4 44.78533333
-65 53.4 45.9672
-34.55 49.9 30.756
-55.4 70.9 37.406
-45 70.9 29.222
-1.5 58.4 27.0368
-49.6 80.4 27.838
59.9 3.9 52.27633333
44 68.4 48.4642
24.075 73.4 52.0335
-54 62.9 41.5844
-52.5 71.4 37.0848
63.2 38.9 60.331
51.2 42.4 59.376
54.5 52.4 56.206
-55.7 81.4 29.602
-56.5 70.4 40.1936
2.5 43.4 28.3414
-48 46.4 43.463
70.6 24.9 87.954
64.8 38.9 61.443
-52.5 57.9 43.0938
18.4 71.9 53.62366667
2 44.4 28.3558
-20.65 58.9 28.672
19.925 73.9 53.49025
6.85 64.4 60.1075
-59 69.9 40.4726
67.35 47.4 60.925
-42.7 83.9 14.403
-55.4 83.9 22.37066667
-5 55.9 28.1888
-50.8 85.4 20.221
-10 58.4 27.2468
-54.5 30.9 39.9464
-53.5 53.4 43.8546
-23.5 50.9 31.5312
1 45.9 28.6412
-69.5 58.4 43.786
-19 53.4 28.6656
-51.5 31.9 40.4256
-57 38.9 45.119
-51.975 65.4 40.50891667
55.5 65.4 51.7226
-60.5 11.4 23.72
-52 46.4 44.0392
48.9 44.9 55.796
-59.5 76.4 35.7876
60.075 46.4 60.505375
-50.5 80.9 27.9372
-69 28.9 41.2252
-59.5 28.4 40.9954
-49.5 71.9 30.111
-66 71.9 39.2264
35.3 19.4 36.489
-61 49.9 46.2218
-40.15 49.9 30.184
-32 58.4 29.9368
-37 64.9 23.213
42.975 70.4 48.50508333
-44.5 44.4 37.3466
-34.5 82.9 42.1338
-59.5 36.4 44.4238
-9.5 42.9 28.3972
-60 74.4 37.4398
-52.5 37.9 42.606
-69.5 24.4 38.4528
-52.5 56.4 43.4068
-33.05 82.9 41.68675
27 73.9 51.0866
-63.5 69.4 40.7498
-43.5 44.4 37.2532
6.4 57.9 60.72266667
-60 58.4 45.1284
53.35 38.9 58.782
-36 66.4 21.8504
62.5 70.4 52.9562
-69 26.4 39.7902
53.3 56.9 53.023
60.5 31.4 62.1408
-59.5 47.4 46.2278
-55.8 19.9 34.484
64.65 38.4 61.2715
-12 56.9 26.7726
-48.5 73.4 32.6452
64.4 61.4 48.778
-60.5 82.9 28.6586
-63 15.4 29.8164
-22 45.9 31.4896
-40.8 51.9 31.197
-61 71.4 39.5818
-32.5 60.4 27.3304
-21.5 44.9 31.3394
-56 17.9 32.7092
-57 79.4 32.8562
-63 43.9 45.957
-49.65 61.4 41.724
54 54.4 56.8386
-51.925 77.9 31.50175
-1.5 44.9 28.7964
-67.5 60.9 43.9076
21.575 27.4 33.44
-17 51.4 28.145
-36.6 80.9 42.31333333
-40.15 31.4 30.255
4.3 50.9 55.457
-58.5 37.4 44.7264
-53 60.9 42.2676
66.05 4.9 66.67975
-54 78.4 31.0004
-64.5 41.4 45.588
42 68.9 48.4724
-56.5 87.4 20.6558
56.5 53.4 57.2256
50.6 50.4 63.29
-12.15 48.9 27.3065
-65 45.4 46.1032
71.7 50.4 76.724
64.7 50.9 56.247
-31 50.4 31.6092
-52.5 76.9 32.5494
72.6 49.4 88.988
-13.3 57.4 22.402
48.5 52.4 53.6604
54.3 73.9 53.016
-67.5 14.9 29.126
26 25.9 34.5934
25.475 72.9 51.5105
-11 44.4 28.7782
-54 22.9 34.2488
-43.5 43.4 37.2052
21.425 45.4 57.497625
66.85 10.9 69.378
42.8 53.4 51.144
-65 75.4 36.5828
-49 76.9 32.2512
56 45.9 60.3224
-65.5 37.9 44.7788
28.5 72.4 50.591
-15 49.9 27.185
-44 34.9 34.589
3.4 49.9 48.16
7.35 64.9 59.687
-68 38.4 43.4086
-63.5 23.4 37.7174
6.3 49.4 60.487
-49.5 77.4 31.5708
-58 36.4 44.4296
70 45.9 63.0698
56.7 4.9 48.616
6.075 50.9 61.468125
53.7 67.9 51.816
29 24.4 35.1368
18.7 32.4 34.525
-36.05 77.4 43.99325
57 58.4 55.7252
-46.6 21.9 31.842
-66 67.4 41.8146
-37.95 84.4 27.275
-52 42.9 43.72
5.5 33.9 36.6664
-8.5 42.4 28.1382
-63.5 63.9 43.4266
-66.5 77.9 34.3278
-44 44.9 37.3852
-59 86.9 21.8222
-54 44.9 43.9422
52.9 48.9 62.542
-37 69.4 17.33
67.3 23.9 71.267
37.35 17.4 36.8485
-55 68.9 38.6676
-1.525 59.4 26.52708333
59.5 39.4 61.468
-38.2 66.4 44.573
-41.3 23.9 25.845
-43.2 84.4 11.983
-55.45 52.9 44.00175
-63 52.4 46.0798
51.2 41.4 59.525
55.4 74.4 54.63533333
64.4 50.9 55.351
-32 47.4 31.5958
-55.45 46.9 44.13075
-58.5 35.9 44.243
31 71.9 49.963
-41 44.4 34.807
23.6 26.4 33.69866667
-51.5 48.4 44.114
-59.5 48.9 46.263
-55.7 19.9 33.918
-50 19.4 31.3278
-26.5 49.4 31.6088
70.1 24.9 82.526
63.15 71.4 51.2985
-33 51.4 31.5456
-67 76.4 35.7428
10.425 48.4 60.994
40.3 43.9 52.507
-55.7 81.9 29.164
-38.5 45.9 32.8914
-37.1 84.4 29.752
18 31.9 36.6254
-16 49.9 27.2264
-69.5 16.9 31.6688
20.5 73.4 53.2884
-69.5 30.4 41.9858
26.975 24.9 34.53033333
-39.575 41.9 33.40025
-59.5 19.9 34.8178
-62.5 12.9 26.2188
-63.5 84.4 26.3822
17 32.4 36.864
-45.5 52.4 37.4034
-64 16.9 31.6738
-59 28.9 41.2478
-42.5 29.9 31.7672
-44.075 53.9 36.941375
-30 50.4 31.5784
55.5 40.4 61.781
69.5 54.4 59.493
-58 32.9 43.1432
-58.5 64.4 43.2094
-23 59.4 29.554
-8 60.4 26.3142
67.5 61.4 55.8802
-36.9 32.4 24.81766667
65.6 51.4 57.829
-23.5 49.9 31.5852
4.7 50.9 57.794
50.025 52.4 53.52083333
52.7 44.9 55.089
56.5 51.9 56.2224
59.575 44.4 62.49625
-40.875 78.9 33.838
32 22.4 35.7832
-2.1 66.4 32.731
49.975 66.4 48.00516667
72.5 51.4 85.639
58.5 55.9 57.7724
23 46.9 55.9838
-63.5 66.4 42.3488
-38.55 79.9 38.8105
-36.9 74.4 44.38233333
52 51.4 55.226
-4.5 58.9 26.9456
63.85 6.9 64.9045
37 19.4 37.28
-32.1 84.4 40.87633333
-53 40.9 43.3686
68 25.4 70.4144
47 68.9 48.6924
61.5 39.9 62.2622
-18 48.4 28.12
-45.85 79.4 21.178
34 42.4 48.8382
-26.5 57.4 30.2718
-60.5 78.4 33.8922
70.7 55.9 66.711
-40.35 79.9 34.179
-6 52.4 28.914
-4.5 59.4 26.6408
-50.5 80.4 28.5994
-18 44.9 28.6978
-20.3 55.9 25.738
-65.5 88.4 18.0754
-60.5 28.4 41.0016
4.5 59.4 61.9292
-53 73.9 35.238
-14 45.4 28.1256
-38.7 31.9 27.633
-67.15 43.4 43.7185
12.1 48.4 59.893
55 44.9 61.1194
73 49.9 91.355
56.5 38.9 60.6074
65.6 47.9 59.20333333
13 32.4 37.3166
-45.5 55.9 36.8576
-45.05 21.4 26.20375
-65 25.4 39.1528
57.425 55.9 57.537
-15.05 48.4 19.762
-64.5 57.9 45.273
60.5 64.4 51.4384
-55 77.9 31.5006
-57 31.4 42.5038
41 68.4 47.902
55.1 6.4 48.15033333
37.6 43.4 49.88766667
12.5 70.9 56.8312
44.7 50.4 53.006
-39.875 79.9 35.39375
-47 76.4 32.7078
-36.475 46.4 29.01983333
-24 53.4 31.2434
58.5 75.9 55.7848
-44 33.4 33.8908
-55.45 50.9 44.12775
-40.6 51.4 33.73966667
-70 87.4 20.502
-39.8 60.4 37.58
14 32.9 37.2324
-45.5 51.4 37.5094
-65.5 53.4 45.9546
0.025 38.4 29.495
56.6 6.4 49.656
-58 86.4 22.8874
-57.5 76.4 35.7912
-3 49.9 29.093
-13.1 57.4 23.249
53.2 49.4 62.394
-55.6 73.4 36.659
-39.85 49.4 33.4185
52.9 68.9 51.095
-41.15 22.9 24.164
55.85 6.9 48.912
-56.05 73.9 37.75275
-55 46.9 44.0988
-59 75.9 36.241
-37.6 54.9 32.22766667
62.475 71.9 51.01408333
-41.7 83.9 17.346
-37.8 54.4 32.286
65.525 37.4 65.50475
-39 83.4 26.755
22.5 43.4 56.3348
7.425 67.4 59.490375
33.45 43.9 55.1265
-36.8 74.9 44.314
65.5 23.4 70.3046
68 16.4 71.2824
-38.95 79.9 37.781
28.95 74.4 50.11375
65 56.9 58.277
-62.5 79.4 32.8516
62 56.9 58.2218
-1 52.4 28.9848
-67.5 25.4 39.1486
-35.05 84.4 35.694
55.5 67.9 52.3888
56.025 36.4 52.50583333
66.4 6.9 68.323
2.4 50.4 44.072
5.5 51.4 61.3534
-17 57.9 23.219
-63.5 56.9 45.4626
-55.7 61.9 43.824
48.65 49.4 63.3665
38.05 44.9 50.692
53.7 38.9 59.774
-45 57.4 36.5142
65.85 64.9 53.9255
-49.375 65.9 33.02875
-33.075 83.9 41.516625
44.5 68.4 48.4692
-35.5 50.4 28.8112
-55.45 23.9 35.55725
65.35 60.4 53.8925
-63 47.4 46.2234
1.3 65.9 47.255
45.5 69.9 50.6834
-57.5 83.9 27.1556
58 55.4 57.7424
3 37.4 32.9426
-41.55 23.9 26.24375
-69.5 39.9 43.401
-62.5 87.9 19.5514
54.875 9.9 45.5215
-16 62.4 24.487
-29 47.9 31.6558
-48.5 58.9 36.0446
-12.35 48.4 26.913
-2.5 57.9 27.455
-68 24.4 38.4472
55.65 73.9 51.393
51.075 10.4 44.489875
-2.5 55.4 28.2582
21.95 30.9 33.625
-16.5 57.4 20.478
18 72.9 54.3028
20.5 72.9 53.2464
-36.55 84.4 31.348
-51 51.4 44.0392
-34.3 51.4 31.443
42.7 45.4 43.007
-57.5 67.9 41.6104
18 73.9 54.1718
-18 44.4 28.8374
-7.025 58.9 26.99316667
-59.5 52.4 46.0966
-13.2 59.4 23.977
-62.5 50.9 46.1688
65.6 35.9 65.175
-68 76.4 35.7054
-65 38.4 44.9344
-28 57.4 30.2702
21.2 31.4 33.474
-37.3 42.4 29.358
-67.5 82.4 29.2364
54.5 40.9 61.7558
-58.5 49.9 46.231
-4 59.9 26.2584
68.8 23.9 79.452
-49.8 83.9 22.828
45.65 50.4 52.4475
-39.6 45.4 37.869
68.55 73.9 51.67425
-32 50.4 31.5976
-62 54.4 45.8656
-66 64.4 43.179
32.5 22.9 35.9068
-37.4 43.4 29.894
19.5 44.9 58.1228
-41.85 78.4 31.74
67.8 9.4 71.472
72.2 50.9 81.659
6.5 51.4 61.7692
-68 66.4 42.3406
-59.5 20.9 35.7162
-11.4 45.9 28.73566667
5.6 49.9 59.241
-68.5 27.9 40.6708
-52.15 85.4 20.1735
44.7 45.4 43.24
-55 82.4 25.7608
-59.5 32.9 43.121
-60 64.4 43.1954
-54.5 57.9 43.0962
-50 67.9 39.245
-52 45.9 44.026
-50.5 78.9 30.419
68.5 52.4 59.8408
-38.65 33.4 28.1745
-38.8 67.4 28.582
-37 57.4 27.6528
-68 34.9 43.313
-65 18.4 33.2796
-55.6 61.9 43.164
54 72.4 47.0584
-42.1 63.4 45.25333333
53.55 39.9 60.83275
66.575 46.4 60.920875
-53.5 19.9 31.8722
68.075 60.4 56.513375
-59.5 19.4 34.3454
-46.375 79.4 19.89475
-68 83.9 27.005
-60 63.4 43.6078
-56.5 50.4 46.2458
-49.65 40.4 42.99
-58 71.4 39.5906
-12.5 41.9 27.43866667
2.9 50.4 46.758
-65 37.9 44.795
26.025 27.9 34.51583333
-44 29.4 31.4016
-57.5 43.9 45.972
-44.15 53.4 37.1245
67.05 12.4 69.327
-62 14.9 29.1818
-63 82.4 29.3058
-38.85 79.4 38.359
-55.45 31.9 40.42925
2 45.9 28.334
-62.075 40.9 45.510625
-21.525 42.9 30.94141667
-38.5 55.4 32.6276
-10.5 58.4 26.857
-58 79.4 32.8708
-62.5 26.9 40.1168
-43.5 26.4 28.9818
64.8 72.9 47.261
52.1 46.9 56.333
-70 83.9 26.994
38.5 42.9 50.4048
22 44.9 57.3464
-20.35 59.4 27.872
-58 82.9 28.672
-58.5 9.4 19.3022
10.55 34.4 37.14275
16 29.9 36.5768
-18.8 57.9 26.401
64 51.9 55.6086
-37.85 79.4 40.8145
-47 71.4 28.756
-13.2 58.4 22.897
-60 88.4 18.2232
-45.5 30.9 32.4442
-0.95 38.9 28.6875
-55.975 65.9 42.48825
-54.5 42.4 43.6166
-67.5 42.9 43.6524
-29.5 49.4 31.6356
-30 57.4 30.2426
-46 25.9 28.126
-67 68.4 41.3266
-64.5 81.9 29.9116
-66.925 57.9 44.34775
44.5 70.4 48.2534
-54.5 14.4 25.858
45.35 49.9 54.8135
-57 51.4 46.17
-31 57.9 30.0538
-37.95 79.9 40.35
-50.5 21.4 33.3568
42.025 52.9 52.49716667
-40.05 50.9 33.282
64 61.4 45.557
63.3 59.4 55.107
-30.5 47.4 31.5868
-15.8 59.4 22.268
63.8 23.9 65.159
5.85 56.9 61.623
-63 61.9 44.1486
-43.7 83.9 11.445
-26.5 49.9 31.6084
-56.5 11.4 23.779
-49 56.4 36.7642
-10.7 60.4 25.504
-38.9 51.9 32.967
40.5 70.9 48.6378
57 51.9 56.2836
-31.5 47.4 31.6112
1.8 50.4 40.86
-58 80.9 31.234
-52.5 16.9 28.481
-48.5 30.4 32.1154
-17.15 62.4 24.643
-53.5 80.4 28.5976
-26 47.9 31.6066
45.5 15.4 39.9192
62.9 66.4 53.19966667
-31.85 85.4 40.493
-39.2 58.9 44.745
16 33.9 36.614
-18.3 46.4 28.154
70.7 53.4 64.684
-69 39.9 43.4292
-67 67.4 41.808
63.4 35.9 56.006
-48.8 43.9 38.993
58.925 68.4 53.03575
6.3 38.4 31.172
-60.5 85.9 23.9016
-37.7 48.4 32.743
-67.525 38.9 43.51558333
-61.5 10.9 22.6912
-65.5 61.9 44.1192
51.35 8.4 44.9375
-68 19.9 34.7368
43.5 52.9 53.1084
-24.5 59.9 29.2838
-13.7 48.4 23.312
64.65 25.9 68.3485
-44 76.9 22.145
-41.6 25.9 28.36733333
-20.8 48.4 26.618
-51 54.9 43.6842
-57 56.9 45.484
61 58.9 56.8354
62.4 57.9 57.59833333
-49.65 67.4 39.1805
-52.5 39.9 43.1342
-59.5 62.9 43.7838
72 52.9 79.931
-45.5 76.4 22.8888
36.9 45.9 52.891
57.6 6.4 50.667
-49.4 49.9 43.287
24 46.4 56.3852
-45.5 54.9 37.0568
71.3 55.9 74.773
36 70.9 49.116
64.2 59.4 52.016
16.425 71.4 54.4255
-55.8 73.4 37.917
-45 26.4 28.9996
-0.05 54.4 28.646
62.05 60.9 45.8285
-64.5 65.9 42.5596
49 68.9 48.7646
36.5 44.4 49.2288
71.7 52.9 76.268
-11.5 46.9 28.1196
-37.45 73.9 44.3675
-33.9 83.4 41.74433333
31.5 46.4 54.8776
71.9 50.9 77.852
-66.5 10.4 21.5724
-19.95 47.4 27.748
-37.7 41.9 31.034
58.9 73.9 48.76
46.075 68.4 48.49375
-65.975 45.4 45.97883333
-33.5 44.9 29.323
63.4 64.4 51.21033333
-53 36.9 42.3352
5.5 65.4 60.3544
60 51.4 55.819
61.625 8.4 55.93725
67 47.4 60.8388
63 57.4 57.5614
-7.5 45.4 28.9022
-61.5 57.4 45.3494
52.35 73.9 51.3105
48.5 67.4 48.6054
64.5 54.9 58.4694
-67 84.4 26.3086
65.35 6.4 67.3555
65.5 53.9 58.3386
-43 28.4 30.757
-43.2 78.9 28.009
23.5 28.9 34.5104
-41.4 34.4 33.737
17.15 46.4 58.486
-54 70.4 37.7596
66.4 15.4 69.84766667
-64.5 81.4 30.5626
-46 39.4 36.3118
44.475 14.4 40.50983333
30 73.4 50.3002
43.3 42.9 54.502
-47 45.9 42.934
64.5 34.4 62.3726
22.2 43.9 57.006
70.4 54.9 61.551
-45 25.9 28.116
15.5 73.4 55.1848
29.5 43.9 53.3492
-59.5 80.9 31.2096
-67.5 16.4 31.0746
37.6 42.9 50.14366667
-0.15 52.9 28.859
-51.5 42.9 43.717
-36 57.4 27.6074
-65.5 72.4 38.8826
-57 9.4 19.3518
-67 62.9 43.7708
-69 71.4 39.5742
-48 62.4 34.7264
-54 75.9 33.5018
-66.5 20.9 35.6424
-44.5 76.4 22.867
-51.5 71.9 36.7386
-16.95 48.4 27.363
61 58.4 56.8428
-46.5 34.4 34.3894
71.7 51.9 75.911
-23.5 53.4 31.245
-61.5 80.9 31.1846
-57 11.4 23.7648
-30 53.4 31.2316
-18.5 45.9 28.9394
67.575 36.4 65.992625
-53 82.9 24.964
-59.5 57.4 45.3486
-37 60.9 28.211
62.3 43.9 63.106
-40.6 49.4 34.154
-67.5 79.9 32.263
67 47.9 60.6114
-58 81.4 30.625
-48.35 43.9 37.8345
5.5 45.4 28.1956
40.075 16.9 38.469
-36 79.4 43.6704
-47 46.4 43.3912
19.95 31.9 36.14775
-58.5 79.4 32.859
-35.3 83.9 36.123
72.5 42.4 63.8748
-30 46.4 31.4678
48.4 50.9 57.967
-28 58.4 29.9046
-69.5 71.4 39.5556
-44 59.4 36.1668
63.525 41.9 63.00466667
65.1 32.4 58.498
-18.975 43.4 29.51058333
-6 48.9 29.0786
-70 63.4 43.5648
58.2 34.4 50.619
-59 56.9 45.4746
3.15 66.4 53.8065
53.05 42.4 59.64975
3.4 63.9 59.846
-61 11.9 24.5926
-52.05 10.9 17.65675
65.1 73.9 47.86966667
-40 60.4 40.139
-43 30.9 32.427
-62 39.9 45.3042
-65 24.9 38.8106
-41.7 21.4 23.323
59 61.4 43.3232
-34.5 44.4 28.7456
-68 36.9 43.3702
50 69.9 47.859
4.05 64.4 60.19025
47 53.9 53.394
-62 45.9 46.1514
19.3 29.4 36.171
-39.7 63.9 45.845
-53.5 22.4 33.87
-36 58.4 27.2694
-66 38.4 44.8252
65.8 9.9 67.395
-37.65 55.4 32.232
-52 70.9 37.434
4.4 50.4 54.747
-67 26.4 39.7848
-68 84.9 25.5386
-41.1 33.4 32.91516667
54.5 44.4 61.3208
47 45.4 49.759
-4.65 48.4 29.109
-54.5 26.4 37.3356
7.5 34.4 36.5936
-46 54.4 37.1278
-59 62.9 43.7774
-28.5 59.9 29.1652
47.1 42.9 56.61266667
-8 53.9 28.624
-16.5 42.9 28.4502
-37.4 33.9 26.79733333
72.4 50.9 84.218
-68.5 19.9 34.7606
-59 39.4 45.2066
-44.65 68.9 30.814
-39.35 79.9 36.75
-65 50.9 46.1634
-41.85 84.4 15.931
-43.15 76.9 21.2845
54.5 71.9 47.0876
-59 20.4 35.2718
5.425 60.9 61.3815
-60.5 14.9 29.2002
-37.35 49.9 29.036
37.925 20.9 36.48225
2.4 66.4 50.825
67.5 37.4 66.3064
72.1 55.4 84.751
-56.075 82.9 28.454125
-47.5 65.9 32.877
69.575 14.9 71.01425
-49.35 23.4 31.2215
63.1 39.4 61.21033333
-55.45 31.4 40.23475
50.95 41.4 60.29075
-64 58.4 45.1594
-61.5 77.4 34.8638
-56.1 87.9 19.296
-35.7 68.4 19.371
-15.96405389 25.83154375 36.12479378
6.889016851 55.3254209 27.32529547
-64.41955468 23.47444134 37.76866846
-69.5947052 38.68035291 42.86015024
-39.10181929 46.49134 33.76894715
-55.63783059 34.05779024 41.42080333
5.039262049 49.72676132 57.71154075
-7.48962552 59.65852443 26.37868766
26.70516228 69.24818138 42.54654303
32.23542623 25.20538588 34.20176099
29.97151858 41.79405878 45.99251327
26.79768873 37.36181696 22.30414403
-15.20213838 62.99431608 24.08505114
-12.8780897 82.3595565 -8.884858623
-73.17671814 37.74838469 42.70894696
19.62328853 32.80712869 35.7600066
-56.50196393 46.70886399 46.21536566
-2.655252023 31.82133555 22.31925754
40.55264123 32.09111748 22.60070936
-12.60867716 83.17853016 -6.790198975
4.593948659 57.59334945 18.91513031
18.61542904 33.32219312 35.79960847
-10.37248629 48.42866623 29.10675704
31.13424809 22.60556078 35.45922516
37.0875221 17.85766166 37.00121044
-50.33509321 17.01977415 28.44019065
23.33872625 36.36777707 22.85004766
-70.85252977 54.64242816 44.25311477
2.969026966 45.27627507 28.19786209
-68.19370834 72.11100468 39.0638661
60.18751727 69.30156517 53.02146341
4.706974795 50.65103409 58.698559
-70.04820538 41.67463453 43.14667453
-49.45756376 49.32692442 37.78616227
-67.73016974 12.09349309 24.85235145
-52.13027189 44.82385816 43.95761724
8.341547225 70.23759348 57.8693429
-54.19037245 74.24159191 34.96649735
-63.41414455 33.51380792 43.3920962
16.66666902 30.75347727 36.8575727
36.58364864 33.6176592 23.11633245
-74.89263282 55.34907597 42.64694378
43.33680532 43.86337627 54.38137194
-20.79346267 52.6484783 28.72056863
-55.99669215 6.930528559 6.80383268
-44.38164671 38.84634056 36.1249618
53.27993269 7.233910194 46.53530551
52.22899241 66.5861959 51.10134442
-75.62648249 9.563083243 14.94658208
9.025818756 32.90002021 36.70265527
-11.9594735 81.79039862 -8.279484887
28.66095498 29.95411672 20.54318494
0.4825345303 59.0334652 55.42106086
-53.27397207 40.38528594 43.26214525
-26.50695866 88.22125325 36.37357895
-11.26618575 56.06583515 28.01897348
-40.46208815 48.31249544 30.36697927
-60.9531298 42.30337418 45.76660252
-2.758738823 61.18402978 48.55378957
-43.18144 46.62325363 38.84224733
35.2151073 43.99583581 54.39254147
-50.91788245 25.36253112 36.58205779
-55.69556632 67.76016602 40.57497897
-68.35723087 17.94750249 32.76065516
58.77809431 40.90398306 62.48485946
58.7644041 8.60707836 52.85521026
-53.05266033 9.269745058 8.220260523
-74.99839671 35.33856446 41.89809939
4.817993941 60.44112916 61.55963675
40.7213841 68.90888611 14.67914367
-15.21732977 36.70184099 37.97814536
-10.73318624 61.21557435 26.40180504
42.17159101 33.52443678 23.13287213
-36.47455133 49.36201294 29.07624854
-3.337150627 66.39219421 40.64368919
-55.91072085 7.283606794 2.688646938
4.835268556 58.87915449 21.42246889
51.20657051 41.27924719 59.57245407
-70.31618641 72.69277117 38.54170622
-10.95157126 83.73683703 -7.760811895
-49.51636599 17.10233178 27.325665
-36.69537148 57.00664402 27.77351673
-6.01303131 37.55950053 26.26649063
-72.35895871 60.68558912 44.30666772
-67.6260905 14.30429285 28.30113083
-41.19175101 24.12153767 25.82701852
-59.11194721 60.44549754 44.5675415
57.25178217 42.93944572 62.4533638
-58.82863308 86.39117986 22.99585527
52.81329617 8.275509934 46.18592766
-14.8680451 28.28530936 36.56388092
39.65700564 34.5584357 23.26228645
-65.77022199 68.36410117 41.31199729
6.37131408 56.95972805 60.89156778
2.510347051 64.92914879 56.51263789
5.627156022 38.24904492 33.52182634
27.82056642 70.34084117 47.12935989
21.88659494 69.30018956 42.86172387
14.89197584 70.85484964 55.28121738
39.66101688 18.75325482 37.76701236
-59.00994787 39.80318789 45.28680865
-56.33607984 12.80140873 26.11471077
-56.90005602 8.559298187 17.20136918
-10.07808704 50.93990647 29.03150832
-78.72831828 12.6048451 15.18697465
-55.76001917 81.21530419 30.05622749
55.27379433 54.96785721 57.20873854
-16.25802473 51.15831052 27.6746657
1.478054874 60.12186779 25.11631003
2.413860247 60.62280459 24.94924721
42.60704659 67.04570135 18.46882288
-66.35142957 28.7344415 41.16821824
47.01984673 10.753819 41.82731577
5.742610463 60.17588079 23.67601115
5.225246598 57.89805914 61.87057957
31.87028853 22.07894585 35.60554737
-33.00205756 47.66470199 30.63772494
-10.60306107 70.1336077 21.90284421
-7.957178992 61.7431145 37.22825716
-63.35797499 63.6184352 43.50335369
-74.84476655 35.08096321 39.03258314
58.42473766 58.57879896 55.47587359
-46.51671968 70.91874393 31.29064324
-49.67150214 67.06196026 35.87815327
-49.5204484 41.02585535 40.936769
-64.75852064 68.34931477 41.29071108
-49.51370797 22.72447866 26.15222363
3.921547018 60.97432611 61.36749612
29.03804632 48.10662765 54.46259935
-2.48084471 68.81820862 41.45064538
-49.66915919 86.57101189 13.33482477
43.06153711 15.0090526 39.79745879
-78.00322147 5.077504089 15.0319489
61.82742977 8.45618188 59.84943138
-49.53276162 58.98795427 42.18103631
-40.08662957 52.27803414 42.52692057
-67.25864718 81.9181028 29.91712658
-49.51798714 81.07614861 18.82797046
-70.30005736 60.63810935 44.46520271
-8.543885776 50.40711229 29.09734974
-21.71164805 58.61790587 29.51789111
31.11570478 40.70135062 40.97934041
42.2994124 68.05664952 18.44494816
-9.624004149 80.54941889 -5.971955996
-44.21870205 29.21538121 31.36427365
-56.09738649 22.40986939 36.8866273
-49.5198194 66.83677816 38.46330499
27.33381184 27.81036651 34.02921641
21.05869407 27.92811615 33.85899521
-51.37536627 60.50730678 42.37460194
-47.81506048 42.86823205 42.53045184
-47.61229243 44.27259563 43.66882818
-62.71679718 68.31050023 41.35192971
-1.990305709 29.47038643 20.35077665
-47.58584927 45.26368868 43.81173578
-49.36640404 24.60948093 27.54821364
-77.70214859 5.626069489 14.43302578
-49.49977685 35.93705288 35.91430913
-12.97746157 38.03162649 27.94050773
-12.26300886 42.01106766 28.22112706
56.69605823 40.96313293 62.1952613
-37.74965818 61.20930753 25.80011707
-18.2778636 27.26647924 37.38819568
-39.96239345 69.5947024 45.41113653
-56.82270046 45.2153414 46.13450961
-45.53067571 18.35100359 23.72335747
-26.38012916 50.48441446 31.6014487
-48.52874162 37.44942821 35.652293
-16.67465228 49.73793713 26.76292716
-72.47147431 28.84829954 41.01360029
6.821165118 54.39516967 61.44383948
25.42047413 33.79769245 23.15525936
-47.16380615 51.31850479 37.53557411
35.60342739 44.99594724 48.53510306
26.8118188 69.97581084 45.73263154
-68.95502581 63.71625228 43.4352128
-67.76075635 44.12205751 43.43217657
-50.43774221 68.31090691 39.00551875
-49.53418718 57.55635887 41.32038956
-39.61257779 59.25483236 31.36443945
44.6798624 13.90682895 40.6309694
-41.17976727 58.56537443 45.49368589
29.90795838 32.25738091 22.70060701
-72.73453042 19.88489269 34.41135697
-49.5200069 27.80603099 31.25610747
-50.69537137 15.69486509 26.40948607
29.21299238 42.18334932 47.4446412
32.43680512 41.78936026 45.57691204
-68.27975925 55.58794239 44.43953462
-50.8394893 17.0264336 28.45907425
-49.45663266 26.46563719 29.32436805
-49.62431741 11.61909274 15.25335901
-65.78347682 34.52409094 43.76912131
28.99146467 34.23300479 23.24746769
52.92958908 47.89191301 46.57226098
-37.16496334 58.04973455 27.40739628
26.39417028 25.27772289 34.47598285
-42.67739048 53.9765644 40.78313779
-64.37482498 28.22625955 40.86397386
-74.15046459 10.20700831 18.84322688
-49.31821448 25.54004849 28.38959813
-14.11973233 75.84615392 20.35113839
-34.31904061 50.62378041 31.49758514
-74.64408402 54.24404361 43.77428456
-51.08631741 33.99182795 41.31854074
36.4738665 46.28651096 50.86722263
-11.75470841 41.50022267 28.22781172
-19.30733346 26.81279063 37.02462926
-74.96464411 33.83966805 41.81202334
44.68571553 69.4335134 48.6193571
-49.49367372 62.10542216 40.94358476
5.880557799 55.88074141 61.85754071
-17.74188254 52.09359187 28.75349972
-48.86923009 33.10124028 33.75439671
5.063761655 67.19285217 58.88017034
-7.010889492 48.36827787 29.16671244
60.20185711 55.8154914 58.08225303
-63.28949901 35.45979572 44.08444266
-51.70547647 12.18667517 20.71462012
-5.548990999 60.39111896 40.81067099
-70.72529785 33.23073666 42.24583371
-69.95971939 86.02803264 23.50765735
-48.5249786 27.39655055 29.99325629
-42.7596633 41.31972841 36.23595303
31.63624735 24.10675847 35.41641522
60.19397541 69.8253013 52.76696144
-55.74006336 82.40789255 27.86126717
-68.39836632 48.61674829 43.81588988
-9.672896552 81.07629824 -6.252391093
-49.69823925 58.24044088 39.23699264
-51.3877899 10.61222549 15.62483193
-55.65452301 64.10006065 42.26984014
-39.79711487 62.18777157 32.31177839
-73.50708153 12.70992186 24.62472904
-55.88197469 89.47720808 -1.737680108
12.70877724 33.27885134 37.42918576
57.86867356 52.9137006 57.25346497
-61.29032069 63.06432406 43.71492527
7.176522244 63.60269142 59.36797411
-61.90963861 77.9343391 34.35698944
-68.4879595 12.98462816 26.30520224
6.610036971 65.55042387 60.17187019
10.2845251 68.69238647 57.95677346
-53.32982346 71.54419426 36.98254178
-46.71234642 10.76776408 1.274307201
-37.78421009 69.03919264 28.13794035
-60.42383313 19.67111486 34.54532223
-43.15384625 52.5863708 39.53245971
23.84299797 25.92707313 33.25101584
-71.46915281 82.5620415 29.07142553
47.19470077 42.09554224 56.68803345
12.74604641 45.05213942 28.93525618
51.46046606 8.890258074 45.13411614
-43.55260019 54.52382628 40.42367099
41.01276742 31.60457355 22.33447656
-0.9618412915 51.11862458 42.69822012
-62.26889266 24.38510579 38.44014867
-70.20446125 66.86425761 42.08898938
51.17522947 52.66592154 55.57970391
-74.34118788 11.09703722 20.20077583
-65.25497731 36.46458113 44.44827059
-49.71688874 86.69420004 11.91386891
-39.38933542 49.24656642 30.97230312
-17.56457235 80.2950328 21.8020254
54.68909304 51.59862512 55.64626306
-64.96558835 70.46263228 40.10383152
46.8336923 40.72144587 55.22847044
42.13168336 70.48741089 48.52529694
50.40119476 9.98302846 44.30373103
-53.13494477 23.98314567 35.53785508
5.611827741 59.73963691 22.9385485
-55.78914072 22.47737831 36.04414995
-15.31288084 45.07617256 28.18062672
-22.87944233 74.63181969 6.98533902
24.47484475 26.33356052 34.23835768
25.90707506 73.50837027 51.50977161
-77.54707254 5.671732068 13.87336721
-55.82187348 89.96242447 10.09803676
-53.62176889 47.34397466 44.11990755
-79.28335033 12.6866031 14.34371591
13.5506756 44.4251088 59.36740607
38.94843494 48.515654 53.65099318
-25.40906956 75.16453136 1.28364731
-12.59746528 82.6706584 -6.723232025
-66.2786848 42.4129048 45.51070632
46.25907904 66.9712115 47.77030008
47.18215469 68.38389033 48.67478001
10.70539656 45.09041239 28.88011982
44.44284738 43.27400668 55.18563462
13.19973165 54.11094905 28.5669983
-62.66966971 37.40377114 44.70571569
-35.59298965 51.37218269 28.87917538
-41.65053133 25.91582793 28.39164386
-70.40165272 77.0013906 35.15353281
46.97972589 31.06572246 21.62527755
-39.33469066 43.54595797 33.04565578
-0.5741875344 31.28523761 22.31916121
18.86043306 28.34988782 35.20862458
-39.76060129 44.2449018 36.66631181
-6.706997749 61.48413983 39.99620752
57.85110713 50.65926025 54.29479905
29.98691407 33.72420051 23.13755998
-14.09135145 31.48816013 22.54129284
-41.81220931 51.18917866 32.18966287
-2.970966064 37.51162982 26.24206869
-59.00927798 14.16985588 28.21849547
-49.78027201 9.862343484 12.37016387
-54.34234866 26.359954 37.30959319
-70.41960432 78.08921174 34.18201629
-53.75884487 56.95522586 43.27780724
-1.9575705 46.79031472 29.04123945
-20.36446281 56.62324643 29.10987373
-30.20757707 76.67497289 -9.508916905
-55.77275893 12.1074831 22.40959481
30.94489606 25.71437383 34.36581021
-15.81454216 52.61469285 28.16326463
-54.26973316 40.89424744 43.37028714
-55.73068367 73.55003546 37.46169539
-62.64205819 37.89205879 44.85869157
-13.33002451 31.02312322 21.96795003
-37.86156391 65.48170838 22.83743769
-14.77801899 42.54306481 28.35158434
-79.35067304 9.434710878 17.14704801
-55.71754297 50.27468357 45.45762197
-70.38470717 62.18745825 43.97529582
51.66572679 65.64289735 50.50597871
34.96989699 19.94362818 36.49619591
26.6483905 46.51697117 55.89229129
-44.78135785 29.22665138 31.34074312
-49.77778312 65.60892465 35.28639666
-18.27768863 54.12495991 28.58064203
-49.63497542 67.59530642 35.50635673
6.845127072 50.36535408 61.60718303
62.38782138 51.85963269 56.97474418
46.91468679 42.11290346 56.53989207
15.66003882 44.51710215 28.76400259
6.520299011 56.98368538 60.58504121
-20.91382874 44.52507234 29.81969402
-55.98124934 19.15788499 33.82940067
-61.6580222 12.00379636 24.73562157
53.22027121 65.67639084 49.82070078
-58.91118551 11.10041894 23.04556565
-42.27542224 22.6907584 25.16328832
-69.25577567 60.10467207 44.63194031
8.047576967 44.69001641 28.20842963
-6.23719839 40.53720858 26.70167778
-32.27135811 84.91941243 40.84321777
-27.41407801 87.68106819 36.99079361
-13.55418579 73.29756757 20.69593128
-56.6235769 61.43358668 44.27581624
-51.90154812 85.78256633 17.0867659
-37.40868108 43.31107676 29.57838528
-64.55550685 22.53858912 37.00536905
58.08327724 4.759493394 51.03755212
-43.58423724 45.29501508 36.85830258
55.49902925 67.42775905 52.36153151
-57.11668101 36.82596477 44.5276846
48.21830226 41.54775904 57.0252528
25.91597491 26.27559767 34.63640064
-60.22632342 68.79531228 41.07557019
-48.967296 74.18375564 34.64183082
61.50701232 8.518702109 59.18475636
-68.74063622 58.58594517 44.68426781
-43.93417067 37.86396186 35.79051253
34.58007805 70.10263275 48.60557789
-22.62260702 75.55624923 7.858188179
-61.81748981 77.38563945 34.88248126
-49.7124414 25.85134194 36.50434105
-13.15980728 60.01623244 23.30748924
-78.41617941 6.947791079 16.70560995
-12.66223146 53.01342586 28.75995735
-49.67650409 87.80214969 10.6975607
25.29776803 31.85072632 22.47390023
-72.94590185 14.86358647 28.64960324
-11.04160587 62.3822448 30.39698381
-53.19636235 55.41261717 43.59834334
4.023952354 35.53079419 36.24980122
42.13255635 68.98032244 48.54059038
-12.46852175 82.5065026 -4.84218167
-73.21397174 28.4232953 40.2623174
-47.39522151 25.05891043 27.78163253
-66.38929545 44.9054479 45.72974562
12.99707561 35.89228053 36.1688164
37.83243729 44.28610818 50.52370677
16.68231589 32.25223243 36.93586778
36.60145906 34.11089091 23.21378307
-73.19453499 11.79560711 23.52587545
-49.83712896 12.57554956 10.0173347
-59.22935561 24.3385104 38.40140396
-13.9937159 31.69334625 25.96736435
-69.34407097 62.68506981 43.82684297
-70.8446936 55.1406913 44.2940591
-57.44800375 22.42217383 37.00135916
-3.126558806 52.11814404 37.33453343
-23.46529661 75.73355773 5.960567311
-49.35366306 25.08049203 27.90458422
3.678184211 64.63737827 59.67700992
12.04331498 35.87783926 36.51834912
34.91129012 46.56106231 53.80466421
-9.846737319 40.11468269 20.64174464
-49.78945782 24.85432333 30.61322463
-65.5009536 43.37190743 45.88286778
43.90169488 16.58744786 38.79399402
16.36768327 49.98184791 29.2833434
-43.82456428 47.32599854 36.63355971
-74.74240647 6.363061553 5.313041987
-72.0133822 80.33215185 31.75483671
37.25753209 68.89696689 15.4780881
13.66553044 55.1281917 28.3225543
-65.59166533 31.61226007 42.57535395
-68.50396777 42.64342568 43.25902624
-51.64683659 10.61900995 15.59547472
46.15260888 69.93910413 48.29698637
-20.86248458 45.5036043 30.1036711
-11.08255123 35.63678286 32.07366666
32.06076273 47.51674555 54.90729031
5.020055527 65.0573681 60.37219961
6.920197718 32.97106729 36.27944332
-25.29203309 21.75522231 32.78484101
39.46917971 50.50961171 53.71093132
-49.4873219 54.4946441 41.89422629
-9.690707106 77.87376363 -4.031480469
-11.04685142 37.32364533 29.96445023
58.46711598 9.110591531 52.89339743
-49.5712881 57.07285806 41.11365425
-21.76087084 22.01240283 34.95252932
-13.77681563 30.74148585 36.97589214
24.42308821 43.56076065 55.67467352
29.0612501 45.03207086 55.19002809
-7.031864237 70.32769636 30.62195585
43.48774199 31.09277197 21.97720937
-66.26886532 24.92284306 38.78716166
60.72376891 7.308042589 55.90842162
24.85417419 30.01607337 20.54992283
29.01991987 25.69429628 34.96598967
-0.961999661 60.29580007 52.69571911
54.72718415 49.73733235 48.11303804
-49.49634717 27.69498464 32.54070271
-56.15773982 21.00799956 35.69214227
-17.78928148 33.31916716 36.9506921
-22.31575775 48.90975977 31.62616685
-44.97251871 54.7445341 38.1323202
-11.68547548 83.28272017 -8.175772989
-12.37362262 63.15993881 27.44960813
-64.58553626 85.31742241 24.90540985
-66.45171507 84.76546903 25.84213828
-20.81317249 51.7035444 28.04052786
41.10223158 45.6671765 51.34761128
11.1826576 53.63511339 28.64887312
-55.90157054 9.834825797 19.68361969
-52.95308191 13.07711473 22.26895569
-16.29049357 55.11808376 28.3260472
-51.24056049 40.35066424 43.27627532
58.81092961 4.664992211 51.9958233
-18.22238897 28.74514365 37.69212346
32.73776865 40.65122883 41.25185346
53.83907835 45.18314576 60.36424252
-55.87060369 86.32948562 17.32129127
11.25922238 46.85319882 60.75056369
-53.76098717 80.29270433 28.73193058
-57.27061199 28.11150394 40.84132475
-63.4281625 45.3262852 46.10629414
-63.08967714 31.08683173 42.37112459
60.96110228 7.640982256 57.8247953
-54.65228292 82.67684085 24.53167219
24.09507329 25.89902746 33.52851824
45.81479503 12.82544761 41.31471306
-77.81396639 10.17764132 14.0561504
-69.03658015 11.25251073 23.21094672
-78.40915133 7.446461857 16.74197623
-47.52484982 86.37467847 9.378432175
-39.85745528 56.70715134 43.50655892
-64.48867265 42.85461263 45.86831441
-24.42800602 54.00631016 31.13091773
-20.93338537 43.98316577 30.2876297
-10.39552284 71.03334899 23.07169385
30.91893244 32.24296138 22.67520056
-46.75173857 86.4059131 8.868400772
-49.50544631 36.97578163 41.33699246
-69.84154743 68.43561568 41.25823084
39.41648424 42.6989599 51.16647254
-40.76764408 63.16144326 32.8470545
-61.89836272 82.94415239 28.63703728
59.73425033 43.88095643 62.70348057
-49.77462707 87.17598593 12.15859863
-54.30508142 15.27908446 26.09792352
5.954752588 58.00378889 19.71757728
56.98545607 7.979685422 48.82441899
-55.71742749 58.91140915 44.24908108
-66.13382036 31.13575065 42.38377439
-69.99685331 13.00244021 26.38618213
62.0259261 48.92312094 50.3464439
-36.07725504 42.88057068 28.49546199
25.22182609 44.98660482 56.45003252
37.06876328 42.3651382 49.66280183
-54.28047724 86.7662536 11.94772228
-3.954121981 60.03260325 44.66843243
28.13887406 38.43303597 21.26616141
-66.32563326 53.43578436 45.74412875
-60.96674926 26.73488868 40.04703214
-44.7186853 47.75891079 37.60515368
36.37333019 19.37768734 36.97176632
-57.80132665 33.9102533 43.54611176
5.557953357 66.61479064 59.66984432
-75.21072244 11.21355386 19.01399088
-63.42722864 52.8590545 46.05984593
-62.2408196 76.84918972 35.36407193
-65.94372158 23.49995775 37.76312312
39.8786704 44.62334554 52.04522156
-46.3054697 19.75293232 25.07642646
-64.9938992 14.71761678 28.82763129
-69.91659526 17.51333578 32.27151837
-28.05433784 55.60777833 30.7113923
-75.60095131 6.629360599 8.212019319
31.73071372 72.53959952 49.99208471
-13.96439645 31.62900062 26.70874415
18.81688097 46.50304778 57.54744023
-72.89950135 10.48702197 21.18069899
-61.76557018 24.37898791 38.4151828
27.1499228 70.80968942 47.64116401
-42.42954313 46.54130039 39.65022924
-10.03404751 68.53814877 22.83565897
-20.67439739 46.80960315 26.56009508
-36.02393605 53.40021093 28.72509117
58.33489988 68.33966626 52.88791151
52.17181115 62.83843187 42.17925979
-31.04979105 83.94299628 40.29192708
-79.53205784 12.3098453 12.93818429
45.01994595 42.72845711 55.58207522
52.08133903 45.02249246 56.73779331
-40.31826827 69.5807984 28.16048634
-41.99002784 21.7901403 23.9106121
-52.81431023 71.53192687 37.02663913
-40.96516852 32.12368911 31.94189004
56.54683632 68.39919151 52.53789336
-78.48849287 5.452843014 16.59392605
-55.14627295 47.87187718 44.10900279
-53.36400556 14.79770842 25.67997814
-21.43974451 20.59154406 33.90573131
-33.43542392 55.34221649 28.9937921
-5.113623693 33.25486043 23.62660359
-68.22476751 59.07571448 44.72692979
-71.25574147 16.16187282 30.73574707
44.4110512 30.62035815 21.45861218
-9.62500566 53.47021238 28.7046697
-46.68954104 71.93602057 31.1675934
42.08341435 71.01397577 48.24839349
-56.16095483 68.72680264 41.09558984
-61.61119714 9.865541433 20.41591034
-24.13968934 57.62416217 29.86375358
-60.75573134 75.7398427 36.30568486
62.71974921 48.77135106 51.97598353
-31.45499374 48.0555179 31.63245537
-10.48613181 80.58786563 -6.25386092
-41.12545679 21.87435029 22.76816204
48.09656581 11.15889532 42.74619849
-46.13694676 48.78499193 37.61740769
-51.59348641 79.68822656 29.5091535
-8.883079961 70.7164124 26.46249372
20.2773876 31.96829944 33.71972601
-47.90317876 47.93484276 41.99675057
4.587219877 50.25878304 57.44829228
-19.23908744 45.09926105 28.66134988
-72.97986908 17.14402251 31.31632009
60.2689947 51.45990307 56.19252926
-43.52580424 36.39909562 35.23794822
28.48051913 26.22082952 34.78311531
-75.89301362 6.175361315 7.711431406
-13.65959983 31.68678946 37.63304608
-59.94681075 73.57070456 38.02579421
37.96773546 68.55683022 13.4645616
6.268864109 49.45338977 60.65054514
31.61695675 22.5850635 35.60511711
-69.53937628 41.66573889 43.15290483
2.83066155 37.34205264 32.93088115
-29.52615064 77.00689456 -7.6654169
-11.68672183 81.51746229 6.490228009
-46.65228165 26.43856784 29.10380228
-44.28485563 18.51545239 21.57673007
-37.41156279 43.64130146 31.57208676
-70.78236884 58.1225483 44.62644657
-57.40930071 8.568403468 17.19278445
-25.62610014 88.27246932 35.6110403
6.40239098 57.92664892 20.52770708
54.87119065 58.1842431 54.90720767
-15.06167354 59.25262164 20.88145584
-9.145327146 69.2924968 25.56243438
26.19542941 37.92005864 21.76516242
48.79745493 65.21145495 38.58154904
-65.52905913 42.38165296 45.73054246
-44.24870562 41.79257579 36.85740583
-68.70659111 75.88523587 36.13413149
-68.71538563 32.20353696 42.13373338
2.419147834 38.47973545 31.45558842
-49.97140063 11.93618388 5.811882089
-0.6864049982 67.35458925 46.44591569
-49.8791301 85.55526741 19.33973076
-65.89477209 77.46015884 34.79496801
46.4806205 50.94387021 53.15925874
-46.42765072 32.10406858 33.21138786
-49.47640094 41.10274743 40.03574934
-74.04233943 45.22272272 43.50606896
-41.04997974 24.17391173 25.19198555
-65.20957614 13.82198099 27.61267409
-4.061904383 68.66754229 37.67456607
-35.47200418 46.84076417 29.02034807
42.23576267 67.63609673 17.51252923
16.06457742 36.12225389 27.08748446
-58.89979445 14.61684394 28.83529368
-14.01057965 33.12270756 38.51071767
-42.70165343 54.39190823 41.7927916
-48.36017063 64.731838 33.54462935
-10.18792705 60.58697444 27.76842664
44.81358299 30.1628672 20.86609489
40.92609682 49.01137076 53.34584009
-25.86518119 47.96135209 31.65958997
-46.80752431 25.51244653 28.23121817
-49.50835639 59.52741501 41.74056895
40.84933453 16.13829582 38.76354125
37.61502609 16.8844693 36.54485757
-55.79504291 88.32501163 17.4479227
30.37695788 26.25530253 34.02126512
-66.66130047 75.8446219 36.21555897
24.66251079 30.91896543 21.75832617
28.7721385 69.92184503 40.15861949
18.31379504 35.42034015 23.15358973
-58.02343814 86.98277976 21.80121712
41.37882168 30.66326732 21.53875559
-9.451811499 52.95496371 28.82638227
-49.4449304 9.803754875 1.333067986
-19.51205747 50.08567356 29.09390038
-24.8846275 54.013648 31.1328152
-51.26645131 9.440814941 11.72035716
9.57812225 50.68787478 28.2087189
-35.06392023 45.82870074 29.03801317
-70.83505652 55.63804599 44.34520596
-54.34730799 14.79816924 25.86215026
-63.46899659 43.33801949 45.88875735
-69.60748408 71.07640323 39.69060861
19.38062858 45.96423297 57.86824483
54.19851044 49.75623843 47.99438379
-65.17011344 44.35811731 46.0244123
-46.76516701 20.66714236 26.20455808
31.30079178 41.1102012 42.02444833
37.47708384 20.37045376 36.88979854
-22.38220503 53.46322272 31.21868563
-16.0615931 25.71876151 25.79937025
-20.82945977 26.8868334 36.45536534
-13.44073493 61.4415912 24.29438186
28.41231626 69.20469237 42.72583071
0.7689702454 65.91334074 51.24615054
-36.21662763 76.16666608 44.15097729
-1.244179628 30.84843657 21.69099702
-4.599618749 51.96255396 33.5950166
11.67959348 31.7958085 37.36177816
-61.36413605 16.92153415 31.68393735
-0.6768639193 60.7532934 53.15706656
-69.04319058 17.04131799 31.75784087
-76.04160912 7.887515243 11.17040124
23.39665477 75.04248417 51.65933282
35.48684036 47.52294186 54.18198544
45.08803235 70.48277896 48.01527421
-48.77128419 63.68700988 34.09887684
12.70960303 45.86047115 60.34499283
-76.35092154 5.606798498 8.572408559
-53.61486582 49.85157259 44.14176866
-60.01697379 39.81561581 45.33458217
-58.72652729 8.991746755 18.3561507
-72.3456708 18.47942219 33.17300352
-23.91752446 43.95226045 31.21549458
-16.96166883 25.33700837 36.22933173
-14.46576048 69.73967488 15.559261
-50.3647039 60.49304449 42.34742966
43.5583648 66.76404042 21.55712691
-41.73679741 49.79315686 30.90139828
-6.621359632 68.71308441 31.80734153
11.14814424 43.10598682 28.52578182
10.11455494 43.13181439 28.42306814
53.87229822 68.50944505 51.76804005
-53.80897164 31.61527312 40.29334553
-38.93677212 62.61620341 44.65408113
61.55751204 51.02626404 55.15415853
28.84128745 32.27511836 22.69811831
-74.71131188 36.79950664 42.36359033
63.84305414 44.32991911 62.53466149
-55.83546372 17.01926602 29.49504311
-20.28788145 56.08239964 29.54717259
34.10468899 21.99249287 36.18304321
-55.79584125 87.02364805 20.90197195
-55.7832523 87.84162579 17.23052878
32.6548421 44.52588361 54.5542586
-72.03981316 43.69703277 43.35557492
-49.85221283 10.51807973 4.771617263
13.66861043 69.89764243 54.97319859
-16.21915215 24.45357766 34.68995572
-39.05956204 68.51131416 28.68251539
4.890095529 56.38702997 61.99570156
29.29636346 73.03090854 50.57949724
-3.071604642 59.835204 46.79168599
9.414990637 69.16245196 58.49237657
-44.63544004 18.4693008 22.17935006
-46.6811174 71.63443709 34.66698864
-48.05739439 36.95722393 35.44962976
-49.51989802 40.07070138 40.3715066
-17.29835529 80.25985346 22.15964376
-49.49612785 86.41512424 9.28484274
-67.2769115 29.71016735 41.67105729
-57.7214292 13.25911593 26.89214702
-69.24034773 59.09245754 44.72624133
-50.68932494 56.39128986 43.41237115
-68.36634882 85.99238492 23.61761676
23.42680474 30.45873939 33.16326526
-49.47878344 39.59730119 40.03259975
-49.7161986 10.60479466 9.565670001
-62.8313079 23.91881644 38.13379074
32.34160684 31.26420989 22.11266783
53.97950765 39.61270907 60.91010661
-55.87038361 90.02313279 -2.250742335
-49.69882068 48.80440006 43.89801661
59.63766081 39.45929162 61.61204475
28.04285546 44.54801761 55.17684463
1.368688316 58.83423158 57.56491567
-29.93689675 47.53084737 31.60706269
-41.04448165 59.5511186 45.67793023
-11.65623367 80.00875262 18.17197765
-55.76574388 19.82394766 31.88280154
7.34286451 49.8598375 61.55402597
-26.42525254 44.98280026 31.38375696
36.44255748 18.38788993 36.79557317
-45.25760171 41.8059471 36.89458443
40.44618684 46.50605827 41.73436954
49.79517629 9.036518337 43.75380079
50.09786035 9.007721407 44.03037295
1.530664781 45.73594625 28.96260887
31.97465609 36.22369654 22.875716
4.33625694 55.87646058 27.2418922
-56.19120137 88.25229374 18.36763493
30.58437084 46.51373025 55.17929898
-12.62573671 41.54315926 27.89548279
61.51891423 47.92666785 50.35740711
51.44666221 44.84709228 58.89500359
-71.98089107 82.57240464 29.04871424
-51.69608728 27.26839644 37.91235441
-68.54250963 83.07461042 28.3896638
-66.66258288 18.8404205 33.72511364
-49.54199681 76.22808482 28.4992863
-72.97526828 26.01480408 39.04311431
-35.49043861 77.20122439 43.65586245
-71.1337041 38.21113516 42.77402769
48.11693171 45.33740735 53.83806717
-11.73834784 55.55401843 28.22391886
-74.08962438 42.73549505 43.25543592
-59.66322868 8.212046606 15.93283146
3.40473844 50.10528041 53.62912763
20.36216275 43.95580003 57.68985216
44.01185383 71.0323063 47.66783426
-11.32401174 81.92436396 19.17737011
54.15248336 70.62475087 50.46515673
-51.43340368 19.34155131 30.82996613
-51.71110444 56.41116009 43.37656247
57.71985982 8.836654912 50.38931857
-40.04067016 67.55099071 45.84439774
-41.49784828 20.05333567 20.67228214
-10.73270762 56.57149941 27.8737571
53.04452827 49.34058106 47.21306817
23.54925183 70.03580021 45.65842221
-47.34572646 57.43686512 36.46356591
-55.68999329 41.89655894 43.65885772
15.65961964 35.63275121 32.84721756
-11.38182789 36.44529232 34.39825478
-46.24975861 29.24881492 31.36348534
-63.66567323 37.91285494 44.81248417
43.39160234 13.0071351 39.66766921
50.00331762 39.80967837 59.38182623
55.55464004 67.92670058 52.38548972
-54.03724438 73.1599158 35.84089888
23.83945658 28.84304428 34.36081549
-49.56771276 26.12522486 33.2973071
41.59177994 68.71760143 16.73372641
54.07740413 6.198868791 46.74545716
-43.34013805 32.53548835 33.440986
-49.49071987 51.65650701 39.88219534
-50.75158515 39.84956763 43.17347907
53.67323566 38.48355779 56.5966033
-65.18152825 37.9341785 44.85402738
-67.25600983 76.39905507 35.71934947
-45.1400794 46.26660827 37.52933004
51.90469401 64.28826084 48.70609244
-54.13623574 13.58149337 22.46603575
-30.80081916 76.80078345 -10.85628314
40.30168268 48.12369588 52.11641553
-39.97753033 44.15610683 37.73847842
53.61774069 42.05850068 61.7197683
40.69765677 67.6124299 12.25395536
-49.58529496 37.41916516 42.03174151
1.407867578 67.44459286 50.82833942
57.54873097 45.0215174 61.53308967
-49.5100311 23.68415917 32.49077432
-65.67247415 59.5293422 44.80079145
12.07044529 72.29812172 56.54466745
-74.87381248 34.89739233 41.16914746
38.47479828 68.54882556 13.46078558
-63.74130416 36.4443582 44.39436663
-35.68623257 55.96394031 28.0322517
-64.58157819 39.88818183 45.36268028
-65.75665811 29.68662508 41.65443177
-3.703694645 42.86051152 28.38113205
-43.65878071 55.53226441 40.39051625
45.46307919 66.19967035 27.74550711
18.18775957 74.44228368 53.79362516
-72.3876802 15.28243553 29.50748251
-50.63221739 54.35361838 43.74969458
-73.69475624 6.330141071 5.495413814
-50.81117631 31.08751794 40.02157439
-38.17218255 71.06551987 45.47533264
-40.40387335 44.09195633 32.7378487
-49.18547721 51.34720408 37.58795663
-26.36845073 48.97187531 31.67795726
11.68919791 34.30204376 37.39894305
-6.508500118 50.3714948 29.12266334
-46.93910229 28.31955959 30.62913953
4.716891669 58.46143046 20.46764847
-12.67404488 79.15273724 22.47669137
-29.95362731 76.62120382 -8.933155967
45.61817498 67.44983575 48.1629457
52.4233599 51.67610573 55.17890042
3.478091323 46.77390884 28.19507743
49.42095865 64.33850353 36.9432182
-49.88702309 15.23088655 25.81488973
-72.25612859 36.27291073 42.18184077
-56.20759021 77.28134092 35.02341322
-68.86559028 13.42955804 27.03878901
11.06250442 41.64253897 28.0509618
-68.03150712 19.79205823 34.59215241
46.3109318 45.30021007 48.78765365
-11.66877517 69.87164714 19.32047027
20.34326413 32.4620625 33.80184186
28.90745074 26.75546235 34.32192969
60.44632212 57.37226632 57.44337521
-44.74812761 50.40873209 35.98291318
-18.71752395 50.58354008 28.98942094
-55.95003472 22.88731086 37.14267825
32.25934471 30.80349024 21.65008466
-5.693514816 52.21702556 30.84953657
-67.04390901 86.57084392 22.47688607
-56.39015619 78.37786671 33.9822397
-30.90234944 85.46199127 40.11012929
-39.55210424 64.5390205 45.75652832
34.55171711 33.14931826 23.11403648
-8.028797289 49.89284313 29.14249005
-14.47571671 62.97476928 24.17343171
-14.47786664 30.57959071 21.50905854
-18.16739386 57.33039741 26.30839524
-73.47653743 17.57466623 32.23845215
-11.97597441 69.92749957 18.73067914
-67.82043541 79.69707054 32.50106316
-28.45083833 52.54671467 31.36222294
-55.75149232 79.70435483 30.11488164
-46.61542098 87.46547026 8.195573056
-16.52805648 56.24235225 26.97330435
-21.28155806 21.51618041 34.79499262
35.55669969 33.13792108 23.05468702
3.641735059 60.99158541 61.22049925
58.91354533 57.9786755 56.52268537
34.32116123 70.1093517 48.57695644
-66.99881983 17.01355698 31.69023718
-49.87259804 86.41919727 9.309355114
52.56688556 10.40867361 44.77616224
-49.53999286 34.7546846 37.99449107
-5.542822595 44.85554842 28.87858413
-32.98503708 50.59507875 31.57637247
-73.86629158 11.48358542 21.44951769
-30.47644363 85.4825366 39.79040124
-36.96791143 69.14775319 26.72201692
-1.21222639 60.34769109 52.14104993
-24.51960787 56.06138197 30.59817677
-76.68538918 10.48222472 16.13173886
54.62590666 68.47618413 52.01043946
-54.541159 29.22575967 39.03898949
-46.533126 31.15284256 32.62074652
-0.1833649927 67.80127419 46.99117923
-16.05156064 46.75721806 26.28661669
0.6373806433 51.66323373 47.72430111
-79.79876902 10.31163195 12.87920307
-34.9057233 52.85425766 29.02302445
5.997565653 42.4201544 25.81638912
-73.42088559 52.69025493 44.0986036
-58.78881018 37.83144003 44.82756091
-34.1018816 55.38107071 28.66984136
58.2006897 58.01615689 56.2235198
39.91760591 17.19150633 38.36807305
-15.93162724 71.89997248 19.89370457
-7.916244741 30.47837274 21.43272999
-69.06956375 65.28067797 42.77598328
-38.86017278 53.59869081 32.78852072
56.20298846 64.05269819 50.62096544
-76.65333452 11.29704211 18.31991301
-42.42996383 31.56748434 32.85142552
-50.11603266 64.6331113 40.85221644
-70.00341399 44.16312004 43.383181
-19.76611514 29.80973646 37.2812327
-63.91018381 47.330522 46.23813869
61.38655297 40.82706268 62.8803587
41.04926532 51.50987538 53.45109063
-36.98772784 48.36866954 29.05228773
-69.01144533 84.81780882 25.72269573
64.82005514 45.34945796 62.1660722
-42.68010376 46.06636596 39.38475713
-9.333181912 57.58851131 27.45392459
-46.62740778 47.17886247 38.87614205
-62.70657534 8.260563716 15.94999391
5.640558235 58.96849057 20.23181673
-58.85700708 70.35455689 40.19350649
-55.15772407 24.012404 35.58395912
-4.775064925 53.00593251 33.16945717
-2.046777259 60.52089409 50.28953661
-62.54838958 16.48398011 31.16258725
-49.83947618 85.50382756 14.1026781
-1.619429312 51.75433203 41.27051075
-56.20505766 85.7167665 12.84468186
-72.73712138 9.623679528 19.51875931
4.888787003 57.59328807 18.85961538
-39.08391899 71.0794356 45.48766238
42.62859109 32.53725377 22.85237851
-37.49771551 56.21538317 31.28542156
16.64664209 30.26136365 36.74756514
18.50482674 43.93876879 58.24176843
-42.76702209 25.45648051 28.11036453
-39.96695994 44.52910145 39.23310999
-39.36238228 42.78515433 36.0562602
-49.67687041 79.13597175 29.7279794
-55.79268702 85.44107529 21.79310747
52.06119116 70.18635388 50.12656993
48.31740109 45.28583811 54.3984899
31.53946356 34.68879881 23.29700025
-49.67755551 24.22132565 32.11354262
45.92324071 15.50482399 39.32307949
-61.92250704 33.00248463 43.21680493
57.31645349 56.96221262 56.97382161
11.54462916 56.71359484 27.80242874
49.81684575 45.52723009 57.13692388
9.694768829 45.10465721 28.90748829
-50.58376175 64.11905839 41.08227571
-17.21615526 42.05662945 28.63680739
-49.5780734 25.40523448 35.83081628
-18.15012934 73.63372088 17.67009178
56.66456784 40.47680195 62.02031076
31.42640793 48.59339353 54.18303174
-38.10490096 68.52530121 28.33808393
57.16289227 47.73869031 47.54366577
17.32014712 73.3799537 54.63810408
-62.90764704 51.84030281 46.13388994
-70.13239262 19.35850049 34.19921757
-60.93344088 16.91488568 31.67898153
-69.98195796 45.1567288 43.49783547
-4.842709615 39.47356365 27.13002538
28.80460835 73.03119136 50.6699947
57.60048547 51.5175597 56.03210968
-16.88044795 48.28554509 26.18267764
-46.68286952 28.31419458 30.64255531
-67.11710541 66.29132445 42.32459749
-52.0000593 14.35079781 24.78128884
-2.071232662 60.10534794 49.29152242
-8.700822679 76.81599482 -3.594186744
-20.83058137 30.90512738 36.42141723
-58.04374106 67.17879468 41.94513212
-0.5507392361 31.75084751 22.73619101
-0.8540542574 68.46836829 45.20160906
-56.59265816 11.92640526 24.66803861
-31.458836 50.06632019 31.59689114
-21.32176491 46.39282615 31.52180441
-54.65034956 24.00424211 35.58194893
-72.95336966 32.81178119 41.7076403
-46.66083012 74.16006134 34.47703469
-19.10547678 28.76938569 37.57911432
-43.38863316 32.05773524 33.16970012
-51.9411827 61.54938121 42.0386278
-13.20142009 63.32973512 25.63635427
53.36866942 40.15050045 60.61016314
41.38165384 16.61976679 38.89939935
-20.27950766 45.27720155 26.79410078
-71.70341119 78.66914174 33.52123603
-46.29951642 33.54608048 33.92694815
-9.377653923 40.05150285 21.28572549
-46.49393885 59.48683199 35.81017689
40.44117448 31.14291381 21.97612501
-71.00637872 44.67571536 43.4505128
-20.12109015 89.16929879 29.97679081
-46.21625992 25.96418318 28.70101265
2.831839832 57.21855855 23.60202209
-73.29626746 32.28431398 42.06961639
-49.49791809 51.20747777 39.26958623
-54.88875877 86.85009496 11.09043335
-13.5724456 31.58661066 32.95273219
-11.7424297 82.89688562 2.140780125
-49.7441611 87.71238938 11.75246668
13.9357125 70.33945482 55.62002092
16.39691113 36.47494613 22.92979682
-72.78026148 16.66500553 31.01239147
-49.68590713 76.8524508 27.10527556
-49.5219246 26.34990599 30.6803121
-53.37456138 83.25307972 23.42524249
-46.69759893 53.84218949 37.28309617
60.37533397 57.91029167 57.03776302
41.33245344 64.50377497 36.56715315
-55.06453802 68.91706085 38.67784006
-55.81625035 90.3560336 5.527661591
19.15930786 49.31697406 36.46930582
-56.74722556 34.37629477 43.76157984
-12.37852587 81.3509026 -8.924219566
-12.98009134 33.03557066 27.67246256
-54.14495749 51.88005804 44.00128435
-16.72529957 58.19538776 21.81858011
45.81700709 30.15225618 20.79791633
23.41298621 34.82366924 23.28109386
0.4421061814 51.71218243 47.19330873
-49.7144651 9.903481355 11.88005559
54.67416973 7.100490731 47.81824684
6.664473933 56.79001153 22.01936118
-49.60287654 62.25961994 39.17436974
12.94873987 69.81477316 56.0724893
-53.17981975 43.35108781 43.77484266
-36.48058659 48.86284135 29.04550672
3.470909337 57.74529177 23.19208486
-65.86836263 63.14366852 43.66689686
1.461812957 59.603067 25.31538483
2.419357713 60.11781542 24.98384078
-13.7622773 31.1801535 31.88074455
40.26707468 15.68201691 38.34475938
-6.054486496 79.98104216 -5.881193384
-10.61227454 52.468242 28.87126953
-49.48222843 39.425594 42.02655939
-55.85313186 7.707216873 3.586730458
-10.08617876 45.92182801 29.02015949
-45.26612604 51.80501191 37.35258623
-58.44678424 31.97920584 42.779649
-72.87177832 11.75668473 23.91616323
55.80049725 70.53676097 51.17232164
-49.50599703 70.4939849 36.79163012
-68.93094637 47.1309768 43.68545315
-41.79695995 50.62971452 32.85478191
-49.53658178 24.44754699 29.46051523
-49.51123657 36.89770998 36.41776779
-10.81004794 80.88366827 19.50722755
-54.16878365 44.35883656 43.91804282
-59.06344492 59.41659896 44.84921576
-72.43858418 75.43202837 36.28036752
35.65035454 71.03795286 49.19687328
57.5195015 7.381520047 49.83987282
-0.1228718489 59.66762795 54.00133211
41.41069448 18.78742658 37.03664748
-49.51729308 23.13605068 33.02836184
-41.3058484 50.32576314 30.46297213
-55.80936943 90.55078542 3.265527779
-11.7053248 31.92654815 22.8237761
-49.65074909 86.51216937 14.01440083
-74.88199498 34.39930389 41.1262411
-52.12550856 13.91186352 24.07404135
53.71943438 40.57488772 61.44331402
51.50833522 52.16911835 55.45679175
-39.92258457 42.06997014 38.63883372
50.85003735 68.29288815 49.03171262
-46.54435356 12.05912148 -2.095467971
-72.26711085 18.01191088 32.7585932
-60.68913464 68.27877859 41.3334902
-53.24691479 21.67721492 33.19493061
-13.33135244 50.59209914 27.86288578
60.57308545 55.80167365 58.17186415
-59.20565217 68.77600109 41.1051004
-24.42358293 43.95975847 31.22496047
-53.10591649 48.83818426 44.15557855
37.55882867 32.61686359 22.89504377
36.45429145 43.2087557 51.63993969
36.07829031 22.53981366 35.27960723
-8.927302736 83.70682748 -7.798479243
-53.95643674 13.08769081 22.33752666
-6.498414793 48.85975927 29.1901466
-37.60225331 67.7562113 19.6904589
59.47581542 54.83041448 58.00292357
-64.72511693 36.94729248 44.57019832
-51.53418679 21.15347598 33.12160605
-74.14203267 11.52356908 21.03794369
-65.70092036 37.94840256 44.78795537
11.20316863 44.58487834 28.82715896
-70.66897464 46.66304619 43.62234478
11.75296175 45.39482269 60.10637632
26.82108979 48.38250404 51.68281141
-49.79228483 25.13496472 33.18251494
-55.8100802 90.25380971 6.713174646
-49.47428574 31.10025671 33.7920985
-49.37886953 59.00277729 36.15296983
-78.00828805 6.537152247 15.5681768
-40.38235997 69.01180143 28.95140034
-76.23053486 7.38148966 11.2541039
-55.92272883 13.2685586 26.43950656
-38.1738987 67.62520696 44.62683663
-21.96356257 41.94885233 30.79332225
54.30091579 48.04260685 44.56137635
-55.87413631 12.84094194 25.5676849
-55.95470389 8.602758899 16.51656108
7.038645021 52.23664619 28.19366634
-33.42526635 45.24321639 29.69560084
-67.2444418 35.64604705 42.67653241
-48.58883759 86.9180563 9.100055452
28.22141539 74.58424724 50.23212959
55.69633808 48.73957656 47.85761014
-16.62886258 58.62417917 22.64910635
-7.719712809 41.44703442 28.07576191
-75.41619944 30.09927851 38.70618153
-53.74617596 41.38013837 43.45650604
14.0898341 34.83217354 36.61363466
37.48482454 49.51505529 53.98161474
-18.11165303 31.80169148 37.14821275
-69.03817556 41.15847529 43.119368
-55.3732802 75.34717386 34.0109644
-42.18162738 23.14566047 25.69120687
-37.58289781 43.1759581 31.18016569
-39.95115085 57.21853551 31.7612638
-67.22212562 56.05220189 44.67501533
-42.90714389 53.21191862 38.0502371
-62.0806957 75.76398464 36.27810634
-58.12639689 15.50369906 30.04582257
35.08447947 35.1372138 23.24205683
-69.34748665 9.146043088 18.59044696
-76.93412887 7.817403719 12.15451601
-8.904959736 58.62470914 26.99660106
-76.35691283 28.86894186 35.68830379
-64.69722357 30.14997613 41.900115
-70.68074341 78.09705169 34.14080519
-49.58750661 66.46781775 36.93273396
-16.63348268 71.90712707 19.94449687
31.95226985 21.58493081 35.49815929
-44.47952855 18.48976409 21.91206396
-57.23065964 34.87459614 43.89576846
-37.25181305 76.1437431 44.61450013
59.18087948 67.80714923 53.08178353
38.59776058 33.09055701 23.02457521
53.08835564 10.39340134 44.85400639
17.25855305 36.9637383 22.91786122
46.52106667 64.21554482 38.92363979
-49.12289043 45.79791541 43.72980578
-72.77342731 10.90086325 22.17912538
-26.37335944 49.47656749 31.64669914
-49.69546221 11.85986285 18.29853292
-50.58522668 12.61934852 21.30483466
-4.631610331 52.48090784 33.41032824
-58.62229634 13.72020905 27.53788333
29.92534221 26.23533737 34.33916109
39.89307142 19.86362977 36.48599698
55.79090401 49.69630569 48.38644775
31.98774165 43.3805261 50.49770117
-76.5405843 7.364446282 11.51108421
28.86302742 22.8096988 33.5225772
-17.13502297 73.41899401 19.96921148
-45.07310826 30.65746958 32.26632251
-16.0725553 73.83125932 20.80735694
-9.100705873 61.19163484 32.19503029
-71.47648644 69.52144212 40.61823943
-16.25127288 54.0938672 28.55513923
-49.54219063 23.04283966 28.28852979
49.76316877 9.530102409 43.8566616
-46.68071196 13.51066503 -1.440117716
6.089416058 58.91551333 20.7612154
-60.58785192 67.2232554 41.91417683
23.26913577 31.88293713 22.48684608
-47.69250132 35.01207141 34.6542289
-64.69274002 25.37333274 39.08361312
-38.79633906 65.89223147 29.90333623
-39.60792875 45.14754173 37.81197515
-6.57867218 44.38030015 28.7665147
61.69791126 47.88593014 50.79618439
60.54627949 8.706000741 57.19367433
-53.0498909 34.99565251 41.69315981
-41.59771702 31.56888857 32.67640962
-56.31690339 47.20445041 46.25358554
-41.69928742 35.41485234 34.6621529
-49.96917853 12.18950044 8.697416144
-52.57113225 34.49970961 41.53248387
-52.7494332 32.07857177 40.53961737
30.41371828 36.76997773 22.65840638
-70.9976435 77.55647018 34.65006383
-71.06119814 14.80486768 28.9718295
-73.88362797 11.89543521 22.49841197
-49.85882012 12.7847143 7.593333954
15.63262034 30.27732055 36.75570578
59.35484038 42.86969143 62.86205678
-41.0805186 27.83537716 29.30597435
-37.72812719 62.81086485 30.51052911
-54.36740534 19.83712636 31.46314199
-53.91888837 60.55147051 42.34698208
7.534478877 44.19884948 28.18146383
-7.529958772 50.8934192 29.08522309
57.36825505 56.43386577 57.2707377
-10.23200337 81.61277204 -6.546904583
-3.48445508 47.82163409 29.01339934
-57.1676391 15.49084171 30.0122393
-76.75268907 7.347551322 11.74766291
46.65556036 65.84591268 31.62477163
19.3973431 29.3064812 35.65468031
10.55695384 56.21404551 27.96327676
-8.707993012 68.21612268 26.32108687
7.90962135 38.23745748 33.22106199
-69.44455276 28.3058519 40.90683532
-69.33478033 61.65117333 44.17401439
-55.84027819 12.89819609 24.89657996
24.81952064 75.06806814 51.09098631
51.76064802 11.57202626 43.07815145
38.35280442 42.28730604 50.32146001
18.96945804 48.61319179 38.8488891
-57.20885239 80.04972004 32.21019868
-56.02348181 11.08085879 22.7219317
-3.9977956 47.33058945 28.98506013
-37.94949639 65.40883479 29.52681879
-68.37070594 68.93921481 40.95822719
-71.03393928 43.18296996 43.30472411
-56.45934724 12.36137381 25.41982403
58.3694339 64.45318481 51.38531132
-29.42060328 48.52261845 31.64868171
-54.3110695 78.06576715 31.38218355
-14.73574863 43.52617397 28.58415642
-36.64435318 78.19306958 44.01513989
50.92716461 8.936276423 44.70160528
-48.67460675 49.3284146 37.61954864
38.4338127 45.78673219 50.46860493
14.69694004 32.26262293 37.19387036
-68.56160047 65.27221908 42.77730852
-49.51214234 37.7043761 38.70685363
-49.53874162 24.05219083 34.05051064
-10.27986111 56.56606583 27.85047357
1.632742195 67.93248436 50.94829479
-49.69892735 12.56204967 15.97433722
-49.23835097 77.32046511 21.58687134
-74.43484723 52.20396549 44.11053404
-60.91386334 43.79021465 45.9787076
31.94276108 44.1350918 53.40001225
-74.58484901 35.65028905 38.20050624
6.026903091 46.22486076 28.25611364
63.58153604 47.45608781 55.42689012
-41.75822626 42.5318225 39.45402342
29.79794621 31.30844349 22.08426316
-72.1618652 37.7320085 42.70551517
-79.20084244 10.33212546 12.52727117
-48.09651391 45.27351024 43.79510676
-20.85022974 49.01293463 30.14895259
40.38106401 15.19663357 38.13106405
-54.07378988 63.14068929 41.45218585
-32.93390556 48.08298365 31.59565017
2.792716445 60.04376522 60.73211889
5.407108141 36.50650803 36.31250858
-64.28306256 76.88826632 35.29991027
10.80597748 69.70633303 57.73997937
-49.66991429 11.69164937 14.41976411
-50.06566012 9.783304216 13.34213476
54.55540746 8.140053465 47.42617434
-67.7642848 76.40767119 35.71625841
33.00388654 41.39753087 44.19076661
-43.13215676 34.94165422 34.60199791
-66.36582477 51.92463267 45.81442252
-12.07906755 32.87173277 23.57594015
-78.80825924 9.495596236 16.33680465
-20.6626857 45.32182508 26.34915259
-30.54675179 45.58184052 31.04229593
-70.37901617 48.64953266 43.81302682
28.99964717 34.73109322 23.29037405
-49.45160351 70.06344198 35.95267235
59.47951757 5.077723704 52.90367221
-34.48823085 48.82415205 29.11468617
-57.51568545 21.95409922 36.62137718
46.53514173 51.4185835 53.46468383
-58.86600911 53.29041197 46.0091226
8.563296775 52.21088519 28.20195851
-49.51534571 48.83573706 43.49924396
-43.4639412 25.03326256 27.32965302
-49.23756097 34.54591095 34.53377094
-49.55064249 69.56162955 35.97032367
-63.22524159 24.87418619 38.77156883
25.57422068 47.06548524 55.55623108
-49.47246903 42.45874497 41.77351349
-9.249571449 31.42444254 22.35761661
-46.79698402 56.40340351 36.70281229
-40.45121722 57.7747826 31.22594809
0.1983853052 66.05249996 49.73950243
4.281890061 37.27899576 33.38613344
20.52655587 71.99031188 52.67867613
-0.09787244448 37.20210534 29.28733906
-71.86487498 76.49394803 35.49670098
62.03066715 48.31940192 51.52731005
-74.81688727 55.55864537 40.19966381
-48.76154485 13.77408974 1.725324732
-37.26949698 61.32064351 30.24271979
-49.49855504 40.56215113 40.48896985
-16.53910612 34.18851678 38.27351328
-49.68830442 11.3473772 12.5931926
-17.28298764 36.34962441 36.63422467
-67.50485304 49.59167927 43.98106304
-10.7180901 40.23211652 19.44465907
-59.03185851 31.0221306 42.34837213
42.02676044 45.03309138 52.70558019
-41.47261688 23.63711259 25.67750027
-51.46543147 21.62100901 33.50767031
51.57730544 9.910085134 44.92621913
7.207596162 51.83782506 61.9244629
5.433893661 33.47970017 36.48494974
11.1875747 44.09052853 28.7422653
-57.81219103 47.73000389 46.26437836
-46.13409771 49.28577078 37.63009889
32.97927204 42.97281016 49.21503355
-16.73833506 48.73419248 20.94734864
4.206801717 61.98353116 61.25078931
-12.66586238 79.65082568 22.51959774
-55.93880737 40.74508248 45.42020569
-43.47054063 24.5591534 27.00807566
-53.93499334 30.17219554 39.58975359
7.989535362 67.10453224 59.34748997
39.27568181 43.173091 51.51590547
17.88036749 46.47990634 57.99468171
-56.94229804 88.93517077 16.41017924
-12.87542039 31.01680303 21.95462378
-70.076043 40.18202889 42.99931622
-65.38534315 50.87160703 46.19844731
10.36465736 50.13578828 28.64110412
-66.49968555 27.3025798 40.33866439
-3.482264058 48.32274487 29.0223627
63.81949732 46.68516726 58.50427735
-3.14204933 66.34333286 41.17370056
-68.6788898 33.69177505 42.33014227
34.93300066 31.69811373 22.40804193
-67.52208387 48.59593915 43.89034525
52.35059301 55.70576267 55.02670597
-52.94410923 20.26559789 32.04434156
4.963131281 46.26591363 27.98240962
22.27301073 45.99186853 56.99583638
38.07826039 19.31054224 37.42609527
-30.46169999 76.72882931 -10.08565885
-43.27910147 40.79459289 36.60452857
-39.14559144 60.24669811 31.41436265
-49.8364737 11.66323945 3.128047485
56.5141584 44.5208471 61.71589429
15.85642501 43.49645528 58.05489164
11.61110588 55.67562922 28.18593202
7.374017732 50.84855459 61.72361709
-56.69814532 17.76508999 32.65477508
-39.59786361 47.25709892 42.45398143
-78.07302902 10.15965597 14.31434235
-16.64920973 49.24100576 20.87353441
-13.74389668 31.9637729 22.78041188
-21.11288504 23.94217497 35.73331486
-41.97953484 31.08583207 32.53025607
-38.77034016 80.15270597 39.15161084
-55.01598434 12.12218076 22.09465221
-47.61905186 21.40852889 29.41412544
-0.4367192031 47.26868685 29.02455198
21.33273389 27.91376854 33.97329168
-61.24844923 10.28388401 21.31694723
43.30856744 67.74581334 21.8609101
-15.18101865 25.62811269 32.51039904
-39.43442849 44.37770117 35.06247644
60.50442685 56.84073989 57.77600013
-49.84563542 85.05427421 13.49595505
-65.94653244 40.91215049 45.38929148
-70.14790094 36.70276479 42.61637051
-37.98000825 48.05154712 32.92291067
-4.053570673 67.55979496 38.87923465
55.65487254 41.48261953 62.18988292
54.74198254 45.70067927 60.01078748
-66.84481968 13.40131298 26.98130523
-56.01087169 43.21818122 45.85761819
-26.38189025 46.96979065 31.61561652
-64.94447812 52.37886546 46.09693845
-49.47597698 55.26369368 38.79101436
-55.7604202 81.2650622 29.47867664
-14.71867651 43.04012392 28.39668335
4.796191999 60.15773583 24.06713877
-16.44513614 51.12880789 28.0528371
-52.48041027 62.0758271 41.85673445
-73.78026233 51.19144618 44.08648205
39.59321618 44.64345104 51.86626006
-11.06985542 38.29012583 30.40250811
-66.50813678 19.76694363 34.5931851
-73.11787707 18.52206461 32.82524346
-39.42394084 46.05916276 38.84740721
50.38494735 65.5637581 45.84229551
0.1088035005 37.17218311 29.5952838
1.139706403 59.06511184 25.79514773
-55.78803732 12.92139936 24.61725667
48.28949351 64.51947403 40.88479927
-62.75903594 29.15895724 41.38167962
7.707372534 53.96624936 60.42741327
-49.49467735 76.00599212 31.06847678
-23.87996539 52.47098654 31.36963746
-52.58430806 73.67254703 35.43945862
23.28666784 44.97166351 56.99252849
23.28832114 26.41175656 33.55687808
-0.461103276 30.37242837 21.24086928
30.09476404 24.14100998 35.31276871
-5.120474962 68.90419428 35.12921242
-36.19709574 70.78590528 25.03807511
51.3969844 67.16122079 50.41134552
-68.6684908 34.18874539 42.38560558
47.36917889 65.63460147 33.94173541
-7.550256621 69.91353365 29.70207836
36.40776314 43.88310963 49.6470609
-55.32928048 37.95703993 42.70975209
21.86869405 49.92101736 40.59371661
-68.1033794 19.32609752 34.18842994
51.19953876 50.81823983 53.71774195
-57.84029061 52.76633872 46.07069574
-61.39749574 12.87680822 26.20466079
-63.43707229 44.82856364 46.05926753
4.757963515 43.70966138 28.56317367
56.21041884 5.463120065 49.05314611
43.05330436 45.90282054 42.41335965
43.06360593 14.50579298 39.81262833
-57.82568507 27.64151091 40.57656171
-52.15436001 43.83157714 43.8280514
25.20928725 71.6037203 50.44684711
55.48468495 54.45837076 57.2563701
-27.42575833 45.49415519 31.46501913
48.59298081 63.65928484 39.15934425
23.90281821 33.81992446 23.18660006
6.59607051 54.2209844 28.54898535
-61.94906019 11.14617576 23.09372808
-46.57185226 36.4489754 35.23980689
-48.31442981 64.20654844 33.80721003
13.24916109 45.04612637 28.90911309
16.55360686 49.93761616 29.76136115
25.38260824 72.52072216 51.42184884
-50.59771469 45.29632536 44.00724616
-72.30402259 30.77269415 41.94845284
-64.63922684 10.76209697 22.2387849
24.74434886 45.48190132 56.61796795
5.221722363 49.68427659 58.16993827
-25.33255404 45.9563655 31.52679884
-14.75746926 50.64248865 27.54989653
34.68889193 23.04911906 35.45882598
-58.97148491 71.41673623 39.53806677
41.41001057 52.0283396 53.19022415
-36.5246241 61.18601881 25.83684824
-59.16094519 8.206452839 15.90197952
-49.48989299 41.86620688 42.82881014
-2.146882306 31.31045561 22.3263346
-47.71370349 63.14686404 34.34095613
-56.41815754 85.16191966 25.15303122
56.23415634 65.44451249 51.93774821
-25.32545381 46.45499988 31.56357388
-54.64662674 51.38312594 44.03906533
-71.28915461 58.1304132 44.6317922
42.45649073 15.04170631 39.5337766
34.30665144 42.09168505 47.53735556
-51.18205893 55.88985044 43.50077233
-16.70322818 25.24287818 25.61949398
-16.30704893 26.38769225 18.08074264
-37.69359455 68.10628074 21.47065781
49.77135127 10.02819085 43.89956796
55.82523874 55.96667457 57.16185567
-49.54133595 82.0675809 18.97643541
-49.47598604 55.11503113 40.51679982
-56.23237842 11.49022797 23.83615446
27.72985782 23.43753048 32.27699178
-19.06449065 44.58330484 28.79100864
-7.582318024 32.33117949 23.16687905
-61.03964188 14.20294019 28.22163121
-55.82543871 9.237991121 14.94431085
32.7323124 23.5719774 35.58887711
16.6186005 36.79099942 25.04518335
-20.99573328 49.28455539 27.02352848
32.17004878 42.93501405 49.80812222
-18.72009525 60.44905374 25.1700052
34.57168393 34.14367823 23.22025424
-49.67223274 78.94686946 20.26904908
-48.60683042 72.14172511 34.9719051
-53.66740077 10.67192874 15.36648846
-66.4718174 9.93876282 20.49285249
30.91672906 36.76401708 22.63167469
-56.10048529 8.577185572 16.84123636
35.99368199 32.16083585 22.66078844
37.49113603 17.35006911 36.99010264
-27.4534829 44.50370855 31.31485204
-53.55872322 21.6858367 33.15430639
-48.97010928 65.79616948 32.95869343
-38.0717832 67.59661569 21.63270371
-2.982164416 46.30938863 28.99293577
16.36250762 73.3674691 54.96566214
-35.58085422 50.86384507 28.9513744
34.67015274 72.04128164 49.38971263
31.35362034 25.73119729 34.0925649
-69.15655652 35.19159918 42.4901057
16.79941348 73.8970705 54.56098279
18.86042902 34.90254709 23.23362222
-46.09763051 35.9553211 35.05342844
-58.00358762 58.37308867 45.10764179
55.77746047 64.52178937 51.08320438
37.08159012 41.93078535 48.87599946
-40.38643249 45.80134724 42.02387143
-79.08143261 6.812592738 18.40195901
-12.68890687 83.26855701 -7.819996218
-48.1661841 49.82194001 37.62002712
-1.402566289 67.51762743 44.68982556
-58.29045421 69.81432699 40.53020349
-69.4182848 48.13346372 43.79407352
25.67800908 25.79430991 34.44228078
52.02765994 52.63236647 55.80667218
-41.16157165 20.97485854 21.56372405
17.53004878 29.90818971 34.85235382
-71.95841289 48.17591537 43.78568149
51.55912713 53.62536706 56.02183042
-5.76394884 52.75477139 30.44706362
-49.80958639 84.08121327 13.13180006
42.45942618 46.45683443 41.92185901
-65.88943288 15.17601949 29.50358275
26.30401265 72.04926921 50.89246052
-44.61298883 35.4475268 34.8385043
-4.743099868 42.38705686 28.24891918
-49.80883236 10.80019004 7.315043281
-54.03450022 35.49898861 41.86447885
-53.38692087 37.9311818 42.63951257
-47.48319484 37.91978482 35.8192991
-2.515164606 44.81494532 28.77254202
-18.47895969 31.31131496 37.08426798
-4.620125238 31.82138368 22.6934119
-75.32610516 12.555405 20.93873069
-38.83609259 46.55551943 32.97322964
-30.31027985 76.19978648 -9.799661246
-59.35430672 77.88421803 34.4515143
51.54754499 51.69667903 55.10709378
-38.70618162 66.08972988 45.07336214
40.8408057 15.64038209 38.71867287
-75.29541164 29.14541651 38.10299098
-53.84815053 31.13282053 40.07483808
-67.00664447 27.31053204 40.34302902
3.176365539 67.04804734 55.09446677
-49.50641405 51.76353521 38.64272664
-22.07629832 89.01580436 32.13153647
-5.222024714 39.4914607 26.994601
-37.58504582 69.61438705 27.24932275
-24.84883372 22.75908719 32.7000059
-15.02160986 50.65184269 27.49168122
-66.46632601 36.02830608 43.91724031
-59.0652147 38.32441866 44.98405809
-69.45860578 17.50530787 32.27737089
-55.36111552 22.60835488 34.44205115
-24.01455375 56.05439059 30.58302102
-17.67819768 44.07556593 28.59419707
-3.418102271 61.3184168 47.11947127
-56.88272642 11.06829549 23.03163879
-62.45352869 14.67504285 28.83739673
12.10661464 35.3436923 36.88040439
-71.49572586 45.67921555 43.54775343
7.375356811 68.6608296 58.87790463
-66.50736918 8.245166744 16.85356754
-53.45484434 11.32136009 19.44016194
-16.63380991 41.15488429 27.34058459
-34.68462996 54.32371078 29.40229909
-20.61726021 23.43772408 35.66818946
32.77149856 30.79291746 21.67515038
59.58292538 7.98930102 54.04408287
50.54986642 53.81236728 54.04346712
54.20363038 40.076202 61.31344184
-10.43975458 84.22865036 -7.741105585
-55.17656234 44.87386023 43.9581025
8.201515557 32.46672643 36.06320427
-39.41178678 44.86849579 35.18729728
27.96323431 33.75391307 23.17856276
-50.63342542 20.21499804 32.19108032
-18.94067224 25.34418435 36.52343554
34.96861859 49.04322361 54.11219593
57.91322023 7.932906809 49.19052914
-55.71422073 67.3416936 39.60982867
-58.91570524 55.32434482 45.71378648
3.83326043 57.93123175 61.75094773
-76.90308003 11.92523997 16.90160244
-56.31177102 82.85161131 28.64592507
46.95807225 50.95080258 52.98772972
-16.93701799 41.109612 27.92396212
-20.93480356 75.2004093 11.66716408
-5.297047696 68.94370907 34.70416931
-14.19590292 48.51713359 22.98226989
38.89601197 51.05317841 53.33675967
-48.08407083 36.46846459 35.30195135
-22.06574562 20.15733003 33.23914588
-60.8871428 12.8671439 26.219524
-20.76945661 52.53725725 30.00712589
-46.76468074 55.8848924 36.88926647
-20.78024749 52.59225631 29.37071434
-60.25212198 17.82341119 32.65550471
-66.69248505 67.32933919 41.84686892
-55.67335802 78.08395905 31.43079522
-1.97421217 66.05156234 44.33807258
-9.781285091 70.39473133 24.5414542
34.14136773 44.94723745 55.20602733
-20.67042014 20.17202863 32.80241672
6.491018154 56.827954 21.61197627
-19.51746935 49.5861875 29.0666902
-22.05219503 20.65271058 33.3124636
-61.076659 79.01413929 33.31630817
-49.69712062 83.47936815 20.09701067
-64.5700854 66.24539623 42.37203379
-56.85147317 43.72320745 45.98185384
-55.77632202 86.43725216 21.87892019
10.18105989 53.14258708 28.7308478
-56.05254993 78.93278103 33.30264644
-57.58128877 25.25492585 39.1019703
14.13796459 34.30567854 36.8897533
-59.86930383 52.7987417 46.08148337
-53.73248684 41.87544904 43.53060857
-63.09960298 9.469913899 19.46585036
16.03554054 36.08734557 27.49826332
60.72743296 39.89495021 62.17341669
-37.50486746 69.11462255 21.38238054
-15.74741242 30.24220517 37.3210705
-78.19102733 6.995271231 16.11148776
-16.05810425 74.32618565 20.88577592
45.55257853 67.00548711 47.50713765
-80.27257517 10.8050766 13.06793595
-22.36419954 48.40720191 31.64282253
35.60627118 44.51707455 48.26702353
-49.59968691 28.17499589 32.81473026
39.25787566 17.22696696 38.08223462
-70.72234096 18.90609996 33.73687878
-38.17892201 47.79833844 30.07363445
-55.94447917 85.76542399 18.05677811
-46.60218667 21.63891618 26.54569889
-48.56087529 18.87698589 29.84853113
5.490719971 37.13999181 34.76926187
7.076267688 65.5630166 59.93677161
-66.78345399 24.4572059 38.4641119
-60.1616359 85.81804928 24.07674424
22.92135025 75.03446864 51.84302638
-70.75684119 31.74000033 42.07748176
-62.80975754 20.64021192 35.40362825
52.1651471 57.25640153 54.54104668
-69.2820182 60.6201259 44.47982154
-1.870537306 52.28410577 40.99503923
-48.49268291 24.61474168 27.32051913
-46.21196467 54.3472103 37.15447351
-50.03365599 11.66359483 3.161525795
-20.81005764 46.98380608 30.39033307
-64.9725869 9.913436418 20.5009476
-66.150614 55.45547302 45.57129517
11.7061443 45.87109964 60.41297519
33.74976123 42.98058961 48.97778708
-50.11827178 53.83992574 43.78836021
12.89777976 73.38076955 55.4720052
57.46739653 64.9396461 51.73677853
24.65775046 46.53001714 56.12047111
-5.515092252 70.00857599 34.037281
-52.95680865 19.36668337 30.82873724
-73.4722594 10.98082631 21.38412724
25.80921094 68.52225881 39.49116311
-15.76127489 54.59668676 28.45123807
27.22213593 38.39991904 21.82543444
10.58501327 50.09896532 29.02654879
19.30417172 35.91852681 23.00804549
-62.43133619 53.8566748 45.94210048
-45.77719663 25.03560393 27.7436244
-10.62398441 29.61333139 20.33786147
-11.79286316 33.19333777 37.26785852
-27.9837842 52.03189816 31.42287787
3.981070708 59.43765185 61.71505822
-68.53718463 19.79936408 34.60377652
-38.01871984 72.59386651 45.1838259
34.47287448 32.18465765 22.67427473
-66.73242551 72.61942558 38.7097106
-10.75408957 41.49102674 28.14374077
-67.71510209 33.17416436 42.32850383
-46.49576285 74.92590065 31.38177412
52.57369196 70.72737782 49.57487307
56.86589459 9.422755919 49.57492738
-55.32333745 31.63584546 40.34332545
-54.2374689 32.58807815 40.73531368
55.70923064 49.23528905 47.92720002
-18.59757379 42.51235378 29.43651261
-2.227845185 58.70247947 48.1269875
-60.87250458 23.41961418 37.72869836
-61.37191476 49.80008825 46.21875263
22.74965471 30.91755185 22.13952919
-52.5043392 11.33744356 19.0721862
33.43057827 46.0624422 54.0487502
-70.56261722 11.27897053 23.19480645
-66.83365404 11.64698394 24.0381436
-55.89615193 53.2541881 45.86326604
-68.7029184 72.12009249 39.05547758
-60.81113957 34.44407065 43.74980069
-46.59370529 19.85543335 29.76812954
32.97428157 70.16932646 48.13758363
-32.79816072 44.72199692 29.80006311
21.07091242 43.08940278 55.95917886
-12.71634886 83.35673343 -8.838379301
-46.1376832 48.28314739 37.61668483
-44.40047279 55.15640963 39.0685169
-10.60393979 68.67695859 21.3329349
-55.75275496 56.92036567 44.06274046
-44.38224651 22.27258535 24.59288932
-49.73871602 24.43228142 35.50291912
-48.76088153 22.74116792 25.81491397
29.200126 71.57280657 50.04463931
-49.53211458 35.98480215 41.19280954
20.81350333 45.98476805 57.3566008
-14.71494635 26.39743883 35.14389916
-13.45724598 56.17998126 27.11173036
-19.33478047 26.32442 36.87258535
-49.47672022 38.83960691 37.17479218
30.95101474 45.04513271 54.67800716
57.69618306 40.45197017 62.11184091
-72.60001388 41.22076223 43.0755597
-55.67198827 75.85140331 34.04110727
-56.03990242 14.58653528 28.64174016
-70.40564137 68.97460519 40.93546421
-70.91891565 81.42871448 30.46969766
-49.58168191 53.06037293 41.08236508
-51.11101265 52.84071565 43.92396008
-72.85691844 56.69605146 44.10196923
-71.30574369 18.45039756 33.31162223
-18.22300201 57.91022017 25.41463507
-11.59979109 82.54091651 17.89921168
-23.89031938 44.94296915 31.36271863
0.8199156334 51.16805101 47.611292
-42.73490488 20.44862687 22.14600866
-12.21504017 73.7932659 20.51276096
24.060409 47.08577806 55.60935023
-44.9634266 55.74437972 38.17693043
45.31940862 46.55702837 46.03996034
-59.52695692 75.7146672 36.36360589
-71.15283973 37.2163559 42.67251878
3.040032546 59.04806817 25.63059942
55.19698337 70.56864163 50.9173214
-73.50089792 48.7004993 43.81673047
-14.08039832 31.98485095 22.59989539
-70.14411525 16.59889626 31.27710723
-53.59720515 12.14982621 21.50315383
-56.92033437 41.24686373 45.60898675
46.8443331 66.65600863 33.83786864
9.714083533 58.92730315 25.75974636
29.15074857 38.41816222 21.24585622
-68.80381047 54.60008464 44.35396627
9.459717698 33.88379874 36.85277074
-3.139756643 59.41692234 45.83376076
-55.86394885 47.71356728 46.08364508
-68.47194651 74.26022348 37.47379073
58.70669312 9.064072601 53.3877344
-77.41488453 5.704162454 13.47168251
52.12435274 9.354916791 45.44005241
28.315139 73.03032115 50.7734415
-54.80149835 40.41249696 43.23756828
-10.42614201 70.0939182 22.32984935
-62.27524966 23.91087434 38.11994473
-1.281546377 68.56012351 44.21797271
-13.79564728 31.487662 22.49068297
15.68260127 70.90202726 54.58276805
-74.97421624 30.04976911 39.19663404
25.16019676 68.90586271 40.98842102
-68.21949183 67.35628816 41.82523498
12.63915808 34.82061816 37.02443068
-20.57517559 26.38023532 36.46119117
-16.26265793 54.60182631 28.48719127
-11.31181047 33.72544757 36.82564084
-49.65591947 62.33593267 38.29859133
-52.98893437 72.07267474 36.60918596
-49.49615403 27.2917826 31.39468827
15.63327318 33.79250341 36.73525949
-12.4553092 57.82543571 25.29894126
-45.3464369 39.8418951 36.40511119
20.72949324 46.5293622 56.87721653
-15.03387512 26.69366878 20.11258314
37.89751385 31.18714742 21.94772034
-67.74003922 31.68009559 42.19757747
-61.80071862 11.57356224 23.93066531
-55.83551135 15.20864878 27.2074546
-62.30452596 11.5799247 23.95288435
-62.45369069 54.87238338 45.80855898
-24.03771396 56.56828957 30.44836426
-56.88963138 42.23580793 45.77602724
-11.20120577 31.92002846 22.80332288
-40.48792213 46.32998093 41.73310129
-73.30413827 31.78606831 42.02847585
-16.63609336 47.75917804 26.41988971
46.06285942 51.91125947 53.66204961
-44.45478647 31.59777341 32.88595363
-65.6732742 38.43667273 44.94112745
25.54514485 26.28921452 34.54904584
-47.71863353 43.32180638 43.07328177
-36.02098824 36.57115763 25.98267099
-70.03737931 74.83354997 36.9433766
-55.77913067 16.94129362 30.38946074
31.46647012 42.53993329 48.70204355
-8.495691779 70.11789337 27.51002146
-71.043729 74.3186429 37.28606976
4.400859142 52.46742547 60.97750428
-58.75906897 11.52590117 23.90388894
8.539957026 41.75045576 27.27924973
-14.76744064 77.68619147 22.42072543
-63.94626527 53.37531526 45.99233855
41.34837493 68.07412928 47.55660807
-71.53056658 10.01281711 20.59791009
-41.26462311 59.0608395 45.58478918
18.83746331 34.39952697 23.25078562
-61.89060105 33.48860597 43.3941099
15.70178964 35.68581251 32.22320118
-71.2861566 30.75479805 41.96209066
-71.06127689 27.37362591 40.3838321
-62.80839688 28.68164089 41.10548867
3.322724784 58.46798212 61.44396077
40.37244927 68.93623596 14.42819054
20.43646981 27.96943509 33.49799666
-20.71171907 49.314732 26.61905306
-42.17198135 52.48752371 40.49270153
-15.82679181 45.58984773 28.14215729
-23.83888566 48.93475208 31.62650758
-55.80268544 11.16770292 21.67167435
-62.67163008 76.31651356 35.8032829
-15.15526284 25.81334324 30.3551955
-58.01855875 78.95817987 33.38272792
4.965127818 50.16726226 58.43866319
25.80673566 31.84176081 22.48091534
-46.65801281 23.67594126 26.21563495
53.82492273 7.181995647 47.03403467
-70.91034917 68.98142187 40.95258199
-54.11543616 73.70157495 35.39447661
-67.30338895 15.64815709 30.11895587
-73.21870228 37.26734719 42.47454711
-25.46116416 43.48538371 31.10308091
-66.38127265 42.91731462 45.50135402
20.7816334 28.89886394 34.29596537
-6.768597369 39.29861986 23.70153743
-41.40027754 33.50293399 33.49350978
-61.14035688 21.54586572 36.22505522
50.53406166 65.08543415 45.53995115
-45.35693111 56.92253366 36.22838476
-39.3006575 69.63736461 27.30975991
-63.67376578 25.35487759 39.10352941
50.4073854 8.498035427 44.06151062
-17.65323319 55.66349711 28.08101453
-63.9516596 44.33814793 46.02386504
-10.90301711 80.52746111 17.83342882
-70.29649849 53.63006762 44.24601448
-19.23582362 31.34621808 36.82342555
5.999573542 34.44655872 36.8063533
57.01854847 45.5929563 60.82715967
21.92507752 33.85124133 23.20021799
-48.61663869 73.65332087 34.90599146
-0.261839619 60.66782746 54.07007006
-56.25538555 46.20615303 46.17753647
-27.50224293 76.10318318 -3.387087136
-61.3787446 51.31018157 46.1697124
-73.89675834 6.940914144 10.09691977
-40.70337057 47.33066468 30.15751948
-1.061726392 67.99657701 44.89146995
-5.117386971 31.82444427 22.75271318
36.02638453 32.64655538 22.84260606
-13.87972824 31.43762339 28.91424433
-64.83307535 82.43565403 29.27308387
-49.59000546 69.63104155 35.17204519
31.13197012 21.65183354 34.87793958
-55.48889919 12.11295978 22.29188374
-68.84697412 52.11077802 44.12668131
-59.44954356 26.71288378 40.01314082
-65.88116553 23.97061795 38.11406715
-1.95903225 48.29770016 29.02261058
57.22745948 51.98494459 56.50414411
6.33697827 55.3960668 61.57031426
-39.08144305 62.77497488 31.18525171
-41.07101466 41.92406847 34.72493777
-37.70007071 76.14686188 44.66378047
31.663044 23.59102511 35.57167323
31.02000787 70.64146594 48.85597673
-9.701957827 55.5178943 28.25492233
0.6548738065 36.59272975 30.39122163
29.34969908 31.78899513 22.41779336
-70.80857873 56.62912156 44.48830977
-49.62461769 82.79191156 22.23704122
50.19889171 10.51296085 44.01711039
-21.60888596 75.34222117 10.14945979
-46.74815599 9.905285108 -0.3598603846
-55.81022878 19.67294268 33.6442614
-78.92464035 5.852957536 17.8589217
-17.23487049 28.24034005 37.53728541
0.6189505676 52.12519093 48.19172049
-43.8227223 20.96685857 22.16409109
-55.36383238 31.15405674 40.11736231
9.175003687 53.15452583 28.78411499
-16.42452829 47.40374849 18.85234565
-9.556323568 49.92005416 29.11791308
26.25799584 31.35968926 22.16445474
-44.81342493 40.32301666 36.54489333
-64.48634393 16.9726653 31.68579344
-75.68062049 12.15768743 19.79669365
52.65035926 56.85631463 53.26637344
-12.23256329 62.38168492 24.80396414
-68.54078618 8.73245148 17.41123649
-56.0378279 9.804448161 20.06225062
-51.5920029 46.80897593 44.11683376
28.64683026 69.33417248 41.17800767
56.4049945 55.94677848 57.28226111
-62.59467698 58.96123082 44.98224772
-49.71876105 79.62122339 18.27617028
24.86524434 28.3189361 34.42278103
-61.90964991 44.80140503 46.0832559
-55.72940249 69.30866029 40.0853027
-72.28680257 16.18642507 30.64735617
57.73549635 47.72240182 47.62355542
-37.26321973 68.68873238 20.45369979
-72.36014378 17.55662447 32.2349911
-62.60992884 31.0802167 42.35642578
-66.34954932 49.90755857 45.92042339
-63.5617449 26.30280173 39.731253
-15.88491854 48.87358498 19.16642691
-15.92521721 60.95375681 24.60468439
26.60365136 30.42867298 21.25317059
1.304920122 58.36418283 57.20710455
-63.44003942 77.96300015 34.31612768
-45.22369243 30.18165866 31.99195934
40.96085761 42.53936124 52.7246892
-57.30785306 50.23445389 46.2279192
-55.81186632 86.65340499 19.37643851
-0.5205861599 51.95002523 44.61584675
-23.41049627 43.94427326 31.21152236
-64.85609765 69.40296865 40.7310181
-49.35330386 39.40345595 36.43232553
58.88155145 65.850167 52.5503815
-49.90227101 85.63461525 12.59637422
-45.83114762 33.05537864 33.70741151
-61.89376977 45.79782649 46.16632179
5.286562705 65.04682026 60.44382207
-55.86254985 8.072175527 11.00510309
4.444676135 57.09209902 18.93583739
-18.94552124 59.91188466 25.62220511
-49.90410354 11.80312505 7.343692276
-43.74644532 23.179294 25.59918449
-60.43934837 8.610212015 17.2852886
53.88808164 67.99926723 51.86090571
-9.543337841 32.83149984 23.55941448
-77.05201638 6.295889358 12.35992434
-57.33967743 44.72613113 46.08556915
-60.13018945 10.69278335 22.18352739
-46.77395825 62.61660351 34.49075147
-43.20697407 23.16733013 25.63518935
-15.79035911 28.25500513 37.09156537
-56.30241237 88.82206911 5.947832308
16.48156051 34.86458177 35.78129965
-49.68132052 69.42266711 31.78178061
-46.80821863 44.58554574 39.88253265
-76.14750392 11.33921474 17.73387729
11.07299175 68.74065306 57.24609418
-20.26158698 75.0590342 13.17996331
-15.78883347 55.11280446 28.29166361
14.32370246 73.90702755 54.91752711
-10.42250693 30.51612997 21.4723757
-37.70692352 70.24761569 43.22815151
-53.77199351 71.01724833 37.35739838
39.24299166 30.24733703 20.94785259
-55.83469561 9.620987435 16.32661658
-59.34084743 27.66248506 40.62202898
-0.8620485208 36.81281454 28.1256026
-3.597766214 67.45375785 40.02326642
-46.32610652 22.75186066 25.22645847
-53.61852774 50.35563584 44.11757382
0.5169480291 49.24248362 29.2359721
2.467462639 38.98235737 31.43821334
-66.31291945 85.94766545 23.74515178
33.84914997 31.24707918 22.02403605
58.85852459 69.87951089 52.39233541
-16.55938148 59.06656824 23.32692176
-74.54180308 11.12390216 19.9271665
-74.6292302 34.79213395 36.51777858
-58.8790219 53.79919217 45.93195313
-67.14272026 34.63985821 42.6844261
-28.46958262 44.52071366 31.31122054
-59.80841934 15.08185039 29.43708066
5.00162369 44.23763238 28.21427429
-60.58153471 18.74623393 33.65880776
-16.53139982 34.68684543 38.31372186
33.58129054 49.07404649 54.0189533
-27.43674731 52.53072283 31.3544747
-2.371955074 51.93571393 39.30840816
-42.22241996 53.92050366 41.34716618
-13.61882458 80.11341982 23.15784393
-18.72159826 45.56343509 29.00083158
10.64471362 50.59584391 29.07367523
-78.44476594 8.444081517 16.82095285
-55.1450843 50.3823577 44.09849051
-3.507967391 59.50355146 44.89832651
52.52198162 40.80726399 58.97408171
-55.80604992 89.35311498 11.34168766
-53.15690998 17.96097458 29.70546849
16.64396102 33.27601224 36.71168081
-61.75793635 10.29311158 21.30698908
-64.03126613 41.36500857 45.59357261
8.427848048 50.33507845 61.65681022
-71.23011771 10.00992556 20.57417994
33.71734342 45.11157693 53.37911849
-60.42967262 11.12307024 23.0721975
47.06681701 43.11492837 56.53192524
-69.3705405 13.43649701 27.05453338
-69.74812742 31.22450496 42.04271954
-11.20076551 81.70500957 -7.432916516
-40.46402581 78.88097577 36.75785617
-56.36943283 14.57931889 28.78835665
-47.52288491 85.87147126 9.393013099
-46.66682863 13.78287705 1.223840218
-15.23041455 46.11333881 27.77799889
-56.76018979 28.10180467 40.85658036
46.52508116 45.2417401 49.42557631
58.59910701 8.237800648 51.34693746
17.45328421 29.29602974 36.14675228
-49.52815864 23.64374773 32.96335691
-35.34181695 54.90966022 28.55214057
-62.5314173 44.31549962 46.01593437
-72.91850013 29.83045113 41.35060018
-74.00650791 12.36052548 22.94937961
6.757898772 62.10899129 59.30784667
-73.52982035 9.298332551 17.6201557
-62.59662289 72.01317013 39.1322032
21.62599997 49.96910861 40.08172144
-41.91255888 47.05528484 39.41157149
-74.79034922 35.55572891 39.33741963
-44.65691488 48.85607258 36.51000042
18.2938106 72.40470096 54.12058428
46.94210793 11.24569394 41.95873864
47.23007304 11.22432185 42.15192481
53.17571281 48.84652376 47.0967873
-50.8966131 36.90942951 42.37256041
-70.38833723 13.45435817 27.04128797
-70.16992753 17.51723766 32.27453454
-5.559219106 39.1593393 25.0877724
32.55452655 47.51544946 54.82817259
2.999792805 67.08756213 54.66942366
-5.238841711 78.53372877 -6.715194456
59.85252487 41.35976951 62.8154983
-73.05922634 39.73514791 42.92930674
-43.69898951 25.93326188 28.57991114
-16.96745477 73.38402148 20.34324199
3.982842429 38.78782855 33.40745411
14.11305471 41.59038805 28.07460944
-49.50663908 63.7177219 39.70922767
-60.46477746 26.72945214 40.01441438
16.17948743 32.76031713 36.9601831
-45.64790414 46.27499728 37.52878961
23.58140901 26.38780033 33.77908584
46.66799695 12.26450949 41.83714053
43.98032829 31.09206987 21.89142077
-76.80122124 11.29730865 18.34502174
-22.39387706 43.92700609 31.21809689
-57.18556898 11.50027699 23.90127726
51.05606599 11.00400667 43.97986468
-54.65316914 45.86204992 44.039943
20.83927866 27.51180437 32.90704898
-48.24518368 70.70042619 34.15466382
18.91796306 43.94097772 58.13733802
60.06608624 8.799562707 56.19911433
-49.64875069 23.50635646 34.58129303
-45.36763698 28.29496178 30.61500096
-78.21840295 6.494475658 16.10366805
-6.661792956 42.41336557 28.30941485
-30.95714714 77.30752413 -10.88244517
-74.07094495 12.79613476 23.7314387
-63.57682617 14.24390687 28.22991638
28.09111013 47.03475611 55.43297133
19.63261131 30.74400609 36.40189802
-39.12070708 45.0437754 33.09701263
-49.47755131 39.19357505 38.89247139
44.47155778 52.97507344 53.26927315
7.575326489 41.9149427 25.55372585
-36.52662929 59.07905479 26.98984331
-9.719382865 41.96685153 28.24933553
-69.62616098 19.35091512 34.19073269
-62.01267741 22.49477479 37.02906486
-64.44204572 45.34217223 46.1152196
-49.54194173 52.03589431 41.31440015
42.58445916 17.68645677 37.94038302
0.220609257 52.22701914 47.0855902
-55.77077949 25.7854055 38.42514113
-62.95474805 27.24881832 40.28672595
24.88524381 27.3044155 34.54298077
-67.12314692 33.14811439 42.51802154
-23.93388031 43.45829499 31.12628446
-22.10697108 48.40133464 31.66187912
-33.91542081 44.77764254 29.36715638
-71.27724681 31.25251961 42.00911727
-16.51575517 25.04919771 27.83212629
5.792527662 35.45632175 36.77705376
-23.36586172 45.42840585 31.4540388
-61.05136728 58.42384197 45.09969035
53.03056033 43.18568551 60.39984459
-55.82485054 16.89002303 30.99336637
29.04539132 24.67701538 35.11718938
64.10912708 48.46693778 55.24487289
-60.10442367 37.850865 44.85295703
-40.30047415 47.87742183 29.56016943
23.8167701 26.84562417 34.24608873
34.26258786 43.72633096 51.87616037
12.18296173 34.30074766 37.31982533
-55.7931755 87.19574831 18.90359776
56.14231136 55.95539732 57.23230265
-70.66928264 26.41923449 39.73506669
-76.13886107 6.101153978 8.619769064
-68.52410227 64.75108743 42.99319305
52.42661121 57.3722869 53.14590176
-58.48817287 26.22127019 39.71017
7.462011776 60.55409619 24.7841517
-41.81204441 20.89414897 22.62470884
-40.64499781 47.82821295 30.19712482
-57.35094149 53.77170163 45.95966934
47.46255114 30.61728391 20.91236086
-50.18763887 12.5474793 4.58339728
51.91551287 45.4844687 57.23310095
26.08344502 29.99064745 20.6106561
-7.228187119 62.10059793 38.76594656
-49.52266687 22.53077506 28.4025853
58.59025567 8.645370236 52.44390131
-39.49664901 58.76847069 31.16173145
25.39966178 35.81705515 23.02355598
-50.67084489 42.31991907 43.61364357
-38.36529232 44.55694299 32.77781553
-49.764079 11.39225994 17.91325348
-49.63247978 76.6701738 23.38444963
-26.96426326 87.7019418 36.66269512
-49.51430922 25.03763924 28.43268691
-53.29467381 57.97328961 43.02414174
-37.89967937 47.18003888 31.37141311
-39.68843393 69.62236312 27.55785948
27.94040212 33.26321454 23.05266281
52.51885846 48.85575581 47.11488092
-45.2526944 18.86166162 23.56889461
-49.49841151 36.33946876 37.06915267
-72.67601779 26.94261552 39.86861503
-57.80196241 20.08964491 35.01335439
39.4256554 51.03378318 53.46090758
-64.43388121 12.92181382 26.26125992
-57.43536743 81.16841553 30.92004438
1.30303938 64.68905811 53.70338521
-72.30792975 21.7281177 36.23906443
-61.91691019 9.871088458 20.40981773
52.01608084 9.886971731 45.11085931
-54.90664091 14.33032505 25.57325434
-41.057469 51.35356961 30.13735888
-65.34214687 82.44467196 29.26548016
-31.53900956 45.63637449 30.59845523
33.56432102 45.13921197 53.08749296
57.35987151 50.65928102 54.38823954
-13.66050618 79.13001367 22.92860729
-49.71464534 11.76376925 13.59107427
-77.84719505 6.077563602 15.04605483
-49.57506732 77.55319218 30.60270277
34.64030572 41.34016768 44.54460553
-48.36445781 40.37985289 36.56230016
-62.9049202 51.33671126 46.15278732
-72.99006195 18.05448484 32.40223465
44.39806801 42.76960135 55.22303869
52.22830174 68.59540841 51.08363461
56.22592553 9.547359621 48.2504824
-43.9839255 21.81721848 23.9765171
-72.17596992 37.23690747 42.62905821
-4.911303474 40.43826368 27.59744649
56.78001411 6.905441711 49.68092136
-13.78211813 60.05759505 22.94600018
-18.34761628 50.14250607 28.21208104
-12.66101571 32.41529662 23.1589241
-48.88065432 69.51433546 30.56493544
17.01031001 50.88511346 30.32830677
45.64251654 42.68696347 55.9450358
-70.33335473 83.68731053 27.44515946
2.210757333 51.71866424 52.60741254
8.244969095 38.41518434 31.09392573
-60.64711741 11.98941178 24.70982271
-55.72293153 72.11381045 36.65304226
-36.11706647 41.89622115 28.27682028
-44.50158458 31.11916427 32.62428166
-77.88943392 5.584265663 14.95403162
-49.51648549 67.33731243 38.47874302
-44.79081449 28.75398352 31.00297907
-72.66028084 36.75599697 42.47753835
-70.30239088 56.11934225 44.48302608
26.93313377 33.27576475 23.09906291
-49.59725869 78.59359632 30.18244597
-74.43918549 51.70393786 44.08940614
-39.89694378 58.58726793 44.99488795
-32.22897717 83.89360171 41.09021327
34.06306262 34.15246905 23.21520116
-17.05695993 80.21241734 22.66428135
-31.11969542 45.12086212 30.67629249
-54.83899384 14.79841713 25.95304012
-59.3378125 47.75625405 46.25059252
-25.57740077 46.45820294 31.57443814
-68.68658744 33.19344203 42.28998274
-51.59296703 28.22081042 38.48965399
36.85164222 46.39327971 49.55572876
-47.94533514 10.24329372 1.771239495
-58.77799153 23.85638644 38.08553197
51.99421862 43.86378467 58.55225188
56.32857714 9.142029055 47.10963807
61.35528765 7.541250471 58.9073813
-18.62062869 23.42165575 35.47395264
-10.84480497 39.7690428 19.0178811
-65.43090374 48.35873167 46.2452226
-67.76433874 18.39841805 33.23967115
8.598561391 43.15320783 28.46382655
29.26322685 37.85920756 21.88651882
-13.6661931 58.61635893 22.17489054
33.32911479 43.67687796 52.62826587
-50.04894344 12.32884854 7.094973113
-63.29534251 62.58240876 43.86507851
-14.48567464 61.97536042 24.12391642
56.45216108 67.89517783 52.58026617
-48.18158865 71.17056228 34.51149393
61.30035536 7.141078101 57.73670905
-79.34424914 6.269507124 18.92997306
-41.11820318 21.0397042 20.80267755
-46.55599207 21.36117625 23.93445571
-36.32153492 33.7094695 24.30070572
-37.4045724 72.1782951 44.06431945
47.88599679 50.97827663 52.49183005
-55.75099936 37.3330551 44.20720706
3.048377952 43.72959912 28.65775003
-35.81222499 49.35002059 29.08915488
25.35365868 49.22850659 47.96828458
-49.69057121 14.82779501 24.63016492
-13.83898716 30.51841023 33.75073183
-49.51570371 22.1803726 32.46898551
33.31830678 44.43898347 55.43653235
-13.60719504 30.85414513 35.63571312
-66.96722125 12.96084131 26.29132355
-15.4887381 82.17354192 22.9056103
2.972760141 46.78103998 28.20866357
-77.17517634 4.925701761 10.80962636
-37.44970698 60.10312356 26.93102391
12.64766445 55.1460878 28.30891649
-15.48214758 74.78564415 21.26884897
45.08640354 15.48095746 39.7597295
38.81923904 16.76018325 37.75801023
-55.80385384 90.34312136 -0.1514495721
-48.79932664 38.42929834 35.98212937
-59.91140221 19.66040232 34.57195758
-71.81966382 18.46186132 33.27655018
-49.49226204 49.47497586 41.90073187
-54.02101237 76.41280241 33.03576653
-55.17319682 10.21073997 15.18082764
-58.82335347 64.06320979 43.30194697
-65.44119596 38.92093043 45.10189291
-52.29158371 15.25450814 25.99923725
5.945039261 59.72021853 23.10042577
-22.31452729 49.91357113 31.62623915
23.41447048 30.97863729 21.30362032
-14.44907344 48.57050958 22.41092351
50.05849384 10.00723367 44.08809432
51.27868445 43.80350581 59.38846967
-54.22440124 55.93786091 43.52362465
-10.46718229 61.98671172 29.05243328
-63.92560778 52.36046273 46.11626613
-55.17571407 53.41110475 43.90427089
-53.57913985 24.46165329 35.89434095
61.16057384 48.00831796 49.47789052
-61.09785853 67.23274502 41.90127565
4.38619084 38.28036995 33.39484209
-39.12558656 46.00111316 33.63774974
17.61351422 33.28248709 36.45161635
-49.61007004 82.08413427 24.62402167
-49.75426706 12.83774382 12.78443138
-8.247398197 69.08732331 27.77299799
-71.97027325 15.27010125 29.57106585
-58.219603 8.983811979 18.35158987
-68.63085827 75.34481711 36.5666234
-46.35670046 57.93573312 36.31036978
-79.15655999 5.319064128 18.27433367
18.07152924 28.32661961 35.62918772
-20.3587467 44.79017908 26.63628353
-42.90840888 52.56167359 39.77235666
-11.17312781 34.75903384 36.4538512
36.31239533 43.73299422 51.40789773
51.19062998 10.45868893 44.45800806
-42.40140369 40.34518734 35.8275329
-10.11551954 32.37013709 23.19772749
32.81023263 41.77443315 45.67898202
-49.54902716 27.82732893 36.84104151
8.840442228 46.93354903 60.27907563
-5.877415784 39.19596143 24.72331794
53.83604504 70.08393642 50.9770314
61.54714262 7.996242723 59.41555226
16.06484472 46.9811656 58.34857045
7.309088347 49.37465955 61.36612608
-11.58774408 59.31567202 25.31366337
-5.401907033 61.72446045 42.784143
-37.71119906 67.13550698 21.09018359
-49.47729456 59.38119794 37.6053981
-49.57248284 26.98339601 34.98922185
36.0811223 29.85342581 20.29700802
-17.85239072 48.63382453 28.1515922
-49.05468018 85.39861971 9.347700633
5.044696269 37.1915394 34.25591909
42.77004385 42.88467438 54.19766069
9.339537344 59.99678233 25.06916146
-51.18202859 32.05602234 40.50247408
-20.77889361 21.51039447 34.7662989
24.76982755 37.89585482 22.31800894
-54.72815136 10.20924155 15.11334954
-69.95474373 46.6496489 43.64166213
-70.8295562 24.99343495 38.83743063
-14.72666275 30.72507045 37.34759572
-14.00190746 31.04659526 27.65024344
48.61022454 66.11211082 39.81506628
-49.52168258 22.57983425 27.83288255
-21.81919066 46.89818549 31.57672729
-49.61137911 12.27010822 19.34671124
-31.11584456 47.55566615 31.54378052
-63.12688196 30.60338333 42.16380067
28.95712523 33.24589496 23.05982595
-45.01797461 52.52894018 34.72801508
-23.87739218 45.43866416 31.43250472
45.19137304 12.86737072 40.94692917
-49.57730902 53.56038309 41.10368918
-41.08720619 25.50111551 27.27185774
45.90495687 43.70767703 55.6990807
-77.65380396 8.614978631 14.68621289
8.590794483 42.65490975 28.42327462
-11.86537508 76.77884383 20.74715569
-48.64554795 74.17011991 34.73876511
-40.04973105 65.30371979 31.14759807
-76.67567939 9.695895344 13.60489021
-16.9073554 25.81182648 36.53357961
-20.77086573 51.63264743 28.85548354
-36.44668474 75.15439496 44.29275068
-51.52558547 34.97006622 41.69948996
-49.08199651 78.49380413 19.58935943
-22.45018287 42.44876097 30.90946026
-54.9930144 61.60217852 42.00772068
-57.19340122 10.2205805 21.27849857
-49.93584728 11.8686936 6.588578311
-62.23418066 15.56882679 30.07315137
-51.05542819 50.8162014 44.10883515
-47.44190876 10.23712343 1.746862229
-13.93207666 30.81314692 30.34696544
35.07613638 42.45351999 49.01680729
-11.2634019 36.5589984 33.05568692
-39.60420389 79.24679674 38.17380719
-10.58078146 81.11304895 -6.505881101
-41.48967089 58.57303535 45.4638528
-37.30603598 40.82255181 29.31421945
-17.00632735 24.5962235 27.3574956
-62.10342786 10.7218291 22.22265163
59.55814453 51.04371251 55.33289536
51.21221429 8.414158969 44.88172419
58.80357953 42.88760244 62.75926137
-70.90583366 50.65158862 43.9787309
-13.18227245 53.53019325 28.6867601
3.988356692 45.75985663 28.21634301
-30.4362691 49.54383673 31.64060986
-67.75136954 58.06787078 44.68315344
-22.19953059 75.46672147 8.816808731
-66.77454283 24.93014891 38.79878577
60.80125855 44.89225875 62.4133421
10.05944467 34.88303826 36.79177938
-49.48253935 50.14573234 39.93888747
-49.79697443 84.32928877 16.07619481
-56.95566816 87.78788708 6.424670222
-21.43945124 42.43446374 30.88268039
-64.94004358 12.92936425 26.2701372
-68.81972883 15.66972523 30.15775225
-55.72672762 54.84881661 44.7992668
-36.39260221 57.00258196 27.76293214
-74.59324257 55.24094807 38.0184342
-56.5086521 9.36629011 19.41185705
-62.15784191 21.56356965 36.21357563
57.69425106 69.92170522 52.12454672
1.449943927 46.80587505 28.21077012
-62.03125462 12.44650752 25.49412604
22.36733329 29.36930165 34.35901952
-66.26634635 42.90889681 45.57715697
61.50648887 4.501237963 59.20938486
-55.77944455 88.54014387 14.94753557
36.71446558 30.28393616 21.00518834
-67.51750742 14.75173514 28.91282777
6.278112497 68.17938455 58.84948021
-69.88207096 13.44675533 27.03299929
7.752243386 51.72135196 28.21284943
-16.9059687 48.89974624 24.88409008
-41.42216481 42.48618865 39.91968559
-40.45988064 58.9389109 35.19347266
23.42010088 71.61425624 50.66574691
-49.49142191 29.24870392 31.98297865
30.65987295 44.54111336 54.75791312
-59.64685741 79.53844062 32.78380474
-66.45493078 27.78221969 40.58876043
-9.566708229 46.40978043 29.08322652
-61.98503084 26.75299442 40.0310399
18.14970365 37.46242294 22.78545909
-55.67057858 17.99007898 29.84697505
-36.23081567 31.85306574 22.52733085
28.34661649 46.50276365 55.73336056
-0.06507204605 66.63805893 48.81879024
-12.78779973 46.04574327 28.09687595
-46.67937056 10.29846939 0.8892928023
19.53320042 30.39104839 34.69160565
-39.78853779 45.77230112 42.24703784
55.0621828 48.78691399 47.42901988
-2.40512366 69.35656141 41.01325358
29.48082096 33.731716 23.14682966
-53.12013002 10.93524582 18.03198505
-51.57822431 20.69825213 32.58793396
-55.76240511 31.05777885 41.31103668
-48.45607518 32.13617456 33.22551093
-30.98678655 76.34282126 -11.3310997
27.97264726 35.25581287 23.22154142
-49.72371748 10.36080895 12.39946859
-49.7062826 10.40156979 11.92296195
-73.84486815 28.97120484 39.84874007
-67.84219465 9.130047945 18.48906203
-13.01134387 81.88166256 -3.311703618
-78.31702395 5.490236358 16.12713659
-70.03665348 84.8394086 25.66746302
59.10332431 5.621039847 52.49484504
56.87412197 54.41409511 57.50538016
8.578727861 51.91428371 60.77539189
-53.43174013 30.16611261 39.56439531
58.77076895 5.162689062 52.0524948
-52.09078649 48.82166831 44.15371638
-39.94088923 48.34691566 29.86800833
-49.46409823 51.8413008 37.73189687
-17.00701025 55.13263408 28.29378049
39.06793275 68.64606998 12.21878692
-71.67946014 26.4332522 39.7649858
54.40173838 67.9755474 52.03830534
-70.45688613 13.01149886 26.36875367
56.16318865 49.6819551 48.48204305
-13.45369344 32.57403604 27.29397101
-71.97550518 47.18010537 43.69574852
-48.4226707 10.24869165 1.799607069
-31.63829463 86.57749666 38.95415931
-11.18163103 81.51711134 12.22462006
-36.95457684 72.67651567 44.02143549
-9.632124524 62.59468438 33.48867494
11.63357406 71.76850284 56.94954301
43.6776049 14.46862322 40.12702883
-61.0172675 12.43056808 25.48578919
-60.85718065 64.09804112 43.28546247
59.12235537 39.47145418 61.56912461
60.48077536 49.56054991 49.06801351
-56.13114086 18.68172467 33.55896154
9.4142676 58.40288155 26.07815091
-51.09253382 46.30258585 44.07348682
54.71585775 9.813542405 45.44841691
-5.098732937 67.33702293 35.83801229
-58.82374014 19.64446501 34.54954579
28.12266762 70.36189397 46.82735115
-38.74304542 42.46607658 33.81559507
59.14518699 65.84106774 52.60573552
-70.48232735 84.85361294 25.58756132
-38.37479324 43.07986542 32.44669911
-37.51849905 54.917442 28.87691045
-12.21531011 82.18054947 -1.106568277
-14.99468869 82.12254606 23.40338942
-59.32487068 43.26694823 45.92349475
-15.09116493 26.22701101 25.54081346
-51.67517837 70.44842727 37.73417233
-55.88517396 8.642018722 16.04754455
-18.70652481 57.83201671 26.41468871
-55.76363191 88.48261162 15.61239646
-69.64974791 36.19703857 42.56556788
-69.79172422 18.42999993 33.25968029
1.447805845 47.30916961 28.19520818
44.63017165 67.95488393 48.31503692
-35.94604675 33.69350291 24.41444974
-40.20629934 57.75034744 31.46290201
46.20965632 68.403679 48.63052001
40.62808405 34.53936331 23.29850216
-44.30055658 22.72120471 25.19605061
-7.07598249 32.32354171 23.15898278
11.26498503 50.0780719 29.13942023
-21.5089889 23.46632493 35.50622784
-27.00206466 76.47697813 -1.995117565
11.56168609 72.85875383 55.96010495
-62.48066953 80.70869269 31.39237124
-58.47787099 40.28478114 45.42129333
-65.64374048 50.87806392 46.17276889
-67.46683586 32.16838596 42.30368595
-14.54387828 58.28432676 20.37010091
-72.97373107 23.17417748 37.0590422
-49.50632236 36.2939036 37.59961496
53.15735084 67.61788312 50.6010044
8.055497508 51.71704532 28.20501168
-15.87477615 25.17852203 32.03523825
-49.54391699 19.88174723 30.02528188
-7.525084696 50.3887445 29.11628501
-15.64929817 81.62842968 23.43764994
-75.81652325 30.60521138 38.73592856
-53.36059076 38.42011584 42.78522896
-17.95960742 46.62948921 28.13326273
-67.5665918 71.03800797 39.74711838
-51.0508011 76.9033938 32.60082735
61.26580161 4.070878713 58.4245686
8.566880613 55.7989231 27.33519859
53.20994179 68.59578615 50.89204477
-55.73866092 37.82904712 44.2736577
6.018720589 45.72677232 28.21320728
-12.32681049 57.20884398 26.60564196
-66.68764666 30.66512869 42.12607464
-8.119392809 53.95627141 28.60165319
-55.48702811 36.49279943 42.25788517
-59.44225417 75.16972364 36.84691421
-52.57514291 85.87457651 16.14710297
28.1325728 45.50719257 55.67821391
-49.49953621 34.63516519 39.37424445
-9.57574794 78.919317 -4.53766872
-65.13595605 75.8185115 36.22777519
27.24516025 36.30510697 22.83258749
19.81131111 30.39942513 34.54132499
-55.68068523 42.3940796 43.70814066
35.65511754 72.03998225 49.21695821
58.83311871 42.38287458 62.7862366
-45.5529149 19.31709829 24.16574815
-72.66264357 15.29724901 29.38795397
-50.68598859 41.82534215 43.53130053
46.99658152 41.61893209 56.43201348
-66.01686626 8.698175894 17.32780583
-50.00396386 76.34388954 33.06968212
-7.891187014 62.23681933 37.31102702
-7.147669628 60.75155638 36.9313273
-73.57008533 17.19397315 30.84901428
21.21863599 30.93506463 33.88148624
-44.11520767 55.11162167 39.53404655
49.77732525 65.31849577 42.97871545
57.04961383 68.89556456 52.50639086
-48.41872663 47.94712762 41.95245702
-49.68428169 10.53913876 10.32176498
28.78277283 69.92313958 45.96820521
7.898255621 54.71054928 28.44406533
-70.26877554 13.8962624 27.71518106
5.398578081 66.0938586 59.92095662
61.31083973 3.570721473 58.39552892
27.3308577 72.52905973 50.95351735
-57.78291532 90.8592016 5.888216041
-62.03153596 40.34294511 45.42378878
-55.32233814 26.84857074 37.65090435
-77.61802761 9.148703315 14.3101653
-58.19228844 68.76037612 41.09323191
44.79181662 52.96218892 53.35777074
19.88618213 71.56334799 51.93066673
-45.36684479 39.34997365 36.2929454
60.70804595 56.31014364 58.07007646
41.34844438 14.17499458 38.15322805
-1.062269695 31.76110582 22.71465693
-49.6162971 83.8779399 9.628042759
-70.56010247 19.82869229 34.64909576
55.54259694 63.63583208 49.75955109
-70.88745995 51.64675223 44.07592335
-61.23419216 62.54618343 43.89253485
-15.71794224 76.74000773 21.93270133
5.951044129 49.51459973 60.00058529
-49.74544434 84.05551634 19.24451839
-50.56933927 47.79309176 44.15075272
55.4341345 65.95384654 52.0042367
-14.11532439 80.11609606 23.22146166
36.80259692 73.1345809 47.94450864
-72.02912202 13.03648538 26.37852673
-40.69237235 39.97198027 34.00742923
-13.06542578 81.95849939 -4.193368094
-38.91668403 43.57048797 32.68117724
-64.47204615 54.39719412 45.88317432
-49.77847393 10.7353203 8.062309152
-11.05371487 35.69657019 31.37411238
-49.52836168 57.2246492 39.34336528
49.0356741 65.14084317 39.35583233
29.863827 31.77742655 22.45404262
-78.63159074 6.405570202 17.21454503
-42.91152268 50.21895023 32.00914634
-54.98526511 72.64036307 36.22639858
49.23695162 41.45513422 57.90624695
-40.58143381 63.03127336 45.97593411
-61.68572991 84.08910231 26.95835418
-63.06744295 14.23473171 28.23928592
41.52516838 52.57157673 52.68861051
9.765294329 57.87226043 26.34440437
52.74900989 68.58052048 51.15716231
-37.79819488 65.30107534 24.92227343
-52.21665216 58.46443135 42.94366569
52.2024675 55.12960008 55.91682951
-44.61455987 20.92029241 22.85567333
-70.00535627 20.28646129 35.05582667
51.99049785 10.42687072 44.67483748
-24.46608383 55.02995021 30.90830037
3.984911717 46.76602647 28.189928
-16.26007859 28.74638831 37.30345012
48.41333797 40.618388 56.12359376
7.082824002 63.12296277 59.12824238
47.64020351 69.93203627 48.09534204
-28.4070043 50.01352288 31.62779826
-38.09490005 74.61638116 45.02609129
-11.24693019 81.20130304 -7.403360535
38.61287733 71.53242263 48.71837717
-19.66361121 73.95118158 14.27340584
-3.038787125 29.50372619 20.16369597
29.38958374 36.79103593 22.60925594
-50.13209188 17.92180403 29.58333779
53.0355144 54.20050223 54.89032641
-30.83289191 85.99111472 39.78107302
43.98766294 52.99616907 53.11666127
-64.07514869 85.30774062 24.92046926
-12.92651002 37.26258881 36.85834296
23.23934109 43.99110501 56.73131087
60.56709008 7.74062669 56.7431903
-74.5449601 45.7307026 43.53156149
-7.434852655 39.79003231 23.95056294
-60.78503449 11.55676676 23.93194238
-50.18491445 32.5260325 40.68273846
-17.23371025 44.55178718 28.80774785
-4.531091788 67.19934868 37.3279832
52.2753878 41.78742091 59.29601362
-33.83881889 54.21916124 30.45468544
-44.04299468 54.145096 39.08712858
-1.506278445 51.2445938 41.33970478
-59.69303766 76.80287716 35.41582468
-49.82432531 84.49175279 14.19540951
-49.77305497 63.54811977 35.90225857
-39.70433101 45.07228169 38.70403318
-60.52502151 16.45442478 31.11981864
31.60107587 74.11164222 49.24731097
-16.84513351 46.11019981 28.12237525
-49.71852508 54.86570991 43.45735657
-8.635271438 43.4221866 28.62792004
34.19141148 30.31777499 21.09352407
-71.34257731 35.75586351 42.18324174
-43.90321131 32.06953094 33.13090023
0.6677780287 38.61986229 30.16289033
-74.60518362 7.379265297 10.96996165
-70.81987818 56.13260544 44.4277452
-57.33590213 12.81020284 26.20329438
-36.68040542 36.58168248 25.98624564
13.0929948 50.04795185 29.14046325
-35.85522435 76.68479836 43.89383866
-46.65473715 45.26477692 43.62153399
-65.47227056 64.69829012 43.02410017
43.30251683 41.87180868 54.20091739
-44.06223195 50.15519078 32.96875991
52.66965766 57.42312932 52.50933532
-19.25409417 21.986775 34.77197849
-13.53302794 31.46761537 34.32659949
-12.95530134 32.52750848 33.56569242
-54.22966975 27.30754621 37.94104462
-47.65956311 74.68291092 34.42452043
-7.066721993 40.60876421 26.02920284
-13.85092829 31.37353981 29.65268109
-16.7850187 35.21788576 38.02402567
58.94592392 9.017728374 53.88010934
-11.46017411 80.31295677 20.42980534
22.82196623 36.38067339 22.79888703
-47.21467064 53.85524304 37.23016971
-64.83082986 28.71072452 41.15355474
-59.76798246 34.9156327 43.90326882
-66.65000988 83.61328644 27.60204916
-58.37839163 54.29664838 45.88828609
-1.559453008 32.26634287 22.77095323
-17.2448539 27.74316007 37.48417653
24.87612622 26.80679876 34.49477694
32.20092089 23.06526056 35.74591468
-4.492050636 37.53559138 26.25398535
60.17325103 7.840183773 55.66256631
-25.35856859 47.95357455 31.65326332
-55.76763399 78.58417602 31.46853148
-68.14850077 34.67643575 42.45161565
-55.68434288 13.823968 25.77308811
56.15477504 9.180146228 46.70029114
-51.08027182 48.30526449 44.12915669
-59.33721975 86.39995321 22.99099839
25.17328725 48.71816586 48.10044922
-27.03276613 55.58801292 30.74602376
-53.45622738 81.99250908 26.42115927
35.51909077 21.44579688 36.43311345
-39.25526468 44.96298463 34.06055199
-66.79601444 15.6399952 30.11694567
-70.80155539 18.44384294 33.29156141
-8.707608999 39.96128336 22.20527768
61.74543893 51.43368207 56.21535504
-25.31580827 88.28939041 35.35543341
-12.20137743 48.58312014 27.66252361
-56.41097623 26.66677756 39.96890244
-49.6276414 20.73019046 31.84518251
-17.01636352 48.18066147 27.42616332
-58.99129431 31.50388439 42.57472768
-78.8983612 7.346349336 17.9974509
18.24649078 30.34633738 35.45602714
-64.92499296 47.84909212 46.23836929
-49.62169998 11.21466779 14.12107874
5.266081527 52.76579074 28.21564956
-46.18360115 44.79079811 37.38069467
55.03446148 8.648393618 47.26027323
56.70731803 7.996100338 48.68690524
-61.03695239 39.83457759 45.30897601
47.5720303 51.89330766 53.58263934
-56.17214524 8.185451317 15.57579838
-47.34364429 12.69863494 2.286305113
3.612420583 54.47756451 61.09926676
24.78911209 31.85953461 22.46865094
-44.43531128 37.86897911 35.82783915
29.4326232 36.26710905 22.85653284
-3.767345323 38.47178672 26.90102285
-61.77322741 73.60002097 38.03377649
56.86406436 50.66160785 54.45578132
-50.34014853 72.02496739 36.65786831
-2.748997194 52.02679796 38.32294231
-12.07035953 75.72914718 21.31861047
-65.50741377 8.688965792 17.33756777
60.17016188 44.38628156 62.58080766
-58.71698888 62.50290707 43.91487273
15.49593748 43.9327374 58.88559857
-49.69363004 86.63291169 12.6209133
4.922903396 53.26767485 28.28149954
5.605461491 61.46955393 61.12403833
-15.55113794 50.31763084 25.64579926
-0.7314080808 58.84627716 51.99894091
-45.22481299 20.85646119 23.71305173
20.39363819 49.69210053 37.70581088
5.066777957 54.24908641 28.51440206
-54.42169135 60.55732633 42.37489099
-64.43810028 7.900852894 14.62935075
58.58106309 5.184050883 51.84068921
-6.25930952 39.2399371 24.28564558
-49.15183432 46.31473192 43.56238323
-63.45358357 7.887390611 14.59787751
14.16635435 33.78914156 37.05403661
-5.786185416 47.34072357 29.20847258
15.22990331 43.53142518 28.63520777
2.899875848 60.09842313 25.11732292
-19.76464474 28.30234665 37.29991062
-15.21902615 37.25446844 37.38980684
-52.01675075 12.59813211 21.82413058
45.86003616 46.43298088 47.37689343
10.25172704 46.38842536 60.51148766
-45.78624994 41.3226243 36.77952378
26.97340863 48.90699564 51.39172288
-49.49896897 39.15482328 39.34641476
-34.33825122 47.84317556 28.82069331
-14.29467348 77.19327092 22.22610649
58.41420148 4.71645096 51.47411094
27.72418632 48.19950166 53.63501095
-52.99597459 86.58907723 13.75955283
44.23318733 13.43171124 40.40502517
-57.36790243 54.28247344 45.8601328
-17.15726301 42.55391568 28.67935577
-2.588219759 59.28737173 47.23249758
32.44236515 44.5587321 54.21344996
-40.01240269 60.78972347 31.10247491
-74.16900026 30.4183577 40.59087027
-49.1446547 9.800950673 1.308356826
-38.8484039 42.97188402 33.79054657
-3.866897032 38.97466498 26.90887094
58.91753022 7.872293203 49.70264826
16.10407146 29.78506387 36.55363115
-58.33946631 52.26814876 46.12260329
-35.93248496 59.07667837 26.90412356
-54.22347087 42.37755893 43.62202509
-16.55436828 81.13676662 23.49119507
6.685931316 59.24071196 22.69895448
-14.21404256 49.76037564 26.03319135
-43.55466897 45.78231566 37.02236389
-49.41747437 10.74478127 2.056992677
-77.80753692 5.127459935 14.41470667
-7.771786104 60.01239847 33.97775231
40.7928512 44.55864107 52.62201861
9.215990117 58.42856725 25.81778523
12.3175104 47.89367781 60.12338403
-60.7561639 9.024464193 18.36340669
-71.28041798 58.62822211 44.6778378
-54.64130641 46.86049792 44.10024941
-60.9422767 11.13387013 23.04458114
-70.75390146 27.36724031 40.39934242
-55.24084728 55.95504073 43.51803113
54.48452321 8.678026401 47.02115075
-2.934637049 29.95340467 20.75027902
14.15479642 42.07154785 28.30763587
-42.64704439 29.66812454 31.63541501
-20.79269533 52.19543091 28.15308605
-64.14920771 59.50428001 44.80123553
-55.86088197 7.972222763 12.16510984
52.90382702 8.78161421 46.12007597
-55.7898945 14.73297747 26.89405405
50.9350585 9.434510445 44.74287662
47.60722489 65.56407705 34.71503768
-37.7267566 64.40927403 23.60804819
-3.988661686 46.8237659 29.04025494
-56.28860176 50.21585898 46.2494051
-57.7030364 62.4869851 43.90633968
-49.81977089 86.37443018 15.6456116
42.44708389 16.06327248 39.32976663
-49.27171814 54.9062197 37.07523866
-55.67616038 14.32205643 25.81599447
-25.35620441 48.45459798 31.66320769
-40.91196123 80.5392472 35.07277754
-62.72900659 61.54007229 44.20399568
-54.045463 13.88925279 24.70267015
5.797021875 56.97981124 19.98145563
-77.40829699 10.52477684 15.77562557
54.55947496 63.66097973 49.65510602
-44.87099564 51.32647666 37.00577795
56.16864807 52.0111063 56.40236153
-55.16010473 9.677141907 15.54608583
-59.76142375 20.5891791 35.41471893
-55.82483432 9.467345867 18.1083197
-55.60502494 11.69410019 21.34981647
-5.499711892 46.3404497 29.11246767
42.98005125 44.42166435 53.79503243
-55.91656539 45.70646725 46.0869977
-72.31513981 13.93741795 27.62767131
38.10092318 29.82566991 20.23403057
-16.45088503 62.47779466 24.49271137
-53.70969371 55.42378393 43.56660674
36.11303055 43.18267881 52.0077399
-45.58949407 35.94677487 35.05573469
-49.61747987 82.68254059 17.67869634
55.47348544 51.12195305 55.20359101
59.96467841 57.39222639 57.30351649
55.2869919 43.5160016 61.96162045
5.905466539 50.41813371 61.17367737
-46.78846298 22.30943115 24.62403638
-71.65540789 33.78091465 41.86298441
29.11266353 46.01056788 55.47439758
-10.95707236 70.21307403 21.04785291
-38.49554926 67.08702178 45.10918285
-48.67423432 42.8804109 42.55292043
-44.81802632 33.03987596 33.6941696
61.3125125 40.34980676 62.6081727
-11.52679185 62.22388782 26.50119357
47.57707758 11.70016174 42.38849322
52.25971793 10.41495438 44.76182913
11.69300979 44.08334497 28.72926777
6.837756548 56.75215641 22.42576507
54.09934878 64.14752365 49.92133882
-17.8530762 47.12752224 28.15805652
-38.33673779 53.08021134 32.8809458
-43.5667484 49.70036686 32.32755518
-51.88905192 14.79701718 25.40671995
-20.74058154 58.14617917 28.98220969
-53.37954321 13.08162747 22.29789724
-49.826548 79.11236382 30.03058192
-51.06247063 12.61231132 21.4775409
-63.90905527 50.34324886 46.22383662
53.75705677 43.06698958 61.6392066
-37.36076774 67.13218008 21.06197521
-56.47460676 58.3451439 45.14045926
6.397170759 55.91747498 61.33258932
-53.3344227 22.57712241 34.41811797
-64.50771042 35.47964281 44.08636334
16.11594284 51.54115057 28.70975104
-49.62859182 11.57169321 15.80442254
49.26613077 42.44484711 58.06465454
-42.77104824 28.72624509 30.93980542
-49.09605799 20.90803946 29.67920508
-66.55323706 26.82737734 40.03873297
28.64576372 38.42511867 21.26140438
61.56890864 56.79932889 58.05372629
-7.769788535 39.83509837 23.49127734
-24.81755911 75.03959431 2.621203428
-53.33480667 86.6361087 13.27819447
-12.23770082 39.6921029 31.82997306
4.762056856 66.69416124 58.90023459
53.28123181 58.36683693 53.09073968
-32.57593078 47.1705309 30.46651775
7.542661379 44.69693792 28.22437019
28.9743162 70.73183792 48.19699841
-57.32380102 70.32448075 40.25026065
-24.89371007 52.48680369 31.37934772
-62.53769833 40.35049554 45.43266606
18.60032491 49.45028077 35.02838549
-50.57555115 79.67029553 29.52318372
-13.74730421 42.51868727 28.43801322
58.207198 44.45970011 62.10286042
-65.98397384 70.48079043 40.08725067
-20.89489727 20.5644794 34.11601204
47.63202698 10.21535065 42.13486209
35.92117049 46.5967502 53.19778286
-44.71588775 42.78489505 37.08021596
-36.96950898 50.36834331 29.14170439
-69.42826821 47.63628374 43.74096464
-67.34973527 13.40823448 26.99724579
-9.816253022 60.49859829 28.72348112
-21.35500526 44.90294586 31.34383854
-67.6204724 16.56476043 31.19210694
-13.92252433 28.84328995 35.73275924
-54.36068534 31.14358548 40.04761413
-48.4685872 27.87032045 30.30927631
-51.26409064 66.22332963 40.09066565
-6.502398428 77.95034459 -5.528517999
55.57487141 66.43462483 52.222813
-65.54750455 56.45300628 45.52946625
10.99993114 53.12596064 28.767696
-72.18216764 16.63585896 31.23668605
-47.05605611 65.24520746 33.16300035
-45.1932173 43.78233308 37.24553881
-36.39733729 40.4318417 27.84993215
54.61954667 38.2539784 59.08126157
-73.43796591 47.70148855 43.748693
-28.92227351 53.06374117 31.27674842
-52.20099854 55.90828812 43.48105224
-72.32255441 57.15171314 44.53705052
46.89006935 44.20851916 55.52371371
15.69691636 36.48782106 28.74047024
-42.5134823 26.83523276 29.5363735
-40.2751649 41.95049181 40.0930648
-16.80534221 49.14632038 22.00248697
62.25023994 6.283590766 61.68325891
-14.30173361 45.06166568 28.15620128
-60.63467896 25.30850932 39.06223407
-62.41593624 70.41537632 40.16617919
-53.61416599 48.3445715 44.15608108
-70.03898389 42.17219886 43.19546696
-26.93346077 75.48749518 -2.174842363
-41.18568668 31.60882218 32.13425437
-23.33832664 47.42338013 31.59623389
40.12417861 33.06390503 23.04287371
-50.77333518 58.95713711 42.77536931
-47.33881222 22.7671537 25.24205481
-76.99240234 10.51320543 15.8306414
-11.2767802 37.76840184 30.67188186
-70.56426691 70.56127046 40.02646911
-74.05325793 44.72601443 43.44766261
-48.6937136 42.66057742 39.2819813
49.99114825 64.85708582 42.46768064
-74.37043865 7.862177786 11.14583469
-51.07698582 10.60411034 15.65976628
-37.87858666 64.36863298 29.93543597
-21.3368485 45.39600288 31.44325117
-40.59533989 62.03385611 45.90405176
23.9073133 35.32430503 23.20171798
33.09295343 33.66883649 23.18792555
37.35221053 18.82768546 37.34327031
-49.8260619 13.2637468 2.026126975
-24.86202257 56.58765883 30.38071229
51.59446463 42.81322631 59.17085373
12.99578151 72.82911744 56.03065522
-28.96003708 75.91511455 -6.752480525
-49.6544766 83.25963393 22.63971383
47.51265729 66.01488542 35.32640074
-68.41792433 15.21260115 29.56111471
-77.13814782 10.10928723 14.72077208
5.866823465 57.47159434 20.08580775
-46.45805726 73.66705874 34.3348577
-49.50060807 33.58171812 39.95034958
28.5952509 71.12793571 49.49773731
29.12662322 40.71361043 41.21634933
-40.04005363 42.03011491 39.12390442
-19.81099958 41.55013262 29.18480388
-52.20989691 85.18811549 18.21974835
52.30538724 57.28477009 54.18497902
52.36444075 57.32931465 53.65661129
-70.49960088 46.16211361 43.57859424
-55.78040128 32.52394922 41.77401571
-56.17572274 7.151387113 10.10072903
-55.77158463 84.05433051 26.23414742
-68.83796043 52.60844717 44.17429653
-45.24014943 53.80077235 37.48595222
-59.29896719 28.14357498 40.85584022
-3.640424625 31.82273976 22.49083473
24.27404205 29.37381619 33.94299058
50.97896245 38.85820401 58.58789176
-20.78827435 51.43081729 31.20179547
-16.74818318 75.78449206 21.56821585
-16.92413096 48.50580342 23.63408841
-10.7073353 62.31273648 31.14013977
-49.51809567 35.59940368 39.83748163
-30.93921977 49.04755103 31.67113135
59.02463566 4.178863398 51.77176539
-46.89367324 17.01823114 27.80180341
55.15139069 43.0078548 62.05977841
-69.32120458 30.22771628 41.87947145
-33.83120307 46.25419667 29.69010029
12.5428808 73.3863501 55.47490348
15.51339627 35.45873371 34.89522577
1.757106802 66.21443457 53.38903123
-47.57076389 73.11907996 35.08175436
-42.31941352 20.44964782 22.05492001
33.37918223 20.52970793 35.82255407
-41.90666682 50.30672839 30.79852114
-77.89970404 4.671736768 13.89600963
-24.23734864 54.51146223 31.05702519
25.52278828 68.16452607 37.87196714
51.76681285 67.09855348 51.06830497
54.09490946 51.17313888 54.87229127
13.69926499 32.27033389 37.29461851
-19.5924225 44.6513924 28.10127823
-78.80177486 6.869744779 17.68516389
-55.16115157 18.48309776 29.8531434
-62.89259095 74.15894367 37.58550403
-10.37229217 60.63042003 27.29923799
-65.32854602 9.504058226 19.49455091
15.07641013 70.45782026 54.02841036
-59.665104 68.2577728 41.38205203
-37.5014041 42.73174474 30.49473626
-74.62123346 36.07174746 39.14148923
-11.94705479 30.54183855 21.46467213
52.54789199 50.52786211 51.00487805
-68.65843812 34.68589043 42.43910687
46.59208763 66.73369584 32.98412341
-43.02423295 24.0795801 26.66355812
-46.57305694 61.56038434 35.06051962
-49.49242557 34.07980656 39.99325594
-5.356294025 59.94036583 40.17995275
-53.01445236 35.47999189 41.89047741
-72.73751621 14.40491934 28.10776466
-36.98806474 68.24593977 19.71484481
-55.78668822 43.73119922 45.68601861
-14.45117055 82.07137399 23.89377998
-46.16794833 52.31614256 37.41763245
-65.06446574 19.27981659 34.14615359
26.48644471 29.98558078 20.59261926
-58.33596875 27.65114029 40.56209092
-55.88119688 8.032026141 11.47474265
46.61693372 14.95001512 39.80477346
6.224005354 38.84663143 32.29742395
-75.10764598 11.66377391 19.59449165
-49.58881464 81.57119615 24.7478873
-75.12740667 12.07684662 20.62965176
-44.78879704 25.48523221 28.16215845
-17.77443542 54.11887697 28.55528376
7.385318018 55.82506088 27.25710314
55.41956004 5.564727296 48.02443657
-56.63168193 76.74527554 35.50068744
7.160828637 37.21430139 33.58812211
-27.39799516 50.50231056 31.58781089
-71.50104147 63.76167386 43.39346637
46.57516096 52.40282821 53.68450275
-37.16481156 40.89997957 28.38844904
-59.9989902 40.30876017 45.43301379
-35.39239482 78.20575486 43.62912387
-37.65017505 40.19659934 30.8197213
-58.9788114 19.18330648 34.10594262
59.26743111 7.243128582 51.11308149
30.98655404 33.21328234 23.05139275
-72.06028681 64.29821754 43.09816807
-40.56090789 40.95965198 34.17002592
-46.59149731 43.85011736 42.55195154
-71.18332643 78.65456828 33.59123209
-71.28369775 13.91267353 27.71822044
-8.919120234 84.20491591 -7.755572882
-14.93989664 69.22885395 15.75302283
-44.39652507 37.38436452 35.61956133
3.829952003 56.92846861 61.73910329
47.08648362 52.39267472 53.70485962
-25.98886309 76.26321212 0.2932110107
49.22703091 11.61119351 43.10664546
-40.76683548 41.94407305 34.43470116
-36.44632601 37.52997995 26.58635806
29.69984666 71.5685056 49.99926839
24.22650432 71.60391907 50.63196226
-37.61451628 59.21326484 31.46594324
-62.35997123 74.150436 37.58269353
-39.84266349 45.34694402 41.36857456
-74.61336613 6.88117686 10.92705529
-22.3643528 52.44978528 31.32672082
-58.10740492 11.94716977 24.71586031
-75.67969574 9.943475078 16.36750525
57.21454989 40.9493631 62.25623205
-47.63770646 9.331459738 0.6445251771
-4.069245253 59.13579979 43.44785421
47.43371153 67.87866803 48.66515491
-63.16718781 25.34711752 39.09700656
-59.56620287 87.01767767 21.69031112
-50.07950935 83.41116422 9.308411916
-69.75373264 79.17897444 33.05755145
-55.84893286 11.97246337 23.99152952
3.76844692 50.02092242 54.53905558
-39.62264679 59.7621276 31.30394718
59.32973874 41.37570564 62.73019846
-55.65047217 13.09978442 22.52019787
-51.7882266 15.2483728 25.97446758
4.719660364 53.27108752 28.28064244
-20.96390959 44.42644589 30.97417268
30.26983016 73.03540925 50.34160365
-17.35937083 58.99933631 24.25996197
-16.43847693 47.90061185 18.91369417
-57.88878649 42.74648152 45.8649413
-49.24145076 41.38067463 36.76456066
-55.78769488 25.7443826 38.9045908
-48.29272651 55.91236547 36.86174646
-54.03120065 62.61698952 41.69691212
58.42945584 6.21540411 51.5501824
10.72467667 52.11171106 28.94105624
-70.71940112 33.72997813 42.27579072
-57.88879607 19.63123133 34.52487252
-62.96519009 16.9486688 31.67426918
-50.65509138 55.369589 43.61321005
-57.54132487 38.29904201 44.98803381
-13.23780624 37.24617436 37.10825998
-53.11723543 34.02519179 41.31853724
-46.32921115 57.41965036 36.46955183
5.342516266 50.07600353 59.42609106
-36.63386112 41.90913493 28.22546344
-23.83941431 48.43280272 31.62696193
-19.64833956 22.94230198 35.40799076
59.64410846 45.48263145 61.60718108
20.87670237 34.86418169 23.29448029
-55.88704637 7.929206507 6.842821889
-53.66124436 53.39048 43.85487958
-46.35938616 60.52250158 35.41499012
43.6784961 33.10452333 21.89351706
-61.08923295 10.70578484 22.215492
-9.660832332 54.49271687 28.49480549
-6.49694349 32.78144535 23.55951783
-48.1617347 46.81305834 43.41526937
-10.13975773 43.93999134 28.73042024
-13.31966975 70.40136156 19.31263505
60.02659448 37.04935171 60.38100928
35.40386998 19.92293732 36.65362937
-51.45512335 68.32855843 38.99462777
53.71875906 54.53150672 56.74412704
53.72268172 55.031744 56.76290051
17.79059389 72.88820365 54.43033958
-69.25180317 25.44310353 39.1431036
-48.37780755 70.01067358 30.53382534
-26.45827593 43.99502656 31.20357091
56.11698697 8.59788063 47.64022082
20.77620815 37.95294908 22.41682424
-71.32301834 56.64084737 44.45029469
-49.45560812 71.16900553 34.77252906
-54.84778838 13.59353963 22.46189139
22.50835691 48.51957967 45.08735947
-12.83987813 49.63708163 27.20241707
-61.54659162 84.66979842 26.01731613
-46.85657771 9.358650012 0.1799146165
-55.86540421 8.377608574 13.28659898
-61.15262176 11.99663028 24.72242783
-24.34518656 48.94237239 31.63460005
-16.38801867 62.97307618 24.55777397
18.95773986 44.92534473 58.35578353
15.42942188 36.39975552 29.81381234
2.469555112 67.20636855 53.39135127
57.42259198 54.39759191 57.59236494
-63.26273068 85.88330797 23.91057109
-67.90218981 63.17849985 43.65041237
20.31567961 32.89054184 23.00635883
39.54066282 32.10604364 22.62042594
-69.35168384 29.26343707 41.42605901
-72.38195887 56.67946315 44.2039609
-46.83624084 21.85609408 24.06917443
-20.82605225 52.31136892 26.81355416
-49.72528352 37.89288572 42.38578519
-9.621973758 60.95236763 29.24538624
-52.09875403 46.81682336 44.12237561
-57.30155904 49.7290629 46.26702541
15.10965764 44.88424623 59.56673737
60.30626945 8.752738049 56.69688483
-36.9208069 67.68501829 20.38696346
54.6791672 52.53373061 56.44605094
-11.17955546 43.46673467 28.59598373
-41.59131413 19.59826763 20.14622751
-37.89660854 68.16099148 44.18081882
22.89542461 72.03425045 51.71684758
-74.02905032 36.34957568 41.62996484
-40.90605295 44.09749769 32.76928925
-60.2472309 9.015516144 18.37022559
-39.20455334 60.77865692 31.07688172
31.41032472 25.82648256 32.97560994
3.556746281 55.46052524 61.35224044
12.05127639 56.70579983 27.7962983
-62.59414071 22.03809406 36.61479565
-37.78671514 60.75722134 31.05533134
4.001282617 56.96887486 20.4508714
-9.176912786 56.02781256 28.06193067
-79.39182351 8.804585705 18.64320788
-22.99190843 56.0340836 30.62373475
-57.03297713 59.38346223 44.84666863
44.42023806 31.11793995 21.50720841
-49.93609272 85.70123202 11.82948811
-47.12445686 21.40681351 29.33971658
25.43457884 34.29279348 23.23171632
61.04898927 5.085568345 58.33993999
-68.29602036 54.59171311 44.3543105
-68.58089125 38.66450082 42.85083238
-20.92489822 48.69705156 28.00355228
-37.66835823 68.29177334 19.31251124
-14.01875908 31.10465162 26.97949648
-40.04635907 56.62899715 44.44985502
-59.53315875 39.31712226 45.20255177
49.98680488 11.04782857 43.67506259
7.579504829 36.64955512 34.23762415
-55.89522887 7.43111807 6.799915528
41.75580872 45.04588123 52.60877843
-49.91339262 9.898058167 6.154306777
-36.51244483 52.89664014 28.83742903
-42.76693009 42.6689861 38.05409387
-78.25092641 4.57635128 15.07029439
39.93023268 68.57315641 47.86062614
-76.88568355 4.990281535 10.00472908
62.46775021 50.86443698 56.85917754
-70.73598863 85.4466193 24.57852625
-74.48280446 34.24083266 37.06312126
18.62150717 43.0737012 56.60857067
37.1094498 35.60906625 23.20491613
-65.77649544 79.65713791 32.57483875
-59.44005457 41.78123683 45.71276051
51.47674554 39.3323186 58.81573433
-54.77871598 17.53352812 29.15022614
-60.84189743 52.8185784 46.0366838
-71.1147245 26.89837104 40.08448928
37.68019036 21.48304235 35.58860615
-65.82529362 24.4446499 38.42714414
1.633720581 58.77487204 58.20346136
-61.38704006 82.93399889 28.65739415
-57.33527113 52.75936481 46.05534378
-63.43380224 70.43327242 40.15254138
-29.2740191 76.95404268 -7.099956585
-5.982075233 61.84367601 41.51084315
-37.20272706 58.57100014 27.19000754
-44.77105068 41.30607341 36.77805401
-40.92863429 78.96667613 35.85158722
-1.844367341 39.27430361 28.87137975
-51.48204158 81.38643586 27.25376977
-22.34555633 51.93808754 31.43665608
-8.388004026 62.33876699 36.22228989
50.02939756 65.24089592 43.83147967
59.09890826 51.05236935 55.31997955
-78.34032945 4.999776581 15.99855521
-57.53003762 87.86701074 5.615680628
-77.64178242 5.167735864 13.91554382
-76.2449679 5.652462572 8.022100583
-70.62452868 38.20206482 42.78222001
-55.62712331 53.41581333 43.93569681
37.64553148 71.02261828 48.99439596
-75.95406136 5.827829536 5.930834923
-71.3192394 60.65666932 44.44410921
-22.06315988 57.56779006 30.12215557
-13.73349586 78.1624006 22.52200374
-49.30485017 64.73759372 33.65796887
-59.39360239 27.18688079 40.32661021
3.019390867 57.99489085 61.16715625
-7.142491042 42.9102868 28.45910346
31.53735665 70.62827263 48.91047281
-22.31791216 50.41749462 31.60361393
-62.11625423 30.58913851 42.13643219
-49.7524055 86.41478189 15.16433242
-60.13155033 37.36233277 44.70272798
-2.183607178 51.89025927 39.80016007
-73.31057495 12.25724701 24.01559306
-78.920412 5.360997486 17.74250572
-72.39382412 72.74970232 38.27702576
-10.94637997 69.70546433 21.11187683
-72.51526947 59.66089519 44.57859038
-48.75269903 23.23925635 25.85782033
53.37463816 65.19541723 49.54811377
16.92528089 49.84924001 30.71641564
-46.57242928 43.43739406 41.51286738
26.39458055 32.79743842 22.9278884
-49.51166214 72.07782425 35.88626993
0.9464134542 68.65702336 48.49484475
-74.00742755 47.21397584 43.69005515
-53.9049486 84.74806143 17.82483257
-55.76477271 10.46625825 18.15404347
-55.8182699 88.90661892 10.70062921
48.7846943 68.34361737 48.83668399
19.90479339 31.38147875 34.77638402
-61.18251235 8.235099717 15.95495065
-50.986241 19.76686733 31.63395171
-62.66030351 72.54750926 38.7679897
-79.10065482 5.817857158 18.2999596
32.43851176 44.05845988 54.19506889
-76.41485755 6.470037503 10.21677522
-37.80128419 67.51637958 28.33919918
-52.71006639 42.84729862 43.70696957
-38.33864854 47.0545829 32.91151197
-75.15318389 6.775156236 6.434124943
21.3485057 45.46045352 57.51455747
-48.8261377 33.58171874 33.99507504
-16.6968607 57.37483282 19.68547514
-59.94902219 41.79020235 45.7057454
-22.70996933 74.59588629 7.370160657
-4.342051235 67.15354461 37.82365916
-36.722514 40.44720699 27.73357383
59.65697339 69.32141464 52.89221424
-57.83095806 51.75719895 46.13046516
0.3148919083 64.93974151 50.9817145
-48.06793362 61.06890954 35.22435885
-23.74897848 42.47209125 30.8863135
-53.76086943 40.88533434 43.37671364
37.98730928 45.28675004 50.53127262
61.99131356 38.95913401 61.14274258
-62.4745305 55.38511185 45.68704778
-49.76184766 86.81599057 10.50860923
3.531486698 62.02685339 60.87665983
-38.22759477 50.56363072 32.94123152
-11.55109052 81.95126033 7.255158986
-60.64082966 37.37145552 44.69394705
-73.67270144 11.00760389 21.11149892
-10.62564741 80.60868729 -6.468967532
-78.73816007 4.462852954 16.48078439
-44.5247711 24.59539323 26.78842538
-62.49886391 65.68948566 42.60381421
46.31426571 45.80074434 48.80309169
-44.26028368 45.41303983 35.61711107
39.18005988 70.01222091 48.77790048
-75.86083706 6.977728289 10.04411589
42.12327359 13.62881301 38.51929688
-46.55839449 47.5223761 40.70186507
49.05811027 12.70083603 42.14277566
-31.98516185 51.59068998 31.48119028
-55.90189854 7.130489333 4.464463992
-69.49422349 12.99667174 26.35729221
-50.46022044 81.50855281 19.81463759
-78.41950926 4.537502084 15.55343405
41.98626781 49.97656635 53.59224618
-25.42194149 44.47003025 31.31838718
-65.96363865 47.87747035 46.10701007
-18.22950805 23.42258136 35.3886184
1.813455007 51.81996835 51.50716832
19.90700737 45.44426853 57.97734711
-55.67445121 68.30128643 40.11587239
-41.70044749 20.45952818 21.82218103
35.31787864 21.97517896 36.15267319
62.63501629 56.27965956 58.05647315
-69.89074565 76.99144675 35.17153525
-52.69116969 55.4055559 43.5839724
-69.37116621 24.49888307 38.47378512
-47.39807299 58.4677029 36.16011319
39.75899853 45.1611511 51.65144519
-13.2321738 82.19128103 -6.863867823
-44.43807597 25.05387669 27.27612245
-77.833947 7.075312795 15.11420934
51.06511723 40.24705853 59.92854753
32.95370783 36.72759596 22.6660136
-74.37056751 7.384804617 10.86091447
-75.8232015 9.587982782 14.69504551
42.93771757 16.57816031 39.08564705
-51.75625366 26.79653079 37.57494833
-49.69413122 30.98815506 35.13538232
-49.63302893 63.3783735 37.84609367
-42.64364912 42.15413783 38.18067708
-41.11992231 25.97027917 27.65834366
-25.33241719 44.96425334 31.3906529
53.66647735 49.77683911 47.85669793
-55.68387663 71.46800427 38.31594314
-18.51298547 44.14977704 27.89189938
8.987425453 60.52795085 24.79676032
5.541575404 34.454591 36.80045173
-41.63987641 28.72552647 30.73242646
41.27304722 65.08551363 35.65187612
51.6556518 44.40050942 58.21276675
3.799730761 58.75729784 23.03455334
53.81890827 8.716455514 46.70197428
0.01800227727 44.77600518 28.74149738
21.28047896 32.39719871 22.72281205
-40.41438556 44.30879983 41.87585911
58.11009871 7.331377233 50.30933684
4.884947667 46.30535263 27.53948284
50.34035392 8.98450916 44.25359271
-5.470479402 33.26142805 23.61841643
-64.06164607 71.506636 39.46516885
56.51213114 51.09357483 55.33495023
-49.84849403 12.68039398 8.802391291
-26.96475 44.00273422 31.21068237
-79.33007755 7.266790444 19.00335963
-78.6181118 7.403202926 17.28400757
-46.14465874 51.29996229 37.55647141
-76.63722259 7.841077044 11.82307691
-3.963623802 67.06176177 38.8169731
-69.29454293 55.60428364 44.44335881
-74.71598674 7.863041377 11.2017076
-54.4027281 12.56282383 22.68903518
-55.89011768 7.732532967 9.126537959
-55.87839722 7.776176397 8.617657932
-55.8849137 7.878574992 7.430182853
-58.51307434 30.53077505 42.12680978
-68.21540091 79.14631271 33.14334355
-66.57908271 22.56824927 37.04696045
-6.617810758 67.70686217 31.83434515
-37.85432678 47.80804433 29.89905913
-64.70497766 81.8688203 30.00223372
-55.79732322 21.97928988 36.00124359
-10.196724 58.70643715 26.29418976
24.70537773 49.35730314 46.59675103
-6.620896116 80.0809284 -6.932728226
-63.86606655 69.91371856 40.43969638
60.04583775 38.99895301 61.05150843
-10.91789769 61.25919464 25.93065438
-9.114860514 53.46065288 28.71835568
55.64145669 46.24472087 59.35026115
-8.484015105 69.65934286 27.00434262
-46.57445801 71.40052982 31.53537296
-57.70735597 10.23206173 21.24323033
-43.90279794 50.19865502 32.43379014
4.547278263 58.01999651 19.7978412
59.72202218 7.212037114 51.38732074
-55.95561182 7.871341242 13.35428189
4.708518262 33.52349704 36.11485703
-62.53285733 19.24166262 34.10627984
35.00985714 48.52020518 54.34927031
-16.85159701 49.5727578 22.88755229
6.828248151 44.20821115 28.20746922
-10.81531987 48.00116642 28.32729675
-12.60769368 81.10335323 23.1264275
-74.41953168 6.882510265 10.87461075
-49.65145785 64.59032468 35.43367577
-47.04500278 31.16327558 32.59725041
-45.29298534 25.49178683 28.18221926
-49.49928764 41.3687299 42.7790367
-59.10679375 67.72170081 41.67205286
-66.72171902 21.63576879 36.24579399
-6.534106899 45.86446768 29.00863873
12.21296469 45.86321727 60.40782575
37.2218662 46.37996859 49.63965001
-69.87118697 84.25372451 26.60831519
56.86595127 53.42617363 57.32217019
-19.21067353 50.08231031 29.07546769
-31.83955418 86.0033592 39.83090666
27.12359272 75.11991492 50.04971044
18.43034943 34.4695426 34.16891576
-20.95184807 48.80975758 26.70031782
-64.95886131 11.19016006 23.15711339
-50.05100265 73.63353702 35.40919879
-64.63367093 72.04962619 39.09747188
-37.72904756 67.92594185 23.57092469
56.52103288 66.40334372 52.40550839
-61.12245055 16.00579782 30.61510014
57.76061844 41.42389839 62.46998248
54.2067637 66.98274606 51.94736898
-41.06250612 57.0739086 45.30542456
-15.74374426 43.53909105 28.62643627
52.48012216 43.84167576 58.71624348
-19.50768657 48.57682062 29.12901025
-60.74708516 35.41619104 44.10578404
-63.81639252 28.69455798 41.14776852
-71.86248673 15.71794535 30.17825014
61.08184353 54.77599285 58.32841039
-5.574148981 43.86691868 28.708012
-63.18464974 8.266334351 15.97417584
-46.12655808 35.46769733 34.89299687
-9.508851546 68.82499021 25.23227746
-50.13763944 80.44050914 20.49846814
-53.1961155 23.51178666 35.19475913
7.576161239 54.72435972 28.34516902
-14.01171929 81.06826546 23.80150808
-49.18348424 9.367877562 0.5165495737
27.28335316 71.12070845 49.83182339
60.70640163 8.674784303 57.52552133
16.61255423 29.77634293 36.55789943
40.77832895 34.63701862 22.1361952
-6.95964809 81.62217861 -7.280160822
-1.50734543 60.92444365 51.32860701
-56.92949003 88.90873307 -0.7652806956
-61.09250237 52.8211045 46.05515091
-6.179669411 42.40555237 28.30817224
-16.04945146 36.22293546 37.86967987
7.704342784 56.56279345 24.45876556
14.57225071 29.8144412 36.50472509
-23.97383054 74.8617617 4.524710367
18.25044442 36.95894486 22.78434727
-78.95364237 8.88088349 17.67392358
-39.0335229 52.58259223 32.96391183
-67.02413461 8.710726106 17.37420593
55.43187856 68.4412868 52.26184955
-68.84376704 20.73544813 35.4487787
36.02914246 18.90042654 36.75115571
37.05613711 17.39605536 36.5392176
-57.44796571 7.831254164 14.10424292
-57.39988042 42.24541985 45.76175264
-55.85180798 8.674743268 15.66129099
-55.85722171 8.87569701 13.32950534
-12.24549229 51.05450897 28.11445594
-67.61057902 73.16817244 38.3335666
-53.35115972 9.246875363 8.542674313
-52.83937227 9.286043118 7.990385393
-49.79148338 9.606902537 3.684363704
-55.78029499 38.30270936 44.60962151
-49.6560873 9.712048207 2.437934989
40.36036693 30.68146042 21.52178233
-50.45045449 9.779187379 13.46330863
-48.01249735 9.774957054 1.394200546
-41.08275539 22.37980384 22.71898583
56.46831212 4.979479263 48.79177878
-30.95096747 50.56012512 31.59423038
37.16191567 43.87435211 49.60490323
57.74748483 64.01692676 50.74168156
-53.83650532 71.55200675 36.98847603
40.41589644 42.59126123 52.22612355
-38.32700794 51.06643911 32.94986443
58.68049218 6.187882545 51.82179873
-67.660191 85.3817326 24.74520539
-48.70231433 47.36496412 42.93807687
-74.92432639 55.29072994 43.33031137
-46.70105987 9.807194338 0.7698675279
-61.44709226 42.8069865 45.84114564
-47.4957781 31.64510255 32.91645784
-75.11743782 12.52447824 21.25795707
-48.06568955 20.88383661 29.76367194
-78.59299842 4.501127836 16.00877836
-55.62930995 71.037374 37.47796639
42.90294747 17.69924324 37.73121067
-17.27105326 48.66268628 27.70567937
51.7062967 55.13211035 55.98231116
-36.97703148 51.87878603 29.08874011
-10.74136874 60.71748591 26.35889868
-62.34565025 63.60333433 43.48559913
61.0066574 6.11135432 58.09322245
-22.42757468 21.09650489 34.05880353
46.75761878 46.22798209 49.58549501
-16.83061326 48.6072964 22.43804927
-61.43731665 43.30427131 45.8930773
38.11953058 34.08844926 23.1847968
-16.32384936 47.34723193 25.31587291
6.377951323 59.27218038 22.39237972
-79.16405226 9.464461003 16.76609708
-78.55524217 9.529020143 15.90054359
-43.77828195 28.73877783 30.98640172
-74.90546808 9.471930517 15.86724782
-58.96167017 56.85417949 45.4430376
-72.80961403 9.207587465 18.53624338
-70.86967947 9.170563696 18.59608515
-70.35738709 9.159921037 18.62193569
37.03755576 69.57487945 48.43682674
-54.57540847 35.02185026 41.67996257
-73.43054098 51.18847743 44.05425152
15.69204311 31.75399336 37.08200063
10.0715245 34.36233975 37.00748319
9.06947547 51.19889448 28.20007222
-61.26128728 9.031490518 18.37817004
-33.97233496 52.20331453 30.57500391
-49.62817189 27.20971068 32.37261581
-17.7757879 27.26099029 37.35616652
6.077652954 61.00170164 60.63851844
-36.3253849 77.67862006 44.09977539
-57.20367644 8.966894213 18.35424036
-20.8879706 46.47133683 30.52766782
-55.95541652 9.006222987 17.65963248
1.416623876 60.63029426 25.05248059
-66.30926744 61.59926337 44.19963953
-20.88808906 46.42184087 31.10227563
-55.80795366 9.154837384 15.90628576
-55.35928729 9.275401703 14.42112355
-53.27528466 85.32909492 16.78633188
-19.67907268 32.45035379 35.74362426
-56.78266852 87.11168048 8.414937292
-20.07164189 23.91248648 35.87938925
40.71657909 17.16013943 38.57983391
26.23153667 70.11986172 44.17104677
-39.60589978 45.80406032 41.84352364
-16.69684643 24.09321872 33.13772325
-69.76401601 60.11327072 44.62904545
-46.72052893 10.26967564 1.231400839
-10.22935485 41.97632368 28.23663055
-61.38112191 46.28922053 46.19073695
62.37681202 6.771514372 61.82158845
-49.27279171 34.06146675 34.33763055
16.66809095 32.76162409 36.85183123
-64.96782254 83.00584204 28.50626317
-13.49239812 31.34800861 35.70733387
-55.51680253 10.86816584 19.26775853
-55.72166316 23.54688334 35.26896829
28.45613428 35.76191683 23.08075422
-0.4278493391 60.70201383 53.70486866
-54.1370066 63.66240548 41.23442019
-57.47862877 57.85384051 45.20869977
-37.70565961 64.65306932 26.60051252
39.91880475 42.19113143 51.1392796
-49.82323605 78.00714967 31.20680117
-64.11754687 37.42797068 44.70090869
-52.40973288 85.21458735 17.95055357
-23.39202781 44.43717305 31.31270081
-42.41740732 53.94452969 41.10543973
26.90057184 73.51515593 51.24153292
-38.04921733 78.95795509 41.23034711
-70.48632539 20.29369214 35.06360936
-33.05257629 54.14466046 31.16960376
-54.28869325 26.83510405 37.61011322
51.32843354 56.73025663 54.98184619
-68.02161737 52.59309605 44.19682196
-33.06572044 53.14685886 31.10203786
-76.88439857 10.08039716 15.0077576
-23.45854452 41.48220849 30.66892561
-63.28495775 24.40209343 38.43671338
-53.84985277 78.60788301 30.82759098
-35.54848445 51.87672634 28.84021805
-62.26437578 10.30080178 21.31429674
7.768448375 59.59330783 24.22596292
-44.04049168 20.37627148 23.23494624
-49.84502889 11.97820716 11.12658699
-26.08592259 75.30774084 -0.2497531669
-56.81436229 33.40572923 43.38813448
-60.59305267 38.35178691 44.9577153
-52.79713176 31.60041684 40.27284414
33.97355796 19.5475606 35.45740431
58.36771877 38.59991312 60.17724108
-74.72897304 9.835559527 17.43895938
46.18949086 67.91163525 48.51972763
-64.1244409 10.75019645 22.278762
-49.54102865 23.16614279 26.85691532
49.24141209 42.95953225 57.92116867
-70.68448858 9.579361868 19.64178168
-70.18324407 9.574292206 19.60504367
-37.56577374 68.24668447 25.64301417
33.45252397 42.12609617 47.30077337
-69.67746266 9.566933947 19.59400816
-19.36414856 18.7745573 31.2962471
-30.53597183 46.07861869 31.09991747
37.81280058 45.82743967 50.11447346
-50.90996712 73.10622745 35.86776192
-8.218967622 56.52997007 27.87647474
-37.75427495 65.17980891 26.32164704
48.60384838 41.52671881 57.19597724
-57.52908092 9.385479003 19.38370026
43.29516515 30.18548355 20.89311914
-55.67471524 73.64569197 36.34057113
5.623392933 57.01784106 19.57308972
-55.83146926 9.52024851 17.49545243
-63.60147088 9.475298024 19.49905673
-53.77072668 9.707229569 14.93184346
49.51384409 64.99874612 40.91420901
2.476395962 65.49804684 55.74156959
-52.80526229 9.728155908 14.50479533
4.025293145 50.39357471 55.99069475
-14.99442705 33.62565931 38.68635317
-55.66109806 32.58765708 41.01169648
-13.87701952 30.63670672 32.38471262
-51.07684118 50.31239804 44.13479495
45.91252548 50.48044598 52.82072362
-47.3826082 10.70942583 2.079363246
-12.90214105 81.72746477 -1.542488598
-46.51641589 11.06537439 -2.217936109
17.00543999 36.91797389 23.49739817
-27.17119134 76.51273682 -2.377977178
59.22533744 4.151954784 52.04586523
-18.18567349 21.08761213 33.35308105
60.75657148 4.129532356 57.84078745
-25.38911079 51.98670947 31.45264132
52.01258971 42.29831375 59.24195471
-71.49726482 20.3080942 35.08921201
3.481476186 46.26998535 28.21770265
-55.77853573 9.543102385 17.22005319
6.983035832 54.77577786 27.86138222
-1.677340814 52.23620528 41.51425947
-55.97440352 86.36372758 22.77022267
-19.10391675 25.82963615 36.74572935
25.91287811 33.29486629 23.07188714
-55.81499604 87.90719434 16.47541482
-46.1355085 49.78869846 37.61865721
42.4774672 14.05147073 39.37187179
-37.51514604 68.32025721 24.77927296
-13.99565651 30.57173384 21.50830644
-13.7064196 62.93359435 24.50471078
-78.51546368 4.964232168 16.44457991
53.02986009 53.70113847 54.86174282
-64.49167267 72.57932391 38.74791505
-65.69255024 36.96452613 44.55463291
-59.38553054 32.96162278 43.20734255
-59.64967119 18.26950497 33.18867255
-75.24555225 10.32580281 17.67301327
38.13555475 44.75930865 50.79928502
-73.30589378 10.11547333 19.74476719
-37.41302484 68.46696594 23.05669558
-74.62744907 35.90143789 41.11975268
-67.00847238 83.044115 28.45112681
-11.58986739 61.75348967 26.1473065
-72.89938112 10.07146779 20.17809125
-72.51344613 10.03784247 20.49483843
-72.03271154 10.018341 20.62954684
-46.70105081 9.955856895 -0.9559179293
43.66486196 68.95070053 48.59224327
-55.70614542 87.61596708 8.18215186
-65.45575231 9.921775174 20.49628778
-10.9154201 80.81293302 20.34846917
-63.43300764 9.892534309 20.44998753
-17.68198668 57.0045457 24.17190838
-36.39421666 78.71777366 43.70293035
-0.3588987412 67.84026487 46.57202218
-59.38142291 9.830977821 20.39191864
-64.44797709 44.84294823 46.08506639
-36.34875291 78.19262386 43.96394142
-56.84984914 9.792841318 20.35184869
-54.2331754 10.207334 15.0410989
11.61265964 42.11501277 28.2878641
-50.27357547 10.1921784 14.46191659
-49.65201846 10.47330816 11.07982198
-49.74776918 10.67027586 8.811537047
34.52288825 36.17715686 22.93001938
-49.61770959 83.43265104 14.79755104
-38.29328812 42.58609059 32.33661324
-37.32430736 54.36682346 29.40521795
-77.95548192 9.071150509 15.27480959
-0.3881638594 59.72711852 53.36131491
-51.097476 86.32517691 16.46104569
-1.482419152 58.52467897 50.04886861
-46.70377213 11.53821128 -1.844600917
-60.06297509 9.424786011 19.41062466
11.7394356 46.35652217 60.59812825
27.14509811 46.5141901 55.82985077
59.7194714 5.055792607 53.11250379
-64.87915181 28.23288406 40.88324987
-49.03213336 37.45558102 35.67686647
-48.72350852 17.02449419 28.07805797
-15.93076277 51.1666712 27.51519795
-50.75960061 16.13036955 27.19272237
-48.0497676 66.8442896 32.26911711
-10.7448471 80.92511187 19.01368573
-58.69522996 68.76630182 41.120356
54.58916692 43.54590092 61.7476067
-57.42957907 41.25596901 45.60040202
-71.89096644 56.66717612 44.25296245
-46.63101199 12.78515548 1.146008113
-63.71145403 8.271908483 16.00993198
-40.70786925 54.60693585 44.74300881
-43.09563785 30.14885088 31.9669832
-4.993841481 59.35442536 41.08621481
25.94947784 34.78305197 23.26888464
-71.27928909 67.41310362 41.74920132
-36.91476111 58.32662693 29.97195364
-11.12906003 52.98336719 28.8165153
26.73054138 48.95517424 50.87874669
-55.00545753 13.87795616 25.01688645
-39.25387615 64.56202704 45.43257761
-58.32490816 47.23864479 46.23957079
55.31432252 55.47440701 57.14725063
-49.69834326 56.70906741 39.53439062
-59.03881441 78.97728141 33.35555214
19.6803668 73.93047123 53.62382756
-47.58975363 45.76787422 43.78616752
-12.73649417 43.0065665 28.40822837
-49.47860092 53.58492425 40.79997312
45.47863106 12.84635532 41.13610954
-18.36290138 60.94453156 25.17665888
-76.49188 10.84966003 17.65601936
9.984065438 46.39955553 60.43332512
-75.09803183 10.72882566 18.79293796
-21.48882133 41.45493598 30.60988683
52.66887009 9.85501242 45.35737552
17.31153987 72.3798635 54.59624068
-30.91754922 84.45283768 40.1747375
-74.4055895 10.65208586 19.55173676
-69.84919211 10.4102148 21.49062047
-34.93487576 55.42959918 28.2653444
-49.58874009 78.40919527 26.49484191
-67.32104699 10.37380785 21.43112648
-37.74666862 63.7188796 25.79980731
-56.94827967 7.826970662 14.0586758
-23.45155448 42.96055265 30.98581736
-63.27181729 10.31343934 21.35971584
-67.63337981 35.65640936 42.63041194
61.87725462 47.84501773 51.23692369
60.98207512 7.227298093 56.79650227
-42.58304053 35.41465213 34.83301414
-59.04930908 38.81861124 45.07071757
-62.14180575 16.02444514 30.59302563
-62.50284185 56.4038253 45.51975948
-56.68166296 10.21021736 21.30120987
-13.08519247 32.64924552 38.00389263
-55.70814351 10.51202572 17.61194157
-54.65181283 10.69772716 15.25473485
43.60932893 33.59207439 22.07350001
-49.63467637 10.66944188 14.62629287
-49.69827326 10.89974558 11.9648873
46.51265712 45.75022331 49.35174228
-49.72759271 10.96409121 11.2235075
-41.56562122 34.46688287 33.98810147
-49.75656582 11.02826215 10.48408972
-29.45669379 86.0402398 38.94834386
14.69000432 31.76390557 37.15802728
-49.95955079 11.47754602 5.307184262
-80.33172618 11.31235296 13.01702317
-52.118803 69.92221509 38.10078843
53.25087502 61.38212841 41.39938648
-68.87760221 86.00250348 23.5976523
-75.10919286 9.878690807 17.01077013
30.27863392 37.84255185 21.88622627
42.79291629 30.19105985 20.92416728
54.27879857 6.171610773 47.02348105
-49.60218376 64.96857343 36.85993255
-37.31054379 74.11494947 44.87085394
-45.91667984 24.12631076 26.67268145
-16.75122664 29.75079695 37.39048823
57.18802578 5.843859034 50.27345587
39.34012146 51.57914601 52.97289041
-49.96956811 85.76767407 11.06456403
-49.69587632 76.91369432 32.22285906
-47.57672989 72.61987343 35.05140494
-69.63451017 33.21322266 42.24112919
-52.87205005 80.84206125 28.01170968
-78.11089183 5.534922022 15.56908206
-13.81766451 32.35453331 24.08488896
-45.84958403 19.79552136 24.49508152
37.61610532 18.3162394 37.40354229
-41.38339403 27.80232599 29.7474167
51.7112247 63.34998582 42.15524565
-70.35326971 61.66938389 44.15684493
-63.962014 33.04018108 43.16814325
-59.87183964 12.8505406 26.21864284
57.89834871 56.41424342 57.39743624
-49.92437837 13.76600196 2.040972957
49.50924834 66.39048214 48.06536268
-53.5123586 24.93018213 36.26922168
36.55830909 41.97174899 48.50025647
-55.93132065 28.27835773 38.64895296
-48.10469641 44.7754218 43.75220039
12.10916417 55.67216682 28.13114339
-79.66021574 11.8474995 12.50323122
5.208731948 33.01673135 36.07572844
-76.4836975 11.34774847 17.69892572
-2.309293737 68.78122701 41.84723916
33.53530569 33.16648061 23.10863916
-73.20500335 10.94512286 21.74763167
-69.73545345 16.13831307 30.71931775
48.13352682 11.66724455 42.66450174
-68.17190432 10.80967398 22.36017897
7.960358193 68.12368245 59.17529662
-70.77056734 25.46589458 39.16816583
-61.59123938 10.71123885 22.24791356
-63.45685838 27.25432474 40.31855891
-58.05748829 10.66312025 22.13260176
34.04362644 35.66892153 23.09474327
55.84616011 9.211934933 46.39011933
-56.90859131 32.43644122 43.0050253
-49.52854077 55.40836829 42.94819171
48.7765118 67.84552894 48.79377763
-62.55868311 14.22587101 28.24512381
-56.95351416 10.63895447 22.20260128
0.7504394475 58.49521841 55.79169053
-68.14004121 35.17438442 42.49609163
-56.38131337 10.63525835 22.13638645
-55.81008417 10.74355268 20.77028981
-11.97692927 33.66451499 37.65983294
-55.78112935 10.85283595 19.49612772
10.45411116 33.82818744 37.30871058
-49.49925776 51.00993072 41.56311242
-54.12063858 10.90721934 18.54814002
-41.15335029 42.44969904 40.29201912
-53.65530831 10.92026629 18.30794018
-66.00660176 11.21073712 23.11805016
0.187083615 58.12851495 54.32944609
13.79764157 50.54160523 29.10203516
-37.88353008 65.69767079 26.16121893
20.64704297 48.96294537 40.29540984
-46.95759555 12.5220151 -1.563626251
-40.22495496 40.48619042 33.77560414
-45.65304312 12.43383622 -0.7887672368
-74.8492671 54.87454022 42.32079009
-41.53068955 18.86058832 17.04490358
21.73757248 43.50310678 56.85610584
-48.31955973 33.57395866 33.98855218
-65.14039839 25.85358738 39.42047877
20.39955258 35.89869582 23.02936231
-67.45150984 33.16508694 42.3836126
-38.91940165 61.26092007 31.25068001
53.14357835 6.775699015 46.0539179
53.85452038 6.689627211 46.91752145
-49.86955815 13.49056986 5.227935759
-60.3545794 47.2713797 46.24663057
-19.31013505 54.15021096 28.48440303
54.66598723 6.602402294 47.77534048
39.04045963 20.39949234 36.2545522
-69.11961765 19.34317251 34.18401364
55.95164732 6.474197769 49.01844928
60.79098934 5.618818655 58.0254242
-14.15724842 79.13281221 22.99085161
-23.60709183 57.08867182 30.15190598
-58.33656708 23.37927643 37.71334991
-15.21693997 53.06323246 28.66896414
-70.07798569 24.50854173 38.49645507
-54.72866353 42.3846202 43.63639603
39.93428482 31.15083112 21.98088204
61.82950023 5.367507739 60.7447749
-41.51798422 57.5828857 45.31035027
13.02915061 57.21029324 27.57992502
7.091641646 43.17002408 28.55598997
52.99158377 70.71479895 49.64120324
-60.15793764 79.54847182 32.76482129
45.31087979 46.05911464 45.99509196
-50.32954881 13.49480621 22.74640556
62.02349141 4.841768205 60.98430697
-72.38625271 76.50917371 35.41938003
-37.90776586 66.5590023 21.99351636
-58.55752065 10.66757846 22.17620686
-57.0730012 67.68688695 41.68834116
-6.50318497 49.36438157 29.15967328
-56.04904866 30.97937288 42.27589524
-44.26218694 41.2971603 36.78448051
-71.73134179 27.86696008 40.61127535
9.820778792 68.17632448 58.20939599
-75.93599482 11.78429265 18.35339307
-63.95827396 80.73549894 31.36297269
-31.10082208 48.05030422 31.62543684
50.47417253 10.49798722 44.13843745
-10.99793947 62.1057802 27.77141786
-72.54317182 11.31170686 23.19248294
-72.37119396 29.80214608 41.57481129
-2.8246831 68.3903273 40.65673497
-72.176821 61.19503887 44.1845094
-71.8527023 29.78837625 41.63578204
-37.48653493 52.89245389 29.07179098
-72.07085301 11.29645064 23.27951418
-72.68653511 12.61811683 25.53398578
-68.53259965 11.24606092 23.18970869
47.62530959 43.08098044 56.8195097
-49.64691617 44.30782878 43.64783102
-49.7162907 9.52607551 10.43496511
47.20956619 69.39927276 48.53553402
-63.3443622 23.92984344 38.10362376
-59.91918115 11.11333603 23.08784551
-60.68669388 10.27306806 21.33537649
37.73178252 17.32778861 37.20285827
-55.85602453 11.12027577 22.23241547
18.73555777 72.90708236 54.03097146
3.516617373 43.74117857 28.43403119
-66.57609819 22.10169451 36.63585845
-59.25558356 69.30340455 40.81877923
-9.61254339 32.36419392 23.17079959
-39.17086447 57.69881103 31.86371072
-72.20183067 26.44897869 39.68204038
-55.3586945 11.29062571 20.16002483
19.63918055 43.06829119 56.47729756
-53.19236186 77.49478638 31.97055997
-51.55386869 11.3535445 18.70401426
-50.00940731 12.25934919 7.894232605
-61.91084784 64.63621283 43.06555404
57.13097458 48.68488938 48.21886516
-5.623692046 61.25978828 42.39405143
-8.035499733 48.89179207 29.11141778
-66.31145824 10.36008714 21.39787194
-46.74556186 12.71904984 1.935256117
-14.38465308 30.58878463 27.21119422
-37.32408248 62.90438245 29.34785471
-63.3810431 84.70316792 25.97977947
48.92252125 63.64171831 39.30042407
-61.79467528 34.94686517 43.92720201
44.57614799 13.407375 40.6221334
53.58823016 7.202281632 46.84367881
-65.80093213 10.35033546 21.41371615
58.49732603 6.745328277 51.21213352
59.13559109 4.625108974 52.3969022
-55.75823295 78.45554448 32.95998766
53.04128155 66.09545158 50.81671336
50.77612554 41.22382482 60.29792598
-48.53981864 60.55695306 35.43087384
-39.31673058 45.37231729 35.14708269
-19.45682956 19.75140786 31.62720948
-61.94569278 54.86390702 45.81008043
-49.52499069 24.99347171 28.94745301
15.6741089 45.01222065 28.84026335
8.067568041 52.7153885 28.26649531
-72.75329396 67.98986584 41.16146685
-56.57614894 30.01783105 41.88541383
16.48730936 43.92820602 58.74914159
-60.05863557 58.92084066 44.96748787
53.83631981 64.65853296 49.86597189
-58.39606347 9.817090429 20.36521966
-1.995432447 51.84489196 40.29093098
-44.61740921 54.68929163 38.70589366
-49.5637588 78.19837086 28.93748188
-39.76423776 40.03651514 33.08126042
-73.97604823 49.20701093 43.85402871
-49.99881265 16.57092516 27.75998553
-47.31076316 41.34831541 36.77201641
59.77958516 7.939653505 54.58292334
-79.56099622 12.72754761 13.92135078
-47.73644404 25.98769052 28.71803058
-77.4805568 12.42142462 17.07829812
-75.95740057 12.19819519 19.37923357
-8.544846341 50.90981286 29.08845868
-74.15988855 11.93568092 22.08389493
-67.94195421 17.4835825 32.24034033
59.94928969 52.85526701 57.53501883
-17.59987901 23.90452103 35.50047786
-2.780074725 58.83237948 46.72432663
-71.41357844 11.71488907 24.12327033
-68.37916705 11.67087928 24.05548767
-71.86328445 21.24938296 35.88512918
-39.24828993 42.82671291 35.55205978
35.80694894 30.74838356 21.6132538
-67.87442463 11.66404513 24.03856609
27.60231432 70.32704718 47.33111268
-65.34409764 11.62653755 23.99143286
12.44705966 48.45443316 59.41566155
-49.47227986 38.03781673 40.65507059
-62.81217754 11.58822635 23.95332493
-61.29573376 11.56660579 23.91511715
46.61308876 10.79794828 41.39260194
-60.27592833 11.54773136 23.9397423
-61.51886309 57.40745536 45.33452042
51.477209 45.38345497 58.48932945
54.82374461 52.05769525 56.11800406
-55.87944034 69.78745012 40.38241501
14.80883258 51.02929229 29.07440853
-56.72666173 11.49178611 23.91232915
21.91119194 27.41708891 33.80215507
30.30999616 31.29792313 22.10874028
10.13291308 54.67352237 28.44773817
-54.56482353 11.7144929 20.91471027
-37.69504189 64.5981576 27.23594305
-52.81533642 11.74876075 20.18326628
-12.92188382 41.56865868 27.65594382
-51.39683429 11.77654596 19.59019828
19.34452614 34.39152231 23.2470096
-49.74382971 11.9338171 17.44924184
-46.72165959 54.3565427 37.14333815
11.65611025 54.64849513 28.44778985
-49.77152692 12.09645583 15.56649451
-49.81093552 12.36411369 12.46684175
-1.459555999 46.28465838 28.98965201
-66.8678445 35.10252708 43.0876486
-67.19383356 35.14308001 42.67904862
-51.20571765 70.96160667 37.51392318
49.29759431 10.5711664 43.51330062
-57.11303565 36.3342988 44.40796588
10.28579946 47.35089204 60.98525609
-72.01228723 45.18979564 43.5011674
-45.23390132 29.2319321 31.36574342
-72.17458404 33.29281374 41.8015851
29.49328161 35.23207842 23.20707413
-6.058954938 52.30225707 29.9297985
-72.3138217 32.78650132 41.87914181
-37.26190016 66.58009122 21.62553029
-20.75602448 52.98314185 30.65505142
0.05571319731 51.80798342 46.15486825
48.00767238 43.06154601 56.97219988
-49.86041345 9.722666182 8.180281816
-12.6250187 62.71020367 26.89176356
-51.54435426 85.10016713 19.11379424
-16.78981214 49.18804208 21.5151892
62.0785158 6.320659726 61.28568412
-64.49561473 32.55935652 43.02502567
-46.59451961 42.99587701 40.81589126
-76.66151703 10.79895368 18.27700665
-53.69269815 54.91299465 43.66633948
-63.42491585 12.90840757 26.22447373
-19.15848232 73.84504106 15.40923153
-70.66113847 47.16130932 43.66328912
-19.87420606 56.12150341 29.01433696
-39.59148509 68.55183615 45.79347293
63.97817944 46.70423294 58.25268744
-68.11848128 36.16794076 42.6113347
-57.9972303 14.15489478 28.19917129
-10.53433605 37.91421763 28.83755694
-58.23390668 28.1266499 40.84920551
-74.83935902 12.48331535 21.68277473
-13.4382264 39.70302854 31.93208773
-40.68323623 70.09367419 28.10289234
-71.22654472 12.1475355 24.89176737
32.18353945 44.63973865 53.32242629
-2.40472046 59.2443629 47.69678117
-63.6860657 12.03571017 24.75190286
-56.78390536 17.30613483 32.17237547
-63.91940886 27.74209158 40.57105754
-62.16615864 12.0123426 24.73331531
57.232704 42.43446457 62.49255091
-57.59999577 11.93899041 24.71404631
-72.92687019 53.19104796 44.0174619
-13.5986635 72.81778791 20.44740487
1.460671729 67.96972802 50.54875798
-49.64814461 12.33820995 18.56314793
-49.66135979 12.39443193 17.91300222
-77.42621039 9.200610001 13.67101382
-49.6744018 12.45056656 17.26383752
-34.95196358 80.240226 43.23406878
63.08384786 46.00893008 60.66818433
-55.15479782 51.39168964 44.03656287
-49.59330797 79.01282694 25.31495678
12.21793475 53.10621837 28.76459811
-15.93746167 74.79232248 21.27815308
3.167840492 60.08714009 25.19720224
49.71139396 8.588954469 43.13878562
55.12508645 8.10046266 47.77713215
17.14445233 28.82571186 35.83880064
19.93141915 30.90299839 34.49921464
55.89509855 8.046538137 48.25628152
56.31641825 8.019841175 48.48585118
-53.89133599 80.86067363 27.99002757
60.2080223 7.469094827 54.1371654
-11.30335452 81.0267419 12.11375884
5.613416565 55.36461521 62.0734149
-59.84383634 47.7637346 46.26025461
-37.90434197 53.08509391 32.7418048
-54.45813148 13.09298758 22.37171404
-21.73548539 74.87908921 9.723335833
-35.71415627 43.37306984 28.53556817
-20.66495918 19.1896197 32.55261326
-69.38816704 11.684303 24.09207766
-49.6085786 76.73245063 22.65693652
-10.76599762 69.66402782 21.5585022
-41.33858623 17.88314078 16.7019109
-74.28255607 12.8285465 23.39553512
56.87617845 5.877278293 49.94497183
-54.87990279 12.55575169 22.86213382
-56.33305002 53.25157665 45.97690075
-49.56059599 31.12567621 39.34011207
-53.30515012 12.57905131 22.29133995
50.53229795 44.83755574 59.18008557
7.342230369 45.70336414 28.2325453
52.61700191 8.802411238 45.93334813
35.30907753 50.11130388 53.30149477
-74.49323299 48.71790548 43.80391103
62.05642482 47.80419268 51.67668199
58.99346555 8.137981512 52.43050448
52.7639365 6.830927671 45.48518324
21.46511268 72.01242258 52.24301058
-55.75772488 89.48886496 -1.896696938
-23.95843224 55.02164856 30.9078598
-14.19075508 44.02602938 28.50417731
62.23064603 7.322684638 61.27771729
-7.291544849 69.85736243 30.30481712
-54.18224825 27.78584089 38.20624823
57.10372309 39.98844769 61.7790929
-71.90280492 53.17002469 44.06621994
-65.12894978 66.78174774 42.07889371
-45.16576979 44.77291948 37.39413628
-37.62392779 41.64045152 31.53334881
8.556326422 51.7121853 28.16591924
-37.81526088 44.56442999 32.58600668
-36.82248654 59.61901295 26.60467747
5.278906207 66.63166415 59.52718025
28.26805468 72.04964036 50.5135973
-5.37137928 60.35108006 41.24160018
10.04444043 50.17342951 28.26520453
-71.51606771 13.02545838 26.40869372
-55.9304924 70.84241277 39.79865626
-72.81154617 30.30349306 41.66542836
-41.57833308 45.47915103 40.16482152
-63.93138992 12.91611523 26.23158519
-49.7827421 85.37609716 15.57464657
-61.90230743 12.88367732 26.22118997
-18.20584341 31.29743322 37.1933324
-65.69702236 67.83734919 41.58631827
-58.32292683 12.82493026 26.22055929
33.16784898 42.14580859 47.12622643
-63.51865926 32.05988134 42.81034591
42.34814021 43.41388193 53.96133367
-51.94969244 13.06652118 22.20058093
-50.9463376 13.0559451 22.13200996
-50.44464286 13.05064833 22.09782258
-23.97913352 55.53430715 30.7871334
50.34853642 9.482597597 44.29649907
61.43143765 4.061658691 58.50001356
-59.31972979 54.3131075 45.87673551
50.6443372 9.457272872 44.53407602
4.893773156 38.27210324 33.3940091
51.21862235 9.415358529 44.91112874
20.82840788 35.89297546 23.01398324
-20.93416151 49.70615639 27.94417527
-49.82233263 31.08426188 39.87079465
-55.75844843 25.90307448 37.05680191
-40.60352239 61.53576767 45.8611454
32.05676728 49.12452051 53.72374963
-49.54529667 22.49263474 28.84966159
-7.375538897 61.62315645 38.50989561
-11.20882774 62.41704263 30.02491533
-69.90727641 28.79370609 41.15835294
-51.20977658 80.24466222 28.80310735
28.37769611 32.28597208 22.66053006
-63.94467467 70.4431988 40.13473514
5.355912694 53.37414623 61.92277535
-55.72916129 64.06337768 42.70991682
9.338575169 69.67843721 58.34365692
-63.02609512 78.5041843 33.78136617
-52.82237888 22.11394482 33.87073065
-62.98411725 8.65500467 17.25060546
-61.17436112 36.89059601 44.55122188
-9.842045643 57.59742441 27.44749809
-9.285056694 37.02293367 27.29270449
48.5171369 65.70640771 38.71587536
44.49034457 71.03871457 47.50219131
-41.41974509 33.99019238 33.66741219
-48.22251013 43.32820378 43.09510841
-16.0967462 32.68172498 38.20116883
-68.33703703 13.42310166 27.01294109
-67.8582873 13.41699036 26.99258511
-64.31210302 13.36259995 26.94770996
-6.041218057 45.36139693 28.92801335
-49.5399697 77.58504322 30.22625932
61.02632825 53.78717605 58.16462272
-52.38720338 13.54165706 22.59493385
-51.82135271 13.52877526 22.63656405
-17.46405172 35.83963309 36.76246526
-68.12328775 76.41532196 35.69590671
-57.47530241 16.40269315 31.13875742
-55.79248842 13.80418746 26.02333903
-49.58297097 78.35672938 27.10280412
-49.54043267 13.59230088 21.46412691
-49.60324464 80.85325852 27.2583427
47.31423435 10.25176896 41.77269665
13.56519976 71.78522611 56.3870344
-57.78842897 32.93648192 43.19461913
55.10645587 9.765175884 45.93540146
55.58612963 9.671875972 46.92701843
55.7460786 9.640746884 47.25788442
31.1956839 71.55727982 49.84432022
-49.61042764 83.57764485 13.11296807
-12.98349086 33.13639693 26.50264569
59.18602055 8.970947391 54.37738934
-1.940596368 67.64036465 43.36760764
-59.41253392 11.10554101 23.08171506
-17.39758334 22.99626562 34.35230864
-49.48013079 36.78290753 37.74454653
-16.18588393 31.22004162 37.70653238
-58.37112788 53.79076823 45.93288597
-41.92441401 20.44861677 21.99156043
-53.63195917 74.23246184 34.96599309
17.74798261 71.45260616 53.62400645
53.47771843 58.31727676 53.62859915
2.466727148 46.28630913 28.22172305
-54.33487037 62.6215058 41.70239545
-74.58919736 43.24193755 43.29819426
43.53364015 71.02575827 47.83504683
-64.05279902 19.26504766 34.12467119
35.39068415 23.14110777 34.25711765
-66.43998961 35.53758276 43.78225138
-3.48346507 50.83443084 28.99831246
30.01725201 29.93894716 20.46063026
-21.3194314 50.91159325 31.50398104
-50.90616404 61.53522154 42.00561937
-17.84865754 50.13858944 28.16239367
-61.41462753 41.32112867 45.60395377
-55.6621548 24.01908092 35.60264652
-67.23640749 13.85328334 27.63582246
-66.22020387 13.83622582 27.64004257
65.14847644 48.23998176 57.68133423
-63.18097852 13.78978766 27.59953203
51.39775144 52.65278379 55.68978032
-62.17381407 13.77728986 27.55254332
-53.56548305 13.89490984 24.5454639
-70.46639467 48.15422714 43.75291747
39.15253411 18.76197576 37.76274408
-41.81579106 51.14142973 32.74465045
-49.5834247 14.04187028 22.08003705
-52.13725063 53.36505094 43.85944391
-50.57255097 48.2969279 44.12910851
-14.67640726 78.64470256 22.9295504
-49.61822242 10.20182258 8.398338358
-39.5561191 57.71970422 31.69463764
-38.20449088 78.9867257 40.92596843
-77.36730568 6.207155615 13.45013813
-16.86630319 48.3279656 25.68753178
-7.978043881 30.01971965 20.94225425
-39.12940576 58.21820931 43.30617442
-67.09706889 12.52412573 25.55915836
48.08838331 10.66080688 42.70329213
51.67813116 56.1755281 55.52820947
50.91673446 10.47296374 44.34452909
53.76011667 10.42620546 44.34508336
4.902117502 61.10873473 24.66033074
36.65099187 43.92560082 49.10740694
-15.0236595 30.12969421 21.00923047
-10.07851179 50.4379047 29.03255129
-54.68912932 9.687341213 15.33786697
61.19901891 8.072739123 58.59391537
-68.69826885 9.140663215 18.52909072
49.06087171 51.88561087 53.38805829
60.93779626 8.130176936 57.97695194
-71.67656697 14.36529029 28.36547819
23.91277843 69.29399187 42.5472658
-70.66455393 14.35034666 28.34595781
-71.70000639 75.40634952 36.43761511
-66.46535955 14.2886634 28.26121057
-51.5982806 46.30992664 44.08471853
-62.05577623 14.21996278 28.21780351
-56.98154618 14.1380993 28.20044836
36.92036669 20.90572211 36.60882301
-55.76768216 14.2938902 26.16042252
-55.93524338 7.228302333 9.16198001
10.71197403 56.73717443 27.68749201
-53.93778037 14.3371493 25.30926584
26.48767413 49.00335284 50.3657705
45.91855226 11.34079371 41.04994917
-69.64193566 16.5933549 31.24566668
48.38054814 11.1395323 42.91682139
52.61574795 65.13223108 50.42634984
51.42074872 10.99349079 44.03239356
59.80392259 57.93836744 56.82081055
52.40883048 10.99061907 43.87729736
11.28604892 68.75706759 57.01491104
52.8105971 55.74627422 54.46869276
-37.39298846 46.14301193 31.66006806
-64.19468873 76.34147092 35.80401603
-55.69722342 64.59903865 42.31212347
-65.73473328 55.94612466 45.62278282
6.01019893 43.72220689 28.17872727
-39.204825 50.20747959 31.43541638
-72.88647661 21.29474365 35.553678
3.7003315 38.30423331 33.24861625
14.19125121 54.60855919 28.42792874
-14.82474707 51.60652481 28.02477333
-56.75407628 86.22337355 12.89495956
-62.57414886 58.44865957 45.1019931
-26.85968749 49.98871763 31.62067307
-39.3799367 54.11531845 32.71689309
55.47109534 70.55425763 51.03202663
-17.85058066 49.13512749 28.15839733
5.671203727 66.08022436 60.02724151
-16.78786701 24.23824464 31.47151446
-14.48482988 73.79845925 20.88533537
-49.6214098 83.33540616 15.92714643
-71.56968091 14.81358862 28.96756122
1.550338367 46.30257168 28.20769628
-70.55365045 14.79661844 28.97080031
-38.28041065 49.33140368 29.77597143
-58.96601514 40.78545847 45.52879844
-50.65649774 42.81489781 43.69147394
-68.02422392 14.75956509 28.91856582
-66.74662661 76.38987991 35.72871901
-56.57256585 25.24164189 39.06381069
-70.80628899 60.64569473 44.47368759
-69.21600897 75.8944285 36.12456575
63.51011108 54.75280145 58.13454792
-65.99877788 14.72896155 28.88756935
53.7965581 69.02508964 51.62314635
52.76468941 68.07039507 51.24943936
-63.49638591 14.69315661 28.82599803
-63.3461936 77.41344446 34.85123341
-43.14294504 24.99522825 27.70996717
-61.44155028 14.66011669 28.81768014
-19.76923658 58.82741119 26.7153705
58.08583825 66.84715469 52.78164984
-58.3946018 14.60978267 28.82092274
-10.58094113 44.4417576 28.81632846
-55.75080872 79.55534287 31.84459114
-57.82956499 14.59731143 28.85794218
-53.85565678 14.79793883 25.7710642
11.17388558 43.59521786 28.66816276
-53.94469665 67.84329216 39.27608855
-49.49845657 64.21581034 39.75213403
47.23825554 11.72241029 42.19483117
30.57913488 41.07778875 42.5383401
-49.55907246 77.64423514 29.54275921
41.98386353 67.71360924 16.66074602
-15.220518 58.32831722 19.98846668
51.28745611 11.56294505 43.27381336
-75.66873654 30.12755712 38.4260631
-34.73707233 53.32095495 29.39973984
50.04623342 42.75177033 60.1795368
-45.13774269 26.41790815 29.05480337
-64.4709916 43.85012563 45.96158282
-55.80813787 29.5494224 41.34993807
-76.00930525 5.78136325 6.480784917
-5.490579493 39.52781928 26.62373899
-42.02743259 53.89647763 41.58889263
-71.28898204 79.21008131 32.98922136
11.77823797 49.56472867 29.17416182
-20.02840866 29.81815875 37.23348151
53.45051696 64.72925841 49.11851411
-16.6788102 47.73117046 26.75316864
58.02879143 71.00515999 51.13649585
-60.05216165 50.78300713 46.20990981
62.29187538 45.38588238 62.22536998
-2.008517376 44.8071503 28.76641157
-11.10509062 80.77806237 14.9628027
-40.11870122 43.49544354 33.78157975
40.07531738 69.02696364 48.3914731
-69.94309558 15.23862419 29.5498795
3.47816776 45.2672222 28.20585821
-59.89785756 32.97228291 43.18129581
-43.79505913 39.32521425 36.2806217
-56.75783442 15.029682 29.4609245
-55.80911403 90.00554379 9.595042808
-55.8271493 15.08054135 28.69302471
52.99099585 64.21106505 49.39507334
-55.7994385 15.14089646 27.98709385
6.197427373 59.006752 60.46794457
-55.63219024 77.53619847 31.9551081
-32.6733417 50.08429797 31.61980547
-55.70601929 14.7897788 26.21866708
-19.91117685 26.34262148 36.77121154
-54.80843853 15.2852198 26.12269319
43.68349503 12.53112146 39.31127626
-16.09270982 49.58638977 22.58457804
43.88999781 12.50128658 39.61823999
-41.84295095 24.98368234 27.5960838
49.94057042 41.75108331 60.16311086
44.58858746 12.42144582 40.41186471
45.18105485 12.37035962 40.89192366
19.44010811 50.40275968 35.46444523
45.80773809 12.32679139 41.27818327
-49.41275099 26.92093966 29.85715724
46.38903639 12.28133933 41.69496697
-32.76594304 45.20796103 29.97913389
-59.16773465 24.8096423 38.74705054
51.85446203 39.41433938 57.79154435
49.61809867 69.92541933 47.79495976
-9.822577663 83.16066761 -7.114163378
-15.5284927 49.87832519 24.91462025
-50.79636156 66.73738254 39.86060638
-48.89618836 39.89808494 36.4297775
-11.54282474 79.72854383 21.4032185
34.60713504 42.44232488 49.23620968
-19.06507357 49.07876526 29.04430249
-64.71202342 42.36595244 45.75699194
56.88436095 6.375366729 49.98787819
-49.55658232 30.16877521 38.79446991
59.33671454 69.33429915 52.80371665
-70.83260699 81.98650431 29.80464746
-63.76943517 35.95633261 44.23844772
-11.23928828 38.22692756 31.16847171
-72.54869487 71.67472628 39.13238533
-46.9437758 38.39767439 35.99537867
33.463355 32.19834343 22.70792168
-46.27572824 18.75827531 24.9641772
-13.60521713 32.16157263 37.93768636
34.58956547 43.71005722 52.00272121
-72.76517672 15.75207552 29.95419049
-70.55002483 15.70011016 30.13499961
-78.17217951 5.038844155 15.51296302
-15.90621731 33.16216401 38.41418779
-67.73046066 15.65297774 30.14443935
-49.45308762 67.37300658 32.22564707
-53.08654481 21.19321314 32.956352
-66.28739313 15.63120438 30.12199875
-62.74616135 15.57931222 30.04906666
36.47206216 73.12787115 48.08543526
-65.24167999 61.06645864 44.3545895
-60.19985806 76.81075952 35.42097411
-57.67123864 15.49709934 30.03563555
-55.82527154 17.34289572 31.56281097
9.398736046 34.91899243 36.50039823
-55.80450759 15.57133597 28.81784555
-55.74510322 15.69003711 27.42854711
-1.501939412 44.79939023 28.75988872
-55.2971368 15.78617665 26.22705906
-54.7858834 15.7760581 26.24702353
-62.73722724 21.1058407 35.81107856
-39.64974367 60.08071852 45.09060374
-9.771135914 56.5572226 27.85611527
46.09496774 12.80800632 41.4637537
46.42554717 12.78991568 41.61071959
0.6662752435 50.74333748 46.74433581
33.51953369 47.0220015 54.54580303
-55.80743872 87.10383185 19.97335144
32.68244734 71.55063125 49.63796704
29.25572084 72.04697828 50.35614668
-34.41426863 45.29610816 29.27020316
-30.01678488 86.5276544 39.22353312
-70.31275525 55.62235444 44.42775897
44.06742583 64.3630359 37.67937955
37.10018107 70.04550471 48.78816318
57.35636375 38.61452482 60.2004893
3.728930134 57.45980947 20.63032426
57.02395874 8.98947301 48.7480068
46.95076664 11.74354216 42.00434279
-22.85587464 51.9477344 31.42198908
10.30941315 59.45132271 25.38965284
-67.82107935 10.37826606 21.47473158
38.10937401 33.59135663 23.1307069
22.21582079 43.50963735 56.68908947
-21.94627349 75.4133018 9.388645622
-50.35705534 9.477635554 11.11948924
-63.13512019 38.39521689 44.93833594
-53.22019446 41.86480638 43.55645911
33.25597339 22.52639004 35.9736712
-60.4269844 65.13324315 42.83932247
-77.48975223 8.656113512 14.17740344
-59.3876432 53.80798299 45.92690005
-23.922848 53.99926636 31.11635058
33.87115177 24.14394054 34.55856875
-4.408792503 61.52082717 44.95867422
-20.89822201 46.12295916 28.74720386
-38.23566802 70.67446311 26.72054539
24.40455682 70.0007951 45.90167527
-63.40062544 49.33012258 46.23470347
-69.5761237 39.67541168 42.95851991
-56.00800008 56.29912717 45.4965665
-68.21842092 16.11639553 30.68444541
-64.37009077 16.05825753 30.62545402
-61.55357863 16.01266467 30.61760343
-18.23057148 28.24705521 37.64921709
-58.08041993 15.95794458 30.590482
-55.95318348 15.95032736 30.27323148
-61.89884569 52.32919532 46.09272535
-55.82966371 16.01190821 29.53479988
-56.47965241 33.88777104 43.55505106
-55.79580559 16.06916236 28.86369494
-55.84303653 9.879632413 19.15230892
53.05040356 65.13597372 50.30001104
-55.78844159 16.13978122 28.04249521
65.26055092 46.60246517 59.18952611
42.33116169 13.59827933 38.8341087
43.14248423 13.5111272 39.69111022
-73.0269192 28.88071008 40.74328164
-51.99599967 16.61205155 27.66343585
45.24739365 13.36610552 40.97320911
-71.50799644 77.56613451 34.63520063
43.88200393 32.19565645 20.75221492
43.96915718 32.05258137 22.39651446
-59.30940012 45.25561942 46.14116149
-47.09438038 44.76133421 43.7230661
47.94830327 13.26732695 41.60482324
54.79278217 48.42059465 45.90625736
51.9691481 57.25616001 54.58122857
7.458282497 68.12917139 59.20732577
4.482660353 33.9909779 36.55771153
-5.599310629 60.8939404 40.80978981
-48.85344771 12.2146898 2.365581133
-43.31759024 42.24632271 37.23905212
57.13827078 51.52771898 56.00232047
-49.72742524 10.35602036 6.629123337
-23.01146809 75.15107919 6.809214723
60.0142557 56.33800768 57.87892028
-63.01544068 32.05381587 42.78479143
-73.36292651 32.82015555 41.68852949
29.34378366 31.31454095 22.100091
-69.23081493 16.13153133 30.70180757
9.160536318 53.66403979 28.69870502
-79.17374703 4.825517947 18.1804147
21.00586345 75.00960425 52.49696529
-75.39663137 6.774071821 6.493140534
-55.65791927 65.24248411 40.66168402
-13.85868856 48.44614031 23.74210305
-38.1191259 69.03469287 28.25404738
-10.15902274 43.44749337 28.62472913
-76.37713097 10.0227335 15.58042061
3.770760192 56.88693695 21.4460286
-8.539511744 61.86342194 35.94269466
-73.60762713 11.85532054 22.91145749
56.49354936 65.43755333 51.96906745
41.86088102 17.12801959 38.73447926
18.94955736 44.42725629 58.31287716
15.80307237 35.80816634 30.78351316
28.4460137 23.72715535 34.60488094
-24.34050198 49.44222532 31.65768997
-64.79794753 9.08107661 18.47700084
40.62628894 33.05839862 23.01104075
60.48690374 46.60703594 60.04682866
-72.50554343 16.65031579 31.13053024
-70.62615221 16.60666578 31.27884034
-65.5922211 16.53274181 31.17700287
-26.05713355 75.7954345 -0.09010641257
-6.094820528 39.60962245 25.78934033
-59.3426845 82.89385661 28.73352416
-60.01612315 16.44549421 31.12644134
-7.558260855 53.94576993 28.61655082
35.90942727 20.92012416 36.63442567
-58.41709513 16.41938157 31.12463168
-51.88681348 9.74802097 14.09903343
-60.4944765 40.81314119 45.49892401
41.49055745 14.15286265 38.38304983
-60.76561144 22.00771197 36.6187817
43.05521563 14.00780936 39.76854475
-50.87995044 9.437412008 5.859511004
-49.65717636 41.8390911 43.17549192
43.39379524 13.98568311 39.96083338
43.81994189 13.95655123 40.21774874
44.24111008 13.9299307 40.44646001
47.83579036 13.82629909 40.96396444
23.47190953 70.40358587 47.23028866
-72.14944153 67.43082053 41.70947377
53.88669117 39.15731347 60.38773078
-3.834968608 60.49949131 45.05237289
-29.63481213 76.05727581 -8.274108862
-39.13578407 75.74943196 43.7245951
-63.44650277 86.49061507 22.72219418
-9.073120782 51.93294958 28.96516788
-10.8476046 81.14853402 -6.866932999
33.83594716 19.56742139 35.25308882
-74.41216167 9.799636205 17.79556574
-15.02099721 26.78628405 19.03498137
-13.22332867 54.55533574 28.44726935
-49.79610717 12.25752224 13.70140539
-49.62740339 70.72867221 34.09036105
-49.27440354 29.28789812 31.48659706
-55.78506042 85.81555487 23.27106184
7.606317281 54.20693175 28.51945865
45.22677719 14.93270909 40.27078582
-52.90157373 13.55334793 22.55731118
-58.25266417 11.51822845 23.89638508
-46.87936536 71.90788644 31.53039562
-49.72224027 14.44066557 23.30364434
-66.91718456 20.24011045 35.0049682
59.47167393 53.35063339 57.70216945
-63.50915442 31.57764854 42.58000263
-27.3921625 47.48607218 31.64154963
54.24505839 7.143507038 47.4007162
-51.6622766 40.85499934 43.32865015
-4.441937755 29.97041308 20.84028422
7.491922019 34.93930885 36.6281913
3.318390947 55.89435668 27.22825439
-67.74319606 39.64281087 42.98742367
-65.41805218 20.21483355 35.0125074
27.88613062 27.27731687 34.28527425
-8.066057767 32.33980796 23.15896554
-19.72849404 54.61568581 28.98725586
-56.04291089 86.97483508 21.51574621
-36.05042194 33.24393799 23.82659137
-49.01963458 60.04899725 35.59245849
35.54244062 73.10125314 48.57172101
45.5191679 13.35290076 41.07467068
-0.5622791723 66.23798736 47.73129228
-49.45570498 41.39630073 42.45066282
-58.26556535 84.60049742 26.19610131
-37.04065077 44.37763783 28.78006208
23.94744971 74.51020322 51.90675775
-47.88889233 70.01182331 30.4272393
-49.85374366 12.73259782 8.197372116
-73.13698841 9.248839202 18.1197953
7.609251917 53.70323538 28.53953324
-49.69933964 68.27865751 33.41243373
-54.88332072 11.2986063 19.97672366
-62.66505877 30.11598337 41.90718178
-55.39053518 58.51685169 42.94041061
-20.95723613 49.21558015 27.8169019
-49.85292257 30.59747667 39.70094537
16.61102523 70.97880508 53.5144188
-59.45916867 19.19022869 34.11719154
-74.25786581 17.04536063 32.70538098
34.44000301 46.55102024 54.01111725
-73.26861342 17.16578767 31.11871937
-28.41141381 47.50466709 31.62006372
11.17271231 35.89578329 36.47607093
-70.05759329 17.05746707 31.76382329
-0.9222129447 48.77245926 29.14017368
-68.53844815 17.03448384 31.74091929
54.47569074 6.146623883 47.27599863
-66.0056276 16.99571841 31.70791262
49.23115684 66.8595339 48.49994225
-65.49766432 16.98725953 31.70923787
49.64972095 52.84078185 53.84072122
-64.18194693 16.96778214 31.68443036
-62.45771168 16.9404545 31.67284758
-61.9541814 16.9342318 31.64905893
0.1661876881 65.51697002 50.13582426
-59.92094298 16.89969746 31.66220798
-53.36237024 17.06018508 28.54839099
-51.34402393 17.03316293 28.47717304
41.1472289 69.98623682 48.70439178
-51.18969203 41.83165219 43.55410818
-77.67706058 4.757649149 12.8562164
-47.35197304 17.00163774 28.08183232
-68.91312679 48.12641993 43.77950637
41.58410988 14.60788701 38.90959496
42.52936593 45.90694901 42.46535153
54.61393471 55.50559913 56.91871791
45.23454448 14.40179707 40.60587789
-65.97727681 46.37770192 46.04009473
-50.92048791 19.33059418 30.85934831
64.40492417 44.30665638 62.69756022
-0.31139092 39.17000975 29.78975132
-29.44904797 46.02588698 31.50478275
-53.25241974 20.27245519 32.02353377
-61.38580054 51.8159569 46.12628973
-60.49817307 22.47413257 36.97986975
-46.16424247 50.29302015 37.59624367
19.57714696 44.43278656 58.12899256
-45.15355076 34.97021375 34.65595005
-58.18187376 35.86330236 44.25282917
-10.05743153 47.91619084 29.16922167
-56.30541881 77.83290568 34.46574422
-52.71291428 27.28576843 37.90460266
-63.81377336 73.63824153 37.97922874
-49.20561521 52.36179456 37.46697209
-13.28109407 31.74363424 36.90096697
58.63982298 57.99286296 56.41018741
48.60116332 64.15737328 39.20225062
-41.48589478 33.01912894 33.2995586
25.8693416 72.52297252 51.30290205
-53.49602513 62.09258764 41.85584979
7.947310677 32.94850665 36.34546715
28.93357374 35.75646649 23.05297525
49.08551497 67.83832224 48.81850946
-52.68731367 20.259949 32.06094562
-17.75331855 53.60600873 28.67836457
55.4412767 6.031210698 48.43165625
-74.07738984 43.23153948 43.32129796
-74.34880261 56.92828384 41.69055723
31.84377691 45.11512194 53.6952658
-69.93746033 24.03455039 38.14545253
52.97828671 44.81781395 58.94279784
-45.50919841 12.41083078 -0.5491353324
50.78605212 12.14464475 42.44328024
-13.84676505 58.1308972 22.01827975
-40.22406213 60.24560999 31.63266526
-57.91223735 55.81592774 45.64240673
46.0067817 53.44619544 53.33400964
-15.14797281 78.6595788 22.84678656
19.38147576 29.41359676 34.4142306
-61.04632925 17.37493579 32.18655355
15.39849568 73.35819901 55.25711897
-60.33864395 17.36484038 32.16878865
-55.7847466 17.39678684 30.92947498
-55.72285871 17.53901304 29.2666068
-55.72833026 78.49001605 32.55411386
-55.81803467 13.74274047 26.74153253
16.84212293 72.36887804 54.81328865
43.56677355 15.50418527 39.77988666
-53.77061644 17.52055862 29.10853489
-15.16153244 31.70108999 37.75346264
-60.58566804 10.69954468 22.19189954
-51.25033297 17.48811741 29.00450298
60.61077521 65.7955451 52.85469913
-27.44924061 53.03924099 31.28024826
-49.37062332 12.22779576 2.312066069
8.770567717 70.21940085 57.99871943
-47.07728638 17.43962415 28.77162297
40.12636237 15.22600407 37.83868297
24.81534759 28.84632209 34.13665607
-23.85554299 75.32908651 4.903745761
-66.50746531 11.21561461 23.1569464
-37.52513616 54.69778934 31.42806494
-61.03415841 87.05200556 21.57175581
-61.89030131 23.43747534 37.71545295
44.6644937 14.94379145 40.24936446
44.97249252 14.93709139 40.26840643
31.37431161 36.75619445 22.63522183
-66.1569164 35.51867833 43.94772396
-56.29604702 20.07333531 34.9155011
52.65092792 41.77142811 59.41005194
45.67439414 14.9299135 40.21787574
44.07560833 64.86112433 37.72228591
-51.91509563 60.0047959 42.4844038
-55.92602716 77.83974787 34.31396279
40.77740843 34.68703868 21.55570131
-0.3067084583 51.43903197 44.68040076
-8.463726557 70.62876268 27.40002252
-42.70255126 33.96829015 34.16630823
-12.1641829 82.65697485 -0.8203710121
-13.97921061 74.77083698 21.15412227
9.408372025 32.88048969 36.85642456
-45.86066742 55.87487253 36.83318361
50.78464544 44.28237889 59.75021859
47.3440431 30.57795278 21.39154549
26.18632289 44.99563032 56.16132252
23.91918321 34.81610134 23.27241278
-62.7652047 10.30566179 21.35338919
-69.66527712 71.60777242 39.35974951
49.67062091 12.13742316 42.73983316
-43.28399342 45.24267125 37.40868974
56.76308241 9.046736121 48.1330054
37.87430716 51.79822768 50.70916972
-66.38145928 45.40371103 45.77068995
-48.87247182 70.0124239 30.6078418
41.50455286 15.10067914 39.03071721
58.69799824 58.56484443 55.58575551
-49.84356182 9.664609826 8.851028774
23.24626941 49.6473356 43.50810277
-4.674307474 69.83222835 35.92411074
-64.56508427 57.4574225 45.33539808
-49.87674558 9.780460486 7.512477893
19.84163301 35.39297189 23.17993252
-7.540116516 37.08427303 26.24786168
43.15914959 47.05208277 40.70498114
-72.38826429 80.34781271 31.64452623
-46.52526848 70.82394488 32.3927703
-41.27082713 68.70518495 44.33354848
-39.63035679 64.28596106 31.22922513
-65.83301443 13.38647416 26.96060763
-69.89642723 50.13574385 43.94789273
9.407019555 57.35255 26.61926662
5.298528016 60.44650669 61.40556974
-65.08141628 31.60264816 42.58962855
-14.92750272 30.45951556 22.98872708
-55.17011982 33.56742033 41.19753884
-49.47936855 40.19892378 38.87527788
53.58774205 39.63688666 60.7041475
47.47741652 46.06310954 51.36218639
-16.73500215 28.73818061 37.48930191
7.186810022 44.7029989 28.2218729
-48.066393 24.14526615 26.86259664
-41.55159962 18.36892019 16.92989286
-13.48797088 32.73999411 25.37394523
-59.9707354 55.85081514 45.62997622
-15.47053643 62.50360172 24.00616574
-26.9067497 75.97623705 -2.026967749
49.33707536 44.46747957 57.87749475
-67.34920061 17.93456794 32.71857151
30.05588968 25.16505185 35.0856387
7.899322657 41.85061721 26.23867512
-10.44531477 82.67525136 -7.186978567
-55.79486374 47.30039117 45.04027889
-65.82389083 17.90847501 32.73059153
52.27888935 8.322085874 45.74715467
-64.81007688 17.89262293 32.72127367
-62.28040791 17.85544729 32.67041259
-66.7004533 16.09400628 30.65487054
4.040668215 36.60112764 35.47468324
-55.75081869 40.42059368 43.32461669
-49.16780288 17.93093436 29.29345058
-54.85873925 38.93472353 42.92363418
-56.38243638 32.43119465 42.96559037
-37.88574649 66.05233774 27.87105005
49.81746224 11.58591223 43.28753027
-6.742919647 54.9601341 28.33886259
2.406453215 67.76519361 52.7427769
-45.80310235 18.31498174 24.19347892
-9.881225686 62.64600762 32.94038233
32.2604018 44.13178725 53.37779779
-45.18035105 18.39728621 23.11926596
-42.98803452 30.62156525 32.28549017
-51.51904693 9.388130571 6.553485547
-44.1291173 18.53600303 21.30846296
-44.07283776 50.66275681 32.9052265
-11.80374316 35.18291234 37.48006343
-73.6408689 39.74360685 42.94203191
-10.09810161 45.42562636 28.95606328
-62.78882368 82.39556416 29.34862527
-57.39228281 11.07755801 23.02128824
-39.66331975 53.21759855 43.19232534
-42.53245305 18.7464724 18.5606871
-49.62553821 82.84193161 21.65654734
39.07605181 15.81203826 37.06251165
-39.8888656 54.13758231 44.20877739
27.33078126 74.03574636 50.94273657
-15.81868886 47.40786151 18.68906143
41.37184812 15.60789222 38.99456608
-57.29294225 17.31513529 32.16496796
-56.0170605 12.81392732 25.90854687
12.15165729 70.24829097 57.01854771
-64.11441898 75.79876356 36.26221045
-29.78432747 76.58535777 -8.549315342
-70.8743158 52.64455383 44.14348926
52.84243094 40.79428339 59.06365842
-36.46609893 51.36661331 29.11033614
-12.4830061 48.62607373 27.21759521
24.65022941 43.57007063 55.52327933
-43.46064295 52.61724232 39.23258852
-72.49878532 46.69407468 43.61109924
-71.50415079 45.1812494 43.50347366
29.02655332 41.79995862 46.10423413
6.915453301 63.08350211 59.61824912
-48.52039136 9.783380996 1.39326771
-41.998817 24.05787549 26.71996805
41.84575705 68.63904071 17.59728176
-57.11704568 57.84488821 45.24366855
9.39277881 57.8867275 26.24750106
-60.26238847 13.30198686 26.87904614
-65.80313268 62.10633182 44.04333686
-37.471691 42.23931807 30.37887705
-76.67699585 5.926609764 10.74863156
-64.7512046 36.45823615 44.42585535
-17.5344659 33.26496133 37.5313581
18.84280066 43.47667667 57.71497633
28.51318506 24.69770342 34.9785225
44.96449801 64.30696171 38.15925264
-55.64969027 9.663941383 15.79269408
-49.47776628 40.70033156 38.8809058
-67.23752482 30.19251513 41.89074196
-53.02269704 16.63440256 27.59976644
-67.25689496 18.39022122 33.23805335
-66.75149451 18.38305514 33.22485962
-70.77025606 21.6957879 36.32112868
8.476233603 32.45203669 36.18134304
-14.69257489 31.21009117 37.53726143
-10.20035811 81.09459286 -6.364178252
-65.74010486 18.36842597 33.2018076
26.20312706 38.41839166 21.80532195
-20.09349929 75.02379961 13.55693685
-4.380628492 30.44170221 21.18416497
-58.63949368 18.25548726 33.15875344
-3.695512144 43.35859996 28.42403842
-56.6152598 18.22549517 33.12088989
48.71704978 11.11845426 43.09733788
-23.38422489 74.73778551 5.851475356
-64.32335144 28.70251022 41.15213315
57.35035507 52.92738308 57.19347523
-39.40431847 54.61744471 32.71913566
-49.38398927 79.72490723 17.00868941
-56.00693786 7.190134398 9.618733892
-21.49693138 58.63348328 29.29610933
-54.14127687 18.46418839 29.87816095
-52.60135204 18.43072303 29.97297836
-55.80830141 90.29580919 0.3986329465
19.24629282 48.52310073 39.84193799
-49.5620155 18.40900665 29.64545823
-55.88931508 42.72898064 45.68679307
-48.59167304 18.3903055 29.67750464
55.16509613 42.49872519 62.14087192
-46.65903295 18.38173922 29.4083822
-62.92410766 64.65178539 43.07801114
3.732854666 61.4973927 61.157983
-44.80239328 18.90706773 22.95591205
35.30199872 30.75532253 21.62899816
-43.98364297 18.98961372 21.8415172
-11.19282899 42.97121436 28.52423563
-43.5742101 19.0308576 21.28464678
-57.08404295 30.02625499 41.88448099
-15.51051989 34.1404351 38.63552298
-43.12385125 19.0762346 20.67199121
-41.64638997 19.2105566 18.8309223
31.55956918 46.51735746 54.95121551
-48.54240412 86.39241734 9.366560182
-56.49204212 30.97983619 42.35499833
5.894216669 61.10383647 24.52799401
49.38377101 40.47575189 57.59435062
-19.8724945 74.48443331 13.94950676
41.94495123 16.08117909 39.21765332
44.49366825 16.03439483 39.27470353
-49.4989582 59.09479171 40.93434255
-49.80844065 27.73518701 37.96016416
-49.76549168 77.8408139 21.47345262
30.33337617 42.54162151 48.89853328
-54.82415844 78.07681162 31.35182036
-19.59209552 26.33033095 36.85303825
15.6042618 45.38474226 59.48893112
7.591872649 42.66324964 28.51695948
-60.17966548 73.03728285 38.43590872
42.56730781 71.01765869 48.1133581
-74.60537314 56.58027032 39.95284042
3.761229778 57.51784978 19.95039021
-49.58105339 77.10355148 29.99696073
-6.029260576 45.85758111 28.99230576
-37.10940964 43.66232804 31.27037232
-8.678989589 39.5189641 21.50794358
-57.75787914 84.5921783 26.19585693
23.40881585 49.61489625 43.85368394
55.99738093 66.41971661 52.31530348
-25.4185328 45.46035389 31.47461933
-24.28889385 22.69963133 33.28342906
-68.5275652 70.5249891 40.05923841
-49.7780303 82.55291703 19.21407947
-51.82272362 22.55789528 34.35303047
-55.83203487 18.9025112 30.93886862
-77.29618944 8.204923634 13.55159026
37.97001647 20.36957695 36.80597196
-16.21082112 34.17158354 38.40748077
-71.73033659 18.91901705 33.77915863
-71.22448592 18.91162385 33.76851553
-65.97763638 64.70543874 43.0374901
-70.28734192 57.11618299 44.56138312
-69.16972244 18.87862032 33.75978889
6.902972065 62.61235999 59.26335005
-68.69485158 18.87446568 33.71745825
-50.80326452 22.53919555 34.37569359
-46.32935002 70.92333844 31.20157427
-55.48752647 13.4826372 23.87132981
-67.11912292 71.56228096 39.40228074
-65.60275193 18.82613342 33.68885238
9.612790937 43.13714611 28.45686312
-35.58479812 43.38214986 28.40549119
-65.14277972 18.81710534 33.70593749
-64.63710221 18.80979949 33.69431338
-52.8347868 31.11719556 40.06296959
-74.09082725 10.61719619 19.89673449
-57.83109486 80.61804204 31.55800832
35.58646043 35.63398868 23.20604167
-11.85290584 82.72481257 21.63932396
23.25309029 48.34212857 47.00531653
58.92950827 6.161380074 52.08196993
-70.67721635 35.71756251 42.50097941
-62.61186397 18.77930077 33.66213969
49.94875292 42.24917174 60.20601722
-61.59746126 18.7631517 33.65615727
-55.92440156 45.20820412 46.04605336
-60.07731178 18.73966183 33.63894314
-50.963892 18.85027295 30.61690417
-49.96088349 18.83987158 30.54637118
-30.15130596 46.05802249 31.2656551
-20.7875389 50.92823027 31.20941121
-35.42983761 49.35535161 28.95434496
-49.49057898 18.89170574 29.85495242
-46.6245295 18.86655029 29.60040234
-44.9887999 20.32191301 24.04682719
56.77754862 44.51187158 61.76985854
-21.41661905 42.92516227 31.00858034
-49.76564264 84.86525546 15.67496792
-69.8022768 17.97027384 32.7718877
-12.82692865 80.71172828 21.88786407
-69.65727238 35.6986182 42.52638936
-46.31330587 19.25466918 25.03548212
-76.90294133 5.389702743 11.19788844
-43.94686104 19.44849758 22.3340849
-41.75207422 19.58025018 20.38604528
-20.92668667 44.02934238 29.75030034
-59.77552391 77.34670262 34.94507331
-59.11069435 29.5800284 41.62445861
-48.77486933 68.45654114 31.17112837
40.84601893 16.64218437 38.74130844
-13.93663549 31.56544114 27.44129484
-65.09478287 81.3154594 30.67374826
-41.56203596 63.06660989 34.09944499
-16.13223501 25.9030633 23.67333201
-24.95746713 54.52782945 31.00435345
-56.28288613 90.82108338 6.044655611
-52.41536946 67.81517268 39.31086804
41.7370446 64.42357193 37.4210507
53.40115216 70.70641876 49.66037946
-51.13894463 17.9341446 29.63209232
-51.7156389 41.34696671 43.45435132
-6.175415086 41.91357922 28.19190342
-68.22188255 31.19794034 42.06003703
-20.10936619 23.42930013 35.6691223
53.93653156 40.08704828 61.23846759
-47.63915449 87.43677046 8.723973465
-4.574109567 61.55466414 44.59739686
26.26545824 24.81565661 34.03789055
-16.42682133 25.817204 36.3795126
-53.92080299 50.86166473 44.12750785
5.753181208 41.94210445 25.58590642
6.680857587 67.62267458 59.40872917
-32.33192606 47.57443883 31.55776764
60.23529764 39.90781375 62.11794046
-10.13463131 53.47950984 28.69392674
-67.11269528 14.74575419 28.90505886
-70.63547266 19.36449607 34.22555686
-68.66489231 19.33679117 34.17137409
-20.83425691 51.94383987 31.081665
-59.32493389 17.3490407 32.15888219
-44.73401098 51.94542594 35.62107994
-60.63869464 61.50152558 44.25283894
-65.5756845 19.28991768 34.12638533
-24.34814866 47.93943454 31.62471763
-63.03316676 19.24626059 34.14831532
-16.76090656 32.71942034 37.89023285
-55.68151243 73.14690472 36.30551286
13.53964988 72.30033054 56.23883968
49.5207369 44.97616063 57.76396933
-61.52056723 19.22657923 34.08832908
-67.92779729 28.28407412 40.87039336
22.76313612 43.98354371 56.90990318
-55.45197329 19.36167576 31.36271216
-49.54818598 19.38168466 30.00454639
8.059004578 52.21749223 28.22143072
-53.4646334 19.37507237 30.82819681
-52.372876 19.35706921 30.82898595
-60.41270367 54.3317243 45.86905476
6.519833737 44.21512085 28.18607283
-50.06071048 14.36362522 24.26253335
-48.54029424 19.36881999 29.96167793
-46.96220598 19.6629934 26.24574678
-45.60073808 19.81866043 24.17900989
-42.87758171 20.05554965 20.90970414
38.31143575 17.28316638 37.61032236
-46.38570121 32.58512355 33.44559151
-62.93819197 44.32247055 46.01258515
42.36267966 17.1226704 38.70088048
-42.37888082 27.77176632 30.29202101
-46.25095204 34.02379864 34.19862644
-49.5175304 38.11019866 39.82343771
-15.29185777 29.75796731 37.02893899
-19.83989572 21.03006664 34.33658137
-50.01383167 30.97598005 35.33768758
-55.70431552 15.7933514 26.22142081
12.9198497 44.89864922 59.81714657
-21.13828006 23.45276923 35.59289594
-14.55291638 26.41481571 34.91127547
53.98123049 57.6601801 55.33399334
-42.24121292 33.48113477 33.90694251
-5.484385873 47.33715067 29.19239433
-12.05623973 83.40207418 -3.663972823
-73.02155401 18.97569135 33.36748478
-37.37338394 42.31361724 29.49761014
50.89255524 65.40681137 47.56744419
34.94869547 73.75431135 46.93041614
3.481203027 58.57108628 25.25697854
-69.74236921 31.72381632 42.07189174
-54.14161895 53.39741095 43.8660304
27.60861872 69.28799814 41.91202641
-19.5274253 50.59564174 29.00338914
48.28275118 43.0466743 57.09238242
-20.28262114 26.8587642 36.67692771
-22.47429647 41.95870883 30.77630082
-46.62406911 21.55084316 27.57228805
-11.91287723 81.14930509 22.46047892
46.50700609 50.50267778 52.44926958
-49.68477547 25.22939974 32.06574155
-65.43889793 33.54406206 43.42701671
0.9977992332 47.31317064 28.2345803
-62.02527502 19.23339591 34.10544686
-9.55696725 46.90708271 29.13496199
-62.14907939 25.32909913 39.11201779
15.90814681 72.84667268 55.27145551
-7.214000666 41.4397111 28.064334
-74.10889854 41.74078568 43.1531422
-16.79523156 42.06729904 28.43267436
-64.42759995 19.7333279 34.58665024
62.1442909 54.24578099 58.45424065
-27.59102588 48.99392365 31.65515645
-46.2310653 18.25830733 24.93301199
-49.66320282 33.03763226 40.47086233
16.8644149 28.39042459 35.11869673
-61.38852178 19.68696399 34.54530585
-67.43082666 7.948612923 14.64564836
-56.36885438 19.60784629 34.50648117
-46.67876733 9.994171909 -1.404956242
-51.95779268 11.3466885 18.86063448
-55.94953246 19.61932387 34.29327378
-53.854732 19.82629153 31.49115075
38.72040291 44.71206589 51.23617946
-60.88994838 44.78258301 46.10729243
-53.13700318 19.81112975 31.53028453
-40.00601611 62.16926062 32.56650582
-46.72852485 43.96879536 41.20038246
42.07940559 32.0642908 22.62096987
49.34499457 67.8313194 48.8503192
15.82395343 50.51056493 29.07594375
-50.72446697 19.75870709 31.67875986
-49.93830642 19.75858127 31.53029495
-29.800841 45.05024658 31.24453581
-49.05219651 19.88148188 29.93458823
-59.50721638 26.23976028 39.68986131
10.19746669 52.63209479 28.8272451
-46.86241511 20.1892044 25.9447156
-56.23803164 7.462033601 12.33304181
-46.79982905 10.45834499 -0.943687979
-69.54647018 70.5434093 40.03971452
-46.28958139 20.24713363 25.16298784
-19.35801097 21.03476283 34.19016625
-44.26096605 20.36359849 23.42410938
-12.14812641 36.78894783 36.3816298
-76.73405952 8.820024035 12.13049501
-39.81814562 42.61861431 38.07649545
-11.70652816 82.12878085 5.22401384
-26.37259034 47.96953145 31.66140397
-21.13195182 19.17741132 32.78339539
-51.19529975 32.54015503 40.71148035
-13.99377814 63.45687649 24.31151171
-19.35596606 19.27264574 31.33915346
59.72061666 6.623807603 52.38954552
-18.94071487 19.31095462 30.81524504
19.13373098 31.25009919 36.4485804
35.9396689 19.39822097 36.81609868
38.05195665 17.792385 37.57506617
59.04119414 65.3665321 52.30768265
-67.63578267 16.10743432 30.67736106
37.91225016 50.55165494 53.51977856
-6.876790034 80.57594957 -6.803853837
12.11477487 30.81963172 36.95767707
32.0106562 46.03537326 54.6337739
-31.81738653 84.41119529 40.82975727
41.29162679 17.66961216 38.38247848
-34.43026613 46.78604818 29.45686561
-48.31727203 56.42696326 36.71924161
-55.7562206 79.05585681 31.81738097
50.6361547 8.959184435 44.49116966
-47.88036571 39.88121958 36.43183938
-10.47067674 37.50377852 27.76345239
-46.57802621 19.71697597 25.54581216
-64.24381894 15.60384519 30.04988241
-19.89774171 20.55703049 34.01232086
-49.46735561 50.18762875 39.44962768
-55.12740759 16.6802125 27.46935206
-69.72871531 72.14198926 38.99690943
31.64206085 71.07767158 49.50019509
-62.219658 61.53091459 44.21316901
-55.66020245 69.87508041 39.32332855
-5.445632278 79.82257835 -4.157741684
-43.51616803 50.20184594 32.32301499
-37.78650147 67.60803399 27.27238854
-54.12446599 49.35864117 44.1346193
-65.9772119 43.38915088 45.77351852
-49.71776301 58.75250548 39.12293713
-30.11489046 77.130434 -8.987276831
-42.3191993 28.24387655 30.62668026
-47.96242534 65.78340963 32.91464776
-12.03773 75.2600272 20.93163405
-71.91311097 83.13954103 28.27869579
-49.49032355 63.60372133 35.20287641
-58.36437524 44.24525745 46.03667687
-54.07012 12.14060524 21.70038536
-58.78271511 34.41196467 43.73567763
27.65184008 46.00413127 55.82770632
-15.76896077 57.37754142 19.47707558
-18.99022147 73.80971912 15.78718608
-49.51405297 34.38805056 36.41905856
-37.8995268 66.30706764 24.91658702
-67.4524603 69.97600333 40.40059608
28.51353562 70.64216235 49.32589159
-49.46639996 35.41800019 36.10684558
-49.54331502 66.79907461 38.90547622
47.49350372 53.91700592 53.41161202
-64.47942181 11.1837008 23.14066511
7.832825691 36.69521471 33.65926441
34.80830515 68.91834214 15.69703091
-15.76924026 29.751 37.20086042
23.19485057 49.12150163 43.79553231
-57.64585352 24.78527892 38.73964652
-72.86690553 20.35477271 34.80853248
-72.32361676 20.32849421 35.00998411
-37.54037331 68.28342717 25.21163407
-0.7297397208 38.17838709 29.7277307
38.47637282 46.34181271 49.84334939
54.67683551 38.67773989 59.97764467
-66.4368879 20.23321881 34.99337592
8.060547954 45.68790537 28.27501452
-62.38055847 20.1692689 34.96218675
7.973155922 33.43768528 36.48843671
-61.86722711 20.15810214 34.99392336
-65.97634493 51.90160812 46.00743228
-67.70127829 31.18310483 42.13297612
18.65537879 32.42149806 34.59463351
-60.82967737 20.14266568 34.97525405
-45.2407565 52.79664653 37.48941157
-20.34812524 20.13333919 33.19008846
-60.34804734 20.13510144 34.97121556
-58.11034219 20.09653715 34.99215421
-55.73190574 20.28431649 32.35869204
-59.36134258 29.58257633 41.64268047
10.65497408 31.81711129 37.30988089
29.77721947 26.78749471 33.7842045
-21.23697646 21.99590765 35.04410764
-48.54883024 20.37755794 29.90642117
24.41118991 35.31790763 23.17989135
-47.53266126 20.36051789 29.91044506
10.51848603 49.94281839 59.98511298
36.11977493 35.6127416 23.35098703
-47.03533026 20.35742236 29.85153619
-49.727534 78.1752154 23.41087845
-37.29996625 78.3007869 42.88970776
-46.44133645 20.72678969 25.45037178
-14.11600452 31.40148473 23.55218581
-46.03839724 20.77130923 24.85671342
-4.960330993 67.76942336 36.61863945
-45.63156182 20.81386337 24.28512783
51.41693544 51.2603478 54.37061214
-1.288313053 58.97853565 50.56979272
-45.02139529 20.87773826 23.42725893
-44.81797758 20.89901534 23.14146613
-44.41105559 20.9415258 22.57037104
-44.20720497 20.9625845 22.28703077
-62.05419499 39.85215922 45.29886984
-23.46215663 19.69051869 33.09789564
56.66339797 5.408063869 49.60589287
-53.33804811 66.78062035 39.84338525
-49.48431563 39.74875898 38.27542192
-21.92453488 19.6582151 33.17966559
35.59528264 35.12732236 23.25947066
-14.53371801 80.58158838 23.72411828
-69.10622388 37.67728947 42.75800455
-20.51853521 19.69230706 32.51576854
-15.29208686 69.61139486 17.20601475
-16.91594846 49.00389185 23.67699477
-70.91130245 69.50889662 40.65609273
59.7851139 51.92126301 56.75564632
-24.53779449 22.72607557 33.02391188
26.86318275 27.7999925 34.2393973
37.0094312 18.34971421 37.13063859
38.65581735 18.26254541 37.82858199
-49.67428541 16.20233859 26.150277
-17.51832208 79.79426411 21.77985281
41.21443936 18.21076467 37.94174607
44.41888221 46.76351214 43.81468156
59.573782 4.577512559 52.86587055
-35.95515204 47.84113719 29.15270893
-35.9762709 46.34514233 29.04341387
-65.88275037 55.44424093 45.65060224
-64.56923975 40.38417384 45.42913092
31.43243697 32.23170727 22.70791818
-66.27749261 68.37221065 41.31459609
27.65347189 27.8225619 33.82668226
-66.25816385 43.40698525 45.62006333
5.808809489 55.36516245 62.02979947
-7.171245445 42.42257567 28.2996529
-37.55586876 42.68488537 31.0491008
-46.62360349 86.96738182 8.152666694
-55.89488733 24.49002961 36.00655486
5.896374878 32.99196827 36.2320587
-52.49676152 79.14610231 30.14814663
-39.01973402 68.00139619 28.76777906
-66.94550137 69.96805108 40.39623146
-61.0093067 57.39819285 45.34487097
-40.83993001 49.78674169 30.80483233
-19.76303475 19.73242529 31.90596825
49.24110905 10.07266599 43.4843883
-39.42562995 51.73971716 42.82339953
-60.96403442 55.86870758 45.61169581
9.778009346 47.35926357 60.98491186
-28.60096024 76.33530896 -5.872241083
-59.107499 25.281473 39.08484902
56.3926204 6.425197914 49.50317923
-7.814233881 53.95100391 28.60460656
-22.94411069 42.95235582 30.98419957
-55.53338485 62.12920093 41.81935266
-55.49591482 29.71403644 39.37943495
-53.29604234 39.89420305 43.14056175
-72.52246537 66.40394518 42.04804842
-56.71511865 62.98641215 43.74686508
-75.71470959 29.19587147 37.59723555
33.11901722 24.11451333 35.04361792
39.40370098 41.75053195 50.52567224
-73.02208058 20.83615395 35.07654143
-72.84726684 20.82230432 35.20398002
1.338862529 65.77453092 52.74887461
-72.46615083 20.80349882 35.34960692
38.46367566 48.51240784 53.7811235
-11.56198669 33.36089063 23.62546921
1.148956156 65.82077174 52.24829359
-68.9313596 15.22382032 29.5287895
-72.14422961 20.78977573 35.44752222
13.91872807 73.36131861 55.50310503
-52.24541007 41.35403746 43.47329945
-61.87761058 48.80297128 46.23717153
-59.16439764 61.47638773 44.26350017
52.67791656 11.0531039 43.10061148
-71.88375432 20.78227063 35.48497278
-70.40901576 20.75691003 35.49813559
-2.717050049 60.66078423 48.79341458
-2.155702569 29.02682548 19.70485702
-57.54033528 18.69879997 33.62948076
-71.32597194 9.177735568 18.59984655
-67.3721108 20.71164238 35.4444795
-65.34538335 20.68039244 35.42074252
-64.32530086 20.66137825 35.44693729
17.23387793 34.91776563 23.36714733
-47.07479449 48.1116404 39.78637634
-60.77679618 20.60581735 35.41520769
-58.24092793 20.56551454 35.39946682
-63.15222569 16.03858514 30.62157132
-32.43728096 55.78692982 29.4675244
33.99309462 20.49258185 36.13646406
-57.73144081 20.55628696 35.40942497
-53.19973405 20.7480944 32.31856014
-49.65517536 11.28110985 13.35615466
42.35127912 45.01805803 52.81821067
-7.4069458 77.91107815 -4.900181753
-57.42749403 20.55163092 35.40551126
-55.78792149 20.61656631 34.33901734
-37.19607867 78.8001535 42.89952477
-40.19419962 44.06718084 38.81211655
61.79222719 39.86577052 62.30909321
61.66730763 8.487398319 59.51758437
-19.51573969 47.57645089 29.09028609
-60.69075387 36.39220728 44.41801426
-55.04339787 20.78611098 32.22883366
-49.78644047 20.68640593 32.38374933
37.63731434 70.52454731 48.9512934
-0.2430651055 29.93366997 20.46607484
-55.61304106 15.29464852 26.16668036
-48.49514659 20.88986053 29.77564199
-15.91817659 25.29952641 30.63880768
-46.68900381 20.88316001 29.50898413
-11.4885222 70.33249192 19.76291345
-46.04842055 21.36530391 23.78974187
-17.93163098 55.68004305 27.94202926
-45.58924414 21.36906619 23.65849887
-78.01647056 6.03906381 15.52527044
-43.1723743 21.38879104 22.96860676
-41.51187074 21.36883441 22.88360989
-41.35898961 21.39082634 22.59915609
-59.73757847 17.81163296 32.6941084
-24.05669008 20.19530742 33.17796167
-41.43147288 41.98866799 39.87040265
-24.64121778 75.97985045 3.325676555
-23.05950717 20.16306685 33.36206489
-49.77277 27.18354046 32.70399467
32.56862555 70.60361556 49.00004093
-46.07550013 18.27894532 24.66376387
-59.76013225 80.0977971 32.1386295
-27.41574029 45.9913177 31.51832421
10.56274912 41.64685741 28.09613652
54.93817798 48.01848002 44.71992851
15.01183968 71.82359533 55.66573354
-21.10838319 20.16995439 32.9100182
-19.08934285 20.11780429 33.13037156
36.15173758 71.03281331 49.16092007
34.97339116 18.98698924 35.94761071
36.64181383 18.86392647 37.05803512
-78.61775055 7.90523672 17.2826049
37.62268606 18.81513583 37.4373743
-28.57256148 87.09423648 38.19745345
-55.13831773 34.05359409 41.3742552
10.58805865 34.33716844 37.20118397
22.78058915 36.9037617 22.56102784
-11.54772351 79.85490424 19.93726862
-65.93255972 33.06786852 43.22252222
-68.40717221 48.11897432 43.76945188
55.64438161 71.11772811 50.2844441
-56.61933642 13.6885595 27.52331795
-16.83879576 48.10920796 22.39514291
-49.30829525 64.21233768 33.92954044
-14.57737332 49.39188279 24.55357355
-55.31923724 39.92367103 43.18431137
37.08480818 51.22348796 51.70508933
59.51866616 49.58748678 48.93879058
23.84620466 36.35956277 22.84862607
-48.02920485 19.86100019 29.97726399
-16.33121438 82.25163141 22.15975569
-11.14699069 53.49462817 28.7114851
-67.61369266 43.61999012 43.40585448
-48.89880955 10.73092409 2.118944442
26.72034887 69.59406954 44.35496582
-55.80681354 88.40528278 16.51832118
-57.67581529 35.85580434 44.24336328
51.8207172 69.65677562 50.49351993
-74.16963018 38.75812505 42.82978005
-62.526807 23.91388087 38.13301627
29.08712281 47.02788371 55.32280547
-49.48603709 32.0115052 34.86918396
-49.70792342 62.41172129 37.428699
57.82351543 57.48120229 56.67895188
-27.46187244 53.54782902 31.20523702
-49.38626821 12.73790371 2.21998522
35.16731057 72.03827347 49.32982274
-72.12825186 85.47993167 24.45732479
7.511279635 60.12613886 23.91615598
-53.93243462 11.3144976 19.61090615
0.1170199842 64.99000049 50.43600695
-73.27075971 29.85947812 41.08081163
-53.63051592 16.19101666 27.03618724
-59.55242138 25.7603475 39.43721465
10.21621023 51.11599171 28.94377894
60.39638566 38.56768485 60.16449143
-64.92474089 8.679987112 17.33067962
-17.04903434 49.17679616 27.52181714
-58.20832342 10.23699163 21.28153796
24.2250698 45.99828437 56.54908715
-77.53340703 7.146708844 14.22807697
-39.61816541 45.55581012 38.90109091
-69.83465219 21.21717216 35.87218333
-36.14235996 40.42710999 27.85623559
-3.509012801 51.70974202 36.32185741
-49.51941908 36.39962154 36.37486143
-55.93169558 16.40453723 30.82297496
0.368800104 46.25436363 28.99265706
46.71699621 68.39553458 48.6283136
29.20320604 42.94493945 50.25869565
-73.87551154 53.80012961 42.95434983
-48.36788168 33.09611821 33.71824731
-40.06120025 61.16788785 32.54841388
-73.80988504 31.7934091 42.03970755
-53.03022598 68.88198493 38.69707139
-58.64990861 64.57704456 43.13054186
61.26220895 4.574907022 58.40076616
-68.82042264 21.20111043 35.8652199
53.70117957 45.75492356 59.57956741
-68.51450179 21.19545858 35.87248972
-55.84692422 89.46618612 4.210228262
-68.26108294 21.19151303 35.86996405
-14.69197326 49.80324058 25.62672807
-65.69276442 74.74806638 37.10718847
-48.04377612 20.37056656 29.89126541
-53.48477215 29.6906481 39.26740692
-53.4318688 61.05579252 42.22620751
-57.59501161 18.23655385 33.1793573
-46.55307577 17.49730302 28.00207438
-66.79269084 21.16935386 35.84717278
-49.5181935 25.49225895 28.98251127
-55.90380075 42.23407179 45.60817789
-64.76547853 21.13785933 35.82618263
39.47742607 45.17929847 51.49447471
-47.11428206 65.77679504 32.82968873
-64.25748062 21.12938298 35.82770408
55.95501064 69.45438606 52.05457127
39.20961766 44.1612928 51.71001845
-60.70464684 21.0716383 35.82049977
-69.04511301 8.73907599 17.4305125
-59.61290675 21.05364387 35.82119081
-43.10294579 26.38258765 29.07678154
-59.18044531 21.04610442 35.82624132
-49.61810551 24.14178093 33.02561846
-55.78245491 21.06646906 34.94181634
38.58757753 69.24858861 16.96920012
-55.44185026 21.21676627 33.13210104
-17.13779048 35.27194899 37.46369587
-49.47559776 33.11644158 39.52020864
-6.661612607 59.74971586 36.81544744
-11.87719573 62.55597191 28.53958442
14.50066145 35.37493002 36.06121494
-48.13124034 21.41911914 29.38886351
-46.66272264 21.42167445 29.07914439
-46.53329842 21.85194467 24.05957085
-46.46334665 46.64344302 39.23375351
-44.51394776 21.82441589 23.99404277
35.13403758 72.55727375 49.1378749
-41.66898248 21.80163689 23.71592604
-52.52250513 68.87364834 38.69702322
-30.70432294 77.25432284 -10.31306081
-24.09007426 20.68958593 33.27302343
34.00800212 70.11865482 48.52868033
-1.807257715 51.79952464 40.78170188
-51.9469945 83.12762518 24.60936496
-21.68053379 20.63867445 33.40452726
36.64999633 19.36201491 37.10094148
-65.78365506 15.62487687 30.09938731
34.37965942 42.88584392 49.95753898
40.19493383 19.30825343 37.04900304
41.96151738 41.55049047 52.36010912
7.404243385 32.9622845 36.28908995
-16.85106661 49.0207185 23.46928534
-16.63721272 47.60441663 22.39004509
3.202941456 61.54170188 60.74466727
-3.690909837 52.25415863 35.86320174
-23.01346537 22.07437203 34.47202287
-40.25327763 44.35141968 41.35037246
-71.1359586 36.22818617 42.48741304
-58.33404726 51.26098312 46.16020184
11.12811731 42.61387319 28.41577425
-55.27178 65.24592252 40.54812935
-18.8519265 25.82641125 36.73511034
-65.09791074 14.26786843 28.24183305
-73.68191079 37.75544596 42.7233179
-37.80660327 66.86152065 24.28901147
-62.63903932 81.83017971 30.05681504
-54.85597088 13.09545119 22.41898503
-24.56430201 74.98617465 3.193040319
25.92343955 70.38104885 47.02439331
-66.40759814 54.45678499 45.56052124
-65.00600037 55.42319594 45.72770634
-67.94401711 63.70181528 43.41000255
-55.72858169 50.78246797 45.39163603
-64.92778306 33.53401339 43.44619637
-59.93169663 42.28367865 45.80044918
20.0896185 49.09546592 38.86331861
-51.0752409 48.80494272 44.15420863
49.23193085 9.575079825 43.43584112
-72.39382246 10.45493758 21.45672213
-1.974219737 47.3009293 28.94346873
-49.70550504 10.63083719 15.08795181
-75.80892654 10.38699113 17.07013337
-3.879464418 60.90026356 46.23504273
-71.08021988 40.69523566 43.05978094
-49.50587047 34.88613899 36.46196492
-47.72516623 42.33878861 37.00619165
4.928200432 36.52508795 36.18814984
61.03213941 4.089636054 58.25138044
15.01701879 74.50550788 53.66435182
-49.5295034 27.31526433 36.95509702
40.15616601 18.75125992 37.69574274
8.880254539 47.39090424 60.78881104
59.86055663 38.57781143 60.14912051
-11.52858538 38.2241937 31.25537901
-5.078975418 32.75994425 23.53870321
-49.89072797 12.99903276 5.11145352
30.81120889 42.54836172 48.72916248
-66.9714688 9.943028852 20.53861581
-19.04990107 45.07816128 28.87021244
-68.10998708 36.66587197 42.65600688
-56.10779498 11.92959901 24.53849689
56.63440501 8.584652379 47.6951093
-63.12708188 59.48423508 44.83900623
55.83182574 50.68675409 54.36071953
-23.60906161 21.67437624 33.40240858
-54.21537122 55.43108978 43.57823085
28.08943486 71.13531144 49.50857661
-39.76065336 42.12456362 37.97419141
-48.53514953 85.38432578 9.414557456
-72.38552144 31.76868184 42.05512497
28.03639187 37.88340474 21.83958512
53.21181097 67.59041121 50.90953258
-56.97205998 25.72237838 39.38589748
57.98836607 5.749857878 51.21206034
-52.29629847 39.38323247 43.05498313
29.13442858 30.39093829 21.20858799
29.59936225 46.01283571 55.35525458
-40.21856399 70.10705308 27.85896466
23.91100071 34.3180129 23.22950642
-6.064648158 44.36878398 28.80217535
-18.26838854 30.27499579 37.42117837
-39.82361668 71.18481397 26.92549247
33.58607595 70.5859291 49.01132431
-72.8024915 21.75459415 36.02602223
-65.70471885 21.61830945 36.25452674
-64.84228953 21.6044884 36.25050118
-54.44975279 9.312152435 13.82104044
-55.75599311 70.87827747 39.34903451
-51.50075275 73.11929853 35.82868967
-59.10954275 21.5125543 36.22447012
52.59233154 42.78064072 59.35883195
-48.63131689 35.99452329 35.08153005
-59.43837831 55.84243995 45.62567785
-55.80573359 21.53087209 35.38176739
-55.83362121 8.739902684 14.90140449
-55.81860243 21.58691937 34.73358371
48.76834338 65.62924461 39.56373452
44.05881018 46.84603576 42.92535486
44.11453899 14.44643865 40.30123758
-54.97387449 21.70591689 33.1910788
57.98018357 5.251769442 51.16915398
51.14883316 42.76546463 59.81029091
-54.64716239 21.68977709 33.31613542
-53.94133936 21.66823215 33.43164011
-37.50980917 46.58026782 32.43300202
-52.97678559 21.66483999 33.28707257
-51.99509008 21.62802298 33.5272561
9.507314995 35.44077036 36.2491534
-50.95424734 21.6109254 33.52724237
-55.80712549 89.7005475 7.308641857
-22.85852183 45.42026143 31.4518324
-50.44877762 21.60372437 33.51444104
-49.83131812 21.61436468 33.27316725
49.37129004 65.87686542 42.40082727
-48.83661616 21.97982129 28.84098396
49.32737767 11.08313634 43.39094093
-55.07659136 81.45283487 27.16846381
-64.91418282 78.53983225 33.72760839
-45.92734971 22.29627396 24.61255512
-43.98241519 49.66226763 32.84910917
49.99543417 68.82975525 48.78898621
-45.42077174 22.28851388 24.60603227
-42.38126933 22.24193597 24.56709136
-41.11150517 22.31997284 23.41903061
54.47126065 48.10534507 43.80057941
-58.22780407 17.32832748 32.19010722
-17.34248602 50.63325042 28.150119
-22.21752204 21.06487932 34.385878
54.77591223 39.58263788 61.1073158
9.255286831 45.63034307 28.71539587
-21.32690159 21.03683751 34.54156114
-55.55208952 74.25949555 35.01834636
-51.73820364 57.42926208 43.21614126
-54.17768936 9.193031442 9.325358123
-65.00589148 38.42212973 44.98267934
-49.49387908 28.19595566 32.55123568
34.69115288 19.96034883 36.35524862
36.31370006 30.74053613 21.60771196
38.03477071 19.83469624 37.17626773
-16.08537129 25.78031191 25.08938282
-69.56081145 78.07722506 34.15738948
-64.02385191 32.0669426 42.82471685
-17.76809281 51.62937523 28.32082278
-43.76018489 33.50403064 33.93082752
-11.6371236 32.39436071 23.20670112
-46.80444907 63.13420035 34.31456522
-70.63475451 37.70500713 42.72773771
1.29673762 57.8660944 57.16419819
13.15878346 34.30406041 37.0952735
-69.8158967 29.75204248 41.66913994
-36.08114027 64.923821 23.1476233
8.564874784 45.68128086 28.25573851
-49.75640838 84.11060276 18.60712583
3.061583018 57.30088953 22.60245034
-46.9611516 11.19726477 2.162439467
-69.76675239 61.14386096 44.31900532
-55.89533258 8.138268182 10.24410305
-53.60576375 21.20734985 32.891261
-68.67473865 22.13205352 36.68365216
18.12425872 29.30445275 35.9210129
-36.2012704 66.01362945 22.17252264
50.46897191 64.71516347 44.0240953
-39.31013677 57.19251607 31.94107122
-48.36024574 17.01469459 28.12254256
60.04340307 46.03120551 60.98942598
-52.1593627 54.38063723 43.72727583
-54.6920817 27.79524317 38.19432807
-49.75248456 11.47886371 11.07904062
-49.47957333 33.96755301 35.46728445
-49.45203952 64.34106281 38.28925971
-38.85427681 44.55582819 32.88400917
51.51393651 10.44426703 44.56377145
-46.9838205 37.91565855 35.77196616
-48.02447574 85.87671562 9.427789089
-54.34795127 60.04269065 42.508454
-54.90585821 37.95406021 42.66359378
-6.169884278 68.60923108 32.926848
-42.48458656 36.871636 35.38042902
59.60350736 66.31031108 52.89765279
48.95474571 10.09323008 43.30027649
-35.8823059 34.1883433 24.48446645
-37.4718709 49.87176061 29.17556132
-55.75901223 26.38079705 37.33779809
-62.96194219 14.68372884 28.83352086
-15.36297317 59.21877557 21.33182539
-69.99932371 23.56354078 37.79843254
-74.67326436 38.76440016 42.8529801
-54.52783493 24.94687278 36.26912182
-52.85783561 17.05345574 28.5302922
-15.32630941 70.43486733 19.30635393
-44.80372508 52.69342037 32.77775006
21.51049026 33.86215325 23.15260879
-55.67671847 58.41343569 44.19551611
7.604123421 41.80130493 26.86742444
-64.83447275 19.74034832 34.58274512
-69.97334202 63.73437549 43.41902435
32.48317349 36.21495813 22.88018048
-48.60533386 45.78461728 43.78547906
9.436414802 71.28439811 57.1617547
-51.79403361 78.02257522 31.40357313
-38.79501497 63.85792159 30.212274
-63.75863379 31.57960685 42.60484632
-52.7153135 41.85790235 43.54032234
-0.01308478506 60.61667892 54.61640065
-43.26508037 25.45994291 28.16515316
-49.81351318 23.38458762 30.19965417
-14.77443057 30.24695047 37.08043008
59.17269698 67.30906079 53.03887717
-41.06371057 25.53881907 26.82968651
8.010712099 57.61090314 23.8863986
-58.89658308 27.1839425 40.26593551
39.62109075 19.85216081 36.67100545
-45.24013761 42.29935352 36.99007301
-65.63565183 22.08568525 36.64235682
-1.845910682 59.11114355 49.13672049
-26.37071102 48.47079947 31.6686015
-49.86982939 84.40893655 9.338837068
46.72497092 68.89372784 48.67004275
-17.89025752 56.91138318 25.29312558
-13.11221851 39.68724397 32.05315491
-53.12507737 22.14274903 33.59407674
-77.40093675 4.8413617 11.83176138
-51.39342066 22.08689984 33.91217758
-62.59292112 9.462101414 19.45991612
-51.08964704 22.08233115 33.90728286
-58.66149822 36.35968202 44.40860005
62.15278509 54.7437122 58.49891283
-49.93817172 22.07137061 33.81492753
-43.56339308 26.85690463 29.48501493
62.27578095 56.2626497 58.32244434
-55.03107874 87.52321441 9.130153397
-64.10524359 9.481643011 19.52147198
-18.75941853 53.11575355 28.73483295
7.411579388 37.23647959 33.28284161
-1.403874955 47.78799651 29.00710974
-49.69603589 22.09789453 33.46084169
-55.78293779 23.07557675 34.92528374
-13.63893878 30.91971368 34.88059916
32.98438103 44.48353999 54.98296932
-49.57345849 22.1351729 33.00471083
11.8899542 68.84887877 55.8339313
41.49298885 49.97761786 53.67411073
-22.16789662 23.50137065 35.22504922
-49.68360616 22.41373658 29.7919457
-72.1633799 19.39189927 34.19882166
-68.12431437 16.57114036 31.21412978
-14.56075196 30.82628323 24.48771986
-49.55511232 22.44803045 29.36933275
5.823610423 59.32881188 21.84067593
39.58670398 19.29295731 37.34256475
-37.45274144 41.77709882 29.9144007
-42.66279835 52.53688902 40.01323463
32.98060149 48.55273045 54.35868452
-20.90565901 46.27537781 26.9792349
-71.98645831 46.68341455 43.63714597
-48.23406008 22.76449263 25.44367563
-45.81970172 22.74418794 25.21895461
-43.79418641 22.71354946 25.18835055
-49.76993969 84.44619955 20.54049509
-41.06378201 22.82289321 23.39830374
-49.48400092 33.51712615 34.87037152
-68.03148426 78.04910558 34.19216897
-25.00396697 21.73379927 32.97859943
-50.74891949 78.00332298 31.42775767
44.59236266 15.48239332 39.8372776
-53.67068316 27.77556511 38.22797852
-19.3059833 21.51073397 34.48146478
-55.36216562 38.94089381 42.94801145
-53.99767573 68.37223299 38.97250158
-65.47281262 33.05895399 43.23833201
-18.02204428 21.55684261 33.7013471
-17.79767372 21.5675502 33.53425672
-46.84205719 40.35522747 36.55783918
-71.3719136 68.46425926 41.21756528
32.36930101 41.04198073 42.61263027
-1.096186435 59.88605119 51.65133232
33.09007713 20.55165508 35.62290958
21.78729935 32.38931634 22.71766261
-10.12461964 83.16436279 -7.099458538
34.32744902 20.47258695 36.30481601
-56.45085462 88.93504518 16.31791597
35.72992166 20.40934668 36.77149536
-29.44226314 51.54684071 31.5052785
-74.71445968 56.51140876 40.77303935
-29.91910921 52.56881271 31.38570092
-59.50279178 16.43432744 31.15817795
-58.80953008 33.92327521 43.5872144
-55.2744235 85.59420658 14.08996953
-20.93110591 56.56219439 29.92667266
-58.54652846 33.43705755 43.35478692
42.37443282 42.42096467 53.82954592
58.45384326 64.9128213 51.86005903
29.49577859 42.54291642 49.04323592
-49.6366402 29.97950005 35.18034018
-36.77955821 74.1444329 44.42732653
-55.28181548 40.90922602 43.38941511
-56.27843962 87.59586142 20.1779767
-48.99636545 59.5226544 35.87155201
48.69542992 40.59997856 56.28350727
-12.27926239 82.26236532 -2.044150419
-44.87030166 56.92483643 36.10884936
-51.36780294 70.44204168 37.74968265
48.91701199 45.73336445 54.91556915
54.00366989 44.09568702 61.30358703
-54.19621785 80.86580137 27.98864382
-57.54935185 10.65457402 22.13490801
34.71410067 19.01867454 35.62923282
32.0396184 46.52297957 54.79440167
-49.53067422 24.04146237 28.34687419
-69.19239272 85.4113021 24.6941411
-48.78171334 29.2871436 31.40139707
-67.72413502 35.16280718 42.55117287
-55.81539956 42.84036176 44.37970341
-66.72931003 13.84526122 27.63224265
-62.62469799 38.38551762 44.95359155
-10.99708653 37.44720901 28.52054213
-21.18312822 24.93248484 35.90375275
-0.5826162872 59.27343653 52.83842877
-40.3055323 79.37757475 36.78938796
-51.60399822 51.83718711 44.01438524
-15.35799265 55.15976787 27.6643141
-21.01537023 45.40728108 31.25101764
-21.24049038 26.41851302 36.14371511
-10.93458484 82.72383634 -7.657681764
-39.34960997 57.15141228 44.07904553
-55.72074923 84.9521266 21.6288207
-60.68556374 24.83196166 38.77741022
-55.81871059 16.50859903 29.59340243
57.34180145 64.47600751 51.31634444
39.61594541 16.21535488 38.10419809
61.34689018 8.549918547 58.85290935
-58.87654196 9.824073787 20.37578187
-53.18506482 33.05497566 40.94116784
-71.77036399 73.25953169 38.06629759
22.83460338 28.35216017 34.42434715
-13.85808992 30.57760215 33.06723172
-39.59088722 47.85490718 41.33949707
-16.80728693 58.59029443 23.07649183
-66.14652426 22.09561163 36.62455058
-12.9907818 50.11630821 27.49462432
-14.49365927 75.81177726 20.821518
29.03440523 22.77280454 33.91818996
-11.3634692 83.24417632 -7.789736271
-50.0783564 33.97892834 41.27606469
7.528440877 57.70551332 22.88006646
48.17741124 66.89552914 48.28303881
-71.14330615 22.64062311 37.07721678
-16.70610503 36.3063998 37.02599257
9.600612076 55.19647422 28.30508529
-70.63278004 22.63087144 37.09306099
-69.41363868 63.72459465 43.42582863
-69.25819645 87.23608959 21.00314742
-49.81854755 13.21705948 8.393316304
-65.54087808 41.88539889 45.66703486
-8.398811344 83.18579735 -7.677408622
32.13465196 24.10312136 35.36358862
-64.01935317 16.50743645 31.17081049
-55.71397059 59.53577065 42.82701857
-67.09044001 22.57842023 37.02640738
9.034001258 33.39810864 36.74556163
-57.9532748 40.77014796 45.51339831
-59.47559702 22.45386052 37.02019108
-55.80849869 22.61103254 34.49628559
4.793284407 61.45802571 61.4127535
-53.68518303 22.58061527 34.44446242
0.5277527067 68.74432367 47.56124
-52.82874519 22.56981656 34.40649385
-18.04010154 62.03390873 24.12209542
-53.9262771 27.30316969 37.93399167
45.45593319 52.42229962 53.6719079
17.0283034 34.33434484 36.00576891
7.971360367 60.5449385 24.79332503
-49.66359682 22.80674765 31.0524115
37.59652822 50.56225086 53.45698347
-49.54692982 22.94611889 29.41223911
-72.11680206 86.07548337 23.36818324
-58.84159494 51.26923236 46.16123102
-40.85296482 62.07616121 33.80879017
-45.98117994 23.22874876 25.45125474
-45.26199654 23.21285324 25.49862902
-54.23866073 40.40113507 43.26212887
-7.555401542 45.88409333 28.97557689
-41.131336 23.26007481 24.16270462
-25.19110085 22.2560797 32.77791542
-47.66517726 23.68843906 26.26262366
-51.27600666 58.44832164 42.95129222
-23.2047297 22.09652001 34.25138817
-48.82243005 56.43400705 36.73380876
13.68351997 45.86474475 60.10964987
-22.28739731 22.03022575 34.84603963
-69.79632611 67.91058817 41.51786849
-20.23829705 21.98769005 34.94904936
42.46208361 31.11459887 21.91942603
-64.1167585 16.05435566 30.62243785
-19.73656768 21.9823758 34.91505818
-17.70879672 22.02493304 34.03431454
-17.52491921 22.03128913 33.92546183
31.35693335 21.184804 34.43001651
31.66153412 21.60770052 35.28927693
-44.27723195 25.47495642 28.18388873
-13.31970062 31.91177609 34.9564159
-49.72604898 21.15815802 32.72243072
31.62488205 21.14875115 34.79744499
32.05582604 21.10481037 35.225358
-44.91415915 22.28073634 24.59970562
-75.43613027 30.56199275 39.16509881
33.24988519 21.02281311 35.94952797
-28.46166925 53.05439426 31.29741422
-55.97183567 26.66825781 39.86797184
36.42407468 20.90829353 36.67361796
-40.33209374 60.20099412 32.17120109
-49.51960298 36.48070681 41.26024119
-11.72215185 35.71830393 37.07592793
-66.51331381 17.00403753 31.708157
12.25299035 51.58188682 28.97354325
20.8688662 34.36591855 23.25353595
10.66275515 54.1518109 28.57646068
6.018770324 52.25555556 28.16864879
48.79458177 11.63111569 42.95784491
-15.32071441 27.27383258 36.7388865
-34.70597386 81.2692379 42.89491757
-52.62086581 45.82798728 44.04779459
-60.13414302 30.0781788 41.86338399
22.2147982 37.44360725 22.2286473
-62.00629825 56.903343 45.45294056
-17.8442371 41.67302073 27.3831553
28.10115167 45.02082664 55.50365578
-45.22839217 50.27932933 37.57670436
-10.18739618 42.95959007 28.4674368
-49.72375689 85.24723119 17.0593682
-37.73880871 69.56806206 27.81642066
-0.3605998704 58.75833777 52.94909034
-5.583209677 29.54834415 20.13097485
-65.23481103 85.92415846 23.81243631
-68.82853114 53.10590668 44.22426617
-8.312480852 82.6400327 -7.184878795
-4.666161575 39.05573456 26.12018054
-66.65245055 31.14959036 42.32201883
-8.185098459 55.4960641 28.21906898
-49.5561636 25.46851585 35.09202153
-49.48975345 56.82684688 38.12734103
3.210035917 65.87796345 57.01795189
42.43095948 43.92387624 53.85179426
-55.86044621 8.275996139 14.46524495
-37.77849985 51.57691057 32.74597623
7.299383961 58.24894145 22.44187951
-71.58743197 23.1190958 37.43409506
-71.08223932 23.11203453 37.41972412
14.75638338 44.52694149 28.82211288
-70.7717123 23.10405915 37.45308885
44.16466684 67.96670288 48.26660848
-70.51383447 23.09786431 37.47582423
-69.55422818 23.08457893 37.44704792
-69.70299408 25.92514013 39.45995661
-65.49717146 23.02026215 37.419979
-62.45642227 22.9730553 37.38810137
-53.74151084 85.39083347 16.15853774
-61.94503034 22.96286687 37.40885064
-65.28005552 15.61861923 30.07599105
-60.93319046 22.94801059 37.38834925
-57.89472703 22.90195678 37.34352227
58.25645246 64.00796124 50.74869667
18.79148084 50.53173094 34.09094967
-55.79648829 22.93374504 36.57435408
33.49022516 46.53456989 54.38321324
-62.03695422 67.24807294 41.90242917
-50.18489421 56.38461294 43.39368375
33.65217482 44.39445606 55.88976837
-54.28360264 23.06258648 34.790152
-53.78131914 23.05699271 34.75930006
-8.421189389 68.57366324 27.94244107
-39.62821928 42.16925881 37.43008102
-53.49321101 35.97593479 42.05115335
-52.25542061 23.03060279 34.77465552
-65.67808686 10.77819438 22.25003122
39.12941583 20.47850847 35.32031067
-51.74382088 23.02030954 34.79658201
-11.68848846 43.47568272 28.58916483
61.9864639 57.84090755 57.53597244
-49.5936083 23.05065792 34.03421723
-49.52460256 23.48662784 28.95999961
-15.2123355 58.82640566 20.03137305
-49.44747169 23.69549842 26.52056773
30.680693 41.42966329 44.26079701
-49.16250371 23.70041606 26.40913513
-43.10642581 23.61882551 26.20136736
-42.09351231 23.60342765 26.18694824
-23.60484998 22.62712806 33.9946488
-58.33851169 45.73885889 46.17285164
-23.04629542 22.56837102 34.57022387
-22.74954223 22.54256586 34.81319638
17.53936323 29.77960185 36.34331949
-49.47535274 52.38063085 43.126389
-61.06744562 22.01130233 36.63466376
-21.72820292 22.49813982 35.13415074
55.24694019 7.561556485 48.18326927
-58.07313751 59.91482056 44.70327912
-18.36201835 46.63425889 28.15463501
-71.18972904 33.24201403 42.20348756
-17.77161605 22.48450413 34.53789883
-44.14654126 23.63555622 26.20550134
-47.6074428 61.58661373 34.95329329
32.61469857 21.54810896 35.79928515
33.91167658 21.48852877 36.24359399
34.33283542 21.47430189 36.32843259
-69.72566925 75.90374343 36.1136266
-55.75877601 82.96910371 27.1765279
-19.17367369 46.07069573 29.02503417
32.48543054 20.60888498 35.07385279
-69.92059378 48.64128637 43.82133187
-0.3043752253 37.23194021 28.98037533
-55.74135799 45.26453659 45.35719728
-65.06192079 40.88713977 45.51093352
-24.36192536 46.439736 31.55701748
7.967699254 36.75106824 32.98515448
-4.459368957 68.21715651 37.15213085
-36.65897908 41.41958943 28.08661414
38.99064746 17.74009453 38.00307816
-40.5394816 79.42125477 36.32693388
-51.39240532 9.758788766 13.87974635
9.468935203 42.18050291 27.93642779
-70.83627187 61.16303534 44.30037897
-64.46402463 74.72290834 37.1649133
-49.56679923 77.04680539 30.65299252
60.50531718 69.28992104 53.09603063
-14.07572103 31.28205267 24.93095818
-38.02474631 67.47377694 23.04973553
-65.22042503 29.67629468 41.67209171
23.68958962 49.02060218 44.87249729
-5.570320702 69.48466375 34.30312828
-6.860809625 82.14887004 -7.586587561
37.98734131 49.00712783 53.95553361
40.62719403 15.17158695 38.37488505
-61.02927908 16.46101435 31.13948705
-46.98611782 31.63578762 32.927397
-52.94946386 72.60453594 36.25406662
-20.89708236 24.91208089 36.08606604
-46.5715984 46.30160395 43.22271574
-5.178647202 68.381767 35.3783825
2.031026576 37.90074724 32.42428239
-67.60542358 44.11803488 43.44925135
-69.62485984 14.33382851 28.33943669
-65.85105656 11.6344898 23.99579749
-55.9868922 66.63013425 42.09548857
-46.25537151 42.31592188 36.99134658
-49.76644457 87.67407437 12.20150499
56.93139802 54.91219661 57.53877244
-72.505363 13.0537201 26.26927558
58.09736795 68.86259167 52.6893513
34.57855038 34.64243054 23.25570491
-63.58117118 22.05282439 36.63202786
-42.95314049 27.31145179 29.91856188
-64.13180566 18.80268582 33.68053104
-3.807523987 52.76564389 35.77438748
46.74148164 45.18213456 50.07625212
-61.93859438 7.866503808 14.55142923
-65.89108762 57.48208853 45.3019335
-55.8100874 89.40470736 10.74353557
52.40751254 9.335968694 45.60601551
-77.50992784 6.657426813 14.07690134
-33.9944202 80.77975297 42.61487747
-72.45751352 80.90973873 30.96112595
-1.484144322 52.18830478 42.03347971
-59.25305452 8.997181126 18.39347646
-64.62155649 37.92426227 44.86235223
-46.68640737 86.94950696 8.372148268
-37.41232815 57.70373623 31.47117184
-0.5541864774 58.30421903 52.43110926
-49.49126247 37.91684413 36.23638735
27.04454701 34.76337822 23.28843563
-44.4780693 27.33735254 29.90870009
-58.34551126 70.34431604 40.21484437
-55.23409861 42.39180375 43.64939356
13.17530323 44.89367729 59.82614779
-45.69163784 29.71727058 31.64551418
38.58956745 69.53531699 48.60011876
-56.66797888 29.05972178 41.37207621
-72.57287248 23.62285118 37.60070525
14.74310985 44.03142117 28.75036477
-71.01535417 23.58051096 37.79519345
-7.067495494 83.73500105 -8.480215198
32.54634179 34.17422936 23.25183932
-67.46691875 23.52498499 37.76307144
-67.73404916 34.66559226 42.49845639
-66.96103345 23.51757432 37.75262454
-48.96917368 27.87505818 30.34974217
-72.90513935 27.91395872 40.28953102
-74.59434296 10.67296725 19.34532673
12.76289216 47.9167876 59.77017164
-63.91107192 23.4657204 37.77293674
-43.48643693 18.6206434 20.20333265
-46.58633711 43.49396544 40.85879762
39.48519079 48.06478851 52.95596533
-61.37388762 23.42475372 37.76465156
-68.6868191 85.40404866 24.68192838
-60.39079086 23.41200772 37.72513403
-59.85851747 23.40367475 37.72036151
-58.83812328 23.38450333 37.7483221
-40.36196386 47.38144587 29.50290587
-56.81070317 23.35290398 37.72850916
-10.75043009 56.05344547 28.06444425
-53.69940334 23.51788706 35.21992121
-49.35932022 66.32127442 32.7637578
-59.33027776 64.07114456 43.30650779
40.93622227 67.54164343 13.03020067
-53.31373 36.46194682 42.20158377
-51.88119466 23.49271109 35.16543897
-63.47002767 12.46611457 25.54089573
-55.71295271 13.43529536 24.46389874
-55.83945063 88.96685906 10.00535696
27.77956527 31.33548311 22.15528491
-49.55177272 23.55654823 33.98013595
-49.0722414 24.15710009 26.91704104
-77.48659093 5.213340209 13.35653927
-67.90931467 69.45646477 40.69226289
-55.80663318 89.25429778 12.48894126
-46.99081346 24.13542377 26.77173477
-10.84559427 50.00052431 28.42962835
0.9491903413 65.32021433 52.27058491
-55.27435747 32.11335399 40.61735817
27.94242196 35.77327577 23.04685939
-42.49988834 24.0628578 26.75768707
61.584308 38.9662214 61.13808534
-22.54794452 23.02981025 34.94510288
-20.82585739 48.49842419 30.29047643
-55.75203947 45.22036905 45.87196338
-0.5799431518 37.27183651 28.56978234
-2.555957248 29.48316777 20.31027462
30.34674955 22.17847908 34.74064095
58.26671972 54.87044109 57.76884726
30.63998173 22.15445006 34.96366622
-66.12233798 49.38983928 46.0605153
34.35845418 21.98837262 36.18247957
36.23546665 21.98308932 35.88585439
-70.92548873 28.81177689 41.14275311
-8.595650721 52.94430477 28.78684408
36.87121091 22.00928391 35.46052818
52.7829857 57.96728947 51.99735565
-21.16576726 41.46541929 30.4265805
-9.80807052 60.99668673 28.76638748
-55.06562507 18.93712631 30.39087207
-20.94728427 41.47909682 30.22613572
-51.97396197 35.95289915 42.02883796
-12.03080612 30.08187013 20.99365226
7.346408759 48.42573513 60.72153426
-11.71066651 62.52152348 28.90772886
-8.243605306 82.10307303 -6.791234947
-10.00456353 30.05086477 20.96716845
13.4123579 73.36897386 55.51080509
-55.61553298 69.97643657 38.13819327
5.299542218 37.16209612 34.54911768
-23.04654073 57.06607441 30.30733268
44.54011695 11.96845816 39.85307872
37.8813586 16.84923172 36.90312994
44.45504759 30.17028212 20.84839192
39.61179107 29.80686206 20.16423421
-49.48545951 63.12382954 34.94624202
37.70979927 30.26501734 21.03499564
17.89746838 29.36929935 35.21147639
-33.72082594 51.62378279 31.42802628
-49.6323139 26.70958405 32.35259972
26.42112959 35.79734215 23.05759884
-18.07771173 23.89778083 35.66984866
-42.3370611 24.98515314 27.67323949
-55.72844297 10.09438314 16.63747166
0.3053373899 50.82629786 45.85010405
2.441969612 37.36467975 32.74233499
-3.50045743 45.82084223 28.93653963
-71.03842035 35.24888541 42.18396804
-34.53446575 53.29308548 29.6846308
-75.85826923 30.14883159 38.21523851
-20.73089845 18.70827722 32.32632225
-72.95234732 24.11826699 37.74856609
-69.43137846 63.20654945 43.61641768
-72.72815143 24.10428484 37.8681256
-71.45733275 24.05790049 38.16423627
10.19368378 44.59854665 28.86100211
-68.92755988 24.02067244 38.1139638
-68.42392571 24.01439733 38.09076375
-55.8202305 72.9938791 38.10840067
55.0972138 40.52410371 61.7701011
-66.39307695 23.98106844 38.09037485
-64.36247063 23.94786183 38.08861253
8.343601256 66.62193375 59.05568202
-58.9404374 26.70384838 40.02094073
-59.31098067 23.86508046 38.08624964
-55.88033666 23.83180651 37.81827349
-46.56901142 46.10780613 39.64532749
-13.47408781 39.19412462 32.02001391
-67.99652398 42.63522885 43.25740845
37.82165072 68.73629168 17.23574269
-55.81562193 23.94782889 36.45905941
55.66305504 41.98070797 62.23278929
-37.67505693 68.24559673 19.8498406
-21.85384366 21.05487007 34.43271702
47.47936455 50.50773754 52.20509752
-54.14986697 23.9995568 35.54089446
51.30656065 57.29306194 54.27920345
24.53932744 68.92172031 40.92273797
18.83618959 27.85990119 35.07472938
-52.62930189 23.97585729 35.52603476
13.15280142 42.583654 28.38046133
-40.4024338 61.1170193 33.2040085
-52.11898358 23.96621043 35.54070176
-78.14763796 12.10704445 15.02842768
-51.10991433 23.95275177 35.50450418
-49.49452935 54.36773163 37.54225185
-49.75070662 23.9608918 35.15079959
62.0959546 56.78124392 58.16315903
-1.229189104 49.7830755 29.1200268
-49.58238352 23.99987203 34.66618842
-77.27360295 9.724956016 13.3815603
-45.4715207 24.58980424 27.03385728
-42.96783941 24.54095999 27.12340994
-35.0736612 45.33139846 28.9862777
59.17881297 6.134732019 52.34377614
-65.59013472 26.33489024 39.74557227
-21.77832443 23.4782995 35.41858218
-19.60628615 23.42330455 35.64278301
58.59328816 69.36242383 52.61900073
-16.92076363 57.71743823 21.57760232
24.8510429 26.31723579 34.35612384
-33.13504961 45.72292451 29.89809372
-71.36631331 87.27122889 20.99725526
-17.65800206 23.43162465 35.17464772
-17.19876717 23.44774635 34.89991627
-59.86675931 51.79302609 46.10279712
2.130086521 39.00387654 31.25274274
-65.98234236 45.87804116 46.01484658
59.80669725 8.378637373 55.3083488
-10.19444938 55.01633259 28.34469692
-71.14689922 19.37470198 34.20461138
50.27946509 66.82628148 48.68604192
-16.5184036 74.81281693 21.15102771
-56.51333618 87.30458929 11.95078917
14.9820027 43.54110395 57.70333488
-49.68494443 83.42367027 20.74127031
6.955116506 37.19418172 33.86091651
-66.44019929 52.94180442 45.67381601
-47.14523137 17.90178084 29.24616932
-73.5226887 28.93257364 40.2357578
-62.26707329 35.9294266 44.26428279
6.496523579 34.44365537 36.7452862
-6.531123372 82.15600781 -7.732321142
2.495886852 47.28842076 28.23620074
14.24203262 36.00760899 34.59257847
-72.63561841 39.22985851 42.88764946
-16.43593954 44.04358869 28.72850622
62.08820204 53.26964245 58.14338384
33.76878527 22.51548533 36.00246477
34.27401255 22.50840659 35.98829003
30.81577757 31.29056487 22.09770477
-58.49381125 39.79060603 45.33443765
-71.41102631 50.65864989 43.99310184
8.124356434 47.90275833 60.8176304
-16.63161186 23.48376215 34.37365858
-55.23943842 32.59795542 40.81173274
-74.50486928 54.49593011 40.82365264
26.95717094 35.27250352 23.22144157
-56.85294332 54.27048558 45.90109092
-6.553507332 78.47834203 -5.821510046
-33.10178765 55.82479988 29.1546264
-51.22067022 65.69921057 40.34010078
38.1245855 71.53095843 48.82849486
-65.95662816 12.94661395 26.26375887
-61.2829832 28.65549555 41.1180973
-55.97091532 70.31103201 40.14838004
-51.35373273 73.64646307 35.50758217
-54.21818854 9.69755799 15.1294519
2.823046908 67.12716427 54.24339953
-49.735064 26.66230399 32.92105641
-20.48998696 41.97363409 30.22461776
45.57609817 44.27667139 54.98313492
8.3236206 44.14766412 28.62516684
36.23467634 44.07619204 53.26526956
-38.83805354 51.07645284 32.93107718
-44.85870399 28.28601373 30.62181987
51.7692397 65.16279875 50.23293217
-17.39795369 57.36348388 25.77756639
8.813220963 52.66136281 28.75146449
45.28723101 41.26042451 55.0931734
-66.34517841 48.39870563 45.95553326
-32.04213088 53.62829158 31.14465167
-20.80937379 52.25339991 27.4833201
-6.546676584 54.44564755 28.44733236
-49.75241329 25.61706792 33.40494644
-52.54078474 34.98661711 41.70095973
-71.3950884 24.52871792 38.51341449
-70.88760998 24.52050362 38.5119929
-55.88988981 7.682862314 9.703107794
-68.36216622 24.48545935 38.43719513
62.78260884 46.56399195 60.10870991
-67.18773799 24.46292042 38.4748729
-56.12829019 76.73912272 35.47611398
-23.84351929 47.43044139 31.61060484
-49.75772771 16.14531888 26.8281165
-16.83821251 48.20802516 21.24788931
-61.40343869 64.62803347 43.06374005
-25.07098937 75.09310133 2.048385525
-59.33249537 48.75800393 46.27381669
48.99594737 43.00902112 57.39347731
42.02606188 31.58898352 22.32221566
-57.60052033 85.76501068 24.20403489
-60.24005261 24.35279016 38.42838003
-16.23264084 24.75771616 31.16186589
58.09046308 39.49642572 61.47602485
-57.29739315 20.08289811 34.99545181
-69.06187655 74.81224576 37.00465715
5.153822042 50.12163289 58.93237712
-13.05190229 73.2919738 20.66507934
-56.18351538 24.28873544 38.39836808
-66.6560493 10.78835042 22.31863575
-55.48810574 76.43430645 33.06591539
-68.72890969 67.36548079 41.81566924
-59.59647322 59.93991768 44.70244263
2.082988588 59.1743482 59.30701349
-55.59817881 24.48902484 35.96163485
-54.08429787 24.46869709 35.9089081
55.54334704 7.535926031 48.42427976
-53.0646656 24.44991001 35.93255224
-52.04849662 24.43286995 35.93657613
60.57482426 54.29414844 58.19206265
11.47769305 57.24957618 27.41977223
-49.52839927 24.94563542 29.5034216
-48.4109749 25.07574085 27.77996306
-12.16621054 61.39333754 24.61148618
-45.96744694 24.57980222 27.24454415
-51.92258732 15.71925444 26.36039414
-56.81879733 52.24439684 46.10833219
-46.45077712 25.07805119 27.37932138
34.07437123 35.15119657 23.27236834
-68.44957393 45.62928335 43.54648337
-71.78148017 59.13541583 44.71215944
-42.84204595 24.99210958 27.68878765
7.793647297 58.65049798 23.51270028
-19.06059856 23.89803201 35.85437521
-46.88724951 10.24227597 1.581270913
-16.6505569 24.02031268 33.9752422
58.63082285 55.3624336 57.81464331
-69.92068575 11.69275972 24.09546041
-21.14189697 74.75310395 11.07266409
-16.96367427 24.52515184 28.1744133
-12.72201328 31.94385026 22.81680916
-7.151906808 31.85962501 22.73230464
-7.022355469 80.04805128 -6.474505847
-45.3653537 12.38782534 -0.309503428
36.1277625 70.54269129 49.02854544
-7.20197402 80.56654068 -6.632613952
-26.87791237 48.478874 31.67159271
-9.05585929 48.91094602 29.0836534
-7.068316283 69.34165221 30.42234576
-49.53877611 77.87511644 21.03200768
20.14664319 27.9917462 33.29426461
65.37772934 47.59734335 59.27118933
-42.03125081 30.60970369 32.24072336
59.73546072 44.93433362 62.12816025
-67.8260432 40.13726267 43.0899067
-61.55879958 66.71526795 42.16979058
7.900207861 55.81310796 27.29766884
-62.53274155 12.45169947 25.52949064
-55.63769806 48.87642559 44.19447129
19.18356247 72.42665301 53.69606784
-20.83958995 53.27307423 27.30524068
32.7858826 44.13101039 53.28660388
43.00901671 13.06384872 39.08225735
-12.96803815 34.12157403 38.36960951
15.53574813 71.35443273 55.18556188
-49.61142714 80.35517008 27.21543634
38.90583678 51.59999411 52.81369113
-46.73527373 44.04653333 40.29923027
28.58640053 23.25323389 34.25309358
-63.04855882 31.56830599 42.60061938
-46.14917413 46.78229581 37.56193403
-16.16724489 70.05951926 17.9973986
29.17904108 23.20206033 34.73413355
-39.4084455 45.74735309 36.63752517
33.22668317 23.04338125 35.80428663
-28.53221217 51.53377521 31.48340025
-19.52963264 51.09897124 28.9874348
-47.7793982 23.2686186 25.33134752
-55.73084826 51.2610495 45.6629856
-64.9703906 33.05329034 43.20826488
47.54075985 68.87865624 48.68942894
28.2885593 74.04816109 50.61596332
17.89065288 27.86608929 35.1832129
-34.48916589 54.80166031 29.64327688
-36.46085201 67.67599894 20.40395046
-34.1612143 54.7601174 30.062995
-49.8265678 13.27066094 7.772600943
-9.132891709 41.95594676 28.26407869
6.317562376 56.86589647 21.20459136
-36.46489594 50.86379044 29.12060061
-74.4775731 54.38304939 42.12884913
-45.28195774 41.31601726 36.76005157
-53.2104536 65.20960839 40.57658305
-46.14511493 26.43051077 29.10061487
-65.61212973 85.339721 24.84232905
20.40122378 32.81115204 35.56494364
-18.16467082 26.78149771 37.16998769
-49.6727294 34.97696839 41.26601364
-14.39434645 55.10314817 28.13782381
-50.41409828 14.79632595 25.13346177
-55.93044837 44.70903835 46.01524614
-77.75776979 12.46215075 16.65838552
-51.07806447 47.80193499 44.14511103
52.84308247 52.66703541 55.24870373
0.4905096537 47.24695612 29.09999009
-75.42611966 9.914672363 16.65350975
51.82655426 51.22716431 54.67771356
14.21832493 54.09268607 28.58475636
-14.8118545 52.1022373 28.09436321
-55.87780305 7.323216358 8.049194345
-28.92658986 47.01454837 31.58132579
-64.01702248 26.78689983 40.02495413
15.83409169 50.00323475 29.13682843
13.36141958 69.85437921 55.53401285
17.17758524 31.24574483 36.87217775
-37.61340757 42.13736071 31.58949883
-72.8345666 25.0508967 38.55274012
-69.31428995 24.97240838 38.79255197
22.75776428 49.74509042 42.46645421
-65.25470504 24.90681628 38.77980582
-71.25260456 32.24452111 42.14182235
-64.74320921 24.89657543 38.8011437
-64.23874385 24.88988104 38.78265251
-36.99613965 52.390641 28.97703902
-16.91338377 73.85896188 20.64611645
-62.66463779 24.86395113 38.78347437
18.58947052 29.26800375 36.25542087
-62.2073756 24.85629009 38.78520664
-55.72219732 65.18818609 41.30427336
43.1626512 33.52006263 22.99464933
-3.489932256 51.33990917 28.95822523
10.98217213 72.32675621 56.41979754
43.12277818 70.48301927 48.38727035
-55.28110696 56.97978141 43.28305303
-64.91365723 43.36059648 45.90217264
-59.70127791 24.81861584 38.74462897
-33.59407045 84.41542448 41.11948611
-49.86261658 14.80308138 24.94986889
-61.3650054 64.10643012 43.28492203
-3.54796447 60.83215287 46.9625025
-49.59076386 67.5234486 36.33210354
-38.89644525 41.56380353 32.66581147
50.12726166 53.82732355 53.95043708
-49.84307124 12.62810279 9.408391476
-57.68751171 39.78032128 45.3000644
56.0945986 6.966247966 49.10575073
55.21532057 54.47035989 57.16856094
-55.03576357 24.95531419 36.26799279
-49.64920258 24.91412107 35.71894307
-49.53286681 25.30143793 31.20049956
-60.2525238 73.57625158 38.0197016
-15.5094474 74.29719927 21.11763891
20.87197072 44.44862663 57.69817873
-49.53990278 25.40409858 30.01008145
-48.83206989 25.54259584 28.2673159
-11.07616599 36.73710535 30.95238407
-48.32285983 25.53350803 28.27570443
-47.31500272 25.52066083 28.23263976
-43.77030766 25.46702164 28.17932791
3.074561076 59.49848667 61.18172
-41.26006502 57.83590851 30.67060595
-41.32306407 25.48381366 27.5176898
-20.86892774 24.42044038 35.96137772
-49.50065609 46.84424077 43.30862131
-20.2465876 29.32688968 37.1514586
-49.66896477 46.80525327 43.79331423
-21.14996627 24.43831843 35.80743202
-65.58434884 71.00441997 39.75900675
-48.40503689 42.71317993 38.61628053
61.07243122 38.97665442 61.11458924
-72.62600625 36.28604715 42.09988154
-50.54668191 14.36099052 24.3857966
-40.59523719 58.55545891 45.49731909
-2.962932913 38.91511423 27.42778829
-19.01816419 24.37884241 36.09132568
-70.86358802 61.67903074 44.14217793
-18.52358073 24.37713285 36.01685142
-70.79925924 30.25474965 41.84752227
-61.41648401 44.29819452 46.00420013
-18.05572014 24.37651447 35.93480616
60.86484046 40.84245723 62.80114113
-17.06970694 24.38707515 35.62417141
-60.82020194 63.57717152 43.49840396
-17.29456565 25.8385072 18.81776809
-51.29378959 19.77334028 31.61746038
-20.88721418 46.57006668 29.38139523
-44.89203429 38.85602236 36.10990239
0.4407955437 51.26018326 46.61405401
-52.14041849 33.52578649 41.10307267
-11.32149417 40.3134975 18.61500197
-15.0464067 26.6008788 21.19214693
0.5247187726 44.76817523 28.73575934
-37.14481408 62.25552147 25.19283643
45.70661817 41.69975165 55.7398047
59.20373932 44.95477707 61.9922402
-18.65199454 57.27751553 27.014703
-24.4340512 43.46282307 31.16910475
-61.33891901 74.13093264 37.61438196
-10.28971354 62.22596028 32.06785845
-57.0981625 11.93362376 24.68064374
33.49018451 42.51020326 48.66123697
-69.85364902 9.15359352 18.59932424
-57.82120456 77.8541943 34.50767984
-13.34039671 28.90891989 34.85986365
-51.11313318 44.80632893 43.96713481
54.87456312 69.50385084 51.68639582
28.10307035 23.75148285 34.38787081
-71.52082388 23.58771199 37.80799477
29.00571842 23.69259597 34.89933248
-12.9521226 56.17295494 27.09696701
-14.19827362 70.90077989 19.50921859
30.51312001 23.62598095 35.38517771
30.78031985 23.61508371 35.46072422
-20.88353196 49.07928934 29.38500952
-49.48548399 40.25156437 38.26535365
-12.1214904 61.8729949 24.86138602
-70.94754273 48.16154826 43.75968644
-10.90837681 36.77635491 30.46474812
-56.99756338 71.91433977 39.21172383
34.53464784 23.60436425 34.86919063
-50.1415225 12.47466061 5.419935216
-69.6472607 26.39924197 39.77224878
-60.33397858 69.85404999 40.45878233
-30.81765987 76.30706257 -10.94824009
-60.82242109 84.65138718 26.09294355
-49.84452176 84.60105212 12.9304345
-58.79092928 74.08451526 37.66731192
-66.73895315 67.85499562 41.58016798
55.89108758 69.98884748 51.68898436
39.20143515 43.66320436 51.66711208
38.42940744 44.73496662 51.02582558
-49.79402252 36.4209124 42.00671192
0.4824820619 66.51051739 50.1949638
-72.5690593 42.71179555 43.24057622
-49.64511115 84.48234235 20.09711739
-60.81804272 15.09558858 29.47013901
9.236557927 51.69116085 28.28026219
-50.22446795 22.99722148 34.77485523
-56.74998475 63.50621606 43.54589193
-71.12025741 38.70656812 42.8467568
18.24636839 34.91589905 34.84901158
-40.76896576 60.55191842 34.00737101
-17.84884685 26.29341216 36.94917057
-15.52546717 59.16446598 21.99327941
30.74959316 72.53895972 50.18661758
-49.64695611 77.38457405 26.74721318
-41.79500045 50.57917036 33.44116186
-46.74355607 86.90400154 8.911307134
13.1987824 32.27501921 37.33567297
-69.43956532 31.71973679 42.06150335
-35.4942914 45.8475747 28.90098475
-53.16677193 27.76915024 38.20634809
-48.41919836 38.42338002 35.97834087
45.99503003 50.94104333 53.28468021
-13.91401289 30.7544791 31.02457948
-13.28170941 77.2026254 21.92433441
-69.41891495 23.55570422 37.77871766
6.430858097 38.24007044 33.47273792
-60.11567697 38.83619582 45.06994564
-34.11535469 82.82484351 42.20360038
-71.27376323 34.26405851 42.00815695
13.09653939 41.60760281 28.06862351
-55.5728411 35.02943893 41.78208414
-65.7912718 39.42629402 45.12866592
-71.47110908 47.17344592 43.67686492
-24.22674131 74.91500666 3.954835499
-71.06452836 84.86235359 25.59712273
-55.67916271 13.0647014 22.93293875
-59.88813494 53.3104569 45.9713519
-0.3479817875 30.84235915 21.59063783
-71.79400922 25.48660338 39.12293944
-71.28244413 25.4763276 39.14466972
-66.72047182 25.40508931 39.10166024
0.9618618767 48.81316913 28.30827926
55.16238562 41.99787646 62.12896553
-66.21084617 25.39579186 39.11240319
-64.12891537 25.36147295 39.11376548
-59.83259589 64.0767558 43.33716353
-60.11996228 25.29664375 39.10181877
8.425148743 60.06159162 24.4911872
-72.2993803 12.16838265 24.85435465
-54.96828966 25.42349362 36.64679755
-54.46205803 25.41590825 36.63831268
21.30316743 36.40348192 22.82375305
-67.37870067 15.1963203 29.55192851
37.34402803 18.32959703 37.30036395
-50.40953821 25.35388007 36.58554125
-49.58297769 24.45283206 35.23465201
-49.63092906 57.82816011 38.18357109
-39.59615889 52.22884353 43.00442544
46.55295772 13.30219418 41.46615978
-49.52277045 25.94722806 29.52841159
-39.3592643 52.20845563 43.19592582
-55.50714301 73.18283494 35.85515535
-49.510011 25.99034739 29.02541764
4.380155225 67.33946834 57.3085104
61.78415454 45.39421897 62.22541816
15.91921988 35.94779963 29.14039773
-15.83033672 25.05699355 33.43755489
-16.04093186 25.65878343 26.49169946
-48.23854117 47.35401868 42.97669519
-51.47525908 79.12637184 30.18238569
60.18043188 66.29184318 53.00201868
-0.444830335 38.71032816 29.3248774
34.76743004 22.5072852 35.90721029
-63.43459914 64.6615196 43.06236313
-55.96030478 46.20596936 46.12339496
-72.02711149 77.5802188 34.57069823
9.169155924 36.8635622 31.45011731
-39.75389175 70.12043196 27.61503697
-57.41159547 41.74911338 45.69883364
-63.63697802 79.06677607 33.19353018
-63.68918423 13.79836884 27.59683338
-49.54750919 32.57616706 39.9791827
-0.3727191275 66.19192124 48.22991128
-37.85765011 66.5403808 28.02677275
26.84795678 24.31693878 33.8896442
-14.2332305 30.25889237 36.83858963
29.03720882 24.17892695 35.07428302
-64.30045412 68.86824187 41.00591136
29.53757232 24.16191278 35.17637364
-62.79742552 13.34187038 26.89949586
-49.78388197 11.54425755 10.32588868
-57.46518798 31.47738964 42.59126035
-20.06417148 44.28803441 26.58272717
-78.68833189 8.393501151 17.45457625
-56.3615462 70.83685322 39.94540011
56.21235625 41.96378547 62.32448292
-51.6581273 33.02806163 40.96240937
-19.37455256 28.78122923 37.49294018
-53.01349485 13.90140977 24.36474048
-54.78803336 23.0692634 34.8088394
48.55711313 45.81580072 54.02722346
-62.86677665 84.69152946 26.01681354
46.56697846 51.90473978 53.64159639
-23.35532739 51.4496842 31.47232701
-58.75145831 31.98422875 42.77944246
-53.79988431 39.90058298 43.16258458
-49.56143759 60.18029663 39.99820612
-66.25675974 29.69111822 41.69764446
0.3302726873 28.98691288 19.69410125
-52.40145093 22.0998344 33.95426123
-73.15296076 29.37217306 40.88869856
-6.369883219 30.45404938 21.42019288
26.42173999 27.27267969 34.61837414
-42.55835488 53.15986447 38.58800363
-12.87690723 59.99741537 23.47198081
-5.726602717 60.43115785 40.37974181
-0.1833782671 58.21627964 53.38125869
-48.35411716 11.7083696 2.321449383
-11.10189091 35.59698288 32.53938218
-12.82484072 69.90970103 19.09918154
-4.839236782 60.23131277 42.5304637
-48.61137828 44.78323428 43.75813464
-38.72007615 65.0923068 45.00154519
-15.98085296 25.47981005 28.55789598
-60.13988142 11.98131977 24.7070277
-51.09282324 51.32489209 44.03735469
-41.94403219 39.88481085 35.25805475
-60.14412853 8.219242519 15.94100655
-62.13932447 37.88623791 44.83039026
14.19635355 32.26736066 37.23433622
-55.94814515 25.24877069 38.86197358
-56.64558142 21.00630824 35.80481059
14.65237224 70.3991659 54.79017971
58.03809894 51.06714997 55.3506981
63.09178298 54.26338437 58.06919531
-44.21143481 19.94789722 22.41378608
58.65383352 67.8252342 52.9723508
-49.63677432 11.07360477 15.76151618
-72.69306089 25.99633642 39.20368184
-11.22492944 37.28958588 30.39379774
-3.424230616 59.91397153 45.94454279
-60.98222442 78.46428661 33.85474935
-57.92798644 85.1802034 25.22871364
-49.76175718 84.94428798 20.58340145
-49.94766167 29.64083489 39.17112675
-49.73618745 19.78051348 31.23714456
-70.71469543 25.93992653 39.48124281
-7.230315176 56.51442155 27.86843134
-29.50569935 54.08992065 31.12865265
-70.20704385 25.93162488 39.48080223
-1.586087544 65.95488785 45.38632318
-66.66671252 25.88018695 39.40276888
60.67674677 8.187527399 57.36096952
4.131982236 49.93665177 55.44800252
0.9503546232 48.31675784 28.24653747
-51.97676409 35.46448555 41.87259291
-51.66687404 15.71415149 26.37086688
-29.47160223 87.05219227 38.85698587
-73.85233319 30.38250717 40.94665913
-61.07932426 25.78724406 39.41616932
-58.0363932 25.73893659 39.39665244
-56.02770945 25.71678863 39.27069404
-55.85949424 25.87971255 37.34727431
-62.66676189 67.78304436 41.63883949
-55.41089164 25.90119762 37.01230874
4.037399607 67.41325686 56.51728487
6.663850111 54.90240824 61.41208568
-53.38644995 25.87110071 36.9756224
-61.99830477 41.33061399 45.60515205
42.80355701 67.90144981 20.1504766
-51.79963866 25.85115069 36.90460237
-11.71281005 81.26286689 -8.02919266
-49.84306943 58.94626337 42.72419236
-62.81447255 10.73361912 22.2213849
-59.62932981 36.37711657 44.39077831
-50.35117274 25.82665416 36.91274481
-49.46697046 57.26801453 38.8282412
-66.25585547 41.42173456 45.35929834
-49.5479811 25.96660429 35.13492789
-53.26049769 71.00700749 37.37873626
-34.79889987 45.31668689 29.10466172
-49.4967611 26.28765678 31.39814762
-47.62806544 85.37275689 9.3758712
30.19183425 22.65972929 35.01012142
-49.81100464 11.14817579 3.275784399
-9.032742468 32.82171322 23.5756511
-55.91523408 32.92073544 43.02018627
-48.67308689 26.46683037 29.16108986
19.46882163 29.8469741 35.19325372
-6.142376273 52.84655422 29.45374967
-9.101532424 32.35419766 23.18939064
-61.35048383 17.37969666 32.18929005
-41.4425179 80.63343667 34.08053755
-41.83870088 49.26801155 31.19046112
-51.4668029 53.34896183 43.91835971
19.44327538 29.78552711 35.91144722
-45.64508257 26.42605257 29.05700978
-15.93935732 25.35976654 29.94353543
-47.85689898 12.19515808 2.402271861
-42.59816873 26.37573603 29.06005616
2.191957116 51.27548752 51.92907564
-20.9842586 25.40871647 36.16402377
-20.18707478 25.3782522 36.36564751
53.51260997 48.8748019 46.7042656
-19.9217076 25.36827942 36.43081166
-15.25673686 25.39375165 35.24547343
-15.16196154 25.76716663 30.89252487
-41.69592148 28.25158187 30.41836846
-15.14856414 25.85951985 29.81786614
-18.94889737 18.81286618 30.77233867
-34.77932835 49.32454242 29.18794451
-15.14151911 25.90552176 29.28249879
-54.73036065 65.23297596 40.5951706
-15.13464725 25.95161102 28.74615044
48.32118975 65.35259114 37.03396349
-49.14076571 74.72392117 34.23091772
-48.85063155 21.43511948 29.34031202
-15.10525498 26.13500719 26.61154815
-60.97998855 41.31249078 45.62134049
-15.08071132 26.3208492 24.44947753
-49.48725174 42.36709055 42.84032413
11.70007599 59.39403978 25.7894272
-15.06390534 26.4610387 22.81885021
-15.05824562 26.50773942 22.27563477
-26.52037072 87.72580281 36.30104603
-11.75500321 81.60146191 5.528120574
38.49212752 49.50248761 53.93541084
-68.64217276 66.31729509 42.31395089
26.55692992 24.79251567 34.2509422
18.7522339 36.45138427 22.75414589
6.891216198 51.84875292 61.85793997
-64.46727592 33.04727729 43.18212179
27.4976987 24.73917699 34.69072633
-49.61367758 79.12221362 24.04900076
-46.6575791 13.0215577 -1.59325537
27.98558497 24.71606791 34.86595053
30.08341362 24.64895162 35.24501695
-11.49375755 37.10308166 32.61014239
32.9372941 24.67123114 34.4421278
55.05945556 51.58524412 55.73096913
-54.32884424 39.41741741 43.04575812
26.67968656 44.51709791 55.79574766
-72.61639605 14.84603741 28.79048686
-28.91099395 49.51776128 31.65243368
43.52514648 51.45027679 53.67078914
7.47967501 48.88550406 61.18542716
-41.24593767 32.5569248 32.79275935
59.95023558 7.54957727 53.25202779
52.54516458 66.57537287 51.16669013
39.39619605 18.23595812 37.99603211
-29.8347995 85.51814397 39.25467937
-66.09636489 66.27197833 42.3545201
-4.883597317 69.33647526 35.89244643
10.77729582 48.35517179 60.88639424
6.587522613 43.17654377 28.57644319
-73.62776854 40.23921452 43.01279901
-39.33890086 49.72334119 31.25457633
26.45455744 34.2738317 23.25732248
-16.08115817 80.61884477 23.58672497
28.01309435 30.86413261 21.75588923
57.19453436 6.342791865 50.30687911
-18.83027514 49.58642863 28.93283892
-42.74390487 41.81399524 36.32177865
24.96449543 25.83212324 34.13938737
-55.54133322 85.62973533 13.72842713
-68.48312237 23.54204252 37.75885134
20.35934444 34.3751636 23.2433816
29.40238477 32.26463435 22.71281973
-54.8651784 75.3386451 34.01307445
-70.45044536 24.04554245 38.11567794
-17.06809757 75.31976465 21.19747947
-53.66140997 17.96768644 29.72376348
57.613614 55.90699209 57.51363964
-4.560547616 32.29354632 23.02748253
-45.2565279 55.29090454 37.67045645
-52.45133531 36.44814324 42.197362
63.95114853 47.89574546 56.07917686
-58.83433517 46.74562601 46.23340244
57.48354466 39.0525856 60.91754691
-44.20046899 19.42776067 22.62317871
-49.60471492 74.73495541 34.19130205
-49.51176304 21.44650911 29.33417465
-35.83140479 39.94148046 27.60783568
-66.2361705 8.306585884 16.08884999
56.24517725 49.21271424 48.08705675
-4.54315697 61.03683436 44.77619915
-7.034864967 84.27031262 -8.874084504
-60.67705997 29.60205372 41.66748798
-4.500233138 37.03750294 26.21107899
-39.58198747 50.2180963 31.38409699
-49.06407483 84.90114273 9.29792719
57.87655198 58.03099807 56.11304755
64.20750615 47.31376789 56.95966756
-49.68742061 31.48698598 35.16995008
-60.10477071 81.77829489 30.17583162
-53.55691909 35.0036397 41.69713203
-70.10572638 26.40751009 39.76369848
53.13437499 49.79747473 47.71861965
-42.55684983 49.20905169 32.01186563
-37.16110344 72.23372543 25.89448713
-69.13985155 26.39106261 39.77043479
-67.11135783 26.35892169 39.75670413
-62.07141666 39.35863051 45.20475467
-64.06603709 26.30940877 39.75072521
-48.27507952 41.36397674 36.77410921
-49.94750922 36.90144197 42.28428574
-20.76302913 57.07873704 29.72485619
-57.46746698 26.20194154 39.73989641
32.71965498 23.05136844 35.80825885
-1.649845075 59.51445607 50.24402455
-60.27480973 63.04761593 43.71522132
-15.67682985 72.37276971 20.18317877
-62.08529375 58.95205566 44.99161725
-53.329262 26.3444688 37.29615508
-14.43803114 33.61754688 38.67442012
-62.66934259 12.01839059 24.75906599
-52.81887442 26.334787 37.31121448
-49.65681192 26.32549619 36.81604462
-1.761253008 67.59945224 43.80834695
-49.59926403 58.64317405 40.36953796
36.11748343 42.42377908 49.16347029
40.31982108 17.68905142 38.34214252
-49.94788257 26.29887913 37.18054381
-56.36964136 42.7234983 45.8420373
11.1032465 55.68403569 28.18519539
15.19558709 32.25886354 37.14241717
36.85942021 22.13912089 33.95553403
-49.52826036 26.35976095 36.39375867
-66.22213074 40.92728426 45.26616591
-49.61830303 58.80144522 38.53584122
23.46236419 70.5322902 45.73801497
-48.0908963 26.92314423 29.57947933
-61.52144645 81.24737235 30.78269574
-47.1394547 26.91397745 29.50444831
-46.87971536 26.90684359 29.53772957
-52.72450926 10.94629031 17.82832531
-63.59218954 39.38243484 45.21843716
-46.06697411 26.89330938 29.53984996
-44.05274911 26.86836618 29.44528393
7.225695538 60.14630987 23.73645872
-63.08459015 25.82005683 39.4176709
-19.4190153 25.83991761 36.68646591
4.221493394 35.00326396 36.50944404
-1.413877031 36.89295655 27.30049258
3.941803811 64.6035092 60.0199131
-55.64423268 43.88233952 43.9043043
19.61022217 31.75973213 36.26816032
48.55923576 65.28206672 37.80726576
-36.12594373 62.23711874 25.21216412
-15.09769046 26.18074705 26.07912384
1.76205203 67.36503917 51.68431172
-15.37076555 26.31942623 36.17459521
-51.8343459 38.39355122 42.80254644
10.70975907 52.62145213 28.85309564
2.907318438 56.57915661 25.18363152
-77.67937651 6.619014372 14.55513594
0.2877486721 60.03704378 55.46121695
25.50468268 25.32997109 34.03907807
-72.80440375 69.04485761 40.5773811
27.09760578 25.24499339 34.72178137
-3.323775435 59.46019323 45.36653414
52.68586252 62.80212405 42.50271485
29.04055188 25.18167262 35.08632366
-18.24470185 53.10388798 28.77441765
-55.70622007 75.81911552 34.42245577
46.92286929 42.61099189 56.58279843
-44.79104297 40.81394231 36.66824265
31.97994308 25.19798382 34.33641172
-5.048827901 32.2920763 23.13766563
14.18833705 33.27583656 37.18202249
-37.91183456 67.64167823 44.38564889
41.429942 43.48061295 53.36177716
-46.5067935 37.42058916 35.6014801
-16.43029442 48.39870028 18.95660053
-79.37192753 5.279037188 18.78006756
36.61307937 35.10946121 23.27271607
-68.1026704 37.16439716 42.69400818
24.05726996 49.48601235 45.2261985
-5.650712655 69.02300071 33.85114004
-71.32083933 67.93627928 41.51036112
8.685137679 70.76471128 57.51129086
-50.39159362 36.90245559 42.35720846
-80.24664236 12.29864311 13.20450313
-45.32683552 52.31307442 37.29284435
61.04674921 52.82199854 57.71193142
-53.30616276 58.48130113 42.95560517
1.725082098 60.26021149 58.42305655
-17.16497763 59.42827032 25.07015201
50.50289242 45.40416082 58.43476882
11.73842136 52.09589392 28.93134597
47.60942029 41.58234758 56.7398412
58.40210091 8.287622865 50.80613496
-13.64816398 31.82302894 30.22265579
-68.36352371 50.60803635 43.99948367
-75.42374062 6.639079707 8.065397659
-1.303653352 30.37622152 21.35751499
22.30956446 43.97695416 57.07289826
-79.07498175 7.311554686 18.43505526
17.85565023 30.39482619 34.96766918
11.21581877 45.08071312 28.89537542
-51.97480202 62.0685562 41.84471794
-50.01358158 63.07478272 41.44297134
-7.566087985 52.41829233 28.87019567
-53.3166098 84.67428 18.56914165
55.13144019 41.00905455 61.96055162
18.2158556 33.82774748 35.83360654
-49.11110423 86.93373038 9.017698644
-13.21965118 43.50472812 28.54398661
45.04533976 51.92898086 53.65037382
-20.39375666 20.55946215 34.07868542
22.18711022 26.94913341 33.35526379
26.8540272 36.83118151 22.62676263
-68.57882162 26.85805076 40.06894462
-63.00779691 65.69843371 42.5969953
-70.50430857 23.57049723 37.8139807
-54.06544567 70.48961658 37.7118551
-67.81699488 40.63491435 43.13771812
-0.3284286528 51.90264884 45.12918092
-69.99346522 44.6603175 43.43609368
-61.47907627 26.74554881 40.02098541
-71.5935271 70.58491426 39.94828073
-56.91568402 26.67359424 39.98602022
-55.69332126 59.02313826 42.94745066
-49.51359857 22.62531205 27.30340128
-49.69206556 57.20811671 39.56650585
-49.91447379 25.82936606 36.79798194
-37.84012964 65.57666642 27.5576495
-57.8330368 23.37305374 37.68956125
-47.64273371 35.97900971 35.07309424
-60.35760161 46.7706882 46.23295836
-12.56236833 83.2875762 21.06829252
-19.74891325 57.18662193 28.2790515
-61.37276981 27.69635553 40.61633561
-49.52460765 27.35569148 30.65821353
-80.02270392 10.84008739 12.61385318
-49.03436182 27.40572571 29.98388676
32.39747554 37.26261973 22.3877849
-12.23496321 31.93355193 22.84347635
-24.90271338 44.95810495 31.38008079
-4.131041043 60.07229275 44.24142729
-40.98411684 27.40842142 28.41736276
-30.47940652 76.23554518 -10.18252086
-25.92148295 22.8541739 31.80073051
-18.35445509 26.30068307 36.96118709
-17.35581032 26.29248294 36.8659326
-13.28231952 52.07401301 28.13031992
-49.82753325 87.1283404 12.72179228
-15.90149785 26.30220395 36.47573782
50.24309701 40.75510054 60.01423028
-67.97044439 55.58057846 44.46603227
-67.60174205 12.53092494 25.57647234
26.92435025 25.74788409 34.74354091
-71.72002253 71.65315577 39.2247588
-37.78235123 66.99795406 22.70056765
13.27310748 51.56285516 28.99993422
-22.82002447 48.41413794 31.64923265
-60.62318621 72.51101825 38.80311342
-44.6472347 48.05736922 34.12680373
15.19092081 32.76343342 37.11253246
-23.09581153 19.67916423 33.15984223
-36.32452824 77.17597189 44.10807784
-51.11491701 53.34490119 43.89839183
-56.83346334 53.25622703 46.01834762
-18.35538116 21.06171926 33.68602867
33.17744444 23.5704353 35.52188951
54.54958857 48.06583327 44.2443237
-37.81600896 64.20651802 25.97880925
-16.13578737 24.6593036 32.28584025
-36.65506081 71.33330369 24.5974524
-20.75814624 52.48199612 30.64648047
8.136496527 53.18283418 28.65354058
-9.64722897 77.34961546 -3.781718339
61.83629742 5.866294985 60.77983316
50.28701043 11.57205363 43.35886532
39.1293526 33.57239485 23.15631306
-44.10612705 30.16302582 31.99513648
-62.5376988 57.42584062 45.31538894
51.5937883 41.80913543 59.17392067
-32.77661914 84.39951432 41.14829002
-48.61464188 61.599129 35.00008579
-41.49072218 62.02620425 34.5103512
-41.59530956 29.67031039 31.40946783
7.917793568 59.11486741 23.92492656
13.53988452 57.20043674 27.59694644
-71.57597812 26.90804552 40.06014469
32.51806309 33.18406224 23.09853299
36.15992008 71.53090175 49.20382643
-62.79101018 34.47646194 43.75135218
-51.33677356 74.18967991 35.02493131
54.58807415 58.20316936 54.74148983
-54.87604338 17.08040801 28.60229495
-50.10696634 20.67464729 32.58137843
-8.426741557 69.12823572 27.33225868
-68.02956742 8.722350397 17.43100476
-17.26168627 58.54695562 23.66625712
-36.39084296 38.01659699 26.75341231
3.391354303 38.3114269 33.22403157
55.25508925 52.99320657 56.82892778
-40.24497826 57.08273768 45.04702266
26.82743983 74.55647875 50.8203261
-70.33748981 21.22304545 35.89989603
41.15720889 48.56601735 52.64511203
52.69216067 64.66580292 49.99977497
-51.81951827 31.10060973 40.06189222
-55.73846501 75.71149191 35.67797864
-55.70356791 75.76822195 35.01275977
-55.67733137 65.69254775 41.26735979
23.73218637 71.60549469 50.70794075
-72.96492476 27.4419009 39.95428317
35.20055 30.30428138 21.05771889
-16.26543035 27.74465591 37.28002974
-63.50497293 41.84951935 45.69529149
55.33374867 47.91804942 45.81036261
-67.54110551 27.31996855 40.33540809
-70.20358426 10.84342216 22.35585901
55.32897092 65.0024633 51.41536117
-65.99331538 27.29492454 40.33096433
-55.85528916 49.21584705 46.12235634
-64.49774153 27.2714427 40.31834374
-61.29382317 80.12811782 32.07912851
-60.41323465 27.20566787 40.30296607
-65.18936809 60.03563834 44.65784602
58.59919577 41.3973314 62.61846981
-56.87270509 22.88196426 37.38070436
-55.8983851 84.59573202 25.79998606
-58.33061985 27.17100392 40.30820336
-14.81245119 44.0670819 28.14617084
-20.9182195 49.14634286 28.61321835
-55.74341216 27.32780409 37.99455618
-35.50482893 45.35067424 28.84473663
13.66924209 45.36973107 60.0322119
-22.33412615 21.55158166 34.58476006
-53.22021954 27.29389538 37.90700527
-52.20602462 27.27785112 37.89984563
-51.19037513 27.26107312 37.9009265
-17.64198744 45.61350379 28.21370913
10.63464245 54.66820813 28.41374699
-68.38155104 28.76740352 41.17272739
-50.88628982 27.25634719 37.89779759
62.58525597 53.26668669 58.08290535
47.7428186 67.87140892 48.69047535
-45.11974794 48.26521636 37.63071505
28.34775288 40.71005878 41.40611484
-59.1954748 15.52265069 30.02969781
-50.63066312 27.25128791 37.90777982
-49.50333933 59.92132987 37.16676024
-49.35705037 27.87249619 30.45345403
-60.71780616 15.54724118 30.03455119
-18.35489833 49.64396341 28.17427594
-21.81289267 50.41052071 31.58826197
-47.88778015 27.86228298 30.29181776
-68.56248291 39.65964695 42.94822104
-47.45622782 27.85520212 30.29171795
-46.43770381 27.83697408 30.30908361
-72.0226516 44.69280783 43.44590029
-45.12063573 27.81681536 30.29192799
51.01408832 52.29454741 54.09497539
18.80932892 33.40928706 23.0982968
-64.00163652 70.97414871 39.8085849
-4.347301101 39.01877758 26.48839555
-44.73473716 42.29218744 36.97687928
60.21490268 68.78553483 53.18005685
-46.45272802 73.97409364 30.76955569
-61.71010092 73.06596137 38.39485075
7.401094794 37.74398448 33.21999491
-20.56341436 26.8765185 36.52437178
-30.51522047 45.08849666 30.93673734
-55.03763388 10.70651715 15.22627259
-19.90153977 26.83997617 36.8223584
-71.66308878 37.22596782 42.65824418
-12.29053111 36.76167116 36.72543482
-19.57802544 26.82544945 36.92929901
-66.29658745 50.91005235 45.92592567
23.79081803 29.90917839 33.64691006
-36.61040997 55.45713789 28.26518945
35.7099604 46.57940392 53.43943013
54.4290359 7.627250856 47.57662022
20.74229249 30.92757321 34.05929374
-63.97045732 14.69690798 28.87285768
-17.76262064 26.77691002 37.14657165
-16.32380498 26.7696704 36.95622358
-14.91742434 26.80357019 36.29448476
-14.18898944 26.8573414 35.53135234
-41.40823254 44.10303905 32.8007298
-61.65492201 67.76818808 41.61833809
52.55514503 43.30161513 59.14471325
-55.70607965 77.47435989 32.68706675
12.10245279 57.23175609 27.50749535
-14.7020708 48.62379822 21.84055813
23.86549235 26.36838636 33.95028099
-6.518205059 78.99991693 -6.056423616
8.515616392 37.24944888 32.92173812
61.18877205 39.42018338 61.77023551
-46.4840144 44.62022672 39.41810279
-39.37077409 79.20337878 38.63331824
-14.57236012 27.80738273 36.22897213
-64.91959741 49.35301846 46.25858848
-39.32052828 45.82689333 35.69739756
30.79115781 38.38356413 21.3346602
4.840827353 53.3985867 61.73728238
-0.4153132643 29.8966697 20.92844975
-72.17225465 22.66410968 37.00079422
-57.86507933 43.73895471 45.99234892
-48.69762397 42.17272287 39.11945777
-49.48582749 52.15625511 39.90646248
-66.44863549 40.94243109 45.13352623
4.368420643 57.53367001 19.65094243
58.00826187 8.387179948 49.72551098
11.61236597 35.34523298 36.95677527
-52.27557697 31.10766368 40.06697798
35.77992588 22.52926481 35.45896585
12.09547755 47.88179098 60.30371806
-74.83641994 35.43030174 40.80225597
-58.34862856 64.57373372 43.11152057
-61.58299307 25.79353663 39.43917317
32.63912562 43.75238029 51.88336473
-20.87665302 47.26517806 27.13666147
-29.94963766 51.5550026 31.5072887
-74.68280025 29.07177991 38.84098975
-76.34772779 9.654352436 14.02460834
-51.35400697 59.99431625 42.49905617
-52.11776028 45.31976282 44.0250489
-60.67327771 79.56065186 32.72170495
-59.12022994 37.34773854 44.67928356
35.04879505 70.08919344 48.67220409
12.61262046 42.10614877 28.20006531
-17.37300715 25.17763744 20.67794526
-61.5723612 22.01822383 36.65060432
-62.93057162 36.43183726 44.38510728
-71.35779886 56.15617606 44.25670493
-54.85529947 22.60097915 34.43121185
-69.64024936 36.69446314 42.61592993
-69.40209374 20.74453452 35.44977346
-11.06931497 50.49170493 28.59695108
-41.50183617 58.07695599 45.39838317
-13.04574395 48.71171885 26.33068144
-58.06491742 81.74042374 30.22645533
-55.72549414 75.17795499 36.04255012
-15.79211 42.55905666 28.35933258
17.85709717 48.97608921 34.84824418
-44.98660191 50.3184165 37.07684104
-45.41872 32.09067979 33.17440547
16.9328892 50.39717381 30.18079014
-55.62876521 66.2941543 40.10085084
-49.47871782 38.99594064 41.18697859
-55.96943617 89.29832653 0.3555871664
14.74634116 36.17443869 32.55972259
-71.83636684 26.91550695 40.02318464
-6.595615644 52.39952567 28.90297784
-5.552058025 32.29814759 23.1631547
-71.01894642 27.85448872 40.62019397
43.49478281 14.98872861 39.950772
25.82677252 31.36660415 22.16641847
-68.98813229 27.82117729 40.61960887
-70.99222609 80.31259608 31.78711374
-71.17167003 26.42488067 39.76533002
-65.43796524 27.76477781 40.59729698
-45.72438439 23.22309987 25.4678588
-64.93024439 27.75644122 40.59724881
-37.0147538 53.40446279 28.86468738
-49.57485627 25.62661232 33.26028681
-62.08991736 25.80147141 39.443734
-70.72419968 66.87865637 42.02095534
-55.74752037 30.67194363 39.96061383
-62.49036243 41.83326545 45.69048629
-55.69343421 60.05240514 42.65227286
7.493044705 67.60941989 59.40771032
-9.909177375 58.68527616 26.48500515
-55.92302602 76.20769663 35.77950991
-52.44852361 27.75372641 38.2484249
-51.64620685 27.74545073 38.19148839
-50.63457476 27.73069927 38.16980978
-55.45020623 18.48024161 29.94142412
38.12975642 34.58550696 23.2392791
3.572532248 65.79421691 57.92101276
-49.62199303 27.76502448 37.57823222
-49.47793252 59.33196405 38.17706287
34.16191878 40.98015815 42.98844902
-68.85787354 62.16059663 43.99594874
-71.30880929 35.26139137 42.09035456
-49.52735447 28.26239772 31.7863116
-49.48323941 29.74679236 32.02588501
21.64719873 33.36530945 23.0676222
-49.46987573 62.11153639 35.04142595
-46.37293126 28.30651619 30.6725846
14.28823748 51.54633921 28.99807204
-13.05047056 59.43408465 24.21799373
-45.86375583 28.29744585 30.68077692
-56.47154759 66.62683567 42.2262075
-43.86325485 28.26703668 30.65228118
-43.33619947 28.26133589 30.6179475
-20.37334725 27.35719047 36.7347747
40.08836157 68.62688108 12.24694371
-20.0564677 45.23902465 27.19475113
-17.98238798 73.598661 18.0451033
-53.80722753 78.05938722 31.36016071
-18.6409359 27.27618276 37.3447902
50.99141074 45.38073291 58.6135737
-22.53604926 57.05634021 30.32298069
-32.42243373 50.08161901 31.60305514
-66.20595267 60.05288804 44.65146769
-14.36771633 27.32582529 35.95357461
-53.19101531 42.85451928 43.7148667
-47.05502917 18.86071122 29.75028543
-72.00997279 20.3189465 35.06100705
21.57650211 68.92961917 41.39607086
26.44410365 26.75918253 34.74851825
-55.64384109 57.49153037 43.23811408
-14.23621565 71.38496865 19.72227884
26.94652567 26.75351888 34.71845111
-43.26607037 41.29023549 36.67490326
41.77435764 30.20930535 20.90660542
-72.83018472 13.06890632 26.15492833
-52.92645751 36.94225176 42.37863919
29.50040751 26.77846284 33.94184274
-46.75667125 87.43761125 8.545918146
-14.58564503 32.16058343 38.13614328
36.61522363 69.25241031 17.30097477
-66.01576586 50.39006771 46.08209155
-67.71552154 30.68807368 42.05573435
-52.8805507 37.92352654 42.6318125
-47.76773304 55.39309983 36.96300065
41.16270408 34.0400195 23.16665333
-64.7931443 10.33752319 21.37025908
-8.330085071 68.1246019 27.31145778
-49.47959913 31.05338129 34.33727596
-31.36610804 84.93354656 40.50650188
-42.10472134 42.08050347 38.93270173
-49.70373461 63.46359604 36.87025208
57.80744857 58.61762839 55.14283387
65.64372946 47.06005742 59.63102787
-55.93417197 27.15147473 40.07789544
-55.6897286 58.51910995 42.9712531
48.07640919 40.63968151 55.94065743
52.10724137 64.23564339 49.27828731
7.903586084 37.73828589 33.19032018
-78.84099456 8.898392896 17.44917897
-50.96033461 79.11440145 30.2231476
-73.83028346 17.55488734 32.53552141
49.55191737 44.4086602 58.51934145
-11.50864467 82.82370957 20.42560252
-5.594972466 43.37520687 28.59349178
-7.515709937 32.79979567 23.54077876
-69.15939161 32.70672705 42.20365111
-55.76958639 31.63795097 40.40398552
-70.19670973 34.21630581 42.35710457
57.47497776 47.72992876 47.58585961
-49.76747806 79.68389397 29.21121858
-49.63329414 79.7896511 27.95792279
10.57423563 34.85874629 36.97560464
-58.98476065 40.29269845 45.42605036
-38.16275752 48.81939432 29.87067175
-54.93515785 25.89660768 36.97486681
-55.19478079 27.80104658 38.22282558
3.398963582 60.62143532 24.77727761
-51.31571093 31.09424727 40.03967318
41.94386807 49.49573849 53.3554919
-14.00196064 62.95878805 24.26860535
-26.93110321 44.99019346 31.39440006
-79.35205416 4.326524834 18.18045427
-76.01600279 28.82086218 36.18143429
-75.47006186 28.74369011 36.97318992
-75.12701613 28.6945331 37.47841955
-74.23563403 28.56699567 38.78897549
-71.09930497 33.74817217 42.13703091
-72.91294718 28.39533548 40.52948821
-13.07077776 38.90968606 29.4184294
-44.28704012 33.0321923 33.68210489
-15.6315054 49.5271844 23.18392284
-55.91045826 7.587467521 4.987801041
43.6309054 17.1519276 38.11938325
-57.93927399 41.2653014 45.58926666
-70.15926853 28.31949377 40.88477114
-69.90693195 28.31609416 40.87611415
-34.87529615 46.31010063 29.240241
-67.51989395 11.23076789 23.17411235
-12.44974499 81.10281206 5.622662537
15.18906696 31.75993653 37.10892852
-20.85906496 47.70896623 27.80813129
10.2898097 48.85551718 60.99762719
47.50943163 64.66519057 39.34197764
-10.48976324 49.94491788 29.00728936
-63.8719181 28.22035132 40.83665356
-49.7603385 10.17440032 8.743778392
54.5725659 41.0308061 61.8146244
50.26987758 42.23585047 60.29941987
-60.31309283 28.15958429 40.86339226
-4.130240299 31.82204425 22.59231952
1.641989664 59.2729168 58.24685822
-61.5413548 83.51405758 27.77971913
-56.26036522 28.09745129 40.81179805
-46.15675934 12.51448628 -1.62895042
13.55570287 69.88026405 55.19647169
-17.06743603 47.6546528 27.71555488
-47.96139586 59.50851947 35.83826562
-55.88451767 28.10608127 40.63993857
-54.63305821 28.26768533 38.52525947
-19.71523535 19.24326408 31.74875208
-49.49510215 28.74834436 31.96557859
-42.29323071 28.72060403 30.91416832
-53.76099296 11.73023145 20.57871035
-63.0533711 81.27680209 30.73320107
-18.72187823 27.7696737 37.45806436
-19.80730185 28.80130281 37.34243921
-15.79902647 27.75716128 37.04591217
-15.37686466 27.7672063 36.84879337
-20.74967409 51.02063006 30.1295458
-15.15115207 27.77723693 36.68930583
-14.87737048 27.78779744 36.51449988
-1.581332215 29.00964601 19.79475299
-61.54791685 14.21155631 28.21854015
-69.16681699 34.69455896 42.4354272
-1.077247815 29.00314379 19.77410357
18.42044025 27.39396113 34.73634659
-52.65840978 43.83806189 43.84889702
22.56975332 27.38221789 34.08137167
2.075646243 35.91893087 32.11558035
22.84278302 27.36837983 34.18994558
23.32124916 27.35002266 34.31180273
-6.779219722 68.19138805 32.06703769
25.91751706 27.27925179 34.63823876
-53.1739264 74.22441209 34.97209087
56.10551267 51.55312727 55.90431565
37.00966646 19.85624361 37.12162363
-40.17442025 40.00803833 33.49006433
-30.44429476 50.05010123 31.59169353
-48.87809713 12.72934 2.222487675
-67.44910874 20.24826726 35.01171909
-1.296495555 58.48044722 50.52688636
-56.81145553 27.62544918 40.56959828
-25.54256387 22.28470488 32.51263952
-67.5268337 8.716529517 17.40270344
0.9271415584 57.95342233 56.22091584
34.28022959 46.07984474 53.68469506
-63.92121536 46.33165471 46.18254113
55.17487715 49.25706024 47.77636859
-4.504630336 50.3517752 28.96943281
27.87312685 32.29271888 22.67843265
13.20206761 43.56323417 28.65266628
30.56943493 46.01904851 55.09813316
-70.97865136 17.52874533 32.29517363
53.94097855 66.01327547 51.59909603
-50.87408807 75.80982048 33.60884268
-6.020221143 70.11471651 32.90145532
-69.74947937 59.60372182 44.71484782
51.92635696 10.98621988 44.02037704
47.60945839 12.21082141 42.28084871
-51.93308172 25.37908201 36.58352755
-5.163575206 41.89872294 28.17140203
-55.80990518 89.55328256 9.018731127
9.912065651 35.96181431 35.94994743
-70.54260579 43.67272692 43.35220854
-16.80932618 26.76680859 37.08203745
33.49850152 40.99270081 42.9693623
-52.91122959 36.45713201 42.18071835
-72.92804243 13.52137435 26.74765216
-37.50701824 68.86792895 24.2465905
34.42922796 31.70445872 22.43045717
45.75746914 14.38579105 40.69196255
-67.66362606 22.11756412 36.65903052
-11.79032867 79.70472779 21.72689298
-51.2632157 10.19583627 14.60818369
34.84069883 20.44907241 36.47991026
-55.80163693 35.85195069 43.9306822
-17.09257821 35.77613594 37.42874432
-37.34456567 62.31338756 30.38582015
-72.97409069 36.79040621 42.13793618
-69.6176223 22.14929225 36.66334605
-44.76942731 33.51757666 33.96604409
-28.40639006 49.51099701 31.6347273
-49.69814944 66.67229764 34.58008203
-54.29795939 57.98383075 43.09310511
26.3844113 69.2365363 42.74289681
-15.87327135 34.6521928 38.59048594
-56.09676803 88.95465227 16.02277603
-24.40913189 44.4546848 31.30337945
59.0010077 70.40896403 52.04552541
-7.706597491 68.96318847 29.11091209
-8.036226212 37.58896851 26.31024025
-49.49820959 53.86737206 37.52485179
-1.314458606 66.41919456 45.77115171
-37.32625339 40.33053428 29.20313278
-76.10197141 29.24255502 37.12915183
-72.28476051 62.22914216 43.85375013
-71.93705155 28.82649341 41.16482412
4.31357673 49.8946038 55.90149497
33.63767723 74.32319002 46.40311716
-71.39824406 62.20317057 43.9861833
8.428634417 42.23449389 27.50805265
-59.82687658 33.94090925 43.57651963
-66.13231736 9.513067653 19.54324693
44.15643476 68.44829365 48.5041622
-57.96555158 19.16773392 34.09348552
29.76751905 37.85260052 21.86704661
58.28454745 41.40738574 62.561757
-65.31868225 28.72142758 41.12234221
-66.1617886 18.83557796 33.685825
-59.25133789 28.62176484 41.12222105
-55.81018495 28.59557869 40.7699605
-55.70521291 60.98844528 43.44156391
-54.59294424 28.74966623 38.74906439
28.26872105 30.85907333 21.76587146
-49.86946643 28.99340364 35.01878646
-62.18453508 18.30930114 33.21010328
58.9490956 53.36646471 57.61804682
-49.543424 28.62405275 33.41766178
-49.47900425 60.98781987 36.43480677
-49.49918677 28.75706375 37.69177831
-47.77274799 29.27373735 31.36461088
-43.72195981 29.21258267 31.30202934
-51.34379648 80.81448335 28.0404069
49.27335381 65.47360814 41.27416802
-17.88367916 80.80955221 21.7166051
-55.31495206 61.08891602 42.20080098
14.30802915 50.53192343 29.11709457
-18.74504573 28.25879849 37.61100581
-53.12946571 64.16432274 41.04229511
-57.48696509 75.67672616 36.4150144
-67.85723016 76.95677265 35.18625393
-56.30803795 51.73231147 46.12894721
-16.74027549 28.23862467 37.46287655
-15.36768646 28.26479247 36.89734055
-52.50933104 16.62321832 27.63169925
47.00010022 12.24565016 41.99273932
-52.35319708 17.046674 28.51278201
-14.69353612 29.23794978 25.47165391
17.98609377 34.86042934 23.88929686
-50.41345124 84.95091698 20.63072923
-14.15101674 29.65806077 20.49123652
-70.77247741 12.14148609 24.87539998
-66.96091998 27.78968277 40.59861872
-69.34775389 79.72417674 32.47766341
-7.108588721 29.57447201 20.11856243
-73.01115612 46.20254087 43.58825369
-6.62549257 29.5661682 20.12282985
25.50568241 24.90158433 33.1852726
-44.95747386 30.17125641 32.06194679
-56.38138527 87.7088071 7.233169311
41.15237434 33.54301422 23.11158243
48.67314489 10.6136078 43.13969606
-50.7320849 23.00550567 34.77549201
-2.293836894 29.47483283 20.35704479
-70.82570816 72.70201621 38.53155187
-1.486567637 29.46405891 20.32816521
-0.9671755261 29.44983486 20.39423722
-0.4597317414 29.44163803 20.39261943
49.02093518 65.5513827 40.41944178
-55.15550237 21.67364814 33.60031557
-74.64611939 40.2573552 42.99641436
-36.7990362 77.16755455 44.29628406
-69.76299414 13.88890414 27.70414555
-55.84287812 9.122898998 16.28371022
-20.9962346 46.40206034 31.35252655
-64.8050726 24.42556583 38.45412371
-54.58109514 63.14881624 41.45458845
60.37025714 7.790361556 56.20336881
-51.53957606 12.60520425 21.65103194
17.99011695 44.41568014 58.6302327
19.50972581 27.99092124 33.42530538
-44.74821495 23.20145936 25.53291626
-52.31885605 11.75848324 19.97571864
15.18491801 55.10508618 28.30102372
20.72417517 27.94819403 33.68971131
-48.1266172 71.64504846 34.81946965
24.88563392 27.80643474 34.5417416
-74.81282967 34.61219263 38.64168267
-6.801915141 68.75460828 31.35973515
25.89151696 27.79458333 34.48749341
28.32134743 73.52940539 50.80516433
28.19588102 42.17608689 47.72291804
-3.0142651 66.83064501 41.31889718
26.38251015 27.79468485 34.3926795
-24.36257668 75.43618789 3.757128944
62.11481013 6.829345265 61.20021047
-8.528173209 32.81496642 23.55774851
-49.98390633 24.88473648 36.12389092
-49.74014696 56.25271674 39.01337358
54.21572749 65.04369382 51.14902937
54.24745251 38.26823291 58.98674539
-41.36679112 46.97820012 40.2023461
-51.25961876 78.01316201 31.41093245
-69.299742 76.43888289 35.6467511
-65.68418042 16.07691382 30.65948304
36.36514769 18.8795989 36.92885996
0.8503548568 45.25479424 28.8512709
-74.41786035 11.5635964 20.62587919
-49.91269091 10.04637132 4.432445367
-37.81006526 70.1062228 27.4092879
-58.0153432 31.00491584 42.35435805
-41.78068912 54.24944832 43.27093703
-40.83625074 47.80079947 30.55183369
49.9967086 45.48605563 57.58060622
64.12443115 47.85789034 56.48558076
-37.92154693 50.55791482 32.94922075
-18.36407802 47.63972991 28.13606809
-80.28429365 12.77029627 13.56303021
20.28713301 32.40272589 22.84808549
55.24136911 70.01925777 51.45986475
-19.58396284 58.28129123 27.1931559
-1.752568732 52.72681363 41.65990701
-37.66129322 78.8861596 41.98982228
-8.158474967 54.9804181 28.37334597
-47.89933972 32.60532902 33.49969168
28.27654475 67.7350962 36.50530347
-11.81102995 32.72506367 36.88074958
-65.14391997 72.05923811 39.08319729
-2.560476134 51.98125595 38.81567524
57.0687638 66.87704043 52.62867652
-6.050681973 44.86395489 28.8778475
6.881025821 55.83166792 27.27657534
-54.74411613 32.59587316 40.74144413
-11.66578759 70.37235611 19.33394628
-49.49249791 67.87032491 32.28656963
-46.53474122 74.41856905 31.45204209
-35.93828822 79.73999532 43.40256869
-64.42582245 51.86279687 46.1622876
47.59707899 52.3828881 53.72109624
-47.332848 12.19097283 2.350917635
33.31709221 49.08342964 53.96041099
-70.1428467 38.19447437 42.77847583
54.38203693 69.0023066 51.77597422
-37.52471012 43.22093942 30.6468929
19.63528401 74.45542885 53.36497472
-22.41068313 43.43326774 31.12633614
6.630370211 42.67749152 28.53499372
-12.05939641 80.55901433 11.86102266
-71.83011183 60.6665957 44.42630297
-32.19809044 45.67150756 30.31629559
-72.33121852 30.28419686 41.7978314
-71.8932665 29.30662247 41.40942649
-67.73539444 21.65155099 36.25589666
-71.38121655 29.2961021 41.43390361
-71.37241712 61.68792638 44.13594763
-69.88191101 29.27073784 41.44242382
1.654755872 66.81783629 52.2304633
19.30248905 32.90607946 23.01920833
-68.82262648 61.64060055 44.19908011
-61.47231339 11.14107482 23.06202506
-14.28173557 75.25221397 21.4502811
-67.82633943 29.23732667 41.43827524
-67.3195883 29.22947925 41.4327334
27.48484705 29.97750294 20.49599136
23.24692766 45.99614213 56.76049342
-0.00800440834 67.76237085 47.40935526
-66.84196702 29.22393718 41.40598449
-60.39331919 44.27762548 46.0478569
33.65227772 74.26626923 47.06111097
-65.79895395 29.2057448 41.4182661
-66.49187188 55.47895031 45.36383357
-63.68586784 60.52276305 44.54286117
-62.25276967 29.1513544 41.37339094
-32.82735724 46.69603323 30.19614059
-56.30852358 23.34736262 37.69706861
-58.98579535 12.39692473 25.48893193
15.230996 54.07741051 28.56896382
-11.2417195 70.71021058 21.15765394
-49.81191871 39.84634073 43.03173925
-57.13188651 61.44222026 44.27252898
-57.17164769 29.06601435 41.39508005
53.67420504 58.2677166 54.16645861
-55.89007924 29.23720807 39.16333527
-73.01427465 12.63477492 25.40310848
-55.74618436 29.09028805 40.84144873
-56.29169521 47.70633933 46.24912625
43.89129207 52.46823289 53.43706743
-52.95759411 61.56654356 42.03323048
-53.01366735 29.19856612 39.06337026
-27.95012562 75.70300821 -4.482791172
48.13666868 69.9293775 48.03152811
-68.37251903 81.38310073 30.51360231
8.857038631 35.99845595 35.72578451
-56.17179492 88.87341067 -0.4997294113
-49.91463317 29.15912473 38.93023028
-46.82706025 19.19171576 25.86426746
-48.34593466 12.20645803 2.364355744
-31.44399791 84.42106565 40.64396743
-40.43870331 63.69595096 32.40600887
-49.73091245 60.91666982 37.30880902
-49.5129233 29.51398758 34.7341141
-44.54478104 49.95260687 35.41252716
-49.57281023 28.53976494 34.40174044
-49.70831353 61.90970206 37.42993816
-17.64707842 73.52862858 18.79414532
-48.65017704 17.91760129 29.34951627
-63.71799287 29.17191505 41.41413472
-19.83342775 45.20084775 27.59540148
-69.38877385 76.98601022 35.13891749
56.6506783 48.25150943 47.51481025
-67.22763896 21.64319693 36.25604469
-24.62403763 21.21337635 33.12095284
-49.50081273 30.05965025 34.22399491
-49.56778552 60.44366625 36.94202939
23.14520967 71.62903332 50.54662712
-49.5352415 29.12214119 33.46056814
-49.49065004 60.49147846 36.37228017
-49.49732678 62.05105026 35.74882833
-19.08635846 74.81309089 15.81093002
-49.49300036 29.65260477 33.12114499
-40.77363267 41.44528581 34.39964289
-49.53553697 27.76430928 31.74340524
60.58300676 54.79223688 58.23496901
-48.64819194 62.11826905 34.80656833
-36.33715292 38.49172957 27.05412855
-43.07846252 29.6751377 31.63627511
-38.85485641 45.05833659 32.87727634
-49.60363439 67.80915635 38.84449517
-49.47722371 34.41902808 36.05242523
-42.14250977 29.66139521 31.61731622
-36.08714814 54.93874576 28.35663352
-49.62236731 66.53495862 36.15996179
-37.13432778 73.64773216 44.434407
-20.2547701 28.82880124 37.10855224
-49.42019506 72.15557379 34.96625307
-17.72014011 28.73956735 37.66107531
-65.15849264 30.63709657 42.15987312
-37.48756004 54.17661838 31.64439092
-13.51828638 28.88715436 35.14645847
22.34261486 32.85918708 22.98379906
-40.98884038 46.38444172 41.19640871
-42.45382346 37.35833386 35.5512593
22.8418508 28.85072031 34.46195605
-75.87989879 6.366968493 5.484614015
-55.71862376 51.78187683 45.44115132
0.1212194595 60.07149221 55.09307251
-5.363106621 29.97669798 20.94299697
40.62169151 29.79298411 20.13274549
-41.48564747 44.9301806 40.69335447
-40.96928749 28.30626152 29.64503352
-3.443777843 29.96245754 20.7422829
18.64556193 28.30961047 35.71717071
-52.70986472 27.76166827 38.20606929
19.05623947 28.50508807 33.36960499
21.69683551 28.38098397 34.30671865
-71.48633758 17.53706445 32.295418
43.49196825 67.25019387 21.75280605
-44.98959976 27.34761086 29.88716601
-51.71117886 20.23832596 32.12580687
22.32151444 28.36320465 34.39398396
25.84033879 28.32261572 34.19410896
-9.179519529 37.59580171 26.44894838
26.30999815 28.33347889 33.97843441
-2.337909986 60.16553731 48.64365712
-12.72790368 38.88539349 29.63504712
27.82948033 31.81251994 22.43461509
-62.40656654 52.33753191 46.09277353
27.349578 29.56830001 19.44547325
-11.30776566 37.23226044 31.07507077
-53.11364679 50.34873181 44.10143705
8.563858072 50.70395398 28.21548613
-15.20452334 25.44174658 34.67835573
18.29459477 34.85347596 23.91118375
-74.76172243 35.49173268 40.07487538
-48.05221936 18.8681776 29.85378041
-27.35137626 44.0119304 31.17765841
57.17107477 48.23677874 47.58657213
-49.67804268 60.84044444 38.18360641
-49.72425186 29.44714536 35.55036918
-53.41041108 37.4408152 42.50988478
-57.71202068 8.975545268 18.35075689
-64.58852752 14.25869327 28.25120258
-65.71923495 72.60388796 38.6968611
19.20926918 72.91590152 53.83825259
-35.27765063 80.75806474 43.11137003
-70.24294876 75.9169018 36.05952293
47.26162397 66.09196117 34.47952259
-33.72438573 53.18178228 30.82223265
-68.46857103 44.63443422 43.44575927
-8.516224014 61.54768147 33.77695815
50.6830152 55.22707182 55.07507496
-49.78501944 11.09217102 9.747614976
-32.16049938 55.77119915 29.59735357
-37.21061108 59.48029779 28.28900226
33.71768392 20.50762098 36.01440124
-49.52249172 24.5395508 28.38978055
-42.13442965 49.24374424 31.52857109
-16.23898308 44.54143361 28.73823919
-9.823580691 36.99725292 27.6935252
-39.14595962 65.05936127 45.46521949
-44.39307585 18.94836984 22.39838761
-53.89045114 37.93740449 42.66330123
-14.38986822 51.13271668 27.61550902
-76.61754199 8.333365369 11.93112245
-6.80806346 67.75327769 31.33180211
-74.65864588 30.0144718 39.54620983
-70.32413704 29.76064113 41.66624508
-68.80258322 61.12827389 44.31607866
-71.44220557 66.89395791 41.98025194
-49.85391755 11.02208464 16.40101016
33.89336249 40.99173964 42.90521791
-68.79775364 29.73400662 41.68434737
-68.29235319 29.72684053 41.67115364
-3.006391072 45.31717749 28.86258512
54.07993726 52.0860121 55.93112992
-71.33624116 81.99277941 29.82784751
-57.20323407 17.77209884 32.66973463
-62.07040259 73.07426727 38.36714146
-48.69606797 51.85032064 37.48072003
-49.26017779 17.47531601 28.77357633
-70.89564246 51.14866379 44.03301699
-55.80122486 37.95971834 42.76866183
-55.76959081 12.98642775 23.85884169
-1.258071104 60.87303306 51.87788064
-65.09810571 85.32820483 24.8779897
-61.62977263 61.00369269 44.39441007
-14.71785709 59.25263156 20.81577281
-46.57841499 74.31671062 32.64281818
41.24227132 44.00316417 53.15805201
-78.09827466 4.623236774 14.49690166
-3.267616959 30.87802684 21.73337322
-57.6199797 60.93843984 44.38722127
-57.26269064 23.83534241 38.04084951
-76.10495562 5.730501787 7.08946309
-55.73800186 29.58837649 40.88435509
-55.02500598 62.12053241 41.82303232
51.40973361 56.18702952 55.44587762
-53.94793616 61.06833943 42.17897092
-51.92606113 29.66009515 39.3248323
-59.52269812 58.39605443 45.13074199
-40.12131749 44.43352072 40.37211792
-49.17848424 62.64020763 34.67529519
-48.21060339 30.22648515 32.0412026
-47.94839645 30.21810653 32.08846328
10.58980652 42.13542458 28.24597315
-18.7869841 30.28881802 37.35961901
-42.58996034 30.14154503 31.95535908
-42.08417893 30.13418677 31.94432358
-47.79644838 41.84775235 36.89345188
-73.66236759 30.36101432 41.15993624
-69.05873114 40.16441231 43.0098148
55.1844105 6.061673083 48.12701253
-47.28881248 75.2334948 33.78887735
-67.19235573 59.05048089 44.82297259
7.407545654 38.24294642 33.25309115
30.16383893 40.7107314 41.05196794
-39.04323856 69.04246274 28.34008294
20.52950274 28.4247215 34.02159875
19.73062379 28.93347904 34.09456191
-41.06996217 30.19246506 31.07436931
-45.01874236 48.0341611 34.46706935
-11.91247285 81.2892514 -8.297406429
-40.79421787 61.56908867 33.85741393
-53.11461414 18.44185485 29.94163416
-18.74229142 29.26184116 37.61971102
-17.23973542 29.24722617 37.5028261
-37.77085294 67.69925165 26.21048296
-55.74425497 77.87194383 33.90554353
-14.6438689 30.16777195 26.32140971
20.04703838 28.92253371 34.16128104
-14.7381249 30.26487423 25.21215052
-13.35605603 32.07878237 33.02461798
27.14385849 45.00816734 55.83317586
49.45017822 66.28973131 43.41957523
4.389772797 51.16459263 58.62392983
-14.83324672 30.36241328 24.09798627
17.76854215 28.3555694 35.35089878
-15.05488778 30.6228852 21.11650583
-12.93730855 61.43542092 24.27000459
-12.44491086 61.91225212 24.4673377
37.76802074 43.33894832 49.87803239
-69.11022185 8.24492567 17.3527458
-68.42921497 28.28923113 40.90615036
4.586826526 52.92538765 61.45232084
-18.90883592 47.6171938 28.50157238
37.5051628 19.32707968 37.34340973
37.32588627 30.72550515 21.58917259
-5.360398371 30.44038107 21.38634973
-14.74100679 31.06587825 21.74070121
-46.56652391 21.4722593 28.47357276
-3.354727682 30.41975302 21.24332172
-55.66325545 57.90442837 44.27523622
-34.94816313 79.73609286 43.25904843
-2.316151256 30.39140974 21.37428854
59.44042993 56.88190632 57.5010208
-14.50654884 35.63619625 38.56009528
-1.831551464 30.38234742 21.3870744
-37.44106991 59.7518767 31.00690723
-17.99820416 74.58629352 18.23624225
16.58399297 28.88542977 35.25243444
-56.68475989 24.2938051 38.43510609
12.91138161 44.45026055 59.19734337
-15.26023314 59.59483176 22.77333862
17.95661712 28.82485736 35.69383553
-8.575572833 52.43196063 28.90403883
-46.70480739 21.08935482 27.11833778
-36.25572047 58.03707668 27.38093997
21.30202986 28.88413325 34.36772725
55.42502427 39.09987319 60.76117014
24.33266627 28.84202771 34.27855854
-44.92621424 37.881221 35.77934446
25.29186425 28.85372616 33.95982958
26.15346829 28.88987712 33.37584969
42.11619481 32.54794884 22.82593937
29.51025846 29.94691687 20.46479869
34.42657327 45.60291841 53.36665451
33.04937764 29.8960904 20.37989825
51.70004346 57.26801811 54.49489093
-11.77444103 80.16749265 16.35175002
-12.79515123 70.39694047 19.26392955
38.61054884 29.81637246 20.24477352
51.66508953 64.35957142 47.92396106
16.11647398 33.79653573 36.59630005
-50.09577875 52.82414729 43.92268652
-59.46876763 56.86220162 45.44661741
-55.81203635 89.84503293 -0.1943559335
6.430734314 61.5488097 60.04659493
-67.58972383 42.12602904 43.26435126
-67.51056208 59.56454607 44.74265354
-4.235737105 51.87810861 34.50592556
-37.72365596 66.59001901 21.59834076
0.8183809692 52.07414579 48.74625715
49.19602795 66.36837938 42.55503887
-13.33187681 31.96747396 34.31215626
-1.615998552 38.80555616 28.44274992
-71.74312275 56.17710419 44.08723945
-10.59928433 69.0777148 22.50641766
-74.80931888 11.15973663 19.56219055
11.6743526 54.13707691 28.55458587
10.72605858 33.81489532 37.41115317
39.13533906 67.70674685 11.45699862
-10.41734351 69.03549212 22.96187214
-57.56842972 59.90800387 44.68616134
-38.76381897 68.05333505 45.59595571
19.29572305 36.93960964 22.80946378
-21.35114656 43.41912916 31.08840716
4.368304598 55.90024806 61.91948897
-57.37982844 19.62226581 34.53188762
-35.03735245 78.72709584 43.3359494
-49.47053745 57.97691166 42.25280849
-69.34444969 10.40338065 21.47369889
-44.4678192 53.70851168 38.40969352
-62.8989896 23.45074183 37.75380876
13.34531239 74.0041218 53.97697093
0.3791327158 36.6327134 29.97964763
-11.71585684 80.08838469 17.2589208
-54.60396759 60.04794647 42.4962645
-12.9314228 32.21812892 37.15264295
-48.52547187 36.48051354 35.24625612
-24.84926181 49.45108602 31.65185208
47.76347855 9.7459365 41.73245453
-31.45339148 48.55692567 31.63808328
-39.37295311 49.06485753 33.07858859
59.74217829 54.81993944 58.07372852
50.68014757 40.25541905 59.90490859
57.59410559 68.3664632 52.71810696
-40.26994568 65.29091577 31.33823274
-76.99150949 9.731323676 13.25384291
16.6849042 31.74871075 36.95398035
57.5253886 40.94123048 62.29136261
27.8186993 72.53075104 50.84084903
-21.16609298 47.8952805 31.53045283
-70.29139986 30.24634318 41.8482589
-69.78118545 30.23674873 41.86233729
6.169601655 57.96680067 60.8924834
-68.25279335 30.20910096 41.89181932
40.89389828 43.02579858 52.91718005
-67.74102046 30.19872035 41.91472682
-55.82328675 10.01833695 17.5383588
-64.18825594 30.14101061 41.90713011
-63.67731424 30.13104929 41.92532875
-55.7763315 37.46972294 42.6254978
-68.74935178 12.11205306 24.83125795
-60.63770792 30.08441896 41.88697644
-58.60502292 60.43756276 44.56298067
10.43786206 56.75155843 27.57278678
-58.6115346 30.05344855 41.86010023
-55.80592783 61.44235461 44.01810105
-49.90928129 30.47058025 35.35815802
-46.64128194 18.14312526 26.34836059
-49.49725095 30.51051386 34.81600277
-49.44372964 30.70651621 32.5304572
3.868667896 36.11134204 35.36663254
-35.14501136 54.88471701 28.80416764
-49.69987858 13.09618233 21.42719956
42.14884294 69.47432285 48.62940809
-47.63444725 62.10245191 34.79685807
-47.59993733 30.69321267 32.33327219
-44.55766429 30.64523714 32.31002746
-64.61533325 31.11309936 42.35714255
-48.13235511 74.15902302 34.76971691
46.64928186 69.93634054 48.23434965
-58.39386945 41.27161724 45.60264197
0.5719423114 67.06671743 49.54776802
-58.67570289 21.03927026 35.80931974
-41.06466555 62.05906243 34.04765791
-20.31256416 29.83760912 37.0618776
-49.69677309 27.27575272 37.44567601
-19.28851483 29.79181604 37.3981848
-12.87381588 45.01036772 28.47939305
43.18792142 42.35749775 54.41117918
-18.2499384 29.76347275 37.52915161
-17.74329117 29.75567774 37.52302116
-48.0950229 72.13132703 34.9950088
-63.97292969 40.87053204 45.496051
60.2276201 67.27247157 53.26245149
-49.65913231 77.44027193 26.10295354
-49.71060635 66.12680968 35.0882392
-14.04075795 29.79586353 36.35041971
-14.04423047 30.11972731 32.59143415
45.39225889 67.54382024 29.63506303
55.86700493 40.98489561 62.10072961
-42.09673966 40.36494734 35.54004313
-61.02646495 83.50210465 27.82028484
45.22806354 45.59867451 45.52936955
-7.527286855 47.3765592 29.12450481
57.06348866 39.51891648 61.41078582
54.26511683 6.643561643 47.37398104
51.60016989 42.30813232 59.20662451
-51.68452031 42.33570127 43.62374624
-4.566313403 29.53093721 20.139119
-55.91505246 88.274049 18.06242068
-57.534531 67.16970687 41.95352065
-14.27371862 30.43371334 28.99021936
57.93275126 6.267739359 51.0373611
-60.81098752 23.89079849 38.07375633
15.16335109 30.76847661 36.97014117
-55.81292141 10.34187929 19.60710767
-14.32935901 30.51133634 28.09972578
-55.79619397 87.64993127 19.45832718
-13.98488267 30.98845155 28.32197141
-14.62169216 31.08002464 21.55372555
-59.6976544 15.52819205 30.06113836
-63.85531742 34.49300705 43.76225429
-49.98469432 11.13668054 3.442353297
1.908062156 65.6360705 54.24767462
-47.85766831 12.71015111 2.250644462
-13.69875659 63.43194484 24.54467411
-50.10777669 81.28121007 22.38658523
-13.58331349 31.02700326 21.97121146
-47.53299126 19.3562523 29.91547403
-73.86439949 30.84125422 41.45013069
-75.40401358 12.11726702 20.2131727
-46.64685863 48.29221774 37.60849251
-11.3628555 30.99713914 21.89444134
-13.12534828 38.03189302 27.96561646
-13.38377815 41.57865636 27.62796948
-10.84962803 30.98602478 21.92558933
-14.9770577 34.62132955 38.77785576
39.18709604 29.81048382 20.20318208
-54.98391343 36.48678638 42.23174208
37.80134589 30.72105352 21.55017739
-49.39381458 31.18355304 32.80978739
30.73566161 72.04377134 50.11114163
-9.886854946 83.70769665 -7.62557682
3.044037112 65.36037258 57.2315405
15.54006311 29.31957815 36.23824791
17.66533731 29.41206219 34.75932292
-4.153183847 67.10782789 38.31835411
-15.04022749 26.64731747 20.65187453
-55.81648391 89.79772075 0.3557265852
18.2696619 29.28066116 36.16947391
22.81972957 29.36409518 34.33318536
24.41583934 30.51640271 20.65196869
5.72395509 51.39023828 61.57667527
-77.35669888 6.704021136 13.50677865
-56.19860069 10.21431957 21.16146563
-55.65472307 45.87171755 44.11871648
29.63518823 30.38611322 21.16910314
-14.49957032 30.745865 25.40960581
8.898028015 36.82688521 31.92759636
-49.73760997 49.80362115 43.95900801
-52.02959986 28.70789226 38.74516193
41.68268761 14.13027791 38.60858959
-60.3584981 50.78886862 46.20028556
-55.93430268 48.87737794 44.23997989
57.76164931 71.01602811 51.06127634
33.69047867 30.32268742 21.1320279
39.75535331 30.23665943 20.97409552
-35.97818806 51.36826968 28.99806023
-35.96850003 50.86116661 29.05639427
46.32490111 30.14383223 20.79884916
46.83913293 30.13221124 20.83568703
35.57662272 33.63008712 23.16410597
37.6625418 68.20856634 11.73902396
54.52028741 40.06513438 61.38153439
50.24554703 41.24369175 60.16848061
-63.88725805 34.00690318 43.58475312
-61.14020533 67.7563225 41.65792279
-38.5989788 60.74573679 31.343556
-5.281080036 30.90258569 21.8322557
-49.45955416 50.33236051 37.76798775
-18.8141012 47.06718971 29.04171397
-57.49120647 14.14741423 28.1895092
-78.88798403 8.357935647 17.90552151
-25.73595232 76.20996716 0.8630858782
-41.09931906 32.0922487 32.33245661
-17.48520955 41.6901354 27.11600689
-55.79314697 43.8335687 44.49887053
-66.50275838 14.73541136 28.90880738
-66.40457091 77.46954364 34.78324405
-11.19651136 35.39693475 34.87973188
36.52624599 45.48531619 54.33144687
-49.7081082 10.02416395 10.47787147
-57.28688703 33.89852749 43.58412684
-46.68785219 17.61484546 26.66325845
45.54138019 51.42432033 53.58760285
-37.32921409 44.10197743 32.03516044
14.62942216 56.14195448 28.02351789
-6.570062549 32.31611357 23.14873208
-49.90964239 85.18567336 11.98278409
-54.44485795 13.5885079 22.44346215
-52.07901152 73.66543335 35.42567628
-65.8484634 20.68638803 35.44708181
-74.47364713 49.71245762 43.90797057
-74.48323839 30.88086544 41.10831092
-45.73753549 29.2382072 31.38894347
-11.10573043 81.42926826 13.22989201
-74.14569777 30.85926329 41.29471348
29.39000334 23.67219194 35.06291189
-11.10072312 44.94600542 28.88841819
34.8519851 21.46020012 36.39313118
-55.76010238 36.88498673 43.58380681
28.77668325 46.5088211 55.58102494
-13.20700513 62.83033642 25.60816308
-3.842031722 30.43017947 21.21521572
53.59641266 7.700370069 46.88658517
-60.59534282 30.5652643 42.12353452
-61.1808254 79.5689011 32.72273414
-60.08976921 30.55801086 42.1113218
-55.83021078 30.63932248 40.35507347
-53.38753948 30.64603202 39.81135211
-43.24650686 20.42175448 22.55552853
-49.72160115 69.84609092 32.70069083
62.77992194 57.86187843 57.14120991
-52.87351546 30.63451585 39.84701276
-17.40374588 52.09646691 28.65563951
-15.31891191 77.21438153 22.17636744
48.55291762 12.70789729 42.15714661
-52.36808038 30.62733229 39.83401523
-51.860221 30.61892582 39.83475186
-51.33177453 30.61252331 39.80829899
-20.51987608 53.76268064 27.38720164
-18.82213378 73.77448453 16.16415963
-9.835710178 30.97012029 21.91686008
38.6494344 16.30645684 37.23093931
-57.54579688 30.51362002 42.14149228
-11.54327148 62.48663829 29.28077836
-44.86075215 56.41780324 36.1663986
53.64028141 41.06747603 61.56672529
-38.77052957 67.55450413 45.56138795
-45.51667995 31.13566275 32.62634003
59.62884806 43.88456993 62.68163326
-38.99966555 67.47666788 29.02874022
-43.99971668 31.11378014 32.59107529
-54.77901249 40.90337019 43.36150621
-20.58787184 30.37959017 36.64930969
-52.23321846 80.26537102 28.75788096
-20.53390379 25.88197796 36.4108145
-49.92075495 9.597778834 3.814931194
-36.97609838 49.86945124 29.10782334
17.37432336 43.47410098 58.02492435
-15.26223754 30.24524169 37.1932946
-49.46816374 41.55125257 40.65424452
-49.2231482 35.02846766 34.75579282
51.06686995 55.15732907 55.81149636
-13.89098188 30.27208209 36.62020482
-63.99274055 55.40762338 45.71524924
37.56705563 68.36250382 15.79685667
-68.03703387 43.13310186 43.31210158
8.291541279 48.3701952 61.18603988
-13.77960092 30.33978626 35.8130043
-74.59821105 42.7442684 43.25057904
-9.71254654 52.96259984 28.78746019
-13.79974266 30.39950228 35.12361812
58.83447479 48.64324348 48.3774535
-14.03543754 31.16262062 26.30973054
56.95675969 71.0503795 50.81599758
-47.3410305 11.69288439 2.308011273
32.06730658 74.12423495 49.01221239
-62.4194028 77.94370643 34.34546168
-40.90907524 31.64273305 31.6878407
-14.33689264 29.51070456 28.06394465
-45.92757421 27.36237318 29.89467158
-49.63601543 36.23543681 38.30307349
-65.98942047 42.89309336 45.70780364
-42.69701485 20.93585926 22.30927455
-10.26407806 31.44064403 22.36301043
-9.780012187 31.43185104 22.37277151
-36.04512219 53.91311411 28.60161794
-3.747657022 31.37461614 21.88678968
2.899683826 64.83186283 57.56775557
-37.48901934 53.39592315 29.05426702
-13.77442365 32.45661012 22.89166033
-2.882560973 31.35944988 21.89787169
-49.4670559 37.78074407 37.81172003
-45.74932984 28.76671858 31.03793576
-2.561853415 31.32156116 22.27655082
-59.98651322 40.8046823 45.50024925
-0.8327580231 31.29178186 22.29250178
-19.02841961 74.30642599 15.85497572
17.72959764 29.90664053 34.83228267
-6.520059501 37.56748772 26.27046285
37.84061979 44.78419662 50.56661313
-36.11841185 65.46961618 22.64538319
18.02265515 29.85792177 35.34195784
-4.597488333 67.68550212 37.52366234
19.79260023 29.90664764 34.43877312
20.29471056 29.90114122 34.40694016
23.25452283 29.89476227 33.91653766
24.3060911 29.93419893 33.25818763
24.15808006 30.92564235 21.77701357
26.68449354 30.89010891 21.70770947
34.39085064 44.01517887 54.3251838
-0.9290957241 49.27814724 29.09773202
29.72770167 30.84166168 21.6897622
42.28090097 30.20156275 20.89988637
-52.16430879 43.33437968 43.77513872
30.74515207 30.82397523 21.70104559
9.425625301 35.984194 35.78291469
-51.03862207 21.15127143 33.05269085
-55.78025909 16.63786965 28.08540157
53.22535121 64.69417408 49.5687391
-49.53181036 35.25277303 38.03739744
34.80103127 30.76025243 21.66730579
23.96695239 30.47028322 21.27296286
-65.11909242 39.4093314 45.19739183
50.11985839 53.32884203 53.91194527
-49.80164122 13.10941983 9.639652085
-49.58842796 33.42113629 36.00460692
-49.49852219 64.81717088 38.59775519
-39.04641612 44.07620152 32.66186681
41.88120907 30.65762115 21.50849225
-53.72971342 32.57972409 40.73546171
43.39917669 30.6352319 21.47891738
-8.040816864 47.8900422 29.0881936
29.15893108 38.91625066 21.28876258
-29.10042772 86.5609635 38.66210187
-2.82487822 66.78466624 41.81653517
-2.068542846 66.60136261 43.80022002
-41.45028057 41.4025579 35.0247005
-69.57989292 32.21840623 42.1259868
-47.79143361 22.77247373 25.26661365
-26.44514933 53.53050944 31.21240015
18.44834107 32.92904095 34.56882946
-63.42571315 39.87500694 45.29519359
3.040683631 50.18981311 52.71723766
-67.53234434 48.09889893 43.83566675
-60.42823125 57.39001998 45.32893299
-56.04931922 59.88503811 44.66306114
-46.59388829 70.30678778 32.58275268
-49.51794631 34.69400771 38.69466838
30.35848807 37.30005412 22.34206626
-54.63572254 34.04784309 41.34516908
-68.08262181 40.64501815 43.07108246
48.36522212 10.14283132 42.83689474
-13.2360763 43.01079759 28.45438409
-6.707333341 82.72233348 -8.446397836
-21.98843809 41.45918454 30.65584636
6.367797524 51.36279211 61.77250588
6.622546595 64.03746544 60.25308761
56.86790598 56.45087267 57.16872882
-72.80678179 31.28074391 41.97316056
-71.78555641 31.2611532 42.00583
7.013961873 41.80167227 26.97570746
-70.25415126 31.23198551 42.05238163
-68.9200558 63.19639595 43.63677455
-7.303545305 57.55579387 27.44666861
-71.83593697 67.94833703 41.4686182
-73.13003037 41.22795672 43.09311808
39.57412729 32.59137882 22.80656001
-69.44774782 31.22164835 42.01859699
2.885828962 68.24859361 52.86633793
-65.82325871 31.1277578 42.41733533
-63.59639364 31.09466168 42.37686264
-57.29466509 86.36071938 23.05692588
-9.579296861 61.78660459 31.2060982
-35.54587899 55.46525777 27.9679154
-61.06249947 31.05535467 42.34993824
-50.3058717 86.87407677 15.76469264
-59.74276467 31.03385075 42.3478902
-55.94320857 30.02066218 41.73184249
-59.48726784 31.02885698 42.35713668
-57.00246433 30.98953545 42.33974272
38.38779631 31.17521854 21.99269974
62.90348729 48.12689433 53.59562696
-49.06034437 19.38337597 29.89187807
-52.52091703 86.52288377 14.43737857
-65.06108046 22.54584255 37.01758177
45.22774361 30.65438652 20.90783781
-55.76453476 31.15796455 40.1484139
-55.72242531 62.52855385 43.04606476
-67.78766102 61.11186276 44.31303928
33.31420606 32.67668486 23.01006984
-60.61498441 37.86063415 44.83691661
-52.52886595 31.11154371 40.07023941
-53.28204829 26.8228683 37.56018148
-49.51686751 31.17795134 38.7249248
-10.83666239 82.19700208 -7.387120462
-49.96631054 31.47900233 35.31581619
-16.43151685 46.62677139 27.87339708
-49.01326086 31.66724721 32.94877955
-48.5134363 31.66289382 32.90399724
-67.75840543 60.59488902 44.48222764
-45.9788841 31.62325488 32.88080069
-45.46222799 31.61041098 32.93137272
-43.94353308 31.58765486 32.9059181
-46.64021263 21.13110415 26.62136242
-46.17361896 52.82121908 37.38205788
-43.36379965 31.58015896 32.88237727
-42.93456772 31.57424861 32.86913191
-46.24172836 20.74998848 25.14299672
11.76945954 50.57358891 29.11753164
-20.44558649 30.88436521 36.58901879
42.84969007 17.124781 38.58350331
-18.84375623 30.8072833 37.17836334
43.53192107 42.3326374 54.63417348
-14.59210825 36.68378797 38.06848451
-17.77111766 30.77414661 37.3584797
26.45962299 34.77349246 23.28257063
-17.45716199 30.76444167 37.41126846
27.4488438 26.74790764 34.68779537
30.55312288 33.22131096 23.04084858
30.87649807 31.76215099 22.43825009
-51.498505 29.17759196 39.017903
-16.22793734 30.73903905 37.47174012
-14.24159177 30.72815938 37.21923121
-19.34940294 20.56537134 33.81092261
-13.6703362 30.98510753 34.12744722
-49.85778877 59.98289831 42.34626622
-13.73191887 31.11528376 32.62801042
-13.82178201 31.30928153 30.39307988
-53.77766658 9.214244301 9.002816978
-10.19411059 31.9075656 22.75594177
-9.681956736 31.89699282 22.78100749
-60.88880672 52.31524749 46.06202144
-9.147585171 31.88760145 22.78812156
-45.34166152 19.33442273 23.92434613
-8.665164361 31.8796383 22.78856303
-9.844314296 68.4919953 23.33525898
-6.057422071 31.84024607 22.74854471
-0.208785679 31.776575 22.37231485
-67.73103419 32.17776911 42.24514364
-65.9655578 50.89173459 46.07544219
-55.88464766 35.51549794 42.02566074
13.59955508 30.31176754 36.74353774
14.11233233 30.3008803 36.7721351
-56.19371545 88.89112722 5.125426108
46.00593789 66.47760198 30.2421888
-50.15226312 22.53291596 34.32444177
-55.99859945 16.85377981 31.44723917
19.13153081 30.24677699 36.44301443
-42.97458463 19.5279323 21.2265296
20.78751381 30.4025457 34.31893139
5.404182678 49.64179186 58.62833578
22.09934229 30.40980789 33.98445291
22.70507858 30.42636187 33.67676463
23.22547298 30.44879901 33.31705562
44.87648024 67.47708692 47.9880399
21.91383758 31.40777573 22.43468891
18.89907377 32.91135578 23.03489074
-6.589486777 43.88353945 28.70869696
24.73348261 31.38538037 22.15694737
27.60547214 71.13166346 49.6432196
25.23929866 31.37800464 22.14610807
28.78821893 31.32223409 22.1167329
32.03741763 31.26898822 22.11520812
-49.6935697 14.87886328 24.0378989
35.88211133 31.2126846 22.03561549
37.39512646 31.1927936 21.97798367
49.46385217 54.36685617 53.64030571
40.90080036 31.13406043 21.99124809
6.372446158 59.04235464 60.02126586
26.24691877 68.59879535 38.51919739
-24.87030304 75.54363867 2.606588081
-78.80857205 6.370957533 17.65010562
2.825596054 66.54932879 55.12420862
-49.74423079 26.11515635 33.4478528
-23.15532453 41.9801351 30.65744521
26.56782117 68.88957939 40.90900731
25.43376211 32.81133526 22.94979763
-5.759047689 39.56413419 26.25336748
-48.70492017 67.9190399 31.57085449
-59.35570252 33.44879233 43.37287537
45.35095779 42.20793232 55.73494008
-62.27368377 44.30937757 46.03785225
9.159358043 44.12252969 28.75756558
-73.52295047 29.88758203 40.80265522
-50.7462832 17.48163266 28.98365736
-62.69530362 18.31917511 33.19288564
-62.03073987 57.41788838 45.31102432
-55.76672146 85.27931007 23.66604468
-60.86307651 50.29340402 46.22158554
65.02248947 47.15043556 58.70032656
-56.24733293 46.70430698 46.21970707
-3.945221835 39.41727982 27.61225289
-52.78901945 66.24923038 40.08080386
-13.52037188 35.6342854 38.39420795
43.42593754 64.91628842 37.205797
-53.9693036 21.21728921 32.84520679
49.95343803 12.71997537 41.7498478
51.46805694 7.906774728 44.89637913
-39.81807368 56.17175176 32.23443328
-6.640594042 53.42664666 28.64126717
-75.4761764 7.484128598 9.918735304
-69.78863476 56.61017725 44.51371972
5.507959224 44.2299946 28.20637802
-11.31415833 38.71735538 31.31614668
61.28866506 53.77873192 58.21261916
-49.84494516 11.67417173 8.829394914
60.23580261 67.77056 53.30535785
-15.23616258 49.47642446 23.69778711
-42.69326174 23.15597119 25.66908418
-10.06557297 37.54720617 27.18205668
22.68584678 46.53361173 56.4547964
-13.46396483 32.62877306 26.6605025
-73.91764046 33.45352483 40.26834466
-12.16981294 73.31779113 20.19715314
-31.37875167 77.39630941 -11.83272712
31.8916714 41.80418051 45.50882809
-9.633623654 76.31354248 -3.410103376
27.45582515 33.76209243 23.18037676
-19.10468669 42.99782187 29.72419514
-55.79389365 86.52044957 20.91645477
58.79628628 8.18789108 51.88872097
62.23576818 47.76328027 52.11742129
-11.06184652 45.44099943 28.96139341
-12.74519616 42.50874012 28.36237897
-51.2445154 26.78616765 37.59765963
25.4218747 74.00756263 51.63395392
43.9803467 12.95792694 40.12663641
-14.37672232 61.45535117 24.31314454
-7.003902416 32.7893976 23.56388246
-12.58079692 32.87704701 23.60993133
-9.352407659 70.30227731 25.53293864
-74.83796772 31.81645891 41.96819005
-69.18541052 31.71542 42.06314699
-67.47553786 31.67055957 42.25783655
20.26186231 30.92214327 34.21394936
-48.87684318 11.22205938 2.239939335
-9.443886334 60.40987274 29.68245965
-67.15784184 31.65896785 42.33181517
-66.05083232 31.62088197 42.56283055
-51.2102313 82.5263121 25.62270015
-64.57743578 31.59619835 42.56839052
46.0950508 45.35955356 48.13992088
-62.03758476 31.55388646 42.57521293
-60.46366918 31.52805264 42.57495567
-41.61737149 20.91983727 22.28937495
-43.59418099 30.15255787 32.01902499
-56.06488384 28.57539208 41.05287394
-62.44271001 86.46742916 22.7999239
1.949923843 59.69413181 59.12500312
-59.50233991 31.51389812 42.55594043
19.31676693 33.40109314 23.0966463
-7.539762063 58.60504974 26.96447039
-51.77874628 31.58225869 40.28942499
-4.020378188 45.33311693 28.87092197
-49.50955083 31.67647653 38.7629261
-47.95313366 32.13024886 33.19838683
21.46431935 50.00111121 39.74104533
-14.24353365 42.53774569 28.31140304
-20.75252261 31.44230094 35.99726909
36.53329925 44.42111153 49.20424255
-20.45949245 31.41837383 36.21914985
-17.45157695 31.26384037 37.43945965
-58.24957149 63.01711721 43.68304764
-72.35399322 14.38489966 28.26702762
30.494533 35.72477943 23.12313743
-62.07074563 66.72573591 42.14590207
-15.61486499 31.21694166 37.63362214
-62.54398788 81.26762693 30.74257061
-14.04992 31.2080219 37.43872485
-70.37072735 29.28192716 41.40575032
-77.23676247 5.750588608 12.8987648
-46.70978245 17.452463 28.55249509
-13.75498815 32.17424967 26.16580065
-71.66249846 16.62149514 31.30432768
-72.24614563 87.29382873 20.90268915
24.34727815 72.04521184 51.31272244
-12.78594192 51.06696636 28.07290836
-57.08132609 21.48055314 36.20916984
57.99341274 39.04316585 60.92966328
-6.061787577 32.30749745 23.15182314
-13.35321334 48.33982509 24.87989076
-45.13588925 23.65145559 26.20960435
-72.00503981 45.68835577 43.5387763
-3.060034945 32.27996207 22.89902168
-1.054087193 32.25919426 22.75756329
-54.35110247 59.01507034 42.78513973
12.62350007 30.8107885 36.96331876
13.79415333 30.79015383 36.97961047
14.65867793 30.77527583 36.98745515
53.52671508 8.739960425 46.48483507
-50.05035249 63.59554749 41.23120707
-54.21060881 77.51287465 31.95476393
15.6620674 30.76468228 36.91908038
18.2687026 30.88690456 35.00313363
-10.92942313 36.9108608 28.9073189
18.81013824 30.76244943 36.34464477
-47.52874068 60.54248112 35.40605599
24.39455356 75.0594382 51.27221272
-48.2261464 25.99932678 28.67633756
-62.66293066 21.5705785 36.22853519
19.13957478 30.74493531 36.48513598
-79.08887917 6.31413306 18.36322195
-55.97582478 88.87366675 -0.5400748029
21.22683145 31.90965575 22.56615652
22.25944313 31.89671025 22.51951202
24.43682792 31.86379616 22.4863625
-59.12378327 13.725401 27.57324792
29.09244277 31.31743829 22.1143888
-49.26719788 65.2703721 33.29254891
-15.30540836 29.26258676 36.95562127
-49.48415948 54.76560525 38.748108
-31.52420261 51.58116399 31.50386716
-61.99512713 75.21860433 36.76631972
-70.60956648 26.89132725 40.06992213
31.38030541 31.75578853 22.41603105
44.10633183 46.36940358 42.62274545
6.145909704 65.03455406 60.4223345
33.91634682 31.71539837 22.4012712
-57.95181801 71.39793168 39.56190711
-65.82873293 36.48145051 44.36185722
-15.44722249 81.65038373 23.1442543
41.52097313 31.59599238 22.33717521
28.7155501 30.85667519 21.70849778
-15.14296956 28.27532536 36.73221219
42.50955631 31.5804788 22.32873941
2.557861573 58.54130034 25.77884231
43.99895836 31.58488812 21.99351484
0.6507011492 38.03953805 31.07633355
-52.97960908 11.32941056 19.25607597
46.71592772 31.55234193 21.85319238
36.09013014 17.96434123 35.95300457
47.17410946 45.06301084 51.37662273
23.18044178 46.53189635 56.38038753
-50.75245628 66.2130189 40.11278834
-60.36456398 33.46214617 43.41025017
5.744448671 64.00963466 60.74362613
-46.92021325 24.58963723 27.31207043
26.63568114 71.55319103 50.76140597
-74.55082607 54.34566762 42.5767739
-55.89240237 7.913789379 12.84945844
-8.134968429 54.46634442 28.50996475
35.14184138 73.09272 48.74717667
30.06974083 23.14920066 35.1779054
-64.67521506 16.06350756 30.62269685
35.31854071 42.83345736 50.38663008
-74.31340616 32.3018432 42.06009882
-40.71754536 58.13878902 32.8777274
-75.26597529 9.51750688 15.40691495
-72.7914904 32.27746236 42.05289101
-46.60982939 24.1538516 26.48515502
-49.45447697 57.46659407 42.34724377
-56.88222438 55.28968819 45.72830895
-21.32967035 45.89459795 31.48046767
-70.24010665 32.22933289 42.1250488
-10.26809599 37.52547778 27.47291804
-66.95411563 17.46844453 32.22768617
-66.61218965 32.12132424 42.68702663
62.15753895 55.24353018 58.52239516
-49.72258836 63.60365486 41.07458425
-66.05470067 32.1002715 42.82510576
-10.03931364 62.17398191 32.62350865
-65.54545598 32.09116622 42.83369048
-46.61588661 22.04893159 27.61519441
-58.87233852 44.25371633 46.03535163
-47.06877952 45.59752607 39.8376987
-65.04296469 32.08546763 42.80401576
-60.65898176 76.81935959 35.40869596
-59.96831904 32.00339451 42.78901503
-62.55242011 29.63128136 41.68583456
43.51887529 46.96973384 41.59234582
-63.0482389 66.22105033 42.36443357
-57.93553085 31.96908728 42.79961346
-57.43161961 31.96267241 42.77798303
-19.52872493 24.38861155 36.07528527
-35.89171622 79.21428647 43.66985823
-56.41579695 31.94580706 42.7800449
-64.87838956 15.16156503 29.4785687
-55.93543604 31.95127194 42.62499688
-67.56178324 47.10931706 43.67578765
-55.71538552 32.11282586 40.70759575
-54.75559377 32.11183586 40.53605039
-16.74279454 48.234226 20.92553403
-60.87789534 33.47331294 43.37851356
-61.64766871 76.2955427 35.85145232
-58.69038605 15.51564184 30.01473825
-49.49027407 64.71389878 39.79504039
-49.71199064 32.33036718 37.03733341
-37.92027902 47.06654091 32.69290902
41.03402912 68.44718199 14.15267772
-49.86997105 32.45961466 35.56706191
34.33608175 20.97044828 36.350273
65.84633139 44.24855377 63.0971728
18.9206878 28.94559816 34.10833392
-49.54864214 32.19841181 38.53801627
35.94829373 49.54680857 53.90602517
-49.56602334 65.90520898 37.63316943
-49.49603682 32.5187655 34.80908409
-48.40484407 32.61254752 33.5122968
31.02117054 34.20025241 23.24060411
-47.39411244 32.59825028 33.48551693
52.26161706 57.87101022 53.21446372
-46.88968171 32.59157336 33.46682954
-79.83864212 12.76849213 13.49898565
-16.78165228 49.31447413 25.8725592
15.76699356 53.05037794 28.73600774
-52.20738206 32.5551336 40.73060833
-15.45116378 51.17252042 27.35583347
-44.35942398 32.55410073 33.4193039
51.85954735 8.360174124 45.38496944
6.522518773 45.21819846 28.19438563
30.04234914 43.27163596 52.13277569
-41.03413518 32.58636117 32.41064842
-19.01155356 31.8591924 36.65231648
48.63143685 69.92757477 47.95810026
31.14482026 23.10244377 35.51566948
-17.17300551 31.74942285 37.57597949
50.77596792 50.84607235 53.47541914
26.35848399 32.31343098 22.72684295
-14.6395775 31.68557315 37.83405362
-62.45572087 19.70496786 34.53982506
-14.14068803 31.68169146 37.78397385
-13.33071013 31.69432904 37.48279984
10.90533321 50.58826601 29.11194329
-69.49084455 44.15233762 43.41060115
-54.14396405 20.76601227 32.29062667
-18.296801 21.99943431 34.44245841
-13.36771273 32.13421819 32.38330138
-54.32617599 58.50028038 42.92980281
-49.48216288 65.20536425 34.08820274
-13.37919627 32.18956666 31.74296578
27.99041517 23.35565042 33.17782557
-13.4747557 32.68377213 26.02409095
-13.5010129 32.79612874 24.72478053
-45.42726276 38.37601889 35.9575633
63.51211742 53.77213517 57.86517328
38.90963207 44.18872851 51.44873371
-16.85812069 48.82605404 25.73043814
-10.55649377 32.84701999 23.57246018
-39.47638805 42.74355208 36.56095114
-64.83675775 11.61839313 23.98922646
-69.13909861 75.35341575 36.56372854
-9.679435001 78.3950714 -4.258710309
-11.50855775 82.53113251 6.342109031
-5.994244403 32.77564194 23.53102032
11.65336727 31.30682205 37.21665038
-52.96891983 14.34397356 25.04527734
-37.62478525 54.62416714 32.301729
12.65696454 31.29612368 37.14945283
52.90819564 9.306404375 45.85373647
-56.21480235 34.85771332 43.89802654
60.32862307 8.264023799 56.53933263
-12.56580918 36.72708768 37.17940228
16.67696413 31.25050003 36.9124474
-63.93674571 9.898861825 20.47259898
58.45721309 55.87789192 57.69057631
38.41265234 48.03593006 53.49551485
-39.39947953 44.08085615 32.67516381
-37.4441075 70.72116903 26.02739353
23.36580378 32.36116771 22.74340217
29.10107009 32.26796265 22.73164482
1.4155347 51.46892387 49.83159343
-50.50332455 16.12498271 27.2063834
-23.68685018 50.43904081 31.61455475
34.87349605 32.1807906 22.64276533
-64.21325266 8.277257668 16.04353075
35.48907809 32.16760013 22.67849483
41.57428221 32.07131713 22.63573323
-48.33457447 28.807151 31.06158839
4.562520392 54.25567598 28.53407047
15.94808359 35.9827953 28.72863786
-15.78468515 24.93485362 34.84673862
-67.75172489 39.14489714 42.94255528
-20.77407932 50.48116932 30.57001316
-49.16252119 30.23589214 32.11353584
-16.61001118 80.18518097 22.89522528
-49.60086968 20.91490855 29.69573425
-17.95069032 58.44851603 24.94041317
-30.97105292 53.60833105 31.17210712
28.5210905 25.19593162 35.01985924
-77.05969285 8.283847929 12.59027797
41.14761318 41.61416703 51.77612144
56.37254293 53.92950643 57.39985254
-43.84103945 36.88688418 35.46210078
-12.13015126 37.90154281 29.28902709
-38.58395556 75.66928995 44.54970512
-24.71516704 89.32533551 34.86816234
5.791217456 50.90364808 61.38590119
27.74766935 74.57544555 50.42465227
27.93679104 42.18289319 47.69331558
13.60424864 56.16353679 27.96848138
-59.95882289 26.72200653 40.00435989
-22.43045574 54.99421042 30.9349874
51.44055768 44.34795563 58.86386937
14.82384492 50.0172874 29.16635513
-9.63015626 60.4542792 29.20247988
50.6301716 66.3453776 48.37520235
-2.236413585 67.13870379 43.4210263
-37.17128395 43.74309568 30.34456232
-41.52938346 53.39080382 41.5375014
-17.32218036 62.49608809 24.44650914
-20.87869825 45.61089455 28.86125937
-56.93423129 40.75165788 45.533707
2.607540166 51.61762219 53.70471372
-77.66348929 12.03626506 15.75775654
25.19715836 44.4968322 56.31373384
28.41686981 36.28393946 22.85486337
-46.65517561 18.68951659 25.83474159
-54.56495589 68.91282976 38.63168434
-56.85778254 57.83799456 45.27425203
-49.87977694 37.39426892 42.37691477
-72.08998296 27.39699018 40.30878296
-49.74941491 10.63966924 3.340513095
-55.98624781 32.42956379 42.90896707
-49.49928144 52.81392499 38.10095692
-12.70003164 50.09353122 27.70358842
-49.57668236 78.30400144 27.71370937
-13.85641518 51.11139972 27.76123919
22.31372589 36.38927203 22.79599217
10.68390938 49.40671177 60.35044174
-32.98326165 51.09639918 31.5829814
-3.052549823 67.32739555 41.38619631
28.47742774 42.5610571 49.02685127
-77.51932249 6.159949834 14.0271279
-69.33517837 53.11370169 44.23039662
-36.5069084 38.98046577 27.23953632
35.80644942 68.90626485 15.64688087
-76.89158618 6.841067108 11.82714895
-77.04371844 6.79391956 12.40348472
-36.57988895 61.71611268 25.52031192
-61.0623474 39.34517185 45.16855709
-74.34124181 32.81810066 41.89895475
-12.97617232 32.93448233 28.84522246
-12.09359187 74.76121233 20.90625337
17.61810009 30.26687764 36.49829181
-12.97156065 32.83304459 30.02190641
-73.85727908 32.82174864 41.76431177
-48.14532341 9.339743919 0.6451619592
-44.65186025 49.90751058 35.95645856
-72.68899818 32.80231068 41.76716362
-69.27974494 30.7214073 41.96708011
-43.68759415 20.39647838 22.93307001
-68.19103187 32.68902607 42.22446494
-11.25701214 81.60469235 11.22229114
4.345407108 59.42731067 61.76562508
-65.23118792 8.295188706 16.02950054
-67.72384738 32.67635982 42.28240918
-64.8139776 35.48546936 44.07713149
-51.15473555 33.02190881 40.9378359
-67.45969234 32.6669985 42.34070624
-68.08690422 48.60830121 43.85455208
-66.02494191 32.58747599 42.99024618
-49.91513505 13.16001181 3.247345412
-63.99776424 32.55599894 42.96905983
-72.59357279 20.34078178 34.91882315
-63.48903904 32.54715571 42.97470152
-62.46850632 32.52791441 43.00344691
-57.89998841 32.45337425 42.99751967
29.56550949 38.90937873 21.29099999
-59.60072094 81.77181015 30.154986
34.07126633 20.00048853 36.00749372
44.02691964 51.94715648 53.63359677
-30.91743617 84.95499668 40.17192872
-52.82416471 32.56892971 40.68807715
-64.70317134 13.81430827 27.60517023
-52.56077449 32.55995419 40.74204141
-49.52407268 35.29842554 37.50595414
-50.68737111 32.53171361 40.71260939
-49.5535446 65.34714246 38.28259228
-49.61849979 32.95886758 35.55005342
-13.2677434 31.48153318 22.46115625
-49.49270292 33.01929977 34.82452212
-45.32865633 33.04968005 33.67773678
-53.0754487 85.30262306 17.05552666
-19.38275241 32.42476701 35.98414424
-73.59055856 12.29937076 23.57998427
7.598588794 58.19712172 22.9863806
17.99799691 45.41613721 58.66797585
-54.75623339 80.37352366 27.98352016
39.87990213 69.01125188 13.65128261
-17.77195602 32.28121758 37.34338247
-11.67808945 43.97265306 28.64462814
-9.275807326 68.35388429 24.83013495
-51.36087427 37.41044821 42.47154793
-50.16423231 23.46905219 35.11265371
-16.45490251 32.2115105 37.90142327
-15.16053488 32.17802495 38.04330427
-55.86891974 71.91489647 38.9900221
14.75806284 53.06376672 28.77299013
-14.88997277 32.16543165 38.13789876
-13.13590409 32.17261051 37.72004979
-55.76894229 24.37694208 37.29533919
-67.81071718 41.13396365 43.16983335
-63.29262875 17.87049574 32.68875575
-12.93684559 32.27042011 36.54664277
-12.9486117 32.42502254 34.75414851
-28.34761656 76.28184563 -5.299913686
35.41139446 20.42135769 36.69280789
-11.66766723 34.18417159 37.39494258
49.70318127 66.84442618 48.58530577
-12.57261442 33.37513545 23.65283769
-6.995719914 33.28748603 23.60678882
-63.9936021 8.668672976 17.28444861
-6.488760989 33.27953379 23.60242419
-58.51300692 15.96554737 30.58472026
15.6304096 35.59793024 33.2570154
11.17114534 31.80451197 37.35770609
-10.54028659 62.278026 31.51122724
-49.60119617 35.46787799 41.38017588
12.3334694 31.78807888 37.32681114
-53.20733652 42.36053634 43.62585279
-49.58533343 51.33256614 43.66078134
-59.60126208 15.98178385 30.60377208
-58.84756608 33.4402461 43.37518162
-42.65841988 34.4482445 34.41287262
51.57735274 50.34843969 53.2728325
29.57777684 45.52150825 55.23641791
-8.666831101 42.93589056 28.4525771
8.492254646 34.42804127 36.54594812
-49.85605266 81.55067586 19.21042389
16.1876771 31.75175387 37.01347783
-50.51253344 34.95459848 41.68585565
-69.37650648 50.62346915 44.01351039
17.66547063 31.74963008 36.75630824
19.99129928 31.76572411 36.1259274
-39.76958065 41.5086916 33.47210324
-79.19534398 10.83156784 12.55497185
3.526825914 58.19452333 23.79305436
-16.72570326 23.93433031 34.98771942
-71.75846079 85.46683893 24.53879352
19.81124888 32.89721877 23.02504623
5.470533042 34.95547691 36.8259916
23.06125684 68.92398386 41.17833794
-49.7798935 12.15023198 14.94381712
-67.73606207 57.06810594 44.6329523
-22.11404319 74.46872179 8.732732574
28.42556784 32.76403965 22.9282843
-39.91751171 57.72856043 31.66074798
-13.28447909 59.45301669 24.04284355
17.48840731 28.8256519 35.77390242
-50.14629566 82.45373979 20.43563179
39.3849384 16.23277284 37.94605211
-55.15949873 51.896277 44.00648196
32.49273734 32.69462156 22.95850647
33.00038892 32.68631991 22.95806589
-39.89742453 48.82720632 30.11079583
-9.16968289 42.4519439 28.33983904
-12.12834133 29.62885484 20.44454386
36.54297139 32.63374641 22.89278569
-46.87059762 19.69111596 25.90180924
-37.35778274 54.43326552 28.64029387
-49.75519922 60.42670503 37.17466857
-49.50543346 30.01242542 34.77309641
23.29128316 45.47155138 57.01522601
43.13963669 32.52724004 22.87116576
43.63340051 32.52594394 22.79204804
55.56428055 44.02700903 61.80323355
-22.42634633 42.93895287 31.04105007
-10.21050885 70.48736006 23.54800773
-2.465808046 37.50458602 26.22750154
-73.20319735 30.8050511 41.74430772
-3.548646895 38.96279374 26.9859888
22.32969695 28.86129308 34.43689032
-51.80445423 19.78316184 31.60083136
-45.69986452 43.79012809 37.25166926
-59.47342429 40.79363783 45.53061243
44.38988551 42.27151291 55.18013233
-74.80752549 55.00215117 40.83142845
-76.78325203 6.383314329 11.29377632
-69.13773259 36.18653567 42.58984879
52.82602481 51.67120794 55.15896696
-49.6783733 86.45177059 14.7208222
-51.89431364 37.41936935 42.46971497
-40.17754114 66.34784661 30.70426771
-67.10098774 78.57749824 33.70739088
26.32564718 49.03553014 50.02313237
-36.02916328 61.18386667 25.76734444
-16.83003001 48.7061136 21.29079567
47.11751765 46.14554582 50.4738407
-55.58311207 83.9030426 22.12782348
-53.89460231 30.65403668 39.81512813
-49.54279907 24.50689789 34.599354
-49.45785838 57.86474546 37.72585599
-54.22899966 21.22440124 32.81217079
-49.49302854 39.80270983 37.65078251
-70.29115116 39.68921078 42.93468991
-49.61606924 83.82826925 10.20461259
-46.64758393 50.30144771 37.59058649
-8.885596049 60.27691545 31.11945594
52.10907741 42.80141315 59.20984982
57.89911336 49.12173693 48.82777227
-62.15124441 29.62713479 41.6574644
-74.37617718 33.33793952 41.69758919
-50.21327215 70.95653371 37.38354847
-37.57965288 68.55767887 27.86205232
45.48847617 50.47135308 53.0071494
-57.4187068 56.31696535 45.5585182
-54.51332231 83.77223155 23.44235854
-73.58508243 33.50876966 39.56360135
-50.76853414 31.56822351 40.25970209
-69.19933005 33.2043118 42.26158158
-63.65010167 33.03150689 43.20935608
-49.59708726 82.00325166 25.56049028
-75.38364682 11.70388859 19.18144613
6.209231699 38.90363958 31.63844913
-62.43531891 33.01338935 43.18801136
-40.44747568 65.54911309 45.85467078
-62.15840262 76.3053992 35.8344309
-61.41547885 32.99449745 43.21283271
-55.79940555 33.03575178 41.66290323
-57.31319446 23.35860257 37.75818389
56.43403956 69.43574499 52.17961671
-55.63356447 33.07598403 41.16423089
-52.69551112 20.7415223 32.29869553
-52.67866002 33.04730293 40.93366397
-49.50532729 33.08188266 39.92706345
59.70917223 67.78843533 53.19827955
-4.369290726 69.22570513 37.08026573
-39.38224993 52.60983726 32.71413591
51.00114423 56.74668759 54.85351952
-49.33155044 33.5765021 34.15201882
-63.58778833 83.55524807 27.69181698
-49.7155819 61.98770208 36.52584294
-53.63007905 64.69606789 40.79152011
21.62733948 27.43638641 33.63226793
-50.09471348 12.40149252 6.2603972
-47.81190815 33.56565701 33.9881116
63.68933796 56.67125619 59.13610927
56.41577069 7.467507723 49.05215309
-34.00142302 50.11001222 31.57456875
-47.30307905 33.55676138 33.9943419
-69.69624438 10.83527774 22.35365261
-43.25571953 33.49733625 33.91233633
-40.25520765 69.11630455 45.19392848
-49.77429034 12.9469558 11.52043739
47.54816933 14.39926935 40.19399934
29.90286154 22.22894934 34.23939731
-1.282031511 38.23398494 29.18763555
-14.5475744 32.64359507 38.34837226
41.56818928 29.78631121 20.02970659
23.86820026 29.3679276 34.0887461
-14.04719571 32.63896216 38.30672919
9.918797997 45.95504093 59.77938099
-49.80237581 11.9071355 11.9435047
-41.65332687 20.032654 20.94202077
55.94118077 51.10902911 55.26442924
39.63723067 16.70683375 38.22133434
44.07630934 67.50905799 47.7694937
8.784458912 32.42044453 36.48930758
-60.97076706 41.81005511 45.67013292
9.680145785 32.36506915 36.96133258
10.01032116 32.34718233 37.10600944
10.29639589 32.32676383 37.28848623
-40.24691358 54.6098006 44.6218457
-55.01419168 35.99984394 42.06365863
10.67787554 32.30777489 37.43617325
54.1307531 7.653827613 47.3249821
12.20021114 44.07527043 28.72627656
17.17722259 32.25035981 36.86322474
12.54921226 41.6235295 27.98811342
19.1961795 32.29736322 35.93254761
-54.14468637 45.85332898 44.04421127
19.57573703 32.27934388 36.0693454
-20.55175122 24.40911071 36.0324133
-70.84327122 21.23040371 35.91093154
-57.0963978 13.69381913 27.55323879
-12.23568344 83.69081575 -6.98167523
19.82410682 33.39294872 23.0944399
20.33508315 33.38296993 23.11283475
-42.41736099 52.51219181 40.25313158
-37.39697805 41.82330305 29.36739374
5.348017671 64.01138782 60.79887601
20.83857879 33.37676471 23.08884989
-69.37152969 17.96359916 32.76722619
53.98701029 47.9283972 45.94706914
27.46225269 33.26902302 23.07641963
28.4499585 33.25395202 23.06301336
29.46252567 33.23872887 23.04663222
31.49662992 33.20375777 23.06468633
-15.62169326 32.18765139 38.01949923
-21.59032086 23.96001241 35.61729472
-53.18815753 38.90968307 42.8957322
33.00188076 33.17539447 23.10688795
39.61261352 33.07418082 23.02114342
48.52163866 11.64491007 42.84976151
28.84855847 31.79401239 22.45511998
-15.7579229 46.75776232 26.22430009
-51.86209595 37.90533345 42.64878576
42.17640524 33.03218119 23.01977524
-18.92081514 48.57811295 29.002088
-65.70497678 30.16277092 41.94376827
-59.86214195 33.45648252 43.38018303
-13.57493181 30.78831453 36.39377012
-49.4906809 38.2453218 38.24970907
52.09019966 54.10746695 56.15062523
-68.54029959 40.65288883 43.06699576
-50.1294144 54.34776256 43.72178567
-67.01372217 78.0312619 34.20521818
-60.33529008 33.94959525 43.57264375
3.073670012 64.24188107 58.55687352
-64.3205059 82.4248716 29.30050402
-27.91565959 46.49793491 31.55912052
1.898523128 36.91359964 32.25579963
29.87559527 44.11240693 54.05757573
2.385846264 51.22723763 52.45221993
-58.84112677 27.65818409 40.57665806
-69.38389242 50.1249789 43.97511668
-37.73329137 75.63662586 44.76666707
17.65974706 31.25030125 36.72733225
-77.3296678 5.258071044 12.80734483
-70.32744277 61.1541397 44.30660927
-70.41257296 28.80081977 41.17213528
9.169473095 34.40853673 36.64322147
16.05365646 49.51415056 28.94596141
45.25224227 11.88178946 40.72338585
12.14212547 55.15332377 28.32132541
-49.52974956 27.53838919 34.36494732
-47.57287641 86.90112107 9.102902137
4.542168048 57.54513614 19.48470054
57.81142893 8.436914814 49.18568949
-49.623524 82.74180415 22.81851611
49.86471047 10.53286839 43.84973946
-9.587263805 45.41571745 28.97367331
-55.38457872 59.54305691 42.67961727
-43.90100322 22.26521619 24.58665992
-16.41342716 42.07292414 28.29456169
-49.59433604 78.46157381 25.88786072
57.93320116 70.45205218 51.74896382
-13.26781828 42.02237749 28.28145753
-50.45382493 86.23588264 17.37489081
-56.02061776 59.3683439 44.82911027
-74.7098954 33.87221844 41.3855724
9.247104329 45.13225464 28.6724895
-46.80070579 11.01055899 -1.527383049
-74.57668208 33.91652055 40.84587656
22.9873251 70.17059188 44.20082468
-74.49221909 34.14647036 38.16034326
13.7776421 51.55612582 28.98183543
-37.82207864 66.77021564 25.35189806
-67.44194203 33.66247657 42.43436706
-67.11309424 33.64525944 42.57152283
-62.39620929 33.49587688 43.40612642
-61.40810808 33.48060643 43.39496012
-58.28951645 33.43129947 43.37261724
-56.15501418 72.43754095 38.80398442
-55.88653162 33.40847277 43.17934252
-55.74805047 33.46250649 42.52567033
42.69384041 68.01486789 47.98796928
-63.3993867 64.14154098 43.2652983
-55.73317549 45.76262503 45.40010364
-60.45506679 55.85974206 45.61871092
33.18584014 30.32946916 21.14953809
-55.66218663 33.56786039 41.28627048
-20.72313163 22.47430897 35.21912359
-64.89902314 17.43527503 32.22082344
-70.18783457 34.71404485 42.40393497
26.23210633 69.99568509 45.61247136
-75.79265157 6.602172772 8.564193909
-54.67483216 33.56535555 41.12705403
-54.16527577 33.55609304 41.13740458
-9.496530992 30.04237095 20.9688861
-51.55489273 33.5153687 41.11234669
-50.61032939 33.49728267 41.14216861
-70.12793372 22.62398487 37.07672802
-60.36563374 18.28377576 33.15954508
5.827176877 61.48160091 60.94190578
-72.66525694 37.73816132 42.73008864
-49.72074081 11.41329516 11.83415459
-49.49746394 34.02613347 34.79065142
-48.27844141 34.05543293 34.21804697
-39.85015107 60.69641714 43.80798429
18.41610574 27.84880795 35.28362032
-45.23776148 34.00826102 34.18577694
23.39987669 33.82585017 23.21372415
-38.08111736 68.01108776 28.47627233
-44.22945414 33.99518671 34.14526291
-51.07339621 49.3062282 44.16120997
30.09732941 22.20518537 34.47818072
-43.20466158 33.97379656 34.19814119
-41.20567882 24.58122374 26.31994421
27.36001039 68.12975895 37.92519999
-12.99035367 33.23897023 25.31320858
-42.20168771 33.96341266 34.12741199
47.30055105 30.12245378 20.86096358
29.53606976 45.04033098 55.00358769
-35.83470121 34.64176772 25.03834738
1.62330153 36.95332124 31.84716867
13.49190036 72.82663337 55.96487926
-16.57174856 33.20055091 38.09548546
-58.63653696 28.13153022 40.86933517
-46.89128186 42.87301961 42.29870421
-11.52489625 33.21922037 36.91629147
-14.7082209 71.87750485 19.92121424
-56.86548671 22.41327377 36.9935881
-65.39185979 28.24373637 40.85504491
4.949901713 33.02340662 36.04759749
-12.19516689 41.02196906 28.03708581
25.17761592 32.81665659 22.93687236
15.6937839 32.25533125 37.08841335
10.07260454 32.84275793 37.1677693
-22.08715683 23.98763608 35.39136858
-70.93794028 48.65892041 43.81063709
-12.00323652 80.48112928 12.75445927
-40.14526723 70.64662879 27.40783869
17.15593255 32.7633154 36.73916291
53.89900011 48.47500916 45.44502349
17.64110743 32.76635192 36.61138701
-67.73231605 37.6532363 42.77521876
18.02637511 32.77023 36.49289457
-71.82383748 13.47231429 27.10659878
-36.96565559 50.86861552 29.16008546
-49.7899922 10.43713739 5.699389288
19.8409129 33.88668708 23.18620065
-10.00252295 60.54300475 28.24350135
-50.60596846 23.94631943 35.48306995
-59.95590802 78.44212778 33.91626053
26.44111075 33.77839874 23.18459336
-14.18156982 78.64286489 22.85651496
-39.54323934 44.33347839 35.59659772
-72.98269511 47.69483205 43.73914364
31.0111525 33.70308989 23.18729902
32.02001396 33.68973605 23.14992423
36.37776143 50.06777316 53.60302682
-71.78280269 72.18704067 38.86564656
28.62584723 22.87978646 32.75417998
20.59780674 74.46545016 53.06508119
36.15080623 33.62539082 23.10912373
21.32571859 32.87659401 22.97565491
-49.46801641 30.21655143 32.39631675
-49.44351199 61.59602142 35.19423143
-4.909346056 67.29104416 36.33565027
38.51693369 33.58398971 23.13850338
-39.32561764 44.42192395 34.52835516
-18.71782672 22.46625436 34.93020366
-44.70033564 25.94282469 28.65986184
38.77954468 33.57540727 23.18805308
44.11078824 49.56721459 52.11250023
41.64110987 33.53186567 23.14779812
-54.91738056 29.70714545 39.34910063
-68.05234399 45.12491115 43.49920894
-49.49856254 37.4745252 41.37254123
-58.29160588 90.86802736 5.88277055
-69.90063689 20.74824151 35.50181526
-66.533918 8.30811476 16.12788392
-18.5290668 58.36860494 25.97837946
-50.60536062 53.33563868 43.90874238
-6.681715988 41.92119953 28.19999589
2.847193257 65.96188469 56.112929
24.34912623 48.8861278 46.3077966
-74.35206882 58.18880098 44.53810033
29.11388483 46.51216783 55.47786727
40.84224227 30.67377242 21.51913362
-62.34442645 28.19315777 40.86103433
-31.57841005 54.64658245 30.88843553
-73.03085108 12.21525428 24.44973033
-13.0357567 63.29572345 25.99959365
-13.245927 32.91523154 23.29350168
-49.4773898 32.55891489 34.33944453
53.91871152 66.50194605 51.75713874
-58.38489619 43.251177 45.92731987
-66.05723895 65.75002558 42.57962614
-16.0784744 35.68580409 38.28398691
55.94346482 5.976109332 48.97554292
40.97362222 49.48961304 53.61163231
-29.63908571 44.565546 31.0138053
-49.84019505 11.21911527 8.284475608
-51.17836613 9.414500093 6.182398949
-11.17851506 36.64006728 32.09839161
-55.79175376 43.23153847 45.66077046
-50.05094559 16.11978497 27.18045114
-9.483980428 59.17122831 26.58927122
-37.4387716 68.43039795 23.48611366
-74.96719627 34.36794934 41.50647607
-7.163545651 68.83791806 30.46157934
-74.79562266 34.70262413 37.5886062
8.402119282 47.88653268 60.95301862
-0.05629312045 50.90960764 44.95194824
41.13962031 33.04723185 23.04277736
37.52441777 19.84436057 37.16140453
29.43889693 32.74843215 22.91621961
-67.15886832 34.14578792 42.5963932
29.74068525 23.16652261 35.03957242
-63.37752849 33.99755331 43.59608468
-61.8581063 33.97443032 43.5747503
-47.37398525 33.07792441 33.73991221
45.84999368 44.26239658 55.09661388
-59.52015917 33.93485559 43.5883021
-37.68501835 64.99620592 28.43983718
-60.81821622 28.16661062 40.87815561
-70.58793881 83.11529428 28.30745152
10.69598436 53.13061668 28.77160971
55.30807562 66.44531825 52.14955551
-58.30970552 33.91892183 43.5424321
42.51146191 50.96384759 53.6842892
-71.83975785 62.72988135 43.78258034
-49.5368467 34.02788083 40.60451917
-49.49369242 34.13000132 39.41080003
-55.83000313 90.11519253 8.326143757
-50.60516316 20.67817957 32.63538225
-49.62647541 33.29166185 37.5148968
-49.8182292 65.68554196 40.23132211
26.04364098 36.84352775 22.637984
-48.7423772 34.54389857 34.46269751
-22.3301128 75.00559859 8.368121505
-45.70484886 34.49831646 34.41257307
-43.17313655 34.46011008 34.37328793
-66.35703718 51.41798383 45.8676553
-54.81838722 31.62890649 40.32758109
46.20101642 69.41246937 48.57467464
62.06761297 8.409357222 60.34720188
-53.41604798 13.56509121 22.5190999
-2.951673652 50.82634099 28.99080945
-15.51870239 33.64234666 38.59261662
-40.1517523 55.60339619 44.72259691
-14.68573558 33.61860985 38.70931917
-62.56084222 39.85995424 45.30500029
-72.25642117 25.97430034 39.37622288
-74.82136944 32.31030209 42.05877358
-42.28377093 55.30943255 42.71508892
-11.15088249 81.95426083 12.97064676
-5.999457918 82.25948185 -9.034915347
-58.85128125 80.63710864 31.53122495
-11.13341309 30.52785012 21.47189378
-63.88086438 49.33698511 46.24662275
4.5144833 33.54704264 35.87852614
9.416554527 33.37857813 36.89933092
-23.12680132 58.11099319 29.84574087
9.694548262 33.362236 37.03602717
4.160496191 54.26025057 28.55763367
-58.39945113 42.75630308 45.84831227
40.17009457 34.54739122 23.29264963
-22.76102827 21.56870569 34.46738457
-47.31371208 42.86310998 42.49430244
13.69762092 33.27559528 37.27840602
2.18243351 45.27661927 28.34387448
18.44543352 33.30883439 35.98710562
-48.61902287 46.82073248 43.41338995
-44.5076083 36.90101663 35.42515965
-51.41157221 36.92141737 42.3316023
-48.92805755 42.12790693 39.68365911
-25.95154387 88.26319145 35.78080867
-49.37741746 38.91340381 36.29916609
14.67953803 33.27583326 37.0883858
-46.276513 72.50244478 30.34000163
-1.668169586 58.56882336 49.57183187
-78.45664011 8.977065213 16.46259444
-12.0164695 70.87802544 19.35728575
-68.64914737 35.18341982 42.48829171
-76.49758812 10.46182376 16.33275332
20.28514274 29.40375159 34.35618571
23.24198964 71.2514425 49.08487924
-49.50924691 28.70592384 32.46072445
-49.40390118 61.07382409 35.4220843
21.44619264 34.35963684 23.21635907
-11.18512671 82.23263143 21.39893135
22.90269338 34.33108722 23.27002045
24.92619997 34.30146201 23.22803665
22.88726346 33.33443865 23.18950519
1.241616411 44.75343274 28.77018426
25.941988 34.28461413 23.22990232
-21.82759332 51.42236836 31.49808119
-18.33624756 50.63898725 28.27303802
27.9742567 34.25056895 23.23755772
28.48443648 34.24099197 23.25143991
-9.413056716 30.50247914 21.43833635
32.03335675 34.18522143 23.22206474
-28.94878234 45.51909508 31.46594846
-68.65577323 24.96233776 38.78387606
-46.65923892 45.76926382 43.59258124
-18.29386915 30.79006527 37.27337606
-65.67939839 67.31385398 41.83343081
-49.56793019 78.67791324 29.19804032
36.09228364 34.11996125 23.20559074
-52.6119429 50.84564644 44.06385228
39.1472323 34.06559162 23.25415608
40.16191207 34.04930278 23.24974327
-45.45187401 20.29533904 24.44362834
-22.93049084 43.44770144 31.0579097
42.80523498 34.15301829 21.5416402
46.26294609 52.91387105 53.63812671
-68.03118518 52.09570643 44.1460675
-1.388893902 29.91700431 20.87805842
-65.57065621 78.54887214 33.74786024
-39.26219479 57.28076148 42.56079483
22.59002828 70.17278179 44.2511695
-41.05963198 80.03718877 35.10255898
50.30159745 68.82398112 48.79762945
-16.17771343 26.02511589 22.2651293
13.72579798 44.04903774 28.7398662
41.91063925 16.60070348 39.01981941
-16.86634098 26.29335315 36.76248579
23.8305542 27.83071403 34.46109983
-1.130992423 37.35158544 27.74908687
38.52759455 43.25312252 50.72950582
-49.34755141 32.1398702 33.35261904
-47.37621204 39.87468242 36.41158236
-14.99540394 35.13280017 38.67047112
-0.3687823723 58.26024934 52.90618397
3.222724669 36.23895935 34.00834265
2.854996559 65.40617665 56.73586454
-49.66373892 10.42966473 11.588702
-51.61845478 82.53401386 25.61114313
-29.98938215 86.01161531 39.38222467
39.26146931 17.7273702 38.09914417
-19.30119242 27.78934704 37.34016051
12.78541158 50.05444227 29.12377572
-49.48490272 38.34151847 37.13188582
-18.86031813 31.33024275 36.93726767
14.15933482 45.37028682 59.93229673
-54.35359371 66.79734594 39.84289299
-39.02468103 62.25651469 31.36645015
60.29678007 40.37901553 62.46280222
-49.53183551 22.68406722 26.62480623
49.6245745 65.7986541 43.26045857
-74.95901377 34.86603778 41.54938243
-48.03738736 19.36291176 29.93435763
-74.78912615 35.00334021 39.92307672
-74.78744015 35.20071256 37.63151256
-74.79771154 35.25544958 36.99804405
-76.17024304 5.689101863 7.582514406
-70.70591064 34.72760503 42.34531865
61.85247032 48.96106342 49.939059
-67.46961995 34.65609264 42.55830671
-63.04693991 34.48167408 43.73965318
-47.67060107 50.82415828 37.54431714
-39.63951884 70.58493044 45.50755452
-13.95307748 55.66771542 27.32640323
-59.28929308 34.41972474 43.74220048
43.51033119 16.03487313 39.45667945
-55.79200396 11.21187045 21.15690825
-18.36278745 48.14129492 28.13993018
-29.10782077 76.442323 -7.017876888
-57.76169753 34.39247878 43.76716985
-53.95745413 25.40914398 36.62060629
-61.45680304 55.36728563 45.69990079
-9.605559093 44.92273032 28.87347587
-49.49392254 49.95325166 42.17551498
-57.18628455 34.3871623 43.71915279
-13.04119962 33.12926976 38.24967221
-55.8648487 34.52585634 41.85708505
24.47210934 33.31548006 23.10735067
-4.99738263 48.84591296 29.06462878
-55.10800485 34.54051906 41.54253486
-54.60080349 34.53244452 41.53954365
25.89889255 73.01028184 51.46686525
-11.87477489 69.37423437 19.30744518
-48.68393016 50.83976578 37.55638183
-58.1189848 79.5110549 32.81034374
-53.07788338 34.50755703 41.53802571
-19.20334792 22.46339254 35.05601753
21.38917888 48.78680454 42.19865173
-49.48424306 34.577895 40.0361623
-39.10704467 70.06189123 45.65118841
-63.8658862 17.42206457 32.17715469
-12.1945753 33.65041527 37.86501927
-55.74540283 10.78525833 20.27380495
-61.2067918 21.07716218 35.85213652
25.48237157 27.28814519 34.61798257
-49.594135 33.80234191 37.40702339
-49.74792354 65.17263874 40.3454284
-48.19831736 35.01944714 34.6650682
4.901037108 50.60269684 59.2226843
30.53603782 35.21401561 23.21790057
-69.3081373 54.60670915 44.37324227
-36.73469592 37.53916732 26.53469831
-46.67757912 34.99566028 34.65118951
-42.82516229 34.9354608 34.61535
-76.97430493 5.828806068 11.94070706
-74.13650788 56.74686014 43.75617031
-41.72444001 34.92532431 34.5231074
-44.19025311 49.56888673 33.9727789
-36.46602013 35.61777286 25.48184556
-49.50305407 37.39579841 36.46067415
-35.96520853 35.61292158 25.44265501
52.18618799 39.99699782 56.79099849
-16.87680963 24.38222235 29.81707781
-20.0534746 24.88297635 36.26305176
52.52415396 57.94918764 52.25685561
57.0783249 8.583721741 47.62125462
-16.82629199 34.20949579 38.08474167
-33.46212558 51.61717303 31.45542147
26.93449559 35.78615792 23.08953165
42.0004138 68.04254849 47.79887323
-14.47800421 34.1151491 38.73215439
-77.06770219 7.785672141 12.54835262
-46.65594271 42.90297206 41.90611366
-47.55717215 20.87509821 29.76813642
-11.83596168 51.53597493 28.27378861
-11.3412061 34.2177141 36.94329857
5.452075639 33.96035553 36.72832499
40.97644014 68.02357192 13.2545942
-55.85078443 14.62741464 28.13111669
9.741636486 33.86547666 37.01170325
-76.20931172 7.848222013 11.6585277
-43.27485557 28.73260755 30.96202446
4.648027715 36.54252924 36.0391092
23.75140744 37.91403044 22.30123189
-29.43320246 47.02232591 31.58765244
-64.56359031 12.48502837 25.52987954
44.13526586 14.96297705 40.12757098
13.67470314 33.78937197 37.14512267
-75.23891024 30.96472294 40.27894295
-57.4095031 70.85753948 39.90511062
-1.475377365 50.78780569 29.15661683
14.6580402 33.78889367 36.96314675
16.60642819 33.79716136 36.49560008
-12.1003844 33.17501907 37.5391616
-63.92731592 16.96322513 31.68877177
45.4996178 46.51567921 46.4856047
53.25297044 70.15506238 50.26254508
17.08952509 33.80124609 36.35605204
41.04021709 45.08474277 52.29411288
18.00265412 33.81140574 36.06397218
-58.91653581 30.53607466 42.14223058
-49.47718019 52.7036648 39.37672306
28.4055303 29.95907408 20.53434723
38.20710944 46.35375089 49.75611248
-58.22935784 13.26755734 26.89101799
-1.036594147 38.20928772 29.4275325
17.63301668 34.87747979 23.75869665
-60.79594077 17.37251888 32.16686018
37.14840828 71.02560899 49.05448205
-70.21539514 33.22129944 42.25814629
-4.623725927 59.26683537 42.03244019
-20.88827698 45.66528216 28.2317149
-54.63504305 50.3728506 44.1115879
11.72284708 48.39853205 60.2027143
22.39494705 34.84165269 23.26647503
25.44276135 34.79088192 23.27462268
-57.0101044 9.371464592 19.44741785
32.14521016 71.07164105 49.47424821
51.58833518 38.87292362 58.30080472
-8.026399642 37.08179556 26.3693591
-45.37257991 32.56962088 33.4323496
29.5060866 34.72340303 23.28306639
30.03543352 34.71654629 23.26171477
-43.46806496 27.32342218 29.87779997
31.02848722 34.6987776 23.27860541
-50.36533278 37.39142458 42.50253244
33.05954378 34.66534389 23.27939372
33.56300479 34.65915613 23.25521266
-48.69128586 41.37570538 36.71732755
37.62279749 34.5934592 23.24364372
24.39389483 34.309811 23.23262932
-14.92683207 75.25551489 21.53498542
-20.89897843 46.02422931 29.89347644
-21.35944324 25.92674597 36.04855008
-49.52361381 49.34212385 43.44895421
47.34865087 66.50072157 35.5443781
-0.5861342659 36.77274354 28.53815761
-55.74717543 44.74047726 45.61532899
5.570022015 53.74079207 28.49244118
-50.63458317 17.92750262 29.61301252
39.5171406 69.26284719 16.62640281
-53.79605685 57.97842915 43.06009494
-48.67413822 50.3326103 37.61530449
-49.58832889 61.55184265 41.56135019
-49.64126209 29.18228124 38.60927897
-0.8045214466 30.37221754 21.30880863
-15.72132474 30.7312615 37.46541347
-23.90495198 53.48802285 31.22118459
-74.86006137 35.36789388 41.5312406
-74.80495876 35.46398715 40.40521128
-66.05871187 14.28175653 28.26384038
-57.16612923 61.96170971 44.07508747
-64.83812584 34.99543469 43.94377585
-50.57000446 49.30007539 44.1366365
-24.34394893 48.43953206 31.64506072
-62.29796312 34.95296558 43.95236408
-71.42074 55.17110517 44.05084821
-63.1812348 37.41404693 44.68398541
-30.96421354 51.57123903 31.51229011
-58.03630115 34.88454896 43.93386956
-45.27493166 33.52479516 33.97864922
-55.79450294 11.66579135 21.71458071
22.69462684 48.47517322 45.56733924
-49.68779065 78.42772363 20.47199888
25.92415741 26.77368611 34.679307
-45.82390257 32.57428577 33.46426603
-46.31112319 40.83739724 36.68584919
-7.200644584 70.36328035 30.24105826
-53.98793028 35.01044759 41.7002975
-16.0199258 25.3575118 35.8117168
-51.04616618 34.96361716 41.68292722
-50.01028458 34.94902218 41.6548075
-49.50552547 23.2225671 26.19512999
1.997894803 51.32382477 51.40495034
-54.50757844 68.38167021 38.96018901
-55.89371604 7.62857777 4.507370353
-49.49649393 35.08629086 39.96330928
-14.54155347 36.15607004 38.35833732
27.26609537 31.34671976 22.12276349
-49.49768797 35.38422743 36.50487128
-67.86888976 73.17458565 38.30837869
-37.75545807 64.92596824 23.44199905
32.58426127 44.03449358 54.44549193
-46.66676528 35.48003243 34.85282271
-15.50330711 34.6390127 38.67293568
-12.54404896 34.63291327 38.17939394
-12.18259712 34.64880504 37.9259797
-40.53754655 55.23291696 31.61703869
-59.51987335 14.1796425 28.20225885
-11.96517898 46.52944145 28.15151661
-44.18478059 43.26697747 37.20920698
37.02208133 18.84554899 37.19885505
-49.92280898 13.21343856 2.628592074
31.53625862 29.91603381 20.43694146
3.925692117 34.52637291 36.27531353
37.4508844 51.88793015 49.74858715
-70.32104165 55.12431841 44.384264
-51.98934258 18.87199503 30.56029804
6.996565092 34.94140857 36.69828371
7.992672514 34.43227236 36.59210385
-51.04694269 24.42320233 35.85780264
8.899329469 34.42091894 36.55099776
-55.93534751 14.15546676 27.79931787
9.552095629 34.38897127 36.79738317
37.22128597 30.27605379 21.00003891
-53.12049687 84.64968619 18.81724468
12.67156525 34.30205463 37.21147347
16.55781285 34.32390095 36.2167346
7.586937864 32.47941027 36.03316464
-15.67285511 31.71124349 37.73310577
-51.17517172 42.32654358 43.63291957
17.79755452 35.43297441 23.10537213
18.82342069 35.41104269 23.16433268
-16.80527657 25.34391126 24.46608926
31.62868982 40.69035855 41.00911499
-49.93354103 83.18886471 11.86119024
19.35793079 35.40158144 23.17223156
-10.43854894 82.14484453 -6.857560008
37.63812921 71.52856831 48.94901127
-70.20520393 33.71837461 42.31243238
20.34388187 35.3873956 23.14888438
21.36697742 35.3668615 23.19214874
-67.39356851 63.16970904 43.65546545
58.41610723 8.683662111 52.03259235
31.51160045 33.69842205 23.14604835
-58.26924522 34.40072802 43.76819904
23.39654477 35.334179 23.18450035
-68.48822616 43.63991701 43.34130733
25.93843912 35.29083637 23.2028987
27.46534201 35.26393981 23.22394403
30.00460427 35.22192492 23.227431
-9.752466688 38.06492358 26.93894479
-48.67587902 49.8312724 37.60889176
32.54462845 35.17952569 23.23523442
53.0847452 58.41639709 52.55288022
5.050727538 66.64765538 59.38505747
37.62575044 35.09418564 23.25692353
39.09637498 35.09567818 22.95914005
39.19249014 35.19586089 21.77781609
-38.56418098 66.11721037 44.7272681
-49.50706575 40.36156251 36.99253055
15.63599785 56.12975367 27.97319374
-46.88945169 58.45891208 36.16516628
-2.168447647 66.09998694 43.81296627
62.36711025 40.32577059 62.68608399
18.26225963 29.78661119 36.12408922
-12.64237186 40.33557794 18.61057533
-66.18203054 59.53860471 44.7904409
58.85652138 63.50038019 50.69999031
-70.2710442 60.12125791 44.63301767
48.3106774 12.17921001 42.51409028
-1.49680742 49.79418384 29.04210951
-63.62032186 10.74367676 22.25830878
44.49714051 66.94136676 25.14620472
46.75955734 66.24611267 32.78576628
21.88708767 34.85005916 23.2657384
-60.87610559 86.43289446 22.90206705
-58.16803981 35.37888579 44.04701119
-69.23191697 18.42016668 33.26707317
-52.08808994 18.4195912 30.00432256
-46.02845209 21.84505811 24.04323788
-49.25274054 22.74150315 25.90482281
6.413827551 32.97872255 36.28714338
-41.77326107 52.44744897 40.88188007
13.09881899 70.29083886 56.34399118
-64.30091893 24.41902867 38.4338667
-17.29353973 35.80317558 37.15317297
-67.5943241 26.36715999 39.75317247
19.15326072 74.45080255 53.51060503
-10.40699612 36.96943938 28.12766589
-46.79651728 43.32743251 42.83211642
-76.69417322 10.05877329 15.22250623
-64.12367528 25.8362678 39.4276419
-47.14982004 30.21171764 32.01033691
9.349992538 50.37785712 60.98434566
-64.77770787 35.96938945 44.27915795
4.528946771 59.28811319 22.56003628
3.933470101 65.71125653 58.81524452
-33.56944561 81.80520009 42.28297542
2.610411867 58.55474546 60.57259087
26.91754714 27.27035286 34.55083236
-49.35239553 40.87247904 36.85858288
20.86977253 45.45416754 57.678827
-47.51303482 27.38164186 29.97334351
46.8934332 65.77547561 32.39709289
10.68298487 33.30962958 37.45822021
-43.39442525 22.25745612 24.58013706
-71.56071246 35.76678837 42.09801764
2.665598763 66.00393266 55.65943654
21.88398313 36.39544008 22.80634355
-16.90000634 74.35442979 20.71845316
-59.73351364 35.40046125 44.09509276
-57.96031489 57.34682809 45.3596895
-59.22350703 35.39097162 44.10799394
-35.78343429 61.20857806 25.43361459
-31.09800611 45.61213717 30.79571776
-63.46998474 65.18158556 42.85844694
-55.82627003 35.3621606 43.79457973
-55.54626856 35.51825068 41.92917395
26.79490194 72.04942314 50.79705801
11.53006443 55.1647381 28.30554315
-54.96645288 35.51071329 41.90609911
-37.56374872 41.6844283 31.01135765
-52.50939824 35.4730005 41.87532166
7.769576488 57.65820823 23.38323253
-51.49357558 35.45613515 41.87738353
-50.9841231 35.44692504 41.88714547
4.304814008 58.84940318 21.86900474
-50.48152791 35.44117404 41.85805935
57.41184665 53.90079626 57.5349396
-22.96224569 75.62846543 7.084620859
-67.28427238 24.93949877 38.78745421
-47.10389451 35.96736468 35.10551841
-37.8407295 65.42164295 23.53074792
-44.01743753 53.65476575 38.94772509
-46.59960231 35.96075764 35.0860462
-23.24440913 55.00835609 30.92599993
-53.1063922 49.34064025 44.14943432
-56.44945946 7.823123916 14.00820363
-21.85880499 23.9715575 35.53447237
-57.37194805 43.23576167 45.91309695
59.19029849 8.088246646 52.97032596
-43.55951114 35.91388273 35.05044073
-71.48747409 46.17726905 43.5910522
-8.822737414 78.38414166 -4.295207361
-54.34752518 67.32127861 39.58618375
44.00967089 16.03076433 39.40915031
-15.73304665 35.15800732 38.518521
-6.961506872 68.23378543 31.60962119
-74.60587158 29.53518997 39.27335776
-9.645689209 40.08759938 20.91780651
-45.18605256 19.85725465 23.65189682
32.31228031 42.96237807 49.4633362
4.880535235 57.09523457 18.81631662
-10.8660202 35.82489002 29.84868738
-37.73647377 64.81728037 24.70010698
-14.24429649 63.45935891 24.33046933
-68.95320533 17.49814178 32.26417716
-71.27756131 21.70391485 36.32353129
6.503424666 34.9423902 36.78093307
8.983187724 34.93038914 36.44734442
-49.78808691 12.20392079 14.32212075
-72.3045963 58.14708638 44.63188855
-71.79219941 32.75876335 42.10166798
11.13937269 34.83383554 37.15701179
12.17818653 34.81776142 37.14550386
45.6365793 31.09903625 21.49469281
-49.79618218 67.82529283 32.86724938
15.53022418 34.84891758 36.14456654
-60.38408348 64.60938615 43.08581456
16.0785716 34.85725407 35.94321734
-69.74473235 67.38234614 41.81360737
-9.130134352 43.92625315 28.6973619
-14.19523508 58.20756831 21.19468084
-39.29677031 51.08484763 32.92110441
21.80138787 29.88444727 34.31340332
55.45274932 6.527639464 48.49320184
5.947067508 57.42817283 61.3610662
44.62339197 43.78426006 55.05445099
-3.474081556 48.82083331 29.06526906
36.36520802 21.4402043 36.33667659
-10.42640056 47.42665288 29.09585919
31.57610835 35.71191251 23.06624308
-63.67443605 81.8445301 30.08768159
33.53666751 35.67687378 23.09910789
-44.0506361 30.63724995 32.30605524
-6.926723085 61.19187365 37.60430822
-56.34646925 54.26277791 45.89397946
44.85997158 13.38809206 40.79185703
-22.50069247 19.66390719 33.2234643
35.06073051 35.65140978 23.10406463
36.61294999 35.61174249 23.26853387
-49.48198166 75.95003219 31.71567945
37.78770724 35.5890376 23.30807556
20.8423505 43.96135226 57.53382312
50.79736337 11.5623893 43.37372853
-11.87959099 62.30274269 25.65306936
-49.18161664 50.3408246 37.61672608
-45.83080607 40.34066818 36.53400235
8.626928966 36.79019366 32.40523889
-49.48957432 39.65230026 39.3961882
-52.60948234 28.23802518 38.48366807
-15.58367495 44.03489514 28.66689527
10.38074429 48.34459682 61.08478104
-49.22429863 53.37565129 37.35422804
-2.937691418 52.07242733 37.82922838
22.38193426 35.3504329 23.18930556
-56.29237872 48.20890014 46.24180481
17.31972237 72.87795194 54.63914705
20.05533803 71.98023487 52.88552157
25.08066198 30.45359541 21.25429613
-59.84250836 20.12786547 34.95880664
-24.62908942 57.62325701 29.9675928
-58.35333076 74.61376726 37.26655661
-49.59796312 37.19589587 38.79938883
56.43435105 45.61509294 60.68159128
-72.89880506 36.29976874 41.99261564
-68.96524082 20.26973058 35.05169269
-71.73857202 36.2596126 42.23751406
-70.15663757 36.20495587 42.57032491
-5.505198222 49.35651337 29.06069095
-68.63108536 36.17874066 42.58371834
-65.80416281 35.99161817 44.21686196
52.95232301 44.27913937 59.3744361
-60.71815759 35.90381482 44.2662156
-55.66224755 45.37329718 44.07953797
-77.29706108 4.888073986 11.26968126
35.94463275 31.68336227 22.38636332
-64.14699471 38.41009064 44.95864113
10.19205952 49.90925841 60.4369532
47.59085269 63.71879783 38.65958588
46.25370712 10.84295686 40.93864565
-5.735457902 59.53030414 39.18591595
-52.98729107 35.96850665 42.04090266
-57.81521324 47.2293124 46.25070615
-65.99185317 83.02684783 28.45770134
-51.46426706 35.94356675 42.03997332
-50.95595745 35.93493317 42.04326059
-50.4530852 35.92904241 42.01574408
15.30457963 51.5292118 29.00307696
33.5225921 70.14051478 48.3674846
-49.86112298 68.84436475 38.52942691
-66.21684292 12.07344486 24.79648546
63.33143785 44.35260965 62.36882145
-12.50274227 74.26716565 20.89289438
-45.97920512 36.4349653 35.28942501
-55.94565734 15.04121023 29.17220934
-45.04737367 36.42330176 35.24711805
-42.50973915 36.38210797 35.24138351
-48.59660458 72.6387828 35.0263874
-73.92224315 52.1931656 44.13815039
-9.258081611 56.5461956 27.88628225
-57.55705804 81.73201727 30.22719196
-16.77091673 35.76254389 37.52518811
-50.73284641 26.77583946 37.61997852
44.0858886 15.49010098 39.84438905
-62.81937081 63.09230377 43.68171539
43.87877416 46.88729757 42.48069151
-72.70198071 25.52339777 38.86895892
17.42659366 34.88342553 35.38232394
-11.22164013 35.81271534 35.88448062
29.54820346 25.17337097 35.08588308
36.16990691 44.00975297 54.04889482
-11.05526597 36.07567938 32.80007314
35.68623178 42.03999259 47.87434516
-20.88766991 46.66940798 28.22825556
-50.18470673 15.68504353 26.42611509
-57.06003802 37.80182378 44.84168064
-20.88640306 46.61921322 28.81071146
6.985464279 33.94257623 36.64229374
36.06940794 43.96133734 54.63010466
-11.00723574 36.15056238 31.92161674
-20.89032208 46.72030155 27.63795156
-59.42384168 55.33289106 45.71148023
21.91654869 33.3533276 23.1553496
-17.31656033 57.16709968 22.21517385
-38.00931659 67.76332781 19.68547466
54.41644019 42.52948787 61.92652945
-10.98304746 36.18791654 31.48336956
57.04555065 66.38653407 52.50061835
-37.88891814 66.10349336 27.27780301
-57.76847205 23.84270066 38.05188502
-14.03026272 28.85381188 29.80451757
2.761736202 35.79583976 33.41367054
-68.63693161 9.549993596 19.59222861
5.489896931 35.46031393 36.78842316
2.946711425 57.25972404 23.10223621
47.35407877 13.82151555 41.11136058
6.489961649 35.45139752 36.70121297
7.002578025 35.45298035 36.58507927
-13.46142518 38.55497284 27.78405701
-46.65004693 11.03367192 -1.824426503
8.439908114 35.45887905 36.2424954
-15.82718192 45.08782849 28.14339645
-57.80451776 64.04482452 43.32107844
-49.50420484 50.55976591 40.96325645
10.21844764 35.40415811 36.5385579
13.07519812 35.35065632 36.61484627
-40.68654353 53.3125309 42.28541614
52.09666886 70.74500707 49.46119101
-65.08411929 16.52421304 31.17911292
50.31532363 42.73991951 60.26579269
56.01109053 45.07860827 61.16357436
-51.75994391 57.94244479 43.08952879
14.02189365 35.36866151 36.22528827
-65.35938849 65.21789738 42.79723348
-71.75700259 83.71167674 27.43379689
36.49066508 69.249426 46.492582
-12.6631865 32.65936916 37.80589092
44.77912741 46.68090116 44.70498928
15.74517179 35.73826234 31.60605188
61.71918247 45.95398742 61.56624887
61.45307934 45.96433144 61.49691543
-49.48675606 41.46151988 41.6994729
48.10161362 52.37615877 53.70299745
-49.52550647 23.59285417 33.55366091
-5.746069145 53.24796816 30.54490663
-47.02269843 19.84883432 29.92654743
16.23966449 36.33126707 24.62771638
16.73738551 36.47664193 22.84518027
20.28270397 36.42268828 22.79540006
-9.572951292 45.91071366 29.05130749
-49.51014305 25.7178576 32.18870363
-33.34318113 44.74914894 29.58880136
-65.12862364 22.07769806 36.6383846
-47.75493114 34.5289586 34.44781973
26.37964861 36.32048288 22.81915105
-10.52243553 47.95253502 28.83599091
31.47465836 36.22813727 22.9195173
-61.67982729 18.30248446 33.1929855
33.04485957 36.20417713 22.89821734
-63.33738217 80.16785828 32.00751115
33.49916931 36.19800543 22.88322337
-20.15407475 57.14322339 28.86012021
-74.44826844 51.20630365 44.04139851
-38.11922353 71.76402189 43.18315234
-9.100712771 44.91584375 28.8571429
36.11909694 36.01618821 24.49425463
25.75142344 38.42324873 21.83508015
-42.79057159 33.00825915 33.67455287
-58.478144 56.84565875 45.44974119
16.14248843 30.26790081 36.76782215
-63.11845397 10.73829264 22.22510241
47.92562291 66.76144504 38.23431909
-21.35597518 75.28897621 10.71933466
-53.11784257 16.18018183 27.064196
31.39698948 42.90375321 50.31844793
-38.91894027 40.57071889 32.54527094
30.70096039 41.09066855 42.3655891
49.27486546 68.84634959 48.73376384
-72.08837952 41.2104515 43.09768239
52.32879076 56.2437545 54.6121029
-52.10603608 46.3182807 44.0845705
-71.76779142 16.17239319 30.71126995
25.84416815 24.85472756 33.66466894
49.23780851 44.0153378 57.31857623
-59.84901296 48.77077795 46.22402947
-53.62204712 34.03206088 41.33506641
-17.49940338 57.0858882 23.19280536
41.73213756 67.7910344 15.80994382
-67.50648903 38.14064737 42.9005697
-63.46736611 54.88816558 45.81866165
2.590877885 35.83247196 33.02100081
33.34437292 31.2539308 22.04076142
-67.44971678 74.24012613 37.51215004
-58.60849169 37.33737541 44.70199485
-58.7124917 24.32556167 38.45315321
-63.7313347 24.88170168 38.78083851
-15.05550304 32.65203648 38.34724322
-19.92558476 74.98865237 13.93292939
-13.78023012 52.57961694 28.18249596
-66.42452188 41.43248323 45.26668567
-49.77747065 84.22033884 17.33724577
-55.87643603 18.44747079 30.4031364
-49.76702609 84.16551447 17.97169529
-37.56131142 69.05064271 27.96251137
-66.17186831 39.44483747 44.98598206
-59.87488765 44.766102 46.10503786
-41.90610731 35.89750749 34.92522238
-52.95025463 80.28018339 28.72267123
20.33851051 45.97659529 57.54206019
30.98703239 44.52474791 54.88550403
-74.27553936 36.80268587 42.24357906
25.84409348 25.30742555 34.23607556
-5.529603231 51.87990438 28.90518514
-71.67539259 36.72995834 42.59198974
3.522746688 67.52420169 55.32750354
-37.13222138 57.53100214 27.59633568
-49.47259432 49.68805535 39.42339852
-70.65253946 36.70954653 42.63388069
-63.90672659 64.1496854 43.2675047
-0.9420595391 46.77360659 29.04153551
33.63827745 23.57336192 35.40003152
-76.16139238 6.5156243 9.639232988
42.46649217 51.99096421 53.42262758
33.50520041 41.74597512 45.87680907
-6.284180372 60.16065106 37.79967413
-12.59951118 81.60144166 23.16933386
-62.67234746 36.42546772 44.40980469
-6.468718657 60.204184 37.32950447
-62.21935377 36.41995988 44.38735502
-58.71316974 10.24387819 21.29787093
-56.62922952 36.32563685 44.41625545
-21.69733592 22.98478527 35.30556963
-59.84574832 49.27134716 46.2390751
-54.46926602 36.47495574 42.27093437
23.8218955 27.33286581 34.41549569
-50.19269912 82.55148116 19.30982792
-49.51232514 53.0997201 40.61236754
-49.47495809 52.95698136 42.26225711
-54.93722473 19.39934982 30.82719855
-51.94132869 36.43865361 42.21026318
-59.99356044 19.19963026 34.10996301
-21.92647524 54.98776061 30.91374937
-49.47743298 36.5337681 40.63622481
-2.559864055 32.27543398 22.85620139
-49.50154844 36.74415576 38.19848991
24.26141405 37.90454082 22.31413307
-49.43192135 36.95681156 35.71654752
-49.05877516 36.96680421 35.52938426
16.0751771 45.89718791 59.27692246
15.02170882 41.57802718 28.04481769
-49.50819446 26.21909068 32.19629357
-47.54253918 36.94528848 35.48999927
-45.5248509 36.91859826 35.41505348
-43.44268633 36.88416143 35.41774013
-36.39521186 79.2204917 43.69384309
-42.99016017 36.87888944 35.39264174
-13.55870127 36.15583633 38.17361457
-49.42691985 77.29169769 21.9567859
-12.06246123 36.21874093 37.15803
-70.77186594 58.61946623 44.68249848
11.73665781 47.86457148 60.57204369
-64.39303648 74.18488299 37.57052549
-38.28771634 65.12198539 44.57456072
-11.53561125 36.29986624 36.11579458
-56.49068614 78.93079414 33.40926691
-10.72113223 36.95445749 28.36149432
-46.59967981 43.35202892 42.50904518
-10.18259669 36.98013242 27.96073901
-52.63121401 10.6447909 15.48391731
-7.035235561 37.077369 26.23172491
-71.22622589 25.95018485 39.45970873
-6.528242003 37.06939928 26.22755649
-66.13622829 83.60189256 27.63633639
-15.89664954 25.23911157 31.33604195
-2.479492268 67.76353863 42.04048467
-45.848762 19.29289067 24.50318777
-2.473990548 37.00649759 26.18459518
-2.103835832 36.99322141 26.26812403
-1.758769849 36.9430453 26.78479881
-66.84527492 34.11148787 42.9347691
-49.49151995 53.76488612 38.71330788
-8.068054837 69.0464109 28.21373729
-52.6090377 46.82645275 44.10790481
1.343966787 36.49290165 31.41868512
-14.24789211 49.34956828 24.98195727
3.464552937 36.19130486 34.51543277
3.626233553 36.15930226 34.85610888
8.041571428 35.98858739 35.99586045
8.418270372 35.99678789 35.82882428
-64.38110518 63.63898677 43.45989312
-55.80503122 12.02465115 23.37732326
14.39081704 36.05644532 33.99727648
-60.49802667 75.73373521 36.32743923
-21.81068731 47.8983281 31.61800208
15.04321734 36.27246076 31.36519456
-52.31904986 26.33043362 37.26643217
-15.46434606 35.14635304 38.60256961
-67.36414095 11.65441574 24.05303689
15.1620717 36.31161718 30.88797196
15.57806199 36.44866464 29.21769285
23.44582225 72.50660196 51.9551233
16.23202077 36.66389392 26.59444004
19.0749113 49.96087458 34.83717429
-5.386214063 52.66333795 31.43645351
-54.24902982 15.76541469 26.26819877
20.28025419 36.92614007 22.7780723
21.25112344 36.93195106 22.52546354
21.76019496 36.92293313 22.53306725
54.25421617 48.01660833 44.87209201
-68.24296805 58.08046353 44.63071834
-11.57326453 56.6981764 26.56349719
-40.14835506 64.2499263 31.74632843
-73.66021233 38.74893242 42.83934579
25.32688188 36.85820036 22.60434388
25.78563328 36.8497881 22.61451286
-57.22413681 25.72564696 39.39602599
27.8667329 36.81588847 22.61116629
31.88830667 41.06161323 42.47645035
-49.48522597 32.06065175 34.29850019
28.3752503 36.80715007 22.61563077
-56.08498087 20.54341451 35.24486805
-66.65046072 34.08754919 43.17551454
64.00219188 45.96690337 60.98092744
-55.88741 64.54630941 42.96051282
31.9363267 36.74524747 22.65512262
14.64354835 72.31336971 55.87695138
32.44307782 36.73740005 22.64958078
-59.64133972 84.04894256 27.03468039
59.28978948 51.93573527 56.68210313
33.97271671 36.70912335 22.68612609
12.62587187 71.26128836 56.82178089
-70.38386032 84.26455934 26.58030643
-16.73555429 30.74732323 37.47237691
-11.37471092 82.31494758 8.826216537
-58.21943768 17.78915637 32.66551453
-15.59507807 73.33596282 20.63942151
-56.34316357 43.71457387 45.98514111
35.08575173 34.634356 23.2527137
-68.63978735 35.68091427 42.53786895
59.5275733 55.33129957 58.00505426
-67.58334416 45.11132917 43.56743746
-49.52431627 65.20850922 39.8863743
-60.35764794 32.98121927 43.16524077
-52.64814931 44.33510211 43.90357552
2.207655254 44.23468649 28.78129897
-63.68777813 37.42178953 44.69070446
47.92724681 45.96008604 52.47237325
-49.2277007 55.41100938 37.03351814
-49.47634624 41.05316413 40.61133816
-18.66299379 22.94081042 35.23739457
-14.35170785 29.77927437 36.60229875
62.82004728 52.31726129 57.40647024
6.448395543 55.83929255 27.27056835
6.440620115 61.06638472 59.81840988
-15.6563284 57.87249679 19.53643368
-9.915028514 30.50791567 21.47095411
60.84707569 41.32885668 62.98469015
16.56665658 29.29727956 36.30132871
-49.22694876 74.19084583 34.60904007
-35.933892 61.71235753 25.44070886
-74.68390579 38.26755211 42.79614337
-45.22713564 25.03068709 27.69580268
-5.704488553 81.83147192 -9.949157152
-49.93535241 9.487912059 10.91977076
4.808042809 46.74725119 28.25090909
-19.45011073 74.39525495 14.90420326
41.997421 14.09540281 38.95342382
41.96320344 46.45937091 41.98704636
-17.01695198 24.86267942 35.91959019
8.376852196 51.36523489 61.36100939
60.93047037 52.35366046 57.34427576
-49.73213476 77.72487589 22.81298451
-57.45202028 83.43345855 27.93551079
53.44285118 47.88078118 46.60360518
35.42914404 49.56091033 53.84132657
-53.78695746 59.00304895 42.81710696
2.600151396 58.05770523 60.51791237
-12.96429682 32.68071329 31.78889436
53.22293825 69.09144623 50.96222326
-63.769592 17.87570587 32.7192325
-74.72172613 35.63261297 38.43180643
-69.62804945 37.19052503 42.68159576
-68.24097448 13.86447088 27.69752635
37.82391096 51.09843287 53.01586899
-33.36363195 53.65068649 31.13669338
-68.60980249 37.17243676 42.6973918
-67.74300944 37.15641446 42.71808773
-57.33446041 74.59536453 37.2858843
-57.38149956 22.89084243 37.37467027
4.340912454 64.0486336 60.55856081
-1.406646932 37.39152542 27.33800337
-55.63085526 44.37780743 43.97664101
47.06392537 66.93688195 48.0153336
-59.14496694 36.85800087 44.54259248
40.90938717 68.88838324 14.88130265
-58.64302977 36.8525818 44.50977851
-73.78896995 12.75300647 24.17832912
-49.5010644 29.20401228 32.50363081
39.10042622 45.74779935 50.79343858
-60.3741143 45.2723698 46.14975822
-16.83400429 52.5838384 28.71586333
-55.75987452 36.83531607 44.16037665
-5.478661904 32.76333961 23.57551007
-56.23077911 33.88611845 43.52677383
-16.63416531 52.60692072 28.40979629
-7.541721782 61.65743018 38.14371319
-49.09152812 20.39114945 29.85213678
-55.80728508 36.98312117 42.45358839
-70.15617506 14.34167814 28.34963748
-50.08725944 28.20460564 38.39062349
-62.38423035 50.31740052 46.2331098
9.245521386 70.74219832 57.66576924
-54.95803356 36.97594753 42.37490784
-16.35501547 70.4582316 19.23130478
1.894496566 36.41341477 32.23643755
-75.61971413 29.6501679 38.13195579
-11.2125737 36.60769214 32.48072113
11.64149559 53.62665746 28.6595326
-64.71839462 18.34859068 33.23722387
-10.46295236 62.76600933 31.65825337
-49.61417058 36.93151518 41.87160425
57.6415233 39.97732676 61.805631
61.27456672 39.87912106 62.25283132
-71.8146604 57.14328919 44.53798336
-9.012867484 69.77745047 25.73411833
-74.88445032 55.84716441 42.68985014
61.31064804 51.88266798 56.91275711
-43.45835581 37.36950393 35.61315939
-13.98963658 60.49104688 23.78039108
-15.53925099 36.71556407 37.88023006
-20.63365112 74.64539111 12.22614799
16.54636031 45.9072824 59.06988082
-51.0503476 69.3765836 38.40447525
45.9975789 52.92384382 53.57296257
-66.61777393 31.63431408 42.51501998
11.30200043 48.91481748 60.11619509
-11.86435601 36.84402528 35.68813371
58.10348321 6.782942628 50.8505868
7.471461187 33.44298205 36.52262409
-11.67914336 36.97359714 34.14864754
-68.12950368 35.67128489 42.55233975
6.3159792 59.54827706 59.98555876
-10.63264898 37.48637251 27.99640308
-9.863107666 37.56896367 26.89086832
-11.9601568 63.07525903 28.35378253
-8.969723611 37.61386135 26.19928951
-58.15640947 36.35267316 44.3936405
-53.19363929 10.20328446 14.88986307
31.56775168 47.0154459 54.99412187
-7.027053059 37.57545743 26.27463127
-4.999009561 37.54354363 26.25834998
-71.94225568 49.17219706 43.870317
-39.14296805 65.86886246 30.24072508
-2.095739912 37.49135352 26.31053989
-74.80573008 55.10035691 39.69104193
-41.27834326 20.51915796 21.04945705
41.94449997 41.98695721 53.12317101
7.484977145 33.93838007 36.59574561
54.35171769 45.72409982 59.81333047
-69.49719151 43.65332327 43.37809351
2.268237651 36.87576766 32.62447417
0.3886380066 66.00608444 50.24204547
45.75760856 13.85872685 40.98384106
3.004782139 36.82633664 33.05784213
-67.59348542 22.58439834 37.05294287
3.177198933 36.78891827 33.45934097
3.608067753 36.69545971 34.46210705
-13.68619897 71.85751233 19.95839634
3.867731928 36.63880806 35.07024137
-63.2084762 61.03194177 44.36754235
4.371881065 36.55793962 35.91287708
47.60673727 44.94388712 52.67699333
-37.80256947 63.85284863 30.08189929
6.884867042 36.5044017 36.05514279
-36.83838814 78.21661518 43.77880901
-48.91408959 25.0817539 27.80610615
7.455163909 36.56361063 35.2590432
8.211348764 36.75205075 32.9272834
-50.06910628 49.29518043 44.09793646
8.433410478 36.76392302 32.74711286
9.668666038 36.90892315 30.82827466
-50.45117775 11.79507526 19.19475421
5.969914536 54.79128054 27.87462413
-64.65004012 38.41606875 44.98517662
20.72041554 37.45409928 22.39183597
24.78876234 69.65166008 44.0547779
21.21857773 37.45058446 22.33763594
62.85428776 44.37030295 62.25441998
-50.78144311 15.23606717 25.92532064
22.71652757 37.43829301 22.19465612
25.78138122 37.37892689 22.29933532
-57.84393467 12.81869667 26.20157673
-72.04896538 43.1994335 43.30717489
-55.75311602 32.96284574 42.50042218
51.45192483 40.32449338 58.95586067
-51.58830436 48.31375831 44.12743904
-24.85527877 49.95633724 31.61431548
53.93823882 65.05978117 51.01519441
27.3067949 37.35278156 22.31194394
-10.47969384 46.92654231 29.08503018
31.37510728 37.28278695 22.34864079
42.09254595 18.23859108 37.4512566
-49.81935075 87.62642884 12.76469864
-13.71287644 54.05228906 28.55372114
18.86708741 31.83726541 35.5098176
-66.15389368 52.92126944 45.85760082
-25.53144775 56.07623825 30.61867817
9.718799719 49.87543359 60.91986996
58.82629229 48.14515504 48.33454713
-34.78829307 45.81355241 29.16130225
24.23847988 44.48487171 56.63540583
-21.55046803 19.66774333 32.99771805
-56.51724806 25.71595336 39.37374844
-57.50009349 39.28267527 45.21471982
-18.81605038 54.65096904 28.40365374
-59.63188351 11.97284342 24.70854914
-31.47089937 47.56132532 31.54579588
27.63979701 46.51242231 55.75603051
-37.5295658 45.08358579 32.33142426
-74.18838485 37.76315362 42.73042936
56.01645309 65.93265766 52.1391611
-56.5089366 21.94181106 36.57203404
18.25025505 32.78118509 36.32302466
-63.48152178 55.3975223 45.7350175
-67.3615899 37.63967187 42.86198495
-62.1595592 37.39422911 44.71920548
-42.80493884 38.33340073 35.95221314
8.139022093 43.16201754 28.44919397
-54.7063043 54.92874191 43.67683455
-49.71247224 12.67465262 20.49638467
-55.8022371 44.84258618 44.44047454
9.373951614 68.65615984 58.55097049
-59.62826249 37.35623237 44.67756591
55.65130043 38.22907675 59.17357652
49.12326123 69.927257 47.86799521
-41.44318244 52.99377161 40.30346096
-57.08647203 37.31294215 44.69537565
-55.94560301 37.30733186 44.54293335
-60.4035104 43.78055032 45.9935708
-49.507877 37.60311307 39.88157555
-67.51346846 49.09380921 43.93570414
-49.49355908 37.64544623 39.3874107
-52.61699014 51.3504085 44.03180934
53.51013935 43.59464942 61.38747556
-44.60178052 57.89292687 36.47262229
-11.12088427 35.55700819 33.00705971
-0.7395905826 58.34818873 51.95603455
-49.60315618 68.33590901 38.5561156
-49.33448516 37.93687066 35.97400631
-11.40801447 81.66940412 22.15315035
-64.70445122 61.05562598 44.37789026
-48.99853036 37.94084632 35.86378535
-48.01717101 37.92897674 35.814425
-50.58678704 46.29524506 44.06225512
-70.33007464 10.41740198 21.49889365
-12.63457355 37.28876912 36.49874883
-13.27785152 59.02179102 23.22092096
-41.30453303 22.2934547 23.76368411
-12.42216978 37.94973746 28.78523799
14.62869894 36.13467081 33.04381228
-11.91126724 37.86546233 29.66613315
-10.99612675 37.82505161 29.96072755
-14.33212351 52.09563101 28.0795663
-10.80073156 37.86276449 29.48566576
-9.508919566 38.09073559 26.59285419
-37.07773944 61.71947026 25.57627776
-9.171337028 38.09389014 26.49185474
3.836338163 37.3055275 33.163103
5.808830067 37.10341336 35.13322583
-69.87975053 51.13176349 44.03547127
7.882208474 37.2468536 33.07266072
-22.8846533 44.42901116 31.31069061
-40.69079193 58.06427782 45.39088964
55.0797883 44.04840602 61.64723718
53.85672446 55.54067695 56.65591345
27.22133022 37.89810945 21.82431917
7.987703245 51.86553071 61.45406437
28.2923216 37.87819259 21.85128412
30.78483092 37.83498394 21.87754519
-15.76803103 43.04912627 28.49229582
-54.88236799 38.44442681 42.79322158
43.40197774 14.48377154 40.00373974
44.75715342 30.61921504 21.40587851
34.17922881 72.04114518 49.48491894
-5.629021328 31.834755 22.73059049
-40.48925698 60.04581302 45.65591257
49.6559039 53.84209497 53.86885045
-53.65655138 44.85044025 43.94010356
-76.92222334 8.313614107 12.21851412
-11.74072077 32.76393042 36.4161476
27.03636451 34.26528978 23.24552927
55.91656423 65.4560518 51.8643582
54.58769371 39.12581126 60.61974609
-17.42353442 73.96763558 19.48184142
-24.88635753 45.45207042 31.4692909
-52.09253052 16.15852963 27.12001732
-22.83997496 46.41533768 31.55000587
-49.46079732 48.578561 40.64768734
-61.60492719 38.36666066 44.97802049
15.06892028 44.4025798 59.33940081
-2.464485603 60.60771397 49.36132742
-48.273473 29.27854495 31.40429193
38.99766688 35.07113701 23.26285611
-66.81588897 10.36676406 21.41655934
-47.27198599 55.89301934 36.89166907
23.77533277 31.87536922 22.47816501
-44.48128662 26.408877 29.03445342
-58.48807573 31.49781892 42.5491732
-73.67175421 38.25253859 42.7774078
-66.07190626 37.96209802 44.69971766
-64.16858011 37.91876317 44.83980447
36.34928383 43.78871972 50.75396049
-57.8818692 65.0882758 42.87596763
45.9135001 45.90379547 47.68322723
-24.86940377 46.4479503 31.55843907
-61.61018535 37.87948599 44.80786143
-57.53952046 59.39120484 44.85338768
-59.60220944 37.84530617 44.82171268
-42.99887765 46.10327926 39.01703263
-10.44469407 59.83595544 24.88248811
-68.55751119 80.82553518 31.19486845
-58.07939323 37.8204711 44.81960613
-27.59409461 76.60217723 -3.335616718
-41.28278466 28.75600657 30.31049162
-59.80082077 50.7801098 46.195612
-54.39799883 37.94565373 42.66433042
39.07323432 68.62140465 47.46396006
-52.36756566 37.91253448 42.66158709
-56.68743 73.51232245 38.08195292
-12.61654597 71.85065953 19.83395934
-19.38452791 58.30457738 26.88479983
-50.34194646 37.88184359 42.63157163
-17.22734602 28.73876042 37.57646393
4.498505865 34.98741682 36.64058122
-41.79498995 33.47904236 33.84613529
-49.28270061 38.42535337 36.12010779
-47.96126083 38.41537831 35.98389909
-55.74546445 78.64732636 30.73120825
-47.45353998 38.40704172 35.98385091
-0.6763725181 65.19199724 48.24238559
-6.357262344 70.18562245 32.14260317
-44.40981236 38.35833244 35.96884668
44.83892756 71.11065362 46.60059338
-54.35143144 75.32726869 34.04716548
-13.41226156 32.35491328 29.82980709
2.414574126 58.10176228 60.04185664
-50.67469504 80.2349127 28.81424357
-12.96147149 32.62973237 32.38017937
-36.25907409 38.97933729 27.20537302
-77.19712056 6.747412588 12.97262639
-52.1783096 42.83922624 43.69927036
-35.79655501 40.42611683 27.80181784
-65.89428017 84.7568325 25.83609131
2.830595607 59.5224414 60.95016164
-13.57063705 38.56050806 27.74062744
-65.86232964 35.01652784 43.89423301
-2.8161882 38.38842988 27.68729859
-71.35379617 15.70911959 30.18369563
10.76171821 46.85638169 60.80849156
-2.417265902 38.34825323 28.07762164
0.006514650582 38.10432456 30.44709455
1.478965671 37.95622863 31.88549526
-68.73702526 59.08633967 44.70127546
24.38519284 33.81198461 23.18677992
16.93361867 35.44755542 23.10086289
-56.82918563 12.80237289 26.19755634
1.302168164 66.32003493 52.24990452
6.854689075 37.7718353 33.00088507
8.528353111 37.74523998 32.99044508
59.61205139 6.089633846 52.78468755
-69.47028897 45.1464006 43.52015436
-53.32869285 51.36253046 44.02681477
62.24205744 5.785502329 61.64035255
-37.58457873 62.31057069 30.46429247
-40.27269682 42.45146283 40.10359778
51.67874383 64.14780187 44.55309126
29.62024481 38.40432983 21.31689697
-58.07621999 21.96429934 36.60986404
29.98106873 38.34620478 21.92284382
30.62046813 38.34577771 21.80586421
-59.01024525 31.99088219 42.75155676
-37.63678572 41.14472156 31.46395514
41.68409873 18.22162785 37.72607152
-16.54895641 81.63625267 23.51840525
-39.46634152 54.17222244 43.72607145
-54.20839644 42.87217079 43.70397572
-55.65928866 51.90061291 44.05146048
-75.79675717 6.225917049 7.106186774
-50.36845159 9.476725349 5.305587065
26.08476113 44.04243018 55.59285204
-55.69910416 70.92391392 38.80840413
-55.24829767 27.32582665 37.92309036
-36.27555744 39.46509034 27.39618153
51.24969204 54.63722368 55.98775318
60.15464779 8.302228323 56.12900468
4.493266418 49.85351669 56.3441963
-17.72818408 28.24140903 37.61895376
20.38922014 44.4443672 57.8396888
4.248639917 33.55725563 35.81066422
-1.521973499 31.76999851 22.69909239
-5.938119963 81.76349123 -9.115432748
-67.6831156 18.85966181 33.69636825
4.848071563 59.42152473 61.73693137
-55.84241531 29.71538046 39.4299123
-37.45902422 45.14711316 31.58049939
-22.30334673 45.41276483 31.43298322
-55.78482573 46.21656198 45.96696319
-0.6811531158 51.52880574 43.70965008
-41.29141504 25.02757721 26.98133886
-31.7677516 51.07791476 31.56575693
-38.48374057 40.59896492 32.13437506
-4.614717727 43.37628343 28.39405386
-70.7625314 59.61918956 44.72848213
38.80002282 35.07172533 23.29371839
56.50237925 9.103911881 47.518985
-64.97922617 74.73501849 37.12258177
36.05891391 33.13236226 23.02344267
38.54332828 35.07732328 23.27768658
55.74993593 5.521961473 48.45788886
11.65718113 43.09698636 28.53318933
-20.83249034 50.56018994 29.66382339
-16.0375486 24.56019224 33.41766271
2.306029536 60.14060285 59.70077095
-59.94600014 8.609125563 17.20381645
50.50964293 51.89812711 52.96647114
-56.82493866 74.58611948 37.29603864
-39.46109193 45.69957654 37.20219034
-2.780158082 67.2643236 42.066435
-46.62479482 10.01650168 -1.674469413
-6.397463468 82.18769571 -8.125667246
-18.76721252 50.08161115 28.99901333
-70.61419894 38.6990701 42.83729092
42.99083678 51.97424191 53.51675652
-69.08781554 38.6724356 42.85539321
36.35379999 46.247966 51.33757816
37.46131895 31.66161943 22.34952896
-64.52547062 26.79560329 40.02088205
-67.75973423 38.64672135 42.90062993
-68.26405227 56.5844512 44.52161949
-64.49700496 41.86677267 45.68418883
-66.43470966 38.47388192 44.65438631
1.137290842 36.52282387 31.11074037
-74.84121398 55.87490995 42.35951425
-61.09890335 38.35918011 44.9683584
-6.181816168 69.14224389 32.56816259
-10.92608019 60.7611062 25.88774801
-1.643178867 31.30414557 22.30352695
-60.08782539 38.34470817 44.94354056
-55.74385697 45.71845749 45.91486974
-58.56033374 38.31751462 44.96792132
-66.21243969 21.62664604 36.25457492
2.572827009 37.34822564 32.90839102
-11.83233256 80.24625121 15.44850329
-20.84554724 47.15037561 28.46344022
-57.03679024 38.29231268 44.96993502
-54.63733199 71.03253688 37.34494295
-18.90741422 27.28671604 37.27333124
-43.19429768 18.65911034 19.70106757
-56.03842253 38.28425231 44.87311092
-20.87085116 45.55738045 29.48099372
-59.64869104 51.27991893 46.19109162
-65.57730036 12.50082804 25.53978601
-28.91288961 50.02093355 31.63824516
-53.76323808 15.75575111 26.28773726
-54.3752359 38.43638721 42.78983796
-50.31527003 38.37060293 42.77925004
3.760566561 64.09360523 60.14717268
-49.50715147 38.65673484 39.3035084
-49.4824984 38.74341024 38.29261543
-5.358683219 68.42302881 34.93371915
-17.41773821 25.24975732 19.84925541
-12.85807669 76.20749639 21.74244709
-0.4703405102 45.77899874 28.84442788
-49.48722853 38.79535202 37.69053929
6.657608581 58.82711665 21.67903104
-46.91474433 38.88524575 36.15639884
-50.39754625 11.01136783 16.62909192
7.950516592 57.03994077 24.69938537
-66.14681962 39.93441791 45.12443896
-37.85824582 45.55337862 32.76704853
17.7479185 36.96466092 22.8138258
-42.6801177 46.56858902 39.38122893
-41.74877438 25.45078078 27.98234512
-51.82338551 17.03958287 28.49406278
-49.47719916 42.51068675 41.17143735
-13.56106959 39.99945633 16.86108033
-55.7130246 39.92409063 43.25453803
4.443137618 36.01977888 36.32000984
-13.21827983 40.01237308 16.64576118
34.97644936 20.94475495 36.52641797
-67.87414189 28.75922416 41.1709134
-66.00785958 44.38426502 45.88063383
-13.21758774 71.3666882 19.74023311
22.96421791 70.86456803 47.80233206
-11.99175605 39.9016104 17.69766994
-10.39881013 39.71750977 19.53106038
-10.08032484 39.68074205 19.89714987
38.53327923 19.83100672 37.12402974
-8.169413133 39.46013576 22.09368675
-40.73095737 64.20931367 32.32889533
-42.54774052 35.89906139 35.02954693
-55.08125915 35.02924346 41.69060568
44.16115948 43.2922123 55.02801012
20.59576142 72.4576178 53.06729102
-55.92204934 7.041542803 5.500863268
-20.94902741 57.57566678 29.81824506
-53.86554098 38.42705482 42.80097332
-39.63195461 56.79162243 42.48295239
12.77213088 51.06557365 29.04163924
-2.670162504 38.89131814 27.64819754
-49.5243351 34.52378549 40.67195082
21.57458298 48.74283484 42.67372644
-2.14307187 38.84843279 28.04552278
-32.8258389 82.34114511 41.74616499
0.4955656284 59.52910781 55.49143555
-49.72746046 53.33879135 43.70472295
41.37330608 16.10937279 38.99937649
16.46990267 36.74211941 25.64097584
-42.87237784 54.00059042 40.54141134
-40.9518092 64.1596643 32.94737931
-46.90440909 63.66206244 34.03246068
-49.47418665 36.02991449 40.65806522
5.351485573 38.26421509 33.39829201
-64.42258528 50.85673186 46.18752541
5.943639885 38.23806466 33.58893788
51.0207523 9.943054727 44.649622
-9.991716214 33.33689554 23.60456166
-65.38058365 34.02925091 43.6101105
8.265110839 38.35546831 31.78331191
-12.12664573 79.67615702 22.12270107
38.66549344 44.21277059 51.21619435
-0.2250491752 31.28256294 22.28362794
6.518907805 44.71780395 28.17737798
-53.57803599 84.70704263 18.23866462
-52.28356906 82.54741256 25.5824425
-55.78279507 12.4908725 23.78748598
45.94184212 31.09371634 21.49823478
7.271247565 63.62924167 59.04169479
-16.74843053 29.24717046 37.40977801
25.40841275 33.30156068 23.09037833
47.43258803 64.17695974 39.19773104
-26.73658404 75.93995425 -1.638222065
-46.70068804 10.48266778 -1.244951506
37.99254539 49.5067187 53.98156657
-58.08670688 14.60313502 28.83937609
-72.46025971 19.87042861 34.52696147
58.82493621 41.88478614 62.74333024
-63.01753297 39.86732702 45.30650536
-37.29898266 68.6324391 21.11401361
-49.48157095 57.32493532 38.17024739
-49.53317656 24.95470422 35.22569727
-2.407625715 29.93533717 20.85951555
-49.5333866 66.28519134 33.21583208
19.72158065 72.44019999 53.4362013
-9.41825214 84.20891989 -7.706866526
48.96066978 51.43394079 52.82384231
18.56966793 30.83419677 35.55760834
-64.78849939 29.19158733 41.38991661
-55.78461645 9.949274356 18.33271323
-51.55543962 76.91017554 32.61833753
-69.13421481 16.58501831 31.24561851
56.80229376 64.03532944 50.70830347
-70.80913571 67.92593362 41.53287622
-75.16366235 30.0709999 38.98629996
-55.79733996 19.21378071 33.14544964
-49.45193933 41.36296439 37.01029549
-49.46502199 55.05994471 41.15419238
-63.39748671 75.7840036 36.29683158
3.01776393 35.74102248 34.00120362
-65.52779499 39.41727483 45.18312068
-37.77405118 40.15997611 31.26849456
-55.51705075 13.16462977 21.74198137
54.24415779 64.57669209 50.73826341
9.712642853 50.40553325 60.59390129
-36.14159385 32.28549678 23.31698159
-62.11096579 18.77440581 33.62343966
-12.01697889 81.62970069 22.73019002
-71.42463062 20.77367057 35.49725093
-68.49990657 43.14359307 43.27858453
-35.99136238 76.17692235 43.98895548
8.154142065 54.70535898 28.45551908
-53.77000055 32.09783054 40.51067578
8.116912653 54.19714514 28.53569527
-61.73630065 35.92185068 44.25100817
-60.52201007 57.90237509 45.22566602
-30.94324633 48.54736617 31.65176927
-72.72154349 12.18311618 24.76382619
19.28754055 36.4415212 22.76655742
-49.55211475 49.83393906 43.5716805
-63.1689545 21.57805905 36.23819728
-37.82648549 54.11219661 32.45688115
-8.69331624 61.58745831 33.348972
18.24226192 36.46085642 22.74144091
-22.51067254 56.54132313 30.4701944
25.38753646 27.80103314 34.50873143
-37.1283176 41.91078043 28.30065711
-68.57135807 39.16190792 42.90139063
-67.48821922 39.13586339 42.99717355
-3.147939144 58.9188339 45.7908544
-56.31218509 27.62137532 40.52167674
56.1058099 39.07856922 60.87865234
-59.61925247 60.45362449 44.5699441
-57.95911861 22.4322225 36.98217951
-12.27125039 56.15160729 27.21493971
-49.50122592 35.83867244 37.05665767
-66.15600884 38.45718311 44.79508835
-63.61387884 38.89115979 45.09901188
-62.52893545 38.87659389 45.06119822
-62.09019681 38.86588796 45.10181039
-60.57541541 38.84510598 45.0541849
5.132807114 59.30561348 22.24172038
-55.83570355 42.3483879 44.26812623
-58.32813816 46.73805811 46.22472136
-54.94513621 30.18619578 39.6198689
-59.55273545 38.82478152 45.09509484
58.06801751 68.35040335 52.81416351
-67.13747606 81.35476311 30.60703596
35.99273467 20.40066234 36.82218958
1.00912935 51.1221596 48.10794897
-59.34381832 50.77257978 46.19587276
35.9464586 17.98725931 35.71435368
-53.39336582 19.81656027 31.516133
-52.78343197 38.90374579 42.88747278
-51.8167779 38.88690523 42.89862364
-68.02932757 43.63143051 43.35231016
-55.67771596 66.26929135 40.39881335
-49.48015784 39.04622276 40.60354167
48.94205084 66.4469401 41.69148352
-49.59795953 65.13988753 34.87038746
-79.00579101 12.64570226 14.76559053
-47.39696626 39.38293567 36.29745455
-46.88941858 39.37468643 36.29642536
-58.53715326 21.97381222 36.58733432
-12.57877009 39.23804688 31.33938974
-55.92372322 37.79851083 44.66343774
-11.64815926 38.71239411 31.43743672
-49.79271095 84.92846553 14.94634127
52.23792677 50.34559055 53.17993186
-10.98629855 40.2683004 19.07575908
-10.3159073 40.17790622 19.9972733
-68.02347148 27.32790403 40.33527728
-52.11092846 45.81853259 44.06030337
32.90450694 31.73025465 22.42177259
56.69391865 5.894884017 49.77534974
-38.14374955 76.17244877 44.4513613
-10.57758842 59.22733177 26.14653889
18.04471459 31.25433074 36.60713939
36.38785811 31.20534381 22.02438378
-67.37011873 37.14175813 42.81711657
-10.54864796 61.17204141 26.8719747
-65.07322236 9.499151813 19.50281638
-49.0981789 47.37882048 42.85271594
-8.439573713 39.92518683 22.57319666
50.39098378 53.81818061 54.00628161
-49.8247249 12.47018102 11.23816417
-15.02089569 33.13679515 38.53985197
-57.95870114 66.13146502 42.43379985
-64.45981022 77.98185711 34.29169873
-37.44922401 45.66916341 31.34493095
-69.63690731 72.67222715 38.65065361
-59.92927676 55.34007461 45.72447776
49.03844826 43.53935367 57.05552567
-31.54723685 55.23218485 29.91103121
36.09313075 22.63143895 34.21312359
-68.03619929 17.02890754 31.70987115
-1.219718776 39.23176175 29.24611247
-0.8790958298 39.20860475 29.44997704
-66.31214842 79.66717568 32.56046519
0.4267813105 39.11975763 30.23234079
-7.600107144 40.65482478 25.59621782
-6.533388791 40.56272985 26.46189356
-60.39561783 9.847022076 20.39907828
-56.17360774 87.02744604 9.276640777
20.04188517 33.34596601 35.2516016
-41.93459671 36.38931688 35.04801407
2.741747774 38.96788601 31.55389959
-65.56574675 36.47267881 44.41353245
-16.74895925 55.12635189 28.31749689
4.391949044 38.7796813 33.42401429
4.901955658 38.77019167 33.43691547
6.194977537 38.96038567 30.98241734
-61.2727045 13.31607445 26.90818044
-0.8720992527 29.90409052 20.92941526
-18.08118856 51.58909068 28.84818455
-36.80273672 73.65390907 44.29946455
-68.99185844 9.971081742 20.59825782
-55.88061673 24.96026911 36.37159104
-46.64638292 47.78976174 37.61463673
41.55332676 42.0232249 52.77674823
48.66761908 43.02597619 57.25926457
-24.39552166 74.95059065 3.573937909
-74.18007115 17.25389637 30.26970964
-10.32865092 80.05576552 -5.933527902
-62.50917721 41.34054037 45.58734581
-76.26193641 6.932280641 10.6481975
-46.38560972 58.45253216 36.14314344
47.44130971 13.27529667 41.60899167
46.64314691 11.27261282 41.70325823
-56.96062638 12.36646095 25.45636584
-70.28096033 57.61517988 44.59408696
-65.34432284 35.0030026 43.95245693
-71.46099393 15.26097849 29.57984678
-65.02844677 26.80154647 40.04780995
42.80250775 49.08072447 52.18288863
-61.71485898 21.08567348 35.85022267
40.29221094 17.17598522 38.47681391
-68.01471904 64.74191227 43.00256258
-52.21515421 56.41764484 43.39740809
-38.52037895 73.60357736 45.2113166
-56.97726358 39.76893307 45.29681847
45.01131672 45.65845475 44.87673172
-64.06810724 15.1492712 29.46675872
-56.12073859 72.97202267 38.41943472
-14.84859777 29.27993138 36.66715565
50.10033425 54.87011674 53.50334962
-49.51964642 24.0854552 27.83407011
-64.44550554 9.907722523 20.46676108
-58.89317116 43.25979312 45.9242288
-49.84783722 13.49958975 22.59900943
-49.57039374 78.2512735 28.32461461
-60.49481836 81.22505629 30.84597274
46.68528068 53.95303882 53.14744878
57.78120865 42.4179439 62.57973189
-71.84244477 86.06097562 23.48427824
50.22058695 39.79919027 59.46214528
41.01490943 68.09367577 47.39329198
7.041667231 52.73733769 28.20733855
43.05597564 67.82367525 21.00520284
52.27066356 7.824019275 45.70400306
-11.19565383 83.34148521 20.18183662
-5.193940726 60.31112851 41.67154836
54.21772831 8.161725498 47.23898697
64.9108879 44.80142218 62.68410834
-12.10069494 52.0230661 28.49640598
-50.71263768 65.69071675 40.34181843
-43.18777074 25.9231608 28.5996794
-66.82401696 9.111994615 18.50446566
-56.87269635 19.61422622 34.52850401
31.5024444 25.17868619 34.6514946
-72.11909167 39.71929589 42.93403929
-45.89299592 20.27008041 24.82097361
6.26899343 63.51537323 60.55469878
-68.05445037 39.65115313 42.94993869
-56.10386863 90.87944677 5.332991005
-61.56549672 39.35120237 45.19450397
39.50400426 44.13668144 51.93958379
-43.90786168 27.79971109 30.25920354
13.57114881 56.68244973 27.77751455
-59.90433629 27.19673729 40.30958878
3.720550957 58.29502334 22.58943217
51.55273009 40.82541482 58.94821568
20.06769994 32.27895623 35.98002516
-53.61659944 47.84358301 44.14574431
-51.19739727 20.22693208 32.1600941
-60.57814902 25.78220934 39.3790389
-54.14315463 16.20183402 27.00837469
-49.9267499 61.5243332 41.94523899
-44.28567673 45.75462017 37.48328103
-55.99625607 39.26741387 45.10509439
-14.09022463 69.74846209 15.3856357
-72.53641751 18.02387089 32.67111104
-55.72519592 39.32890289 44.33959203
58.20632644 48.2101126 47.69870352
-55.73055797 39.43071915 43.15865704
-52.80582022 39.39247751 43.04482878
-55.63146569 65.76852265 40.37664075
-49.51492004 39.56597426 40.40315713
18.94655884 32.34894851 35.38131196
-48.38576616 39.88838567 36.44503311
-46.46078201 36.93232984 35.43413499
-46.86682882 39.86550726 36.4209519
-46.36267515 39.8589701 36.40069488
28.52635362 47.56519608 55.01887427
23.43918705 48.29780947 47.48431529
23.46993242 69.39349222 41.47664641
-43.82486747 39.81768896 36.39594135
-43.31957092 39.81057528 36.38215902
25.81644811 72.04743813 51.00669846
-31.22669821 84.43534008 40.43681907
-42.89544369 39.81962887 36.1961747
27.94617479 26.74481212 34.6288865
-58.3184533 86.38160288 23.00973746
-41.42612339 39.92089074 34.7404446
-38.92245737 40.09505489 32.24115558
-38.0980057 40.14994702 31.44669963
27.90560892 69.71473374 42.72817136
-37.43437158 40.28596209 29.74117811
-50.04324656 20.21462197 32.0828954
-0.3160255456 59.21329083 53.48580357
-37.10975726 40.41954762 28.12851364
12.15897976 43.09163717 28.49959055
-41.52462906 31.10413756 32.23099932
22.54507732 73.97206548 52.59465343
63.42731791 48.0113199 54.83740191
12.76181945 51.57299118 28.97977355
-58.20381765 66.65821641 42.19227133
-64.4042036 20.19896399 35.00338574
-38.12938311 67.72478285 20.15583018
54.09431118 42.54331578 61.82743693
-10.95885918 36.22527069 31.04512238
-37.23019617 43.64892414 31.44900939
-51.49135932 14.35435624 24.64296792
-2.598909916 67.22245033 42.51796543
-44.62854936 23.64331117 26.20739795
56.3869685 54.92666163 57.47467793
59.70098973 67.2903469 53.15537318
26.85620658 23.9840062 31.92635408
-69.04258426 27.34642906 40.31457618
-65.77709725 73.1352921 38.3656096
-44.03432164 36.40783402 35.23348374
-39.49698739 67.98197481 29.08425171
-46.76471772 29.26080279 31.32252723
50.65302327 63.48734018 40.76254117
-6.082112258 43.87537756 28.70668676
-11.66525887 81.65050158 22.42164291
-70.0334032 39.68308146 42.95668954
-58.44931227 8.585081766 17.19750704
-35.51640545 44.85429788 28.78260244
12.66940471 54.6329051 28.43552895
-59.31600849 46.75321209 46.23719567
22.37510961 33.34501143 23.16443947
-21.33628673 58.65155898 29.05563754
19.02175191 28.79550588 35.83144139
-65.8790169 52.90649964 45.97663886
-28.91710566 50.52527632 31.61091108
37.98876877 50.02322873 53.81290227
32.49022055 46.04123995 54.47421322
-68.60071954 37.67007097 42.74539942
-60.64466005 13.74925774 27.5863418
-60.56852255 79.00559306 33.31861443
-39.64648965 45.11743771 38.16879837
-49.49336594 37.2422442 38.24139627
13.61523885 41.59372816 28.13077148
28.10584129 69.21062374 42.71542165
-52.60548851 13.90620647 24.2312476
-62.62074401 67.25761504 41.90298979
-74.46387152 50.20974243 43.95990224
51.23252026 65.73753515 49.48996898
50.91026618 8.442584657 44.60932133
-72.06754688 42.20437472 43.20880522
34.92796595 73.6408789 48.25117629
-37.31433043 42.3581618 28.96924241
-0.6098555276 66.76420284 47.45831288
-6.516361933 69.68932919 32.10763784
-41.19425294 58.07046557 45.4150707
-74.07086971 43.73046648 43.35478661
-74.42384845 34.83719814 35.95547301
-74.1389873 40.2493156 42.99303074
-54.22839854 71.02447698 37.36052212
-68.09197317 40.14751933 43.02155427
-60.42898384 42.78896811 45.85615687
-71.43328333 62.72306183 43.78422914
28.19071139 23.30416853 33.73726729
-62.88391558 20.17540425 34.98695642
-65.0938013 39.89878955 45.33722215
-60.73026961 39.8285414 45.32056229
8.903698964 59.01398213 24.90805866
-51.09822276 45.80323955 44.04470703
-57.83060054 33.42280422 43.38371818
-54.10116436 39.90389382 43.18160588
13.49656037 35.90048748 35.97828931
21.74912482 31.90635711 22.50484502
-52.78742104 39.88541224 43.14561483
-52.27571741 39.87506657 43.16812993
-60.59194092 59.44429914 44.81914512
-56.65264468 8.18005632 15.7300616
-56.13691813 14.13325794 28.09557479
-51.76868922 39.86707938 43.16415771
-53.64238778 52.37653592 43.96860464
-11.11746719 52.47530326 28.88564048
-67.57888615 42.6227781 43.32229981
14.10744952 57.18669016 27.64828865
-17.22541592 53.0852756 28.79609976
-37.99153569 57.67380007 31.92915112
39.03601367 69.0556738 48.25638603
-49.4712753 39.92228482 42.08516194
-16.73247413 53.5866102 28.70887579
-48.86591012 40.38502738 36.59786096
-61.19622837 24.84178322 38.7607812
-11.93969147 69.00387634 17.79257034
-47.34960487 40.36347671 36.55886837
15.17925671 31.26266919 37.05680065
-8.62428576 80.54067719 -6.061128209
-44.30587725 40.31476742 36.54386414
48.43305763 66.60458565 39.95848675
-11.67584974 33.68608316 37.35203622
-78.64369829 5.909461723 17.14940241
-41.09556216 40.42980316 34.59621868
-73.96350199 49.70289812 43.92165656
-39.78965136 40.51438404 33.36529687
1.898752845 58.7155125 58.84200704
-37.88929458 40.62121419 31.76272482
-51.57475857 18.40842444 30.03605916
-37.47009072 40.73186275 30.39829073
-37.23555364 40.86133121 28.85059849
-36.79148492 40.93465906 27.91466859
59.68615971 68.80447584 53.06101021
-36.53072102 40.92700836 27.95375417
-36.18080919 40.92394353 27.92260276
-20.94657598 54.48680046 30.71574681
9.73646644 41.66722247 28.01730101
-35.78058012 40.92027447 27.88886973
57.32179056 49.14040574 48.72115005
-7.811372393 30.94007579 21.87958514
-41.78502547 44.98208767 40.14787238
-51.61451106 73.65412104 35.46841484
-57.31441879 77.84632941 34.5023342
55.82772922 38.64229488 60.16963386
35.16726442 70.55642653 49.05226955
-3.876524467 59.59035529 43.96093023
-20.89354261 50.13832688 28.74611964
-55.49803389 61.60915244 42.02307264
-32.36210977 44.70025784 29.96926871
-3.884706969 59.09226685 43.91802387
-69.49466862 70.01506245 40.33663062
-47.1806018 63.13811324 34.34087611
18.41350837 34.91738035 23.14665724
-63.05208461 26.29348681 39.74219216
-49.98294752 83.26334307 11.00601308
46.88804501 30.58497616 21.3969746
-39.41075307 49.20781465 31.42624649
-45.17326803 53.31437432 37.29301992
-37.67242115 64.48763536 28.51465222
52.30730023 70.73786742 49.50390461
-51.9411938 19.34992284 30.8296219
33.26097197 35.16506272 23.26652011
-6.000130626 40.51673334 26.89415917
-5.703925795 40.49120479 27.13402514
-27.99745441 54.57465195 31.04054753
-37.96642656 67.14222279 44.3674623
41.4217595 42.98252451 53.3188708
-56.37304896 55.28061785 45.73650128
-39.58243051 57.20598289 31.83666661
-4.281985037 30.89415845 21.73955184
-55.35707749 12.54867956 23.03523246
-39.2576141 64.0616966 45.41485055
13.79152072 51.04690886 29.06390996
52.94778742 50.09647889 50.10978576
-19.15332896 22.94037696 35.33593632
5.578204517 54.2388805 28.53534754
-46.4809238 45.6478776 39.1410737
-20.87032481 46.91509589 31.19946463
-11.1245709 29.61806912 20.37832732
-47.03921671 36.9391706 35.4650334
-10.03878471 70.4502911 23.94558252
-74.12831123 40.74614618 43.05006367
-73.61688467 40.73594028 43.07100915
-52.98115367 63.12224282 41.45795363
-70.57398824 40.68765028 43.05129607
-28.50242369 53.56477956 31.20690213
-58.45621184 81.18781407 30.88953317
-7.225787789 31.39467757 22.31720243
-57.99581094 40.27699998 45.41969102
-20.85016995 45.09428769 29.02632749
-56.95553964 40.26019065 45.41643995
-44.28626592 40.80709069 36.65151728
-55.96042741 40.25377249 45.30117283
-51.75198705 40.36087015 43.25532984
-50.73044997 40.34112221 43.2897651
32.59926636 24.65591224 34.68442477
-57.87231002 27.16281441 40.31587075
-57.50571224 78.94725768 33.41171769
-49.80014306 40.34261663 43.09500161
-51.98250976 76.3756126 33.07873835
-22.48664651 56.0269874 30.60975621
-49.56419784 40.37226335 42.7058446
-49.5286309 40.52776692 40.89386264
-36.67001633 75.14316326 44.46572713
-67.75354699 72.63896388 38.67762977
-33.95049885 53.72372544 30.4007229
-15.8585051 57.8505937 19.82925707
-64.91171665 20.20719576 35.00461113
-48.69901814 40.87738983 36.67697182
-47.83331602 40.86191785 36.69148738
48.19581061 42.53875895 57.17857376
-15.24135687 43.53344487 28.59617293
1.473936209 58.54296377 25.96624305
6.906914024 52.34305032 61.94342224
-67.84999489 17.93941049 32.75786015
-26.51590222 55.57506419 30.797773
-47.32247821 40.85200893 36.70909741
-10.62078506 52.97475108 28.81960637
-50.70116692 41.33078269 43.4487613
7.509680909 58.69463081 23.05452822
19.77418657 36.43142669 22.79093558
-43.77418133 40.79655285 36.6761906
-14.01767074 29.50500206 33.89590777
-48.03306751 60.54910563 35.425332
-63.56393517 16.50070571 31.16209499
25.76742831 75.08546187 50.7082954
47.79394205 40.6582802 55.77861839
-40.77895796 40.94575608 34.37292322
-39.42739382 41.0320854 33.11299789
1.905723198 59.2142124 58.87804632
-37.86947545 41.11343264 31.87155517
11.03434548 56.72322423 27.78795795
-63.74137863 21.1168186 35.87513687
46.91335092 67.07385354 34.80069894
-39.49172654 45.23759172 36.74444855
27.22536166 24.75266564 34.58607647
-71.39535396 51.65517617 44.07499052
-16.24367991 26.2070592 20.16557838
-38.98387245 66.0563635 45.51366084
27.47664363 48.25003874 53.09554745
24.69570902 30.49911428 20.79929257
30.37518354 44.56083306 54.58328443
32.16212298 41.42570513 44.02422866
-56.40442203 8.557058701 17.13284638
58.13693714 44.99735858 61.70136848
57.23699335 64.02666097 50.72603355
39.48135157 15.75625563 37.63278409
-71.90931788 51.16444599 44.04311966
53.39932184 8.227673188 46.62949298
29.13783598 44.56555536 54.76443423
42.91175616 47.06537777 40.59782239
-54.83597632 39.42545701 43.04914173
-5.686882132 67.9377026 34.80368862
-72.78946102 62.26073302 43.5832697
-53.52220784 35.48834595 41.89032938
-37.88827412 49.33181683 29.69639246
47.50561312 10.70700143 42.27816983
-46.43597389 74.4678582 30.86102213
-59.0610389 18.72256936 33.64355565
-50.90574949 14.79655636 25.22454783
-39.7626654 59.57352019 45.17342607
6.478110707 68.70460258 58.54086599
-69.65260314 75.36466987 36.53101093
39.14451914 35.14572586 22.36896858
58.18767406 70.99934666 51.17368137
-46.54977554 47.09014588 39.89122411
62.95381802 46.60151632 59.64044894
-67.50370433 23.05371404 37.41430284
-70.469542 15.2440178 29.58766306
-37.76171752 78.38504598 41.9996255
-49.75010004 78.23615434 22.7077581
-73.60828658 41.23381907 43.11626994
-56.29773567 50.72268253 46.19421029
-70.48774107 41.1835265 43.10499614
41.81214078 43.96347728 53.51008894
-69.5484246 41.16808721 43.10509341
-14.38762624 50.62936971 27.63165957
-62.52404384 40.84582368 45.50657239
-50.04094886 12.07037826 4.267318749
60.31146793 39.44150789 61.68999217
-15.79743961 82.2053743 22.59494798
38.63998213 67.70884657 11.52709103
-60.94961743 40.81973217 45.50920913
-55.73233292 40.91348473 43.42589325
-53.25249056 40.87666581 43.38039331
-42.08665235 40.86207491 35.59374062
-52.74667452 40.86929008 43.369554
-53.72332673 29.20965733 39.06995162
12.63753061 42.5957991 28.3377374
32.03766953 35.18747794 23.23959904
-49.48380136 40.90825767 42.29494011
-66.72103122 12.07999949 24.81654628
-49.491284 41.25892218 38.22559687
-46.80162237 41.33926254 36.78001253
4.404015428 60.6100032 24.71832056
-13.29112541 51.57623904 28.08388191
-46.29154649 41.32973797 36.79330612
-39.2208638 63.82495859 30.67614451
-72.12579653 31.76155526 42.08832448
-49.4220694 13.25817932 2.013714608
-43.75321932 41.28819478 36.79149562
-38.4600334 41.59143811 32.26178268
-20.76141638 52.08054111 29.4808458
-22.97400244 41.4731753 30.68138447
-23.29392619 22.59417468 34.31790076
35.52292352 32.65274313 22.86678713
13.20461902 33.27650703 37.36184019
-26.15764344 76.29879611 -0.08768657937
30.59279685 24.63977646 35.25438649
-21.20766793 22.48333925 35.20669743
49.55179202 11.59603786 43.22064936
-20.5977115 41.50098086 29.90542408
29.49672843 43.29909079 51.9181131
-17.13990967 41.68939702 27.05872787
-68.22032535 59.5756897 44.7486463
37.68434959 42.32716771 49.98619494
-68.64318045 25.91086179 39.42359725
19.20280102 31.76702917 36.26114865
-55.77793174 79.66813624 30.54037567
60.836648 3.636478175 57.72260824
10.85709056 50.08560769 29.12972713
-66.36785895 52.42787482 45.79944919
-73.07366319 42.71855982 43.25828261
-43.73550664 19.46577106 22.09325514
-9.236883872 41.46902184 28.10984944
-8.731171728 41.46169853 28.09842153
3.880850789 58.33805083 22.05936746
-14.73837861 73.80247032 20.88712527
-26.94150221 44.49322312 31.33893675
11.6750204 71.24537959 57.1877946
-64.39428624 34.01489036 43.58872534
-67.74420994 56.57000003 44.59024214
7.149810832 41.50348488 24.58473583
-45.08803624 19.3551509 23.63535042
-63.40762844 50.84030326 46.18468222
-13.33523116 61.94014252 24.31335498
-13.46284054 30.56312717 21.50660776
-9.07169281 60.32123455 30.64045718
-29.88530434 45.54551366 31.33786273
-66.66168804 35.07286691 43.39265031
-55.76585391 81.86913062 28.29388644
-64.61437163 66.76995204 42.1176936
57.38759451 53.41081401 57.40099536
-52.54567208 83.73361579 23.51539578
-22.54680815 45.91390347 31.48847062
-55.81324094 89.75318809 6.698717625
-40.6834666 80.49831874 35.50432976
-45.55592851 26.88329564 29.55863721
-62.87316151 17.40446185 32.1921814
7.113007476 42.66941918 28.53666146
-77.19352691 7.247815828 12.98953594
-43.19801018 54.46884587 40.99430142
-54.53979677 35.50610228 41.87826119
-37.03556581 61.19598013 25.8186496
-44.97108961 55.24602923 38.13696711
-41.18102809 29.70910844 30.88006574
-59.89675927 43.77270289 45.98802896
-53.69963661 42.8633101 43.70981361
-16.74921991 27.74092058 37.41565373
-15.30344792 82.15440755 23.0924001
48.03936508 44.82476339 53.97736394
-6.516876074 46.8602078 29.09935652
-60.11022971 15.99074937 30.59675697
24.31883849 32.35119731 22.67739646
-7.098627571 43.89259232 28.70070084
58.52126365 54.36371198 57.77614461
-13.13614765 72.33003854 20.19471003
-65.99990243 53.42013955 45.86362631
-46.56427059 43.03017387 40.41197949
-11.30749098 69.78851206 20.21666406
-26.49949541 55.0645719 30.8941703
-49.47812056 55.86610244 37.62486338
-61.63315504 71.99793682 39.12530345
54.48874224 44.0739974 61.46286978
50.17657145 45.44488117 58.02428856
37.0413088 69.1451038 47.59861935
35.39270188 20.93300301 36.58346123
-64.61903262 22.06841808 36.64893135
55.98600207 9.594053253 47.75418341
-68.62207167 36.67640981 42.63133356
-75.85373831 10.81270062 17.96337379
-13.31934332 60.45025783 24.12607122
-73.12030382 41.72526628 43.14477179
-68.52338046 41.64878618 43.15594772
-68.01503622 41.64013513 43.15943118
-67.59790634 41.62794061 43.2214449
18.14386281 43.07925491 56.63518877
-65.98011287 41.406528 45.48324144
33.27002517 22.01708573 36.05672674
-45.01875945 21.83128498 24.01057194
-49.86726336 11.28232534 7.555848959
-8.740901254 46.89187135 29.1559178
5.41192764 38.76071952 33.44962044
-58.64841387 41.27613057 45.59879106
-59.25092219 80.08870929 32.14701802
-65.64683782 25.86127758 39.42778643
14.23557989 70.36163444 55.30535713
-29.18907232 86.12824128 37.87572116
23.21838952 31.40631958 22.20280704
-56.41528026 41.23987235 45.59383099
-9.828991626 79.45016806 -4.825239392
-12.13482823 79.17806859 22.07979471
38.35056244 42.79065302 50.30530946
22.1942302 46.53382467 56.54607866
-14.60064982 39.79384531 31.09950135
-55.72756353 41.28940561 44.88766093
-60.35467414 47.77364351 46.24264457
-49.17753912 49.83655171 37.64327535
-55.26293143 41.40191616 43.492948
-36.72299932 57.52279665 27.61354987
-74.80948485 34.56094965 39.23591072
-10.54831127 33.34510842 23.61536654
-54.20909826 23.52721946 35.20878585
51.26419265 40.78513098 59.4708858
-46.67141958 20.01852489 27.88966424
-49.66802126 41.34234569 43.11750251
-49.49356417 41.51450987 41.08562462
-49.4071394 41.73108116 38.55502492
-57.83668758 45.23128083 46.14284646
-49.05294019 41.7754093 37.97288355
-48.29028146 41.84908339 36.9721772
-46.78048719 41.83081712 36.89629857
-74.84244253 33.32536426 41.93249157
-11.36834578 81.10908185 11.17029063
-37.66284849 55.69735555 31.50391522
-13.70106791 27.38598627 35.12804812
-46.22119078 41.82124156 36.90079747
-43.7364825 41.78196807 36.88286396
-43.25009548 41.78439313 36.76195514
-57.45129199 66.12328566 42.43198586
19.54578774 28.47494615 33.62615462
-37.26932811 76.65479443 44.51182436
-49.68497525 69.38161394 38.08569469
-14.87670001 29.83256115 24.43054234
-63.28770206 20.64591705 35.42854592
-42.07054709 41.85838281 35.67808182
-70.48060393 58.11899288 44.61017211
-69.59450274 62.68750855 43.84629109
-41.44043136 41.89980558 35.0770491
-46.85910205 11.84622566 0.4360068968
51.80566496 7.407700409 44.79897328
-1.827976285 59.55475702 49.81015233
-40.54956464 41.95836204 34.2273893
-41.22835889 53.36284401 41.8046722
-59.88969789 9.839593936 20.38882758
-40.13667646 41.98547399 33.83391369
-46.19368845 44.29367053 37.32699718
-39.24576645 42.0440086 32.98449915
-57.82950943 45.7298759 46.18006295
-24.02344107 21.68518333 33.35597659
-38.45718436 42.09221696 32.27447388
-47.56513063 61.06305372 35.19644994
-36.10271933 42.39119988 28.35465066
38.0241633 17.30418907 37.42106024
-59.86517916 77.89414441 34.43370806
-20.96722904 42.44403412 30.68152481
-49.48982316 50.70117833 39.31889497
-55.09150161 16.22182867 26.95711766
-17.59030003 42.04714052 28.81831348
12.22239863 32.28437891 37.41322106
-12.76982926 42.01895003 28.2262765
-8.700477887 41.94843132 28.26885941
60.16026629 37.53369393 60.55956031
-67.70605377 33.67181604 42.37631525
-52.18389907 55.39744642 43.58137359
23.4484515 69.75787697 43.07733997
-5.694391134 41.90632071 28.18443139
39.91253793 44.10404536 52.24053754
-4.76548183 41.89613121 28.12556986
-70.41247354 62.70368077 43.81454415
6.528427883 41.87315047 26.2385305
30.23871157 72.04667469 50.17220873
-72.51854223 75.9745785 35.82393892
-40.30927176 78.85246721 37.05929181
-58.18226358 61.9787323 44.07125977
8.910198324 41.7371359 27.36326909
-16.84130162 51.1304271 28.10959119
11.56225971 41.63822052 28.00578709
-43.94854809 55.5793235 39.8994803
21.18779821 31.49000327 33.27187525
-74.28346115 12.40112059 22.53093853
9.460181058 67.1927366 58.04308824
14.57207451 41.5818404 28.08629898
-12.99456431 33.29064996 24.71407548
30.96049581 36.23972332 22.88307184
14.88144133 41.57445027 28.11309093
-55.75037119 30.0472764 41.38610988
37.5933652 72.59274794 48.25705597
-43.84132385 51.42651451 35.64810082
-16.26471023 29.25094072 37.27376161
-72.55130877 71.14905095 39.40866576
-63.44591621 74.70488995 37.17992453
15.61411381 34.31677313 36.47944864
51.76349063 38.43101916 57.57071
21.11073884 73.45005275 53.10145435
27.2064729 71.58737246 50.25574953
51.99631889 38.4622382 57.16389478
-11.34811558 36.47784217 34.01396324
-5.784714209 30.90886079 21.85545575
52.48703102 38.58637077 55.62929131
40.66098747 42.56673872 52.46405848
56.3131419 38.21732898 59.18373604
-12.66279617 40.49454793 16.76903049
6.760628164 37.1188446 34.77257498
6.706652632 37.04696065 35.61734997
-59.65210987 60.97241513 44.38035069
-39.97162429 55.10942766 44.59595174
43.54010357 42.83072584 54.67707984
24.90093433 33.30977498 23.09179993
-4.697195451 43.87054887 28.4986297
-41.71993502 21.34990126 23.14307888
-66.42646274 23.50808249 37.76086677
22.2729922 30.97309494 33.23893049
5.531037326 50.03046151 59.91882398
-70.1173516 86.63454264 22.32354869
-7.343377884 82.64673466 -7.447493878
-68.3152333 68.40901611 41.27594073
59.11096933 8.53136812 53.66801805
-13.2312926 31.95297301 22.80802823
-38.83287826 42.07112055 32.59102354
11.33298556 69.23560685 57.27736782
6.110422442 37.6517167 34.53724575
-43.03600437 42.70560673 37.68028882
-75.65973424 11.74404694 18.76791011
-59.84728849 50.27655613 46.22345121
-22.83473229 50.93263729 31.5486635
-48.91555727 39.40563938 36.32349779
-60.2655328 28.6378091 41.12938068
-42.91904823 19.09681286 20.39404651
3.988516096 58.78595608 22.66586505
60.14696445 63.97109038 50.81618861
-51.05747444 73.64568541 35.46011154
58.20171823 43.93547045 62.3629051
9.728960105 46.88178998 60.71048674
-6.300833342 82.21328509 -8.441155442
-56.86254811 27.14900634 40.28359722
25.20525527 45.99939587 56.34925681
-63.67144579 21.58375764 36.26787201
-49.67192142 9.931576294 11.54579564
-66.82774003 85.95958343 23.70497848
-73.51220752 42.22695171 43.22222051
-8.104551251 39.88007709 23.03297276
-9.177137515 54.99871601 28.3551955
-69.02170666 42.15459975 43.20576933
-65.91675638 22.55625692 37.05986679
40.77666107 34.73697139 20.97618845
-56.14484658 88.5195858 9.429231146
-60.95257583 8.621326371 17.2541406
-54.79151338 20.30550126 31.93342522
-61.35715123 20.14857757 35.00721694
-68.51526723 42.14690956 43.19846167
-68.00442942 42.13700065 43.21607171
-20.82375228 21.03080699 34.51561426
-58.96650989 26.22717331 39.73286406
-19.54633251 57.2083212 27.98851715
-1.888950074 29.0161539 19.77786926
-64.01716141 41.8601096 45.67002957
28.35028337 27.29095781 34.03840353
-14.00172134 29.32351155 35.99974072
-55.84932134 25.3723649 37.40835519
-63.00161582 41.843384 45.67052182
-60.35544324 49.28067955 46.22793974
-55.7329754 40.78991955 44.86045075
42.50327941 50.46575915 53.64138284
-58.42430117 41.76440641 45.71442998
-57.92014751 41.75786925 45.69417296
39.01152826 44.68909964 51.44726909
-67.55877072 40.62854481 43.16241552
-55.73271467 41.79422008 44.85502939
-65.66988456 30.647285 42.13912385
-60.35089001 48.27395067 46.26063324
-58.09232177 67.7055168 41.66646285
-55.24661021 41.89589909 43.58196191
-50.71929455 26.3039541 37.26874276
-22.90463568 53.98119556 31.13195042
0.2356700489 60.56553039 55.16273124
-52.41126282 41.8531939 43.53699724
-51.69723971 41.83990143 43.55513737
-37.42068313 55.97164312 28.27360204
-50.18135006 41.8185604 43.51379035
-7.547672473 51.90678677 28.97797271
-44.98079106 37.39414683 35.61742417
13.81050668 49.53068349 29.18181721
-49.42430942 42.03134668 40.8992393
-63.16979064 72.55673683 38.75803156
-72.15509616 9.125200458 19.36783154
-48.46710381 42.21749514 38.55574694
9.379465128 56.31246182 27.04534236
-51.91769315 52.85119257 43.95617511
-56.28214136 17.30080312 32.1385805
-40.51965439 41.90037732 40.72145614
-20.05382199 28.3243302 37.09985737
-54.86729793 31.15136302 40.05394078
-39.23035426 42.30306047 35.80093814
-39.09744398 42.34751544 35.25952553
-38.97936885 42.38706494 34.77788837
27.77654199 72.04980089 50.60546818
-37.30890357 72.23394829 25.92008636
-38.86120713 42.42657076 34.29674172
-42.67782652 39.83374316 35.99082486
14.26418597 52.56290382 28.85491664
-49.61955969 83.3840286 15.36234873
-38.47708056 42.55491373 32.73358736
12.69899247 32.27935512 37.38065149
-20.36315513 42.46142667 30.36441882
-18.17936877 42.52439478 29.21697769
-14.97430321 43.0451832 28.38670112
24.35077726 26.82402769 34.39495813
-17.7147658 42.5378086 28.9726576
-17.38959913 42.54722623 28.80131961
-45.23250432 50.7836197 37.54995889
-66.35194755 35.04051043 43.7091987
14.2984889 53.0725939 28.75816135
-59.8456464 47.26243165 46.25344948
-61.37328582 71.99073744 39.15932046
-49.47858535 55.81678119 38.19750917
22.66732363 71.64709787 50.42805635
23.36841367 36.89218433 22.58332479
-13.24654457 42.51386219 28.39852838
-11.69340869 42.49851049 28.28054972
-6.792744478 30.92179535 21.8975394
-52.59950254 49.33272295 44.14467729
-10.72477188 42.48066975 28.30293316
-12.22545177 80.79144656 9.194446976
-41.04950244 62.03995356 45.91987959
-5.134924701 42.38648648 28.33026397
-49.72443803 56.9023346 43.12242199
-49.5182136 23.18607074 32.44786796
21.20377477 48.83077423 41.72357701
-10.21359145 62.71455507 32.2080175
-61.13142655 72.5196169 38.80021856
-42.88277772 18.70018978 19.16477861
57.90729586 49.61982537 48.87067863
57.61325402 70.46477946 51.66223205
-50.57838876 33.98338654 41.31966978
-63.4264387 49.83297094 46.22883634
10.21306184 45.59320361 28.96388444
8.847230428 42.2215598 27.57837227
14.57061956 44.40616449 59.39281602
-58.16306788 29.08295903 41.38744362
-58.09816789 60.42966293 44.55802743
9.129351242 42.20313581 27.73844929
-55.76172264 38.79555673 44.71138857
-6.511522328 49.8708033 29.10899113
-39.51040389 42.20893933 36.94697235
-59.66608591 29.58763419 41.64208152
-59.68001353 62.00331185 44.07155231
10.08208567 42.14376117 28.24602133
-21.80989792 49.40457798 31.61212636
13.13069652 42.09258859 28.25868163
-45.07685495 52.48430827 35.25736383
7.685618477 52.3714635 61.46507752
56.11938294 63.61743406 49.86313217
15.54749407 42.06279419 28.14365895
-9.901330455 79.98887475 -5.238503474
-70.85477263 68.45117077 41.27088414
-43.21112201 29.20267376 31.31963937
-67.78643129 87.19983997 21.14328498
-40.37900213 53.63483262 44.31189374
28.63386376 40.71439989 41.30115692
30.68823913 40.70636999 41.00259209
-6.714211717 80.59304984 -7.033370986
-16.16831593 31.71339562 37.80260957
29.7105658 42.1801315 47.38710574
29.76779825 73.54309214 50.37043162
52.17172779 38.99986009 56.7159769
53.93717255 38.70396613 59.81424941
-58.41269002 42.2607653 45.77676037
-51.20852771 57.42223938 43.19665356
54.25864354 38.69047016 59.90961408
-72.49407176 24.56275508 38.327869
2.591968894 57.5596168 60.47500601
55.30771372 38.6568334 60.1000302
4.231197519 64.58141646 60.22119262
3.933874619 35.02446134 36.3182199
-52.44215324 25.38809994 36.57592384
-61.60440419 59.45946989 44.83611487
-61.63297914 30.58074443 42.14171332
46.94290385 12.76432945 41.80908017
58.87848731 38.59003915 60.19445871
30.53645532 45.53346874 54.91474593
-60.36751169 82.91526422 28.68044968
-49.4561049 56.76031747 38.89324613
59.5315713 39.01059147 61.01447437
46.196444 63.87708173 37.08803264
-25.90654393 45.47113702 31.44250753
-30.44236646 47.53804839 31.61986402
57.5308549 65.8872893 52.37702465
7.412335547 54.46862697 60.47835359
7.186390561 54.43393572 60.92416403
-13.11916137 82.03516153 -5.073070545
-44.12009086 45.86931473 36.12024444
-55.74072599 39.28718119 44.8268898
52.7198804 50.5649934 50.54103162
39.03870068 72.06761451 48.25091307
45.79617889 11.8304063 41.21614718
-50.153106 42.80874499 43.66690048
-70.51893449 9.99806565 20.57623148
-41.57060488 17.8762912 16.82567326
15.99181207 36.03507043 28.11345059
-28.01526623 76.69074413 -4.28344614
-59.41926548 70.89262047 39.88113859
30.23989016 30.83107143 21.71502413
-58.85632966 47.74876406 46.24572017
-11.21583147 82.29767588 20.64970346
-15.16831391 25.72081531 31.43181626
-20.13392583 55.57685567 29.55989476
-24.54013599 56.57395322 30.47843139
38.44836868 20.3760551 36.6395442
-73.55280831 42.72487057 43.27639861
-66.63020352 85.35772192 24.82751402
-16.76746386 47.14978569 27.69257339
-42.39173198 40.84252456 35.87887596
-72.05829076 42.70192158 43.25779385
-55.68388202 73.09854433 36.86736752
54.90321221 9.216817138 46.51326897
52.56849085 47.90062072 46.54003903
-46.17216316 45.28724434 37.44204405
42.77822635 43.38276282 54.24056705
-69.01393975 42.65289783 43.24632127
-56.63068889 24.7687455 38.73798056
-64.4560071 42.36069662 45.76918145
-42.88784729 22.24969604 24.57361421
-64.00246794 42.35491363 45.74982197
42.60543725 68.40449412 20.17519465
-46.82107449 28.78701542 31.00670341
20.27536894 49.05132152 39.34035535
-58.94863161 42.27094866 45.76075179
-46.08309865 25.06602413 27.44882177
5.554908289 37.68416219 34.26653469
-57.90389556 42.25188713 45.78279447
-9.156118575 76.2956701 -3.293690834
-39.06207536 64.08694814 45.08442172
22.19182065 31.45338362 21.85222613
-12.96694898 32.73160686 31.19859036
-54.95128828 84.87943224 16.49932739
51.26790349 42.30229609 59.33774102
-52.19231042 42.3440728 43.62340201
-56.74915621 84.57889434 26.15769733
-49.64695918 42.33615316 43.22992517
-48.15229324 42.75913069 38.03465075
-11.21775056 51.47190253 28.89969131
-62.51080216 32.04703413 42.76728124
-49.49215709 13.79575044 1.613203678
-43.70006509 42.76802969 37.08227784
-72.09666592 40.71241547 43.05418742
-52.17842596 12.17747166 20.91165545
-57.32590637 55.80510377 45.65624245
-12.23869819 82.74411911 -1.817794869
10.06337479 41.65098368 28.14346946
-19.79061136 31.90529101 36.26574105
27.70553554 71.58340342 50.20665077
-39.10553739 42.87859197 34.92258588
-38.73396522 43.01326789 33.28830817
-49.99757307 87.59244219 13.19322864
-37.4666956 43.26600809 30.11263909
-23.18024844 75.18666319 6.428317133
-44.63263561 46.25841144 37.52771225
-65.28374793 9.090744557 18.45741329
-67.42431429 51.08247816 44.13934499
-37.07682575 43.39145392 28.58202162
-72.4449709 83.16005436 28.14199093
26.85700059 74.02696214 51.13506304
-36.56636891 43.38173718 28.59747343
-51.96073159 68.33582934 39.00664429
-54.16090743 69.9612218 38.03741157
21.54600904 49.45768757 40.20728404
-19.56672683 42.98311525 29.9830342
12.17592437 43.58530565 28.59213611
17.18510972 31.7441652 36.91135627
-50.52944581 28.68209999 38.7584891
-18.64261191 43.01251102 29.46555228
-72.3150992 57.65016845 44.57583664
25.66217055 28.86515137 33.75657774
-18.18067566 43.02727005 29.20612461
-17.25663001 43.05670077 28.68825028
-16.78264154 43.06538016 28.49710103
-39.09585202 58.21274341 31.70994403
-12.08486938 42.99997244 28.36050819
-15.16140204 29.6721833 20.51997841
-39.65290364 58.11061521 44.65503968
-49.89909455 13.05280891 4.488776135
-35.77160497 41.89540123 28.22045687
-20.94969568 42.93740559 30.77740581
-61.83181202 46.79578237 46.22278763
9.596608251 42.64309329 28.36863402
-19.93983315 27.33673108 36.88960848
9.25071505 59.48981619 25.14469247
-55.63994712 70.51574594 37.70879327
-39.85194811 58.29681492 30.87817258
-16.47680042 75.29404661 21.38326933
13.46315527 42.57576596 28.41284506
-52.25309837 10.19961644 14.75307736
-37.65303018 68.11725521 27.16216414
-63.50815875 26.77798672 40.03138063
-18.36919574 55.6777679 28.05188707
57.65023102 43.4388907 62.40609627
30.35082483 41.06906839 42.68311246
-12.56286424 55.57349251 28.15508967
33.17646912 40.98170213 43.1584566
-68.43369379 46.62570481 43.62954926
50.56382431 39.32205413 59.1089914
51.66348253 39.37439904 58.29162195
-50.96399298 83.11492722 24.56930787
52.17379182 39.50103493 56.72422085
21.09504815 27.48189521 33.20547963
54.32098451 39.136461 60.54697911
54.9006104 39.11663043 60.66664879
20.1461056 43.07274496 56.32892128
24.45771508 73.49615102 51.92802333
-58.05795073 30.5241928 42.11642656
56.9718064 39.06294873 60.89483561
-11.47848003 81.86507693 8.241791714
60.5339066 38.98814077 61.08394723
-8.000740043 79.45709282 -5.254285666
-44.64661095 45.26102913 37.45543749
-50.35205571 19.29163231 31.20324284
-78.46217092 6.443997201 16.73614694
-40.88835961 29.2946481 29.8089692
24.161381 30.01865659 20.65205473
-11.09901128 82.84007445 20.15750753
52.53927227 62.76456981 42.96662754
-51.59338023 33.99983261 41.32231676
9.238657694 35.4524028 36.16535004
-7.119301219 69.82003141 30.70533495
-16.47517816 32.69917653 38.07074761
-56.54912642 76.20141514 35.97183122
-70.8805935 52.14550453 44.11137403
-70.70465561 10.84840447 22.39357804
32.79312192 20.57756215 35.37879255
5.221282462 63.49876865 60.94726176
20.85870962 33.86882592 23.19944606
-49.62586642 83.13943143 18.2030144
30.66109911 74.09024781 49.67493222
-49.40328193 60.55890676 35.57286149
-49.50118287 29.15451633 33.07823863
-55.2983838 80.32489436 28.65143594
45.51152234 46.00745805 46.5564957
-57.28254964 50.73629476 46.22399946
57.32391156 46.1641354 59.96490203
-64.09284245 83.56223945 27.70697273
52.80511367 7.777421497 46.1430213
55.67063382 52.02693541 56.31357996
-17.82252212 55.14756591 28.27596392
-68.20122308 32.19195091 42.17017884
-21.18012027 46.89792439 31.45788376
17.65035241 30.75282428 36.6775588
-13.52341442 49.25666853 25.92224324
56.99672266 7.422230363 49.46697476
-70.55168297 43.17508979 43.30423362
-69.50835244 43.15673727 43.31831375
-65.51253012 42.87553107 45.82073359
15.55790675 46.41255769 59.2194214
-55.87263716 7.575047952 10.9514056
-55.76807403 83.62467716 25.39456662
-4.734976858 31.37710351 22.04620233
1.064333336 60.39529732 56.98089016
-59.91977378 42.7798803 45.86454539
16.05280572 29.30870838 36.26664908
14.71572683 53.57494983 28.67351813
-57.86375337 53.78260634 45.93087577
-56.87261751 42.72944147 45.8689652
-45.00790287 50.79421082 37.38417652
17.80147216 35.94560314 22.98029745
-51.15625304 42.81921625 43.73664866
-9.401858307 61.74665304 31.63604638
-49.63546491 42.83257101 43.29159338
-49.5130263 60.60853631 40.84429602
-49.72718136 28.22119038 38.12942655
-46.78181947 43.18947012 38.60423996
27.38223373 24.27042836 34.32768143
-46.21995845 43.30249014 37.18507059
-59.4554407 41.2867822 45.62904406
-45.71268783 43.29438066 37.18247178
-11.20961401 55.03286601 28.34636289
-42.77497267 43.32392625 36.27924493
-58.12198613 60.94389384 44.41964283
-46.4579823 71.2426633 33.34579048
-72.37907568 79.79140587 32.27530986
-56.07810263 7.834019196 13.81090325
-38.42622476 43.57085882 32.58333855
-3.942875116 29.96644404 20.79118545
0.6276619371 48.26892545 28.86335029
-27.28141618 43.51159043 31.14598721
-69.17437081 32.21206714 42.12224004
-79.32394411 4.802172072 18.48007418
-41.66140566 18.82741545 17.45492688
-26.98481156 43.51063807 31.1004786
-39.14069563 45.50651876 33.55560291
-13.18847127 43.99534536 28.6692383
43.32031879 66.83456484 20.78382464
-25.97110151 43.4948384 31.09057214
-55.89291398 7.52906176 5.662472046
-18.73686322 28.75688693 37.65391217
-49.47834319 31.55496377 34.34094185
9.751921837 42.16164217 28.10140987
-20.93167745 43.43053249 30.87603363
-49.53004545 21.23273081 31.8193561
-20.34405413 43.45622339 30.46573153
-56.9089633 90.86434588 5.661829545
15.13707374 56.13365283 28.02307731
-12.71401513 37.99784476 28.2824299
-18.18583182 43.54447598 29.02964491
-65.96927757 27.77262599 40.60751411
-17.75422892 43.56214746 28.74219215
-16.76244144 43.55740644 28.6080896
-50.92402333 11.78581934 19.39237815
-14.23678989 43.52225734 28.53446905
-58.0029287 88.26181133 18.60265516
-13.71525054 43.50695013 28.61270561
-43.65574827 21.38484607 23.10658518
-47.2327406 25.98138047 28.69522293
52.71838334 66.08488965 51.0009026
5.561136944 43.19873754 28.5145396
58.38965878 52.89867292 57.32856229
4.680076684 63.51160748 60.90143043
10.60768018 43.1184515 28.48415248
-59.56699347 18.73001497 33.65361014
42.88646872 30.64608421 21.45071241
12.66012037 43.08661992 28.46226393
60.5027715 8.225731923 56.95064158
13.68813379 43.06360505 28.53338903
58.33815153 5.709690003 51.61165223
-71.07296571 17.07410531 31.76431204
14.70499543 43.04621559 28.54133698
15.21465571 43.03690067 28.55227614
-14.09035737 32.15851864 38.06565846
15.60888176 43.03625978 28.48453471
-26.54006678 56.0894698 30.65742638
-11.58586043 70.7846979 20.35858031
-49.57341457 68.56868467 35.84821351
-9.565171265 50.92894935 29.0608905
41.54716786 46.97322944 41.92778292
30.33072923 41.43275434 44.29165412
-21.55357082 20.1591356 33.1205106
45.87830399 45.4193338 47.48728305
31.12961809 41.43859676 44.07147811
-65.40407726 17.44226642 32.2359792
-28.90968706 49.014886 31.66328676
43.50880894 51.96073414 53.57478424
-53.89807334 72.0852802 36.63623088
-58.72504593 17.79642728 32.67753105
34.60056907 41.43454603 43.45657062
-74.03821046 28.99440104 39.61633323
-49.67217246 76.79596677 27.75836431
50.64681373 39.7824069 59.57569482
-49.46871844 66.8415086 32.57200357
-63.4637165 19.71788495 34.58210492
17.50774733 44.41122853 58.77390099
-16.65954184 47.76434747 20.53770912
-35.9722507 37.04878845 26.25533029
-49.2206096 29.76297828 31.7879019
32.47945633 23.57563906 35.59459106
51.27166733 39.78931733 59.37631023
51.48414881 39.83080012 58.85422614
26.55753745 44.05172103 55.3948357
54.46025956 39.59319886 61.04491312
56.59837347 39.53053889 61.36456466
36.6097801 34.60890947 23.25747424
39.98529231 72.08567211 47.86076646
57.57446499 39.50893769 61.42918066
56.87645615 55.93195466 57.36443634
60.05041542 39.44930417 61.64927157
36.48130444 19.87499245 37.00473521
48.37427044 10.640483 42.88470616
7.046288964 42.18846977 28.30595589
-68.17735283 66.8328155 42.06741062
-55.85533913 36.00292954 42.18825053
14.56914835 57.18918003 27.53133572
-41.43484245 32.06327758 32.73276101
-46.60947664 46.58087875 39.98791313
-48.12219317 46.29089595 43.64272984
-65.4734131 70.47102128 40.10329109
-20.67459123 44.82561469 26.28515447
-30.89479246 46.58839352 31.07714939
-49.58380222 12.75841333 19.49949091
-12.62220743 81.33847628 2.919789421
-47.20510319 29.73738869 31.70059536
-36.79826412 38.02389403 26.74640064
-7.085466336 53.42805773 28.70972628
-57.26818375 87.5995717 20.32365519
-49.61318987 29.49023257 35.02900105
-36.30902188 76.66593383 44.19937388
58.57124554 45.52827025 61.28197395
-36.98947114 64.93601882 23.17924628
-67.30358455 73.16197901 38.3469187
-1.869813761 51.32886444 40.43075784
7.984974871 33.93393934 36.55194432
-69.06592469 80.83422117 31.19099258
-70.00931073 43.66387857 43.35322399
58.46336409 70.43239489 51.87605476
24.7729883 25.40173337 33.34554753
-71.54797769 25.00894613 38.7943728
-63.97477526 54.89634494 45.82047565
-67.76911202 43.62405643 43.3882892
64.29875275 47.81951111 56.89787072
-49.60792696 70.64451393 35.06361759
-63.9700333 43.34298433 45.92667258
-53.6279455 18.45302162 29.90989755
-36.99481124 47.36781065 29.01905723
-60.92530133 43.29376841 45.91735822
50.38280128 53.32009218 53.96337525
-55.20703206 43.38258233 43.79583281
-49.69972988 65.06733538 35.73203413
-3.913187641 61.41949096 46.04054426
-9.634926341 61.31245034 30.89439463
-49.67952397 43.31984544 43.4699994
-74.23155276 29.01759723 39.38392639
-46.60666108 43.40510626 41.89421589
-46.55608809 43.52826231 40.45488585
-54.549583 9.18240695 9.519617361
-11.62681976 29.62364542 20.40937547
21.45389894 34.85796549 23.25656765
-46.55164052 43.57557448 39.90480333
-42.68819139 18.72592175 18.82895421
-44.60997297 43.77306614 37.241888
-11.28646096 82.17131928 10.47672914
30.89915417 48.61158333 54.0724218
-69.75734741 12.12497014 24.8735378
9.67050754 44.11246355 28.77694144
-35.68670876 44.36365624 28.68416565
-74.48487733 49.21590657 43.84779841
-67.80382682 55.08103622 44.40666729
-25.94529089 43.9840345 31.23334549
-8.036651771 48.39015718 29.10834049
-40.43341474 60.65520918 32.74430469
-55.8324723 18.8283983 31.79930882
-21.81180592 43.91833096 31.20779972
-20.9739969 43.92931832 30.92047519
-49.02651863 18.88887472 29.79931789
37.61108244 34.09715273 23.18072472
-20.91259662 44.1213462 28.67956565
-71.72078667 22.18193331 36.68551083
-34.19101889 47.26668954 29.65824495
-20.90659057 44.16787221 28.13831224
-54.67040996 17.98111016 29.76035347
-20.89821493 44.26275862 27.03520417
-20.36254086 44.31465484 26.33059855
-19.04990894 44.19762248 27.43886902
-44.86414571 39.34417024 36.26444788
-18.75155399 44.17100932 27.69091589
-74.64351774 12.00619826 21.35750911
-36.51826051 36.09678536 25.7577224
-50.0185465 83.0963957 24.60413295
34.62543284 72.55366729 49.27673537
-48.86163021 11.71660137 2.322674772
61.37299419 8.034534598 59.00424331
-14.4704083 44.04320862 28.3580797
4.864559996 53.88883101 61.86828359
-0.2981786305 30.38935394 21.01331416
-73.12222956 40.23197855 43.00039008
-46.6434845 30.20407985 32.00244064
-11.16091384 43.9595471 28.6981432
-40.98753924 53.34047616 42.01840884
57.37173611 8.913151312 49.56768167
-39.10915209 66.54744213 45.66339421
-54.6557587 16.21263391 26.98075833
-41.45213607 60.06074933 45.66614776
-8.109913326 43.90716908 28.72434147
2.638197719 43.73828795 28.63510758
4.956762002 43.70849072 28.53885147
-44.74804828 52.73966827 32.23025259
7.089330401 43.67340599 28.53944703
-49.53475089 52.10660051 40.4922194
-58.11014236 84.01987969 27.08005481
8.208823682 43.65380064 28.55354609
-63.40126714 48.32601423 46.23796661
-68.98845355 23.54917367 37.77243748
10.15470354 43.61377782 28.64706926
-56.59016966 85.75090562 24.1750968
13.70836851 43.55561386 28.64457381
14.22017603 43.54521578 28.66767751
14.72554185 43.53806716 28.65428758
15.63057106 43.52753484 28.60395999
40.78091784 68.52530596 13.29402743
-49.47349627 20.39799588 29.84550203
-31.50436129 45.14145832 30.51055485
-43.27200131 45.73883796 37.47317836
9.707193363 71.27169561 57.25757546
-37.86217619 65.63734331 26.85747219
39.28723003 15.7798449 37.3959627
-70.77310237 13.90288691 27.73445706
-16.21926337 33.67362613 38.36310289
-17.84537658 49.63471836 28.18443029
-77.18646998 7.746472047 13.02606573
-15.04873246 73.81035835 20.85474155
39.15828932 34.56223003 23.31334723
27.4060741 32.78275684 22.90542498
-63.089517 18.78485885 33.68870875
-18.79809193 48.64009562 28.25914375
33.08715852 41.75862969 45.80962869
44.68376251 64.32468689 38.00702371
-58.34137853 80.62767143 31.54353752
36.37357939 50.60926556 53.14442623
-70.12230258 12.56350592 25.67893464
-52.74807434 10.20152399 14.825328
49.83792588 40.29621333 59.5919555
50.17051401 40.27710942 59.75030112
17.29678176 49.76095122 31.67048911
52.38629107 40.34871797 58.49645464
-28.45208365 46.00614561 31.54382791
17.05085116 45.39835913 59.05498322
-44.52313369 29.22028183 31.36544053
5.940260627 49.94792183 60.7989636
55.36530645 40.03531793 61.56651536
58.67649791 39.95080038 61.91619277
27.12420999 23.89837005 32.86937138
59.71510897 39.92243962 62.04735579
60.08600448 65.33504417 52.47396588
-48.66499954 23.69723318 26.35120727
-44.55129226 26.87207317 29.49732573
2.420019569 35.86910417 32.62833108
63.27037828 51.88521417 56.5094667
-42.01126808 26.82967393 29.50512915
-50.55342468 73.63920067 35.43926592
59.19807189 39.9354757 61.99462554
-35.69041734 42.88365678 28.38586406
42.78160412 44.99920736 52.95497748
18.2806362 32.26364357 36.49858961
-60.20884364 35.8946746 44.27519273
-55.31179564 15.29135515 26.14746286
4.234936118 66.81139535 57.63982042
-61.14104912 25.31616457 39.06993414
-54.12497633 49.86111463 44.12827887
-70.24381211 25.4603452 39.13213173
2.915215202 56.51601538 60.8749662
-57.36420821 27.15428564 40.31798081
-54.67520851 69.97287774 38.0001813
-63.97180486 11.17541662 23.14002833
-69.57136367 74.82147334 36.99469901
-57.57443073 15.9504815 30.58062371
34.75984615 70.56372211 49.04527423
-62.88972642 64.13222606 43.27623745
56.85297201 38.62067763 60.22506277
51.08535511 7.95115804 44.45412872
39.52290853 43.14749116 51.76593954
-41.64192233 39.90585927 34.95609506
45.47044856 12.34826688 41.09320318
-18.01465915 33.3585233 36.53679809
12.02706378 48.4185177 59.91269025
38.56796719 32.60336998 22.85923859
45.40002899 11.40413519 40.41352039
-54.48401214 61.59319553 42.01493199
-74.57082365 44.23710115 43.39538671
-72.03034924 44.19447481 43.40574077
-71.51992703 44.18477554 43.42099637
-72.47906093 47.68855694 43.71594359
0.103305043 36.67274073 29.56758312
26.94942035 33.76976515 23.18788062
41.14576069 42.06776182 52.33745634
-8.567178061 61.07116875 33.4917419
-68.47997439 44.13797051 43.38460609
-68.02053899 44.12921322 43.39865006
-55.86078811 48.2141889 46.0981021
43.81715884 42.31264109 54.81190871
-65.00415967 43.85890992 45.96128678
-56.31574276 52.74063014 46.07839931
-49.51451789 31.62942641 39.31006559
-55.79356928 84.91452645 22.07919801
-21.008888 57.05409117 30.05785028
51.76291884 45.43755409 57.80682068
18.89951799 28.45406105 33.99185168
6.940111442 60.16648088 23.55676147
22.3839155 33.8427854 23.21087747
7.351427096 63.6631294 58.63301048
-63.45724691 43.83430849 45.95187255
-62.94821001 43.82530803 45.95928007
-55.74529274 32.00402302 41.97636227
-37.5883998 43.606616 32.00846486
27.41636773 70.72483942 48.5753522
-50.99010513 62.56960797 41.66699652
44.03480314 66.62281688 23.10569348
54.93383423 66.46065592 52.04287478
-58.88235656 43.75655382 45.98204654
9.939057639 49.88821577 60.72948055
-58.37453181 43.74816481 45.98258697
49.52542611 65.39600828 42.12693224
-42.31784675 43.35350074 35.84874603
-6.79365834 60.67209004 37.78631859
-57.36092566 43.73241755 45.9720919
-54.18167622 43.86312407 43.84845294
-53.67530605 43.85546882 43.84075287
-51.13386192 43.81235331 43.85660059
44.74911433 66.41115626 25.42658131
-17.47330161 49.14303536 27.99464755
-49.1879675 43.82187885 43.37492734
32.41895395 42.98290109 49.20474668
-22.87527773 57.58100981 30.12356682
-20.85175733 48.05882887 29.57191805
-49.49148507 33.62667182 39.42675438
-57.72207997 28.59368031 41.15660813
-49.75995575 85.35794385 9.954396111
-46.48241313 44.11720295 39.43062359
-44.8059282 50.81621582 37.09020863
-46.5992048 44.30000671 37.33077664
-45.37088727 25.95896621 28.60035744
-45.68575981 44.28522912 37.32812622
30.50194244 33.7121777 23.1789105
-45.17492201 44.27532021 37.34573626
-44.64478719 44.26806602 37.32884824
-50.19839334 12.72634928 2.508994685
53.24433452 8.758515442 46.32328653
0.1278305861 59.11328094 54.56214552
0.7104625925 64.83931092 52.07214861
-59.32678733 20.11549326 35.00408121
-11.65901066 57.21886842 26.36191737
-40.62641264 43.88876107 40.96576939
-59.53333453 57.88681492 45.21775342
-48.78228338 34.06181285 34.24006981
-56.59086977 79.48354688 32.83825615
-16.02938106 59.5739868 23.16200339
-40.30231781 44.02260866 39.35016188
46.18198009 14.93403388 40.07324365
-39.65196361 44.28921193 36.13120951
51.9283706 69.65202655 50.52812039
-7.08299901 44.38692466 28.7857907
-61.37726851 46.78949275 46.20911802
-35.03269244 44.83329397 28.93418425
-31.70808528 44.66767543 30.2227828
-31.19176181 44.6426104 30.41529001
48.59699769 50.46429267 52.49629797
-28.41037893 45.50766988 31.49590376
-69.54647842 17.0474184 31.78300294
9.071648185 50.69558245 28.21583036
14.4758498 47.46726638 58.83522967
-64.46124986 53.889532 45.94778684
-29.3665755 44.55196999 31.11943617
29.97624914 35.73844444 23.06334389
-49.9914677 11.54320192 4.551089285
24.36271842 28.32465216 34.45225956
-53.81952876 58.49248537 42.92367236
-54.27466621 17.52704337 29.12938052
17.98547602 30.75721922 36.56262899
-28.986308 44.53359251 31.26025611
-69.32783064 15.67825399 30.1556422
9.863638635 48.34527856 61.17548201
-58.31941786 48.24030731 46.26377597
-26.44316686 44.48962096 31.28571774
56.02649081 67.40946444 52.47331867
-25.11654013 44.46464045 31.32271397
22.29387027 29.88379757 34.22702612
53.6118174 61.37349931 41.43072551
-14.47007585 29.98099222 28.45654209
17.41468472 50.80501962 31.18097806
-48.10606507 30.70074563 32.34234567
-23.90120324 44.44624339 31.30450849
19.84526205 50.32227276 36.32153107
-61.09489032 30.56947792 42.16988644
-15.1951714 57.8876489 19.27259146
40.77907631 34.58708592 22.71570807
-55.35534724 17.08679884 28.61951169
-59.79629377 63.04162255 43.69354098
-20.89575591 44.61506708 28.7715226
-49.49108901 40.15528035 39.38415791
53.70543897 39.62707124 60.79564663
-57.43162527 39.77513098 45.31151815
-24.04016636 22.67327445 33.54196524
-20.88114636 44.70680885 27.70373095
-20.12869359 44.74846446 27.07666485
-53.16872809 43.84770875 43.83423002
-68.62988798 16.5783938 31.2263425
-49.40965077 24.15385818 27.0190215
-36.48230442 64.92796175 23.17605887
-19.82221586 44.69297599 27.66236843
-18.24662374 44.56718503 28.82216698
-16.73218868 44.54657775 28.77257946
-56.27948504 64.01887136 43.33152885
-52.92885802 61.04983187 42.19947582
-6.397099533 61.92914195 40.59783964
18.58087739 34.97015973 34.15532101
-8.898668903 79.42687666 -4.732273877
-15.79404746 44.56889809 28.33455948
-15.67526459 71.89454196 19.9078561
-15.31340948 44.5742232 28.18108108
52.88077954 54.70643928 54.87318969
45.9778753 42.66646194 56.11907761
-55.69051868 27.80333848 38.29075977
-14.80437259 44.56522275 28.18848859
-72.62476918 39.72660174 42.94566341
-36.55167543 43.87654121 28.67726583
-13.21449931 44.49417242 28.71009428
-54.71929101 56.96893489 43.30182565
-60.53264832 14.19497048 28.21746278
-46.98218585 11.52998741 -1.69603703
-55.79609006 31.00043736 41.98312263
-12.15889144 44.46962671 28.79372814
51.59639155 7.438932901 44.47631336
-11.65210568 44.46176181 28.7883825
-50.03203603 23.9417498 35.42666524
61.48857274 49.02073895 49.31569919
-11.1461511 44.4543162 28.77832801
-21.87320277 53.45423973 31.22589694
21.27476665 30.404534 34.20292763
-8.605988389 44.41184708 28.78691624
-13.54162468 38.92419979 29.33973676
-7.589230646 44.39451003 28.79427558
-37.03184487 44.8754118 28.82650009
56.72079484 49.19579398 48.19277627
3.056906782 44.22751285 28.70261842
-49.24391791 26.00478629 28.80705477
-10.51695541 40.20498953 19.72121144
-14.09603594 31.34185605 24.24059098
7.080757894 44.20472419 28.19979324
-59.86892978 53.81537399 45.9328842
-39.39241535 42.2485325 36.46484468
10.1750768 44.10571674 28.75903885
-64.85066227 13.37410377 26.91687175
15.64042748 44.02477888 28.65634945
-70.62058191 75.38217867 36.51235533
-55.28510373 65.76724851 40.3253786
42.65391684 33.02669445 22.99240503
-7.547921518 48.88122732 29.14107705
52.42868386 64.67482211 49.94532021
28.26521654 42.18240764 47.63631938
-41.04953426 69.14532752 45.00849143
-46.7869205 42.32388944 37.000223
30.19816498 42.1819451 47.273064
36.67708426 42.38927211 49.45733322
-11.63325749 52.48771041 28.83997351
24.31912867 36.87099515 22.64799715
-42.54065743 33.48351968 33.93636253
-20.66371199 22.95894023 35.40847951
-70.6740471 78.64544553 33.60001302
26.19450539 45.49371876 56.20422888
31.1991838 73.56437843 49.85035089
31.41784553 42.21091262 46.7041874
-20.49974903 25.38731074 36.32011824
32.21265859 72.04204936 49.84945938
40.50400759 31.61343425 22.32863866
38.24446377 41.83974147 49.71113647
-59.22044036 10.25198767 21.30046973
38.82325119 41.795556 50.1136955
39.98501659 41.70507116 50.94255404
41.67082494 41.57323834 52.15147202
-64.89262328 45.84867729 46.14790794
43.16627944 41.43831636 53.43255287
-8.212114514 70.57617285 27.9625398
-60.86951659 27.6902726 40.59097733
8.816266846 48.35328073 61.28232706
44.07190731 41.35314849 54.2485352
9.191487264 55.73162453 27.99733331
49.18565125 40.9787962 57.61906867
41.6522651 67.3291093 15.36089862
-16.88734112 80.20118825 22.76228959
-17.75926423 58.47584216 24.58668531
47.49970957 50.96252392 52.74836632
51.09269412 40.73536365 60.08132721
52.04372328 40.82551635 58.85340177
-62.45786021 66.20818452 42.40120045
53.29794377 40.63883767 60.78131698
15.48418624 35.42391274 35.30502361
-55.81998343 89.20481755 7.23924818
56.04238247 40.49332341 61.94717202
-20.88750581 46.52065808 29.95502203
-55.85379199 9.054070837 17.08479921
59.25595251 40.40849438 62.31908226
-54.02570221 81.43066947 27.2253651
-50.61294087 84.97721413 20.36349647
54.08930574 41.54140867 61.80597858
-67.92014235 48.10868618 43.79600461
-65.37473962 34.51613004 43.78358867
-64.65201069 59.51013583 44.82914444
-73.6558271 29.42761655 40.34096959
-9.084010593 45.40963452 28.94831504
-37.64587093 74.08587934 45.27227016
-77.16906599 8.742124814 13.11776452
-58.02704471 38.79849642 45.10927309
-74.55709269 10.25107208 18.4092488
-31.99059005 52.09564421 31.44698912
53.30901836 9.829429738 45.53227789
48.44390263 13.26510494 41.53610425
-23.42779362 54.49953528 31.04109496
30.55045005 25.16335394 35.01103364
-17.88186143 80.30641925 21.73035216
-38.23375778 67.12840845 44.57881094
40.4452085 42.14859256 51.53271349
-0.4886795853 44.78381767 28.74743162
-68.54505806 78.06039464 34.15905895
-57.81975929 46.72938959 46.22840103
7.853980551 60.10193364 24.13179269
-9.93417397 61.86650768 30.34620185
46.96327073 64.19070187 39.127704
-47.45077996 13.7571106 1.672460287
-14.73604022 71.38932203 19.76706115
16.18124441 36.26162513 25.44731207
-5.55148734 53.19936886 31.07197497
-56.51417299 45.71059069 46.15309722
-6.135232756 53.34516677 29.49076996
-54.8179581 39.9185839 43.14776956
-37.70845021 65.05758162 27.73181177
-36.55319038 44.86934854 28.80560455
-66.4207311 17.45955106 32.22920847
-73.51713452 44.71573935 43.46470125
51.6395846 40.3537194 58.58079581
-57.82399365 46.22930954 46.20786174
-49.83101351 12.52290897 10.62725893
3.013175375 60.53388417 60.82705846
54.883428 53.00724271 56.73686412
-40.10855794 60.05199571 45.51153777
-20.19212233 22.46661366 35.20718969
-72.5288486 44.70037573 43.45458137
39.82701383 19.97128548 35.24884828
33.54483888 33.66388771 23.15919741
-55.73830921 59.39938092 44.41497189
-68.97424854 44.64174006 43.45738338
-46.60286203 75.28391904 33.07270097
-59.37492388 56.34981375 45.55025226
42.17977352 34.02252522 23.17577849
-66.39799744 44.40762151 45.68389622
-64.45726784 44.34541884 46.03588155
-60.90114394 44.28601448 46.04731647
-59.88521738 44.26909672 46.04996695
-6.231089813 61.89495557 40.96304105
-59.40490341 44.26219634 46.03847278
-61.6586209 60.49125106 44.52206723
-61.69721173 29.62110285 41.64090081
-57.34990327 44.22907343 46.03108685
-56.33196801 44.21114239 46.04511707
-52.14375321 44.32844266 43.88469192
-51.63052574 44.31732831 43.91583992
-50.11557118 44.29645897 43.86919544
-35.86642383 58.03892239 27.28527241
-46.8432342 44.47931975 41.12235933
-46.33634505 40.34790415 36.54641127
21.83724705 28.87431531 34.37963193
-55.77506621 84.22953174 24.20094732
-59.92252809 22.9337483 37.36117698
-76.00004939 29.69335741 37.70311253
-64.70629666 60.54195194 44.51470438
-46.59796094 44.60337244 39.63548982
-49.56887218 67.28940485 39.04487971
-3.713627862 60.86616454 46.59926312
-49.47181183 34.91851413 36.07963541
-45.67418329 44.78160548 37.39026041
-52.61625369 51.85225304 44.0325322
-10.67279305 43.94870865 28.7308763
18.7604164 36.94947271 22.79705225
-44.32711633 39.82326526 36.4269895
-13.47156372 31.28794318 36.4006441
-40.18729756 44.3924702 40.86124519
-40.03328634 44.48822563 39.72027524
-54.1855461 10.68552386 15.30747973
-0.780593126 51.07675132 43.14975055
-34.08452039 45.27843681 29.41246019
52.10815451 63.8073003 48.42399133
-33.80988893 45.26379075 29.53010846
-71.57658233 27.38578848 40.34091197
-33.15050501 45.22850482 29.81398486
-65.51468023 22.55165612 37.0365979
-69.76090933 25.45213893 39.13530368
-34.35125722 80.7615333 42.89443567
-9.060961453 46.40243964 29.07199481
-29.47590672 45.03500359 31.35952072
-28.9594584 45.0222645 31.40891553
37.13489232 70.53021096 48.98136053
-25.9211335 44.97628057 31.36330374
-58.63060743 60.95268466 44.41458975
-58.64638438 29.56881217 41.66611806
-22.86857451 44.92311639 31.39833111
-22.29191042 44.91716879 31.35740186
-20.84745402 45.14247337 28.46643485
-64.41825705 64.15994371 43.24597062
-55.73057773 80.27035424 29.36699963
-20.84733556 45.19196932 27.89182704
-0.6187197707 65.71468657 47.99027248
-55.77309406 80.21746765 29.98905399
-17.73626165 45.05973211 28.83363279
-14.80644141 45.06848237 28.17331906
-12.14908119 44.96689404 28.84585601
-48.17063023 26.46114925 29.13121893
-6.708219257 41.43235284 28.05329849
23.93134346 72.50946377 51.82930942
-3.339465396 69.00355342 39.46277143
-8.59392701 44.90797886 28.85179726
-8.059826734 44.89872433 28.85737442
-72.33254177 86.08519988 23.29652979
-14.35990031 72.35268087 20.16523834
1.557130554 44.74294164 28.83180213
-62.38846898 13.33379883 26.91520582
-10.6948255 81.64801559 -6.867774592
-32.21359063 55.27098667 29.58766908
-47.93222936 38.90294968 36.14491926
-16.91364263 48.95317299 24.26533674
2.971026517 44.77305039 28.21263922
-70.85189363 15.70371799 30.15068547
3.985914086 44.75665673 28.20940363
5.511985786 44.73017946 28.2257401
6.016589679 44.72341519 28.20803372
8.427653989 44.67173502 28.34817045
-20.80242341 53.15521449 28.66635483
-74.85705481 34.46105662 40.40461405
-56.78578595 64.02649167 43.33962132
-23.57668278 20.17617281 33.30854983
8.755201531 44.63039593 28.76559954
11.70998903 44.57699597 28.82200952
9.240360545 50.89820922 60.79126707
12.01874977 44.56991157 28.84536794
-32.18263505 46.16592725 30.40040444
13.23200868 44.55256272 28.81539032
30.41249301 32.25065158 22.68250822
-39.40034261 55.6322601 32.59094154
1.347993349 36.99308651 31.4380472
-68.83167245 10.39249341 21.50229626
-17.77820823 31.27993941 37.31486083
15.23668293 44.52004839 28.81053696
-45.66195411 18.82033039 24.12674604
-49.74898638 77.78293225 22.14223755
30.56045965 42.55096061 48.74681208
-75.05737554 34.84132191 41.85506051
31.92076841 48.57940753 54.25111397
-49.64487257 75.28220645 33.67271173
-49.54395514 21.989742 28.86071088
-64.11851737 38.89794153 45.11652207
31.07234798 42.54052177 48.77037358
9.272018046 52.15071149 28.76534151
31.82298101 42.55831747 48.42063778
-56.53187465 38.28539117 44.95399446
-61.28493262 20.61436358 35.41290143
32.15945557 42.66114236 47.16280351
32.19242386 71.55004056 49.73827461
-56.55117429 37.79291067 44.84810715
-20.80326045 47.48259333 30.42539134
-0.2371949577 50.95130621 44.50237983
32.58002112 42.57288119 48.1071994
38.43413584 50.53617304 53.59997714
33.87602089 42.56335018 47.97068735
31.78718864 42.23521263 46.35165913
-10.96333914 69.16224749 21.59452769
-72.05408719 70.06724501 40.21895389
-64.18573494 87.11795145 21.40723231
40.7639824 42.11167926 51.900438
-49.68174229 11.04673275 16.08204196
-71.74352026 66.89728621 41.99907703
-45.66234522 34.97909192 34.64991595
-22.53876468 74.55907939 7.764792411
42.48244799 41.93859494 53.58200569
-47.76528321 34.04435351 34.24880256
43.88338222 41.826575 54.61524856
23.11333733 32.36463283 22.7513234
46.32615839 41.65978665 56.08559757
47.94193916 41.56327861 56.89779442
-13.39033347 32.24474044 31.10459221
51.20548873 41.78200891 59.56287631
-61.87249408 48.29817428 46.26960687
-52.24227843 40.86263063 43.3506704
47.21314597 50.51813979 52.13511007
51.10217535 41.23279696 60.13159115
4.888580838 56.89001007 61.98367126
-70.10125743 37.69605687 42.72989767
3.822566057 56.42997836 61.70070959
51.57829827 41.31473321 59.08961561
52.03795878 41.30586236 59.1049349
23.87975472 35.84042273 23.04214353
-12.95691562 81.80465102 -2.42807712
54.06893248 41.04946975 61.69400899
56.18068353 40.97533044 62.15194876
-56.02932621 83.43435132 27.65383053
-19.21912234 53.12464624 28.71926841
8.965563864 59.50976882 24.96744774
-19.64546097 27.31212701 37.11909208
39.63451418 69.51614918 48.623355
57.74367885 48.22049025 47.66646178
5.388336134 56.88569163 61.93849655
58.2562779 40.91943002 62.40505328
-38.52260472 71.57105817 45.50014362
-0.7119460959 60.2439964 53.24940729
59.29849078 40.88925237 62.55662134
64.4439499 46.74183569 57.72734192
-34.33194574 53.26525968 29.96903126
-63.90982257 40.37364899 45.42555627
-49.51894285 23.53332856 28.41678417
37.01209472 68.92166411 15.23819115
60.34122314 40.85881265 62.71113243
35.9179096 47.05105544 53.75113887
60.70657216 48.59088385 48.6282621
7.652498048 36.68706792 33.78822785
25.08101562 68.16437356 37.95798637
-49.32315496 57.97024054 36.47550323
51.16413152 53.14895797 55.80102188
-53.60033109 28.2546816 38.47926898
28.52508135 48.06805389 55.00821739
-20.16260358 56.64530966 28.81525182
-27.93147047 45.50147851 31.47644704
31.3225287 31.28271744 22.09216293
-55.76094142 84.67506694 24.8527969
-55.79706369 8.553746585 11.22882712
-55.75880875 84.62443543 25.44015786
40.45664869 72.09567924 47.65470583
-60.87963091 82.92581953 28.65558016
-23.36034 51.95442879 31.44048028
-8.072908379 52.42617469 28.87534511
-40.41377947 64.76214397 31.67739084
32.70916685 42.15418591 47.11644986
-14.90673909 49.43413906 24.12584383
-18.39054269 41.63887641 27.88371134
48.62617518 52.86154306 53.79490622
-55.85269947 8.470311199 12.2080162
-55.76287457 83.6963879 24.56110424
-70.76213308 75.93102103 35.99462813
-49.79108744 24.3777072 30.31980231
30.50761337 25.69956746 34.62108579
-49.73566138 50.30485423 43.96659795
-20.73127292 53.52242788 30.21654609
-7.544309883 46.38071427 29.03496425
-71.920572 11.72285879 24.12743875
-34.58348804 79.24814267 43.0273438
-16.83259807 24.31036452 30.64282462
-38.00438054 66.05596696 22.02490184
15.75512365 53.55858163 28.66531295
-45.88692343 33.53617455 33.96325936
24.83792501 72.04548807 51.21594651
52.40862708 50.39989019 52.51702841
-74.55473571 45.23341779 43.47962982
46.67929393 43.13696586 56.35000091
-59.36546425 43.76486345 45.97771373
-60.99383498 56.88817225 45.43597081
-70.5166874 45.1663007 43.48869397
-68.45959198 45.13212084 43.49317829
9.675488771 34.90327632 36.63006389
-55.79523878 15.64099397 28.00743695
-45.01259315 36.90797307 35.44070781
-8.547732003 48.40018838 29.08935704
5.384651128 60.61088758 24.52104086
-65.91920953 17.45434163 32.19404007
-61.40605037 44.7951474 46.05985964
8.22517368 38.47472566 30.40650157
-58.13198417 36.84256807 44.52856576
24.25767562 44.97740461 56.74070453
31.75300918 30.81112802 21.65798093
-50.60186657 81.94021274 26.48390968
17.48235903 49.71689417 32.14654484
-47.11672771 44.27039109 43.59991298
-59.36779019 44.75807987 46.10145804
-33.48974487 50.60189543 31.59349025
-47.08909953 30.68330376 32.35088222
-69.39273295 49.6272224 43.92848247
-73.5430962 43.22218741 43.32797057
22.24294888 27.39840428 33.95579183
-58.85996544 44.74969087 46.10199847
-52.58826373 69.40903569 38.32103758
-56.83223364 44.7179343 46.08395136
-7.681355958 42.4321177 28.28616312
-56.32378551 44.70923083 46.08802343
12.60943043 71.77179812 56.72518738
-56.52558625 18.68247619 33.62546036
-67.60890081 8.33004506 16.07830666
-53.17071058 44.84075192 43.95992001
-52.63684985 44.83161823 43.96414009
-8.134411813 60.59842629 33.07050925
-54.70540659 43.3773205 43.76125302
-45.65832268 52.30684511 37.42837541
39.35489949 30.69310218 21.57838496
47.41940033 66.91769297 36.51701851
-49.62796286 44.80048398 43.75175631
-71.68256165 25.95737856 39.46322487
-54.65450434 18.47530274 29.84701295
26.24447146 48.4999289 50.4296223
-61.31479486 23.89716095 38.09597537
-43.36949366 19.05147954 21.00621157
-46.65918991 45.11169701 39.57280489
-16.51630259 40.61817063 27.72209831
-12.5375657 59.97490476 23.6685859
-12.53859624 30.09024166 20.99330803
-41.03573619 26.90746645 28.41601687
-46.48910631 45.14978916 39.09816734
-58.53704192 9.39837862 19.42617632
-50.6668644 9.453811978 5.62849136
-46.57903018 45.29426186 37.43817162
4.982894219 34.96607224 36.79598898
-68.3039258 54.09348491 44.31297376
61.08419266 5.594804196 58.24828597
-49.71047423 83.88920886 21.16846821
-39.42036574 41.53075617 33.14936417
-78.05756922 8.019104046 15.85391971
-42.98392277 45.19041477 37.95809589
8.338288975 59.5536355 24.57783634
50.11344895 52.35242102 53.59489346
-39.43353886 45.28257303 36.21117575
-39.10144207 45.53627337 33.20270374
-69.89651678 72.6792955 38.61810811
-69.25754393 58.09669996 44.63571975
-36.49556827 45.85710257 28.98132786
-45.52360577 21.83817154 24.02690491
-55.78309767 66.13661628 41.95909954
-38.16527461 66.64120242 44.39495299
-34.40255358 45.79241464 29.33312216
-66.28368858 21.16037087 35.85438409
-61.36712826 48.29102567 46.25621693
50.65755592 8.470950616 44.32822188
-55.97790146 84.00973802 26.79115553
-33.41050361 45.73798549 29.77578564
-32.74920623 45.70173433 30.07050223
16.35096172 36.70300666 26.11770794
-43.13247644 54.03268336 40.2184554
-30.16087377 45.56063286 31.21490065
19.32848198 33.89739962 23.1595653
-68.73549098 31.20924687 42.0267308
-59.49455947 40.30208325 45.4143264
-26.41429941 45.47949108 31.44235951
-60.93223632 14.65097646 28.82665728
-60.8519613 77.36936667 34.88725555
16.07346916 34.32044514 36.34921935
-71.86316137 63.77089695 43.35545646
-36.2242917 39.94144583 27.6831636
-68.79704792 55.09888935 44.38882834
8.635142814 43.63697069 28.66761883
-21.8431494 45.40362319 31.45134364
-15.06973822 26.41442534 23.36108463
-38.0248651 66.45128589 29.09294057
-49.4994665 40.76083375 38.18269051
30.63333792 71.09095554 49.5383547
-48.56324746 31.18580459 32.62525567
-20.54613116 45.81480194 26.43073061
-19.25090691 45.58266144 28.87858029
-17.78752502 45.56302962 28.82740509
3.090786498 38.94048375 31.80544132
-15.31990215 45.58193042 28.13740026
-0.7376007438 38.73412424 29.10446816
51.67053946 39.87305526 58.32815173
-13.76595598 77.67655878 22.34155954
-14.29898847 45.56249694 28.16830388
-12.78205983 45.54063181 28.13284293
-47.1586988 50.31149638 37.57140684
-49.84390635 12.14442695 3.370130201
-11.96451919 45.47512087 28.73743186
-49.78321429 10.1049473 3.727760571
-8.049306516 45.39563353 28.91352444
-7.188086155 41.92885479 28.20769596
40.05921592 17.69662204 38.3039562
-58.62301159 68.24004483 41.38911792
-2.059693164 32.2709059 22.81338111
-49.48711531 63.2056573 39.82328318
-13.79611622 45.55660618 28.14078737
-60.87041348 46.78159291 46.20416479
-11.02879085 81.34090109 14.24105003
38.92625141 70.016363 48.77821887
-57.28894982 27.63092719 40.59706659
-4.527025418 45.34091194 28.87705242
-2.499432147 45.30922525 28.85822049
0.5428755296 45.26123224 28.83517197
43.3886167 53.02579607 52.88697113
1.572689849 45.23730892 28.91649959
-49.51642515 27.85377992 30.70111989
-63.2882918 9.062880219 18.4003374
7.542982228 45.19899209 28.22273862
-2.946948032 66.2944715 41.70371193
-36.08703331 43.37533034 28.58043654
8.556692282 45.18319242 28.21283215
-12.45860761 83.00371753 -4.789465192
35.58117076 36.13887303 23.17262532
-69.61771971 37.68753031 42.73666667
10.20186629 45.09663508 28.90390847
11.80536194 45.06826879 28.92740892
31.21312181 42.1655165 47.27022082
-68.32738025 10.38588637 21.48282405
-57.59599378 37.32218719 44.6852213
-53.26687953 23.0452669 34.79731514
-56.40420096 85.74288895 12.57941113
-16.14742661 34.66659864 38.47553545
-51.47659998 18.86112526 30.58869921
41.13606306 32.081761 22.59806408
29.71369751 42.93520524 50.27434366
-65.07971251 26.32519098 39.76082788
7.912184129 41.4162469 25.45206895
30.71992688 42.92317914 50.22205749
36.84651722 42.80601923 50.41375768
-49.44146557 40.58290858 40.23711489
-23.15631627 57.59888785 29.96962113
-20.8588084 47.91371859 31.25780903
32.68439631 43.0224461 48.69505776
-42.15029414 36.87645023 35.26079035
-62.72068693 73.61956795 37.98754715
19.98358761 31.41606529 34.35985161
33.33482813 42.90495349 49.93495675
-54.40750217 25.89060407 36.94393398
33.66405185 42.89993564 49.93042259
57.93231238 53.88603063 57.60709389
-56.35073819 32.91742081 43.14171812
-1.967103682 46.29290762 28.9906812
-12.12219663 50.04982618 28.10075188
36.26761666 41.99449685 48.29161937
-44.95918085 52.57361577 34.19817583
33.92821142 42.9649056 49.12582692
31.53352893 41.06713264 42.48003533
-56.78339139 33.89232226 43.56014198
-78.91368722 6.349648356 17.91752424
59.86363211 56.86664869 57.59743533
-59.77236343 11.5414912 23.91614984
-37.35037563 44.63964605 31.62418711
-31.77739098 45.15529637 30.40198094
36.34395666 42.81175276 50.44304
-17.26436134 27.25078439 37.377112
39.02829887 42.74610933 50.69315676
41.19945499 42.51811439 52.92583619
-72.46938922 48.18589416 43.76728665
-71.5468642 42.69171567 43.27873933
25.9484846 25.78176028 34.53638476
-55.67701028 70.4353307 38.6493804
42.78285104 42.38838682 54.12984567
44.10265635 42.29251376 54.99111546
-55.40671698 30.67347345 39.8778612
53.83310523 7.680084084 47.07694103
45.63433404 42.18887503 55.90212944
-61.19708125 73.05495183 38.42482153
45.9696928 42.1683735 56.07617125
62.54612038 52.78421226 57.86464314
10.60141767 42.63178346 28.30830355
47.58199296 42.07362122 56.86864976
-77.33199214 8.70042215 13.63295055
11.73756295 68.81425079 56.2649801
50.78915664 41.71946742 60.36830067
51.3115563 41.80283765 59.30085325
-61.46033131 8.629680432 17.25399257
53.60955819 41.56041225 61.67686193
56.20417374 41.46569704 62.28157655
56.72259614 41.45196215 62.3421549
60.16325969 41.3516893 62.85004025
-49.54534015 67.45097931 37.16471743
36.49381532 48.51497169 54.12702473
-45.82157322 25.49826068 28.20787099
-14.61985902 80.12282539 23.23956045
-47.07486199 72.61448931 35.01819857
-46.48259158 71.65818183 34.35348226
42.68126045 34.01733327 23.1404139
-7.521494018 47.87585309 29.15387321
48.95737711 40.59173097 56.32929643
-24.94901031 43.47481094 31.12814664
-58.62624797 85.78687252 24.14585915
-61.07467644 9.43957241 19.43191086
45.2505868 66.7099648 27.6888012
13.99355848 70.81249097 55.94428144
5.400238504 59.94253944 61.40995158
-37.05368186 43.88199522 28.70968739
-23.62926713 57.6144629 29.87900919
-63.02627764 26.77029582 40.02876461
-54.84879543 71.56709014 37.00642679
-55.5053547 10.63273145 16.17202809
-6.982816978 68.79630685 30.91016674
-55.88746124 89.43042001 -1.19348366
-63.95422167 53.88154481 45.94381462
-74.03356817 45.72051416 43.55231076
-6.748246628 81.70137298 -8.239822604
59.13260849 66.32485102 52.81866584
-73.5501661 45.71205603 43.55831129
-70.50833174 45.66430178 43.53258134
-69.46196794 45.64441916 43.56384553
-63.6784812 72.56556259 38.75258607
51.65119261 63.86436557 47.84868131
6.462495449 54.37787446 61.71301531
-62.19428296 8.249921056 15.97584445
-44.53861294 19.40011145 23.00863713
-68.95497438 45.63644944 43.5596771
-5.163016386 53.10251966 32.12218759
60.71610557 45.43742941 61.92748315
-65.99031707 45.37984791 45.97311743
-27.75723167 76.15747635 -3.968734144
-65.46039657 45.36031291 46.09883495
58.6864272 7.288432147 50.69796551
38.06013915 18.29047343 37.61797253
-17.71106086 81.75168639 22.39998647
-64.8963468 45.34833956 46.13026263
-3.480871573 46.81539437 29.04059917
-63.93349369 45.33341635 46.11988028
-61.90119035 45.29935371 46.12773188
-41.86030808 45.52227932 39.71793109
3.323590109 57.96532959 61.45231227
-45.67279005 52.81635907 37.34296544
-61.39655182 45.29257196 46.11022169
5.700040503 60.9691841 61.08801885
9.203770079 51.44366082 60.2928856
-68.18384895 25.90215691 39.43705261
-71.09562503 83.1236134 28.3076959
52.84516466 7.279779968 46.08572849
-45.8591963 57.93255024 36.25244192
26.45495168 24.36651949 33.38902359
-53.12006996 76.94371444 32.52737791
-58.34662492 45.24073551 46.13033769
-49.58464083 76.44781905 25.95658314
3.865848634 67.45023847 56.12069109
37.3471037 42.8012815 50.37329182
-15.52503669 81.10069788 23.71360766
-7.652393758 42.91972401 28.44679088
-56.31574154 45.20738915 46.13014499
50.01659609 69.92262379 47.75141662
-54.15252255 45.35506584 44.00326693
-52.99613586 79.15022858 30.19547957
-27.44257243 75.59564477 -3.33323132
-75.66481169 10.79173188 18.17076483
-6.041781448 60.99325149 39.74129595
-58.31677551 64.05544971 43.29542411
-51.10522774 45.30455713 44.00847155
-67.19799371 57.54667685 44.80137998
-49.12047528 45.29448109 43.74693733
18.41440317 48.79407272 36.85494321
-47.08619788 45.25942264 43.76597246
-46.57073224 45.79229206 37.481732
-45.14895456 45.76886924 37.48249964
-44.63652364 45.7581567 37.50913498
-64.10188456 20.19512905 34.99025052
-58.50946144 66.66372849 42.18657113
-15.12224163 72.84300733 20.44519497
-43.83733662 45.82579335 36.57154942
27.07612584 71.1013531 50.09603393
-15.07522477 26.36763727 23.90528108
-52.17228985 33.03964768 40.92596391
-41.01446967 45.39293813 41.05811186
7.555656621 52.72583899 28.242803
-39.56595188 45.60380505 38.33397321
-38.37127099 71.22030496 26.23651595
-39.5135652 45.65171263 37.76783652
-24.08189175 21.18767437 33.31592979
-39.26753552 45.87449518 35.13469442
-43.79640117 50.9264155 35.61840713
-39.23209158 45.90617151 34.76021299
-46.67918306 17.05870066 27.29109905
-66.86407826 20.70314856 35.44619715
-39.08996945 46.03270214 33.26424932
-37.21248539 46.188237 31.10063926
-37.33222161 46.36013719 29.12793057
-37.00307216 46.36754574 28.97915586
-25.0987771 21.24224257 32.87638833
9.184147496 46.90883718 60.50040221
-59.02161529 66.67430127 42.1615054
-66.7558192 34.59335663 43.15046605
-53.84253404 82.57809362 25.52357831
-34.43567801 46.28656212 29.42965544
-31.52528581 46.13166769 30.67275396
-26.95608076 88.20003024 36.70560148
36.01029908 73.11302477 48.34584394
-30.86568543 46.09627257 30.95785664
-55.56273484 68.92059313 38.73184388
-58.14340823 29.562869 41.63919017
48.56596501 51.88748348 53.46070134
-26.91214074 45.98506007 31.49492796
1.201315616 68.60277386 49.07600125
-47.52480876 19.85434074 29.95838039
-68.72744701 31.70740519 42.06885236
-63.79035819 24.40925952 38.44990711
-26.40265362 45.9758325 31.5048861
-25.58558328 45.96011451 31.53153178
-41.8649509 41.37634494 35.40807986
-23.35587831 45.92558584 31.50714768
9.143142089 55.20424008 28.3021758
-55.82952743 89.61273654 8.332287981
-22.84950814 45.91793058 31.49944762
26.7741977 70.72666411 48.67663562
-22.29113816 45.90882235 31.4986981
-46.7596426 34.0326244 34.19318095
-13.34114029 45.0478824 28.13301673
-49.69265843 49.30350759 43.92947783
-21.00459027 45.90405925 31.30863917
38.10350342 67.33524414 15.96645122
-49.84012797 73.09928075 35.7443795
-20.90283368 46.22439689 27.57051991
-20.53812183 46.31297773 26.47265595
9.093736 42.64898405 28.39615052
-55.8588889 73.515596 37.88594337
-18.13293684 46.11559621 28.30532185
-17.84505969 46.11904634 28.21037025
-45.62960128 30.18819283 31.99351518
-55.05940484 73.17997821 35.80293198
-17.3496335 46.11691168 28.14067024
-73.99047379 48.20985572 43.77920332
-5.767570116 70.06160258 33.46985867
3.617066768 35.61215463 35.38290527
31.981761 32.70460035 22.94011162
38.2137847 43.28753176 50.38990365
-15.92593645 46.14207636 27.57703259
-12.27988002 80.8684581 8.310820478
-49.70142698 11.71900564 2.454918567
3.71285404 58.70201059 23.6929361
13.52164866 44.01186871 58.34349317
54.09874909 8.699181646 46.84913464
-49.51694801 28.31336292 37.01932749
-66.23406537 60.56928526 44.488754
-66.31058831 29.21605553 41.39614341
7.363243026 64.10982928 59.27177991
-49.56901302 60.68633401 39.95184042
-64.36987791 77.43427555 34.80463361
-13.69838449 46.10863375 27.54045125
-11.84007978 46.01367599 28.28840044
-11.48289231 45.95738531 28.87374604
-9.066338695 45.90293611 29.04498084
-11.22476961 42.48511049 28.34673446
-5.008589324 45.83826993 29.02183597
-4.005476917 45.82781614 28.95189158
-2.490418457 45.8068944 28.90583571
-13.51288619 31.40789934 35.01598567
-1.960182629 45.79958926 28.88951995
-7.079424098 77.9193078 -5.05817798
-55.77490607 47.79253973 45.14989405
-70.16759069 35.70826506 42.51172236
-63.90885662 49.84093263 46.22841122
1.075610435 45.74249357 28.9733845
56.47109465 5.430735956 49.3793721
1.711744312 45.76849161 28.55026556
34.20609708 43.32694475 50.69665901
3.454942873 66.40530552 56.6761158
-60.26442972 74.10925129 37.66116334
-61.96608236 55.37640839 45.69111986
33.68165186 74.15234029 48.37807959
1.963949642 45.78993607 28.25322555
-11.59955429 79.93060551 19.0683573
57.49810555 45.57404881 60.9551972
2.465055618 45.78493629 28.21570273
-47.42662112 38.89567877 36.13290274
4.574912784 45.74891911 28.23145405
-2.362856288 66.14849889 43.28687895
7.034681777 45.70983709 28.21605396
39.91115505 16.69254438 38.33497681
32.11103967 25.31768725 32.92180576
-9.649472417 60.25914128 25.64482545
35.34822751 31.22182994 22.03126459
9.704717606 45.60185467 28.96040097
-47.87215907 70.25781986 33.39498213
47.64911849 66.91424304 48.1665428
-57.64958908 36.3447908 44.38849106
19.15196234 43.06628542 56.59349753
-72.10696983 12.59831683 25.6533121
41.6183938 68.99190849 48.50414492
-20.16064162 41.52828351 29.50512313
21.99284548 43.11989918 55.42933619
14.82293532 49.51553022 29.16465126
46.43490413 30.59055824 21.41859041
29.05078252 43.30104344 51.98048994
30.00993858 43.28798517 51.94916299
31.49398334 43.282708 51.72740792
-58.75396759 63.02377666 43.70193124
-69.43513467 47.13753143 43.70551397
31.93211955 43.28469489 51.62078746
-16.68910912 48.28147844 20.36675493
33.14918986 43.38931269 50.17420485
33.36593667 43.32953245 50.82684268
-60.9236915 27.21538461 40.28751426
-55.04029831 76.43141478 33.01408442
-1.627809441 60.00555642 50.36541185
33.60222369 43.28467319 51.30254042
34.76260103 43.24444426 51.54825635
45.84836202 13.33550893 41.21378847
-67.8083057 21.18611439 35.84628812
36.79829563 43.25822663 51.00004126
22.65581165 31.41755044 22.17971797
37.06792278 43.29566066 50.51406012
-49.82344499 12.22841578 19.87114976
37.43424424 43.34626277 49.85677411
-69.44941721 8.744800708 17.44115904
-63.92593457 45.83181925 46.159255
39.03116798 43.19732234 51.28124058
40.47960691 43.06133909 52.58360767
-72.38423388 15.73335737 30.09883637
42.33995771 42.91579349 53.91842731
53.44906937 66.13752529 50.25052333
-49.48746275 47.89157326 42.801187
46.4073015 67.23001412 33.08437937
-56.18487141 6.901564593 7.175954508
-18.23363549 29.25303288 37.6249603
43.19666671 42.8553023 54.45727383
-71.41072824 79.76132217 32.43987374
44.73447729 42.74856989 55.40303198
29.84810049 37.30973592 22.32700685
-70.52536053 44.66845975 43.44300808
46.53467087 42.63337005 56.39704816
47.20288327 42.59363068 56.73093981
-16.2001063 26.08596748 21.56298997
47.59017546 42.57170965 56.91155612
-55.86048458 48.71625181 46.09637243
60.20585335 53.32717326 57.83449935
-64.42737214 27.75055046 40.5697323
-64.1234154 58.9890533 44.95080367
-65.90192333 48.87554852 46.1621025
49.7854546 42.25721253 60.14381591
-69.74990412 59.10172006 44.71589078
-51.94367985 85.15293615 18.57736671
-19.59260317 41.56385382 28.9838686
52.65935285 42.26939426 59.45433172
57.21263565 64.94426862 51.73170136
-55.7460678 76.24232095 35.34381853
-5.733233817 61.7924838 42.05766424
54.09748824 42.03949711 61.84888494
56.73091717 41.94998071 62.38584607
-71.97949736 23.59608493 37.79826725
38.13662287 35.08425926 23.27472977
59.34921155 41.86809879 62.83706679
60.13501849 41.84337348 62.97423806
-49.68836468 67.67179386 34.6286163
28.10991883 27.84008996 33.53615651
-37.26117148 70.17622832 26.49193527
-58.73998785 13.27736143 26.87458516
-25.37202048 50.97365637 31.55636006
1.78032867 38.52942553 31.00057625
-75.2077468 29.6034108 38.59618172
9.113592507 54.69215222 28.42585986
1.782875138 59.72884229 58.75391564
-49.79436631 78.35759547 21.30642247
-53.288182 11.73950483 20.38089021
-47.08830133 45.76269974 43.75060673
-64.72537188 8.287812977 16.01866123
18.32498295 31.38550833 35.03088508
-20.15578334 22.95049881 35.40960855
-61.94670829 14.66716048 28.83224729
-66.34240679 49.40173957 45.96433656
-55.60429686 34.54309048 41.60732981
-11.33084569 82.24326445 9.650001321
-15.12742905 25.99752557 28.21176411
57.39448024 48.67585563 48.27348342
-55.94763653 71.37805485 39.4104424
28.36779832 68.47049554 39.60413099
-40.82949229 79.99543047 35.54343081
23.86137599 72.0425422 51.43637809
-49.61566821 83.77851124 10.78216344
-61.39549771 86.44711851 22.83599504
34.51432853 36.63413505 23.45335642
-49.76264084 12.04241762 16.19211493
-68.74855671 30.71362173 41.95615991
-49.67355979 11.54482119 16.12494832
34.20099062 41.36355333 44.35690786
-46.82137224 40.84700915 36.67157459
-49.10589681 77.90453298 20.60796625
5.666081923 46.23342871 28.22546132
-74.02483154 46.21832308 43.59835636
57.2245215 41.93637614 62.44964455
-73.54237321 46.210341 43.59901038
54.07984381 43.05282973 61.74202696
-37.81522153 66.05965884 21.94596999
6.968658202 33.44883787 36.550533
-71.51209086 44.68303868 43.46194071
-72.50627517 46.19563684 43.57211692
50.05031134 9.509145237 44.04518796
-71.99509104 46.18555323 43.59168898
-70.98252387 46.17033007 43.57530784
-15.54632809 32.66423907 38.29918998
-66.50186689 32.60505574 42.87712002
-39.57745162 67.03810902 45.88332325
41.36104097 44.51980149 52.96453972
-75.77942078 11.28981677 18.23712942
-65.14781707 45.85351819 46.14037823
-13.56496463 32.64348365 38.16227609
-59.40354253 65.11253435 42.88454886
-61.94775728 65.15704748 42.85300495
-57.21955405 28.58796425 41.12712961
28.47943303 35.24794797 23.21619579
-62.37586734 45.80562658 46.16771154
-60.87569598 45.77982557 46.18113681
-62.40086128 51.83243791 46.1285443
-71.39749521 17.99446476 32.79527962
-62.4433368 7.87333796 14.5683508
4.539533343 35.49371477 36.58192189
-60.36641666 45.77070282 46.18991774
-37.76294209 67.7448168 25.68002067
-48.14519248 84.88362232 9.326080532
-59.86077378 45.76341444 46.17809743
-22.84158043 51.43830779 31.50641804
-59.55502609 45.75784994 46.18438624
38.95141885 68.55617722 13.2845477
-57.32310463 45.72220318 46.17255909
-18.7266044 31.83934168 36.82841669
-56.81995531 45.71617266 46.14661221
52.65300027 40.33806822 58.56922162
-51.60261885 45.80989901 44.06359063
-50.59309937 45.79621323 44.02994368
59.39876336 64.42950607 51.4638921
-50.08128776 45.78994273 44.00513069
47.32816699 66.92747696 48.07412116
-49.61925171 45.80052177 43.79420832
-47.98320385 55.90489672 36.88942133
-48.10190748 45.778447 43.7611018
-49.4993717 62.63529033 40.62021577
-49.49187009 29.25558894 37.72977962
-46.46945118 46.14430637 39.20261929
12.76682688 43.57217561 28.63187054
-53.93246538 75.86591966 33.54085331
-49.78429238 61.02458934 41.89282198
-55.17949509 11.70244623 21.17177835
-54.97531162 80.95321455 27.12246554
49.05251344 13.28020889 41.24470076
-44.2090063 46.24293835 37.62654661
-45.57646537 30.66360493 32.29109218
-41.88339992 45.97419918 40.30276037
-41.53297952 45.93371101 40.7059493
-40.76861484 45.8454685 41.58456406
-40.08621603 45.77379506 42.28646418
-5.511054502 52.17454084 31.30793408
-69.19135535 33.70250506 42.30331073
7.766601558 53.19597933 28.57148324
-39.45881714 78.69601956 38.7132638
-39.2376528 46.31208143 35.87581606
-19.93775259 31.37818762 36.58616183
-39.19704128 46.36592888 35.24297058
-66.51503746 23.03815825 37.40634119
-39.15642976 46.41977633 34.6101251
63.85222443 44.83989819 62.43934416
22.99352219 30.94320226 21.79525346
33.0089816 43.41048376 49.95517419
-38.1973339 46.55541556 32.85262029
-58.51865214 39.30092077 45.19715796
-37.5070325 46.67797857 31.29817457
-49.68219803 11.14607406 14.92890229
-36.48249338 46.85493911 29.04850136
-35.97054733 46.84447115 29.07238987
0.9808813701 44.76106887 28.73126218
-35.09358953 46.82332134 29.15067119
11.23649409 46.36244787 60.62525235
51.75046585 8.86785961 45.3388292
-42.08909807 25.90579893 28.59170512
-34.81718314 46.80777994 29.27837482
38.52650151 34.08137934 23.18925783
-11.25983634 41.49836753 28.15497247
33.05250416 34.16667893 23.24296204
-48.74845631 54.38782758 37.16668273
-49.11328618 73.15385559 34.97222177
-33.65648407 46.74261086 29.81355288
13.21489091 44.0589816 28.72186376
-32.16406269 46.65877462 30.50217151
-29.95956493 46.53785007 31.48554114
-49.84092243 10.86593329 6.557967292
-44.45441429 56.88849083 36.4514634
61.2506585 56.81120013 57.97660844
-26.90267683 46.48250211 31.54509381
-41.04029996 54.25909357 31.36448752
-26.39239315 46.47287272 31.5595646
54.46790901 55.00271087 56.95781895
49.73084998 54.35606084 53.71470771
-62.02361951 66.19974754 42.41633084
-9.925488127 84.2170119 -7.704071518
49.82180914 43.79507759 59.76414548
-23.34911576 46.42439055 31.54200975
-44.83773765 57.41062584 36.28988217
-22.53897197 46.4121666 31.52941496
-63.54040274 19.25435259 34.15111033
-49.06338372 31.1903152 32.66827216
-21.17405252 89.08100966 31.20252187
-51.9209208 36.93057506 42.32242897
7.616938674 57.10909968 23.96015345
4.853800986 65.593894 60.00216199
-22.28174351 46.40629933 31.54847155
-55.17265882 17.98668646 29.79140161
-14.02459792 73.78930014 20.90389199
-48.76338978 43.3408781 43.05112464
33.03352763 47.01938427 54.66887007
-20.89280108 46.77110777 27.04862857
-17.34453332 46.61655496 28.1661146
18.46936961 44.42170406 58.4689062
-59.46368052 31.99661277 42.77150485
-56.16967817 29.05613708 41.31866099
-56.12860094 61.43167913 44.2035656
-55.75206781 44.24072915 45.59106185
-0.2389227135 38.12902178 30.2071976
-10.8932657 46.43472599 29.0466227
-44.73102664 22.72773963 25.20228174
-45.16312259 51.30039245 37.36429297
-77.48530368 9.707857237 13.62042804
50.7475443 40.73602634 60.13945644
-52.87263943 67.29584377 39.60018042
-38.27706589 63.84442546 30.27017092
-55.7076801 13.02953102 23.34666064
-1.09188822 60.83875934 52.24406305
-3.4896082 46.31758546 28.99455357
-11.05967772 32.85306798 23.59821086
52.77209168 67.56444504 51.29482405
-24.47313009 75.94461586 3.702650099
-16.80944345 49.0988335 22.55453151
-10.63047905 47.45526484 28.80262962
-0.9520429481 46.27642661 28.98842662
58.24709697 63.58479807 49.8362267
66.00850415 44.24108077 63.15299753
-47.14499335 49.80236677 37.65250036
54.45399355 71.19787353 49.58107158
0.6316564055 46.24565745 29.04359653
50.2133217 66.0534377 46.01710836
35.35319342 42.05932362 47.71344891
10.07689085 33.34281177 37.18860289
-49.4648348 48.63015337 40.04953524
-39.94223726 68.05310887 45.82331288
4.499653875 46.25193202 28.23310629
5.368030008 46.259889 27.97513122
15.31978575 43.5193795 57.89111086
16.16411213 43.48991245 58.07216778
16.42242288 43.48349924 58.09735569
-47.47810703 24.59850525 27.31551772
-54.80335983 57.99099684 43.10629884
54.48654934 65.03096949 51.24509538
27.46760947 34.25836397 23.24368816
12.24138192 46.85331451 60.56191689
52.24470854 68.08491612 51.18003191
-57.57541273 37.81402129 44.7983681
-69.85950665 52.1259837 44.14325866
19.33064227 43.47836799 57.60230801
59.17583643 7.791548707 50.5907289
23.16036908 43.52872569 56.28736693
-18.80772183 59.41449055 25.54339889
-10.24922295 70.05422871 22.75685449
-3.219561504 60.3624998 46.52530862
29.34849699 43.67785797 53.37601697
-48.60319578 45.28132272 43.801041
29.70271559 43.68500955 53.22544459
-56.10545246 79.48646111 32.71185366
-37.34371378 62.81517386 30.38719702
-2.451338931 52.42841871 39.43051142
-67.19104883 58.54760562 44.83382567
-49.44037896 42.39300171 42.53058947
30.25163294 43.66828069 53.31496364
-3.549451763 29.51354775 20.14706694
-46.61361867 20.3676945 29.65186676
-40.59814769 59.08299201 33.54724209
31.2511089 43.65966127 53.22441801
-61.19736647 36.39998483 44.42434091
-41.35545714 28.27806058 30.0460556
-52.83562609 66.7749567 39.81331812
-15.94228608 58.271183 20.78936806
33.0154781 43.71119985 52.28964471
48.90496704 44.58634124 56.58006717
34.10335889 43.73231899 51.83701283
-52.82722293 59.50430384 42.64178816
-75.68842951 7.895190928 11.01394279
2.653867262 63.80276357 57.9078878
36.21968875 43.68064779 52.0332531
-51.64549398 13.9175031 23.9170313
-9.621045171 29.60296496 20.26693607
-72.12879802 39.22197614 42.88250003
-6.557433101 44.87180232 28.88338934
-44.8626772 32.56018366 33.44466217
39.90435542 43.60595692 52.19763118
-55.81399965 9.914540735 18.74153006
-49.49022901 36.83755719 37.11205903
-38.21193834 50.05351696 33.03337777
55.53179376 47.8677031 46.35705118
40.45896404 43.56157929 52.60703191
-48.21431097 29.75091723 31.73600814
-61.37355364 47.28983484 46.22671428
-2.590997461 61.14896989 48.92880109
9.589725601 52.13911394 28.83938552
-21.93944896 42.43890447 30.92648168
16.18858671 32.25351105 37.0151817
54.68267319 42.51907834 61.99659865
-49.49358984 41.30964105 37.6372549
14.03014906 71.79846524 56.14467633
55.71910451 42.47942821 62.25923272
-70.02778834 42.66876738 43.25544293
-19.27472889 56.74254219 27.51718233
56.73643294 42.44941435 62.41364485
-47.86332791 42.81158761 37.37058501
58.30704246 42.40047038 62.68229754
-17.43996397 60.97452132 24.65250608
19.9036949 49.13969766 38.38530087
-41.47075221 59.06570802 45.56758188
-7.694352423 41.93645763 28.21598463
-49.54302819 29.73405549 38.01178779
-49.50467063 61.1065374 40.8881834
35.97696751 42.01722289 48.08322752
-36.70812176 70.24413741 25.59812692
-20.83621436 49.55763643 29.64962453
34.15393542 42.92537476 49.54168295
60.89199171 48.06946819 48.8192341
8.457817992 69.16212078 58.67875973
-49.49224944 48.69353814 39.31894657
16.22115471 74.44320533 54.1579694
-51.10755055 20.68382575 32.66564559
-15.83633469 62.48990247 24.23495659
21.88212602 34.35034599 23.24107885
-48.55929693 36.96262553 35.48263993
-56.31497454 64.53898974 43.12702405
46.50869718 66.32310107 31.93986915
-2.389144476 46.7974065 29.04121663
32.00511269 33.19503684 23.06895461
-1.137962776 36.85288554 27.71304759
-59.62250866 30.06786807 41.88550668
-54.71945562 55.437592 43.59888027
-50.29017517 26.29810052 37.25485974
-20.95396983 48.30861186 26.69174686
-59.03152909 71.94924099 39.19445452
-30.36745491 77.18350426 -9.555189675
-61.8884297 61.01028061 44.36726014
-24.92340673 53.50621594 31.20421133
-60.46331717 78.45030714 33.91807453
-20.87479399 44.75316016 27.16443956
-23.79143888 22.64691756 33.80050141
-46.97677398 12.02947346 -1.668826856
-38.96200504 59.22399114 31.59839871
-12.18795754 43.98207281 28.63251177
-52.94044999 87.23672936 12.05718602
45.35914029 42.70602075 55.77784644
-12.27231197 46.53570476 28.13737971
-13.15051763 57.9779044 23.66155354
-8.160768265 31.87297885 22.76967943
25.34543196 32.32889872 22.74047726
-50.8540609 30.60693466 39.78207329
-47.87771577 57.95965974 36.32267886
-55.80946141 27.64033736 40.20567911
-58.83911053 50.7657631 46.17875498
-73.53446489 46.70856775 43.64036347
-58.99749684 57.87668398 45.23317339
-21.9901278 74.93320769 9.143650849
-52.44087719 72.59576259 36.2589235
10.97923662 35.36727503 36.82163659
-17.99852839 23.42995506 35.25896997
58.99586823 54.34730273 57.8761252
-19.21551693 46.56924135 29.07217351
1.21672228 51.97231758 49.85238744
-47.85351657 57.44523665 36.46322168
-51.68569794 65.18379498 40.58546383
-67.89727023 46.61527832 43.64828832
6.038360091 63.50782484 60.68630923
-12.72694088 71.36696443 19.64345718
-12.75652478 39.99005673 16.81676617
-58.61976657 11.95784737 24.68961737
-63.91755664 51.35196935 46.16877606
-69.19167701 72.13125268 39.01913108
-66.36613326 46.40041201 45.85061661
-39.17892565 45.95368601 34.19849086
-64.43094491 46.34100457 46.17120957
56.29253271 38.63082971 60.21408919
35.12729379 43.20914546 51.88848155
-62.3188799 46.30387366 46.19946879
-58.38941688 65.09652504 42.87699682
-61.88645309 46.29635168 46.20432309
-71.36966358 53.14886496 44.21018428
-55.11368171 48.87225781 44.14292085
37.40350467 47.54721226 53.53471741
-23.34249173 46.92326515 31.576087
-52.00061532 29.18309838 39.04973595
3.903641955 62.51478408 60.96807293
-59.85421902 46.26232398 46.21178228
-55.89608598 57.83604369 45.1134978
-39.26331274 63.29459984 31.01439045
-8.281107837 57.05114323 27.66480771
-56.81201524 46.21438338 46.18814515
18.51395562 31.90389917 34.80362857
-29.12864426 75.95061119 -7.132397104
-54.64907332 46.36219984 44.05969748
-54.13830478 46.35232587 44.07691511
-53.63231557 46.34486279 44.06705682
-37.50493122 69.0155433 22.53257718
-57.68172357 29.07553892 41.38178647
-49.6318489 46.30909234 43.71939327
-47.55690527 46.27829804 43.68117174
40.44552811 49.00601071 53.4997109
-46.77446775 46.28005027 43.51161508
-46.53666791 46.43265071 41.69476765
-70.20774641 59.10967372 44.71087212
-46.92501948 19.14201898 26.45986548
-65.19789804 25.38037653 39.09818027
-9.519734163 37.59397489 26.53503645
-43.77686485 46.68822063 38.20161397
-49.47842323 53.65916818 39.93806141
44.54236361 51.93492403 53.67730172
33.04171013 47.5174727 54.71177643
-38.96603736 56.65300045 32.31189967
-38.60074377 70.05427092 45.64309594
-41.74039092 46.46622026 40.39038882
-41.42724486 46.43214587 40.72623044
-77.83901255 6.575652039 15.08896119
-37.7460525 64.87166798 24.07056251
-49.6250553 83.18857798 17.63233064
41.80723989 50.97219598 53.72167433
-66.38528214 34.55510973 43.52380039
-49.83269189 84.54552895 13.57273212
-64.4590627 82.99698134 28.51210107
26.39166117 73.51170379 51.37866027
-37.80529585 47.2810951 30.18027904
-7.216266445 59.88083878 35.39905241
50.79238716 11.01312777 43.9242654
-35.96236483 47.34255959 29.11529623
-78.23319408 7.983807127 16.29716489
26.07917741 68.63385524 38.14418587
51.78598093 9.376938228 45.248941
41.31645032 45.06928871 52.4208355
-47.8471568 17.00365011 28.15290575
-29.94293201 47.03167578 31.57632088
28.61323037 69.25201022 42.13821503
25.88838821 28.87492722 33.59995148
-28.41873049 47.00614189 31.58206242
-50.26141708 80.85649654 21.51962904
43.82521196 43.31301082 54.85063287
14.15751759 46.37563556 59.91510323
-41.47480019 21.82757268 23.37781262
42.31434684 44.46013859 53.4753491
30.03629072 44.55833946 54.67686089
32.89330128 49.09898426 53.86066102
-49.91156447 19.29243122 31.10996413
-22.83213879 46.91360082 31.59095021
-49.48538167 42.01259831 41.12853099
19.01997781 45.43333216 58.27346633
48.24465317 13.84305267 40.69150395
-13.41402185 34.61337283 38.57214281
55.62768655 40.02685196 61.61475705
-20.82188751 47.03932926 29.74803545
11.68951325 70.23363675 57.27679817
-47.29971541 64.19024213 33.80299343
-49.70430682 65.5223045 36.27793445
-55.81936269 20.17976622 33.58906659
-20.86135988 47.20790786 27.79857933
-9.217013641 62.50917476 34.40216895
-20.66766947 47.30842534 26.59476094
-19.20861585 47.06797618 29.10782038
-18.71645793 47.14182373 28.15668652
-60.86431414 50.79624435 46.21112487
-16.24714217 47.23420413 26.61335383
-53.0851445 69.4119041 38.38249709
-16.67697671 47.72358663 21.01421576
-12.27957572 47.04158491 28.09277982
-43.85645977 18.57190842 20.83964953
-8.490772518 46.88958547 29.13475291
-71.6546547 19.38305604 34.20446336
4.231049974 67.96645485 55.88506931
-30.43723165 48.03767421 31.64550457
-49.53051327 76.17273635 29.13962189
-5.791597291 46.84123751 29.18126241
-51.61194424 45.31238709 44.01420959
15.21462382 54.58788533 28.47276272
-55.76929411 43.31932031 44.63745134
-71.55532873 74.32893615 37.26414327
-1.874263582 67.05513195 44.32212514
-1.449849652 46.78197813 29.04119128
-0.4345811215 46.76539229 29.04011391
-29.43965332 46.52336396 31.55455619
-5.374071392 61.20820299 42.94528707
0.5902807033 46.74396721 29.0933846
-11.39983197 82.16749256 22.19605671
-43.44399242 20.01915824 21.44018018
-58.52894632 78.96786167 33.36766851
56.72813022 42.95581861 62.3631589
1.098985834 46.78468982 28.52363367
-48.0050228 31.65420783 32.90787312
-61.01094157 19.21728177 34.09907203
35.74621354 18.91925817 36.58650063
-69.43380179 82.52644148 29.09654294
-61.29256232 69.33982567 40.78444032
15.99378797 43.92937982 58.82963273
-21.35811428 52.93997058 31.27103726
-77.4237886 8.170178444 13.97927177
55.01707916 43.52826734 61.87070475
16.97778305 43.92856959 58.65138465
51.25433547 40.27549839 59.56231178
-15.64888117 46.80186863 25.69148682
17.46392765 43.93111694 58.52910242
-44.95897477 31.60432804 32.90601445
-0.9381091431 66.32845993 46.75269352
-43.59459966 50.94850785 35.32345823
-55.84190492 21.14601378 34.0297405
24.19459093 44.00479506 56.39021485
16.91201693 35.96066824 22.97503524
-55.49246646 72.64843761 36.22938979
28.00838457 44.10035689 54.5535498
-9.890913444 70.92747052 24.20457651
29.46789266 44.09506809 54.336609
9.648706683 46.42005706 60.2592833
6.116963612 57.419747 20.63998643
3.985168489 43.75260079 28.21207817
31.00761107 44.03692901 54.71789646
31.01009913 44.13478535 53.58143399
22.0639107 73.46479101 52.74858619
-60.98500752 32.02069662 42.7820481
34.45573702 44.1063368 53.25458164
15.84654111 35.8605725 30.16685437
35.00808503 44.02593274 54.0826347
-19.30059271 55.14982947 28.53156389
-64.12865487 58.47709134 45.06838996
35.56587766 43.96756004 54.65389332
-64.01316476 55.92014222 45.59609247
-16.44441785 61.97231632 24.5327986
-62.2154834 68.30539562 41.31558411
35.87228337 43.96166361 54.66390998
-70.39499155 47.65315925 43.72942137
-30.50787545 46.56666175 31.25564017
54.60643619 57.0779744 56.1467908
-12.23722317 76.21642462 21.52040114
-66.35780769 11.64233722 24.00133933
59.79837942 44.40037883 62.48805729
-23.80095249 75.80376486 5.20956326
-47.83132608 56.93182684 36.59238477
22.80048991 32.37377872 22.70481311
32.16274459 30.35000326 21.10627372
36.95043265 44.37174846 49.69773534
38.36415141 44.24089055 50.94722503
22.18405356 71.64310049 50.56662338
-52.09706419 48.32261901 44.12160114
-4.124253183 42.87443559 28.29969431
-49.05401476 47.97025865 41.80508839
14.57036818 34.83755107 36.45956764
40.46262263 44.06194978 52.62430934
12.59237828 35.34643183 36.75596393
45.29364194 43.74349285 55.39988588
45.57420428 43.72585502 55.5511338
-51.90539137 30.13949557 39.5823014
-42.08002975 35.40869149 34.80628244
-59.71478371 45.26188862 46.14569306
57.75795197 4.799711477 50.63271268
-55.73742051 76.78973063 34.8140791
46.9041412 43.64964901 56.18216187
-64.38900104 25.36878151 39.0785222
21.93899725 72.02115438 52.05127272
48.1143725 43.5842717 56.71031107
49.85830299 43.27445258 59.97434013
12.61959417 70.76223906 56.78966565
-39.29122537 62.54722557 45.52242276
-67.33438088 53.07075403 44.3474004
55.68099948 42.98847704 62.18373011
59.72561848 42.85610078 62.94911728
-9.556030181 48.91547411 29.12647368
-48.57200124 24.15253706 26.87461316
-50.64554964 77.44896298 32.01681905
-49.99209212 80.67108232 23.62069106
-39.57150061 62.53994072 45.66044077
-74.65592963 39.76008786 42.94428649
-63.79693335 62.58765313 43.8998545
-5.650934031 78.51860428 -6.461029826
-59.02976701 39.31096944 45.1779783
-20.46435128 74.60954506 12.60998862
11.21863946 56.20237636 27.97255337
-49.75923471 62.48716051 36.56273071
-49.78755466 33.50346501 40.91349137
5.227373894 57.59593599 18.7643058
-46.70834699 73.63225906 34.78656969
18.59648678 71.49536523 52.96581303
-57.35887236 32.93040778 43.18321316
-56.37090646 22.87661507 37.34710559
-44.72989272 19.89970539 23.07210515
-9.621659612 58.66412973 26.67565704
-49.10404942 36.48742637 35.27634519
-55.8350315 18.25313967 32.65117807
-72.48325776 70.08551334 40.08872715
-13.72475446 71.37474527 19.74342052
-51.89102872 22.09013513 33.96951684
59.60596838 69.84935967 52.5998108
-74.52179791 47.22566671 43.65243248
-38.742304 79.61156951 39.60153034
-46.56987965 22.07527951 27.3005547
10.36749912 50.61179295 28.94139353
-73.52609479 47.20656155 43.6843326
21.25807739 43.49720515 57.01605892
30.52055858 34.70721901 23.27747637
-44.95204694 45.26643639 37.4509145
-46.71471467 34.51217694 34.444258
-65.17029223 67.30481858 41.84123073
-39.2095169 41.04606865 32.90911957
-66.25222087 87.1692572 21.20572901
21.82883197 45.46593587 57.35931324
-60.63599542 21.53922374 36.20597542
-11.6022014 35.20513571 37.18364301
-21.2252753 44.40962231 31.21931717
-65.31761177 35.49174447 44.10033154
-67.88918874 47.11341772 43.69062243
-64.4228663 46.83914542 46.21352732
39.25645147 35.2627076 20.98961278
-38.8524747 60.22272733 31.63673391
-12.33378877 80.94520759 7.430137014
-55.72418782 40.28327071 44.91368354
-23.21368456 75.6809679 6.523084591
36.91910215 47.07873689 53.23885889
1.935870816 46.29392727 28.23452352
-15.7816826 29.25506039 37.13382117
-48.57506571 61.07694914 35.22774246
22.25654973 70.16755701 44.37541893
-11.74088091 36.93040652 34.6618096
28.97438685 26.21948102 34.7045862
-65.68647815 24.91400851 38.77865484
36.49365131 49.01727045 54.12264633
44.3431501 12.44614304 40.17196776
-41.67298719 51.81938819 30.67382081
-55.64448808 46.87098665 44.16980139
-59.40487204 54.82110597 45.8223965
-13.40685169 54.0465848 28.56157957
-54.13559426 46.8531746 44.0888215
-53.62856607 46.84518742 44.08484928
3.529337841 66.96910511 55.94357199
2.009492694 45.23996841 28.80232533
-11.96870298 34.66479737 37.69953811
8.9964901 60.02116225 24.85156273
-51.0876068 46.80231648 44.09795016
-63.61633539 25.82812339 39.4254355
-73.80026488 12.33082163 23.25487182
34.36381214 30.77019186 21.63530199
4.160181245 54.42425235 61.61369211
-48.88573207 46.83138223 43.34062297
-66.13696029 38.94979027 44.8995532
40.09568199 45.13998133 51.83299174
-49.49196276 32.12026471 39.43439592
47.7103951 69.39441274 48.49644158
-60.88827471 77.91467851 34.39044369
-46.14452418 47.28216621 37.58482775
11.77599599 50.06807564 29.15801128
17.01421877 45.91905403 58.84400375
-72.23664401 71.6659822 39.17438297
-49.92127663 10.59101407 15.59139669
54.6354276 51.14825799 55.05804672
-44.68299804 47.2634705 37.52313937
14.76841012 45.02309073 28.8867977
-43.14135888 47.22914037 37.6276688
-49.53907337 68.50180585 36.61804265
-19.25409982 74.84815077 15.4359185
4.493842731 44.74821532 28.21053267
-0.925167852 58.39224577 51.47997882
-68.5337102 41.1517809 43.10087681
6.483977341 33.94776818 36.67765834
-58.93581344 65.62658727 42.65448959
53.20791013 53.20821815 54.72332551
-14.83769433 76.71719825 22.02962187
-42.86812867 47.19042331 38.02501813
32.9642468 42.52772918 48.55808271
-42.59507162 47.1517936 38.42138645
-12.73521986 81.49459579 1.128992143
26.38347867 73.01361535 51.33575391
-41.63976158 47.01678615 39.80646829
-24.35011159 50.45150485 31.59635094
-17.57776469 57.05108328 23.61179029
-37.98905657 47.55389544 32.87509925
-39.45526939 68.50031335 28.88568462
-44.09788107 47.36475928 36.23571987
-55.67608077 85.53092145 20.72786989
-37.87822698 47.57232203 32.64005401
-50.64602947 43.31183321 43.74732966
-3.203825145 66.87671113 40.82027819
-6.778866882 70.27440773 31.19232122
24.8335898 32.33931426 22.71717736
-49.61345047 62.81672559 38.53573801
57.61005923 58.04153863 56.04150683
64.02850912 47.35450559 56.52089028
-2.837344275 30.40654224 21.298014
-33.54540103 47.74056379 29.86068449
-41.86698874 28.71620193 30.88398416
-32.79945097 47.63683251 30.92261591
36.66036803 71.5262339 49.16257577
-32.59597857 47.60852628 31.21241193
-0.9055938308 52.04512741 43.58525436
-72.90292941 11.34451615 22.88021658
-30.84691309 47.5438954 31.62913715
-46.88583829 25.04973527 27.79100207
-25.86986578 47.46149916 31.63650005
-52.98384843 83.24182206 23.48141833
-24.85923805 47.44725434 31.60913157
-55.74180457 71.42289192 38.85068746
44.10815858 70.48151023 48.21687037
-22.29753007 47.40630584 31.59595855
-21.1742928 47.3972008 31.48744836
-37.44833766 41.32221705 29.36751937
-56.53045908 30.49699925 42.14080733
13.70676567 43.9928217 58.52930195
-20.87557025 47.76684789 27.13934636
-55.78473048 38.45361393 42.85865675
-20.70977246 47.80710198 26.64042876
-16.77342159 47.67978489 27.36773288
-16.7553397 47.91844219 24.59377601
65.31687457 47.10104711 59.21752282
-61.39760413 65.66668911 42.65843637
-62.40982049 12.89190909 26.22241535
-16.75430089 48.3886974 19.1345113
-49.78152971 15.30156066 24.97433481
-13.40167046 27.91061897 34.8072728
34.04065569 73.6176952 48.68952499
-15.61432505 48.40831552 18.68936953
-7.821072067 82.63992458 -7.277338273
-55.19091097 52.40194971 43.96889546
51.02803588 54.64992468 55.88258183
-9.574270546 51.43575542 29.00589189
9.337648974 60.52472878 24.76737473
-72.09100487 17.09208876 31.74969322
-11.56171487 57.72156018 26.33439053
-66.08215203 78.55911298 33.72652236
-6.052678165 47.35126412 29.13693187
-4.503680907 47.33800012 28.99550703
-51.7710842 66.23129934 40.09483408
37.00839639 32.14452955 22.65657185
-49.50113451 38.15148361 39.341045
3.44568896 35.64904888 34.9872925
3.636965623 63.579405 60.31331475
15.28238803 49.50676419 29.17879333
-53.11976591 79.71480866 29.49163982
-2.986024985 47.31576811 28.96416633
-2.480105045 47.30833997 28.95391563
24.39159559 25.87333877 33.77018274
65.54722655 44.77776122 62.83742844
-1.493634467 47.29389207 28.93351148
1.959682632 47.2987366 28.21870429
-15.05162935 36.16559461 38.34504374
-12.73665316 43.4964738 28.54769812
10.6134223 71.78755197 56.92295584
2.980700213 47.27925071 28.25019652
-69.14788916 35.68944304 42.5357589
-50.86549819 60.49788704 42.38671831
-77.31385317 7.209401893 13.45841995
3.486273825 47.27199727 28.2379838
-49.61266131 61.26012372 39.12583546
-66.17518111 30.65439868 42.15290619
17.17982581 50.34694246 30.7168201
-9.316424447 82.65090577 -7.119643267
-52.31651337 58.97985466 42.80594068
-45.67731779 53.32085907 37.31386554
12.51967373 44.47440903 58.99171126
-56.84221717 70.3169398 40.24596056
6.020512343 45.22365246 28.22680719
36.29403546 17.93581662 36.24525313
-65.9406923 64.18458661 43.25023539
16.05454311 44.40094847 59.17037424
19.95903217 44.4383709 57.9913377
22.7958733 44.46924576 57.091917
-52.28819876 31.59146879 40.27966305
-58.15603188 8.195090601 15.84223766
-16.79884839 48.88860715 19.1663305
11.22077982 69.72009048 57.50116757
64.56394174 47.23307864 57.82839302
-78.70119812 12.18805997 14.19350754
25.67703441 44.50254167 56.15593898
-14.31275902 26.4175598 34.83362066
27.10620345 44.52494605 55.6233013
-20.85547924 51.77444136 27.22557218
-66.1468332 84.76034129 25.84352203
9.730910363 45.97548556 59.57787601
31.17133139 44.58044709 54.20376028
-61.73975 29.14034487 41.40336172
36.32248079 44.93056159 49.15701975
-66.00419966 23.02824934 37.42395122
-51.6725282 42.83186798 43.68823486
13.71951792 42.57033544 28.42699659
47.71581977 45.44063318 52.71624069
46.56229519 44.22519471 55.39264021
-50.88950125 76.35697832 33.0866153
47.54098843 44.17750324 55.759635
-61.03275806 85.83625536 24.03152302
-6.292362874 59.66256263 37.75676777
-12.20956149 62.62451936 27.80721959
-42.23430892 45.06005747 39.32842296
-50.0325795 44.79397739 43.90445249
49.98034545 43.78943896 59.79936896
51.11951018 43.75991054 59.92491096
-68.79153629 76.43030171 35.64944976
56.7078099 43.46828503 62.24459074
6.029628707 53.25451019 28.22326534
-58.53671529 58.38185258 45.10757466
57.2302497 43.4525236 62.32792856
-3.871110358 67.51731024 39.33763217
40.98774524 50.98913688 53.68129449
-43.70608031 52.64193953 38.99269157
-57.98032025 31.48946486 42.54932122
-6.218873674 61.03302833 39.3133098
-50.56865158 76.8955675 32.5997319
-54.27685558 32.10573038 40.51562901
-49.8336907 80.08896215 24.52151796
-46.47475912 18.73478535 25.27482229
2.633351099 68.3016202 52.29891559
-66.33868917 62.63092587 43.88224096
-31.96300798 49.06843453 31.62394294
-10.11248339 44.93066509 28.8780367
-5.178570544 69.93793212 34.79319011
-78.55568211 8.425698601 17.05550758
-14.4083758 62.45097267 24.41456181
46.35038033 67.42737143 48.28409206
-49.03226775 17.05635569 27.76706865
-73.69588703 47.70770523 43.72571236
62.55415144 48.20400219 52.76712298
-70.95551743 47.663355 43.71795729
-69.93553883 47.64439322 43.74356344
-13.51826239 73.78166236 20.89599572
-47.65248449 30.21750358 32.03903063
51.14653235 65.32825065 48.43099954
-48.08187422 18.3809207 29.6892286
-68.92418381 47.62978152 43.72031522
-49.53787244 23.59243313 27.73426507
-66.35626191 47.39986455 45.89964139
-44.07461917 24.11388072 26.46568631
-64.99011664 16.97901029 31.70820868
-29.97237283 76.1284438 -9.035904042
-62.89834393 47.31566572 46.2176373
-12.75804593 78.66811692 22.2918963
23.46947846 74.5035329 52.07534373
-11.21515839 35.35678537 35.34937143
-55.73062071 60.57337955 42.43815416
-71.93966024 25.96318032 39.44490404
15.05007107 34.84334788 36.30079178
-62.38646714 47.3052327 46.2411334
-54.74609299 41.89119631 43.54110365
-58.4699199 66.1415661 42.41403159
-42.76158367 54.89957766 41.73746427
-63.75973287 15.59504201 30.05975794
-63.46308305 76.87108083 35.34303694
-49.64269115 69.15540233 34.87701882
-70.26821985 12.13489652 24.85573157
47.16628904 66.99581693 35.65836822
-49.61341046 79.71674738 17.14716694
-55.14295736 47.36798863 44.13123561
61.60140857 53.76963844 58.25854084
-37.63795934 61.79811524 30.59678842
-50.61778616 12.20789039 20.26090913
-53.11266272 47.33493926 44.12770747
-51.08146764 47.30143566 44.12928059
-68.55599271 84.8110606 25.71417643
-72.00063866 17.54872038 32.25818773
-50.57495894 47.29371053 44.12236533
-5.86864979 67.97983792 34.34921515
-21.15817636 22.97297864 35.33980867
-49.66965693 47.30781845 43.78594375
-49.47215415 47.35685711 43.17900214
-42.33708748 18.77181129 18.22927726
-1.357171212 52.62647038 42.7493601
-46.81036645 47.35214679 42.72606395
-61.7517371 68.82145761 41.06296157
52.31475817 44.45208599 57.48833266
-55.82309508 17.79445814 32.14697075
-69.18066188 10.82297541 22.39814237
56.58014709 67.39034507 52.58968474
-46.57381515 47.38148778 42.34034048
3.540497513 56.80486802 22.44265731
-45.12089998 47.76358147 37.62763777
14.89297479 46.92808045 59.18830439
-44.27564046 48.08053367 33.78702861
39.64882313 34.06034726 23.21938009
32.3267588 43.7860616 51.55193766
-43.96976428 48.09968221 33.50640592
-2.825516049 69.44473523 40.06983871
-37.34707093 45.86541177 29.04725526
2.212439218 68.39005607 51.35255769
-2.993776903 69.48005717 39.69188415
-44.52366593 50.49372728 34.95342058
-40.70937562 48.28857021 30.69188001
-38.1536236 48.31257078 29.92586656
-36.67256002 48.35835314 29.11194357
-34.45276005 48.32882095 29.0314456
-37.93148894 51.065146 32.88944788
46.75850358 14.37638548 40.61024602
-23.33236064 47.92258666 31.6265833
-33.49066363 49.59792684 31.59518377
-50.07176386 49.79873704 44.07943149
55.83468885 7.512850595 48.63659566
-1.450544051 59.96569223 50.79437901
-55.48543748 13.87229911 25.1740927
-52.06510841 34.49222906 41.52282178
-10.87285121 30.52030133 21.50983485
-20.81984043 47.99317297 30.32801303
-11.40471497 60.85388201 24.90201712
-73.74383649 10.16294454 19.27720495
-27.29974224 88.72693602 36.48107372
-49.65508429 68.70899363 34.23497935
-49.94314139 17.02028047 28.35956526
-55.39112313 14.32693912 25.70495428
-52.13750055 83.72594023 23.52665849
23.49993704 73.4837363 52.25479658
-16.92182513 48.45508455 24.22243038
-64.4033421 84.72330021 25.94102776
-68.23439416 30.70203568 41.99260537
48.08314375 65.42311556 36.26066121
21.68308808 72.47901647 52.6115199
-16.87246429 48.57885205 22.77623326
-74.21885842 6.346741074 5.402658282
-49.70060243 11.81290008 18.8446914
-60.54326547 58.41531321 45.1018004
-16.09315296 48.90429336 18.84965306
56.69035352 71.06087638 50.74494737
-61.52646314 26.26723665 39.755978
-60.6774262 85.83045643 24.03107727
-72.49526796 78.14514709 33.92851936
-37.50713671 68.818433 24.82119831
-15.03980465 48.69505356 21.07778194
-14.87102431 48.65946956 21.45867953
-14.02729574 48.48163695 23.36218647
-71.62490155 22.64816989 37.08145147
60.42715497 41.34245901 62.90686579
-50.37392692 22.06818265 33.93503691
-55.80585864 89.65035274 7.891097762
-40.96649958 50.32802672 30.371979
32.61429203 74.13738811 48.75520742
-12.17538736 48.09257152 27.52557265
-49.50352001 68.43431558 37.39473887
10.63029538 59.43812373 25.48168207
42.17597218 15.56153808 39.37932749
-60.37422857 52.30345179 46.10082132
50.51304412 50.38976334 52.99608735
-9.563252117 47.9146851 29.09245838
-0.9480673439 47.78106923 29.00060152
-65.92708908 20.223834 35.00509989
0.09255241873 47.7516952 29.14314427
-44.16625879 29.69114271 31.65792661
1.452490431 47.80902254 28.21829811
-50.72892659 57.4156986 43.18112089
43.99472755 67.09569296 23.4504864
13.20575275 32.7737191 37.37171225
-68.87446541 50.61799768 43.98128503
23.71441068 47.08686876 55.66267251
12.60721008 44.90769029 59.77181349
-48.36161838 57.45376542 36.46111163
18.0269151 31.38719881 35.06810414
-16.95640769 61.48059034 24.51211128
6.624910781 64.53848887 60.26303197
-64.96070261 53.3914818 45.99812477
38.32464001 17.77872164 37.68167806
-53.8964912 25.88060781 36.96252502
-55.84543227 30.19434051 39.69701082
-34.44998998 52.27081826 29.88246299
-49.31130564 58.49125713 36.25154342
16.08419796 44.88820537 59.33492606
-27.69730143 75.64980692 -3.913406811
3.747604099 63.04615626 60.655915
47.0431687 10.28939413 41.38760997
-2.973511397 49.82053587 29.01781222
20.39740265 44.94245564 57.88259516
-57.33045594 45.22369546 46.13436158
-49.4985997 53.36535283 37.52609096
33.27860743 44.1302384 53.20160009
-37.61570877 68.17276233 26.51067944
-52.657719 77.48525815 31.97921096
31.66392616 41.42923741 44.07823248
-15.46439837 60.46686348 24.34237563
-27.2727531 75.55953667 -2.94644766
-57.1706119 20.54593835 35.42260582
32.79096451 36.2083629 22.89804523
8.885124416 51.40617587 60.78880593
-6.430477926 39.65505248 25.32596719
25.70201384 44.99215705 56.29400348
-48.16868681 49.32098646 37.60929794
28.58535682 45.02435233 55.37038621
31.1746653 45.08098135 54.21919832
31.42333808 45.10420777 53.90214567
22.13581706 48.60839258 44.12739992
32.5393854 45.08634071 53.8967226
-49.47117031 54.05861412 41.12625934
36.02758961 45.45764349 48.92114667
36.57061712 45.40671893 49.40875769
-48.99645561 24.62108666 27.34293437
4.871328347 52.90576259 61.62588677
-7.52318075 79.47635988 -5.569025185
36.87441231 45.37736149 49.69162468
17.14109857 30.25971815 36.67237147
-56.50616794 38.77463968 45.09617921
-62.0717961 58.44303086 45.07153356
3.835294613 57.4279896 61.76592106
-52.87238898 14.79749548 25.58869587
-37.83668819 66.67847388 26.41968971
-70.33143175 81.98146959 29.76751704
-56.9276352 56.81924333 45.46069931
-8.553578657 60.20188479 26.10050714
40.37351699 45.12371928 51.96878873
-31.17128543 46.6039786 30.94895524
56.58623502 58.08721815 55.70647496
-65.48323187 83.01805702 28.46275443
-67.38923804 28.2725703 40.90123157
-11.96874677 34.16260343 37.7027393
45.44481035 45.53889427 46.18200738
53.94878638 65.53189871 51.35913884
-0.182466426 66.14550571 48.73245432
-37.86400248 66.49402949 28.56606414
-61.02928069 57.91048457 45.22826483
17.85334041 49.62886743 33.09767528
-56.47597257 14.13084587 28.18823564
14.8083138 73.89795941 54.93037838
47.82313776 44.88428158 53.32766914
-47.15550534 46.80103224 43.3629832
48.68839338 44.64603413 55.92841035
-18.98089011 24.86225586 36.29904201
14.34866124 51.03842082 29.05619526
-19.57156627 43.48777249 29.95216848
-14.04332202 36.15252029 38.30452971
0.6599730845 58.99351365 55.85100904
-63.08876316 39.37626456 45.19405989
-71.43630111 49.16475145 43.86026251
53.52142011 44.11595282 61.16029477
36.13220742 46.61418382 52.95515458
55.83211532 44.0157915 61.8823771
-33.02233466 52.62275727 31.35127678
44.95410424 13.89237943 40.74641039
-1.462174827 48.78467062 29.10138928
-49.81171761 23.01201404 34.52441844
-49.47455525 55.38686578 37.36087141
-31.5756382 47.11194384 30.9558774
59.31021696 43.89663335 62.60235718
-23.33162419 48.42443119 31.62730616
-66.82370004 62.1255906 44.01439526
-66.86126302 28.74384378 41.15629807
-51.16357415 55.37830994 43.60894177
-0.4182557792 67.2936574 47.10211959
-17.51017045 58.04619589 23.70018807
43.65736495 69.95891438 48.54287258
5.376050633 57.39410496 61.86544733
36.50587726 31.67280401 22.4018986
42.99221255 52.5005418 53.23346187
-11.33567878 34.71714192 36.97116276
-64.62491046 75.80849777 36.24656244
-71.22331266 81.43359764 30.47106074
-45.20448212 43.28579949 37.18517044
-74.50248911 48.22035862 43.7549224
-10.24837742 41.48370342 28.13231285
-50.60336356 11.36962797 18.33603852
42.08079659 42.44519751 53.60423162
-33.10178039 54.67169704 30.88740284
-73.68476074 48.2043087 43.78529592
-49.89775121 80.80359683 27.89101607
-71.45311634 48.16880169 43.77189916
-12.0999318 83.47366995 -4.486776595
-69.29352312 67.90473235 41.48995958
-70.50695873 70.03014585 40.35458138
52.63485681 50.60788278 50.05935488
-5.357251863 53.15094426 31.59708128
41.49664109 42.49209086 53.17126123
-76.01983392 9.612838645 14.44399945
-74.94070543 29.10276641 38.53045996
-7.326600665 68.31884223 30.69184515
-58.91263504 64.58568523 43.08033815
60.99026153 4.588199137 58.29832358
-67.37019602 28.75279182 41.14947917
-65.43454935 47.85835463 46.22801874
-64.62132324 47.84457584 46.23288596
-13.08834925 36.1663501 37.96186407
-13.04064908 62.79597534 25.97532651
-64.3688135 47.84108888 46.22520998
-64.99309148 54.91446815 45.8042872
-63.39871346 47.82251003 46.25588298
25.19163173 49.26068389 47.62564644
34.53106679 32.6575191 23.00049727
-51.60342865 64.13786294 41.05843538
-62.8920316 47.81469754 46.24994873
12.1368056 42.6006067 28.37741845
-50.44959614 62.04251568 41.85614935
-61.84801706 47.79599998 46.26790385
-46.57125788 20.21577778 25.58070692
-59.20100723 29.09859203 41.40390566
-37.5671867 66.03365136 22.20058212
-54.63796335 47.8632436 44.11229006
-66.19701779 33.0773827 43.16250839
-53.10693915 47.83426808 44.15668346
-50.36730231 75.80195558 33.60349704
56.34222728 39.53586022 61.3516394
-24.85786708 50.45985892 31.59620291
12.56864211 72.29458944 56.49066363
-51.58415757 47.80945048 44.15438071
-50.0723546 47.79017094 44.08988182
-49.49329563 47.8449599 43.34342143
1.614717262 51.87066407 50.95655571
-23.84184578 49.94067733 31.60283939
-52.73818973 87.20903458 12.34011497
49.16938604 64.3481767 42.70554324
-46.97322683 47.93651625 41.799976
-71.93480047 49.67065238 43.90910312
17.44836054 28.38080388 35.11901902
-11.70653236 81.76191619 -7.997077428
-44.52630405 48.3251379 36.82192858
17.28202553 73.90139982 54.41868791
-44.38698669 48.36636088 36.3168134
6.295387236 37.05619688 35.58855984
-43.83023677 48.53151486 34.29340966
-9.54241252 69.89590749 24.45996999
-47.36715503 57.94989059 36.33871927
-43.43847273 48.63948661 32.96528276
-6.018723042 46.35448158 29.04855389
-49.62889048 76.62546644 29.72940269
57.48097447 65.410235 52.09789067
-52.23578029 71.52503807 36.99628028
-13.23157462 51.09353887 27.84942011
-12.1874891 83.61694885 -6.133365153
-39.4373985 79.73920288 38.25242754
-29.92773537 48.53065804 31.65206533
-28.90775678 48.51169626 31.67767148
58.78071207 43.9159587 62.47899408
50.92359865 43.23263102 60.25667705
-25.86226288 48.462096 31.67267358
53.79187927 6.243787779 46.278456
54.05616757 69.01802129 51.65569185
-49.63728256 10.2295562 2.253379159
-20.85708163 48.56373069 29.5383055
-58.14183756 68.23271061 41.38249611
32.986944 45.12074134 53.4120228
-20.96149056 48.76506594 27.22096998
-55.70990767 41.40438854 43.54948782
-50.04909758 80.22905897 28.76289279
-20.70206566 48.80764641 26.6771909
-54.12676091 48.8575828 44.12506733
-51.10108218 52.33349029 43.98366754
-0.2562233141 68.34024293 46.57497211
-55.68999801 86.16404717 19.20737921
-16.28559924 49.2062529 21.20762828
56.52474973 68.91254891 52.40931888
4.355337577 58.92008531 61.82533255
-69.94529761 82.53668233 29.07520506
-55.72848172 74.67724603 36.02907411
-14.24244935 48.89416241 24.44096201
-13.60891471 48.79758235 25.44131515
-12.76428844 48.66885261 26.77462883
-65.32736878 62.61633165 43.85879653
-11.25364744 48.52626995 28.14174441
-15.22849513 77.69576549 22.397509
30.82800176 21.68127832 34.59409157
-70.87715034 79.75233115 32.44249151
-10.05749164 48.41843718 29.16543187
-9.060301446 48.4109708 29.06193689
-6.007396124 48.35763192 29.09892628
7.494538718 51.72745945 28.19109506
-5.513320612 48.35617859 29.02157439
-55.36861208 87.56959074 8.656152629
-49.47578582 52.85162855 37.65878569
-1.470357328 48.28658218 29.05848292
-0.4344497614 48.27197411 29.03051035
-63.64065917 38.40245286 44.95074486
25.34995785 25.81112522 34.30963766
-49.49502702 53.2644392 38.69688884
-66.78398771 32.62347973 42.717043
1.877687378 48.29497469 28.32256419
25.83973951 49.13197469 48.99619898
0.1704029844 68.24892447 47.5537034
12.67333331 45.37655106 60.14296637
31.58302584 24.63582159 35.11145484
-37.15700273 41.42303436 28.14159897
57.89383509 8.798450387 50.79964651
0.3803238937 58.5828084 54.84546514
45.71251122 45.95562676 47.11986147
17.5287185 45.40508186 58.88580862
-19.41096018 73.89806764 14.84180919
56.09910491 40.01204998 61.69668703
-65.233288 36.95585619 44.56769587
-40.94050199 29.72405545 30.66068012
-8.694508907 54.99064805 28.35681419
-36.42278726 60.13021609 26.42067411
26.87673491 68.57842461 38.63556604
26.67531653 45.49895652 56.05173149
-47.25489982 54.36536338 37.14263336
-65.03532091 65.73008546 42.61621963
-16.33087621 45.59634994 28.16280671
28.59408981 45.50977415 55.56023092
29.09751983 45.51599096 55.39205455
-18.22815652 52.59332581 28.87159976
-62.92596778 86.4758145 22.79474087
-29.1413099 87.05902431 38.71468583
31.153049 45.54454581 54.66856708
45.64644666 67.92540146 48.46348122
-57.92721221 26.68829329 40.00828743
-65.50060101 77.99892848 34.29200677
31.52710719 45.55407842 54.48657049
-8.817119377 82.64681444 -7.167368611
-13.02501694 33.62332257 38.33790131
42.83891992 13.54036817 39.40955125
32.91359127 45.59801484 53.71211343
-6.059908264 46.85269525 29.09942107
-64.33381624 11.61246743 23.96210236
33.40490395 45.63099235 53.23559016
33.97223316 45.58432757 53.66911588
64.38650318 47.27303018 57.39844484
42.50430047 15.54458301 39.51354023
-56.4207109 56.81130856 45.45613848
34.21940368 45.58353394 53.63119205
57.35075769 55.91569827 57.46270017
33.90349408 20.99044034 36.20068763
34.84887801 45.63766919 52.88270595
-11.7883729 82.96961696 1.3052232
-4.066912972 52.34471855 34.88362196
36.35475877 45.62137705 52.78465646
38.15221138 45.80489412 50.31147094
46.73713077 63.77778222 38.13766182
40.0253678 45.70244456 51.14355824
-57.30193101 90.86435201 5.736699658
41.42620266 45.6648083 51.31331977
-25.91987879 53.01110404 31.31522396
41.42793883 45.73826607 50.46023716
-65.92603475 74.2148543 37.51494855
-34.34653532 52.77040323 29.88982747
4.636789028 67.28434533 57.89947702
54.59337912 48.44684813 45.63951511
-68.63427794 26.38380917 39.75822207
-74.81050124 55.18948617 38.65727418
41.06253947 46.49247908 41.77446454
-63.77194769 76.87821764 35.31908992
41.45028766 46.47032803 41.95766418
-11.86182932 56.0937457 27.80856023
-37.35394008 72.67974797 44.06007357
42.92690368 46.46879823 41.69382371
-9.590897901 75.26277996 -2.873514945
-49.65618902 66.60157538 35.39307568
28.10413396 48.68350155 53.77056958
44.30714754 46.31765964 43.1851302
15.8826662 73.36174217 55.1236532
-49.14076991 42.0865406 40.20443519
-49.66826116 10.83505054 12.71019115
50.16464378 55.41422597 53.00130914
46.71312651 45.69865408 49.91216501
59.9764181 7.889918639 55.12274483
27.29418517 47.59208367 54.9417254
46.9135959 45.64708484 50.47258774
0.5985877603 68.15681985 48.5412638
47.91628916 45.38906394 53.27666343
34.42747486 43.99670412 54.53266778
48.51804364 45.23418152 54.95989365
-23.32897379 49.42752627 31.63542276
48.91915558 45.1309557 56.08172012
-62.0429545 16.47679656 31.14958972
-50.07936874 46.78927711 44.05704373
51.59850586 44.89438541 58.31703281
22.31530459 44.4638857 57.24578781
27.5521063 47.58586699 54.96470604
-57.34371238 53.26583895 46.00407302
-37.01298631 45.87033082 28.92643938
38.28342304 20.93669859 35.98928232
-39.10756304 44.51015113 33.46256512
-36.60569548 42.39714306 28.38157856
28.01042959 42.64796292 48.10704436
27.80889104 74.04234681 50.7749354
31.88569434 43.66166257 53.08016628
51.75906544 44.93728187 57.78843961
61.04422289 53.30071109 57.98180947
-79.32351124 7.765694158 19.03710988
-3.486176756 37.02152857 26.20313455
55.01953633 44.5810156 61.30244772
-77.5258734 8.147340622 14.2638581
25.58545434 48.63414122 48.99726603
-59.48152616 71.42624332 39.52496939
56.0898018 44.53668707 61.61293967
-10.99082374 57.68135792 26.69221543
57.67402202 44.48087731 61.95869987
-31.55824573 46.6257322 30.7702192
-73.52661575 44.21830605 43.4144373
21.32215728 43.96709666 57.37563586
7.548470689 42.17053841 28.41834714
59.32027692 44.41855257 62.36826026
35.87331438 21.440557 36.42638923
-60.66921394 60.98992688 44.37102932
-68.88775372 11.67965263 24.05063079
48.11693964 53.37285975 53.7829241
15.5960793 44.88665382 59.44602476
-69.62523073 34.70280088 42.4271712
7.175604796 54.21358896 28.52431637
-48.38599005 69.51258514 30.49091898
-55.12260847 63.67641516 41.25974575
-35.07947368 49.82949688 29.20994499
-18.21130828 82.90935226 20.70963791
-48.19266708 52.34637923 37.45274917
37.49454721 49.00793476 54.04014499
-71.44115886 48.66498588 43.83619157
-70.63382034 48.65417702 43.80770439
-5.648525963 79.8753705 -4.73189858
-47.67199763 71.1612823 34.52204068
-68.90712615 48.62560899 43.81005199
-77.66151075 7.614434204 14.64945076
25.59669909 49.18024064 48.48224178
-60.46905543 75.19212706 36.78265619
14.69841793 54.60050212 28.42474133
-46.90872363 10.71815764 1.887625384
-77.75801935 4.724154496 13.26048616
-24.49661178 54.51835588 31.02644171
47.24917717 14.38903715 40.36980179
55.20713806 53.97227145 57.12565458
-62.89430821 48.31806199 46.23360199
-55.59691405 64.12055604 42.02092821
16.81530797 71.88018857 54.66482543
-62.38572154 48.30928864 46.23845887
-18.66282833 43.52492503 29.34757272
-70.13400174 65.82428291 42.49505593
-6.580484609 42.89934419 28.47895519
-44.68403908 34.4789354 34.44288808
-65.46781715 44.86184013 46.06024504
59.37993961 38.58486467 60.15889791
-49.75280666 83.755237 16.90514281
31.46770235 32.71613399 22.90425477
58.85962777 41.38984813 62.65567512
-53.10786509 48.33695119 44.14798861
-5.70951718 31.37314437 22.27801364
-8.427000832 79.43672656 -4.936568689
-12.75369567 55.59542212 27.9369075
48.10556044 66.26757259 38.1065948
-50.0682934 48.29033833 44.1094401
-53.78949427 75.31636102 34.06662481
-49.4806018 48.48943976 41.68604864
-54.6076687 85.50564677 14.99088251
-65.16273802 79.09309611 33.17895953
-55.43481265 60.57282901 42.38813289
35.15573755 18.96933984 36.11772331
-43.30516522 42.74227105 37.30599327
-49.50811678 48.8006537 38.07849686
-39.85867191 53.66969106 43.8080014
-48.67215698 48.8249628 37.6368764
-46.645291 48.79364298 37.61392422
-52.53548896 24.44313917 35.91023595
46.5423102 70.49251744 47.62488323
-39.34662101 57.6273427 44.3801688
-2.871354103 51.5615259 37.92085314
-44.98227656 48.77241976 37.54315202
-11.17250593 54.00971512 28.56348658
-63.40898309 46.8232584 46.20460187
-12.16516837 53.5126815 28.69608147
-43.09024188 35.42272667 34.83600535
-44.4474319 48.94862365 35.39565031
-36.56739532 35.14191677 25.19862231
-44.13338059 49.08753759 33.72314412
-70.37003276 52.13573538 44.12741444
-67.8088285 68.40134339 41.26843686
56.56927216 45.05720613 61.30557754
-49.8156124 84.43780194 14.82004891
-71.78524366 67.42054923 41.75925582
-50.71496689 70.96183049 37.41773585
-55.13703871 28.27413514 38.54649749
-41.13864697 49.2989325 30.69800359
-53.5360284 16.64556932 27.56802984
-49.69453489 82.55462425 25.0049795
-49.46746998 56.8651619 37.67830272
-39.58358579 69.03009063 28.58675487
3.028614587 65.91992407 56.56544045
-57.8090904 48.73287186 46.27504558
-12.44413886 33.15020003 37.89283524
-54.79160013 26.84101228 37.63743352
-32.92160992 49.08121328 31.65840912
7.268121044 52.8612816 61.68517178
-30.36247776 49.04156412 31.63064355
-67.29680987 21.17587354 35.867626
-37.98195324 75.0616222 45.66250973
-70.4619339 28.32350342 40.89594434
-28.40657238 49.00887295 31.63714367
-46.4256805 18.23258991 25.26867288
-4.105161339 43.36702091 28.4044044
-27.89836667 49.00029177 31.63984233
-69.12643313 36.68305179 42.65041336
-25.86176887 48.96406282 31.67202302
-24.64778266 48.9463471 31.64616565
-44.73236103 34.00109494 34.17258321
-8.917691815 59.73467519 25.76701478
-56.90724561 56.30674197 45.57965988
-70.29389668 56.61727346 44.52769826
-50.06682967 48.79181599 44.11428321
55.64132961 6.506845426 48.69863086
53.7876465 48.38251334 46.54001842
55.04854552 40.04643796 61.4978342
-21.30519929 48.89554989 31.59840597
61.96467041 46.54298106 60.50860554
63.17959287 56.80450316 57.68649236
-14.60922196 72.8319978 20.47516575
-17.26287076 49.16077472 27.74858573
-43.40406786 38.34655091 35.91381368
-22.9898321 56.54764117 30.48822865
-15.89503841 49.5610098 22.84151017
-11.25495434 49.02914522 28.13089133
-10.8006136 49.01056896 28.25989266
-29.92949249 49.03376043 31.63866162
-6.003057866 48.85765955 29.12005419
-44.13912223 21.3809011 23.2445636
-9.235617246 77.34020452 -3.750966191
14.31583786 49.52355234 29.16823108
-37.81417399 42.5921844 32.17450193
-4.413398025 48.8362726 29.06517179
-12.97671553 57.93978723 24.07090047
-1.950849748 48.79578859 29.06551694
0.08536709769 48.75975182 29.0955394
-31.81307824 45.65073666 30.48399525
-8.131735459 69.58075003 27.84952379
15.1334687 45.87666701 59.6947336
14.02196656 71.3003768 56.10176997
-49.48246071 53.71076056 39.33990932
-46.54489327 17.99539146 28.04498074
-2.752193062 66.24578485 42.23176127
-69.35899859 51.61906951 44.10579779
3.482390257 64.68658903 59.1430745
-30.26991448 55.15774192 30.53162613
22.06838749 73.96474876 52.7704989
-54.13094431 47.353045 44.11171523
10.08443689 71.80661528 56.80253578
32.88706447 21.53460574 35.90409852
18.43102389 45.94769528 58.24132183
-36.63914607 55.97384957 28.09894412
-4.969127237 53.05426976 32.64533188
25.69766841 45.99878111 56.2624872
27.03828162 68.97437203 39.83495268
-61.61290624 82.37227402 29.39473974
26.67643394 46.00060888 56.05461257
-65.90402529 63.66387425 43.46141106
15.24324611 44.02691056 28.70734829
27.16573012 46.00156645 55.95018475
-10.72585443 70.59865429 22.35430235
-24.21909059 89.38481747 34.08304751
-71.2433138 32.7420505 42.19100719
-76.44830355 5.556810547 9.171276615
-41.27385659 52.87026101 30.05168731
30.09004374 46.01309446 55.25867485
31.53022602 46.02994332 54.78842952
33.64375523 21.49978998 36.16395993
34.80603179 46.11194289 53.21180269
-13.86358064 50.18491578 26.86462546
-52.20338646 56.92631366 43.31680594
-15.84200355 50.34046606 25.43618115
-55.74417404 74.08383443 37.09418088
-18.8528321 57.25493693 27.31511287
-71.39111762 54.16411931 44.08174405
-63.71294156 61.03863617 44.38603355
36.71105575 46.36508585 49.90983439
-20.73943766 21.9927073 34.98637598
-59.51306255 39.80920094 45.31295174
-62.89583007 50.32769377 46.21118332
-49.59386097 21.19058841 32.32074602
-13.30700493 31.85581616 35.60361858
-38.48101245 72.60406509 45.15359523
41.17734092 47.06067384 40.98319256
15.82452683 73.89328606 54.79083208
-50.20257224 74.71442696 34.54362636
-59.41575033 16.8926362 31.64783704
-15.86397398 70.44591983 19.28058429
-38.59247611 64.60005766 44.86495737
-11.07928109 36.03823787 33.23930133
-57.84263935 64.56627064 43.10166228
46.22062769 46.35019521 48.26916316
-41.11070544 62.54826502 45.85734182
-35.95950464 50.35441295 29.11080427
-5.463493192 37.05263143 26.21915596
-53.00394509 24.92149613 36.27309755
48.73697598 45.77462626 54.4709058
55.15746853 7.054917911 48.25512148
57.9467471 55.38539725 57.67852189
-31.23817083 83.93891131 40.37527349
54.92794165 45.12943573 60.78009513
-47.28439842 67.3940346 31.56795624
55.36280354 45.10829646 60.94256445
55.63378413 45.09549205 61.03952972
34.47083976 19.49493418 35.97349564
57.81453106 45.01132625 61.60070634
-38.92372976 50.6670111 31.87387356
58.67045944 44.97600668 61.84749105
-72.83748789 18.50451413 32.97551036
61.11923162 44.88052727 62.48889034
-20.24085114 18.7212448 32.08233003
22.32348709 44.96197414 57.28869417
-58.95000838 85.20019592 25.19153155
-12.53566344 49.13007441 27.20347037
62.35295854 44.85285352 62.57486831
-3.552846621 38.46269623 26.9656457
2.315783483 57.03287707 25.85596357
-65.02782378 41.87437189 45.69720184
-49.91149611 80.17776608 23.50545488
4.758946947 62.47977897 61.21132599
-49.89235206 13.60117946 3.94824558
-8.231354437 81.59467717 -6.718381927
-61.87610306 49.80664288 46.23881345
45.15434309 67.93824899 48.40818485
-17.82439017 60.45561381 24.92303467
-61.84947975 74.14070179 37.59834155
62.79945143 44.87540281 62.22795074
-50.45590167 16.57849885 27.75923427
32.55306971 34.67305155 23.28650518
-67.03331227 65.24462372 42.80620194
-49.50705246 26.74550848 31.91167455
-47.52022649 37.43624907 35.61295619
-66.18531833 67.32128212 41.84368151
-78.79471792 7.368400998 17.72169367
1.718502111 65.68213662 53.74905563
-63.31094589 34.96839837 43.9663908
-56.80853349 77.83891874 34.4918873
-24.3512055 47.43876052 31.61084922
-19.9824556 42.96982513 30.21659759
-79.10595029 7.804614651 18.54380213
63.5608421 45.99131539 60.78170288
-12.84989418 76.70558483 21.78535345
-49.90449284 78.55035494 30.74301498
-65.12705959 12.0564375 24.78609107
-46.55517534 46.78887657 37.56296666
-72.95880563 49.18942929 43.86413488
-74.50981664 28.60619316 38.38623059
-30.62861819 47.05545178 31.43107605
-70.42196766 49.14863732 43.85388768
-16.09114875 81.62831648 23.52322771
-8.03292775 49.39271068 29.12253936
32.51387185 29.90605392 20.36635856
-73.79680049 50.19535666 43.99968831
-49.18001441 87.47070752 8.623858594
-14.59292386 27.3155399 36.11592344
-64.91988362 48.85094682 46.26041625
-11.27483027 82.20354801 21.75366002
-63.86080594 48.83703971 46.21988759
-62.88934656 48.81777515 46.25826153
35.03740232 32.64988132 22.992601
39.22447081 35.22928425 21.38371444
-62.38474267 48.81101088 46.24055514
27.03162787 69.96404158 45.82733876
52.66462799 69.62751821 50.67222242
-58.5239447 48.74658361 46.2521966
2.930556383 59.00696786 61.08845073
3.275253185 66.44639263 56.23341447
-3.989973769 50.84215597 29.00522771
-56.29212713 48.71098925 46.23978084
-40.31015087 63.20322139 32.27481662
-1.75110684 37.44139579 26.82476214
61.51190956 53.28779152 58.04259864
-54.63565927 48.86651338 44.11844463
43.63957084 68.46124238 48.45241295
-65.63186941 9.508399806 19.50199627
-20.76922025 56.57964407 29.69323146
-49.50252251 48.97793564 41.84605337
-70.64262532 37.20676146 42.68659717
-49.49993428 49.29874213 38.12140322
-49.17945308 49.33530116 37.63588161
7.912665263 70.25571623 57.74075119
47.35222561 31.07604122 21.43445185
43.59938191 32.04088829 22.60277473
-46.99541878 49.29903278 37.64040293
44.36148966 16.60328714 38.5224345
-53.57310417 28.73077433 38.77388573
-19.21754715 51.0902097 29.02962864
-66.23334491 67.84772472 41.56815147
-12.53971029 58.39500495 24.52970245
-1.956883032 50.3054801 29.02098936
-71.79474327 30.7635714 41.95723377
-72.00899405 60.15550376 44.56690431
2.200237154 59.64219712 59.68016281
-50.08421425 64.11484477 41.03592378
-44.97745344 49.27220281 37.56702676
49.73790267 68.83577539 48.76821285
-43.86858258 49.70395723 32.34343723
-67.79497165 24.9493378 38.77062899
-43.27648887 49.67783737 32.53373975
-11.29644223 82.03434605 17.89535992
-41.24406279 49.81096333 30.60072036
-40.44396593 49.74805728 31.17839644
-57.52797969 25.7302506 39.40052831
-38.15765724 49.67258646 31.61850417
-58.83026284 49.75686791 46.23577756
-64.92628959 64.16843754 43.24425297
-36.46993601 49.86190081 29.09894606
-72.10643454 86.67158101 22.27291036
32.86408859 37.25024173 22.44249181
-26.88832053 47.47969225 31.61952679
-35.47728271 49.85672303 28.96974857
-19.9805503 43.47108004 30.22394227
4.891471688 55.88411976 62.00694704
6.938647956 68.14351774 59.13988034
-53.16215289 45.3386511 44.00495189
-72.3935548 22.19919293 36.61344948
-32.95016783 49.58544615 31.63699295
-42.38980457 50.45799033 34.96134102
-14.11690219 73.30886831 20.6720574
-57.50524959 71.92265889 39.21196821
-4.98078657 60.75537658 42.30038375
-49.66959864 9.644530312 3.224308804
-37.47859882 49.37293842 29.14089546
35.07309244 33.63630981 23.18789463
1.857322826 58.23419547 58.61074643
-31.96154424 49.56991219 31.62878605
-53.68882201 43.3600708 43.76763135
-30.94201588 49.55117752 31.65184157
26.20902365 70.36087784 47.20409056
-29.41864554 49.52606293 31.65287426
44.63693115 44.32920208 54.55242515
2.201604547 56.99136217 26.35967349
62.62518961 55.25540663 58.29534088
-52.72387941 56.42648807 43.3917664
-27.31406988 49.49270995 31.63870473
54.95531574 66.95203579 52.16112284
43.7433479 67.17294342 22.60164622
-6.577679362 39.27664658 23.92021011
20.03928868 75.00162696 52.77390314
-26.89445969 46.98057307 31.58819637
-26.92032325 45.48697163 31.4520216
-45.55632759 24.1427572 26.41303778
-25.86913651 49.46999539 31.62683453
-43.49455867 31.10673634 32.57650814
-20.9859923 49.78185767 27.07526394
-70.66124146 36.21172015 42.58803129
-18.70584914 49.64755235 28.19954128
25.33808482 48.68459095 48.45878354
-77.65684925 8.114298779 14.67240988
-41.08710012 45.88223621 41.21847457
-53.21069118 55.92206124 43.51371818
-1.880021786 66.5558206 44.29295294
-23.37110166 52.46207344 31.37606396
-17.30839305 49.64878726 27.9187022
-53.78353523 26.82806025 37.59554607
-55.64322708 53.92615275 43.84101628
13.64697139 34.30557702 36.98456721
-16.58789487 49.96672954 24.09039226
-35.44547413 53.90796139 28.54707801
-16.06629642 49.92661467 24.45660308
-49.48378026 32.61835315 39.47730228
-45.2536269 55.79165718 37.68344195
-52.50811997 35.96218279 42.02293381
-57.00618047 58.86772888 45.00192663
-58.23330617 24.31923054 38.4352661
49.77727209 41.75912409 60.10090955
-13.91525469 49.73354411 26.28769075
-13.7271439 39.72488106 31.733506
12.18335486 54.12809393 28.56179718
-53.67458989 27.30009765 37.92165589
-68.82085178 87.22608683 21.03586245
-10.45362914 48.94703617 28.93125577
-55.90563616 51.22533038 46.11097233
-11.09663399 49.52583156 28.16144211
-10.43722134 49.44097543 29.02076019
-62.16751926 60.50018164 44.51544452
-57.85502665 44.23609976 46.0458502
10.11124208 55.18667013 28.32151811
-67.9724828 27.80439929 40.62068973
-45.45726394 37.88893669 35.79104946
-73.35345415 57.66601109 44.58994424
10.16593449 53.65243297 28.64170999
-39.67701118 60.29176724 30.99251211
-9.054014596 49.41223151 29.09065473
-37.8485827 41.60510951 31.98646779
-56.50893428 46.21016409 46.17932638
-4.493773935 49.34186674 29.03783513
57.86491469 6.804174917 50.64960332
-19.94006747 32.45812095 35.70323066
-2.976672138 49.31991425 29.0033552
-2.470959995 49.31259093 28.99192728
-49.78705362 22.5469122 34.09231548
-1.503821064 49.29550578 29.00582498
-8.69652502 55.50627001 28.1981235
-71.10278416 39.70218595 42.938848
-11.12693548 69.74698819 20.66427044
-72.83999595 59.67603336 44.46478268
-50.84766158 72.03319916 36.6590937
17.13415766 33.27651558 36.61235425
-1.224730623 49.27861043 29.1487343
-55.83830902 17.47336163 30.05075354
15.09378863 46.39889928 59.46648832
-55.890043 7.279834982 8.555131336
-50.34629644 36.4145566 42.18581667
-71.35053927 76.48227463 35.53412745
16.16542663 46.42821196 58.92183754
56.09965412 48.26930107 47.41335555
16.53602153 46.43949158 58.72022072
-12.97496863 69.53277137 17.67684536
42.72644627 14.52720296 39.62838399
-12.64789045 72.33152021 20.08439614
18.34895324 46.49131109 57.77292489
-64.49207598 8.67234502 17.33688281
-14.87141739 60.954901 24.39043576
-3.491910292 47.32317878 28.97461323
-57.94946072 81.17996664 30.88399133
-62.93172403 53.3590963 45.98714094
-55.73964113 67.70806559 41.18820422
19.76783633 46.51890414 57.18201512
24.16350178 46.53155782 56.19684199
-49.65807187 68.20828466 34.22150334
-40.02850637 66.54042275 45.92020669
-54.49207958 40.40508062 43.26465454
25.15608581 46.52641498 56.0672521
-55.65336759 43.38473151 43.85600238
-35.71504096 59.09088001 26.69779271
-14.1017216 48.87272929 24.66293571
-5.509430598 50.86529643 29.02636589
26.1511634 46.52001428 55.95178877
-37.60012788 63.32284636 30.36930474
0.1177345969 66.5953995 49.27914978
28.08627107 46.51020325 55.69664576
-59.23154085 73.55678317 38.05099762
-5.179072422 59.3983077 40.61212111
-37.90361898 66.40824332 23.7428461
-16.76699571 27.24767139 37.31839932
28.54252891 46.50304884 55.69268816
-36.06246408 54.42407809 28.49992318
36.39141321 47.05997942 53.55724278
-8.24544177 77.87565946 -4.329109481
30.09822624 46.5111829 55.30158122
33.00789018 46.53010082 54.52707773
-34.00580624 80.28328052 42.55382239
24.34679115 36.35482504 22.80816021
-13.03734958 79.13778013 22.71960879
-41.43297773 24.09455516 26.18625467
-37.00800956 52.89884469 28.90634423
7.916970878 48.38569883 61.07749523
-35.97201607 38.00354643 26.82503994
-20.89820722 55.51638905 30.40758867
56.39999962 69.96752108 51.83950427
4.366852002 57.90984491 61.89746271
-13.21942805 78.65307837 22.5544632
-41.30777694 18.36981534 16.87300279
36.41046392 46.64726486 52.51806052
1.112951412 45.24621908 28.90073885
-50.0265673 9.502487782 4.941318499
-49.48719182 41.1577465 39.39933779
26.37864838 74.02048399 51.3014908
-67.31411968 54.0649655 44.45528588
36.56452984 46.69343698 51.95267939
3.64929001 50.48413464 55.01111497
36.9482387 46.8343796 50.2433388
53.34526137 41.11718477 61.04593199
10.36005015 32.8216479 37.35801243
30.77213734 23.11699528 35.41781786
27.10721627 30.42243282 21.22957814
-60.83608891 69.8595564 40.49061529
38.41041617 72.61420651 47.85213266
-32.54892579 53.13394507 31.15339469
-57.8195976 21.9587378 36.62548709
-15.53845226 33.15009328 38.48417868
55.01743653 45.68561829 60.13309555
-73.71320489 10.57537518 20.31020856
42.67799248 71.66226368 46.43584532
-67.98305815 74.78838064 37.07596383
-37.30801603 45.21960558 30.71015614
-57.83645552 52.26218812 46.09587159
-63.05019851 40.36124302 45.40563832
21.96488827 75.02139005 52.17725532
-39.55916118 64.04036428 45.71999853
59.43431581 7.710716859 51.47979056
-62.95904335 19.71108573 34.56479094
-9.819526895 57.07146009 27.72234056
10.69721406 44.59232395 28.83721346
-27.44007084 44.99915898 31.38738495
16.59630977 45.39225875 59.21248463
59.44452133 46.06074953 60.76066781
61.89016791 6.36611439 60.79393221
-16.85977952 49.07466936 22.84464593
-51.27831036 85.06507514 19.47043159
-74.3717932 57.19431871 44.43325598
52.45732037 54.67227084 55.35059816
-49.44716227 55.95625617 42.39903084
-5.41733004 81.83528456 -10.04817995
-49.5374355 56.05134938 41.31233492
-70.71398924 34.22946418 42.3030009
-61.38131325 15.10716884 29.44312588
-26.037056 56.08350916 30.63069468
-73.53446635 43.72005019 43.37341122
-0.9169853501 58.89033421 51.52288518
-25.61791764 88.77055775 35.65394666
-72.94903001 49.6867141 43.91606655
-70.92164454 49.65513223 43.89605741
-74.77596399 34.76704106 36.8370583
41.52788427 43.9829786 53.33791277
-47.63674405 17.90194137 29.33804019
-50.62017507 44.30322325 43.88690183
-69.19494176 66.85057184 42.05534243
41.54236483 68.51565566 48.22069363
-70.41285007 49.64625406 43.90209151
-45.05586152 26.87881997 29.51522832
-14.12224934 31.95640661 22.93807938
-9.434227835 56.03372351 28.04238358
-52.64956849 53.87792248 43.82999978
-33.21425981 46.71775771 30.01773155
-10.6075541 45.43483646 28.94630134
-64.86755938 23.95487069 38.10357208
-52.60537181 50.3401157 44.10452811
62.60402241 57.32450404 57.58634827
-55.34998307 80.95225059 27.20510802
-49.45847337 48.82516724 37.7844584
-64.41530521 49.34641142 46.23911627
-67.4178057 51.58141099 44.17276824
-60.43835022 32.49493491 42.99913396
-69.30916738 81.96135477 29.80607255
-61.32021827 69.86838141 40.48049451
2.664126002 67.70954649 53.33962959
-49.50055326 59.44248629 36.89835371
-49.6736654 27.08399234 33.84072219
-17.15679513 59.92635876 25.11305837
-40.78161951 55.0967988 44.89702765
-56.79450537 49.21884684 46.26664678
4.115584088 65.6689465 59.27168001
2.432626985 58.59487171 60.14068067
-41.17952497 62.56966253 33.96878457
-55.67788471 84.3043987 23.31330384
-61.97122936 56.39582572 45.51124277
45.44537237 50.04043608 52.1911275
12.67004994 54.11797466 28.58645329
-56.28654209 49.21038796 46.26797203
22.35773506 35.86485598 23.04876273
-13.85926423 50.61062087 27.74854799
-60.87410608 51.30339983 46.15220221
-55.64007857 49.37984244 44.17753593
-64.19732067 13.80691507 27.59452712
-64.14698463 79.0762657 33.180629
-55.13824531 49.37447578 44.14413336
-53.61833825 49.35110821 44.12554581
51.65102328 65.17287623 50.13849
30.00678948 25.69203602 34.80402639
54.10135731 51.62254664 55.48064934
-55.82493609 26.31494032 38.11488328
-64.18804448 81.85583663 30.05437537
-75.62448688 7.397886409 10.94818144
-49.49481403 49.74881959 38.7222402
-48.46523783 59.5148994 35.86028846
40.55521903 45.6829445 51.26888336
-47.65853252 49.81363836 37.61958654
-40.74807662 56.08431045 45.08015673
-32.59554875 54.66411167 30.87891797
-45.1277553 49.77590367 37.57500394
-26.87742509 56.61319601 30.46860744
-47.19127716 52.83901036 37.36959728
-44.17017698 50.11053124 33.50778625
-22.89105857 88.95602348 32.98089638
-42.46635374 50.25455631 31.51090858
-42.15542618 50.28354565 31.11508328
-64.42496579 51.36014871 46.17059005
5.92518974 38.74958769 33.48096464
-12.27830364 71.38843202 19.30868781
-51.48259904 16.60084985 27.69556486
59.35739405 42.36618722 62.87997315
-74.84504926 55.67196284 38.88956226
-40.0596755 50.21747757 31.48237756
-11.53818496 39.20869032 31.48173629
-38.94126312 50.17363963 31.77799257
-9.748749622 82.6212097 -6.692462596
53.1964951 68.10035319 50.81931565
10.04188142 59.46238737 25.31222605
27.81403088 37.34468955 22.30914894
-63.03634109 12.45795711 25.55288689
-37.9672787 50.05398991 32.98122926
-47.05218023 61.05207913 35.22602832
-33.48376255 50.09666168 31.63083064
-7.537836572 33.76583359 23.9838071
-49.57130385 66.91230473 37.59635569
-49.47193668 35.4951267 35.21256048
23.15115855 70.39194079 47.42664244
-22.54723484 88.98080758 32.62761515
-12.38565079 82.91735942 -3.800870441
-29.93719731 50.0420791 31.58811371
-63.63483736 59.49258914 44.8388582
-67.52446104 17.01854441 31.73258244
-44.67093195 29.69794192 31.67524059
55.40830096 9.209808283 46.49830942
-72.54663355 43.70491513 43.36072435
43.79658397 66.69342865 22.3314102
9.663962694 53.65786951 28.67432775
-26.37248446 49.97834214 31.64820681
-50.22809768 57.41083858 43.14202845
40.10960693 29.80352195 20.10807217
-23.3384194 49.93450705 31.57846213
-22.82013554 49.92084204 31.63825567
-20.78053018 49.98220737 30.53691692
-49.53215556 27.3661579 36.36479302
-58.14389886 73.00147318 38.46338055
-56.59558635 90.83015502 5.998979181
-17.3496988 50.13467281 28.1127063
-62.38039724 47.80438682 46.27207142
-17.02964048 50.1466673 27.91242847
22.86861486 70.38580024 47.5518092
21.34629964 33.36842811 23.08880171
60.47346114 52.83863207 57.62816677
-49.51158363 29.71153463 32.44058791
-60.38378601 44.77503258 46.09841515
-11.9716661 36.79904546 36.2307571
-64.63297179 58.99831581 44.94045312
34.45823101 49.05290541 54.09713652
-44.20011962 50.88228904 36.20765094
49.81488958 68.31950188 48.92016984
-13.57262845 50.16203688 27.07473408
-11.75683856 50.03896963 28.15710671
-79.13476519 8.848359968 18.08602164
-11.24703974 50.02958483 28.16883068
-12.94492055 32.37360486 35.35033858
24.73791773 29.38759689 33.69455024
28.9240019 36.27589986 22.85147976
-7.528695665 49.88834999 29.09927736
7.20184001 48.90176612 61.04963018
-70.40434291 47.15566043 43.67989318
-6.016477097 49.8688608 29.0371329
49.57177556 64.11375819 39.52346485
-5.51194247 49.86213147 29.0190341
-67.60642936 19.32319416 34.12736284
58.09077742 69.38052115 52.50474556
6.424663589 55.33665896 27.28339077
-3.506581345 49.82927065 29.01807208
-64.71337011 73.11903962 38.3514211
-0.9283592808 49.77999177 29.09845488
36.05804938 46.14937614 52.53848276
-63.07951468 58.45580819 45.11538303
-49.5289468 69.42679609 37.53143168
36.23577768 70.06032157 48.78100521
-55.84109049 34.86742063 43.71406792
-72.25554702 18.9361761 33.68012462
-0.2697213455 49.76986 29.09046568
8.593446386 46.9590324 60.03034957
-44.30750172 52.06477828 34.15421275
-40.16459115 49.16685784 32.04546449
-8.162435005 42.92923111 28.4336935
-20.77532422 52.1371125 28.82677604
14.11596735 46.89881122 59.67626303
47.85190893 50.49325592 52.30216434
-57.26732154 15.03890958 29.45096635
14.57357449 46.91575403 59.39231006
37.66300963 41.88527214 49.29346986
-29.93165804 48.03042077 31.63329185
-6.008859857 47.85615426 29.09408318
-68.74498277 25.43522116 39.13795417
18.61749609 33.87292215 35.2325906
-49.49667791 61.54850692 35.75595356
-49.49849783 30.15759394 33.08655142
-18.60707481 74.21777174 16.80378615
-59.36412775 80.64803083 31.50223518
-61.73248593 15.56353002 30.03896399
-41.68824155 50.32043504 30.59774936
13.29478565 49.5430557 29.13654265
57.97740501 46.13182086 60.21540769
-8.208327935 56.00999802 28.08401981
-56.0977979 9.382039159 19.15067819
-68.18366617 83.64358969 27.54274437
-60.99654581 85.24143883 25.10304079
15.53810131 49.50166124 29.18926607
-49.78389421 86.87666746 9.808431918
-35.04403419 78.22825036 43.30154514
-35.50108299 48.85190788 28.98563319
-13.72693096 43.01062619 28.54998281
-57.57322468 72.45916433 38.82342562
1.957907204 46.79741617 28.21209536
-54.02658085 29.21396397 39.07778938
-39.18368435 59.62834846 44.42652422
19.52260811 48.43327172 40.83204385
-68.63335845 22.60100676 37.05845027
-49.68675146 10.26782822 13.47180732
30.08150774 47.02183242 55.20341809
-49.42605652 10.27167229 1.724182729
-67.79225681 54.08315671 44.33529265
26.19184782 45.99727537 56.18572391
31.08171099 47.01284613 55.11699271
-35.46427191 47.33907972 29.0607038
-55.05253368 13.57293225 22.74016309
34.96771615 47.03481474 54.12088043
44.57816942 45.77784053 43.57341807
36.96164552 44.86830826 49.7578094
-67.93185476 77.49663237 34.7600405
-59.30558421 69.83084294 40.53206566
53.58600362 55.55335033 56.5604197
11.89716557 53.62157634 28.66976009
55.02389343 46.28368861 59.01566816
29.10401074 71.11907501 49.50357521
18.47755211 44.9197925 58.51181256
61.55464079 56.27953229 58.26398463
-62.50752341 75.2292994 36.73988058
-17.59404641 74.00409309 19.09113372
53.21444776 41.63583281 60.87667477
-61.22803454 62.02847233 44.07468806
-15.91287314 76.24940032 21.83856378
59.71763761 46.04686779 60.86973222
31.59734541 22.09274024 35.49746397
61.81814646 56.27049854 58.3186029
60.42448445 46.01241748 61.13485668
-60.92118643 54.34044524 45.86478648
62.56586539 45.94810949 61.47301664
62.80440883 45.98883449 60.95475935
31.6493846 44.15919361 53.17616947
-1.198655575 65.85856275 46.43064973
59.01070419 46.08136178 60.60411726
-68.73735586 79.16182956 33.06275257
-1.779976693 66.00313774 44.8631789
64.28154207 45.94987699 61.12530828
14.04009158 53.07905079 28.73248293
-11.26725856 83.74741548 -7.823410778
64.65884847 45.93299321 61.24935292
-49.63580598 87.85595347 10.06520573
-44.56342276 54.20939834 38.43990868
-17.47587378 73.49182167 19.18877708
-49.52287799 25.34595487 30.68180942
-49.20596562 71.19174278 34.46097007
61.15888599 48.51138541 49.46487922
9.628136023 54.68037399 28.46446354
-66.80345946 55.51223854 45.03682105
-49.60104979 69.92821702 37.55096285
-3.690607295 51.75178999 35.86836495
-42.28935379 39.86078233 35.60285023
-53.8845555 74.23599247 34.97317856
-13.90835503 31.50161962 28.17678857
63.02956411 53.29033806 57.72361069
-70.61342877 50.15053875 43.9128792
36.28889971 43.69529066 51.85006896
2.853470828 63.75163109 58.46340547
-57.50907693 30.997313 42.34606937
-38.35108371 45.05199161 32.8548611
56.51586378 71.67589001 49.46533722
-67.49104271 50.0869288 44.05585228
-70.45125219 82.54412794 29.08525955
-49.6031195 62.06151966 41.47410899
-60.78489979 49.78891924 46.23646335
49.44870087 50.43110447 52.71914599
-14.78808719 29.76401233 36.86269195
-35.40181435 80.24414841 43.27432381
-31.96017724 48.56479057 31.64342892
-57.75896842 49.73679819 46.26445928
23.629128 43.54004309 56.06659112
-16.59604529 47.78187293 20.3221516
-64.14339727 30.62059809 42.15781475
-69.97188122 77.53459087 34.70843578
62.54132229 57.84595964 57.37150929
-10.59743216 45.93194657 29.00019503
-52.60143282 49.83591268 44.13029257
9.755690624 56.27134544 27.45090293
39.70835081 18.22716163 38.03861835
-52.17773553 52.8584793 43.92117708
-50.56888706 49.80172775 44.13951758
48.20866127 63.67970634 38.99556864
-55.83664943 10.27951511 20.3356018
-2.839117229 52.5249185 38.38422285
-51.88883617 60.51854343 42.34208052
-49.47620509 49.89475854 42.851167
-49.49570888 50.00370847 41.59011604
-49.48196515 50.09588699 40.51741933
-21.9534649 58.6159989 29.58614456
-16.28604137 26.32753947 18.77503388
-16.28332728 58.6976556 21.73024261
19.57736416 30.24488113 36.37999993
-20.95680223 50.21979332 27.81246154
-63.53611701 22.51992433 37.02763977
49.53002606 9.548597698 43.68641647
-49.65284645 27.50137287 34.81813513
-49.47699202 58.8788293 37.61056131
-44.41147838 50.53624672 34.43842902
-51.45887319 62.05607916 41.89116972
15.44079869 72.3324276 55.50367301
-41.91006341 50.47615676 34.65896225
-38.74793066 68.54753636 45.68251709
36.48549428 48.01695313 54.08333356
-55.76153712 70.34169129 39.75253494
-22.31443255 49.41130731 31.63022515
58.0955648 67.34446425 52.83330356
-49.62652614 14.59060629 21.54476953
-41.79269461 50.52845149 34.02950384
-49.67113533 77.49588246 25.45967491
-41.80832503 50.73455895 31.6398385
-12.53786739 35.65900494 37.91987592
-41.81582579 50.78789834 31.02206618
-41.33087878 50.84060552 30.31772044
-76.59712112 6.425700496 10.7662304
-39.58466074 50.65352173 32.15651162
58.00948814 69.91135389 52.18459497
0.05194640261 29.93352124 20.41154093
-32.26900526 50.58077301 31.60589234
-40.33640497 43.48137294 33.98643909
-72.4322078 64.3123847 43.0046329
-72.14859086 26.92433838 39.98020599
15.10724072 34.31229315 36.62811923
-31.97035731 50.57878991 31.57195967
-3.477994255 37.519617 26.24604091
-68.86540195 17.9560662 32.7581527
-38.38310275 44.06371127 32.68036492
42.09973416 68.56047999 18.46083711
-53.71755093 42.37013079 43.61177439
42.00564709 43.43798232 53.74687418
-30.44612115 50.55323856 31.57789741
-37.15318235 61.36108108 29.75110901
-3.021777202 44.82272286 28.77886867
46.92347608 13.28873456 41.55174876
40.79747533 46.50229899 41.71101717
40.92397608 15.14576723 38.61802107
-29.93562967 50.54350435 31.59354543
-29.44807743 50.5329527 31.62305754
34.02185673 32.66660691 22.99210875
-25.86534322 50.47251395 31.6414258
-13.52154813 81.57099768 23.69858793
-49.57682328 22.58953056 33.55747823
-54.76113865 87.48615702 9.508863506
10.37249685 47.84654114 61.0415068
-61.20701182 82.36574712 29.39310215
-25.36631521 50.46856238 31.59213084
-6.047225818 67.56770294 33.34099327
-70.06194903 23.09291552 37.44709609
-72.70017599 37.25355989 42.53571407
39.9943473 31.62274917 22.3176995
54.90258627 65.97420264 51.86929766
-47.40207175 65.2463069 33.21622453
-49.93208413 50.8070425 44.00092992
-21.30988189 50.40456006 31.56153028
-38.7901847 66.55998692 45.456936
-59.21703191 13.28261232 26.9046041
-25.88731422 75.7593264 0.2966772477
-7.471614101 83.21363827 -8.177428538
-20.99736091 50.81680236 26.71632038
-12.86479833 49.17221422 26.77704867
-5.141752322 67.81138398 36.16612801
-17.00504424 25.54379365 22.18380512
-20.66878291 50.82449918 26.56430833
-64.25538929 73.11101607 38.35722457
20.84727852 71.61125558 51.19123349
-16.43108135 50.66905776 27.56063082
-50.8439538 13.50651456 22.70858669
-62.45475084 65.1650172 42.85717338
46.04024534 46.39163172 47.82253779
-11.17430256 35.88794775 35.00210019
-32.47191549 48.5751537 31.62071763
-55.76065632 61.97128955 43.6958456
-12.80295579 50.57333283 27.9799704
-8.663134642 81.06249017 -6.284664627
-49.66846755 10.72272536 2.360899768
57.90598227 53.39709659 57.4613775
-56.31294462 33.40057221 43.35237748
-12.24814797 50.55363257 28.1028602
-38.26541954 71.56521274 45.51895496
-49.50755526 75.91337685 32.14607854
-16.33204778 51.62353323 28.11477853
-69.1019912 20.74181767 35.42408129
-43.67791361 34.4669617 34.3900133
48.91649461 50.4517925 52.58047911
-11.33772545 50.52799072 28.22690657
-66.74735915 81.90796677 29.93728725
-10.920968 50.46642836 28.86208929
-37.87141668 52.59104688 32.64414335
57.90061628 56.94009345 57.11919379
-8.03533374 50.39835641 29.10201042
-3.491647572 50.3363424 28.9554061
-1.434720294 50.28985843 29.10275756
8.602664854 47.40704254 60.65440382
-49.80635293 26.64870932 33.09246841
2.204349684 67.26581544 52.75182457
10.78070616 47.34901942 60.91261305
19.36133652 46.51334748 57.3240428
14.07975784 47.44407065 59.18003978
-22.97293436 42.46467963 30.82435661
14.31581238 50.02578122 29.16463748
-49.60492562 27.67542248 32.7885011
-49.40243179 60.04387296 35.72494669
16.131656 50.02657614 28.80911718
-52.3907387 60.52394503 42.37509069
17.11103133 49.80509562 31.19345237
18.78676801 49.40578696 35.50934628
-4.662144556 60.19153593 42.95844985
-12.47092927 61.42316085 24.32338713
-49.48028525 64.75841572 39.27635024
-5.146133872 52.08957138 32.22472911
19.34557778 49.27256761 36.9492856
-60.44966078 71.44383074 39.50543088
20.46129253 49.00708977 39.8183731
-12.63287596 29.63558417 20.46264265
-40.69633005 78.92382595 36.3047217
-49.81902116 84.86074498 9.91086672
-45.90723355 38.87257325 36.11137216
36.82064697 21.44585191 36.18426008
20.83262024 48.91888833 40.77146556
11.67533789 43.59004337 28.63260197
21.01819751 48.87483128 41.24752129
21.7618919 48.69790429 43.15959228
-63.85471515 23.93950777 38.08876055
1.202479449 68.02563719 49.94896225
-68.88789105 62.67795472 43.82244392
25.8907315 69.99444352 45.69198665
-34.55081513 81.79229695 42.61991763
-69.24804538 58.59412452 44.6860818
-5.708946001 37.5547746 26.26336172
-49.81295357 11.15581785 9.014083268
-45.82943037 56.91310304 36.42797071
-49.70462163 78.11410176 24.11596082
-63.32179284 63.09796742 43.71178253
62.37283766 48.847236 51.16121371
-68.31654488 15.66367724 30.13200157
41.35122074 17.13733451 38.7235401
-72.36973955 12.60697934 25.60286314
57.85315875 64.46583654 51.33689751
-78.75247825 4.910347019 17.11531826
-41.07919716 41.42598003 34.6820314
-35.99911224 45.35223239 28.92091131
29.53722378 47.55082897 54.99287921
34.56142364 69.68416034 47.6404276
-11.02470535 35.75627016 30.67553912
-37.49674176 64.9441283 23.18184509
31.04989257 47.53111267 54.93328537
-49.22495234 63.16586406 34.40859426
-55.69421165 74.13296492 36.51431003
34.51243857 47.51891283 54.41458156
-37.75468491 67.79020725 25.15152041
38.82259782 47.61186612 52.51353849
39.24722557 16.73012327 38.02534884
55.63280078 6.008931691 48.65376247
53.82382439 47.88682557 46.46078365
61.45048552 46.5669673 60.32821382
53.28828836 7.731911279 46.57919288
-38.4398406 66.4376158 29.33077135
-13.51955183 33.12279161 38.41609997
36.59341353 46.32531797 50.39392408
62.5017722 46.53221245 60.53118689
-52.08033835 76.92717736 32.52106934
-26.95701696 54.04315386 31.18550666
-63.36083786 28.21032012 40.85563701
62.99135494 52.8073963 57.5105969
63.62092821 46.66144436 58.81753848
-51.65408341 10.97621444 17.27680744
-10.24491861 33.3407319 23.60831359
-11.27769592 47.52158 28.15622292
25.9972751 48.55029128 49.89212082
-7.93261526 83.19840754 -7.912703405
-42.55025964 19.56166398 20.75402619
38.36117362 51.07519418 53.18318142
-19.28681357 41.58304603 28.7027554
-59.72660213 82.33752578 29.4383931
-58.08914815 25.26333232 39.10123366
-37.61466251 42.64020978 31.57894006
-68.63831134 14.31934131 28.31947399
59.74984833 40.39474277 62.3845321
-18.77425435 59.94937992 25.15427113
-77.54420857 10.14893517 14.33794803
-60.45831796 65.65126511 42.65836196
-67.09351359 19.31223704 34.15674502
-5.308902055 79.04650824 -6.827910974
2.240660765 36.38746254 32.4716945
-43.6145722 53.27144011 37.49417899
7.201790277 41.75647966 27.46451634
-61.13752357 76.2859832 35.86513831
-37.45864389 70.15195219 26.81140946
-58.16784351 21.03086379 35.81005637
-7.040889554 78.97863502 -5.709688488
26.22700203 47.63485735 54.64869546
-79.2551046 12.26925019 13.35662537
-72.13100154 22.19063961 36.66267218
-55.95213654 81.16354891 30.69367856
6.533481055 46.21710068 28.24959079
35.91242781 18.4323083 36.38103117
37.75903644 69.24531506 17.16520967
-14.8676928 30.60278997 21.31408675
-14.96873691 61.97125821 24.26366067
-65.43509233 50.36970866 46.20769635
-67.43096281 63.69078828 43.44016953
-28.286301 75.77347738 -5.236738259
41.4725481 49.02066375 53.13374882
20.63308844 73.9454366 53.26840877
-49.63179036 79.83844824 27.39116307
-74.04421583 50.69848982 44.03277595
-16.67267498 70.94724308 19.44172319
-29.08595307 55.6326101 30.61986215
-70.34892691 50.64321851 43.96969184
-69.88814083 50.63377988 43.9913877
-68.05313523 50.60013085 44.03206359
-54.15143607 50.3642891 44.1187493
-39.25831138 58.74735487 31.36140692
-74.82104859 32.81235626 42.05714201
-21.80957707 49.90663216 31.61049479
-50.56963983 50.3043235 44.13180374
-60.89284331 64.61824685 43.07997666
60.47259286 49.06246147 49.02510715
46.57948757 46.26828304 49.15162278
-11.15063377 35.92556396 34.56090997
-49.47712747 50.6452184 39.96609765
8.90022464 56.85820349 26.62800965
-45.74868385 40.82623626 36.70815345
-49.18496687 50.84473062 37.59429706
-43.99831812 50.90438139 35.91270203
-42.83097931 50.95975223 35.04729829
-49.73859468 77.07725139 24.50567372
-12.66678564 82.75518213 -7.69122553
-73.83912756 57.67561498 44.5710761
-39.76523622 51.09816028 32.85590067
-49.78369591 13.00125606 10.89187394
-10.31873995 30.97839062 21.91296872
-4.07833154 61.45324058 45.68024792
-40.17469711 59.55836513 45.42793405
54.78937085 54.48700349 57.05658102
-62.38734568 27.71259196 40.62133702
-72.4179996 73.81588518 37.55788912
-49.91664468 18.38967325 29.93752433
-53.01174973 81.4147475 27.21683205
-61.82484963 34.45987032 43.75970716
-36.1067758 55.45086278 28.2419894
-72.41733135 61.71946672 43.96907579
-72.42062416 29.32486467 41.29822794
-37.4650554 51.37497067 29.20382481
59.41591389 48.63488717 48.36357564
27.47670492 25.72942131 34.85253304
-46.97936569 60.01091645 35.64543662
-33.49745884 51.1080027 31.54633973
5.243265155 62.98546365 61.07524764
-12.19994965 43.4859061 28.56802315
15.1701849 33.27610949 36.99160987
-72.32939142 60.16845815 44.47762191
-35.3570106 77.71046744 43.5453928
-55.52399197 63.68066656 41.2869387
-49.69237872 60.50078617 42.12934218
-65.85049111 82.45332301 29.26199669
0.3619816425 65.46775926 50.66975968
-30.95580691 51.06478236 31.56336467
52.34869372 56.78548636 54.14612923
-43.79957635 40.30714711 36.53577167
-27.39999471 51.00553524 31.57303376
-26.89549471 50.99882338 31.55473877
-46.54621191 46.8405697 42.78780745
34.01433621 36.18591274 22.92535869
-63.42335892 52.35488643 46.08521798
-25.81212711 50.98505238 31.50799789
-65.77713258 61.59100028 44.19408221
37.36229946 44.8272581 50.15794257
-51.25748725 57.93676367 43.05965786
-70.0459834 64.77545081 43.00059706
-9.327430217 68.78302959 25.68478891
-49.57156227 12.80179471 18.99355392
-11.75229251 50.53889244 28.17941183
-79.54050295 10.33001911 12.61649847
-23.85554207 50.95201835 31.51834848
36.97506753 48.01777094 53.98047536
-23.26428024 50.93870706 31.56011852
-56.90425559 41.74096896 45.69662724
-6.856915394 57.55349619 27.38816676
26.77020276 28.34911143 33.70919662
-37.35706329 46.7509951 30.42194525
-51.55485937 34.48261714 41.53709637
-20.83700353 51.1142383 29.05952708
-21.19213291 51.9261443 31.35533713
-17.83385102 51.13555247 28.23937729
-42.70672592 29.19601431 31.30075577
6.941661551 58.78294014 22.1376936
-55.65916873 74.21439926 35.56227776
-14.92685381 51.1558156 27.44976622
-61.40831906 53.33396424 45.98836983
44.83838796 43.24792484 55.41297979
-50.7665888 58.43912901 42.96085796
-30.45414681 51.05950306 31.52898108
42.09368438 43.94534446 53.66689592
-60.57855327 80.11419643 32.10433193
-40.8535702 54.12866163 44.49631017
-17.23552387 24.95961813 23.18265401
-1.657553556 47.79207309 29.00816389
-10.9550102 50.96103881 28.95342321
24.93538692 70.48301729 46.02909667
36.99617723 49.01155439 54.0931678
-6.204030052 82.23878712 -8.755662628
23.65202943 49.56654295 44.36862215
-55.69759225 55.33633557 44.96087558
6.557895594 68.1621398 58.99631357
13.17537437 45.37107958 60.11074101
-64.95634896 46.84808839 46.21144911
27.60970756 30.41673423 21.19990341
-4.496447834 50.84986363 29.01233917
-77.99906657 7.034716577 15.61696923
-43.56705586 53.60101982 39.48575667
-37.81972194 65.36149017 24.22503916
7.587072203 47.95078576 60.36255626
6.075057068 43.18727378 28.54961165
-65.97373721 54.93836668 45.7138706
14.06618883 44.41284141 59.41150342
50.26169508 41.73776204 60.25651351
54.56718941 40.54369112 61.64379497
-47.50344909 69.51339681 30.31319067
9.804097022 47.8483199 61.12925483
-46.55341913 75.33331159 32.48988708
10.78933889 47.84688075 60.95807004
-60.72669797 29.12487713 41.38972741
34.73191102 44.06613477 53.66860817
-1.581909648 67.55853984 44.24908625
13.45747484 47.96285739 59.10289869
-42.42157436 24.52556905 27.19790317
12.27704186 50.5653222 29.11669866
-55.98572513 75.66277504 36.2906738
-49.2162777 63.6884821 34.16664924
6.373555611 58.87129315 21.22036847
13.37259156 50.54540605 29.13897196
-15.11026184 49.84079745 25.27051066
-51.59242528 32.06482036 40.47860536
-49.45123435 58.36362006 37.75993324
28.28455012 31.32852667 22.13973675
-44.63585348 50.45120785 35.46841213
-26.40683825 52.00453569 31.43978832
16.60364039 50.46414894 29.46608352
18.66207786 30.26058755 36.37221893
-47.27032834 42.33235048 36.99418976
-36.35398425 67.11987446 21.01282828
16.7682648 50.43066137 29.82343683
-63.03269064 22.51375404 37.0032625
-60.74208859 62.53333591 43.94783122
-39.1599099 58.61192361 44.56811001
17.83771737 50.21329792 32.1427998
-51.67461975 55.38832367 43.59015452
-59.61579601 67.7306838 41.66484155
19.30088079 27.94760408 33.96799072
18.08422107 50.16328495 32.67637723
-50.81647641 59.4687264 42.6713356
31.13252475 74.10022 49.46926399
18.74496983 50.02819911 34.11854362
20.64074797 49.64178183 38.24282185
-62.96141154 54.88071997 45.80860716
20.88785774 49.59146313 38.77983283
-48.24347146 54.38087113 37.15113457
-74.84921864 34.95931976 40.44555839
-15.82272467 52.11660442 28.12035827
-67.87775368 20.71893076 35.45629982
-49.73315701 80.49090916 25.66289239
4.737345215 62.99289179 61.08549834
-33.14030403 82.32632919 41.9781295
21.71063345 49.4242 40.56463735
-46.96337068 10.99350239 -1.298356407
-54.69737818 54.42202319 43.73085215
46.33984216 66.81138305 32.13037818
3.610123911 58.25161584 23.11439735
58.40931158 46.11217238 60.36113441
-49.55091638 61.68163738 40.04746326
22.12176156 49.34069946 41.45556809
14.69629117 32.76516627 37.18674512
51.10135332 41.73542765 60.12348491
-49.6044788 61.75821216 39.16874182
19.5525207 74.99939407 52.89265373
22.5327165 49.25728627 42.34551783
12.12605646 71.76785315 56.8631658
-5.312734496 69.42906031 34.89949046
23.44230668 49.07100823 44.33450531
-40.63854617 52.33344273 41.98895095
29.44822656 48.09793883 54.4852418
-69.39100284 86.01370518 23.56552329
32.05517769 47.01734685 54.87909913
5.0868073 58.03278135 19.54653399
-24.86247191 44.46036734 31.32386709
47.40309421 40.68199483 55.57785863
31.50494604 48.05155968 54.63141604
-74.66435457 39.26212172 42.90000671
-19.52723358 44.24018169 27.03583928
52.88825859 58.46595725 52.01502076
49.17127136 54.91755049 53.12988188
-71.57731044 19.84625646 34.63918579
-73.71634497 37.27059994 42.53169017
-49.67347448 83.14532796 23.97028409
-12.73634374 55.05967521 28.32629858
32.56935718 48.03496216 54.62110254
-56.45254432 83.42483914 27.84496516
33.53302417 48.0196284 54.61533095
6.643872919 57.87916913 21.03283517
-60.74611108 24.36028818 38.43784592
57.57315191 5.798252807 50.72944044
-37.4877773 63.03651251 27.84520982
-3.033179541 52.57325575 37.86009755
-65.21168726 72.59563872 38.69583191
-45.54400036 55.839022 37.18897246
50.51637776 44.29381479 59.6686225
-76.59781251 5.458783989 10.33775288
52.93777158 48.39000144 46.61516734
52.72731001 61.85111776 41.88150381
17.4120588 46.46836183 58.21800815
-21.7810514 48.90019952 31.63517755
53.82652638 48.43723431 45.89736282
54.39388949 48.47314528 45.37228235
55.05882607 48.38550265 46.2628947
55.25822912 48.35924917 46.52963695
-16.60012387 79.72753309 22.37940933
55.45780534 48.33290834 46.79736022
-59.97355415 85.22095714 25.14571655
54.76958556 48.81062272 47.20959165
-65.97311172 46.8778169 46.06024162
37.249141 69.27952645 16.86529889
-0.1520599263 38.68653208 29.54528664
64.38342634 44.81971679 62.57232118
-68.09874734 22.59149455 37.06692141
-19.78689834 21.50554862 34.63337357
-23.83772645 47.92973528 31.63997324
-59.42758395 32.48062021 42.9725503
-20.64589799 55.53778109 30.11113727
3.946534948 53.95547468 61.26970809
-43.77310604 52.16687509 32.86708583
59.8499766 48.12432395 48.38114694
48.75719178 9.163674456 42.47562943
60.69838966 48.09279541 48.58535574
62.5944549 47.68145545 52.9988999
-11.13362358 46.45686091 28.83550209
62.77379826 47.64054305 53.4396392
58.81069494 43.39861809 62.6523027
63.13265814 47.55863088 54.32209882
64.80222014 47.18721484 58.3153724
-67.79964276 53.58466646 44.29689894
-55.68729336 72.51132664 37.85822892
26.08572622 43.61406088 54.73885037
31.93575945 32.22558939 22.68295231
0.5571991688 58.04092497 55.27567147
-6.750496679 61.15253356 38.02738932
-49.24455804 23.23959159 25.94772918
-64.51174276 23.00633982 37.39367242
-50.57433545 33.01407662 40.91807197
-38.93061684 66.93962086 29.42336508
-10.08373971 49.92593693 29.15020298
-18.43700344 25.33789177 36.50043169
-70.68968871 71.62897039 39.30902945
-16.05815409 35.17333766 38.40255508
14.44309617 70.83350434 55.61461333
5.262165527 62.47371351 61.18577151
47.50762243 12.7396298 41.98811654
-46.26263968 55.87942092 36.85704111
-60.1826446 61.494476 44.24770413
-60.2209512 29.11753634 41.3784957
46.01350294 43.17548378 56.02982707
-73.08444316 42.22178165 43.20066107
-50.58644392 51.81944825 44.02625723
-66.28780703 84.1805711 26.77416135
-39.58015303 45.84062832 41.41410556
-62.42978186 74.68786736 37.18375222
-71.40312087 51.15687809 44.03443858
-40.60756071 52.86958413 41.58576203
-68.35599923 51.10645673 44.03866219
-63.83380816 63.10847031 43.68750161
-60.47638901 41.30623314 45.59794424
-15.65427674 55.72058956 27.03703055
-39.79105605 45.39524468 40.79802332
-56.61273614 72.97242779 38.50855876
-63.91174747 50.84682295 46.20513544
30.05959233 45.52623938 55.08961039
-26.62441491 89.28493503 35.70126582
-11.76500474 48.53644091 28.12119134
-58.32546747 50.7544391 46.21225741
-57.81791979 50.74618986 46.21122822
-61.96586991 70.93833905 39.83605672
-70.07270627 83.67971807 27.48359103
-41.80839064 51.33591949 30.48545967
4.301131661 59.93947211 61.65516575
-59.49270477 83.47174899 27.88017823
-11.11068831 51.96966769 28.92749353
-55.81918144 50.72905877 46.02892705
-66.03982744 71.01118129 39.7673789
3.812374851 55.93290319 61.64642349
-57.27643246 69.79837009 40.52206284
-55.91664251 8.230010283 15.00979938
-54.63375249 50.87441561 44.11544999
4.395291518 65.09996819 59.99681288
-52.19688207 65.19387859 40.56589177
-6.604110986 43.38870047 28.62929696
-49.52673616 50.97423623 41.98272038
46.11431581 45.85205152 48.24561199
10.11705539 33.84954501 37.12505486
-49.53132962 51.13760942 40.0870409
-61.92916651 43.80681795 45.97958876
-49.48411513 51.32440426 37.90958561
-10.36473769 47.92254149 29.15410375
-16.77450256 49.65775748 21.88611145
-68.31857671 61.6341158 44.17823449
-68.33780062 29.24755005 41.41713356
-63.94720429 65.71391886 42.596383
58.85106638 52.4114826 57.06958898
-19.30069259 44.60334333 28.60343231
-47.66872174 51.32542629 37.55151468
20.01922313 32.80562594 35.70194449
-60.56456593 9.430030374 19.44540065
-2.995437944 45.81386831 28.92118767
-8.324487134 70.08108646 27.90465321
-60.634521 60.47021032 44.57102146
-37.71996662 64.05895892 27.67346738
-46.65373027 51.30898022 37.5488677
54.56123464 52.06622673 56.06902662
-55.73146402 69.83669409 39.78253496
-62.15546774 30.10670338 41.91772854
-45.6413709 51.29386189 37.53130934
-43.07169746 51.4842849 34.83068746
-41.38066248 51.8701505 30.02878669
-37.48568741 51.88759432 29.08349082
47.33469706 12.22553298 42.16246468
-30.45933258 51.564335 31.49615334
23.31214271 29.36348042 34.24641575
-67.22736675 12.08763727 24.82444255
-50.76834364 10.19398113 14.53534444
-57.54529096 58.87951076 44.96796554
-59.36871125 38.32884759 44.99052243
-26.90248139 51.50456377 31.5117085
-3.081792608 68.9479063 40.05962412
-44.20317641 44.7581273 37.38228242
-31.8338296 46.64085868 30.64717537
-24.87928649 51.47509579 31.46795888
56.86593946 53.91600668 57.4624738
6.892304063 48.4813591 60.16241093
-70.97399504 46.66824381 43.62017622
-22.33358252 51.42983144 31.50793949
-21.32330112 51.41576132 31.47860899
-20.53779602 51.83718966 26.43655997
32.47062654 40.66209695 41.17663395
-18.72353175 51.59100272 28.94848695
-15.32865831 51.61293968 28.04640376
-51.25235037 87.00462931 14.42964239
-41.39739957 25.93680506 28.09985227
-41.23969852 57.32341879 30.78943572
-4.451414698 60.63700691 43.57355108
-71.42548144 68.99349709 40.91064287
-7.536599121 51.39898489 29.04415485
-69.8479039 61.66223527 44.143455
-7.027008101 51.38970491 29.0547016
59.48142986 52.86827393 57.47324864
-6.012917087 51.37371306 29.04695336
9.021207067 67.15400554 58.57642169
-5.508243928 51.36691385 29.02963938
-16.45203256 48.89949298 18.97381987
-2.985397629 51.33317984 28.94012644
-37.037157 55.46179476 28.29251234
-27.87788159 47.99791514 31.61897344
-41.80989442 51.28712235 31.05221939
-71.4706133 72.17822672 38.90842901
-74.22193632 9.778012331 18.01031437
-32.02015734 52.61277518 31.27603493
-55.63555998 48.37313102 44.21003322
-2.480447408 51.32624087 28.92438208
-45.31333155 22.73653269 25.21125454
-55.63821922 63.06262645 42.65674253
-66.0150533 54.43221432 45.7708949
29.94325949 48.08361361 54.55713362
-46.44298151 38.39283184 35.95609003
47.14657221 30.57845375 21.42338878
-16.83588975 51.62991316 28.13680137
-40.39013262 39.99296318 33.7062053
-72.52613594 64.83720405 42.75670027
-66.37275728 45.90153742 45.81653935
62.2009435 51.42690764 56.20713005
22.29366952 32.38166109 22.70996254
-15.25637974 44.0680169 28.21997647
-54.36224209 21.66994092 33.49207214
51.27184347 42.80252462 59.35661259
27.19467332 30.88053193 21.72159166
-29.86249918 77.07745109 -8.420344998
-55.68618259 68.80942024 40.0459624
7.240599875 51.22945128 28.19412414
-60.86900411 40.3264072 45.39407105
-49.49109894 53.31201343 38.14386328
61.12859529 45.97934732 61.38448115
-65.59351281 40.40530193 45.37919567
-7.652901355 54.97316466 28.36113325
35.9478514 19.89630941 36.85900505
8.05396451 51.2156026 28.19977616
-36.01297453 44.85700907 28.84582777
-61.76125038 9.035913781 18.42216754
9.533707517 51.18771756 28.24129021
-55.71855101 56.47522934 43.39704398
47.97962531 44.15445972 55.94349037
-38.85954024 54.10058776 32.78865497
-8.484656478 30.0274972 20.9485809
-1.948077139 50.80325408 29.06742736
-2.966360708 48.81249672 29.06522088
11.27670999 51.08897833 29.05512638
58.54195931 52.4187417 57.04426854
59.19069579 51.48350614 56.12416563
13.27895127 51.05769128 29.0364898
-14.43960082 30.66605823 26.32462468
-40.70672029 69.62328131 45.22131935
15.31395597 51.02226596 29.05964518
16.24701619 51.0223715 28.88047977
17.2530041 50.83702222 30.84030195
62.16736579 39.39731996 61.84902713
61.38659019 44.86954995 62.56533611
-14.82565366 70.43009466 19.26628048
17.65720564 50.75701572 31.69199222
-10.6703454 43.45764686 28.60437226
4.884319017 54.88329581 61.97332414
18.54731485 50.58056467 33.57061589
-66.19815355 52.41660205 45.89794793
19.19698111 50.45106931 34.94999753
-40.04555109 42.53510409 39.08931085
28.01468463 47.57552428 54.99655537
-67.50308469 64.73160155 43.02468527
20.73606392 50.14547231 38.20407878
-69.30720613 29.74321672 41.67458543
-69.30770661 61.13530021 44.33084201
-30.97046533 76.8368042 -11.24208579
-50.48108585 73.10049398 35.85253
59.05512619 44.42841616 62.30432239
-16.82681614 75.29716386 21.41383214
51.26388242 39.30341726 59.19183657
-35.65024978 52.38072124 28.83552554
22.2728956 49.84101087 41.44540689
42.42781486 16.58759752 39.07333448
-49.744223 86.91287033 15.20723878
-59.86811238 10.68447025 22.23005231
35.14921393 71.04297011 49.2341999
1.846234066 39.02317404 31.0828556
24.30031037 49.4377464 45.7401557
-38.40642489 65.10753327 44.76496992
17.96355987 34.89750317 35.11649776
5.475126289 54.87021381 62.01251902
-56.24765877 23.81887595 38.03843143
-32.47976601 51.09019395 31.55899654
24.54335079 49.38948044 46.2541129
24.94859132 49.30894984 47.11168924
-53.73859382 56.44279438 43.395983
27.37830283 48.82663974 52.2473372
-49.87008629 84.71305933 11.63504814
-74.78408584 28.64543433 37.98299519
-54.5111809 35.99388329 42.03692693
-69.9275295 48.142569 43.78548879
-79.11716217 8.312486408 18.47683518
28.26633407 48.6512369 54.11418872
29.89403306 48.62305038 54.13098645
-57.49285371 58.36323217 45.12466322
-11.40417972 51.01417328 28.42225871
25.93151973 33.78767872 23.17404661
54.18071046 56.04051725 56.61825725
-22.91270638 53.4706618 31.23349718
27.28735694 68.97261753 39.80781998
34.07256236 48.53462465 54.36062635
-15.1442784 79.64975831 23.00463738
-62.44408341 27.23899676 40.30335498
-49.54566477 63.63829664 40.63869667
-46.13983959 47.78201914 37.60791768
-70.05366304 19.8210021 34.6417881
-70.63642377 65.82994656 42.52512306
35.50881588 48.51628855 54.29958294
13.65507009 46.88352808 59.94157677
-54.91145374 67.33319081 39.55544279
-49.52471904 25.44599499 29.52082165
-14.16218424 59.52409921 23.38504936
-54.6919599 43.87275346 43.83398214
6.783946283 57.28151653 22.11746924
-10.12035024 29.60705629 20.31466141
36.00251043 48.5150274 54.22007281
38.47530512 50.01318954 53.83665912
-47.33363902 11.19932873 2.20951511
-52.97641572 68.35262481 39.00536722
39.88269364 48.52754391 53.33479764
-40.35606606 59.66131501 32.61412098
-71.71258198 10.86128662 22.4362503
-67.71003935 67.34707806 41.83499693
41.445076 48.59425041 52.26246413
-7.496912393 84.23083181 -8.327647231
-53.24891086 85.96667405 15.20645902
55.41224094 64.53257604 51.02763436
54.18456423 48.85795283 46.7717162
-70.36481636 26.41431639 39.73409601
-49.74236211 83.70041264 17.53959233
36.57924985 29.84992847 20.24261179
55.35478004 48.76320526 47.64844811
45.36807319 41.72186044 55.54771227
-2.657428359 69.40950065 40.44681225
60.98351638 56.82206825 57.90138893
-67.78871995 51.09285428 44.08838573
-49.52541506 25.90566534 35.83804823
-49.47317348 56.31626724 38.22471935
-12.51190907 48.16321539 26.76966354
-43.05331413 35.90631483 35.04175965
56.23699475 48.7146258 48.04415039
-15.02071974 30.55609374 21.88535396
-7.488990628 83.72461973 -8.279319504
58.3320874 48.64888966 48.40771683
-25.08228948 51.9806034 31.46501241
60.89740359 48.56895425 48.84644427
-55.79009087 18.30480335 32.04285788
-21.81270516 47.39712997 31.61001973
-58.00722558 39.29071487 45.21810343
-8.880419037 83.1809614 -7.529423882
-39.38610386 71.18711532 26.81534036
-17.0640124 23.91915335 35.22842225
-49.49650909 49.40278468 42.73958982
63.07798206 48.08842775 54.00889793
-66.40027102 28.2568631 40.89497033
57.77508779 42.92324753 62.54160668
-68.13535236 66.30941272 42.30880145
-13.25638566 70.88404344 19.52388388
63.25264999 48.04987383 54.42314992
64.64722278 47.74284001 57.72146964
64.82154438 47.70446079 58.1337596
50.32544117 67.80752132 48.93960765
-48.74890731 26.00286126 28.73500033
-60.88103859 45.28030457 46.15431904
4.035641355 63.02474778 60.84950993
59.95624218 58.47524758 56.38590656
-67.32541914 53.56844938 44.39472131
-61.56776138 31.06245088 42.36391678
49.11962497 45.07938646 56.64214285
-49.51524826 39.86347407 36.94962419
-12.05327834 32.69859653 37.23419776
38.64816463 68.20693501 11.56999739
-12.54130672 49.61035928 27.4556902
-49.58055237 29.03349749 34.49825005
-49.71649604 61.41161362 37.3870318
19.3223674 49.91038117 35.37614729
8.649316796 44.13203678 28.7444682
28.20463281 71.57941691 50.15774821
11.13511805 31.32046958 37.15705304
-69.90031969 62.69310799 43.83960987
3.563407337 58.6782862 23.99684664
55.41508047 57.58111182 55.97843201
-44.02504029 43.76294753 37.24780206
-40.42876459 56.75902453 31.36004549
-58.12818348 37.33047794 44.69046798
39.95907182 49.00362059 53.62022731
-57.70567794 35.36865226 44.07763426
0.7648114835 47.26964313 28.78431171
-4.387632956 52.90960742 34.21378373
-49.09837048 84.41622684 9.107084263
-55.44274427 30.18943107 39.67720816
-10.51141856 30.0587646 20.97212168
-13.90172996 29.82484355 35.98748497
-12.49907425 73.29805008 20.48911383
-63.01353867 41.34718235 45.60642561
-54.56674755 12.13095699 21.90709913
-37.68261909 46.06611276 32.60800417
-59.84988076 46.76235161 46.23291018
56.01729679 68.41853433 52.41433406
22.77630021 45.47116235 57.11795254
-49.6081764 77.21634486 28.69274526
-75.63234825 31.01452202 39.77586981
-0.7654602449 52.47665433 44.37569164
-0.4376797685 47.77138743 29.01566092
-31.01856557 47.07871219 31.23541756
6.135233528 63.0062976 60.66328815
-55.64930085 82.03333718 26.36542913
44.9090752 46.16268987 44.86934143
-22.5876234 20.16041902 33.30281181
-29.4507451 86.53945506 38.97859517
32.1973354 22.06263718 35.73250062
-49.87768794 10.93403502 5.774403988
-62.85124684 28.20104014 40.86618377
-6.39683172 61.07324193 38.88041859
-9.024559967 33.31980166 23.61855746
-60.87839139 51.80777754 46.12447573
12.78604412 50.55633921 29.12390997
-47.10862164 46.27516616 43.63203856
7.547004313 65.58572529 59.58338023
-19.1597627 58.89163548 25.85357756
-68.34508074 51.60316501 44.09706853
-54.45207117 67.85145404 39.27809875
-55.87649811 7.167232028 4.03308389
-61.4531273 70.92746928 39.86445789
43.82534134 42.81072953 54.85481507
-65.36539917 51.37615142 46.16416526
-49.61953792 45.29845013 43.79603609
2.315592082 64.97783544 55.98458855
-62.37186468 51.32798375 46.1524457
-52.62236826 64.15630061 41.03871529
30.88987255 37.29216956 22.33225787
-63.46968209 22.98862786 37.40055847
19.42508798 45.43958981 58.12356603
-20.76636954 75.16500001 12.04609965
-57.82352115 51.25123145 46.17604605
-56.29911183 51.22559274 46.18296481
-54.14891479 51.37983824 43.98231468
-53.63637997 51.36907329 44.00953863
-68.93551587 28.29685144 40.91424283
3.349442562 36.75158726 33.85985879
-39.15792607 52.20600383 43.18599179
26.53630962 47.60271924 54.96279132
-60.38440147 52.81079944 46.03974044
-10.85360559 47.49348542 28.40148877
-70.16801247 75.37688485 36.48750218
32.11921401 43.76686639 51.81434969
-58.47084813 19.1748476 34.10726786
-49.51422455 51.47014089 42.05015203
-55.29431827 86.90600862 10.51868939
-49.50100031 51.56258147 40.97451229
-52.73482094 41.36552667 43.43325781
57.37013926 7.959080489 48.99025473
-23.33629764 50.43565277 31.58703308
-49.49677156 51.80822685 38.12207448
-0.9952921821 44.79159521 28.75375827
-47.65488179 51.83304981 37.48265196
-45.6515438 51.80120954 37.47022846
-60.03509436 36.38357795 44.39315166
-49.51269174 51.26448591 38.61061141
22.26913104 27.87502403 34.24448982
36.82017413 30.73282847 21.6006005
-44.4654927 52.02058611 34.69735761
43.46795136 52.4835604 53.33986809
-55.7281726 61.47746526 43.59568258
-39.88278931 49.12381691 32.49137391
-44.14948188 52.10895589 33.61123138
-13.29413608 31.79976887 36.25180227
-55.9500498 54.26103058 45.83866417
-39.86120005 52.10144469 32.88062562
-76.40479707 6.012302272 9.701940274
-11.87131074 82.2729196 -8.071112829
-18.60557066 50.12396167 28.47655146
-19.11573725 28.27234546 37.52443582
-59.26680107 67.2010876 41.91958448
7.542196755 53.95045935 60.64221525
-35.42065542 52.38879374 28.69802902
-42.25982073 25.44840597 28.10737332
-34.97308724 48.3435167 28.96007613
54.47563745 66.97100442 52.0323986
-46.65862091 87.56159563 7.087917906
-61.02424891 26.26167782 39.72473365
-33.01337391 52.11602108 31.40549058
-13.08675208 72.80733743 20.47109718
-31.4899343 52.09087154 31.40691567
6.885181695 57.83177669 21.53698226
34.48275261 46.10157313 53.3938337
1.111853007 57.90980204 56.69206651
-28.43758311 52.03781218 31.44076583
-40.28915597 53.14831021 32.46274218
-24.83077543 51.97761871 31.45169561
-24.37172965 51.96905796 31.4635323
-18.77158447 56.20952026 27.78229645
7.951131425 35.45765943 36.34986625
18.24867231 50.12988473 33.03274953
19.12684326 27.91150644 34.42022851
-65.7340948 8.301096938 16.05682084
59.48770007 5.575812141 52.94657857
14.98604768 73.35564287 55.36544889
-19.18397101 52.10248242 28.92530667
-37.85722678 48.31172324 29.87918074
-54.3159652 31.62324284 40.29751396
-18.72757464 52.09525814 28.92213388
-49.75340844 11.98820471 16.81969737
-49.6860491 34.48147137 41.19400393
-18.23104019 52.09256443 28.85871235
-50.26358392 31.56128454 40.24395772
-17.19977982 52.11746736 28.37295326
-39.70113485 44.69234267 37.28739204
-37.73986596 79.42801092 41.54124178
-16.82846917 52.12838594 28.17539128
53.92313968 8.186438766 47.0082771
-44.0158188 44.26051186 37.29659449
-11.94455558 51.99385874 28.80568972
-11.86541605 31.00287267 21.92372366
-11.63413246 51.98593576 28.83846584
-3.709624736 68.58894947 38.51974724
36.65720212 51.3400785 50.43316806
-70.17587709 35.21022903 42.46822739
-10.62737282 51.96125323 28.93300356
-48.16836986 43.80310924 43.39837527
13.76003352 45.03619999 28.92691933
50.96631529 54.15406519 55.82401026
-9.580703098 51.94121629 28.96600087
-20.26680496 25.87113168 36.48578875
-4.944064478 51.86948004 28.91453274
43.15360287 33.02241095 22.94683791
32.05629853 70.61427569 48.97399419
-51.14491895 43.3157149 43.79740943
-2.983691466 51.83453521 28.94634296
-55.41803705 18.90428555 30.83931875
2.931638163 58.50420614 61.09802849
-40.13281554 60.75135321 31.57086841
-48.88031208 9.791494726 1.367716647
43.08209962 66.90517662 20.00954136
-67.71589996 55.57606513 44.46988318
27.6441697 70.68426037 49.00298037
-18.8498476 41.61040109 28.30186585
5.712102488 63.49895752 60.85146685
9.480299341 48.84264375 61.30144993
-50.89676248 61.01587405 42.2061502
-50.94052276 29.6461175 39.29914703
7.461853961 53.2258172 28.28322068
10.80468016 48.88074082 60.60662391
-12.66969362 82.6412766 22.76483694
-25.40735777 51.48130904 31.49653729
11.633365 48.94588649 59.69233006
-52.55721225 28.71387404 38.77634001
-60.94790467 55.35835506 45.70652349
9.61409947 51.6493806 28.69327846
7.476243043 52.88018563 61.42602981
-66.46382951 12.95468849 26.26675008
-46.56903764 21.16953414 26.1616655
10.22089481 51.61584464 28.96686887
11.75063759 51.58751553 29.00400279
-35.75935014 57.00525585 27.61112662
-53.91965577 37.44992048 42.50130005
-37.98543356 52.39412417 29.12526814
15.79720056 51.52849222 28.91748456
32.33640683 49.1197371 53.72595003
0.03616818814 45.77127361 28.83751262
23.96806341 72.99315675 52.03388652
-2.477521925 50.32033309 28.94785406
41.87130314 49.03012718 52.94784539
-41.70556094 23.15329165 25.51182976
61.53562225 2.97989382 59.3847794
7.392605749 53.92680775 60.94530827
42.45953253 49.05551235 52.54097683
53.88438702 49.31336767 47.36881499
54.63747597 49.2803688 47.60827136
-12.62987629 36.18285616 37.68281617
-13.89560278 30.69563659 31.70415554
4.315424452 63.00750303 60.99634329
-54.40783753 72.09464753 36.62470311
48.71851303 45.18261229 55.52031638
-37.7346803 59.7265408 31.35701824
22.0557282 72.96670258 52.70567983
58.41698162 49.10828157 48.88521138
-16.18446878 44.04062584 28.71494417
58.93048615 49.09702745 48.91792899
30.4539923 32.73193367 22.91416124
-38.962756 42.93045648 34.29327548
59.51048365 49.08939834 48.89588421
-33.18399002 49.08967924 31.61016743
-36.73781542 39.47527146 27.36614707
39.63378333 33.56571792 23.13762566
-55.36034489 59.02861636 42.8203563
-14.89403803 27.2939892 36.42352395
35.06148129 33.13995093 23.12556424
-72.14061697 38.72572208 42.81899242
59.94641271 49.08010968 48.92057948
15.70824911 54.58665911 28.3928602
-37.06887919 56.39550629 29.11263899
-54.19720088 43.36873932 43.76395168
-16.83696115 82.2589722 22.1709874
64.80191121 48.315692 56.86852645
-64.08648646 14.25322179 28.21897722
26.09887431 30.43552461 21.26989597
-19.2179719 50.58820793 29.03067161
-12.46396101 83.34943588 20.33141333
-65.89992696 28.25224766 40.85313106
41.28060329 14.63709886 38.628363
-9.633664627 43.93247585 28.72115055
12.24534565 52.08795914 28.92678514
-48.44216123 65.26302451 33.22050567
-11.1974131 57.71123647 26.38476119
-54.97245011 56.97274941 43.30582283
-55.97921434 26.19454174 39.54197992
65.32175906 48.20212664 58.08773813
27.4061853 27.27164235 34.4426767
-45.62867179 48.77637581 37.62049875
-68.00228275 71.04717652 39.72377201
-70.2578863 79.18551159 33.07780847
52.73532587 64.21614617 49.38484585
-60.54318472 66.69850742 42.17067524
-55.74394778 80.15320936 30.72945277
-57.74800568 74.06636801 37.67908667
-11.23801693 80.94422724 13.05918908
-55.57082197 81.46675536 27.10111734
44.70590541 14.42068567 40.48741985
-15.22520589 30.72877743 37.39963752
-20.83634374 50.05991772 29.64544233
25.85102281 74.54107737 51.18532524
-74.92992474 36.78589462 42.56329935
-48.72883349 63.16337999 34.3428183
-53.33372134 58.99741883 42.79603071
-70.76924784 67.40359652 41.76229871
35.55595392 49.03189301 54.13172152
-72.41880191 68.49679539 41.0395099
-41.84775481 53.40273621 41.45969683
50.23696007 43.25688746 60.10603666
-62.97660619 55.3906008 45.71907694
-39.83215115 66.89883451 30.06877093
-22.24244743 47.90551379 31.61692467
-65.26689335 84.16113762 26.80506497
-16.31254647 70.93902453 19.46845146
-41.20723423 33.05200616 32.86475357
-51.30852602 26.3162412 37.23847509
42.59261674 13.56550214 39.16474924
-32.83402141 81.84305668 41.70325862
-39.64739072 57.12818008 44.40553052
-12.1706772 80.71426032 10.0800355
9.678568595 53.14828567 28.76052253
-72.48349906 81.47461863 30.23518418
19.78236907 36.92951512 22.83384194
-29.42237871 48.02129802 31.64207278
-49.49266109 63.13412125 40.65478353
-50.78668232 71.50022271 37.00800341
-68.69487384 32.695406 42.24648777
-71.89326457 52.1607801 44.12716656
-68.3370714 52.1013408 44.13899388
-69.27276605 57.09994657 44.55638171
-67.76893496 52.08730597 44.19357343
38.84187982 30.70411171 21.54841418
-64.40042079 69.9231012 40.43268042
-53.61713327 50.85714844 44.12202452
-59.35824191 51.78428768 46.1072616
-39.28651815 70.13244817 27.38641307
-63.25084871 69.37371792 40.76445129
-57.31939297 51.74692316 46.15219544
-13.72983737 54.56306087 28.4541846
30.030172 45.05125309 54.78256748
-66.4142397 63.6734687 43.44733267
-3.991933498 47.82984839 29.01482093
40.15849286 32.58154628 22.80926125
-47.51128539 66.30583077 32.59060757
55.34629275 9.718525928 46.43120994
-50.09157185 51.81759311 43.95341799
-49.68229345 51.83413705 43.68331192
-53.98830242 29.69687079 39.29119558
-58.53422246 37.82690486 44.83165707
16.35456006 70.95950946 53.78732592
-31.79857525 46.14563678 30.56270854
-49.48472474 52.25481026 38.76215192
-14.30961106 39.77092274 31.31010047
-37.48678132 48.87484998 29.0979891
-62.9202212 24.86898857 38.77373739
-40.16864563 52.63485955 32.5736292
2.148607263 67.82092807 52.1449432
37.69725178 45.30917762 50.32623257
-39.83317431 52.61430125 32.74830867
52.49567847 50.47986718 51.57199574
57.17720655 40.46598458 62.04812332
-60.48216599 56.87784406 45.4582897
-35.18224363 52.89463439 28.60703589
-38.32911536 47.55199 32.96207023
-68.77569376 18.41302975 33.26291937
18.90418118 45.95679396 58.04546372
-59.98418835 81.2152522 30.86240557
-40.26819341 60.70814299 32.0983016
40.72471416 68.10099707 12.403792
-28.9610181 52.55629165 31.34834075
-11.91756622 57.72762116 26.33189324
-47.58570894 72.12218681 35.00398593
-26.87635131 52.52059259 31.36520302
-25.90849374 52.50314494 31.38317192
61.68185078 48.39624773 50.70174911
-67.62519731 36.15449779 42.6733183
-80.01371282 11.83998396 12.65789115
-25.39914515 52.49398724 31.39234525
-20.82318314 52.76258194 27.40163798
-20.83778363 52.81950273 26.74364417
-20.54200793 52.85613529 26.26198034
-40.52108412 60.12288361 33.11400668
36.22067325 46.20362022 51.8777645
-37.49078466 61.20261042 25.82849327
-15.30747934 52.6066882 28.15948861
-49.6266866 82.94162232 20.49948362
-55.89782987 19.31402764 32.00087403
-49.62650438 83.09019753 18.77467918
-69.76720421 58.10601488 44.6247806
-13.80228104 70.4094208 19.31111446
-68.52901046 27.33514 40.3476862
37.9221677 43.78818418 50.46021896
-59.57465498 78.98741381 33.34011582
-56.00756283 61.94717215 44.02290457
-12.12786905 52.50182307 28.77046881
-9.591741817 52.44900069 28.90001493
-40.51216455 42.39881516 40.76043846
-68.88278644 50.11997912 43.93759386
-21.833166 45.90080317 31.50445253
-7.056600863 52.40906476 28.88015382
52.32977649 39.04402314 56.17315903
-52.76923642 26.81196359 37.58897505
7.126403888 57.78442793 22.04063883
-44.66498047 51.34644347 36.73470033
-58.886251 54.30505486 45.88754945
7.60024303 51.33576475 61.85122436
-10.21739737 42.47250787 28.30092297
-5.328594187 52.13205611 31.7663316
58.26950791 4.287224284 50.65784035
39.79478623 15.72203566 37.97026073
-74.09906575 29.47775925 39.84340475
-63.41567638 46.32441874 46.17013221
-4.417677927 51.92033128 34.05047108
9.178398107 66.69638521 58.03219426
-41.92910028 31.56260685 32.81252928
29.15088852 42.54344808 49.10283667
-4.053969447 51.83597328 34.96039903
-20.86416636 41.96416263 30.40592764
-38.18098888 62.80391717 30.67754633
-49.94380523 68.30952347 38.92738205
-67.39982755 69.4472372 40.70222103
-2.257622947 52.38025616 39.9526747
-1.324683951 51.20254583 41.79319723
-15.70566897 59.58413818 22.98242491
-40.88462235 42.4132531 40.66386215
14.18817105 31.76927222 37.19142986
2.858569645 50.23212314 52.26080217
-20.8161293 47.53864061 29.77720765
-55.82080913 12.43571478 24.43504664
0.4858928988 50.78477399 46.29771043
1.033793335 50.65705776 47.67584601
-55.81423242 88.85502654 11.2987813
2.311881358 50.35922791 50.88953367
-54.73550294 20.77946333 32.24728702
-55.81175342 88.80422033 11.88810429
-52.09909237 34.00715593 41.33374467
-0.4182699591 50.99309213 44.05183041
-20.86173825 47.4129805 31.24466003
2.494168508 50.31683054 51.34695018
-50.07645388 79.66630902 29.47428116
49.09369747 68.33641068 48.86141582
-55.8299813 89.01163805 9.483723781
25.92510847 74.01387844 51.46466574
-63.38181744 17.41327013 32.18693212
29.45530189 26.22466637 34.55267741
36.92840795 47.53909191 53.71958819
43.16283005 69.45838341 48.62107124
-71.2979605 57.63263922 44.5853542
-55.22399025 55.44432133 43.61697906
3.950387742 49.97869974 54.99451006
4.674168255 49.81181812 56.79376471
22.33375638 30.41544912 33.8742612
5.586469828 49.59939449 59.08575228
14.19867975 30.78431701 36.97022289
-37.97880038 53.39521017 29.15594801
-41.70777774 51.35949979 30.19253474
-44.40622209 32.07549158 33.15763192
-45.25263015 52.34997577 36.85031513
-58.97852721 57.36489889 45.34408966
-20.83337104 47.09467773 29.10769986
10.31957505 49.37988513 60.73134577
-68.2626365 12.1019236 24.85602851
62.72881937 48.16544826 53.18137497
6.526664335 52.24713161 28.16958163
7.549413557 52.22677222 28.21088397
9.857242905 52.12805656 28.91673056
-39.19948939 45.46184317 34.08544216
-37.21804862 40.37506279 28.66557795
-59.51549003 66.16104837 42.38726371
-49.45325985 50.88761608 42.97425816
-3.998156271 50.34406753 28.96232135
-60.00967258 83.48475013 27.82784038
13.26664029 52.06833349 28.95984699
-56.75175597 72.44122763 38.87498899
-45.94104435 38.38741277 35.92327606
-52.48447225 14.34737695 24.91338119
14.78633955 52.04507074 28.94008223
-39.46312387 42.02976329 33.1913205
-45.10002212 25.94622004 28.6966687
-68.12922382 26.37681778 39.74306631
6.943645396 53.36612034 61.71315557
-16.73340256 47.9569319 24.14277567
15.31932003 52.03638109 28.9393155
-64.41619651 49.84907705 46.23061762
-23.32863662 48.92514016 31.64078217
-61.83131453 63.59166093 43.5230256
39.93581698 51.55120926 53.18359797
-53.9413468 85.41730533 15.88934296
37.58744455 33.1046446 23.05370951
34.80391098 49.57896918 53.75092202
-66.31185788 62.11517505 44.03769516
36.46235238 49.53527493 53.94188202
-49.49737807 58.4612304 36.63560089
41.00383171 18.76966409 37.32043866
-53.25119329 56.94692421 43.27736666
-14.42114664 34.6134617 38.76317588
-19.95143628 57.16489354 28.56991286
36.94804888 49.52565939 53.96088096
-49.48221769 39.34459608 37.14019862
-66.00561181 42.39904491 45.61952549
-18.73871603 45.06985396 28.90730502
39.98633702 49.49208294 53.77124116
35.50181693 18.47838114 35.92448982
41.46021704 49.49193328 53.4919007
-65.78065609 60.56356778 44.46865876
41.21585181 15.12242247 38.83336175
26.53255973 70.06712481 44.72584848
40.98617436 17.14803383 38.66895109
-9.653587658 43.44030981 28.6117316
-36.98861914 48.87133517 29.04378907
42.42568356 49.50046961 53.20868438
56.70680946 7.444585152 49.26275221
43.04097851 49.51220182 52.95514794
-47.68653468 67.90088175 31.58743534
-14.71181116 78.16034571 22.732429
-9.047193911 47.39992678 29.14309236
23.85456035 48.98693991 45.23181263
-5.742218003 69.52182006 33.90457248
-32.51234865 46.18358113 30.25834361
43.41090673 49.52381774 52.74975422
-4.940099942 29.97392789 20.89448424
-12.33154625 80.20739967 21.82136526
57.59243974 49.62998453 48.8127886
23.62528381 48.25349038 47.96331405
-20.52500552 24.89783512 36.18048412
9.367535386 68.15718042 58.51807045
-10.40523293 69.58115479 22.45175295
-8.343519386 78.39018306 -4.456730063
28.37631669 44.55325112 55.0524971
-26.47276802 54.54887349 31.0490359
29.57172929 40.71208144 41.14921458
58.42516413 49.60637 48.92811774
-63.23105184 18.32926093 33.17797253
-20.39461011 53.87293635 26.08338374
60.900318 49.54713829 49.143696
62.45806915 49.15779065 53.36645574
-49.51927878 35.34556301 36.95783364
-14.72457779 54.06707546 28.57500734
17.75590953 36.45841389 22.86254593
10.08375377 57.88506146 26.13506853
-38.30786712 48.54570357 33.07554748
63.68940639 47.95344533 55.4592704
-16.80175424 80.66023076 23.24370818
-35.87774137 59.6008352 26.63552945
45.66972429 69.42030735 48.58500622
23.77326267 44.97398375 56.87279689
10.86727897 34.32020758 37.34482904
-5.466886893 40.47074411 27.32634303
-39.62542249 64.81046588 30.96609026
-8.567512538 51.92567867 28.95315136
14.94185627 71.33190394 55.56035136
10.73409941 70.72224546 57.61351578
-65.52121978 60.55658678 44.50022325
-69.69287722 81.40491912 30.5121187
-35.64494709 55.45328713 28.12577223
41.13138643 29.78365172 20.14388085
-27.40589551 88.17915663 37.03369997
-17.44166281 31.7610553 37.49217613
-62.28811726 73.6119739 37.99321078
-55.10115214 21.24312706 32.76111254
-10.04740857 62.68028134 32.57419991
-25.892364 51.99279241 31.4779996
-47.90148778 25.06651328 27.78992121
24.09483922 43.55289787 55.8285495
-33.44816893 52.63934264 31.23995087
53.88428835 66.99674867 51.84631443
55.85457854 63.62712294 49.80115632
-67.52156977 19.78260354 34.60466118
-68.22410392 75.87723924 36.13494902
-56.44778416 40.25183659 45.41658798
-49.53997792 87.98105886 8.594615836
-49.51792521 26.40410318 35.87703055
-49.58119552 87.92751714 9.224027779
-68.94406831 28.77860381 41.14998167
8.623209276 59.53379934 24.75377305
-73.026967 45.20608448 43.5055802
-13.64059453 29.6483615 20.50649212
-71.91881643 50.66702142 43.99275761
-8.922747826 61.15142124 32.6279215
-32.01065071 54.65401053 30.88463582
-37.63939553 47.34607533 29.39430279
-71.56922069 41.69856115 43.1589836
-72.45333591 49.18222827 43.85133355
-49.87388278 82.52120538 25.42713292
51.75274277 66.60343498 50.99204421
9.097297006 57.93666917 25.72409172
-6.647845935 83.77109122 -8.979205927
-55.05143763 10.88119532 19.02775489
-49.95824776 35.92720473 41.94270864
-62.1429304 54.36207429 45.84669452
-14.9703586 25.87539605 35.42622054
-39.47149587 66.91512809 29.81084403
-36.27328367 75.16603579 44.12454676
-59.03898652 21.97917888 36.62073689
-62.39073217 50.82289633 46.19282637
-32.08968614 44.68672551 30.07440907
-3.700341849 59.04882127 44.38721251
-50.08604606 43.79793266 43.82418119
11.69626103 32.28961674 37.4527541
-68.13712745 71.58024694 39.38785812
-45.62509545 49.27678779 37.6373102
3.785999629 57.88368161 21.52546474
-15.08614507 73.32701477 20.64624042
24.80945782 68.17746913 37.85775106
-55.75989152 84.12276559 25.43747298
-56.80473319 46.71292605 46.22595025
-51.35184988 72.03975379 36.67915451
-48.37797511 70.26519558 33.40582144
-69.78263413 57.10936631 44.54426534
-33.85147243 45.76220534 29.57871931
-44.18425776 46.7326702 37.76330309
11.4883122 68.778927 56.72257816
-67.20165623 79.13049557 33.13363329
-69.34367257 52.61577049 44.18572444
-44.90038709 52.61829136 33.66833657
-46.94626586 59.49200352 35.83640345
-62.45088817 43.3200011 45.90376858
61.77511219 53.27891064 58.09550014
-72.93971156 52.68292072 44.09198179
-59.37414555 53.2989582 46.00681635
-78.81900568 5.874004661 17.59444611
13.57698019 35.35770442 36.43733394
-71.37767291 52.65068917 44.16825893
-72.99458332 47.19861293 43.67524363
-48.58845672 73.13688871 35.06909755
41.9766244 44.48183246 53.28791647
-33.95857393 51.1176073 31.52277995
-39.90165913 44.08639751 32.70660436
-65.92508057 52.40274215 46.00676709
53.22366925 42.13339714 60.92546721
-55.1951733 20.29851205 32.09154149
1.216773142 50.61431098 48.13718656
-59.36083022 52.28780935 46.08914904
-58.90579318 52.28127078 46.07827531
-55.66911523 52.40778586 43.99234162
-51.58846639 50.82492017 44.10927487
-46.67222264 73.93614856 31.25190881
19.48756198 71.54142758 52.26115407
-51.61243948 52.34366125 43.96311447
-66.30710839 31.62626882 42.54916952
-50.59024438 52.32358138 44.00127758
-49.49093298 52.46282372 42.17520522
-9.41494826 69.35417114 24.89789189
-16.7539027 54.09963567 28.58402915
-63.49609504 75.24480715 36.74838179
-49.58588164 52.56027541 41.06202198
-49.50319123 52.59289656 40.66756235
-49.47764498 52.65434355 39.94936884
42.47798829 49.976301 53.50155252
-49.47654224 52.7528987 38.80505828
-49.20969273 52.86606745 37.44042282
-48.20419065 52.85440822 37.3840164
20.78048123 32.40163944 22.76661334
-6.202541746 41.42504699 28.04167438
-68.33144771 63.18442331 43.66351058
-47.69723173 52.84645597 37.37965177
21.76315125 27.89487129 34.11058171
-29.37668874 50.03189209 31.5994797
-44.26572387 33.51126661 33.94323645
-44.84174762 52.75642621 37.88022512
17.56818899 33.80756701 36.1913901
40.998403 50.48154465 53.7451222
-44.01305018 52.6728984 38.69183936
-9.756735416 61.82655613 30.77615003
-52.92804281 67.82600752 39.28285929
-1.796377365 60.46891572 50.84518681
-8.141044519 68.07879783 27.80713374
-36.77715741 76.65430197 44.42368133
-37.34121466 41.86950728 28.82038678
-72.2436091 23.60542441 37.74021545
-36.44551539 75.65602112 44.29592606
-18.76171129 29.77385336 37.50624411
-41.37451194 52.40735967 41.27122211
-40.94514085 52.36421234 41.69022427
-11.6491301 81.31359453 20.50298883
-39.84148082 52.25348251 42.7651825
36.56923222 33.1227154 23.03810967
-65.43070312 49.86527854 46.23601144
-51.08701584 64.65213565 40.81654029
-10.00297129 49.4201448 29.17976327
-38.32231817 48.05077724 32.99712849
-67.21345826 56.55004575 44.72066852
-56.48839214 39.26788887 45.19343362
9.688963222 32.8628373 37.00783598
-0.1197458757 51.39427612 45.16430458
2.708243187 50.71105855 52.55627946
40.16901604 42.61668637 51.9780515
53.176386 66.15118864 50.14391144
-55.76008306 78.40692204 33.52478536
-21.02441855 44.9096294 31.20320622
17.10718387 29.77461008 36.48368677
-20.85271271 45.04601465 29.58720114
-21.40784361 24.94672904 35.7812502
-41.03697009 44.85251653 41.50937035
-67.73106492 25.41931667 39.12922491
2.519722127 50.75660056 52.06354653
32.31723705 48.03825258 54.6309858
-49.62383468 23.38801745 30.12366536
-65.85490807 73.67675908 37.92134555
-34.06191907 53.22815861 30.34823188
3.828612074 60.46943455 61.4197281
-40.47793217 70.10919867 45.36344559
15.3239557 50.51500566 29.11974505
-9.047500691 61.667012 32.49299969
-33.41464523 53.14942579 31.13878104
18.57704529 74.99590663 53.11916758
-31.0013471 53.09661879 31.29157282
49.53978723 12.70563704 41.99518333
-25.91578354 55.05506911 30.89316823
-68.30246402 87.21236941 21.0962446
-29.32922423 53.07080091 31.27240183
-58.4676894 57.35498998 45.3616997
-26.93362348 53.02692119 31.32493422
63.80522076 51.78886275 57.52598674
-25.41943084 53.0064362 31.27397329
-2.417488587 67.18048971 42.97047688
-49.19842073 51.85594934 37.51117957
-24.91403039 52.99927011 31.26077956
-55.47626896 79.19958309 30.09551268
51.08774949 44.27814795 59.74153062
36.7823247 46.78179519 50.88541784
-11.00496265 37.75517524 30.77358868
-24.4043701 52.98995519 31.27171871
-23.89072704 52.97863119 31.30522114
-22.88110366 52.964893 31.2721628
-6.61639295 60.63222585 38.21528576
-22.37047366 52.95508891 31.28859562
15.33107471 73.89442493 54.87171562
-21.8614714 52.94610592 31.29580693
53.4730307 6.733399065 46.48213812
-59.44332649 65.63481904 42.65571498
-13.3446332 34.11332112 38.53723428
-64.6534587 79.08397336 33.18774046
-20.94394399 52.95404686 31.02864477
13.15166417 70.77878654 56.49610136
-20.82117984 53.21423171 27.98481674
-20.90487891 44.57011339 29.2951178
-20.85782688 53.33182939 26.62664563
-17.73455671 53.09432847 28.78810363
-55.23992473 19.40337693 30.83817554
-15.72708511 53.07279197 28.65527815
-10.11351444 52.9666416 28.81700756
35.99432793 48.01693896 54.17716645
3.179471578 67.59825226 54.53333497
-66.21186644 30.17068823 41.9485253
-7.577022806 52.92602432 28.80479834
-66.14198363 81.33576422 30.63774252
-62.36303858 84.68520194 25.9942021
23.39829499 47.17199686 54.73472837
31.03521926 32.72368437 22.89908164
-7.070167779 52.91812449 28.79984511
18.49699423 50.07895457 33.57662759
-5.574908287 52.70896732 30.94273957
-8.04197409 50.9039221 29.06094218
-5.008652451 52.57199187 32.42486239
-4.255087703 52.39008586 34.39285106
-38.31734626 64.36696055 30.0385248
-3.503254598 52.20905337 36.3510296
14.18958609 43.05843057 28.49782824
-37.34060064 63.86093228 24.07331622
-3.315426194 52.16386076 36.83983848
-42.28769933 33.00236839 33.64703636
-55.73039731 77.93928702 33.12113173
-14.55723212 30.07451311 27.38750438
-51.6586464 59.9993217 42.49904584
-71.52824807 43.68675698 43.3773052
-72.49039502 47.1920583 43.65518281
-56.26265066 15.02766963 29.38985108
-1.431427744 51.70905207 41.76030064
42.60774782 68.48617897 48.3597056
-1.243772505 51.66394681 42.24812851
26.70969587 37.90842018 21.80219649
-43.31995425 38.83111445 36.09924641
54.75713979 44.06249598 61.54520163
31.14635325 23.60388649 35.520905
-17.21204349 32.24655338 37.63901122
3.273113711 50.57478191 54.03055418
-20.87924323 44.93550846 30.87509739
0.06721670686 51.34952026 45.6482084
-23.49690716 41.97899835 30.73578328
-52.62849501 65.20099003 40.56564823
0.2540061253 51.30485176 46.1311312
-20.85542863 44.99782896 30.14709377
2.896591083 50.66560389 53.04803137
0.6301824244 51.21420449 47.11169199
-16.22197969 26.14655702 20.86379367
-48.20604543 31.17906227 32.63540485
1.387210455 51.03055146 49.09930089
3.837291577 50.43885467 55.50090486
-62.27460199 62.56306264 43.89500112
6.865731734 49.87752207 61.43972261
56.20783765 42.97049689 62.29198563
-38.37865068 54.60800199 32.63315259
-59.61037825 76.25896435 35.88755706
15.81577073 36.52697748 28.26324764
48.74274448 9.623622581 42.96561208
-12.79120658 81.57239348 0.2365365396
7.852005304 49.85078463 61.56202209
3.720918079 59.15060308 24.31045028
-63.59246508 58.46678278 45.08580465
9.011125825 49.83596754 61.51297843
-45.10587017 21.37301116 23.52052045
-48.53060348 85.88424859 9.436862572
-8.48283987 80.01666579 -5.831636875
-46.56076037 46.28947787 37.53477547
5.567699248 52.76230957 28.19854124
-18.23556672 45.06382344 28.88135813
-61.07863399 38.85117144 45.07973938
-67.47939744 9.951470264 20.53748677
-14.01857229 39.74800017 31.5206996
11.22229388 52.61068718 28.88031959
13.35002523 52.57804246 28.85351221
-69.94674366 69.493113 40.65537335
-69.50066045 64.24593016 43.21702202
-71.06939064 64.79614214 42.95556687
15.277238 52.54743608 28.84128232
35.87280331 50.08710505 53.47490667
-53.34254228 31.12554962 40.06282156
-74.25031527 9.389223223 16.70243301
36.92620259 50.05128451 53.68984809
-30.02131649 54.10224045 31.08396669
40.9902205 49.98345621 53.70221584
-37.63054345 54.12485579 32.2725568
8.453740677 56.93476112 25.82441993
-22.82549447 50.42576133 31.60444691
54.19351662 53.03315008 56.56768261
-54.50450289 80.37042978 27.97142963
-58.94079573 56.34143354 45.564745
44.93354959 50.01367507 52.59939659
-37.92207466 51.88472519 29.20001951
25.46437233 70.46395398 46.14951673
-66.17824364 48.38883048 46.03833581
-7.017851204 83.23252028 -8.48315996
-58.89755332 65.10507127 42.87469057
-43.57892546 35.43384901 34.80008396
-12.3699573 51.55756559 28.12498461
-49.4961244 31.51437763 34.81548647
-49.69523865 62.9075386 37.49711166
-8.104163517 53.44637311 28.69137961
-70.73441544 32.73311992 42.19762989
-55.88638487 55.28290315 45.61716208
-14.39289806 28.80800928 36.2320262
-12.65011734 78.18579087 22.04386556
-55.65788085 59.98491487 43.42896908
60.3493908 65.8035088 52.81209826
41.46080675 31.12412673 21.99976914
26.75430833 70.40792608 46.5539311
-11.55554962 68.90921092 18.81825767
-14.22116709 53.54630814 28.69781022
-58.50315024 14.16232292 28.20942199
18.35620584 43.47435644 57.83470793
-4.061062751 59.63388823 43.49076058
-61.0136498 66.18583465 42.38523452
-7.691867623 68.40398639 29.77308809
-37.85077824 66.58647006 27.4904244
9.155409523 35.99661261 35.69028222
-58.63526719 63.54044892 43.50802654
-28.11804015 75.73815545 -4.858783704
48.72168086 69.37983598 48.47280095
-56.61273514 36.81953243 44.50625037
-37.34698393 62.41371885 29.22156235
-61.8875737 51.31907721 46.1634821
-59.97236717 56.86845925 45.47001367
-65.44804898 46.35851633 46.1618882
-69.25512833 21.67483122 36.27546522
-48.70579992 74.70272949 34.39397565
-71.76234596 54.17793706 43.99213292
-6.038778657 51.88897472 28.89699282
29.33206777 37.32226536 22.27996646
-59.89580085 80.65606098 31.51040854
46.40083687 46.30884604 48.71480753
-50.70741856 74.72131352 34.55995933
-39.15739196 50.68576845 31.7006854
39.82735486 42.65275602 51.62448541
-55.78045667 11.25560123 20.64704721
7.436374682 41.45804178 25.05762306
14.62227364 34.30915181 36.75707234
-52.61379156 46.32663476 44.08442248
-46.69905291 12.03804673 -1.82131479
-59.93385022 32.48822305 42.98083897
-73.93812131 53.20560724 44.04129874
-38.32162155 52.06815404 32.97348101
53.16671666 66.63350443 50.37332276
-49.57291794 65.72285299 39.75140603
25.29308294 26.79469159 34.55580965
-41.36427129 27.34001938 29.28392136
43.41775504 64.41819998 37.16289064
-53.11568097 50.85197396 44.08646372
-36.56559051 34.66356818 24.92465671
-57.73623325 34.88184958 43.90798118
-53.7026109 77.50439829 31.95628538
-28.76974059 76.37089296 -6.253138673
-69.84518499 53.12319132 44.21749544
62.54629343 48.80929353 51.56859862
6.865223267 50.85743276 61.717583
-9.894582774 38.03750132 27.28438482
58.93866866 49.59511589 48.96083536
-66.10225394 26.34544555 39.72070275
-68.32202244 53.09818154 44.21735092
-69.84133087 73.21561813 38.20820012
49.96841858 66.83451892 48.64973416
-77.56363202 4.799542567 12.34825543
-65.46070949 52.89371113 46.04532346
6.55952598 66.58033573 59.87881619
26.90323648 32.78863014 22.93313769
-28.93994181 46.01685159 31.51258267
-66.51128935 74.76451811 37.07230229
-64.95316181 52.88546189 46.04429427
-58.97982363 83.46080934 27.9093642
-10.59130535 51.45323223 28.99696293
-63.95869971 52.86698274 46.06916381
57.10599122 9.375974936 50.07220738
-58.11228972 13.71071942 27.55078451
-61.39565259 52.82535873 46.06357728
-18.53735209 20.58935217 33.37767259
-16.30538443 49.94810901 24.25267656
-0.03064525111 65.56670489 49.59600277
-72.10301447 23.13139813 37.38960531
-58.85912633 52.78472399 46.05156426
18.89108765 28.40875795 34.51937093
-62.77825055 9.053373124 18.41343478
-11.97422047 38.75298344 31.02842797
-14.6610072 72.35590436 20.18524064
-41.27296983 21.84965047 23.08302721
-55.71801669 52.78600266 45.43769198
41.22257445 18.78321059 37.1214654
-55.6745088 52.91272262 43.95833666
-55.16664942 52.90431615 43.9590733
-54.68794009 52.89822523 43.93848841
-7.013158934 47.36499062 29.16075407
-72.08037018 41.70862729 43.13960774
58.73545611 44.44100368 62.21916023
-53.14445887 52.87535481 43.90963381
-49.53577512 52.86377068 43.35591305
-54.78206876 74.79450517 34.48735746
-49.45585533 52.89778944 42.94575722
-48.70041792 53.37155193 37.30190916
-50.63220182 43.807074 43.822217
-42.00063878 53.07675253 39.44646805
-60.03821031 75.72478575 36.34364142
8.031540865 42.1622477 28.42246741
-41.72186732 53.03524023 39.87520976
-72.94122847 50.18499471 43.95681468
-11.88987776 80.32483507 14.54721858
-40.88598584 52.91092172 41.15898235
34.03219071 48.01560695 54.5668208
57.00969652 65.90240433 52.30094631
33.68716201 72.04158524 49.57365057
-71.74903825 60.1482607 44.60141184
-11.67271507 42.99028781 28.39433418
-40.91047063 53.69138633 32.10344749
-4.000720479 52.81354438 35.25516724
-34.6122319 53.80975207 29.52825926
-9.205628594 62.00121566 34.47011691
-7.455921792 61.31015597 36.33212191
-49.52334662 23.98821032 28.9636655
1.087339172 59.59374404 25.495027
0.3900014892 67.10894011 49.09231354
-56.79745114 47.21146871 46.26375535
-32.55324574 53.63834025 31.12547201
-18.52591106 56.81080927 26.58188408
-24.47655484 42.98204762 30.93176187
-68.86365081 51.11475838 44.03910277
-29.48853062 53.57904402 31.2293664
12.1245999 69.7597238 56.86871107
19.85688778 34.38084471 23.27325254
-47.06735647 20.87579372 29.66665163
50.18383387 39.34029185 58.96974108
-58.84397545 51.77264922 46.14429567
-56.76912111 87.59560266 20.27455643
52.60115562 53.13023317 55.74434397
-74.80649103 54.92729578 41.70020725
-28.98891386 53.57479546 31.18340688
-25.90886719 53.52015429 31.23033806
-55.72874677 38.32626204 44.32637418
-64.18997703 72.57580343 38.73124819
-25.4300886 53.51402843 31.21014558
-49.47574489 64.65035502 34.70329019
-52.62469495 52.35872718 43.98126144
-24.41326159 53.49665644 31.21789732
-69.35081609 52.11715794 44.14870415
-39.07458654 68.53886995 45.84541838
-50.4197518 61.52524495 42.02867316
-50.39151042 30.11916782 39.52957465
-37.06390802 39.96853693 27.5287902
17.52070917 44.90690608 58.84388327
-23.40429623 53.48325019 31.18111113
43.38180312 13.48951639 39.89634476
-55.79755108 22.02896053 35.42467376
-20.60854518 53.65874183 28.6107091
-54.62713978 73.71192062 35.37196151
-20.49031971 53.79732691 26.97936582
30.14128133 30.37859773 21.15983346
-4.926439009 69.88508023 35.35865043
-46.62648959 73.47280756 30.79534682
55.02161187 71.1593222 49.92035535
-51.02566389 63.08976129 41.46209932
-22.14951184 22.51244242 35.04846193
-58.75274006 77.32632575 34.98657186
-76.2617848 6.064050309 9.073937893
-70.74613049 32.23681344 42.13471089
32.65064258 41.02394982 42.76829274
-18.77038799 53.62350302 28.66923941
-62.39719097 23.44539265 37.72020998
-61.57916601 58.94452269 44.98254377
-15.81263628 47.90702437 18.71990135
-10.64394527 53.48865006 28.68494961
-40.95388246 63.54792272 45.87596041
-8.605685088 53.45158254 28.72654801
-7.592425261 53.43600998 28.71409091
-6.330160889 53.39394077 28.96173961
-67.20357252 61.10216998 44.3141709
-62.38089732 64.12333042 43.28246775
-3.421304167 52.66993025 36.81184695
-59.30264622 45.7544285 46.1759745
-14.2172124 30.35565359 29.88561799
56.20374001 71.08334343 50.57693361
34.10283596 70.57303278 49.06248495
-2.064080126 52.33218097 40.47385696
-67.18037924 72.09539718 39.05180141
-13.75632233 55.078637 28.30069242
-52.36792482 60.01022075 42.50778542
18.13635625 48.88477524 35.85502724
-55.91542964 75.12509909 36.69236194
39.46382428 69.04769356 13.30758945
-25.3661512 49.96626362 31.59650924
24.18432866 48.91970272 45.94946228
-60.01421995 57.89400356 45.22601025
45.83465857 66.96710687 30.41896367
-40.73266783 45.34989719 41.50402127
2.966821054 51.08283734 54.01772874
3.35373353 50.9867743 55.05911226
-36.20519166 70.28777317 24.99565925
-47.93463383 58.99280359 35.99332742
3.933149842 50.84316017 56.61579197
5.288988569 50.50610969 60.26995389
49.70450166 66.21099589 44.2850926
-70.99145045 63.75239388 43.40401312
-24.30508569 21.70336711 33.19859735
8.722063001 50.33533154 61.59776365
-37.56153277 65.49141648 22.66752131
-12.84164197 58.95041622 23.96630399
34.68798056 30.3150638 21.03029874
11.25304696 53.12216795 28.76345356
-2.018343603 38.30807658 28.46794469
11.70865543 53.11534112 28.75581717
-51.83326878 59.48608092 42.66378006
-55.66039719 76.39732803 33.52804504
-52.60799074 34.01608651 41.32712197
13.73801497 53.08276344 28.74699157
41.65033948 50.47479336 53.69916784
44.47597964 50.46176274 53.3115702
-52.0601809 69.40281661 38.29252458
45.10441775 50.466865 53.13249256
-8.52856067 37.6019325 26.25363585
48.33089456 50.47463669 52.42696453
52.06058089 50.32768036 53.42166748
-65.99554824 75.29151858 36.68278215
6.89539006 53.8802894 61.58014933
-46.84287613 88.00809039 7.766460467
-75.99468548 6.555419799 9.145465709
-58.27189843 23.84887095 38.07626228
42.91663423 34.04726825 22.74801955
-60.05133452 71.96811542 39.16982938
-39.53683953 58.26175055 31.22513233
-9.066549698 51.42741883 29.00584372
-55.15981637 45.86984493 44.04607344
-67.46206059 27.79470002 40.63594534
-27.90455878 47.49676725 31.61511049
-8.869205518 78.9097981 -4.561908294
7.985200976 34.93825733 36.54632675
-63.53737035 79.61785734 32.60382168
3.586679262 50.06305774 54.08458211
-63.03013695 40.85333918 45.51584208
-10.68974894 42.96521878 28.49789634
-52.59999655 48.83075612 44.14532785
-56.35504634 9.791021116 20.27861704
-7.124532929 61.57087235 39.06897935
-64.09890603 39.3902648 45.2241752
-20.80858265 52.70566114 28.05963179
-14.80134123 45.56812565 28.19876341
-54.52544295 13.88359574 24.8598764
-68.25645853 57.08283663 44.56119042
-11.75539787 60.35511978 24.93224947
-71.3804945 53.65654455 44.14537556
-73.22823374 30.32785338 41.46210077
-16.49556727 35.19576391 38.22563218
53.45103368 48.37886962 46.64651154
-69.83856096 53.62206591 44.2515727
-68.31255853 53.59562358 44.26751677
-39.18807246 45.00342369 33.5782918
-45.62961603 48.2746361 37.61859868
-65.47351449 53.40238652 45.9693312
-49.81136889 85.44009338 14.83719082
-45.6432984 23.65963495 26.21141835
-64.44979554 53.38153795 46.01612721
-63.4105892 53.36526584 46.00684291
-71.9127835 24.54208593 38.45695639
-60.41903168 53.31809543 45.98392346
52.04544974 45.51803465 56.8186631
-8.688589672 42.44465045 28.33275942
-22.29033676 88.99988482 32.35716056
-58.3623403 53.28411939 45.98611876
-55.76814532 28.14648972 40.1486548
1.546146976 38.54844493 30.82444506
65.69843928 45.87936038 61.67370591
20.33993575 33.04029106 32.91661821
-55.72334099 53.29090448 45.40407943
-58.35477279 44.74262961 46.08762753
8.571242603 58.02858752 24.75735803
-42.99947806 21.80379114 23.94465146
16.28356614 36.38345485 24.01351012
12.27036688 51.07090536 29.07543422
56.61368306 40.00025428 61.73548692
-34.7913618 52.31913087 29.38671646
-77.52522453 7.644797281 14.27098333
-65.38226616 15.16796242 29.50039534
-55.43683204 22.14433336 34.01655022
8.346384423 58.06768161 24.34640706
-65.74104432 13.82990778 27.62200831
-51.39992165 30.13229455 39.56950008
-12.37117127 60.89540067 24.60434716
-74.179198 38.26073542 42.77902559
-5.873485865 61.31146093 41.84183477
43.63450329 45.93175217 41.96666201
-47.88463813 13.22597181 2.094405447
19.49523369 29.90798434 34.47996527
-41.6237389 49.28346521 30.9700691
-51.73022199 53.3579519 43.86423195
-50.1072677 53.3321588 43.85414995
55.84230423 39.08760297 60.82403407
-4.060376726 32.28901824 22.98466225
-49.57604217 53.51018833 41.68614509
-16.07297567 81.11693321 23.62963133
-44.89191018 57.91539084 36.26717347
-41.27819129 45.90429684 40.99882089
-49.48660573 53.16107974 39.89515505
-31.53699834 54.12347666 31.12649092
-48.73350408 53.87806904 37.25483953
-45.69348217 53.83122907 37.21884166
-9.705735724 62.104823 33.36274056
-43.76013842 53.62408499 39.25482135
-54.00094336 83.17260671 24.4788869
-9.872438101 62.13935877 32.99361511
-42.66681202 53.49379002 40.55887679
-48.9442625 42.89273488 42.46135072
-11.6964865 82.82415428 2.97633705
49.19875449 63.62626426 39.4271467
-42.24884166 53.44400475 41.05711113
-25.57819623 75.20029006 0.9007876968
-39.90402396 53.23990817 42.97924271
-39.3768987 53.19700534 43.37676415
-40.30188254 54.15916206 32.38374493
-39.92100897 54.14047885 32.52799841
-37.06363705 54.43355355 28.58085489
-7.763603602 60.5104869 34.02065867
-60.00884185 26.24502211 39.7244411
40.66897489 34.04129813 23.24596725
45.98691516 66.98461281 47.66663123
-35.38695707 54.40543687 28.58750087
-3.878911404 52.29943859 35.37341185
-55.75098137 34.92107732 43.07399738
35.97706289 70.0669386 48.75352827
-68.0954645 65.78707563 42.53822394
-34.94495944 54.35592019 29.07803513
-49.66552829 76.42094736 32.1105969
-16.32414234 52.12176143 28.15611527
-34.48946943 54.2995974 29.64500655
-68.84077339 73.19406422 38.26760128
-34.22922653 54.26743167 29.96878
-69.0555465 23.08080206 37.39579093
4.902881242 67.82577855 57.39002045
54.13634306 47.97695693 45.35487368
-65.44277563 46.85807226 46.18831357
-33.51164653 54.17801141 30.86998901
0.01003517581 58.67048573 53.89825875
-49.47560596 42.86343197 42.90285072
-23.43859265 43.4562302 31.05579965
47.23416473 64.17794114 39.22417877
20.08830247 50.27400681 36.83548827
-52.09403481 47.31665882 44.14566173
12.79527265 69.38997227 55.20651412
-49.47934133 49.06535352 40.8268218
-56.35988365 27.1432204 40.2549035
1.671918721 58.27816517 58.13567172
-60.76785818 13.30918789 26.89184747
-17.333135 61.97462018 24.6755375
-59.12405877 11.96445441 24.70908958
-28.08683083 54.06796533 31.11293929
-27.82626895 54.06041655 31.15088036
-28.49836174 54.0773355 31.08264495
-80.02063988 11.34126223 12.62209713
-31.82481211 55.24831588 29.77670574
58.42935401 53.87069215 57.69036545
-55.86774394 63.50717914 43.3664632
-53.67251317 86.68257238 12.80321269
-15.30293244 69.71597723 15.99401438
38.33282252 18.27681008 37.72458442
-14.47778839 74.77456143 21.20596787
-66.54055155 65.75843858 42.57413246
-25.13475624 54.01593388 31.15398009
-13.62586553 32.42296776 23.25387453
-50.26233124 58.43253944 42.94118955
45.35890437 64.28145202 38.38017218
7.141635258 59.63694158 23.83896755
2.972958784 46.27872375 28.21323818
-22.24414489 53.97012913 31.13445804
29.66491295 70.68080696 48.65770218
-21.37496217 53.95290139 31.16869194
-59.01830202 58.38939498 45.11185839
-20.78578037 53.97780613 30.76721894
-20.62978229 53.99822574 30.50042335
53.80697221 51.67193509 54.96345308
-49.91968089 31.56125078 40.1787652
-4.792258132 61.0881576 44.22790654
-20.35724021 54.03418937 30.0309559
-14.29896766 28.32035556 36.04851247
-48.7593116 70.71199477 34.11841456
18.38442209 50.61317873 33.2230727
-19.98705863 54.08284427 29.39553828
-15.74500661 54.08626436 28.54685056
-49.51787164 34.24130972 38.12326176
-67.43370894 50.58500118 44.08957155
-12.69227445 54.03301282 28.58285894
35.35967577 46.08044079 53.47191881
-10.66734791 54.00267133 28.54891944
-10.15980023 53.99442209 28.54789025
-13.9235879 29.40798712 35.00418595
-9.646642025 53.98334267 28.57864584
-41.12638568 20.54161576 20.75977119
-9.134037949 53.97254278 28.6062622
-36.27684688 59.07694361 26.9667164
-44.75893945 49.86241429 36.50038996
-46.72909924 43.31154301 37.17707447
-57.63745366 29.55542339 41.62913567
-8.628637501 53.9653767 28.59306846
-0.9624663483 52.52647655 43.83488914
-0.5684541415 52.42683211 44.91649414
-0.1751345913 52.32753708 45.99417509
-67.54961796 47.60539642 43.74125728
3.003803507 51.51684219 54.79907187
27.0895562 47.04442373 55.51174482
-55.66920071 54.94368696 43.68697165
3.991431486 51.26642084 57.51779954
4.190688723 51.21546306 58.07135519
4.78742145 51.06311382 59.72613607
-51.76189058 70.98199555 37.38329973
38.41172267 47.58373871 52.91841836
-45.4282307 20.83518412 23.99884452
-4.778909663 67.72746274 37.0711509
-17.10276353 25.64264296 21.0549257
4.985986031 51.01250545 60.27576767
8.144679029 53.68092262 28.69644694
-50.10496966 12.62486581 21.14919803
-54.18654879 11.7218985 20.75660132
4.90811063 35.99583926 36.50924529
57.27871554 71.03663894 50.91410908
10.67673766 53.64254153 28.65912382
-61.07524352 37.37997986 44.67783565
-16.58075837 59.48045622 24.35292576
-39.77643608 66.36851353 30.38785789
-7.137703952 54.44645164 28.55071032
13.73026637 53.58888818 28.69964486
-60.3145937 63.56990062 43.48638744
15.28083949 36.35081727 30.41025885
-13.35498294 76.71259368 21.80031301
-13.13943141 82.12823696 22.98351647
-3.920992549 68.09424458 38.47631078
55.08415471 57.59937716 55.8295041
-18.79848402 58.90761459 25.5991823
45.97657491 64.74464619 38.71192369
-46.98911813 12.19102642 2.284744103
-51.81932919 26.32613264 37.22106126
36.87135148 51.28160853 51.07109072
37.5120679 51.10707212 52.97504857
-46.49941418 71.46178606 30.8099544
43.56791351 46.46755879 41.58596752
-26.38839726 50.99080125 31.55115895
-59.25579398 34.90504245 43.92853075
-4.496604673 49.8455107 29.01834915
-44.55217339 48.90234812 35.95282536
44.18343204 69.43910717 48.65020904
-54.34607585 83.17326065 24.53711424
-74.29756899 34.27128159 36.67432223
50.78648029 65.00765959 46.39467739
42.05814786 50.96951702 53.70492399
-43.34630318 47.25818909 37.32953417
43.00976263 50.96026289 53.63087399
-52.35946512 21.17333441 33.04846042
49.44881392 50.93326346 52.71633721
45.70582351 68.91227033 48.64914545
50.35239708 50.87390488 53.23309633
-5.50513811 48.85426703 29.06448075
56.46584899 9.500665989 48.74678139
53.1165795 50.76798678 53.93552489
-58.54698857 25.74872321 39.38041582
-47.16185842 66.30301052 32.55670932
-43.77901312 19.01027933 21.56259148
54.42086078 50.72950038 54.13356881
55.31323018 50.70057633 54.29916017
-46.44678189 20.40742448 29.15883524
-37.22439092 45.69202662 31.03664115
-14.31557778 70.42057009 19.27957406
30.56339546 26.30992292 33.35163687
-57.47270953 88.93497664 -0.9663399201
-6.787823888 56.51421156 27.78648332
-52.1071129 51.84320017 44.04052832
-15.75505933 37.27708693 37.2294596
3.96184075 59.65517714 24.23368182
-49.35935266 70.21512971 34.17417731
-49.54483697 26.41101964 29.97522973
-16.25532467 46.73611569 26.57044746
56.15791976 68.92414795 52.34462545
-55.70797262 60.43806566 44.00465773
-48.50232044 60.03582141 35.64675836
-11.23380541 35.31663598 35.81901099
-6.241761581 52.3449165 29.46943897
-50.88922528 20.22014467 32.18011708
-61.91694036 53.34275505 45.98331674
12.06744056 42.12099231 28.13171976
-44.3939357 56.38054479 36.50989728
15.72302716 74.44670267 54.21236562
5.780984324 61.98234049 60.96440537
-64.24952955 35.47329511 44.11081549
-61.5021513 22.48502311 37.04490908
-66.99512141 64.72314266 43.02601051
6.176814327 56.9339524 61.22788268
-56.36187171 54.77276356 45.80327202
48.8279625 42.00673465 57.40750403
-37.62242761 57.18361368 31.72256038
-13.64146858 27.88291581 35.1746023
-49.6823887 15.32588344 24.67307129
16.21045445 36.2964461 25.03751423
-80.10650424 12.8045013 13.13204768
-65.13684839 59.00471321 44.96227975
40.64821004 70.4923998 48.75028935
55.47492235 8.624429997 47.45446193
-73.49300403 49.19873332 43.85800182
-49.84572526 13.37943616 6.513512009
26.81745332 71.58538698 50.35298671
-74.60641629 54.29937603 43.12476187
-74.50914368 54.44853059 41.37471617
-74.5400763 54.56324568 40.04891844
26.02669951 68.1581112 37.85033671
-74.09860344 42.23780843 43.20801691
-11.77587822 41.00868557 28.11132948
-69.82847365 54.11919349 44.30527019
-11.27238539 31.45371834 22.40352446
-67.14314837 32.15377189 42.41160757
53.78382334 56.5818833 56.23601464
57.58853687 66.86262421 52.69690676
63.49269316 54.25937173 58.03932094
-66.48348705 64.71283194 43.0481332
56.3844379 5.927109477 49.46027287
-66.0074086 53.926142 45.81765301
-65.40903405 53.90924276 45.8997181
-49.5066578 58.94334964 36.86721949
2.380873236 68.35464678 51.73149325
-46.07121382 18.77899916 24.68459747
-12.78093249 58.44235371 24.02604588
-40.9872483 54.62397678 44.59846447
-16.61644197 73.35562341 20.60596726
-54.74998347 56.45742354 43.41903502
48.00893815 54.43177216 53.16417422
-64.97014822 53.89846258 45.94116414
8.475311789 67.62187026 59.07585302
-49.48838327 47.94159332 42.22069312
-54.66386685 86.16010071 13.23086147
-20.84796446 45.29139798 26.73770636
-68.07455572 38.65686304 42.84293611
-3.984987813 37.52758672 26.25020933
-61.41856122 53.84134683 45.92689655
50.98363005 63.44445789 41.19730147
-43.36901332 55.48520532 40.8815522
22.41097702 72.03084705 51.84874373
-13.5721356 31.2271754 37.12526065
-64.86206856 80.19363675 31.99902278
-39.08699425 68.04291288 45.77857533
-40.65569736 44.30663809 41.94697385
27.57340389 44.53704961 55.39369639
-60.90858924 53.83187467 45.93960152
-55.33192201 39.42785372 43.1158987
8.293685087 50.83063819 61.75621769
-56.33698901 53.75577967 45.95113629
-39.17490924 62.61244023 44.74318108
-22.56557255 19.18203041 33.00317381
23.76556742 69.74657787 43.1480322
-55.89857574 53.75762679 45.84608544
38.11842802 70.02741644 48.80395922
-72.09212618 84.88515882 25.52835206
24.3235706 69.63854325 44.29576255
-55.72762631 53.79528219 45.37635295
-43.37614933 19.49510832 21.6841552
-62.85076165 15.12986086 29.45993298
6.576828118 51.74438878 28.1695799
-36.01137349 52.89165783 28.79971001
-55.18588696 53.9184524 43.84319001
45.75299976 66.55563859 29.38451952
7.99201813 44.19104868 28.18476572
45.24489535 44.2950765 54.83263739
-54.67667691 53.90936459 43.85157854
41.1390464 69.48814838 48.66148542
-21.86532316 51.93122793 31.4247041
-7.030943884 46.36953003 29.06689705
-53.13082269 52.36626014 43.99033492
-49.77933856 53.84239896 43.69501266
-27.89884238 49.50274777 31.63369811
-49.45280585 54.19801746 39.50446113
33.89923329 46.07381201 53.82738578
-47.73723982 54.37328576 37.14264969
-44.95380154 54.25765422 37.95416675
48.68824689 66.52541347 40.82890918
-43.49009757 54.07680387 39.77447273
-38.90697762 60.75243685 31.32451404
-12.30322139 45.52208683 28.25680926
-53.79364437 39.4076082 43.0575647
-37.93490624 50.35977203 29.42531312
-9.330136566 30.96286685 21.90464736
-39.49994452 53.68695714 43.53915257
8.053339814 59.57348623 24.40173613
-40.41802049 54.67040711 32.29762846
-43.19014199 46.12542724 38.79639793
-36.5843406 54.94177141 28.41632721
-35.63731469 54.93483209 28.31628039
-29.02297426 54.59640896 30.98354899
15.68325374 54.07227392 28.54234486
-28.51729675 54.58910312 30.97192488
-25.96736757 54.5417074 31.03584217
-21.90598175 54.47520684 31.03329854
-10.75323799 57.63584353 27.17527076
-71.34257277 61.17065565 44.30847144
-71.43365981 28.8203406 41.14025065
-19.31338138 54.65406457 28.46256262
-49.85772829 13.43504669 5.870233378
51.14350787 42.2659349 59.78357125
-66.38103597 63.15450335 43.63888811
33.43340652 31.72362357 22.39788669
3.007747441 67.63532122 54.13576018
-49.83212122 29.95421939 35.51109602
-56.57142421 88.24587084 18.5147075
-13.78841263 52.0815285 28.1395896
-21.40761177 54.47158721 30.98027574
6.057378708 56.92281018 20.59351401
55.73668778 52.49583112 56.68434043
-17.77494501 54.63373898 28.40512787
55.82258231 55.45340963 57.29407539
18.69227694 48.70345755 37.85387818
-18.42384723 56.23233023 27.45118608
-10.23540269 68.99326945 23.41732662
-5.011104402 50.35948286 28.97654427
-9.038926217 61.96667988 34.83924236
-7.985813157 61.42878769 35.05601155
-49.60797523 23.88223332 30.21006323
-49.45895549 73.15478032 35.02740797
32.0113162 48.04390443 54.62371598
-71.0724876 41.19355121 43.10013667
-11.69673748 54.52859642 28.46654885
-11.18441047 54.51793629 28.49259559
-16.82581367 49.26241738 26.48529395
33.02488773 48.02817464 54.6130247
-26.57968088 76.38779977 -1.04042106
-57.1144956 18.22955156 33.16900764
59.82607619 8.846300014 55.70232483
-49.36086541 69.99862211 30.86120268
-10.17430223 54.50395352 28.46228408
-7.625446674 54.45709938 28.52011909
-51.44803233 13.06124188 22.16619734
4.886223506 51.46593435 60.85770037
43.51696398 50.95218835 53.62788278
5.381369617 51.41438526 61.36169257
44.88586314 67.70015552 27.91678143
-28.40672922 48.00452002 31.64315365
11.73687504 32.78373432 37.53557215
-66.15864534 25.87167565 39.40468273
-46.96991278 18.66244897 26.20898464
5.996725039 51.37653125 61.68377766
55.82918723 64.98552337 51.51661794
-16.17072782 80.16181134 23.0827431
3.885494516 58.43187663 61.75577621
-42.09428771 42.57745635 38.98836125
-50.23270338 40.33781704 43.23321066
-40.47297283 59.11896012 33.10582641
8.050552679 51.33161086 61.81356901
-74.48937136 29.04854004 39.0738871
-12.35960333 70.90244903 19.13919652
-76.18458637 10.42782216 16.66777743
6.086652654 54.23017704 28.53941961
-9.523958632 70.33925892 25.13634486
-62.69845874 36.91607748 44.54606894
9.639832765 54.17225765 28.53417733
-49.6683201 17.93563714 29.33430884
-55.1619396 79.75385031 29.42787056
10.20651463 54.15895657 28.58051638
30.58753158 47.01435187 55.193756
-22.88067864 41.96548176 30.77517522
-53.65745103 33.54770404 41.13794501
-52.32497248 22.56347157 34.38407861
11.16718588 54.14513398 28.55777329
-12.98187768 33.08602747 27.08706362
-49.6129704 83.52937182 13.67384172
-53.55476871 34.51272786 41.56894391
13.70769492 54.10249016 28.56832354
-35.97393307 60.65379027 26.08368457
-2.469339423 50.81842152 28.99076042
-49.31098662 88.03899127 7.878423948
-17.09284567 24.73897829 25.71679311
-62.55000891 26.28799786 39.710163
47.09459686 52.8907981 53.74737358
-17.40704004 74.46153117 19.57183635
-49.12310558 72.15437684 34.92349129
38.12531313 51.74594358 51.26825346
35.56493167 70.07661159 48.71983309
-68.71370713 66.84320704 42.04906397
38.46477862 51.67381474 52.04083977
-67.64907578 81.36505636 30.58510948
-65.28998458 76.90064183 35.34827239
59.07186686 69.34400987 52.74149555
41.46763045 51.49705775 53.52010224
-36.87127055 57.27547861 30.51287488
-27.92335723 45.99960189 31.51896099
45.08633064 16.05754382 38.89294887
-61.59242909 72.52916472 38.77729644
65.46944059 44.26522791 62.97548259
-58.43405073 75.15670174 36.80581157
42.57811122 51.46960976 53.62696327
38.88975777 48.04303714 53.3220238
-39.65096929 46.75689371 42.44421519
44.02663342 51.44508484 53.63542454
46.11283637 51.42099981 53.51716967
-55.80281799 87.15105667 19.42424993
44.53421574 51.43681813 53.63459156
-58.48904871 57.86798051 45.23724546
-70.81145585 84.26964423 26.60282236
37.11434936 34.60216267 23.23957165
50.18625798 51.41037254 52.86371273
-60.42429651 54.83978823 45.79992958
50.70443492 51.34720576 53.49817948
51.09313978 51.29979428 53.9744383
-75.29213192 6.380198056 5.218944877
52.3414441 51.21521138 54.71827927
53.18257148 51.26169161 54.01829475
-66.82512162 62.64091265 43.85907272
53.53419488 51.30731909 53.42156083
-67.31048699 84.20089556 26.73325141
-11.95244828 60.88240252 24.67538618
-8.527224822 46.39336855 29.07551184
-37.8985203 72.73145578 43.56366614
61.841037 57.31293938 57.86610524
57.53159024 51.0748751 55.35761335
-49.67264259 20.37456415 30.15549312
-27.90800177 50.51180019 31.57490971
60.56527434 51.03123218 55.28571045
-55.8275999 19.73126109 32.97057141
-39.01728672 61.73817975 31.55560943
-55.80483963 90.05294332 9.043979278
-72.44919086 24.087455 38.01029916
-69.48732721 18.42511677 33.2583172
-38.15844953 73.59445036 45.2482474
-45.02892476 24.60193039 26.8086824
24.01935792 48.95336499 45.59014695
-54.84501311 26.36573994 37.3382869
61.95650587 51.02321809 55.11342762
-37.96801614 48.54771383 32.98739929
-49.63404166 36.43932294 41.76247959
-11.47886079 51.47972793 28.8586437
-39.99063816 68.4854299 29.16056064
-59.5490624 21.98870344 36.60744331
-49.52318503 34.1944343 38.66843922
-42.75897801 45.15127701 38.36953736
-77.83523107 8.068003652 15.24385608
-61.62316478 87.06417911 21.54276346
-61.85456823 17.38619887 32.20993947
-4.421580527 48.33818416 29.02226543
-46.43389304 22.30401656 24.61927418
-68.27413756 18.40780286 33.22794718
-19.14951767 59.86612167 26.19235878
-38.04817818 67.53515264 22.34171013
-14.52425119 48.93720335 23.9950526
8.503509867 50.84868327 61.50672232
-15.04119643 80.58980268 23.72553987
-5.364476527 59.4422774 40.13704639
-37.90523216 66.45861278 23.15842817
-37.35040682 43.3560144 29.04560299
-74.15975067 39.25535745 42.88230032
-47.61740714 71.63596065 34.82785818
10.28053515 70.20104723 57.92382181
-57.64782595 36.8337285 44.53885005
0.1422469974 59.60822474 54.64036831
-8.543393745 48.90021601 29.11048494
23.36931413 70.78411022 48.6590909
-38.62471055 42.50549506 33.33542943
-51.62383632 23.4867783 35.18523132
-78.32068152 9.57175422 15.35972303
-20.05839242 30.86249369 36.76907919
13.1067332 47.94156297 59.4169885
-50.39382096 61.00994835 42.1790261
-3.698779671 30.88491116 21.73568031
-37.69035158 68.06174808 27.81364885
-65.33062704 13.38082799 26.9303443
-70.3671091 79.74282406 32.45558889
-70.98142291 24.05322174 38.12779171
40.78431662 51.51963705 53.38829727
-64.41237774 50.34936674 46.24880249
0.9442871685 68.08154636 49.34916653
-37.95116536 51.37240584 29.32630324
30.99532038 48.06085713 54.62067309
13.69108248 31.77224546 37.25171215
-41.08408954 40.92623193 34.65776427
-66.36132746 46.9002038 45.87439324
-44.9123152 32.08300707 33.16690161
22.3850357 69.32131208 42.52146256
24.45750076 30.01794881 20.60379929
9.176518378 52.65154574 28.79614528
-59.73597751 9.005397591 18.39019006
-55.83422129 11.58671549 22.64012566
37.37642517 17.83581641 37.19971042
-69.65233686 78.62561024 33.6354293
-64.90855724 69.93164743 40.43037416
57.56496941 5.300164371 50.68653408
-18.80203191 48.13986708 28.24027218
23.81138057 48.20917128 48.44231281
-38.02045895 66.97605713 28.82681361
37.6203574 51.85199675 50.13340879
38.19107513 47.57115307 53.10660049
-54.88182035 9.294715345 14.10586083
-56.4510277 31.46136286 42.58390451
-0.9706202171 66.84707587 46.56506213
-74.92361829 54.81293458 43.0501327
54.32846513 65.51381823 51.49662334
8.796706859 57.9891877 25.17174254
-12.14888707 82.72545047 21.68836421
36.8768804 45.87833252 49.70215765
-71.39096994 54.66626083 44.07913146
-7.031208668 59.83704379 35.8721651
50.87484338 67.79054788 49.03187353
-54.65693275 49.36707168 44.13829636
-43.39268271 50.97054197 35.02916333
-19.29347761 30.30892442 37.22280057
-62.65943233 25.33876346 39.09715459
-35.94351564 38.49138567 26.98305168
13.69073275 46.36332235 60.14706257
0.7558262055 66.44696501 50.88059805
-69.11969566 22.61094549 37.03582159
-72.55352126 78.70152631 33.30741332
-69.51920167 42.65999404 43.26029981
-73.95158516 33.39631436 40.93895909
41.99746459 42.93989389 53.70396782
-49.61909232 57.6487442 40.264105
27.73029785 37.88914393 21.83133428
7.425129478 59.16516447 23.43499554
-60.76595907 73.58747076 37.98737639
-62.44421045 54.36538513 45.86571581
-44.22923282 42.28496894 36.96427416
-13.63930503 82.13261509 23.02802083
31.87933685 22.57659753 35.65335879
-0.5452437191 60.20946062 53.61853274
-24.97570948 55.03924766 30.89755742
53.52408487 10.39660241 44.73374986
-41.02590993 46.930135 40.6953131
-68.94682651 46.13455535 43.60238726
22.37052857 43.12760323 55.26787544
6.524986858 45.71916948 28.20491861
1.378082967 59.33170856 57.61468911
-64.10394942 66.76025278 42.13294921
-44.58144917 46.75958479 37.52660561
-65.59848243 75.82882801 36.19622016
-43.61641568 26.3938243 29.04426013
-54.18428925 54.41097871 43.76121534
13.60299685 34.8299756 36.73199284
-53.68027411 54.40451144 43.74017351
-65.73442187 87.15583677 21.26277571
37.08589708 29.84213345 20.23648135
-54.08259129 28.7400019 38.76392759
-53.17348835 54.39664654 43.73482787
21.2992456 49.50783156 39.67223509
-14.26322389 43.0333757 28.38816405
-52.69667806 54.39151357 43.70348456
-53.63973615 45.84639001 44.02846691
-56.69318003 35.35346405 44.06086071
-49.46992343 54.41153404 42.8557107
24.71721304 44.49115768 56.47113629
-71.25077095 83.70409137 27.42531202
58.00733347 40.44368032 62.14873728
42.58922362 44.44536879 53.59438713
-57.30679973 47.2206264 46.25458202
-3.981601509 29.52092998 20.14378226
-54.62375655 76.97128867 32.49403809
-49.44064057 54.69409682 39.56993076
-47.244679 54.87481253 37.0532675
-67.56873847 86.5878108 22.38001029
-46.65889349 54.86426371 37.06401304
56.14769873 44.00526546 61.94438738
-55.22239272 23.54280949 35.22104674
-46.22871781 54.85787729 37.05611419
-45.71642543 54.84723463 37.08196472
30.08692786 23.64274685 35.27182437
-73.0650651 43.21643861 43.3035434
-40.91404409 55.26178164 31.35375665
-12.95847299 32.5786641 32.97244539
-63.17377332 12.02506751 24.77775339
-7.528774084 49.38617352 29.10228235
-38.39640817 55.11917562 32.5291034
-39.93180507 42.57683736 38.5831484
5.354122933 55.85856448 27.25553001
-46.72109475 11.70227558 2.080765955
47.51660954 11.20367041 42.33701763
-24.8483104 48.44617404 31.66414053
-71.85642673 25.0158733 38.77278021
-46.94956778 47.79403344 37.6228669
-36.567726 54.4311743 28.51390172
-50.75070932 82.51751113 25.63723463
-61.41746964 52.32175919 46.08724805
-38.07161438 55.45089345 28.61633997
40.51743696 69.26620286 16.39668519
-37.97723539 55.47761839 28.28809862
-42.43052232 42.62317755 38.52171807
-37.51485984 55.45498904 28.46261889
-52.24489857 32.07184243 40.52151858
-73.7918865 31.30689173 41.8574829
-42.70718549 45.65214463 38.37186426
-33.82417647 55.36487418 28.80490259
-11.01105965 81.68364775 -7.221110921
-55.64100649 47.87366244 44.18262685
33.77719719 20.02493974 35.77972689
-32.49120917 55.28713953 29.45309836
10.13999579 31.35167067 36.98462386
31.85078141 42.66818309 47.13993561
-7.468918669 39.37933413 22.8981026
22.29406427 69.8198621 42.57791979
2.038190886 50.422955 50.2019374
-50.85546494 25.8332612 36.93221701
16.95676113 34.87264971 35.59701748
-39.08316633 69.04541397 45.7933628
8.420052459 68.64178845 58.8997184
-31.46588279 51.07430693 31.55007108
-30.60304808 55.17712099 30.37019032
-36.46223368 68.27802335 19.24211594
56.34952084 50.67338608 54.41717763
53.76400498 54.03124466 56.71626458
-70.39010348 51.14142782 44.02060807
41.69775041 44.49861862 53.14623342
-47.57647377 18.37375461 29.67603487
-29.04135513 55.10789706 30.87596816
-5.489347522 46.83743751 29.16773478
-52.24845001 70.99204639 37.35941208
-44.14624778 34.95764607 34.60974615
-27.51427908 55.08091315 30.8979945
-36.76726146 77.67851995 44.185206
-49.58108692 53.866282 43.37995301
28.22675149 68.11376285 37.94560162
-36.2227938 57.5182511 27.57092579
-27.00638507 55.0724892 30.89892734
-14.43532801 55.67559267 27.32692642
-48.68226149 62.63767115 34.61010784
-48.667095 30.23375747 32.04383583
-33.15203893 84.94244029 40.7438447
-52.33425225 9.738337744 14.29677267
-41.86868886 40.87601449 35.3903528
-58.04315985 88.96256761 -1.17784778
-17.32947643 55.6488481 28.18932856
-22.94427196 55.00562177 30.90050396
59.54582093 8.435900484 54.6933474
-47.90614169 39.39200601 36.28926223
-16.74354711 49.44391945 24.36259632
-39.83789345 79.29034574 37.71282462
-55.71928767 65.09999586 42.32749524
-62.46187593 42.82332775 45.84496983
-7.234229358 80.03103985 -6.236619121
11.48957153 45.4037982 60.05241207
-70.85621916 53.63985719 44.2391121
-20.08369347 55.08646717 29.41646181
-18.83685556 55.16368005 28.28233875
-13.24950194 55.07075463 28.29554298
44.90568921 11.92032678 40.34210617
-57.75472243 69.27725559 40.8361122
32.353689 73.04967347 49.7786097
44.19670799 30.17670989 20.8230405
-12.956505 30.55548939 21.49871149
38.28834746 16.80498597 37.33915178
-12.22488255 55.04945184 28.34744025
2.015347398 66.73505062 53.12273304
-11.74026832 55.04038225 28.36030787
-55.70054287 62.61662687 42.0194756
-67.75119194 36.65832602 42.67518137
5.309489539 51.90330482 61.52630634
-3.044027755 60.32350912 46.94446566
-16.5676315 24.87141343 35.73251127
6.381694443 51.85799796 61.84778562
-53.51841804 86.00351306 14.83020144
44.70937868 41.30413829 54.69591183
-29.57386393 55.63095373 30.73213807
3.620801078 54.78693231 28.37309159
-49.49175178 49.79683057 38.16430958
-54.09232488 79.7346279 29.44703646
43.33901244 47.01090831 41.14866348
-46.4661648 70.74457486 33.30288411
5.623128768 58.47506668 20.13652434
52.47570062 53.60901133 56.0369036
-74.6204406 56.31531457 43.03151416
3.940624257 54.82382327 27.88384214
4.933381448 54.81859308 27.75523325
-39.77627394 41.00985195 33.43763358
-20.73574327 44.3294684 26.22980389
-48.58548148 42.68031428 39.03222089
-65.32712038 70.9985527 39.77806334
9.136371756 54.17844541 28.55835839
8.604763409 54.70104785 28.41962956
47.76025621 53.90633434 53.48462426
-50.23306258 12.61994858 3.75078339
32.22858966 21.56943308 35.62537242
37.76624416 19.31926885 37.38429383
13.66118566 54.61577844 28.44520915
-14.70387651 53.55441687 28.69573373
58.26663991 40.43676484 62.17956601
-51.86926792 72.05298204 36.62426603
-47.81980486 46.28702606 43.62998702
-33.01848668 82.84142913 41.80188301
-46.49740529 46.61106787 39.61608303
-18.31728277 59.4148716 25.44544574
9.061471383 32.40459739 36.62044476
40.97819637 52.04794149 53.04502064
-9.552523111 49.41592102 29.14289273
6.799713581 59.66075371 23.6277454
-61.70287678 85.85217851 23.97447115
47.06943511 51.89905866 53.61172546
-12.1369267 74.28085616 20.66420164
-13.59863579 60.46725509 23.98201739
-2.469532878 47.80522296 29.01035996
29.63691329 71.11042466 49.50236703
-72.20319345 10.87338147 22.38940697
50.03354186 51.8905134 53.14565205
-51.11703709 83.70673387 23.55501148
-16.8411016 48.15992683 21.80680094
7.554292133 66.08426504 59.62121802
-14.57142298 60.08408485 22.78901247
51.15108114 51.82233803 53.7239602
-69.06053666 78.07264456 34.1151578
1.528768902 65.72829009 53.24945562
32.39815497 42.16240587 47.08033829
-62.36106949 43.81407569 45.97770202
-54.61488728 69.44023321 38.34536317
-44.76156979 48.80975338 37.06766599
51.912626 51.68596223 55.16187899
-70.06246764 37.19905155 42.66545983
-55.75927016 28.64422875 40.19548521
3.027054388 59.55683091 25.55362615
53.05658826 51.71595837 54.59550138
-14.96923357 50.27187305 26.0660165
-51.78566695 58.45763656 42.94035306
30.54521941 47.53791188 54.95059935
-53.27623771 9.717956602 14.71301419
13.80525164 50.03555037 29.14859707
60.01029434 51.46651283 56.16513408
60.72968554 51.45051248 56.21368557
-51.23256162 12.19589614 20.51738859
-20.56421063 53.71071124 27.99895537
-20.46059018 53.83188583 26.57251101
43.06503411 68.00106761 48.0773842
-66.53178779 33.09758723 42.99180202
62.46254437 51.41883475 56.25095718
-55.78259446 60.92836828 44.15373882
-68.42474937 47.1234089 43.67677207
63.79703826 51.29077432 57.48308038
51.77499536 67.59664192 51.11121133
10.12253367 68.19755197 57.90542523
-8.905301236 30.49412507 21.43848437
-61.85709872 70.40603225 40.16807841
-32.46341214 49.57529632 31.66199242
14.50967141 36.09560174 33.52005387
-51.06721847 16.13687744 27.17583864
-11.69255638 37.82946921 30.04225821
-69.45759971 8.246712271 17.39825268
-64.65371558 30.63024495 42.14314775
57.07259388 45.03869971 61.42442712
-54.2366405 41.8819862 43.55086559
-2.465790549 49.81219928 29.01776404
-11.97186949 59.93732303 23.99698044
60.94658484 8.627959644 58.02329184
-47.3897929 28.32390565 30.66463665
32.52261141 46.52711671 54.65426502
-68.30001968 84.80582662 25.72612068
-33.51385388 54.68134091 30.85403467
39.90150065 16.19519843 38.28373185
61.18676805 8.581134986 58.52106235
7.207202635 54.975209 60.46334067
49.14813927 53.34823762 53.87209982
-17.93957732 82.89616933 20.81085423
-9.18102985 70.26538305 25.92855141
-38.77521216 52.57617901 32.98909974
-49.71388282 83.84137258 21.7244368
-70.70969891 13.01513869 26.37471287
-39.20211364 41.54455017 32.94761138
38.64583863 71.01357958 48.90855919
34.29757125 68.92819864 15.68000948
-28.94513079 52.04606142 31.44179502
-74.84633423 55.42483249 41.75867727
-8.132816955 40.70054468 25.16705875
-49.67827499 87.37516055 9.828059047
-62.13687711 87.07553805 21.50886863
-74.8449308 55.7214588 38.31495445
28.13669974 48.11530231 54.53378979
44.14519527 45.89713895 42.27108544
-39.48392873 63.62408721 44.71145826
-66.7998668 55.00821023 45.06062349
-66.47829342 54.96988475 45.44420767
3.222624454 50.14759044 53.17269214
-66.24399628 54.95080712 45.62099288
-46.81387568 56.91414038 36.60366816
-67.83080132 48.60300172 43.86723209
-46.60154146 19.35717021 29.72718521
59.58857322 65.3505792 52.38848705
-30.04322991 54.61551051 30.95637322
-23.50293633 55.0148785 30.89958575
-46.30376034 28.77383958 31.06100328
-65.50434488 54.9245867 45.78432274
-24.17022634 58.1417823 29.68730573
-61.4128768 54.85530035 45.80838174
-3.036720815 39.35544047 28.15687275
-55.87692817 7.471442159 6.328313946
-2.597923378 51.49792984 38.6069779
-42.49466637 21.79692204 23.92812229
19.56965034 49.85997512 35.91413927
-57.88302554 54.79676007 45.81479629
-50.60679765 44.79869115 43.95923854
0.07504453164 46.75609484 29.05085687
5.85570058 56.39549643 61.71327004
-30.9748713 52.07883126 31.44846239
-37.48858924 61.72849685 25.54984206
-37.64506827 43.13298586 31.69087522
-44.12027322 54.61196091 39.5087984
7.837598956 52.90851471 61.02825238
-52.68705086 23.03772296 34.77431388
24.82052055 69.28209105 42.51230738
-57.37655148 54.7890524 45.80768483
21.34660398 27.45411159 33.480039
1.201683398 59.371136 57.19062701
-19.06279795 47.57096926 29.06754212
-52.32446411 38.89522435 42.89886802
48.75645023 67.35343278 48.68357387
-56.71653172 34.86302756 43.93201772
-49.50041577 54.90435379 42.96715535
-10.05028542 52.45730813 28.89102317
-8.575200124 79.96414465 -5.20431948
-72.29251534 31.26910545 42.01019463
-6.331763153 52.89253299 28.95611169
-13.66265087 77.196565 22.06733579
-57.81318415 82.86564979 28.76928467
3.921324119 54.44563022 61.4110736
-49.48309482 54.96994996 42.20236379
-55.76025441 80.88665181 28.04486779
-75.02423509 30.51527205 39.62891599
14.65473554 45.87038103 59.85900313
-63.66034301 75.79270978 36.24589211
-49.473106 55.0144669 41.68367365
-59.95451191 75.18034883 36.82125987
8.917998309 45.17437988 28.24623131
-49.47673339 55.16496383 39.93728695
-60.37914489 12.85866754 26.22104544
22.49237333 72.49200346 52.30642184
-72.65624325 38.23583047 42.77770385
-67.86330186 49.59905051 43.96385057
-45.6929691 55.35000725 37.06758086
59.48923347 64.88608528 51.97297523
56.25212225 46.20923262 59.64577717
-43.83002914 55.06687738 39.99908569
23.8736121 70.77750027 48.63965139
-36.41069991 47.84793345 29.16068867
-41.67771733 51.87132997 30.07174467
-16.29046781 49.61181341 22.3271554
-28.42094302 51.02498619 31.54193394
-70.0755079 83.10458174 28.33408686
-11.40535006 81.77863148 9.231367477
-2.474065813 51.82523776 28.95708592
-45.27921339 54.29791598 37.5488368
29.46538406 70.27924145 47.53077788
7.522086509 51.22373612 28.20678866
-43.18878869 54.9664102 41.04309385
-42.97518618 54.93299393 41.39027906
2.450550466 59.59026242 60.2353225
51.58891602 69.17237816 50.33432409
-41.40934498 54.68823246 43.93303389
-22.52055447 23.53059855 34.95300434
-39.8806831 54.63567074 44.25168375
-55.80007838 85.39524808 22.32651278
-39.31927684 54.6745866 43.69285647
-57.99617515 75.68581397 36.40662588
-55.87379219 89.97529651 -1.694773747
-11.9969558 80.81339101 20.54940788
-14.95137094 36.69156976 38.0466613
-40.40377222 55.74198531 31.50853106
-69.11482644 12.55085089 25.63371175
-38.4000701 55.62323887 32.50490856
-57.78228314 69.80576329 40.53270594
-37.79483599 55.64004616 32.19437535
38.7808243 43.22449451 51.0135483
-0.8553379143 37.31164546 28.16017036
-37.6899168 55.76056562 30.77528858
-51.22735622 40.8462195 43.34763102
26.07827044 47.05900049 55.53538545
-35.82790973 57.51727828 27.50691221
44.15387715 12.46728648 39.96261469
-67.69083772 75.86840546 36.13580096
-60.86907874 31.53433495 42.5793401
-37.74232178 55.88611225 29.32784539
-37.61362441 55.94508131 28.61874605
4.472168899 61.97355838 61.31595345
-7.866056429 58.60858989 26.98559999
-35.42692614 55.95702483 28.06308043
27.95413512 69.94469262 45.87602795
23.81445204 71.13611407 50.31452407
-46.85603013 70.69346031 33.97060886
-5.89931076 68.5470763 33.59678606
-34.9287366 55.92871836 28.29667482
35.7115956 30.29426765 21.07650614
-45.99863605 28.76858955 31.06376045
5.005200025 44.73804436 28.23108574
-40.3055978 66.87749287 30.40680948
-33.76606346 55.86255348 28.84303641
-49.52161749 34.3423107 36.95148287
-49.89618094 66.72929605 39.78281046
-32.82490506 55.80901826 29.28502782
0.1098711978 68.83123091 46.6320498
-21.69053385 74.37897565 9.693805654
-61.97625526 55.88375604 45.63003898
21.90461602 35.3558221 23.21777124
13.63158705 44.88650979 59.82233735
-19.35232482 30.82843646 37.0297891
-18.70785223 51.0808773 29.040764
-49.52993064 62.72503907 39.58417447
32.15064476 40.6748417 41.08970597
-2.102328257 29.92999978 20.86325373
-62.41964391 9.876909339 20.43811904
-65.53710271 26.81035475 40.04256066
-31.77295898 55.74915292 29.779376
-53.15580013 53.88550785 43.83848466
50.69011345 64.05183891 45.85563726
-29.89065949 55.64209122 30.66326071
-0.4759248368 68.95328537 45.32686707
-51.0009357 68.8494422 38.68785339
-20.84490047 74.20018218 11.60810372
-38.15630018 49.0457966 33.06783881
9.438804144 41.69348622 27.76917819
57.29676892 52.45219809 56.8933476
-65.7529322 76.37178802 35.74923939
36.98847959 48.51322137 54.05300827
-57.57017863 76.2209185 35.94014279
12.28685928 49.55593785 29.17921491
-61.42688301 43.80122418 45.94873682
35.37617016 46.57433638 53.56191374
-70.3742004 66.34855353 42.28138814
25.60122602 71.57945536 50.65378723
5.39283363 61.10897602 24.56394722
59.00405073 54.84539117 57.91903156
-67.38747215 82.4853034 29.18385648
-27.74872869 55.60228371 30.7168963
60.07853038 53.81928264 57.97265678
-19.64876832 89.20359676 29.48856023
-72.43012996 13.49320765 26.97967693
-6.826146031 78.46720501 -5.640229418
14.68268273 42.555255 28.41838006
5.344736394 58.03894958 19.42574004
45.73688009 43.19113437 55.90089717
-61.76681188 62.55469111 43.89534534
-61.88177207 27.70533852 40.6091243
46.85054849 13.82773824 41.13514924
27.84116119 38.43454738 21.30539155
-41.01095599 30.66491596 31.40520261
-55.09991273 24.48545762 35.90802344
-16.25714395 28.24269193 37.32352471
-24.48346035 55.54093166 30.80640941
63.41426498 48.61923177 53.6094472
49.72016731 44.9251155 58.31850599
-22.96660098 55.51910147 30.77055606
-21.44818314 55.49648511 30.74353181
-58.91404479 32.46934862 43.00546411
-49.54964667 50.33491008 43.58221348
47.68462726 54.44670069 53.05272095
-18.82514264 55.67237653 28.20142595
0.6674735384 45.75015165 28.96231798
-18.65404609 73.73924994 16.54113317
38.38639202 16.34003475 36.89130617
-49.54940842 62.52998269 36.02560444
-49.49841026 31.16198181 33.08014905
-16.55706788 55.70521505 27.3876768
-49.11409189 72.652046 34.97110651
-15.36508506 55.69859873 27.23716554
-74.34130002 36.15353828 38.13861647
-3.516149892 59.00546303 44.85542014
-12.24870078 55.56368276 28.20905565
-65.63175277 66.78760356 42.10680262
14.44367373 73.35668204 55.45681936
-11.23810768 55.5494554 28.18149097
-55.6636246 58.003726 43.12258705
14.47101222 72.82828644 55.75896665
-10.15423791 55.53543108 28.13759504
-72.88038269 24.58418111 38.15281175
-69.09724483 38.17497609 42.80542356
-12.1434507 83.54517837 -5.308599357
-7.667611154 55.48280091 28.27434986
4.947378794 52.41473932 61.48489903
-68.9906806 74.2741156 37.41144656
37.87385026 20.92030314 36.25772067
63.27716306 51.42913133 55.97607428
5.562589913 52.3769581 61.80616662
-30.46285598 52.06832837 31.47274331
-70.26445679 58.61128687 44.68068449
5.879697167 52.36566337 61.87680979
-17.71863552 43.04197667 28.94728555
22.11121498 49.87301346 41.10473078
24.36813746 27.31748652 34.48985811
-49.6061313 26.81453576 36.95589215
18.03995666 49.58428628 33.57961707
-39.26787421 40.07107441 32.58541151
-43.33977692 54.49080309 40.76644165
-20.69664207 51.49609457 30.42653419
7.904072603 52.38515559 61.26446923
-61.79410451 19.69333365 34.54870926
-55.85147856 50.21835688 46.13704571
3.021596729 55.3684108 27.56377758
3.375699127 55.36323206 27.55636665
25.23000859 45.48469326 56.49293888
5.007994248 55.35750492 27.31156298
46.96559983 41.14473342 56.11612512
8.122615274 55.27303411 27.69818494
-12.53911285 63.19386313 27.08734976
3.545939588 57.58355417 25.05534448
8.710313766 33.41272271 36.63764001
-16.99587391 81.16125726 23.2910876
17.70119938 74.43994597 53.91355296
-2.895205073 59.79577656 47.2157481
47.2973805 46.10437136 50.91752304
-2.017776145 45.3016479 28.85432916
-17.07027461 58.57428903 23.31244752
-67.42520753 77.48883736 34.75391005
-10.09147163 51.95109026 28.94878323
-67.37991743 36.64448497 42.7650541
-55.65425334 62.54372083 42.85699455
-43.05263081 53.53974565 40.09896817
-49.52007786 26.85784938 36.43666503
1.976709961 67.85808438 51.7463874
-37.43667048 47.36770181 29.10458595
-61.84918772 84.67377312 26.02888174
11.12585259 55.17041623 28.31671291
-33.28589847 84.40863707 41.13950909
28.60671914 44.56091599 54.91957863
29.88416977 40.70314083 41.1934195
-12.10291003 81.13366506 22.67828029
-55.68179791 55.45225753 43.6121566
65.84069313 45.3053857 62.48305347
44.65497073 69.95123836 48.44173203
-64.63374331 52.37078525 46.1314804
-0.7271039689 69.00565682 44.76680232
-20.67594696 74.16451083 11.98998232
-42.71830326 38.82495137 36.05605352
-49.48778163 30.55529285 34.29436959
-14.48169162 35.12144123 38.70436595
-23.89949165 88.89482766 33.88361644
-48.63871135 67.38342544 31.94939076
-53.38879396 60.54423671 42.32986516
-40.16951641 59.21366285 31.94857683
-12.9038821 35.64541488 38.1474405
-66.24346197 18.37456131 33.22657727
4.172489477 66.24156854 58.44005402
-22.35333422 46.90746186 31.57090488
-59.29584903 46.25321574 46.21103277
-39.58968111 47.75518736 42.49688779
45.03849161 52.43465137 53.60812837
-55.6618462 61.51834108 43.10851733
5.095099421 50.55435959 59.7468096
-49.64590351 32.47047511 35.39825476
-49.51661685 65.83073062 38.48834659
-13.45008834 63.38083998 25.09051419
53.61023794 40.10297698 61.11578174
-72.62849791 63.28308012 43.33770356
55.36265718 69.48063694 51.86279723
-46.83244205 22.75949845 25.23435474
-47.09853254 73.63086175 34.87720139
48.58185545 52.36929479 53.69109452
30.78350892 42.17161903 47.28130796
-17.24554458 29.75237257 37.46646671
60.05651492 54.81004233 58.12867552
54.33401035 69.52874919 51.50044418
50.61798358 52.34569169 53.57679467
-11.17452473 68.81611782 19.82628679
16.16500892 30.75875658 36.89195628
-66.4213116 82.46871178 29.19221146
51.97771024 52.15534711 55.5271458
52.66856901 52.14135076 55.55787472
53.22556047 52.24443825 54.25493802
33.46949813 36.71518881 22.71168057
-15.87154361 75.75111385 21.7885141
-62.74961407 76.85806789 35.35803784
-33.14782851 81.82790882 41.93895098
44.72629479 42.25048145 55.36012562
10.74488394 51.09708564 29.06243318
53.56469558 52.19725392 54.73801315
-39.07451405 46.52712183 33.34835818
-73.55869493 45.21414229 43.51344291
3.287462123 64.73536304 58.61404415
-66.50227457 39.46284076 44.83999718
-77.20651521 6.249935608 12.92285295
-46.71396977 17.90723557 29.10060268
-12.30054479 49.58802055 27.66909984
-41.9222827 54.27131819 43.04405828
-55.98963964 36.32511369 44.30035496
-61.37045103 50.30156591 46.22359574
58.77576545 51.94725144 56.64644248
-41.84002617 19.14717856 19.60358862
62.8809618 51.85865106 56.89209482
-63.05953908 16.49404626 31.14321139
-71.14456021 65.83849279 42.52281681
-57.66147334 21.02320854 35.80235631
33.63557273 21.00170154 36.12105357
27.35896323 73.5193142 51.10584267
18.79717816 35.92649652 23.01221392
-54.15425541 52.88918035 43.94171114
-54.19348473 86.09626573 13.8821999
40.16226311 44.60417907 52.21363718
46.96019756 64.69446812 39.1068446
-59.19559267 61.9943398 44.08332447
-57.68061945 68.75004792 41.1155508
47.59155605 53.39010615 53.68290908
-50.88836268 67.78822372 39.33250198
6.523605565 57.5090113 60.31268483
-62.31836154 38.37965613 44.9632158
-72.08994915 19.85707382 34.61137323
-69.70457886 18.88825628 33.74992798
-34.16091667 46.27185055 29.54803946
-60.36918133 51.79868973 46.13286426
-53.24025602 41.37271023 43.44625534
-64.51365184 70.98465161 39.78430398
-52.46876913 87.17223924 12.71588204
-48.2618177 54.89234175 37.04374994
-16.00476001 55.72394265 27.06494462
53.45511467 66.61191396 50.56896145
-64.77098399 15.6096013 30.08359477
-49.78139308 30.13351182 39.24670597
-49.94869796 62.03762072 41.81744931
-37.4653854 50.37070508 29.20885377
-72.65043545 66.93354776 41.75108069
-49.4831752 54.81466444 38.17840525
-50.54903417 63.59932436 41.28246405
-14.02632546 31.75935156 25.20734532
-61.87382645 49.30327844 46.25516019
-38.37377947 65.39985981 29.7119202
-14.2177574 54.0591931 28.56985791
-17.10043503 57.58507113 23.14848086
-10.06035899 46.91323552 29.15953546
35.16020169 71.53964346 49.29299865
-20.93351066 51.21247615 27.93751474
-19.50656949 20.10526976 33.35544919
16.84651756 71.41178487 54.26980471
-55.20372282 33.08215504 41.01061996
-36.48143212 51.87656402 29.02002111
-46.57806215 21.57719107 27.25764834
-67.53135254 39.63505976 43.03700435
26.04055894 47.67935116 54.16773467
-25.31460752 76.1213129 1.811896312
21.06545542 72.46846351 52.85181267
38.04670805 32.12870875 22.64221899
-41.20846156 57.57541695 45.33802513
-55.77035322 10.04119082 17.26295955
0.1246087168 50.86790907 45.40151665
-37.07548994 65.48162624 22.68848227
-66.60582726 16.54848907 31.18749794
9.468930618 48.34616257 61.24049295
-20.98908263 53.95647082 31.05366575
-61.12295922 15.10073379 29.46855905
-39.21663363 44.46605938 33.99521488
38.42991303 20.98669232 35.38098193
26.70421885 75.10846355 50.26262353
-47.9843733 24.60610809 27.3238064
50.96778535 68.81004084 48.83241236
-74.91120871 55.78632887 43.40117656
-74.82816291 55.96743787 41.28289349
-7.818292634 77.36858372 -4.350705071
-37.95237276 67.28860164 25.18558388
-37.90187258 67.16401587 26.62223593
-12.48363159 56.6866235 26.87122419
-28.94838225 51.03088061 31.57409303
52.62001163 53.66010423 55.41625881
-74.70141766 56.45527412 41.42220405
-56.84100414 53.76224694 45.97217812
-66.35255518 37.97977953 44.54797924
-11.12977193 45.94031302 29.0045915
40.4166901 51.5316379 53.31909119
-10.0793593 51.44276427 29.02085145
-57.39029156 55.29819948 45.72639511
13.84632486 57.19452284 27.6071593
8.164212196 59.08967521 24.17038259
48.06400996 10.17088577 42.56866117
55.58380016 51.56852183 55.82509808
36.51256386 43.95413314 48.80258193
-71.67827534 9.597500345 19.62073809
-68.75713976 57.58983817 44.59767027
-13.15142295 77.68867533 22.08370666
-56.37770249 16.84679203 31.60065563
-49.46677361 55.4639329 42.29137771
48.12954454 64.55060312 40.55393328
-49.74832946 55.7546283 38.97046721
-48.80539985 55.9232003 36.8337377
-5.074796173 29.53965815 20.13485072
-67.15097991 9.531365565 19.52509647
-47.71374244 53.35700067 37.28266587
-44.23831548 55.62638259 39.40844435
-47.86508148 11.69706964 2.3593655
-45.7322299 42.80202246 37.07521105
-4.627814267 60.67643435 43.14948898
-49.47080501 64.25141747 39.33350708
-8.320182756 78.91779392 -4.759431541
-44.45581418 55.66176426 39.03918638
10.21520485 45.92941047 60.02039147
4.494595499 44.24561957 28.21824651
-19.69465964 54.12118018 28.89474473
8.343508833 49.34841269 61.47354861
-18.17681456 22.9433403 35.11530854
-13.79211624 31.24476119 31.13642171
50.19164152 12.13476655 42.67131123
43.10228723 68.47210271 48.42880153
4.318330677 50.74797064 57.64736537
-41.48820935 55.18067519 44.05808019
-54.31599161 66.27616188 40.05936613
-44.34277699 48.99494285 34.83798474
-54.59941502 62.63106366 41.64189112
59.7276539 5.553881044 53.15541015
20.29849845 43.48569888 57.33262965
11.27094658 47.8517167 60.8100853
4.870426063 58.40581615 61.87047286
50.59516429 53.31207902 54.01589897
-69.06380295 30.22176166 41.89950905
53.93496751 44.63255933 60.91092419
42.54272909 41.50508209 52.77640232
52.48842128 55.208354 54.94806326
-74.40366594 56.73295845 43.96849996
-69.00551482 43.15086398 43.29060105
-55.64347976 50.88820621 44.14791973
-39.8613142 55.1281163 44.35796347
24.56997631 69.63813542 44.25350591
-39.79135135 55.16715775 43.89139947
-10.87352636 81.16346792 16.27121416
-40.75883794 56.29942171 30.93175642
32.20551791 22.56072561 35.77540699
-13.70218214 31.99894503 28.19079468
-55.81836305 90.00355044 3.793305182
18.24585728 73.39615584 54.27347927
-49.63395731 66.75807579 39.39870652
-58.90352721 16.88202848 31.67329517
-4.510219341 45.8346503 28.96881316
-40.40548743 56.26984441 31.20772534
-50.71381706 40.83494791 43.38054484
-40.11952965 56.22470704 31.67717904
6.500227026 37.65330618 34.44445582
-7.679292634 60.87106161 35.64540682
8.817943336 66.65521263 58.57889658
-39.42003954 56.14441205 32.47590502
-37.1138527 56.27778492 30.48781144
-36.66182142 56.48750397 27.96703419
65.24826753 45.33167297 62.29087067
-34.62608602 52.81230861 29.45667121
-19.55719905 56.7115867 27.9304051
14.65971201 42.06462635 28.2916953
5.449634612 57.55815217 19.1605416
-63.48181989 42.84227206 45.81955988
-29.9964939 53.5875029 31.22804116
-47.8205417 25.52789679 28.24504869
-2.257583398 66.64716668 43.30454406
-69.36673086 51.12075396 44.06544206
-62.04804713 57.92883489 45.20952576
-49.33931649 28.34098866 30.83811044
-71.85760045 72.72698774 38.43845211
-60.48394173 85.23063894 25.13065714
6.041724779 58.46213259 20.20684396
-25.02175284 56.06690586 30.62981353
-23.3850541 88.92026967 33.49016063
-58.88200027 32.95540008 43.18355389
-20.87014396 56.02922667 30.27548427
-41.30568673 23.19935731 24.90080749
8.651360892 53.67311013 28.69051269
-17.41482714 58.52509472 23.94923941
-12.80291342 57.90167006 24.4802474
-55.65825794 63.64928439 41.67685126
15.67430705 29.79124283 36.5638599
-20.53612133 56.05895484 29.86667777
45.04918143 46.61900844 45.3719843
-35.87000646 39.45873669 27.39259827
-36.60853869 70.77045323 25.29591853
7.367058273 57.160816 23.40744629
-38.90186407 71.19062353 26.68226699
39.07232866 32.596728 22.84015879
7.493159647 34.43646851 36.63865198
34.0391823 33.16008321 23.08681252
44.79721669 41.76204523 55.19008358
-19.42055709 56.16522074 28.42032062
-18.17699267 56.25647383 27.12383281
-49.59979273 24.38032176 30.2529696
-54.00581508 35.98673468 42.023537
-17.57484088 56.27483598 26.79583758
-58.94160921 78.42603111 33.90968951
-54.61838577 86.81281919 11.47159599
-78.97815136 12.22865508 13.77506645
-49.16025811 21.44264064 29.31204855
-49.40629163 74.73397402 34.16485432
-13.82114947 56.19010406 27.06361603
-72.56024337 62.24421769 43.73127856
-40.53701664 54.17866189 32.20221818
5.292892019 60.95156574 61.37019136
-10.24360969 56.04556311 28.05929482
-41.61652826 30.14606322 31.71726929
-41.37370398 61.51451518 34.60145448
62.39373586 57.30669189 57.8332276
-9.739629192 56.0391133 28.03805679
-21.80652221 48.39844309 31.63814897
-7.197942633 55.99587549 28.05527792
43.05563691 43.88131983 54.22669048
43.78676412 44.96294282 53.18427265
40.480052 68.5531857 13.02775589
-61.68249756 36.89914225 44.54891562
-64.05320274 12.47534657 25.54493895
-49.61731689 58.15006462 40.27071393
-49.85730128 26.78018011 37.40261733
-1.589712951 68.11424787 43.62615071
-33.60656221 80.80710224 42.22342025
18.22743873 49.53926837 34.06646393
-49.51570359 24.88967549 30.15062427
13.72718932 47.42369778 59.48377992
-55.78458851 80.82459336 28.76992838
-38.51547188 59.19696441 31.82698822
4.345198774 52.94816314 61.23400614
49.35317707 68.32940784 48.89322557
15.82430891 51.01260163 29.07450838
39.82661715 43.1181774 52.04831603
-74.61145604 56.713887 44.22952221
-55.87776858 89.35119707 -0.2756542755
-55.74945877 65.6545982 41.72166115
5.526989235 52.8723547 61.88867143
-20.88732557 44.66037018 28.24400335
48.28714568 45.87764977 53.36071894
-49.80655316 84.38367638 15.44665035
-62.06215425 9.454528397 19.4466088
5.891169787 52.86209214 61.93835538
6.402635958 36.49988022 36.19959588
23.40702021 34.32446271 23.25074445
6.399617924 52.85338867 61.94242745
-3.089801946 68.44973051 40.01769877
-72.74519091 18.96017179 33.49494316
54.94782788 68.46246105 52.10835476
-49.58546088 78.95931284 25.93469113
8.17778555 52.94752246 60.51054627
-71.36740216 10.43272634 21.51882193
-48.94992219 38.92075842 36.13226245
37.18890188 46.84925746 50.02472982
2.815830391 55.90009021 27.25753671
-74.83634541 55.46934943 41.23998713
3.801870944 55.88585923 27.23469638
-8.404754213 58.62016359 26.95397698
2.877190226 57.0126349 60.94373684
46.92932107 70.49551618 47.51626631
8.901286966 55.778902 27.50384483
-43.95393973 48.49480427 34.74316394
-70.76967844 16.15207239 30.75682249
-56.55556038 15.93207877 30.59995139
-21.86418069 44.9120162 31.33564621
9.568585862 55.71484558 28.12020074
-59.87934071 70.37470664 40.15455858
-50.34217882 78.54814094 30.85218608
12.61903225 55.66274707 28.14325976
-64.21839689 18.34414994 33.19342257
49.55563525 51.45592801 52.45513511
16.61954953 71.40238754 54.42217992
52.52704096 49.35384425 47.15778729
3.160363874 51.03476214 54.53891101
-6.837665851 81.10798547 -7.160935204
9.2541496 47.37574128 60.89352975
15.67071677 55.61002401 28.17333303
-21.44380374 58.07968965 29.88817742
48.63435768 53.3596315 53.83781258
-69.02874628 41.6559348 43.16933765
-9.197319301 55.51111256 28.23741214
42.71717851 43.90338494 54.03508856
-59.7995286 23.87613438 38.05109671
10.15632813 55.70330969 28.14203171
-11.62173057 81.43512233 7.433696222
-44.75130081 41.79832679 36.88649195
-64.27955455 62.08111241 44.04554676
-55.12741913 58.50801448 42.9928216
53.4393391 51.01400825 51.01797545
19.77253867 29.41455148 34.32856935
-62.91757597 12.90026315 26.22226733
-65.60805589 14.27742794 28.22814706
-49.48254387 38.47087678 41.45639193
-55.73381377 66.67591613 41.51575541
-67.44889169 12.96842593 26.29513313
58.37743736 56.92256611 57.23173133
44.23540135 43.8065334 54.86987793
-65.3146115 17.89935226 32.73937246
-11.98983523 78.70182358 21.75410226
45.53216068 52.9356191 53.52502465
-39.9225598 78.78128317 37.8118999
-51.20672867 15.70503606 26.388933
48.11142386 52.87342611 53.75512532
-43.72181395 38.83560607 36.12374158
-20.95866325 50.72294812 27.79846923
53.88976465 51.2270666 54.28538123
-72.58087825 42.21554148 43.17706861
-79.33814461 6.768643773 18.96110728
-12.76412718 48.21611095 26.20371272
-32.16153736 45.17563052 30.23918634
11.76103016 47.35006104 60.71356753
-70.51178205 42.67752397 43.2460906
4.385530304 55.34938906 27.52448552
11.72672461 52.60401026 28.86163219
55.36719109 52.50877536 56.60453942
-46.57791292 17.96249221 28.43319633
8.936927417 35.45594075 36.1818207
56.25999341 52.47963293 56.77258331
-12.72069885 54.54956727 28.41837943
-28.91399984 48.01262949 31.64575245
59.88884625 52.38354113 57.19604314
61.45027807 52.33922677 57.4127022
61.94030367 52.32742745 57.45622643
-64.96129987 7.904608669 14.68552828
-12.14581417 32.40318646 23.20125563
62.48282839 52.3139233 57.50952981
-11.12742711 36.68862999 31.52489734
-47.08478792 18.37350673 29.58514501
-10.14313156 70.98036608 23.63862568
-61.77561139 13.32198268 26.93550074
31.41515558 44.60611933 53.85923931
-40.0946531 68.58265396 45.53167445
-68.37923069 49.61152755 43.91739879
46.66721053 67.41621646 48.35316562
65.51794939 45.3195237 62.38047836
-71.56072818 70.05376419 40.28136259
-55.80425302 89.69243957 1.57557505
49.35298924 67.33745947 48.75524023
-58.22939875 63.53393514 43.5062418
-48.96178732 32.14349788 33.23693884
15.68543523 55.10038339 28.26016546
-15.92125991 61.45397661 24.62365406
57.63304125 56.95117995 57.04152175
28.65394622 38.9232071 21.30431074
43.98578301 50.46125941 53.41089676
43.93282026 49.98575996 53.11430078
64.96359723 46.65314933 58.65777818
39.49582176 43.63859301 51.89667743
-31.53125844 53.6183652 31.1624579
-2.489009329 29.0462919 19.5424402
-18.98570736 56.7701929 27.1410745
64.73527576 46.69399044 58.22720697
-49.62418351 33.74316604 38.09971059
31.03410108 25.16715915 34.87462483
45.50179328 66.6328017 28.53666036
-40.69620435 44.80450964 42.00168334
-58.01750734 71.93328408 39.18631387
-8.044546317 70.54120032 28.33657031
33.02746488 20.10580109 34.98400762
-49.29389948 55.92184092 36.94267818
-64.69766315 12.05044416 24.77377767
-55.84687857 89.26794026 6.511602542
-22.61624058 58.10122404 29.86178129
-54.65001161 51.88704943 44.01644011
-0.7501075757 66.28317997 47.24248341
-74.68751889 56.25004018 43.80205926
-55.46069801 36.98173347 42.40360156
34.10797056 43.63088126 53.01369678
-74.69415383 56.30294282 43.189192
-69.66559342 35.20059964 42.48269819
-25.35650695 48.95696662 31.65804448
5.322683424 59.42988966 61.54931375
-55.87662916 51.73769194 45.98421444
-58.07852123 72.46627801 38.83720796
2.05675521 60.19201344 59.15149733
-62.48678137 55.89350771 45.61419476
-68.27247721 56.08648505 44.47733972
-15.78778713 28.75592375 37.10268695
-67.75675617 56.07411284 44.52261428
15.7740355 35.77325801 31.19429201
-66.74367253 56.00907345 45.08443799
-53.50186632 18.90402708 30.47689393
5.152618206 43.73358212 28.21022116
-65.48027544 55.941655 45.62614322
-20.23246084 19.21922842 32.1264136
-17.92385743 60.9708384 24.78754147
-33.68374765 83.35711785 41.76890207
-8.763132661 75.76866091 -3.077369144
-13.79704535 51.58366718 28.09413261
-49.75615321 87.21622267 11.68797806
-55.79106293 84.83892858 22.95631541
-60.11833989 72.50413169 38.78678045
-48.89229164 57.97589617 36.32768026
-43.96150512 37.37553445 35.63910628
-58.93415538 55.83586785 45.60581324
-58.42210543 55.82534749 45.63029036
-49.92711439 11.41162806 6.066222274
30.37532283 31.76718571 22.4753805
-67.7600598 52.585045 44.24040384
-61.08593081 18.75289338 33.67769135
-55.63605201 49.8800273 44.19689801
-65.84502477 28.72676878 41.16071438
-69.09560729 71.06597021 39.71410471
-1.565377867 69.18073788 42.89419725
-55.69365915 55.96045685 43.54151086
43.47729773 67.53866752 47.53999977
-54.73769797 55.94901021 43.49208425
-55.73517289 86.78816267 17.79744683
50.13727046 44.31160702 59.53437534
-10.58459341 58.72864934 26.11030341
-50.70263097 55.88339701 43.48425864
-49.78126874 55.87701457 43.38264135
-62.42365685 53.35058501 45.98905479
52.6318837 49.80317332 47.74829438
-16.6079332 25.14525179 26.73463924
-14.77301253 52.59724878 28.16714224
-49.6084518 25.8732189 36.23054585
-49.54966035 56.25573893 38.94196282
27.61926528 48.779422 52.74952226
-39.58551192 67.54439099 45.83421072
-3.777549247 59.99308846 45.09347555
-37.41567546 64.40101912 23.64455216
-47.29802073 56.40836835 36.74072752
37.3010355 51.16396978 52.35478413
59.08195848 45.5060354 61.44269638
-46.28631938 56.39358195 36.71944132
24.28896719 70.81575674 48.11633157
-45.64906722 56.39423804 36.59029717
-74.57942175 43.73922236 43.35012593
27.16319277 45.50063037 55.93925937
-57.10198463 79.49359555 32.81907649
-50.49328412 68.84114055 38.68741281
-60.35514069 48.77831091 46.23310296
10.68846249 71.24748261 57.35152371
-19.20831132 48.57447064 29.09919783
-27.72179372 52.02362873 31.46891228
-39.69740133 55.63437047 44.2763773
-39.76636775 56.67266305 32.23626967
-55.94673368 38.77738351 44.95763929
-72.26269158 25.50002523 39.05650944
-52.1059099 51.3403773 44.0507928
20.61765088 37.35727085 23.53548908
-70.22239882 74.30077607 37.33684833
7.011212326 56.71421394 22.83314998
-61.52085115 66.19390919 42.38822573
-37.37483684 56.71755882 31.2590074
-66.58835557 19.30519324 34.14217787
-26.4551837 54.03778721 31.15210408
18.45262639 32.42466323 34.59655594
18.49645974 32.30344048 35.99543904
-47.38960497 47.9235537 42.02986059
2.705102021 64.88046214 57.04068724
-26.57368611 56.60864479 30.46351651
3.093746139 64.78352558 58.09188087
-50.16807654 42.31408072 43.58553846
55.02265071 63.65033565 49.69033984
-26.07832918 56.60654507 30.3934241
-43.37414647 53.57804201 39.71571098
-16.87874455 60.43698184 24.95898791
-11.61740581 37.01678777 33.63548549
-25.58473853 56.60533632 30.31332537
-25.13293101 56.60042684 30.28415578
-67.97294259 23.53246554 37.77273353
36.3853065 43.84488197 50.0951182
-51.42120493 25.368649 36.60702366
9.365330746 49.85551495 61.21850884
-64.98064222 80.75566617 31.32382857
-53.10559836 45.83711656 44.03425661
-60.27320974 34.92271144 43.91744357
39.10129086 33.08433431 23.00078655
-19.32797276 27.30064011 37.19189349
-31.39460171 85.45013595 40.34162997
63.58789393 48.58120195 54.01781311
-18.19501266 56.81733558 26.44301758
-40.09667398 65.80483789 31.16584662
-73.64984796 39.24592023 42.8946129
-17.99430635 56.86475824 25.85422469
-2.75286836 39.33614296 28.32675989
-51.58114253 49.81679367 44.15766455
-14.05488663 31.22198724 25.6242684
6.511921942 62.53706525 60.21200212
-70.41866993 86.03654535 23.49636021
-72.74124284 17.1227611 31.51763058
-56.61341083 68.20504536 41.41217434
-49.76032409 11.43992152 17.35924691
5.136360783 58.47283379 20.25527493
-59.26478111 11.53322449 23.91531686
-41.81122503 51.23823785 31.61996012
-37.0883955 74.12677807 44.69117392
-11.96538237 56.67297597 26.93082152
4.269220478 53.43915002 61.37540264
-7.167370998 55.47823789 28.23192198
1.119528914 59.86523839 57.29703408
-64.06281477 66.23728675 42.36943498
-71.17144359 14.35826397 28.35071484
6.400111938 53.3553555 61.94177689
-69.58555298 39.17795218 42.90855027
3.934312274 60.09694609 24.9371963
-66.87888549 82.47653005 29.18871336
7.438311931 53.40153566 61.20769275
-11.43239635 36.39646756 34.9746921
-13.51405491 32.85226338 24.07561583
2.677315505 56.49695665 26.18173175
-8.125484994 29.59187895 20.11041828
-5.36795477 39.13719132 25.3084071
-46.66736645 51.81807489 37.46816658
-11.4955464 79.77902826 20.80814197
-3.492901525 51.84362302 28.93795444
11.13066718 71.77441107 56.97686331
-65.017875 42.37156935 45.75011452
3.425279592 56.76387723 22.94048116
-0.493844205 51.48387518 44.19551593
6.899378852 55.45925893 60.72948054
-66.49051717 70.48853303 40.09396972
-46.15839882 51.80910937 37.47518169
-74.81312144 55.05452971 40.22444725
-45.05015166 51.85712167 36.70647042
-69.59256631 26.87386791 40.07865489
-49.87312898 12.89104369 6.361713348
-60.2181751 20.59658246 35.41588064
3.885978114 56.92792774 20.94820475
-35.96178324 48.84891429 29.10824329
29.58289533 47.02557435 55.25506749
-50.24878747 66.20672633 40.08978449
5.219970631 57.09745447 18.72581399
-58.01572069 49.74242525 46.24810047
55.47650245 53.96028232 57.21346373
5.44976399 57.05587088 19.1647238
3.634805725 66.36413106 57.11979814
-18.79224714 49.1393633 28.28880645
-49.71720219 87.74834592 11.32991568
-2.233695405 51.41330979 39.51984888
-63.00009801 83.54373839 27.71335365
7.184321777 56.67644617 23.23857286
7.444245698 56.61966349 23.8481787
-13.2637232 58.53722594 23.0167707
34.47558282 29.96954558 19.25519076
-62.38468973 49.81541622 46.23395656
54.14041669 54.0148122 56.83524065
49.32026752 45.02772987 57.2035466
-59.8409252 49.77113021 46.26294984
8.053159147 56.48594765 25.2843265
-38.30563141 51.37776801 29.33165405
-40.65024406 59.55047189 45.6102542
-61.80379059 63.0755607 43.68240385
-52.00431765 73.1255387 35.85228212
-61.78218999 27.22722279 40.31380879
-52.38271415 25.86033246 36.90920966
-49.51589018 35.79651398 37.5488605
-56.9443537 57.32989286 45.36253619
8.402668167 56.40875244 26.11381148
8.920194235 56.32113613 27.03223035
-1.223396093 67.47680237 45.12958385
12.10109384 56.2032317 27.79433455
12.58879616 56.19260435 27.82469688
-49.78921686 57.41244986 43.03962633
-49.50752121 22.67846103 32.51189187
7.801561595 48.87179845 61.28314627
60.39538712 48.59919117 48.59116952
8.646065953 58.51783744 24.89015948
-73.30498182 36.80865408 41.98920447
-11.86127316 82.30595196 3.196792741
-41.16449756 52.95230299 40.73171216
14.19245845 56.15176506 27.99296108
-71.26942106 59.62710687 44.73323916
56.58577953 63.60516525 49.91661282
-0.3083566393 68.91831284 45.70089757
-20.50716662 74.12892684 12.37087991
-20.55935168 57.09988309 29.44053491
-49.74546364 19.33253245 30.61276331
49.13538791 66.40562764 47.96084017
-55.84636814 89.11901565 8.240331035
-58.26740889 48.74106574 46.26732914
55.54236505 54.95626844 57.29205139
-54.90467591 30.66800198 39.84563584
-68.78924638 55.59716996 44.42957647
-71.98518651 63.25590183 43.53052573
-9.535155339 33.32958828 23.60232084
-46.72485732 13.24798843 1.617643261
-7.004429478 49.36945123 29.1964113
-48.12004791 44.2809497 43.66868015
-31.03114766 54.11608346 31.11584782
-55.67766593 9.26255991 14.6309172
44.54170294 53.51623894 52.80029277
-16.86098075 48.52350358 23.41656886
-24.3798142 45.44432781 31.46257185
44.98064574 53.4930411 52.98588126
14.16751732 45.86837526 59.97520309
-59.58350294 30.55040802 42.10303312
49.65790345 53.33887029 53.88362758
7.91215708 43.67956212 28.31106408
-52.85904182 84.616909 19.14788521
10.59518502 31.33266638 37.11843215
30.97587265 43.29628566 51.6685954
-35.92720716 36.09596816 25.65449159
-62.07700608 31.07155616 42.35533206
54.618335 53.01707718 56.67325325
11.75129093 56.19385267 27.96992272
9.28826282 70.20603284 58.05517753
-56.83113991 51.23380197 46.1891271
56.83151708 52.94132761 57.13054245
53.64929755 71.33123866 48.18633194
59.22169051 52.8754078 57.43996738
-49.55773999 76.99267983 31.27959395
-75.06555804 34.34323347 41.81215414
60.78696658 52.82915424 57.67840491
-60.54556799 83.49491019 27.81209341
61.51681689 52.80787789 57.78620951
-49.51322895 35.89428296 36.41337904
-49.50973761 66.25514291 39.38678809
-72.83034127 30.79041225 41.84314036
29.03016095 47.55883362 54.99665523
-65.01315031 56.44362364 45.53648221
-65.71210049 12.06661071 24.77956389
-12.78539092 77.70225665 21.85624017
63.41315311 52.81762476 57.31141797
-50.68549945 13.92879973 23.60281501
49.45694688 45.60966637 56.24857819
35.16466475 23.06928728 35.13396569
36.49780102 32.15431617 22.64033523
-33.06048173 53.64643226 31.12826702
-67.20762002 16.10206335 30.65805795
8.775460218 47.87164924 61.05459809
8.562343381 51.20693408 28.20345583
-56.44229388 22.41040978 36.94612995
5.285967061 52.39260869 61.67723671
-55.83616065 22.14754819 34.05538451
-49.25576156 54.39595453 37.16908533
41.79000598 64.92384993 37.42999943
39.52722675 42.21521439 50.93438324
-49.47437373 68.3700573 38.13513766
-34.03397942 54.24327462 30.21197797
-16.78161004 41.15510715 27.36618382
-22.55758811 45.4171253 31.43084908
53.93812085 43.5769809 61.51096601
-78.23374291 5.999997732 16.02021321
25.65320901 46.52342427 56.00716601
-39.74978524 65.82807587 30.82992929
-55.20228031 64.72103694 40.80148938
52.00450139 38.96032664 57.20680114
-64.27050652 35.96131492 44.27616674
21.17257035 68.98471172 40.83354842
-20.91193671 45.77632851 26.94711966
36.79304891 17.88231669 36.77115458
48.07112757 51.88932115 53.53373678
-57.09844463 85.75952174 24.17200573
-49.53824221 53.36723121 43.33848719
-41.16222545 41.95196001 40.24518871
-57.70096351 24.31086262 38.43088599
-57.06165349 15.93959426 30.60922107
-38.84795655 53.09031242 32.86117754
39.56537512 31.62852851 22.33241639
-72.26914628 66.91465491 41.89768859
-24.31079043 52.47770053 31.37385752
-58.65702243 73.01253513 38.43282116
25.89302435 32.80266532 22.96286059
-68.78054438 56.09499635 44.47542587
-3.727872715 66.49017898 39.58072341
-50.38431241 81.0485737 19.3132937
31.95003959 45.56373473 54.29381719
-11.80270504 36.88725958 35.17448115
-39.12912452 46.45555816 34.18953612
-49.68149392 84.67414028 17.87752507
23.39048204 33.32837319 23.16395071
-50.1642156 80.52824881 19.48499011
-69.43275253 24.0277337 38.12833475
-9.69044604 68.86703818 24.778785
-68.77277747 56.59329442 44.5159778
-66.68900838 56.50849249 45.10303334
-64.83288614 73.65676655 37.95852764
-66.39688733 56.48502398 45.31976378
-55.53579593 24.9597724 36.31159788
-20.95703208 48.26060088 27.24967748
-16.80973941 49.68219132 27.43582717
-67.86801636 62.65904535 43.84746147
-50.19905659 41.32527628 43.41692835
-41.27841538 62.0429973 34.27491701
-53.06126202 28.72035878 38.79718564
-21.25668321 89.57535227 31.30623116
-61.45275293 32.51108399 43.00511638
-59.96361422 56.36182788 45.52305025
-14.10849695 72.82719019 20.4354847
-49.1266421 42.58162999 40.28102295
-56.49627776 58.85829167 45.0142392
4.807613245 60.94858163 61.49737866
-14.68010112 37.23039119 37.56653697
43.42428058 44.97234935 53.14420261
-71.59896312 40.20691637 43.00083416
-72.40850989 51.67069632 44.08803622
-39.53312048 65.84267059 30.61918389
-47.13707879 87.43128152 8.691944308
20.22901379 49.7255881 37.34845757
-49.83859477 32.04734151 40.34704659
14.81142089 50.52577061 29.0925211
29.51145479 70.40466862 46.06594154
-11.77660832 31.46029044 22.42338907
-58.43747325 56.33531567 45.53977913
-76.21216882 11.82449468 17.93936654
59.15578899 68.82423795 52.93274205
-28.53877887 75.82650397 -5.804160597
5.863090562 55.84959896 27.26254512
-8.688511352 81.57750725 -6.431878334
56.42908975 49.67171299 48.55023197
-57.93231523 56.32827187 45.52521198
-55.74885012 56.3294574 45.0950496
-74.89709512 31.34406916 41.63667004
-69.57055022 32.71590941 42.17546594
-54.24340551 56.44966347 43.41251217
37.11171096 45.35676978 49.88541408
-47.42222117 68.9702061 30.77681335
-51.19846574 56.40034273 43.40437503
-65.07031156 57.46450123 45.34957282
-49.53654418 56.72656077 39.30045892
15.15922488 42.0604302 28.24514717
-15.10249089 60.02986508 23.51971299
0.9816322215 46.74477636 29.00935838
-22.42398457 22.5270084 34.93171293
-23.45026155 56.04229495 30.61582211
-48.86447457 56.94504312 36.63598814
-65.1123375 58.49013289 45.1045884
-32.46837379 49.07558315 31.63733287
-66.63705979 9.519901806 19.56016851
42.97507206 53.04857097 52.7014489
-48.33762699 56.93944715 36.60047725
-48.33709765 68.98570579 30.77135408
-46.30840597 56.90693936 36.59086683
-8.461629184 47.38948934 29.15258711
20.33887141 71.58277157 51.61885304
-40.26363311 57.25227506 31.42918273
-38.98080412 57.17505436 32.08097383
-53.83819499 26.35341684 37.28933617
-55.69564199 54.43003115 43.82826486
-13.52973063 81.07290924 23.65568157
41.77370079 69.07849344 18.33617634
-37.1930432 57.2643489 30.70344069
-40.06796413 60.54612348 45.59424137
-73.04582557 44.21116546 43.40564091
-71.38488907 33.76834319 41.95733365
-72.74863807 61.73792423 43.81798965
-74.14065927 11.07021596 20.47389466
-53.16956138 64.68676465 40.81169541
-28.27384371 51.52733287 31.50891516
-19.45847124 25.35760477 36.46638883
35.65947213 71.5355696 49.2450771
-68.14778365 85.39230463 24.71546437
0.9349877878 37.05275626 30.82411974
-54.91448633 61.08512758 42.16840867
8.413181999 57.53177016 24.72827876
-58.32427561 47.74054173 46.23970504
-59.61116822 21.51781613 36.2590499
-52.31681587 19.79383944 31.57458842
-41.45197059 24.55679625 26.65048576
37.11764863 35.1027144 23.25481348
-22.03445842 57.05109584 30.2882047
-14.73637947 50.25352335 26.2346266
55.13346679 38.24251464 59.11633362
-16.1699868 47.11429131 22.16403377
-51.09567317 64.12950888 41.0585834
55.04054347 39.57303635 61.16831065
-18.66593811 42.01984566 29.34030289
-17.66265144 57.32356325 26.29147367
-16.98076954 57.6733741 22.10057449
6.086636606 66.06888588 60.07964131
-71.57624567 64.80404198 42.9605201
-16.83183931 57.74691473 21.2184591
-16.56013268 57.80807764 20.45661919
-49.48792443 41.96432527 41.68940463
-52.45138717 13.07181795 22.23476831
2.773278233 51.13091253 53.49654648
33.2896438 41.37727251 44.37144488
-53.34567262 69.94642974 38.05365853
-16.20864355 57.828995 20.14676407
-68.37070186 50.10944128 43.96226718
-44.43974352 47.41331939 35.73719381
-35.94787 48.33967985 29.19051403
-49.72239964 33.99759204 40.99151967
-12.02168619 57.20359394 26.60839913
-28.92159358 47.51424406 31.60618153
4.734966716 67.86092579 57.01402792
-71.19058853 75.39715689 36.44718085
-23.55265891 74.7731948 5.472539789
-63.72612105 29.65345341 41.65227704
3.309865882 55.48595038 61.10416839
-37.53422428 61.29312672 30.61263993
-7.786512832 57.04942785 27.59039885
29.51676601 25.69144533 34.90433396
32.43832001 32.21985586 22.65366998
8.317744553 47.42687871 60.47846711
-71.41976294 50.16084098 43.94705624
9.849060403 51.62996812 28.8738242
-59.16027564 73.01861806 38.45817943
-7.267189986 57.03523874 27.65607846
-9.613794353 76.83053364 -3.588854886
-19.2535686 43.50080645 29.74021661
60.8921355 49.04904986 49.10078964
-59.83348493 27.67560192 40.56370782
12.72795892 44.55904747 28.83623595
3.750508598 53.98002481 61.02209556
0.5673431608 60.49733234 55.89117202
4.186719666 53.93342711 61.47984769
-37.9939162 58.17721692 31.91221576
-50.83051377 22.07550301 33.93713058
53.13939902 65.64285251 50.22546104
-53.04881471 51.85984272 44.02691762
13.09985255 56.17019624 27.98736498
-59.99279139 57.38097809 45.35085689
-50.14419347 54.85743375 43.63460988
-30.12060231 85.50125854 39.50520222
-39.93252726 64.26494321 31.53084147
-31.06978875 54.63779163 30.89348862
5.901127717 53.861501 61.98787067
-63.61639369 72.03202708 39.10777426
6.530829923 53.86596559 61.81595441
65.27711179 44.79012887 62.74536823
-58.51089183 75.69767955 36.36704118
41.87307068 14.58601265 39.10842195
-18.25059841 27.75494159 37.53920953
42.16393628 14.56317743 39.31804006
42.18578401 45.91920971 42.38854347
2.42996242 57.07439197 25.35225365
-44.23450262 23.19010042 25.56681109
3.176454611 57.34205503 22.10266446
-78.47087292 5.946170817 16.69029754
-11.95499875 81.85101495 2.669266597
50.94696873 64.57315376 45.58149097
-52.92347271 12.58474047 22.15250787
8.256069893 38.23613966 33.17029035
-70.35723929 50.14519559 43.92604972
-53.75662712 57.46871209 43.14310229
6.95064866 57.24698075 22.48659469
7.117177872 57.21253232 22.85473913
-49.50486051 35.14006701 39.3406319
7.783294722 57.07473861 24.32731688
-50.09681651 45.2914304 43.96854612
8.20195547 56.9874383 25.26092163
18.24925832 73.90904517 54.1454779
9.331161484 56.83904418 26.7682427
63.51829358 55.25088988 58.17745428
-15.51423914 25.85241736 35.79669529
-1.817163073 69.23341506 42.33069895
-18.74370438 52.60561067 28.8273062
-68.547133 23.07211607 37.3996668
9.853693638 56.82323616 26.85210373
-6.640133919 31.8492444 22.75521213
14.59833103 56.65985414 27.84393079
61.20929561 6.6228517 57.94338411
48.21684377 64.17779478 39.038475
-46.69237853 53.33734008 37.31612012
15.61398053 56.64307614 27.84501166
4.125480513 50.79569643 57.13010715
6.862871577 38.26992373 33.04379143
-2.213825964 60.55560458 49.91844914
57.48316026 48.2280172 47.62876597
36.56243462 44.90863049 49.36585133
-6.882140967 30.46467457 21.39453855
-59.70652551 35.88906336 44.24453699
-66.16347706 82.46253878 29.21470159
-11.77117854 48.0373392 28.08966471
-62.31487082 63.08559191 43.6634204
-37.23457791 73.16304926 44.25343729
-49.51100986 67.93587774 37.35575656
16.95102301 46.45314857 58.48253709
-7.956632137 84.21495469 -8.055662612
-72.37578421 25.03007989 38.7069044
-37.39794236 41.37112917 28.79010105
-39.54982764 45.19256672 37.27821185
-9.365839787 69.8563927 24.88501311
40.19949605 68.56121824 47.94786305
-49.61593086 11.1622019 14.72904095
49.09004369 54.38197545 53.53607751
-74.73580083 35.29855285 36.485863
9.782673524 67.69333031 57.99697081
-55.81420374 68.22311619 41.0499814
-62.86164174 53.86312674 45.94926357
32.84333621 31.25889564 22.07867665
-25.48321471 76.15680955 1.431979734
45.53630483 54.01229423 52.67868522
-20.61997458 57.60790192 29.38128353
-51.11425421 70.43803061 37.74789274
-63.49318878 8.6640226 17.24300174
-57.87652373 54.29126426 45.85507972
45.89094039 53.99445762 52.81811453
-17.07632735 56.258755 26.88744774
1.056105864 59.51038797 26.46864152
-12.10855847 51.54959461 28.16766723
46.41419908 53.96589418 53.04991126
19.02446783 28.84369157 35.27154875
47.00858721 53.93861693 53.25321218
-17.21975419 57.49659346 24.19834925
-17.32758344 25.10516816 21.51055915
29.90688509 22.67958002 34.83402121
48.07899121 53.89421851 53.56448895
52.94929586 52.71256846 54.69986727
48.61071261 53.87377506 53.70040899
-49.48070922 50.59746947 40.52108522
-70.38283292 24.513652 38.49526752
48.92401025 53.86440206 53.74946991
-53.27313354 15.26647381 26.04751848
-44.18835941 18.96899178 22.11995241
-62.41466147 52.84383134 46.04346479
1.049450284 37.99944875 31.46567559
-59.61293409 25.28865656 39.09784655
-46.16179879 45.78423216 37.49731116
21.95676245 28.37375547 34.34106267
53.73971821 53.54127987 56.58212413
-55.73720777 67.85550523 39.47615293
-18.30192246 54.63940046 28.439903
55.29219016 53.47670738 57.0356631
-22.49716329 41.46802777 30.65020466
55.61040567 53.46485361 57.11258475
-44.10866945 48.44896699 35.30478453
56.34853323 53.43940188 57.26728171
-0.3103931752 36.73275989 28.94973161
-53.4672544 61.57585848 42.02229133
-53.46471295 29.20309125 39.09685631
57.97446336 65.39668867 52.16103513
-18.41625883 74.6732881 17.30607104
-49.52425833 61.99030207 36.45917374
-49.527059 29.62022962 33.5034745
30.70181741 73.55510288 50.05287903
59.9442092 53.33526799 57.79042696
-56.5151758 67.15105955 41.97559516
60.5184237 53.31816714 57.87944002
-29.42062876 49.02484732 31.64508812
50.97750416 65.81661997 48.62052756
-59.80652652 63.56138932 43.48830129
56.30308865 40.48570183 61.9859306
63.47661827 53.28782637 57.66751232
-58.67285127 76.78381056 35.44260805
35.00905143 48.01839559 54.34815504
-40.98092264 56.64110994 32.83418422
-56.96615321 26.19683694 39.7035508
-57.05733212 76.20999631 35.96913256
-51.64733521 74.19767276 34.99137037
54.30439485 58.22237951 54.5725837
-45.99443185 37.40991156 35.62772304
-46.60470704 71.36623296 31.93928474
-67.81193717 54.58291139 44.36416969
-13.23045692 81.43376146 -9.72363742
-62.15113498 81.81945044 30.08832188
48.0395958 12.19206537 42.41655276
-66.42968747 43.41917521 45.51126396
-70.51607416 27.84859795 40.59267747
15.49686145 57.20437181 27.17805795
-67.00546096 54.53537073 44.76225651
50.38063927 51.38665224 53.10200564
-10.11178569 61.90654658 29.91527267
43.25563512 44.40653786 53.91807626
62.91939376 57.33886283 57.35951771
2.900801396 60.62495013 24.83147763
-21.30120736 49.39575222 31.61757185
52.73303192 50.76324101 54.06376204
-56.51194919 87.07423001 8.798061954
-14.62819645 29.66338009 20.520487
-4.855615862 68.8449221 35.7667771
-46.86107859 72.45043358 31.05526585
-74.44456075 53.21329744 44.0486064
51.10672583 53.64296499 55.90381642
-39.58761735 66.03880498 45.83263075
-39.35158643 61.70859135 31.9628463
-74.36689756 31.3243944 41.76395719
-55.85769042 18.70484115 33.23845987
-50.00643449 12.00341209 5.038128901
1.964124788 47.79871182 28.2404208
39.97398399 51.01735131 53.54709119
-38.4888229 58.17908953 31.9848588
-71.72603666 79.21993776 32.95814952
-68.72745782 16.12539599 30.6770379
-20.83876387 30.40703894 36.37851087
-68.76549543 57.09183709 44.5537829
-46.66981623 52.32152669 37.45083882
-56.22459922 8.968564755 18.14813155
-37.45848167 62.06685826 27.44279537
-51.08205597 74.18507922 35.02976323
-49.12865779 44.79639265 43.70403097
-67.20451383 57.04774984 44.76789133
-10.9236026 80.31484458 20.30556281
16.76761593 50.93320471 29.8163116
51.97226659 40.35934599 58.45203373
6.559545621 61.58294343 59.6257805
-66.02932093 56.97526947 45.38518007
55.30524787 71.14013389 50.08901623
5.917604426 58.96207221 61.03998411
-58.98176151 48.25230839 46.25077153
-49.6724775 10.27874676 7.515692871
-64.54355181 56.94434462 45.46083333
-64.03870548 56.93745805 45.44450036
-64.98808998 86.524919 22.61795906
37.66941998 47.13385838 52.45587819
-11.62000212 81.42279045 19.22980775
-59.04007947 41.77769589 45.67758867
-57.94602068 56.83740149 45.44411846
-57.43701842 56.8284185 45.45132977
8.910146223 48.84530695 61.37926522
-64.48000529 43.35245648 45.9139676
-64.19823631 67.81224698 41.59189545
-48.86920752 64.74083846 33.53722184
-55.93189958 56.8125107 45.34896383
-60.00950663 31.52195519 42.55912784
-21.82442937 46.39861209 31.55049812
15.60102639 35.56319662 33.66583223
-60.62169864 76.27355858 35.91100149
-63.95394875 74.71338377 37.17820688
54.58672579 65.98486844 51.80571777
-51.21961724 56.91322844 43.28109801
54.72513481 64.55706833 50.87434524
-46.57524908 26.90192549 29.5367589
-50.71175786 56.90482197 43.28183464
-46.47004213 24.58555322 27.27363027
-56.86872673 54.7806634 45.80822526
-49.48289233 56.95393406 42.47735345
-49.47018392 58.82583931 38.22440959
-1.323141561 38.78171641 28.66364967
-64.24653379 84.14198367 26.83282935
-55.74218869 30.54536484 41.42901624
-55.41547882 54.93958855 43.68616275
1.253318846 38.57227013 30.60370882
65.97389327 45.8642994 61.79601398
60.76727361 57.3590324 57.53579685
-46.80640608 33.55399778 33.93170518
-45.83513564 57.41819704 36.39219994
-19.66801773 56.14138287 28.74424035
44.57100693 67.35676583 26.13650334
-40.7571481 57.80521736 30.93098196
-36.81060119 58.04442479 27.40145687
39.83858184 48.08474646 52.65688499
-24.98935646 57.63154545 29.94007971
22.39199411 34.34092625 23.25319522
-10.99755132 37.39788776 29.09318792
31.94036104 37.77242229 22.38344005
-68.73123676 79.71051457 32.51869037
59.39053728 43.37867833 62.77319864
-49.49978902 41.66631707 39.32452275
44.27284915 66.55229246 23.87899575
-19.13437507 57.82484117 26.57958128
-11.33152743 81.69183662 10.22486729
-61.80987647 69.35300151 40.73014044
-59.69381313 73.02758869 38.45579057
52.42254772 64.22525712 49.33872797
27.43224218 35.78285275 23.0329772
37.48636471 48.50984632 53.99723863
1.894404019 64.53941675 55.32775472
-14.3101623 31.04676553 21.88041106
-16.53227323 58.1971288 21.76155752
-68.17449371 33.68511559 42.31125867
1.500218648 64.63914854 54.24516872
-16.39826548 58.25341909 21.08254253
-51.98433061 79.13538977 30.17478197
-6.411900719 83.80073794 -9.368362935
-62.9596318 65.17192123 42.87331015
41.74318809 52.56071011 52.77318061
-14.82775808 58.32841591 19.91241936
-43.79728581 33.02052984 33.72409221
-69.09425605 86.61400854 22.36681306
55.14928603 6.556829475 48.21221512
-69.06753703 39.66663834 42.9633768
-14.36938352 58.24586018 20.78337188
-5.643580635 42.39529477 28.32501468
26.69005006 71.09788266 50.20994831
-49.58227195 68.87714588 38.09570099
-12.29294078 41.52395052 28.05500563
-11.94422501 61.83313071 25.29035318
-44.4927856 57.38528506 36.51827203
-11.13987762 35.51703351 33.47473725
-8.821755352 57.5783054 27.47487007
-58.39868089 16.87514192 31.6569622
-56.2585463 45.70553141 46.16307945
-55.83935575 84.46018485 21.53561769
-19.39919209 31.88128813 36.46973814
-54.6566803 64.19137653 41.01948396
1.113608838 66.94214584 50.89058718
-62.78691661 17.86317242 32.67732784
-7.884487237 57.57628831 27.31954324
-60.85786526 49.2863432 46.25800688
-7.619682839 57.56659943 27.38151909
1.765118395 50.93903068 50.0896718
-49.46621417 54.65744148 40.00032985
-55.81755193 90.05269698 3.222621418
-21.80070774 21.53028217 34.73029402
-19.60134334 41.99614518 29.79382285
4.876759906 54.38489291 61.93394942
-49.62228258 67.23535474 33.85587682
-79.48872843 10.84328474 12.47490364
-12.39126636 75.71758072 21.51408134
-55.86662807 82.29829198 29.15772749
-1.143262621 51.16058521 42.24570868
-42.98590273 31.09792806 32.58175743
-47.00454009 37.42389433 35.65803455
-37.35744185 70.19521213 43.76984208
-39.15159117 53.68467846 43.49917206
-13.37701857 76.22149333 21.67892571
-49.18544902 79.74864469 16.69526478
52.64368476 11.02081611 43.48195998
50.93676728 42.7237722 60.33472944
5.370026564 54.37145867 61.99583493
48.68936595 64.44165131 41.71196425
-47.17234623 71.15701627 34.47627736
5.906227897 54.36114429 62.01331502
57.74283418 51.97274708 56.54745665
-38.79621761 70.1328991 27.28767512
-15.80759752 24.99596726 34.14165625
-20.74854753 52.02449383 30.12902949
-15.8527296 25.11784514 32.73541556
-35.76125791 42.39239778 28.27562587
1.768637783 57.48898363 26.49213072
10.72730939 68.71680653 57.58884589
2.124721535 57.50758327 26.20830511
-62.02844228 71.47211913 39.4781217
-48.22132475 53.36526738 37.28349885
-14.18502126 37.22843122 37.49487495
6.348564386 62.52035805 60.43710472
12.23253059 48.43876112 59.63850609
-39.58165135 66.53801151 45.86298016
-50.64152446 54.86052927 43.69351876
-54.12799137 47.85377144 44.12499503
46.86381443 31.55207539 21.82808365
3.376357181 57.71865418 23.5193452
-36.46480119 50.36152662 29.12458661
0.5717704467 47.75773659 28.98162157
6.241576184 57.95821477 20.19193603
28.82767167 37.32892482 22.29885006
7.367626081 57.73707916 22.54429541
8.171353731 57.57942465 24.22118864
-67.88090523 47.6114552 43.73410104
6.446511644 49.9031599 61.22204771
-49.73157123 83.64541357 18.17600388
54.83185913 43.02037245 61.97540106
8.817383539 57.45176366 25.57996904
24.25323155 37.40645238 22.27122671
8.97923732 57.41967371 25.92162616
10.42984231 57.30737566 26.94862498
-59.08600427 81.75994457 30.1945707
-75.23829014 7.89112293 10.97532279
-26.42552167 53.01839242 31.32704427
-39.91171432 52.76634933 42.65148497
2.076710891 58.6752989 59.27489825
-16.89703245 48.72179345 26.94819205
4.652241808 52.4397292 61.25108263
-12.2352965 78.68936608 21.94552905
-55.79419336 23.43480342 36.58390605
58.5687733 65.85927795 52.50426362
15.10639231 57.19072863 27.41090278
19.8164059 43.48110751 57.47786755
3.399720519 51.4162369 55.891468
-16.39531514 57.37138804 19.66795806
-47.51087188 9.76969522 1.359620759
-46.62394593 52.82538171 37.41961512
15.39070729 57.18358674 27.4395905
-8.487898418 39.49690347 21.72759727
-70.00357399 27.83785047 40.61970522
30.26164766 72.53732082 50.29869729
-73.0790342 13.97586752 27.32699981
30.58337473 22.63566526 35.21480522
-8.785947769 69.21032258 26.44783704
-53.12683461 82.65067805 24.54447702
48.08187418 51.41885088 53.16660864
-57.30196746 48.22262085 46.27505936
25.33621994 37.9157369 21.97918874
5.762503969 57.97687767 20.06664533
5.144543285 58.97092223 20.2981813
51.19772636 54.13644342 55.98444527
-52.89974096 60.53292802 42.36787938
19.21337891 28.94661994 34.04065444
-38.99404634 65.5592796 45.45947284
51.56730963 54.1234555 56.06473678
-15.84808036 69.54497073 18.0831459
36.10431038 34.6161105 23.27027556
-55.4185512 67.34121294 39.5590226
-29.98486642 53.0794215 31.29736254
52.4333754 54.10780007 56.08131246
-65.99865064 74.75370075 37.10011485
-11.53464224 80.22663227 21.44612486
52.53162323 54.15735127 55.48734947
-52.15654527 74.20676057 34.98298185
-37.7722438 52.07597079 32.77796883
7.225627939 58.73880731 22.59586566
-37.94551379 65.87760503 24.08423128
-5.7272754 60.02839257 39.22882232
-49.49922873 50.75547859 38.69033151
4.398698817 50.30432506 56.95555936
-59.69153038 73.56581998 38.03381442
11.7070593 33.79081772 37.50358085
-65.454554 52.38839002 46.08364486
-45.6366648 47.27375974 37.58556439
-63.51489436 65.70645584 42.60057512
60.5526501 53.80311797 58.06989054
43.59221358 41.84956308 54.40391367
-55.78027968 46.71648479 45.9892683
62.12183967 53.75489028 58.33049893
-38.31389602 76.68616006 44.34691454
33.86163701 43.63125269 53.05636218
-66.28006768 20.69349509 35.44688732
-69.56769877 40.17337783 43.00279969
-58.65949431 24.80104365 38.7499454
-67.89597351 73.71524169 37.86385476
-66.26003919 36.50079617 44.21953134
3.42142556 65.26911385 58.21896837
-7.957190209 60.05636816 33.5026776
-48.59015625 21.42761438 29.37776258
-64.33009329 34.98694087 43.9454935
-42.27949683 53.1183085 39.01723584
55.83949793 56.48647553 56.95154829
-27.95065828 53.044398 31.31600526
28.3632139 68.19359277 36.99285338
-43.03155837 26.84879294 29.47775717
-43.98564508 50.69310682 32.53627316
-55.93953325 85.68193656 13.19837617
-62.78101624 35.45107478 44.08871093
-49.81018096 13.16328333 9.015993689
-74.35408667 57.68760284 44.53011799
-60.86139064 47.78147347 46.24838261
44.01827776 50.94708375 53.59153717
57.42254102 55.40204966 57.58517775
-66.2618404 48.89604935 45.99275233
-72.82316638 57.65867974 44.57392279
56.6747447 43.98718049 62.05382011
-47.83329533 28.80206387 31.02504658
-72.37273472 69.55232284 40.4306734
-14.11599739 70.01590041 18.11257287
-69.77562914 57.60804873 44.58050082
26.84201175 72.52787506 51.0604958
-60.19810351 21.06389569 35.81378072
-69.69961492 66.85737105 42.07265641
-69.26572643 57.59861152 44.59281339
-79.61245028 11.34596648 12.48964249
-48.53211174 19.86690842 30.00458429
-16.31761248 50.35738194 25.33051068
61.85696511 40.3353301 62.672398
61.72129106 7.957950847 59.82686121
-70.42781664 85.43983189 24.59854922
-73.06733695 17.59124948 31.96790462
-71.8042614 57.64025954 44.59344667
-68.24865699 57.58111724 44.60193855
3.642743059 68.08977591 54.56566191
-66.15342442 57.49053266 45.25393706
17.46630793 29.41334932 34.78233711
-71.94978016 48.67377669 43.83113849
-52.25593291 40.36730249 43.27676407
-55.80357092 89.30230876 11.93101065
53.73511519 53.05377473 56.41567656
4.695156123 37.23158353 33.85771696
-65.58218834 57.47493425 45.32607672
-40.28471465 56.09835029 44.82880606
-78.27121906 8.480426649 16.36593553
-1.659987084 59.0669118 49.61473823
19.39828934 43.94646007 57.98209379
21.86442503 32.86501595 23.00732698
-59.48472421 57.37246679 45.35277074
-55.75569435 54.28687902 45.50153177
-64.70221547 37.43795608 44.69649068
38.13831642 70.51959994 48.91318197
8.823236941 49.34180786 61.45873535
-57.70030886 73.52770283 38.09656824
-14.48838929 26.83492593 35.84866486
-55.94612452 57.32190237 45.26492727
55.15629855 58.1643123 55.08420715
-54.78799201 57.48102865 43.19681007
-64.25035448 79.63062569 32.59156762
7.027081897 67.094476 59.64777591
44.59891823 46.72225032 44.25934491
-19.57878811 55.61893364 28.96555487
-54.28324959 57.4741945 43.1798885
-39.70431302 42.66030391 37.57082351
-38.89506415 57.68357535 31.98798081
-6.514888884 51.3791496 29.07957112
-13.55282335 31.52715669 33.63917533
-49.54279522 57.43681184 42.70982053
-48.36524345 11.21176613 2.261865822
-67.29538546 68.91711417 41.00971742
65.53603227 46.56261255 59.5996286
-7.725543086 31.39899601 22.36237715
-21.61723059 19.17442722 32.91058269
-46.51718956 74.73848632 33.56150295
-16.7411331 44.04887366 28.72535665
-49.48856313 57.9297899 36.97662809
-49.57211852 24.57124353 33.85797419
48.47216606 44.70555232 55.27871556
46.97531284 54.48239576 52.77361708
-13.05903228 81.08324831 23.44589309
23.75856919 44.47917972 56.79300448
54.83782931 7.594483743 47.87904548
-45.35923321 57.92812698 36.20844442
33.08330759 70.59176745 49.03942942
-67.38046028 73.7029743 37.90795211
-63.53423156 78.51273054 33.77905992
33.5523229 24.13132586 34.76581176
56.81484447 58.67233706 54.69703114
-52.8251693 58.98866295 42.8006914
3.351022551 67.56127065 54.92992875
-62.49136638 26.7606322 40.03893617
0.6948014117 68.70961319 47.93232747
-26.91448983 52.01283734 31.4402289
-71.14289096 37.71355336 42.72543146
-40.64160835 58.17481686 32.4450083
41.28714041 68.36905803 15.01132801
-55.65756647 74.71580704 35.56790568
-40.56558476 58.21080102 32.01277971
35.97031822 47.52683441 54.04459562
-13.81457486 29.19767043 31.59826478
-62.34220962 15.12110499 29.46459366
15.13210312 30.28202334 36.79656405
-4.54133793 44.84591573 28.79941824
-65.45046579 12.93906352 26.25488159
-51.12744494 53.85343681 43.82396919
-71.21663166 72.17399727 38.90909163
53.85807882 71.27547657 48.79384323
-40.20613103 41.4811269 33.87534723
-54.82416501 58.50370784 42.98498384
-37.28517655 71.29188092 25.19848512
-20.88367424 48.12448477 28.81582307
-72.5036271 63.79663977 43.17875558
-55.96935074 31.46616387 42.43631218
-38.78892544 58.18180638 32.01055098
-55.03262947 9.153518921 9.94709061
-59.25484579 20.58141903 35.40819608
-0.06104095475 67.21257507 47.9752596
-55.00468831 82.68116355 24.54869703
23.27834681 44.47364496 56.94883732
-37.07862813 58.28539888 30.48180951
11.24641374 72.31735122 56.4785851
10.75601283 70.20897541 57.74110926
-11.05876388 61.63424649 27.43028394
2.53476109 59.58216307 25.35343521
-20.34167705 58.19273399 28.36569376
-19.88311524 58.24636201 27.65569
-63.29724776 11.59752596 23.93787398
-49.47685668 41.20208874 38.88260967
36.67316753 46.73774712 51.4175771
-37.67509713 63.63322031 26.78055397
-19.13973401 58.32976046 26.54577252
-0.4255232787 65.66678608 48.50949272
-12.3961914 77.22671318 21.47583224
-18.81647214 58.34013895 26.36364341
-12.20830556 63.12610184 27.81088549
-18.25684219 58.4047287 25.5071135
-38.00504785 43.56901175 32.52445979
4.470116096 45.2500111 28.21648673
-55.82085831 89.05659174 8.960128578
25.35571804 28.31929968 34.32502409
-9.278663919 61.23184844 31.76213908
-64.44943837 86.5133686 22.64932047
-53.071854 51.35685973 44.04366408
-15.8251487 58.71431015 21.44952698
-66.06855904 56.4680689 45.45397652
6.668887868 62.07955847 59.66649938
-15.56091548 58.8040208 20.35770916
-44.98548595 31.12787426 32.61545254
-52.23798209 65.71682715 40.3296022
-24.9508894 43.96558592 31.25787168
-8.836467915 69.73802303 26.15818043
-44.86691384 27.81271695 30.2911191
-21.47106629 56.01024433 30.61044467
-14.82078773 58.82711581 19.94845864
-13.9078482 58.66392607 21.66878143
-51.08065995 49.81210836 44.11661008
30.43473958 45.05778444 54.62959335
-56.46226983 39.75692773 45.33797279
-3.246687677 69.53330214 39.12200928
1.79126759 68.47862297 50.40472826
-47.24775617 87.98932773 8.061484234
-12.1376733 58.31609034 25.36913008
-51.44533445 29.65298659 39.3156762
-11.57163047 46.51675342 28.22375652
-55.25638827 56.46509627 43.42653889
57.72443826 43.95322927 62.24776787
-11.08525831 58.2312106 26.15377425
-10.41695782 58.21620464 26.2005255
-39.86057492 67.96716038 29.32556681
-10.98427203 68.76970229 20.32882983
-49.85009774 84.25031598 11.17645786
30.28570817 38.34119933 21.92285415
21.31021236 29.38222169 34.41063361
-5.104265492 42.87323675 28.50050565
-62.75337009 35.93934496 44.24188301
-71.26476983 31.74844174 42.07635272
-33.244224 47.21031104 30.13216829
-46.65100741 45.60978545 39.61571126
-39.69089975 58.60722018 44.72397383
-9.665053667 58.15902558 26.72090921
-52.98518578 57.96583833 43.0516204
-10.15282054 82.12460072 -6.677045242
-49.12909811 35.99784593 35.13788829
-11.31145313 59.30018885 25.44071299
16.24164761 50.52286361 28.85351485
-31.80693657 83.90370788 40.89240776
58.74551787 4.220550396 51.34106166
-7.340417985 58.06422105 27.37814752
-55.91338055 30.50783173 41.89737532
-6.859295905 58.05691304 27.37123141
4.006421875 54.92969732 61.60208175
-6.480946032 79.52050476 -6.280251753
5.909561803 54.86167855 62.02875305
6.362562778 54.8809449 61.71870518
-31.44681232 83.92026933 40.63147243
-40.2565937 62.14699506 32.87276733
7.047356446 64.07095254 59.78333103
-48.36514673 12.71836541 2.252066054
-72.46950667 11.73959639 24.0378216
7.065404016 54.94762659 60.81057923
-68.48522541 27.81526906 40.59228857
37.84803652 42.79636907 50.33478799
-51.0839994 28.2118449 38.4966691
10.59085022 55.69473076 28.15875625
-49.63426043 77.32861412 27.39441586
-3.66136938 39.39798232 27.78214003
-19.17020146 53.62696243 28.70532687
2.087985256 58.02833058 25.99673704
3.194723531 58.0069001 26.03445611
3.55412209 58.08164261 25.09825084
-58.09899979 30.04268359 41.88732418
-57.94964951 61.45828766 44.24195925
-55.32973629 66.29197902 40.06907639
52.73114375 53.17616219 55.18637679
4.392323793 58.42693292 20.9300182
6.55106884 58.37864362 21.07891003
-58.36856635 20.10290669 34.9674568
6.850446838 58.32673654 21.62439213
53.79456628 9.255728486 46.27298303
37.9399309 68.82529346 16.17998822
7.822927483 58.15828969 23.39438854
-51.13900315 54.36148327 43.75504021
-40.62037225 60.53983538 45.77258584
-59.29661182 15.07145231 29.46018436
57.54571138 8.874946787 49.97800961
4.371257542 56.4009745 61.93276878
-38.343109 45.55018487 32.89659025
-56.52655507 88.76169087 6.691492524
-7.778589992 69.50172045 28.69961003
-57.60172886 84.0111937 27.08393068
0.5907675455 48.75258573 29.08234567
48.67098654 54.9469139 52.88441725
-22.11369577 19.17708599 32.97439662
49.61283759 54.89302928 53.3303327
-49.46789296 55.30917148 38.26153309
-61.51878199 71.46280421 39.48906086
-56.78908354 20.07426453 34.99873907
-38.2498641 69.57327969 45.33323737
-49.1118617 18.40493137 29.60691997
-25.44614907 54.02434602 31.1157103
-58.60035431 67.71401062 41.6647452
24.44897845 72.99834211 51.88197773
50.4699795 54.8323197 53.87163175
-15.80904451 27.25999876 36.99260709
13.19123681 47.39671987 59.89916866
55.8561384 54.44443945 57.34725654
56.38072544 54.42759486 57.4427589
-55.55964382 9.122032236 10.4131159
59.73399579 54.321851 58.03082216
-9.379558846 79.94867253 -4.87131164
-41.24926323 29.22892408 30.64076815
42.15702544 69.97241128 48.67231445
-49.75683162 77.13600655 23.82707867
21.30013279 45.98707082 57.2370654
-19.48886434 57.78065934 27.16007945
-57.45242801 19.15667198 34.12404491
-66.06497535 49.88789711 46.09439808
60.04833242 54.31195389 58.08576915
61.6240676 54.26042433 58.38345978
-37.7763215 65.2404858 25.62146973
-65.43557925 74.74222094 37.1259998
-6.107651425 59.61894234 38.22791843
57.0769463 67.37512887 52.67158288
-37.52849085 63.4601547 28.76166752
-60.52175762 26.25597924 39.69505892
-62.5354012 71.48007138 39.48248633
-71.30033058 30.25973196 41.88524129
62.61994174 54.25362182 58.27250911
47.79742553 68.87307286 48.70529725
-66.99619569 55.535129 44.80784775
-13.00858267 34.61946451 38.42410644
-58.65658373 8.199810858 15.88289972
10.43356385 69.19375482 57.93474201
-43.44521883 46.154987 38.50189132
42.02138385 52.54426607 52.91102136
50.93029666 64.00501425 46.35340777
-48.22790454 17.45015231 28.86883431
-38.34139878 68.0880276 45.11266117
-73.55052382 28.46917664 39.7938757
-4.923029494 47.33892491 29.06474364
59.82027255 40.87382287 62.63623132
-17.85233976 47.62936678 28.15877938
-52.64602674 33.5330574 41.11508919
-69.5009646 9.980117142 20.5904579
-51.69525176 55.90094733 43.46982053
-29.9441402 51.05001343 31.54188226
51.4051669 67.65930923 50.45425188
-7.586555163 59.96851612 34.45184602
-72.11021651 40.21703492 42.9808697
7.389595335 52.34734469 61.80151977
-66.33723736 13.39304626 26.98047225
-6.380637166 81.19955364 -8.311083635
-28.57123639 55.62074452 30.65944685
-8.699672451 60.2326837 31.59747369
-37.50655345 68.9172502 23.67394471
-31.14057664 46.11104966 30.83873685
-64.91789125 49.85437383 46.264805
48.82760266 50.9740714 52.36107757
-49.50685354 76.06169 30.42421713
-65.60399293 81.32454721 30.66535973
37.96953744 68.73602515 17.21063395
45.66155717 45.47911403 46.83464521
-12.1863928 34.14850371 37.90792563
7.054640477 66.5783583 59.80735036
-64.59201452 81.30962105 30.64564315
4.890735245 55.38227522 62.00622418
-64.04777719 72.03902278 39.10883055
-68.21416792 67.88059644 41.56430739
-7.192953048 81.08898401 -6.872596943
-62.8033982 34.96014913 43.96536161
-52.3042465 66.24008072 40.09457074
1.953812619 50.89340131 50.58338573
-38.82914375 51.5741744 32.97810379
53.90244342 48.87637682 46.61163917
-5.567570425 61.75847213 42.42090362
-51.12232004 44.30874713 43.91853858
-28.9386941 76.4065643 -6.635017274
55.38647057 65.47567415 51.73765967
-37.51131016 45.58898186 32.28757735
-2.458570595 48.80412519 29.06556511
-73.34527165 58.16409952 44.6328506
-57.72059065 80.06008318 32.18748738
-62.95376683 54.37464764 45.85536527
-29.69423833 77.04212915 -8.042390443
-10.56361053 71.06858357 22.69472031
-72.81494924 58.15675071 44.61702535
58.29885996 41.90238195 62.63939118
39.6366727 70.00488744 48.77595396
-71.79503991 58.13782387 44.6422391
57.32997306 49.63849417 48.76405641
-63.20282021 36.92271946 44.56514874
-49.51312088 24.13171916 27.29575973
46.09089739 11.80562752 41.44759281
-32.97874306 50.08968776 31.61547869
-61.23401716 35.91625691 44.22015623
-42.16445081 36.38137541 35.18403908
-55.85187635 14.21247191 27.12164187
-67.19334375 58.04654724 44.8242737
-18.71594395 46.06279885 29.02941517
-49.4579132 55.20502587 39.4686284
-55.83055921 90.462363 4.296040985
-67.16325066 10.79642496 22.32162696
33.55489155 34.16103275 23.2126987
-54.71787943 77.52098412 31.95736274
-11.49607716 81.2726262 9.296107354
-64.58315346 57.96875335 45.22958307
65.64360008 47.56233871 59.62684567
-55.7576808 27.68855067 39.63610888
-19.23795146 55.64522454 28.59535118
-64.07314684 57.95926373 45.24248425
28.38832072 22.95004883 31.98382075
-63.57062092 57.95354767 45.21300572
56.95459965 69.94792564 51.96121686
28.19686585 38.93077642 21.30360853
-62.55594115 57.93725883 45.20859292
-12.14092285 38.78751922 30.65930252
-68.04720386 51.09935485 44.0622168
-70.35079213 68.44472096 41.24964611
-4.504114809 46.33378695 28.99994738
-56.45473667 57.83290459 45.2564768
-55.31509809 58.00135997 43.08358754
-65.83198212 35.50343534 44.06270887
62.03704019 52.79323455 57.85699038
-7.141855756 54.96315093 28.3799205
-11.89671086 58.2688726 25.87131514
-59.19135489 47.25449239 46.22083688
-52.73228642 57.96215484 43.0461517
-52.27147437 57.9527031 43.06799471
-49.53552871 58.05925161 41.30934028
-49.63373158 79.7155382 28.81836298
-73.78861799 50.6934451 44.04259467
-49.6230496 58.32640142 38.22476068
-49.68865217 27.9473172 35.47475725
24.90291954 35.81985369 23.0858003
-1.588828216 38.26485646 28.88776436
-55.90167403 7.54013963 11.36218445
-55.75468568 83.56836782 26.04569334
-71.39733722 13.46778189 27.07787795
-54.16590997 17.9743983 29.74205848
-50.84852443 37.88960366 42.63809448
-49.80360793 12.31086164 13.08363306
52.28799516 56.74177163 54.66517736
-27.42072146 52.02042271 31.44871377
-19.79991591 29.29979307 37.38083291
45.93365962 30.5956279 21.45532842
13.14944413 55.65535312 28.12794137
-48.9194 58.49178675 36.17065644
-47.9037505 58.47500875 36.1717373
-49.50012036 71.0427922 36.24619451
-11.54495933 62.98970574 29.26776705
37.87028951 47.55952546 53.30275807
-51.35839341 13.51824037 22.67057161
60.64238163 4.639794698 57.76570736
-18.36697627 21.53261755 34.04834986
4.588510541 51.1138969 59.17454244
-10.6992957 55.02321915 28.36102989
9.945649717 54.16665822 28.54085854
-9.188566046 39.57779245 20.92220041
61.0678349 51.02549865 55.25642812
53.674873 70.66745421 50.06050832
12.48892649 72.83701728 56.03560845
6.007409715 34.94482186 36.84729764
-40.22644054 57.57560253 45.14859352
10.68605804 32.80586332 37.47907961
-65.99442154 80.77150078 31.33334263
-40.30136249 55.10231609 44.74139102
-49.74155882 69.35394235 32.59107568
-39.66108614 57.61252678 44.61213331
-39.1723669 57.71288679 43.35387912
-40.63337115 58.62332199 33.06350349
-26.94218755 50.49538327 31.58130266
-40.6804171 58.59749818 33.37225713
-13.00271905 60.91664491 24.47816838
-40.56843006 58.66489662 32.56849015
-52.49043182 21.64250397 33.45361481
-40.48161106 58.72021299 31.90978038
56.68262041 46.1902946 59.78352499
-40.21880356 58.81064283 30.80988556
14.89472153 36.22347884 31.96213158
-37.42603448 58.73986017 31.09898462
-36.5741069 65.47648669 22.65252907
-17.81464662 73.56360111 18.42011482
-37.61291501 58.71024107 31.47846404
-13.65232368 79.62810211 22.97151365
-37.07404744 58.78530421 30.50431083
-36.90692494 58.82489007 30.01289798
-71.18791136 66.36257691 42.27377408
33.7928113 22.00114959 36.14202659
-52.74052865 56.93710265 43.29399568
18.89975323 28.78271343 36.00321137
-21.13535104 58.67408808 28.75578358
-47.65366877 35.4946987 34.87077433
2.104433208 59.6010152 25.21665205
-17.18620977 31.2538676 37.50462379
-19.92991009 58.80935005 26.95567878
-1.098443995 52.0928532 43.06799614
-0.103457484 36.70270663 29.25914787
-19.56827203 58.84992573 26.41568004
-16.72242308 59.02492381 23.84145379
-52.90445651 37.43336959 42.49983028
7.297952931 69.2020913 58.43594595
-0.9303954465 48.27437082 29.09726732
38.29495929 51.70992284 51.65405611
-16.34156229 59.08058061 23.12271644
-60.66994189 84.07225442 26.96021985
-75.49601137 10.3530332 17.40466698
-51.30450033 58.96491103 42.7864203
-52.11773603 52.35077493 43.97689681
-49.37690102 11.72874646 2.279950837
-49.67856896 10.76591665 13.51471368
47.80154377 10.68161119 42.51648254
-23.56371179 21.17406177 33.37513472
-63.74465539 73.10115957 38.374246
55.12820855 57.05016072 56.37016718
-48.29616275 40.87239596 36.65811747
-63.82744607 11.60481218 23.95440229
-69.28176592 72.6665243 38.64912875
-16.23588207 59.13877154 22.42703974
52.55750036 54.69606952 55.0552207
-45.62430922 35.46212103 34.86194873
-70.79086304 57.6246171 44.58177438
-76.41945084 11.85472263 17.62798826
-76.62664628 11.88490691 17.31710048
-14.44133676 59.21225482 21.23176137
52.16031289 38.5034022 56.65475831
45.47097363 53.46870037 53.17493772
38.14649892 71.01768838 48.95608833
-13.85946427 59.11695741 22.22707692
-65.9040052 40.41325984 45.34602714
-40.67072352 53.63332168 44.38506688
49.02739161 9.126486038 42.85581106
-16.60227036 72.8710365 20.40206225
1.890572326 60.22628717 58.78531491
-17.38588492 59.41581571 25.25686262
25.74324093 37.92516029 21.79217379
1.230775352 67.4843697 50.40035327
-42.83477223 32.52833973 33.42759607
-12.55100876 58.90292038 24.462245
-46.74858563 55.37455734 36.98389795
-40.19790615 56.59394334 44.88568625
-4.438841315 59.22312773 42.50457187
-12.26046213 58.85546821 24.9576955
-52.14027854 84.52689184 20.05579779
60.32805421 51.90754921 56.81130413
-11.14545312 69.20455752 21.1380922
-11.18118938 58.75704032 25.89449458
43.90586244 46.42097282 42.06232271
27.8608338 48.73189853 53.25514086
-54.63286317 48.36288688 44.13773441
3.852763517 55.43509133 61.59104365
25.01684154 43.58335933 55.29909924
37.63832627 68.54955269 13.6118648
-58.71904068 73.54603569 38.07802537
4.141074949 55.41354454 61.78619184
-63.15522921 60.00064977 44.67609634
5.349651152 55.37377998 62.01732512
6.027985133 55.37849058 61.83327874
-30.4345904 48.53855788 31.65701856
7.008303633 55.47864638 60.48364418
10.44637966 67.77975334 56.86713621
2.019088752 58.55291189 25.7467942
-49.50604205 51.96822933 42.09305839
-53.10922294 49.84428422 44.12994834
51.15793228 68.2862405 49.05016598
60.3802211 63.92775976 51.27471865
4.171587015 58.8174969 22.26480338
-55.66933505 62.02433479 43.06264213
-49.09671007 26.93496069 29.63411993
-20.67161364 51.32814314 26.54482234
-49.07648303 66.8542608 32.34916506
-20.58195429 89.12922109 30.52993166
-20.5326017 58.74170451 27.85589469
-20.33172374 58.76426273 27.55571371
-20.37247584 89.14744087 30.27847427
-13.5686579 59.06937421 22.72399894
4.522084846 58.86369217 21.66169288
63.84951209 47.3952433 56.082113
8.077613685 58.60636515 23.97087233
-12.41473361 35.14423091 38.04562526
-14.38188059 29.88694722 29.53146587
-7.739317438 56.52340454 27.86122003
-58.48209191 12.39061468 25.46612428
-62.66994129 60.50584528 44.54551165
-57.59013004 11.50613127 23.91046864
6.599582456 60.56791724 24.78817726
51.82502737 68.09839761 51.10356461
54.73754296 71.17872889 49.74924195
8.931071326 58.47318051 25.35421761
9.858014328 58.40203827 26.00331522
-49.50683094 35.74238842 38.17546193
8.227827075 56.44739372 25.69857848
54.25296351 52.55050233 56.33263221
10.07831554 58.41479861 25.81317106
-66.76874042 29.70160365 41.67355974
-66.76872141 61.09342508 44.33275936
-8.087098686 52.93554889 28.79150476
27.70429897 30.87123448 21.73233461
27.053951 68.13548068 37.91714536
-56.8040712 50.73032031 46.20210656
-41.06569842 24.60972333 25.96240503
-37.12481134 53.71441779 31.11413345
48.66496239 10.11551936 43.0967897
12.74347118 70.27186797 56.63198609
-60.26861501 80.10651803 32.13436122
-15.83090717 51.61851598 28.07745191
48.09356328 66.4603873 47.52383213
36.52192113 47.5335287 53.86168945
53.19608097 61.87068836 41.56491643
-71.57992464 17.08205756 31.76867667
52.36027377 55.17388542 55.37263823
-62.08973323 72.00525282 39.12744617
56.71523383 67.88636246 52.63243191
6.129821157 62.00459578 60.63952442
-74.2047407 37.26918816 42.64121925
-28.79142989 75.8796179 -6.372563947
52.86060641 55.21883145 54.75545538
-2.647937202 68.35072516 41.08275909
-49.59641215 77.76218222 28.18066405
-38.29756784 49.84227449 29.67532308
42.08894447 68.50010603 48.29696902
-37.01595055 79.83761084 42.47489302
61.63249253 54.75839048 58.42773956
-15.47871893 62.00551329 23.96325938
2.120642118 66.13016393 54.29797816
46.65339902 31.05689008 21.79004276
9.676715783 50.18547986 28.19544253
-55.14740121 84.90402605 16.25122436
-11.48313798 36.34773014 35.55014839
25.85411585 47.72384496 53.68677389
-71.10892957 80.87368211 31.12251446
0.6595930253 37.09256521 30.41450776
49.18734275 53.85545566 53.80310717
-47.66201411 48.81096256 37.60676109
-60.3062451 23.88396434 38.05683475
19.68323511 50.35445006 35.97889294
-11.7473571 41.99873039 28.26600922
11.15410425 44.99647201 59.01828631
-74.11701178 41.2426623 43.11062825
2.838844052 37.84014107 32.97378751
-5.717128502 37.05668617 26.22045536
36.95979738 31.66682885 22.38469735
-55.82247869 88.25785885 12.40607158
-50.83292404 80.80455697 28.05821314
-49.58762835 50.83150777 43.65122938
-55.07415463 85.56751634 14.36161684
36.10660233 69.26120113 17.29592169
-65.85288701 49.37780648 46.14881496
-49.73952464 68.8507002 32.606049
-51.5311336 63.09696232 41.47490065
-43.99976847 24.578339 26.88628218
-47.90172315 65.25057293 33.26198785
-58.79297765 15.06517721 29.43698431
64.62845544 48.35363447 56.46114154
-37.25561378 59.09989438 26.88694377
-50.84451984 59.98508868 42.50901432
38.02702263 43.30740861 50.19477526
-11.88031246 59.33936619 25.09439864
51.28508701 64.39593415 41.74766283
55.81493285 58.73073195 54.20982972
-42.88458339 32.0512505 33.1488545
51.8207529 50.77743044 54.07301794
-44.86593208 49.81727432 37.04481187
-74.49439207 58.71325496 44.30363854
49.79613491 67.32453045 48.82081916
16.09378746 36.15707486 26.67768662
-74.0397668 53.73431955 43.74964568
-68.231253 58.57677 44.69363734
-37.84596303 49.8498562 29.50118534
-58.8985365 54.81346819 45.81450023
-67.74412501 58.56643236 44.72074598
-48.7782955 88.01805866 8.019837661
47.27052734 44.19004559 55.66561276
-71.56330532 11.2882014 23.278485
-50.88050091 86.95333145 14.95423153
-66.13868854 58.51230919 45.04288101
-65.62331384 58.50011168 45.08619356
-66.33498854 24.45398229 38.41600878
8.282371292 66.12582677 58.99988999
-5.558451155 44.36121608 28.79349427
-69.65747592 66.33389839 42.31483205
55.85926451 54.94507854 57.36151736
-41.58535832 62.57708756 45.61326782
-20.88250389 49.63054247 28.81210558
-8.741386531 82.10639567 -6.7348767
-56.09311144 60.91156075 44.40807039
-56.1151223 29.5308329 41.62428229
31.62513925 23.08315194 35.64802347
51.43722092 55.1439539 55.89613702
-17.48119551 48.64480134 27.95337621
-31.4642642 50.57127442 31.56268998
-1.485410398 45.29326834 28.85007985
-54.37154339 17.07369615 28.58399996
22.88989754 35.34197401 23.1906308
4.83173402 59.93198208 61.64092647
-66.54605908 71.01876667 39.77586378
52.40328862 54.62519309 55.90741524
-6.21176604 31.37872066 22.30906179
-10.05363503 68.95113413 23.87180009
-60.3129686 84.64217708 26.10270549
-71.65651507 71.11890399 39.58799128
-13.01651847 48.26909386 25.63678088
13.1554892 55.1376988 28.30945692
-52.79626004 58.47186392 42.96791774
-48.69779454 71.18317908 34.46347253
-49.82365071 14.59093255 21.57857484
-66.16590727 72.07921316 39.0462114
-49.5110733 58.47479777 42.30735447
-48.94855168 59.00870808 36.00205668
-65.26816495 61.58203477 44.20109732
-65.29123311 29.19740821 41.41821792
6.690685615 65.04469216 60.20075194
-48.44083084 59.00037149 36.0020085
39.66737614 51.56273252 53.10102087
-13.76164758 70.89113964 19.53786242
-3.005935179 61.23439216 48.01628809
-47.42857536 58.98530557 35.98386154
-46.46350574 58.96926426 35.98603614
-42.14917408 49.75339795 31.4415915
41.17478777 67.47085695 13.80644598
-38.29632433 50.87085712 29.38782987
-53.44006594 36.95355829 42.34533296
-49.90222036 67.78633033 39.16641845
-40.3213312 59.16635516 32.52671111
-40.00540697 59.25476861 31.44009437
-18.28016501 82.29320326 22.04883591
-7.887845657 69.00506174 28.65938165
-30.48490794 52.5816683 31.34436503
-36.6951287 62.77867637 24.86055574
-60.43329663 22.94362227 37.34395935
14.19906407 32.76820939 37.24624261
-50.30546416 31.08019462 40.01014648
-30.17348047 52.57323869 31.38283102
-49.88218822 12.94516925 5.735111917
-55.21010477 20.74631548 32.72260094
-37.39502166 59.25120992 30.98359012
-64.5526761 40.87803449 45.51951825
-70.59686422 39.69475781 42.9285973
-74.57789167 9.43057687 16.28484041
-36.85757514 56.79113192 30.30627208
-72.76279874 60.18886367 44.32339309
-6.232704599 68.06437062 33.43732518
-36.37912441 59.60597475 26.67148266
-13.12661683 71.84779264 19.96451391
-40.22370672 80.4150642 36.38313038
-7.154398014 59.66286696 26.26434646
46.06139516 70.48733208 47.77679202
-14.74737873 59.57151667 22.9461929
6.533892718 57.3333202 21.56378107
-13.86967358 59.50043415 23.60398709
-58.99971174 16.42833186 31.13183866
-13.57707633 59.47672542 23.82341532
-12.46544923 59.38675454 24.65586918
-22.30652579 55.50824468 30.77070925
6.754994139 49.40638876 61.10345932
-11.030748 59.25770113 25.88040933
-16.32828215 24.85551725 30.0447586
21.87508469 49.39079978 40.92100965
-20.92589213 52.44272473 31.13436168
-16.86911198 74.81628358 21.17766647
17.42658926 50.29679846 31.25186906
46.4591896 43.67587794 55.96253227
26.51776157 70.45291636 46.07676184
-11.7473608 68.95641261 18.30688552
-10.89365669 59.23810239 26.08178178
-56.89444062 55.79806658 45.65565214
-8.986303101 59.16795808 26.53232437
-27.91246025 51.01626525 31.54620221
-3.532878839 68.54934733 38.94577137
-46.69887155 29.72980332 31.69211049
-58.31969294 50.24931017 46.24842059
-73.22527969 12.66688092 25.07063845
-61.82764137 23.90808313 38.0669856
14.61981074 30.292666 36.77071351
-37.7415088 55.234819 31.0617371
-57.6485782 76.76268247 35.49254329
31.66264297 30.3544964 21.14948641
-7.766048352 59.14708025 26.54197963
-63.27860535 68.8483367 41.04211244
-68.54885688 74.80123623 37.03462794
-49.66501158 61.33608705 38.2539811
-37.42016264 45.37528833 28.92426392
-49.7237644 61.48961364 36.48293658
19.73462107 49.82631285 36.27345461
-63.15100853 60.51312564 44.55273843
58.52944615 54.86180042 57.81905097
5.405218648 55.87274335 62.04103807
-46.58975243 44.79745457 37.3808771
-42.38870977 41.34321606 35.89254817
42.26526666 17.67402552 38.14556591
-46.33609784 33.06231761 33.72315587
-60.94738052 70.92012849 39.85322618
-38.84598125 45.55607563 32.92410675
-44.65539919 49.35752404 36.51513783
6.599087709 55.93950909 61.03829442
35.01606839 42.08071613 47.52940078
-55.70867073 76.84956163 34.11403432
7.117120624 59.19664744 23.12825727
8.410515381 59.06454124 24.4151846
-61.97841614 42.8148405 45.85129737
8.656818566 59.03940727 24.65998661
-62.4543154 62.05238998 44.0308936
-53.80168967 15.27293165 26.07335006
-8.237108177 31.4092718 22.34064686
-75.34993399 10.75678398 18.51641657
54.81068687 70.59006038 50.7423459
9.304641512 58.97278638 25.30982685
-40.45358666 80.45669147 35.94373007
-8.250716331 41.45472681 28.08772852
-32.18641631 47.14748887 30.65972371
10.62329203 55.17614976 28.34599523
-57.38625225 16.85998864 31.63979624
-56.12013696 12.36370727 25.3280461
-68.09265236 37.66155968 42.74731327
20.97893117 50.09729371 38.71705497
23.35438548 32.84436574 22.96290526
58.01017206 45.55113116 61.12358788
-11.76062269 49.53866248 28.13911805
-61.52232162 12.43755947 25.50094495
-44.8299398 18.44352515 22.51566496
54.2364032 55.52259646 56.79339795
-49.31789552 42.05200073 40.63917826
53.67404502 65.1434956 50.09375937
-37.84981393 67.03864394 28.06771709
-9.200418703 59.75340491 25.60350421
36.1741895 45.9106864 49.46057748
54.88171176 55.49441072 56.99753446
-16.98182429 35.24282897 37.77199859
56.37617221 55.43432374 57.41006541
56.89636088 55.41969788 57.48065008
-65.4331712 49.36430752 46.22547846
58.36622047 55.37202058 57.75381197
60.05714747 55.31193927 58.12880977
23.37654744 72.03933098 51.56611601
-15.02752275 26.74002009 19.57329175
60.58367394 55.29411635 58.23529946
61.13748895 55.27491691 58.3525648
-49.57782889 77.70325236 28.86122112
3.989099651 61.12329685 24.665401
22.8040558 44.9673342 57.13482336
-49.79107379 64.64263375 40.67970124
-40.24604306 59.70492633 32.08686678
-49.47031355 53.90951481 42.85694986
-37.72654871 64.76271805 25.33161347
-61.28628949 84.66238066 26.05378568
52.29897192 58.61455039 50.40242338
-48.13764223 17.90683633 29.37674022
9.685131749 44.60730252 28.85634143
-35.70060568 43.86845039 28.6088859
47.40635935 54.46318112 52.91447144
-20.94286122 53.45571669 31.03132965
-49.46144235 50.38952764 42.9313518
-19.83221839 73.98667822 13.89348926
60.01177717 64.87027144 52.05690166
-62.904415 33.50445806 43.40342776
-72.71495299 21.28255369 35.66247736
-56.6304847 35.83644291 44.26877409
-68.99072785 12.99046651 26.33330735
-38.46099642 57.16062067 32.14940027
-41.92827855 46.48666489 40.18888385
-8.318986999 57.57246705 27.44676496
-49.41508794 42.52891101 40.94803173
-16.04701449 57.35674702 19.7714987
-46.69459374 43.80301199 43.11844621
58.47298031 4.258918053 50.94763638
-73.75350053 59.21340284 44.18290529
-72.7533638 59.1548944 44.67138202
-30.56123671 54.62903575 30.8981493
-5.093416262 43.36997998 28.55851959
61.94682546 7.391521766 60.53273183
-61.38850785 45.79073028 46.15234325
62.47082924 7.27585998 61.7754878
47.51535038 45.49220242 52.15581796
-3.299555965 60.78117903 47.50687107
-15.17466628 25.674464 31.97110765
-14.51522838 28.30555703 36.26154689
18.14216091 50.6610516 32.71353005
-20.13688793 54.57391783 29.55001235
-66.12906225 59.02205824 44.95014702
-49.53368897 23.09476573 33.51075455
-11.6324587 59.91477749 24.19397793
-67.43790444 17.47709775 32.2194947
-70.96764983 73.77804943 37.7205237
-63.61351269 58.97961608 44.96311624
25.06490672 47.07462546 55.54725394
-63.10551478 58.97113973 44.96463768
-49.85474696 16.62214181 27.13795098
-57.30565487 49.22891298 46.24727093
51.51538785 55.65629504 55.7602411
-17.39302646 48.14766495 27.88104294
-59.03709849 58.90109272 45.00192313
-18.87731049 26.31665414 36.87549484
-42.89583381 39.31760964 36.19741386
-40.46543721 62.12845499 33.12782177
-46.56495206 19.22055374 25.47950952
-52.00159868 62.58428955 41.68945993
-55.66639877 58.93522389 43.96289071
48.75754795 66.87544088 48.40560247
-13.33077065 28.42662594 34.63020708
-51.81170168 58.97298557 42.78941151
-72.55775984 43.20831166 43.30114079
-21.49779368 56.52594274 30.45557907
48.1876281 42.04067051 57.1356674
-49.47362153 59.03245516 41.65315908
-37.50824508 41.73076356 30.46287918
-57.45482014 89.69592388 13.5031834
-54.12841729 34.03971614 41.34276648
-3.91225259 49.33346894 29.02442325
-49.68135475 59.2363557 39.3257484
-7.004161569 48.86710006 29.20137831
46.26408174 14.37801351 40.6856359
-20.5521159 53.36345019 26.20126734
-25.20302472 55.55701936 30.7568769
36.65218553 71.02814547 49.11966941
-55.7201454 56.85436037 44.82275948
2.836857376 47.77969337 28.29476543
-51.61185149 23.95817083 35.53731815
-47.45689586 59.50180761 35.81997063
-40.69254212 59.53371303 34.15958615
31.26895397 44.15287552 53.32206483
-40.58035458 59.57623246 33.6445946
-2.294964899 68.27178293 41.93186432
-78.40362401 7.945889676 16.76984041
26.26563571 68.52327948 39.39227141
41.78923485 17.66637687 38.32513922
-37.33102578 60.14236732 26.45282029
-55.89214684 74.58636042 37.11535266
-72.41696946 51.17274764 44.04356024
-71.60769974 39.70910745 42.95478857
-39.72380168 63.25433287 31.56965683
-45.63215338 47.77370002 37.6076733
56.75443673 45.60229578 60.76910786
-33.88515664 47.78806113 29.37409407
-15.02289587 74.29954135 20.99766206
-18.21233161 59.93847953 25.17364872
-39.90058475 53.62796002 32.64715519
-46.57281543 46.95310102 41.48653501
-16.3666279 59.96137858 24.55583299
19.95550953 30.75456802 36.21770878
-55.77049634 53.36424727 44.56165542
20.35144664 32.96015094 33.84474822
25.86627541 70.81298939 47.84765489
54.13964826 71.207775 49.52607554
-65.96894266 51.39565808 46.05281697
30.45683199 41.79702541 45.86552218
-66.00774804 76.91582107 35.30894241
-11.34968852 59.89602593 24.35773374
-10.16190946 59.8171966 25.04632568
-8.578315651 59.71214712 25.96381606
3.941258906 47.2654849 28.22681577
53.98224267 52.5631757 56.23713845
-69.45770397 80.28185608 31.85132358
-35.77027592 56.48377338 27.84031849
39.65765863 68.69547811 11.53275685
-8.007944014 59.67220691 26.31869792
-66.89515439 69.44043798 40.68490705
-60.92941989 54.84681455 45.81469293
-54.66624403 44.86421338 43.9727695
26.70075414 69.3742943 41.08337268
-8.39796135 30.48598066 21.43627797
-49.59397669 82.62778786 24.13646575
49.71406379 11.06152104 43.5681237
6.632367602 56.47449309 60.64810391
-73.30418135 10.53010305 20.7577573
-49.61042791 69.08957173 35.63507582
-9.534676799 50.42373959 29.09327764
-65.57490491 31.12636726 42.38611507
37.40621301 45.33210019 50.11563344
-57.20112051 85.84755347 11.51636617
-14.71205901 53.05632843 28.65282737
-65.00593863 54.40634382 45.87877438
-64.99374509 23.01409186 37.39560173
-53.26818885 57.45771349 43.17763392
60.47765427 64.85826468 52.10743926
6.372376421 59.69048612 23.36408558
-68.68238086 28.77048725 41.19429931
-74.52270504 34.0131816 39.71347066
35.90629231 46.10203933 53.11694405
-18.27773848 46.0647508 28.92318711
-69.11606876 37.1800396 42.70568047
-72.96101895 23.64520314 37.41524928
54.15339065 49.30156052 47.45458038
9.574194744 59.47530694 25.25143688
-15.53321919 80.60260944 23.6707013
-39.93547111 46.77651878 42.27064926
11.16513374 59.41610796 25.63526033
-11.51314726 81.23648238 -7.760978892
-34.15394632 46.77055045 29.58407874
-38.45553632 70.66148439 26.91314208
-16.71176722 55.6841403 27.66182998
-20.90000835 46.17341597 28.16180492
-55.76445315 81.39098585 28.01763182
-49.47286232 62.61525898 35.02105706
-56.38282518 43.21997585 45.90771862
-47.68421453 43.79427113 43.40864321
51.0339424 56.20314898 55.33041684
43.71926435 32.63129784 21.55264819
-9.654754251 68.44592918 23.83387797
-39.70478389 45.47561475 39.8485761
52.12977418 56.17070161 55.49810791
-68.07775028 26.85306845 40.0312256
-12.05921264 35.16311445 37.75861137
52.42126495 56.2714403 54.27307042
-6.971883785 30.00772849 20.88957568
-41.26277916 33.53268561 33.12190944
1.619534714 36.45300535 31.82927811
-71.3062469 57.1346032 44.54185923
-63.37714456 74.16798269 37.57297977
-49.47430172 55.54206395 41.38581097
-47.03088093 19.35074589 29.88364107
55.34577691 55.98553402 57.0343577
-68.74937285 58.08813625 44.63822221
-63.4369715 82.97695388 28.54967557
57.92957837 55.89627388 57.57780814
19.76932312 28.02102581 33.02632277
-52.92476165 25.39615772 36.5744196
-52.25177744 57.44055114 43.18303123
-16.58486244 47.5284533 23.26189945
-71.65393656 37.72356709 42.70664421
-72.39471801 53.18277613 44.01200268
-4.963846722 52.047174 32.68214562
14.6219892 44.88246757 59.6803867
-36.84068885 59.08881213 26.93646594
55.88719892 68.93682133 52.24913169
59.5132791 55.84072617 57.92062531
59.93667753 55.82536954 58.01815165
-39.51446754 63.27262545 31.31738193
-59.26901521 77.33897748 34.93815805
61.62745444 55.76536394 58.39229924
-62.36565853 69.88779817 40.45446238
-49.9879162 80.26587121 22.4972399
-70.71223779 53.13934465 44.195328
11.51391378 44.96363651 59.33084698
-9.551693905 47.40663864 29.16138735
60.05654914 51.04007541 55.28006876
-49.50177505 31.61633947 33.63291645
-17.33356707 51.63318339 28.19374822
-54.462559 86.13288636 13.50839485
24.55486547 47.08413256 55.53415656
28.59065763 47.03054249 55.3866194
-17.54747544 81.71872293 22.75145371
-20.8940329 49.41335748 31.33554648
-68.67566925 81.38735496 30.52202867
22.75692129 43.52162947 56.44668494
38.98780624 31.63493251 22.36821986
17.63524027 75.00021232 53.2487919
-61.6386348 81.80870296 30.11534963
-71.78299486 59.63839592 44.70012914
-69.23427784 59.59161166 44.75717935
-47.47347664 29.27143978 31.33420985
-49.72247726 83.94481939 20.52518958
-55.80036226 18.35954036 31.40938936
-68.89665788 49.12254439 43.8659077
57.85970532 51.51068789 56.0624479
-57.31805144 51.24403042 46.16324472
-20.89130457 50.41641325 31.34410454
-67.19941167 59.55625622 44.77954992
-65.26750039 59.52327972 44.79393814
-79.88232025 12.33786484 12.67971006
-65.00803521 59.51628416 44.82566614
-65.45006089 51.88390749 46.11254856
-10.58672146 46.42875968 29.05742416
-68.37220466 62.66559998 43.86752228
-51.91261977 61.03275688 42.20389212
-62.06046289 59.46652383 44.84120063
-61.37766357 27.22138597 40.30442121
-52.58237649 63.63391111 41.26872639
-49.51668589 26.8494794 30.70654126
-62.31258961 80.14646814 32.06038943
-61.09443221 59.44999773 44.84881985
-59.59552784 59.42483584 44.85506739
-71.59731882 9.099872614 19.55548417
-67.57154207 40.13277117 43.09351235
-58.55440803 59.4075985 44.85662327
36.46169874 21.99285789 35.72930988
-65.95884416 33.55856564 43.35780546
-55.67137674 59.43995101 43.93124018
-61.1691629 68.28553484 41.34660304
37.60289994 33.59906429 23.13781836
-60.0670166 39.32625449 45.19834803
-56.38436946 42.22871173 45.7620487
-61.70965885 36.41062749 44.39849038
-53.86796178 59.52134901 42.64239051
2.468613646 45.28092545 28.23930896
-53.35785127 59.51180698 42.65588029
-12.05599056 62.34217013 25.22900725
36.46156366 44.43917898 54.83482461
-49.5754547 63.04533752 35.87459748
-49.50780491 30.66450483 33.0303756
-63.21538715 29.64359691 41.66929847
-49.70255173 59.47670794 42.36624788
-47.99404546 60.02720529 35.64984942
-70.21874142 18.89984233 33.71348252
-47.46407515 60.02003409 35.63202945
-13.02916708 79.63586857 22.76251515
26.31546086 31.83291758 22.48655703
37.2637974 45.85660075 49.88064844
-40.18935595 59.06354362 45.34833785
-53.76474675 20.28311532 31.99748704
43.69723235 31.56364607 22.29764911
-39.66987946 49.09075004 32.83463507
14.92933001 70.43290206 54.34572806
2.792706591 56.53786009 25.68488891
-20.63792838 53.62400821 29.01952594
49.99172314 63.57314844 39.89253007
-65.33674437 63.13566603 43.65841285
-54.55295172 18.92629148 30.41888083
10.53608322 67.80883676 56.51240755
-1.922802641 61.01012797 50.41315098
-16.40988174 60.44824683 24.73880071
-16.86246455 48.07159175 22.83633313
-15.96779664 60.46063066 24.51073185
-15.02464307 60.46803363 24.24492762
-59.83832057 48.26316825 46.28805339
14.27962306 52.0529007 28.94582027
-37.16979325 54.18976882 31.43113104
-12.70491144 60.41286969 24.44292425
-72.79856466 31.77881487 42.01626313
-12.03474806 60.37214616 24.78786864
-14.2437575 54.57452463 28.41911256
-10.96462112 60.3278875 25.09757578
47.60089634 66.42303436 36.39812333
-45.04806455 18.88232723 23.28996889
-10.25227632 60.2906638 25.39384736
-49.30685581 42.54864787 40.69827131
-9.12894395 60.23195508 25.8611549
-37.77295708 56.65821946 32.02378604
-38.42723856 56.6413758 32.34409494
-70.00820059 9.98820915 20.59325291
33.72639836 73.60277445 48.92266672
0.7524978203 67.02519357 49.9953744
27.06742033 48.33257842 52.21540784
-59.76777204 69.3139948 40.7935173
51.56624517 54.62620848 56.05525712
51.53843006 50.79595634 53.9117964
-7.344881239 60.16205909 26.33232713
-51.08993956 51.82565347 44.05024209
55.14962995 64.54115848 50.97808466
25.42155385 73.50550846 51.63558549
-54.60356732 49.86714586 44.14953411
4.162159171 56.90955687 61.89529069
2.83765056 58.01223452 61.0004772
4.424236234 56.90124377 61.94181561
-48.23953959 34.53802528 34.43498481
20.08147367 31.94323715 34.04802931
-71.57166045 84.87039319 25.60050634
54.80033184 65.0213519 51.29690313
5.923812443 56.91290976 61.52041003
-43.84695844 32.54337072 33.44613544
-37.20929256 60.81283577 30.29960039
-66.23837823 36.01243132 44.05805508
-0.140961606 68.88342765 46.07394707
1.957975593 60.10277686 25.24640768
-55.72403761 49.27352768 45.42772691
-55.04950324 29.23441072 39.03550603
3.471337044 60.08271116 25.1907379
4.383572904 60.11809922 24.60595857
1.15139987 46.80780575 28.2452914
5.231246641 60.17366612 23.79924083
1.49072336 50.55045286 48.82625435
-70.74540514 83.69694275 27.41192208
-37.60231348 63.54694955 27.76816771
-60.5097764 58.92541383 45.00043436
27.01930053 44.06656741 55.13442702
50.67160832 56.83858541 53.84954697
-49.73393711 82.19617359 23.34700948
44.76533592 31.11730348 21.44878487
-26.94802158 53.5364002 31.23991666
54.53990803 56.54737326 56.49244252
29.68644452 44.54898227 54.85220381
55.2474302 56.51258228 56.76139292
-51.3010915 38.87455049 42.943702
-49.49235104 54.11885425 40.43098709
54.63062959 42.01833738 61.99284928
9.693209128 52.63868436 28.84691351
14.25091366 44.53414251 28.83491421
55.57136012 56.49784593 56.87068796
0.08217948869 38.66748357 29.72174483
56.31786883 56.4681664 57.07286589
-55.62172035 44.87541543 44.02494294
-72.14092966 74.87877467 36.81953499
-61.45699582 16.46616039 31.16131627
-14.16018669 30.27733179 30.78395967
34.35438284 19.98156227 36.17321156
58.95247527 56.37805601 57.6164979
19.82395464 28.45851668 33.76383188
4.159923715 57.02512801 19.76758987
-22.15994714 74.96931578 8.756867188
-67.72926488 57.56689318 44.66801056
59.45461948 56.36014358 57.72867661
-49.57865328 68.06911126 35.82198435
-39.96514056 56.66236078 44.04705678
60.4401246 56.32140484 57.99044239
-8.715338599 70.68135251 26.83750524
-11.11504401 35.98194459 33.89961514
-8.787339472 57.0587286 27.67329259
-55.70401285 72.60648312 36.75677135
-72.01161781 78.67594662 33.50101686
-72.22174668 9.611482019 19.56207185
-71.02070569 70.04152226 40.32049035
-10.13199555 82.64108962 -6.850155935
-39.86311853 57.5905509 44.90577421
-49.67616653 69.22184439 34.11209473
57.14701068 44.49894481 61.84946335
-21.92195023 42.93229342 31.02216647
13.18859119 31.77794405 37.28138688
-49.76555459 55.36687169 43.4751146
-23.37546417 44.93103369 31.40308814
-78.12628705 9.03342232 15.74535959
3.475457245 44.76637347 28.19395182
-37.68401793 63.33372713 24.43234928
-67.7726712 81.92961897 29.88146593
52.00440721 41.80022532 59.19904835
18.72359312 73.91754987 53.95629067
-43.67539161 48.5772939 33.73244308
-56.94515739 19.1485625 34.1214461
-71.79033855 21.71480209 36.29493392
63.43955277 51.92376492 56.02967884
-40.31514592 67.94868602 29.62672017
58.93855254 8.568786485 53.26651921
-55.6708308 84.96984248 15.58700025
39.01890433 19.83381613 36.99880447
-71.90343211 79.77447251 32.38117653
-30.95045713 50.05765166 31.60057081
-58.28684991 15.05764424 29.42791083
-46.59896904 43.64900463 39.06139831
16.73953046 49.89338441 30.2393789
-61.20108819 40.82269502 45.52277117
-20.88865221 48.62921189 28.78417255
-57.17031094 35.84858585 44.23075816
-9.302866275 70.80428049 25.52251239
39.47136875 49.0018594 53.73368044
-25.88977569 51.48927073 31.49611217
-33.80112346 47.24345534 29.85360914
59.86744593 66.30105895 52.95472358
-37.88936227 64.4169654 23.54977092
-59.8109825 28.15407788 40.8315593
-50.47718529 24.88578799 36.20575547
-64.30408767 17.88515985 32.71141537
-68.16767583 22.12404887 36.67987614
-64.37530951 15.15556944 29.45222941
44.79474307 45.71814764 44.2250749
24.40193303 34.80797222 23.27471817
-68.74798556 60.09630054 44.63228453
-2.977011295 47.81343726 29.01178155
-68.23808284 60.08686333 44.64459711
-7.143967187 68.27627015 31.15122367
-67.73223217 60.07947013 44.63395401
-62.29022574 33.98179726 43.5716373
41.91364111 52.0096774 53.31082321
-61.99648797 72.53476569 38.78933275
-64.73854969 29.66860669 41.669443
-64.67745667 60.02518785 44.68153833
-49.48446571 33.4678049 35.44301731
-49.50221334 64.86858856 38.00156512
-62.13736322 59.98275367 44.68973415
-4.396506217 60.13187066 43.60042908
-37.95605774 50.87265774 29.3020361
-55.24300817 70.51373656 37.65642102
-61.1472966 59.96649179 44.68970234
-52.66960534 43.34149336 43.78892106
-60.60921355 59.95522819 44.71784276
-58.55375453 59.9218738 44.71305652
-11.91125584 71.35194525 19.66225476
-6.082345646 60.5114977 39.5149404
-57.05565248 59.89711663 44.7147587
-16.83791135 50.17384057 27.56041739
-45.39345305 19.83798666 23.91512635
-55.73774594 59.9013128 44.41471373
-41.3887292 31.58735585 32.4221727
-12.95195652 32.47626551 34.15992047
-49.47395855 64.59989821 35.28868913
-51.81374112 84.4860623 20.46750432
61.54842377 38.55644042 60.07532438
18.09020405 33.28980381 36.27577088
-41.88632032 18.79220447 17.90657433
-55.69557594 59.9543741 43.79069735
-52.37667708 72.0611614 36.62608003
49.27691086 45.65092818 55.80391484
-28.40509232 48.50591032 31.64897777
11.2065584 32.79108022 37.5514301
-14.85830412 28.78261163 36.61561639
2.228996857 58.14581933 59.56580092
59.95459521 49.57819812 48.96348584
-13.68446469 31.94045192 28.8664467
-53.89226487 60.03582451 42.50125907
-49.30008133 57.45638523 36.60966948
-53.38180803 60.02610777 42.51671088
19.26162441 29.75327 36.32055319
-52.87883188 60.0201646 42.48978298
-59.54857523 46.25681189 46.21748248
-65.19765602 21.61030479 36.25075072
-16.18690573 72.38229428 20.16988518
46.52467046 29.71428937 19.92055799
-36.75109524 39.95940851 27.57510429
5.725669404 62.98989779 60.93177556
-49.75342266 85.31175152 16.31602637
54.93935991 38.66920113 60.02670387
-39.19104604 61.27405932 31.14995419
62.61238462 55.76408202 58.21934862
-52.70504831 55.91477286 43.50189787
-70.06496511 78.08376222 34.17764649
-21.36728283 53.44681159 31.21564625
-58.64687637 52.27455183 46.10689676
-57.86217388 9.807942181 20.36960325
-46.4894782 70.85544651 32.0202509
-69.94597247 47.14744034 43.68790393
-69.91438535 49.14037061 43.8530547
-19.79356209 24.87575513 36.29731403
52.40420288 57.91058445 52.72786514
-46.56463293 47.45118946 41.52944138
-54.34899056 79.74021128 29.43116816
-35.53502243 50.36328747 28.92683103
-55.29113865 71.04023156 37.38030238
-49.62746783 60.26316069 39.04885184
-49.6919396 60.34523859 38.10832666
1.141490569 37.02292139 31.13108347
-47.6563598 49.31032633 37.63534468
-36.52334502 53.40435467 28.7722279
-72.43891035 50.17938347 43.92615894
-71.58994943 40.70458552 43.04844938
19.85793592 34.88253202 23.27574122
42.44830823 14.54361788 39.49087024
-48.97178656 60.56424356 35.42861922
-55.86315397 33.89889615 43.30833266
-41.45581631 59.56038977 45.6487477
-41.30238834 59.55732799 45.65503134
-49.79275515 13.05538162 10.26527251
13.16021775 31.29004074 37.12409455
-57.76805854 63.52421698 43.5310769
-39.56526515 59.59783271 44.85354312
54.47305624 57.63508379 55.53153555
-40.55348126 60.61666422 33.21466021
14.95981696 43.94301102 58.86857628
-74.56136679 12.44219613 22.10710188
-49.48294677 50.82159887 37.91965388
5.509016517 45.23389331 28.20546931
-18.71191275 60.94714217 25.21291156
-47.97164662 42.79189442 37.61985492
-74.6238668 41.25056214 43.11558148
0.9533460299 59.89951212 56.93085167
-60.40135325 53.82378266 45.93680651
-16.402288 60.94663226 24.77837163
-15.42972175 60.9515872 24.53537678
52.8934021 52.16421396 55.24958491
-8.611944988 37.05505644 26.79143334
-72.15021941 38.22834992 42.76804176
-44.62616284 24.13193441 26.36128858
-63.44885585 53.8743962 45.93042469
-11.1109648 60.80481384 25.41561634
-8.142594315 60.10033786 33.02760289
-7.401324222 59.92463378 34.92593972
-12.37574438 62.65879308 27.44103718
-6.476901158 59.70609557 37.28659811
18.57269489 34.47207129 34.11241465
-8.776435961 79.94176696 -4.906165935
-60.75838207 32.9851431 43.1961125
-5.921554664 59.57462324 38.70691719
-61.17373419 18.29496897 33.18371581
-72.79959743 13.95879747 27.47187115
-37.90165947 66.35769915 24.32922605
-5.549880633 59.48624709 39.66197168
-24.36766129 50.96257366 31.49347896
52.19250054 69.11568401 50.87736399
-4.80861054 59.31054301 41.56030851
-48.16129611 23.69092313 26.32839962
-13.10202144 36.70024079 37.59331949
31.53128105 34.19071037 23.25409389
-2.963920352 58.87556302 46.25808102
-49.4993235 48.90198836 42.72709482
-2.596402261 58.7892833 47.18959122
-2.040709438 58.65763626 48.61187233
6.164775443 64.52282138 60.53266217
-1.110745121 58.43630282 51.0039231
-49.71676807 52.83118165 43.76874686
4.401333292 54.90393285 61.82586258
-11.17769117 35.43699679 34.41107333
59.73674626 66.79487167 53.07375128
22.32208699 48.56398613 44.60737969
-42.77135288 40.32119306 36.17662762
0.3721413919 58.08471996 54.80255878
16.15227517 33.27626012 36.80257067
51.41092282 63.91123391 47.3504203
43.81821107 49.54135749 52.46846462
0.4261709665 68.19423821 48.13976496
23.88589658 28.32979095 34.48353747
-7.279522223 61.27072853 36.75618401
-49.61939461 63.56971863 35.62221932
1.480410083 57.82299821 57.62946278
1.663736219 57.78007673 58.09276536
-49.49307306 32.11416585 33.67876584
1.849140324 57.73610704 58.56784007
-52.0484925 20.73725178 32.22488044
-72.92482884 51.18115411 44.04282361
2.406391624 57.60367384 59.99895028
-55.19891882 43.88070571 43.83834677
-55.21722326 42.88550717 43.74154671
-36.30296054 33.24743951 23.83410385
3.318957473 57.46545046 61.42951665
-73.43723427 50.68963778 44.01978186
-56.43465878 57.32056046 45.37367154
56.50315352 70.50442456 51.41370561
4.34642604 57.40554365 61.92901428
-63.03028381 80.16161245 32.02145185
4.884572583 57.39424802 61.9575144
-61.43976842 22.95577066 37.3948721
6.267860405 57.46457462 60.87731041
-11.97199755 78.22777629 21.42714655
-48.02150563 37.4413362 35.649498
5.548381966 58.01055599 19.71651708
21.33138403 35.88703228 22.98705535
1.925291355 60.62148015 25.05779528
61.74947303 7.441518685 59.98996731
-48.60201117 26.9331929 29.56029968
-13.92550362 29.96072522 34.41460481
-67.77816034 43.12640475 43.34047778
37.37160801 68.41153988 15.26488328
12.0766662 44.92753975 59.64256432
48.93070376 64.13980675 39.34333043
8.630210525 53.16917429 28.71796036
-12.30779839 38.82214235 30.28919606
-41.04400588 21.93237454 22.07904091
-66.39631361 80.22423699 31.93638255
4.893935001 60.6106463 24.61742438
-54.4530487 36.96899109 42.35935968
11.25275695 35.35318948 36.93299003
-73.43874698 53.20148097 43.9939658
6.753567899 62.57622521 59.7113213
-72.45984354 17.10470107 31.67362005
60.41204797 52.36739535 57.28369741
-56.46384349 16.38802905 31.1160978
-24.35623642 46.93908229 31.58579727
-52.22492582 20.24970237 32.09171584
-31.83815796 85.45088315 40.41754477
-49.54879462 62.1827831 40.05603421
-21.09953844 58.10425101 29.53739776
-53.9186425 36.95958224 42.36666995
50.9290378 57.3100549 54.15393254
33.63312542 44.12485002 53.19654359
-37.04693602 79.30146944 42.87808193
14.23379665 53.58266548 28.6758562
-20.01667538 57.70001883 28.19687026
51.03253526 57.30740227 54.16498872
-12.54959937 34.1334971 38.15139896
-66.27449684 33.56912661 43.29540278
12.23626542 47.35811151 60.52948155
8.195241381 65.19012446 58.22553957
53.65811958 57.12273158 55.80806637
-15.97541849 59.97464833 24.32718198
54.08847921 57.10386344 55.94502938
-40.07579721 60.29470837 31.03442023
56.20664737 57.00170922 56.72696289
56.78528622 56.98237655 56.8410408
7.074769322 65.06155641 59.9317323
-61.78561302 28.66126402 41.14698722
-61.71117523 61.52219366 44.21743729
-54.19596961 33.06936024 40.96696669
43.67233724 43.84278739 54.55639476
59.01568082 56.89794428 57.39584257
-60.383102 80.66648597 31.48231889
60.13319852 56.85455765 57.686389
-7.618248219 59.14685739 26.51638039
-67.29671569 55.06061827 44.54698468
-69.2793208 56.60103703 44.52269686
-49.68768978 81.03786429 19.30476624
5.308192836 50.94908259 60.95057981
-32.85441515 47.18712052 30.32704197
28.98192432 35.24224938 23.18652106
-49.48155308 52.20365464 39.35539895
29.97485269 33.22806874 23.07267895
-22.71980028 22.05012463 34.69750068
-51.09520872 84.39616161 21.37410889
-62.94463287 33.02252957 43.17903423
40.74823155 72.09726007 47.58074788
-53.45594959 76.40031806 33.07293313
51.53658758 68.14478701 50.62004975
10.24923119 67.74292489 57.33226526
-8.823246907 30.95494954 21.89989033
-5.041532459 61.13956819 43.67863291
59.8581591 48.62241238 48.4240533
-72.21734022 70.60225682 39.86592048
-20.35671321 57.12155324 29.15032756
57.42996615 69.93113205 52.0655139
-66.62978984 81.34644399 30.60679159
-61.12648095 81.79813019 30.14041535
-50.53845992 24.41448139 35.86207092
-64.39777863 17.43020537 32.18408543
2.57833992 36.36579051 32.65888187
-50.87036816 68.31852935 38.99953629
-43.3011371 40.30349254 36.48314127
26.71607746 38.40741707 21.83490032
-68.2460962 60.60551055 44.45193072
-36.58840454 42.89063683 28.47608613
27.83721154 73.52584476 50.9388263
-61.25470211 35.42447522 44.10642082
40.06304997 43.09802244 52.23720037
-55.80541947 86.31472295 11.65359427
-14.10246833 30.19866058 31.68622539
48.55172796 43.56187458 56.88690695
-36.40273074 69.24670971 25.46547391
-2.415809391 51.45561982 39.06341339
-37.64731619 63.16654615 26.36610922
33.99158298 46.541832 54.20329742
-65.21623401 60.55140663 44.50219561
-47.07590824 48.80025215 37.6193215
54.21270903 43.56235668 61.62836902
35.58671003 34.12721469 23.21780346
-10.07247705 70.01462657 23.18287862
-3.395268416 60.40157784 46.10517056
-7.278363052 30.93137158 21.87898192
-46.89422625 9.780746083 1.113736218
-56.57022602 60.40224226 44.58495883
-38.87189501 54.60903603 32.71521335
-55.93295654 60.40288962 44.45591278
-49.91774498 60.49058084 42.29079174
-49.62996669 31.58855209 39.80658142
40.84373834 30.22035743 20.95577947
-14.00464516 34.6139731 38.6778098
15.59215649 42.54248067 28.39323177
-49.58319419 60.52004259 41.88497733
-2.979148566 37.01354138 26.19916233
-48.85919181 57.45698324 36.51864709
-49.62413393 60.76369496 39.06428987
-3.511362697 69.04070973 39.06421563
-13.38349897 71.85348521 19.94741934
7.39350443 66.58086646 59.7136104
-41.29420584 60.05541643 45.69793771
-52.11243091 64.14684593 41.05122407
-49.62735126 84.86317027 9.846159538
59.72048824 70.34336291 52.66986193
-70.08961161 65.29967469 42.74998472
27.43623222 69.95816546 45.81839263
-7.020752678 46.86660519 29.12118315
-41.05720874 61.54162491 45.87967101
-46.56657699 47.02428766 40.65895871
-73.03674261 44.70879967 43.45364854
-40.63309751 61.08470838 33.62308637
-40.21295581 61.14532814 32.83924426
9.737428705 70.72944979 57.7199538
-39.79578676 61.20744743 32.03856145
-53.71423509 81.99876944 26.39768813
-4.443262435 52.43545318 33.90208016
-72.39294144 52.667275 44.16933629
-71.61768315 39.21192747 42.90167968
-74.8592745 29.56390534 38.98833427
-39.53031555 61.24697788 31.52903603
-37.98003825 61.25784603 31.10722342
-72.4823551 9.166393961 18.95203746
-40.228406 58.31327068 30.7589349
-71.96642222 47.67773958 43.74375614
-67.24916074 60.58578374 44.49081237
-37.60247957 61.68683636 26.055187
-56.38754266 11.06628312 22.96056537
0.8370653104 58.95373681 56.27899519
-44.08672377 49.61577372 33.40873676
6.322329025 61.52916019 60.29537433
-18.36285897 61.47150405 24.88581043
-70.45138219 69.49989475 40.67288353
49.13995677 52.85014918 53.82919346
-49.75906726 17.4791977 28.8236561
-48.90184087 79.25203225 16.57957768
27.47548772 44.0842265 54.84242976
-17.88686498 61.45852356 24.94572244
-17.66901085 61.49316653 24.50201544
-50.13474145 43.30169719 43.76749033
-15.72190899 49.12012735 22.09993761
41.10245012 32.56376598 22.83564963
-61.44007052 32.0272483 42.79277467
-15.42153925 61.44967564 24.57828315
3.79390589 51.31650511 56.974054
-14.86262881 61.45268371 24.43677567
-55.75657637 38.94162787 43.01470647
-11.94426578 61.38049096 24.71829255
24.00921391 69.69800619 43.66542294
-55.80664386 53.88469758 44.35342279
24.19531067 69.65368709 44.1444217
-11.65354448 61.35772853 24.92709315
-11.39653246 61.35197044 24.94492348
-73.71513049 9.720581603 18.58036135
-11.1027823 61.30290228 25.4585227
-51.65856704 77.46441325 32.03064957
-39.11694051 70.56909912 45.59167715
-10.36410967 61.12850847 27.34214435
-63.09558327 75.7803783 36.28134193
-10.17974455 61.08506288 27.811333
-9.994340446 61.04109318 28.28640771
-9.435703832 60.90796118 29.72536602
-9.249433906 60.86355473 30.20534579
-67.91013422 15.20422961 29.56145894
-71.92765695 50.16926492 43.94612341
-71.01541196 13.02068571 26.36862027
-29.87637146 46.04322357 31.38502014
-9.063510308 60.81932298 30.68336354
52.12344915 43.32115734 59.00018009
-8.877413547 60.77500388 31.1623623
-49.07312399 61.08041154 35.28253109
-16.70504321 56.87674438 19.64256878
-8.691489949 60.73077213 31.64038005
-8.317881092 30.94780093 21.88650039
-8.505739516 60.68662774 32.11741678
-49.5118244 28.30411943 31.29901383
21.21421789 46.53262583 56.74689
-7.208083943 60.37892722 35.44195877
-61.52608894 31.54364562 42.59655081
-39.81050727 70.65120717 27.29084876
-6.838141554 60.29142459 36.38720314
-42.14254283 45.56553864 39.26956915
-37.50605208 68.61966304 27.12845866
-6.653430105 60.24780429 36.8583538
-4.985658979 59.85251379 41.12912117
-4.800428038 59.80863145 41.60321487
-4.615543425 59.76492381 42.07534655
2.288243061 64.43985967 56.40837871
-16.84321829 34.72025013 37.98540133
2.877702894 64.29117918 58.0219571
-2.955737851 59.37365145 46.30098738
2.681389447 64.34065199 57.48507865
-2.771892223 59.33046792 46.76723299
26.82694266 25.25763765 34.62661463
-39.93431112 44.8909588 40.85282055
-16.2650338 26.26738669 19.46932512
-16.4569334 58.63656249 22.47256383
1.730634517 65.12424481 54.39651646
14.2657803 45.0288592 28.91568762
-51.22829966 85.69073085 18.02446682
-1.474236651 59.0227674 50.09177497
30.62851213 21.70757548 34.32685882
-50.23489202 26.77242947 37.56460128
33.26367821 21.51807137 36.02421911
-1.102562619 58.93439126 51.04682946
-39.23437094 59.23749436 31.49358535
-72.85838718 69.57430508 40.26810426
-59.11451075 30.05939172 41.88702812
-57.31661913 46.22114766 46.20585154
-54.39957668 22.1290454 33.99621319
-26.37443504 47.46824596 31.65440263
54.56155677 43.03283473 61.88227809
8.655702923 57.48376626 25.23929293
0.5653816706 58.5390134 55.31857783
-48.16114718 35.502913 34.87219592
23.55865711 68.9208534 41.11982147
-11.76695962 61.79326651 25.71932034
5.45062729 57.90825718 61.70921175
-42.76228291 40.81883382 36.22456167
-56.98579089 58.35522752 45.1208872
-69.52949677 42.16297128 43.2054251
-69.04718925 40.66080614 43.07175279
-74.63400211 56.37171125 42.37940642
4.383071719 61.12278408 24.59622075
-19.51214505 49.08128568 29.10030275
2.555303863 60.08919226 60.25004457
11.12213262 68.31275979 56.37737906
57.43077448 54.89568034 57.6352713
6.607764958 61.06600568 24.83108362
56.76483727 48.69613901 48.15809578
-61.91932163 44.30406781 46.03191283
-54.65307587 79.7449372 29.43429706
-16.74536453 30.25005589 37.42024904
6.915443423 61.05946722 24.84831071
-39.58962407 68.04868135 45.80746525
2.503549861 47.78677125 28.27616406
-67.22771089 67.3391615 41.83491519
-55.8048304 14.23829572 26.81288823
-36.5625706 44.37186428 28.75591286
-18.40110889 57.87617528 25.84381908
-60.84886314 66.70403697 42.16477883
57.61442282 8.486737031 48.64488699
-38.33606939 46.04884982 32.93302193
-57.63068807 68.22264447 41.40187196
-71.88632885 52.65949746 44.16300964
42.58390558 32.05757894 22.60267488
-44.23815521 54.16920938 38.84442112
-1.688046103 51.28672912 40.88523131
-40.20186756 58.56763896 45.2809062
-8.271141772 76.29525303 -3.457619584
-36.01258216 56.99671823 27.75853051
-49.6130798 83.62853842 12.52266407
-49.62192249 66.98739455 36.73431144
29.41477752 41.07046966 42.84535542
-49.6349568 78.72984312 22.78134087
-49.64488186 78.78440543 22.14983438
-3.327591472 51.6677814 36.77436885
-12.56240147 44.97716684 28.80542455
-46.72958586 46.80918174 43.18715235
43.81158623 13.45855015 40.17386137
-71.91916001 62.21816331 43.91147838
-61.06864639 71.98573199 39.1593308
-21.97715939 56.01775982 30.61971436
27.21586883 69.26311653 42.27577004
51.36750569 58.0866469 50.8817078
-40.44751855 49.21046657 31.59317851
-73.11276564 40.72942059 43.05055593
-41.67252801 23.61406666 25.98315856
-46.6300188 62.09133425 34.73436938
51.70131147 57.96781734 52.19750954
45.96839241 64.24655775 38.66901733
55.40037243 57.03675942 56.47383603
-66.31906674 9.105055641 18.4887213
43.59529761 33.02260277 22.86037723
-59.09136546 72.48164093 38.85201948
-68.3497371 62.1520504 43.99825499
6.073074547 58.4681532 60.9174665
-27.91242958 46.99852158 31.57396995
-7.527396416 78.95149255 -5.301819119
30.09392786 36.25563207 22.86365122
-52.89796021 30.15701968 39.5681576
57.55966344 57.49041074 56.6223716
-56.06277618 89.27107748 0.6897145857
-65.32282867 80.20306227 31.97747407
-62.56049092 10.72938968 22.22204752
45.64319023 64.76415191 38.54906541
-60.22212507 69.32018834 40.80826603
-0.4269084664 68.37678778 46.18328339
-20.29522461 74.57378637 12.99284823
-20.73353739 58.7191754 28.15574865
-53.22829575 32.57456708 40.69970471
59.37214314 57.41856899 57.11071239
61.52015476 57.32613836 57.77407601
-37.50725517 68.76893705 25.39580612
13.22122948 53.59788864 28.69223734
52.46092718 50.44784144 51.95040121
-70.17687684 71.61806568 39.33782302
-59.09204249 59.93324076 44.68375523
51.28366341 44.30321024 59.41322731
-74.56291821 44.73532935 43.43672346
44.06616967 67.51231495 24.42705086
-49.31117211 56.43276997 36.84137582
-19.22598841 51.59668384 28.97835788
10.58992032 68.23433945 57.38923581
-49.52908485 23.08578429 27.78749786
26.08260676 49.08379609 49.50917517
-58.12848272 18.24549099 33.17734449
51.91676781 44.98161963 57.24365973
-15.15813215 49.03395812 22.99273744
-51.16692935 81.95269709 26.44674309
-65.99888053 44.88195164 45.92805284
-56.71517841 69.26081314 40.82874088
-68.75925923 8.340442185 16.17698952
-7.477374863 62.15196485 38.21716344
23.06682036 48.38653502 46.52533676
41.04801301 68.52963729 48.15266045
-57.78099924 15.05025104 29.41726773
-68.77575876 67.8913294 41.54681008
-55.71278927 77.97996052 32.64560611
-71.42531335 49.6614248 43.91906126
-69.43686394 15.23103882 29.54139462
-55.91224011 10.24331257 20.77028292
-2.645054916 52.47658125 38.90834815
56.96248281 65.42400483 52.03691991
-63.63343745 36.92932863 44.57054601
2.063985581 44.73504181 28.8268489
-60.96984842 65.66152342 42.63682788
2.676282495 50.27452051 51.80338567
-30.5549353 55.67984482 30.35167072
-55.12739073 76.97756378 32.51723815
16.08332293 36.61501391 27.19023254
-65.70396568 38.92959294 45.05144394
-55.65779985 73.68671487 35.86112145
-55.53877232 73.72578393 35.38487986
-52.54595191 63.11332103 41.47852864
-66.26006547 61.08461679 44.33800865
26.08994879 70.7497151 48.53953319
-55.82916298 89.90988695 4.88267909
-63.4715647 11.1708536 23.09760045
58.28524209 43.41589943 62.55189528
-64.2212858 61.04728722 44.38255008
-64.23238732 29.66105626 41.66056572
-13.71972642 32.05735079 27.51612368
-16.24706382 30.2464712 37.36683382
-62.70304112 61.02475822 44.35454483
-52.46271558 29.67063811 39.30478522
-23.41862507 53.99269426 31.09648597
-59.11766326 60.9629859 44.38788987
-64.60870333 58.48385778 45.08138835
-37.5070273 68.7192664 25.97237595
-69.41186858 48.63244314 43.82697356
-50.51458366 63.07973009 41.48108277
-52.42525848 61.04357424 42.17607957
-36.31032003 66.57085456 21.55128408
-49.62654643 79.1782609 23.40081707
52.4419834 45.59056327 55.9010764
15.8984987 71.36966936 54.93950523
-67.85477303 50.09696425 44.00871896
-15.95914981 24.89897145 35.29655657
-58.83815393 46.24533633 46.21521757
-18.36578505 60.44377018 25.16377148
-49.48333744 54.26297455 38.75621425
-1.962089093 47.79702613 29.00874216
-44.35760888 50.03163457 34.45942095
-19.82554524 44.266773 26.78403767
46.08808682 67.43579372 48.23634088
-36.40487457 64.38668694 23.61816469
-60.89479754 61.50682507 44.24015892
6.299499486 60.04641719 60.03256816
-47.01451353 43.79094762 43.31950885
-52.75298464 70.99877572 37.37751087
-39.76819274 60.5559626 45.42285348
-65.59152738 71.535035 39.42725012
-60.90015245 9.853751408 20.41717707
-38.10149234 61.77177275 30.99098989
-71.61997517 80.88369584 31.10372721
-16.92242373 61.96566347 24.70118839
-63.79047139 9.068421576 18.43177795
-71.16226902 36.71889639 42.62254913
-55.90253835 7.781695231 2.731553299
-46.89727242 70.26160429 33.16513284
13.23451933 53.08896867 28.77097643
-72.03124333 73.26724062 38.02655801
-12.9310912 40.53077548 16.39963999
-15.84451719 61.99181404 24.19205023
38.12737225 44.26122021 50.75637866
4.974919514 34.46787898 36.75425983
-67.68674941 72.10305243 39.05950148
-37.84390638 66.63255932 26.95407604
7.866352449 50.34576337 61.63985247
-48.4581522 13.76971322 1.718271783
-12.92372333 61.930784 24.34351852
23.89506985 49.518277 44.88257935
-5.473793593 68.98331121 34.27814518
43.45076234 49.98115112 53.25973489
-74.43791288 53.72454893 43.938999
-53.04191474 46.83420184 44.10049988
0.3001949115 66.55291477 49.7375473
-56.49011554 89.70484456 13.21565062
-40.23191658 69.6067716 45.32242811
-9.813230716 61.35283864 30.4595414
9.141651512 43.62924556 28.66070357
-70.91791412 20.76584061 35.49151289
9.02248661 66.67592192 58.29948036
-7.502027244 60.83119742 36.07437398
18.49122051 32.78352153 36.2499479
-36.80564118 68.80480103 19.01902699
24.89622122 72.5182971 51.54275766
-53.41965351 75.85501495 33.56964688
34.75005184 42.09101647 47.46055784
-36.41776557 36.57308549 26.03595884
10.17907927 71.25665777 57.34215417
51.50321068 68.6638397 50.42751331
5.616510153 57.52352904 19.53064806
-49.58517594 33.52526619 40.6218125
-67.43120164 70.5046624 40.08612246
-5.904387599 60.4712841 39.94783161
11.74505754 33.28182276 37.57847851
-28.43427331 76.77821915 -5.219012916
-67.23565322 36.14382975 42.72287231
35.25743218 21.45389889 36.38895932
53.02178514 66.58272453 50.99045226
-3.60080335 59.95348632 45.51949968
-60.15917269 60.98041979 44.38412671
-46.24086478 55.36622075 36.98384977
-60.17155563 29.59483522 41.65488285
-50.5891198 51.31858205 44.01454704
57.58425724 49.13189609 48.76988224
-50.53280602 21.1438957 33.04185155
-16.86427234 50.63979845 27.98290643
-0.938012888 59.35360168 51.97558939
-13.68922298 44.49825282 28.75325878
-40.67580977 46.35042556 41.53159632
2.688308061 57.16707256 24.22708143
56.1262904 7.489644134 48.85038307
-66.01509995 65.22655292 42.82180178
-59.01286272 67.19688 41.92000184
-49.91257244 10.09586727 3.857837555
14.67756198 31.26796597 37.09098803
-50.20240927 56.89566428 43.29100798
13.80967844 73.91854372 54.88186646
-54.35017609 22.59395282 34.4164485
0.3052691403 59.0733294 54.9920937
37.97769653 69.09397511 48.01358389
-16.87226545 48.78363353 26.225584
-44.19495062 45.25619389 37.42543403
-72.57535648 27.8962699 40.43198425
-49.35191164 59.51617251 36.01460329
35.91863391 42.81569117 50.47843171
3.609477739 58.44722149 61.63027985
-5.197346675 52.61762124 31.93114846
-58.81108303 17.33761188 32.19356182
-49.53469945 35.2046747 38.59630906
18.2879681 35.93558433 23.00382539
6.458939787 35.96926224 36.52201828
-38.58435691 69.0539616 45.59900977
-63.81643713 20.65246514 35.45336379
53.2354629 50.55269107 50.58552138
-40.61025031 61.03694548 45.82647953
-51.14914141 54.86881345 43.69415554
-44.86096036 52.64795862 33.31641842
-16.37792255 36.26474042 37.44701871
55.82750092 58.12306466 55.4350376
56.46467431 39.07098833 60.89821923
59.29090368 57.96176552 56.64702431
-42.07907643 19.57175847 20.54698455
61.66918348 57.85228963 57.46434825
-49.56896606 72.58416885 35.84582128
-11.36976296 37.18920085 31.58676131
-55.83499401 89.16283379 7.729488984
-62.87579913 33.99223907 43.5620935
-16.28434903 27.25198324 37.17630065
52.04366916 8.84384515 45.56169097
35.50070264 48.01816518 54.25706898
-64.92430732 42.86375279 45.84528687
-19.21113291 49.58032601 29.07631445
-41.6325281 24.07132725 26.49395673
-71.88744549 61.69994919 44.09459711
-19.06147275 48.0725168 29.07160041
-0.8117430986 65.76249971 47.47203325
-68.85484492 51.61253235 44.08554077
-67.30441643 61.61808901 44.17087865
-16.6302585 49.81465451 25.86386952
-66.15497796 10.78336811 22.28091672
-45.34216837 57.41730276 36.30856956
-49.47258426 39.24062517 38.34533191
-68.14504055 75.33514042 36.58630905
-47.54816959 18.86169285 29.83293479
-8.389393179 61.0310425 33.9236521
-71.2726857 59.12653766 44.71819353
-63.74354783 61.55629124 44.20919329
-63.23548065 61.54777995 44.21110714
-46.36209473 85.88058978 9.065788936
-18.83500616 74.76063209 16.37197578
-4.098961888 60.55832674 44.41971327
-49.92860341 12.11947698 9.502561706
-26.67899152 75.43346406 -1.59613839
-38.44326663 68.56729636 45.39502732
-50.25230471 82.51387402 25.58440802
1.0143307 58.91387262 56.70796235
-70.92413964 86.04374638 23.50916154
51.4466527 63.38433533 41.80694691
-67.07209188 65.76640177 42.58305793
-61.67141524 21.55358578 36.23671117
-54.93163939 22.13727209 34.00217928
0.8487110952 59.45007823 56.34152178
-55.76371218 81.96716166 27.15546195
-8.143266824 80.54581013 -6.212448389
-17.34060669 51.13451843 28.15731654
28.97819114 33.73748447 23.17571958
-59.91437032 14.63308037 28.84029509
-8.057480909 68.48930525 28.85236902
-17.31431641 55.13898474 28.27866258
-67.5682341 46.61035511 43.6426914
-66.69158567 60.06247154 44.63282845
-16.45197592 59.51460451 23.93194783
-55.15634555 54.93276041 43.71601047
2.683050026 36.86474218 32.67335868
1.50561112 39.04633104 30.87899104
-19.02636968 43.51008731 29.58914942
-69.50373491 74.2851426 37.38127957
-0.7435585111 30.84368137 21.65072736
-61.26651092 73.59219102 38.02803845
-37.36129972 43.88850311 28.69280366
12.6986736 33.78616405 37.36849729
-37.45206467 71.67525826 44.08635434
-72.51075536 74.89188488 36.73787006
-66.94630996 54.02809438 44.81316929
-49.51086345 29.21561426 38.19745715
-49.49648813 61.60462584 40.93108976
-56.87427157 16.84950321 31.66388096
-63.31414366 28.68898168 41.11672038
-71.24568355 85.45595169 24.56739089
-49.14725458 62.12223809 34.8556671
-48.21438462 21.96327653 28.9143843
-52.50205056 18.88284733 30.53209308
-11.03125085 36.11312088 32.36084494
-44.29920425 50.57872247 33.92392797
-9.37314908 58.13566626 26.93641339
23.74322493 37.415942 22.25832553
6.737715794 37.17995825 34.06749261
-57.34653258 80.60899865 31.57058163
-48.13946674 62.10942582 34.81221003
2.073041358 38.50565857 31.22065849
-5.505114474 67.89556728 35.25816208
-24.94164031 55.54905566 30.79947777
-60.20876926 15.53824072 30.0419587
8.139371959 42.23174256 27.59515615
-41.44217814 61.06015819 45.71566305
-40.17489152 46.29596477 42.0682889
-50.24327242 17.47567201 28.95692566
-22.45278769 75.52014113 8.24497184
27.84826768 68.48478947 39.53727416
-9.085302385 52.4413105 28.89270727
-58.57634398 81.75062965 30.20550985
-73.4455553 50.19161922 43.97609069
-39.3794567 62.22486628 31.80150603
-49.81943293 84.9915009 14.21967665
-36.72309908 78.73500826 43.56557838
-47.65566768 42.83072458 37.108827
-76.10558275 10.84062982 17.6871794
-12.60759943 69.5291596 17.64871385
-12.23031782 70.40934968 19.01215721
-38.65567992 62.28098722 31.01198439
40.56580475 69.01493139 48.43761336
-13.8012164 45.0569629 28.11534302
-38.17556144 62.28657439 30.85556295
-44.52257882 19.91901707 22.80838512
-50.56807418 48.79688565 44.15102122
40.64725096 33.55004054 23.12634578
-15.71890261 53.57088041 28.69818451
62.07587456 5.819776055 61.27417013
-37.79902123 62.29485497 30.68762752
-62.92744664 44.81926619 46.07001049
41.10930104 71.54918404 48.04771548
53.04765979 42.67484382 60.50016593
-48.20275742 53.87050621 37.24141776
-10.7950283 81.07431458 17.29120129
-35.8566653 62.7769447 24.72075816
4.438024521 54.8206928 27.82532566
-60.11598649 76.26623526 35.89957358
-57.96441656 83.44415362 27.90907165
-65.04313432 57.9777858 45.21244891
-16.97858902 62.49621157 24.3795508
-51.62725151 83.71632832 23.54093309
-14.959273 62.46870025 24.31382651
57.91633415 43.42854668 62.47542971
-55.77190645 39.85468162 44.07151768
-63.22545802 79.60918314 32.64503451
-52.11310332 10.63121324 15.54272984
-59.06136262 10.67395838 22.19822969
-64.60458354 39.39757064 45.23579931
-11.70301826 62.2632279 26.07811247
7.985993659 66.6041028 59.33087473
-15.54683835 48.80215494 19.93116513
-41.23670074 26.8849519 28.71570734
-57.51257286 60.4192102 44.56769386
-39.99573604 64.53154652 45.92789498
-21.77749314 75.37771781 9.769543212
52.98820428 62.34561733 41.91787871
-46.58061178 42.93930562 41.46996102
-34.88260391 54.85145939 29.14020374
23.34259214 27.84147241 34.42926598
-11.48804649 69.83003593 19.76905767
-10.28939741 61.94658547 29.48434348
52.99144067 64.66350099 49.96942299
-9.224592917 61.70678885 32.06501354
-8.870235301 61.62714781 32.92196686
-40.84006297 43.8478679 41.48123165
-68.33222135 73.18530835 38.27226196
-13.47091645 62.46124804 24.11649864
-13.55237556 30.10607627 21.0028221
-67.46593281 64.2106446 43.23860778
35.4553768 18.94207883 36.37704602
-46.65367016 50.80673387 37.55265749
-8.339304952 61.50799198 34.20396329
3.844693829 37.80352859 33.20699037
-49.54476428 23.94945855 29.41760887
-7.632494526 61.34967076 35.90707879
16.03339998 71.85339138 55.12502144
-76.34837886 11.36621071 17.45879644
5.491862885 32.99779781 36.24152803
54.80606853 70.04061833 51.2949102
4.971999567 37.73042662 33.84062758
-5.864689222 60.95347464 40.1692821
2.260253978 59.13448401 59.73598065
-42.34460133 51.99139175 34.63180028
-53.70733377 76.40323723 33.08698568
-4.804213836 60.71586179 42.72542687
-37.22949261 54.69732178 31.37711172
-42.88926839 27.78144812 30.27696161
41.48023842 67.8685469 14.95816062
-16.90576648 25.44415819 23.32151365
34.58139943 35.14320939 23.26839611
-49.88326872 83.1139496 12.72127246
-10.73304947 49.4910918 28.49538883
63.19090384 45.41554387 61.70958762
-2.516560689 60.20610031 48.20684186
13.06862289 56.68816579 27.80699308
-49.47731637 37.28370385 37.75704153
-12.75795989 59.41041959 24.43693146
45.5748696 70.4849769 47.89691602
-14.59287922 69.98390268 18.57497014
-0.9190942088 59.84627435 52.07931848
1.025110665 59.41065079 56.76558389
24.91548927 33.8046489 23.17080752
-39.3248129 70.65398163 27.16601589
58.6074327 66.3419926 52.71982804
15.14255705 33.79226213 36.83164301
-72.40934135 27.40942047 40.22538663
2.614438429 59.05493032 60.59195295
57.09350038 63.59682866 49.91656464
-68.03218633 11.24141055 23.14826182
55.04633474 49.72500689 48.19525715
21.71320737 37.44885161 22.26342328
-17.30466913 73.45501476 19.58340883
-59.10951897 79.52755263 32.80772682
-49.6678828 56.16670804 39.99804428
57.00951816 69.41800648 52.27579116
-32.03015707 53.12003548 31.21593507
34.8713797 31.22698183 22.06239535
-49.52651695 35.70276314 38.63921542
-52.07791113 63.62721672 41.25023519
-42.29769053 46.02212823 39.82537252
3.798910671 58.9406023 61.69327028
-17.0025956 57.3060573 20.54217716
6.119597906 65.55004283 60.26982333
-72.14335267 65.3445514 42.62068387
-19.2141956 56.18501285 28.15120502
-49.58375316 84.41329514 9.233682948
-44.45905189 51.36645395 36.4631322
17.51479432 34.33671749 35.8854487
3.014844812 57.49496804 61.14485114
-58.58537626 72.47417784 38.84216119
51.30614443 41.3033516 59.27364308
-34.9681626 80.75061346 43.1388487
-51.48971782 24.90099367 36.22233282
31.5287019 35.19644345 23.23258393
55.3943974 58.74464438 54.12852277
57.09315567 58.6558348 54.83552591
8.858485117 51.9466077 60.34679993
37.48483152 47.3013059 50.54722699
-49.09798417 79.25186353 16.61894207
59.66775759 58.49688173 56.18977735
60.69844186 58.398186 57.13895181
60.98320342 58.37842991 57.31398926
28.81236729 69.85199628 40.96180303
-55.13456507 49.87483535 44.16153342
61.67736598 58.35037806 57.50725461
-57.6303597 72.99020159 38.49629436
-34.81234694 78.73748315 43.17245607
62.14518253 58.33739298 57.56877955
55.31222142 49.71477206 48.26336433
-55.80022971 84.29178095 23.4831118
-61.39495657 77.92249099 34.39637794
40.72344617 18.21066315 38.03655998
-70.92459276 62.71423608 43.78967463
36.57591687 72.57326657 48.67724348
5.0757562 51.44465988 61.06852496
-56.57464423 21.47274065 36.20323559
-55.44501559 78.64443481 30.7074781
-37.06787685 71.30615535 24.99133677
8.256335456 41.76963679 27.11067061
-59.79372381 34.42640167 43.76088788
-57.08221631 86.4880122 9.885418741
62.56370916 56.79306795 57.93669335
-52.67507458 54.89522084 43.67860388
-9.86125219 59.18809462 26.46542279
-7.094253921 53.93470657 28.6564935
-47.59655619 73.63430668 34.93218622
-49.79415295 82.96988454 14.37669012
-66.79108506 61.60692225 44.20261526
-12.53014569 38.85996985 29.89246976
-66.51121619 80.78441457 31.28198579
-2.184238707 59.63535892 48.94240788
46.42672751 41.18117302 55.79587383
4.794400161 64.54509259 60.53546059
-43.40533575 27.79399503 30.22972502
-68.77637532 60.61283753 44.46800122
-66.63326556 8.209562602 17.29089566
52.25825341 65.61655912 50.69873376
46.9376548 46.18672028 50.03015836
-16.8329191 48.65801526 21.8497073
47.84509774 65.49363998 35.48735894
-59.98833962 22.4647303 36.99178992
-65.39486585 23.96069814 38.13648329
57.77302614 41.91985546 62.53682553
-55.51720182 68.39540839 38.99324735
-6.032125897 78.50028127 -6.175627287
-43.90811762 38.35303566 35.9346593
-44.82174617 53.75076871 37.98663909
-66.2241159 72.610792 38.71299787
36.59408797 70.0530202 48.79743286
-70.89156219 62.19535809 43.98024905
-63.66426611 60.00965023 44.66868882
-51.08292567 79.67845741 29.52519392
-11.76593067 49.03912401 28.11249648
-69.87667462 62.17896443 43.97701346
-69.36060726 62.16641751 44.02425005
-68.04121879 62.14508829 44.02023999
52.66693812 52.63200054 55.68900587
-13.44359522 32.51938638 27.92645851
-47.16512506 62.61570119 34.57582411
54.59843584 45.14698479 60.63921152
-71.87869019 10.44286236 21.49866126
-54.69503719 23.53695728 35.1884135
-4.784684124 30.89996186 21.76804936
-72.48495096 62.75800886 43.57909784
-55.66067672 64.72927013 40.79333148
-15.96019172 25.41983197 29.2502252
-46.91849948 58.975781 35.99715512
-14.13579993 31.46102605 22.86476166
18.90994056 49.99453685 34.47785895
-5.953162556 52.8006628 29.95040664
-65.8258678 68.89438721 40.99330273
-77.03311116 9.274871089 12.73397038
-68.55042153 40.15577873 43.01310206
3.927772472 59.20731332 23.61266784
-58.68759476 61.98586345 44.08484591
-58.74794615 28.61561203 41.09764758
-69.60735534 38.18451813 42.79193378
56.17544231 57.54444421 56.25909088
-52.88492838 25.86589129 36.94045401
-52.97511186 29.68133318 39.27834608
-52.9114128 62.08263063 41.85994898
-53.8669395 17.06693188 28.56629357
-49.48240997 49.64345106 39.94306967
-64.96749643 77.99017623 34.29194311
-55.63492383 28.27751019 38.60226713
-50.13357268 27.72575191 38.13169835
-20.92260541 44.47994394 30.34525124
64.91065576 44.28693433 62.83006221
-62.45920533 11.15573526 23.08004209
-55.91864076 7.089379084 4.944894679
-49.72431025 62.5190989 36.18530625
-40.20039195 62.65802742 32.75625371
-39.38648303 62.74301559 31.61443141
-12.35265315 69.91928888 18.89782976
-38.73137926 62.8028057 30.79541186
-64.83223977 68.8763288 41.01344706
3.084938979 50.62014922 53.53978328
-52.42285797 36.93599413 42.35524293
-51.47496208 76.36736336 33.07770916
-37.63977449 63.31140894 24.68299777
-15.45750533 62.99924433 24.07654043
-38.06575187 59.72010184 31.49490373
-38.33276416 52.57599086 32.90690646
35.38730633 19.42907667 36.56324204
-13.45662578 62.88192171 25.05692744
-12.87429303 62.76161426 26.34248994
56.8484915 64.48946358 51.25421369
-65.0787155 71.52413028 39.45604369
-55.79365121 87.6982043 18.89745354
-13.96751155 30.93013314 28.99566141
-53.29559742 66.25699046 40.08732671
-42.0096239 45.02105073 39.73839292
-10.874384 62.34744696 30.7690523
-77.17951377 11.96557303 16.48610439
-72.78206774 57.16050974 44.5225651
-6.87387329 61.51876296 39.62610106
46.65981445 11.76642106 41.79423417
-6.45677102 61.4322488 40.55087671
-71.76836022 32.25691079 42.09635158
-72.24847369 59.65020177 44.65184786
-64.21739608 36.93895589 44.57015015
40.37833127 48.53769152 53.12247575
-6.258256235 69.6334637 32.70694305
-37.77794564 79.97421375 41.0344236
-39.39750143 46.09538135 38.42191317
14.46608853 43.95667819 58.80407475
-49.81791679 12.41719103 11.85201245
-4.128219252 60.9514121 45.68871215
40.59466001 32.57213752 22.83530541
44.38367249 14.43456599 40.38773873
44.23884619 46.80477395 43.37001821
9.001781587 66.23370606 57.61035233
-53.09476491 73.68226377 35.42400681
31.63348817 36.74934447 22.6653148
-48.65854745 35.50604347 34.9307124
-15.82872363 62.98827917 24.27462561
15.1911655 42.54653407 28.42264834
10.7198476 45.58533871 28.9585388
5.126999195 59.73642569 23.06828642
-62.97608863 32.53618112 43.0042799
-7.275827539 81.60822746 -7.057908328
-68.02742804 65.26292816 42.78329443
-24.63454054 20.71645842 33.06490092
-32.64819481 82.85286162 41.59854941
-3.051320623 60.73029255 48.05025862
-47.71348206 29.74605722 31.69691569
-41.21391852 47.26609381 31.00446989
-41.19671032 55.13274259 44.55892626
-42.08314528 41.36252183 35.61015966
-69.25097725 12.11731489 24.86583774
-37.6404576 53.62764086 32.21984032
2.617426007 59.55563929 60.60542896
-49.43323948 65.25501885 33.50244582
22.44587257 75.02654046 52.02573894
-49.52485291 34.29438707 37.50843247
3.705905322 59.45256724 61.59438517
-15.53851039 35.66120273 38.46660312
2.375592595 66.65243964 54.01304075
-55.72473326 57.86110672 44.78986979
-6.638991777 53.92805444 28.64689509
23.0087269 72.98152819 52.35183066
-67.17234707 18.84978784 33.71358588
-46.41948317 73.18255116 34.12537813
32.67313217 30.34032146 21.12133313
-70.24908571 31.73164627 42.07762978
-49.7354166 72.04202801 36.34449017
-51.62731715 81.96193481 26.42730355
4.260471764 35.51059555 36.43917607
-63.26217341 62.06346089 44.05643774
-42.16337464 34.44630201 34.34101439
7.878318055 56.52458893 24.8690935
59.33251712 6.65852481 52.0605357
-16.30485415 46.68676682 27.15277085
27.15513984 69.35156571 41.26056865
-50.34980733 82.05418336 19.28614422
52.30752519 41.29377132 59.19388857
-53.02259924 73.14364444 35.83628988
3.417945609 57.44323779 20.8820071
32.30929716 43.40415428 50.16208546
-38.73395974 47.05577118 32.97310573
-19.53630088 45.15007624 28.12813043
-56.21379537 69.2556736 40.79278767
-51.28525514 39.36877801 43.02996908
-56.65752859 15.48129967 30.02572909
51.88592115 63.82029247 48.31554995
6.247620047 54.36237712 61.93389783
34.55927622 33.64772116 23.15341119
-55.81577999 88.30403546 11.86874221
60.46580902 7.388612384 55.02230301
-2.412902961 58.74627446 47.65387481
-65.15496058 45.35490564 46.10335794
16.40286932 71.86523879 54.91702803
37.97760033 48.50982555 53.90379814
-15.93849901 33.65588639 38.51549532
9.841438266 69.17033504 58.31953781
-51.86790419 78.57444351 30.83781163
-72.10248428 62.73852202 43.73237662
-10.90149286 58.73983925 26.04083744
-64.81871285 62.60752336 43.86404582
10.94650776 68.27746287 56.82062423
-36.9191757 46.86460775 29.0195387
45.67982698 46.47433005 46.93124907
13.70252963 32.7709031 37.30966414
-25.03977604 75.57957207 2.221766444
61.70563596 39.40723465 61.82198475
-74.55225249 54.61894356 39.4046588
-59.73457781 62.5206634 43.90280454
-67.80439661 53.08484847 44.27341661
-36.54640133 53.91820124 28.63815975
-55.62700312 71.99108668 38.05941548
-39.63346235 52.72509909 43.07728364
0.7422569457 57.99712997 55.74878416
-6.574443439 61.11328083 38.4494894
-58.77737287 84.6108955 26.17299761
27.91656558 32.77302264 22.92107299
10.33935604 31.8276548 37.24767441
-49.74133462 24.30305414 31.17694149
-48.28014612 86.91100684 9.123021461
-51.28483485 15.24221998 25.94989411
-44.54484775 36.4175857 35.21763952
-40.071583 79.33389473 37.25184205
-13.16537717 32.4219386 23.1780039
4.686705475 65.07685636 60.20953753
2.805758418 51.56718852 54.2523833
-56.68731764 62.47017216 43.90781295
-45.62572662 33.52830549 34.00479747
-52.09287164 50.32936822 44.13155586
61.03527181 3.610617774 57.98493593
-63.04671968 85.2845161 24.99394879
-2.451955016 59.6960724 48.28865651
45.01176345 42.23036867 55.53916886
-12.29289643 75.24007644 21.21189876
5.64405174 51.88320511 61.6958355
-55.8135157 62.47539214 43.68057624
59.09514181 5.12295141 52.45193868
-53.52794744 62.61090658 41.67155384
-55.64653661 50.38753218 44.13405131
-53.02393231 62.6044393 41.65051202
-18.35480359 49.14169959 28.17826194
-61.36960352 49.29670634 46.23529558
17.92086065 72.39460931 54.30885935
-61.37439002 50.80576892 46.19783128
-51.52049392 62.57699027 41.68244572
11.19486529 54.65816524 28.4234943
-62.51696289 56.91316456 45.43631154
-42.94460885 37.35812752 35.64725042
-49.7194771 62.99409903 36.49687729
-49.52541777 71.53299142 36.38706951
-37.63847457 40.64335746 31.45783671
-21.10753887 56.52730511 30.36533977
7.639179168 48.40193904 60.9419435
-12.67905997 81.41671074 2.022428759
-41.29431512 63.08022769 33.89030363
-40.9265586 63.11771696 33.38496731
-11.92360505 83.1865007 -1.186732399
4.483008345 67.91369032 56.44954861
-70.87452026 75.38638627 36.51193796
-38.73334305 63.32459573 30.56510776
-63.42119215 45.8249851 46.14233342
-49.93440019 9.958210949 5.460015538
-60.61813671 81.78947913 30.14389882
12.27030711 44.56690504 28.8322964
-55.49084523 80.89941794 27.8452913
-47.73213637 47.34634596 42.96919132
-36.83052476 63.85140772 24.08660981
-48.20616016 77.80994795 21.53439328
-22.5728548 57.57712245 30.11102021
-43.59432499 51.45058532 35.32156505
-7.787403954 39.41610185 22.53201312
-0.2790701925 65.09069313 49.34262981
-56.60269419 61.95004646 44.10303255
1.461400222 48.30674411 28.26532472
7.565254951 41.6970225 28.08542361
-55.81414335 90.10053326 2.666652829
-49.48416854 54.61694269 40.47389346
-12.70460308 63.22778745 26.7250914
-53.3009391 31.6067793 40.29506318
-71.20360884 71.64043415 39.27395741
-55.80218042 46.80186598 45.00227759
-50.92149917 9.769023013 13.67113508
-14.44548501 50.23067357 26.44440822
-11.71148855 63.02415417 28.89962261
-20.29080364 26.36067576 36.63402135
-40.67422301 62.1098858 33.3832032
-41.39240824 68.30146619 43.21675571
-11.0453717 62.88636046 30.37220037
-66.31830968 76.92381393 35.27538147
-49.77724017 12.63299064 20.99237902
22.98582468 26.44045886 33.28136867
6.477150524 54.78318853 27.87182912
-49.47306009 37.03377826 40.65754892
-10.87884248 62.85191203 30.7403448
27.06970401 70.34793903 47.19015678
-78.06710718 8.526131408 15.79643588
-10.71248644 62.81755095 31.10750823
-73.8174275 17.21963122 30.5983262
-6.970577402 60.71177954 37.35931345
-7.725523623 62.20280765 37.6742664
41.10696724 69.0021144 48.48319944
42.73962126 41.4800952 53.0289199
-56.70761525 13.24326384 26.88282916
-52.33707612 59.49244338 42.6859991
-15.03301393 81.08789111 23.76844624
-17.93749057 25.33369562 36.45388356
46.3494241 11.29688933 41.47745342
-6.646200696 61.98046519 40.04954703
19.85304884 32.39869499 22.97766163
60.6856264 66.76221914 53.27184855
-70.99231459 20.30115522 35.07346765
-7.562110693 81.07697937 -6.662837721
-39.83430714 44.61085309 38.2587795
-40.17256544 70.10382635 45.36757617
-3.253131536 61.28475453 47.4787866
-41.19905188 47.7608105 31.08524331
-41.69435035 54.7328894 43.46897577
5.002854147 45.24144374 28.21434659
-2.172336714 61.06166958 49.86240584
-13.9765672 26.87407621 35.29657215
8.328676827 48.85367854 61.3929714
25.85007048 32.32211697 22.72296708
-9.872040534 68.90908615 24.32529255
-55.88877386 22.42852592 36.63026486
-62.29444212 28.67015966 41.14075692
-62.18991755 61.01369627 44.38510422
-0.9258784995 60.80457296 52.60926445
51.90967535 63.32421277 42.41659234
-64.06777549 31.58688343 42.57932968
-41.1262988 22.78009566 23.90705124
-73.92800136 51.69385425 44.1089782
-59.09729385 37.83838467 44.80577211
-58.93796197 75.1631166 36.827442
24.82151606 36.86534897 22.61773382
-8.015837041 33.3065177 23.58039785
-41.37203023 61.5517666 45.82197719
-40.23299254 52.14032489 32.50017895
-59.2673031 33.93119393 43.58258815
-38.90210267 59.72076782 31.64666966
-14.18688935 71.86230247 19.99827359
-16.35700732 50.37725431 25.10733052
-14.09567771 36.68114666 38.00447438
-55.52412188 76.97946834 32.57078761
6.072197292 60.00002165 60.61450945
-48.92014326 65.26874856 33.2452108
-55.94234099 55.78856787 45.58434896
-60.89148087 80.6751545 31.47863922
-37.69593312 69.16330607 44.16023136
52.69177198 58.51551742 51.47716129
-39.39713091 55.12842396 32.61258575
33.16764967 73.06030199 49.49999896
-58.68876342 35.87121967 44.2575862
-37.39209175 45.18768325 31.09676769
-7.56683953 45.38764709 28.91422751
10.03423231 50.44153323 60.11465815
-26.22695288 75.8315426 -0.4768900728
-52.10210783 28.22986329 38.48165787
39.48998489 49.9969007 53.83224631
-39.90922384 64.03111628 45.89411519
-61.47305056 41.81564888 45.70098486
-20.84221333 47.65090988 28.47887825
47.80652963 64.61443362 39.87454308
-49.7160314 56.39587793 43.17349655
-55.85074121 89.08978482 2.753856148
-17.29613049 58.08689252 23.18693308
-55.63444452 74.77847695 34.83597801
-59.90916698 43.27674582 45.92118592
-40.46421997 58.25877989 31.43647493
-66.44785902 64.19264368 43.2534228
-1.765939356 68.15358796 43.20306962
54.41585675 54.50197428 56.95402054
-9.619248215 44.42741964 28.79937334
-77.4560341 12.00594976 16.07011583
-62.89519036 49.32293903 46.22170594
-54.56279503 16.66795527 27.50396802
39.18903657 68.91375131 14.91489278
-39.29122428 46.23999365 36.72288008
-38.99542081 78.61093065 39.61266566
56.80295103 52.4659104 56.82833921
-38.60911707 61.25306697 31.28267132
-13.5064861 33.61841674 38.48667086
-38.45732604 66.96104112 29.08444362
-68.84874945 84.23352234 26.64785172
-73.80170254 32.29149754 42.08261392
0.06007399502 46.26143057 28.96949485
14.09324821 35.95877265 35.18788046
-63.50921832 40.36979067 45.39394877
-40.34093149 54.11784426 44.52412273
-14.99569129 74.78803427 21.14833255
16.87432564 43.47854171 58.06872565
-7.583250451 43.90166628 28.68778417
-65.77513369 84.16973627 26.80217011
-59.25697837 63.02973731 43.72866293
-41.08619312 63.09913172 33.63116166
-70.13363964 38.69204598 42.82718651
1.646118061 60.41541661 27.50317175
-66.88813343 63.16252548 43.64246792
-65.79566521 69.4185346 40.72949837
-3.692159347 59.5469097 44.43011887
-62.38109707 49.31138791 46.257759
29.62145822 74.62134408 49.53448575
-41.56975356 33.4893132 33.68394997
-25.94336137 88.76127989 35.82371503
-63.0828547 77.95626656 34.32617875
-37.24535293 67.72484329 19.98653857
-11.87870083 83.11429347 -0.3570615442
-61.99979119 32.03703787 42.78587229
-45.7670542 41.81515721 36.88482248
-57.73319244 63.00441307 43.73205005
28.49261898 34.73908041 23.29434627
8.087956865 42.66078304 28.45098732
-71.70411725 66.3751937 42.22575269
34.49250559 41.34039054 44.57020477
44.40549268 49.99510322 52.91569583
-55.09051299 63.15800887 41.44502271
-56.35585158 85.37160959 16.88027313
-49.56076478 63.2923648 38.83076438
-63.84309762 20.18847561 35.01813622
-48.70549802 41.81075704 37.49628183
21.11161381 73.9518274 53.10296202
-37.71114789 68.01602394 22.52177226
-16.68206289 31.72477203 37.76851854
36.64550108 43.23619102 51.28498557
40.80413081 14.69161713 38.08634111
-12.53091555 82.58753229 -5.770934707
-40.4517411 48.80949198 30.42214828
-14.86333323 55.14150143 27.78202978
-54.72822222 70.50183604 37.69639813
19.96230671 31.25335527 36.25276704
-7.809141558 69.96979222 29.09835859
-37.67090646 63.98465526 28.52668252
-2.868494007 60.28451844 47.36362271
-63.8854888 74.17663375 37.5694963
-6.642184146 31.38522938 22.31558722
-49.68269051 66.23661706 33.80819103
-69.9398266 63.21525291 43.61234561
-44.91718688 38.36649433 35.97085688
-8.153455653 70.0443669 28.29830395
-37.86281798 64.27968611 25.13834727
8.942075065 52.19325893 28.33434175
15.68561971 32.76166563 37.03871221
-0.4776347732 65.14130151 48.79299821
-49.52909577 23.63756153 27.20870785
44.3468626 64.34596585 37.82425089
-64.28964865 10.33131797 21.34627422
-55.75796316 79.60850756 31.22878084
-31.219315 55.71765083 30.03949213
-71.37658648 21.23926224 35.90980164
-51.30910416 66.74825231 39.83220522
47.01059066 66.16903692 33.63264444
-30.88070991 55.19329569 30.23537435
-67.90153081 82.49683704 29.14799963
32.46276786 48.56616834 54.30144161
52.2835703 42.28550935 59.33891998
18.67314224 74.44521538 53.66702647
13.63627486 55.64516544 28.15336594
-10.95133177 81.25227185 15.25515108
10.87324675 45.89480151 60.29666504
-44.2531233 51.38646443 36.19156407
51.52289688 43.85376006 58.75850871
-9.213119346 81.58197348 -6.383679696
59.15869617 66.81390735 52.96300881
-67.31825726 16.5609779 31.17838312
-70.37821526 51.63764694 44.08450808
-24.0244965 75.36475785 4.52186716
-56.25591189 84.5778603 26.07563657
-40.17080268 55.72357737 31.67779517
-57.85146786 53.27419301 46.00392499
-74.53011894 46.72764816 43.60874131
55.62297836 40.50666514 61.87227435
-47.71959493 17.44151872 28.87212158
12.15994524 31.29906197 37.21012753
-50.16097692 65.15799905 40.59414841
51.11258186 44.81739323 59.30348309
-76.15117274 8.345888214 11.69680868
-40.76090461 63.66025962 32.88178577
41.64929237 34.02995411 23.19070448
-51.06146184 18.39727514 30.06759956
-38.88885594 55.11980784 32.61567681
-69.66350767 63.72674951 43.44846504
-43.64117074 33.98337785 34.17015954
-10.05487785 47.41268663 29.18713803
-68.44862101 63.70857955 43.42770893
-49.68879273 79.05433441 19.02467532
4.182925233 59.2396599 23.18850593
11.17732112 34.31247678 37.37544695
-71.81021301 33.28245509 41.85234817
-9.09751862 52.94968889 28.82005045
-64.91982505 84.73605676 25.89143674
-41.23667004 60.55338691 45.7328015
-73.00297362 46.70062931 43.63116005
32.7332219 22.54230871 35.88856756
-6.461392311 29.99799428 20.90522369
-55.6727513 63.56987293 42.60148146
49.61642965 52.35535932 53.65556816
25.59232368 70.85207041 47.44621801
-49.83722889 84.19426869 11.82464155
-63.1718755 81.83879657 30.05839927
-53.3839961 80.85252921 27.98782117
-56.79525814 49.72144259 46.25893294
-2.959856153 50.32825256 28.94790309
-49.5124629 63.81977109 38.52567663
-55.88126837 54.77810615 45.64959742
-10.3468227 57.60427604 27.46422346
13.18083258 44.43822775 59.28564303
-48.82876553 64.21822185 33.76978356
-17.97674023 44.10227372 28.34108744
59.37641578 58.51995716 55.97746146
-52.07858117 13.53464253 22.61750746
-38.89696939 64.33723403 30.49414964
-61.42229633 56.8936939 45.45358168
-68.44465194 80.26638834 31.83768927
45.55765602 15.491017 39.55308026
-9.45679513 61.27214939 31.32826686
3.100525775 61.0415928 60.74318886
-41.71600205 52.36808052 30.1390438
4.295355497 60.95920682 61.47172432
43.5404071 43.33278875 54.67535017
-49.56698537 26.47840684 35.02381541
-49.62447672 59.83376939 38.206328
-49.60850075 87.8917353 9.644616752
-15.19303074 25.53506066 33.59290586
-60.69254662 73.0482225 38.40672274
-55.51063197 62.64471733 41.65716389
-37.99016967 44.06372261 32.6052986
-5.112589329 39.10748597 25.60454872
21.60288318 74.47878349 52.71862403
-15.34705004 57.3877131 19.27853431
6.325215354 61.03787711 60.17135551
-25.43680812 43.97531356 31.23761377
0.5577755968 65.4185485 51.20369509
-59.9982081 66.16916147 42.38513817
43.09197276 32.04906765 22.60458873
-59.13840182 85.7974453 24.12079342
-64.95490138 44.850883 46.08962722
-48.78932449 71.65723298 34.80440508
-46.53311674 60.00881094 35.58477655
11.82613298 57.24725382 27.38028221
-15.29323492 48.74856057 20.50496404
-15.13048902 80.13262948 23.22312763
-50.26738915 39.35088191 43.04360689
-3.51338463 45.32514721 28.86675354
46.5406157 53.4246863 53.48189804
-42.45657141 27.3087406 29.85533655
39.14099336 68.20611061 11.48558221
-13.05974675 58.98610362 23.59361248
-4.857339329 39.07783886 25.90003634
-41.57863359 22.25827724 24.22432245
-47.116128 71.63087352 34.79131637
-70.87882812 64.27213216 43.17567492
-11.94690347 80.40315688 13.6488769
-34.94938923 48.83377848 29.09088114
-54.61592661 63.66860268 41.25381151
-68.61641974 82.51056626 29.12495444
-68.48463 64.22895998 43.22026111
53.434527 50.90493532 52.28509146
34.40831006 40.9797576 42.94611063
-66.95994361 64.20318152 43.22874948
-11.51528867 82.72794966 21.53852071
22.89544596 33.83252709 23.23241155
54.47798267 70.05745111 51.16207088
-63.51162899 81.2849654 30.72582798
-48.68374784 51.34188983 37.55396545
63.93571186 52.17569803 58.83707619
-58.82263366 20.1089561 34.98382419
-55.16408945 64.19955589 41.02129796
-11.63958614 52.99311887 28.80067109
-49.16730728 35.51490417 34.9248745
-53.63829481 64.17321838 41.03606481
-49.59472416 13.54535416 22.01947247
6.662549961 48.95080536 60.58319266
27.35818086 36.82464435 22.60650561
-39.89328551 69.08240336 45.51845749
-18.83452979 26.79728984 37.11440734
-61.6943552 32.0316306 42.79039528
9.484762893 67.67016362 58.32272004
22.01973663 68.95381212 41.03069393
-74.76275316 11.61368516 20.11018542
-49.63149229 64.48114182 36.69734276
-44.6224962 21.37695613 23.38254202
-45.14967322 12.35336086 0.0494539228
-14.94811345 62.9900648 24.08595902
-48.67094288 87.46168959 8.63146231
-37.99062238 65.93179644 29.29038207
46.0990799 12.30371595 41.49049917
-32.99294969 51.60350224 31.52464735
-23.63321113 75.76870497 5.58457478
-49.48757476 64.70587819 34.06099257
-47.85619013 64.7253882 33.52339132
-0.9753946297 45.78599013 28.85958364
-60.09082478 25.7717727 39.40725935
21.58677064 73.4577014 52.92188103
-53.43838367 22.1521264 33.54496677
-13.48497109 74.26708489 21.08114878
23.20678455 37.928593 22.23604193
-58.37801977 32.94895027 43.16231586
-69.44127395 69.48591198 40.64257202
-40.6964664 63.54196081 45.89607976
-40.08083818 63.52874704 45.93207086
-66.76319431 58.53000116 44.95659672
-27.33470652 48.98851497 31.66906273
-33.30915653 46.22624469 29.91502994
-39.69497019 63.5323223 45.81697927
52.86657543 40.3294436 58.62861242
-6.08618577 69.59622003 33.10647987
62.95314162 47.59963064 53.88037851
-0.4714579147 45.27734638 28.8415468
20.6695727 31.46647206 33.64387143
-68.1557547 9.542657922 19.58562314
-55.60451695 82.6120738 25.46513233
-70.91886296 77.01451403 35.09982155
-37.16365597 66.02831681 22.18555372
-5.005565131 48.34782453 29.02172241
3.053420722 63.7003239 59.02088517
36.22447359 43.5791227 53.21091806
-55.69563746 67.28276373 40.29038575
-26.5026204 88.72128088 36.39470685
-60.4812557 67.7460335 41.65169941
-38.79371524 64.86169801 30.2127387
-65.31713671 73.66565271 37.94772015
-62.73226138 73.08602377 38.35688385
-54.64437481 21.2357106 32.76009798
16.82521743 28.87524664 35.32464465
-38.26343052 64.88327569 29.8611206
-37.4770454 68.37537126 24.1322028
-66.06991579 26.81895997 40.0442757
-57.49311929 66.64660108 42.19157604
-52.58041917 12.1696417 21.07921395
-51.6788203 17.49365215 29.0219667
-60.23996873 10.27072234 21.27741421
-13.68075349 80.59731961 23.37883317
5.287602349 61.45645009 61.33677501
6.123413587 61.50561199 60.60667343
-15.69268097 58.34349482 19.90231835
16.54124183 44.4032163 59.05123125
-79.93113019 10.27928891 13.27990676
-14.3559551 27.82210847 36.01675521
-68.62273159 71.58981589 39.36938239
-8.483465483 59.16208479 26.50461166
-7.981558352 70.00721059 28.69685975
-37.76816095 64.13282582 26.8251573
-1.146024463 69.09308816 43.83172605
58.81039144 57.44177202 56.94848402
-53.56866598 23.9913206 35.52566771
52.64222328 62.34669929 41.97129909
-51.04762338 34.47452513 41.53430136
23.80261109 36.88376918 22.59820993
49.55088646 64.22340693 38.2545658
-62.75840071 62.0571159 44.0340225
47.92899362 63.69689286 38.84938929
43.59250471 41.39675591 53.83373332
-55.94636408 47.20336915 46.19547352
-20.13078805 58.78679183 27.25585975
-62.96040277 22.97950511 37.4093394
-51.65921729 54.87833802 43.68086195
-3.993170314 37.02949828 26.20730297
-63.7674007 62.07053963 44.07061249
-7.041516051 45.87264704 29.01045273
-13.25621727 78.16942033 22.34949371
-11.60356111 82.6781675 4.65333697
-14.29432341 52.59116805 28.14644291
61.15828155 7.591072688 58.3665788
60.15302339 45.46130359 61.75771734
-49.68657799 12.50626444 16.61957788
-42.15251013 20.89244881 22.70937444
-68.84673362 29.2564981 41.41031465
-62.19639455 84.09892387 26.94172515
-27.02062319 56.09649247 30.66754713
-65.99982718 19.75831005 34.59647236
-70.51378254 77.54778056 34.65866404
23.25286001 30.48361063 21.25443032
-9.257616408 60.36546629 30.16243943
-65.56366893 56.96337628 45.43444236
28.87783307 48.06355649 54.99315457
-46.62742485 22.15386337 26.39926999
5.8012506 57.9296113 61.39445158
55.51488944 70.00517222 51.57121819
-69.03099193 64.75900474 42.99795008
-52.52849196 81.40636216 27.22201507
-37.95862091 71.24753702 25.84169145
-52.36450236 80.83381201 28.0106805
-64.44827456 64.6773018 43.0724658
-0.9624144896 47.28609049 28.92277114
-63.92099407 64.67148745 43.03940744
14.70333416 55.11262713 28.30532381
-45.86889676 12.46838805 -1.148705599
-56.1854582 73.50688591 38.04933516
-68.23788573 21.65724958 36.28557139
-49.47825391 52.35065753 37.64825272
-18.00919295 57.95103326 24.90007207
6.326470405 58.41760668 20.66943057
-73.87331664 11.03446883 20.83788959
-63.48768177 70.96266748 39.84385314
-73.47715058 30.81935503 41.63050176
-36.78928945 38.49680501 27.08143422
12.1598398 70.7463794 57.06145407
-58.82621995 49.25261249 46.26213063
-53.7876844 40.39664487 43.22825042
-14.42955776 60.51473825 23.58925986
8.256482643 50.71033957 28.19997581
43.37704567 17.14370662 38.26323097
-49.67253194 69.92044982 31.82812051
6.957096188 60.56101769 24.80009226
5.806792266 60.47506276 60.97714086
-50.75045906 57.92877648 43.05568564
40.05011531 32.09683354 22.63018788
-47.56616927 66.83573247 32.27622946
-59.36566336 64.59121053 43.10259161
24.26585812 45.47549305 56.7836109
6.461941707 33.45666783 36.55627104
-37.70807683 62.79409169 24.87477866
-57.32795731 64.55442253 43.14105077
45.49271559 11.85959631 40.93516047
-56.90562271 65.5935903 42.65037284
-54.04173984 64.70550357 40.76049001
-51.49371547 64.65906872 40.81361617
-50.6244808 64.64181477 40.84814437
-37.79406599 67.56229414 27.80481285
-40.59558033 64.0428415 45.88889257
-67.75384072 67.87138928 41.58340357
-38.75046474 65.37926349 30.02285357
39.49693503 68.59350661 47.70701863
-68.01183823 9.959887674 20.54131098
7.517520963 67.09485704 59.54982276
-59.04164581 9.405142892 19.44388271
-5.541698131 59.98433553 39.70487804
-37.98510462 65.99668768 22.70938297
-46.4976607 70.35735807 31.97734454
-72.28217992 78.68853993 33.40642237
-56.17744955 62.46075241 43.91992932
40.29440913 16.17044432 38.49616564
38.35521853 43.74318064 50.90006762
28.75558044 42.9477394 50.31155668
-43.33124809 54.98871683 40.81131004
21.35337062 44.45356741 57.54901678
-76.35760035 10.86864638 17.41000401
-13.67628212 49.712108 26.49096327
-57.20058919 35.36164341 44.0626747
-45.64522826 46.77586348 37.5404998
-19.61906424 74.43092629 14.52232466
-45.65812998 45.77793958 37.47430731
-8.853469871 58.09651953 27.29175133
-12.31234766 82.83082662 -2.810313666
-24.39828266 44.95142803 31.36139339
51.18622689 64.50201788 46.36166033
6.488558715 62.04663459 60.08309373
57.80968224 48.66463362 48.32457521
35.41844512 23.0775483 34.98966835
49.6369829 45.56840455 56.69324154
22.82102365 69.80182081 42.68686201
4.441407371 59.69820947 23.64267478
-24.62080763 21.71396303 33.13580227
63.11195759 55.25763952 58.17659029
-69.46201313 77.52517113 34.72055215
-4.990354148 46.83350341 29.11824362
44.19649048 44.3531555 54.3583509
58.42643366 56.39563434 57.51275504
-14.57852812 73.31873059 20.64560363
63.22368863 56.28004344 57.93975343
-49.66745784 59.73156155 39.40102815
-4.002042319 51.85267589 28.92995832
3.893397813 57.95339485 20.69570095
-55.82870871 86.38934458 22.44505688
46.70728986 67.89821483 48.57677434
-59.05096907 25.75517302 39.40165385
-50.12683787 83.48459437 8.465006897
2.035063922 57.69187529 59.04585782
58.58924559 5.68213932 51.88359557
-3.324941563 67.39046751 40.70595763
52.08205179 53.60936104 56.10791507
-39.17804249 53.19580557 43.35276896
25.58852136 30.44518893 21.25503276
35.59392281 34.62579229 23.25521616
-74.48298668 34.09225746 38.78792571
57.564354 66.37260701 52.56335492
-49.48103954 78.44731919 20.20509056
-68.74643776 21.66600546 36.28091071
-69.35497057 73.20566775 38.23095961
-39.87716242 44.93646423 40.31366169
-16.34428559 58.65407189 22.24781922
-64.70923224 76.35324915 35.76541235
25.19815536 70.85268218 47.51428644
62.62694673 54.75230424 58.30874459
-55.7763397 47.21671333 46.00813988
37.432122 69.12140664 47.7991829
5.886034168 60.60574804 24.48508765
-18.32930788 55.15543081 28.28130956
-11.31478917 45.44470477 28.96661686
-44.13233657 53.28484306 37.43732849
-71.28676644 25.00106978 38.83599266
56.62480089 51.53895563 55.96979906
36.2159684 44.45729691 48.84469315
-25.03542262 22.77887669 32.50585851
52.67490998 65.60460336 50.75806605
-28.09444604 76.22846964 -4.728567301
-67.36583676 62.65350399 43.81602092
-8.538258932 49.39984183 29.1361255
-49.65428742 78.83870569 21.52127093
-49.46692755 71.0756041 35.85895993
-5.206194702 31.36702649 22.25304777
39.3031731 44.66587134 51.66130176
-42.37414194 42.11729881 38.55693466
-49.49651451 31.01235839 34.81672563
-58.09752971 21.49761066 36.20494973
-10.09009341 37.99984667 27.7587926
32.529226 35.68951134 23.14452698
25.71019634 45.49024549 56.33690984
22.8807236 48.43085412 46.046338
-53.74931786 87.3474211 10.92645126
-57.35265213 9.798697139 20.3797576
-35.71413383 77.19017613 43.82677877
11.1937351 32.2953328 37.48223262
-12.28405094 83.76477 -7.830966319
-38.510779 79.56911241 40.05025026
-65.77537897 21.15173728 35.85767136
-10.5899013 39.7395704 19.3114067
-47.02150469 60.53438911 35.40326098
-46.77732573 30.67469944 32.39131024
-50.09228999 52.32017139 43.94590035
9.300497481 47.85457754 61.15265109
-53.11567935 28.24559308 38.49234915
3.705910575 66.92959032 56.36861511
-35.76085334 41.4125395 27.99717688
-67.54266086 65.25378141 42.79702861
-66.52195497 65.23445275 42.82675501
32.15597819 20.65118493 34.64563257
-64.34520008 63.11865875 43.66675234
-64.49942241 55.41543587 45.72118349
48.03275484 9.709213954 42.1074041
-61.57837988 9.445882457 19.45471851
-63.98310831 65.19264751 42.82788755
-23.72109293 74.80860409 5.093604222
-7.856731188 60.91101316 35.21545865
-20.03835058 24.39790901 36.06454231
-58.40538227 70.87673345 39.87221313
-40.45047935 64.22888515 32.04820637
-8.789132399 61.91500723 35.39145902
-7.809067259 61.38918555 35.48203568
13.13548076 30.80030307 36.98740347
-12.01394018 82.48207486 1.181343783
-36.15563352 35.13243013 25.23022466
-55.67569952 73.59663277 36.91027388
-49.62993499 9.773189473 1.723175028
52.31049006 56.20343041 55.08370448
-57.36891879 65.0773012 42.90554601
-11.17181352 80.86127583 14.00952437
-56.35728671 65.06254974 42.8838674
-40.55438564 47.35462201 29.85099291
-49.63771882 21.64059456 32.93175109
-54.90383452 80.32409042 28.58552573
-64.42723031 16.51496352 31.1612155
56.03519818 39.54207111 61.3380911
-56.42962519 71.37341107 39.55626892
-8.122975601 60.97098415 34.57004588
-48.21796106 63.15345361 34.36062454
-12.61685028 72.81807831 20.25679605
3.294150717 56.47353195 61.2958796
17.66776314 49.67292448 32.62161955
41.63712299 70.4891088 48.59990201
-53.71938659 65.21855643 40.56976414
31.04948415 46.02467062 54.94131932
-46.46938089 37.90393273 35.80998124
-51.18161354 65.17729276 40.56481441
-6.525869526 70.22111909 31.76268659
38.10284055 18.79592698 37.56879632
-55.87592796 62.98431361 43.61118774
-49.62979153 65.43516023 37.27535831
-49.48600422 33.06547638 34.28719276
-55.6110605 10.13428204 16.15191046
-13.62975388 31.76418643 30.90223185
-18.21342987 22.47198345 34.7675046
-49.58980211 65.69993326 34.19405305
-4.506392948 69.79708112 36.30010327
23.00019862 49.6971302 42.97697787
-47.45560495 65.77552726 32.90949832
51.64534808 43.33107107 58.97627093
-49.80833466 74.71378025 34.47595033
-49.70568558 21.42044229 29.67375959
-17.90683374 58.96577578 24.75396158
-39.05484526 64.58551701 45.12193252
53.72407028 9.818283431 45.58251946
-52.37000961 67.2900753 39.5712905
-40.40018358 65.78449555 31.45987647
-69.55771536 40.67055782 43.05590857
53.42258429 51.75433571 54.08019144
-5.05514829 69.37345687 35.49585265
-1.39237156 65.9067253 45.90848646
-67.47702712 84.78712123 25.78631696
-79.03611626 4.402542464 17.23773474
-64.92957883 50.86470157 46.19169383
24.66207582 71.12976153 50.226622
-28.93703586 51.53976198 31.49110375
-19.11027147 42.00859594 29.55563494
-61.89244777 51.82375192 46.13242017
-49.72454507 67.13827299 35.00237487
43.88414579 45.95448942 41.65510303
-39.46826191 63.54185066 45.66313255
-8.992515857 30.03590367 20.94784427
15.08578988 29.80316961 36.53763891
5.512281157 45.73446252 28.22051494
-48.03915091 86.3863344 9.341201905
-1.293755817 59.43394152 51.11078798
0.8158382486 60.44631483 56.43603109
3.233077664 65.31456851 57.72721646
-37.89191664 66.15456163 26.68553699
-78.62369684 6.903804224 17.25581638
-51.77514014 86.41923984 15.49832898
57.19776084 8.951355836 49.15735373
44.68151581 68.93341589 48.599014
52.00505455 64.69029521 49.84648585
-67.78758348 62.14103355 44.01894059
-61.25939033 17.8359614 32.70190481
-74.79730865 34.50525178 39.88017036
-58.23561913 28.6049519 41.12369432
8.906455808 67.63977323 58.78580057
21.84350714 44.95631709 57.44590042
-69.63631227 80.84939157 31.12365988
34.0282636 41.72989923 45.96367853
-18.24903696 74.63849027 17.67813953
-36.70733979 66.57290471 21.60319852
48.91277487 44.10496448 56.34010998
54.16230725 56.56440554 56.36673016
35.16730029 18.53562466 35.32375955
3.616369508 62.53580676 60.77881081
-42.8458228 42.18178892 37.89823864
-11.99205859 40.40397904 17.69250673
-13.42380863 60.93083686 24.3937222
-72.09899716 64.81996064 42.87541646
-71.70925471 54.67814955 44.00181741
-42.11678433 34.92501598 34.60150915
22.51541652 49.79300697 41.95642105
26.94147833 68.94887 40.14945987
5.446470384 54.80754861 27.78559644
54.62244709 41.52024894 61.94994292
-11.62785694 82.03954016 6.244981978
-63.04060568 57.43174885 45.34270924
-53.12429029 12.15904718 21.3059223
-12.19486579 57.76839098 25.91149014
-72.91171932 52.17897319 44.11019331
26.27492973 69.62053432 44.13268749
-48.84059329 68.99191102 30.79533894
-61.54008386 57.92037601 45.210851
-55.59415198 74.80770745 34.48896491
-51.72657617 56.92118069 43.28546263
40.51057647 19.32247537 36.8237094
-68.11342904 81.37629443 30.54320477
-48.56812017 58.48803184 36.14725502
-17.211992 57.21346256 21.65701778
-11.55657892 82.60491206 5.494779966
-21.18602282 18.70247092 32.48052093
41.37911229 64.07713373 35.68437686
-55.67468224 77.40896604 33.44021869
-65.96133741 36.97622409 44.47009379
-53.43168652 68.35928131 39.01491658
-42.42477758 45.60879796 38.82120721
3.274137987 35.68603049 34.59069872
-34.06520998 83.37609806 41.62131315
27.94741044 24.23310868 34.65313294
-54.1449137 52.38225198 43.99808316
42.13589852 64.44537441 37.09188773
40.48089739 49.49038503 53.6966361
-5.323346816 67.85343196 35.71263555
-17.06435489 58.1310315 22.63033458
-17.19927066 25.74088081 19.93291335
-67.7377559 13.85840542 27.67197187
8.941296366 50.34863054 61.40156992
-5.71910158 68.50572714 34.04243043
-39.77023669 43.51788664 33.45458963
-14.00721902 27.35458749 35.550933
-50.27779363 12.69206846 2.922093547
47.02343639 63.75724724 38.32144663
-32.61070376 82.35651143 41.52675398
-46.59213961 20.70374954 25.74659772
55.78778036 52.97227396 56.97034149
-60.21304307 62.01202626 44.07204108
-75.5192378 6.991245576 9.822052289
-69.88077084 34.70781648 42.41767947
-63.94815564 82.98703749 28.53010351
7.29914989 36.5184212 35.81338795
54.37603644 66.48186444 51.90304617
35.65096493 42.44849831 48.96547931
53.55388492 40.58406407 61.36835955
-8.269631233 77.34847881 -4.031239485
-56.31957284 74.57897087 37.28264871
-36.92687166 60.13671831 26.44132353
49.62096963 63.61150453 39.51796946
56.6424958 47.75342099 47.47190389
-12.07088505 33.3698212 23.61884651
-48.60980314 30.70707315 32.36495711
9.944552127 35.41843292 36.42507893
-24.87523124 45.94867389 31.52887446
-3.227241854 52.621593 37.33597225
-49.89414243 57.91803892 43.01703036
-13.85374303 76.21419408 21.85457498
52.51963231 63.26430411 42.99573347
60.23245238 68.27446602 53.28292883
27.35078073 73.02122577 51.06293631
56.18429648 43.48458804 62.15517064
-39.68795512 80.31825419 37.40480073
-49.57537067 21.75781073 31.55912985
-0.3785413424 60.17492484 53.98765819
-54.53083113 79.18105594 30.13028871
-43.62833341 43.27122811 37.05374461
-46.87489719 52.82808251 37.43612021
-49.50834696 24.58197132 27.89463468
-10.69594385 31.91293226 22.78934434
-17.22499117 53.58727736 28.79505679
-63.52125952 41.35551894 45.60647379
-55.82750584 89.8909321 10.92904661
-57.22225181 64.03605112 43.31188492
-36.407366 48.34846772 29.1761267
-5.274764089 81.40981957 -10.96290108
-49.89965996 85.85629882 10.0224112
-12.85918812 39.25561192 31.18895933
-67.77906555 51.59020023 44.13963069
-69.62191716 65.81374507 42.51972925
-68.60498625 65.79632067 42.5280696
-67.16778386 25.88516926 39.4404879
-61.90550957 47.29800767 46.23328533
0.2476975403 51.76069438 46.6672214
12.6490976 69.79247998 56.38842841
-29.82441879 85.59963001 38.30675014
-67.58237556 65.77603115 42.56858713
8.423906644 32.93109286 36.45672939
41.8908306 67.25832283 16.13714392
-49.49789016 35.63876691 39.37667118
-13.13675256 29.64198157 20.48446929
14.09654111 51.04171124 29.06607852
-55.88251575 52.74509343 45.94396716
-65.54615872 65.73999437 42.59860959
-63.41012954 27.73296883 40.57983847
-17.23321746 52.58699499 28.75535162
-47.38315119 13.22077986 2.059040851
47.24130825 64.67655368 39.26119906
40.45053125 44.58265412 52.40854012
12.23548567 45.06190857 28.91921576
-64.53276036 65.72435193 42.5869373
-14.1891961 44.52745899 28.50955997
-40.15912396 42.49328346 39.59645432
-60.47282469 39.82256493 45.34084514
-29.36133762 76.49587369 -7.591185296
48.45660328 54.95594615 52.82044839
-60.93058425 19.67896228 34.55086407
39.6401599 68.20208916 11.43707206
41.26861086 30.21664614 20.91783713
-71.93960348 77.03386016 35.06989894
-60.18459629 24.82703176 38.73910259
29.9452671 32.74077689 22.90851954
-57.73605158 77.30902364 34.99353879
-55.09148738 28.75337322 38.80110618
-72.17111992 66.38776824 42.1688385
43.01444721 51.46011582 53.65396391
-67.53522845 78.58593523 33.69226049
-53.94262643 86.71971712 12.42352157
-20.54472769 54.53187032 30.11590807
-12.40910832 50.07066689 27.91353353
-56.81926674 64.54559678 43.14649626
56.21797592 42.46316671 62.3528703
-59.95167073 65.64347009 42.65223151
-8.17886022 81.06598092 -6.417541787
-49.91393394 11.0018747 4.993783718
-50.74359365 9.462009505 11.37460304
-40.98478922 29.24417971 30.41323282
-40.6073662 61.59872233 33.477771
-70.29964859 67.91670605 41.54283436
-73.52507831 9.69904508 18.79412897
58.3027435 57.46246076 56.80512549
-49.76815155 66.20539354 34.18695449
-58.3191826 49.74683671 46.25476102
-47.0055564 27.37342756 29.97192191
-3.834908009 69.65657952 37.80309239
-48.01765555 66.31348603 32.59830763
-49.877206 85.11975541 12.7418221
-40.53937164 65.04369744 45.91278883
50.32241649 63.53022247 40.32778087
-35.40325123 79.21566332 43.56072156
-23.41306536 52.97306874 31.27870113
-60.55557518 31.0474199 42.34537741
15.08436809 56.65006681 27.86485906
-57.38123881 42.73823228 45.86391211
-13.6154183 61.95759109 24.16423259
56.70594562 9.453885007 49.24406139
-50.86287651 10.99832088 16.86929177
53.30475876 50.79695074 53.56340306
-38.81851726 66.41802401 29.63042309
-37.82964316 66.72447579 25.88432237
-51.9114236 67.80874035 39.28943381
6.993854577 34.44055984 36.68637732
-37.77392089 67.04325716 22.1730484
-37.76462473 67.0881235 21.65043421
-12.1727654 59.36300213 24.87578792
54.949314 51.138588 55.11044308
-12.08602025 82.01621897 0.7764441018
-49.58491808 69.02714782 36.35487336
-49.17177374 48.82921136 37.68283592
-0.3716212022 52.37709725 45.45631562
6.356746888 63.01844649 60.48001108
-67.34403733 76.94567577 35.21720573
-1.044399061 67.43606467 45.56836113
-66.59100739 58.01526058 45.07260307
38.92227177 31.16577475 22.00040242
-69.37354255 80.84072906 31.17410884
-49.79759621 71.49684436 36.85859708
-12.15083835 82.09847157 -0.166043099
-27.38723548 47.98580282 31.66601297
23.35080927 28.33954338 34.47236855
6.253501231 66.58603999 59.87095776
45.85846847 41.22004755 55.45296032
-18.15125993 51.12222152 28.4546646
-60.42745168 16.9074226 31.66912324
-62.91787883 45.31665582 46.12076494
-67.65572234 9.538199718 19.54201804
-52.91272921 75.84708017 33.56508605
-58.53967867 86.99541402 21.75299952
3.495068585 58.03709805 25.62661858
16.09559704 52.55584587 28.58758928
-26.14269329 89.26493565 35.841566
-49.66735845 12.72622895 19.88904549
37.51727124 43.83137444 50.036051
-23.75582098 89.3989039 33.83117363
-23.7109533 56.04990925 30.57714528
-40.89507779 63.04082107 45.92491117
43.77694984 64.38567458 37.47196871
-11.78433357 82.21758478 4.207950761
50.52999131 64.08305535 45.52379026
-37.51391764 69.31741067 19.02999366
-74.81670586 54.2320097 43.94690303
-11.37604961 62.45184046 29.65284684
-49.33724239 39.89756991 36.51986793
49.20693699 64.12435269 39.47005306
-56.2548186 74.04409016 37.65294448
-44.03481942 45.37364001 36.03149558
-41.65341764 30.61733204 32.08011283
24.05643833 68.91753076 41.06346322
52.85059883 63.69867852 43.71672202
-57.32533862 33.41570802 43.36973964
-5.857319445 67.52146212 33.84157429
25.68207379 30.90021329 21.7815779
-47.15024239 35.48852842 34.84639707
3.574068839 59.12556841 24.62907599
-35.13267589 52.36741437 28.89129693
-57.57509074 88.25660415 18.58151265
-66.69040941 59.54727324 44.78676123
-64.62987466 38.90811249 45.095969
-64.96324716 46.3493521 46.17581859
-61.40058703 80.6841899 31.4708393
-40.06694485 70.57754088 45.67485056
-73.06820973 39.23746347 42.8818632
-71.97229186 24.06988836 38.12327816
-55.76874312 79.11172939 31.1711593
38.15141324 67.70752214 11.6356391
-8.010636221 60.17578099 26.29999675
-56.71252586 28.57997706 41.12315739
-56.60090155 60.91993228 44.40772616
-49.41311812 37.44954246 35.8196226
-55.8930962 7.380486555 7.387276491
-67.16076826 66.8155658 42.07378895
-51.60674337 51.33635585 44.00228264
-37.62877671 62.60542235 27.04986748
-39.97507193 63.23241672 31.8719943
-69.15138282 66.3263829 42.30556236
55.49345821 58.14290232 55.26845148
-65.18758606 81.87687807 30.00072949
-17.19928946 32.74233575 37.70781629
-52.62762836 45.32918256 44.01293253
-54.20782361 85.4526157 15.53025308
-67.60147143 66.30026884 42.31313599
-21.46872513 41.94701466 30.7202868
-29.20076543 86.0598062 38.6723956
-0.1751957652 58.71436808 53.42416505
-38.94936036 69.63360096 27.28645659
-23.46656408 55.52352473 30.81455356
-56.04172815 66.106024 42.36355948
-66.60682173 66.28169506 42.33906829
-65.05705176 66.25565235 42.34584076
-66.63293005 57.00719817 45.12964027
32.62401741 24.10404399 35.2595532
-49.59830419 76.51092605 31.0532384
-49.69773885 77.60841378 24.15840247
41.98624378 71.5652086 47.69445233
39.08355307 16.26091463 37.67683754
-58.97881827 66.15049668 42.40740889
-56.43682001 66.10710164 42.42639584
-46.95358521 64.71607879 33.45932957
-68.33041274 52.60019793 44.17326734
38.08126898 72.05502508 48.57964834
-20.16747853 57.67697869 28.4930962
-70.1750379 67.38879808 41.82077046
-54.85130692 66.28602932 40.04690554
-17.37770242 59.91390414 25.29976898
-16.69583873 72.39124233 20.16306627
-39.59276132 65.03696776 45.81038759
-48.10522402 61.58993637 35.00965153
-49.74080253 66.74336931 33.76316432
20.7916716 36.41372276 22.80241517
-69.47625178 20.27972684 35.03310164
-33.7676504 52.17439685 30.87166702
-48.12813294 35.9884753 35.05577936
15.67309298 45.88940381 59.44396594
-48.57837506 44.28914794 43.66091465
-42.41820653 54.34803745 42.24802062
-18.85588647 21.51907636 34.29878406
-25.09321214 88.80320912 35.17484121
4.77626043 50.21297897 57.94396824
-71.47094074 33.25997943 42.04856084
-72.29779911 58.64587362 44.66694682
-76.77205943 5.04446523 9.354056289
-49.73320479 12.72800774 14.05431145
-74.61228219 30.46852224 40.09306018
-49.72206759 12.67283397 14.69268502
-12.79351092 81.8642312 -8.977531533
-28.26502292 87.11254645 37.92624847
-67.52017601 83.05446067 28.42861172
-17.19789885 30.75754802 37.44185194
-47.21454312 66.83180286 32.25479007
1.600316817 58.02655192 26.11038637
-49.48047315 40.80080843 37.71501298
-38.68791179 65.60307566 44.89267441
38.09828235 33.09473569 23.07131954
-16.75222076 49.92573297 24.59764845
-19.99141734 42.47212977 30.16927667
-38.35278529 65.60746915 44.77776095
39.48322192 45.72814654 50.94858129
-58.32326555 52.77458139 46.06711503
1.53852998 68.53178058 49.83583441
-76.567764 5.517959857 9.645065678
30.02780481 70.64641661 48.98772485
-21.84299517 53.96599566 31.10594073
-14.51075054 33.12723564 38.55353796
-49.58844934 32.08221399 39.89451708
59.67833144 68.29381979 53.16393038
32.55045974 45.58297039 53.95601186
-25.95449331 54.03299707 31.11222683
53.66863818 56.06343781 56.44983388
55.75325046 65.94153854 52.08625961
-51.39968534 67.79837721 39.31214511
-59.39053303 70.3635217 40.19118303
-34.04290277 55.87831326 28.71288025
1.616865418 59.76302867 58.38871424
0.2550364682 67.70401585 48.03661931
-57.97728022 12.38374559 25.44959511
-46.60482166 72.62516031 34.80468225
31.67874815 73.57024513 49.69079021
-11.83362345 83.04199889 0.4735903222
-38.54340231 68.06603716 45.40646556
3.254929093 63.64823056 59.58719397
6.616788391 63.53815408 60.22391541
-73.10862833 36.30650075 41.9544801
-11.65019697 82.75124825 3.813855999
-67.1665753 72.62781671 38.69509524
-16.94893901 33.71959222 37.96864839
-13.49136588 32.91515166 23.34123564
-20.53982223 52.35281671 26.27781206
-56.39596242 65.58427538 42.661312
37.28141139 51.92386355 49.36376551
-43.4321296 37.85849038 35.75828717
-61.83805628 28.18550252 40.85333426
-36.76190263 75.64217479 44.51700134
43.78513235 64.88376301 37.51487507
-5.004290888 49.85382982 29.01859352
7.037770046 51.73487154 28.19215867
-10.55395713 70.56149798 22.75285815
-59.76835126 68.7872931 41.08132462
44.97268052 64.80505015 38.202159
57.17845796 5.346469406 50.22270141
32.53552719 33.67746866 23.19402158
-63.15199554 37.90151347 44.84618279
0.6721383618 59.48959302 55.91647867
6.383295278 58.007672 60.37726638
44.12583662 67.93487682 25.33690652
-32.3957995 82.37199421 41.30603496
-45.83506611 20.79262998 24.57043012
-49.57153666 82.60980925 18.51425327
-20.82865182 47.59451319 29.13098598
47.96665177 64.58321718 40.20639009
-52.25846714 17.50110437 29.04599802
48.44944249 64.48834494 41.21566526
-13.02224126 58.48974615 23.52189879
2.044005199 68.42546536 50.97362212
30.71019358 45.04272347 54.75190142
59.38609244 8.039035886 53.50426138
24.51409696 48.85246554 46.66711193
36.81569873 69.15979932 47.47104824
-50.54544379 34.46898378 41.50286082
-50.06084533 84.92171528 20.9024798
49.80944168 64.22352932 44.03047873
-63.58537995 85.29607087 24.96253833
1.340085593 65.22214222 53.3345317
21.57858814 72.95961297 52.87897467
-57.25369084 84.58562367 26.17579612
-37.80811977 64.88485833 29.75591772
-14.00550608 74.28188546 21.00860209
-49.47765602 50.4968179 41.68894007
46.25766386 66.40017683 31.092991
28.47207265 69.93120232 45.93385946
-51.43630807 86.37220838 15.97968734
-62.33378262 34.46881837 43.75288825
-6.437813661 82.77221065 -9.076807564
51.17047986 63.95818959 46.85117827
52.07084217 63.90045574 47.34969058
-49.30576931 40.38390963 36.69472036
43.80566065 67.52169497 47.67440871
-46.78401641 10.40081274 -0.2788270909
-49.89273139 9.838080087 6.846635993
58.50862856 63.57676012 49.87966143
-57.45955342 87.20535033 7.456635132
39.07961708 42.24278415 50.69969511
-58.72215204 48.24524004 46.28331703
49.80688024 67.82132609 48.87824449
-9.383023362 62.54336114 34.03696755
-54.92745902 78.63113668 30.76315139
40.63877806 18.75558923 37.55344786
-12.35761093 38.36983166 29.72280723
59.2849446 8.493163595 54.07834599
-2.120112892 67.68136441 42.92588733
3.22235067 68.17794973 53.62224704
27.60305511 45.01191943 55.70204731
-63.2077161 76.86615258 35.35154766
-63.25402073 15.58771869 30.04833003
-56.70343629 8.962331188 18.31181248
-60.72625539 34.92824548 43.93959893
-67.25115788 25.41262157 39.11542536
-17.58762215 82.89207375 20.79127876
61.84860653 4.378215547 60.57227516
-55.74707565 80.70655892 30.13300455
-23.943487 50.44460963 31.59884995
1.221126059 51.51743582 49.30550611
44.37912107 67.85666551 26.19653782
-46.84298136 46.0849517 39.96288613
25.57991257 68.89236375 41.06508452
-65.4776274 44.36457279 46.00811717
58.32996903 5.211601566 51.56874587
-54.26694668 56.96375462 43.27569719
-3.560054696 68.0112842 39.37054254
-72.53764524 70.61516463 39.77716128
-72.29084023 84.30973729 26.41952028
31.0199767 35.20528668 23.22694224
-47.15578049 50.81224029 37.58449045
-43.80952833 45.33432755 36.44489908
-50.09581172 83.68714316 23.58768092
-7.011106443 47.86617129 29.16893262
-4.58134894 52.95776997 33.69162045
-41.35740274 62.4116128 35.83746384
-33.27364271 47.70258921 30.24969522
-54.69684523 64.71385338 40.78849185
-40.12722267 61.04106515 45.68653909
10.79605093 68.25424802 57.1188122
-66.16481406 66.79633398 42.10711153
17.26186865 36.45984975 22.94009403
-55.17714568 69.9782968 38.03299527
-49.65642421 63.09284078 41.16522801
24.66989544 44.01281059 56.20652129
-59.52957857 66.68276015 42.16018016
-15.28532424 42.55119176 28.35398694
-54.86460467 66.8073422 39.82430195
-53.84358709 66.78785631 39.85579417
54.14859919 54.51290063 56.87814702
33.67790589 71.54403838 49.52466194
-37.82908245 54.61572265 32.43871953
-7.39314172 60.42272221 34.96884608
-64.95127756 65.2102524 42.80815284
-50.28954116 66.72950018 39.85545695
-52.03601456 63.10386635 41.49103741
-44.60401125 50.83824993 36.79591373
-55.99965881 43.71474102 45.91769226
-49.59456589 76.50238136 25.32507665
-49.75849174 85.74219104 17.14677807
-14.98475534 34.12299653 38.73769623
55.29923198 46.26868587 59.13732222
-40.41243348 46.8436731 41.58203238
-49.57436944 76.39308203 26.59005165
-11.93786641 82.39414444 2.187596744
-8.536965801 45.89606627 29.02377638
-12.11520997 80.63672467 10.96954807
-48.13185632 67.37552561 31.94443753
46.60657642 41.64222161 56.23602798
-20.08056756 56.10171131 29.28345256
-38.28825216 66.12668773 44.5646267
-38.14842308 66.22959721 43.34331188
-69.45375081 46.14249012 43.60694809
-40.21175148 67.41909146 29.92828145
42.62759441 70.48503164 48.45834377
-63.57055046 66.75135202 42.13455327
-49.57482854 76.95174429 31.75806261
-38.0271892 58.6962172 31.72026792
49.75240959 64.92795964 41.69045432
-57.91906778 78.40577653 33.94981463
-37.74625457 67.83551035 24.62400116
-53.14979944 33.53940238 41.13750443
35.6160955 72.56188259 48.99244079
-41.01138616 43.43780428 34.62093893
-50.87907135 85.0123498 20.00636862
-37.70202489 68.06097764 21.99817706
51.09836711 50.36705892 53.14803231
-36.63642993 68.24200579 19.69345446
-30.0751713 44.58730255 30.84440349
-32.25268296 82.87634997 41.25045291
-63.40516432 47.32354808 46.22278673
-57.01125472 21.9474223 36.60268978
12.4340779 69.77705543 56.60849343
15.16525571 55.61722067 28.1861834
-4.72013234 67.24515274 36.83230724
-46.20762001 43.79848215 37.25152123
-17.2792406 57.45226728 24.72426446
-66.58071902 75.3017573 36.6755192
17.94927569 43.93406611 58.40230753
-17.2818134 25.03252417 22.34513506
-17.33855385 57.40785374 25.25116068
0.9081613525 64.7891393 52.61687515
-19.72341785 18.74517564 31.70584572
-2.29980258 67.72245152 42.483186
-46.06000095 19.27555895 24.74467154
34.45814492 29.87923055 20.30695922
42.93990666 68.53976728 24.36770135
-14.8682427 46.10408951 27.8163031
1.105686948 64.73905503 53.16062068
1.697397915 64.58923897 54.78695223
-6.829164372 81.63069086 -7.40386123
-55.38600217 38.45070191 42.81642163
-54.08329123 68.90524804 38.62784206
4.054029626 64.06945974 60.37150597
60.65056413 5.137883134 57.80861372
4.67052216 64.03103212 60.70003304
-53.84596428 38.91939555 42.90843025
-55.78687412 85.89080333 22.39786849
-49.24997697 28.81052711 31.19696884
-41.00068333 57.05418264 33.86934433
7.532431951 64.14837274 58.79207379
-10.92858421 83.2230254 -7.62713615
-1.986137657 31.80591903 22.37061954
65.25994671 45.90233111 61.49066804
-7.245754837 69.38160376 29.99239759
-55.41498583 10.68625712 15.53342906
52.42688187 56.82037863 53.72616329
-49.52817715 38.41956251 42.06078807
-55.65441348 66.71019694 41.10265655
-60.4809986 69.32688544 40.77988982
-47.22043671 43.3182741 43.01927795
-51.07864756 85.03869063 19.73864536
-34.03648856 81.27841214 42.66074149
-46.48592308 12.55664064 -2.055535623
-57.90277428 11.08729221 23.00564023
55.13235525 68.97070535 51.9997344
-62.91563807 52.34654984 46.08516981
-43.83639342 54.56787176 39.96647995
-61.05683823 75.74306619 36.32568716
50.50158724 38.87601088 58.47221497
-67.87228092 49.10136389 43.91643156
-58.53888687 38.80891197 45.08597318
-68.01513429 70.51427656 40.08587375
-59.23238582 17.8045717 32.67973745
53.50455601 69.60026988 50.82836165
-64.3460564 69.39346155 40.74411548
39.6341939 67.70288263 11.40672265
-34.48581102 55.90351145 28.50482658
-70.29193054 15.69380613 30.15896126
-30.34996916 84.99085525 39.64743685
38.90144957 43.69064007 51.40582735
-12.54359919 82.13326839 22.81147916
-11.36903924 35.7631802 36.48763071
-18.9542324 20.57664294 33.60471224
56.85458924 49.6552965 48.65966126
-66.89643406 23.98720378 38.11514452
-32.52813854 50.58760115 31.57604462
31.88972006 37.27097379 22.38763688
-31.50152715 52.59893547 31.3377905
-52.04568262 21.16371682 33.10026817
-62.06420674 38.37533934 44.96485943
-39.7619992 45.02703832 39.24024901
-29.50557689 54.60446383 30.98207746
8.47941502 34.93673413 36.46975966
-49.6267413 82.99120563 19.9238948
38.15959574 68.20561057 11.67854546
8.331137356 51.89051135 61.09857586
-57.05904634 24.77421473 38.75618003
-59.13313401 84.04036138 27.03737905
-6.998662489 67.79986792 30.82729705
-17.82587398 59.41476347 25.35298626
-60.72249556 62.02123636 44.06227914
57.34608785 9.329193954 50.56948739
-68.15889978 34.17946541 42.39615233
-55.79466488 13.35262504 25.43917926
-51.43030017 85.7182946 17.74300939
-49.76229341 81.96269912 26.06275966
6.283839153 57.38512387 21.01009289
-55.14734249 69.4486579 38.34910563
-21.52140717 74.34321695 10.07666527
0.3605308375 68.77912151 47.18917151
-37.05285314 57.77018089 30.63127955
-49.8364597 83.04078151 13.56173444
43.67304447 69.44878897 48.63514963
44.98716033 66.34063184 26.19988358
-23.42222234 42.46831816 30.86779993
-49.476955 51.87922307 43.12076109
-56.67144567 82.28305133 29.48813564
-66.76489422 87.18009203 21.17772025
-75.47832482 11.24943201 18.64852443
-52.18684736 70.45875547 37.71185344
-46.00201612 40.83013813 36.71116962
-7.369424931 70.39886435 29.86016067
46.41768751 65.91634975 30.85245037
8.889463488 55.20831666 28.30322995
-55.71553482 88.90342191 -0.93513337
1.452067847 59.79660359 58.03037992
-20.86826845 44.79942412 26.62612918
-49.59288396 57.93951682 42.71024736
-57.84635929 44.73394361 46.0915034
32.62397778 22.04564417 35.84840459
-41.28337997 54.17203025 44.07482278
-41.05119065 48.26277284 31.05654098
-25.39511086 46.95518698 31.59696184
-64.8989719 47.34613894 46.24541364
-39.47871926 44.8279257 35.67102898
-67.1592646 22.11092214 36.63995072
-70.61332048 59.11603901 44.71432458
-56.80224482 50.22718298 46.21590268
35.21167315 69.65348217 47.87255564
53.71087782 64.24437285 48.8711262
-38.16102151 73.09353175 45.23712581
-13.1386227 49.66389132 26.94816292
19.98181743 49.77595048 36.81095609
46.07104192 52.4093479 53.70495597
34.89196843 69.66608717 47.78719728
-37.0575624 59.29159344 30.45043307
29.23993025 74.0638078 50.25289264
12.15905177 54.64256942 28.42066575
54.30290777 64.11917375 50.21162535
54.38184603 9.232036994 46.43601342
-62.28386619 68.82971779 41.0685516
-64.02815162 56.42991823 45.50773946
-60.500076 66.17454559 42.41834454
-50.99023002 29.16897585 39.02099406
-71.40543202 84.28432475 26.54567497
58.76299579 64.00021864 50.74197762
4.507663213 46.75010781 28.27503164
10.97710656 57.2543139 27.46023809
34.062776 29.88044796 20.36822597
-68.99480977 64.23853696 43.20637892
-36.49057198 46.35679826 29.0061836
-38.12519261 79.49849615 40.79648178
59.34849558 63.98981395 50.75110448
9.800102715 57.35248564 26.54505051
-50.57978206 46.79392748 44.0984906
59.78370167 63.98088998 50.77170402
-4.904916858 61.62242543 43.87386114
-37.48619775 52.39006778 29.07715039
-71.7733565 31.75721509 42.07149584
-72.29017073 59.14424158 44.70671395
-34.4694744 47.2832646 29.51893268
-20.90248934 44.21535909 27.5862677
-63.26325887 29.16552934 41.40154423
12.21577534 33.27976309 37.51261993
-51.05433072 63.60643803 41.29624639
17.16632042 30.74921124 36.81180938
-54.53174628 76.42265891 33.01874511
-50.4382739 29.6405412 39.26809888
26.29541455 71.55139041 50.84719973
-21.03130484 54.98220148 30.8075695
-37.5821479 41.19149357 30.9105716
-49.9494873 11.06936497 4.217087496
-33.78769366 52.68672351 30.75466847
60.43649589 44.37582107 62.65144911
-68.12391013 78.59794499 33.66510753
9.833602093 68.67207191 58.27859347
-11.80464178 77.76502845 20.9405045
-12.20822767 82.70582858 21.92746601
36.55224779 45.91820119 49.30124226
-66.43915582 55.97935205 45.37139253
-43.55124749 47.2872378 37.03139955
-65.02195695 55.93346112 45.63385967
-40.49185694 47.85007386 29.91414254
24.67889453 48.81889062 47.02544625
-64.32395314 73.64781851 37.96534655
-32.49031987 51.59773377 31.49575743
62.20516191 48.28093535 51.94058103
-70.84820262 87.25765123 21.05606779
-38.82610454 79.10207019 39.70551069
-55.818629 90.15901066 7.815301707
-48.87410048 42.62768266 39.69824865
-51.43350395 36.43026461 42.21080361
14.97797467 35.38193228 35.88890111
-72.69965686 67.46059308 41.46878167
30.94006742 41.80104026 45.72675895
4.963377628 33.97148516 36.69232184
-12.67076988 47.06547424 27.89005805
-71.78588769 58.63542314 44.69063913
-66.36235186 73.68495591 37.92296334
36.24968777 31.67814718 22.38872808
-12.05723521 69.4167191 18.84904766
-69.23784269 67.37442884 41.80885034
-72.1229463 71.13119026 39.53431444
-75.01002066 11.18664525 19.28809072
51.66669261 66.11984832 50.7956405
-62.8231875 77.9491691 34.35905126
6.540535775 53.24456634 28.24126778
-10.64496717 62.02683797 28.62052308
55.60458154 40.99338342 62.05224267
-41.87912249 40.37906162 35.33469329
-57.89509859 70.86710406 39.88668393
-38.08264353 60.23083871 31.3957596
-60.02910292 58.40372716 45.13824586
45.64692865 67.46491012 30.50254242
-52.67181053 42.35056263 43.63950693
-54.67782055 44.36783702 43.9106353
-63.13213594 67.26780347 41.88224052
-56.81472421 67.15349688 42.00442657
3.65292856 44.24896723 28.33989538
-67.12381696 31.16436614 42.24038315
-16.63003281 71.42794865 19.67985088
40.84026751 48.55245055 52.86304808
-38.53245739 78.52606011 40.509615
27.82906367 73.02773886 50.89611614
-49.55582789 28.25864196 37.66198309
-49.62001048 67.83553604 32.71474165
-9.067964456 47.91262031 29.02197356
-40.98567857 53.77888 31.10209959
-48.19945045 67.91183887 31.55805317
54.82896835 6.104694427 47.69537425
35.44520254 47.04172965 53.94954761
-47.34129829 67.90017539 31.52979661
-73.59819927 41.73094665 43.16996743
-38.09195513 66.72810635 43.3721261
-63.85381055 77.42172864 34.85187019
-17.72948182 29.24649572 37.60470329
-49.61191171 68.63765991 35.05484009
-57.98174185 16.41038335 31.14606508
-60.73345551 68.80335187 41.07895381
-10.79401933 68.72328677 20.83137287
-40.08783156 67.95790864 29.47630699
-53.60048491 63.6519295 41.25371516
-42.57265255 34.93197384 34.60767402
-4.999520711 49.34920752 29.04906684
-17.01466547 33.22575346 37.88738275
0.4196933303 52.17614871 47.63816484
26.93887633 68.87584899 40.99763742
-5.042278342 41.40965338 27.99910539
38.85153649 45.22246634 51.1127114
-1.005285919 65.81057491 46.95085099
-2.219662683 59.20056791 48.16989386
56.49096243 65.91629645 52.23860214
1.144637967 65.27117828 52.80255831
48.39043511 54.41277449 53.31195934
10.09997942 31.84929473 37.04211287
-37.8822285 66.00100742 28.4662591
16.71903984 74.43983028 54.10219976
2.120663948 65.02660945 55.45555819
-56.53942392 88.81773815 6.043308835
53.35555422 9.27896127 46.08700213
20.49319667 50.19365091 37.6911026
-50.31318856 59.462626 42.64617353
-13.79700328 32.29455523 24.77721817
-49.70711514 82.8268677 16.02033564
7.030828379 45.20956487 28.1976729
-52.31788132 75.29257712 34.06208036
-17.48827765 61.52588659 24.08771011
-72.63103114 65.36755565 42.44663689
-11.1963062 82.02673011 12.13803287
-46.94990987 11.69381 2.222677035
47.80972627 11.17969962 42.5593889
-61.34692217 33.96434671 43.59432236
-50.84130582 67.26227029 39.60253835
-17.05381128 48.67698982 27.498204
35.08158664 34.13424102 23.23256681
-2.446450787 66.6928834 42.80984911
53.29718946 45.25827382 59.59544257
4.513433776 64.56293424 60.38192365
-56.7964886 48.71763123 46.25886064
-55.85541562 58.35491071 44.90899559
-18.3881893 31.8172984 37.01977336
-28.93151688 46.51481773 31.55686244
5.166269202 64.53095163 60.62870146
-41.84647152 53.87952704 41.75115714
-74.77011578 35.8371266 41.89353254
-53.69410191 55.93052375 43.5076686
39.13572075 18.24346322 37.95858155
60.90088565 38.56097299 60.14619643
37.96654623 31.65454069 22.33535421
-4.197912918 69.18881087 37.4758785
25.47489107 35.30166825 23.1655557
-20.91981481 44.07543164 29.21395198
-38.68551339 69.04784546 28.20937605
7.365796183 64.58597953 59.57043429
-6.040015078 61.34590936 41.47369033
7.636981814 64.6226274 59.09328226
40.09907307 48.10201117 52.40678639
60.67841097 67.255686 53.37134208
7.894748732 64.69082207 58.25247091
26.9872946 47.59822468 54.92896192
47.11423845 45.59542825 51.03399149
-18.77301147 47.64778982 28.12048892
-54.05256868 24.94251869 36.22903116
-56.08337348 88.95935549 4.312339519
-6.76405961 55.47301398 28.21565096
-37.26837787 44.6726166 31.22580342
-58.85817708 74.62065382 37.28288958
-49.51778902 73.66189653 34.97829371
-49.74845649 12.22016741 2.472676605
38.32346568 67.72650221 17.2091349
-44.64998585 34.96397358 34.63235759
-56.23995494 34.36818529 43.75898103
-56.211623 62.98020692 43.72288022
46.08776986 66.88898291 31.27761396
-40.15451771 78.82395865 37.36072745
-50.456161 76.34899556 33.09664444
-65.07013364 12.49277097 25.53659859
42.48766181 67.55858423 18.36431244
-70.55251481 53.63532344 44.23382497
60.588538 68.77050289 53.28330404
15.55721133 35.51096517 34.280529
-51.99328273 24.90723384 36.24592527
42.73886829 67.48142112 19.2121716
-61.1566061 13.7597257 27.56245329
-49.63692865 84.98043079 20.14002375
-3.247831047 59.87454409 46.3686049
49.11817876 65.95498938 41.54217698
27.99911008 25.71367734 34.93567466
42.98920896 67.40469478 20.0551257
-70.99049857 45.67213681 43.53357869
21.05248215 49.55797556 39.13718614
24.68338474 25.85003768 33.98503291
-17.92733743 22.01128462 34.23443231
25.87410963 36.32771884 22.83155997
-57.05338785 87.14913095 8.03181263
44.24593403 67.01852986 24.29834556
49.82642435 42.76353959 60.08482958
24.16412144 71.13317152 50.28199924
-73.04685013 22.7088457 36.64825645
-44.10599527 35.43955708 34.83433588
-35.51639752 52.88975028 28.72745937
-14.84384576 81.5937944 23.68611699
-38.10075632 67.66078662 20.89328593
-51.78451848 67.27967498 39.58036832
-55.56477786 86.94332806 10.13703625
-49.47497099 51.4998955 41.69725287
-56.51146201 86.96341286 21.73769924
-13.94696657 61.44917658 24.30286674
51.54477517 64.7738118 48.96474219
-55.86808912 21.03533289 35.31959878
-49.66189252 66.05268072 35.93949231
-40.11618518 68.06652174 45.70077935
-49.48383523 50.45037923 42.22921247
-13.47336357 74.75105669 21.28727828
-61.96864092 8.638314017 17.25070531
-51.5407065 24.42449842 35.93692036
9.955775597 67.2772395 56.96760384
36.09611008 72.56752217 48.83543074
34.36483637 31.23472444 22.06911441
-3.355267121 68.50930843 39.37670056
-49.51723825 25.79577027 31.28558942
-34.76026882 44.81976164 29.03932462
14.23815963 44.03836014 28.76610914
-49.51983955 24.48865724 28.98008455
-57.46755067 12.37439572 25.46092667
19.58532946 44.930875 58.17189893
3.212848141 55.98755041 61.12637196
-37.21501634 68.76352803 19.57622441
-66.96785169 84.77805088 25.79450929
-54.71514757 42.88001822 43.70951756
-60.60010386 87.04366248 21.58583162
-62.08765517 46.80095084 46.21157914
-55.86962324 58.86429364 44.82505714
-72.68483863 27.42450329 40.1028333
-40.96929704 60.54240231 45.80932902
25.20827582 25.35560155 33.79806759
-69.54066769 14.78118565 28.9567736
-1.756619757 60.97585424 50.77933339
-58.62295405 25.27243835 39.09732423
-40.59089575 64.54269442 45.9119825
49.25794827 41.94675867 58.02174818
-37.9768436 67.35050144 24.47167241
-40.08825876 63.03027426 45.89348095
-45.72010644 25.96168164 28.6354329
-70.99746892 45.17343692 43.49753941
23.7813759 45.47210713 56.91531084
54.81155241 56.53423401 56.59316833
-49.59995074 64.67011275 40.32425661
-50.03563566 27.24846601 37.82706323
-55.73254349 39.78526963 44.86979616
4.554337891 53.75758754 28.49116411
-67.33145552 62.13394466 44.01424723
61.55229376 7.491428253 59.4481838
-52.17313499 17.94788645 29.66979326
-72.35121895 82.58576817 28.96420437
-58.78067202 41.77072945 45.70898965
-60.36596963 51.2948536 46.15450847
58.90839287 52.8847808 57.39090646
-62.47961709 42.33006109 45.74791162
-31.51273903 53.10680723 31.27082355
-55.91934186 65.59162704 42.48507412
5.256643584 52.88483882 61.7953032
-61.50569299 40.827683 45.52295704
-72.30973634 67.9643401 41.37319931
-53.64826621 52.88171727 43.93185285
42.60221407 71.57660013 47.44474184
-1.805074831 60.04542062 49.93644468
-9.991535091 61.39322695 30.02468817
-50.28645444 51.3145724 44.00337384
5.949510794 33.46738037 36.5296357
-72.97610572 48.19372412 43.77302469
-35.96547064 49.85520642 29.08045486
-5.45531069 37.55071986 26.26206232
-21.90147987 55.50214581 30.76426471
-41.27862007 61.05198638 45.77933588
-63.68964964 67.80347364 41.59675234
-49.70671117 38.38573309 42.48755225
-63.10175586 67.79186132 41.61944169
-60.07402792 67.73883401 41.65761561
59.91472682 64.41701157 51.51054009
-13.94979411 30.87164003 29.67131342
-62.44113647 78.49405259 33.78742738
-57.6089197 67.69705866 41.67246338
-12.97323283 74.25672176 21.10386008
-69.18415202 81.39607589 30.5177604
38.24053728 30.25745888 21.02152481
-56.56545351 67.67863771 41.68731197
38.7406389 30.25296574 20.97831212
-55.93069492 67.68055165 41.54404126
-18.76975048 21.9902308 34.63949374
-14.77947894 70.90901827 19.52442081
-25.14864012 89.30860742 35.14502018
-28.18266127 76.72562932 -4.656495636
-47.6397537 26.45352091 29.11853292
-44.59179312 51.98518579 35.13239672
32.68789469 21.05852553 35.64212707
-55.28127653 17.53926165 29.17950846
22.06231522 72.48594164 52.45880651
-55.69167453 70.39317224 39.14158323
-16.85412889 46.61695348 28.06796525
-49.47964728 41.60660104 40.01390893
33.38736601 20.06053046 35.44090695
-11.43176026 37.14614125 32.09845185
-44.97873867 19.87656633 23.38817679
-49.93918026 86.86254761 15.82860126
-49.66909033 43.81679831 43.52565892
-49.50830299 67.83540087 38.52164938
-38.77871208 67.05641569 45.51848158
47.44420952 41.11391491 56.38261524
-49.61447593 77.82085004 27.50305001
-49.95676663 13.05232217 21.98535046
-11.26227908 62.16479033 27.13679622
7.09534356 48.45327125 60.44975442
-66.12235485 71.54263859 39.44021408
-37.77265188 68.53114618 28.20686932
24.9007601 74.52487161 51.5546744
-66.83209716 68.90641332 41.0455889
-53.80591573 66.26663731 40.07265971
-8.513922017 60.1885393 32.07451042
-37.50574234 68.96639675 23.10326094
-37.50982546 69.21623499 20.20373457
-18.4386408 74.18236245 17.18272172
30.75763713 73.03711804 50.22873913
50.83226398 50.37740293 53.07869887
-49.53121118 14.08986521 21.51291935
-41.82728365 51.04811564 33.83010031
-40.67744397 60.58008466 33.66294297
54.7525363 57.6179919 55.67665214
-22.32839675 50.9249995 31.54076723
-64.87294067 37.92718145 44.87640479
-50.27843233 15.23010653 25.89858895
-63.94261127 44.83579961 46.07167646
-12.95219077 33.36838643 23.8035727
-37.31774094 69.39189611 18.12790005
-15.36048266 69.21801404 15.95906884
-52.49171982 76.38470041 33.07034982
-45.63507688 50.7884709 37.57041555
-52.411623 30.14708095 39.59078628
-71.6411029 86.65984941 22.32035778
-50.48117213 62.56065992 41.67381542
-40.39319539 52.30878919 42.22835739
38.98389179 50.0044162 53.841516
12.82802736 47.38171472 60.14262563
-55.75211986 68.26613211 40.53878137
-40.10114174 69.59516018 27.95235697
52.28525883 52.14887416 55.54363713
-11.36495059 68.86262069 19.32276274
-40.89931932 64.04739272 45.8939835
-37.43319373 44.87964572 28.85388923
26.93201326 26.24623458 34.78350423
-49.47591236 65.25842588 39.29767435
32.01080361 35.70324623 23.08394864
37.44829606 50.03569778 53.77122388
-68.94987923 80.27346708 31.85186402
-16.90843153 55.65945654 27.98588224
-9.698142502 39.63662079 20.33645724
-9.086420445 68.30790552 25.32777293
-43.3883964 51.4705958 35.04999692
-37.50877387 79.38577219 41.98750917
-12.87026647 63.26179913 26.36185202
-12.93716777 32.88337004 23.604491
-7.278000662 39.35736085 23.11677527
-40.98896714 50.8415763 30.24124629
31.88751935 23.07468597 35.69626516
-23.71621096 22.15630921 33.65485387
12.73583127 31.78333396 37.30516091
-8.897206728 68.2620141 25.8244299
41.41352643 67.39998313 14.5836723
-53.70606959 20.75573218 32.32645641
-7.570805907 67.9398133 29.31181983
4.022975014 53.46425487 61.13092763
-57.22334949 69.26937684 40.82623842
-6.482639687 50.87305469 29.12189919
-62.92275664 70.42325869 40.17132862
-43.25039165 53.26117754 37.54386294
-8.66024151 69.69868295 26.58126153
-65.1484369 10.77118478 22.23039637
-11.55298396 80.81243417 20.4758475
22.85977613 35.85938451 23.01653737
-64.75940512 61.57317407 44.20693521
-49.48883833 70.00789118 36.60464756
-55.90734221 34.37384682 43.62982655
-55.75215883 26.85477809 37.66081427
-49.46115457 49.13051294 40.0669353
-49.48576385 49.54603147 41.07462708
-17.67713313 62.02425762 24.16491229
-5.667586236 67.47530865 34.34117429
-60.28314086 77.3549868 34.94571009
-59.70564576 29.10537377 41.42141584
-60.42281846 14.64178383 28.83622302
-63.50450883 55.91133393 45.60134176
-5.477853027 67.42915518 34.8407743
-40.97416677 31.148574 31.61017211
-55.78833782 87.74507972 18.35227608
30.99922188 41.18799181 41.17890917
38.78763813 70.51416766 48.85241434
-46.87940604 11.3542518 0.324429716
-46.72936384 11.20423082 2.037369088
-3.583118399 66.96893072 39.82205919
-1.691327562 66.51019123 44.78666688
3.47886957 45.76908421 28.20638487
48.21697306 69.38665266 48.48991873
-56.11379407 68.2007968 41.36621482
50.68331557 9.964604469 44.46380805
-59.36819785 52.79374192 46.04396055
-19.57321232 42.48417077 29.94974176
-78.61330595 4.939254245 16.75320103
6.307681988 60.54450563 60.07547452
0.007959439563 66.09900284 49.23597837
-55.80237845 16.45080473 30.26120636
-57.44354525 40.7607981 45.52472987
3.486886083 57.53900961 25.58371222
5.402457175 56.37635237 62.0219445
42.36830792 67.11657518 17.69159656
-72.4253573 67.44611444 41.58454966
-3.570975329 60.44065588 45.6850325
-55.80458072 79.50813409 32.40287974
-55.52720988 16.23100596 26.93367319
-0.5742023999 28.99716568 19.74756808
61.33974404 47.9674929 49.91764882
22.76311193 31.89041768 22.49650817
36.95982857 43.90195953 49.3229553
-43.54933947 24.09668673 26.56511272
-53.26656667 17.51407387 29.08768927
-37.77354869 43.57612339 32.39775439
-49.54130048 85.89852835 9.463838643
2.665782843 65.45206807 56.23920757
-49.50395984 38.20246453 38.74975999
14.17300886 55.1199774 28.32113271
3.827169212 59.59922169 24.90893626
3.609600292 65.22374654 58.70973927
-9.033408706 50.92087406 29.053224
-37.49574254 53.67852995 31.60148456
14.06092805 29.82459469 36.48436822
-36.77993555 37.05977201 26.28185545
3.799160336 65.17768042 59.20835827
-29.61502763 76.54951173 -8.165474716
-49.93620205 86.1977732 17.71857924
-29.54904135 55.11621618 30.87621254
60.68659347 67.75377444 53.41424844
41.79336299 68.2128101 16.72862859
-58.39372482 54.8065991 45.79797106
-55.76943738 85.23112438 24.22593731
5.647574353 65.03815622 60.47555351
54.32692133 45.16005853 60.53922146
48.96305837 41.50657467 57.36132162
-53.89289509 67.31494531 39.57300465
-55.66800046 42.88989691 43.77655333
-65.60876041 39.91077741 45.29626403
7.426131465 65.08253783 59.62115777
7.635985106 65.10056835 59.37182591
-68.67938274 10.81788828 22.36160056
7.798303677 65.11779965 59.14083724
32.96216569 46.05095009 54.27148803
-50.5462632 69.37008138 38.38382583
44.22390127 41.80347041 54.81852452
-49.5513019 78.74385882 28.42932471
9.550284157 59.98958445 25.11252907
-6.440630959 68.6714732 32.25592893
-49.7219332 14.01262821 22.44591482
-61.01116278 81.23774296 30.79716654
-54.04137201 59.00749677 42.81399181
50.20582654 66.36121175 48.27231314
-16.35815244 55.72876325 27.0763777
-20.70267223 48.30573636 26.6772038
3.23793705 62.05104255 60.65183603
-49.56087053 21.9487191 29.34016055
-49.84266244 75.25809047 33.99038741
36.65268613 68.55119275 13.78079326
-70.33657546 54.12772225 44.30316014
-59.87846737 74.63977284 37.25551761
-47.62556935 74.15115813 34.76437127
27.37378715 32.29682768 22.72596179
-62.92217452 52.85206311 46.04469018
-44.78160596 47.4618795 35.23866775
-40.96042211 64.69100288 32.60749663
-49.50536513 38.60627803 39.88890734
-55.69605493 66.80553615 40.0038305
54.40767425 54.00388584 56.91111418
-41.85318967 41.87262812 35.47126047
-55.89090097 9.426339023 18.59695647
34.00439687 47.02519525 54.41626131
-46.17107042 34.98793514 34.64427426
-64.10715583 22.05798506 36.67242746
39.21857868 68.69396729 11.63403081
-37.4347967 57.21285426 31.34733202
-35.37385242 53.3943943 28.66863999
42.04647428 68.13468614 17.58727888
-20.89245984 50.63999671 28.74880452
-60.46502029 41.80271432 45.65890121
-36.18314114 34.64409007 25.07783739
45.58050829 67.04575495 29.55442731
-3.872201789 51.79383796 35.41487249
-49.53874732 23.44420733 29.45514547
18.41649026 34.37742231 35.24095845
-57.91749851 75.14391024 36.85579499
-63.91941765 51.85512415 46.15478374
-50.38853812 67.78387033 39.28771967
-52.23830222 85.82854958 16.61717969
-47.66122472 50.31721244 37.60088537
23.38289578 70.07016129 45.29125879
-9.334141848 58.6429833 26.86630893
47.8531418 66.34534715 37.25186856
-12.27755709 82.64168975 22.68525797
49.87803211 65.72035544 44.12107089
11.54255533 35.88266434 36.55783396
-50.25912667 19.74697113 31.72625633
-8.3077887 69.62000277 27.42742371
61.87823036 51.86891267 56.96419743
-52.60049972 47.82657789 44.1493758
30.61709994 24.12530096 35.39551792
50.63875129 65.48528473 46.70486985
-64.24539707 68.33825282 41.32127047
31.21299701 22.11317629 35.33352485
-24.89360906 76.03283335 2.758744723
56.90429051 64.95114338 51.71069737
-10.80745408 48.51180355 28.22458914
8.351862848 52.4318283 60.637262
-65.8328385 62.62353267 43.87159786
59.00026128 64.89735321 51.93541882
-16.31208096 52.6178932 28.22099629
-56.61356756 68.7321183 41.12019773
-0.4444952648 46.26817737 28.98739744
-48.2015204 51.33402423 37.55331146
18.51957444 28.78865724 36.00671374
22.85237904 32.84981975 22.99532682
-34.17701952 52.23223222 30.2783408
-49.35021511 56.94229195 36.76055939
-55.78598832 85.4872519 21.2557781
-17.7861221 56.95796444 24.73251698
-53.96355388 36.46763242 42.25950646
40.29902295 68.19184384 11.43035817
-55.74712874 74.63709664 36.49871367
-49.23090434 53.8811995 37.313356
-13.70121412 31.05023932 33.37723831
-66.39699233 53.94921897 45.62405465
-13.59189469 31.64597729 32.26727005
-54.45676526 61.07723507 42.17274062
-18.96927694 42.5016332 29.63185096
-19.38273506 28.28314079 37.45003382
-59.57739735 67.20909793 41.88582734
-44.2104335 53.67778725 38.71728028
-7.625509527 43.40837853 28.59564651
-68.24954979 24.9556449 38.78410239
10.10162243 56.22073258 27.97248277
20.11806922 74.45967082 53.22366086
-61.87858747 50.31011214 46.22128949
-60.45931739 40.31796733 45.41391761
-68.46931841 67.8854155 41.55702294
-64.60169873 26.31945091 39.7363026
-15.96640911 50.68243664 27.31670313
-46.54768747 11.55858722 -2.110906005
-71.50289072 80.32241764 31.77048472
-12.70711181 70.8919126 19.3277831
-71.38543982 52.15239109 44.127707
6.135276323 62.50405999 60.66697985
-56.44425921 19.14366754 34.08274607
-40.15769585 41.99034704 39.6079941
56.7187506 38.21094622 59.18047978
-13.39508058 75.2261725 21.58349907
24.42306592 74.51806152 51.72483
23.81555064 32.35729771 22.70255853
-15.24559565 81.64778633 23.13595529
-54.00991062 62.10403393 41.82097395
62.70252254 45.4141248 61.81919845
-21.8534111 52.43982396 31.34491946
-70.01094412 64.25555955 43.20255122
28.80281659 73.53431122 50.65639478
-23.98346423 42.96870219 30.99265001
48.10435247 41.07824609 56.67079175
-74.24968331 17.54344907 32.74828735
-16.25794768 29.74974544 37.30862368
-3.740956533 68.05298277 38.92097413
-7.021381257 50.38243445 29.09347737
-70.2236785 58.11327847 44.62751193
-20.27495865 28.33676915 36.99762906
-43.85882801 19.98063971 21.96644303
50.15882711 52.83174645 53.84852114
13.4962876 70.31334015 56.00698012
-68.76286923 30.21862552 41.87852574
-71.85799395 68.47406842 41.19639176
-48.38582451 10.71993203 2.148719023
-36.76653428 76.1467272 44.48731284
-53.99498554 9.330527802 13.52099889
45.6543428 69.94267135 48.35059779
23.02408755 73.9782117 52.43195351
-69.35572683 68.42593754 41.27793284
0.7038119573 59.95105373 56.38010653
-55.68776945 16.1881095 27.46226638
-69.03301611 14.772884 28.95633302
-68.96291586 77.52118462 34.67164959
-61.18474619 29.61037284 41.66773235
-61.17558411 60.99758214 44.37872939
-12.24126506 77.7251116 21.48715519
59.77741001 42.35253685 62.95833706
-68.82073765 68.41623461 41.28854585
13.179065 54.62359017 28.44646811
-65.26409426 68.35656821 41.3029238
46.95770897 45.12261638 50.72594692
-55.76915342 27.1921219 39.5745633
5.992852773 66.59363245 59.83252619
28.8884507 42.55194317 49.05426799
-20.83331112 89.10734861 30.83177862
-30.6485332 76.27130387 -10.56538047
-62.92566775 9.88438989 20.44778113
-37.27776836 79.34357714 42.43328606
-49.85756377 84.65718675 12.2812698
-49.79129475 83.9728748 14.38598393
34.72328296 36.57828561 24.06184873
-51.35058514 67.27139304 39.59375742
54.20313475 70.07220636 51.04319635
-57.98378679 57.8608843 45.22326692
-50.33731849 59.97701414 42.50602311
-60.17708469 68.26825822 41.35796731
1.19159609 58.87400842 57.13692952
-54.42222796 14.33374591 25.44116199
-20.99610596 50.31395329 26.72687916
-72.3547663 28.36154479 40.81530684
-57.1176684 68.21163493 41.43184275
6.004043817 68.19374672 58.73502023
-56.89822336 78.386378 33.98032585
-46.74368882 42.81668656 37.09787067
-74.84624512 10.70092557 19.06880534
-15.20875747 53.5613209 28.7118705
-55.01730799 68.39102007 38.94885745
28.14117089 46.00507136 55.7234747
-53.68891499 68.36514859 38.99585999
4.4143211 55.39959729 61.89599202
43.04721662 44.98911084 53.02153139
-52.4711538 68.34552861 38.99138868
-55.14117277 48.37152047 44.13444715
-32.5917364 83.87836945 41.33622088
-6.526686319 46.36294046 29.04722864
-18.98252669 27.77726615 37.41963279
-49.68898352 68.92431662 31.74181728
-61.73405838 62.03595288 44.08435015
-46.3896255 38.88052184 36.11109419
-67.38922743 31.17436075 42.17497376
-70.92968851 49.15697391 43.85393586
62.19938188 48.88517847 50.75382881
-33.43609123 52.13103412 31.31182288
-32.4362987 45.19034209 30.12080232
15.11945219 46.93772527 59.03314965
-62.00646285 78.48539722 33.8050103
-13.55639815 26.90990432 34.80052445
-67.60253316 75.321645 36.63951432
-37.34334792 69.06458229 27.75912355
-62.82233974 69.36817225 40.7471102
27.3116729 48.28370101 52.73623212
-1.545977472 60.41693735 51.400837
-66.05488823 55.95895676 45.53487384
30.99716282 35.71901096 23.09424751
-4.998536649 46.33541497 29.07533726
9.405108656 66.7430792 57.44690117
-63.02582662 56.92207766 45.42988504
42.61964971 68.97000227 48.56750281
-58.17008882 77.31735799 34.97956108
-8.172367489 76.83436576 -3.908229657
-10.78122515 69.11993747 22.05096318
-41.19435716 54.65411739 44.2880672
-60.44049086 70.91221118 39.84846915
-49.4122758 27.39813813 30.14403948
-62.92651466 82.96723714 28.56512738
-9.146008887 68.74106897 26.13730035
51.81601351 62.96835502 40.73886956
47.09657856 66.57832143 34.69161388
-56.54268753 29.53590251 41.64696989
-56.54824333 59.88893727 44.71294471
-18.89396212 21.04845618 33.94270693
-11.40348799 35.22878582 36.87119939
21.89477744 69.798278 42.90463023
-48.81386389 28.81353455 31.07888688
-16.79120367 60.94459438 24.87619742
42.80578366 67.58007239 47.18740349
-8.964760721 68.6991957 26.58883078
-51.39740071 14.79678677 25.31563389
-57.21145722 13.24964377 26.904852
-6.414818585 68.10668065 32.98088969
-71.04333393 42.68549298 43.25495067
-49.88260881 84.76893191 10.98882647
-52.69493097 80.27527698 28.73093669
38.78893933 34.07288425 23.23782652
-20.56626431 56.60115408 29.40482263
22.77703645 29.88784736 34.08787048
-56.74894368 23.823966 38.07494055
-74.05384137 50.20112931 43.98169448
-64.91440016 44.35301436 46.03488504
-19.7527915 57.74035001 27.67835223
-6.961900836 77.40736395 -4.964213413
-55.86213702 48.33875861 44.65226296
-42.05090557 21.34341134 23.28153662
-71.63680808 65.85141311 42.46670292
-60.02198368 14.18514892 28.23409181
-49.46273811 53.40347742 42.90331556
43.81375103 67.59008951 23.57232461
-4.416240168 67.64362885 37.97519277
-71.18947343 9.586318312 19.65732984
63.4223591 46.63772146 59.1307996
-6.021213812 37.06141209 26.22358426
5.049675751 43.20896091 28.49339792
43.56115924 67.66795143 22.71661736
-49.48995055 53.21232272 39.300927
-52.60398848 48.33055379 44.12616197
-50.03516333 21.14064294 32.98470849
-1.693188581 67.01334603 44.77267456
-39.09809506 67.04408054 45.72258536
-1.331731235 66.9301236 45.66984936
-56.71842342 20.5408367 35.39559445
37.42459144 47.10869277 52.7947093
-12.84399745 77.2048263 21.81531046
-0.2480518531 66.68080571 48.35744969
-61.49281979 78.47407323 33.83851273
52.58671328 70.1690376 50.22736976
-58.78074174 47.24558518 46.24593186
-25.86756887 49.97142063 31.63226624
63.10550673 54.75867757 58.14349404
-6.943815366 82.69295755 -8.060281964
-53.43927509 82.65961866 24.5002721
-9.55794414 48.41422356 29.11907995
-17.89550643 25.81473313 36.68828341
-66.7944307 79.67506894 32.56080853
-49.55107441 70.56627948 35.96097825
46.34305245 31.0895523 21.47006083
-49.29922406 78.47949331 19.79691655
-66.5009247 83.03586576 28.45009762
1.93887446 66.17229925 53.8435047
49.77737172 66.37911903 48.14614125
-11.60301797 34.69094267 37.32628597
-62.94849482 7.880381756 14.58291795
-57.78688321 87.61344635 20.26150722
-52.51731971 62.59666176 41.64418537
2.48400427 66.04598063 55.20594409
-56.89925156 75.12582197 36.87159103
-52.61460789 79.70776486 29.47707267
-11.57421464 71.28103932 20.42110691
7.955879422 65.6148616 59.16716967
28.90466948 70.41342167 46.08004744
-39.50186313 63.18047373 44.03802642
-49.77757045 16.63276654 26.99989337
40.63336888 69.49545423 48.67310953
8.526270152 65.69880103 58.08396239
8.593808701 65.73906539 57.60366422
7.049583048 46.2045363 28.29702358
-45.20810655 45.27171404 37.43847974
-47.75714187 68.43871492 31.18398138
60.92007731 57.88327942 57.24745515
35.31360915 68.90709507 15.73123065
-49.29569529 42.89656695 42.48388558
-38.32512862 51.56770712 32.95706196
36.75177665 68.90431205 15.48927076
-73.40173043 11.82579139 23.21498768
-61.70110933 24.84868725 38.77691797
-44.9079771 51.89690336 36.21754194
-56.39899367 41.73387275 45.6826487
-16.42253815 24.95261953 28.93549941
-54.12642374 48.35519669 44.13042675
-64.75154189 72.58652329 38.71389803
12.16509297 45.3851497 60.14007151
37.59330191 68.80192444 16.51737707
-55.79369871 88.59688996 14.29150379
-44.40197885 50.86022581 36.50227284
-4.100509073 68.13524434 38.03459047
-38.07001347 78.44145163 41.4036213
-5.914115304 69.55897637 33.50601668
-71.41238434 55.66910625 44.09473559
38.83477468 68.90662157 15.06521991
-39.25692375 65.34979678 30.46150949
-39.51535322 45.93182992 40.34301289
-62.92421081 27.72324119 40.60009638
42.85889487 68.32619545 21.03580697
-70.38205352 17.97779157 32.79518327
-39.45020709 46.02285682 39.27388225
-6.28917957 30.91555519 21.87394695
-72.29184094 83.73369251 27.28021864
43.11235248 68.24789678 21.89641928
-39.5542331 45.87710896 40.9856685
-7.016939101 50.88240967 29.11519387
-46.39790603 61.0441486 35.19331762
43.36563694 68.16968547 22.75605059
-49.48764768 38.54778491 40.56455936
43.87255217 68.01308814 24.47727521
-55.77819276 80.63553317 30.96345763
1.609770177 51.42049927 50.35669974
-62.98685308 42.3381531 45.75070663
49.9588251 66.13226047 45.15060997
-39.60961438 69.56541336 45.68386812
-46.47681747 41.82630083 36.89081524
-49.40374645 70.13841233 35.07323495
-63.94928411 77.97210543 34.30754295
-49.42585676 70.10000997 35.52325427
44.63257869 67.77836684 27.05715013
-55.7949637 87.94664492 16.01362335
45.13897443 67.62203156 28.77543173
-49.61509401 83.72866589 11.3606953
-40.59779413 79.95288602 35.99313174
-8.75267191 70.17319108 26.91709282
53.49134326 43.07713705 61.57208044
48.1787342 66.68332107 39.09296938
-52.5879553 76.93546154 32.52170613
-38.59293448 79.05878325 40.16355022
-55.97192807 23.35101508 37.5904774
53.01846668 65.6047443 50.69091151
-55.15572195 77.53123801 31.9218273
-9.176692395 40.02446321 21.56129684
-49.48290651 40.65336879 39.42706427
53.36807134 65.67616798 49.79510154
-51.27067479 75.27226938 34.0981188
-16.7293317 53.082809 28.73012759
53.60972645 65.62860083 50.30121065
-56.47728262 86.40585962 10.72374145
-61.20267724 15.55644035 30.0202284
55.06970965 65.48679417 51.66897851
-65.90556027 48.37516712 46.1449477
41.60913199 32.5559535 22.82971539
-16.41659235 47.1710146 27.37921933
-66.33317305 68.90251416 40.99570534
-10.88184482 61.59455699 27.85728908
6.714316546 55.42872265 61.11926058
-49.97261039 85.31654841 10.47549919
-55.96475767 65.0627649 42.80651206
12.20161967 33.78911981 37.42897579
-71.06806575 19.83715118 34.64777052
60.60615851 65.32043578 52.54435435
-3.255789904 38.93895398 27.20688854
-42.83114535 28.25434451 30.60279174
-55.95027963 6.98144975 6.203851089
-67.8501509 24.47495645 38.46147605
8.621482038 56.89970123 26.19943145
58.16427549 51.50571738 56.06206583
26.4192468 70.73227086 48.67923959
-50.02321144 11.60877047 3.79597532
-70.23064763 59.61105313 44.72150234
-55.80119947 88.65022936 13.67373146
-55.87740103 74.05192815 37.48997951
-64.4354217 45.84104682 46.14929686
59.1251905 69.86888298 52.46485714
-49.75098037 82.89855082 15.19655086
-36.76695078 38.9877525 27.20453829
-58.82678323 48.75068061 46.26238878
-47.37839857 64.71976023 33.49760707
54.96349824 67.45012423 52.2040292
-57.56481293 30.03338539 41.89339184
3.429588008 60.49727361 61.17264824
-50.75713829 85.62650279 18.6802198
54.05902435 9.246214305 46.33299686
37.97901431 68.9046896 15.25084622
-49.84572826 72.56272296 36.14756111
-65.88516411 80.21417085 31.95575841
49.98090713 50.41041643 52.85781287
23.73428441 45.99807803 56.64507827
-3.532425089 66.44114292 40.11269681
-15.65143077 77.2334505 22.01841422
21.70421214 71.63737356 50.72461444
50.33081841 68.30702485 48.96662162
-55.76405113 82.3456594 28.58828978
-48.91859103 32.62392393 33.47820577
0.7720435389 68.11887738 48.9486487
-37.79060841 66.95256361 23.22906791
-29.00417778 54.08471123 31.09348426
24.92769343 36.34673953 22.79124122
61.63967068 55.25698555 58.46495605
-55.81018054 90.50163887 3.836211543
37.51507826 32.13671706 22.6506376
-64.95956259 64.68743782 43.05230513
-17.45335863 22.49740707 34.32741847
5.751485205 59.9635791 61.09872305
-69.84429297 68.96399452 40.9515881
-69.38230339 68.95394879 40.980102
37.44199419 69.28135485 16.80729529
-68.87911945 68.9479008 40.95435131
31.1063259 42.92648652 50.10997433
-65.31464903 68.88428613 41.01307099
-33.30190389 48.59738808 31.52088847
46.52744827 64.71454171 38.95634363
-10.55683046 60.67395297 26.82906834
-49.52996888 55.91130471 42.93665193
-47.8271603 68.97625111 30.78386285
-62.76728269 68.83818321 41.06246931
-48.43773371 38.91016818 36.15752438
-15.31652766 52.10903651 28.11167719
-37.4306539 60.67006199 26.17259602
27.64351905 45.50611271 55.78401515
-59.51094963 68.78133847 41.10136222
-37.09124216 42.89651012 28.50379884
-67.57135204 45.60749588 43.63192607
-43.7959134 46.19561347 38.09714912
-57.01770436 68.73775858 41.13179258
-59.40888503 22.9224243 37.3946794
-55.81603439 68.75103352 40.74852167
-46.21024931 34.50548255 34.4257668
-39.69721448 65.32411886 30.84356363
-51.86303601 68.86309728 38.69374287
-47.31701901 23.25837634 25.36206869
47.13130604 65.70503854 33.16941415
52.61543921 54.18940463 55.0992664
-16.83283615 23.46137864 34.67187753
41.6420077 33.04158567 23.01251402
-49.86262026 84.30618856 10.53023619
-37.13895646 77.16557923 44.38403985
-61.53046462 40.3379628 45.38606976
3.33412812 57.38640734 21.55772107
-55.64766118 51.39253151 44.12078185
-38.3309457 69.59420375 27.62587289
-8.387516699 37.06573492 26.62466996
-48.67169234 43.80922711 43.42334115
-44.52727346 35.93128234 35.03301139
2.618381453 57.50633959 26.12859879
-70.87592 29.28898842 41.42012127
63.45455373 55.76048104 58.10054469
-14.89901514 69.96488517 18.85412131
3.818802436 62.00580886 61.0661672
-45.12053652 35.45577604 34.83953349
-6.846324056 59.79333615 36.34429678
33.55871019 73.56347395 49.41087493
-12.04355177 62.59033298 28.172421
-49.49577734 38.70055297 38.79266635
-42.53411982 45.11218292 38.78048833
-53.25625359 32.08645413 40.54476681
-72.80894861 58.65593977 44.64757097
-10.58561528 69.6225913 22.00512758
31.4066791 41.81344082 45.4938186
-12.27573651 81.82604533 -8.632984719
49.92725609 51.44191226 52.54696986
0.9353240602 58.45151077 56.2638222
-66.29264846 50.40584933 45.95169013
-78.03489619 12.50283322 16.23896343
48.493999 66.88449647 48.35073896
-43.12162846 50.98247826 34.83890644
-10.04481457 69.49845646 23.34304168
-9.864778555 69.45719465 23.78770503
47.60654933 67.38837698 48.49720915
-9.684742538 69.41593284 24.23236838
-58.51913281 71.93854592 39.22089366
-70.06664835 40.67950586 43.04908967
-46.52483804 46.37712753 42.33706527
-5.538892399 68.46437798 34.48807479
-4.99878435 68.34059254 35.82206484
-7.534568903 46.87801654 29.08669971
-39.07234809 69.55456176 45.70738144
-0.1362711457 51.85527246 45.64251509
33.29306625 45.15215468 52.98897442
-20.77062497 53.04006264 29.99705762
-54.04343262 75.32056863 34.06620744
-4.639058645 68.25824362 36.70942952
-64.48243801 14.70739341 28.84877296
-39.85834513 47.85261673 29.76380854
-37.8055244 48.56485778 32.75739163
-48.31734538 75.25677172 33.71480922
-0.7130899954 51.99757632 44.10055055
-64.78890314 62.0902701 44.03637343
53.52884799 42.56839104 61.64418221
-62.71104196 84.1107545 26.90253286
29.90391946 36.77936253 22.64668242
-8.137247783 43.41874167 28.57293521
1.294164347 66.90062197 51.33819356
-45.19409614 52.39478238 36.31900436
-9.169218492 42.94153674 28.48284044
40.15046345 70.49570497 48.8068438
-5.696760337 41.41768873 28.03063887
55.8479559 53.94635101 57.30435018
3.095390332 66.4875671 55.78973213
4.777998874 66.1343477 59.56927768
-49.79258089 25.3647737 36.34142253
-49.7033048 57.74078012 39.21174449
-17.74738794 44.56312864 28.77404923
5.06315006 66.11439507 59.74652241
58.64598102 68.33141135 52.92441548
18.27981318 31.76184271 36.49737624
-48.38772238 57.96914937 36.30977768
6.585699246 66.06491684 60.03054255
7.97673754 66.10655594 59.28188609
2.555385212 44.23272228 28.73778681
-53.16033779 44.34569236 43.8783136
-37.79534388 65.45496324 28.96192817
34.3780998 49.5955429 53.63972691
-20.00082558 74.02217486 13.51357268
-6.004723943 69.10246704 32.99614875
35.83888267 43.57540768 53.32757927
-49.8501071 67.25782625 39.46510036
57.56106178 6.833561467 50.36640933
-66.55259333 86.55839384 22.52771884
37.03728155 69.26250775 17.10326737
-49.54955749 77.49076827 31.32250031
-10.83511429 37.46461501 28.28759144
-55.7247923 78.03557104 32.00232747
-55.77720205 17.84564138 31.54404612
62.15648898 7.862308509 60.85415258
30.78072225 43.64916497 53.43597231
-8.328344748 60.14448225 32.55056615
-17.27030082 43.56581291 28.60735296
-48.5436091 84.8863771 9.370081476
38.59370436 19.29830979 37.46980021
-38.35993758 79.01558366 40.62060874
-49.64719199 20.28739226 31.16259457
47.30847213 63.73735284 38.49803734
-55.47514469 25.43139346 36.65175079
-51.15136568 77.45633871 32.02765836
38.11635969 69.23851159 17.17604556
39.08037163 69.24778168 16.88458873
-45.66413062 45.27875052 37.4437617
-19.67353981 74.93584417 14.49789919
-12.52100309 62.42807432 24.32044928
-12.34269517 82.34391912 -2.978789526
41.16478459 69.26176637 16.32473402
-53.01479317 18.8937171 30.50369191
-9.116888732 75.77366494 -3.06799619
-39.48623974 50.70298557 31.56352963
5.058595455 53.75099797 28.4714957
-57.4480225 57.33618543 45.38554004
-23.52285875 56.55635411 30.48873377
-63.80912687 13.35665678 26.92078206
47.81455942 66.47723897 47.38141332
-60.87149625 46.27992308 46.20147991
48.75506846 66.42403132 47.81972593
38.60264992 18.77919233 37.66774771
47.93717612 64.19498129 38.89229565
15.6630643 56.7669834 26.39724492
-72.70823901 64.32773684 42.87905479
-60.6120976 15.9961335 30.62996334
54.31919406 65.9959331 51.72829098
5.004283247 45.74293887 28.2189935
-43.84783416 54.12098262 39.32983605
59.61836297 65.82537913 52.69762277
-66.87273943 73.6946377 37.90790394
43.15584338 69.9641238 48.57804098
26.18536164 30.89411289 21.75641582
-48.5770048 66.85008211 32.30242072
-12.2984881 58.34765618 25.03335903
-11.27899093 81.42421546 19.14823233
-47.87883095 10.71196231 2.144550598
-41.69572143 32.52364664 33.26485315
37.10273821 34.10580378 23.17724126
-43.28785088 22.70591168 25.18045428
-59.42858194 42.27766559 45.77430609
-55.89026922 64.02075783 43.23540348
63.02733232 52.33658752 57.1425867
58.89862934 6.70395055 51.61594548
42.63527877 69.96655039 48.64914624
0.318992895 59.5686226 55.06639243
-66.09741375 16.53980307 31.19137381
-5.288119818 67.3830017 35.34037431
-57.0683583 85.8301385 11.69321333
-60.38529528 70.38215225 40.16461307
-20.70209096 89.61770539 30.70880107
-20.25390951 74.07550718 12.9427168
27.98126168 34.74925138 23.2737932
-62.89310323 49.82410222 46.23008069
-17.98373455 24.85480695 36.19535083
23.97821998 73.49024939 52.08797641
-49.89461184 84.82454244 10.34554784
48.92928941 64.39495768 42.20826324
-37.68758851 68.15280676 20.9294044
-71.100932 65.31426892 42.77342915
-17.42112865 57.1207368 22.77332992
-41.16484956 23.6676961 25.26377151
-13.1727238 82.11173631 -5.951791985
-59.54779777 58.91093175 44.9850979
1.488592585 58.32108665 57.67236915
17.9660026 30.89093168 35.01411062
-41.13534518 48.75788414 31.15161737
-41.46556329 40.40583508 34.9450191
-72.64336898 69.56495254 40.33567016
-71.99913404 69.53730837 40.53372439
-49.85271671 62.56575344 41.49483588
-71.16398536 69.51247093 40.66278769
-39.2270324 78.65343143 39.16345523
-39.26443854 46.27603754 36.29934807
-70.23984955 16.14497252 30.73820135
-6.100580711 43.38247778 28.6055083
-47.75468561 54.88430216 37.04036632
-16.65873977 47.66483146 21.69281082
64.17674855 46.72795584 57.93942632
-2.466753097 48.30603675 29.02265875
-49.46342816 68.95920441 31.29379957
-58.83530998 82.88569472 28.73151396
4.310550156 60.44932599 61.56125454
-14.46539811 60.96070006 24.24568578
-51.61870679 44.81358237 43.97934753
-49.52440319 60.02533677 41.79003088
29.4979041 34.22531459 23.24016002
-7.509234144 68.36141431 30.23246662
55.13604991 47.96822104 45.26563607
42.12956926 67.187449 16.91437024
-62.01303288 40.83582742 45.52516344
-56.97226711 78.93833364 33.41358334
-54.48443619 72.63550305 36.18730614
48.66020355 54.40058154 53.40205753
52.91711517 53.20623988 54.80174706
-7.06183107 62.06623685 39.13310998
-45.42492884 27.82164611 30.29387968
10.25347787 46.86498034 60.8055967
-65.36250245 69.41064137 40.73852197
-55.77577036 24.83304676 37.82848635
4.74497626 51.48762952 60.63278365
-67.01547765 74.77107274 37.0923631
-10.7302483 55.54104893 28.18222761
-19.72353239 55.11539898 29.0119154
-62.31375307 69.35939891 40.75196708
-40.25952207 52.81790849 42.11927751
-70.12330702 39.18904981 42.88227376
-46.08113104 32.58015305 33.44520944
-60.7854995 69.33182101 40.7806643
32.16281478 73.57384069 49.55673584
32.14198143 42.19251885 46.77961861
-3.061798729 38.41321445 27.44642062
58.73514045 40.42343471 62.24496595
51.42565821 64.43079465 47.14281069
-5.5176131 50.36720799 28.98345953
-55.77925621 18.75199822 32.67606824
-58.75011384 69.29620352 40.8059779
50.08878467 8.534861377 43.69476712
-61.23667409 68.80941734 41.10450829
35.06556957 49.57086717 53.79507616
-7.955509054 69.54140994 28.27260489
-60.93006445 65.13923874 42.86566176
53.21001074 65.17935061 49.76602228
-44.75952228 54.71142356 38.47607187
-80.32354367 11.81044139 13.05992953
-13.15356271 54.04270477 28.55831814
-14.51118837 81.57465555 23.84485502
-55.57276193 69.45264508 38.3839496
39.18832896 70.51026567 48.82129735
63.93532498 48.50505495 54.83552596
-49.25706915 43.35452052 42.98690106
-53.59106444 69.41933224 38.39274779
-69.47906023 44.64860916 43.47391256
-51.56735004 69.38960221 38.3519412
-48.18450288 51.84004485 37.50245031
-73.98825415 9.356140306 17.03650708
59.69524188 57.40425191 57.21529857
-49.63456335 69.70307407 34.34433635
-49.79313233 77.25342953 22.47086958
-11.62654274 53.5011442 28.72729573
-49.67842858 69.7747572 33.52055157
-49.6701908 86.94985903 14.76372856
50.73384999 43.75623045 60.04117977
-50.04672748 34.46518944 41.45180003
-40.78012557 68.65587787 44.81236137
-38.32367184 50.35765844 29.52398912
-55.67047358 75.34842321 34.05313756
-40.58516707 68.6318664 45.05392432
-8.038064545 46.88422177 29.11068457
-4.739599794 61.58858846 44.23513849
-5.007192063 37.04545519 26.21544362
17.21148761 74.43919805 54.01562635
-20.32524376 21.02711747 34.46337626
4.845259202 55.86747759 27.24910351
17.48619854 45.92874669 58.64147476
28.30681797 72.53230259 50.72975033
-36.86619781 70.22476601 25.85315037
-49.82874567 80.53246905 19.37202232
-69.30154791 55.10560121 44.40712333
-36.51064936 70.26841353 25.27865272
-57.91524609 65.60732849 42.68343118
42.87944231 15.52879112 39.62532222
-38.5569529 67.5706566 45.33314784
-62.19128593 62.04359644 44.08281408
-10.69063902 60.31356901 25.21154525
-36.76611685 63.31670171 24.45494355
-51.03380728 28.68874197 38.7775689
-77.30298663 7.706136388 13.51653199
-50.95167184 62.04800463 41.88817851
-52.09139356 47.81754249 44.15717572
-38.38277987 65.91900494 29.51366205
-16.89042497 59.94065789 24.89626512
57.05915071 49.14900273 48.67143684
61.94451897 49.36729063 51.03236385
-11.13416458 70.25285088 20.61986676
-49.76419718 80.00435107 25.49049248
13.73986806 44.54415624 28.81612696
-10.77156957 46.94988708 28.8696895
22.70651108 69.33259175 42.32921268
-9.895731151 69.97502443 23.60890274
22.72720873 69.39648457 41.58355085
-9.718985254 69.93542229 24.03492687
-31.22943044 86.5694717 38.96934607
10.90222236 35.9083402 36.3818852
57.78556086 40.93387824 62.3270964
52.99372791 52.21271694 54.66739405
-49.47439667 54.15935304 39.95742349
-40.73061912 49.25416264 31.13991151
-44.44860026 49.45142904 35.38558203
-20.73323441 51.56410895 29.6439519
-55.86874567 7.969530596 6.371220307
-43.29566034 33.015268 33.68951242
-55.56348733 64.20280566 41.05973984
-9.408052126 79.43605182 -4.741643414
-5.827631717 69.0626902 33.4241349
-4.590756762 68.78564991 36.40434177
-36.67970354 47.8597406 29.07492328
-5.336068728 81.36641057 -10.44728649
-4.414357193 68.74622247 36.82840388
35.43909377 31.69059824 22.39877224
-40.05345296 69.01933228 28.8012522
-3.885677977 68.62820221 38.09764716
-62.1885214 36.9066228 44.55857771
-2.471537633 68.31129772 41.5068212
-60.94145476 10.27769059 21.33029932
-46.84447671 33.07098614 33.7194762
10.58868973 45.46179411 59.55095673
-61.88639459 54.35655642 45.86182707
-15.37662459 26.78743101 36.56941241
-59.56267639 10.67906299 22.2345753
-1.942165761 68.19292805 42.77998853
-70.67894373 66.35361139 42.28078919
25.89070631 71.55731895 50.85555725
-54.48021363 19.39181544 30.82750835
-0.7101394024 67.91833359 45.73272707
-55.88170731 8.18095075 9.746014155
-49.68077248 79.00073296 19.64539068
0.5191163296 67.64513675 48.66976944
0.8764177361 67.56401074 49.54339995
29.61140177 22.7047446 34.59824278
1.053683126 67.52414655 49.97236711
-39.76728573 42.00974998 33.48165521
-13.18495248 48.30450315 25.25784532
-42.98518691 54.43582268 41.33707207
22.71564678 26.90551867 33.76078048
-46.70058874 45.91401163 41.9201284
-54.11987995 76.96489128 32.47221146
-39.38124271 67.45853922 29.31196001
-61.14352326 60.47919331 44.56381015
-52.66038595 64.67769431 40.81988773
-49.52802913 25.85076934 30.64917788
52.08684943 54.61137297 56.12819621
-41.03121966 60.04664473 45.74961294
7.621482585 66.58975424 59.56695776
-58.04589063 59.39886009 44.86108775
-47.67914908 62.62721736 34.54016346
-53.60848802 76.95470284 32.49296073
-15.71579186 48.83782628 19.54928652
-35.00718559 53.3580997 29.02004871
6.857314888 55.98269374 60.48773
-72.96508332 48.69038 43.83201965
26.55793605 68.13791234 37.98350992
-49.50833597 40.87107822 42.73122528
-8.206626483 61.79461244 36.67800252
-58.55982902 18.71751717 33.60662143
35.57812293 43.20472952 51.85376924
-70.16690844 85.4321084 24.63845231
-66.79687856 84.18958903 26.76655764
-12.84684698 81.65001648 -0.6539570409
17.05997017 29.29621058 36.21966037
-53.64729526 45.34798711 43.98909219
-3.614500659 52.71783074 36.29262671
36.09761894 43.19045305 51.92042986
13.75289794 52.57303984 28.83475597
57.47506824 58.63662748 54.98566546
-36.81627684 79.25944909 43.3218968
-38.89767816 55.62647415 32.56224782
-42.1992475 29.18780001 31.29933418
-69.17005351 9.558754588 19.59219417
-9.70884533 42.46375199 28.30558365
-49.60535479 78.56624353 24.67487933
17.86729062 43.4732067 57.94129398
-52.1806738 23.49511348 35.19466278
-36.90587837 78.75287603 43.39301325
34.5569578 35.65775477 23.12647987
13.77280266 52.06078306 28.95096971
-62.72216272 85.87079088 23.95278904
-54.70746544 33.07960108 40.94562881
45.98843594 41.68148052 55.89816495
55.10051075 52.51941055 56.53193594
-63.30251427 13.34887924 26.91445541
-55.67126479 57.40625258 44.23331087
-52.63549124 52.86638929 43.91664892
59.69296835 7.62979766 52.36983322
-54.51607299 62.11158436 41.82985123
-49.69996117 48.30275934 43.89500472
25.18695325 29.40886358 33.3620372
41.95993865 31.12012276 21.95106278
-66.63901627 57.51248434 45.09171127
-55.95079273 17.30710231 32.00226463
-61.6068608 67.24172801 41.89406434
-23.85121693 46.93210838 31.57044531
-18.4859584 73.70401536 16.91810671
58.08686307 66.35681064 52.64708514
-7.100003358 39.74500991 24.40935802
55.65700809 43.5027953 62.0443645
-41.54678713 20.48112703 21.54214178
-64.53514273 41.37140596 45.61539924
-32.51665715 52.61545142 31.33965267
-54.02390684 79.17312116 30.12572788
-12.19851539 72.83005379 20.03799689
-49.60969075 60.96702099 36.70117812
-49.50899523 29.56156181 34.18108854
-8.048198846 51.40927815 29.02222836
47.17968177 43.63454436 56.30496045
-37.86140414 53.18732359 31.5468594
-40.78420549 57.31665019 30.78114532
-12.32689629 80.70727007 21.84425898
20.8216145 31.90345197 22.71545186
-17.0071573 59.01988825 23.95421075
-37.53079859 78.3428946 42.44491189
-49.77249292 78.29700593 22.00561877
-39.25810998 45.41708023 34.61626243
-34.00117143 50.61210134 31.57254477
58.1619544 39.9625786 61.87758909
-63.44553186 44.33061497 46.01479155
-51.03112949 14.35758713 24.51769275
60.63865959 5.646104295 57.73772272
60.52188588 38.00216138 60.8789261
-1.846788141 29.92498418 20.87274545
48.01472393 65.86073392 37.02015704
-31.21395607 55.21273154 30.07330088
-37.50662627 68.66950839 26.5499268
-64.56881279 71.51469307 39.46835626
-22.50218326 75.04284225 7.968584692
-52.09555667 49.32629061 44.12324306
-69.99612093 70.02023694 40.37219142
-17.57125144 22.95998163 34.80663925
-69.01024777 70.0060904 40.34840277
-49.05041119 85.89868228 9.36843613
-68.4744476 69.99597838 40.36361019
-13.42270611 32.40973764 29.19535756
-50.54813047 18.38610838 30.09933617
-67.9628825 69.9857026 40.38534047
-33.61511265 83.88665622 41.435186
-66.46310076 69.96009813 40.39655847
52.28723459 9.874079974 45.20880559
25.8326507 48.58377884 49.53476751
51.99318926 65.62637903 50.63528639
19.53167454 49.22824851 37.42828436
-42.37443825 32.04169099 33.16254048
58.1162232 6.249522177 51.21385027
-55.49665585 8.588042509 10.77340535
53.07714781 57.31668606 53.66729573
5.126614226 65.58016513 60.10950964
-77.66969325 7.116345767 14.60654439
-63.37947248 69.90365025 40.46378024
48.32736113 51.00341297 52.11585819
18.41388182 49.49477457 34.54742471
20.15674494 73.93794518 53.44621626
-57.32579089 52.25236656 46.11250062
27.13543558 48.87481834 51.73436101
-59.81874241 69.84192236 40.50131007
-67.01336407 19.77402237 34.60735984
-40.23796241 48.82554255 30.19505267
-43.43213001 46.65060049 38.57259302
-9.289657598 57.06433984 27.70394833
-58.72366026 69.82224206 40.52093463
-9.822556388 59.79468017 25.24299616
39.0331844 32.11425794 22.62184753
-56.26088687 69.7816445 40.5225551
61.4042925 57.86202219 57.40188189
-36.50517071 45.35973041 28.9303772
-26.67950441 50.48676444 31.63126112
-61.57027349 75.75428537 36.29336195
-60.86072346 48.28335294 46.24871307
-49.49149924 60.54812148 41.5415303
-71.41959459 36.24737449 42.31875217
-69.54307651 64.76954258 42.97327676
-9.05189284 49.91337723 29.09922568
18.42736873 33.84494088 35.59367604
57.93673692 64.92589232 51.80693637
-56.24761381 13.68688134 27.4719095
18.23986622 71.47703637 53.2465975
-40.71801975 69.12676519 45.16075478
-41.76802496 42.03454934 39.40196095
-41.09985425 27.36769268 28.91224358
28.44157521 68.22839768 36.57386845
4.410816195 66.77222996 58.06093949
4.585830451 66.73350133 58.4771535
-61.96227468 32.52032903 42.99496204
-38.34440081 69.06919614 45.37639499
-14.68155678 30.98633353 22.65277705
6.667263181 63.04764329 60.08185587
3.46074263 59.97934192 61.35259504
-8.718022854 56.01933042 28.07288446
15.12542473 45.37850869 59.65261205
-70.47324539 64.2657625 43.17227151
-35.98538849 45.84752559 28.99521005
-10.98370038 70.65438876 21.75646865
-61.53341001 58.4316144 45.10139075
-7.053715961 45.37658515 28.9447869
-3.683433163 69.07795339 38.66467882
-12.98596986 33.18720315 25.9133227
46.66023963 67.15197751 33.94204865
-49.79926994 23.87961877 30.27689595
-63.10205797 72.02035368 39.14520073
26.89107653 24.77262559 34.41811693
-67.85907159 68.92890408 40.98034988
-76.58028561 12.28940316 18.43921265
-4.540668535 69.26259939 36.68465297
-72.26536658 53.69407557 43.87843837
-76.23418064 12.23870295 18.9617735
-67.79033908 45.11663444 43.5453251
37.06908014 32.62852314 22.85308915
50.93985777 42.21999721 60.35568694
-51.16554866 13.92317762 23.75962885
-15.1200377 26.04335278 27.67835879
-57.01301821 86.96863975 21.77267143
-66.49789928 58.52006478 45.0213521
-71.2103991 22.17225151 36.70057024
10.72347442 46.3734574 60.59528156
-12.19743745 49.08334868 27.68139518
58.25853722 54.37235265 57.72594089
38.01005306 48.02840582 53.65963981
-16.6695216 47.86849309 19.33061381
-58.90886898 42.76549572 45.83874653
5.674994401 64.52210841 60.63434315
29.31880061 70.35318413 46.70035147
-2.052227024 68.72588562 42.4406583
-1.795160311 68.67054423 43.03407745
-63.80619463 10.32283363 21.35256906
-3.560205835 32.28449015 22.94184196
-46.21649443 73.01155177 30.24511113
-1.623955666 68.63373732 43.42870921
-37.05331939 59.79166912 30.47102142
-59.17880584 76.79125617 35.45266254
-55.56565849 84.50795468 20.92887487
45.36708687 64.77954045 38.42307854
-0.5975936186 68.41333263 45.79159467
-57.914528 8.575482204 17.20695919
-59.33477198 49.26136837 46.25746995
-59.48228582 81.2098506 30.8293954
-67.05578863 71.02811653 39.76453222
-37.79869242 66.90708581 23.75854918
45.31912197 30.15563123 20.85368597
40.78494493 17.67742464 38.38841273
-15.70280925 79.67133662 22.86065583
1.804639496 67.89532805 51.34685059
5.429701199 67.15692444 59.22745881
47.31470783 45.54385901 51.59441422
46.28640828 44.24047406 55.26787961
5.958322206 67.12565603 59.48963421
54.1033125 48.49583492 45.16429926
-68.32316913 79.70289142 32.52936448
7.462616786 51.83307152 61.93101159
8.467389034 67.1236508 59.03441818
-64.92280194 48.35020291 46.24733265
-78.908968 6.849483814 17.94081037
35.04997351 19.450574 36.3780167
-55.86515124 35.02822498 41.85192184
-79.16264757 4.367258609 17.67146582
-46.15403188 46.28253024 37.5378631
23.16230462 69.77833187 42.89445443
-51.86155544 87.08893655 13.5671214
-6.836847255 42.90477471 28.46480366
-64.17913201 21.59207676 36.26811638
-58.55492519 67.18887829 41.92556006
-52.31744463 86.49457754 14.7271746
26.7270543 68.5052625 39.51343039
-60.55015892 39.3345816 45.19381902
-47.68518866 52.33816493 37.45132758
26.66414403 68.63610843 38.00647249
47.39033678 45.00349265 52.02631752
27.33463327 68.4961091 39.50382079
46.02059473 13.84995515 41.03551629
-36.84421552 67.11938859 21.11195864
0.5788907081 65.95966891 50.74458851
-13.98155536 35.11693062 38.66134947
42.56640704 67.60171232 46.98184195
-55.65073734 60.48352741 43.46598937
-11.26532582 35.2582026 36.50335959
-17.08371434 60.96825943 24.65725969
-56.18850688 35.34666483 44.04354672
50.74511819 69.35024571 48.43042475
-8.692801891 68.63634212 27.26661694
43.09197383 67.55959565 47.37053429
-70.59895759 80.86420996 31.13521944
0.2073680105 67.15151218 48.63293501
-48.16976959 48.81931663 37.60661306
42.11422272 15.06569315 39.32059242
-49.71268382 76.96506947 25.80302211
-16.29827237 42.56660709 28.36820986
-9.702683832 42.95047097 28.48086033
-49.49568271 61.04578888 35.76504082
-49.50936537 28.65642789 33.03533226
-47.85741871 11.20337713 2.262406254
-12.44681008 30.546157 21.50984685
-45.87827135 39.36017956 36.27199992
-49.81640736 70.42756786 37.62184482
2.287009081 65.54402561 55.2439316
45.14444488 67.46580388 48.06791922
-43.87122383 49.20307355 32.33192324
-57.31058896 82.85989879 28.74019854
-16.48574092 25.34470943 36.04916981
6.393028533 52.3544966 61.9085464
47.86910259 67.37982366 48.54643185
-41.34029546 22.73937719 24.42055148
50.53698988 8.029553025 43.64863893
50.3171894 67.30946783 48.89630888
-77.09549702 5.790828888 12.40468601
-70.33015924 54.62670167 44.33606018
-59.15693293 68.24920909 41.38455448
50.74557063 7.998669938 43.9673748
50.86666088 67.29245944 48.98896717
-70.24201204 72.15313856 38.96536903
-60.0558828 21.99658581 36.61259275
-46.07854106 30.66909387 32.32312134
60.28782596 58.45665031 56.53856231
-69.97563135 14.78998732 28.93754748
-28.25684042 87.61063489 37.96915483
-49.90888026 30.42082224 35.93570886
-3.981871669 48.82920484 29.06492483
-54.10380705 69.43020201 38.36434662
-12.50150567 39.96052609 17.11094576
34.12556624 73.07264683 49.17401052
56.57196459 66.89225663 52.54677838
-3.665558374 61.3689102 46.58049827
-53.68990236 83.26348346 23.36460563
-49.57726747 78.90562404 26.5563875
-47.17038527 24.59194495 27.33299007
-64.35524817 80.18575438 31.99387334
-60.41740732 43.28534447 45.91829106
-16.01095013 56.22928123 27.02642701
58.63307014 66.83127605 52.86162038
-49.06858427 86.41006555 9.26203251
-64.52981173 56.43519753 45.54212305
-46.83582375 57.42742791 36.47587848
-6.625097796 81.14942459 -7.682528792
59.47237477 66.80434218 53.01422796
-27.97222538 53.55749335 31.19037381
-63.00703014 56.41037993 45.5398203
-49.61593828 68.13747504 35.03547801
-72.88479035 15.3101975 29.28000309
60.21102182 66.77862839 53.17186796
-46.86144289 57.94256727 36.32729136
-2.635664503 66.73877482 42.31319214
2.220814355 57.64773089 59.52289456
47.6279536 64.20229863 38.8663212
-26.48168188 75.88570475 -1.057065563
26.49166782 48.44956652 50.96712378
-10.30344453 52.46112265 28.89502035
32.54634405 47.01736102 54.78526623
57.08005741 6.877976885 49.94253309
-72.7594626 13.51066936 26.83977433
64.47290118 47.78121924 57.30917967
56.69836776 69.42629633 52.23889478
4.360315551 58.41535819 61.85698307
-16.98769141 81.65934569 23.33399397
-61.36758764 47.78904137 46.25706369
58.99938445 55.34996105 57.88914686
-71.07811777 70.57269928 39.99178948
-7.52552249 68.92140255 29.56146151
-69.98119142 70.55208869 40.02186182
-60.38099626 50.28561266 46.22009769
10.93651126 54.66460029 28.39806114
6.020857447 52.75671875 28.17702354
34.0240082 47.51751852 54.52391444
-67.00194373 70.49873894 40.07302425
-55.84627133 89.80128676 11.97329398
-62.11579908 70.412642 40.14068322
-60.89374342 70.39085572 40.160541
-65.13766477 38.91648403 45.09562477
3.894874594 68.03692403 55.13112222
44.94022434 30.65075586 21.00481684
-4.275188293 60.59766682 43.99663218
-49.74463699 13.19309386 20.3107153
-49.64956449 77.93766156 26.15370799
-46.28949046 23.23560605 25.43044696
-60.14334627 13.74415314 27.5499962
-3.482384281 49.32723757 29.01478311
-57.19372752 24.30277062 38.42809098
-27.87231098 48.49732112 31.64708288
-55.09153852 86.87805179 10.80456137
-49.48218012 51.6026435 40.50585374
-16.43762591 35.71830765 37.97515409
51.29183705 64.85184841 48.10707291
-63.35768594 15.13779564 29.46449381
-14.4546949 59.54776426 23.16611164
-49.50785128 23.67640065 26.75378346
1.198343066 51.07626818 48.60460594
40.4898856 50.49028306 53.74065772
-11.83972187 80.20708191 21.73126021
-52.69238634 70.46599143 37.72426236
-38.68131475 64.35233829 30.27768168
-53.94718794 22.58889198 34.39834626
-69.03719086 70.53428655 40.04849545
-65.21249915 37.44758547 44.68201988
59.08754157 58.54178784 55.77912498
-50.6578665 70.4308107 37.74467091
-50.15340114 70.42411631 37.72617971
10.60619122 45.90562596 60.22193604
-29.99218049 55.14153083 30.66685086
-68.03361127 83.06567985 28.39628651
-11.62072106 56.07123227 28.02393189
-40.51846761 43.93342061 40.42674305
-49.61873293 70.45174207 37.30351513
-58.92830731 8.591316816 17.21647325
-36.16424799 56.48428615 27.90949873
-66.60724613 71.55184794 39.42577685
-61.79227251 20.622508 35.41510783
-67.98544062 84.79580722 25.78244109
-5.17088992 59.89639614 40.65502747
-37.96548237 65.93723371 23.39582611
8.052365452 45.18981693 28.23210816
60.43110588 64.40430742 51.5595425
-49.89402919 28.683682 38.61894623
-47.24428778 70.6910905 34.07216249
-25.89309724 45.96656998 31.51523665
-18.33584033 62.03442433 24.17250909
28.86253414 36.79673381 22.64362232
8.44286634 68.12806417 59.03241314
45.22520634 66.27010742 26.97318585
20.7983765 28.41297987 34.10662838
-40.13989592 46.80530544 41.97545767
-47.28561057 41.83784344 36.91106192
-11.27196192 60.33425563 25.08226166
-17.39907588 25.81209183 36.62427327
3.694297661 67.48722008 55.72409732
-49.56375173 76.33817031 27.22548219
-33.22003598 83.87319731 41.51608333
23.56846783 71.18586499 49.78388967
-46.5751192 70.71281342 33.69237243
-47.75934041 63.67210119 34.07896405
2.484902836 64.39021216 56.94721918
-41.04421416 25.05154611 26.65594761
-49.50074824 37.34507955 37.04901613
-36.98313799 49.37078628 29.07139165
-11.55933678 81.35409264 8.362449259
-39.79767495 61.0482728 45.5400207
-27.39868262 46.98714517 31.60806098
58.69336017 51.05872154 55.32357917
4.442725058 43.74479126 28.21547816
-6.5039652 48.3603431 29.16215161
11.7528823 46.85195513 60.67085737
-44.92874353 35.93557741 35.05971383
-49.44752248 41.89438917 42.49356918
30.67173647 44.07007968 54.3971125
53.89247086 67.4948371 51.8892208
-67.30230073 54.56121957 44.51879349
-67.01023366 11.22145296 23.18505151
26.43865714 26.25971395 34.72111187
-13.02772684 82.64843821 22.7499789
-10.41386025 68.63063042 21.83449693
-65.60195453 57.98712115 45.21064779
4.389090844 37.77669104 33.41472048
-52.66821426 74.21708877 34.96066296
-61.12197797 37.86860386 44.84108503
-36.27792099 71.35367072 24.28909419
-8.024019543 32.80842926 23.53749149
25.21627729 35.30823434 23.13865102
62.19183029 45.93856524 61.65514403
49.73092327 69.36628997 48.43758438
-4.235165166 67.60184293 38.4257422
-15.27023573 71.3986246 19.76094444
-39.67504827 59.08905114 44.95414629
-41.96552237 50.99395963 34.48514591
15.43261619 71.83433916 55.46076665
-21.81686629 50.91474119 31.56230131
-39.83759187 71.02600654 46.25163597
-49.48429357 67.26485886 33.48705601
-58.82758695 50.25773411 46.24748775
-47.1852002 74.67901251 34.37931215
-39.67963412 60.79530639 30.97420334
-40.20493309 53.26780975 42.71272592
-17.54729722 24.38021266 35.79491562
-11.29082868 39.8205904 18.50453832
-11.23717344 71.21013338 21.17995905
-42.52878586 37.84880827 35.69841106
-10.73204455 71.10399286 22.31578474
-11.24929881 41.99526799 28.21122059
-67.6046458 41.12912424 43.18671364
39.13443654 45.20364927 51.27720298
-59.35145464 23.39567009 37.71658549
-49.63623173 58.96581526 42.45777455
1.584959804 67.40481602 51.25632557
-11.76266801 47.53083012 28.14132788
-65.59169173 66.26517911 42.33720612
-15.19218942 25.86340731 35.6076991
-39.24608632 66.92531157 29.64963972
-49.26395771 68.45547876 31.27673341
-39.82625618 55.67366332 32.19152691
-39.33084653 66.3915185 30.03582271
-41.95199942 20.00987166 21.26345354
-9.722825754 70.89223593 24.58155005
31.0933487 24.6350562 35.21372443
-38.23403798 63.3205044 30.51738241
-9.047242887 50.41324763 29.12211941
6.040845843 60.22366151 23.06446223
-65.12191121 31.12085943 42.3636654
-5.346831398 69.97325406 34.41523556
-35.98250801 51.87266486 28.97013754
-3.49959845 69.5865471 38.55213442
-53.00394294 76.39530813 33.04489169
43.20086643 43.35539981 54.47761692
-65.31775654 62.09687789 44.06052092
-71.16714547 82.55836379 29.05652449
-62.36904248 17.39794216 32.17172819
-7.061738757 60.14311973 26.49819212
-11.23568294 61.67393598 27.0032788
-3.331957936 58.96210479 45.32362778
17.28955661 35.44145076 23.10385069
-58.58060277 21.50590283 36.20081312
1.689215659 58.5582573 25.74764956
-2.153165289 69.30379688 41.57773288
-13.37756641 49.68531287 26.74505391
9.342292136 49.34014268 61.37907894
-1.985077599 69.26856229 41.95470642
-1.313765824 69.12814805 43.45671453
-46.59425435 44.07450881 39.94757717
28.39158633 23.27717256 34.01234814
-67.92815005 38.15512758 42.81288617
42.73462877 15.0252914 39.67129035
-0.9784562651 69.05811563 44.20575655
-54.5465489 81.44562727 27.15105259
-73.24321884 57.19491035 44.21116185
-42.53254199 21.38814559 22.85407969
-49.28053926 70.72714475 34.04194383
-49.55267314 25.9127565 29.93428539
46.28301612 13.31447448 41.37508058
-37.72027089 67.97107025 23.04536746
-17.77750393 57.99521591 24.34298307
-49.47903341 58.5329691 41.62594891
3.054089816 68.21327167 53.24429249
42.25212324 41.52778628 52.56825572
-6.093726099 77.96719426 -5.80205761
52.9694493 51.22053202 54.5367491
40.14228095 69.99761653 48.76393744
-66.39170629 42.42036175 45.4456945
-62.34094128 82.95679533 28.57467117
3.390611525 68.14262779 54.0002016
-41.15706984 56.30208332 30.97680377
-77.2034304 12.38074216 17.49772022
-57.25642734 87.1772188 7.744469134
-72.91733899 51.67959195 44.08180592
-54.88000942 19.84792625 31.43552563
-60.97971753 34.93221287 43.94187934
-47.70839541 21.95581345 28.90452601
-30.78998181 45.10320823 30.81835332
-49.79738053 10.22452262 13.99562798
5.070622603 67.79071867 57.76503197
-47.25947369 67.93323329 31.13043192
23.67060378 46.53241716 56.28086477
-37.51320079 46.07976267 32.41723698
-3.990054171 48.3311164 29.02201847
-56.28992696 49.71431145 46.2453468
-74.23025147 58.70390092 44.36185385
55.12415631 67.94563132 52.24782364
-55.98136837 85.76591349 23.88477242
42.99620327 16.04644171 39.42043019
5.572461375 67.68623782 58.88221843
-69.81151489 76.4492635 35.6238436
-48.40218691 28.33904146 30.68199881
32.03580612 29.91182019 20.39058953
5.884352747 67.66518527 59.06713227
-6.021143375 50.37343069 29.00724819
-63.29706402 82.4041628 29.34573041
-38.51309137 58.69354756 31.84392357
31.18158262 24.11310924 35.42939813
25.27987868 26.29913634 34.48445395
6.989445165 67.61567753 59.43110657
7.980851675 67.61112867 59.2948458
7.784831837 69.71887319 58.17055478
-41.8006511 50.6811322 32.25859184
-72.49178146 74.35531979 37.13644995
9.264605985 67.65733048 58.5136817
-39.38828474 52.7005329 43.31570908
46.33728639 13.83887007 41.10380504
21.78579252 45.98986275 57.11203634
24.22925968 31.39195247 22.17681199
-18.70543212 56.77747775 27.00305649
17.73803758 71.91028315 54.13949541
-57.37593925 14.59148476 28.8390732
52.57667335 48.39870916 46.5829454
-47.7196555 53.86219948 37.24571787
-71.79211125 13.92135952 27.71434457
-63.96506409 27.26290591 40.31586025
-13.27320193 52.57162975 28.17852374
-49.86758348 85.56756174 13.36816538
22.53909411 68.93960554 41.09656974
-43.39627532 30.62927575 32.27383505
-37.39978155 40.77072949 29.93368875
-48.90620984 13.24573722 2.059773983
-76.91456033 8.811964597 12.25847745
7.193228539 53.90349249 61.25399134
59.50285263 53.83712161 57.87535417
-45.48584518 37.40113821 35.63257992
33.56749504 32.67280482 23.00680842
-63.79823526 79.62555898 32.56416385
-2.11856533 68.23235549 42.35592642
-12.56518172 81.26015448 3.818131096
-59.96620923 41.29665617 45.61182643
24.89091896 68.91780817 40.90110237
-50.45794509 29.16063705 39.01628693
-54.44222956 75.875287 33.52932555
-42.47665804 31.08882278 32.59034216
27.81540122 68.85491755 41.07346621
28.63181354 68.8395315 41.09638404
12.52190962 35.88419512 36.35329477
43.59024454 67.98390856 48.17641821
-38.27942717 79.52674265 40.49798917
-67.6924166 34.16715292 42.45012348
54.59520772 46.30171236 58.88818826
-5.503963309 45.35055116 28.95146126
23.72680303 31.39763359 22.20668292
-55.6625907 82.54225715 26.28669004
43.71649476 53.00906811 53.01863324
7.661406588 43.16755669 28.47597557
-65.22864366 18.35820259 33.22294928
-69.67345869 33.71030806 42.30466778
-64.61410732 9.490556119 19.51504547
61.89543949 7.919658972 60.23817017
-55.75389467 81.31132616 28.94036626
-64.49526216 65.20322029 42.80282183
43.14548772 15.51847622 39.69432866
-65.49304142 19.75044515 34.59112672
-68.06706587 39.15530088 42.88191842
-56.92820071 81.16035846 30.91685697
-10.84171947 44.44941557 28.77716113
-38.2619258 65.66074732 44.14194145
-74.79559865 36.31503045 42.17717659
-56.71316383 77.28859436 35.03562594
6.131648138 59.29731435 22.14757771
-52.82948922 19.80467427 31.54657966
47.17384231 67.88586741 48.63113789
34.97136685 43.66612813 52.43987036
-37.90910751 64.35259215 24.30082832
-37.44524357 44.38350813 28.78907361
51.66365255 52.64254168 55.75796924
16.34053111 46.99076525 58.18455563
-57.14446906 8.180374083 15.82016665
23.8902027 33.32407222 23.1185798
52.25387709 67.5780751 51.23542292
-31.96996201 47.56529435 31.59489464
-10.91753003 81.58876978 17.16903263
54.48381995 67.46909286 52.07530496
57.59692717 67.36060782 52.74099034
12.71077188 44.06550128 28.74231698
-40.29309496 53.65251322 32.43697772
58.64585881 67.32704094 52.93062165
54.03900056 40.56235261 61.52788756
-1.471713864 59.47415512 50.67789677
-59.37233964 74.63223987 37.24644412
-51.58823311 50.32258647 44.11404568
48.82493122 41.03686884 57.01371044
-59.91550204 54.83091006 45.80596368
50.04962489 64.17670466 44.52824924
-4.338305258 69.76184653 36.67707682
61.17496389 49.53248496 49.26142601
-61.29385975 48.79344884 46.23639018
-18.32391214 51.08542507 28.91475048
-8.647676404 54.47719672 28.48175979
-51.45039079 83.12489652 24.54633583
-32.78710999 46.1982927 30.13995959
-69.44747311 46.64153942 43.63906332
37.71485577 68.36228096 15.77125743
-45.63297343 50.28519381 37.58578129
-27.84769804 76.6557716 -3.909415632
-53.15595168 81.98970488 26.39644811
46.15419022 67.30813808 32.22572908
-60.78232278 63.0558477 43.71644671
-49.38163614 70.1768147 34.62321562
-12.28973189 31.47135239 22.39282968
-71.14578116 71.10904749 39.60501271
-40.0590212 56.72117799 31.72888352
-64.39145497 48.34233726 46.23731172
-52.34559929 38.40366977 42.78258198
40.12284278 69.50520591 48.65726532
-18.47846809 24.85659221 36.26897488
-6.13674654 42.89850528 28.40407045
-39.20552717 79.69657107 38.70310949
39.58921856 71.53547332 48.49676838
-70.63296929 71.09814277 39.63380628
-10.83525523 80.44372354 18.79259322
25.91011862 35.80733841 23.03900779
-65.49237308 14.72128883 28.88006549
3.352938272 67.00853255 55.51950989
33.77873966 42.11020678 47.42301777
57.02397329 51.08315928 55.35825014
20.12011057 72.44977697 53.24902256
-46.63578448 72.4606753 30.89340753
58.85638873 64.44293452 51.41143894
-52.15615562 41.84839667 43.54403643
-36.02060354 37.52583991 26.55323085
-55.82654555 89.505462 3.750398821
-62.47019674 70.94496356 39.85533273
-62.96685691 11.16403692 23.08048267
-39.97013179 61.53837168 45.71012469
14.77090649 51.53825086 28.99991963
-58.90943203 70.8832182 39.89305876
-51.70579123 32.54988924 40.69583234
-56.87224547 70.84669225 39.92857488
-61.56718574 25.32051353 39.10071509
-73.59055049 11.44360177 21.8610917
-72.39375373 77.05234034 34.94197672
-37.97136089 66.0888662 21.63668626
-55.66455338 70.98081866 38.14122323
34.57270836 73.63425142 48.3958626
34.51413113 41.73258634 45.83982668
-36.15288597 41.41207394 28.07734445
-46.1420358 50.79642315 37.57478018
-16.80126095 49.59692194 22.59743787
-39.65274353 45.52369704 39.28047739
-41.09321853 26.43424686 28.09381648
-36.13197935 55.9657925 28.0957567
-10.62148565 44.93964808 28.87082539
-55.88263397 80.04972337 31.95724227
-0.08102510415 65.04034681 49.88931838
-10.15201984 80.56799303 -6.086882393
-43.31413563 20.95808522 22.16894797
-63.98261418 42.84711461 45.85884852
-49.46923338 71.12632296 35.27061796
-55.82516822 20.1083612 34.41909543
-55.44943232 10.18902839 15.48555138
-55.80519351 90.14557431 2.142076614
-63.23617262 73.09243864 38.37851428
1.576251007 50.98474739 49.59497685
-55.81337601 89.64748587 2.099170253
-51.38554926 75.82004385 33.587701
44.27583001 12.93276236 40.36241484
4.11075591 36.06355652 35.87519417
-74.48726109 34.04485793 39.33898924
-69.97173213 45.65378649 43.55231776
-39.79441232 58.79478539 30.91303637
-74.53581081 33.97023698 40.21450259
-46.72661286 25.97384751 28.68614945
-44.74575474 55.7089107 38.54716942
-37.04417884 70.1858566 43.8187068
-29.38164161 76.00389982 -7.702762477
25.19209437 30.91198931 21.73831526
-56.10772758 86.35571917 11.23533218
54.80698322 65.49543484 51.6187748
49.4093095 64.30148307 43.20184223
-39.58827136 62.20631165 32.05672396
-72.40032739 52.16878475 44.13094258
-71.63320781 38.71754272 42.81717842
-16.9205017 24.45381812 28.99427404
17.04266865 44.90027069 59.01207686
58.46370871 43.92720102 62.40893951
-45.19518857 34.48900154 34.42351222
-62.58215056 39.36848701 45.18773324
63.21103356 46.62043342 59.37179286
-7.949007708 60.5544566 33.54558396
-72.0808304 14.82365476 28.94818537
30.49971838 36.24915759 22.86142503
8.865639664 66.20327019 57.98963727
-30.16745551 55.65782917 30.5333498
22.77373518 69.42257043 41.27185417
-74.04920155 31.31280268 41.83793581
-55.82034568 23.37366215 37.29866601
24.32769182 69.28291545 42.59672257
-42.52823548 30.61262452 32.30159426
27.89284485 69.24373428 42.37166998
38.62204379 68.66316967 47.06516588
-49.51826434 27.25338024 31.8447076
-55.83084109 24.78649312 38.37941736
-63.79799018 34.97869379 43.93975631
-60.36647118 27.68429449 40.56444184
52.18971172 69.64408469 50.570476
-4.430658813 59.72121616 42.54747823
45.16083648 68.42480055 48.58534181
-8.446006738 83.71182066 -7.948229804
-41.21243031 64.14246511 33.19674215
-55.15695619 13.55127367 23.01150576
-6.536173784 69.22188493 31.71120928
28.71354645 74.5961637 49.99994221
48.26942349 68.35576247 48.79396006
16.10126767 52.05076935 28.62316385
-65.29674384 10.34378083 21.39365534
-57.67989154 82.29619553 29.52786487
-42.2634087 54.82172283 42.54625508
36.78810182 17.43215188 36.17129862
-71.2657539 34.7622343 42.0500823
-42.50978852 19.13814409 19.83619508
55.14185621 52.04589389 56.19433711
-49.7253795 77.0210294 25.15581943
58.10361743 67.8426182 52.87547416
34.67894022 45.62428133 53.07053011
10.73564612 51.6039616 29.00664977
-55.35190277 26.37365725 37.34304394
-55.36063759 71.57750569 36.98312689
26.20297038 71.09580701 50.32693308
57.81481036 52.43865538 56.95176772
24.36515174 32.83005104 22.9363216
-7.09612509 43.40150287 28.57450744
-49.68409492 85.60555082 18.71880974
-71.11011099 74.85434471 36.90655248
-74.41581939 29.51365345 39.48712538
-12.24038818 69.45955323 18.3867261
-15.45477452 69.53653475 18.10607118
-73.26087462 53.81219375 42.69708555
-4.086606634 69.70921302 37.2400846
-11.15887098 35.47705882 33.94241478
-67.49666147 38.63790598 42.95279567
-64.53899556 75.26294276 36.73673784
-66.76365319 17.92413923 32.72796815
36.21651119 30.28734615 21.06056558
-10.16983947 61.43361525 29.58983493
-58.85889151 12.83512527 26.20441992
-11.96836912 70.42587918 18.77031547
37.09192361 33.60904308 23.11942352
28.91841912 48.61963282 54.35671549
-63.47628481 71.4963012 39.47351091
-41.36061923 51.35782384 30.14578524
52.06411948 56.70603654 55.12271088
-46.52845244 30.67304686 32.36303301
-60.85510498 71.45013052 39.5096191
-38.8265025 52.07505807 32.98961778
-58.46279435 71.40791047 39.54351226
-66.29940683 56.98762256 45.29328336
52.95770201 43.72465425 59.98363747
6.338303914 62.02331783 60.38242622
0.7535695511 65.36933774 51.73763051
-2.749817958 30.86460642 21.79041993
-37.39797326 53.92654538 28.70369475
38.50924476 69.07361901 48.14852292
-57.44125727 71.38816253 39.57794752
-49.93407081 10.19436336 14.37180637
-70.45054348 73.76497841 37.77364636
5.320416422 67.73904603 58.31724863
24.46340401 73.99549731 51.95680312
46.38085389 11.78325089 41.65206061
-6.206717454 61.38044514 41.10456488
39.85610937 30.68804999 21.54145074
-55.65821192 71.57894721 37.02314183
-65.81813393 9.100143212 18.45021747
-23.90825454 22.17885027 33.42980462
8.926180811 45.67246832 28.28913767
-38.8367944 46.05365743 32.97270298
-38.66648373 76.1883587 44.36635576
-49.46634455 65.75581551 39.3484288
-14.37814021 57.7976171 20.16193367
-70.38270692 73.22854285 38.16140413
32.22084109 72.5401378 49.89236575
-62.71929565 79.60163271 32.63615723
-55.95169457 13.71105256 27.13487864
-53.31371424 39.40090146 43.04389594
41.5402517 68.29093407 15.8699783
-56.85624254 32.92463931 43.15432324
-16.15514738 25.96417695 22.96824965
-41.43753211 30.63231984 31.86495287
-49.45874505 71.57369253 35.90186629
-48.52812768 71.64936391 34.84594319
-52.76255154 17.50760659 29.06664744
6.287575529 56.42541961 61.28353874
8.702131264 32.91463427 36.59473364
55.51054188 39.55895064 61.24219633
-10.99848306 30.06907022 20.94537275
-55.69112748 12.54374303 23.15624454
-24.36789136 45.94052947 31.52666806
-21.90173836 44.41635687 31.27014302
-13.6318509 72.33231297 20.26284042
-0.4311759677 49.27475472 29.04215859
40.70818309 45.10356715 52.13890649
-69.83485883 15.68624118 30.15961442
-14.65453563 79.63810167 23.0465593
42.56108811 52.51979574 53.09216605
26.34689511 36.83922111 22.63014624
-31.90992334 47.13190379 30.78791786
-67.98822697 74.2516052 37.48158979
-45.86658397 18.79966477 24.40567175
42.5520042 67.97878761 19.30065542
25.08429334 69.6512493 44.00318702
-47.48068176 67.36915867 31.89416672
-69.90287809 49.6367819 43.91479649
27.3258445 69.73463419 42.60771688
25.89699621 69.27179662 42.42652225
-64.58345929 21.59781313 36.27863213
53.31605822 51.29346686 53.62396763
-36.55367577 58.03871038 27.41879669
-50.75175228 39.35982484 43.03216175
12.23856677 52.59359471 28.88493209
-61.48507975 56.38598161 45.53280869
-34.27226985 51.12718121 31.4714627
-16.8881573 49.21953248 26.99502246
3.59035557 54.96613317 61.25845399
-39.05641432 64.84554702 30.4503294
-26.32642378 76.3343801 -0.4685841694
-52.14904446 27.75132403 38.21920109
-0.7420019829 59.8064975 52.50730463
-40.88938528 47.30060882 30.54190395
-51.72141094 80.25497294 28.78098466
-14.37817698 69.99948737 18.35310661
13.81092854 69.92496277 54.62890307
-15.950046 24.46649665 34.48866245
43.15464755 68.96029498 48.57816488
44.17528417 68.94100126 48.60749888
-71.85649305 53.67365697 44.03749782
-49.49718262 39.10436646 39.9318137
-1.958069702 49.80386268 29.01771587
-46.65374093 44.28462019 43.34643678
-70.7729984 30.74371864 41.99284625
6.521335121 67.10181679 59.65900761
-44.04719471 51.40647491 35.91999594
46.21783883 68.90176743 48.67342637
19.20218776 73.92390573 53.79123633
3.006489146 56.99696695 61.10096376
-51.24079155 74.73020117 34.55850242
-37.71627732 64.70798104 25.96508198
40.19864557 42.17385756 51.28643996
31.43256901 42.27781906 45.92468067
44.51761029 52.44962665 53.53361965
-71.48021927 73.78883185 37.69310354
54.3565567 68.48816598 51.92271205
60.60459848 68.26018531 53.37773932
-47.8031482 75.2451682 33.75145088
-6.802158451 69.28172489 31.06726803
-50.98251647 24.89291913 36.21934161
-1.502806502 66.46464922 45.2793998
-55.88742918 89.00918031 3.696568806
-61.85873424 69.87986339 40.44990155
-55.95220886 46.70410147 46.16581082
-63.97871899 22.99762832 37.39315096
-17.84490804 50.63891407 28.18018613
-49.45425505 51.39033412 42.9651709
45.11006407 46.11085858 45.4327072
-55.29607076 13.52233732 23.37395
-72.46037553 48.68356331 43.81490187
-71.53771198 43.18931494 43.32713935
23.94446527 70.48732156 46.16810428
-69.82595215 24.50529499 38.48608131
-69.28608336 56.10223231 44.48783479
-49.70848966 79.56648637 18.9096388
-46.49147498 72.48699112 30.56039364
-64.19816022 60.53340571 44.51701064
-13.43315066 32.46456201 28.56090804
-47.6557455 46.80559526 43.40541108
-17.16009461 57.5408323 23.67341505
-62.97115526 42.8324505 45.8361889
-13.69626259 43.99958786 28.7168271
51.15857414 43.26276691 59.86202638
-55.79550225 83.46506809 27.25265613
-47.31886054 56.92109683 36.61921632
-73.31865858 31.29117693 41.94966446
-69.75399995 58.60157014 44.6961363
-39.76789416 44.65168523 37.77210476
-49.62268187 84.54536167 19.36126568
-64.40369168 33.52980775 43.39507105
22.77930678 49.20722962 42.87958577
4.474006543 53.91239715 61.66919156
0.4015066049 60.53143136 55.52695163
51.36888802 66.67317773 50.25562281
58.7376745 46.09519984 60.49554335
-51.77596787 80.8218765 28.03699959
-63.82070817 84.71434118 25.93391889
-70.82759805 29.76682888 41.69042614
-11.31423011 36.51030466 33.63065272
-12.7884482 31.47514672 22.44389047
-70.75967251 72.1664891 38.90910713
-41.88361822 32.04187986 33.06674557
45.3991663 43.21282388 55.7135136
30.85101468 41.13886431 41.7774812
-46.15063829 23.66777937 26.21362475
12.75968137 52.07628574 28.96421161
42.96548356 49.97816701 53.38692218
-59.54496435 71.96046016 39.16212931
-14.00353678 81.5663539 23.84441444
-49.70906264 11.19586349 2.702749652
-13.19045495 53.03210481 28.64385374
-19.84918012 30.329076 37.0948422
-68.0031587 64.22147581 43.21532336
-56.48585975 71.90399411 39.23423892
12.72704092 53.09718297 28.77239802
-65.55792659 41.39178283 45.57390069
-23.67398992 58.12685 29.76601558
51.5386846 53.13346308 55.90946843
-58.74650155 20.57276797 35.41167954
-74.82181054 56.01378918 40.7436021
-67.7660589 59.56953984 44.73340707
-55.72363324 71.9654973 38.37490367
-72.30427277 72.20231294 38.78780241
54.62898492 64.10335424 50.33308493
-63.10165376 22.04632583 36.61602103
20.5984478 43.0799547 56.15896102
43.42878003 16.57822689 38.99122555
-55.42636154 72.11287556 36.60733745
-77.02266152 5.35098308 11.67020598
-57.40755988 65.59900937 42.68318681
-61.77053983 17.84602754 32.68252896
-54.92047624 72.10546489 36.59689055
-53.392569 72.0780617 36.62362575
-21.37020996 57.0381339 30.31200028
-74.91107409 30.92323826 40.69800706
-19.1119682 23.42172893 35.56680453
7.609813575 59.14633583 23.6183518
-52.72750667 72.0646892 36.65203208
11.05103963 49.44451697 59.84155742
47.85943834 42.0575557 57.00223947
58.5412788 69.8908755 52.32090742
25.30952491 68.52654231 39.53673023
-49.45685116 72.12450891 35.33386741
-53.66073742 70.48368804 37.70349758
44.74834699 66.86420365 25.99406388
-17.28755438 54.62326886 28.43372438
-41.63058961 26.38320668 28.78880818
-55.81677704 89.5071933 9.555079482
-56.20861451 80.04071628 32.1239695
-66.04388553 85.3469045 24.84127617
-46.61511677 72.12813756 34.74980755
3.753087757 65.75269304 58.36861914
11.2177158 70.72606814 57.47691078
-41.1155418 61.53295476 34.33816128
45.15322257 67.03349359 47.25817753
30.51310337 34.2087637 23.23869026
-69.32477937 53.61067204 44.28585993
-77.43478551 7.171293687 13.92387041
-20.28045436 21.50673989 34.7136685
-54.13136347 28.26238856 38.49107208
-54.27773179 20.29410738 31.96771246
4.212082563 50.34890621 56.47361756
10.7976651 69.20831011 57.69633724
-13.8904568 48.84049226 24.99687726
9.028464627 69.15920863 58.60374032
11.98598714 69.277874 56.66216852
0.02152518381 52.27788957 46.53301556
-64.51533618 78.53686055 33.68604363
12.39882557 69.31739997 56.12459132
-0.2286903394 65.61705121 49.04931421
-55.14490198 50.88448176 44.09607413
-54.87082848 16.6746728 27.48472986
-38.58101119 68.01547608 28.52066223
-55.92872007 41.24221005 45.47390319
25.37501047 70.00681573 45.64671208
-41.94765514 43.37737273 35.50102472
-49.67848334 52.3344311 43.70144773
-38.13391515 70.56877806 45.4079354
-30.14132634 76.16411514 -9.417782644
-73.15543527 38.7420808 42.82262041
13.15984667 71.27687498 56.53900772
-55.80205066 86.69800928 18.85676735
-52.60520387 16.16936447 27.09200856
29.03935193 69.91759987 45.983583
-40.88775707 30.22444264 30.66840258
-40.62933974 62.61423873 33.34638774
29.34049345 69.91435892 45.9637769
-14.15453282 50.20779467 26.65451684
-15.72145358 69.98331104 18.79706427
7.536686225 43.69551998 28.19741817
62.37965668 48.24246877 52.353852
-63.8353225 69.38360505 40.76113691
-12.97403965 32.88385081 29.43258342
49.11186039 52.36210612 53.67347075
-47.89249199 28.32970906 30.69313417
-26.8792539 48.98176675 31.66054343
2.702647655 44.75976453 28.41805274
-68.18271084 33.18704463 42.26815611
-48.24082902 63.6795941 34.0838037
35.49414029 69.63488348 48.03459468
-13.30856101 69.90593077 19.23519793
35.87820009 69.61459301 48.19689878
36.51501206 69.59069329 48.35290031
-16.23126426 53.58155801 28.67194157
11.60625747 30.82837013 36.95321259
-12.02068266 81.93370431 1.721874338
50.70788374 64.64420229 44.80230263
-49.49260668 49.24549009 38.73819454
34.99986457 47.52081379 54.29955881
33.72706186 23.03874835 35.76264356
-49.46406122 56.44221886 42.58748491
-2.051754583 51.37108712 39.97530336
-37.61678485 57.68298327 31.75107857
-71.47600147 46.67369781 43.65259778
-52.14108572 53.86920155 43.83426806
-50.57257446 50.80801988 44.11172915
42.62923196 69.46738462 48.61833902
-49.55195326 66.40032748 37.70943019
-49.41503582 35.99341746 35.24382722
35.25031915 22.51147475 35.76648503
-57.06799328 88.24858202 18.57793283
-78.27769628 9.010687902 16.03815199
-1.854093184 58.61305511 49.09381412
51.87368935 7.863213225 45.32471639
13.05596422 72.2965428 56.37505228
49.25118362 69.37290063 48.45233225
13.18835075 33.78692944 37.26622769
-54.20038436 54.92131377 43.66658386
-77.04129366 8.776782652 12.69106402
-43.34157192 39.31945746 36.26096792
-40.0591033 59.75929341 31.42008346
-15.38890008 69.9642246 18.95521369
-3.667166648 69.62151963 38.17810391
-39.27782982 51.7394943 42.79780029
-64.63506721 25.84645624 39.40689263
-55.89023696 28.75984946 38.87825154
21.22162526 50.04920246 39.22905015
-1.110514896 68.52340395 44.61162346
-49.69851326 79.92166171 26.43788474
-41.66495857 22.7049277 24.88238166
-4.026708273 69.15200396 37.87051025
-49.62511163 80.28773954 28.00082915
50.23507694 69.35975281 48.41732736
-38.35200171 53.59012711 32.79102318
-50.62877656 74.17942726 35.00893223
56.48468953 6.930526221 49.44604219
51.82122026 69.12952797 50.78745856
-71.45932477 47.66971745 43.74017633
-5.016502172 60.27117697 42.10149653
-74.81503701 35.11552213 38.62572833
-48.16388918 50.32299838 37.62957908
-11.17519366 48.02164541 28.15819184
47.7510011 68.36949736 48.73338171
54.86447719 68.98194471 51.92034559
-68.21387322 31.69611613 42.10196238
31.1367643 71.08478525 49.51397743
58.57139243 68.84647505 52.7860455
-67.56931833 43.12016772 43.37305426
-42.4283083 55.33278739 42.47153297
-16.77240091 59.45323927 24.70542736
-38.12725489 74.10570839 45.13388295
-70.63327891 12.57348471 25.66053979
7.460657727 35.45729586 36.4476232
58.83390241 68.83794357 52.83502295
-55.8403218 87.41775366 16.3353883
-49.73413396 84.00025521 19.88387298
-64.03141324 75.25467605 36.73590485
42.14408103 64.94346285 37.13479409
-77.68923293 6.12177033 14.50274646
36.89582139 31.19688493 22.02570902
9.028053565 33.90332493 36.70841744
0.03553564334 45.26937666 28.83737837
56.31070502 52.95626794 57.05642615
-54.59731754 24.47970662 35.87893732
-50.41728636 83.09931353 24.64630268
22.72471007 37.93638144 22.23756248
21.22676023 37.9486729 22.38054231
29.60325028 46.51309045 55.37383185
-9.689291364 55.00928879 28.33012977
-72.07514883 83.72349557 27.35726764
-55.3712519 66.81513722 39.83043239
-37.49864637 41.27326126 29.9454282
-49.48381582 53.99065947 41.91753822
-49.47918063 40.95548249 41.74583861
-0.4296463469 66.72285368 47.90395724
-74.87882347 54.83989395 42.72862591
-12.36982975 60.39250792 24.61539644
-21.10350087 55.49605828 30.68275384
-66.69331199 79.12184451 33.13711675
-68.98402416 44.14445526 43.40545171
-72.74394192 26.47466321 39.48725963
-49.47345816 51.6973552 39.40470769
-55.33572353 78.0870874 31.33009008
-34.96083775 47.85989939 28.74528189
-34.0259156 51.62901535 31.42546532
52.46739801 7.321673774 45.67143691
11.2682707 47.3508505 60.79837511
56.9487524 46.17993602 59.85302194
-12.07714831 35.68676689 37.50973301
3.310774971 56.96736202 61.38661028
-67.14464314 85.36944774 24.78949894
4.030250895 44.25685327 28.17639086
-48.71371241 52.86365326 37.37386205
-38.36343442 67.59692724 44.99127387
-71.90857545 18.00449596 32.77629617
52.49545854 58.56499023 50.94028284
33.12359633 74.1117342 48.95588944
-63.63709637 30.61297778 42.14972228
-8.62485933 29.59600522 20.15775122
-37.23920461 68.72617388 20.0144716
-49.49639931 57.43152676 36.93568375
11.36781097 48.37940393 60.49247503
55.49084675 66.92967062 52.31862515
-55.18779253 44.37730918 43.89793033
13.08452671 30.32379035 36.70218722
-26.25539559 75.34367424 -0.6345748038
-14.85948824 26.34646239 35.76323594
-55.97414961 36.81951591 44.38466001
-32.02454716 51.08356366 31.54915287
-5.153411946 61.67344294 43.32900207
-39.59629164 53.62312927 32.6452035
-17.0405157 57.62917894 22.62501818
56.01830831 66.911376 52.43041231
48.3578059 66.18988538 38.96034004
21.94954713 48.65279903 43.64742015
-37.72329608 63.86814559 24.06256123
-51.35837182 25.83916943 36.95953731
33.00248806 35.17156329 23.24035118
-65.31587577 24.43545727 38.43670988
25.69899802 69.6385014 44.03394606
62.97421678 48.94698041 55.71526281
58.25997516 51.95965859 56.60077551
-55.85509731 88.1752016 13.37183801
-57.06221372 72.44916807 38.84201667
-34.96779542 79.23139124 43.2950963
-70.26412832 21.68825494 36.3120552
-7.423366555 69.42164266 29.5614684
-27.49080718 54.56685693 31.03441708
-49.47199829 48.53465551 41.1595104
-49.69526048 64.01109308 36.33953164
-40.25601526 48.33243312 30.09622865
-5.69076579 81.32233357 -9.867965533
-28.78919738 87.07962721 38.40836236
3.022363195 56.62023475 24.68482666
-51.93661962 72.58917302 36.23925509
-51.42356532 72.57814601 36.26942207
6.880297702 51.35204463 61.79953362
-64.30839454 62.5978765 43.87871282
-54.70217997 74.25198998 34.94339365
-31.15608639 76.37866731 -11.71494032
47.18549439 67.40255145 48.41295916
-56.32511779 85.05211302 14.75672012
-16.89776753 57.35228915 19.98549261
-70.53322557 44.17021117 43.40190023
-54.63707205 47.36057796 44.12078871
17.99829945 44.91376857 58.67313906
-16.78552522 42.56461878 28.48421362
-53.54167488 85.36436161 16.42773252
45.9391712 66.05862151 29.29211166
28.38289302 69.62263927 43.70625037
25.14159815 44.02264302 56.00242268
-55.85347044 49.71714564 46.12921052
-25.83197283 75.25397178 0.3260077708
-57.81221453 50.24109587 46.246999
-12.69387748 43.98950095 28.64276247
-49.20090048 67.92145409 31.63741526
-26.15247956 88.24066235 36.08066263
-41.72025318 33.96833596 33.97844624
-36.95764169 66.57527794 21.6233824
-19.56485613 23.90462158 35.87404362
17.95724322 45.93891106 58.4336483
-34.28136537 53.76673875 29.96449108
52.01268389 39.45841508 57.2497075
-64.24528466 36.45080801 44.41560466
-68.99203349 43.64627947 43.36352637
-22.3682527 74.52262188 8.155500118
-46.49311068 74.87500708 31.97207812
-66.04695362 34.53311013 43.71466655
-20.16446016 19.71191998 32.2205632
16.85030544 72.86696647 54.85619501
-58.57385631 35.38537337 44.04909023
-8.740572015 77.33826203 -3.822824423
-50.24085231 77.99481168 31.42967152
-12.47844414 83.33583599 -8.641156358
-38.46481484 80.09769794 39.73191886
-72.51168704 45.69615079 43.54490675
-55.74545328 83.51415491 26.67327579
-21.85983369 74.41482169 9.309965029
29.75041975 72.04521049 50.28232642
-63.844445 80.17586294 32.01128717
-21.267544 74.28949156 10.6519357
-49.6834409 78.05386162 24.81123307
-64.89073083 63.64828422 43.44915017
-21.01385398 74.23585352 11.22622512
-70.96449649 47.16566838 43.67053827
-25.35577051 49.45881115 31.65876734
-64.86615295 34.50735669 43.78844555
-43.44523194 48.13242092 33.02631947
-53.70650584 74.77228225 34.54022111
24.93382835 34.79982997 23.26780377
-13.85716285 73.30173444 20.70533866
-70.29896021 18.43809194 33.26247529
-46.20243662 53.32558299 37.35917019
-39.48050683 70.07683582 45.54892223
-47.98793822 42.31028548 37.38718905
-10.26890126 49.43040146 29.11141084
50.36541 11.03028966 43.80646482
-4.876504911 30.44406399 21.25131434
-24.61643986 75.48991328 3.181858512
9.831153358 51.161563 28.48818677
16.17966776 31.25357808 36.97155248
8.493596172 33.92514852 36.5569974
-49.84935937 82.04951552 19.24489355
9.826208975 69.68023335 58.22981139
-37.93483458 56.13854964 32.26072272
20.39107399 45.44786409 57.84329274
-42.17891084 46.51398264 39.91955654
-52.89238505 78.59567642 30.78669917
-58.4094409 32.46258435 42.98775773
24.45021205 70.47998077 46.15687257
-74.84939648 55.37682151 42.31660788
-64.37733737 52.3653329 46.14587718
-76.92647716 12.34014705 17.9161613
-10.97095216 80.61128603 16.87328341
-55.77872123 26.69473227 39.52380885
58.47856088 39.03382693 60.94555569
61.16678139 49.03439652 49.21851965
-56.31085289 70.30906542 40.23603773
-55.86831561 7.665320465 4.075990251
12.72922352 46.85500582 60.44924857
-49.49599045 39.50686969 41.08567623
21.83532464 44.45822865 57.40299406
40.22157827 18.21604728 38.06976635
33.97172294 42.51507415 48.51285982
-44.35354597 28.27896994 30.60725272
-39.8324473 55.17457034 32.15990219
-73.83094506 58.17370341 44.61398246
-18.39235256 25.81758408 36.74993912
-51.29596165 71.50934546 36.99922248
0.3749787587 38.64367293 29.94231758
40.64224404 69.99319327 48.71993994
19.79823073 48.34379211 41.81822566
47.07375705 53.40352657 53.62586238
-53.4536468 25.40278152 36.59838725
17.26248289 35.95732388 22.94702306
-35.33937224 47.85262481 28.90191933
-49.66419224 28.43719464 35.60987871
-49.65682908 61.83417549 38.29688746
-66.0211105 41.90464705 45.53517138
-40.74320808 60.03670918 34.15674295
5.986923405 33.95072394 36.73813684
-55.68362339 69.38467886 39.19409316
44.59603481 70.48318407 48.10439825
-48.31287634 42.87157216 42.58661389
13.64108409 42.08290679 28.27374104
-63.18800869 87.09782574 21.45059392
-49.60414462 76.55676897 24.69553218
-49.97365673 11.80197269 19.0236176
4.208777416 67.3763626 56.91289764
-64.92283459 50.35908348 46.23335068
40.46572104 51.00468867 53.6003111
-68.58560067 71.05648059 39.72700589
-61.48570927 75.2094117 36.77588546
-50.20547096 65.68265968 40.33863101
-65.20684922 30.15927358 41.88937204
5.467080386 58.92916747 61.50788408
-55.78890282 52.25759985 45.7586549
-69.76379061 30.73019009 41.95743348
-49.51147435 26.90306513 35.91012679
-49.61012053 59.29953366 38.57874758
-49.74279292 67.74880539 33.74498981
-65.7521022 61.07615791 44.33933389
-60.08323995 87.03071375 21.63758086
5.912920528 58.44983001 61.16071781
55.63101145 69.46915736 51.94488382
-77.87094447 12.06658037 15.44539724
47.76369061 65.93780967 36.17327889
57.5388499 69.39876847 52.39817324
-57.61164492 79.50291048 32.80813734
3.740126514 50.89097331 56.09755274
47.86762085 42.55564414 57.04514583
-11.99449805 82.13296538 22.71027979
-32.5509797 46.68050638 30.32368072
-48.72701895 29.76176954 31.70780317
22.82313076 27.85573141 34.36280157
-12.86778303 75.71017665 21.69090783
-39.45842151 80.27680162 37.84223902
7.89036515 32.47501627 36.0263079
-49.38234347 31.65520472 33.15896388
-15.0157589 35.64528406 38.55170676
-49.51491597 69.49405193 36.7480009
-5.0068557 47.84625952 29.01786032
-13.26638091 80.71040526 21.98702885
-9.250492691 82.11543107 -6.742676619
-14.09635135 72.34362528 20.22010185
-54.23389756 8.694391277 9.298015425
-66.29080859 36.99375568 44.32940638
-61.40194913 70.39943689 40.15784234
-72.40637074 73.28302523 37.91485776
-13.68573345 53.53638101 28.71094117
-23.85825655 46.43344342 31.53401363
6.078470152 53.7320886 28.49651325
-17.69494732 46.61987312 28.19442108
57.17286284 43.97129897 62.14319029
-48.08150694 72.62672506 35.06813032
-15.59342234 80.14315127 23.18926722
-71.41113593 73.25176737 38.08792461
50.80519501 66.80886038 48.78801897
12.72128345 46.35679509 60.40771563
3.655542271 56.84594616 21.94385245
54.78854882 54.98963418 57.04847477
61.22349665 45.41687024 62.06938651
24.71651322 45.99815878 56.45682387
-70.91443176 50.15370983 43.93347011
36.54726521 17.90718861 36.52929561
-49.71892673 73.63946934 35.27700317
-17.73283422 52.59124355 28.80131114
-67.43289371 34.16012825 42.48217848
-52.14731211 54.88916337 43.648276
-19.2038411 52.61472173 28.80928913
44.91719045 44.31171711 54.70195629
-10.5346864 48.46093088 28.76313789
-66.79766461 73.15455087 38.336668
-55.76912253 26.23722998 39.00635787
-2.421870786 61.1132112 49.3116607
46.37457725 40.73004157 55.21624134
8.154477026 52.4075085 60.9572267
4.77774342 61.96808124 61.32126125
36.98951678 68.52994874 13.96317368
-17.4968973 24.8570049 36.07699264
19.63109662 31.24698619 36.38986772
-16.19020039 59.53122136 23.68912507
-6.375497203 81.73634364 -8.716873093
-16.13949226 32.20107181 37.96245253
-68.13436547 14.31290897 28.29803976
56.20156949 51.10156768 55.3013893
-12.94105623 32.32209983 35.94750966
-55.7837797 24.33487097 37.786561
-68.07508867 41.14343415 43.11031002
-13.93691909 28.34833523 35.65465908
-67.08979055 75.31077523 36.66791549
-71.06482459 41.6919017 43.1401
-46.45714538 74.20598157 33.9051152
-38.78039957 44.06590118 32.73070974
-49.76453845 12.89248084 12.15096287
-4.24347544 61.4869902 45.31995157
-57.39559231 32.4467148 42.97863607
-74.9326948 10.291874 18.00721987
3.319478452 58.97183573 61.42212036
-57.09711081 72.98137654 38.49704821
-49.10893505 30.71107712 32.41366347
-50.21948717 32.04125636 40.49032591
55.72376265 48.29786002 47.15350706
-55.86419876 88.99839089 15.47067383
38.32024484 68.90600396 15.17051343
10.45708606 35.9346623 36.16120918
-60.51080727 22.00306761 36.62410413
-57.04722459 81.722615 30.23911213
-14.29247319 29.79229077 30.61325674
-54.54707783 73.16931808 35.82897872
9.167540544 44.62061812 28.80047194
-55.59443281 71.49721872 37.95974296
-60.31721381 15.09072856 29.43104656
-30.49442281 53.08868402 31.28701199
-1.512459908 66.97173482 45.22126196
57.36450458 68.88538793 52.56447709
-59.38163863 85.20731609 25.1911899
-12.67617934 53.52267776 28.67749042
4.891864353 67.23000848 58.48161453
-52.74062739 15.7354616 26.3282548
-21.58213835 24.45810084 35.66020108
-31.1401115 76.87282495 -11.62788844
-54.34911359 38.92542608 42.93437714
23.9850434 70.46685229 46.39798802
-40.28404897 64.03435947 45.92794717
-27.24690786 76.0487153 -2.803478105
-37.35573211 42.85648467 29.01888333
-39.54556515 44.78731194 36.15525119
38.07292196 32.60531248 22.93109682
-25.20907588 75.61541812 1.837925819
-54.59156031 84.83424896 16.9552457
-49.91356764 10.59858531 3.848750296
-23.34885562 75.22215983 6.048400555
27.59125093 47.03912696 55.47755744
-58.04768697 74.60825517 37.27225682
-49.0407853 79.17345437 17.51826475
-54.41305403 78.61942833 30.80097027
27.85649416 67.74876404 36.42674335
-29.42740963 47.52161979 31.61702084
-49.46363419 67.90532058 31.87480976
-57.46630817 40.27006462 45.39922232
45.05705393 52.95228166 53.42219912
-55.84627141 83.91190165 22.07516724
-61.94081229 43.31047653 45.91706217
-39.74890418 56.75150515 42.97096612
9.752644988 67.23103976 57.54266256
5.364917726 53.87181975 61.97034152
-17.9747959 81.7856137 22.05642928
-40.53437639 65.2756383 31.56601332
-7.578372661 60.46660456 34.49475238
-26.39482981 51.49626212 31.51126792
-0.5425567343 32.24893594 22.77909737
-37.58923451 71.27181519 25.48940844
-72.32690044 79.23809267 32.86198239
35.7017779 46.08131549 53.39652377
-2.973116093 46.80704031 29.04074719
9.79075411 70.20033425 58.0255028
11.6844112 69.25655624 56.967153
48.26593041 65.78357081 37.8680162
24.70401058 70.85417045 47.59124593
-56.49859686 72.4374131 38.87099181
-54.93557684 60.56877262 42.34001514
-14.1578559 55.0829641 28.32703515
-66.40047848 15.18603322 29.4847955
23.3409827 28.84671633 34.41324969
-52.46698912 84.56780873 19.64311026
26.86410933 70.85475399 47.17252783
-55.79086061 87.293692 17.76615428
40.37862774 52.07783199 52.81237112
-78.18189243 7.492879236 16.15978968
-36.4377658 76.15432793 44.3363799
-55.59196997 28.75805853 38.84216065
-22.82505538 47.91445971 31.6241807
-15.19886362 25.48844729 34.13514029
-69.5418767 11.25962441 23.22472906
28.13951048 30.40964747 21.18113512
-44.55195644 49.4044547 35.95060519
-8.190669915 42.44125793 28.27718598
-7.8916723 58.0945177 27.13156936
57.08124993 67.87517397 52.69251458
-44.5977057 18.92770423 22.67731333
-15.40060362 30.1711731 20.5995987
27.9890944 70.68371124 48.94357582
49.27493226 65.0697073 40.13600168
-68.38748607 76.42470511 35.63736439
-77.17314871 5.303005697 12.25586136
-73.88862703 57.1983685 44.29410035
-37.90144841 46.05260993 32.80648693
10.20971756 52.12369893 28.90009812
-20.76238777 53.48856777 30.6155528
29.25170688 70.76535571 47.75499929
-49.62756517 28.72271164 38.11504527
-49.49454152 60.09699581 40.95246546
33.16647933 71.55424428 49.50371646
-53.09331819 63.64387243 41.25052774
-21.23494702 43.91228509 31.16797411
-49.50248399 53.81997254 38.07591532
5.913141951 51.87135429 61.78209139
-49.88716248 12.04901678 10.31261233
-26.42469543 75.37952029 -1.018415429
-13.24733903 46.05455297 28.08224337
27.13695342 28.36233025 33.48580133
-10.25310112 32.84264347 23.56540723
-11.2381594 47.01180907 28.23983523
50.31425661 54.3343231 53.85579614
-38.27478217 70.1176443 27.36532307
40.71062361 68.1232807 47.10764546
-27.0198531 89.28618737 35.76214017
61.07366103 54.27790441 58.28550403
-16.76602118 47.87427465 25.10854211
-12.37230571 31.01078997 21.92848069
-47.20150952 52.32956703 37.45889773
29.28709102 73.53780196 50.52351762
63.76152287 48.54317212 54.42617903
-49.47898751 63.75332903 39.29060072
-49.73461078 36.91793657 42.05220333
-48.21863154 42.26560056 37.94991887
-53.14253708 87.26433678 11.77523809
44.70843264 46.21434646 44.30793769
-63.35095596 34.48636506 43.74317449
-18.18607635 74.12929219 17.75063456
57.86065595 38.60791778 60.1810171
26.8688023 32.30378412 22.74150995
-56.92365633 31.95421353 42.77930827
-38.0996391 60.74162799 31.29602686
-67.8767756 83.63744868 27.55550786
-16.60925093 49.75450173 26.55816076
20.99271732 72.00293955 52.44318515
-42.06679172 55.27431293 43.08140386
-39.1817126 59.73792522 31.50081731
-37.46274415 50.87158875 29.22036775
-42.56058259 51.47423623 34.84986712
13.1554477 69.83439056 55.80533573
-59.8541194 38.82814477 45.11352752
-8.437824236 84.2099091 -7.905323443
-49.49793794 54.31989535 38.09822044
16.30678141 71.38671557 54.66375834
-61.87823199 50.81214885 46.21985412
-9.74266528 41.4763801 28.12088494
-63.17212159 30.12398802 41.9109578
57.600771 63.58871918 49.91396584
-66.60879727 26.35318816 39.7274218
-70.85801893 10.42355118 21.52819146
-27.39899819 56.10152637 30.68126838
23.24443518 26.87416582 34.02390418
-16.57383444 73.83634646 20.84389875
-37.8028575 45.06038924 32.6528252
37.72560427 46.37364107 49.61703857
-44.19877943 25.93759779 28.62488965
-49.57381672 21.68269329 32.43085168
48.36350592 9.666258871 42.5429819
-70.55491639 64.78439886 42.99377815
-61.06171594 76.82429232 35.42823702
22.2685392 36.91428207 22.53655072
-39.17662742 60.12700468 44.46305401
-53.91829999 61.58038362 42.05577738
-19.14302946 32.40295238 36.1916678
-0.4294881128 48.77168727 29.05516989
-72.53983636 44.20370238 43.39578262
-13.28167627 50.13915799 27.2848427
-61.90955466 80.69315542 31.46382419
-57.87703681 43.24277053 45.9280565
-44.2380355 49.04121838 34.28080968
11.55967675 72.30799569 56.52744982
-46.21771195 29.72247638 31.68540692
-46.63232375 74.3934601 31.76213472
-14.1504438 59.16462795 21.72917389
-34.35347471 79.75821984 42.88877161
-44.05458637 49.12212413 33.30661172
-68.41743269 47.62193409 43.71477338
-16.68403918 57.74669187 21.19285986
25.32812165 72.0459914 51.11661995
41.65280251 69.97898338 48.69217906
-70.53174832 74.30815747 37.31015447
-49.66334666 78.89283125 20.8946695
-6.596932571 68.14899067 32.52445419
-60.7601216 58.9278089 45.02037298
-72.02297198 73.80245102 37.63850851
32.88534377 41.00466837 42.94736697
-50.23903522 12.21505578 20.10549795
5.285862124 34.45969395 36.78997899
19.71777131 49.18392941 37.90728312
-53.50714683 79.16022485 30.17688852
-11.86625544 47.03131212 28.13321128
-42.40606673 20.01592107 21.27982094
11.78875036 55.15813562 28.33285659
-69.43084195 73.74615639 37.79768289
-56.80755081 51.73650762 46.17549534
-37.68373152 64.54289648 27.87529764
-67.056421 26.83342533 40.06448365
-16.23224926 71.90295138 19.91645371
-24.09623429 57.10002564 30.11338491
62.06665307 5.322211725 61.2253777
0.9305283152 36.55278978 30.80230513
45.01169297 50.94152163 53.46665613
39.55724132 69.04052382 48.33285677
-62.91659773 55.89971288 45.62412921
-63.49474709 42.34657704 45.74977379
-58.20910333 73.536581 38.09053415
-1.115970935 59.39381527 51.54269818
49.54930207 51.88700519 53.27872542
47.14491585 69.93410105 48.16582686
-55.13254023 73.71908671 35.38515524
-19.69157048 22.4618934 35.16652763
-55.8820856 89.33525583 5.736868343
36.09652538 45.94986336 49.02059373
-53.50506046 73.15162796 35.83561951
6.861218101 68.68479255 58.69777452
-41.11570678 68.27338701 43.4899507
-62.16118828 67.77579092 41.62662677
-20.89892372 45.97464601 30.46906527
-48.12728265 73.64185932 34.94572245
-49.30912392 33.10014 33.85105991
-65.98844475 78.00962717 34.26084329
37.9641904 42.30989385 50.1333553
-11.96816294 83.25853323 -2.01444123
-31.21014449 77.36081277 -11.45281054
-67.85793531 85.98369893 23.62149263
-28.68605852 76.83089633 -5.782511208
-4.37714725 61.00264799 45.14140055
-60.67808162 8.228422796 15.93626325
49.53820856 10.04668613 43.72932284
-62.43071909 45.30630216 46.14805343
-41.43685384 60.55525637 45.7492756
39.10251984 71.53320549 48.61591137
-8.619504346 43.91644906 28.71379472
21.30492269 29.88710604 34.37721726
1.369922798 68.56727722 49.45591783
23.16250909 30.98184764 21.31440284
-52.0323366 34.97791364 41.7050318
32.71694851 43.40913005 50.02658151
-27.34031801 76.54849552 -2.760836792
-25.14617351 76.08590361 2.190831879
6.749679433 56.53920548 59.87450304
34.76296081 19.47146566 36.19022609
-63.55339692 66.22809412 42.37900072
-13.27456341 80.21231682 21.94412249
-9.313930623 78.91113492 -4.49261532
16.93017884 71.0160338 53.0213761
-20.51397826 75.11201711 12.61303148
-49.46604088 57.36665702 37.68294963
-37.33457242 68.57605846 21.77530843
-49.90728796 13.10649771 3.867079762
-1.126283875 66.37382724 46.26192262
-18.58365387 74.70817328 16.93302154
-72.17925739 83.14990689 28.2091171
-0.8860194792 67.95749898 45.311608
-24.05291086 75.85652939 4.645083957
-46.30437499 25.506416 28.20527129
-50.07333347 47.2884487 44.08778554
-17.33923279 47.12022682 28.14475408
-27.0767422 76.0124325 -2.414732421
4.016388147 58.39356952 21.38901808
-39.89749415 67.43401221 29.69513972
-50.82641034 86.28755174 16.84613238
-53.0488418 46.33548011 44.06470585
-16.7202264 32.22146143 37.83650438
-37.6814093 68.19924542 20.38913199
-37.51916595 60.79570359 30.55757807
30.13392127 42.92145005 50.35388479
-56.47339357 75.66099637 36.40432313
-49.73784335 81.04504488 25.05761506
21.80359181 43.97201996 57.22667011
-70.76481589 31.24180707 42.03575261
-37.99482605 71.09994328 45.04189758
-51.63852517 53.86346802 43.80498574
13.98568295 43.9760137 58.67123026
-17.7466787 74.53374736 18.79826903
-8.662631821 76.29451376 -3.374378171
-59.65827028 13.73485061 27.56547985
-4.499105408 51.35342025 28.9938342
-74.79754758 55.59844535 39.73394829
41.33780283 68.79616215 15.87017106
-53.61910638 33.06331219 40.9271656
-55.80441387 86.02354037 20.86030475
-50.17146583 55.87562309 43.47320765
-37.60273429 62.26351444 25.18737632
-50.89556019 30.12565257 39.55042027
11.69388614 70.73364692 57.29812227
13.54201209 70.79249087 56.26256982
-55.19751444 54.4265338 43.77386864
-70.26302642 30.73424648 42.00555123
47.67251162 66.839569 37.3756688
56.03072517 67.90954448 52.49385796
48.00799459 12.72261126 42.09025622
-46.65787107 72.97403271 30.76483304
15.89536984 70.91858737 54.34994989
1.120035509 58.40789048 56.73497287
57.62606215 68.87733688 52.60805897
2.254668938 58.6350853 59.70778946
-27.1324542 52.52589208 31.35252301
45.57761315 51.92064789 53.6456013
-66.76088603 68.38066441 41.30864461
-49.98719529 74.17789957 34.90431338
2.595740736 58.01997652 25.99688507
-56.15490388 35.83193013 44.2304656
-41.97883624 54.77728428 43.00786067
-38.96049035 58.72101105 31.61042901
-75.74329207 28.78240718 36.57584058
61.24197792 51.43986982 56.2395361
-4.495863041 46.83184044 29.04324615
-50.08661615 46.29071697 44.01943483
-37.73153574 65.11878262 27.0257484
39.09898531 69.52612436 48.6096845
-22.00177418 56.53239255 30.4768171
-67.37092565 85.97342098 23.64793091
-59.62576655 38.33462751 44.97244685
-44.86040174 50.36625633 36.49741423
-36.29145239 58.55731735 27.17506177
-8.059797638 45.89075278 28.99446049
-20.68460065 53.57321805 29.61803601
-60.03626043 66.69057264 42.16611441
-59.73463755 10.26359119 21.26382807
-12.38735119 81.02178237 6.551415574
-63.97801759 19.72954088 34.54487465
-21.00017433 51.32043759 26.6969325
-49.69229476 38.88067689 42.56577504
-49.48857859 26.78574522 31.44105398
16.09238047 45.38629381 59.37783242
-10.58154805 50.94609422 29.05568939
30.12049142 71.10187772 49.50936493
33.12350451 71.06131758 49.40752395
-37.77876379 67.6536865 26.74094524
33.64520334 71.05831885 49.34284432
-11.88879534 81.76806354 3.619601892
34.64883524 71.04760301 49.27584297
30.49074541 70.660669 48.73398705
-14.74775057 82.0970918 23.65178848
-35.92826152 60.12853565 26.34587285
-4.820131391 52.52644986 32.91759532
-68.0358796 23.06199752 37.41963127
-36.99179706 39.4795009 27.36548444
-38.5491951 59.71619181 31.63248975
-53.15449324 53.38263258 43.84933774
39.13949855 71.0123359 48.82885286
-53.5835899 73.69345745 35.38728426
-24.8618407 50.9640794 31.57024225
-18.31025872 80.83890246 21.45723721
39.63194631 71.01170367 48.74227946
-8.59495446 32.34643758 23.18286779
8.007022793 47.45973019 60.15635965
-53.64281845 53.89579017 43.81199733
-9.554911228 70.85708869 24.95754258
-66.52112383 8.704765464 17.34747424
-55.7867812 88.44473336 16.05652971
-50.64580625 53.8458682 43.81997975
-18.50371318 57.85359951 26.12546237
-49.67170547 31.98127465 35.25553045
-52.61911788 21.1804246 33.01566966
-50.02240472 36.41222844 42.15107552
-22.90540752 43.93726441 31.1965628
-69.60505922 86.62389999 22.34939922
28.9297215 32.75750249 22.90802729
-68.26798897 8.340403945 16.08374523
40.12463651 71.01094915 48.65707946
41.1059995 71.01146666 48.46392001
-53.23208512 56.43506924 43.38906774
40.26830371 30.22568484 21.0036739
32.17630611 23.57989329 35.60301743
1.537441905 59.06270064 25.74728814
-55.99433777 90.89852849 5.09058818
-49.68415262 13.27408341 19.35899455
-73.24609058 11.3937314 22.37433293
-34.68579832 54.82651618 29.39223082
-10.95037756 51.46091794 28.97621883
-39.99408652 80.37356795 36.82105918
59.42594401 8.92425376 54.87368833
-62.58068298 66.73519059 42.13339329
-27.41757903 51.51662152 31.46996558
-12.72450007 83.25786173 21.4441594
11.22986932 52.1046498 28.92668529
-61.42918435 54.34892159 45.86326504
-26.48079642 43.50417079 31.07943678
-69.11036484 26.8660154 40.07785374
-48.26929572 68.4492877 31.15891565
28.85242291 69.78223488 41.76400555
-37.09852854 68.80387825 19.08559465
-67.61911423 83.63136303 27.57701698
-55.81353656 44.34607006 44.37990996
14.69649683 46.38869774 59.6606813
37.34857524 42.34787889 49.8097987
61.10130893 55.78299469 58.28796777
-37.89411695 78.92927184 41.53374477
-38.58388092 66.57980814 45.1874934
56.09147162 47.77121263 47.37044919
-51.31337503 22.54873758 34.3622038
-54.53907369 83.89672194 22.00209395
-76.73391038 5.440714828 10.57346761
33.55657899 29.88801586 20.37690704
-73.96853341 9.749296967 18.29533786
-11.52692952 30.07547273 20.97182563
-36.90979015 64.39360845 23.63410526
-24.39107901 89.34768618 34.54689393
-37.0273266 40.45229979 27.73258248
-0.04960796809 59.15323249 54.13219735
-4.279852433 68.17615675 37.59385116
-40.10767868 47.87927934 29.50183882
63.31818263 44.86151214 62.29027856
-55.87053947 24.30430258 38.15796687
-12.53856178 73.78301844 20.69341855
-65.21979298 76.3630183 35.74937193
-63.75426281 85.89586724 23.85851206
-62.32972346 73.08119003 38.33623098
-72.27936073 74.34727751 37.18930067
-34.0670712 52.72854153 30.32249322
-65.61904028 74.20866088 37.52830064
53.85122376 69.54928578 51.35411078
17.57801126 49.06731584 33.84244213
-19.94273651 25.85632544 36.5958686
-39.62659096 63.7818454 31.25400858
-51.58676419 47.30854934 44.14306293
-57.81244063 49.23677788 46.25261657
3.332713144 37.81401402 33.18290566
-59.55116757 9.414387934 19.43372836
6.869143761 54.92273901 61.13692051
18.74374027 73.40517079 54.07387783
-35.9639021 64.96379269 22.66124459
-53.08879048 69.94073716 38.07075309
-61.90449997 86.4561015 22.82878373
-13.69391596 53.03829257 28.66803481
43.49420106 50.46145488 53.50237523
-42.54798116 54.86616139 42.08464948
-65.00479015 32.56842686 43.01683334
-49.56036114 78.79798438 27.80272328
-41.65103993 26.84618297 29.24478242
-58.28535567 74.07726182 37.6550992
-69.8219189 54.61810303 44.33895504
-14.33985745 69.24828179 15.41305927
-59.82777151 17.35491399 32.18659489
-78.92952919 4.423109158 16.97865471
-6.548419412 45.36947147 28.93100456
-47.72097619 87.99264962 8.113167187
-6.510113519 47.35901251 29.13421858
36.18413738 45.75700116 51.24277111
-26.14429706 88.73875078 36.12356899
-68.69218357 83.6523281 27.53827989
-16.28299014 43.06111413 28.4513377
-57.25952412 74.05534757 37.71386355
-46.1374953 30.19661677 31.99258235
-60.1239801 21.52872084 36.23025633
-54.04024375 18.91543918 30.44708579
-69.86841644 51.62826214 44.09623205
59.70895193 43.3667241 62.85124846
-77.0416544 7.295094402 12.41172866
-73.20844011 9.663209109 19.14975433
59.37070092 52.39713625 57.13703441
-74.80870476 56.0567338 40.24257017
-47.12397851 74.14591376 34.72959528
-14.98007125 81.61295639 23.4896496
-38.00096812 67.41222654 23.75972296
12.21841828 44.50248531 58.72323244
-49.17512795 26.47230185 29.19331522
24.76164505 37.39776638 22.27510258
-36.66378213 73.16832116 44.08338295
-38.58862841 71.20605965 26.4433373
-56.98442873 66.63777533 42.19702153
-56.80685089 82.85357127 28.7175871
-12.49113188 74.75113598 21.09904024
-46.62282949 13.28324392 1.188914474
-41.12400487 39.9419348 34.43853395
30.02725102 34.21845786 23.21880841
2.091410122 64.48959454 55.86855722
-39.68129238 50.60315526 32.75963072
-59.09777417 76.24816446 35.91517341
-37.09471309 60.30237689 30.37688776
44.11083885 42.79060219 55.03402182
-65.40714904 64.17561307 43.25265696
-9.207400643 76.82375489 -3.587663892
-55.71585511 49.77161612 45.47063328
-63.96063865 43.84046131 45.97644602
-27.9217327 51.52315868 31.49022259
-49.64967022 35.9449906 41.67738973
-42.68268199 25.91615194 28.58471984
55.52875429 44.55953488 61.45470075
9.228438616 71.29019837 57.13408328
-37.66106699 64.93456817 29.15080562
-4.634571493 42.88408245 28.28502731
-40.85207493 56.79911457 30.97537874
-74.01519446 46.71567776 43.64950322
-63.1100224 76.32704479 35.76463275
5.461888125 35.97665874 36.62629807
-62.54433589 31.56173389 42.58075477
26.42697672 25.76339 34.65838907
-72.47572913 69.02772783 40.71355586
-7.47125812 30.01185475 20.93690862
32.89524697 22.03269418 35.94700488
7.399317461 69.7398974 58.00001018
51.9101552 44.43318357 57.78492604
15.41541959 57.29501012 26.14139362
-53.12337819 10.65766899 15.42827758
-44.84079402 39.83460673 36.39329087
-23.13183361 74.6848026 6.418407188
16.40347563 72.85697606 55.05738415
16.1499302 70.93884385 54.06625164
-64.29438263 9.074836444 18.45340838
-49.48876222 52.30640264 38.16399983
-48.34138083 13.2333708 2.095616209
17.52144749 71.44299045 53.77883419
-49.58024546 33.91922473 36.04751328
-49.47917159 64.30519363 38.71082969
-63.11169925 38.88561843 45.06757133
-55.58194244 16.68649776 27.48307072
-22.97540724 20.66002622 33.40360042
54.8538784 5.651857388 47.12084561
-71.65076395 12.59118864 25.64906021
-7.189261519 67.84645815 30.32279198
-49.55313401 76.28325859 27.86091273
-43.63561777 34.94784198 34.62617897
32.6990894 72.54666836 49.72534938
38.82168211 45.76451999 50.65249128
37.35411696 44.32916966 50.11503621
4.437206636 34.49134478 36.58439678
24.71434592 71.60561039 50.51929395
-22.92883487 54.49561865 30.99140759
-73.0184728 45.70401568 43.55025239
-43.9269611 25.04382802 27.29530211
-68.72901592 59.58451546 44.74320081
-55.86405973 72.45182769 38.58264667
31.69412315 71.55362525 49.79168982
0.3940712519 29.48172415 19.76444496
-15.49419839 55.18717918 27.37207874
55.74506598 8.61204779 47.54668564
-77.42738225 7.669775204 13.96236222
17.02527382 44.40682933 58.91698067
-49.78206234 83.91866189 15.01356637
-7.550259729 48.38019078 29.13127983
11.11768517 45.41794789 59.9590731
-70.04787853 27.35798347 40.37215982
-48.02266047 27.39093931 29.96260055
40.55811373 71.54228011 48.23297608
57.68604402 6.293077148 50.79027006
8.564681705 66.63430101 58.86995242
58.58849924 7.914380599 49.27681517
4.861153619 58.91270958 61.81449324
-4.906289559 44.35435124 28.74881523
-69.04517002 25.91541891 39.44735665
-9.143788841 43.43092501 28.62345556
-74.83815173 55.92292093 41.80158363
56.0129206 5.488412684 48.79719499
-71.48009345 12.15154657 24.89355728
-68.63443624 78.60769667 33.64926331
-68.75001137 13.87347134 27.69011884
64.12763288 45.37663193 61.98266602
4.600674252 66.14946392 59.42761442
58.04882707 65.87378153 52.43505236
-57.79619546 17.32121822 32.19032624
34.22384392 19.52041755 35.72476959
-6.860941111 77.93298533 -5.258622759
26.75851306 31.35498647 22.12359648
-69.25759369 13.88173805 27.69095182
-69.14520477 78.61757064 33.63204568
-55.76958761 67.17140008 41.59727921
-72.01689405 74.33876787 37.23803286
6.24461759 50.39571919 61.36920334
-7.706859882 82.11726204 -7.058311925
-7.344447488 68.87961663 30.01201093
-74.80647729 34.65854394 38.10239128
4.185807269 59.66608855 24.06430242
14.95573687 72.83155007 55.62864013
-49.59319463 30.63990427 39.15888306
19.22945867 29.26727969 36.14177671
-41.59011719 29.20264191 31.01087373
12.20118162 53.61688536 28.66623878
-45.29822364 28.76216288 31.00479306
-38.62001595 71.06842465 45.52701645
-11.43247121 81.19098505 10.23172747
-58.84558152 77.8753748 34.45715599
-49.55784285 55.75765049 38.89905646
-19.50778547 31.35951747 36.72090122
6.654527346 60.1866519 23.37706421
-47.08590345 61.57130652 35.03152985
-58.84224976 45.74518641 46.19546309
-67.45953264 35.15322021 42.6120042
63.33319407 52.35574334 56.86188226
-11.19797135 35.85033155 35.4432904
37.24575638 17.37473721 36.75053269
-57.01461608 38.78334315 45.09210713
-12.27648354 71.86495206 19.60318925
-73.52411257 53.83348138 42.50016423
29.98846879 42.54216191 48.95803593
53.92645352 58.23958358 54.44494202
-44.60768325 30.16825272 32.03010867
-13.06464731 45.03229732 28.26121088
0.6984596895 67.60422434 49.11050874
59.01986767 39.02093714 60.99195927
-51.98647231 81.39311278 27.27245716
-69.743157 81.9696651 29.79236462
-61.19919454 61.51170823 44.241522
-61.23590803 29.13396494 41.38133889
35.8066207 21.97641604 36.04510613
-20.87776888 48.92771508 31.14349536
23.02918718 49.15551331 43.43229293
-69.54666866 27.35293128 40.3352256
-36.51869442 52.38518774 28.94929275
-62.02438605 85.26436635 25.0328967
43.04694093 31.56956752 22.35292308
-49.6173598 76.61299096 24.04538647
-11.75152143 51.0407195 28.18033089
-31.45306147 49.56119126 31.63305432
22.3148034 45.46857057 57.23604999
21.10255633 72.95196431 53.05854799
-25.71766805 75.72330566 0.6824798963
-20.06906326 41.98430367 30.02048473
0.1761870572 29.44296671 20.25592149
-67.8151157 61.62792804 44.15405343
-65.45453447 45.85957185 46.12859576
25.55332735 26.78730295 34.5919522
6.906715381 52.84536654 61.93884763
-49.46114087 63.66333396 34.50528415
-50.33452005 67.25440539 39.59719271
1.226207664 46.24307576 28.96018215
-55.51065686 36.00250271 42.12747256
64.36922417 48.41006775 55.85545974
19.13043924 71.52335205 52.53909361
-10.15363666 80.01703689 -5.517313891
-61.41503282 74.67154358 37.17973183
-53.92555341 12.56989597 22.51593654
-62.46891148 59.47433915 44.8283683
55.46093182 7.025727901 48.5361082
-73.42896088 48.19916207 43.79625917
-59.33617362 50.26650746 46.24263087
-56.17769375 89.72069123 12.97211018
5.594398451 50.45115989 60.84960789
-55.62686431 79.76537663 29.38272851
-49.47048546 48.73211521 38.86696522
-54.1135951 74.77941186 34.53508972
-57.32725787 87.83905391 5.901552606
-56.86216766 65.06945378 42.90000417
50.89999776 66.33315554 48.46562754
-37.98296547 52.89509519 29.13580112
-36.97607483 53.92433434 28.64890354
-50.96923304 16.58966561 27.72749767
-39.90122774 79.82451017 37.35057314
40.90208078 43.52388702 52.96008641
-41.21445618 80.59272659 34.50963723
-51.73089508 74.7420398 34.51453673
33.19215141 72.04351026 49.645705
-49.5968403 62.58534605 41.21859332
24.87224919 32.82202891 22.93274178
0.001852673969 58.1723973 53.85535239
2.820081606 36.31817969 33.16548148
-53.54279543 68.89276735 38.66965124
51.09868378 69.34533776 48.41997268
-65.75497855 76.91220309 35.30273795
-40.46608906 59.61770368 33.14137518
-56.22039703 13.24526962 26.7666292
18.23767478 72.89806741 54.23057291
-61.10474959 66.70922727 42.15332508
-7.761404937 67.98640353 28.80731477
37.99247956 72.60203012 48.07318814
-49.91393382 13.71117759 2.675422483
-44.23066745 42.77551973 37.09651712
-43.61380033 23.6269874 26.20337756
2.64630101 67.16676641 53.8173754
32.64849801 71.06554065 49.44908613
-36.50009954 60.65904266 26.1230541
-49.76423319 32.53682437 40.47723201
-46.71945435 74.69184982 34.14146674
3.44645374 59.06605341 25.34430666
-55.8314032 89.35924527 5.44871595
-77.18091356 9.237928311 13.19101598
-37.6567548 73.58915358 45.21406001
-12.32742738 40.44926348 17.23076861
-10.96485855 81.66219992 16.32562762
-41.01485466 61.04282161 45.83542566
-56.74609714 86.44234923 10.35140791
-68.92618484 82.5181573 29.09590615
-37.51135817 58.21874026 31.3381557
-17.93385823 74.07639663 18.31658538
55.72966568 47.81744413 46.90275874
-9.539742918 59.77590678 25.40699723
-36.32612866 63.84474827 24.06772621
-49.62274041 83.28652167 16.49488715
-65.92299003 34.04269537 43.55747749
28.11860105 37.33971904 22.30876687
-7.658138444 31.86721038 22.74078951
-53.4346554 67.83378506 39.28918594
18.19810365 71.92598558 53.86947281
41.22833928 67.94605941 14.10637741
-5.851592811 79.92825001 -5.307036488
3.98665053 45.25850127 28.21012649
-70.56500244 17.06564643 31.76563728
-41.28748355 64.6577632 33.05573979
7.835961149 49.35666193 61.4745778
-68.87551175 76.97487839 35.17026169
-45.70074591 54.33710922 37.17424177
-76.01974695 6.14017937 8.144017978
-72.60736519 40.7222545 43.0373622
-58.05711516 38.31144916 44.94236684
51.24828863 63.41006471 41.54609072
40.30259164 16.66853275 38.539072
31.23760793 72.54056368 50.07493027
-40.61930234 60.0857064 33.56431773
18.73396594 71.94062008 53.59739268
26.65646539 48.41599161 51.3254581
19.35263937 34.88964569 23.28952355
28.76143753 72.04853643 50.43232136
-37.51535765 69.36769278 18.44655674
20.84987108 35.37993252 23.13902608
31.22904447 72.04266742 50.02986569
31.72253122 72.04151108 49.94917835
-29.95400028 85.71455192 36.99736456
-60.86209962 48.78626316 46.23746758
11.18755976 71.24349611 57.30262115
-37.53048476 42.19464248 30.9087163
36.62931154 72.04411609 48.98318488
62.60652968 53.75817138 58.19997619
37.67135351 72.05119143 48.70232556
56.38074245 64.96746386 51.62108108
-60.44066425 42.29264417 45.79343407
-63.95529724 86.49949324 22.71616008
-0.919761185 50.27787057 29.14371567
-49.95570374 31.97586785 35.37245671
-13.98077711 34.11210599 38.6726569
-59.75345547 13.29303881 26.88586505
27.99790136 29.96647594 20.52615834
-11.4421392 30.53491705 21.44873157
37.58248745 69.56016112 48.50376617
-51.24025597 23.01406937 34.77298955
51.32434104 66.19343302 50.00670399
51.22039679 8.912247405 44.92463055
59.12794073 43.38725348 62.72373069
-78.11665698 9.605114109 14.93354832
-71.20641961 10.85373619 22.42737302
27.971729 48.14896458 54.17447446
6.229022585 38.76976697 33.18876601
-37.06350506 56.49190677 27.99252672
-61.35262253 63.58557874 43.50234262
-69.36246177 84.24488128 26.61395689
-49.67259507 83.36788504 21.38651097
-65.70125515 25.38651188 39.12294994
-74.03678371 51.19695677 44.07143125
-54.74255089 27.31848586 37.91185865
-13.344053 32.02317184 33.66789661
-49.33830257 32.61264288 33.68920588
24.20051633 26.34805371 34.12242618
-16.64886117 24.39778404 35.41959692
-9.465194206 68.39986306 24.33249696
-72.18654208 36.74002448 42.57261388
-70.79869921 57.12635396 44.54083005
42.84491943 66.97526428 19.24114414
-70.55720764 41.68361751 43.13946322
-49.81475989 11.60938934 9.575679774
-72.54537176 75.43634068 36.25067212
-54.26467491 65.74805962 40.35353539
-60.9754256 75.19978231 36.79035625
14.13037262 44.88268051 59.77166897
-61.83542386 15.11324009 29.45924802
-64.60108279 83.5708381 27.70407787
55.87495032 53.45529575 57.17308908
-5.618531945 42.88487521 28.46347158
-61.30976896 77.37730286 34.88243309
-15.43008528 75.26159782 21.56034369
-57.89023275 16.86643845 31.66103427
-60.15455568 36.87172158 44.57584702
-65.56399591 75.28443772 36.68268234
-55.86459309 8.426755121 12.71591522
-38.48135137 57.67310456 32.0306359
-1.151175726 66.88859974 46.11745574
-64.10421839 31.10305068 42.3763222
-0.1286609415 60.12320852 54.54036535
-63.01094979 75.23546968 36.76425784
-14.88187611 60.51990542 23.61553547
-58.63110402 79.52161021 32.78547422
-48.73486346 17.45810455 28.87319894
-11.66172698 60.85964009 24.88418679
-11.86795145 81.31247661 20.55769701
-36.13176024 75.67119518 44.05993941
-57.9484507 66.65328816 42.20078205
-17.76473156 74.04063794 18.699445
60.81863111 40.36355109 62.5428046
-55.33051683 19.82740205 31.75970007
40.16135764 68.96838564 14.09523
-56.39596371 75.11972157 36.84642895
-13.40494864 80.65641521 22.64021053
44.15150761 52.46085881 53.47304647
-51.40212829 61.02302267 42.21954013
-58.33209546 46.23783831 46.20575168
64.97519383 48.27783688 57.27493034
-55.6852149 56.98540713 43.29481139
-64.70311727 67.81915102 41.60803222
2.439418903 46.78991162 28.20738652
-11.62729026 45.45367595 28.9220686
-62.92213974 50.83079254 46.20250395
15.33399821 70.87965404 54.90897337
27.42197027 23.82250116 33.69332828
-49.78784745 32.92040418 36.02886027
-55.68790846 75.30766237 34.5296442
-37.24198648 75.63657015 44.67361899
-24.26151857 88.86683707 34.27759245
55.34763499 45.66771983 60.27790322
-49.65809146 76.73930803 28.41341508
8.322925677 69.69521522 58.34257606
-49.67715616 40.84473768 43.06920058
-7.023026166 60.33513223 35.91507146
-8.372982531 61.82897351 36.3108391
-52.77051106 40.37909818 43.23796418
-57.17161656 82.28757941 29.53095593
38.65731094 43.71468215 51.17328799
-12.8987193 75.22356614 21.51909653
-50.43015939 79.10712688 30.20648849
-48.76998914 47.95087379 41.97595654
-53.33605901 75.31063044 34.04667672
-52.23712671 74.74962517 34.52302161
7.074785526 58.28790451 22.03240006
-14.33170513 76.70973517 22.01976358
8.911348191 68.13952133 58.81006771
-49.70812382 21.92388826 29.65649724
-44.93399274 12.31889638 0.4084112736
54.53335693 70.60606766 50.6094102
-50.79139402 75.2658902 34.08077126
38.48050099 31.64305946 22.37062246
-40.99355997 70.02721348 28.93359755
-49.26598299 21.99818861 28.70964541
-49.35858854 75.28646022 33.56873482
-7.93362713 78.93341123 -5.014447243
-37.96664384 57.15902758 32.07361799
-34.496159 82.84349178 42.05973931
33.64648674 73.06653555 49.33631804
-7.832263725 78.41723015 -4.868211963
-18.36671297 58.93759018 25.16886239
13.88444647 35.91543365 35.73081105
45.19108135 69.42636478 48.60596716
-65.01870596 70.991643 39.79945974
-8.239248567 68.53144057 28.39789555
-22.19831414 58.0886277 29.92830766
-39.69060189 78.73860769 38.26307236
23.29468577 69.40756022 41.34675529
-67.87210345 46.11275607 43.65049301
-46.48492818 75.37309552 32.01498448
34.21680827 23.0394788 35.66076637
14.63728797 71.81431167 55.84493425
-15.3948199 75.74602455 21.75668029
-46.59672579 10.52933639 -1.806541117
-14.00711464 61.98173374 23.95866588
-10.0703424 46.41605553 29.10642657
-16.8446578 45.60774382 28.12851947
39.30442177 46.32055667 49.93219121
-52.22930472 15.7253081 26.34861167
-60.22995924 82.34366113 29.46316277
-7.865868989 77.89479921 -4.623684479
57.95324892 54.87990144 57.71880532
-39.267197 40.54817097 32.87343827
-14.73318623 75.73438161 21.76566259
-59.60390027 72.49240588 38.82479553
-69.19354973 31.21730972 42.02048588
-79.35433644 5.772379549 18.87627557
15.9051246 72.34598118 55.2577833
-36.04651742 43.86949742 28.66269868
-55.82573443 89.55460855 3.179715056
-58.44232216 85.1918768 25.19128717
-51.23583285 39.85845233 43.16268792
50.13148973 65.64205677 44.9816832
30.92478706 24.11875814 35.41279407
-39.35784231 46.14970924 37.78367213
-38.00687318 80.01536058 40.60041886
-13.7372707 32.11575655 26.84145267
16.39529313 72.35888762 55.01447779
-52.65247925 17.95429766 29.68678109
6.185065984 55.90058002 61.56916803
-54.65858102 45.36256387 44.01273282
-74.53430958 35.95356594 40.49684984
-37.38745125 68.50362129 22.62629649
10.44060347 69.69241977 57.97117369
50.62621941 52.81897537 53.9076426
-49.48808473 35.00105641 35.12452758
-49.49905062 67.37807327 38.00223638
18.71778198 72.41383317 53.93371706
-22.82918584 47.41432726 31.60423001
-49.56719375 79.17975778 29.19876317
8.807654408 50.86870532 61.21628952
-37.40685713 46.86283564 29.13311445
-44.437875 49.99779051 34.86761475
-74.33064935 36.32826957 41.93481852
-66.29306072 73.1477866 38.31896162
-2.05516542 67.09683052 43.87255673
-18.60905533 82.91176928 20.7574321
3.619506914 57.80661128 22.45190465
52.108126 63.29843972 42.67793902
60.51182727 69.81363097 52.84182297
-60.00123705 12.41359791 25.48902828
53.61793307 57.66999718 55.28931254
-52.75356459 65.72912948 40.28511245
-51.26946695 31.57313594 40.29820592
-69.75858934 21.68101897 36.29964628
-70.69533847 35.22448802 42.40176297
26.35655981 72.5249783 51.18670208
32.69568623 72.04616903 49.70951894
29.28818096 72.5328201 50.53659088
-34.06543246 81.79522864 42.49331894
-51.21228179 41.34083136 43.42958165
29.77484504 72.5351054 50.41725168
-63.92110347 33.52176017 43.39646082
37.40689225 20.90807729 36.488699
-67.66661893 66.822959 42.08443205
-64.49939027 80.74829264 31.31764821
4.512739318 50.69945869 58.17345269
23.02044393 69.42200974 41.2313143
57.26380191 41.43912332 62.38798624
-40.76439783 55.59032752 44.99114282
-56.42933573 56.30105432 45.554546
33.69250462 72.54110623 49.60046834
-49.69787195 79.51157466 19.54506933
37.05118675 72.58129957 48.49335371
-58.32319076 82.87513942 28.75638349
19.85811496 45.97114788 57.69691201
-49.69821792 78.57019072 30.47340888
-78.87755139 7.85045697 17.96807391
34.60010883 73.0810467 48.98600051
-69.49311715 27.82813373 40.63515703
-34.58599293 82.31225809 42.41717866
12.86423767 49.54962993 29.14233233
-66.91443899 77.47896339 34.77112768
-66.85680883 14.74056389 28.91651261
-20.96288679 49.31754199 26.63433188
-55.81671514 90.30671235 6.100307379
51.27760168 43.30183598 59.38578479
12.28125791 50.06097943 29.14403274
52.23573555 50.76631907 54.12286712
-52.72430286 86.55114633 14.14807305
-65.3259271 87.14799816 21.27586965
-7.753175394 81.60124267 -6.885790699
-53.1988604 70.4736991 37.73137382
18.60492591 29.76242344 36.33952972
-59.85193643 86.41181878 22.95141369
-49.83424172 13.32408769 7.153847604
-39.17051571 59.11948963 44.50457661
-55.88193518 8.230621404 9.16944432
59.81770875 53.82696245 57.93324419
-37.71629246 55.82342629 30.05058597
34.52062107 48.01700127 54.45748792
-70.47135442 42.17969245 43.19093149
-6.539330509 51.89369498 28.93765488
4.504672043 53.41949005 61.55872791
3.210629244 58.53412977 25.73759682
-50.97522119 81.37855349 27.24862033
10.66938532 72.33587153 56.3736306
12.62829485 30.33093165 36.70629196
-15.85181966 80.14960816 23.1635888
-12.7656634 52.0611691 28.18089195
-73.86995088 29.92634501 40.41884127
-68.48863986 9.965016279 20.57270334
-71.26923053 75.93904316 35.99820794
-55.93891948 44.2110955 45.97070477
-67.17615568 75.85655735 36.17518946
-67.88060365 44.62278478 43.46886556
12.06049117 41.63467077 27.95197947
-20.79061749 49.4850798 30.48321943
-67.40028627 52.57700552 44.26512104
-59.493724 12.40536614 25.48780289
8.619031255 55.22084445 28.20937122
-19.75905828 56.68952347 28.22502701
1.285884963 59.83087731 57.66419751
37.45916878 48.02134903 53.84661719
34.17104631 71.54305675 49.44201257
40.5663322 41.65961036 51.35943584
53.76130804 52.57551274 56.13605465
-40.6954478 79.45037478 36.01863115
46.57385406 52.90570349 53.67364967
-60.35204007 49.78117888 46.24377018
-54.94704125 75.88215609 33.54585472
-35.99133023 52.37933117 28.91670855
-8.555914504 47.90209995 29.04645068
-0.8654020297 67.39532696 46.00713841
-43.54493718 55.02217677 40.46363432
53.50944493 44.64898747 60.80136411
-49.61421362 64.06932115 35.64812148
20.79625761 29.39370292 34.37536536
58.51993186 65.38169954 52.23101571
-61.46811882 55.8752098 45.63234523
-27.40644953 46.48884709 31.56750905
57.82645426 9.235544637 51.5650284
-51.90016204 75.83185702 33.54870491
62.98019263 48.71430633 52.5885324
35.13405793 32.17324182 22.6807064
-55.83590448 69.26327283 40.63250414
-49.63219337 77.87934315 26.82739799
-48.63784789 76.7383315 22.40354294
-28.54769522 55.10665337 30.79626183
13.55708652 71.28710273 56.34452044
-14.2269312 60.0837541 22.72715539
-8.742439359 31.41640294 22.354233
-9.455508995 62.05293197 33.91740975
57.1680559 38.20729896 59.13713457
5.897482116 53.36112397 61.97066681
-39.97573988 64.78895745 31.28258356
-34.49061683 55.40371966 28.48104995
55.47048872 8.074894769 48.00807264
-11.0051925 35.79598279 30.21080462
-7.321118128 82.13328991 -7.317938381
-55.97472529 82.85503666 28.54188449
57.41776305 8.536384546 48.10604652
-1.480379484 45.79294657 28.8751318
-49.71012071 84.73813651 17.14006931
-70.5033831 27.36475789 40.38038481
-15.37790993 76.23971049 21.84902964
-58.70059742 82.31552418 29.49813846
-72.16663676 75.41873626 36.3828101
-12.7396463 60.90782954 24.53033412
-45.93233799 27.82982547 30.29569368
-14.37095328 76.22731751 21.80086371
-9.322077116 78.38825046 -4.247678219
-43.75110813 48.11327238 33.30694216
28.60374421 46.0071201 55.61147588
-52.8757859 85.27623855 17.32374043
11.97130065 72.85035035 55.97954276
-13.05084978 81.58133675 23.48879945
5.35657271 55.35511269 27.27285777
-47.20176175 53.34651524 37.30675058
-40.7908635 40.44954569 34.30892511
-44.18684353 50.62115456 33.40991743
-14.08281738 62.44780376 24.3892629
-13.89152011 31.03380814 21.95099228
-13.89850545 62.47871857 23.99523181
6.569125047 37.74244048 33.39658043
-65.80287821 85.93815835 23.75824916
-1.030414444 38.75794216 28.88381366
4.231727816 62.4979513 61.10091225
30.34826791 71.56352754 49.9333995
-33.61969005 53.68074124 30.83662772
-43.12329001 43.30140891 36.60706891
-66.08312829 19.29811451 34.12800312
20.29831017 72.93643541 53.39219349
28.86107659 69.70353605 42.67594887
-68.10184367 12.53541809 25.61968503
15.02002219 72.32168376 55.7086399
-72.22892104 24.55289149 38.39180688
-37.44693945 42.77860411 29.94037171
22.53134441 72.97456087 52.52375208
-38.61053571 70.5614264 45.58417328
-49.46739776 84.90637246 9.314132803
59.37438327 70.39406312 52.14730108
-69.27360625 79.17216869 33.04499452
-50.06649051 50.29829298 44.10585686
8.047352753 58.11941398 23.80288698
-17.02675486 36.3442594 36.64764044
18.08082254 74.99844311 53.18435494
51.50241709 9.396090144 45.08068888
8.903114731 68.64589065 58.75997416
2.96792787 45.77904553 28.18818623
23.49036923 72.98634667 52.20404212
-1.056117266 51.61884155 42.73595638
-37.17040711 69.10123315 27.3006727
-72.33352811 78.13789247 33.98189157
-55.76688371 84.72762018 24.24385368
-49.69119186 68.42321457 31.73275582
-33.18818293 83.35491331 41.69998688
31.71939794 73.04752369 49.92452915
-14.55256379 55.13340375 27.81676793
32.73351355 73.05629729 49.6292808
30.92969291 22.13219718 35.16674425
-60.22702942 34.43436696 43.75105494
-24.86440749 46.94764599 31.58329481
1.80383249 51.37216202 50.88082504
-49.56776168 75.89419172 32.38027529
-8.968258808 62.45802623 34.94849953
30.50493751 23.12789252 35.34227135
-38.74374868 64.09981614 44.87433377
10.07420339 58.91908897 25.78642559
-49.69672918 30.48724955 35.12411359
-49.45779773 63.84175146 38.26008751
-46.5563404 18.17461083 25.9666541
-56.86221708 61.95707114 44.07097756
-44.75866879 49.31050602 37.08065149
-55.57306641 10.59255444 16.65134524
-30.83878831 48.04201295 31.67171651
-31.75918763 84.92122019 40.72455797
-49.47544235 64.14798638 34.7084534
-35.97883162 63.84300243 24.02176152
-8.519990707 33.31305485 23.60065487
-55.78280216 80.77413655 29.35532732
4.091622694 52.97696586 60.94800164
-55.78268724 81.75373277 29.63671935
31.37105813 21.6303392 35.0818661
-67.40783384 52.0785968 44.22581173
-71.33275775 86.65297465 22.34136176
-49.66255725 82.75483517 16.84804447
14.55509718 71.31638926 55.81421437
0.9452198104 47.81713202 28.22089691
-55.77132987 26.74055948 38.99040353
-78.38157838 12.5538246 15.71313254
-48.03106513 85.37782356 9.393908035
-60.67283601 18.29007401 33.14501577
-51.86964524 11.76727257 19.78801841
3.89765887 53.47791374 60.99626397
-55.64641915 61.03312087 42.91172466
34.1497726 71.05157205 49.32494173
-39.59039383 42.70194984 37.06564207
-59.93560235 65.12075959 42.8905313
-30.07560233 45.06495815 31.12615178
-29.45808119 52.05703601 31.41221664
-36.97104915 51.37355228 29.1260805
42.38262675 30.65246413 21.47273525
-46.44543968 73.49297893 30.52665546
-72.61589402 40.22434077 42.99249381
-62.93943824 80.71711368 31.38210417
16.13742935 36.20939367 26.06200884
-52.47695573 9.313837172 7.59861666
-67.91126866 45.61790765 43.57588276
-45.2236086 42.79323164 37.08026414
-74.42957388 52.70352143 44.1369594
-14.39115841 58.75906035 20.65656322
-55.45792443 82.68679367 24.56977328
-58.46590437 22.4400874 36.98752515
-25.8828714 46.46362768 31.56971895
-55.99759549 89.75373158 12.5542078
-61.64913221 30.0990656 41.90983227
-61.6304043 59.97480143 44.68536952
-38.41473782 67.50371854 28.60316703
41.49785576 50.97959485 53.69478427
-43.9750995 49.15636127 32.89400336
-38.41746216 69.03651874 28.28974587
-70.32262975 76.45931217 35.60466395
-63.0924905 66.74558867 42.11028959
-21.01320977 25.90075879 36.28419943
-61.54439617 39.84277442 45.31059381
-49.47913162 40.29791568 37.72606226
-49.55909888 61.18354894 40.0045569
-55.86039289 10.69459689 21.34819864
14.77491987 52.55304732 28.87193806
11.76022173 51.08046487 29.06174823
-47.04425564 85.87780965 9.228154958
-62.90618925 46.31519117 46.18009035
-66.94182277 74.23170219 37.51308287
-40.02422006 67.04047658 45.94104029
-58.07616783 76.22838158 35.95000108
-78.66894842 8.93549507 16.98565955
1.415806353 51.92144715 50.40496208
-9.281080999 77.86535432 -4.011977256
-49.86424291 12.83700548 6.987333768
-47.89707483 69.51373488 30.38433294
-55.68856824 76.36198295 33.94372896
-41.78825302 24.52820801 27.04649006
-58.14973013 24.79167632 38.76147316
-50.10506863 69.89751939 38.00344287
-40.21288995 58.07098308 45.22191125
-26.41635312 52.51155141 31.38243528
-19.34369404 57.22999135 27.6983098
33.77644651 30.78153775 21.61560472
15.26924697 53.05368311 28.79256219
-69.96538681 74.29501799 37.35467865
-49.58266471 76.45348115 31.71711827
28.70210234 71.5762515 50.09962414
-49.43763551 36.46986691 35.54380512
50.01357504 50.89615343 53.03943429
-55.74789954 81.90962941 27.82032284
-64.0776788 81.29794765 30.68306962
45.71409963 52.4159592 53.69627831
1.835138217 66.77639978 52.67708867
-76.26685388 6.494489825 9.904689588
-71.06051422 35.73746863 42.34299176
-48.94642178 77.32187123 21.51487542
-13.83572481 76.70732098 21.95320281
11.24308991 51.59576477 29.00503197
20.82443945 32.88168115 23.01219672
-50.24750075 39.84306541 43.15282965
39.64485521 70.50297587 48.81886032
-69.82045232 24.97995882 38.80142925
-70.22714482 32.72501044 42.19503108
37.11504054 72.04687309 48.85854821
-69.85841853 79.7339983 32.46103438
-52.40708634 75.83979179 33.55326574
-11.50682452 82.75232994 -7.879326745
-55.778962 25.28731707 38.38223477
-12.40066819 76.72675543 21.45391953
-52.4974364 24.913771 36.26618229
-49.54444241 32.69850932 38.55835936
-5.876148295 52.25959764 30.39015804
-9.787418294 78.92698075 -4.58626839
-22.79311901 75.59270673 7.467480472
-70.34422212 87.25120143 21.03482976
-55.80021519 88.69928855 13.10402871
-15.34098218 76.72329865 22.05478394
-41.39081847 79.05115359 34.95905244
-6.13668851 79.01807616 -6.339986758
-39.09140177 67.54292019 45.75705502
-11.57028757 81.92470628 19.22036251
15.24961931 53.56580013 28.67791807
-66.67643338 72.08896484 39.03036718
-5.539264103 79.038823 -6.694763598
-5.350862482 79.1172305 -7.640904629
-52.49396007 12.59107744 21.99703302
-51.8059699 75.28212663 34.08577267
-67.79547385 50.5940452 44.05357271
-44.40432619 54.65613741 39.05013583
-54.09405236 34.52459709 41.53400181
-33.57347217 81.30501523 42.26361334
23.19860205 37.43050456 22.19313556
-70.3138159 52.63437118 44.15480619
19.88020873 43.95113878 57.83587487
-55.1539889 46.36912135 44.07563805
2.579562249 51.17907508 52.9743832
-71.33765422 72.71248416 38.50766336
-63.06116843 57.94433757 45.22276766
-74.4363223 11.97601398 21.66839688
-59.17032732 36.36857766 44.40236975
-17.36208389 60.4330194 25.09716254
20.68366782 73.44248413 53.27076147
8.36149349 58.562276 24.42855389
57.58627105 9.282369295 51.06725789
4.708508251 51.95868579 60.9980143
-55.76869015 82.47188878 27.12381142
-65.14599716 83.5855477 27.63723654
3.931620068 60.60052017 24.91849512
-76.90375146 6.344987746 11.76167932
55.15336416 64.08661447 50.42741008
-71.47806825 28.340526 40.89211664
-24.19345001 75.40042919 4.139988558
-55.05872921 62.6397598 41.62853385
-37.45345607 63.37274838 29.76203441
-71.60378169 78.11666853 34.08910756
43.69891131 46.92847203 42.03700917
5.920058546 36.49553343 36.34208696
-52.37642159 78.58318191 30.83334715
3.391284082 65.83609018 57.46948232
-8.933777817 39.54837827 21.215072
29.21779896 30.8510989 21.67744963
-65.35990701 74.20183275 37.55814836
-37.88005002 76.66553325 44.50362859
27.47293909 26.23353697 34.82774959
-26.0111598 55.56823003 30.78085143
-57.58766163 21.48819092 36.2170661
-19.21207372 59.39546147 25.84141474
-39.79328683 53.12117425 32.68319101
-60.82776951 74.1208665 37.63375782
-16.77301087 54.61149063 28.47232807
-49.36485347 47.97839126 41.76995783
-20.84773659 45.24172733 27.3142762
-57.34410208 26.67909405 40.00387634
-49.68793058 77.27469503 22.20394146
-49.87479645 84.36188643 9.885976551
-71.64405704 38.22079949 42.75916448
49.09704801 45.69210264 55.3602325
-18.56641915 23.89652627 35.77761192
-48.77220528 54.90202355 37.02869053
-70.44806694 63.22385156 43.60945075
-38.00773226 67.49663115 28.60782427
-56.13183405 24.76488128 38.68770458
63.06306321 53.77565577 57.90994096
-77.17906671 8.244953563 13.06455754
29.90753699 41.0696802 42.76054783
52.04664394 39.90633348 57.87010834
-70.40160003 11.69996291 24.10355374
-66.11604782 57.99867226 45.17459474
-47.72376768 70.69757013 34.08838187
-28.70632138 86.58516085 38.30604283
-50.60290917 83.69516529 23.59126074
-36.25306255 63.30567471 24.48511054
-14.01049925 35.63374712 38.49392692
-15.76031328 71.41045012 19.71712591
-71.85641395 87.28306606 20.95330592
-9.999898716 32.8388071 23.5616553
-45.18398626 29.70896893 31.6450736
-18.77550883 74.25318103 16.42485059
-49.53244084 61.49221364 36.41626737
-49.49688465 30.10722447 33.67096935
-57.51582595 38.78839534 45.12904135
-65.96620916 9.931491912 20.48083597
20.18495696 31.45076456 33.91863472
-41.4111543 20.93970217 22.01944185
1.017638207 52.02318801 49.2998128
-51.07863344 11.36159497 18.51992829
-49.12616044 73.66256592 34.89583711
45.59553584 11.37984711 40.65818988
58.60407151 39.48511919 61.50933107
-7.617036858 29.58317548 20.11449035
61.03701319 57.34685401 57.62573153
-15.16429504 72.36200476 20.21040272
-47.55337333 24.13425662 26.89256743
-47.04670541 86.38126144 9.2108272
-24.36339229 51.46263623 31.51421446
53.1706172 69.62005513 50.66236413
-66.8308445 76.93457888 35.24815752
-28.67497617 87.59855895 38.18908215
-55.82097674 76.75754625 35.20363368
-39.12600567 54.17398812 43.64067009
24.12067198 30.51663005 20.7056197
11.08245267 42.12230335 28.30434337
-47.26057542 34.03753683 34.23168478
-65.26441086 79.64660007 32.59951207
-4.241767167 31.37608694 21.96394537
42.97752758 31.10236642 21.96313098
-1.965247852 49.30526761 28.98049937
-44.89234242 25.06002655 27.29136168
-70.77845335 60.12943727 44.63483166
38.66896215 34.57367703 23.27377974
-63.53475961 56.93102571 45.42306613
-16.02113645 25.5992421 27.17912362
15.84312934 46.96911863 58.53070301
-71.43181337 77.02548863 35.07024317
-7.324761854 60.79133322 36.50334115
-8.139508938 62.28774948 36.76714896
-56.42945424 40.74480626 45.51698162
-14.94727406 55.68606063 27.30303791
-67.95392043 20.25513636 35.02824826
-75.64587619 8.338774538 11.68302634
32.04088123 34.6836418 23.26124325
50.88574553 39.30833104 59.2069067
37.66123003 17.81603848 37.37499313
-55.90585956 11.52374135 23.38483746
-51.57989181 16.14771227 27.14782988
-13.49167042 35.11759118 38.56025709
-60.06329527 78.99851432 33.30443968
-56.91524611 85.1648929 25.21331351
26.28685093 37.37172587 22.28653399
-49.69756421 68.77997794 33.41904266
-11.31125681 70.29262772 20.19188061
-62.8536828 63.61182816 43.48388148
60.38720462 48.10110273 48.54826316
-60.84121002 33.95702339 43.58289445
-61.72807699 76.83831995 35.3924731
2.409148749 51.66814322 53.15606313
-49.61439084 65.97916323 36.78387834
-61.17875001 24.36791717 38.43178988
-58.1850566 76.77313661 35.47349358
-50.55894439 10.59056762 15.71818641
5.97092502 35.96765828 36.63370559
-45.95937427 37.89444311 35.82288242
35.53346806 45.50867576 48.42295879
-57.1448055 76.75633748 35.47012805
1.230863912 58.5416901 26.02738417
27.64281126 68.89242327 40.67098636
-53.51649215 81.42158165 27.23375362
-23.85906546 51.45601172 31.49493845
-72.38513549 21.2648474 35.8051268
32.8399242 33.67258551 23.1926585
-55.76465094 79.01055371 32.34490022
-47.08422201 72.11699486 34.96862133
-51.58320418 48.8134016 44.15288339
-49.97422586 76.89304914 32.51560629
-39.22897448 80.23539273 38.2791868
61.43321691 49.52610086 49.28628692
7.600627694 45.69690725 28.25822372
-68.91473996 73.73359201 37.84511568
-9.572713659 75.78060096 -3.061586066
-49.77407106 14.51636684 22.43473301
-49.62139158 77.27256684 28.04259955
-33.19528947 48.59316313 31.54960286
-22.55077281 20.65643503 33.3643092
-13.40147068 32.29991421 30.46621864
-7.023270154 39.3279758 23.40931986
-14.26169387 77.67885068 22.40949372
-49.81324913 75.80741385 33.43447218
7.812491231 64.65798255 58.64938307
-74.28004226 29.9721558 39.96524296
-12.45552535 70.39906428 19.17450604
31.23927946 73.04193652 50.08095059
-37.42864266 78.84313471 42.44491878
-11.67243439 54.01412092 28.60768029
-45.75097541 42.30926243 36.97246298
-27.98485872 44.51158875 31.32470947
-51.14139516 27.73858164 38.17495921
-51.61097574 52.84513891 43.96795758
-50.2269553 85.55727967 19.38270336
-37.81451412 66.8159555 24.81947375
-21.12376932 19.67549976 32.82630175
-17.60618108 58.49773219 24.30337603
-12.62911131 57.86355288 24.88959433
33.94652739 49.06325108 54.07462143
-47.00507559 13.22828592 1.899803731
-49.65159147 79.29000606 22.10837374
-13.22661281 75.71774006 21.67153714
-49.68261887 77.55123093 24.81933931
4.506667068 65.6203354 59.76141139
-64.69892777 87.12904834 21.37628052
47.44189403 68.37675647 48.70806127
-44.27734277 50.06547863 34.05122714
-20.12609793 74.53802767 13.37570784
-40.15041309 55.21107601 31.79675574
-37.62115365 63.9100022 29.3838217
-55.87927874 89.92850844 -1.150577298
40.89563091 44.02935662 52.92009729
-40.1588767 44.80601602 41.88172455
-49.32418202 79.09341057 18.50151728
23.50590304 73.98294283 52.28514599
-49.69946864 76.90884749 26.45316782
-41.82162394 51.09481636 33.28688487
23.98622932 73.98842518 52.12990176
24.93978215 74.00297127 51.77919183
6.015403009 44.22179777 28.20476023
-55.74244122 63.01608886 43.21686063
-14.49784995 74.28246528 21.0957641
57.1574051 4.879651159 49.81924227
-69.18519549 22.14177026 36.66820035
7.36057104 50.35312163 61.65088798
41.78923833 42.46838213 53.39068946
-48.94394067 75.27551773 33.61668738
-52.787526 78.0405652 31.38419725
-46.60156991 47.28971806 37.58432175
-54.22450998 65.22558276 40.58452749
-66.4171763 38.9672534 44.7502673
-62.47546131 8.646196383 17.25585475
31.13112573 30.36244794 21.15854268
59.82149573 41.85286004 62.92390182
-39.36477061 49.56294597 33.12149495
33.66687821 74.20934844 47.71910477
-49.43547729 71.66106674 34.88312523
26.42163794 33.28600559 23.07772503
-78.02898169 7.527245157 15.73168392
0.08610155841 47.25273325 29.11004802
57.04353452 68.38402626 52.61921925
36.33274126 45.42760181 49.21169825
-77.84298066 7.569696837 15.20340225
-44.69599935 43.27707855 37.18943872
-37.6194617 70.13396385 27.05090023
-74.45302229 50.70648566 44.01791619
-45.29564687 40.82070658 36.68594904
27.43129194 67.76503058 36.31899813
-49.57754271 82.56328324 19.05550668
-60.10784249 18.2776246 33.18178996
-62.91038898 45.81509366 46.15974726
-10.83548663 81.42349446 19.07202323
7.05005753 53.2353213 28.25142213
-10.22502375 69.53980562 22.89739731
-64.4562744 70.45349206 40.11280865
-36.03133908 44.36405687 28.74523792
-55.6935107 15.23612928 26.86136056
-39.54577929 69.05577416 45.76131727
2.539411429 59.05282337 25.67086908
-49.58916031 28.04579657 34.31256302
-5.863790115 30.44653389 21.4109232
-37.59082784 68.20976708 26.0763563
-74.32158867 31.80375477 42.01719246
-66.23672389 76.3804427 35.74103158
-47.44371581 32.12105622 33.20795257
16.58812727 44.89417032 59.16957827
-70.47332018 17.52161418 32.28158749
-37.50751962 69.16551612 20.79207655
-49.78524873 70.9636219 37.21963692
22.75929386 45.994346 56.87433895
-71.90120464 51.66256937 44.08563362
-13.32737262 48.75467244 25.88575305
-47.73186296 55.90199938 36.87512352
-41.5199819 40.89833529 35.0647361
-69.54403739 19.81170465 34.65253105
-25.33922013 47.45398729 31.62250602
-56.46141079 26.19000278 39.68662922
-37.48946636 47.87177238 29.0896763
-49.76160009 17.0525929 27.94983799
-57.312835 46.72145481 46.2238402
56.70210115 6.392972454 49.8182561
-13.60595095 48.3929827 24.31099691
-35.39553726 78.70955606 43.60787207
-71.17831029 66.88472762 42.03707748
-20.35211542 42.95807381 30.42351188
-22.18092772 23.00572804 35.15467453
-55.74571216 81.80941459 28.98327262
29.29877521 22.73855694 34.26534419
-8.028082124 59.15537151 26.49569996
-13.98512679 30.0403573 33.50154796
4.281442253 61.46844125 61.38945359
-37.04613035 40.93922334 27.91024543
-50.45114939 18.83940318 30.64530533
24.37631997 27.81557496 34.53276447
45.03715726 51.43089243 53.60746746
-65.49289056 54.41659265 45.85266311
-7.874674266 68.44664582 29.31272856
-37.66852949 64.41133868 29.39961771
-38.46445937 61.75664531 31.23582028
-39.79349161 62.7005488 32.18503599
-64.88064645 77.44414952 34.78741597
-7.001578531 79.49818774 -5.921891636
-38.55798268 60.22284065 31.57925696
-36.80107805 58.5666148 27.16431881
8.839096935 69.68261589 58.39040125
56.47188468 56.99180196 56.79139127
-55.85028277 77.29932328 34.7465201
3.559085541 64.12090692 59.86865829
-55.7389899 77.34229403 34.22645998
-55.72571096 77.3851513 33.72640906
3.979681687 38.28720693 33.39299709
25.27215559 70.4865824 45.92348647
34.50452596 48.52733633 54.36285644
19.59073505 71.96682104 53.12984166
-46.70434912 73.87802091 31.93282386
-48.90453967 28.34467016 30.71245835
-67.71031216 79.13930385 33.128384
-40.96856699 28.78333624 29.93330554
-41.2962715 45.43597907 40.61220245
58.20509481 8.337445082 50.26533246
-49.51065414 35.52131631 40.74255786
-21.31136997 74.78903734 10.68784245
-48.8597905 78.50560361 19.41000641
-55.71679853 76.3018899 34.64671678
64.98527195 45.916999 61.37277452
-59.78225772 28.62941502 41.13466181
3.347694781 61.01602207 60.99289593
-8.107777327 80.02569175 -6.007943598
-70.4130882 46.65785588 43.63379853
-33.83332043 54.71860361 30.48238612
-6.007481715 49.36210714 29.09154289
-22.86816014 52.45614773 31.34893986
-67.63016852 71.57229469 39.38349349
-13.04458544 30.09770474 21.00316633
-61.32408998 34.45504524 43.72022231
-49.49220651 39.30007914 37.65888876
15.93471168 36.56609022 27.78651554
-49.59079717 73.12217524 35.4310547
-8.162559054 61.46838984 34.62998742
-49.51238734 50.06167748 40.92035009
58.08922305 58.5993791 55.30094887
49.35629561 9.0844627 43.28092474
-6.257899673 80.03409637 -6.458293315
-62.65333438 13.78378988 27.5685338
-45.28295726 56.40778006 36.36327214
-27.10310694 75.52351593 -2.560645012
3.453304074 63.07072395 60.42684016
49.7776478 40.77928037 59.82229705
33.95892808 43.35251547 50.44695194
12.5190401 57.21983527 27.56643523
-37.21728366 63.32128799 24.48774289
-68.94092978 46.63379682 43.63234427
45.90107893 67.38626205 31.36707879
-72.44530514 61.20658396 44.10168705
27.9063437 36.29369114 22.83901916
-36.65884428 66.02144772 22.16902455
22.03393698 74.48434303 52.57188018
-22.82140063 48.91704815 31.63798716
-49.36917779 69.50059918 30.81756056
23.71762403 43.9976181 56.56449071
26.32906332 74.54771275 51.01713166
-10.68191918 54.5122377 28.46292086
1.785589212 37.92544446 32.18438544
-47.26073948 55.38513012 36.95883222
27.27288432 74.56716797 50.61128887
-62.39201755 46.80581653 46.21313842
-58.96912295 22.44615286 37.01307963
-55.29193788 57.48746099 43.2182443
29.15914101 74.60677722 49.79175519
35.87999353 44.50288708 48.37952148
27.31410239 70.32376592 47.42416766
-35.799956 37.53842555 26.36504872
1.938971091 67.32534968 52.11131686
-22.38101912 44.42273605 31.28749056
-66.23416631 81.89686989 29.96823904
-39.90063354 44.56997727 38.74594474
33.58000682 43.64942919 52.89906469
-12.6749855 52.51764349 28.69115228
-71.47202831 63.24482241 43.56128132
-17.10733709 57.25978177 21.09935222
-77.78508085 9.109082519 14.80197056
-55.33529709 86.251019 12.30346118
-55.85533579 15.52264224 29.39281134
-66.03673849 37.46691979 44.61476032
-55.80581715 10.41262917 18.78443642
-8.34928659 58.10235635 27.12784236
-53.25195005 15.74561509 26.30789793
-7.172334482 84.24054644 -8.502320658
24.41236239 72.51459672 51.67798924
-1.21403625 50.77986383 29.19897244
42.9989134 50.46351966 53.57286005
10.66810417 43.60257612 28.67919827
-60.94271168 32.5015769 43.01821376
48.82963511 43.54557613 57.02311269
6.892088126 69.22099218 58.29393159
-38.22325754 49.54875151 33.06858117
-63.89737409 63.63036266 43.46775758
-56.95587402 31.46824942 42.60023748
-55.66505532 75.22180075 35.52203048
44.85178908 12.89000362 40.74895067
-49.50636882 35.01693859 40.77028434
23.34278858 35.85112291 23.02033063
-40.3165148 68.47637041 29.32787648
-50.55513778 85.59893904 18.96167723
20.20357722 31.78253172 35.8903297
-47.06813407 73.11331149 35.05286444
12.2322297 46.35571524 60.51351686
-66.11088115 12.50982051 25.53715189
-74.53854387 46.22968201 43.56446153
-68.81955209 53.6035933 44.27168519
-32.92992229 48.58319035 31.614767
27.67562314 38.39416226 21.80578066
14.14416887 55.6367415 28.15429877
2.981069565 36.28652649 33.50223354
-5.944840092 29.98520278 20.95520711
37.02749321 19.34503504 37.22606523
-47.54375373 31.16708739 32.64811499
-13.09951741 39.17862099 32.12855856
46.70361161 69.40671837 48.54558852
-52.69604851 23.50731098 35.15135024
-19.37116093 29.28173438 37.50870522
-49.56180785 56.56585981 41.17081108
-54.6025891 67.32605399 39.57938981
60.08878563 65.81107942 52.77391194
-10.8215399 62.06635276 28.19547996
-72.07075839 78.12922996 34.03234053
-54.85376693 59.02085628 42.81383345
-20.5614799 23.92419116 35.83692778
-72.64781832 38.73379662 42.82198363
4.800536045 55.33826604 27.5744655
-12.50763652 81.18157062 4.719415805
57.94506641 54.381813 57.67589896
-69.0413383 19.80590124 34.62403354
55.14252022 5.605366081 47.60550498
29.78302755 73.03319384 50.46015804
-46.50774054 27.37008745 29.91575987
-5.986061902 33.27373038 23.57392668
-70.32477915 24.98658333 38.82070526
4.027842365 57.53202662 19.73497038
-72.72941574 22.21994778 36.43656273
-16.88177856 48.23666059 26.75041837
-0.5460039756 58.80230747 52.47401562
-60.30434638 77.90506653 34.39066788
-65.07505579 40.39154957 45.43997022
-58.33910746 77.86766714 34.45004453
-49.75864872 15.69312316 26.25106906
-61.439794 65.1485886 42.8543302
51.40068262 65.24960257 49.2955359
-55.82814746 88.06249558 14.67507247
28.41164002 42.99734772 49.80125847
38.98246496 17.24200609 37.9601718
8.435323226 49.84148057 61.55878821
44.51089516 66.48176804 24.65229802
-7.624507258 80.02968913 -6.146510626
-53.293723 78.0481331 31.39287833
0.8321356127 51.61454707 48.25235045
8.069296768 70.25103846 57.76518355
27.80298376 23.77896952 34.12601383
-37.36170449 68.54018928 22.19687841
-7.065985137 44.88055819 28.87872866
63.10036475 55.76570345 58.10746511
-60.83612807 80.1202384 32.08331331
-17.18854169 24.88636269 24.024097
-59.45597956 78.43772198 33.87206683
53.84209487 70.63265637 50.4325768
-60.75662198 17.83012305 32.6737997
-36.99196219 47.8685895 29.03174843
-60.94196887 42.79996017 45.82638228
-50.5727604 84.33084017 22.03277393
61.75097595 40.81667782 62.93141607
-38.47526318 75.66401678 44.59019165
-67.02251587 23.04637255 37.40776278
-48.70114985 52.35510016 37.44848089
-49.59646854 78.041426 30.76565054
-70.60524627 50.64862719 43.95578556
-49.60753396 36.64895206 39.3238962
-58.33826331 51.7653259 46.13286776
-49.61476035 78.62054379 24.04631588
25.50060204 38.42588401 21.85232099
-64.17545024 60.01973384 44.64911676
-44.29081676 28.74954278 30.95917777
-6.765211781 39.70001664 24.86782611
-14.22220329 78.16114604 22.629767
-53.56246722 63.13053579 41.47254272
-14.2293496 53.0482197 28.65490386
36.4606378 20.38763358 36.88420502
21.23545323 71.62605616 50.94539025
-55.79316644 87.34441087 17.1778123
25.41337135 73.00742002 51.59267913
41.5962654 71.01193506 48.36498586
-16.55607671 33.69486141 38.18082058
-43.71593608 42.27381964 36.99581456
-39.44236856 56.65789175 32.34595711
-19.36733634 58.87245484 26.11582607
-15.76752299 49.89979042 24.71102074
-62.20766129 79.59132198 32.65827992
-13.81953808 30.45904361 34.43619396
-66.90361886 80.23236394 31.93878515
-60.43502353 55.3474154 45.73570946
24.0141489 75.05310701 51.41825523
-29.80359248 76.09285981 -8.655006452
-46.65971324 43.80353576 37.27907216
43.76478213 14.97641919 40.04217821
-51.57292628 63.62026027 41.23468703
-49.51582241 48.3337615 43.49999262
-56.31555154 80.59687571 31.51469928
-49.48246824 30.60216827 33.74919213
-37.48346572 48.37096144 29.12022191
-50.67344247 65.16872906 40.56731687
-55.79302937 33.98741171 42.2674061
-67.06566093 16.55744727 31.17119764
-8.926029151 37.04004834 27.02555608
-11.34416741 38.83160461 29.99558101
-60.89872804 53.32468425 45.99891658
30.24647879 44.12354097 53.85759392
-12.40595479 82.42538556 -3.912447622
30.48548693 48.07025941 54.60875292
-63.54028209 40.86289868 45.50215609
49.76779836 44.34931671 59.16707422
28.47051905 24.20462094 34.88407946
-11.5423246 83.34291513 20.2313493
-5.173019778 40.4463948 27.55296534
-17.24951197 44.05754219 28.72167698
-61.98487452 65.67798696 42.63927865
-64.68163173 67.29370789 41.87702132
-49.50276089 34.50624364 35.04483328
-49.47805397 67.86969773 38.11773761
28.75211199 37.86925623 21.86733916
57.12651072 58.06245956 55.89085698
18.4133639 34.95554896 34.35687933
-57.79361875 74.60398206 37.27340994
-67.59498994 44.61498775 43.50491086
-58.84998204 45.24687086 46.15510736
-66.55436807 36.52537841 43.99029299
-55.78984531 89.09716217 14.30988486
-61.68521144 8.240903127 15.98344816
-48.35854569 87.45277081 8.67542197
-40.410436 43.97803647 39.88820721
-63.41070161 51.34406951 46.16382282
-72.60498344 16.19826137 30.57063071
-57.22729175 77.30016294 34.99937669
37.07331662 33.11621318 23.01746025
-11.7419021 36.25526065 36.67294984
57.70691506 46.14437777 60.12122196
-60.10447113 59.94839403 44.70092119
-46.02228819 31.14293365 32.63835655
-72.58885295 41.71734822 43.13533947
-51.72856423 65.70763451 40.33916794
-70.16636089 78.63712641 33.59976865
57.44239602 6.316871735 50.56051023
-18.81814692 54.13742167 28.5390454
42.6746018 33.51847614 23.10614044
-60.42027922 74.65291739 37.20625273
-8.965637457 69.25140969 26.00513571
39.43173964 48.51963391 53.51262235
-41.26130878 44.89134856 41.10136241
32.39176433 31.74112442 22.39337143
-55.66115999 76.92470669 33.23263489
-12.18573112 54.02527021 28.57613988
-40.44012535 60.15637826 32.70973693
-29.63196911 50.03677666 31.59145949
-14.34013285 51.59745522 28.03764095
-48.68104108 75.26678971 33.6678721
-74.4753037 34.18749326 37.68089359
-66.59437515 78.5697207 33.70106423
29.46937362 41.79958266 46.02415002
41.1440172 70.49007296 48.68274757
-15.89871897 70.02317523 18.36809711
-29.95884084 52.06186109 31.45170148
46.58016907 66.9579077 47.86350653
-16.38019638 26.2959005 36.64020356
-70.70070418 22.16291912 36.71170559
38.97964827 49.00212475 53.8243741
-15.18938639 31.21292464 37.59911335
-57.43652287 78.39775079 33.95095916
-65.29638001 57.981644 45.21595559
-55.96540557 78.38660583 33.79978702
-28.41298662 50.51875663 31.59045787
-65.6453658 59.01345161 44.95781527
38.98783077 49.50021318 53.86728046
-72.33419465 82.02540869 29.63937878
-17.2562716 30.25999974 37.4022466
-68.77148225 72.65689492 38.66359955
-64.84206087 20.67427457 35.39577665
-51.21411485 78.56675756 30.8023541
-2.719151832 59.75652383 47.63784818
-40.57324633 40.46365997 34.10357527
-46.68844611 12.53491226 -1.764674265
52.06053565 53.11799864 55.98947081
-39.14345194 52.70091851 43.26454158
-8.57732873 45.40182203 28.94238079
-52.8256368 75.30093118 34.06193233
-54.38414477 11.30696903 19.7844471
-58.24352542 49.24362289 46.25536511
-49.60132822 79.06642839 24.69424141
-49.62407102 83.23763717 17.06262788
-55.75795427 36.35690925 43.88706506
-70.10555135 26.88485997 40.04888031
-49.63924212 79.23422083 22.75361439
5.733175574 62.48389535 60.97774885
-68.23985939 25.42819483 39.12319082
-49.6635945 79.34561659 21.46509511
-53.25454773 80.28501414 28.72462292
-74.09632757 33.37021692 41.26952044
43.2405886 67.32744432 20.90396588
-19.97782196 20.09521022 33.56209843
-57.30637499 47.72262816 46.25353905
-14.51528419 81.07450563 23.82510054
4.260955851 57.06088576 19.33322027
-49.27942915 76.73985919 22.5081618
-27.94523008 52.53944377 31.35020642
-27.94225043 45.00470034 31.4188255
-58.50555994 76.23437273 35.96233901
62.14691582 55.75110495 58.45876365
53.42655692 50.83462219 53.10285765
43.61487962 70.48256174 48.29873492
-49.85073477 35.44508833 41.69232347
-62.32811981 77.39544354 34.86604844
-49.88121485 13.54600569 4.586619152
-71.01691625 44.1788149 43.39426468
-39.96269389 62.03683573 45.7488127
-20.28009387 30.34822366 36.95473995
-71.95065988 70.59162164 39.93852396
-49.72885927 79.67587305 17.64368278
-49.90314294 13.65617853 3.311834031
-49.61957279 29.6735563 38.72870425
-49.54251153 61.05129233 41.53672507
31.95927278 44.62897865 53.49010527
-5.928732009 81.2813174 -9.346438207
-49.90844742 51.30972412 43.98756795
-55.34368731 84.92870721 16.00214032
-59.90609267 51.28587356 46.17105402
-37.80760912 72.68559647 44.07869729
-36.22849668 32.76960551 23.54030805
-8.51895246 68.17031861 26.81676283
6.076512462 60.50006093 60.63550655
-65.05872727 87.13710092 21.35141616
-44.07181636 26.40054631 29.05307373
-12.29413749 78.20444292 21.75945134
38.79257962 46.33097221 49.9088913
-54.17842108 50.86772854 44.10624398
-13.41148054 58.03521119 23.04606163
-47.85753352 40.37191812 36.55773933
25.20909992 71.1139877 50.30541533
-47.13174816 62.0966485 34.76836056
-59.33490136 49.76364965 46.25328775
-30.62022634 44.61447203 30.63294555
54.36940226 44.61163553 61.07097369
-49.47664085 49.59098517 40.55103188
-63.26490055 35.94960327 44.22034893
44.36376861 41.329811 54.46379413
-49.55677754 78.14529351 29.55231117
-72.38584589 71.13991828 39.48312971
-7.026931664 49.88301828 29.06548238
43.55391036 33.52091836 22.9100999
-2.006107496 59.59505797 49.3762801
-16.4931069 24.4431045 34.86378065
57.03923867 71.65965688 49.5539725
-7.95131131 68.03264436 28.30673375
-49.69227742 85.10746239 18.67590337
6.52119715 50.38009044 61.49788799
-6.427384893 67.66035929 32.33786921
-72.13666592 80.89655722 31.05295898
-29.95793208 49.53793362 31.6179158
23.01763669 73.47924975 52.39885727
58.74695137 56.90961313 57.31163045
-49.02790232 66.32753868 32.62783433
-70.96678022 28.33038998 40.91227731
-42.57301882 55.35622958 42.226996
-2.749286547 67.82530032 41.37496116
20.52338994 75.00520506 52.64004497
-55.99797429 85.01097775 15.17186019
-50.80267918 78.55743544 30.83210889
59.40773138 48.13679874 48.32066928
59.64756626 4.124799229 52.2805852
-50.27972503 75.255562 34.10309015
-41.79376235 33.00098494 33.56889966
-5.067186934 44.85100798 28.84058629
45.27536744 13.87898828 40.84059784
50.28164302 65.16320871 44.6852249
-21.15886288 48.39384937 31.56796362
-46.02910847 36.92518783 35.43472189
8.628269948 54.18697417 28.55624834
-38.5814992 69.55473608 45.61175003
-20.95023085 26.39598353 36.34989969
16.60576214 50.96529466 29.47465447
-65.67232904 79.10237609 33.16841278
36.31377032 68.90225965 15.59662699
51.84120005 38.91881324 57.71986165
-63.12710993 79.05735633 33.20564655
-70.60580864 39.19705372 42.88137449
-37.06369908 70.20050444 26.17246107
-1.273278661 59.92582803 51.22334617
43.7080067 30.62811256 21.50266819
-71.8540748 82.00621731 29.7706046
-77.9543369 9.647122502 14.41492875
62.3048659 39.85495316 62.33690577
-7.876285463 70.50587838 28.71452486
-55.81039418 16.57873746 28.77759827
-52.10238478 79.69715714 29.5025308
-62.60841048 79.04348168 33.26779452
-49.60526976 81.50516322 25.51758392
-74.85088634 12.03646989 21.04564032
-62.12230705 79.03366086 33.28909883
-50.66537291 23.47406944 35.14998033
-20.85789879 48.41547577 31.2595129
-49.73681353 87.25602265 11.22226255
-7.384512879 77.39754604 -4.769645244
-69.40194523 28.78657495 41.14476681
-12.60449766 39.67890738 32.05310674
61.412475 58.36011062 57.44478825
-61.5920337 79.0263368 33.27299563
-55.80283817 78.95548334 32.99147986
-63.51648263 56.41959003 45.53005836
-67.52116595 78.03945873 34.20683597
7.83432779 47.92517135 60.61275384
-68.07947238 86.5976673 22.36298886
-62.30401076 20.63287114 35.39239654
-7.312939495 78.96720099 -5.52507242
44.82342556 67.27899127 26.99122959
12.15299165 71.25204991 57.01920862
41.64462001 69.48089495 48.6492727
36.10719406 35.11687188 23.28316297
61.08123104 56.29533882 58.17077302
-56.08009586 83.37896911 22.47978808
-71.78355537 12.15595803 24.90021782
-5.492317524 45.84689258 29.01398786
57.60386289 67.85932519 52.77683341
59.46763292 54.33232604 57.96001721
-62.12926356 13.3269343 26.9454623
-61.07175686 58.93634333 44.98072978
-55.0443703 79.19232753 30.09737489
-67.22423426 60.07099378 44.63547545
-59.36685478 12.84358415 26.20309468
15.89000984 35.91297866 29.55019558
-12.63237122 46.54388837 28.11104384
43.00948668 41.89573579 53.97903663
-69.04749449 83.08156687 28.40521196
-41.56812559 54.21655616 43.61223617
-68.89048409 49.6216461 43.89743433
-61.89137698 42.31827407 45.772563
-14.93171414 69.72694239 15.7959292
-49.7302474 77.45802637 31.73705089
-16.2392736 53.08338222 28.63001622
-50.05570945 79.10407294 30.17053097
-37.50377377 69.06451514 21.96385544
42.48633226 47.01981149 41.20792
-35.70695569 77.6887712 43.86399527
-13.19410637 49.21444138 26.34964595
-11.25073949 82.79272308 20.73613232
-10.98452241 71.15701945 21.7483624
-33.23160595 81.31839785 42.04306212
36.84416656 50.59488285 53.22164735
-15.71099175 79.17324818 22.81774947
-49.89046817 9.818819512 12.89643706
-55.76286819 83.07027939 26.00278698
-15.1524609 79.15166987 22.96173102
-49.87478409 11.73877942 8.085072078
-14.66271814 79.14001323 23.00365294
33.74821338 41.36895197 44.38058379
34.1681901 72.54892959 49.418933
-66.56189916 33.59021481 43.1054049
-11.91760934 79.19238378 21.87218854
-48.88836269 70.27487738 33.39076203
-48.73190969 35.02844543 34.66236869
38.42271301 45.25294852 50.84063124
-14.74853455 54.58137626 28.43583793
-11.45364893 81.73346288 -7.714996972
45.56080354 30.61026676 21.35649578
-60.55548818 71.97465258 39.19008639
-55.25220916 9.140394616 10.14132232
-72.58393489 67.97876777 41.25800357
2.331201068 50.80214258 51.57081361
-55.79607051 90.19052801 1.618481412
-63.41259726 51.84724178 46.1496343
-16.00116787 25.53961343 27.86752879
34.30554727 36.68989714 22.84584513
64.39512132 45.3655891 62.05984756
-56.38606175 55.78939806 45.65933181
-48.22576214 23.26252283 25.48723601
-57.80903029 48.23062551 46.27883538
-14.4622258 44.54129705 28.40098606
-54.04942903 16.65677103 27.53590083
-55.41594691 60.06109634 42.49846056
-31.9599786 48.06247434 31.64800353
-5.157359303 60.79489137 41.87534064
-55.97054935 39.75666238 45.24727913
-46.19025904 53.83404507 37.28088977
-11.60940054 39.85740179 18.13795833
-11.40560746 71.24554267 20.80102349
-10.77028374 57.09267037 27.65743112
-66.49751627 78.0186451 34.25323957
-62.59853866 25.81026223 39.43868091
34.08558204 44.02050169 54.32160913
14.72479899 54.08497841 28.5776449
-42.37460505 41.83831709 35.96900513
-10.2643084 83.1852718 -7.315546161
46.25338608 29.71485809 19.96569141
1.474546692 66.85918546 51.78481894
-64.26259637 48.84149638 46.24477516
-15.2910094 70.91927659 19.50288673
-68.44970969 17.49193656 32.2401923
-3.994570781 51.34669092 28.97573541
47.03029086 51.41658859 53.39341421
-50.91525572 72.56951243 36.27270934
-63.81213422 15.14403722 29.47870298
23.38929764 71.22669004 49.34413138
-15.94480196 75.2734634 21.520759
-68.92950106 69.47553137 40.66547952
-63.96332095 54.38835089 45.88881601
-49.48899473 37.97191483 41.42329569
23.85760405 32.83830028 22.93735078
-71.09647644 78.10854158 34.08670496
21.27705044 28.39451787 34.22966275
2.195556578 66.69370145 53.5683774
-58.40310116 55.31354493 45.74140283
44.67284845 68.43557204 48.55336081
6.11652833 59.5002211 60.58146398
-74.69472039 37.7707914 42.73832563
-25.46328538 75.66931821 1.260693363
-68.99301079 79.71867481 32.47388222
8.55132664 52.45509989 60.32906944
-55.69274639 69.33972517 39.71768836
-58.42311755 78.41226128 33.97066026
39.05738786 47.62970663 52.26165686
-47.07987397 9.335011569 0.4969109
-5.067224689 43.8589839 28.70345117
7.167422294 53.71550053 28.48141
-23.93676125 54.5085008 31.03407985
-48.840582 44.29752656 43.61365398
45.32826283 67.12344215 28.70068207
-37.76802023 68.02659376 28.23655783
-50.27539429 71.49008669 37.02816408
-42.83916182 50.46166379 35.00439193
60.65540876 66.27524613 53.10410845
19.68041776 72.92601347 53.63101474
-49.50599379 67.32536105 32.78884073
-55.75363578 83.01606648 26.63036943
-7.622855197 70.45237136 29.28734276
-37.13242502 55.97490108 28.18080867
-39.38833616 53.11512342 32.67620691
53.52571144 45.19255371 60.3147892
-61.69484942 79.58041727 32.68707349
-59.92799068 70.9014637 39.8754969
-45.55232389 36.43024074 35.26286241
-70.04952828 11.26792606 23.22516964
-49.65955306 83.31175041 22.03567567
11.63826245 42.60431369 28.42946024
33.24843683 70.15492062 48.25253412
33.54386337 74.09795717 49.03567583
45.50625334 50.93982372 53.39205107
-46.96757915 13.76114292 1.533500855
36.1416431 72.04233743 49.09683421
62.9114687 51.41537967 56.20545374
56.87457233 70.49051072 51.50439585
-12.56017154 33.63661411 38.09495464
41.16101591 31.12668635 22.02722713
-15.43392057 49.5018481 23.44036447
-66.35411422 41.92396061 45.37447133
-69.17811645 34.19804284 42.37486263
-60.23932968 35.40783698 44.10593207
-30.50238373 54.10952081 31.09119346
-55.33064505 14.79864754 26.04412618
-2.693133422 60.2456151 47.78179875
-47.80677823 64.19824679 33.80676945
-63.70570032 84.12933264 26.87655151
-52.74526315 74.7581714 34.52071536
-6.163711147 81.23879442 -8.807988428
-49.47670931 38.28782967 37.75358219
30.38395263 48.62369348 54.03009027
-6.349282716 60.5718181 38.86560358
-73.91828586 52.6933854 44.15712007
-55.08393286 8.627626716 10.23517416
-49.47946209 56.36899518 37.6138141
0.1952661168 58.62660339 54.37235245
32.34768734 21.08147289 35.44061693
-44.65704459 44.76407625 37.39977798
-50.03341169 12.14090977 3.447100029
-50.60777787 28.20700892 38.46199036
47.60526149 52.88097654 53.7640026
-49.56146777 36.70004795 38.72195258
-66.26156932 37.48122223 44.49160376
13.12357103 34.82403902 36.89233833
-50.06329731 80.35345223 21.49491098
-56.12485537 18.22589369 33.02274054
-71.11141688 39.20432463 42.89339101
-57.83688996 70.33552522 40.21989746
45.15531479 69.94662292 48.39989275
-17.26553706 54.10994639 28.56190646
45.07584419 67.20121671 27.84595583
14.0220195 72.30478214 56.09517139
-17.35483758 45.6173208 28.11463728
44.8865528 12.39502921 40.6617043
-61.87186043 64.11432996 43.28987527
-57.19915854 62.99519203 43.73725114
-49.63407877 81.42058425 26.50493261
21.75548098 30.94920341 33.61497335
6.31182643 60.21085711 23.1614275
-53.18435391 54.9043436 43.66982295
-10.86892871 59.86412281 24.63640476
-10.77511256 80.36382941 19.7085931
-38.90428143 41.06554039 32.62486714
-41.88010866 51.45308949 34.96558349
-49.44964807 40.08482014 40.19420853
-37.0691339 78.2586792 43.33450364
11.21446384 33.28930842 37.59276684
3.137407952 56.6613129 24.18602179
-35.88216219 78.19264606 43.87470207
-10.30814698 57.0825504 27.68677887
-16.26120446 79.6928465 22.71744273
-58.69390978 29.0905699 41.40032584
-58.65743872 61.46843548 44.25913554
0.3841982629 37.13237416 30.00489578
-14.14906592 79.63090064 23.03375797
-47.8107287 56.41922065 36.71252256
-3.773371101 67.01534625 39.31951615
-26.83311115 76.44130678 -1.613238963
-56.65158511 87.74599551 6.852987684
-53.65138567 69.95197676 38.04756592
-43.37238355 49.14966098 32.85684369
2.337707838 37.86993396 32.72349957
-1.452751021 68.59693042 43.82334096
35.98821622 49.02445401 54.13564386
4.152391177 57.97141514 20.4371166
-43.50432438 21.8106777 23.96098443
8.455235756 60.5362416 24.802007
14.08707764 56.66997269 27.82396633
2.043246423 58.18996372 59.08876418
-20.65610074 55.04071175 30.05678578
-54.40774616 85.47913123 15.2605678
-49.47249681 37.53571015 40.65729076
-25.93000866 44.47854154 31.31647333
-13.85272246 49.29889569 25.49484053
-56.80010872 47.71502532 46.24525038
-24.62921425 22.2204197 33.08472771
-20.66823793 50.32200825 26.57084495
-11.05830343 81.80844875 14.64568466
-44.40293168 45.91287977 35.66844896
-13.29179697 82.2709131 -7.776924673
-62.50167846 22.50605728 36.99134495
-56.03873572 15.49126738 29.79200909
28.13219678 68.84378006 41.14234357
55.92569877 46.22522683 59.52235557
3.882450256 55.35538464 27.55082481
-55.48029598 21.67643125 33.62994727
-11.27477902 82.2321721 -7.711848256
44.69194502 64.82277533 38.04993007
-24.58099357 57.09677948 30.24351524
-10.38223299 70.52442902 23.15043294
58.30254935 42.90495292 62.65339384
-0.5650829212 59.76680801 52.93430977
-6.796166633 55.9914261 28.03030861
-13.86889727 75.2421843 21.48798208
52.49521352 39.08445921 55.67219767
-37.79587947 70.70039435 26.33564633
-10.12402527 44.43427127 28.81609871
-65.05542859 56.95477763 45.43733722
-16.55491576 74.32901913 20.94762783
47.27420007 41.60277923 56.5665842
-49.74689262 76.36287943 32.80020854
-9.755235115 82.12226523 -6.725755042
-55.90351508 7.640179746 10.20119669
-38.23580072 80.05650742 40.16641411
-71.02485632 43.68060417 43.35273173
54.74804671 56.01449715 56.81212325
-39.9515676 62.5334392 45.80839626
6.099899532 41.91587269 25.8243026
-49.61417349 83.67864583 11.94118918
28.63688315 70.68731153 48.77824382
59.98067252 8.340432848 55.71867674
-63.79569601 35.46736362 44.09312373
-53.76341043 22.11786034 34.00473683
-60.49875309 19.20669152 34.12433396
-29.43454918 51.04073345 31.55242901
21.83789273 35.87930715 22.98014009
-21.48101614 74.82505809 10.30203981
1.57516598 66.25665724 52.93357675
-4.524789876 47.83847544 29.01629072
8.167978828 50.34227783 61.6227932
-37.90453765 65.75782358 25.46692769
-49.5451779 68.0026692 36.58690843
-61.14373176 30.09189951 41.89663853
-58.45964497 40.77780322 45.52109837
10.35801597 33.32489005 37.3430391
-68.18907018 18.86710742 33.70642274
-2.228451776 30.84938656 21.86767547
-56.54438697 81.71674171 30.21139943
-54.10734098 78.61388131 30.80706287
-19.48743344 24.86999847 36.30576107
16.38580096 73.89510179 54.66271541
-37.81849879 53.60595177 32.50557676
-64.9794668 27.27905499 40.32184267
22.53952692 73.47264931 52.56665845
-55.53677811 86.27832069 12.02494679
-44.7380359 48.27088804 37.49207918
-44.6528023 53.29960869 37.3651742
-63.38748049 20.18164441 35.01054888
-68.53149454 14.76767457 28.92116462
-68.45425993 77.51237633 34.67689888
39.42822635 31.15832914 21.99034792
-19.42184118 74.88321066 15.06090698
-80.12319197 10.23989452 13.77385303
-53.97242351 19.38344391 30.82785258
13.19964481 46.86548465 60.23789048
48.48716617 51.42501681 53.0177384
-66.90691825 28.26465811 40.90110078
-49.78276295 66.81409157 32.95017067
-48.96607566 10.26263984 1.741316887
-5.425512541 81.33719612 -10.09108631
-37.02231169 45.3728189 28.87705834
-45.32301595 40.33229665 36.53434658
-69.57749239 65.28911938 42.77485424
-4.238130788 68.70688238 37.25148497
-74.50848974 47.72116956 43.72437679
-21.10323757 75.23581859 11.28822851
44.36159576 45.83753342 42.92176125
-17.14104001 24.81284519 24.86848303
-17.04863411 24.66712047 26.54253992
-17.15343661 74.4079368 20.14563526
-15.55146618 73.81617923 20.88304286
44.99938031 66.7871279 26.84094203
-4.285983312 39.4405067 27.40760352
-55.76331288 85.32714635 23.11007609
4.160783454 63.53817058 60.69209887
-39.3224569 44.92254557 34.54281218
6.939058268 35.97484941 36.36559684
-65.57927789 80.20853647 31.96283203
-47.35317246 68.43315909 31.17143821
-3.528389799 44.83050041 28.78519532
-24.34825058 49.94835005 31.61034326
-64.06082671 57.45083293 45.31572967
13.20898652 46.35855629 60.2942625
-4.818921498 68.29941808 36.26574718
-61.80112842 80.13624477 32.08153111
-57.87283358 55.30622378 45.72526692
3.368687937 59.47400634 61.40981383
-59.14839205 18.26441784 33.15213074
48.25810771 68.86368664 48.7264045
-49.67542437 79.40113976 20.82279749
-58.74288964 80.08021546 32.14873567
-56.70140861 80.04152321 32.20858088
-16.18236396 33.17757439 38.28795567
-46.59277985 49.29414809 37.62032232
-52.44637535 61.55644248 42.05299875
-52.51370426 29.19414285 39.01937276
-18.75967805 44.57821204 28.79199999
-55.64220025 80.32488444 28.71711897
-9.049107871 46.89867623 29.13569862
-49.89450483 11.34562276 6.826241298
-4.712046344 69.29949365 36.2890402
-49.64629409 83.75772236 22.68262019
-37.51195813 69.2668665 19.61637361
-70.65243647 47.65913571 43.70913852
-56.93215111 71.37912713 39.58574744
14.64655303 45.3722926 59.81609677
43.69525323 12.97785046 39.94971868
-38.63400701 73.10912426 45.14631768
-49.73683934 20.23350263 31.80528115
-47.32394674 28.79290618 31.03421991
-49.76134014 80.25734734 28.37962358
-14.2020268 29.6971102 31.70093367
-55.80680989 14.69195457 27.37350373
-20.89886902 45.9250627 31.04465409
48.47877751 66.43951449 47.6926763
54.20193576 55.01298937 56.88922127
-17.23144939 41.1094681 27.98178242
49.33335134 43.46492607 57.86329588
-36.7485315 69.19814504 26.09519434
-17.89004393 79.80833081 21.6874458
54.22836247 9.811676391 45.56304726
-54.17275783 61.58485327 42.05241697
-54.43959723 30.17895981 39.60745998
28.72511614 42.18167548 47.55711331
-64.04532707 22.52901214 37.01925124
-11.32756711 69.24686754 20.68165671
49.80955379 41.26540171 59.99960201
7.067237673 66.06978773 59.8821654
47.71487264 41.10127065 56.47778199
-50.72467982 32.04831763 40.50469686
-70.48230965 63.74334101 43.41200924
-35.8785649 78.70543639 43.74781251
-4.781732736 52.00486398 33.13858111
9.107979247 43.1440152 28.47339229
-25.87500059 46.96187335 31.61085949
-70.025193 61.1503397 44.29308165
-50.11292912 33.49415221 41.08365214
-65.48754154 43.86735786 45.95540069
-50.04386491 24.41276601 35.78766206
-17.16845909 73.91329873 20.06397894
-55.75347525 31.50593458 41.93345591
37.5897492 68.49972339 14.19958374
-42.71441838 19.11747847 20.11512079
-14.09067876 31.89092541 23.69221233
38.65167774 17.26020155 37.8120287
-49.70570031 84.33946102 21.76734316
15.56233777 44.40145841 59.25832107
54.78118835 53.98891505 57.01367466
15.86314423 52.03461035 28.85616101
-69.1552705 71.5982828 39.37265069
-39.75665108 45.42744512 40.41765582
-11.86394211 82.77141858 -8.032817223
-35.90906389 62.27638281 24.71499772
-49.93133793 10.00622193 4.902084925
9.630023396 43.61828045 28.69485914
-72.51935005 45.1978003 43.50494342
-19.78218805 30.84705419 36.89563831
-55.16533215 45.37041129 44.01827466
-36.14036803 34.16985326 24.74832646
13.98486762 72.82573909 55.88124889
-5.023629126 45.34364059 28.94008154
19.96721467 44.93645934 58.03424406
-49.49271963 50.27475696 38.44301674
36.14213867 69.60534088 48.25396957
-55.86131437 7.209128436 3.543824097
-39.96804817 71.60641897 45.36530438
56.55006421 38.62480957 60.23486255
-64.90269974 34.02357636 43.58484947
-38.206476 75.65231883 44.67473077
24.39066179 35.83047888 23.06014597
35.32413713 43.58728781 53.28783107
-54.37209371 30.65951323 39.84261279
-67.30869715 79.68670741 32.52377446
-59.29499606 63.55113101 43.50983537
-55.82340265 82.87781475 28.24860158
18.47350922 45.42404792 58.4854595
60.76409618 7.690804473 57.2839928
12.19500382 69.29632672 56.40809509
-49.82401371 84.13804671 12.47478726
-55.81457318 86.75388186 18.21054569
-70.09341861 80.85697399 31.12281051
20.67548532 72.94439569 53.22785511
-44.41222314 27.80635307 30.27828335
-32.18608992 83.38214053 41.19280816
-9.978250935 60.27632347 25.50806209
-6.358908394 69.18202073 32.14017644
-50.17364689 83.55776246 7.624544914
-41.47333161 62.54442562 34.31778378
-68.04688118 80.81573109 31.21130127
-21.93850264 58.08145744 29.96199767
-49.70912242 69.2880244 33.35011369
-63.6876259 76.33346627 35.80024001
37.60880237 70.03671389 48.79321626
-27.3889926 48.48890521 31.65260926
-66.99735714 80.7942543 31.26046892
-19.08147507 60.38356994 25.99910636
59.36851883 6.113370199 52.55558173
34.12625257 23.58737481 35.14429986
-68.61184967 86.60605268 22.36717277
-55.82098048 90.40797539 4.925585452
-56.79757137 48.2159614 46.25617576
-11.41378996 70.74745424 20.75811713
-2.128219796 39.29360111 28.70149261
-64.46569885 32.07921648 42.76649552
29.71443822 74.07222515 50.06468642
0.8810058458 39.08885106 30.50450357
-55.75218671 83.11444692 25.48802089
-17.5766862 74.49755192 19.1860337
-49.62107601 80.93658696 26.29440577
-50.2091439 40.8281487 43.36323086
18.12373415 34.35165547 35.59590914
-68.39181918 69.46447013 40.69134726
-16.24294981 79.19381101 22.6804887
-55.65014239 46.37162289 44.1412178
18.65358856 34.34361378 23.93493285
-0.5344324897 67.87925556 46.15286513
50.23006008 64.78612464 43.24588797
-11.82207244 81.68485008 4.572880221
-50.95896564 85.65397919 18.39974339
28.36855662 37.33752051 22.28662096
-46.67966968 18.11293389 26.70616481
-40.63278195 64.74872849 31.87489259
-38.97391559 79.65407029 39.15231991
-65.17974969 16.0702369 30.64079564
-34.09025504 82.30996619 42.34924447
-52.60939567 85.24097186 17.6823398
12.70700181 32.77753091 37.42257684
-49.47095336 54.56072071 41.12403917
-51.32790647 38.38586103 42.79523878
-39.63463528 44.73313113 36.80120781
-37.97095805 79.47024965 41.09497439
-10.05793271 48.9208757 29.15948385
63.24046288 48.65734894 53.20010027
-48.65342522 9.348272682 0.6430519086
-51.3523664 37.89598359 42.66011732
-40.36548989 79.91003583 36.44626622
-57.0798711 25.24976884 39.0662133
-53.97110483 72.62433629 36.21904275
-47.18879788 35.00576136 34.63142125
-10.7142791 80.98402568 18.32394157
-61.58604314 38.8593508 45.08155338
-54.86526129 86.18735872 12.95283759
-67.78404359 29.71820695 41.6744409
-78.94582433 4.871600481 17.60198869
-14.62141412 30.90643941 23.56877694
13.20000747 45.86086966 60.24684348
-55.93283983 9.099675787 10.74381666
15.48300509 46.95255717 58.79163835
-56.29775196 86.35339276 22.95186127
-68.96384954 45.13871041 43.5128467
52.19944145 66.09888659 50.93738121
-29.02188757 76.9011908 -6.534496271
27.5887441 69.72590617 42.6589016
4.973656407 35.47294819 36.74020556
22.99084919 74.49719451 52.24020187
-38.04865172 69.57569241 27.78693092
7.922409765 68.64504121 58.95686146
20.8029509 29.89254258 34.40983502
-6.505290401 47.85879556 29.15809332
-12.08932129 82.56965588 0.1790148677
-55.74499331 37.37958112 43.66595364
-16.45540275 50.17911044 27.42629417
30.46998734 43.30369633 51.6790423
-73.32799439 58.66998912 44.58346098
-59.40510131 19.65278201 34.5638651
-13.42161618 80.16260697 22.5492346
45.63500773 64.26606348 38.50615905
-66.67126661 59.0354008 44.89865852
-69.13754248 65.80479631 42.5312398
-68.35707002 82.50362894 29.15602842
7.517334369 36.60658287 34.74833368
-65.6270875 83.59283969 27.64433251
-14.04362651 69.27229578 15.07779423
6.909545802 48.92532198 60.83191872
-64.85112002 63.12608689 43.67700304
-49.59975883 78.51386499 25.28186053
34.8193355 21.97888594 36.20471499
-57.19227632 73.51920901 38.09828589
63.75179838 45.39277322 61.86695998
-42.59905129 23.61066362 26.19935716
-11.09965092 39.79848609 18.72468251
-50.90740646 81.94567241 26.47879808
-15.43993399 49.07699906 22.54682803
-49.84886333 81.38026323 27.01396925
-1.554869972 52.67664201 42.20463355
-72.39721425 77.59346877 34.48746368
-57.41624819 85.16984027 25.25142494
-37.81790993 65.51590218 28.25880782
-57.947721 7.835572606 14.14941763
-49.48405631 34.80080148 37.44846513
3.797071105 59.95835123 61.53213052
18.97303793 49.36138051 35.98932605
44.90317507 43.76701531 55.20128436
-51.10734408 69.90755098 38.07812881
-24.88379747 57.10085901 30.25390363
-40.3177265 50.22375977 31.45866117
-11.41277517 61.71371282 26.57529265
48.836145 42.50482309 57.4504104
-56.31163061 52.23633978 46.10514478
-62.84214383 77.40695971 34.83038779
-14.13769215 42.06473173 27.95566869
33.56741231 35.15914882 23.27673296
-39.73042704 62.04356011 45.62645645
-65.385943 77.4512632 34.80119831
-42.98950682 45.69544762 37.92301182
-50.30251807 81.55289056 19.2698577
-16.83019043 45.09822987 28.21392944
-71.05567237 42.18950097 43.18850003
-6.050590612 68.0220606 33.89376067
-72.20462507 81.45783247 30.37686723
-70.66930641 81.42669065 30.44558973
-66.65405176 66.80773584 42.06805091
-63.76656303 81.28967527 30.7197698
-65.53845463 32.57746155 43.01372507
-55.71910573 67.22026853 41.02035139
-49.53878395 37.92269699 42.00414755
-37.89474197 66.20554255 26.09425198
44.3185883 67.43454039 25.2817771
-61.36132815 9.863386579 20.39327393
-52.66301547 84.59235887 19.39549774
39.09436011 19.29353713 37.42972676
53.52011565 56.59101896 56.18025186
-58.52524591 31.01435306 42.34204548
43.79492044 30.18116511 20.84794442
29.01129971 29.9508335 20.51448606
-61.90448169 52.83425437 46.05734698
-58.53381065 58.89499231 44.97676106
-63.78861197 68.85782633 41.02921126
46.40508095 43.15140082 56.23472343
-59.44201964 8.602675755 17.18257842
-49.49796419 26.4369308 29.66549508
-49.29953359 70.27167702 33.50632692
-56.95860181 18.69029517 33.61727062
32.947253 32.21090781 22.66048889
38.48394502 49.00439917 53.89250448
-15.44435027 50.66686738 27.39788273
-18.31804387 73.66886812 17.29409924
55.15420312 41.49978802 62.08605917
-50.4636561 81.36827771 27.27035061
11.22896232 70.21817891 57.54407393
57.24117087 57.50240427 56.54388032
46.5047673 63.79588443 37.97183125
11.18886479 45.88425801 60.35887152
-65.41662441 69.94015872 40.42846032
21.36155312 44.95165584 57.59192314
-65.4748044 7.915862787 14.65281066
-11.24172991 82.0991994 11.30541898
56.71083799 51.99777102 56.45376828
-38.99970061 80.19407119 38.71515357
14.17530821 42.56341689 28.42039026
33.68569817 45.62975611 53.19639216
-12.36171708 52.05562491 28.16821797
60.67235836 39.43290499 61.7210369
46.17912201 65.98713623 30.07620506
-54.67259939 53.4050917 43.87812781
-37.54927785 79.93319793 41.46695683
-58.20518963 61.46330326 44.23246752
-16.74278963 24.16595006 32.30216633
53.82271539 50.90734008 52.18314543
-11.70269253 81.39016931 19.62426739
-39.2463765 49.62711242 32.35402541
-13.77582255 32.2343151 25.47249043
-46.05692679 19.77622425 24.75863805
-49.54709416 81.56826955 18.94726321
-21.30708578 49.90093357 31.58082006
19.81642947 35.90790162 23.03369983
-30.35371255 47.04066742 31.55027758
8.568044989 66.15516627 58.60481593
-7.042860783 51.89991767 28.96144354
-69.62565023 22.6183911 37.04587608
-62.90015399 46.81436276 46.21083217
41.32727849 64.10328082 35.39072735
-11.46174873 82.45796442 7.182571015
-39.71961934 46.26452972 42.3463868
0.8494282147 50.70050335 47.20665737
39.28500592 68.98922972 14.02038222
52.79849685 66.571471 51.16367396
-30.50833605 53.59791845 31.20474126
30.04026919 47.54485085 54.96634372
-62.77616328 24.39321527 38.44274748
-51.58440717 49.31622446 44.14261892
-49.697961 87.33553526 10.29181254
-44.44269544 52.71618404 38.27128394
-55.90810537 85.18957384 24.73473128
-45.49558897 27.35507394 29.8970243
-3.150505052 31.82338286 22.38993855
-41.31194682 18.87413482 16.84593032
31.55248776 47.52536167 54.90419925
-56.52144667 59.37320392 44.86820271
-58.04968387 18.70795766 33.62030742
-9.422209436 83.70870009 -7.725836202
32.06452964 24.64070995 34.96288149
-49.92087341 9.548282882 4.389539005
-49.53971163 22.99203345 28.87785278
-64.07884447 39.88236095 45.33437896
3.258040528 62.56789541 60.47463779
-55.88197327 8.336410974 13.76801068
-46.75703114 9.407546075 -0.4066907928
-24.20402646 22.20416044 33.19239118
-8.606258081 69.16923547 26.89053837
-7.964814639 83.71686625 -8.098568973
-1.503701104 39.25112477 29.07548957
-54.45084978 37.45770896 42.51218754
5.865194011 55.34632187 27.27791086
-37.26814869 61.8221794 30.24690889
-55.7932302 87.24533161 18.32800894
15.3341286 50.00765801 29.18082593
57.42223685 4.845170624 50.16901253
-54.17356222 53.90335154 43.82543545
-1.325719671 68.05541244 44.25881033
-54.16060115 44.856925 43.96094918
34.48331007 43.28621861 51.11657161
-59.85528857 82.90465651 28.70590781
-59.33513662 48.25712025 46.26230271
27.97754298 26.22677269 34.81004321
-49.11107242 61.6017703 35.06409593
0.4053531846 45.75848659 28.91554781
-69.85205144 52.62443901 44.18204477
-34.45199222 81.26500846 42.89558019
21.96815764 29.37243932 34.39872036
3.493908496 59.56910917 25.32205921
44.51602435 50.94377858 53.53498272
-66.72755283 30.18304297 41.90344694
-66.74050481 60.57697545 44.49606166
-15.5798182 36.1842561 38.22913633
-57.19496922 62.47847381 43.90825353
3.258438875 57.50558059 26.01534703
55.72461405 57.02186583 56.58489689
-37.04726162 56.77531718 30.52603548
-65.43415008 48.86258528 46.22338219
-50.29697474 38.86359006 42.87944749
-68.79417364 81.94934944 29.84722687
10.02153846 50.66242037 28.41965019
-20.44431344 55.05776685 29.81840854
-43.97045235 27.32906836 29.90806331
49.15915972 12.14764654 42.71869148
-31.96586419 50.07430738 31.60086336
-72.61563196 65.88678155 42.24278783
-58.74897358 34.89716009 43.92338131
-37.08776698 54.94794169 28.44070447
-71.14626395 12.58447677 25.63076521
-30.83177461 55.6956046 30.22151456
-1.379101931 60.38231422 51.77094347
-55.82489764 89.80862392 6.057401017
-40.68505761 46.88208443 41.18811659
-64.757729 79.63878758 32.59357782
2.037127935 59.05841714 25.70172102
-55.75335322 82.91681252 27.78252808
-65.43093992 84.74610544 25.87225709
-55.76421251 80.08909816 31.47756713
3.992487153 46.25998909 28.2362937
-54.17419864 82.00779315 26.38065207
-55.92015367 12.38671681 25.02279657
-64.93317149 51.36872988 46.1678914
50.72266123 65.89561745 47.75206715
2.012192752 51.76927262 52.05778094
-64.5608662 19.27355896 34.12275734
-67.67571251 25.89361067 39.43935886
-53.63011885 79.72447298 29.47677662
-55.29256023 74.80423938 34.47170944
6.170422204 55.34101943 27.28125663
-59.298096 74.09257233 37.67049933
-9.651506265 79.96196464 -4.973754225
-50.10332342 81.93650575 26.43186788
-52.79494082 15.26064349 26.02400692
-52.14736628 64.66668478 40.84985851
-37.87601642 67.10141726 27.3439955
20.82283958 36.93738317 22.54407994
-24.3050424 75.90938127 4.079623642
-57.63999915 8.182561158 15.88927804
-38.17900009 51.8904396 29.18267969
-63.91514546 46.83080883 46.21347915
-12.19553015 82.81479586 -8.47313659
-71.61696472 65.32679836 42.72638876
52.48546506 55.73683912 54.64022695
-11.10532069 82.03704795 17.8275462
-47.44633177 70.01157838 30.34568368
-56.60351743 85.76909876 12.31315938
-78.42441804 12.14755221 14.61096761
42.04996536 50.47142858 53.66201763
-58.42953033 26.69390453 40.03894318
26.29885765 44.51098073 55.93938669
-54.2546241 41.38884184 43.45243397
-37.29286711 73.60381661 44.97444534
-16.5128223 80.63837096 23.44237166
-70.19831679 22.15727294 36.68144226
-60.7413858 82.35386703 29.44221729
-73.45411877 49.69372296 43.9310261
27.46852241 25.23133287 34.80962668
-67.55651012 68.92494684 40.96858807
-15.28119378 43.05132421 28.37393763
-17.76840168 59.91276598 25.38749035
2.836023304 67.67239018 53.73818539
-37.5403999 40.692996 30.86289271
-46.50918419 71.21893591 33.63099983
-62.95909388 43.32858227 45.90106992
-46.40417655 31.627178 32.91636408
-50.03148818 83.33738467 10.15574098
-67.12597404 30.67562716 42.08779237
-14.35434199 42.55234552 28.16304939
3.489303995 58.16389521 24.15576364
-51.48703054 84.44514541 20.88019186
-17.37374512 47.63572264 27.99372504
-37.99507652 56.6463763 32.20362957
-22.81910571 49.41810653 31.64753913
-54.90867629 60.05298686 42.49586176
-55.96350266 21.95161723 36.35417933
-11.55566826 37.05997839 33.12232343
-20.90010684 45.72080534 27.58941728
-40.2121663 40.98195531 33.84460541
-18.28442246 81.79313486 22.02816581
-37.41045651 42.80975633 29.57177636
-6.188655161 70.1501258 32.52251975
-55.83874171 89.96427456 4.253134624
-51.74401706 85.12655164 18.84558047
-26.97799418 88.71330029 36.57800801
-6.946884425 69.78261305 31.10683378
-12.58376081 82.33497426 -8.655620274
61.80807067 4.87610166 60.62682114
35.98658482 68.56508936 13.74650086
6.298038396 60.59613911 24.51806342
52.14215932 7.361848182 45.26708797
-47.07264654 36.45381795 35.27909553
-39.31153462 54.79457038 42.29852045
-37.45851752 69.16743321 44.06704386
-72.74505223 68.51270226 40.91706931
-72.6957662 23.15784992 37.19557494
-39.27528693 64.83206604 30.6485669
-12.09646631 49.55940859 27.96232941
-42.13475964 54.30416668 42.70324964
-38.76380923 78.56842987 40.06187609
-30.63134616 76.76485005 -10.4714615
27.35289804 31.8175379 22.46725006
-76.55857439 5.96598456 10.26895643
-41.44170097 52.37837898 29.96718089
55.0718381 55.99983067 56.92063348
-6.237305355 67.61403112 32.83943124
-13.47070288 36.68799594 37.8057765
-7.531483788 37.58213435 26.29331867
47.53516594 66.49428719 47.23678723
-40.08590827 44.11166567 38.27505223
-11.31043194 81.69451307 21.84305771
-11.57681311 81.87844232 19.75867289
-64.18900722 12.04163587 24.77902696
-15.83402739 59.14667138 22.25869642
-38.03254814 59.20113649 31.68645917
-22.75873048 75.09792158 7.378108579
48.63003578 12.16677972 42.59748661
-38.66960612 69.61637077 27.43312645
-72.42576687 23.1455405 37.28698115
-49.5137968 26.89757773 30.14762963
-3.253546876 31.37314534 21.80963398
-57.64535641 61.45345691 44.24000756
40.0740471 71.53868454 48.36703045
-47.17733843 51.82754705 37.4554616
-14.05632143 30.11250861 21.02425633
-71.57944653 41.20150345 43.1045013
-72.42619094 50.67518331 43.99476781
-1.15981878 52.57647347 43.29212462
-17.44677444 25.33393691 36.35750004
-62.64481653 16.03040578 30.61975732
-10.42175466 83.71735444 -7.635682977
-49.74026108 68.34885567 32.60532615
10.54511356 35.3880416 36.66335292
-13.40738386 55.65261274 27.39765912
-37.54231661 40.24130256 30.28020445
-20.89165139 49.91437218 31.34558895
-15.52812014 46.13962439 27.52963081
-38.81484092 70.64450948 27.17872087
5.719558386 49.9849195 60.41155691
40.70730236 52.06070222 52.94854588
-9.509427263 37.01222608 27.45979487
-49.7873957 84.27490116 16.70573928
-12.45366476 32.67756711 37.55467878
-13.95010171 27.85276906 35.58342601
48.23518795 67.36860023 48.60690693
52.76390918 67.0663566 51.25191769
-69.71399771 83.6722158 27.50227501
27.28457908 72.05018853 50.69478842
30.74555607 71.561342 49.88300563
-13.52633901 28.38900041 35.10428787
-37.62287544 56.68162356 31.72347254
-7.45324074 78.43664723 -5.165901674
25.61492859 44.03165435 55.80754558
-49.62503174 78.6752808 23.41284737
-53.6144339 48.84692267 44.15111407
-65.118442 73.12515159 38.3577185
-60.77037913 28.64469566 41.14571365
-6.817434463 57.03136437 27.61528387
-39.66926983 79.78183468 37.8017456
-24.99535819 42.99597468 30.86902529
-24.72283005 88.82698502 34.82819902
-49.49030085 49.19477122 39.32653651
-64.01150273 82.41766491 29.32523585
5.700394277 56.90172173 61.69289598
55.45552325 68.95635333 52.10471299
-61.92282293 74.67991511 37.1793876
5.912332872 60.98598443 60.8525029
-28.44924399 45.01267005 31.42299392
-62.27272169 82.38299977 29.39605807
-35.31216098 46.33225026 29.06642464
-46.38270208 39.36685648 36.29068732
-55.7772299 82.52575229 26.50015302
38.52646791 32.12208789 22.62758558
-56.76611415 69.78872323 40.53672984
52.09355058 65.14787022 50.34438544
-49.99776227 33.00817488 40.87662769
-59.22583057 35.88197081 44.23520105
-54.65354791 82.02659582 26.25379175
-49.60188754 82.58222271 24.66692803
-32.28555668 84.3995809 41.05386852
-49.62191082 82.69143468 23.40293404
-54.2318612 84.78908023 17.41100051
26.44719895 35.28197567 23.2087366
-17.78423609 30.26615913 37.43142996
54.23132809 50.75077485 53.92274423
-63.68449023 68.3278649 41.33489278
-19.96083093 56.66741656 28.52013941
-36.02069204 64.39111242 23.49352459
-49.72043402 83.5902398 18.81437746
56.30301723 64.50445562 51.18420041
6.587888008 53.72289597 28.50607899
-10.61551382 61.53454232 28.50319235
-58.89204999 81.19705687 30.86535294
-15.23756283 54.07806753 28.54523276
-15.17723804 78.17212535 22.68444203
46.52648836 42.13528162 56.35414179
-59.41416553 42.77260939 45.85252887
-7.043936362 78.45317807 -5.435860592
-56.98239583 75.66997936 36.39711182
-25.50319652 55.55977115 30.78217667
-70.6304059 86.64556965 22.29338171
2.583087481 37.84526586 32.96306952
-13.99500795 82.06426764 23.88928283
-3.855330464 69.1151097 38.26612302
-40.25057749 61.62935529 33.05411939
-23.06303082 21.15688745 33.47902388
19.034781 50.48333396 34.60637838
-15.82222782 26.77682186 36.77755038
-36.47375675 47.35274802 29.09454696
-48.49998721 37.93713934 35.81174356
-32.04316071 54.13102709 31.1353682
-41.00336858 59.05294131 45.62665429
-69.81207402 55.11535289 44.39127911
-24.90605011 48.95273848 31.62122299
16.76738492 36.83983575 24.44988136
-69.5910553 83.09559367 28.34603887
-65.92753148 69.95010257 40.41045788
27.87397513 27.83522034 33.63768262
-62.64691961 59.99201618 44.6793836
-15.93031651 34.15397483 38.55840169
9.774775699 71.81415391 56.7740761
-8.033379959 47.3840747 29.1337745
-46.74125057 21.98828103 28.34317823
6.808151749 41.83115774 26.67266778
37.15496304 71.52451852 49.08816691
-47.25050921 42.82456893 37.10302011
-65.51801569 55.43369884 45.70342542
58.97875046 53.85372161 57.78259864
39.51230819 72.07648608 48.05760558
25.36566149 36.33642231 22.82748789
33.03033198 35.68451156 23.10700416
-57.15015068 21.01305505 35.82271318
-29.4651025 52.56279387 31.36899017
-49.60665366 39.37685883 42.61604157
-49.8744907 39.35091072 42.96834438
-52.62236791 69.92845525 38.12438089
8.490897205 52.70006681 28.36362924
-8.097124658 44.40293398 28.79334274
53.55095309 70.12863703 50.51248277
-72.18043527 65.8654734 42.40715378
-39.86906581 44.20050432 37.20239511
5.500053785 43.7317664 28.16504128
-55.66321865 86.82619958 17.34216446
-12.2375825 54.54125326 28.42276129
48.26124099 67.85767404 48.7510537
-55.96362644 21.48663042 35.92546817
-7.658992403 80.54930087 -6.345325549
-11.75810406 70.82202891 19.95806249
-60.15689683 60.4646668 44.54428891
-58.40214319 65.61754966 42.65763056
-52.2639745 26.80486738 37.57499651
-32.54928844 54.13856005 31.14444169
-49.77632285 66.21617441 39.89000233
-49.70895049 83.53489132 19.45471305
12.54296223 56.70555195 27.70540844
-63.45889022 54.38167397 45.87012862
-60.59124233 36.88139241 44.54685983
-14.02034637 33.61746138 38.59575763
-55.75593244 85.9247508 21.99788076
-10.62275553 32.3782291 23.20052249
63.31217467 47.51763112 54.76381914
-48.69343135 84.41018183 9.100034759
-18.72367304 32.36485685 36.55393478
37.58502899 29.83812947 20.18777499
-47.2235497 47.33757261 42.97404821
-18.22111567 45.55874977 28.95977712
-45.39954734 38.86425413 36.11112778
-22.66464094 21.14175723 33.57869124
40.08927865 69.27157164 16.41601283
-14.86056115 76.22651718 21.90352571
60.27061077 44.91216062 62.28350433
-74.70622764 37.27438011 42.67658385
-56.02969726 10.65611165 21.82725074
53.0422803 9.840094042 45.4593474
-59.90228146 54.32202503 45.88431036
48.96292822 10.59131852 43.34318285
-56.1786921 87.68089395 7.518550784
-16.13478597 73.34804607 20.60207593
-63.9045295 47.82988576 46.26672228
-57.73601252 17.78068659 32.67164587
19.12578575 71.95358192 53.37219973
-58.19007131 82.3057725 29.51398268
-71.6290875 74.86835912 36.8428349
-55.83668941 89.75835752 0.8165370386
-63.39827956 48.8267232 46.25144262
-69.2035755 83.66251653 27.51753062
-25.58459897 22.80812513 32.27105249
-73.05567045 43.71391559 43.35331684
-41.12533551 56.8130691 30.86549682
3.304997725 37.32577878 33.02934118
-60.08432397 59.43601496 44.81850834
22.90356835 34.83286187 23.27152812
-41.81497283 27.30720418 29.7508158
-49.77524172 77.19484907 23.14750261
-14.11054143 29.60140553 32.79449667
21.7039643 46.53335628 56.64501281
-16.71213334 31.23772477 37.6016123
-14.70430516 60.02722677 23.47440403
-35.43382286 79.73330093 43.3840775
-55.45431187 75.89026557 33.54845353
-48.17869658 62.63143098 34.58651538
-52.82328984 38.40924678 42.80893848
-56.94807442 83.42702622 27.91407656
-13.24324272 82.41986533 -9.515330758
11.26703752 50.57925256 29.14759878
-48.17665954 50.8316563 37.55378303
34.71037509 20.95508441 36.45724803
-55.82057593 36.48760959 42.38174219
-6.774640796 69.74528204 31.50735161
-31.22654974 53.61332481 31.16286064
-18.19828745 42.03172211 29.1132486
-36.98483786 60.66817486 26.10948342
-55.67784645 83.72782981 24.17988826
-73.79289268 36.80699771 42.10148039
20.29607039 30.40267129 34.41119466
-49.75718291 83.71454779 23.20496933
-72.6317593 17.56974916 32.13442879
-55.71935212 38.82373902 44.37614762
-13.01518839 35.12501273 38.3832344
15.43287692 72.83863968 55.45534528
-51.54440265 28.69852859 38.76133228
37.6543593 67.7104779 11.6961176
-63.71192283 18.33644228 33.18631112
-59.22713402 62.51246657 43.90118674
-54.77572051 65.75807335 40.33474814
44.83190988 71.03986006 47.42375513
-44.60069788 55.6852938 38.7936684
39.99451952 49.99017137 53.81414752
-58.95430009 41.28176494 45.59171744
-49.66489442 47.80763207 43.80947513
53.56159998 49.32752751 47.26599463
-66.38483608 69.43079113 40.69957405
6.306220898 61.09422755 24.56096978
-51.32634449 59.47814615 42.65921923
-8.046593376 46.38630803 29.06581619
15.68386061 31.25590492 37.03909426
-56.99580129 39.27606823 45.19524761
-55.81955522 89.60104721 2.639442655
-63.30716077 73.63046398 37.97290209
39.9830652 50.49816542 53.74580716
54.75891028 53.49793699 56.89091394
-53.39463391 78.60125271 30.81774732
30.52802055 29.92907319 20.4778479
6.594250536 43.67536595 28.61110905
-17.55638985 80.79312124 21.84493176
-13.5006504 51.10538241 27.76324597
-46.53444221 74.84630069 32.31320515
-15.57976948 60.02284536 23.69222302
-15.4482402 29.66820906 20.62081608
-2.97454321 48.31440828 29.02231452
53.14916834 67.11979469 50.55809804
-68.31004012 84.22194283 26.67954013
25.49148879 43.60409534 54.96786237
-64.76187387 84.15416371 26.78971301
-49.03392518 13.78760023 1.620429618
-66.32880642 17.91539651 32.74653209
-23.85435163 41.98586296 30.72426069
-6.895475022 62.03187578 39.50027341
-61.20515042 84.08206799 26.94836422
-8.553667418 62.37277867 35.85905051
-49.41201256 72.65366222 35.00915943
31.88134212 31.75082369 22.37811582
-49.0887563 79.12331934 18.10941724
-0.4843600776 38.15371899 29.96730065
-51.64603134 54.36947046 43.75901244
-54.34474566 71.56060539 36.98558117
-63.95874159 7.894434407 14.61244465
-3.109271998 67.90778027 40.48612497
-58.62250401 84.03055729 27.05381187
-50.60554545 69.9022018 38.04453003
-63.40306606 50.33578578 46.21397832
-65.85846521 18.83123637 33.67837964
0.09212965349 49.25855653 29.13040146
-58.59360603 27.17977562 40.25652813
-63.75310541 82.41120802 29.35091427
43.05286365 71.02050304 47.98774043
-68.2874569 55.08960937 44.39937509
0.9590497827 65.86701256 51.74771257
-51.28173089 10.98660745 17.08514789
-57.10131554 84.00654332 27.04248381
-56.59622678 83.99953447 27.02752426
43.90648597 67.97305059 48.24215633
-33.62231816 82.82391429 42.12036242
-40.75119289 48.78710272 30.7391665
-22.40978909 54.4815693 31.05551758
-10.04778543 40.14176601 20.36568278
51.45228356 8.392169637 45.09120978
-69.31555788 54.10823637 44.33465237
-69.79487783 56.11111048 44.48180069
-50.76870017 84.35534663 21.78565191
-40.71626537 57.07269561 45.2534757
-38.36150134 40.13419785 31.67977768
-16.40349764 56.8732996 19.6250517
-49.88491833 84.30597664 22.19023235
-49.71528313 77.66681954 23.48373147
-55.67529619 66.19373715 41.27544024
-49.73805484 84.80178334 16.4065376
-58.20563158 62.4927361 43.9354258
48.86402848 66.03363745 40.67764062
-48.56800216 21.96821068 28.92454207
-55.63115589 18.44763074 30.35450319
21.48785211 75.01424803 52.35113877
-27.39180503 49.99697199 31.6263285
2.315648585 56.45639553 26.72156699
-62.05295452 83.52435083 27.75779264
-25.30217512 22.78954827 32.43284628
-27.92583885 76.192973 -4.348650722
28.34385068 31.80082907 22.47223776
-5.334105201 60.83449352 41.44931651
-25.01071133 88.30893202 35.07039616
47.65727937 46.02193508 51.80586873
-6.091657814 29.55704761 20.12690278
-49.85537345 12.72676101 2.438799005
-56.82973215 52.75212884 46.04293486
-49.94138614 85.25124191 11.22767012
-8.531231502 51.41754993 29.01832065
-67.66757749 10.80304947 22.34090296
-57.99035024 39.78441828 45.31025658
55.70215553 7.003156229 48.75213387
59.92588089 69.83664986 52.68634637
-49.49464327 85.39755438 9.443971429
-33.60497627 82.3129503 42.22205718
-2.652395683 68.85519023 41.0540516
-71.73654103 30.26916257 41.85895188
-36.46334779 37.04873933 26.34955559
-50.26566066 84.29981578 22.33436189
-12.33383365 83.97184425 18.90786726
5.417385248 61.96753188 61.20565507
-19.51534958 48.07847013 29.08904692
-71.08778511 11.2837192 23.23983315
6.172870371 64.01652195 60.58197091
-2.032526936 59.1557247 48.65477869
35.62128844 70.55039896 49.0356569
1.925735815 65.07538345 54.92652783
-10.14141048 59.20552863 26.31646389
-47.15622752 26.44500018 29.12523651
-68.81071156 54.1013498 44.3183194
-12.96925482 32.78232573 30.61024838
4.714439331 59.72153705 23.31980232
-70.89853185 73.24096748 38.11554096
-50.04526112 69.36513401 38.3457144
-57.03333134 30.50289001 42.16832383
42.35301862 68.48226868 19.32046841
-47.59041372 26.91845891 29.53842487
-11.10748808 32.38735838 23.18698452
-47.25452872 63.6652321 34.06243488
-13.61082429 31.70508186 31.58475095
-70.95133364 15.25166357 29.59078594
31.07352849 46.5147577 55.07408635
3.788444577 35.57526037 35.77851803
-39.3321799 53.61378979 32.7032553
-41.42509688 43.41110725 35.00975473
-12.93100626 74.73763697 21.33963334
14.44647251 70.37914086 55.06191134
31.24653511 30.81883568 21.66509239
-16.55408741 49.64568249 21.98425221
23.56646935 30.47408039 21.30525706
-55.9715503 7.501054509 11.82923896
20.24556515 46.52569675 57.01205572
5.700786893 58.43294961 61.39713302
-51.81540506 66.75587263 39.84029769
-71.98970261 28.35083673 40.86999395
-57.0732947 67.16004112 41.97776714
-46.56519499 49.79483812 37.62932742
-69.51958177 84.82635505 25.72038947
-46.5719449 13.05269388 -1.97103781
-67.24587745 67.8629304 41.58472881
-51.80392493 71.51780434 36.99789724
-39.05409403 63.31295065 30.76146154
-49.65858898 87.41478583 9.364305558
-44.26328373 48.40307147 35.86705913
-59.31061598 84.61971764 26.17227646
-40.58705469 59.05354735 45.54022545
-76.91506537 7.330349139 11.97832458
-46.51665554 46.87521597 42.37997163
-43.07950568 55.43827726 41.37111663
-58.29283696 34.89006683 43.91873701
-16.33209751 45.09474999 28.15933702
-70.56784083 52.63862246 44.15389832
-34.43315268 44.80341802 29.16667027
-39.47647334 45.98655088 39.70035729
43.61909455 68.0913868 23.6166629
-63.23721976 36.43785598 44.37371721
6.329685528 58.51215312 60.35774598
-52.19926195 87.13540023 13.09213962
-57.80120842 86.36846198 23.06364493
49.56943164 64.27026663 43.53368924
-56.18287678 82.28437577 29.37958758
-51.11901995 33.50610843 41.13672312
-74.82999304 55.51570074 40.70069574
-55.77490396 84.78122163 23.62313832
-44.68573791 55.20119759 38.60298726
-6.099468924 60.11703077 38.27082479
-12.93299577 62.43767743 24.2875389
-65.05121867 75.27355048 36.71127971
-66.17762559 79.10948977 33.18219512
-59.09205202 15.97269604 30.61216061
26.98190554 29.98342865 20.52311546
-5.0029219 50.8575713 29.01945063
32.62859527 73.58666053 49.31908663
55.68945218 57.56659679 56.09460876
48.76610562 68.85521029 48.72792594
-55.74038995 85.65581411 13.4636469
20.88015322 44.94671507 57.74108509
-64.51524046 55.92563117 45.62812163
-14.87650348 48.99100453 23.43766584
-42.78148071 22.69825642 25.17275421
-57.41008936 75.13573088 36.85398099
12.73610556 52.59039436 28.82720043
-49.77265678 83.86436163 15.64212983
-56.81419858 80.60063511 31.56615247
-28.85397304 76.86604356 -6.158503739
20.20539966 28.43954521 33.9113227
-69.69993675 86.02087694 23.54118386
-41.88688328 27.77136121 30.20289697
-7.576719041 44.89041469 28.86170723
-59.40658021 14.62470884 28.84063932
-62.210563 85.86049763 23.97471553
-37.92519888 65.81780166 24.77459847
-12.0123745 83.33039105 -2.840188038
-3.997155884 46.3258347 28.99558276
46.85591219 50.52489812 52.12478104
-62.22170942 80.7019519 31.42123795
-41.44291793 26.86508701 28.98564044
-53.25809922 65.73585881 40.30321124
-73.8930212 28.51805704 39.2917526
50.25099414 43.77680198 59.89445396
-71.60556238 83.13306809 28.29518712
-48.46640584 65.78985944 32.93588578
-8.975557703 39.99733622 21.83784921
45.16901899 68.92288899 48.62824817
3.546930022 50.9388738 55.5783325
-49.49213705 40.0071419 41.1040573
8.435119519 33.42765266 36.51680345
6.34257567 50.87329902 61.63306796
-12.3104145 39.93846546 17.33059945
-12.23691504 72.34720812 19.82390398
-69.70887567 85.42405865 24.64455009
-9.379100716 83.18473826 -7.478166896
-65.65333783 72.06843074 39.07363155
-52.91192587 85.92057433 15.67735325
-13.72556533 42.03028311 28.27697842
-61.48619333 85.25304742 25.06165843
-2.557264929 66.19701085 42.76079163
-40.58771151 62.53222407 45.94381888
-69.96892274 80.29195716 31.83155532
-69.32769326 21.20921991 35.8678187
50.97917572 55.67900379 55.59888015
-49.8167357 24.79374985 31.32160802
65.92320536 44.76154714 62.95395198
-68.04040667 51.5981421 44.09727507
-75.88808129 5.868880056 5.441707654
-70.1011751 68.96968709 40.93449354
9.870652278 48.84395662 61.21176654
-51.78598016 39.37358561 43.06965013
-49.48793845 48.39402926 42.79504278
-56.42273099 81.15315743 30.90405564
19.06568345 74.99719613 53.01101192
-62.08368387 22.02837732 36.63024746
-40.45281948 57.27337482 31.22032017
-58.39475064 19.63867694 34.53492701
-11.50524987 83.7683566 -8.021124227
1.221758665 39.06562854 30.7091039
-23.6021993 75.27562317 5.476073158
20.39667773 32.31122923 35.54263852
32.24760659 37.76610221 22.39821461
-69.71304214 34.20771374 42.36460932
-13.14221335 81.91624167 -9.514807556
10.24206723 70.72266805 57.70244362
19.68860026 73.42410191 53.6739211
23.75168635 30.9324211 21.77582257
-55.76843127 84.1766291 24.81381458
28.43356617 26.74673054 34.51366755
-54.37502461 59.52935367 42.64616652
-13.8651878 29.88074375 35.33158571
-39.49570511 63.03695954 45.70286982
-50.31027479 86.09486651 13.15789154
41.8384224 15.08092883 39.19632233
4.062962284 68.00168944 55.50809577
-58.02615907 58.88669066 44.97632048
-5.913372162 60.07271167 38.74982355
-67.85648975 80.25464063 31.8618992
56.23540533 70.51559841 51.33505256
48.53108742 44.12405824 56.19124575
-9.154669962 54.48516644 28.48592821
58.15488458 8.741099925 51.41562893
-48.5210473 66.31963884 32.6228811
-47.07274015 86.89661045 9.059885649
-71.94317654 69.0068651 40.85418477
-37.18844717 44.15485812 31.39443761
-9.050994487 70.75155963 26.08650119
-40.13335882 79.867273 36.89841968
-56.84117807 44.2202302 46.03672855
-68.38859071 49.1140331 43.86782155
1.478318652 60.31035549 57.88800759
-49.78158937 58.43781214 42.78829975
52.2043354 45.56177551 56.28058678
-12.83511115 80.21363984 21.84495771
30.64761687 30.37095994 21.15193719
-16.00371083 49.16316829 21.65402819
26.66044989 45.00423984 55.97095808
-37.92738242 67.22643978 25.90243839
13.66748837 31.28193127 37.12149575
25.69819332 71.10265863 50.34365845
59.70070241 51.47401652 56.13706681
-1.726722408 30.84407232 21.83368429
58.95671366 55.86131304 57.78777823
-69.84218105 87.24572995 21.0026044
3.597072951 51.36623998 56.43423252
-51.52289653 87.04199243 14.04749874
27.99560301 25.21323043 34.91925561
38.63442479 51.63779399 52.42664242
-49.15498789 44.30745861 43.55831458
25.29479052 75.07610114 50.90709654
-68.24383648 74.79603861 37.0367965
-62.90652407 70.95445313 39.82838114
32.68286578 41.41079973 44.09795257
-55.82031182 25.43206488 36.70978192
-16.43254794 61.46411263 24.6034934
-49.46254174 49.83165154 37.75451174
-67.52344093 74.77953163 37.09103786
-37.90667218 66.50889489 22.57499126
58.22675961 9.15750354 52.39464591
-71.43064834 86.05147151 23.51607679
-49.75356616 11.5108461 16.53461364
53.22931135 52.71998433 54.56037774
18.92271166 33.90386391 23.16190596
-49.48214105 64.10180977 35.24578277
58.77895145 5.660777499 52.09540117
-64.77365265 85.91453202 23.83624135
9.397245733 32.38388622 36.796841
-64.26447722 85.90546168 23.84443367
-56.05793068 81.71913183 30.09088301
-61.29435893 85.84432825 23.98769589
-41.72300376 50.84018654 30.39736482
-66.356305 34.0630543 43.40377188
17.76574582 73.90517008 54.28267152
28.36020052 44.10872046 54.38936589
-58.1177652 85.77815158 24.15012742
20.34949956 33.87791373 23.19105753
-69.64422819 12.55773528 25.65475272
-28.42448869 46.50683054 31.55289022
-49.69357162 85.1824488 17.80565306
16.80991383 34.9334076 23.26641635
-49.15054394 71.66600185 34.77149643
-49.60029881 24.85801521 30.53429278
-56.18075893 83.47930338 21.33423146
24.93852621 73.50138879 51.77552593
-67.73625998 59.06468094 44.76185382
-55.80478985 17.90911351 30.81247644
-59.3466837 51.27624122 46.17619058
-28.063638 55.09786476 30.80597387
-39.99163298 44.84554073 41.39099841
-49.69286863 24.68171053 32.59862021
-65.2694244 21.14429167 35.84761687
-16.14457526 72.86315709 20.40624705
-55.97143365 27.62293136 40.4386298
-20.87339845 45.43477613 30.90476009
-55.83532402 86.98069118 9.754892596
-60.50331274 12.41908685 25.52105744
-67.13177965 32.65025307 42.47256455
-10.77148721 31.44882339 22.36482442
42.43016158 68.02398899 47.93237
8.285252582 38.29575228 32.47269809
-69.96762627 73.75676486 37.77690006
-77.84367232 8.574477791 15.19258532
39.92808488 15.25168976 37.5783173
-30.99027375 52.58881692 31.35775496
-51.17828869 23.48502656 35.12059815
-14.82392804 77.20008107 22.24798131
-55.81481568 88.75620934 12.4460349
34.80479375 42.84483377 50.35253905
-54.48965083 29.70199287 39.32734498
-49.56890088 78.85184788 27.17906488
-3.307236093 38.43791166 27.20652367
38.56010605 72.06125865 48.41596741
-52.04629238 86.45690869 15.11275179
-73.98215276 48.70787428 43.82289449
-51.6550641 43.3252744 43.78372344
7.442972527 55.29376157 27.58717742
-68.26296484 72.64815651 38.66806403
-10.3211042 37.95532189 28.3197239
-51.61565369 69.91618457 38.07484154
3.56522625 57.44327698 20.85346483
-68.94161014 8.283760307 16.86976965
25.37779626 74.53201362 51.38079095
-11.60556907 58.77289194 25.79140917
-49.61286823 86.9390621 9.051493621
26.88937816 36.31113301 22.83048261
-55.82850691 90.25644596 0.8594433999
-68.86625062 24.49196157 38.45784455
5.761363709 42.44019289 25.62881279
-40.03435116 66.04115507 45.89054399
-52.17477293 77.47703004 31.98262817
-46.53981741 73.13175232 34.73803671
-60.59269816 77.36247302 34.91783902
-77.74692048 9.691160312 13.86415006
-60.26394908 64.08373621 43.33839151
-55.52712536 63.15525329 41.56027609
-20.95972605 49.81816361 26.6487889
-76.80940374 10.79922021 18.30211538
52.32879149 8.823907075 45.73877219
-11.03836772 80.6948489 15.91608103
-12.90582279 81.41866987 -9.610352933
-27.48829668 55.59480044 30.7541016
-24.85327205 47.94646087 31.63948098
-19.28133038 74.35967096 15.28510085
5.022905328 33.48879664 36.45772923
43.7565087 44.37687745 54.16687633
-37.8355516 42.10075211 32.05684248
-16.2026124 74.80218607 21.2142152
-71.09106911 40.19849243 43.001767
28.74119298 74.05522378 50.44765443
-8.753269921 56.5393265 27.86975308
-59.64832405 36.86413621 44.56736215
-46.93273386 64.20334449 33.58090616
-49.76290488 83.80988667 16.27265531
-75.68479296 6.392438416 5.151732656
-20.35600412 57.64818942 28.863256
-12.57827525 81.37733078 -9.192923839
-71.08475027 86.65175882 22.30817948
-55.80141456 87.44768297 15.98052711
-50.59962072 52.83052721 43.94470935
-0.7602280056 59.31347543 52.40749959
-45.12780107 47.26484663 37.59199089
-40.70648976 57.56998042 45.30540737
54.36850194 51.15901693 54.98405347
35.14101866 36.46667408 25.27785233
-55.97362359 85.12924247 19.62095479
24.91461628 35.31173735 23.15551408
-59.01577278 75.70458358 36.38317795
-37.81702797 63.80392744 24.82592773
4.850969821 57.90102573 61.9075181
-63.44525429 80.72448941 31.39294347
-59.64899719 85.80723192 24.1045568
-42.99346578 46.60276532 39.0442428
-63.23833321 21.11084048 35.84860138
54.22944002 53.51724488 56.76774705
19.21745168 73.41398995 53.88115895
-49.4683743 40.42303746 42.09814744
-60.38521417 86.42065839 22.95049634
-8.719503974 62.40687769 35.49483012
-58.38216472 11.09372672 23.02236646
-67.21849399 87.18590559 21.19673637
6.531799148 52.74675743 28.19522218
38.33173467 30.71367122 21.53472819
-49.6137705 76.5682836 30.39033953
-53.56758285 82.60045306 25.21157873
10.67954216 44.09902235 28.74054765
-0.5993449604 51.03487805 43.60128099
65.08233412 47.64724135 58.7482705
-37.21581363 53.6859895 31.46150452
-49.80035398 84.02700036 13.7593825
-55.79917063 86.59744506 20.02364119
33.18329897 40.64948582 41.18712219
-66.99849061 55.03407063 44.79829579
-67.28797041 55.55842281 44.59307933
-11.41840299 82.38654335 8.003412764
-54.08650487 87.39362273 10.45441251
34.93223739 36.52243617 24.67034104
-31.47047309 85.9906246 39.90835325
-39.81958081 44.98175128 39.77695535
-55.79836988 19.36296738 31.41377812
-49.62628557 82.89186432 21.07703447
-32.7137096 83.36334812 41.51158445
-37.17621615 62.78596691 24.86766806
-14.66954374 34.61487407 38.79415089
-49.48147615 35.97737697 35.44270755
-49.51588041 66.33257515 38.48906944
-75.99668027 9.97948575 16.00991787
-45.74368142 34.01568916 34.19602764
-52.09585921 49.82865925 44.11807985
-76.68061989 6.883314221 11.296481
-57.98228756 26.21385952 39.6997231
45.69764101 68.41418189 48.60623909
-54.93308876 37.46558039 42.51277613
-73.41573445 52.18544047 44.13123514
-10.58706382 50.44666058 29.02789061
-48.19406166 74.69236635 34.41668694
30.46697669 74.64083141 49.14701713
-54.14882092 64.18297006 41.02022059
17.89972657 50.70901182 32.20300639
12.64262165 69.35547533 55.63609139
-39.92751079 54.64597466 32.48771498
-7.896037798 40.68021502 25.35790511
-47.21810641 34.51832975 34.46883146
7.422951728 35.97853233 36.23056145
-16.27340222 71.42149459 19.68676272
11.63454993 34.81943753 37.22972112
-70.50173005 80.30055947 31.83330306
-59.80827578 74.1021493 37.65661714
-25.46338707 54.53525759 31.01460414
53.37793608 64.23887729 48.99841676
30.23270113 74.63512029 49.25799364
-33.90433024 49.60837434 31.55279029
-55.2876155 23.07349449 34.85499513
44.56796549 12.90928656 40.57922703
-17.23820336 45.05626971 28.77884416
-57.25742854 63.51441289 43.54750972
-5.94065095 53.29656746 30.0178383
-70.3061096 53.13269983 44.19501477
-52.39652786 37.42492817 42.50095932
58.67445527 51.49614041 56.07594802
-39.31801003 46.20394977 37.14641209
-55.884558 21.21708545 33.2128228
-0.06835172531 28.98977248 19.73692498
-37.82955291 46.56811464 32.63506202
-36.39834701 79.74906708 43.38499308
-20.98086695 89.58488641 31.14295209
-60.08259 67.21615919 41.90019828
-50.28040042 58.9438703 42.83537454
-65.5000014 86.53536949 22.59426675
-38.32195311 71.66806414 44.33576191
51.78000443 68.61093612 50.98887058
-49.67604299 11.22876274 2.314534065
-65.74381844 50.3767756 46.18453414
-49.46356077 72.63010954 35.29240676
-57.10907263 60.92849599 44.40522371
-57.1279319 29.54617835 41.63929002
-37.24444518 79.8785393 42.0433408
3.295746012 56.71771891 23.50102349
-28.12107607 88.14347725 37.58428158
40.48907989 49.98847346 53.73954246
20.31218911 28.91267013 34.22521892
-38.04150788 49.11177995 32.27996422
-50.43495905 72.56262079 36.26111707
35.02877356 36.16974619 22.91957248
-39.25844397 55.66072099 43.88676937
-67.47438043 87.1910959 21.18528262
-45.62903444 49.7809908 37.61154575
-36.63865171 62.24797104 25.18395915
-62.64955045 87.08637288 21.48085987
-38.57725699 40.11914455 31.89567339
24.1796716 31.86962704 22.46771466
14.80565726 47.474343 58.69018279
-50.20506927 13.93422967 23.44815939
-13.43841137 27.41454763 34.74639671
14.17236933 31.27502723 37.10535898
-61.32680289 28.17538397 40.87329873
-18.21533761 44.12352056 28.13994045
-6.420083221 83.3026495 -9.411269297
-6.626481659 52.91731177 28.72466607
-55.07584964 82.04139922 26.16247848
-9.960031919 58.18393539 26.48799206
-71.15495078 16.6132459 31.30329849
22.96622327 72.50075274 52.11448778
-2.468900462 39.31678723 28.49730104
-39.29576931 55.16494446 43.82258237
-68.60942599 12.5436848 25.62051802
1.029170349 66.38341262 51.5662323
-44.98271145 46.76377504 37.55448526
-59.02693942 87.00581863 21.72513877
-65.49614492 11.20102038 23.13350197
45.70112519 66.12914593 28.51880939
-18.56246263 57.3314634 26.37136242
-51.7581224 10.19770889 14.68082673
-47.94727291 31.17241611 32.6632088
1.535360055 65.17319351 53.86552408
59.17517422 68.31224322 53.04601275
7.066067328 64.56373003 59.8858829
-68.58751528 38.16562623 42.81675512
18.6784041 31.33111754 35.59489367
-72.86425971 29.35042973 41.08605402
-72.69002299 60.70392434 44.156955
-7.707904666 61.69170391 37.77753078
33.18284433 72.55042115 49.58952918
-42.49586732 49.71767242 31.9224361
-48.25727565 88.00301351 8.095131185
-65.39630444 63.65553766 43.46136289
-72.44768161 49.68159203 43.87991714
-71.55688225 42.19455316 43.22543424
30.41266538 42.90472941 50.49483208
24.93034371 73.00330035 51.73261957
-12.53104492 83.08981358 -5.775116908
-40.0553206 53.12946552 32.63691135
-30.53588893 77.21891355 -9.934125242
15.35217139 35.391395 35.70768933
-68.29109359 81.94335385 29.82088758
-14.4864668 30.11887974 21.03232686
-38.16560076 68.12323716 44.670397
-32.69549537 84.93514176 40.74150578
-15.18151116 54.58917558 28.42786892
-12.07825552 53.00378986 28.76045132
-56.86811451 14.58309576 28.83961364
-21.75053867 43.42237601 31.12688174
-16.35061609 76.25960398 21.80359242
-53.58968933 10.20484545 14.94727117
-40.80739517 64.73769912 32.03622942
-16.10897631 25.84177496 24.38037641
-49.45879975 71.62327584 35.32627747
-40.08645587 65.03822891 45.88989771
-10.348317 61.4740909 29.15400069
61.57116662 51.87514104 56.95045293
-38.59184211 68.52315567 28.45585351
5.526825722 53.26036602 28.25117425
-4.2457742 59.67750852 43.01960991
-6.014173022 50.87213058 29.04328747
10.16830068 57.34019652 26.61749394
-55.79936814 17.08778043 28.69279412
-68.36221502 76.9637291 35.20180209
1.333392534 58.0125323 26.32404038
-35.89061274 38.97930424 27.13548886
22.36843842 49.29059914 41.99012654
-38.30110556 78.48369036 40.95735391
-67.11698434 14.29525745 28.30893075
51.59886577 56.71772883 55.07570492
-13.78300006 38.56852122 27.68810372
-21.55872915 57.56111314 30.10346817
-58.25115837 80.06755574 32.20192286
-46.66452142 44.76749648 43.56955327
-14.05120987 80.5859701 23.5812348
-41.0338491 54.75805552 31.39758377
-35.90328925 63.30267975 24.45317432
-43.02102718 36.392244 35.22122284
-11.0472674 81.17491853 -7.135146767
-32.49969621 52.10467961 31.43918921
-4.511217745 51.86174623 28.92176599
-21.24233284 20.56629512 34.16119192
2.097622201 65.59000438 54.74629362
-61.00007561 22.47953417 37.01287992
20.33134289 50.22574086 37.34944547
-50.13810586 77.44076615 32.01520126
-46.8078053 13.48805362 -1.153390373
-55.84654209 20.57180337 34.86983761
-56.44058209 88.88510863 -0.5842685328
29.46530744 35.74840576 23.04514525
-14.05876186 31.82526951 24.44830731
-64.41503013 47.33740856 46.25447166
-62.25469099 21.09781932 35.81217403
-19.72836583 27.80660792 37.22124811
54.070607 45.74201426 59.65897601
-39.17588479 58.11776597 44.48105813
-60.15086147 84.0581876 27.02452604
3.270156623 64.19232091 59.09473299
-56.57847412 37.3044658 44.69689709
-50.79593348 38.86750669 42.92913485
-55.77181441 87.98452318 15.5694901
16.32746778 36.43564263 23.39930386
-40.07201769 53.65342211 44.03754953
-75.12731527 8.324969769 11.7443895
-25.23994289 75.12877267 1.666506924
-55.79166148 32.45529576 42.57314268
-42.32947568 32.52122606 33.41381373
-47.85295721 33.08414781 33.75900922
-71.54006351 64.2835742 43.16894894
-46.50853717 71.41683237 31.3335496
-7.600978274 69.46168155 29.13053922
62.41511154 47.72236786 52.55816059
-25.30762577 88.78747884 35.39833977
-68.3894573 20.72927643 35.43378473
-55.80092998 12.07213803 22.82527872
39.4834994 49.49795623 53.79895387
-5.514334118 47.85447382 29.01928191
-59.99347221 71.43671128 39.50108088
-42.25378639 47.10352466 38.91664247
-15.1870247 25.58158667 33.05165245
-52.64521391 53.37350983 43.85811867
-55.63904157 47.37045523 44.19720777
-63.22455266 68.31885429 41.35178169
-46.70095523 71.16700593 34.27041293
-16.33526542 46.10078007 28.13449162
-19.91164056 89.18753313 29.72516991
-38.35763771 54.09518616 32.75564481
-55.82443285 89.85794517 5.484755229
-36.19374566 62.77353682 24.82460254
-72.73438435 19.41976976 33.98417457
-18.2674811 53.61759478 28.64191911
-53.66518411 44.35257892 43.89464656
-9.116129583 29.59604345 20.25099551
-37.89739413 66.25643612 25.50394798
-61.65647915 37.38823352 44.69286619
-48.62908282 46.29881326 43.64748687
-68.29555199 9.135739216 18.50945157
-54.96380943 67.86181718 39.25538746
-58.38470763 15.51011228 30.02063466
54.47707489 48.83428778 46.99065392
-48.41043388 39.39861305 36.30873444
0.9330533292 66.9836697 50.44298079
-54.56277852 10.89486317 18.77589822
-65.43764082 47.35769808 46.21395413
8.314351463 65.65703435 58.60923477
8.921211746 37.7865853 32.43555792
-38.4078187 56.1293636 32.45756185
-55.92970957 87.61817057 19.85249079
25.75996806 70.38917698 46.96121099
-44.34533066 49.49844706 34.82006838
3.53973855 58.64066999 24.43803686
-47.07599184 21.95891536 28.74791353
52.7872208 41.27479396 59.32271091
-67.33249973 9.120715551 18.50019739
27.47520321 34.7567494 23.28325909
26.89238933 73.01706749 51.19862656
-52.09286248 50.83157962 44.12815847
-46.55904882 21.21405108 25.64297536
50.80368258 44.82454751 59.27933986
-72.12343464 63.77830014 43.31915042
-48.02664068 86.90701761 9.120986299
-15.58912447 25.38797655 35.37590322
-49.48518344 37.74033263 38.28430263
-32.8800483 55.30984089 29.26371834
-55.91776694 41.73890087 45.53250573
58.04300165 47.71595071 47.6398015
-13.22788968 40.02960961 16.44749957
-50.32298669 80.79510229 28.07072191
7.866071304 69.17567666 58.63424286
6.043825256 35.45344625 36.7625107
-26.91184 54.55974757 31.00653528
-11.93857487 83.81349845 -8.462526152
-48.10079773 87.44664148 8.697421595
-47.57991716 36.46192743 35.28169433
1.026717418 51.56594777 48.77941879
-8.744962944 61.111295 33.0598317
7.865119839 50.83621225 61.77323995
-69.80361445 55.61330157 44.43575509
-60.97379172 56.37584559 45.55296936
-54.35839283 39.90976109 43.16254929
-49.82618244 83.12324868 24.25571891
0.2519520147 38.07962735 30.68699151
56.10148302 58.10874617 55.54900707
-42.09740908 26.37091095 29.02057132
-34.94280258 47.3114189 29.28236342
-53.85551847 86.04967102 14.3586532
-41.77771699 55.22760325 43.56851576
-11.10476612 81.88144214 13.8071847
-55.76874713 34.40304522 43.26443928
-64.92907644 23.48368638 37.75851411
-65.21568671 67.82993344 41.58061207
25.31937539 69.27822683 42.46203141
61.86286581 44.85229777 62.67478347
-49.48963169 38.92711248 41.98806758
-69.96334183 46.15177011 43.59640134
-18.36803531 47.13951011 28.11709841
5.611703895 65.55846677 60.26889049
2.059979092 56.96369242 26.70789306
-20.7850523 53.09689608 29.34004482
50.4678183 65.97461492 46.88360674
-70.90783903 82.55144831 29.08735322
-46.5645229 17.6765224 25.92374774
-42.9233005 37.84959474 35.76451747
-20.93447308 58.6966463 28.45560261
-20.88768746 74.69920385 11.64989655
-59.79963723 84.63101031 26.1344421
-31.99140383 82.89321723 41.00481792
17.27717495 71.89498255 54.40500535
33.96453024 32.19330871 22.67079126
-70.97470747 10.00497547 20.58293591
-45.92024157 32.09588922 33.20957387
-12.54899709 81.83999985 -8.742866642
-49.71058405 12.61748549 15.33302061
-50.93142825 36.42477566 42.17877445
0.9606061583 38.59603709 30.38362657
20.36693819 34.87354903 23.28295253
-63.67396205 87.10757085 21.43013981
-48.75548214 55.40819267 36.97616167
-58.24277396 69.2880591 40.8037715
-52.65148632 81.98301049 26.37795691
60.846061 51.89402397 56.86952805
-63.97565424 29.17800071 41.3926256
-55.83699916 89.41162381 4.841734752
-56.8622185 43.22641181 45.92442851
-75.34892918 6.675675322 7.626301988
-11.83752113 77.26700918 20.90150498
-27.47055613 54.05442545 31.15259284
1.229996728 60.36128565 57.34412954
-39.77526862 70.10163644 45.31723135
-12.77440003 51.56336019 28.13484635
-49.65717361 10.14047865 3.291249952
-70.85465153 54.14128244 44.24454381
-70.58400628 40.19048777 42.99799098
-53.73863831 65.74287274 40.3134301
50.85314768 51.89829427 52.89902229
17.5910405 50.26339824 31.60824135
-39.36899596 51.58633044 32.9399407
-45.25674485 54.78879795 37.67267663
25.34790677 37.38697735 22.2885459
15.32254412 74.45049984 54.24465983
-55.95243411 67.15213498 41.85579257
-16.9141512 48.4016578 24.84118372
-12.09348479 42.50210239 28.3151493
-63.04076702 71.48722 39.49587626
-25.48183722 55.04678062 30.9066309
-40.96221273 70.08573421 28.24826794
-7.103122654 61.23130109 37.18024612
16.88641245 36.87890482 23.97363976
-26.88163441 49.4851836 31.64360808
-13.49921808 60.03877798 23.11049175
-62.76933231 80.15386713 32.0616002
-59.8657458 52.29473086 46.1050896
56.707891 57.52363388 56.39913117
-44.17441342 34.46963794 34.45363103
-55.78267811 87.79178044 17.80906064
-39.85493603 58.08863933 44.94868057
5.179424776 53.26216426 28.29655033
11.74448598 69.74085702 57.16022041
-27.84071425 88.15071842 37.44675407
-72.08454225 14.37286685 28.35532729
-37.10593563 42.40170608 28.42400644
-64.90622932 74.19597988 37.53957369
-66.00931536 86.54450971 22.58528962
-10.73932683 41.98579584 28.22392557
62.10043001 57.30598022 57.89742448
-70.59175657 65.30519858 42.78162147
-39.48498733 40.53414404 33.0778071
-21.34936133 52.43333921 31.32407384
-9.145745051 80.54355508 -5.995091508
-62.53311125 85.27320957 25.02725501
28.47171708 33.74519214 23.18283104
-9.189267054 69.81687791 25.31005622
-46.56451807 74.81191647 32.71809793
-49.73945478 86.75513898 11.21074856
29.40553343 48.62169101 54.23992692
-40.03262982 63.73888945 31.83010683
-55.77539315 23.87798014 37.26224294
-11.18036052 50.9756033 28.82732361
-49.7372235 14.37389016 24.08167955
-49.59478806 77.16003552 29.34387199
-49.55836926 68.96419981 37.08055697
40.61504095 71.01134767 48.55893012
-13.70197083 78.64871411 22.69715048
34.48919078 47.02842394 54.28632718
-46.56286495 73.95531764 31.00852497
-52.76188795 57.45009318 43.16954145
3.814322249 66.3231313 57.56151845
-65.5768799 40.89912763 45.46997541
41.98691866 51.48288611 53.58558565
-49.74382251 12.78291945 13.41888091
11.20110477 30.83452287 36.95905217
-17.34031826 82.26510754 22.19575707
-68.29482774 61.11991982 44.31622669
-8.031291001 51.91535409 28.97074591
-3.144957993 51.62520932 37.23374738
-60.52139844 74.11498754 37.64357827
-23.56794615 20.67398173 33.35459543
-54.87883194 59.53571613 42.66838556
5.768756979 49.55699711 59.54316879
53.6199999 61.87158774 41.47363187
-7.379860549 67.89304838 29.81828692
-46.56085514 21.66762257 26.20457187
44.15795143 69.95417665 48.50240672
54.42345169 51.6087362 55.57954566
-37.8137069 48.06676934 32.71448527
-55.80114657 86.12100363 19.72825683
-0.08553816191 68.30369807 46.96666083
-49.49256573 37.84316798 37.09192249
-65.79379274 49.87499079 46.19250786
-66.00174476 75.83402716 36.21276913
0.4541047205 60.0026827 55.82838038
-68.40874612 19.33146984 34.18429936
-49.20155791 46.84203055 43.27723927
4.442475223 54.405741 61.77475014
-66.35076345 47.89930693 45.92734207
-53.63256121 51.86936297 44.02772349
-14.14941673 58.71144954 21.16316283
40.51826568 68.54908494 48.02792394
-21.66959354 89.04603547 31.70303058
-21.61328432 18.69499839 32.64874893
5.392435546 59.37286912 21.4114549
-60.23866221 62.52716562 43.92345396
-71.85524611 61.18149048 44.28046268
36.63070402 70.53676559 49.00142135
-70.97780636 63.23090629 43.62857875
-66.18002197 40.42860471 45.22053078
-56.97539431 89.70186045 13.34283792
-40.55538057 41.4590798 34.1978901
-11.60523179 47.0235304 28.17376838
-36.98237108 70.74841779 25.62301407
-55.14913115 46.86888692 44.09970898
-60.36142037 46.27039851 46.21477349
-59.73794228 24.34728375 38.39654708
-55.44241485 67.86785569 39.27656095
55.70226751 68.42878084 52.35546302
-13.28607497 45.54709909 28.15388475
-40.08932124 65.54189035 45.87021552
-55.43021196 37.46857109 42.57286222
4.108495273 65.12075066 59.81024854
6.4135856 67.63360822 59.3327739
12.71025315 53.60786743 28.6738425
2.437346204 59.09470716 60.1639668
19.02242075 27.88984786 34.69157118
-48.59575209 13.23779678 2.092746312
7.33911837 51.34359743 61.810095
-49.67874545 64.13331044 40.74423546
39.64541317 17.20492219 38.2642407
-46.95172783 27.84849025 30.27342296
-18.83869138 60.3849892 25.93633022
-73.08518081 38.24159233 42.79261694
13.11459209 71.77714141 56.56682113
-7.507527435 33.29788411 23.58368512
33.8188532 49.61748225 53.49169051
-36.28930741 32.28567597 23.34307134
-50.3455435 30.59819625 39.78653777
30.25383895 73.5456919 50.24756078
-73.99685539 47.71085883 43.74649948
-8.200341629 41.94392071 28.22584292
-40.6632936 55.77378708 31.18884516
-55.5445831 29.23637069 39.10716806
-42.52550869 50.95432749 35.05201748
-49.49769035 48.44850422 42.1645173
-54.87423209 85.54100081 14.63130213
-17.24243518 79.77898475 21.90461341
-13.72847163 26.8975938 34.97624946
-37.65922749 56.17296714 31.80861919
-70.44802708 74.84247465 36.91808541
20.27641471 33.35154901 35.14206391
-13.67261661 58.09260533 22.42958871
56.81684139 4.927556136 49.32807379
-55.78403627 79.16899959 30.50924145
41.51274017 71.55628462 47.88834842
26.07336469 68.89122489 40.98420098
-76.82693469 5.87835577 11.33739347
-46.2312579 42.80597402 37.12450602
27.61117811 68.12694896 37.90992117
-12.6090476 82.84168669 -8.706444706
-49.71747385 87.29582263 10.75654704
22.51138873 74.49127541 52.40035116
33.52924004 47.51932125 54.59734229
-36.63254548 74.64682326 44.39381724
9.802288943 49.35588005 61.10866394
-74.63568575 40.75430807 43.05207387
-23.46044147 22.12640005 33.95328453
-13.42471116 58.56887914 22.68001864
-58.75258481 22.91347177 37.37344654
33.49057347 48.54325829 54.37138949
47.51930998 51.41768592 53.28741677
-50.16806756 57.91993975 43.04720335
-14.69541335 57.83377336 19.80271127
-56.25054115 63.50205485 43.49895139
24.92609089 48.76852824 47.56294773
33.13794386 73.57750284 49.32825996
-37.48647577 44.58441125 32.29134821
-64.4385144 52.87363126 46.08348656
-67.56802073 26.84371859 40.04255716
-15.80208963 70.92930779 19.48390327
49.11002651 50.95549454 52.52287136
-49.51885657 76.11730053 29.7809385
2.555628611 66.61117783 54.4577041
37.3037302 21.46233246 35.90081484
-60.90173609 74.66039429 37.21127223
-10.51990473 83.21509361 -7.612995801
-49.75030924 86.24027947 17.18968443
-63.47315337 16.95712768 31.67294393
3.461288442 50.5294146 54.52132508
-66.5923611 12.51730905 25.54204058
7.713327922 41.41744667 25.47606414
-55.69057994 11.28459731 20.29329956
-49.75903007 11.83571443 12.76434645
-46.80323742 23.24698246 25.39635592
-70.80572302 76.46761453 35.60041288
-45.13556212 52.439589 35.78769359
-12.98417505 60.42985239 24.29903392
47.23546239 68.88399363 48.68569076
-64.36040617 34.5000159 43.77721384
20.30649267 73.43452384 53.43509985
-69.79157459 60.62938842 44.46947099
-60.04601879 31.03815739 42.35572796
-50.5762447 86.91135254 15.38353
-17.77445873 31.78026403 37.33265329
-37.61388917 76.65516012 44.57328903
17.66239746 32.25339633 36.73544884
-69.40347828 49.13042676 43.87105714
-34.94327158 53.85285274 29.09104643
41.90090781 15.57640252 39.2592267
41.92877677 46.99134208 41.64474299
-53.38146853 67.3047394 39.59395012
-55.80569639 90.20204263 7.313288762
-44.26783518 49.53368873 34.39617839
-12.14028554 80.76180311 21.17561174
-7.640440358 76.85098531 -4.202603286
-46.64946445 17.64503682 26.30545423
44.55954615 67.48829429 47.91837773
-65.72157068 37.4566034 44.67441617
-35.82089087 35.13701725 25.11313662
-73.3393765 29.39187521 40.69553218
-65.48749724 80.763566 31.3287818
47.56401863 43.61411413 56.46883416
-63.39711573 33.02777972 43.20437788
-78.66111622 5.41603201 17.05417542
-53.54021829 9.348903168 13.22095733
-59.67270725 61.48502132 44.2602129
-72.62554486 28.37424728 40.71948609
-12.2719748 46.03331865 28.14273912
52.24569459 67.07998667 51.19251656
-39.92636554 45.29005534 42.04494254
-61.70388368 68.29510237 41.33751059
-37.76346138 44.07325096 32.45145188
-49.83112495 13.79329469 1.706355032
-46.81854198 12.99125361 -1.210766658
-39.34489026 52.08877549 32.92923563
-8.782364687 77.86155999 -4.06303804
-62.58800095 75.77211158 36.28050894
-60.47711351 32.01227268 42.78298093
-67.56784497 46.1079428 43.64834512
-15.63657735 72.85468073 20.40776849
13.0897231 52.58546021 28.81704266
-67.98636866 12.9793838 26.27042625
38.70785106 42.78386702 50.31594915
-64.28458815 29.18517246 41.36828618
-64.25282715 61.56541399 44.20041236
-42.90165056 49.15998257 32.64725141
-38.4684237 41.0934545 32.2176991
15.77772058 52.54275076 28.80022786
-4.035002396 44.83827795 28.79152197
-8.538249778 49.90205324 29.13272811
-71.77916788 75.94849784 35.98569917
-17.30489091 26.76901313 37.15095265
-65.03867301 41.37762866 45.6391879
-1.994855563 51.3042892 29.08660786
-38.6196997 43.05473912 32.78508876
-62.56171435 83.53321153 27.75195475
38.65999877 17.75822011 37.85571987
-37.67238336 73.09482124 45.12897015
-57.30119639 48.72444792 46.27597842
55.86755161 45.64084226 60.49076642
-64.9110745 51.87218821 46.14580659
-55.64069154 10.55233376 17.1311529
-11.01166757 81.73536801 15.48516563
-37.39802114 46.69732235 31.05282871
-37.30192027 59.62546929 26.62115845
3.201848595 51.46649587 55.34576044
-13.03809497 55.63977332 27.47628292
-74.56165895 36.14080706 38.32843362
-6.578836608 80.09687865 -7.125911372
-71.73515481 81.44401319 30.44776083
-70.01790882 43.16599978 43.3079632
13.70005493 29.83318885 36.45342159
-11.50985426 69.28926492 20.22424021
9.596893147 50.9413603 60.22234484
-18.71243688 46.56324576 29.04583422
-52.60393752 47.32609603 44.13334916
-67.5344849 80.80503602 31.23774041
-62.02778199 81.25501013 30.79059201
-54.54739297 8.679086184 9.535473601
-42.21886369 19.14005684 19.75850929
-12.34399454 48.12806816 27.14565607
52.33715558 45.05450419 56.31739193
-71.92492803 84.29860122 26.47901435
-20.86785919 55.02364209 30.29532651
-37.54895509 41.22430547 30.52333703
-47.13964283 23.6835055 26.21967343
-65.43174089 23.48947232 37.78720783
24.73616636 44.98381288 56.57506158
28.5311081 48.61678549 54.46363199
-11.69231458 69.33174965 19.76584269
6.662703704 57.01096205 60.2412362
-65.96808081 47.37749513 46.08529356
-13.66640092 31.88178411 29.54406074
-63.62740957 18.79602636 33.66164744
-49.38866063 65.78430319 33.17626632
38.98014981 50.52090876 53.6730479
-17.73823676 27.74426399 37.56545247
15.27391447 45.01587223 28.87419257
24.90632082 69.99546339 45.8678803
-4.194090135 52.86153222 34.73496599
-45.45732426 18.840996 23.84782032
40.14032666 33.55797532 23.13090661
-74.0590854 44.22673802 43.41809801
-41.02967607 56.57990962 45.20722356
-2.885830395 60.69636823 48.41251699
-12.19680558 39.21881336 31.48982359
-41.09201765 21.42959976 22.09813276
-57.6721877 61.96920774 44.08455336
-65.72353631 81.8870658 29.98467186
-46.93987384 32.1146763 33.18592973
-58.349816 22.90852156 37.35410169
-14.8916453 39.81674604 30.88914747
20.77740479 43.49189751 57.16934112
-53.41221743 87.30126314 11.3979995
44.50779009 46.26600305 43.74653394
17.20651482 71.42841038 54.00814997
-20.43266765 55.55410827 29.88093514
-55.71821423 55.82417631 45.12823791
-74.61405656 41.74782947 43.16770935
-40.8930646 41.91529569 40.61948427
23.86535473 70.02501212 45.72337552
-53.25038654 74.76519773 34.53547871
-49.59945525 75.83584569 33.06364288
-26.88096921 47.97819998 31.6577243
-46.50151721 20.91247338 29.13293825
57.9865764 9.204328198 51.8968754
-39.8085427 51.59744401 32.89475047
52.71383209 69.10048163 50.95442334
-64.45954533 54.90549315 45.80672512
-43.0479099 42.20939635 37.61629071
-68.40878539 73.7261464 37.83506119
-45.11281463 24.14203184 26.33687772
-12.70919122 44.48705292 28.69637734
-55.72443071 57.35873808 44.795033
8.913029244 37.28849686 32.39265156
-66.01853565 43.88743444 45.8236009
21.6015971 43.10664908 55.65776647
-11.54418288 58.23971021 26.14262427
-15.11281951 26.08926734 27.14397246
-55.8377007 13.3000005 26.05829058
-51.6322141 85.74581467 17.46204247
-39.92175103 57.09313365 44.86469734
-42.79862185 33.4897582 33.91313664
26.66214249 47.0494169 55.53529081
26.7090077 70.36883293 47.01639206
31.0376462 29.91977573 20.48859085
-55.81178246 89.05867245 14.76088519
36.01529446 44.96162965 48.85494128
-57.51682555 86.97500221 21.79489047
-73.85133465 58.68620478 44.49502189
-49.61551316 83.48109878 14.23471537
4.351486508 66.20083084 58.8788313
-16.2524002 43.54789934 28.62118698
-48.07515998 73.12573941 35.10063796
-49.98143642 11.37801463 18.12007483
-15.9507647 48.40458445 18.79684365
54.2149462 67.48083449 51.99027534
-11.95066064 83.35067367 20.21915463
-66.86546569 58.02981928 44.95593581
24.77354367 68.54913459 39.3766773
-13.35107382 82.35037047 -8.688019499
-49.64366414 76.68247459 29.07042787
-49.79709324 13.93904166 23.31449483
-62.43890159 44.80821373 46.10514707
-19.08238369 60.8862443 25.99050961
-55.96973746 20.08217642 34.75063779
-46.3952942 71.98581328 30.53344852
-49.84443373 23.4811777 34.91090436
-49.47872763 54.86197662 37.62832273
-31.26684427 51.57523121 31.52365951
-47.81412027 41.35445075 36.79678608
-73.33344767 9.273607714 17.86973025
-14.02091348 58.16918908 21.6069708
-11.65707637 82.14859002 22.46454928
-55.79203268 89.19737699 13.14693507
-37.43251852 54.94840347 28.50109003
-33.43389092 55.84365921 28.99902761
-73.79179489 9.331371793 17.28657213
20.14239018 33.49349001 33.51986812
-68.12954837 46.11873254 43.63021016
-20.66090493 49.81609315 26.61583724
-62.94075819 74.69784615 37.16535738
1.764500414 50.48668209 49.51434113
-62.87611537 69.8975149 40.43901057
13.3490977 50.04265236 29.15314327
31.88529862 42.90520867 50.20842834
28.88393296 23.22703565 34.50048065
-63.55185908 57.44186741 45.32274478
-46.7691497 75.25630099 33.42502357
-65.26021815 35.97739773 44.27820963
-0.8686351908 51.57382365 43.22280323
-60.46655573 56.36775358 45.55017435
-55.72516615 46.26080081 45.44202899
-49.49883254 59.99339002 36.32937381
-10.77998013 70.17329719 21.47583907
-46.65043841 88.05968407 7.130824268
2.82955464 57.5141024 60.95806134
-49.39401879 11.23516534 2.186424271
-12.09433239 83.31551931 -8.47855796
-59.21621455 82.32784398 29.4534525
-37.63719252 60.25391356 31.04293961
-49.53967504 22.64512503 32.90501252
-54.42374961 87.43985348 9.982046765
-51.45572164 68.85585411 38.70014958
-39.54958309 51.08848745 32.92706361
-37.43243744 42.26907268 30.02597788
-0.7902378723 66.80563936 47.0116875
3.993838773 66.28213155 58.00323877
-49.56520788 78.09999041 30.07983042
38.62483282 70.01974372 48.79645535
-55.87379077 8.834499411 13.81091704
-55.85478921 21.67950703 33.66565953
-55.86962055 7.821304795 8.092100706
-66.42966892 74.22112941 37.5381486
0.5127638325 64.88948254 51.52742206
-51.64196373 43.82088207 43.85449054
-2.82411982 68.89225919 40.65647681
-53.45333279 14.3405527 25.17736969
-70.89377231 13.46154173 27.0542855
-49.69615445 83.23110223 22.97887883
-40.43449035 63.53369866 45.94203243
-55.95559909 20.75070545 32.81380919
8.057281235 65.16060577 58.59452389
-49.66778999 32.81028659 37.2842902
-49.49112898 64.16255829 40.36892534
-15.26014247 50.29473011 25.85615313
-70.20974113 81.41786785 30.46036946
21.72138987 37.94694005 22.30632965
22.2229807 37.94169569 22.27155366
-35.85359325 58.559444 27.06687168
-6.653820593 83.27188905 -9.009604389
-16.78060877 49.3882813 25.01555255
-67.16008576 83.62281101 27.58875557
3.970423346 66.87049283 57.00421776
-23.86484594 45.93455135 31.50013257
21.7619156 30.40657472 34.08633526
-27.84519104 87.65076067 37.42484136
-74.12574688 7.422363142 10.37821885
-65.49654176 85.93229686 23.76787341
-17.87543368 82.28726307 22.04060919
-16.46111868 56.65458747 22.1750109
-8.320162246 60.64257069 32.59347251
-14.33193506 29.30425093 36.28630584
-13.4487637 80.60418375 23.2549073
-39.37517801 45.327467 35.67888397
60.69517643 45.99975866 61.23018693
-40.99546037 55.79267843 31.03288666
34.0708988 34.65073219 23.25614549
61.50752919 48.43462695 50.28945915
-56.91858568 66.11473431 42.42966587
-63.2244426 84.12195621 26.87040385
-36.83947849 57.78643528 30.40189492
-49.50601414 59.60056705 40.89091988
-55.13381607 86.22371731 12.58197557
37.33016534 50.5727259 53.38617852
-49.67542366 22.91182501 29.83485206
-49.62219359 74.19200063 34.67100987
-41.36904265 62.05247557 45.8354532
-0.9784514727 45.2853161 28.84571522
10.09567089 42.63912425 28.31953525
-70.11385497 66.34111394 42.31810294
-64.15045216 67.28592668 41.86605207
-67.94068526 24.00602072 38.09584867
-51.64341 17.94083899 29.65058351
-66.1921437 16.0853727 30.6581578
-55.65830182 20.74940369 32.77222463
-49.65234764 84.609882 18.61792386
-23.8691691 51.96332443 31.43424998
-42.97558978 49.67471871 32.51256023
3.164682102 60.00479767 61.11354658
-51.43585152 61.54225006 42.02504166
-11.63478011 44.95523811 28.88308628
-18.35820676 48.64120026 28.1624315
35.61498575 69.26141407 17.38720395
48.25576557 44.76515786 54.62803975
59.58606615 8.893037321 55.20553533
56.3405206 58.70099364 54.45482106
-35.47408932 48.34846407 28.99818756
-69.07828236 39.1698427 42.90595146
53.05692462 50.54364165 50.72462195
9.140917936 57.38767111 26.26230227
-21.42259863 54.98136322 30.89192273
-39.73860954 61.54547168 45.58355009
-41.88622409 22.23999348 24.49523313
14.6507122 55.62899889 28.14757972
-67.41521859 80.24265719 31.91685867
55.67895555 64.06926325 50.52860232
-49.54252313 83.93983672 8.895430108
-45.85415775 39.8502317 36.40515936
-49.54817795 70.99270084 36.83685649
39.74020386 17.70887763 38.2225219
52.71737467 56.29551544 53.93711867
-47.18246543 48.30223221 37.59438055
28.53632443 25.70285195 34.95889367
30.18963884 74.08029309 49.88040425
31.77928005 31.27531409 22.09100122
-37.73782423 67.88081345 24.09648192
-1.291120994 52.14049164 42.55171894
-63.43229333 23.45959454 37.75274426
-49.46936881 39.69166348 38.93537775
-9.707520229 81.59597986 -6.451990825
-33.4541625 49.10207601 31.51778022
-74.60618575 42.24607514 43.20884989
27.50324011 36.29881022 22.85646739
-18.83345267 60.8845626 25.96255939
22.91364619 31.41137744 22.2022081
34.04670677 33.65850358 23.12599104
-56.78690961 86.35236532 23.0570739
28.55426551 70.39195691 46.39605023
53.20362847 67.09232277 50.86662622
-61.29149133 34.94081718 43.90145133
-53.45624962 72.61240083 36.25941225
-10.22378071 68.58430224 22.33605896
-49.56923023 87.46882104 8.719983961
-49.26337346 83.94768935 8.75103552
25.43067261 69.64996643 43.95202297
-27.90053023 50.00581521 31.6206868
-60.46886662 20.59915223 35.43385724
29.66262898 23.6585577 35.16919677
-49.69416034 80.82478655 27.60620429
-72.24884594 75.96242195 35.91362836
-38.23856464 70.06979581 45.39380194
-43.66193196 29.6845182 31.6386506
-62.27204861 35.44210926 44.09572604
16.83954378 73.37461112 54.79177869
-39.05944776 79.14544448 39.24649014
-39.37334788 45.77920412 36.26108172
49.12119436 44.52682306 57.22976197
8.577336095 56.37019851 26.52806347
13.20497414 43.0719409 28.52876192
-37.80999158 43.09229064 32.19474692
-16.41282443 75.76399053 21.74225766
-5.207715343 61.17384192 43.3124505
-31.80992415 83.40299891 40.87893175
54.79335321 50.71504497 54.23034133
26.81902576 31.82667741 22.46296458
-70.88793883 17.98520224 32.80563017
-66.90241564 15.19145229 29.51760947
-55.9761721 87.65306814 7.802951245
-9.379657217 39.59985308 20.70254672
-49.67177932 22.30865922 31.00950514
-72.2761157 32.26526485 42.09620355
-13.62889443 37.23284346 37.3375977
-4.253956702 59.17942009 42.97670355
-11.96982893 58.80797237 25.45363651
58.28221996 52.42587557 57.01098728
-50.82416839 38.37953351 42.77262734
-74.79829479 35.15663238 38.14529765
-58.03399651 9.392400507 19.39964083
34.6624551 71.54294863 49.3495531
-54.76154839 41.39677661 43.4569948
-9.151218674 81.06092115 -6.173369724
-31.45307063 49.05897985 31.63645171
-16.91332765 79.7616366 22.0432407
-49.66676244 77.99589262 25.48099901
11.19684489 33.80041217 37.48950246
-47.96161776 13.76701951 1.654850252
2.302409776 66.0880286 54.75245163
-20.8986868 46.07363791 29.31984965
-43.1534403 49.13832847 32.94664585
7.426497836 59.61713453 24.01457726
-37.78525185 55.13299551 32.25212079
-65.69466464 60.04275201 44.67162836
-48.30980943 58.48161862 36.17244293
10.6261284 57.2826945 27.19770902
-57.60477667 13.70248765 27.54955912
28.79642585 72.53310292 50.62708833
-15.08602471 26.27397378 24.99465499
-34.34955205 80.25845712 42.90754508
10.33351288 68.21501471 57.66246994
5.760064993 59.45703507 61.15077862
40.28798324 67.69519669 11.37126512
21.58781878 73.95938871 52.92436971
-3.988883826 49.83717411 29.01830097
-14.5524927 58.79088825 20.31784913
-73.19100786 59.17743711 44.49315111
-7.699706634 56.00120721 28.0890729
-67.82406079 84.21218461 26.70014139
-41.16076537 79.00943897 35.39943376
-17.85659044 48.13372702 28.1312491
-74.42688853 54.67959413 38.67667498
-72.93200699 50.68255904 44.00560711
-49.68673474 79.45640088 20.18344291
-68.45665415 20.26095724 35.05654957
5.173039588 59.93325859 61.56101877
-61.73516905 35.43145275 44.11704844
29.5932899 24.66080044 35.20093681
-50.87128735 37.39887019 42.51258693
-55.68782435 83.13153563 25.27736842
-63.64595217 67.27921482 41.84775708
-72.88394401 16.2150912 30.42845716
-10.84310583 36.29076924 30.2626924
3.401255787 54.98718927 61.05008194
39.69409937 46.3222102 49.83868187
-61.92725179 53.85017258 45.92145106
-37.8801792 48.8255174 29.7457012
-19.09790035 29.26777985 37.61858714
-71.47316092 27.86061237 40.63572751
-71.29001844 60.13971305 44.61310138
-67.40048383 23.99368853 38.13599014
61.33320759 48.47300618 49.87716918
17.79430875 73.38854575 54.44793584
-18.3042156 32.32671037 36.91677402
-2.39847419 46.29989676 28.99181107
21.22040921 74.47349614 52.85294345
-6.860268177 83.7543564 -8.744425738
9.167171274 46.46474036 59.83239863
2.142506843 50.84777194 51.07709967
-66.71697433 63.67751328 43.45811346
52.16853648 55.6489323 55.72115382
-40.41632365 58.76161291 31.41672906
-13.95005804 60.94852002 24.28880212
2.510182582 44.7329625 28.76589452
33.85098154 43.28307884 51.27360919
-55.30769535 40.42006486 43.24624936
-70.91015207 11.70871879 24.09889306
-56.061049 17.76582469 32.52474802
-57.44842577 88.94270373 16.41925272
50.03689058 44.86445876 58.96225277
-49.27581168 68.98845226 30.91848946
-62.78318866 62.57183599 43.89014423
-59.8744232 64.60007123 43.09675372
-63.66219767 16.0480573 30.60886634
-13.70033868 38.85559449 30.16642499
-44.56973408 20.34590871 23.68834917
2.552227227 56.46094487 26.62363793
-29.46048596 45.52944075 31.44343337
-55.71929862 73.04207635 37.52964336
-39.89520873 61.66070454 32.62242348
-42.79008462 55.39139287 41.86019056
-40.73393727 56.57939401 45.1568099
6.924862833 44.71124649 28.17608374
-55.79301697 89.14831779 13.71663782
-74.91421643 56.15951391 39.06954521
-70.7758931 79.19903684 33.01958455
-66.4083445 43.91062496 45.62872721
60.89258095 46.58822969 60.18778026
-62.29133902 9.043144673 18.43931716
-55.75924456 87.50074427 15.35651073
-52.38070313 14.7972476 25.49780601
-11.29507893 62.93798942 29.82047421
-70.05701127 41.17686055 43.10023653
-72.35324594 63.26812106 43.45886712
-72.04387751 27.87594874 40.56653088
36.97254922 20.37718309 36.90789733
4.856801734 49.76924604 57.25314324
-71.33871291 29.77687755 41.67124649
-62.03882478 26.27791425 39.72973506
-16.86810831 58.1683149 22.1600961
-47.59403178 44.76560024 43.76882942
33.27371279 30.78735863 21.64390604
-73.42287797 51.68682792 44.09421485
-44.07713697 35.9272158 34.99437504
-55.84134259 29.06395619 41.16527552
-63.90912652 48.3344207 46.23722998
-12.17877649 81.32447442 -8.655515292
-56.86912842 85.80397237 11.95897457
-23.83603661 49.43553093 31.63919878
-15.24580349 28.76747038 36.86528558
-52.127869 81.96665507 26.46796561
-49.66976392 54.36367462 43.44940864
-52.51359698 73.13466145 35.84350119
52.31787095 38.54781275 56.10916092
-39.58942741 65.53750202 45.82582562
-57.0827262 60.41298975 44.55793108
-64.78593481 76.89415708 35.32742677
29.46133506 23.18354899 34.89519158
-69.1448893 14.32710138 28.32599685
44.3550451 64.84405428 37.86715725
-55.20844624 74.25959282 34.95168232
49.05088469 11.09872142 43.26274679
28.62535705 30.39995622 21.20098427
-58.47456171 83.45371313 27.89538566
-41.12196066 68.67964633 44.60162972
-3.393558354 66.9228646 40.32067818
-70.11790628 71.0861025 39.675353
-54.65921479 52.39390791 43.96085289
-65.26431767 38.42860118 44.95683741
-1.137189661 31.29668249 22.29366866
-49.14262258 67.38984031 31.97102119
-49.57627654 39.87614032 42.64086548
-72.73759951 22.68912525 36.81820985
-39.19893207 64.31611135 30.7969432
-7.384562992 30.47033822 21.42460568
-53.87900727 13.5756261 22.48509234
-61.97883197 41.82300714 45.71202037
51.2817119 69.8791162 48.01521931
-37.09740145 57.01122152 27.79704724
-60.86451675 47.28083438 46.23412179
-55.82544462 11.63184389 22.11456844
26.25990858 75.09720218 50.47808639
15.66110882 33.27624595 36.89640356
50.28963494 64.12996736 45.02503874
-40.46732673 66.33297025 30.93222728
-51.69842363 9.423329807 12.0057171
28.94639173 27.31236851 33.676171
-57.77840211 28.11987547 40.84098052
-62.41590196 64.64320421 43.0807098
-40.27172315 45.29082284 42.10189456
-11.65686297 40.35878194 18.15326384
-15.05241274 26.55435279 21.73340035
2.544834013 57.11555746 24.85246778
-44.20665065 39.33461499 36.24998401
-55.98397779 80.60306135 31.37965872
38.07547416 69.5468681 48.5640657
-67.29764437 68.39125978 41.28800892
-74.14883218 39.75206574 42.94070667
-64.04555423 78.52288403 33.75870305
43.96133897 30.62421068 21.49965202
-11.05149521 33.35115642 23.64111722
12.20780064 32.78156983 37.47089079
-52.27893933 78.03179185 31.38905414
-9.112947314 44.41979933 28.79128087
1.40943443 46.27453797 28.56000339
-68.44038708 46.12686515 43.5950796
-47.21447324 29.26467716 31.36332181
-12.97813183 32.98502649 28.25884251
14.81447144 74.55812533 53.09215694
-62.62162004 30.59628712 42.14982213
26.86467746 69.744265 42.58386279
-47.36997947 70.2522785 33.36354158
-17.97123725 56.27657166 26.85128367
-49.626796 83.04078893 19.34830598
-9.009652041 70.22848879 26.32416417
15.79242928 49.49725709 29.19189071
-56.96758157 16.39435656 31.13870924
7.856181541 53.45091263 60.5547985
54.64084462 44.59859819 61.17055499
51.03924525 64.92971032 47.25136566
5.347635387 58.41287082 61.69757042
57.34849291 70.47444651 51.60050145
-20.91992025 48.19232444 28.0352028
-61.66446548 13.76813217 27.56171666
-15.21310185 71.88440889 19.93735101
-11.74264866 71.31644861 20.04217134
-5.003743934 51.36020199 29.01134439
-40.64125948 43.46170903 34.27284975
0.08556574083 48.25743559 29.100114
-55.81620285 89.45734795 10.13361134
-37.32211686 69.67517674 43.97341491
-65.48905782 27.28833497 40.31129592
-32.47926681 48.07664598 31.58252012
-50.14664324 55.36088554 43.61728212
58.4268636 53.38212132 57.53588622
61.85634555 48.35778115 51.11502009
-38.91221479 56.13602305 32.47644545
6.986221723 63.57479751 59.72807855
-72.34182702 85.48855629 24.39793399
56.85873167 5.383863138 49.84958134
-74.11006568 17.04513777 32.67978175
34.69862741 23.64553553 34.35997241
-13.01864562 73.7774138 20.8500362
-48.28054491 55.40400455 36.93420708
-11.57016919 32.86280219 23.58256285
-56.7569982 74.04963151 37.68438503
-36.78745592 79.79668238 42.90644523
-3.320746434 39.37482532 27.9860046
-66.9761076 63.68434142 43.42826574
-46.69409357 12.21564896 1.942644678
-3.379499187 67.96976034 39.81814893
41.62440613 68.05877714 47.68218618
-47.07696202 45.09943764 39.79479234
-52.32582727 66.76557189 39.82504208
-52.25914436 10.95931979 17.58832167
-61.45877268 42.31066255 45.77842284
-63.94106933 68.33340461 41.31951499
`;
