import { Dispatch, SetStateAction, useState } from 'react';

import { Card, Text, Table, TableHeading, Box, Button, Spinner } from '@nike/eds';
import { ResponseSchema, Sample } from '@nike.innovation/talos-core';

import { ResponseTableRow } from './response-table.row';
import { downloadMetrics } from '../../api-client/get-row-metrics';

export function ResponseTable({
  samples,
  responseSchema,
  setSipingModalOpen,
  setSelectedSample,
}: {
  samples: Sample[];
  responseSchema: ResponseSchema;
  setSipingModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedSample: Dispatch<SetStateAction<string>>;
}) {
  const headers = ['Sample #', 'Treatment #', ...responseSchema, 'State', 'Metrics', '', ''];

  const openSipingModal = (sample: Sample) => {
    setSelectedSample(sample.sampleId);
    setSipingModalOpen(true);
  };

  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <Card className="eds-elevation--2 eds-spacing--mb-24">
      <Box className="eds-flex eds-flex--justify-content-space-between">
        <Text font="title-4" className="eds-spacing--mb-16">
          Response Table
        </Text>
        <Button
          onClick={async () => {
            setIsDownloading(true);
            await downloadMetrics(samples.flatMap(sample => sample.metricFiles));
            setIsDownloading(false);
          }}
        >
          {isDownloading ? <Spinner /> : 'Download all metrics'}
        </Button>
      </Box>
      <Table width="100%">
        <thead>
          <tr>
            {headers.map(column => (
              <TableHeading>{column}</TableHeading>
            ))}
          </tr>
        </thead>

        <tbody>
          {samples.map(sample => (
            <ResponseTableRow
              sample={sample}
              responseSchema={responseSchema}
              openSipingModal={openSipingModal}
            />
          ))}
        </tbody>
      </Table>
    </Card>
  );
}
