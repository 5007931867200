import { KeyenceConfigurationForm } from '../../configuration/configuration-types';
import { ScanPoseForm } from '../../scan-pose/scan-poses-reducer';

export const keyenceConfigurationForm: KeyenceConfigurationForm = {
  cloudConfig: {
    cropBox: {
      sizeX: '250',
      sizeY: '385',
      sizeZ: '200',
      x: '-70',
      y: '-10',
      z: '-10',
    },
    filtering: {
      downsample: true,
      clean: true,
      segment: true,
      voxelSize: '0.5',
      nbNeighbors: '20',
      stdRatio: '5',
      eps: '1.5',
      minPoints: '10',
    },
    translation: {
      invert: true,
      x: '50',
      y: '-100',
      z: '74',
    },
    other: {
      delayComp: '-0.6',
      minCloudSize: '1000',
    },
  },
  position: {
    length: '500',
    rate: '100',
    speed: '50',
  },
};

export const defaultKeyenceConfiguration = {
  cloudConfig: {
    cropBox: {
      sizeX: 250,
      sizeY: 385,
      sizeZ: 200,
      x: -70,
      y: -10,
      z: -10,
    },
    filtering: {
      downsample: true,
      clean: true,
      segment: true,
      voxelSize: 0.5,
      nbNeighbors: 20,
      stdRatio: 5,
      eps: 1.5,
      minPoints: 10,
    },
    translation: {
      invert: true,
      x: 50,
      y: -100,
      z: 74,
    },
    other: {
      delayComp: -0.6,
      minCloudSize: 1000,
    },
  },
  position: {
    length: 500,
    rate: 100,
    speed: 50,
  },
};

export const scanPoseForm: ScanPoseForm = {
  inputName: '',
  inputX: '0',
  inputY: '0',
  inputZ: '0',
  inputRX: '0',
  inputRY: '0',
  inputRZ: '0',
  selectedPoses: [],
};
