import { useEffect, useState } from 'react';
import { UserClaims, CustomUserClaims } from '@okta/okta-auth-js';

export function useSettingNames(
  oktaUser: UserClaims<CustomUserClaims> | undefined,
  savedSettingsClient: any
) {
  const [settingNames, setSettingNames] = useState<string[]>([]);

  const userEmail = oktaUser?.email || oktaUser?.preferred_username || '';
  const settingsNamespace = `siping-${userEmail}`;

  useEffect(() => {
    if (oktaUser) {
      savedSettingsClient
        .listSettings({ namespace: settingsNamespace })()
        .then((configs: any) => setSettingNames(configs));
    }
  }, [oktaUser]);

  // closure around storeSetting() to add refetch logic
  const storeSetting = async (
    namespace: string,
    key: string,
    settings: Record<string, unknown>
  ) => {
    await savedSettingsClient.storeSetting({
      namespace,
      key,
      settings,
    });

    // Refetch settings after storing new ones
    savedSettingsClient
      .listSettings({ namespace: settingsNamespace })()
      .then((configs: any) => setSettingNames(configs));
  };

  return { settingNames, storeSetting };
}
