/* eslint-disable react/no-array-index-key */
import { Dispatch, SetStateAction, useState } from 'react';

import { Card, Text, Table, TableHeading, Box, Button, Spinner, Tooltip } from '@nike/eds';
import { Experiment, ResponseSchema, Sample } from '@nike.innovation/talos-core';

import { ResponseTableRow } from './response-table-row';
import { environment } from '../../../environments/environment';
import { downloadBinaryFile, downloadSampleMetrics } from '../../api-client/get-row-metrics';

export function ResponseTable({
  experiment,
  samples,
  responseSchema,
  setSipingModalOpen,
  setResultsUploadModalOpen,
  setSelectedSample,
  token,
}: {
  experiment: Experiment;
  samples: Sample[];
  responseSchema: ResponseSchema;
  setSipingModalOpen: Dispatch<SetStateAction<boolean>>;
  setResultsUploadModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedSample: Dispatch<SetStateAction<string>>;
  token: string;
}) {
  const headers = [
    'Sample #',
    'Treatment #',
    ...responseSchema,
    'State',
    'Metrics',
    'Uploaded Files',
    '',
    '',
  ];

  const openSipingModal = (sample: Sample) => {
    setSelectedSample(sample.sampleId);
    setSipingModalOpen(true);
  };

  const isPywebviewAvailable = environment.useMockServer || (window as any).pywebview !== undefined;
  const hasMetricFiles = samples.reduce((sum, sample) => sum + sample.metricFiles.length, 0) > 0;

  const [isDownloading, setIsDownloading] = useState(false);

  function downloadCSVTemplate() {
    const csvHeaders = headers.filter(header => header !== 'Uploaded Files');
    const rows = (samples ?? []).map(sample => {
      const responseValues = responseSchema.map(schema => sample.response[schema] || '');
      return [
        `${experiment.name}-${sample.treatmentId}-${sample.sampleId}`,
        sample.treatmentId,
        ...responseValues,
        sample.state,
      ];
    });

    const csvContent = [csvHeaders, ...rows].map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const filename = experiment.title
      ? `${experiment.name}-${experiment.title}-sample.csv`
      : `${experiment.name}-sample.csv`;

    downloadBinaryFile(blob, filename);
  }

  return (
    <Card className="eds-elevation--2 eds-spacing--mb-24" style={{ overflowX: 'scroll' }}>
      <Box className="eds-flex eds-flex--justify-content-space-between eds-spacing--mb-16">
        <Box className="eds-flex eds-flex--direction-column">
          <Text font="title-4">Response Table</Text>
          <Text font="subtitle-2">Scroll as needed to view all columns</Text>
        </Box>
        <Box className="eds-flex eds-gap--8">
          <Button
            size="small"
            variant="secondary"
            onClick={() => {
              downloadCSVTemplate();
            }}
          >
            Download CSV Template
          </Button>
          <Button
            variant="secondary"
            size="small"
            onClick={() => {
              setResultsUploadModalOpen(true);
            }}
          >
            Update from CSV
          </Button>

          {!isPywebviewAvailable ? (
            <Button
              disabled={!hasMetricFiles || isDownloading}
              variant="secondary"
              size="small"
              onClick={async () => {
                setIsDownloading(true);
                await downloadSampleMetrics(token, samples, experiment.name);
                setIsDownloading(false);
              }}
            >
              {isDownloading ? <Spinner /> : 'Download all metrics'}
            </Button>
          ) : (
            <Tooltip bodySlot="Only Available in Webview" isDark>
              <Button disabled>Download all metrics</Button>
            </Tooltip>
          )}
        </Box>
      </Box>

      <Table width="100%">
        <thead>
          <tr>
            {headers.map((column, index) => (
              <TableHeading key={index}>{column}</TableHeading>
            ))}
          </tr>
        </thead>

        <tbody>
          {samples.map(sample => (
            <ResponseTableRow
              key={sample.sampleId}
              experiment={experiment}
              sample={sample}
              responseSchema={responseSchema}
              openSipingModal={openSipingModal}
              token={token}
            />
          ))}
        </tbody>
      </Table>
    </Card>
  );
}
