export type Program = 'Siping';

export interface Treatment {
  treatmentId: string;
  numSamples: number;
  levels: Record<string, string>;
}

export type ResponseSchema = string[];

export type TestResultsKey = `experiments/${string}/${string}/test-results/${string}`;

export interface ExperimentMetadata {
  id: string;
  name: string;
}

export type SampleResponse = Record<string, string>;

export enum SampleState {
  'Success' = 'Success',
  'Fail' = 'Fail',
  'TBD' = 'TBD',
}

export const sampleStates = Object.values(SampleState);

export interface MetricKeyPayload {
  cellId: string;
  timestamp: number;
  appId: string;
  phaseId: string;
}

export type SampleFileObjectKey =
  `experiments/${string}/${string}/samples/${string}/files/${string}`;

export interface Sample {
  experimentId: string;
  sampleId: string;
  treatmentId: string;
  state: SampleState;
  response: SampleResponse;
  metricKeyPayloads: MetricKeyPayload[];
  metricFiles: string[];
  files: string[];
}

export interface SampleConfiguration {
  program: string | null;
  experimentId: string | null;
  sampleId: string | null;
}

export interface Experiment {
  program: Program;
  id: string;
  name: string;
  title: string;
  description: string;
  confluenceLink: string;
  treatmentTable: Treatment[];
  testResults: string;
  responseSchema: ResponseSchema;
  samples: Sample[];
  createdAt: number;
}
