import { match, P } from 'ts-pattern';

import { Box, Table, TableHeading, Text } from '@nike/eds';
import { useOktaAuth } from '@okta/okta-react';
import { WearTestMetadata } from '@nike.innovation/talos-core';
import { Loading } from '@nike.innovation/ui-components';

import { WearTestsTableRow } from './wear-tests-table-row';
import { useWearTestMetadata } from '../../hooks/use-wear-tests-metadata';

export function WearTestsTable() {
  const { oktaAuth } = useOktaAuth();

  const oktaToken = oktaAuth.getAccessToken();
  if (!oktaToken) {
    throw new Error('No okta token');
  }

  const { wearTestsMetadata, isLoading, isError } = useWearTestMetadata(oktaToken);

  const headers = ['Name', 'Status', 'Requester', 'Request Date', 'Desired Finish Date', ''];

  return (
    <Box>
      {match({ wearTestsMetadata, isLoading, isError })
        .with(
          {
            wearTestsMetadata: P.not(P.nullish),
            isLoading: false,
            isError: P.nullish,
          },
          refined => (
            <Table width="100%">
              <thead>
                <tr>
                  {headers.map(column => (
                    <TableHeading key={column}>{column}</TableHeading>
                  ))}
                </tr>
              </thead>
              <tbody>
                {refined.wearTestsMetadata.map((wearTestMetadata: WearTestMetadata) => (
                  <WearTestsTableRow
                    key={wearTestMetadata.id}
                    wearTestMetadata={wearTestMetadata}
                  />
                ))}
              </tbody>
            </Table>
          )
        )
        .with(
          {
            isError: P.not(P.nullish),
          },
          () => <Text>Error loading wear tests</Text>
        )
        .otherwise(() => (
          <Loading />
        ))}
    </Box>
  );
}
