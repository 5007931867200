import { match } from 'ts-pattern';

import { KeyenceConfigurationForm } from './configuration-types';

export type ConfigurationAction =
  | { kind: 'EDIT_CLOUD_FILTERING'; name: string; value: boolean | string }
  | { kind: 'EDIT_CROP_BOX'; name: string; value: string }
  | { kind: 'EDIT_TRANSLATION'; name: string; value: string | boolean }
  | { kind: 'EDIT_OTHER'; name: string; value: string }
  | { kind: 'EDIT_POSITION'; name: string; value: string }
  | { kind: 'LOAD_CONFIGURATION'; configuration: KeyenceConfigurationForm };

// TO-DO: Can we create some reducer that dynamically handles updating the state so we don't have to create a new reducer for every new Command?
export const configurationReducer = (
  state: KeyenceConfigurationForm,
  action: ConfigurationAction
) =>
  match(action)
    .with({ kind: 'EDIT_CLOUD_FILTERING' }, refined => ({
      ...state,
      cloudConfig: {
        ...state.cloudConfig,
        filtering: {
          ...state.cloudConfig.filtering,
          [refined.name]: refined.value,
        },
      },
    }))
    .with({ kind: 'EDIT_CROP_BOX' }, refined => ({
      ...state,
      cloudConfig: {
        ...state.cloudConfig,
        cropBox: {
          ...state.cloudConfig.cropBox,
          [refined.name]: refined.value,
        },
      },
    }))
    .with({ kind: 'EDIT_TRANSLATION' }, refined => ({
      ...state,
      cloudConfig: {
        ...state.cloudConfig,
        translation: {
          ...state.cloudConfig.translation,
          [refined.name]: refined.value,
        },
      },
    }))
    .with({ kind: 'EDIT_OTHER' }, refined => ({
      ...state,
      cloudConfig: {
        ...state.cloudConfig,
        other: {
          ...state.cloudConfig.other,
          [refined.name]: refined.value,
        },
      },
    }))
    .with({ kind: 'EDIT_POSITION' }, refined => ({
      ...state,
      position: {
        ...state.position,
        [refined.name]: refined.value,
      },
    }))
    .with({ kind: 'LOAD_CONFIGURATION' }, refined => refined.configuration)
    .exhaustive();
