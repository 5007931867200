import { Experiment } from '@nike.innovation/talos-core';
import { Box, Text, Link, Button } from '@nike/eds';

export function ExperimentHeader({ experiment }: { experiment: Experiment }) {
  return (
    <Box>
      <Box className="eds-flex eds-flex--justify-content-space-between eds-spacing--mb-24">
        <Text font="title-3" as="h1" className="eds-spacing--mb-24">
          {experiment.program} / {experiment.id}-{experiment.name}
        </Text>

        <Button as="a" href={`/experiments/${experiment.id}/edit`}>
          Edit
        </Button>
      </Box>

      <Box className="eds-spacing--mb-24">
        <Link href={experiment.confluenceLink} target="_blank">
          Confluence Link
        </Link>
      </Box>
    </Box>
  );
}
