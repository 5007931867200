/* eslint-disable react/no-array-index-key */
import { Select, Table, TableCell, TableHeading, TextField } from '@nike/eds';
import { ResponseSchema, Sample, sampleStates } from '@nike.innovation/talos-core';
import { Dispatch, SetStateAction } from 'react';

export function ResponseTableForm({
  responseSchema,
  samples,
  setSamples,
}: {
  responseSchema: ResponseSchema;
  samples: Sample[];
  setSamples: Dispatch<SetStateAction<Sample[] | undefined>>;
}) {
  const headers = ['Sample #', 'Treatment #', ...responseSchema, 'State'];

  return (
    <Table>
      <thead>
        <tr>
          {headers.map(column => (
            <TableHeading key={column}>{column}</TableHeading>
          ))}
        </tr>
      </thead>

      <tbody>
        {samples.map((sample, rowIndex) => (
          <tr key={rowIndex}>
            <TableCell>{rowIndex + 1}</TableCell>
            <TableCell>{sample.treatmentId.replace('treatmentId#', '')}</TableCell>

            {responseSchema.map((response, columnIndex) => (
              <TableCell key={response}>
                <TextField
                  id={`sample-${rowIndex}-${columnIndex}`}
                  label={`Sample ${rowIndex} ${response}`}
                  hideLabel
                  type="text"
                  value={sample.response[response]}
                  onChange={e =>
                    setSamples(previousSamples =>
                      previousSamples?.map((previousSample, index) => {
                        if (rowIndex === index) {
                          return {
                            ...previousSample,
                            response: {
                              ...previousSample.response,
                              [response]: e.target.value,
                            },
                          };
                        }
                        return previousSample;
                      })
                    )
                  }
                />
              </TableCell>
            ))}

            <TableCell>
              <Select
                id={`state-${rowIndex}`}
                options={sampleStates.map(state => ({ value: state, label: state }))}
                label={`Sample ${rowIndex} state`}
                hideLabel
                defaultValue={{ value: sample.state, label: sample.state }}
                onChange={selected => {
                  setSamples(previousSamples =>
                    previousSamples?.map((previousSample, index) => {
                      if (rowIndex === index && selected) {
                        return {
                          ...previousSample,
                          state: selected.value,
                        };
                      }
                      return previousSample;
                    })
                  );
                }}
                required={false}
              />
            </TableCell>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
