import { useState } from 'react';
import { Modal, ButtonGroup, Button, Text, Spinner } from '@nike/eds';
import { ScanPose, CreateScanPoseResponse } from '@nike.innovation/talos-core';

export function SaveScanPose({
  poseToSave,
  visible,
  createPose,
  onClose,
  createStatus,
  emitSuccessEvent,
}: {
  poseToSave: ScanPose;
  visible: boolean;
  createPose: (key: string, scanPose: ScanPose) => Promise<CreateScanPoseResponse>;
  onClose: () => void;
  createStatus: { loading: boolean; error: unknown };
  emitSuccessEvent: (savePoseName: string) => void;
}) {
  const [saveExecuted, setSaveExecuted] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const saveScanPose = async () => {
    const response = await createPose(poseToSave.name, poseToSave);
    setSaveExecuted(true);
    if (response.message === 'Scan pose stored') {
      setSaveSuccess(true);
      emitSuccessEvent(poseToSave.name);
    } else {
      setSaveSuccess(false);
    }
  };

  const resetState = () => {
    setSaveExecuted(false);
    setSaveSuccess(false);
    onClose();
  };

  if (!visible) return null;

  return (
    <Modal
      onDismiss={resetState}
      isOpen={visible}
      headerSlot="Save Scan Pose"
      footerSlot={
        saveExecuted ? (
          <Button onClick={resetState} size="medium">
            Close
          </Button>
        ) : (
          <ButtonGroup>
            <Button disabled={createStatus.loading} onClick={saveScanPose} size="medium">
              Save
            </Button>
            <Button
              disabled={createStatus.loading}
              onClick={resetState}
              size="medium"
              variant="secondary"
            >
              Cancel
            </Button>
          </ButtonGroup>
        )
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {createStatus.loading && !saveExecuted && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Text font="body-1" as="p" style={{ marginBottom: '16px' }}>
              Saving scan pose...
            </Text>
            <Spinner size="large" />
          </div>
        )}
        {!createStatus.loading && !saveExecuted && (
          <div>
            <Text font="body-1" as="p" className="eds-spacing--mb-16">
              Do you want to save the following scan pose? This will allow it to be used in the
              future by you or other users.
            </Text>
            <div className="eds-grid eds-grid--s-cols-6">
              <div className="eds-grid--col-2">
                <Text font="title-5" as="h5">
                  Name
                </Text>
                <Text font="body-2" as="p">
                  {poseToSave.name}
                </Text>
              </div>
              <div className="eds-grid--row-pos-2">
                <Text font="title-5" as="h5">
                  X
                </Text>
                <Text font="body-2" as="p">
                  {poseToSave.x}
                </Text>
              </div>
              <div className="eds-grid--row-pos-2">
                <Text font="title-5" as="h5">
                  Y
                </Text>
                <Text font="body-2" as="p">
                  {poseToSave.y}
                </Text>
              </div>
              <div className="eds-grid--row-pos-2">
                <Text font="title-5" as="h5">
                  Z
                </Text>
                <Text font="body-2" as="p">
                  {poseToSave.z}
                </Text>
              </div>
              <div className="eds-grid--row-pos-2">
                <Text font="title-5" as="h5">
                  Rx
                </Text>
                <Text font="body-2" as="p">
                  {poseToSave.rx}
                </Text>
              </div>
              <div className="eds-grid--row-pos-2">
                <Text font="title-5" as="h5">
                  Ry
                </Text>
                <Text font="body-2" as="p">
                  {poseToSave.ry}
                </Text>
              </div>
              <div className="eds-grid--row-pos-2">
                <Text font="title-5" as="h5">
                  Rz
                </Text>
                <Text font="body-2" as="p">
                  {poseToSave.rz}
                </Text>
              </div>
            </div>
          </div>
        )}
        {saveSuccess && saveExecuted && (
          <div>
            <Text font="body-1" as="p">
              Scan pose saved successfully. It will now be selectable via the scan pose dropdown.
            </Text>
          </div>
        )}
        {!saveSuccess && saveExecuted && (
          <div>
            <Text font="body-1" as="p">
              There was an error saving scan pose. Please try again later.
            </Text>
          </div>
        )}
      </div>
    </Modal>
  );
}
