export type DeviceStatus = 'waiting' | 'error' | 'stopped' | 'ready' | 'busy' | 'not connected';

interface BaseDevice {
  kind: string;
  name: string;
  status: DeviceStatus;
}

interface KeyenceScanner extends BaseDevice {
  kind: 'KeyenceScanner';
}

interface ABBRobot extends BaseDevice {
  kind: 'ABBRobot';
  motorState: 'on' | 'off';
  modeState: 'manual' | 'auto';
}

interface PLCDevice extends BaseDevice {
  kind: 'PLCDevice';
}

interface Camera extends BaseDevice {
  kind: 'BaslerGigE';
}

export type Device = ABBRobot | KeyenceScanner | PLCDevice | Camera;

export interface Command {
  id: string;
  description: string;
  devices: Device[];
  name: string;
}

export interface RunParams {
  [paramName: string]: string;
}

type Point3D = [number, number, number];
type Vector4D = [number, number, number, number];
type X = [number, number, number, number, number, number];

export type Pose = [Point3D, Vector4D, Vector4D, X];

export enum Tool {
  'blade' = 1,
  'checkerboard' = 2,
}
