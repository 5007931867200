import ReactGA from 'react-ga4';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { Box, Text } from '@nike/eds';
import { Loading } from '@nike.innovation/ui-components';
import { RequiresAuth } from '@nike.innovation/auth-guard';

import { environment } from '../environments/environment';
import { VerticalNav } from './vertical-nav';

import '@nike/eds/dist/index.css';
import '../assets/fonts.css';
import './global.css';
import { DashboardIndexPage } from './dashboard/dashboard-index-page';
import { ApplicationIndexPage } from './application/application-index-page';
import { CalibratePage } from './application/calibrate/calibrate-application-page';
import { CalibrateCameraPage } from './application/calibrate/calibrate-camera-application-page';
import { NectarPage } from './application/nectar/nectar-application-page';
import { SipingPage } from './application/siping/siping-application-page';
import { ExperimentRoutes } from './experiment/experiment-routes';
import { NewExperimentPage } from './experiment/new-experiment-page';
import { UpdateExperimentPage } from './experiment/update-experiment-page';

const oktaAuth = new OktaAuth({
  issuer: environment.issuer,
  clientId: environment.clientId,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: ['openid', 'profile', 'offline_access'],
  tokenManager: {
    autoRenew: true,
  },
  pkce: true,
});

ReactGA.initialize(environment.gaTrackingId);

export function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), { replace: true });
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <main>
        <Box className="eds-flex" style={{ height: '100vh' }}>
          <VerticalNav />

          <Box style={{ width: '100vw', overflowY: 'scroll' }}>
            <Box
              className="eds-spacing--px-56 eds-spacing--my-32"
              maxWidth="1536px"
              ml="auto"
              mr="auto"
            >
              <Text font="title-6" className="eds-spacing--mb-16" as="h2">
                Talos
              </Text>

              <Routes>
                <Route
                  path="implicit/callback"
                  element={<LoginCallback loadingElement={<Loading />} />}
                />

                <Route path="" element={<RequiresAuth loadingElement={<Loading />} />}>
                  <Route path="/" element={<ApplicationIndexPage />} />
                  <Route path="/applications/nectar" element={<NectarPage />} />
                  <Route path="/applications/siping" element={<SipingPage />} />
                  <Route path="/applications/calibrate" element={<CalibratePage />} />
                  <Route path="/applications/calibrateCamera" element={<CalibrateCameraPage />} />

                  <Route path="/experiments/new" element={<NewExperimentPage />} />
                  <Route
                    path="/experiments/:experimentId/edit"
                    element={<UpdateExperimentPage />}
                  />

                  <Route path="/dashboard" element={<DashboardIndexPage />} />
                  <Route path="/experiments/*" element={<ExperimentRoutes />} />
                </Route>
              </Routes>
            </Box>
          </Box>
        </Box>
      </main>
    </Security>
  );
}

export default App;
