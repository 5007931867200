import { Dispatch, SetStateAction } from 'react';
import { match } from 'ts-pattern';

import { Box, Button, Icon, Label, Text, TextField, Toggle, Select } from '@nike/eds';
import { MorphAlgorithm, MorphParameter } from '@nike.innovation/talos-core';
import { talosPyWebViewClient } from '../../api-client/talos-py-web-view-client';
import { getFilenameFromPath } from '../../core/file-path';

export function MorphParameterField({
  parameter,
  setMorphAlgorithm,
}: {
  parameter: MorphParameter;
  setMorphAlgorithm: Dispatch<SetStateAction<MorphAlgorithm | undefined>>;
}) {
  return (
    <Box>
      {match(parameter)
        .with({ kind: 'number' }, numberParameter => (
          <Box>
            <TextField
              type="number"
              id={numberParameter.name}
              label={numberParameter.name}
              defaultValue={numberParameter.settings.default}
              onChange={e =>
                setMorphAlgorithm(previous => {
                  if (previous) {
                    return {
                      ...previous,
                      parameters: previous.parameters.map(previousParameter => {
                        if (previousParameter.name === numberParameter.name) {
                          return {
                            ...numberParameter,
                            value: +e.target.value,
                          };
                        }

                        return previousParameter;
                      }),
                    };
                  }
                  return previous;
                })
              }
            />
          </Box>
        ))

        .with({ kind: 'text' }, textParameter => (
          <Box>
            <TextField
              id={textParameter.name}
              type="text"
              label={textParameter.name}
              defaultValue={textParameter.settings.default}
              onChange={e =>
                setMorphAlgorithm(previous => {
                  if (previous) {
                    return {
                      ...previous,
                      parameters: previous.parameters.map(previousParameter => {
                        if (previousParameter.name === textParameter.name) {
                          return {
                            ...textParameter,
                            value: e.target.value,
                          };
                        }

                        return previousParameter;
                      }),
                    };
                  }
                  return previous;
                })
              }
            />
          </Box>
        ))

        .with({ kind: 'toggle' }, toggleParameter => (
          <Box>
            <Toggle
              id={toggleParameter.name}
              label={toggleParameter.name}
              onChange={() =>
                setMorphAlgorithm(previous => {
                  if (previous) {
                    return {
                      ...previous,
                      parameters: previous.parameters.map(previousParameter => {
                        if (previousParameter.name === toggleParameter.name) {
                          return {
                            ...toggleParameter,
                            value: !toggleParameter.value,
                          };
                        }

                        return previousParameter;
                      }),
                    };
                  }
                  return previous;
                })
              }
            />
          </Box>
        ))

        .with({ kind: 'select' }, selectParameter => (
          <Box>
            <Select
              id={selectParameter.name}
              options={selectParameter.settings.options}
              defaultValue={selectParameter.settings.default}
              required
              label={selectParameter.name}
              onChange={e =>
                setMorphAlgorithm(previous => {
                  if (previous) {
                    return {
                      ...previous,
                      parameters: previous.parameters.map(previousParameter => {
                        if (previousParameter.name === selectParameter.name) {
                          return {
                            ...selectParameter,
                            value: e?.value,
                          };
                        }

                        return previousParameter;
                      }),
                    };
                  }
                  return previous;
                })
              }
            />
          </Box>
        ))

        .with({ kind: 'file' }, fileParameter => (
          <Box>
            <Label htmlFor={`select-file-button-${fileParameter.name}`} font="title-6">
              {fileParameter.name} ({fileParameter.settings.accepts})
            </Label>

            <Box className="eds-flex eds-flex--align-items-center eds-gap--16 eds-spacing--mt-24">
              <Button
                id={`select-file-button-${fileParameter.name}`}
                variant="secondary"
                beforeSlot={<Icon name="Upload" size="s" />}
                size="small"
                onClick={async () => {
                  const result = await talosPyWebViewClient.selectSourcePath();

                  setMorphAlgorithm(previous => {
                    if (previous) {
                      return {
                        ...previous,
                        parameters: previous.parameters.map(previousParameter => {
                          if (previousParameter.name === fileParameter.name) {
                            return {
                              ...fileParameter,
                              value: result,
                            };
                          }

                          return previousParameter;
                        }),
                      };
                    }
                    return previous;
                  });
                }}
              >
                Choose file
              </Button>

              <Text font="body-3">
                {!fileParameter.value
                  ? 'no file selected'
                  : getFilenameFromPath(fileParameter.value)}
              </Text>
            </Box>
          </Box>
        ))
        .exhaustive()}
    </Box>
  );
}
