import { useState, useReducer, useEffect, Dispatch } from 'react';
import { Box, Text, SidePanel, TextField, Button, ButtonGroup, Select, Icon } from '@nike/eds';
import { Loading } from '@nike.innovation/ui-components';
import { useOktaAuth } from '@okta/okta-react';
import { CustomUserClaims, UserClaims } from '@okta/okta-auth-js';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import FlipIcon from '@mui/icons-material/Flip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import StopRounded from '@mui/icons-material/StopRounded';
import { SampleConfiguration } from '@nike.innovation/talos-core';

import { Position } from '../../configuration/position';
import { CloudConfiguration } from '../../configuration/cloud-configuration';
import {
  getNonNumericFields,
  parseConfigurationForm,
  parseKeyenceConfigurationForm,
} from '../../configuration/utils';
import { configurationReducer } from '../../configuration/configuration-reducer';
import { scanPoseReducer } from '../../scan-pose/scan-poses-reducer';
import { ScanPoses } from '../../scan-pose/scan-poses';
import { useSettingsClient } from '../../api-client/settings-api-client';
import { deepEqual } from '../../core/utils';
import { talosPyWebViewClient } from '../../api-client/talos-py-web-view-client';
import { CommandPageState, CommandPageAction } from '../../command/command-page-reducer';
import {
  keyenceConfigurationForm,
  defaultKeyenceConfiguration,
  scanPoseForm,
} from './scan-defaults';
import { Device } from '../../command/command-types';

export function SipingScanSection({
  commandPageState,
  dispatch,
  token,
  isPywebviewAvailable,
  scanDevices,
  isGoHomeInUse,
  updateShowPcd,
  sampleConfig,
}: {
  commandPageState: CommandPageState;
  dispatch: Dispatch<CommandPageAction>;
  token: string;
  isPywebviewAvailable: boolean;
  scanDevices: Device[] | undefined;
  isGoHomeInUse: boolean;
  updateShowPcd: (pcdString: string) => void;
  sampleConfig: SampleConfiguration;
}) {
  const { oktaAuth } = useOktaAuth();

  const savedSettingsClient = useSettingsClient();
  const [keyenceConfig, keyenceConfigDispatch] = useReducer(
    configurationReducer,
    keyenceConfigurationForm
  );

  const [oktaUser, setOktaUser] = useState<UserClaims<CustomUserClaims> | undefined>(undefined);

  const [scanPoses, scanPoseDispatch] = useReducer(scanPoseReducer, scanPoseForm);
  const [scanSettingsOpen, setScanSettingsOpen] = useState(false);
  const [scanSettingsName, setScanSettingsName] = useState('');
  const [position, setPosition] = useState<string[]>([]);
  const [scanPhase, setScanPhase] = useState<string>('pre');

  const parsedForm = parseConfigurationForm(keyenceConfig);
  const nonNumericFields = getNonNumericFields(keyenceConfig, 'cloudConfig');

  const areDevicesReady = (deviceArray: Device[]): boolean =>
    deviceArray.filter(device => device.status !== 'ready').length === 0;
  const areScanDevicesReady = areDevicesReady(scanDevices || []);

  useEffect(() => {
    oktaAuth.getUser().then(user => setOktaUser(user));
  }, [oktaAuth]);

  useEffect(() => {
    if (oktaUser) {
      savedSettingsClient
        .listSettings({ namespace: `siping-${oktaUser?.email}` })()
        .then(configs => setPosition(configs));
    }
  }, [oktaUser]);

  return (
    <Box className="eds-spacing--mb-32">
      {isPywebviewAvailable ? (
        <Box>
          <Text font="title-2" className="eds-spacing--mb-16">
            Scan
          </Text>
          <Accordion
            className="eds-spacing--mb-24"
            style={{ backgroundColor: 'var(--eds-background--secondary)' }}
          >
            <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
              <Text font="title-3">Scan Poses</Text>
            </AccordionSummary>

            <AccordionDetails>
              <ScanPoses scanPoseForm={scanPoses} dispatch={scanPoseDispatch} />
            </AccordionDetails>
          </Accordion>
          <SidePanel
            hasScrim
            title="Position"
            isOpen={scanSettingsOpen}
            onDismiss={() => setScanSettingsOpen(false)}
            className="eds-spacing--mb-24"
            headerSlot={
              <Box>
                <Text font="title-2" className="eds-spacing--mb-16">
                  Scan Settings
                </Text>

                <Box className="eds-flex eds-gap--32">
                  <Box className="eds-flex eds-flex--align-items-flex-end eds-gap--16">
                    <TextField
                      id="setting-name"
                      label="Settings name"
                      onChange={e => {
                        setScanSettingsName(e.target.value);
                      }}
                      style={{ width: 300 }}
                    />

                    <Button
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onClick={async () => {
                        if (oktaUser?.email) {
                          const response = await savedSettingsClient.storeSetting({
                            namespace: `siping-${oktaUser.email}`,
                            key: scanSettingsName,
                            settings: keyenceConfig,
                          });
                        }
                      }}
                      disabled={
                        deepEqual(keyenceConfig, defaultKeyenceConfiguration) ||
                        scanSettingsName === ''
                      }
                    >
                      Save
                    </Button>
                  </Box>

                  <Box className="eds-flex eds-flex--align-items-flex-end eds-gap--16">
                    <Select
                      id="load-config"
                      options={position.map(config => ({ label: config, value: config }))}
                      required={false}
                      label="Load config"
                      onChange={async config => {
                        if (config) {
                          const settings = await savedSettingsClient.fetchSetting({
                            namespace: `siping-${oktaUser?.email}`,
                            key: config.value,
                          })();

                          const parsedSettings = parseKeyenceConfigurationForm(settings);
                          if (parsedSettings) {
                            keyenceConfigDispatch({
                              kind: 'LOAD_CONFIGURATION',
                              configuration: parsedSettings,
                            });
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            }
          >
            <Position keyenceConfig={keyenceConfig} dispatch={keyenceConfigDispatch} />
            <CloudConfiguration keyenceConfig={keyenceConfig} dispatch={keyenceConfigDispatch} />
          </SidePanel>

          <Box className="eds-flex eds-flex--align-items-flex-end eds-gap--32">
            <Select
              id="scan-phase"
              required
              label="Phase"
              defaultValue={{ label: 'Pre', value: 'pre' }}
              options={[
                { label: 'Pre', value: 'pre' },
                { label: 'Post', value: 'post' },
              ]}
              onChange={e => {
                setScanPhase(e?.value || '');
              }}
            />

            <ButtonGroup>
              <Button
                beforeSlot={<FlipIcon />}
                disabled={
                  !areScanDevicesReady ||
                  !!commandPageState.runningCommandId ||
                  isGoHomeInUse ||
                  parsedForm === null ||
                  nonNumericFields.length > 0
                }
                onClick={() => {
                  dispatch({ kind: 'START_COMMAND', commandId: 'Scan' });
                  if (scanPoses.selectedPoses.length > 0) {
                    console.log('selected poses', scanPoses.selectedPoses);
                    talosPyWebViewClient
                      .multiscan(
                        parsedForm,
                        sampleConfig,
                        scanPoses.selectedPoses,
                        token,
                        scanPhase,
                        'bladerunner'
                      )
                      .then(
                        response => {
                          dispatch({ kind: 'END_COMMAND', response });

                          talosPyWebViewClient.readPcd().then(pcdString => {
                            updateShowPcd(pcdString);
                          });
                        },
                        () => {
                          dispatch({ kind: 'END_COMMAND', response: false });
                        }
                      );
                  } else {
                    talosPyWebViewClient
                      .scan(parsedForm, sampleConfig, token, scanPhase, 'bladerunner')
                      .then(
                        response => {
                          dispatch({ kind: 'END_COMMAND', response });

                          talosPyWebViewClient.readPcd().then(pcdString => {
                            updateShowPcd(pcdString);
                          });
                        },
                        () => {
                          dispatch({ kind: 'END_COMMAND', response: false });
                        }
                      );
                  }
                }}
              >
                Scan
              </Button>

              <Button
                variant="secondary"
                beforeSlot={<StopRounded />}
                disabled={commandPageState.runningCommandId !== 'Scan' || isGoHomeInUse}
                onClick={() => {
                  talosPyWebViewClient.stopCommand();
                }}
              >
                Stop
              </Button>

              <Button
                variant="secondary"
                beforeSlot={<Icon name="Edit" size="m" />}
                onClick={() => {
                  setScanSettingsOpen(true);
                }}
              >
                Edit
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      ) : (
        <Loading />
      )}
    </Box>
  );
}
