import { match, P } from 'ts-pattern';
import { Box, Table, TableHeading, Text } from '@nike/eds';
import { useOktaAuth } from '@okta/okta-react';
import { Loading } from '@nike.innovation/ui-components';
import { useBiomechanics } from '../../hooks/use-biomechanics';

interface Biomechanics {
  id: string;
  name: string;
  metadata: {
    study: {
      nsrlStudyNum: string;
      fptStudyNum: string;
      studyDescription: string;
    };
    athlete: {
      sex: string;
      fptSubjectName: string;
    };
  };
}

interface BiomechanicsTableRowProps {
  biomechanics: Biomechanics;
}

function BiomechanicsTableRow({ biomechanics }: BiomechanicsTableRowProps) {
  return (
    <tr>
      <td>{biomechanics.name}</td>
      <td>{biomechanics.metadata.study.nsrlStudyNum}</td>
      <td>{biomechanics.metadata.study.fptStudyNum}</td>
      <td>{biomechanics.metadata.athlete.fptSubjectName}</td>
      <td>{biomechanics.metadata.study.studyDescription}</td>
    </tr>
  );
}

export function BiomechanicsTable() {
  const { oktaAuth } = useOktaAuth();

  const oktaToken = oktaAuth.getAccessToken();
  if (!oktaToken) {
    throw new Error('No okta token');
  }

  // You'll need to implement this hook similar to useWearTestMetadata
  const { biomechanics, isLoading, isError } = useBiomechanics(oktaToken);

  const headers = ['Name', 'NSRL Study #', 'FPT Study #', 'Subject', 'Description'];

  return (
    <Box>
      {match({ biomechanics, isLoading, isError })
        .with(
          {
            biomechanicsData: P.not(P.nullish),
            isLoading: false,
            isError: P.nullish,
          },
          refined => (
            <Table width="100%">
              <thead>
                <tr>
                  {headers.map(column => (
                    <TableHeading key={column}>{column}</TableHeading>
                  ))}
                </tr>
              </thead>
              <tbody>
                {refined.biomechanics.map((biomechanic: Biomechanics) => (
                  <BiomechanicsTableRow key={biomechanic.id} biomechanics={biomechanic} />
                ))}
              </tbody>
            </Table>
          )
        )
        .with(
          {
            isError: P.not(P.nullish),
          },
          () => <Text>Error loading biomechanics data</Text>
        )
        .otherwise(() => (
          <Loading />
        ))}
    </Box>
  );
}
